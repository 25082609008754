/** @format */

import React from "react";
import { ContentHeader } from "./ContentHeader";
import { HomeContent } from "./HomeContent";
import MarqueeComponent from "react-fast-marquee";
import {
  amazon,
  plintron,
  cognizant,
  accenture,
  adobe,
  infosys,
  capgemini,
  tcs,
  zoho,
  freshworks,
  google,
  hcl,
  hp,
  ibm,
  ms,
  morganstanley,
  msigma,
  paypal,
  paytm,
  sopia,
  techmahindra,
  boeing,
  verizon,
  walmart,
  wipro,
} from "./PlacedCompanies";
import { useTranslation } from "react-i18next";
import styles from "./employee.module.scss";
import { Button } from "reactstrap";

const PlacedCompanies1 = [
  amazon,
  cognizant,
  accenture,
  adobe,
  infosys,
  capgemini,
  tcs,
  google,
  hp,
  ibm,
  ms,
];

const PlacedCompanies2 = [
  morganstanley,
  paypal,
  sopia,
  techmahindra,
  boeing,
  verizon,
  walmart,
  wipro,
  zoho,
];

interface Props {
  onCliclk: () => void;
}

const DiscoverTechruit: React.FC<Props> = ({ onCliclk }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.carouselProvider} style={{paddingBottom:"3%"}}>
       <div className={styles.content__header__container} style={{paddingTop:"2%"}}>
    <p className={styles.content__header__title_wrap}>
    <span className={styles.content__header__sub}>Discover the Power of Effective Candidate Testing Today!</span>

    </p>
    <p className={styles.content__header__description} style={{color:"#ffffff"}}>Get started with Terv and streamline your hiring process</p>
    <div>

    </div>
  </div>
   
  <div style={{display:'flex', gap:"5%", justifyContent:'center'}}>
          <Button
                color="primary"
                style={{
                  width: "150px",
                  height: "52px",
                }}   
                onClick={onCliclk}
              >
                Explore TESTS
              </Button>
              <div>
              <Button
              color="success"
              outline
                style={{
                  width: "174px",
                  height: "52px",
                }}
                onClick={onCliclk}
              >
               Not yet? Let us help you
              </Button>
              {/* <p style={{color:"#ffffff"}}>Chat with us, we are live</p> */}
              </div>
        </div>
  </div>
  );
};

export default DiscoverTechruit;
