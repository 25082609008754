import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import useStyles from  "./UserProfileStyles.js";
import EditUserInput from  "./EditUserInput.js";
import { DeleteOutlineIcon } from "assets/icons/icons";
import { ConformDelete } from "../../components/DeleteModelePre";

const paraStyle = {
  fontSize: 15,
  fontWeight: 500,
  color: "#5B5A5F",
  marginTop: 12,
  textAlign: "center",
};

export default function EditUserWorkshops({pageCount, userData, handlePageCount, submitUserData, history}) {
  // const [pageCount, setPageCount] = useState(1);
  const css = useStyles();
  
  const defaultData = {
    workshops: {value: "", validation:{key:"workshops", placeholder:"outer", name:"Workshops", min:10, max:500, type:"text", status: true}},
    industrialVisit: {value: "", validation:{key:"industrialVisit", placeholder:"outer", name:"Industrial visit", min:10, max:500, type:"text", status: true}},
  }
  const [userId, setUserId] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [errorComponentList, setErrorComponentList] = useState([]);
  const [proExpandIndex, setProExpandIndex] = useState(0); 

  // workshops: [{...defaultData.workshops}],
  // industrialVisit: [{...defaultData.industrialVisit}],

  const [pageData, setPageData] = useState({
    workshops: {value: [], validation:{key:"workshops", placeholder:"outer", name:"", min:10, max:500, type:"text", status: true}},
    industrialVisit: {value: [], validation:{key:"industrialVisit", placeholder:"outer", name:"", min:10, max:500, type:"text", status: true}},
    careerObjective: {value: "", validation:{key:"careerObjective", placeholder:"outer", name:"Career objective", min:10, max:500, type:"text", status: true}},
  });

  const [dataDelete, setDataDelete] = useState({ type: "", index: null });

  const addData = (key) => {
    const tempPageData = {...pageData}
    tempPageData[key]["value"].push("");
    setPageData(tempPageData)
  }
  const removeData = (key, index) => {
    const tempPageData = {...pageData}
    tempPageData[key]["value"].splice(index, 1);
    setPageData(tempPageData);
  }
  const traceData = (keys, keyIndex, dataName, val, data) => {
    if(keys[keyIndex+1] !== undefined) data[keys[keyIndex]] = traceData(keys, keyIndex+1, dataName, val, data[keys[keyIndex]]);
    else data[keys[keyIndex]][dataName] = val;
    return data;
  }
  const saveData = ({key, index, value}) => {
    const temp = {...pageData};
    temp[key]["value"][index] = value;
    setPageData(temp);
  }
  const updateData = ({keys, dataName, value}) => {
    if(keys && keys.length > 0 ){
      const tempData = traceData(keys, 0, dataName, value, {...pageData});
      setPageData(tempData);
    }
  }
  const errorComponentUpdate = (component, type) => {
    const tempList = [...errorComponentList];
    const index = tempList.indexOf(component);
    if(type === "remove" && index > -1){
      tempList.splice(index, 1);
      setErrorComponentList(tempList);
    } else if(type === "add" && index === -1)
      setErrorComponentList([...tempList, component]);
  }
  const submitPageDataVal = async () => {
    const isSubmit = await submitUserData(pageData, "users/saveUsersWorkshop/");
    if(isSubmit){
      history.push("/profile");
    }
  }
  const goNextPage = () => {
    if(errorComponentList.length > 0)
      setErrorShow(true);
    else 
      submitPageDataVal()
  }
  const goPrevPage = () => {
    handlePageCount(pageCount-1);
  }

  useEffect(() => {
    if((dataDelete.type === "workshopsDeleteConform" || dataDelete.type === "industrialVisitDeleteConform") && dataDelete.index !== null ) {
      removeData(dataDelete.index.key, dataDelete.index.index);
      setDataDelete({ type: "", index: null });
    }
  }, [dataDelete]);

  useEffect(() => {
    if(userData.id) {
      let tempPageData = {...pageData};
      for(const key in tempPageData) {
        if(tempPageData[key]["value"] !== undefined) {
          if(Array.isArray(tempPageData[key])) {
            if(Array.isArray(userData[key])) tempPageData[key]["value"] = [...userData[key]];
            else tempPageData[key]["value"] = [];
          } else tempPageData[key]["value"] = userData[key] ? userData[key] : tempPageData[key]["value"];
        } else if(tempPageData[key] && Array.isArray(tempPageData[key]) && defaultData[key]) {
          const tempArray = [];
          const fromUserData = userData[key] && Array.isArray(userData[key]) ? [...userData[key]] : [];
          if(fromUserData.length > 0 ) {
            fromUserData.map((data, i) => {
              let defaultKeyData = {...defaultData[key]};
              for(const defaultKey in defaultKeyData) {
                defaultKeyData[defaultKey] = {...defaultKeyData[defaultKey], value: fromUserData[i] && fromUserData[i][defaultKey] ? fromUserData[i][defaultKey] : ""};
              }
              tempArray.push(defaultKeyData);
            });
          } else {
            // tempArray.push({...defaultData[key]});
          }
          tempPageData[key] = tempArray;
        } else if(typeof tempPageData[key] === "object") {
          for (const subKey in tempPageData[key]) {
            if(tempPageData[key][subKey]["value"] !== undefined) tempPageData[key][subKey]["value"] = userData[key] && userData[key][subKey] ? userData[key][subKey] : "";
          }
        }
      }
      setPageData(tempPageData);
      setUserId(userData.id);
    }
  },[userData]);
  return (
    <React.Fragment>
      <div className="form-group">
        <h4>Workshops &amp; Industrial visits</h4>
          {/* <h5>{`Workshops attended`}</h5> */}
        {pageData.workshops.value.length > 0 && pageData.workshops.value.map((data, i)=>(
          <div key={`workshops-${i}`} className="flex-box flex-100 flex-gap-2" style={{margin: i === 0 ? "12px 0 12px 0" : "12px 0"}}>
            <div className="flex-4">
              <div className="form-row flex-box flex-100 flex-gap-2">
                {/* {key, index, value} */}
                <EditUserInput type="textArea" value={data} errorShow={errorShow}
                  setValue={(v)=>saveData({key: "workshops", index: i, value: v})}
                  updateError={errorComponentUpdate} validation={{...pageData.workshops.validation, name: `Workshops attended`}} />
              </div>
            </div>
            <div className="flex-1">
            {true && (
              <div>
                <h5></h5>
                <Button variant="text" className={css.buttonDeleteIcon} disableElevation onClick={()=>setDataDelete({type: "workshopsDelete", index: {key: "workshops",index : i}})}>
                  <DeleteOutlineIcon />
                </Button>
              </div>
            )}
            </div>
          </div>
        ))}
      </div>
      <div className={pageData.workshops.value.length === 0 ? "form-group last-0" : "form-group last"}>
      {/* <div className="form-group last"> */}
        <Button variant="outlined" className={css.buttonSmSuccess} disableElevation onClick={()=>addData("workshops")}>
          Add workshop
        </Button>
      </div>
      <div className="form-group">
        <h5>{`Industrial visit`}</h5>
        {pageData.industrialVisit.value.length > 0 && pageData.industrialVisit.value.map((data, i)=>(
          <div key={`industrialVisit-${i}`} className="flex-box flex-100 flex-gap-2" style={{margin: i === 0 ? "8px 0 8px 0" : "12px 0"}}>
            <div className="flex-4">
              <div className="form-row flex-box flex-100 flex-gap-2">
                <EditUserInput type="textArea" value={data} errorShow={errorShow}
                  setValue={(v)=>saveData({key: "industrialVisit", index: i, value: v})}
                  updateError={errorComponentUpdate} validation={{...pageData.industrialVisit.validation, name: `Industrial visit`}} />
              </div>
            </div>
            <div className="flex-1">
            {true && (
              <div>
                <h5></h5>
                <Button variant="text" className={css.buttonDeleteIcon} disableElevation onClick={()=>setDataDelete({type: "industrialVisitDelete", index: {key: "industrialVisit",index : i}})}>
                  <DeleteOutlineIcon />
                </Button>
              </div>
            )}
            </div>
          </div>
        ))}
      </div>
      <div className={pageData.industrialVisit.value.length === 0 ? "form-group last-0" : "form-group last"}>
        <Button variant="outlined" className={css.buttonSmSuccess} disableElevation onClick={()=>addData("industrialVisit")}>
          Add industrial visit
        </Button>
      </div>
      <div className="form-group">
        <div className="flex-box flex-100 flex-gap-2">
          <div className="flex-3">
            <div className="form-row flex-box flex-100 flex-gap-2">
              {pageData.careerObjective && (
                <EditUserInput type="textArea" value={pageData.careerObjective.value} errorShow={errorShow}
                  setValue={(v)=>updateData({keys: ["careerObjective"], dataName:"value", value: v})}
                  updateError={errorComponentUpdate} validation={pageData.careerObjective.validation} />
              )}
            </div>
          </div>
          <div className="flex-1"></div>
        </div>
      </div>
      <div className="form-group fill-width">
        <div className="form-row flex-box flex-100 flex-gap-2 flex-center">
          <Button variant="outlined" className={css.buttonPrimary} disableElevation 
            onClick={goPrevPage}>
            Prev
          </Button>
          <Button variant="contained" className={css.buttonPrimary} disableElevation
            onClick={goNextPage}>
            Save
          </Button>
        </div>
      </div>
      <ConformDelete
        Header={
          dataDelete.type === "workshopsDelete" ? "Delete workshop" : dataDelete.type === "industrialVisitDelete" ? "Delete industrial visit" : ""
        }
        Content={() =>
          dataDelete.type === "workshopsDelete" ? (
            <p style={paraStyle}>
              <div>Are you sure want to delete workshop?</div>
              <div>You cannot recover once deleted</div>
            </p>
          ) : dataDelete.type === "industrialVisitDelete" ? (
            <p style={paraStyle}>
              <div>Are you sure want to delete industrial visit?</div>
              <div>You cannot recover once deleted</div>
            </p>
          ) : (
            ""
          )
        }
        isOpen={dataDelete.type === "workshopsDelete" || dataDelete.type === "industrialVisitDelete"}
        handleClose={() => {
          setDataDelete({
            type: "",
            index: null,
          });
        }}
        handleDelete={() => {
          if (dataDelete.type === "workshopsDelete")
            setDataDelete({
              type: "workshopsDeleteConform",
              index: dataDelete.index,
            });
          if (dataDelete.type === "industrialVisitDelete")
            setDataDelete({
              type: "industrialVisitDeleteConform",
              index: dataDelete.index,
            });
        }}
      />
    </React.Fragment>
  );
};