import { SET_ALL_STUDENTS, SET_ALL_CUSTOMERS } from "./actionTypes";
import api from "../../api/baseConfig";
import ApiHelper from "../../api/apiHelper";

export const setUserStudents = (students, condensed) => {
  return {
    type: SET_ALL_STUDENTS,
    payload: { students, condensed },
  };
};
export const setCustomers = (customers) => {
  return {
    type: SET_ALL_CUSTOMERS,
    payload: customers,
  };
};

export function getCustomers() {
  return async (dispatch) => {
    try {
      const data = await ApiHelper.axiosCall(
        `${api.baseURL}${api.customerManagement.getAll}`,
        "get"
      );

      dispatch(setCustomers(data));
    } catch (err) {}
  };
}

export function getUserStudents(userId) {
  return async (dispatch) => {
    try {
      const data = await ApiHelper.axiosCall(
        `${api.baseURL}${api.userManagement.getStudentsByUser}${userId}`,
        "get"
      );
      let condensed = data.reduce((acc, val) => {
        acc.push({
          label: val.firstName + " " + val.lastName,
          value: val.emailId,
          gender: val.gender || "",
          registrationNo: val.registrationNo || "",
        });
        return acc;
      }, []);
      dispatch(setUserStudents(data, condensed));
    } catch (err) {}
  };
}
