import { useEffect, useState } from "react";
import TabForm1 from "./TabForm1";
import TabForm2 from "./TabForm2";
import TabForm3 from "./tabForm3";
import TabForm4 from "./TabForm4";
import Header from "../components/Header";
import { Link, useParams } from "react-router-dom";
import { useGetCourse } from "../hooks/useCourse";
import MasterAPIs from "pages/utils/MasterAPIs";
import { defaultFormValues } from "../../Prepare/Content";

function hackathonForm({ location }) {
  const [tabValue, setTabValue] = useState(1);
  const [courseId, setCourseId] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [allFormValues, setAllFormValues] = useState(defaultFormValues);

  const goNext = () => setTabValue((pre) => pre + 1);
  const goPrevious = () => setTabValue((pre) => pre - 1);

  const hasId = useParams();

  // const getAllCategories = async () => {
  //   try {
  //     const data = await MasterAPIs.ALL_COURSE_CATEGORIES();
  //     if (data && data.length > 0) {
  //       setAllCategories(data);
  //     }
  //   } catch (error) {}
  // };

  useEffect(() => {
    if (hasId?.id) {
      setCourseId(hasId?.id);
    }
  }, []);

  const courseData = useGetCourse(hasId?.id);

  const setFormValue = (values) =>
    setAllFormValues({
      ...allFormValues,
      [`formValue${tabValue}`]: values,
    });

  const isEdit = location?.state?.fromEdit;
  const customerId = location?.state?.customerId;
 
  return (
    <div className="page-content">    
        <Header tabValue={tabValue} />
      {tabValue === 1 ? (
        <TabForm1
          goNext={goNext}
          setCourseId={setCourseId}
          courseId={courseId}
          courseData={courseData}
          setFormValue1={setFormValue}
          formValue1={allFormValues.formValue1}
          isEdit={isEdit}
          customerId={customerId}
        />
      ) : 
      tabValue === 2 ? (
        <TabForm2
        goNext={goNext}
        goPrevious={goPrevious}
        courseId={courseId}
        courseData={courseData}
        setFormValue2={setFormValue}
        formValue2={allFormValues.formValue2}
        formValue1={allFormValues.formValue1}
        isEdit={isEdit}
        />
      ) :
         tabValue === 3 ? (
        <TabForm3
          goNext={goNext}
          goPrevious={goPrevious}
          courseId={courseId}
          courseData={courseData}
          setFormValue3={setFormValue}
          formValue3={allFormValues.formValue3}
          isEdit={isEdit}
        /> ) : 
        tabValue === 4 ? (
        <TabForm4
          goNext={goNext}
          goPrevious={goPrevious}
          courseId={courseId}
          courseData={courseData}
          setFormValue4={setFormValue}
          formValue4={allFormValues.formValue4}
          isEdit={isEdit}
          customerId={customerId}
        />
      ) : ""
      }
      <Link
        to={{ pathname: "/hackathon", state: { customer: customerId } }}
        className="terv-back"
      >
        <i className="mdi mdi-arrow-left mr-1" /> Back to Hackathon
      </Link>
    </div>
  );
}

export default hackathonForm;