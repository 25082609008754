import React from "react";
import { useSpring, animated, config } from "react-spring";
import styles from "./TabNext.module.scss";
import { uid } from "react-uid";

interface Props {
  tabs: string[];
  tabWidth: number;
  activeTab: number;
  changeTab: (newTab: number) => void;
  color: string;
}

const TabsNext: React.FC<Props> = ({
  tabs,
  tabWidth,
  activeTab,
  changeTab,
  color,
}) => {
  const getNewFrom = () => activeTab * tabWidth;

  const animatedStyles = useSpring({
    from: { x: getNewFrom() },
    to: { x: getNewFrom() - tabWidth },
    config: config.gentle,
  });

  return (
    <div className={styles.tabNextContainer}>
      <div className={styles.tabNextListContainer}>
        {tabs.map((tab: string, ind: number) => (
          <div
            key={uid(ind)}
            style={{
              width: tabWidth,
              color: activeTab === ind + 1 ? color : "#63606C",
              transition: "color 0.3s",
            }}
            className={styles.tabNextItem}
            // onClick={() => goNext()}
          >
            {tab}
          </div>
        ))}
      </div>
      <animated.div
        style={{ ...animatedStyles, width: tabWidth, backgroundColor: color }}
        className={styles.tabNextIndicator}
      />
    </div>
  );
};

export default TabsNext;
