import styles from "./PackageDetailsHeader.module.scss";
import moment from "moment";
import { TervButtonFilled } from "components/TervButtons";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";

const CalendarSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 11H9V13H7V11ZM7 15H9V17H7V15ZM11 11H13V13H11V11ZM11 15H13V17H11V15ZM15 11H17V13H15V11ZM15 15H17V17H15V15Z"
      fill="#63606C"
    />
    <path
      d="M5 22H19C20.103 22 21 21.103 21 20V6C21 4.897 20.103 4 19 4H17V2H15V4H9V2H7V4H5C3.897 4 3 4.897 3 6V20C3 21.103 3.897 22 5 22ZM19 8L19.001 20H5V8H19Z"
      fill="#63606C"
    />
  </svg>
);

const SpreadsheetSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5ZM10 7H18V9H10V7ZM10 11H18V13H10V11ZM10 15H18V17H10V15ZM6 7H8V9H6V7ZM6 11H8V13H6V11ZM6 15H8V17H6V15Z"
      fill="#63606C"
    />
  </svg>
);

const PencilSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.707 19.707L18 10.414L13.586 6L4.293 15.293C4.16506 15.4211 4.07418 15.5814 4.03 15.757L3 21L8.242 19.97C8.418 19.926 8.579 19.835 8.707 19.707ZM21 7.414C21.3749 7.03895 21.5856 6.53033 21.5856 6C21.5856 5.46967 21.3749 4.96106 21 4.586L19.414 3C19.0389 2.62506 18.5303 2.41443 18 2.41443C17.4697 2.41443 16.9611 2.62506 16.586 3L15 4.586L19.414 9L21 7.414Z"
      fill="#63606C"
    />
  </svg>
);

const PackageDetailsHeader = ({
  packageData,
  cartButton,
  progressBox,
  triggerSignIn,
  toggleSignIn,
}) => {
  const smallScreen = useMediaQuery("(max-width: 767px)");

  return (
    <div className={smallScreen ? styles.mainMobile : `${styles.main} packageHeader`}>
      <div className={styles.boxOne}>
        <h4>{packageData.name}</h4>
        {packageData?.description && (
          <p dangerouslySetInnerHTML={{ __html: packageData.description }} />
        )}
        <div
          className={
            smallScreen ? styles.iconsWrapperMobile : styles.iconsWrapper
          }
        >
          {packageData?.startDate || packageData?.endDate ? (
            <div>
              <div className={styles.iconBox}>
                <CalendarSVG />
              </div>{" "}
              <div className={styles.descBox}>
                {moment(
                  packageData?.startDate.replace("+00:00", "+05:30")
                ).format("ll")}
                &nbsp;-&nbsp;
                {moment(
                  packageData?.endDate.replace("+00:00", "+05:30")
                ).format("ll")}
              </div>
            </div>
          ) : (
            ""
          )}
          <div>
            <div className={styles.iconBox}>
              <SpreadsheetSVG />
            </div>
            <div className={styles.descBox}>
            &nbsp;&nbsp;{packageData?.questionCount || 0}{" "}
            {packageData?.questionCount === 1 ? "question" : "questions"}            
          </div>            
          </div>
          {packageData?.mockAssesments && (
            <div>
              <div className={styles.iconBox}>
                <PencilSVG />
              </div>
              <div className={styles.descBox}>
              &nbsp;&nbsp;{packageData?.mockAssesments?.length || 0}{" "}
              {packageData?.mockAssesments?.length === 1 ? "mock test" : "mock tests"}                
              </div>
            </div>
          )}
        </div>
      </div>
      {packageData?.paidPackage && !packageData?.purchased ? (
        <div className={smallScreen ? "pt-5" : styles.buyContainer}>
          <div className={styles.priceBox}>
            <span className={styles.strikedPrice}>
              ₹ {packageData?.strikingPrice} INR
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span className={styles.originalPrice}>
              ₹ {packageData?.originalPrice} INR
            </span>
            &nbsp;
            <span className={styles.savePercent}>
              Save ({parseFloat(packageData?.discountAmount).toFixed(0)}%)
            </span>
          </div>
          <div className={styles.buttonBox}>
            <Link
              to={
                !triggerSignIn && {
                  pathname: "/checkout",
                  params: { items: [packageData?.id] },
                }
              }
              onClick={triggerSignIn && toggleSignIn}
            >
              <TervButtonFilled text="Buy now" width="12.8125rem" />
            </Link>
            {cartButton}
          </div>
        </div>
      ) : (
        <div className={smallScreen ? styles.boxTwoMobile : styles.boxTwo}>
          {progressBox}
        </div>
      )}
    </div>
  );
};

export default PackageDetailsHeader;
