import { useCallback, useState, useEffect, Component } from "react";
import uuid from "react-uuid";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Row, Col, Label, Button } from "reactstrap";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";
import { connect } from "react-redux";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import DirectionsIcon from "@material-ui/icons/Directions";
import {
  FormControlLabel,
  Grid,
  Dialog,
  Collapse,
  Typography,
  Switch,
  DialogContent,
  useMediaQuery,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { InputField } from "../QuestionWizard/InputField";
import * as CustomIcons from "../../assets/icons/icons.js";
import { toast } from "react-toastify";
import { SelectQuestionsModal } from "./SelectQuestionsModal/SelectQuestionsModalPP";
import { Link } from "react-router-dom";
import PackageDetails from "./PackageDetails";
import { AddImageComponent } from "components/AddImageComponent";
import { TrimString } from "pages/utils/TrimString";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import AdvancedStudentSelection from "pages/Tasks/Components/AdvancedStudentSelection";
import apiHelper from "../../api/apiHelper";
import { LearningPath } from "./LearningPath";
import styles from "./LearningPath.module.scss";
import moment from "moment";
import { sessionPrivileges } from "privileges";
import {
  Badge,
  Paper,
  CircularProgress,
} from "@material-ui/core";

const questionTypes = [
  { value: "MC", label: "Multi Choice" },
  { value: "CD", label: "Coding" },
  { value: "all", label: "All" },
];

function getNumber(str) {
  return parseInt(str?.toString().replace(/ /g, "")) || "";
}

function isNumeric(str) {
  if (typeof str != "string") return false;

  return str === "" ? "" : !isNaN(str) && !isNaN(parseFloat(str));
}

const customStyles = {
  menu: (styles) => ({ ...styles, zIndex: 9999 }),
};

function AssessmentsDropdown({
  mockAssessmentsData,
  value,
  onChange,
  Priv,
  customerId,
}) {
  const [assessments, setAssessments] = useState([]);

  const getAssessments = useCallback(async () => {
    if (!assessments.length) {
      await Apihelper.axiosCall(
        `${api.baseURL}${api.assessmentController.getMockAssessmentListNew}?customerId=${customerId}`,
        "get"
      ).then((data) => {
        let options = [];
        data?.forEach?.((assessment) => {
          options.push({
            label: assessment?.name,
            value: assessment?.id,
          });
        });
        setAssessments(options);
      });
    }
  }, [customerId]);

  useEffect(() => {
    if (customerId) {
      getAssessments();
    }
  }, [customerId]);
  return (
    <Select
      options={
        Priv.includes("MANAGE_CUSTOMER") ? mockAssessmentsData : assessments
      }
      isMulti
      styles={customStyles}
      value={value}
      onChange={onChange}
      placeholder="Choose mock assessments"
    />
  );
}
function CertificateTemplateDropdown({ value, onChange, customerId }) {
  const [certificateTemplateList, setcertificateTemplateList] = useState([]);

  const getcertificateTemplateList = useCallback(async () => {
    await Apihelper.axiosCall(
      `${api.baseURL}${api.lab.getCertificateTemplateList}${customerId}`,
      "get"
    ).then((data) => {
      let options = [];

      data?.forEach?.((certificateTemplate) => {
        options.push({
          label: certificateTemplate?.name,
          value: certificateTemplate?.id,
        });
      });
      setcertificateTemplateList(options);
    });
  }, [customerId]);

  useEffect(() => {
    if (customerId) {
      getcertificateTemplateList();
    }
  }, [customerId]);

  return (
    <Select
      options={certificateTemplateList}
      value={GetObjectFromString(certificateTemplateList, value)}
      onChange={onChange}
      placeholder="Choose certificate template"
    />
  );
}
class Managelab extends Component {
  constructor(props) {
    super(props);
    this.userPrivileges = sessionPrivileges(true);
    this.userEmail = this.userPrivileges.userName;
    this.Priv = this.userPrivileges.userprivileges || [];
    this.state = {
      existingName: "",
      descValue: false,
      assignStudents: false,
      mockAssessmentsData: [],
      learningPathPrompt: null,
      learningPathPromptCb: () => {},
      learningPath: {
        open: false,
        categoryHashMap: {},
        practiceData: {},
        testData: [],
        totalCategory: {},
      },
      learnPathApi: false,
      pathNewList: [],
      questionIdsUpadte: [],
      categoriesNewId: [],
      isEdit: false,
      customCheckEnable: false,
      newQuestionList: [],
      validationTopics: false,
      description: "",
      packageData: {
        name: "",
        paidPackage: false,
        showAtHomePage: false,
        typePublic: false,
        learnPath: false,
        learningPath: [],
        originalPrice: "",
        strikingPrice: "",
        discountAmount: "",
        coupons: [],
        // description: "",
        startDate: "",
        endDate: "",
        type: "",
        tags: "",
        languagesList: [],
        id: {},
        collegeId: this.props?.location?.state?.customer ? this.props?.location?.state?.customer : "",
        userIdList: [],
        filterType: "",
        department: "",
        mockAssessments: [],
        batchName: "",
        regNoTo: "",
        regNoFrom: "",
        questionIdListSets: [],
        isFeatured: false,
        showHint: false,
        showSolution: false,
        showTestCase: false,
        showWorkspace: false,
        media: "",
        previewQuestions: [],
        certificateTemplateId: null,
        hasCertificate: false,
        categories: [],
      },
      toast: false,
      removedQuestions: {
        MC: 0,
        CD: 0,
        DESC: 0,
      },
      canSubmit: false,
      errorHandling: false,
      toRemove: [],
      existingQuestions: [],
      modal: false,
      students: [],
      listQB: [],
      localBanks: {},
      errors: {
        _packError: "",
        _QuesErr: "",
        _packDescError: "",
        _startDateError: "",
        _endDateError: "",
      },
      userId: [],
      currBank: "",
      loading: true,
      error: false,
      hasMore: false,
      currBankQues: [],
      currBankQuesTotalCount: "",
      studentData: "",
      customer: this.props?.location?.state?.customer,
      activePage: this.props?.location?.state?.activePage,
      activeTab: this.props?.location?.state?.activeTab,
      customerName: this.props?.location?.state?.customerName,
      currentId: this.props?.location?.state?.id || "",
      fromPrepare: this.props?.location?.state?.fromPrepare || false,
      prepareId: this.props?.location?.state?.courseId || "",
      selectedUserList: this.props?.location?.state?.selectedUserList || [],
      sDate: this.props?.location?.state?.sDate || "",
      eDate: this.props?.location?.state?.eDate || "",
      selSec: this.props?.location?.state?.selSec || "",
      sectionValues: this.props?.location?.state?.sectionValues || [],
      currentLesson: this.props?.location?.state?.currentLesson || {},
    };
  }

  async getLearningPathData(questionIds = []) {
    let categories = [];
    await apiHelper
      .axiosCallLCpost(
        `${api.baseURL}${api.practicePackage.getLearningPathData}`,
        "POST",
        {
          questionIds,
        }
      )
      .then((data) => {
        categories = data;
        this.setState({ categoriesNewId: categories });
      })
      .catch((e) => {});
    if (categories?.length > 0) {
      return categories;
    }
  }

  async getAssessments(customerId, defaultMockAssessments = []) {
    let mockAssessmentData = [];

    await Apihelper.axiosCall(
      `${api.baseURL}${api.assessmentController.getMockAssessmentListNew}?customerId=${customerId}`,
      "get"
    ).then((data) => {
      let options = [];
      data?.forEach?.((assessment) => {
        options.push({
          label: assessment?.name,
          value: assessment?.id,
        });
      });
      this.setState({
        mockAssessmentsData: options,
        ...(defaultMockAssessments?.length && {
          packageData: {
            ...this.state.packageData,
            mockAssessments: options.filter(({ value }) =>
              defaultMockAssessments.find((id) => value === id)
            ),
          },
        }),
      });

      mockAssessmentData = options;
    });

    return mockAssessmentData;
  }

  async showPrompt(learningPathPrompt, cb) {
    this.setState({ learningPathPrompt, learningPathPromptCb: cb });
  }
  async updateQuestions(newQuestionIds = []) {
    let learningPath = [...(this.state.packageData.learningPath || [])];
    let newCategories = await this.getLearningPathData(newQuestionIds || []);
    const categoryHashMap = {};
    const totalPracticeData = {};
    const totalCategory = {};
    // const newCategoriesValue = this.state.categoriesNewId || []
    (newCategories || []).forEach(({ category, subCategory }) => {
      const categoryData = Object.entries(category)[0];
      const categoryId = categoryData[0];
      const categoryName = categoryData[1];

      categoryHashMap[categoryId] = categoryName;
      totalCategory[categoryId] = categoryName;
      totalPracticeData[categoryId] = [];

      Object.entries(subCategory).forEach((sc) => {
        categoryHashMap[sc[0]] = sc[1];
        // totalCategory[sc]
        totalPracticeData[categoryId].push(sc[0]);
      });
    });

    learningPath = learningPath
      .map((item) => {
        if (item.type === "Practice") {
          const testPathItem = { ...item };

          testPathItem.subCategory = (testPathItem.subCategory || []).filter(
            (sc) =>
              Object.entries(totalPracticeData || {}).find((value) =>
                (value?.[1] || []).includes(sc)
              )
          );

          return testPathItem;
        } else {
          return item;
        }
      })
      .filter((item) =>
        item.type === "Practice" ? item.subCategory?.length : true
      );

    const practiceData = JSON.parse(JSON.stringify(totalPracticeData));
    learningPath.forEach((lp) => {
      if (lp.type === "Practice") {
        practiceData[lp.category] = practiceData[lp.category]?.filter(
          (sb) => !lp.subCategory.includes(sb)
        );
      }
    });
    this.setState({
      learningPathPrompt: null,
      learningPath: {
        ...this.state.learningPath,
        open: false,
        categoryHashMap,
        totalCategory,
        practiceData,
      },
      questionIdsUpadte: newQuestionIds,
      questionIdExist: newQuestionIds,
      packageData: {
        ...this.state.packageData,
        ...(this.state.packageData.learnPath && {
          learningPath,
        }),
        questionIdListSets: newQuestionIds,
      },
    });
  }

  updateMockAssessments(newMockAssessments = []) {
    let learningPath = [...(this.state.packageData.learningPath || [])];
    learningPath = learningPath
      .map((item) => {
        if (item.type === "Mock Assessment") {
          const testPathItem = { ...item };
          testPathItem.mockAssessments = (
            testPathItem.mockAssessments || []
          ).filter((ma) =>
            (newMockAssessments || []).find(({ value }) => ma.value === value)
          );
          return testPathItem;
        } else {
          return item;
        }
      })
      .filter((item) =>
        item.type === "Mock Assessment" ? item.mockAssessments?.length : true
      );

    const testData = (newMockAssessments || []).filter(({ value }) => {
      return !learningPath.find(
        ({ type, mockAssessments }) =>
          type === "Mock Assessment" &&
          mockAssessments.find(({ value: v }) => v === value)
      );
    });
    this.setState({
      learningPath: {
        ...this.state.learningPath,
        open: false,
        testData,
        newMockAssessments,
      },
      packageData: {
        ...this.state.packageData,
        ...(this.state.packageData.learnPath && {
          learningPath,
        }),
        mockAssessments: newMockAssessments,
      },
    });
  }

  setPrepareValues = () => {
    const sDate = this.props?.location?.state?.sDate;
    const eDate = this.props?.location?.state?.eDate;
    this.setState({
      packageData: {
        ...this.state.packageData,
        startDate: sDate,
        endDate: eDate,
        userIdList: this.props?.location?.state?.selectedUserList,
      },
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAllQuestionBanks();
    if (this.props?.location?.state?.fromPrepare) {
      this.setState({ canSubmit: true });
      setTimeout(() => {
        this.setPrepareValues();
      }, 2000);
    }
    if (this.props?.location?.state?.fromEdittrue) {
      try {
        Apihelper.axiosCall(
          `${api.baseURL}${api.lab.getLab}${this.props.location.state.id}`,
          "get"
        ).then(async (data) => {
          this.setState({existingName: data.name})
          if (
            data.filterType &&
            data.userIdList &&
            data.userIdList.length > 0
          ) {
            this.setState({ canSubmit: true });
          }
          const mockAssessmentsData = await this.getAssessments(
            data.collegeId,
            [...(data.mockAssesments || [])]
          );
          const defaultCategories = await this.getLearningPathData([
            ...(data.questionsList.map(({ id }) => id) || []),
          ]);
          this.setState({
            learnPathApi: data.learnPath,
          });
          const categoryHashMap = {};
          const totalPracticeData = {};
          const totalCategory = {};
          (defaultCategories || []).forEach(({ category, subCategory }) => {
            const categoryData = Object.entries(category)[0];
            const categoryId = categoryData[0];
            const categoryName = categoryData[1];
            const subCategoryData = Object.entries(subCategory)[0] || [];
            const subCategoryId = subCategoryData[0];
            const subCategoryName = subCategoryData[1];
            if (!data.learnPath) {
              this.setState({
                learningPath: {
                  open: false,
                  practiceData: {
                    ...this.state?.learningPath?.practiceData,
                    [categoryId]: [subCategoryId],
                  },
                  categoryHashMap,
                  totalCategory,
                },
              });
            }
            categoryHashMap[categoryId] = categoryName;
            totalCategory[categoryId] = categoryName;
            totalPracticeData[categoryId] = [];
            Object.entries(subCategory).forEach((sc) => {
              categoryHashMap[sc[0]] = sc[1];
              // totalCategory[sc];
              totalPracticeData[categoryId].push(sc[0]);
            });
          });
          this.setState({ isEdit: true });
          if (data.learnPath) {
            this.setState({
              learningPath: {
                open: false,
                practiceData: defaultCategories,
                categoryHashMap,
                totalCategory,
              },
            });
          }
          this.setState({
            existingQuestions:
              data.questionsList && data.questionsList.length > 0
                ? data.questionsList.reduce((acc, val) => {
                    acc.push({ ...val, remChecked: false });
                    return acc;
                  }, [])
                : [],
            questionIdExist: data.questionIdListValue,
            packageData: {
              ...this.state.packageData,
              learnPath: data.learnPath,
              learningPath: data?.learningPath?.map?.((path) => ({
                name: path.name,
                id: uuid(),
                ...(path.type === "Practice" && {
                  type: "Practice",
                  category: Object.entries(path.category)[0][0],
                  subCategory: Object.entries(path.category)[0][1],
                }),
                ...(path.type === "Mock Assessment" && {
                  type: "Mock Assessment",
                  mockAssessments: path.mockAssesments?.map?.((value) =>
                    mockAssessmentsData.find(({ value: val }) => value === val)
                  ),
                }),
              })),
              paidPackage: data.paidPackage,
              showAtHomePage: !!data?.showAtHomePage,
              typePublic: data?.typePublic,
              originalPrice: data.originalPrice,
              strikingPrice: data.strikingPrice,
              discountAmount: data.discountAmount,
              coupons: data.coupons,
              media: data.media,
              name: data.name,
              department: data?.department || "",
              batchName: data?.batchName || "",
              collegeId: data.collegeId || "",
              userIdList: data.userIdList,
              description: data.description,
              startDate: data.startDate ? data.startDate.slice(0, 10) : "",
              endDate: data.endDate ? data.endDate.slice(0, 10) : "",
              id: this.props.location.state.id,
              type: data.type || "all",
              tags: data.tags || "",
              languagesList:
                data?.languagesList && data.languagesList.length > 0
                  ? data.languagesList.reduce((acc, val) => {
                      acc.push({ value: val.id, label: val.optionValue });
                      return acc;
                    }, [])
                  : [],
              filterType: data.filterType,
              showHint: data.showHint,
              showSolution: data.showSolution,
              showTestCase: data.showTestCase,
              showWorkspace: data.showWorkspace,
              previewQuestions: data?.previewQuestionsValue || [],
              certificateTemplateId: data.certificateTemplateId,
              hasCertificate: data.hasCertificate,
            },
          });

          // var testData = this.state.mockAssessmentsData.filter((e) => e.value == data.mockAssesments[1]).map((e) => {
          //     return e
          // })
          // if(!data.learnPath){
          var testDataCheck = [];
          for (let i = 0; i < data.mockAssesments.length; i++) {
            for (let a = 0; a < this.state.mockAssessmentsData.length; a++) {
              if (
                data.mockAssesments[i] ==
                this.state.mockAssessmentsData[a].value
              ) {
                testDataCheck.push(this.state.mockAssessmentsData[a]);
              }
            }
          }
          this.setState({
            learningPath: {
              ...this.state.learningPath,
              open: false,
              testData: testDataCheck,
            },
          });
          // }
        });
      } catch (e) {}
    } else {
      if (this.props?.location?.state?.customer) {
        this.getAssessments(this.props.location.state.customer);
        this.setState({
          packageData: {
            ...this.state.packageData,
            collegeId: this.props.location.state.customer,
          },
        });
      }
    }
  }

  removeHTML = (str) => {
    const regex = /(<([^>]+)>)/gi;
    return str?.replace(regex, "");
  };

  validate = () => {
    let value = true;

    let _packError = "";
    let _QuesErr = "";
    let _packDescError = "";
    let _startDateError = "";
    let _endDateError = "";
    let _certificateError = "";
    let _validationTopicsError = "";

    if (this.state.packageData.paidPackage) {
      if (
        !this.state.packageData.originalPrice ||
        this.state.packageData.originalPrice <
          this.state.packageData.strikingPrice ||
        this.state.packageData.discountAmount > 100
      ) {
        value = false;
      }
    }

    if (!this.state.packageData.name || this.state.packageData.name.trim().length === 0) {
      _packError = "Please enter a valid Package name";
      window.scrollTo(0, 50);
    } 

    if (this.state.packageData.name == this.checkIfNameExists(this.state.packageData.name.trim(), this.state.customer)) {
      _packError = "This name already exists, please enter a different name";
    }
    if (
      !this.state.packageData?.questionIdListSets?.length &&
      !this.state.existingQuestions?.length
    ) {
      _QuesErr = "Please add Questions";
    }
    if (
      this.state.questionIdsUpadte?.length > 0
        ? !this.state.questionIdsUpadte?.length
        : !this.state.questionIdExist?.length
    ) {
      _QuesErr = "Please add Questions";
      window.scrollTo(0, 700);
    }

    var html = this.state.packageData?.description;
    var descc = this.removeHTML(html);

    if (this.state?.description?.length < 1 && this.state.isEdit == false) {
      _packDescError = "Please enter a description";
      window.scrollTo(0, 50);
    }

    if (this.state?.description?.length < 1 && this.state.isEdit == true && this.state.descValue) {
      _packDescError = "Please enter a description";
      window.scrollTo(0, 50);
    }

    if (this.state?.packageData?.startDate?.length == 0) {
      _startDateError = "Please enter start date";
      window.scrollTo(0, 50);
    }
    if (this.state?.packageData?.endDate?.length == 0) {
      _endDateError = "Please enter end date";
      window.scrollTo(0, 50);
    }
    if (
      moment(
        this.state?.packageData?.startDate && this.state.isEdit == "false"
      ).diff(moment(), "days") < 0
    ) {
      _startDateError = "Start Date cannot be a past day or time";
    }

    if (
      moment(
        this.state.packageData?.endDate
      ).diff(moment(this.state?.packageData?.startDate), "days") < 0 
    ) {
      _endDateError = "End Date cannot be before the Start Date";
    }
    if (
      moment(
        this.state.packageData?.endDate && this.state.isEdit == "false"
      ).diff(moment(), "days") < 0 
    ) {
      _endDateError = "End Date cannot be a past day or time";
    }
    if (
      !this.state.packageData?.hasCertificate &&
      this.state.packageData?.certificateTemplateId != null
    ) {
      this.setState({
        packageData: {
          ...this.state?.packageData,
          certificateTemplateId: null,
        },
      });
    }
    if (
      this.state.packageData?.hasCertificate &&
      this.state.packageData?.certificateTemplateId == null
    ) {
      _certificateError = "Please select a certificate template";
    }
    if (this.state.packageData.categories && this.state.packageData.learnPath) {
      _validationTopicsError = "Topic missing in Some questions";
    }

    if (!this.state.fromPrepare) {
      if (
        _packError ||
        _QuesErr ||
        _packDescError ||
        _startDateError ||
        _endDateError ||
        _certificateError ||
        _validationTopicsError
      ) {
        this.setState({
          errors: {
            _packError,
            _QuesErr,
            _packDescError,
            _startDateError,
            _endDateError,
            _certificateError,
            _validationTopicsError,
          },
        });
        value = false;
      }
    }

    if (this.state.fromPrepare) {
      if (_packError || _QuesErr || _packDescError) {
        this.setState({
          errors: {
            _packError,
            _QuesErr,
            _packDescError,
          },
        });
        value = false;
      }
    }
    return value;
  };

  checkIfNameExists = async (name, customerId) => {	
    let boo = false;	
    // this.state.packageData.name != this.state.existingName;
    const encodedName = window.btoa(name);
    try {	
      const res = await Apihelper.axiosCall(	
        `${api.baseURL}${api.lab.checkIfNameExists}${customerId}/${encodedName}`,	
        "get"	
      );	
      if (res && this.state.packageData.name !== this.state.existingName) {	
        this.setState({	
          errors: {	
            ...this.state.errors,	
            _packError:	
              "This name already exists, please enter a different name",	
          },	
        });	
        boo = res;	
      }	
    } catch (e) {}	
    return boo;	
  }; 

  onSubmitValue = async () => {
    const nameValid = await this.checkIfNameExists(this.state.packageData.name, this.state.customer);
    this.setState({ errorHandling: true });
    if (this.validate() && nameValid !== true) {
      let result = {};
      const questionPayload =
        this.state.questionIdsUpadte.length > 0
          ? this.state.questionIdsUpadte
          : this.state.questionIdExist;
      try {
        let data = {
          ...this.state.packageData,
          questionIdList: questionPayload,
          paidPackage: this.state.packageData.paidPackage,
          originalPrice: parseInt(this.state.packageData.originalPrice || 0),
          strikingPrice: parseInt(this.state.packageData.strikingPrice || 0),
          discountAmount: parseInt(this.state.packageData.discountAmount || 0),
          coupons: this.state.packageData.coupons || [],
          customCheckEnable: this.state.customCheckEnable,
          isActive: true,
          createdBy: this.userEmail,
          updatedBy: this.userEmail,
          description: this.state.descValue ? this.state.description : this.state.packageData.description,
          createdInPrepare: this.state.fromPrepare ? true : false,
          mockAssesments:
            this.state?.packageData?.mockAssessments?.map?.(
              ({ value }) => value
            ) || [],
          externalResourceId: this.state.fromPrepare
            ? this.state.prepareId
            : "",
          classId: "",
          ...this.state.studentData,
          languagesList: this.state.packageData.languagesList.map((val) => ({
            id: val.value,
            optionValue: val.label,
          })),
          learningPath: this.state.packageData.learnPath
            ? this.state.packageData.learningPath
            : [],
        };

        if (!data.paidPackage && data.showAtHomePage) {
          data.showAtHomePage = false;
        }

        let oldQs = this.state.existingQuestions.map((q) => q.id);
        data["questionIdListSets"] = [
          ...this.state.packageData.questionIdListSets,
          ...oldQs,
        ];
        if (data["questionIdListSets"].length > 0) {
          data["questionIdListSets"] = data["questionIdListSets"].reduce(
            (acc, val) => {
              if (!acc.includes(val) && !this.state.toRemove.includes(val)) {
                acc.push(val);
              }
              return acc;
            },
            []
          );
        }
        if (
          data.filterType === "all" ||
          data.filterType === "except" ||
          data.filterType === "only"
        ) {
          delete data.batchName;
          delete data.department;
          delete data.regNoTo;
          delete data.regNoFrom;
        } else {
          data.filterType = data.batchName;
        }
        if (this.state.packageData.learnPath) {
          data.learnPath = true;
          data.learningPath = (this.state.packageData.learningPath || []).map(
            ({ name, type, category, subCategory, mockAssessments }, idx) => ({
              ...(type === "Practice"
                ? {
                    category: {
                      [category]: subCategory,
                    },
                  }
                : {
                    mockAssesments: mockAssessments?.map?.(
                      ({ value }) => value
                    ),
                  }),
              name,
              pathOrder: idx + 1,
              type,
            })
          );
        } else {
          data.learnPath = false;
        }

        await Apihelper.axiosCallPost(
          `${api.baseURL}${api.lab.saveLab}`,
          "post",
          data
        )
          .then((data) => {
            result = data;
          })
          .catch((e) => {});
        if (!this.state.fromPrepare) {
          this.props.history.push({
            pathname: "/manage-allLabs",
            state: { customer: this.state.packageData.collegeId },
          });
          if(this.props?.location?.state?.fromEdittrue ){
            toast.success("Package updated successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            })
            } else {
              toast.success("Successfully Package Created", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: false,
              })
            }
        }
        if (this.state.fromPrepare) {
          let lesson = this.props?.location?.state?.currentLesson;
          lesson.externalResourceName = result.name;
          lesson.externalResourceId = result.id;
          lesson.externalResource_isCreatedInPrepare = result.createdInPrepare;
          this.props.history.push({
            // pathname: `/manage-prepare/${this.state.prepareId}`,
            pathname: `/manage-prepare/${this.props?.location?.state?.courseId}`,
            state: {
              customerId: this.state.customer,
              activeTab: 2,
              currentStep: "editLesson",
              //prepPracticeId: result?.id,
              // prepPracticeRes: result,
              // selSec: this.props?.location?.state?.selSec,
              sectionValues: this.state.sectionValues,
              createdFromPrepare: true,
              currentLesson: lesson,
              // contentName: this.props?.location?.state?.contentName,
              // completionCriteria: this.props?.location?.state?.completionCriteria,
              currentSection: this.props?.location?.state?.currentSection,
            },
          });
        }
      } catch (e) {}
    }
  };

  onSubmit = async () => {
    const nameValid = await this.checkIfNameExists(this.state.packageData.name, this.state.customer);
    this.setState({ errorHandling: true });
    if (this.validate() && this.state.canSubmit && nameValid !== true) {
      let result = {};
      const questionPayload =
        this.state.questionIdsUpadte.length > 0
          ? this.state.questionIdsUpadte
          : this.state.questionIdExist;
      try {
        let data = {
          ...this.state.packageData,
          questionIdList: questionPayload,
          paidPackage: this.state.packageData.paidPackage,
          originalPrice: parseInt(this.state.packageData.originalPrice || 0),
          strikingPrice: parseInt(this.state.packageData.strikingPrice || 0),
          discountAmount: parseInt(this.state.packageData.discountAmount || 0),
          coupons: this.state.packageData.coupons || [],
          customCheckEnable: this.state.customCheckEnable,
          isActive: true,
          createdBy: this.userEmail,
          updatedBy: this.userEmail,
          description: this.state.description,
          createdInPrepare: this.state.fromPrepare ? true : false,
          mockAssesments:
            this.state?.packageData?.mockAssessments?.map?.(
              ({ value }) => value
            ) || [],
          externalResourceId: this.state.fromPrepare
            ? this.state.prepareId
            : "",
          classId: "",
          ...this.state.studentData,
          languagesList: this.state.packageData.languagesList.map((val) => ({
            id: val.value,
            optionValue: val.label,
          })),
          learningPath: this.state.packageData.learnPath
            ? this.state.packageData.learningPath
            : [],
        };

        if (!data.paidPackage && data.showAtHomePage) {
          data.showAtHomePage = false;
        }

        let oldQs = this.state.existingQuestions.map((q) => q.id);
        data["questionIdListSets"] = [
          ...this.state.packageData.questionIdListSets,
          ...oldQs,
        ];
        if (data["questionIdListSets"].length > 0) {
          data["questionIdListSets"] = data["questionIdListSets"].reduce(
            (acc, val) => {
              if (!acc.includes(val) && !this.state.toRemove.includes(val)) {
                acc.push(val);
              }
              return acc;
            },
            []
          );
        }
        if (
          data.filterType === "all" ||
          data.filterType === "except" ||
          data.filterType === "only"
        ) {
          delete data.batchName;
          delete data.department;
          delete data.regNoTo;
          delete data.regNoFrom;
        } else {
          data.filterType = data.batchName;
        }
        if (this.state.packageData.learnPath) {
          data.learnPath = true;
          data.learningPath = (this.state.packageData.learningPath || []).map(
            ({ name, type, category, subCategory, mockAssessments }, idx) => ({
              ...(type === "Practice"
                ? {
                    category: {
                      [category]: subCategory,
                    },
                  }
                : {
                    mockAssesments: mockAssessments?.map?.(
                      ({ value }) => value
                    ),
                  }),
              name,
              pathOrder: idx + 1,
              type,
            })
          );
        } else {
          data.learnPath = false;
        }

        await Apihelper.axiosCallPost(
          `${api.baseURL}${api.lab.saveLab}`,
          "post",
          data
        )
          .then((data) => {
            result = data;
          })
          .catch((e) => {});
        if (!this.state.fromPrepare) {
          this.props.history.push({
            pathname: "/manage-allLabs",
            state: { customer: this.state.packageData.collegeId },
          });
          if(this.props?.location?.state?.fromEdittrue ){
            toast.success("Package updated successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            })
            } else {
              toast.success("Successfully Package Created", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: false,
              })
            }
        }
        if (this.state.fromPrepare) {
          let lesson = this.props?.location?.state?.currentLesson;
          lesson.externalResourceName = result.name;
          lesson.externalResourceId = result.id;
          lesson.externalResource_isCreatedInPrepare = result.createdInPrepare;
          this.props.history.push({
            // pathname: `/manage-prepare/${this.state.prepareId}`,
            pathname: `/manage-prepare/${this.props?.location?.state?.courseId}`,
            state: {
              customerId: this.state.customer,
              activeTab: 2,
              currentStep: "editLesson",
              //prepPracticeId: result?.id,
              // prepPracticeRes: result,
              // selSec: this.props?.location?.state?.selSec,
              sectionValues: this.state.sectionValues,
              createdFromPrepare: true,
              currentLesson: lesson,
              // contentName: this.props?.location?.state?.contentName,
              // completionCriteria: this.props?.location?.state?.completionCriteria,
              currentSection: this.props?.location?.state?.currentSection,
            },
          });
        }
      } catch (e) {}
    }
  };

  getAllQuestionBanks = async () => {
    const url = this.Priv.includes("MANAGE_CUSTOMER")
      ? `${api.baseURL}${api.questionBankController.ApproveBank}`
      : `${api.baseURL}${api.questionBankController.ApproveBank}`;
    await Apihelper.axiosCall(TrimString(url), "get").then((datum) => {
      const allFilterTypes = [{ label: "All question bank", value: "all" }];
      let tempList = allFilterTypes.filter((item) => item.value === "all");
      if (datum) {
        datum.forEach((i) => {
          if (i.id !== this.state.quesBankId) {
            tempList.push({ value: i.id, label: i.name });
          }
        });
      }
      this.setState({ listQB: tempList });
    });
  };

  setCurrQuestionBank = async (
    currI,
    idx,
    query,
    filterTopics,
    filterCategorys,
    filterTypes,
    filterComplexitys
  ) => {
    this.setState({ loading: true, error: false });
    let userId = sessionStorage.getItem("user_id"); //sessionStorage.getItem("applicable_authors")
    const payLoadParams = {
      search: currI === "all" ? query : "",
      qpId: currI,
      limit:
        currI === "all"
          ? query
            ? ""
            : filterTopics
            ? ""
            : filterCategorys
            ? ""
            : filterTypes
            ? ""
            : filterComplexitys
            ? ""
            : 10
          : "",
      pageIndex: currI === "all" ? idx : "",
      categoryIds: filterCategorys ? filterCategorys : [],
      topicIds: filterTopics ? filterTopics : [],
      types: filterTypes ? filterTypes : [],
      complexity: filterComplexitys ? filterComplexitys : [],
    };
    await Apihelper.getQuestionBankQues(
      `${api.baseURL}${
        api.questionBankController.getQBQuestions
      }${userId}`,
      "post",
      payLoadParams
    )
      .then((datum) => {
        let tempDatum = [];
        datum.questionList.forEach((j) => {
          j.remChecked = false;
          tempDatum.push(j);
        });
        // this.setState({
        //   currBankQues: [...this.state.currBankQues, ...tempDatum],
        // });
        this.setState({
          currBankQues: tempDatum,
        });
        this.setState({
          currBankQuesTotalCount: datum.totalCount,
        });
        this.setState({
          hasMore: tempDatum.length > 0,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ error: true });
      });
  };

  validIndicator = (value) => (
    <>
      {value}
      <span style={{ color: "red" }}>*</span>
    </>
  );

  removeDiffTypeQuestions = () => {
    const banks = this.state.localBanks;
    let newList = [...this.state.packageData.questionIdListSets];
    let oldList = [...this.state.existingQuestions];
    let removedQuestions = {
      CD: 0,
      MC: 0,
      DESC: 0,
    };
    for (const key in banks) {
      banks[key].forEach((question) => {
        if (question.type !== this.state.packageData.type) {
          if (this.state.packageData.questionIdListSets.includes(question.id)) {
            newList.splice(newList.indexOf(question.id), 1);
            removedQuestions[question.type] += 1;
          } else if (oldList.find((ex) => ex.id === question.id)) {
            oldList.forEach((item, index) => {
              if (item.id === question.id) {
                oldList.splice(index, 1);
              }
            });
          }
        }
      });
    }
    const condition1 =
      newList.length < this.state.packageData.questionIdListSets.length;
    const condition2 = oldList.length < this.state.existingQuestions.length;
    if (condition1 || condition2) {
      this.setState({
        removedQuestions,
        toast: true,
      });
    }
    if (condition1) {
      this.setState({
        packageData: { ...this.state.packageData, questionIdListSets: newList },
      });
    }
    if (condition2) {
      this.setState({
        existingQuestions: oldList,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.packageData.type !== this.state.packageData.type &&
      this.state.packageData.type &&
      this.state.packageData.type !== "all"
    ) {
      this.removeDiffTypeQuestions();
    }
  }
  handleImageModal = () =>
    this.setState({ imageModal: !this.state.imageModal });
    // const isMediumScreen = useMediaQuery("(max-width: 1100px)");
  render() {
    const allCustomers = this.props.Customers
      ? this.props.Customers.reduce((acc, val) => {
          acc.push({
            label: val.name,
            value: val.id,
          });
          return acc;
        }, [])
      : [];
    const newValueCheck = Object.entries(
      this.state?.learningPath?.practiceData || ""
    )
      .filter((c) => c[1]?.length)
      .map((c) => {
        return c;
      });
    const newMockValueCheck = this.state.learningPath.newMockAssessments
      ? this.state.learningPath.testData?.length
      : 0;
    return (
      <div className="page-content">
        <Snackbar
          open={this.state.toast}
          autoHideDuration={5000}
          onClose={() => this.setState({ toast: !this.state.toast })}
        >
          <MuiAlert
            variant="filled"
            elevation={6}
            onClose={() => this.setState({ toast: !this.state.toast })}
            severity="warning"
          >
            Removed {this.state.removedQuestions.MC} MC,
            {this.state.removedQuestions.CD} CD &{" "}
            {this.state.removedQuestions.DESC} DESC question(s)
          </MuiAlert>
        </Snackbar>
        <Breadcrumbs
          title="Labs"
          breadcrumbItem={
            this.props?.location?.state?.fromEdittrue ? "Edit Package" : "Create Package"
          }
        />
        <div
          style={{
            backgroundColor: "#fff",
            padding: 15,
            border: "1px solid #f2f1f3",
          }}
        >
          {/* {(this.state.packageData?.description?.length > 0 && !this.state.descValue) ? (
             <p className={styles?.descSpace} style={{textAlign: "right",color: (this.state.packageData?.description)?.length > 100 && (this.state.packageData?.description)?.length < 501 ?  "#1ba94c" : "red"}}>
             {(this.state.packageData?.description)?.length} /500
           </p>
          ):(
          <p className={styles?.descSpace} style={{textAlign: "right",color: (this.state?.description)?.length > 100 && (this.state?.description)?.length < 501 ?  "#1ba94c" : "red"}}>
            {(this.state?.description)?.length} /500
          </p>
          )} */}
          <PackageDetails
            packageData={this.state.packageData}
            fromPrepare={this.state.fromPrepare}
            setDesc={(val) => {
              this.setState({
                ...this.state.description,
                description: val
              });
            }}
            checkIfNameExists={this.checkIfNameExists}
            customerId={
              this.Priv.includes("MANAGE_CUSTOMER")
                ? this.state.packageData.collegeId
                : sessionStorage.getItem("customer_id")
            }
            descValue={(value, state) =>
              this.setState({
                descValue: {
                  ...this.state.descValue,
                  [state]: value,
                },
              })
            }
            updateState={(value, state) =>
              this.setState({
                packageData: {
                  ...this.state.packageData,
                  [state]: value,
                },
              })
            }
            description={this.state.description}
            isEdit={this.state.isEdit}
            errors={this.state.errors}
            updateErrors={(error, id) =>
              this.setState({
                errors: {
                  ...this.state.errors,
                  [id]: error,
                },
              })
            }
            questionTypes={questionTypes}
            isSA={this.Priv.includes("MANAGE_CUSTOMER")}
          />
          <hr />
          {this.Priv.includes("MANAGE_CUSTOMER") && !this.state.fromPrepare && (
            <>
              {" "}
              <div style={{ display: "flex" }}>
                <div style={{ flex: 2, padding: "20px 0 0 20px" }}>
                  <h5>Package Pricing</h5>
                  <p>If the package is paid enter the package pricing</p>
                </div>
                <div style={{ flex: 3, marginBottom: 20 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(_e, checked) => {
                          this.setState({
                            packageData: {
                              ...this.state.packageData,
                              paidPackage: checked,
                            },
                          });
                        }}
                        checked={this.state.packageData.paidPackage}
                      />
                    }
                    label="Is this package is paid"
                  />
                  <Collapse in={this.state.packageData.paidPackage}>
                    <br />
                    <Grid
                      container
                      style={{ width: "calc(100% + 34px)", marginLeft: -17 }}
                    >
                      <InputField
                        label= {this.validIndicator("Original Price")}
                        type="text"
                        value={this.state.packageData.originalPrice || ""}
                        onInput={(e) => {
                          if (isNumeric(e?.target?.value)) {
                            const op = getNumber(e?.target?.value);
                            const dp = this.state.packageData.discountAmount;
                            const sp = this.state.packageData.strikingPrice;

                            this.setState({
                              packageData: {
                                ...this.state.packageData,
                                originalPrice: op || "",
                                ...(dp > 100 || sp > op
                                  ? {
                                      strikingPrice: "",
                                      discountAmount: "",
                                    }
                                  : dp && sp
                                  ? {
                                      strikingPrice:
                                        op - Math.round((dp / 100) * op),
                                    }
                                  : {
                                      ...(dp && {
                                        strikingPrice:
                                          op - Math.round((dp / 100) * op),
                                      }),
                                      ...(sp && {
                                        discountAmount:
                                          100 -
                                          Math.ceil((sp * 100) / (op || 1)),
                                      }),
                                    }),
                              },
                            });
                          } else {
                            this.setState({
                              packageData: {
                                ...this.state.packageData,
                                originalPrice: "",
                                strikingPrice: "",
                                discountAmount: "",
                              },
                            });
                          }
                        }}
                      />
                      <InputField
                        label= {this.validIndicator("Striking Price")}
                        type="text"
                        error={
                          this.state.packageData.strikingPrice >
                            this.state.packageData.originalPrice &&
                          "Striking price cannot be greater than orginal price"
                        }
                        value={this.state.packageData.strikingPrice || ""}
                        onInput={(e) => {
                          if (isNumeric(e?.target?.value)) {
                            const strikingPrice = getNumber(e?.target?.value);

                            this.setState({
                              packageData: {
                                ...this.state.packageData,
                                strikingPrice: strikingPrice || "",
                                ...(this.state.packageData.originalPrice && {
                                  discountAmount:
                                    100 -
                                    Math.ceil(
                                      (strikingPrice * 100) /
                                        this.state.packageData.originalPrice
                                    ),
                                }),
                              },
                            });
                          } else {
                            this.setState({
                              packageData: {
                                ...this.state.packageData,
                                strikingPrice: "",
                                discountAmount: "",
                              },
                            });
                          }
                        }}
                      />
                      <InputField                     
                        label={this.validIndicator("Discount Percentage")}
                        type="text"
                        error={
                          this.state.packageData.discountAmount > 100 &&
                          "Discount cannot be greater than 100"
                        }
                        value={this.state.packageData.discountAmount || ""}
                        onInput={(e) => {
                          if (e?.target?.value) {
                            this.setState({
                              packageData: {
                                ...this.state.packageData,
                                discountAmount: e?.target?.value || "",
                                ...(this.state.packageData.originalPrice && {
                                  strikingPrice:
                                    this.state.packageData.originalPrice -
                                    Math.round(
                                      (e?.target?.value / 100) *
                                        this.state.packageData.originalPrice
                                    ),
                                }),
                              },
                            });
                          } else {
                            this.setState({
                              packageData: {
                                ...this.state.packageData,
                                strikingPrice: "",
                                discountAmount: "",
                              },
                            });
                          }
                        }}
                      />
                    </Grid>
                    <br />
                    <Grid item xs={6}>
                      <Label>Coupons accepted</Label>
                      <Select
                        options={[]}
                        onChange={(val) => {
                          this.setState({
                            packageData: {
                              ...this.state.packageData,
                              coupons: [],
                            },
                          });
                        }}
                        value=""
                        placeholder="**Choose**"
                      />
                      {/* {errors.filterType && (
                        <span style={{ color: "red" }}>{}</span>
                      )} */}
                    </Grid>
                  </Collapse>
                </div>
              </div>
              <hr />
            </>
          )}
          {this.Priv.includes("MANAGE_CUSTOMER") &&
          this.state.packageData.paidPackage ? (
            <>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 2, padding: "20px 0 0 20px" }}>
                  <h5>Should appear in homepage</h5>
                </div>
                <div style={{ flex: 3, marginBottom: 15 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(_e, checked) => {
                          this.setState({
                            packageData: {
                              ...this.state.packageData,
                              showAtHomePage: checked,
                            },
                          });
                        }}
                        checked={this.state.packageData.showAtHomePage}
                      />
                    }
                    label="Show package in homepage"
                  />
                </div>
              </div>
              <hr />
            </>
          ) : (
            ""
          )}
          {this.Priv.includes("MANAGE_CUSTOMER") && !this.state.fromPrepare ? (
            <>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 2, padding: "20px 0 0 20px" }}>
                  <h5>Show Public</h5>
                </div>
                <div style={{ flex: 3, marginBottom: 15 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(_e, checked) => {
                          this.setState({
                            packageData: {
                              ...this.state.packageData,
                              typePublic: checked,
                            },
                          });
                        }}
                        checked={this.state.packageData.typePublic}
                      />
                    }
                    label="Is this public package"
                  />
                </div>
              </div>
              <hr />
            </>
          ) : (
            ""
          )}
          {this.Priv.includes("MANAGE_CUSTOMER") && !this.state.fromPrepare && (
            <>
              {" "}
              <div style={{ display: "flex" }}>
                <div style={{ flex: 2, padding: "20px 0 0 20px" }}>
                  <h5>Customer</h5>
                </div>
                <div style={{ flex: 3, marginBottom: 20 }}>
                  <Label>{this.validIndicator("Customer")}</Label>
                  <div style={{width:"49%"}}>
                  <Select
                    options={allCustomers}
                    value={GetObjectFromString(
                      allCustomers,
                      this.state.packageData.collegeId
                    )}
                    onChange={(value) => {
                      this.getAssessments(value.value);
                      this.setState({
                        // learningPath: {
                        //   open: false,
                        //   categoryHashMap: {},
                        //   practiceData: {},
                        //   testData: [],
                        // },
                        packageData: {
                          ...this.state.packageData,
                          // learnPath: false,
                          // learningPath: [],
                          collegeId: value.value,
                          customerName: value.label,
                        },
                      });
                    }}
                  />
                  </div>
                </div>
              </div>
              <hr />
            </>
          )}
          {!this.state.fromPrepare && (
            <>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 2, padding: "20px 0 0 20px" }}>
                  <h5>Add Students</h5>
                </div>
                <div style={{ flex: 3 }}>
                  <AdvancedStudentSelection
                    customerId={this.state.packageData?.collegeId || ""}
                    existingData={{
                      userIdList: this.state.packageData?.userIdList || [],
                      filterType: this.state.packageData.filterType,
                      batchName: this.state.packageData?.batchName || "",
                      department: this.state.packageData?.department || "",
                      regNoFrom: this.state.packageData?.regNoFrom || "",
                      regNoTo: this.state.packageData?.regNoTo || "",
                    }}
                    result={(data, canSubmit) =>
                      this.setState({ studentData: data, canSubmit })
                    }
                    setAssignStudents={this.state.errorHandling}
                  />
                </div>
              </div>
              <hr />
            </>
          )}
          <div style={{ display: "flex" }}>
            <div style={{ flex: 2, padding: "20px 0 0 20px" }}>
              <h5>Add Questions</h5>
            </div>
            <div style={{ flex: 3 }}>
              <div style={{ marginTop: 20 }}>
                <Button
                  onClick={() => this.setState({ modal: !this.state.modal })}
                  color="success"
                  style={{ fontSize: 14, fontWeight: 500, width: "12em" }}
                >
                  <CustomIcons.PlusIcon size="17" />
                  &nbsp;Add Questions
                </Button>{" "}     
                {this.state.packageData?.questionIdListSets?.length < 1 ? (  
                <div>         
                {this.state.errors._QuesErr !== "" && (
                  <span style={{ color: "red" }}>
                    {this.state.errors._QuesErr}
                  </span>
                )}{" "}
                </div> 
  ):(
      <Badge
        badgeContent={this.state.packageData?.questionIdListSets?.length}
        color="primary"
        max={999}
        showZero
        style={{
          fontFamily: "Poppins",
        }}
      />
  )}
              </div>
              <div style={{ margin: "20px 0 0 -10px" }}>
                <Checkbox
                  color="primary"
                  checked={this.state.packageData.showHint}
                  onChange={() => {
                    this.setState({
                      packageData: {
                        ...this.state.packageData,
                        showHint: !this.state.packageData.showHint,
                      },
                    });
                  }}
                />
                <span
                  style={{
                    fontWeight: 500,
                  }}
                >
                  Show Hints
                </span>
                <Checkbox
                  color="primary"
                  checked={this.state.packageData.showSolution}
                  onChange={() => {
                    this.setState({
                      packageData: {
                        ...this.state.packageData,
                        showSolution: !this.state.packageData.showSolution,
                      },
                    });
                  }}
                />
                <span style={{ fontWeight: 500 }}>Show Solution</span>
                {this.state.packageData.type === "MC" ||
                this.state.packageData.type === "all" ? (
                  <>
                    {" "}
                    <Checkbox
                      color="primary"
                      checked={this.state.packageData.showWorkspace}
                      onChange={() => {
                        this.setState({
                          packageData: {
                            ...this.state.packageData,
                            showWorkspace:
                              !this.state.packageData.showWorkspace,
                          },
                        });
                      }}
                    />
                    <span style={{ fontWeight: 500 }}>Show Workspace</span>
                  </>
                ) : (
                  ""
                )}
                {this.state.packageData.type === "CD" ||
                this.state.packageData.type === "all" ? (
                  <>
                    <Checkbox
                      color="primary"
                      checked={this.state.packageData.showTestCase}
                      onChange={() => {
                        this.setState({
                          packageData: {
                            ...this.state.packageData,
                            showTestCase: !this.state.packageData.showTestCase,
                          },
                        });
                      }}
                    />
                    <span style={{ fontWeight: 500 }}>Show Testcase</span>{" "}
                  </>
                ) : (
                  ""
                )}
                <br />
                {/* <Checkbox
                  color="primary"
                  onChange={() => this.setState({ customCheckEnable: true })}
                />
                <span style={{ fontWeight: 500 }}>Enable CustomChecker</span> */}
              </div>
            </div>
          </div>
          {(this.Priv?.includes("MANAGE_CUSTOMER")
            ? true
            : this.Priv?.includes("ADD_MOCK_ASSESSMENT")) && (
            <>
              <hr />
              <div style={{ display: "flex" }}>
                <div style={{ flex: 2, padding: "20px 0 0 20px" }}>
                  <h5>Mock Assessments</h5>
                </div>
                <div style={{ flex: 3 }}>
                  <Row>
                    <Col lg={6}>
                      <Label>Choose Mock Assessments</Label>
                      <AssessmentsDropdown
                        mockAssessmentsData={this.state.mockAssessmentsData}
                        value={this.state.packageData.mockAssessments}
                        onChange={(val) => {
                          this.updateMockAssessments(val);
                        }}
                        Priv={this.Priv}
                        customerId={
                          this.Priv.includes("MANAGE_CUSTOMER")
                            ? this.state.packageData.collegeId
                            : sessionStorage.getItem("customer_id")
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
          <hr />
          <div style={{ display: "flex" }}>
            <div style={{ flex: 2, padding: "20px 0 0 20px" }}>
              <h5>Learning Path</h5>
            </div>
            <div
              style={{
                flex: 3,
              }}
            >
              <Row>
                <Col>
                  <div
                    style={{
                      backgroundColor: this.state.packageData.learnPath
                        ? "#EEE8FF"
                        : "#F2F1F3",
                      borderRadius: 5,
                      padding: 16,
                      paddingRight: 80,
                      transitionDuration: "400ms",
                      transitionProperty: "background-color",
                    }}
                  >
                    <Grid
                      alignItems="center"
                      container
                      justifyContent="space-between"
                    >
                      <div>
                        <Typography
                          style={{
                            color: "#323036",
                            fontWeight: 500,
                            fontSize: 14,
                            marginBottom: 8,
                          }}
                        >
                          Enable Learning Path
                        </Typography>
                        <Typography
                          style={{
                            color: "#63606C",
                            fontSize: 14,
                          }}
                        >
                          Create your own learning path for this package
                        </Typography>
                      </div>
                      <div>
                        <Switch
                          color="primary"
                          checked={this.state.packageData.learnPath}
                          onChange={(_e, checked) => {
                            this.setState({
                              packageData: {
                                ...this.state.packageData,
                                learnPath: checked,
                              },
                            });
                          }}
                        />
                      </div>
                    </Grid>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Collapse in={this.state.packageData.learnPath}>
                    <br />
                    <Grid alignItems="center" container wrap="nowrap">
                      <Button
                        color="success"
                        onClick={() => {
                          this.setState({
                            learningPath: {
                              ...this.state.learningPath,
                              open: true,
                            },
                          });
                        }}
                        style={{ fontSize: 14, fontWeight: 500 }}
                      >
                        <DirectionsIcon size="17" />
                        &nbsp;&nbsp;&nbsp; Go to Learning Path
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Typography
                        style={{
                          fontSize: 12,
                          fontWeight: 500,
                          color: "#4A4851",
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                          }}
                        >
                          {this.state.packageData.learningPath?.length || 0}
                        </span>
                        Path Created
                      </Typography>
                      <div
                        style={{
                          width: 1,
                          height: 40,
                          backgroundColor: "#ddd",
                          margin: "0 10px",
                        }}
                      ></div>
                      <Typography
                        style={{
                          fontSize: 12,
                          fontWeight: 500,
                          color: "#4A4851",
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                          }}
                        >
                          {newValueCheck?.length > 0
                            ? newValueCheck?.length
                            : 0}
                        </span>
                        Categories Left
                      </Typography>
                      <div
                        style={{
                          width: 1,
                          height: 40,
                          backgroundColor: "#ddd",
                          margin: "0 10px",
                        }}
                      ></div>
                      <Typography
                        style={{
                          fontSize: 12,
                          fontWeight: 500,
                          color: "#4A4851",
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                          }}
                        >
                          {this.state.learnPathApi
                            ? newMockValueCheck
                            : this.state.learningPath?.testData?.length}
                        </span>
                        Mock Assessment Left
                      </Typography>
                    </Grid>
                  </Collapse>
                  {this.state.errors._validationTopicsError !== "" && (
                    <div style={{ color: "red", paddingTop: "5px" }}>
                      {this.state.errors._validationTopicsError}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <hr />
          <div style={{ display: "flex" }}>
            <div style={{ flex: 2, padding: "20px 0 0 20px" }}>
              {" "}
              <h5>Add Image</h5>
            </div>
            <Row
              style={{
                flex: 3,
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <AddImageComponent
                handleCroppedImage={(image) =>
                  this.setState({
                    packageData: { ...this.state.packageData, media: image },
                  })
                }
                media={
                  this.state.packageData?.media &&
                  this.state.packageData.media.includes("default")
                    ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${this.state.packageData.media}.jpeg`
                    : this.state.packageData.media
                }
                moduleName={"practice"}
                customerName={this.state.customerName}
                currentId={this.state.currentId}
              />
            </Row>
          </div>
          <hr />
          <div style={{ display: "flex" }}>
            <div style={{ flex: 2, padding: "20px 0 0 20px" }}>
              <h5>Certificate Template</h5>
            </div>
            <div style={{ flex: 3 }}>
              <Row>
                <Col lg={6}>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(_e, checked) => {
                            this.setState({
                              packageData: {
                                ...this.state.packageData,
                                hasCertificate: checked,
                                // certificateTemplateId: (checked===false ? null : certificateTemplateId),
                              },
                            });
                          }}
                          checked={this.state.packageData.hasCertificate}
                        />
                      }
                      label="Has Certificate"
                    />
                  </div>
                  {this.state.packageData.hasCertificate && (
                    <>
                      <Label>Choose Certificate Template</Label>
                      <CertificateTemplateDropdown
                        value={this.state.packageData.certificateTemplateId}
                        onChange={(val) => {
                          this.setState({
                            packageData: {
                              ...this.state.packageData,
                              certificateTemplateId: val.value,
                            },
                          });
                        }}
                        customerId={
                          this.Priv.includes("MANAGE_CUSTOMER")
                            ? this.state.packageData.collegeId
                            : sessionStorage.getItem("customer_id")
                        }
                      />
                      {this.state.errors._certificateError !== "" && (
                        <span style={{ color: "red" }}>
                          {this.state.errors._certificateError}
                        </span>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>

          <hr />

          <div style={{ textAlign: "center" }}>
          {this.props?.location?.state?.fromEdittrue ? (
            <Button
              disabled={
                this.state.packageData.learnPath
                  ? newValueCheck?.length > 0 ||
                    (this.state.learnPathApi
                      ? newMockValueCheck > 0
                      : this.state.learningPath?.testData?.length)
                  : false
              }
              color="primary"
              onClick={() => this.onSubmitValue()}
              style={{ fontSize: 16 }}
            >
            Update
            </Button>
          ):(
            <Button
            disabled={
              this.state.packageData.learnPath
                ? newValueCheck?.length > 0 ||
                  (this.state.learnPathApi
                    ? newMockValueCheck > 0
                    : this.state.learningPath?.testData?.length)
                : false
            }
            color="primary"
            onClick={() => this.onSubmit()}
            style={{ fontSize: 16 }}
          >
            Submit
          </Button>
          )}
          </div>
          <Dialog
            open={this.state.learningPathPrompt}
            classes={{
              paper: styles.promptContainer,
            }}
          >
            <DialogContent
              classes={{
                root: styles.prompt,
              }}
            >
              <br />
              {this.state.learningPathPrompt === "remove-questions" && (
                <>
                  <Typography align="center" variant="h6">
                    Are you sure want to remove these questions?
                  </Typography>
                  <br />
                  <Typography align="center" variant="body1">
                    These questions will be removed from learning path too, are
                    you want to proceed?
                  </Typography>
                </>
              )}
              {this.state.learningPathPrompt === "add-questions" && (
                <>
                  <Typography align="center" variant="h6">
                    Questions added
                  </Typography>
                  <br />
                  <Typography align="center" variant="body1">
                    Learning path has been enabled in this package, Add newly
                    updated questions in Learning path.
                  </Typography>
                </>
              )}
              {this.state.learningPathPrompt === "delete-path" && (
                <>
                  <Typography align="center" variant="h6">
                    Are you sure want to delete this path?
                  </Typography>
                  <br />
                  <Typography align="center" variant="body1">
                    This path and content inside this path will be deleted and
                    cannot be restored
                  </Typography>
                </>
              )}
              <br />
              <br />
              <Grid alignItems="center" container justifyContent="center">
                {this.state.learningPathPrompt !== "add-questions" && (
                  <Button
                    color="secondary"
                    size="lg"
                    style={{
                      backgroundColor: "#F2F1F3",
                      border: "none",
                    }}
                    onClick={() => {
                      this.setState({ learningPathPrompt: null });
                    }}
                  >
                    <Typography style={{ color: "#444" }}>Cancel</Typography>
                  </Button>
                )}
                {this.state.learningPathPrompt === "delete-path" && (
                  <>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      size="lg"
                      style={{
                        backgroundColor: "#ff0000",
                        border: "none",
                      }}
                      onClick={() => {
                        if (this.state?.learningPathPromptCb) {
                          this.state?.learningPathPromptCb?.();
                        }
                        this.setState({ learningPathPrompt: null });
                      }}
                    >
                      <Typography>Delete</Typography>
                    </Button>
                  </>
                )}
                {this.state.learningPathPrompt === "add-questions" && (
                  <>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      color="primary"
                      size="lg"
                      style={{
                        border: "none",
                      }}
                      onClick={() => {
                        if (this.state?.learningPathPromptCb) {
                          this.state?.learningPathPromptCb?.();
                        }
                        this.setState({ learningPathPrompt: null });
                      }}
                    >
                      <Typography>Done</Typography>
                    </Button>
                  </>
                )}
                {this.state.learningPathPrompt === "remove-questions" && (
                  <>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      color="primary"
                      size="lg"
                      style={{
                        backgroundColor: "#ff0000",
                        border: "none",
                      }}
                      onClick={() => {
                        if (this.state?.learningPathPromptCb) {
                          this.state?.learningPathPromptCb?.();
                        }
                        this.setState({ learningPathPrompt: null });
                      }}
                    >
                      <Typography>Remove</Typography>
                    </Button>
                  </>
                )}
              </Grid>
              <br />
              <br />
            </DialogContent>
          </Dialog>
          <SelectQuestionsModal
            questionTypes={questionTypes}
            setCategoriesState={(list) => {
              this.setState({
                packageData: {
                  ...this.state.packageData,
                  categories: list,
                },
              });
            }}
            loading={this.state.loading}
            hasMore={this.state.hasMore}
            newValueCheck={newValueCheck}
            disableType
            canPreview={this.state.packageData.paidPackage}
            setPreviewQuestions={(list) => {
              this.setState({
                packageData: {
                  ...this.state.packageData,
                  previewQuestions: list,
                },
              });
            }}
            setNewQuestion={(list) => {
              this.setState({
                pathNewList: list,
              });
            }}
            previewQuestions={this.state.packageData.previewQuestions}
            modalState={this.state.modal}
            questionsType={this.state.packageData.type || "all"}
            currBankQues={this.state.currBankQues}
            existingQuestions={this.state.existingQuestions}
            onRemove={async (cb) => {
              if (this.state.packageData.learnPath) {
                this.showPrompt("remove-questions", async () => {
                  cb?.();
                });
              } else {
                cb?.();
              }
            }}
            remove={async (list, balQuestion) => {
              if (list.length > 0) {
                await this.updateQuestions(balQuestion);
                this.setState({
                  toRemove: [this.state.toRemove, ...list],
                });
              }
            }}
            currBankQuesTotalCount={this.state.currBankQuesTotalCount}
            setCurrQuestionBank={(
              val,
              limit,
              query,
              filterTopics,
              filterCategorys,
              filterTypes,
              filterComplexitys
            ) =>
              this.setCurrQuestionBank(
                val,
                limit,
                query,
                filterTopics,
                filterCategorys,
                filterTypes,
                filterComplexitys
              )
            }
            QBlist={this.state.listQB}
            isCopy={false}
            setQIds={async (val) => {
              if (this.state.packageData.learnPath) {
                this.showPrompt("add-questions", async () => {
                  await this.updateQuestions(val);
                });
              } else {
                await this.updateQuestions(val);
              }
            }}
            setModal={() => this.setState({ modal: !this.state.modal })}
          />
          {this.state.learningPath.open && (
            <LearningPath
              open={this.state.learningPath.open}
              newValueCheck={newValueCheck}
              categoryHashMap={this.state.learningPath.categoryHashMap}
              practiceData={this.state.learningPath.practiceData}
              testData={this.state.learningPath.testData}
              setTestData={(val) => {
                this.setState({
                  learningPath: {
                    ...this.state.learningPath,
                    testData: val,
                  },
                });
              }}
              editMock={
                this.state.learnPathApi
                  ? newMockValueCheck
                  : this.state.learningPath?.testData?.length
              }
              onDelete={(cb = () => {}) => {
                this.showPrompt("delete-path", async () => {
                  cb();
                });
              }}
              learningPathList={this.state.packageData.learningPath}
              setLearningPathList={(val) => {
                this.setState({
                  packageData: {
                    ...this.state.packageData,
                    learningPath: val,
                  },
                });
              }}
              setPracticeData={(val) => {
                this.setState({
                  learningPath: {
                    ...this.state.learningPath,
                    practiceData: val,
                  },
                });
              }}
              onClose={() => {
                this.setState({
                  learningPath: {
                    ...this.state.learningPath,
                    open: false,
                  },
                });
              }}
            />
          )}
        </div>
        {!this.state.fromPrepare ? (
        <Link
          to={{
            pathname: "/manage-allLabs",
            state: {
              customer: this.state.customer,
              activePage: this.state.activePage,
              activeTab: this.state.activeTab,
              customerName: this.state.customerName,
            },
          }}
          className="terv-back"
        >
          <i className="mdi mdi-arrow-left mr-1" /> Back to Packages
        </Link>
        ):(
          <Link
          to={{
            pathname: `/manage-prepare/${this.props?.location?.state?.courseId}`,
            state: {
              customerId: this.state.customer,
              activeTab: 2,
              currentStep: "editLesson",
              //prepPracticeId: result?.id,
              // prepPracticeRes: result,
              // selSec: this.props?.location?.state?.selSec,
              sectionValues: this.state.sectionValues,
              createdFromPrepare: true,
              currentLesson: this.props?.location?.state?.currentLesson,
              // contentName: this.props?.location?.state?.contentName,
              // completionCriteria: this.props?.location?.state?.completionCriteria,
              currentSection: this.props?.location?.state?.currentSection,
            },
          }}
          className="terv-back"
        >
          <i className="mdi mdi-arrow-left mr-1" /> Back to Prepare
        </Link>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  Students: state.StudentAndCustomerReducer.UserStudents,
  Customers: state.StudentAndCustomerReducer.Customers,
  UserData: state.UserProfileReducer.UserData,
});

export default connect(mapStateToProps, null)(Managelab);
 
 