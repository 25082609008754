import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import useStyles from  "./UserProfileStyles.js";
import EditUserInput from  "./EditUserInput.js";
import { DeleteOutlineIcon } from "assets/icons/icons";
import { ConformDelete } from "../../components/DeleteModelePre";

const paraStyle = {
  fontSize: 15,
  fontWeight: 500,
  color: "#5B5A5F",
  marginTop: 12,
  textAlign: "center",
};

export default function EditUserExtra({pageCount, userData, handlePageCount, submitUserData, history}) {
  // const [pageCount, setPageCount] = useState(1);
  const css = useStyles();
  
  const defaultData = {
    internships: {
      position: {value: "", validation:{key:"position", placeholder:"outer", name:"Position", min:1, max:48, type:"text"}},
      companyName: {value: "", validation:{key:"companyName", placeholder:"outer", name:"Company name", min:1, max:48, type:"text"}},
    },
  }
  const [userId, setUserId] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [errorComponentList, setErrorComponentList] = useState([]);
  const [proExpandIndex, setProExpandIndex] = useState(0); 

  const [pageData, setPageData] = useState({
    internships: [{...defaultData.internships}],
    extraCarricularActivies: {value: [], validation:{key:"extraCarricularActivies", placeholder:"inner", name:"Extra curricular activities", data:["Basketball", "Cheerleading", "Gymnastics", "Hockey", "Golf", "Climbing", "Bodybuilding", "Fencing", "Dance team", "Cycling", "Football", "Soccer", "Martial arts", "Lacrosse", "Tennis", "Water polo", "Swimming", "Track and field", "Skating", "Yoga or pilates", "Running"], search:true, type:"select-chip"}},
    personalStrengths: {value: [], validation:{key:"personalStrengths", placeholder:"inner", name:"Personal strengths", data:["Strong Communication Skills", "Problem Solving Skills", "Computer Literacy", "Leadership Skills", "Fast Learner", "Multi-tasker", "A Positive Mindset", "Self-Discipline", "Emotional Intelligence", "Organizational Skills", "Independent Thinker", "Strong Work Ethic", "Passion for the Field", "Credibility", "Competence", "Intelligence", "Integrity", "Team Player", "Professionalism", "Flexibility", "Creativity", "Loyalty", "Time Management", "Ability to Accept Criticism", "Research and Data Analysis", "Attention to Detail", "Active Listening", "Self-Awareness", "Fast Decision Maker", "Resilience"], search:true, type:"select-chip"}},
  });

  const [dataDelete, setDataDelete] = useState({ type: "", index: null });

  const addData = (key, data, type) => {
    const tempPageData = {...pageData}
    if(type && type.key) data[type.key]["value"] = type.value;
    tempPageData[key].push(data);
    setPageData(tempPageData)
    setProExpandIndex(tempPageData[key].length-1);
  }
  const removeData = (key, index) => {
    const tempPageData = {...pageData}
    tempPageData[key].splice(index, 1);
    setPageData(tempPageData);
    if(proExpandIndex === index){
      setProExpandIndex(tempPageData[key].length-1);
    }
  }
  const traceData = (keys, keyIndex, dataName, val, data) => {
    if(keys[keyIndex+1] !== undefined) data[keys[keyIndex]] = traceData(keys, keyIndex+1, dataName, val, data[keys[keyIndex]]);
    else data[keys[keyIndex]][dataName] = val;
    return data;
  }
  const updateData = ({keys, dataName, value}) => {
    if(keys && keys.length > 0 ){
      const tempData = traceData(keys, 0, dataName, value, {...pageData});
      setPageData(tempData);
    }
  }
  const errorComponentUpdate = (component, type) => {
    const tempList = [...errorComponentList];
    const index = tempList.indexOf(component);
    if(type === "remove" && index > -1){
      tempList.splice(index, 1);
      setErrorComponentList(tempList);
    } else if(type === "add" && index === -1)
      setErrorComponentList([...tempList, component]);
  }
  const submitPageDataVal = async () => {
    const isSubmit = await submitUserData(pageData, "users/saveUsersInternships/");
    if(isSubmit){
      handlePageCount(pageCount+1)
    }
  }
  const goNextPage = () => {
    if(errorComponentList.length > 0)
      setErrorShow(true);
    else 
      submitPageDataVal()
  }
  const goPrevPage = () => {
    handlePageCount(pageCount-1);
  }

  useEffect(() => {
    if((dataDelete.type === "internshipsDeleteConform") && dataDelete.index !== null ) {
      removeData(dataDelete.index.key, dataDelete.index.index);
      setDataDelete({ type: "", index: null });
    }
  }, [dataDelete]);

  useEffect(() => {
    if(userData.id) {
      let tempPageData = {...pageData};
      for(const key in tempPageData) {
        if(tempPageData[key]["value"] !== undefined) {
          if(Array.isArray(tempPageData[key])) {
            if(Array.isArray(userData[key])) tempPageData[key]["value"] = [...userData[key]];
            else tempPageData[key]["value"] = [];
          } else tempPageData[key]["value"] = userData[key] ? userData[key] : tempPageData[key]["value"];
        } else if(tempPageData[key] && Array.isArray(tempPageData[key]) && defaultData[key]) {
          const tempArray = [];
          const fromUserData = userData[key] && Array.isArray(userData[key]) ? [...userData[key]] : [];
          if(fromUserData.length > 0 ) {
            fromUserData.map((data, i) => {
              let defaultKeyData = {...defaultData[key]};
              for(const defaultKey in defaultKeyData) {
                defaultKeyData[defaultKey] = {...defaultKeyData[defaultKey], value: fromUserData[i] && fromUserData[i][defaultKey] ? fromUserData[i][defaultKey] : ""};
              }
              tempArray.push(defaultKeyData);
            });
          } else {
            // tempArray.push({...defaultData[key]});
          }
          tempPageData[key] = tempArray;
        } else if(typeof tempPageData[key] === "object") {
          for (const subKey in tempPageData[key]) {
            if(tempPageData[key][subKey]["value"] !== undefined) tempPageData[key][subKey]["value"] = userData[key] && userData[key][subKey] ? userData[key][subKey] : "";
          }
        }
      }
      setPageData(tempPageData);
      setUserId(userData.id);
    }
  },[userData]);

  return (
    <React.Fragment>
      <div className="form-group">
        <h4>Achievements / Internships</h4>
        <h5>Internships</h5>
        {pageData.internships.length > 0 && pageData.internships.map((data, i)=>(
          <div key={`internships-${i}`} className="flex-box flex-100 flex-gap-2" style={{margin: i === 0 ? "24px 0 12px 0" : "12px 0"}}>
            <div className="flex-4">
              <div className="form-row flex-box flex-100 flex-gap-2">
                {data.position && (
                  <EditUserInput type="text" value={data.position.value} errorShow={errorShow}
                    setValue={(v)=>updateData({keys: ["internships", i, "position"], dataName:"value", value: v})}
                    updateError={errorComponentUpdate} validation={{...data.position.validation, placeholder: (i === 0 ? "outer" : "inner")}} />
                )}
                {data.companyName && (
                  <EditUserInput type="text" value={data.companyName.value} errorShow={errorShow}
                    setValue={(v)=>updateData({keys: ["internships", i, "companyName"], dataName:"value", value: v})}
                    updateError={errorComponentUpdate} validation={{...data.companyName.validation, placeholder: (i === 0 ? "outer" : "inner")}} />
                )}
              </div>
            </div>
            <div className="flex-1">
            {true && (
              <div>
                <Button variant="text" className={css.buttonDeleteIcon} disableElevation onClick={()=>setDataDelete({type: "internshipsDelete", index: {key: "internships",index : i}})}>
                  <DeleteOutlineIcon />
                </Button>
              </div>
            )}
            </div>
          </div>
        ))}
      </div>
      <div className="form-group last-0">
        <Button variant="outlined" className={css.buttonSmSuccess} disableElevation onClick={()=>addData("internships", defaultData.internships)}>
          Add internship
        </Button>
      </div>
      <div className="form-group">
        <h5>Extra curricular activities</h5>
        <div className="flex-box flex-100 flex-gap-2">
          <div className="flex-4">
            <div className="form-row flex-box flex-100 flex-gap-2">
              {pageData.extraCarricularActivies && (
                <EditUserInput type="select" value={pageData.extraCarricularActivies.value} errorShow={errorShow}
                  setValue={(v)=>updateData({keys: ["extraCarricularActivies"], dataName:"value", value: v})}
                  updateError={errorComponentUpdate} validation={pageData.extraCarricularActivies.validation} />
              )}
            </div>
          </div>
          <div className="flex-1"></div>
        </div>
      </div>
      <div className="form-group">
        <h5>Personal strengths</h5>
        <div className="flex-box flex-100 flex-gap-2">
          <div className="flex-4">
            <div className="form-row flex-box flex-100 flex-gap-2">
              {pageData.personalStrengths && (
                <EditUserInput type="select" value={pageData.personalStrengths.value} errorShow={errorShow}
                  setValue={(v)=>updateData({keys: ["personalStrengths"], dataName:"value", value: v})}
                  updateError={errorComponentUpdate} validation={pageData.personalStrengths.validation} />
              )}
            </div>
          </div>
          <div className="flex-1"></div>
        </div>
      </div>
      <div className="form-group fill-width">
        <div className="form-row flex-box flex-100 flex-gap-2 flex-center">
          <Button variant="outlined" className={css.buttonPrimary} disableElevation 
            onClick={goPrevPage}>
            Prev
          </Button>
          <Button variant="contained" className={css.buttonPrimary} disableElevation
            onClick={goNextPage}>
            Next
          </Button>
        </div>
      </div>
      <ConformDelete
        Header={
          dataDelete.type === "internshipsDelete" ? "Delete internship" : ""
        }
        Content={() =>
          dataDelete.type === "internshipsDelete" ? (
            <p style={paraStyle}>
              <div>Are you sure want to delete internship?</div>
              <div>You cannot recover once deleted</div>
            </p>
          ) : (
            ""
          )
        }
        isOpen={dataDelete.type === "internshipsDelete"}
        handleClose={() => {
          setDataDelete({
            type: "",
            index: null,
          });
        }}
        handleDelete={() => {
          if (dataDelete.type === "internshipsDelete")
            setDataDelete({
              type: "internshipsDeleteConform",
              index: dataDelete.index,
            });
        }}
      />
    </React.Fragment>
  );
};