import api from "api/baseConfig";
import apiHelper from "api/apiHelper";
import { sessionPrivileges } from "privileges";

export const GetAllCourses = async (customerId) => {
  const url = `${api.baseURL}${api.Hackathon.GetAllCourses}?customerId=${customerId}`;
  try {
    const data = await apiHelper.axiosCall(url, "get");
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const CheckIfCourseNameExists = async (name) => {
  const url = `${api.baseURL}${
    api.Prepare.courseNameCheck
  }${name}/${sessionStorage.getItem("customer_id")}`;

  try {
    const res = await apiHelper.axiosCall(url, "get");
    return res;
  } catch (error) {}
};

export const GetCourse = async (url) => {
  try {
    const data = await apiHelper.axiosCall(url, "get");
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const DeleteCourse = async (courseId) => {
  try {
    await apiHelper.axiosCallPost(
      `${api.baseURL}${api.Hackathon.deleteHackathon}${courseId}`,
      "delete"
    );
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const GetCourseModules = async (courseId) => {
  const url = `${api.baseURL}${api.Prepare.getAllModules}${courseId}`;
  try {
    const data = await apiHelper.axiosCall(url, "get");
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const CreateOrUpdate = async (formValues) => {
  const url = `${api.baseURL}${api.Hackathon.CreateOrUpdate}`;
  const payload = {
    ...formValues,
  };
  try {
    const data = await apiHelper.axiosCallPost(url, "post", payload);
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const SaveCourseSections = async (payload) => {
  const url = `${api.baseURL}${api.Prepare.saveSections}`;

  try {
    const data = await apiHelper.axiosCallPost(url, "post", payload);
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const UpdateOverviews = async (formValues) => {
  const url = `${api.baseURL}${api.Hackathon.UpdateOverviews}`;
  const payload = {
    ...formValues,
  };
  try {
    const data = await apiHelper.axiosCallPost(url, "post", payload);
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const UpdateSettings = async (formValues) => {
  const url = `${api.baseURL}${api.Hackathon.saveSettings}`;
  const userPrivileges = sessionPrivileges();
  const payload = {
    ...formValues,
    updatedBy: userPrivileges.userId,
    updatedDate: new Date(),
  };
  try {
    const data = await apiHelper.axiosCallPost(url, "post", payload);
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const UpdatePricing = async (formValues) => {
  const url = `${api.baseURL}${api.Prepare.savePricings}`;
  const userPrivileges = sessionPrivileges();
  const payload = {
    ...formValues,
    updatedBy: userPrivileges.userId,
    ...(userPrivileges.isAdmin && {
      customerId: sessionStorage.getItem("customer_id"),
    }),
  };
  try {
    const data = await apiHelper.axiosCallPost(url, "post", payload);
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const savePrize = async (formValues) => {
  const url = `${api.baseURL}${api.Hackathon.savePrize}`;
  const userPrivileges = sessionPrivileges();
  const payload = {
    ...formValues,
    updatedBy: userPrivileges.userId,
    ...(userPrivileges.isAdmin && {
      customerId: sessionStorage.getItem("customer_id"),
    }),
  };
  try {
    const data = await apiHelper.axiosCallPost(url, "post", payload);
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};
