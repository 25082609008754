export default ({ className }) => (
  <svg className={className} viewBox="0 0 72 50" fill="none">
    <circle cx="3" cy="3" r="3" fill="#FF9450" />
    <circle cx="3" cy="14" r="3" fill="#FF9450" />
    <circle cx="3" cy="25" r="3" fill="#FF9450" />
    <circle cx="3" cy="36" r="3" fill="#FF9450" />
    <circle cx="3" cy="47" r="3" fill="#FF9450" />
    <circle cx="14" cy="3" r="3" fill="#FF9450" />
    <circle cx="14" cy="14" r="3" fill="#FF9450" />
    <circle cx="14" cy="25" r="3" fill="#FF9450" />
    <circle cx="14" cy="36" r="3" fill="#FF9450" />
    <circle cx="14" cy="47" r="3" fill="#FF9450" />
    <circle cx="25" cy="3" r="3" fill="#FF9450" />
    <circle cx="25" cy="14" r="3" fill="#FF9450" />
    <circle cx="25" cy="25" r="3" fill="#FF9450" />
    <circle cx="25" cy="36" r="3" fill="#FF9450" />
    <circle cx="25" cy="47" r="3" fill="#FF9450" />
    <circle cx="36" cy="3" r="3" fill="#FF9450" />
    <circle cx="36" cy="14" r="3" fill="#FF9450" />
    <circle cx="36" cy="25" r="3" fill="#FF9450" />
    <circle cx="36" cy="36" r="3" fill="#FF9450" />
    <circle cx="36" cy="47" r="3" fill="#FF9450" />
    <circle cx="47" cy="3" r="3" fill="#FF9450" />
    <circle cx="47" cy="14" r="3" fill="#FF9450" />
    <circle cx="47" cy="25" r="3" fill="#FF9450" />
    <circle cx="47" cy="36" r="3" fill="#FF9450" />
    <circle cx="47" cy="47" r="3" fill="#FF9450" />
    <circle cx="58" cy="3" r="3" fill="#FF9450" />
    <circle cx="58" cy="14" r="3" fill="#FF9450" />
    <circle cx="58" cy="25" r="3" fill="#FF9450" />
    <circle cx="58" cy="36" r="3" fill="#FF9450" />
    <circle cx="58" cy="47" r="3" fill="#FF9450" />
    <circle cx="69" cy="3" r="3" fill="#FF9450" />
    <circle cx="69" cy="14" r="3" fill="#FF9450" />
    <circle cx="69" cy="25" r="3" fill="#FF9450" />
    <circle cx="69" cy="36" r="3" fill="#FF9450" />
    <circle cx="69" cy="47" r="3" fill="#FF9450" />
  </svg>
);
