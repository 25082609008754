import { useEffect, useState } from 'react';
import StopwatchCompact from './stopwatch.compact';
import StopwatchRegular from './stopwatch.regular';
import styles from './stopwatch.module.scss';

export default function Stopwatch({ compactMode, isLightMode }) {
  const [isActive, setIfActive] = useState(false);
  const [isPaused, setIfPaused] = useState(true);
  const [time, setTime] = useState(0);

  useEffect(() => {
    let interval = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setTime((time) => time + 50);
      }, 50);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  const handleStart = () => {
    setIfActive(true);
    setIfPaused(false);
  };

  const handlePauseResume = () => {
    setIfPaused(!isPaused);
  };

  const handleReset = () => {
    setIfActive(false);
    setTime(0);
  };

  return (
    <div className={styles.stopwatchContainer}>
      {compactMode
        ? (
          <StopwatchCompact
            handlePauseResume={handlePauseResume}
            handleReset={handleReset}
            handleStart={handleStart}
            isActive={isActive}
            isLightMode={isLightMode}
            isPaused={isPaused}
            time={time}
          />
        )
        : (
          <StopwatchRegular
            handlePauseResume={handlePauseResume}
            handleReset={handleReset}
            handleStart={handleStart}
            isActive={isActive}
            isPaused={isPaused}
            time={time}
          />
        )}
      {/* <div className="Control-Buttons">
        <div>
          {isActive ? ActiveButtons : StartButton}
        </div>
      </div> */}
    </div>
  );
}
