import React from "react";
import cn from "classnames";
import ProgressBar from "../ProgressBar/ProgressBar";
import styles from "./PracticePackageListItem.module.scss";

function PracticePackageListItem({
  onActionButtonClick,
  className,
  hidePPView,
  percentageCompleted,
  name,
}) {
  return (
    <div className={cn(styles.listItem, className)}>
      <div className={styles.indicator}>•</div>
      <div className={styles.nameContainer}>
        <div className={styles.name}>{name}</div>
      </div>
      <div className={styles.percentageCompleted}>
        <ProgressBar green percentage={percentageCompleted} />
      </div>
      <div className={styles.actionContainer}>
        {hidePPView && (
          <button
            className={styles.actionButton}
            onClick={onActionButtonClick || (() => {})}
          >
            View
          </button>
        )}
      </div>
    </div>
  );
}

export default PracticePackageListItem;
