import useSWR from "swr";
import { Config } from "../config";
import { getData, getDataAuth } from "../api";
import apiList from "../api/baseConfig";
import { sessionPrivileges } from "privileges";

export function useUserDetails() {
  //if (sessionPrivileges.)
  // const { data } = useSWR(
  //   `${apiList.baseURL}${
  //     apiList.userManagement.getUserByEmail
  //   }${sessionStorage.getItem("user_id")}`,
  //   {
  //     fetcher: getData,
  //     dedupingInterval: Config.sessionDuration,
  //     onError() {
  //       return {};
  //     },
  //   }
  // );
  const userPrivileges = sessionPrivileges();
  if (userPrivileges) {
    const { data } = useSWR(
      `${apiList.baseURL}${apiList.userManagement.getUserByEmail}${userPrivileges.userName}`,
      {
        fetcher: getDataAuth,
        dedupingInterval: Config.sessionDuration,
        onError() {
          return {};
        },
      }
    );
    const name = `${data?.firstName} ${data?.lastName}`;
    return {
      customerLogo: data?.customer?.logo,
      email: data?.emailId,
      name,
      phone: data?.phoneNumber,
    };
  } else {
    return {
      customerLogo: "",
      email: "",
      name: "",
      phone: "",
    };
  }
}
