import { SET_SIDE_BAR } from "./actionTypes";

export type StateType = {
    state: boolean;
    manageAssessments: boolean;
    manageCustomer: boolean;
    master: boolean;
    report: boolean;
    utility: boolean;
};

export type ActionType = {
    type: string;
    payload: StateType;
};

export const handleSideBar = (newState: StateType): ActionType => {
    return {
        type: SET_SIDE_BAR,
        payload: newState,
    };
};