import { useState } from "react";
import cn from "classnames";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import {
  Grid,
  Box,
  LinearProgress as MuiLinearProgress,
  Typography,
  withStyles,
} from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import { Icon } from "@iconify/react";
import crownIcon from "@iconify/icons-fa-solid/crown";
import styles from "./card.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { sessionPrivileges } from "privileges";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  table: {
    minWidth: 650,
  },
}));

const LinearProgress = withStyles({
  root: {
    backgroundColor: "#DFDFDF",
    borderRadius: 10,
    flexGrow: 1,
    height: 7,
  },
  bar: {
    backgroundColor: "#F56F18",
    borderRadius: 10,
  },
})(MuiLinearProgress);

function PracticeList({
  className,
  description,
  discountAmount,
  expired,
  id,
  imageIndex = 1,
  imageUrl,
  onPracticeButtonClick = undefined,
  onRemoveFromCartButtonClick = undefined,
  onTryButtonClick = undefined,
  originalPrice,
  paidPackage,
  progress,
  purchased,
  strikingPrice,
  tag,
  title,
  totalQuestions,
}) {
  const [removeButtonIsLoading, setRemoveButtonLoadingStatus] = useState(false);
  const Priv = sessionPrivileges().userprivileges || [];
  const classes = useStyles();
  return (
    <>
      <div style={{ paddingLeft: "5vw", paddingBottom: "14vh" }}>
        <div className="listGroup">
          <tr
            className="equalHMRWrap eqWrap"
            key={title}
            style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}
          >
            <td className="equalHM eq" style={{ position: "relative" }}>
              <img
                src={
                  imageUrl
                    ? imageUrl?.includes("default")
                      ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${imageUrl}.jpeg`
                      : imageUrl
                    : `https://d2ndap9rlps54r.cloudfront.net/PracticePackages/image-${imageIndex}.jpg`
                }
                className="img"
                alt={title}
                style={{ borderRadius: "10px 0px 0px 10px" }}
                width="170"
                height="139"
              />
              {tag && (
                <div
                  className={styles[`${tag}Tag`]}
                  style={{ position: "absolute", right: "14px" }}
                >
                  {tag === "premium" && (
                    <>
                      <span>
                        <Icon
                          icon={crownIcon}
                          style={{ color: "#ffcc4d", fontSize: "12px" }}
                        />
                        &nbsp;&nbsp;
                      </span>
                    </>
                  )}
                  {tag.charAt(0).toUpperCase() + tag.slice(1)}
                </div>
              )}
            </td>
            <td className="equalHM eq">
              <div>
                <p
                  className={`${styles.description} namePosition`}
                  style={{ fontSize: "16px", fontWeight: "600" }}
                >
                  {title}
                </p>
              </div>
            </td>
            <td
              className="equalHM2 eq"
              style={{ fontSize: "15px", fontWeight: "400" }}
            >
              <div className={`${styles.description} desc`}>
                <p
                  className="namePosition1"
                  dangerouslySetInnerHTML={{ __html: description || "" }}
                />
              </div>
            </td>
            <div className="lastDiv">
              {(
                !onRemoveFromCartButtonClick
                  ? paidPackage
                    ? purchased
                    : true
                  : false
              ) ? (
                <td>
                  <div style={{ display: "flex" }} className="equalHM eq">
                    <div style={{ paddingTop: "2vh" }}>
                      <LinearProgress
                        variant="determinate"
                        value={progress}
                        style={{ width: "227px" }}
                      />
                      <Typography
                        align="center"
                        style={{
                          color: "#71717C",
                          fontSize: 12,
                          fontWeight: 400,
                          marginTop: 6,
                        }}
                      >
                        {`${totalQuestions} question${
                          totalQuestions > 1 ? "s" : ""
                        }`}
                      </Typography>
                    </div>
                    <div style={{ paddingTop: "2vh" }}>
                      <Typography
                        style={{
                          color: "#000",
                          fontSize: 15,
                          fontWeight: 500,
                          marginTop: -5,
                          paddingLeft: 5,
                        }}
                      >
                        {parseInt(progress)}%
                      </Typography>
                    </div>
                  </div>
                </td>
              ) : (
                <td
                  alignItems="baseline"
                  container
                  direction="column"
                  className={`${styles.priceContainer} equalHM eq`}
                  wrap="nowrap"
                  style={{
                    height: 56,
                    paddingTop: 6,
                    display: "flex",
                  }}
                >
                  <Typography
                    style={{ color: "#000", fontSize: 24, fontWeight: "bold" }}
                  >
                    <span style={{ fontFamily: "Roboto, sans-serif" }}>₹</span>
                    &nbsp;
                    {strikingPrice || originalPrice}
                    &nbsp;
                    {"INR"}
                  </Typography>
                  {strikingPrice > 0 && discountAmount > 0 && (
                    <div
                      alignItems="baseline"
                      container
                      wrap="nowrap"
                      style={{
                        marginTop: 8,
                        whiteSpace: "nowrap",
                        display: "flex",
                        maxWidth: 280,
                      }}
                    >
                      <Typography
                        style={{
                          color: "#F91818",
                          fontSize: 16,
                          fontWeight: 500,
                          paddingLeft: 6,
                        }}
                      >
                        <span style={{ fontFamily: "Roboto, sans-serif" }}>
                          ₹
                        </span>
                        &nbsp;
                        <span style={{ textDecoration: "line-through" }}>
                          {originalPrice}
                          &nbsp;
                          {"INR"}
                        </span>
                      </Typography>
                      &nbsp;
                      <Typography
                        style={{
                          color: "#545260",
                          fontSize: 10,
                          fontWeight: 400,
                          letterSpacing: 0.35,
                          paddingTop: "3px",
                        }}
                      >
                        (Save {parseFloat(discountAmount).toFixed(0)}%)
                      </Typography>
                    </div>
                  )}
                </td>
              )}
              <div>
                {onRemoveFromCartButtonClick ? (
                  <Button
                    className={styles.button}
                    color="primary"
                    disabled={removeButtonIsLoading}
                    onClick={async () => {
                      setRemoveButtonLoadingStatus(true);
                      await onRemoveFromCartButtonClick(id).finally(() => {
                        setRemoveButtonLoadingStatus(false);
                      });
                    }}
                    outline
                    size="lg"
                  >
                    {removeButtonIsLoading ? (
                      <span style={{ color: "#794DF5 !important" }}>
                        <CircularProgress
                          color="inherit"
                          thickness={5}
                          size={14}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <>Removing</>
                      </span>
                    ) : (
                      "Remove from cart"
                    )}
                  </Button>
                ) : (paidPackage ? purchased : true) ? (
                  Priv?.includes("START_PRACTICE_PACKAGE") ||
                  sessionStorage.getItem("viewPkgWithoutSignIn") === "true" ? (
                    <div>
                      {expired ? (
                        <Button
                          className={cn({ [styles.expired]: expired })}
                          color="primary"
                          disabled={expired}
                          outline
                          style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "117px",
                            paddingRight: "117px",
                          }}
                        >
                          Expired
                        </Button>
                      ) : (
                        <Button
                          className={cn(styles.button)}
                          color="primary"
                          onClick={onPracticeButtonClick}
                          outline
                          style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "117px",
                            paddingRight: "117px",
                          }}
                        >
                          Practice
                        </Button>
                      )}
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  <div
                    container
                    wrap="nowrap"
                    style={{ display: "flex", paddingBottom: "10px" }}
                  >
                    <Link
                      style={{ maxWidth: 120 }}
                      to={{ pathname: "/checkout", params: { items: [id] } }}
                    >
                      <Button
                        className={styles.button}
                        color="primary"
                        size="lg"
                        style={{ width: "100%" }}
                      >
                        {"Buy now"}
                      </Button>
                    </Link>
                    <Button
                      className={`${styles.button} ${styles.tryButton}`}
                      color="primary"
                      outline
                      onClick={onTryButtonClick}
                      size="lg"
                      style={{
                        marginLeft: 16,
                        padding: 0,
                        width: "calc(50% - 8px)",
                        flexShrink: 0,
                      }}
                    >
                      <span className={styles.freeStar}>
                        <span className={styles.freeStarText}>Free</span>
                      </span>
                      <CardGiftcardIcon
                        style={{
                          color: "inherit",
                          fontSize: 26,
                          marginTop: -2,
                        }}
                      />
                      <span>&nbsp;&nbsp;Try now</span>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </tr>
          <style jsx="true" global="true">
            {`
            td, th {
            padding: 8px;
            color: #000;
          }

          .listGroup {
            width: 1074px;
            height: 75px;
            max-height: 46px;
            margin-bottom: 40px;
          }

          .itemPosition {
            padding-top: 0px
          }

          .itemList {
            font-size: 16px;
            padding: 5px;
            margin: 0px
          }
          .lastDiv{
            width: 281px
          }

          .description p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .desc p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 0;
          }

          .desc li {
            list-style: none;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .equalHMRWrap {
            justify-content: space-between;
            flex-wrap: wrap;
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
            padding-right: 20px;
          }

          .eqWrap {
            display: flex;
            align-items: center;
          }

          .equalHM {
            width: 17%;
          }
          .equalHM2 {
            width: 29%;
          }

          .equalHM-1 {
            width: 12vw;
          }

          .eq {
            padding: 0px;
          }

          .itemPosition {
            padding-top: 0px;
          }

          .item-1 {
            padding-top: 80px;
          }

          .itemList {
            font-size: 16px;
            padding: 10px;
            margin: 5px;
          }

          .styleList {
            background-color: rgb(255, 255, 255);
            border-radius: 7px;
            box-shadow: rgb(0 0 0 / 20%) 0px 2px 2px 0px;
            display: flex;
            border-top: 4px solid rgb(93, 224, 180);
            padding: 10px;
            margin: 5px;
          }

          .dateList {
            text-align: center;
            padding: 5px;
          }

          .namePosition {
            margin-top: 30px max-height: 70px max-Width: 20px display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .report {
            padding-top: 20px;
          }

          .namePosition1 {
            margin-top: 0px display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          `}
          </style>
        </div>
      </div>
    </>
  );
}

export default PracticeList;
