import React, { useEffect, useState } from "react";
import GradingQuestion from "./GradingQuestion";
import GradingHeader from "./GradingHeader";
import GradingCenter from "./GradingCenter";
import styles from "./AllManualGrading.module.scss";
import GradingSummaryModal from "./GradingSummaryModel";
import { GetAssementReview } from "./GetAssessmentReview";
import { GetTotalMarks } from "./GetTotalMarks";
import { useHistory } from "react-router-dom";

const ResumeGrading = (props) => {
  const [assessmentDetails, setAssessmentDetails] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [activeSection, setActiveSection] = useState("");
  const [summaryModal, setSummaryModal] = useState(false);
  const [student, setStudent] = useState({
    name: props?.location?.state?.studentName,
    userId: props?.location?.state?.userId,
    id: props?.location?.state?.id,
    startTime: props?.location?.state?.startTime,
    assessmentName: props?.location?.state?.assessmentName,
    graded: props?.location?.state?.graded,
    published: props?.location?.state?.published,
    department: props?.location?.state?.department,
    completedOn: props?.location?.state?.completedOn,
    allStudents: props?.location?.state?.allStudents,
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [details, setDetails] = useState([])
  useEffect(() => {
    if (
      props?.location?.state?.userId &&
      props?.location?.state?.assessmentId
    ) {
      getAssessmentDetails(
        props?.location?.state?.id,
        props?.location?.state?.assessmentId
      );
    }
  }, []);

  useEffect(() => {
    if (student.id && assessmentDetails?.id) {
      getAssessmentDetails(student.id, assessmentDetails.id);
    }
  }, [student]);

  const getAssessmentDetails = async (userId, assessmentId) => {
    try {
      setLoading(true);
      const details = await GetAssementReview(userId, assessmentId);
      if (details) {
        let temp = {
          id: assessmentId,
          rightAnswers: details?.rightAnswerCount,
          wrongAnswers: details?.wrongAnswerCount,
          total: details?.TotalMarks,
          marks: GetTotalMarks(details),
        };
        temp["sections"] = details.section;
        temp["questions"] = details.section.reduce((acc, val) => {
          acc[val] = details[val].questions;
          return acc;
        }, {});

        setCurrentQuestion(
          temp.questions?.[temp.sections?.[0]]?.[0]
            ? { ...temp.questions[temp.sections[0]][0], serialNo: 1 }
            : {}
        );
        setAssessmentDetails(temp);
        setActiveSection(details.section[0]);
        setLoading(false);
        setDetails(details)
      }
    } catch (err) {}
  };

  const toggleSummaryModal = () => setSummaryModal((p) => !p);
  return (
    <div className={`page-content ${styles.gradingContainer}`}>
      <GradingHeader student={student} status={currentQuestion?.manualGradingCompleted}/>
      <GradingCenter
        loading={loading}
        currentQuestion={currentQuestion}
        activeSection={activeSection}
      />
      <GradingQuestion
        loading={loading}
        setActiveSection={setActiveSection}
        activeSection={activeSection}
        student={student}
        currentQuestion={currentQuestion}
        setCurrentQuestion={setCurrentQuestion}
        allStudents={props?.location?.state?.allStudents || []}
        viewOnly={props?.location?.state?.type === "viewOnly"}
        setStudent={setStudent}
        setAssessmentDetails={setAssessmentDetails}
        assessmentDetails={assessmentDetails}
        toggleSummaryModal={toggleSummaryModal}
        summaryModal={summaryModal}
        details={details}
      />
      
      {assessmentDetails.hasOwnProperty("id") && (
        <GradingSummaryModal
          isOpen={summaryModal}
          assessmentDetails={(assessmentDetails.sections || []).reduce(
            (acc, section) => {
              assessmentDetails.questions[section].forEach((q) => {
                if (q.type === "MC" && q?.solutionMap?.selectedOption) {
                  acc["questionsAnswered"]++;
                  if (q?.solutionMap?.isCorrectOption) {
                    acc["rightAnswers"]++;
                  } else if (
                    q?.solutionMap?.isCorrectOption === false &&
                    q?.solutionMap?.marksGained > 0
                  ) {
                    acc["partialCorrect"]++;
                  } else {
                    acc["wrongAnswers"]++;
                  }
                } else if (q.type === "CD" && q?.solutionMap?.codeSolution) {
                  acc["questionsAnswered"]++;
                  if (q?.solutionMap?.isCorrectOption) {
                    acc["rightAnswers"]++;
                  } else if (
                    q?.solutionMap?.isCorrectOption === false &&
                    q?.solutionMap?.marksGained > 0
                  ) {
                    acc["partialCorrect"]++;
                  } else {
                    acc["wrongAnswers"]++;
                  }
                }
               else if (q.type === "DESC" && q?.solutionMap?.codeSolution) {
                acc["questionsAnswered"]++;
                if (q?.solutionMap?.isCorrectOption) {
                  acc["rightAnswers"]++;
                } else if (
                  q?.solutionMap?.isCorrectOption === false &&
                  q?.solutionMap?.marksGained > 0
                ) {
                  acc["partialCorrect"]++;
                } else {
                  acc["wrongAnswers"]++;
                }
              }
                if (q.manualGradingCompleted) {
                  acc["questionsGraded"]++;
                }
              });
              return acc;
            },
            {
              questionsAnswered: 0,
              questionsGraded: 0,
              rightAnswers: 0,
              wrongAnswers: 0,
              partialCorrect: 0,
              studentName: student.name,
              studentId: student.id,
              assessmentId: assessmentDetails.id,
            }
          )}
          type="summary"
          loading={loading}
          close={() => {
            toggleSummaryModal();
            const allStudents = props?.location?.state?.allStudents || [];
            const idx = allStudents.findIndex((stu) => stu.id === student.id);
            if (
              allStudents[idx + 1]?.id &&
              allStudents[idx + 1]?.startTime &&
              !allStudents[idx + 1]?.graded
            ) {
              setStudent(allStudents[idx + 1]);
            }
          }}
        />
      )}
      <div
        style={{
          width: 202,
          color: "gray",
          padding: "20px 0 0 20px",
          cursor: "pointer",
        }}
        onClick={() => history.goBack()}
        className="d-sm-inline-block"
      >
        <i className="mdi mdi-arrow-left mr-1" /> Back to Manual grading{" "}
      </div>
    </div>
  );
};

export default ResumeGrading;
