import React, { useEffect, useState } from "react";
import GradingQuestion from "./GradingQuestion";
import GradingHeader from './GradingHeader';
import GradingCenter from "./GradingCenter";
import styles from "../../Ecommerce/EcommerceOrders/AllManualGrading.module.scss";
import GradingSummaryModal from "../../Ecommerce/EcommerceOrders/GradingSummaryModel";
// import { GetHackathonReview } from "./GetAssessmentReview";
import { GetTotalMarks } from "../../Ecommerce/EcommerceOrders/GetTotalMarks";
import { useHistory } from "react-router-dom";
import { useParams, Link } from "react-router-dom";
import apiHelper from "api/apiHelper";
import api from "api/baseConfig";

const ResumeGrading = (props) => {
  const [assessmentDetails, setAssessmentDetails] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [activeSection, setActiveSection] = useState("");
  const [summaryModal, setSummaryModal] = useState(false);
  const [detailsForGrading,setDetailsForGrading] = useState();
  const [projectDetails, setProjectDetails] = useState({
            id:"",
            projectName: "",
            description: "",
            submittedFiles: [],
            links: [],
            evaluationStatus:''
  })
  const [projectLink, setProjectLink] = useState()
  
  const [student, setStudent] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (props?.location?.state?.studentId) {
      getAssessmentDetails(props?.location?.state?.studentId);
    }
  }, [student]);

  const idTrim = id.trim()
  const getAssessmentDetails = async (userId) => {
    try {
      setLoading(true);
      const details = await GetHackathonReview(userId);
      setDetailsForGrading(details)
      if (details) {
        setLoading(false);
        let projectDetailsValue = details?.userHackathonSubmission[0] || details?.userHackathonSubmission;
        setProjectDetails({
            evaluationStatus: projectDetailsValue?.evaluationStatus,
            id:projectDetailsValue?.id || "",
            projectName: projectDetailsValue?.projectName || "",
            description: projectDetailsValue?.projectDescription || "",
            submittedFiles: projectDetailsValue?.submissionFile || "",
            links: projectDetailsValue?.link || [],
        })
        temp["questions"] = details.section.reduce((acc, val) => {
          acc[val] = details[val].questions;
          return acc;
        }, {});
        setCurrentQuestion(
          temp.questions?.[temp.sections?.[0]]?.[0]
            ? { ...temp.questions[temp.sections[0]][0], serialNo: 1 }
            : {}
        );
        setAssessmentDetails(temp);
        setActiveSection(details.section[0]);
        
      }
    } catch (err) {}
  };

  const GetHackathonReview = async (studentId) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.Hackathon.getHackathonReview}${idTrim}/${studentId}`
      );
      return data;
    } catch (e) {}
  };
  const toggleSummaryModal = () => setSummaryModal((p) => !p);
  return (
    <div>
    <div className={`${styles.gradingContainer}`}>
      <GradingHeader
        hackathonName={detailsForGrading?.hackathonName}
        studentName={`${detailsForGrading?.firstName}${detailsForGrading?.lastName}`}
        hackathonStartDate={ detailsForGrading?.hackathonStartDate}
        hackathonEndDate={detailsForGrading?.hackathonEndDate}
        projectDetails={projectDetails}
      />
      <GradingCenter
        loading={loading}
        studentName={`${detailsForGrading?.firstName}${detailsForGrading?.lastName}`}
        projectDetails={projectDetails}
        currentQuestion={currentQuestion}
        activeSection={activeSection}
      />
      {projectDetails?.evaluationStatus !== 'Completed' &&
      <GradingQuestion
        loading={loading}
        setActiveSection={setActiveSection}
        activeSection={activeSection}
        student={`${detailsForGrading?.firstName}${detailsForGrading?.lastName}`}
        currentQuestion={currentQuestion}
        setCurrentQuestion={setCurrentQuestion}
        allStudents={props?.location?.state?.allStudents || []}
        viewOnly={props?.location?.state?.type === "viewOnly"}
        setStudent={setStudent}
        setAssessmentDetails={setAssessmentDetails}
        assessmentDetails={projectDetails}
        toggleSummaryModal={toggleSummaryModal}
        summaryModal={projectDetails}
        hackathonDetails={detailsForGrading}
      />
    }
      {assessmentDetails.hasOwnProperty("id")  && (
        <GradingSummaryModal
          isOpen={summaryModal}
          assessmentDetails={(assessmentDetails.sections || []).reduce(
            (acc, section) => {
              assessmentDetails.questions[section].forEach((q) => {
                if (q.type === "MC" && q?.solutionMap?.selectedOption) {
                  acc["questionsAnswered"]++;
                  if (q?.solutionMap?.isCorrectOption) {
                    acc["rightAnswers"]++;
                  } else if (
                    q?.solutionMap?.isCorrectOption === false &&
                    q?.solutionMap?.marksGained > 0
                  ) {
                    acc["partialCorrect"]++;
                  } else {
                    acc["wrongAnswers"]++;
                  }
                } else if (q.type === "CD" && q?.solutionMap?.codeSolution) {
                  acc["questionsAnswered"]++;
                  if (q?.solutionMap?.isCorrectOption) {
                    acc["rightAnswers"]++;
                  } else if (
                    q?.solutionMap?.isCorrectOption === false &&
                    q?.solutionMap?.marksGained > 0
                  ) {
                    acc["partialCorrect"]++;
                  } else {
                    acc["wrongAnswers"]++;
                  }
                }
                if (q.manualGradingCompleted) {
                  acc["questionsGraded"]++;
                }
              });
              return acc;
            },
            {
              questionsAnswered: 0,
              questionsGraded: 0,
              rightAnswers: 0,
              wrongAnswers: 0,
              partialCorrect: 0,
              studentName: student.name,
              studentId: student.id,
              assessmentId: assessmentDetails.id,
            }
          )}
          type="summary"
          loading={loading}
          close={() => {
            toggleSummaryModal();
            const allStudents = props?.location?.state?.allStudents || [];
            const idx = allStudents.findIndex((stu) => stu.id === student.id);
            if (
              allStudents[idx + 1]?.id &&
              allStudents[idx + 1]?.startTime &&
              !allStudents[idx + 1]?.graded
            ) {
              setStudent(allStudents[idx + 1]);
            }
          }}
        />
      )}
      
    </div>
    <div
    style={{
      width: 202,
      color: "gray",
      padding: "20px 0 0 20px",
      cursor: "pointer",
    }}
    onClick={() => history.goBack()}
    className="d-sm-inline-block"
  >
    <i className="mdi mdi-arrow-left mr-1" /> Back to Hackathon{" "}
  </div>
  </div>
  );
};

export default ResumeGrading;
