import React from "react";

export default function PriceTagComponent() {
  return (
    <>
      <div
        className="payment-slide-content"
        style={{
          boxSizing: "border-box",
          borderRadius: "30px",
          padding: "30px 90px 30px 30px",
          backgroundColor: "rgb(245, 246, 250)",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "100%",
          display: "flex",
        }}
      >
        <div
          className="payment-slide-visual-contain"
          style={{
            boxSizing: "border-box",
            borderRadius: "22px",
            padding: "48px",
            overflow: "hidden",
            width: "42%",
            maxWidth: "520px",
            position: "relative",
          }}
        >
          <div
            className="payment-slide-icon-wrap"
            style={{
              boxSizing: "border-box",
              zIndex: 3,
              width: "100%",
              maxWidth: "90px",
              marginBottom: "88px",
              position: "relative",
            }}
          >
            <img
              className="payment-slide-icon"
              src="https://cdn.prod.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa453_money-bills 2.svg"
              style={{
                boxSizing: "border-box",
                border: "0px",
                verticalAlign: "middle",
                display: "inline-block",
                width: "100%",
                maxWidth: "90px",
              }}
            />
          </div>
          <div
            className="payment-slide-visual-header"
            style={{
              boxSizing: "border-box",
              gap: "2px",
              flexFlow: "wrap",
              zIndex: 3,
              marginBottom: "20px",
              display: "flex",
              position: "relative",
              alignItems: "flex-end",
            }}
          >
            <div
              className="paragraph-18px payment-slide-visual-disclamer"
              style={{
                boxSizing: "border-box",
                marginBottom: "0px",
                fontFamily: "Inter, sans-serif",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "156%",
                zIndex: 3,
                color: "white",
                position: "relative",
              }}
            >
              Starting at
            </div>
            <div
              className="payment-flex-contain"
              style={{
                boxSizing: "border-box",
                gap: "4px",
                flexWrap: "wrap",
                display: "flex",
              }}
            >
              <div
                className="heading-56px payment-sldier-visual-price-discounted"
                style={{
                  boxSizing: "border-box",
                  letterSpacing: "-2px",
                  fontSize: "56px",
                  lineHeight: "114%",
                  color: "white",
                  marginBottom: "0px",
                  fontFamily: "Spacegrotesk, sans-serif",
                  fontWeight: 700,
                }}
              >
                ₹7,500
              </div>
              <div
                className="paragraph-18px payment-slide-visual-disclamer"
                style={{
                  boxSizing: "border-box",
                  marginBottom: "0px",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "156%",
                  zIndex: 3,
                  color: "white",
                  position: "relative",
                }}
              >
                per month in flexible EMIs
              </div>
            </div>
          </div>
          <div
            className="payment-slide-visual-check-list-wrap"
            style={{
              boxSizing: "border-box",
              zIndex: 3,
              rowGap: "12px",
              flexDirection: "column",
              alignItems: "flex-start",
              display: "flex",
              position: "relative",
            }}
          >
            <div
              className="payment-slide-visual-check-list-row"
              style={{
                boxSizing: "border-box",
                columnGap: "12px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                className="payement-slide-visual-check-list-icon-wrap"
                style={{
                  boxSizing: "border-box",
                  flex: "0 0 auto",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                }}
              >
                <img
                  className="payment-slide-visual-check-list-icon"
                  src="https://cdn.prod.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa3ba_CheckCirclewhite.svg"
                  style={{
                    boxSizing: "border-box",
                    border: "0px",
                    verticalAlign: "middle",
                    maxWidth: "100%",
                    display: "inline-block",
                    width: "100%",
                  }}
                />
              </div>
              <div
                className="paragraph-18px payment-slide-visual-check-list-text"
                style={{
                  boxSizing: "border-box",
                  marginBottom: "0px",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "156%",
                  opacity: 0.9,
                  color: "white",
                }}
              >
                Intensive technical career coaching.
              </div>
            </div>
            <div
              className="payment-slide-visual-check-list-row"
              style={{
                boxSizing: "border-box",
                columnGap: "12px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                className="payement-slide-visual-check-list-icon-wrap"
                style={{
                  boxSizing: "border-box",
                  flex: "0 0 auto",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                }}
              >
                <img
                  className="payment-slide-visual-check-list-icon"
                  src="https://cdn.prod.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa3ba_CheckCirclewhite.svg"
                  style={{
                    boxSizing: "border-box",
                    border: "0px",
                    verticalAlign: "middle",
                    maxWidth: "100%",
                    display: "inline-block",
                    width: "100%",
                  }}
                />
              </div>
              <div
                className="paragraph-18px payment-slide-visual-check-list-text"
                style={{
                  boxSizing: "border-box",
                  marginBottom: "0px",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "156%",
                  opacity: 0.9,
                  color: "white",
                }}
              >
                Personalized mentorship.
              </div>
            </div>
            <div
              className="payment-slide-visual-check-list-row"
              style={{
                boxSizing: "border-box",
                columnGap: "12px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                className="payement-slide-visual-check-list-icon-wrap"
                style={{
                  boxSizing: "border-box",
                  flex: "0 0 auto",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                }}
              >
                <img
                  className="payment-slide-visual-check-list-icon"
                  src="https://cdn.prod.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa3ba_CheckCirclewhite.svg"
                  style={{
                    boxSizing: "border-box",
                    border: "0px",
                    verticalAlign: "middle",
                    maxWidth: "100%",
                    display: "inline-block",
                    width: "100%",
                  }}
                />
              </div>
              <div
                className="paragraph-18px payment-slide-visual-check-list-text"
                style={{
                  boxSizing: "border-box",
                  marginBottom: "0px",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "156%",
                  opacity: 0.9,
                  color: "white",
                }}
              >
                Lifetime access to Pesto community.
              </div>
            </div>
            <div
              className="payment-slide-visual-check-list-row"
              style={{
                boxSizing: "border-box",
                columnGap: "12px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                className="payement-slide-visual-check-list-icon-wrap"
                style={{
                  boxSizing: "border-box",
                  flex: "0 0 auto",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                }}
              >
                <img
                  className="payment-slide-visual-check-list-icon"
                  src="https://cdn.prod.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa3ba_CheckCirclewhite.svg"
                  style={{
                    boxSizing: "border-box",
                    border: "0px",
                    verticalAlign: "middle",
                    maxWidth: "100%",
                    display: "inline-block",
                    width: "100%",
                  }}
                />
              </div>
              <div
                className="paragraph-18px payment-slide-visual-check-list-text"
                style={{
                  boxSizing: "border-box",
                  marginBottom: "0px",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "156%",
                  opacity: 0.9,
                  color: "white",
                }}
              >
                14-days money-back option.
              </div>
            </div>
          </div>
          <div
            className="payment-slide-visual-bg"
            style={{
              boxSizing: "border-box",
              inset: "0%",
              zIndex: 1,
              backgroundImage:
                "linear-gradient(135deg, rgb(152, 110, 235), rgb(253, 104, 179) 40%, rgb(255, 207, 174))",
              width: "125%",
              height: "117%",
              position: "absolute",
            }}
          />
        </div>
        <div
          className="payment-slide-text-top"
          style={{
            boxSizing: "border-box",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "45%",
            maxWidth: "513px",
            display: "flex",
          }}
        >
          <div
            className="heading-40px payment-slide-heading"
            style={{
              boxSizing: "border-box",
              color: "#000",
              fontFamily: "Inter, sans-serif",
              fontSize: "40px",
              fontWeight: 600,
              lineHeight: "120%",
              letterSpacing: "-0.8px",
              marginBottom: "20px",
            }}
          >
            One-time fee for benefits that last a lifetime.
          </div>
          <div
            className="paragraph-18px payment-slide-desc"
            style={{
              boxSizing: "border-box",
              color: "#060606",
              fontFamily: "Inter, sans-serif",
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "156%",
              marginBottom: "36px",
            }}
          >
            Dedicated, qualified and personalized coaching (and much more) at a
            total of ₹1.8 Lacs* *(Inclusive of taxes)
          </div>
          <a
            className="colored-button w-inline-block"
            href="https://apps.pesto.tech/developer/registration"
            target="_blank"
            style={{
              boxSizing: "border-box",
              backgroundColor: "rgba(0, 0, 0, 0)",
              textDecoration: "none",
              color: "#060606",
              maxWidth: "100%",
              borderRadius: "10px",
              padding: "1px",
              transition: "box-shadow 0.7s",
              overflow: "hidden",
              zIndex: 3,
              cursor: "pointer",
              justifyContent: "space-between",
              alignItems: "stretch",
              display: "none",
              position: "relative",
            }}
          >
            <div
              className="colored-button-inner gap-24px"
              style={{
                boxSizing: "border-box",
                borderRadius: "10px",
                padding: "14px 24px 14px 28px",
                zIndex: 3,
                backgroundImage: "none",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                display: "flex",
                position: "relative",
                columnGap: "24px",
              }}
            >
              <div
                className="paragraph-18px colored-button-text"
                style={{
                  boxSizing: "border-box",
                  marginBottom: "0px",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "18px",
                  lineHeight: "156%",
                  zIndex: 3,
                  color: "white",
                  letterSpacing: "-0.36px",
                  textShadow: "rgba(255, 255, 255, 0.13) 0px 0px",
                  fontWeight: 500,
                  position: "relative",
                }}
              >
                Start your 2-week trial
              </div>
              <img
                className="colored-button-icon"
                src="https://cdn.prod.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa35e_ArrowRight.svg"
                style={{
                  boxSizing: "border-box",
                  border: "0px",
                  verticalAlign: "middle",
                  maxWidth: "100%",
                  display: "inline-block",
                  zIndex: 3,
                  position: "relative",
                }}
              />
              <div
                className="button-black-overlay"
                style={{
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  inset: "2px",
                  zIndex: 2,
                  backgroundColor: "#000",
                  backdropFilter: "blur(100px)",
                  position: "absolute",
                  opacity: 0,
                }}
              />
              <div
                className="colored-button-gradient-layer"
                style={{
                  boxSizing: "border-box",
                  inset: "0%",
                  zIndex: 1,
                  backgroundImage:
                    "linear-gradient(135deg, rgb(152, 110, 235), rgb(253, 104, 179) 11.11%, rgb(253, 183, 134) 22.22%, rgb(253, 183, 134) 33.33%, rgb(253, 183, 134) 44.44%, rgb(152, 110, 235) 55.55%, rgb(253, 104, 179) 66.66%, rgb(253, 183, 134) 77.77%, rgb(253, 183, 134) 88.88%, rgb(253, 183, 134))",
                  width: "400%",
                  position: "absolute",
                  transform:
                    "translate3d(-39.0143%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                  willChange: "transform",
                }}
              />
            </div>
            <div
              className="colored-button-border-layer"
              style={{
                boxSizing: "border-box",
                borderRadius: "50%",
                inset: "50% 0% 0%",
                backgroundColor: "rgb(62, 62, 62)",
                backgroundImage:
                  "linear-gradient(rgb(62, 62, 62), rgb(181, 181, 206) 57%, rgb(206, 161, 226))",
                width: "100%",
                height: "100%",
                display: "none",
                position: "absolute",
                transform: "translateY(-50%)",
              }}
            />
          </a>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font-family: sans-serif;
  height: 100%;
}

body {
  box-sizing: border-box;
  margin: 0px;
  background-color: rgb(255, 255, 255);
  min-height: 100%;
  color: #000;
  letter-spacing: normal;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 156%;
}
`,
        }}
      />
    </>
  );
}
