import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Dialog, DialogContent, DialogActions, Button } from "@material-ui/core";
import { ProfileSlider } from "./ProfileSlider";
import EditUserExtra from "./EditUserExtra";
import EditUserSkill from "./EditUserSkill";
import EditUserPersonal from "./EditUserPersonal";
import EditUserEducation from "./EditUserEducation";
import EditUserCertificate from "./EditUserCertificate";
import EditUserWorkshops from "./EditUserWorkshops";

// import { sessionPrivileges } from "privileges";
// import { ProfileCompletion } from "./ProfileCompletetion";

// API
import Apihelper from "../../api/apiHelper";
import api from "../../api/baseConfig";

// Redux
import { connect } from "react-redux";

// css
import "./UserProfile.css";
import avatar from "../../assets/images/users/default-avatar.svg";

function EditUserProfile(props) {
  const [pageCount, setPageCount] = useState(1);
  const [profileComplitedLevel, setProfileComplitedLevel] = useState(87);
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState({"id": ""});
  
  const handlePageCount = (count) => setPageCount(count);
  const updateUserData = (data) => setUser({...user, ...data});

  const submitUserData = async (data, url) => {
    let tempUser = {id: userId};
    for(const key in data) {
      if(data[key]["value"] !== undefined)
        tempUser[key] = data[key]["value"]
      else if(Array.isArray(data[key])) {
        const tempArray = [];
        data[key].length > 0 && data[key].map((d,i) => {
          if(data[key][i]["value"] !== undefined) tempArray.push(data[key][i]["value"]);
          else if(typeof data[key][i] === "object") {
            const tempObj = {};
            for (const subKey in data[key][i]) {
              if(data[key][i][subKey]["value"] !== undefined ) {
                tempObj[subKey] = data[key][i][subKey]["value"];
              }
            }
            tempArray.push({...tempObj})
          }
        });
        tempUser[key] = tempArray;
      } else if(typeof data[key] === "object") {
        const tempObj = {};
        for (const subKey in data[key]) {
          if(data[key][subKey]["value"] !== undefined) tempObj[subKey] = data[key][subKey]["value"];
        }
        tempUser[key] = tempObj;
      }
    }
    try {
      const data = await Apihelper.axiosCallTokenData(
        `${api.baseURL}${url}`,
        "POST",
        tempUser
      );
      if (data && data.id && data.id !== "") {
        setUser({...user, ...tempUser});
        return true;
      } else return false;
    } catch (e) {
      console.log("Error: while saving user infomation.")
      console.log(e);
      return false;
    }
  };
  const getUserInfo = async (email) => {
    try {
      const data = await Apihelper.axiosCallNoUserId(
        `${api.baseURL}${api.userManagement.getUserInfo}${email}`,
        "get"
      );
      if (data && data.id && data.id !== "") {
        setUserId(data.id)
        setUser({...user, ...data});
      }
    } catch (e) {
      console.log("Error: while feaching user infomation.")
      console.log(e);
    }
  }
  
  
  useEffect(()=>{
    const tempUser = props.UserData;
    if(tempUser && tempUser.emailId)
      getUserInfo(tempUser.emailId);
  },[props.UserData]);

  return (
    <React.Fragment>
      <div id="profile-page">
        <div className="profile-container">
          <div className="profile-hero ">
            <div className="hero-img blur" style={{backgroundImage: "url('/assets/profile-hero-img.png')"}}></div>
          </div>
          <div className="profile-body">
            <div className="profile-header">
              <div className="flex-box flex-100 flex-bottom">
                <div className="flex-box">
                  <div className="flex-auto">
                    <div className="profile-image">
                      <img
                        src={avatar}
                        alt={user.id && `${user.firstName} ${user.lastName}`}
                        className="rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="profile-info">
                      <h3>{user.id && `${user.firstName} ${user.lastName}`}</h3>
                      <p>{user.id && `${user.emailId}`}</p>
                    </div>
                  </div>
                  <div className="flex-1"></div>
                </div>
                <div className="flex-1 profile-slider center">
                  <div className="flex-box flex-middle flex-gap-2">
                    <span>Start</span>
                    <ProfileSlider valueLabelDisplay="auto" aria-label="profile slider"
                      value={profileComplitedLevel} step={1} min={0} max={100} />
                    <span>Finish</span>
                  </div>
                  <p>{`${profileComplitedLevel}% of profile has been Updated`}</p>
                </div>
              </div>
            </div>
            <div className="br"></div>
            <div className="form-page">
              {/* handlePageCount */}
              {pageCount === 1
                ? (<EditUserPersonal pageCount={pageCount} userData={user} handlePageCount={handlePageCount} submitUserData={submitUserData} history={props.history} />)
                : pageCount === 2 ? (<EditUserEducation pageCount={pageCount} userData={user} handlePageCount={handlePageCount} submitUserData={submitUserData} history={props.history} />)
                : pageCount === 3 ? (<EditUserSkill pageCount={pageCount} userData={user} handlePageCount={handlePageCount} submitUserData={submitUserData} history={props.history} />)
                : pageCount === 4 ? (<EditUserCertificate pageCount={pageCount} userData={user} handlePageCount={handlePageCount} submitUserData={submitUserData} history={props.history} />)
                : pageCount === 5 ? (<EditUserExtra pageCount={pageCount} userData={user} handlePageCount={handlePageCount} submitUserData={submitUserData} history={props.history} />)
                : (<EditUserWorkshops pageCount={pageCount} userData={user} handlePageCount={handlePageCount} submitUserData={submitUserData} history={props.history} />)
              }
            </div>            
          </div>
        </div>
      </div>
      {/* onClose={handleClose} */}
      <Dialog open={false} >
        <DialogContent>
          Are you sure want to delete design certificate
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            // onClick={handleClose}
            disableElevation
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            // onClick={handleDelete}
            disableElevation
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { UserData } = state.UserProfileReducer;
  return { UserData };
};

export default withRouter(connect(mapStateToProps, null)(EditUserProfile));