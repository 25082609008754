import { useMemo, useRef } from 'react';
import Editor from '@monaco-editor/react';

export default function CanvasComponent({ id, isLightMode, logActivity }) {
  const defaultValue = useMemo(() => {
    try {
      const text = sessionStorage.getItem(`practice-${id}-ws-text`);

      if (text) {
        return JSON.parse(text);
      }
    } catch {
      return '';
    }
  }, [id]);
  const saveTimer = useRef(null);

  return (
    <Editor
      editor
      defaultValue={defaultValue}
      language="text"
      onChange={(val = '') => {
        if (saveTimer.current) {
          clearTimeout(saveTimer.current);
          saveTimer.current = null;
        }

        sessionStorage.setItem(
          `practice-${id}-ws-text`,
          JSON.stringify(val),
        );

        saveTimer.current = setTimeout(() => {
          logActivity?.('workspace-text');
        }, 1000);
      }}
      theme={isLightMode ? 'light' : 'vs-dark'}
      options={{
        autoIndent: 'advanced',
        contextmenu: false,
        fontFamily: 'Fira Code',
        fontLigatures: true,
        fontSize: 16,
        formatOnPaste: true,
        matchBrackets: 'always',
        minimap: {
          enabled: false,
        },
        padding: {
          bottom: 12,
          top: 12,
        },
        quickSuggestions: false,
        smoothScrolling: true,
        snippetSuggestions: 'none',
      }}
    />
  );
}
