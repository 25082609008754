import React from "react";

export default function InterviewPreparation() {
  return (
    <>
      <div
        className="flex flex-wrap justify-center gap-x-4 gap-y-4"
        style={{
          margin: "0px",
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          columnGap: "1rem",
          rowGap: "1rem",
          width:"80%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div
          className="relative w-full p-5 md:p-6 sm:w-auto sm:max-w-[320px] flex md:flex-col gap-3 gap-y-3 rounded-[30px] bg-v5-green-500 InfoCard-module--card--bS7T6"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            position: "relative",
            display: "flex",
            gap: "0.75rem",
            rowGap: "0.75rem",
            borderRadius: "30px",
            backgroundColor: "#5E35B1",
            width: "auto",
            maxWidth: "320px",
            flexDirection: "column",
            padding: "1.5rem",
          }}
        >
          <div
            className="undefined"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
            }}
          >
            <div
              className="flex w-[60px] items-center justify-center"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                width: "60px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  position: "relative",
                  display: "inline-block",
                  verticalAlign: "top",
                  maxHeight: "100%",
                }}
              >
                <div
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    maxWidth: "200px",
                    display: "block",
                  }}
                >
                  <img
                    aria-hidden="true"
                    role="presentation"
                    src="data:image/svg+xml;charset=utf-8,<svg height='200' width='200' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      pointerEvents: "none",
                      verticalAlign: "middle",
                      inset: "0px",
                      margin: "0px",
                      padding: "0px",
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      maxWidth: "100%",
                      display: "block",
                      position: "static",
                    }}
                  />
                </div>
                <img
                  aria-hidden="true"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAACE4AAAhOAFFljFgAAADrElEQVQ4y42Uf0hdZRjH33vvWLE0/yliUVFJXnc951yzX9C//beJCa1oBCNjUM2splMn1VaN1my72nTqImLVzFjOXKayNhTtvvfXqlUQtdYcJVQQY0jOe96r3vOJc+6516tk9McD7/n1Od/vw/N8hWZKoSl5j2bKMV1JpSuJZkYsXUlLVxHs0kzpVPasq4ilmZnnmimTmpKjmpJlNkvoSt6rKZnSzQiaDTCllQWsWkm3lqBoSs7oKlIqNFOOOzDTVmf/OYyWDFtacgkcXIhjLMYwUlGMhRjBdMIyFuNWHlRlBMkTwgXlbBiLCauCBHdbcSrSMSqsGLdd7Mc//TmBmXGK/zjFHZc+o3h6GCMdt5bZV3JG6Hk2jVTEKpka4Prwh1x75j08J3sQfYcQ7bsRoVcQPa+x9nATorMZse8F1h9vw7ASlpYM5+wL55CxZgUXYtw32kRxz1M82FdD5cATbB1+jPqJzXR8X8ngxYd49JtaxFgn4ujLXPf8NhtIXnssW6EDNExp+VWC8Fw1V68G+FsFSaU0IACUcP6nDbz46UZ8HY14ol2I3j0UNm1fBrRZWcsYSlJiJognq0gnS7g8G0Slg3z1g8HkhQfYcKYFUfccouZxfBOHEMf2ULDz2SwwN1ZLQFNiK4wlqzBn/aQo58cLGptCG1m392nE/nrWHNmFp70B7+k2RO+r/w8o5x7Gmvfzy1QZ97c+wrrJEJ6RA3gPNeKd6MAz1Ip3sgPxwW4KGv8L6FpOmFWwcCeVfVsQJzvwyU6842/jjXfjjXbhTfTg++5dxPG9FDQ8szpQdxVOzlax4/yTCHkE33gb3i8O4pWH8Q61It5pQYTqEe0NiG1bKGyuxeAs7tgsB2Y2Ikb51DHES3V46mpYW1uDr6sF8fHriIM7KGzczk1H3+Tm3gPcOtyNf3oEfT66bNedscntsIoQUHECl4YI/DqC/6/TrOnfh6+6mmtGQhR98hYVfEuQcwT52lnH7LhkNiVi5QbbXaGM/XScIGe54bcBxNbN3Lh/F6XEEd3NrO9vd/pWNvul0zs3HLLfZuYwd8OFanNhB1o0GMKzqZLS309Rzjluib5P0Rs70VNRJ5ly3+Q5tBUms1BXegY+H6Xs8hh3/TyIvhBzGm8njQNz+5V5d1k2XrGBo+6FWqHUsqHZsVgRtPkQG2pmgll+JHRTBnTTCUdW2HfDNMxqyZ1J9tyzPzUlb7cT24aWaqY8obngPPv8ezmqLFfVFVuZDbNZ/wDOijfEu1wXbwAAAABJRU5ErkJggg=="
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    pointerEvents: "none",
                    display: "block",
                    verticalAlign: "middle",
                    margin: "0px",
                    padding: "0px",
                    height: "100%",
                    maxWidth: "none",
                    width: "100%",
                    transition: "opacity 500ms linear",
                    inset: "0px",
                    opacity: 0,
                    backgroundColor: "transparent",
                    position: "absolute",
                    objectFit: "cover",
                  }}
                />
                <picture
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    display: "block",
                    maxWidth: "100%",
                  }}
                >
                  <source
                    type="image/webp"
                    sizes="(min-width: 200px) 200px, 100vw"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                    }}
                  />
                  <img
                    height={60}
                    width={60}
                    alt="Highlight"
                    sizes="(min-width: 200px) 200px, 100vw"
                    src="https://www.crio.do/static/c5c0286677289f6e823f015c5192f7d5/20dc4/PointSeven.png"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      pointerEvents: "none",
                      display: "block",
                      verticalAlign: "middle",
                      inset: "0px",
                      margin: "0px",
                      padding: "0px",
                      height: "100%",
                      maxWidth: "none",
                      position: "absolute",
                      width: "100%",
                      transition: "opacity 0.25s linear",
                      transform: "translateZ(0px)",
                      willChange: "opacity",
                      objectFit: "contain",
                      opacity: 1,
                    }}
                  />
                </picture>
                <noscript
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                >{`<picture><source type="image/webp" srcSet="/static/c5c0286677289f6e823f015c5192f7d5/1a2eb/PointSeven.webp 200w,
/static/c5c0286677289f6e823f015c5192f7d5/5de9a/PointSeven.webp 300w" sizes="(min-width: 200px) 200px, 100vw"/><img width="200" height="200" data-main-image="" style="object-fit:contain;opacity:0" sizes="(min-width: 200px) 200px, 100vw" decoding="async" loading="lazy" src="/static/c5c0286677289f6e823f015c5192f7d5/20dc4/PointSeven.png" srcSet="/static/c5c0286677289f6e823f015c5192f7d5/20dc4/PointSeven.png 200w,
/static/c5c0286677289f6e823f015c5192f7d5/9c1f1/PointSeven.png 300w" alt="Highlight"/></picture>`}</noscript>
              </div>
            </div>
          </div>
          <div
            className="flex flex-col gap-2"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <p
              className="text-v5-neutral-300 leading-relaxed"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                lineHeight: 1.625,
                color: "rgb(185 207 202/1)",
              }}
            >
              <div
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                }}
              >
                <p
                  className="text-v5-neutral-100"
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflowWrap: "break-word",
                    margin: "0px",
                    color: "rgb(255 255 255/1)",
                  }}
                >
                  1:1 Interview preparation (Mock Interviews) before technical
                  interview rounds with our hiring partners
                </p>
              </div>
            </p>
          </div>
        </div>
        <div
          className="relative w-full p-5 md:p-6 sm:w-auto sm:max-w-[320px] flex md:flex-col gap-3 gap-y-3 rounded-[30px] bg-v5-green-500 InfoCard-module--card--bS7T6"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            position: "relative",
            display: "flex",
            gap: "0.75rem",
            rowGap: "0.75rem",
            borderRadius: "30px",
            backgroundColor: "#5E35B1",
            width: "auto",
            maxWidth: "320px",
            flexDirection: "column",
            padding: "1.5rem",
          }}
        >
          <div
            className="undefined"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
            }}
          >
            <div
              className="flex w-[60px] items-center justify-center"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                width: "60px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  position: "relative",
                  display: "inline-block",
                  verticalAlign: "top",
                  maxHeight: "100%",
                }}
              >
                <div
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    maxWidth: "200px",
                    display: "block",
                  }}
                >
                  <img
                    aria-hidden="true"
                    role="presentation"
                    src="data:image/svg+xml;charset=utf-8,<svg height='200' width='200' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      pointerEvents: "none",
                      verticalAlign: "middle",
                      inset: "0px",
                      margin: "0px",
                      padding: "0px",
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      maxWidth: "100%",
                      display: "block",
                      position: "static",
                    }}
                  />
                </div>
                <img
                  aria-hidden="true"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAD4ElEQVQ4y22UXWxURRTHz3azaiQxGjF+JLbFbYEusx9FGsxiKbRECGmihkKthZhAQmIrUt1u1xg1plFCgjwIDzb6okaDGhJislEJEMLuXXZbSmts8KlF+bK2VEKx986Ulvmbmbl7cx98mMydybm//M//nDnEuEVMWBEmrGxUWE6UF8CEJaN6FfD/y5JMFGSUWyrWYaKQ1QxeIAPj1m0VyLgJNoEFqD3Bc4jzHBi39NnEmW8DVXH67jbjVoQYV8r0hfDBZIznZUQUUSlGsYyPIjZfBON5AxMFybills6GCfWvZmQV0HEPCiZdGGrFEJL8JPqdj7Cff4zwnZ8QX7yA6EIRzMmXlRqwa49ikSddXbiw5WIIL/LvMTHfBKASA1caQZcyePLUQUSmTyEmh8pKjVpjj97JM9co1N7U8SLyYhumJ2tx9uIaVPW/BLqWQfDoDjy4dxvCY98idm9QK/UscKFKYbmaGqh8e9Y+gztowA9nVmPl7haESt2g6QwCqe2g5HosSe3yp+3+aywgrxXKVVQKRRFf2t2Q9gpkJxsRmkqDBl4DNTSDkhsR6tiOVf+eQ3T+vAYy7nlYBnptoNukWoyg2f4R/9hrcfTWFtDEOwjmu0CHOkFNLXjq+BHEMQRm5/zF8QFdWJznERbD6OBfY8J5HpKvRNtfnQZ4o1enXfFzF6ryXyAmBz2FbhuVgQZmqjuILfwEbjhrsegsx5Rdj+qrXaDLaQQmUqDxFOjvPlR82o5HD7+FVbaXti9l17cYtzTwF6cDi3YtHCcGa3YdAn+kQJd7QWpX0KtpBC7uR7B9I5YOZNxq+1JWz02lWiMu6FTnbIYZOw7wOhy+uRU0nkFw/G2jTgH/7AVdSSP0Xhvue3Uzwpe+U30p3arrlKV6q1ViVB5x+rAwV4MZux5SRPDKzU7Q9XcRnOrT/tG1tFH7Ww/u/2AHQh2b8MSxfsQxLJmd91JWkwUreEme422AfAb2QgLXZ+tRPbYXdPINVHyzB/TJTlBvG2hnK6ilBYEXmkFb1+GRQ/sQXSj5imI8lJH5EvZNHpBvftaIDa8/h8r2DaCmZlBDI4glQYn1oOQmVGxuxQO7d+Gh93vw2MCHCI8dk9G7Ra91lIeOKb0llznDcumvWRlM94BaX8aS7j14+EAaj391EE+f/hw1vx9H3cxp/Upi90pIYERq/8pVFgU9HLzxFRd5uRqDqEdJstmzOkg1cAIjyifTewtF03eqsnZOwVWccEeZHl8RMxzNE1QBWvHd8+Uz2FwO2nR3GPiW9AazYUTUSylDs/7Z6HtO3qjydj2IzYRR/yhlihEVFv0Hk3UxvVHSJMMAAAAASUVORK5CYII="
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    pointerEvents: "none",
                    display: "block",
                    verticalAlign: "middle",
                    margin: "0px",
                    padding: "0px",
                    height: "100%",
                    maxWidth: "none",
                    width: "100%",
                    transition: "opacity 500ms linear",
                    inset: "0px",
                    opacity: 0,
                    backgroundColor: "transparent",
                    position: "absolute",
                    objectFit: "cover",
                  }}
                />
                <picture
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    display: "block",
                    maxWidth: "100%",
                  }}
                >
                  <source
                    type="image/webp"
                    sizes="(min-width: 200px) 200px, 100vw"
                    srcSet="https://www.crio.do/static/502fcf479d1158103d84964a81cbebc0/1a2eb/PointOne.webp 200w,/static/502fcf479d1158103d84964a81cbebc0/f0b89/PointOne.webp 600w"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                    }}
                  />
                  <img
                    height={60}
                    width={60}
                    alt="Highlight"
                    sizes="(min-width: 200px) 200px, 100vw"
                    src="https://www.crio.do/static/502fcf479d1158103d84964a81cbebc0/20dc4/PointOne.png"
                    srcSet={`/static/502fcf479d1158103d84964a81cbebc0/20dc4/PointOne.png 200w,
/static/502fcf479d1158103d84964a81cbebc0/f159a/PointOne.png 600w`}
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      pointerEvents: "none",
                      display: "block",
                      verticalAlign: "middle",
                      inset: "0px",
                      margin: "0px",
                      padding: "0px",
                      height: "100%",
                      maxWidth: "none",
                      position: "absolute",
                      width: "100%",
                      transition: "opacity 0.25s linear",
                      transform: "translateZ(0px)",
                      willChange: "opacity",
                      objectFit: "contain",
                      opacity: 1,
                    }}
                  />
                </picture>
                <noscript
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                >{`<picture><source type="image/webp" srcSet="/static/502fcf479d1158103d84964a81cbebc0/1a2eb/PointOne.webp 200w,
/static/502fcf479d1158103d84964a81cbebc0/f0b89/PointOne.webp 600w" sizes="(min-width: 200px) 200px, 100vw"/><img width="200" height="200" data-main-image="" style="object-fit:contain;opacity:0" sizes="(min-width: 200px) 200px, 100vw" decoding="async" loading="lazy" src="/static/502fcf479d1158103d84964a81cbebc0/20dc4/PointOne.png" srcSet="/static/502fcf479d1158103d84964a81cbebc0/20dc4/PointOne.png 200w,
/static/502fcf479d1158103d84964a81cbebc0/f159a/PointOne.png 600w" alt="Highlight"/></picture>`}</noscript>
              </div>
            </div>
          </div>
          <div
            className="flex flex-col gap-2"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <p
              className="text-v5-neutral-300 leading-relaxed"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                lineHeight: 1.625,
                color: "rgb(185 207 202/1)",
              }}
            >
              <div
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                }}
              >
                <p
                  className="text-v5-neutral-100"
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflowWrap: "break-word",
                    margin: "0px",
                    color: "rgb(255 255 255/1)",
                  }}
                >
                  Soft-Skills training coupled with pre-training and
                  post-training assessments
                </p>
              </div>
            </p>
          </div>
        </div>
        <div
          className="relative w-full p-5 md:p-6 sm:w-auto sm:max-w-[320px] flex md:flex-col gap-3 gap-y-3 rounded-[30px] bg-v5-green-500 InfoCard-module--card--bS7T6"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            position: "relative",
            display: "flex",
            gap: "0.75rem",
            rowGap: "0.75rem",
            borderRadius: "30px",
            backgroundColor: "#5E35B1",
            width: "auto",
            maxWidth: "320px",
            flexDirection: "column",
            padding: "1.5rem",
          }}
        >
          <div
            className="undefined"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
            }}
          >
            <div
              className="flex w-[60px] items-center justify-center"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                width: "60px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  position: "relative",
                  display: "inline-block",
                  verticalAlign: "top",
                  maxHeight: "100%",
                }}
              >
                <div
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    maxWidth: "200px",
                    display: "block",
                  }}
                >
                  <img
                    aria-hidden="true"
                    role="presentation"
                    src="data:image/svg+xml;charset=utf-8,<svg height='200' width='200' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      pointerEvents: "none",
                      verticalAlign: "middle",
                      inset: "0px",
                      margin: "0px",
                      padding: "0px",
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      maxWidth: "100%",
                      display: "block",
                      position: "static",
                    }}
                  />
                </div>
                <img
                  aria-hidden="true"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAACE4AAAhOAFFljFgAAAEAUlEQVQ4y42Va0wUVxTHzw5IUtM0ad1qYqpIY8y6zuyAoAltbUjT0A/9QlKNSR8hWpFWsCSLCwpiu25hKxW3RRCEUkRZG1slGKkWK8W6d9kFu7Tphz5spIakNURrbVJnBpD7b+7s7IKNfXw4ybzub845c89vSNYZyQbLlnU2oBjMUAwGWQ9zxWBcMcKQdXZPiGuKzrisJ+9rssHOygZbJVikGCxHNtikoochxx/g5uIE7E5oNrRQ/JrGEsezLzXYbcUIO0jW2RcmTBfZhbm1gJthhKHiK6iIWTEC9W4UKh/mKh/hynQkATXiCbGTZIFgwbg6HeGZM1Fk8SgyDYYlQT8W1Zbh4ZKXsbjThxU3+rH0xx489s1xOK73c2VqKF6+lSUpc8p0TUd4+s9n8OBnrUjrawF17gOtyQM5nwCp60CFr4Aaa0ANblDNa7B3+kTWfG75lOyZFuLqzDDSwy1YENgKZ1cxnvxoE17ofQmvX9iIPSPr0fhdAXrHnkXBt+WgE34s7PJBrDHbE/9oXGRopqvqIf64EUP9n1WYMhy4eScLk1Mybv0hY2LCiWvjq3Dl+xUIXlyL+e0e0HEvHg3WmT1NAAWLrG0ggEjXYujgFegfXAlHUT78Hz+FphOZKG3IwY4DmVjvfxpUvhVUXQzqrIY96E8Ak1vqb8BRfAgPXn0nF5RbjALfMwgEM/FGfRbcgWws924A1RQjpa4EdLQG9u66fwcu02M4NFWBq2MOtJ3LxdivLlz9xYUr4y7kRcpB/Ycw70IDpPMHQKfehv1obaKH/wxsnqzE7xPL0da3FpEfstEz4MLG1udA3lKk1BTBVrkZ0q4t/6/kpdooOuFB6f41oNVbsGFvHtxd60Bl25C6czNsnk2wuQshVReBjuyGPVj3Xz2M4YMZDzrOrEa2+3l4TuVjUdt2UH0ZpMZy2Np2wdb9JqSeOtAnPtiP3b/k5LbJMGIIaDuByQyM386GHPOCLh1GaqQJtqFmSOFmSKGDkIaaQT21c3uYmH9OibERQNHDJr0C0DKQ/7UHdL4FaYMBSBcbIV06CCnUZEJTLh8GnXsXC474oN4dTk6aYm1s8yD+UUZ5k+bB7p9eBA22I/XLAKh/P+i03yyRuveAOqpArZUgTyEWtnuh4nJy9GRr9LSk46aiPOfmaZ723g7Me2sbHvCWYP7e7XhonxuPNFaZgMXH/FjS+z4yBtrgvPH5rBziTrglgGfjJ0JBjDsno1g5/il3Xuvj8m8DcAmFzQxD5SMiG0tjo1xFTMASUtDjYmZBUnTmVHQhx7hUBdQ1E4UiQiwwLFtrlmDnCle/x+rXZYMtE8YWUIess5OyCbZ0NmsQ3OdXwK3fhFmmyEzABOsvaX8eUWJPg3MAAAAASUVORK5CYII="
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    pointerEvents: "none",
                    display: "block",
                    verticalAlign: "middle",
                    margin: "0px",
                    padding: "0px",
                    height: "100%",
                    maxWidth: "none",
                    width: "100%",
                    transition: "opacity 500ms linear",
                    inset: "0px",
                    opacity: 0,
                    backgroundColor: "transparent",
                    position: "absolute",
                    objectFit: "cover",
                  }}
                />
                <picture
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    display: "block",
                    maxWidth: "100%",
                  }}
                >
                  <source
                    type="image/webp"
                    sizes="(min-width: 200px) 200px, 100vw"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                    }}
                  />
                  <img
                    height={60}
                    width={60}
                    alt="Highlight"
                    sizes="(min-width: 200px) 200px, 100vw"
                    src="https://www.crio.do/static/8508983d9df347ba5c26cd1b3cc47174/20dc4/PointSix.png"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      pointerEvents: "none",
                      display: "block",
                      verticalAlign: "middle",
                      inset: "0px",
                      margin: "0px",
                      padding: "0px",
                      height: "100%",
                      maxWidth: "none",
                      position: "absolute",
                      width: "100%",
                      transition: "opacity 0.25s linear",
                      transform: "translateZ(0px)",
                      willChange: "opacity",
                      objectFit: "contain",
                      opacity: 1,
                    }}
                  />
                </picture>
                <noscript
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                >{`<picture><source type="image/webp" srcSet="/static/8508983d9df347ba5c26cd1b3cc47174/1a2eb/PointSix.webp 200w,
/static/8508983d9df347ba5c26cd1b3cc47174/5de9a/PointSix.webp 300w" sizes="(min-width: 200px) 200px, 100vw"/><img width="200" height="200" data-main-image="" style="object-fit:contain;opacity:0" sizes="(min-width: 200px) 200px, 100vw" decoding="async" loading="lazy" src="/static/8508983d9df347ba5c26cd1b3cc47174/20dc4/PointSix.png" srcSet="/static/8508983d9df347ba5c26cd1b3cc47174/20dc4/PointSix.png 200w,
/static/8508983d9df347ba5c26cd1b3cc47174/9c1f1/PointSix.png 300w" alt="Highlight"/></picture>`}</noscript>
              </div>
            </div>
          </div>
          <div
            className="flex flex-col gap-2"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <p
              className="text-v5-neutral-300 leading-relaxed"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                lineHeight: 1.625,
                color: "rgb(185 207 202/1)",
              }}
            >
              <div
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                }}
              >
                <p
                  className="text-v5-neutral-100"
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflowWrap: "break-word",
                    margin: "0px",
                    color: "rgb(255 255 255/1)",
                  }}
                >
                  Series of mock assessments and detailed interview prep sprints
                  to ace top tech jobs
                </p>
              </div>
            </p>
          </div>
        </div>
        <div
          className="relative w-full p-5 md:p-6 sm:w-auto sm:max-w-[320px] flex md:flex-col gap-3 gap-y-3 rounded-[30px] bg-v5-green-500 InfoCard-module--card--bS7T6"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            position: "relative",
            display: "flex",
            gap: "0.75rem",
            rowGap: "0.75rem",
            borderRadius: "30px",
            backgroundColor: "#5E35B1",
            width: "auto",
            maxWidth: "320px",
            flexDirection: "column",
            padding: "1.5rem",
          }}
        >
          <div
            className="undefined"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
            }}
          >
            <div
              className="flex w-[60px] items-center justify-center"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                width: "60px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  position: "relative",
                  display: "inline-block",
                  verticalAlign: "top",
                  maxHeight: "100%",
                }}
              >
                <div
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    maxWidth: "200px",
                    display: "block",
                  }}
                >
                  <img
                    aria-hidden="true"
                    role="presentation"
                    src="data:image/svg+xml;charset=utf-8,<svg height='200' width='200' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      pointerEvents: "none",
                      verticalAlign: "middle",
                      inset: "0px",
                      margin: "0px",
                      padding: "0px",
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      maxWidth: "100%",
                      display: "block",
                      position: "static",
                    }}
                  />
                </div>
                <img
                  aria-hidden="true"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAEK0lEQVQ4y22VW2wUZRiGv13wkAhyEKQC5VQqdDuz2xYVgpqABlGhcrBoJIZQLAeRkEJhJQqkXHikpkIIIEqqgJGgMUSKAWIkdGe37basxsQIWhHQC0UuKtL5Z1l3HjP/dNuGcPFmd7I7z7z/d3hHDGWJ4Vghw7EaTceyTRXHcCzX1IqTk6Gsnk9DWa4nfe1YtuHEGzVDxcWHKavTA5mO9+e4ayqLsFZMK6JilKgm/b1YxTB61A31H9JpKCskhvKcaZijYR7UibuTVIt7v0oy0WmjwGlnrEoxyTlL6Y02IumkG8kkXcOxclDH9KGNHtD2n6JhbrGKuyXqDI/Zxyi3j7DY/piqrr28bm9nfudOBv3VwLAru8n/7QBhO+Ga6bjbx6ktmux0H9OxPGcc+ncFbiZE+r8I6YwJ2SJwJ/HI30uQS+uRP6uR3c+T9+FWzGyL21NXJ47kYKbS7jDtJjqyszj0TQlVH82g+adSjltl1J6aiRxeSeDICgIXogS3L2LwluWYmUSuSZrhOfS7qSw3pBKU2ae5mp3O28ceZdn+WZy7WEbixymEUsuQZDXB5CvIxY0Et8xn+K4oYZIYdqzHpeTGwuvqZNXCDHWcq84DfHBqDOsPjKT+RD6z3sij/57ZyIYpSE0Z0lFDcN0c8j7ZRoSzGF23BMYoVEkqnMNcuRbhnlWDkMUDmbZtOFI+ksDm6cjSyUj0QeSXGvotn82oo3VESGF0NeWakgNaetYmqHZWOPvovFbMszvu47mdI1h3pBCJTiXQUI68+hCyfy5yrobbKp9k7Om9RGi/lUMP2KRnbXPmPTouFzBk5RDGVw9m6qZxyMICAt5xHx+BvDuDQKqa2yufZkLq4K1r6LXb24TR6nvqM7WcvzAGIzoUo76Q/nUzkS3Tkf3lyNpS5PgLBGJruKNyLoUdXxLOtmLY1k1HVr7DfDvFwex6mn8YxYS1gxn6VimyphSpCiG1DyNzRiNfVBA8+TJ3VpZTdOUkZqa57wq6oi/8vXXHdyU5wUr2fT0CmTsAeW0asrQIWR1G3pmJrAojrVUEP3+Ju1ZVUHz9DGY60Qt04t4cdg+2E3eL7AStmQW49jieOr8AuRyl3+81yOUNyKWNyK81yB9RgnWLuHvjEh0QOWeGHy49R9bQ4nSzu61jDbs+CzPo/QqktgLZtABZNx9ZPQ+pmoe8+Az9Fj7BsD1RzExL75Y4PlSHg6b7u+hOvJZwBzTUM3DzBu6t20re3jcZ+Wk9+V/tYdy3DRS0H6bw56MU/3OaPsHg6hz1wsGPLx0QXgS5kRtxymhzS2h3S/gOXyk9bxHa9JiEs62umU50O9NQx/BrqOOrO2A11Hdqx7xhxbje5Ksr1ivvN9uiDyx3X6eh4iGvhjloY282+hF/U+z3vg6814OK67jSJXP0vSHTseR/VkkRakXY1RsAAAAASUVORK5CYII="
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    pointerEvents: "none",
                    display: "block",
                    verticalAlign: "middle",
                    margin: "0px",
                    padding: "0px",
                    height: "100%",
                    maxWidth: "none",
                    width: "100%",
                    transition: "opacity 500ms linear",
                    inset: "0px",
                    opacity: 0,
                    backgroundColor: "transparent",
                    position: "absolute",
                    objectFit: "cover",
                  }}
                />
                <picture
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    display: "block",
                    maxWidth: "100%",
                  }}
                >
                  <source
                    type="image/webp"
                    sizes="(min-width: 200px) 200px, 100vw"
                    srcSet="https://www.crio.do/static/da53620b2438457e58b21826c6e567d0/1a2eb/PointFour.webp 200w,/static/da53620b2438457e58b21826c6e567d0/f0b89/PointFour.webp 600w"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                    }}
                  />
                  <img
                    height={60}
                    width={60}
                    alt="Highlight"
                    sizes="(min-width: 200px) 200px, 100vw"
                    src="https://www.crio.do/static/da53620b2438457e58b21826c6e567d0/20dc4/PointFour.png"
                    srcSet={`/static/da53620b2438457e58b21826c6e567d0/20dc4/PointFour.png 200w,
/static/da53620b2438457e58b21826c6e567d0/f159a/PointFour.png 600w`}
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      pointerEvents: "none",
                      display: "block",
                      verticalAlign: "middle",
                      inset: "0px",
                      margin: "0px",
                      padding: "0px",
                      height: "100%",
                      maxWidth: "none",
                      position: "absolute",
                      width: "100%",
                      transition: "opacity 0.25s linear",
                      transform: "translateZ(0px)",
                      willChange: "opacity",
                      objectFit: "contain",
                      opacity: 1,
                    }}
                  />
                </picture>
                <noscript
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                >{`<picture><source type="image/webp" srcSet="/static/da53620b2438457e58b21826c6e567d0/1a2eb/PointFour.webp 200w,
/static/da53620b2438457e58b21826c6e567d0/f0b89/PointFour.webp 600w" sizes="(min-width: 200px) 200px, 100vw"/><img width="200" height="200" data-main-image="" style="object-fit:contain;opacity:0" sizes="(min-width: 200px) 200px, 100vw" decoding="async" loading="lazy" src="/static/da53620b2438457e58b21826c6e567d0/20dc4/PointFour.png" srcSet="/static/da53620b2438457e58b21826c6e567d0/20dc4/PointFour.png 200w,
/static/da53620b2438457e58b21826c6e567d0/f159a/PointFour.png 600w" alt="Highlight"/></picture>`}</noscript>
              </div>
            </div>
          </div>
          <div
            className="flex flex-col gap-2"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <p
              className="text-v5-neutral-300 leading-relaxed"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                lineHeight: 1.625,
                color: "rgb(185 207 202/1)",
              }}
            >
              <div
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                }}
              >
                <p
                  className="text-v5-neutral-100"
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflowWrap: "break-word",
                    margin: "0px",
                    color: "rgb(255 255 255/1)",
                  }}
                >
                  Expert guidance to get your profile ready (Github, LinkedIn,
                  Resume)
                </p>
              </div>
            </p>
          </div>
        </div>
        <div
          className="relative w-full p-5 md:p-6 sm:w-auto sm:max-w-[320px] flex md:flex-col gap-3 gap-y-3 rounded-[30px] bg-v5-green-500 InfoCard-module--card--bS7T6"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            position: "relative",
            display: "flex",
            gap: "0.75rem",
            rowGap: "0.75rem",
            borderRadius: "30px",
            backgroundColor: "#5E35B1",
            width: "auto",
            maxWidth: "320px",
            flexDirection: "column",
            padding: "1.5rem",
          }}
        >
          <div
            className="undefined"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
            }}
          >
            <div
              className="flex w-[60px] items-center justify-center"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                width: "60px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  position: "relative",
                  display: "inline-block",
                  verticalAlign: "top",
                  maxHeight: "100%",
                }}
              >
                <div
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    maxWidth: "200px",
                    display: "block",
                  }}
                >
                  <img
                    aria-hidden="true"
                    role="presentation"
                    src="data:image/svg+xml;charset=utf-8,<svg height='200' width='200' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      pointerEvents: "none",
                      verticalAlign: "middle",
                      inset: "0px",
                      margin: "0px",
                      padding: "0px",
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      maxWidth: "100%",
                      display: "block",
                      position: "static",
                    }}
                  />
                </div>
                <img
                  aria-hidden="true"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAD70lEQVQ4y4WVW2wUVRjHv11sfDBU0lQTE7UYSk13Z2bLRl6MGmMCW1DWhD55i7TQpF0BaXe3xvBUw6UaFbTAgxcufTBGUutlexEv1O7MXtqGGhrRhNLSgqkLmJQNO2f2dv5mzszW1jTh4cs5Mznf7/99Z+b8D0lMJclQXZKhRmRD1WWmQTJULovQsHKoXDI0LjPVXKtLhhYRDKaRBWPqgrlQsmESU2E9q1boUUiZKGTxXoXCrLnENFO4tG5BYqqLJGZWJpINocxEFaa6qMBTjGMDEvBiFEo+BjfTUGMkea2R4LIpxEQnhg2NmEBdqNgQDxvhdWzEqrCQxNqZIVRqX6F8sAfV8z/Dm4/iBf0snr3zHdyFMa4YGrehJlAnu1VRusuI87XsAqrYBGpzSVR92YUn+/ajdaYT7wxsRe+f9fg950Mx78bR37ZhdfQMPEa0BBMjSXZrJuwpfRDt+hEcY2EM3HkJk9MepItPoMglAC4Uc7W4nXYBeRe6xzeDOlvw0PAxeAoJLumiZU6iTSOKdWyMn8m0ApkqFDPVyGVqkC0ouH5DwUyqDmmmiGA5GcB6nJioBx3Yg8rv34OST3B7DwVQfLUaYxS9ehOymRrc0r3I5BToTMKpgY34PLIRC2kFsykvhv54Gt3jPnjP7gQdDqCy//0VgEYU1WwUkWwjkH0M/6TrMHfTiy9Gn8GO48/Bf3wrlK8bUdG/G/RDGyjRAToXgvNQCypWBLIoHs8msf3ifuwa8mND/06UD+6Fo28PqLsZ9GkLaCIIuhYGXQvBmQrinpF2OA6uACz9vJ5cHGVTH4MuBEGX3wLNhUGzQaw68TpWnWyGczYEx3QQNBW0oMN3ASpGAhU3jsBxdS+cV0MgM9kcJ9tBl+z5FVMsCJoLwTkSvBswjvvnj4Km3gRN28lTNmjWjr/CoL87QDc74PyxDc53A6gY/BBKPr4MyG0gX5P6CDSzz2rXTE51gK6HLYHJNjh+2g3qaQZ1vQbasR3U1IA1vYehLP0PF4HZBC+f+wB0KQDHxSAc594And4FOvQqKNAAange5NsM2lQPxzY/yppexn1vB/Bo7DSU4nKgmCjZONbNf8Pv7WoF+beAfD7Qpi1w+F9EWeMrWN25Dw+cOoiHf/kE1Zf74Lp9HnI+blVnudPi0dNLZ9FTSHL3rfP8wZ4uVJ48gEd+/Qzrr3wLd3oYcj4GD8ZFKMUk5FxM5Ei6OMOlPdSFfdkKhqRHuZU4xj0Y51ZyAnI2ZnlexvJFE1LaqmX2ZWjCvly2OZZMlZcM1U7+z2j/Z7zC1Znt4oKhuUz7KkEjS9tf4nGLoFJIFsi8BkSb5vVhMmRDpX8BTtkp7nBK/aYAAAAASUVORK5CYII="
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    pointerEvents: "none",
                    display: "block",
                    verticalAlign: "middle",
                    margin: "0px",
                    padding: "0px",
                    height: "100%",
                    maxWidth: "none",
                    width: "100%",
                    transition: "opacity 500ms linear",
                    inset: "0px",
                    opacity: 0,
                    backgroundColor: "transparent",
                    position: "absolute",
                    objectFit: "cover",
                  }}
                />
                <picture
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    display: "block",
                    maxWidth: "100%",
                  }}
                >
                  <source
                    type="image/webp"
                    sizes="(min-width: 200px) 200px, 100vw"
                    srcSet="https://www.crio.do/static/51492b581203e880f789d128527779b3/1a2eb/PointFive.webp 200w,/static/51492b581203e880f789d128527779b3/f0b89/PointFive.webp 600w"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                    }}
                  />
                  <img
                    height={60}
                    width={60}
                    alt="Highlight"
                    sizes="(min-width: 200px) 200px, 100vw"
                    src="https://www.crio.do/static/51492b581203e880f789d128527779b3/20dc4/PointFive.png"
                    srcSet={`/static/51492b581203e880f789d128527779b3/20dc4/PointFive.png 200w,
/static/51492b581203e880f789d128527779b3/f159a/PointFive.png 600w`}
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      pointerEvents: "none",
                      display: "block",
                      verticalAlign: "middle",
                      inset: "0px",
                      margin: "0px",
                      padding: "0px",
                      height: "100%",
                      maxWidth: "none",
                      position: "absolute",
                      width: "100%",
                      transition: "opacity 0.25s linear",
                      transform: "translateZ(0px)",
                      willChange: "opacity",
                      objectFit: "contain",
                      opacity: 1,
                    }}
                  />
                </picture>
                <noscript
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                >{`<picture><source type="image/webp" srcSet="/static/51492b581203e880f789d128527779b3/1a2eb/PointFive.webp 200w,
/static/51492b581203e880f789d128527779b3/f0b89/PointFive.webp 600w" sizes="(min-width: 200px) 200px, 100vw"/><img width="200" height="200" data-main-image="" style="object-fit:contain;opacity:0" sizes="(min-width: 200px) 200px, 100vw" decoding="async" loading="lazy" src="/static/51492b581203e880f789d128527779b3/20dc4/PointFive.png" srcSet="/static/51492b581203e880f789d128527779b3/20dc4/PointFive.png 200w,
/static/51492b581203e880f789d128527779b3/f159a/PointFive.png 600w" alt="Highlight"/></picture>`}</noscript>
              </div>
            </div>
          </div>
          <div
            className="flex flex-col gap-2"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <p
              className="text-v5-neutral-300 leading-relaxed"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                lineHeight: 1.625,
                color: "rgb(185 207 202/1)",
              }}
            >
              <div
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                }}
              >
                <p
                  className="text-v5-neutral-100"
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflowWrap: "break-word",
                    margin: "0px",
                    color: "rgb(255 255 255/1)",
                  }}
                >
                  Access to a diverse set of job opportunities with 1000+ hiring
                  partners
                </p>
              </div>
            </p>
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  margin: 0px;
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  tab-size: 4;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}

body {
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizelegibility;
  overflow-y: auto;
  min-height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  line-height: inherit;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}
`,
        }}
      />
    </>
  );
}
