import TabsNext from "components/TabsNext";
import "./Header.scss";

const Header = ({ tabValue, tabChange }) => {
  return (
    <div className="manage-prepare__header--main">
      <div className="pl-5">
        <h4 className="terv-form-header manage-prepare__header">
          Create a new Hackathon
        </h4>
        <p className="manage-prepare__header-sub">
          Fill down below information to create a Hackathon
        </p>
      </div>
      <div className="pl-5">
        <TabsNext
          tabs={[
            "Hackathon Information",
            "Hackathon Content",
            "Hackathon Settings",
            "Prizes & Participation",
          ]}
          tabWidth={200}
          activeTab={tabValue}
          changeTab={(newTab) => tabChange(newTab)}
          color="#1ba94c"
        />
      </div>
    </div>
  );
};

export default Header;
