import { useHistory } from "react-router-dom";
import * as CustomIcons from "../../../assets/icons/icons";
import { sessionPrivileges } from "privileges";

export function EditAction({ editLink, id, emailId, ...props }) {
  const history = useHistory();
  const Priv = sessionPrivileges().userprivileges || [];
  let descc = props.desc;
  const customer = props.customer;
  const activePage = props.activePage;
  const itemsPerPage = props.itemsPerPage;

  const edit = () => {
    let body = {
      pathname: editLink,
      state: {
        edit: true,
        id,
        customer: customer,
        activePage: activePage,
        itemsPerPage: itemsPerPage,
        type: "default",
        ...(emailId && { emailId }),
      },
    };
    if (props.fromRow) {
      body = {
        ...body,
        state: {
          edit: true,
          row: props.data,
          customer: customer,
          itemsPerPage: itemsPerPage,
          activePage: activePage,
        },
      };
    }
    history.push(body);
  };

  return (
    <>
      <span
        style={{
          marginRight: 15,
          color: "#51B960",
          cursor: "pointer",
          display:
            descc === "Admin"
              ? "none"
              : descc === "Mentor"
              ? "none"
              : descc === "Learner"
              ? "none"
              : "block",
        }}
        onClick={edit}
      >
        {window.location.pathname === "/userList" &&
        !Priv.includes("MANAGE_CUSTOMER")
          ? CustomIcons.outlinedEye
          : CustomIcons.penIcon("#51B960")}
      </span>
    </>
  );
}
