import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import useStyles from  "./UserProfileStyles.js";
import EditUserInput from  "./EditUserInput.js";
import { DeleteOutlineIcon } from "assets/icons/icons";
import { ConformDelete } from "../../components/DeleteModelePre";
const paraStyle = {
  fontSize: 15,
  fontWeight: 500,
  color: "#5B5A5F",
  marginTop: 12,
  textAlign: "center",
};

export default function EditUserPersonal({pageCount, userData, handlePageCount, submitUserData, history}) {
  const css = useStyles();

  const defaultData = {
    emergencyContact : {
      contactName: {value: "", validation:{key:"contactName", placeholder:"outer", name:"Contact name", min:3, max:48, length:48, type:"textonly"}},
      phoneNo: {value: "", validation:{key:"phoneNo", placeholder:"outer", name:"Phone number", min:10, length:10, type:"numberonly"}},
      relationship : {value: "", validation:{key:"relationship", placeholder:"outer", name:"What is relationship with this person?", length:48, type:"textonly"}},
    }
  }
  const [userId, setUserId] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [errorComponentList, setErrorComponentList] = useState([]);
  const [pageData, setPageData] = useState({
    firstName: {value: "", validation:{key:"firstName", placeholder:"outer", name:"First name", min:3, max:48, length:48, req: true, type:"textonly"}},
    lastName: {value: "", validation:{key:"lastName", placeholder:"outer", name:"Last name", min:1, max:48, length:48, req: true, type:"textonly"}},
    fathersName : {value: "", validation:{key:"fathersName", placeholder:"outer", name:"Father's name", length:48, type:"textonly"}},
    gender: {value: "", validation:{key:"gender", placeholder:"outer", name:"Gender", data:["Male", "Female", "Other"], req: true, type:"select-single"}},
    dateOfBirth: {value: "", validation:{key:"dateOfBirth", placeholder:"outer", name:"Date of birth", req: true, type:"date"}},
    registrationNo: {value: "", validation:{key:"registrationNo", placeholder:"outer", name:"Register number", length:48, type:"textandnumber"}},
    emailId: {value: "", validation:{key:"emailId", placeholder:"outer", name:"Email id", req: true, type:"email"}},
    phoneNumber: {value: "", validation:{key:"phoneNumber", placeholder:"outer", name:"Phone number", min:10, length:10, req: true, type:"numberonly"}},
    bloodGroup: {value: "", validation:{key:"bloodGroup", placeholder:"outer", name:"Blood group", data:["O+", "O-", "A+", "A-", "B+", "B-", "AB+", "AB-"], req: true, type:"select-single"}},
    address: {
      field1: {value: "", validation:{key:"field1", placeholder:"outer", name:"Address", min:10, max:500, type:"text", status: true}},
      state: {value: "", validation:{key:"state", placeholder:"outer", name:"State", data:[...getIndia("state")], defaultvalue:"**Choose**", search:true,  type:"select-single"}},
      city: {value: "", validation:{key:"city", placeholder:"outer", name:"City", data:[], defaultvalue:"**Choose**", search:true,  type:"select-single"}},
      pincode: {value: "", validation:{key:"pincode", placeholder:"outer", name:"Pincode", length:6, type:"numberonly"}},
    },
    languagesKnown: {value: [], validation:{key:"languagesKnown", placeholder:"outer", name:"Languages Known", data:["Assamese", "Bangla", "Bodo", "Dogri", "English", "Gujarati", "Hindi", "Kashmiri", "Kannada", "Konkani", "Maithili", "Malayalam", "Manipuri", "Marathi", "Nepali", "Oriya", "Punjabi", "Tamil", "Telugu", "Santali", "Sindhi", "Urdu"], search:true, type:"select-chip"}},
    emergencyContact: [ {...defaultData.emergencyContact} ],
  });
  const [dataDelete, setDataDelete] = useState({ type: "", index: null });

  const addData = (key, data) => {
    const tempPageData = {...pageData}
    tempPageData[key].push(data);
    setPageData(tempPageData)
  }

  const removeData = (key, index) => {
    const tempPageData = {...pageData}
    tempPageData[key].splice(index, 1);
    setPageData(tempPageData)
  }

  const traceData = (keys, keyIndex, dataName, val, data) => {
    if(keys[keyIndex+1] !== undefined) data[keys[keyIndex]] = traceData(keys, keyIndex+1, dataName, val, data[keys[keyIndex]]);
    else data[keys[keyIndex]][dataName] = val;
    return data;
  }

  const updateData = ({keys, dataName, value}) => {
    if(keys && keys.length > 0 ){
      const tempData = traceData(keys, 0, dataName, value, {...pageData});
      setPageData(tempData);
    }
  }

  const errorComponentUpdate = (component, type) => {
    const tempList = [...errorComponentList];
    const index = tempList.indexOf(component);
    if(type === "remove" && index > -1){
      tempList.splice(index, 1);
      setErrorComponentList(tempList);
    } else if(type === "add" && index === -1)
      setErrorComponentList([...tempList, component]);
  }
  
  const submitPageDataVal = async () => {
    const isSubmit = await submitUserData(pageData, "users/saveUserinfo/");
    if(isSubmit){
      handlePageCount(pageCount+1)
    }
  }

  const goNextPage = () => {
    if(errorComponentList.length > 0)
      setErrorShow(true);
    else 
      submitPageDataVal()
  }

  useEffect(() => {
    if ( dataDelete.type === "emergencyDeleteConform" && dataDelete.index !== null ) {
      removeData(dataDelete.index.key, dataDelete.index.index);
      setDataDelete({ type: "", index: null });
    }
  }, [dataDelete]);

  useEffect(()=>{
    if(pageData.address.state.value !== "")
      setPageData({
        ...pageData,
        address: {
          ...pageData.address,
          city: {value: "", validation:{...pageData.address.city.validation, data:[...getIndia(pageData.address.state.value)]}},
        }
      });
  },[pageData.address.state.value]);

  useEffect(() => {
    if(userData.id) {
      let tempPageData = {...pageData};
      for(const key in tempPageData) {
        if(tempPageData[key]["value"] !== undefined) {
          if(Array.isArray(tempPageData[key])) {
            if(Array.isArray(userData[key])) tempPageData[key]["value"] = [...userData[key]];
            else tempPageData[key]["value"] = [];
          } else tempPageData[key]["value"] = userData[key] ? userData[key] : tempPageData[key]["value"];
        } else if(Array.isArray(tempPageData[key]) && defaultData[key]) {
          const tempArray = [];
          const fromUserData = userData[key] && Array.isArray(userData[key]) ? [...userData[key]] : [];
          if(fromUserData.length > 0 ) {
            fromUserData.map((data, i) => {
              let defaultKeyData = {...defaultData[key]};
              for(const defaultKey in defaultKeyData) {
                defaultKeyData[defaultKey] = {...defaultKeyData[defaultKey], value: fromUserData[i][defaultKey] ? fromUserData[i][defaultKey] : ""};
              }
              tempArray.push(defaultKeyData);
            });
          } else {
            // tempArray.push({...defaultData[key]});
          }
          tempPageData[key] = tempArray;
        } else if(typeof tempPageData[key] === "object") {
          for (const subKey in tempPageData[key]) {
            if(tempPageData[key][subKey]["value"] !== undefined) tempPageData[key][subKey]["value"] = userData[key] && userData[key][subKey] ? userData[key][subKey] : "";
          }
        }
      }
      setPageData(tempPageData);
      setUserId(userData.id);
    }
  },[userData]);

  return (
    <React.Fragment>
      {userId && (
        <React.Fragment>
          <div className="form-group">
            <h4>Personal Info</h4>
            <div className="flex-box flex-100 flex-gap-2">
              <div className="flex-1">
                <div className="form-row flex-box flex-100 flex-gap-2">
                  {pageData.firstName && (
                    <EditUserInput type="text" value={pageData.firstName.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["firstName"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.firstName.validation} />
                  )}
                  {pageData.lastName && (
                    <EditUserInput type="text" value={pageData.lastName.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["lastName"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.lastName.validation} />
                  )}
                  {pageData.fathersName && (
                    <EditUserInput type="text" value={pageData.fathersName.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["fathersName"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.fathersName.validation} />
                  )}
                </div>
                <div className="form-row flex-box flex-100 flex-gap-2">
                  {pageData.gender && (
                    <EditUserInput type="select" value={pageData.gender.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["gender"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.gender.validation} />
                  )}
                  {pageData.dateOfBirth && (
                    <EditUserInput type="date" value={pageData.dateOfBirth.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["dateOfBirth"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.dateOfBirth.validation} />
                  )}
                  {pageData.registrationNo && (
                    <EditUserInput type="text" value={pageData.registrationNo.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["registrationNo"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.registrationNo.validation} />
                  )}
                </div>
                <div className="form-row flex-box flex-100 flex-gap-2">
                  {pageData.emailId && (
                    <EditUserInput type="text" value={pageData.emailId.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["emailId"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.emailId.validation} />
                  )}
                  {pageData.phoneNumber && (
                    <EditUserInput type="text" value={pageData.phoneNumber.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["phoneNumber"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.phoneNumber.validation} />
                  )}
                  {pageData.bloodGroup && (
                    <EditUserInput type="select" value={pageData.bloodGroup.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["bloodGroup"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.bloodGroup.validation} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <h5>Address</h5>
            <div className="flex-box flex-100 flex-gap-2">
              <div className="flex-3">
                <div className="form-row flex-box flex-100 flex-gap-2">
                  {pageData.address.field1 && (
                    <EditUserInput type="textArea" value={pageData.address.field1.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["address","field1"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.address.field1.validation} />
                  )}
                </div>
                <div className="form-row flex-box flex-100 flex-gap-2">
                  {pageData.address.state && (
                    <EditUserInput type="select" value={pageData.address.state.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["address","state"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.address.state.validation} />
                  )}
                  {pageData.address.city && (
                    <EditUserInput type="select" value={pageData.address.city.value} disabled={pageData.address.state.value === ""} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["address","city"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.address.city.validation} />
                  )}
                  {pageData.address.pincode && (
                    <EditUserInput type="text" value={pageData.address.pincode.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["address","pincode"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.address.pincode.validation} />
                  )}
                </div>
              </div>
              <div className="flex-1"></div>
            </div>
          </div>
          <div className="form-group">
            <h5>Language</h5>
            <div className="flex-box flex-100 flex-gap-2">
              <div className="flex-3">
                <div className="form-row flex-box flex-100 flex-gap-2">
                  {pageData.languagesKnown && (
                    <EditUserInput type="select" value={pageData.languagesKnown.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["languagesKnown"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.languagesKnown.validation} />
                  )}
                </div>
              </div>
              <div className="flex-1"></div>
            </div>
          </div>
          <div className="form-group">
            <h5>Emergency Contact</h5>
            {pageData.emergencyContact.length > 0 && pageData.emergencyContact.map((data, i)=>(
              <div key={`emergencyContact-${i}`} className="flex-box flex-100 flex-gap-2" style={{margin:"24px 0"}}>
                <div className="flex-3">
                  <div className="form-row flex-box flex-100 flex-gap-2">
                    {data.contactName && (
                      <EditUserInput type="text" value={data.contactName.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["emergencyContact", i, "contactName"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.contactName.validation} />
                    )}
                    {data.phoneNo && (
                      <EditUserInput type="text" value={data.phoneNo.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["emergencyContact", i, "phoneNo"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.phoneNo.validation} />
                    )}
                  </div>
                  <div className="form-row flex-box flex-100 flex-gap-2">
                    {data.relationship && (
                      <EditUserInput type="text" value={data.relationship.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["emergencyContact", i, "relationship"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.relationship.validation} />
                    )}
                  </div>
                </div>
                <div className="flex-1">
                {i !== 0 && (
                  <div>
                    <h6>&nbsp;</h6>
                    <Button variant="text" className={css.buttonDeleteIcon} disableElevation onClick={()=>setDataDelete({type: "emergencyDelete", index: {key: "emergencyContact",index : i}})}>
                      <DeleteOutlineIcon />
                    </Button>
                  </div>
                )}
                </div>
              </div>
            ))}
          </div>
          <div className="form-group last">
            <Button variant="outlined" className={css.buttonSmSuccess} disableElevation onClick={()=>addData("emergencyContact", defaultData.emergencyContact)}>
              Add contact
            </Button>
          </div>
          <div className="form-group fill-width">
            <div className="form-row flex-box flex-100 flex-gap-2 flex-center">
              {errorShow && errorComponentList.length > 0 && <p className="errorTxt">Please fill in all required fields.</p>}
            </div>
            <div className="form-row flex-box flex-100 flex-gap-2 flex-center">
              <Button variant="outlined" className={css.buttonPrimary} disableElevation 
                onClick={()=> history.push("/profile")}>
                Prev
              </Button>
              <Button variant="contained" className={css.buttonPrimary} disableElevation
                onClick={goNextPage}>
                Next
              </Button>
            </div>
          </div>
        </React.Fragment>
      )}
      <ConformDelete
        Header={
          dataDelete.type === "emergencyDelete" ? "Delete Emergency Contact" : ""
        }
        Content={() =>
          dataDelete.type === "emergencyDelete" ? (
            <p style={paraStyle}>
              <div>Are you sure want to delete emergency contact?</div>
              <div>You cannot recover once deleted</div>
            </p>
          ) : (
            ""
          )
        }
        isOpen={dataDelete.type === "emergencyDelete"}
        handleClose={() => {
          setDataDelete({
            type: "",
            index: null,
          });
        }}
        handleDelete={() => {
          if (dataDelete.type === "emergencyDelete")
            setDataDelete({
              type: "emergencyDeleteConform",
              index: dataDelete.index,
            });
        }}
      />
    </React.Fragment>
  );
};
function getIndia(type){
  const data = [
    { "state": "Andhra Pradesh", "districts": ["Anantapur","Chittoor","East Godavari","Guntur","Krishna","Kurnool","Nellore","Prakasam","Srikakulam","Visakhapatnam","Vizianagaram","West Godavari","YSR Kadapa"]},
    { "state": "Arunachal Pradesh", "districts": ["Tawang","West Kameng","East Kameng","Papum Pare","Kurung Kumey","Kra Daadi","Lower Subansiri","Upper Subansiri","West Siang","East Siang","Siang","Upper Siang","Lower Siang","Lower Dibang Valley","Dibang Valley","Anjaw","Lohit","Namsai","Changlang","Tirap","Longding"]},
    { "state": "Assam", "districts": ["Baksa","Barpeta","Biswanath","Bongaigaon","Cachar","Charaideo","Chirang","Darrang","Dhemaji","Dhubri","Dibrugarh","Goalpara","Golaghat","Hailakandi","Hojai","Jorhat","Kamrup Metropolitan","Kamrup","Karbi Anglong","Karimganj","Kokrajhar","Lakhimpur","Majuli","Morigaon","Nagaon","Nalbari","Dima Hasao","Sivasagar","Sonitpur","South Salmara-Mankachar","Tinsukia","Udalguri","West Karbi Anglong"]},
    { "state": "Bihar", "districts": ["Araria","Arwal","Aurangabad","Banka","Begusarai","Bhagalpur","Bhojpur","Buxar","Darbhanga","East Champaran (Motihari)","Gaya","Gopalganj","Jamui","Jehanabad","Kaimur (Bhabua)","Katihar","Khagaria","Kishanganj","Lakhisarai","Madhepura","Madhubani","Munger (Monghyr)","Muzaffarpur","Nalanda","Nawada","Patna","Purnia (Purnea)","Rohtas","Saharsa","Samastipur","Saran","Sheikhpura","Sheohar","Sitamarhi","Siwan","Supaul","Vaishali","West Champaran"]},
    { "state": "Chandigarh (UT)", "districts": ["Chandigarh"]},
    { "state": "Chhattisgarh", "districts": ["Balod","Baloda Bazar","Balrampur","Bastar","Bemetara","Bijapur","Bilaspur","Dantewada (South Bastar)","Dhamtari","Durg","Gariyaband","Janjgir-Champa","Jashpur","Kabirdham (Kawardha)","Kanker (North Bastar)","Kondagaon","Korba","Korea (Koriya)","Mahasamund","Mungeli","Narayanpur","Raigarh","Raipur","Rajnandgaon","Sukma","Surajpur  ","Surguja"]},
    { "state": "Dadra and Nagar Haveli (UT)", "districts": ["Dadra & Nagar Haveli"]},
    { "state": "Daman and Diu (UT)", "districts": ["Daman","Diu"]},
    { "state": "Delhi (NCT)", "districts": ["Central Delhi","East Delhi","New Delhi","North Delhi","North East  Delhi","North West  Delhi","Shahdara","South Delhi","South East Delhi","South West  Delhi","West Delhi"]},
    { "state": "Goa", "districts": ["North Goa","South Goa"]},
    { "state": "Gujarat", "districts": ["Ahmedabad","Amreli","Anand","Aravalli","Banaskantha (Palanpur)","Bharuch","Bhavnagar","Botad","Chhota Udepur","Dahod","Dangs (Ahwa)","Devbhoomi Dwarka","Gandhinagar","Gir Somnath","Jamnagar","Junagadh","Kachchh","Kheda (Nadiad)","Mahisagar","Mehsana","Morbi","Narmada (Rajpipla)","Navsari","Panchmahal (Godhra)","Patan","Porbandar","Rajkot","Sabarkantha (Himmatnagar)","Surat","Surendranagar","Tapi (Vyara)","Vadodara","Valsad"]},
    { "state": "Haryana", "districts": ["Ambala","Bhiwani","Charkhi Dadri","Faridabad","Fatehabad","Gurgaon","Hisar","Jhajjar","Jind","Kaithal","Karnal","Kurukshetra","Mahendragarh","Mewat","Palwal","Panchkula","Panipat","Rewari","Rohtak","Sirsa","Sonipat","Yamunanagar"]},
    { "state": "Himachal Pradesh", "districts": ["Bilaspur","Chamba","Hamirpur","Kangra","Kinnaur","Kullu","Lahaul &amp; Spiti","Mandi","Shimla","Sirmaur (Sirmour)","Solan","Una"]},
    { "state": "Jammu and Kashmir", "districts": ["Anantnag","Bandipore","Baramulla","Budgam","Doda","Ganderbal","Jammu","Kargil","Kathua","Kishtwar","Kulgam","Kupwara","Leh","Poonch","Pulwama","Rajouri","Ramban","Reasi","Samba","Shopian","Srinagar","Udhampur"]},
    { "state": "Jharkhand", "districts": ["Bokaro","Chatra","Deoghar","Dhanbad","Dumka","East Singhbhum","Garhwa","Giridih","Godda","Gumla","Hazaribag","Jamtara","Khunti","Koderma","Latehar","Lohardaga","Pakur","Palamu","Ramgarh","Ranchi","Sahibganj","Seraikela-Kharsawan","Simdega","West Singhbhum"]},
    { "state": "Karnataka", "districts": ["Bagalkot","Ballari (Bellary)","Belagavi (Belgaum)","Bengaluru (Bangalore) Rural","Bengaluru (Bangalore) Urban","Bidar","Chamarajanagar","Chikballapur","Chikkamagaluru (Chikmagalur)","Chitradurga","Dakshina Kannada","Davangere","Dharwad","Gadag","Hassan","Haveri","Kalaburagi (Gulbarga)","Kodagu","Kolar","Koppal","Mandya","Mysuru (Mysore)","Raichur","Ramanagara","Shivamogga (Shimoga)","Tumakuru (Tumkur)","Udupi","Uttara Kannada (Karwar)","Vijayapura (Bijapur)","Yadgir"]},
    { "state": "Kerala", "districts": ["Alappuzha","Ernakulam","Idukki","Kannur","Kasaragod","Kollam","Kottayam","Kozhikode","Malappuram","Palakkad","Pathanamthitta","Thiruvananthapuram","Thrissur","Wayanad"]},
    { "state": "Lakshadweep (UT)", "districts": ["Agatti","Amini","Androth","Bithra","Chethlath","Kavaratti","Kadmath","Kalpeni","Kilthan","Minicoy"]},
    { "state": "Madhya Pradesh", "districts": ["Agar Malwa","Alirajpur","Anuppur","Ashoknagar","Balaghat","Barwani","Betul","Bhind","Bhopal","Burhanpur","Chhatarpur","Chhindwara","Damoh","Datia","Dewas","Dhar","Dindori","Guna","Gwalior","Harda","Hoshangabad","Indore","Jabalpur","Jhabua","Katni","Khandwa","Khargone","Mandla","Mandsaur","Morena","Narsinghpur","Neemuch","Panna","Raisen","Rajgarh","Ratlam","Rewa","Sagar","Satna","Sehore","Seoni","Shahdol","Shajapur","Sheopur","Shivpuri","Sidhi","Singrauli","Tikamgarh","Ujjain","Umaria","Vidisha"]},
    { "state": "Maharashtra", "districts": ["Ahmednagar","Akola","Amravati","Aurangabad","Beed","Bhandara","Buldhana","Chandrapur","Dhule","Gadchiroli","Gondia","Hingoli","Jalgaon","Jalna","Kolhapur","Latur","Mumbai City","Mumbai Suburban","Nagpur","Nanded","Nandurbar","Nashik","Osmanabad","Palghar","Parbhani","Pune","Raigad","Ratnagiri","Sangli","Satara","Sindhudurg","Solapur","Thane","Wardha","Washim","Yavatmal"]},
    { "state": "Manipur", "districts": ["Bishnupur","Chandel","Churachandpur","Imphal East","Imphal West","Jiribam","Kakching","Kamjong","Kangpokpi","Noney","Pherzawl","Senapati","Tamenglong","Tengnoupal","Thoubal","Ukhrul"]},
    { "state": "Meghalaya", "districts": ["East Garo Hills","East Jaintia Hills","East Khasi Hills","North Garo Hills","Ri Bhoi","South Garo Hills","South West Garo Hills ","South West Khasi Hills","West Garo Hills","West Jaintia Hills","West Khasi Hills"]},
    { "state": "Mizoram", "districts": ["Aizawl","Champhai","Kolasib","Lawngtlai","Lunglei","Mamit","Saiha","Serchhip"]},
    { "state": "Nagaland", "districts": ["Dimapur","Kiphire","Kohima","Longleng","Mokokchung","Mon","Peren","Phek","Tuensang","Wokha","Zunheboto"]},
    { "state": "Odisha", "districts": ["Angul","Balangir","Balasore","Bargarh","Bhadrak","Boudh","Cuttack","Deogarh","Dhenkanal","Gajapati","Ganjam","Jagatsinghapur","Jajpur","Jharsuguda","Kalahandi","Kandhamal","Kendrapara","Kendujhar (Keonjhar)","Khordha","Koraput","Malkangiri","Mayurbhanj","Nabarangpur","Nayagarh","Nuapada","Puri","Rayagada","Sambalpur","Sonepur","Sundargarh"]},
    { "state": "Puducherry (UT)", "districts": ["Karaikal","Mahe","Pondicherry","Yanam"]},
    { "state": "Punjab", "districts": ["Amritsar","Barnala","Bathinda","Faridkot","Fatehgarh Sahib","Fazilka","Ferozepur","Gurdaspur","Hoshiarpur","Jalandhar","Kapurthala","Ludhiana","Mansa","Moga","Muktsar","Nawanshahr (Shahid Bhagat Singh Nagar)","Pathankot","Patiala","Rupnagar","Sahibzada Ajit Singh Nagar (Mohali)","Sangrur","Tarn Taran"]},
    { "state": "Rajasthan", "districts": ["Ajmer","Alwar","Banswara","Baran","Barmer","Bharatpur","Bhilwara","Bikaner","Bundi","Chittorgarh","Churu","Dausa","Dholpur","Dungarpur","Hanumangarh","Jaipur","Jaisalmer","Jalore","Jhalawar","Jhunjhunu","Jodhpur","Karauli","Kota","Nagaur","Pali","Pratapgarh","Rajsamand","Sawai Madhopur","Sikar","Sirohi","Sri Ganganagar","Tonk","Udaipur"]},
    { "state": "Sikkim", "districts": ["East Sikkim","North Sikkim","South Sikkim","West Sikkim"]},
    { "state": "Tamil Nadu", "districts": ["Ariyalur","Chennai","Coimbatore","Cuddalore","Dharmapuri","Dindigul","Erode","Kanchipuram","Kanyakumari","Karur","Krishnagiri","Madurai","Nagapattinam","Namakkal","Nilgiris","Perambalur","Pudukkottai","Ramanathapuram","Salem","Sivaganga","Thanjavur","Theni","Thoothukudi (Tuticorin)","Tiruchirappalli","Tirunelveli","Tiruppur","Tiruvallur","Tiruvannamalai","Tiruvarur","Vellore","Viluppuram","Virudhunagar"]},
    { "state": "Telangana", "districts": ["Adilabad","Bhadradri Kothagudem","Hyderabad","Jagtial","Jangaon","Jayashankar Bhoopalpally","Jogulamba Gadwal","Kamareddy","Karimnagar","Khammam","Komaram Bheem Asifabad","Mahabubabad","Mahabubnagar","Mancherial","Medak","Medchal","Nagarkurnool","Nalgonda","Nirmal","Nizamabad","Peddapalli","Rajanna Sircilla","Rangareddy","Sangareddy","Siddipet","Suryapet","Vikarabad","Wanaparthy","Warangal (Rural)","Warangal (Urban)","Yadadri Bhuvanagiri"]},
    { "state": "Tripura", "districts": ["Dhalai","Gomati","Khowai","North Tripura","Sepahijala","South Tripura","Unakoti","West Tripura"]},
    { "state": "Uttarakhand", "districts": ["Almora","Bageshwar","Chamoli","Champawat","Dehradun","Haridwar","Nainital","Pauri Garhwal","Pithoragarh","Rudraprayag","Tehri Garhwal","Udham Singh Nagar","Uttarkashi"]},
    { "state": "Uttar Pradesh", "districts": ["Agra","Aligarh","Allahabad","Ambedkar Nagar","Amethi (Chatrapati Sahuji Mahraj Nagar)","Amroha (J.P. Nagar)","Auraiya","Azamgarh","Baghpat","Bahraich","Ballia","Balrampur","Banda","Barabanki","Bareilly","Basti","Bhadohi","Bijnor","Budaun","Bulandshahr","Chandauli","Chitrakoot","Deoria","Etah","Etawah","Faizabad","Farrukhabad","Fatehpur","Firozabad","Gautam Buddha Nagar","Ghaziabad","Ghazipur","Gonda","Gorakhpur","Hamirpur","Hapur (Panchsheel Nagar)","Hardoi","Hathras","Jalaun","Jaunpur","Jhansi","Kannauj","Kanpur Dehat","Kanpur Nagar","Kanshiram Nagar (Kasganj)","Kaushambi","Kushinagar (Padrauna)","Lakhimpur - Kheri","Lalitpur","Lucknow","Maharajganj","Mahoba","Mainpuri","Mathura","Mau","Meerut","Mirzapur","Moradabad","Muzaffarnagar","Pilibhit","Pratapgarh","RaeBareli","Rampur","Saharanpur","Sambhal (Bhim Nagar)","Sant Kabir Nagar","Shahjahanpur","Shamali (Prabuddh Nagar)","Shravasti","Siddharth Nagar","Sitapur","Sonbhadra","Sultanpur","Unnao","Varanasi"]},
    { "state": "West Bengal", "districts": ["Alipurduar","Bankura","Birbhum","Burdwan (Bardhaman)","Cooch Behar","Dakshin Dinajpur (South Dinajpur)","Darjeeling","Hooghly","Howrah","Jalpaiguri","Kalimpong","Kolkata","Malda","Murshidabad","Nadia","North 24 Parganas","Paschim Medinipur (West Medinipur)","Purba Medinipur (East Medinipur)","Purulia","South 24 Parganas","Uttar Dinajpur (North Dinajpur)"]}
  ]
  let returnData = [];
  if (type === "state") {
    returnData = data.map((sd)=>sd.state);
  } else if (type && type !== "") {
    data.map((sd)=>{
      if(sd.state === type) returnData = sd.districts;
    })
  }
  return returnData;
}