import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import sliderBanner from "../../../assets/Landing/Frame28.png";
import { Dialog, DialogTitle, DialogContent, TextField,  Button, makeStyles } from "@material-ui/core";
import api from "api/baseConfig";
import apiHelper from "api/apiHelper";

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: '12px', // Adjust the value as needed
  },
}));

const FellowShip: React.FC<Props> = ({ isMobile }) => {
  const classes = useStyles();
  const svgProps = {
    width: isMobile ? "100%" : 1366,
    height: isMobile ? "100%" : 611,
  };
const { t } = useTranslation();

const [openDialog, setOpenDialog] = useState(false);

const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');

const handleOpenDialog = () => {
  window.location.href = '/fellowship-program';
};

const handleCloseDialog = () => {
  setOpenDialog(false);
};

const handleChange = (setter: React.Dispatch<React.SetStateAction<string>>, value: string) => {
  setter(value);
  if (name.trim() && email.trim() && phoneNumber.trim()) {
    setNameError('');
  }
};

const Update = async () => {
  if (name.trim() === '' || email.trim() === '' || phoneNumber.trim() === '' || 
  emailError.trim() !== '' || phoneNumberError.trim() !== '' || phoneNumber.trim().length !== 10) {
    setNameError('All fields are required');
  } else {
  const url = `${api.baseURL}${api.homePage.addHomePageUser}`;
  const payload = {
    name: name,
    mobileNo: phoneNumber,
    emailId: email,
    field: "Fellowship"
  };
  try {
    const data = await apiHelper.axiosCallPost(url, "post", payload);
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  };
  handleCloseDialog();
  setName("");
    setEmail("");
    setPhoneNumber("");
  }
};

  return (
    <>
      {/* <ContentHeader
        description={t(HomeContent.Headers.Difference.Description)}
        title={t(HomeContent.Headers.Difference.Main)}
        sub={t(HomeContent.Headers.Difference.Sub)}
        titleRev
      /> */}
      <div style={{width:"100%", display:"flex", justifyContent:"center", paddingTop:"3%", cursor: "pointer",}}  onClick={handleOpenDialog}>
      <img src={sliderBanner}  style={{ width: '100%', height: '100%', }}></img> 
      </div>

      <Dialog open={openDialog} onClose={handleCloseDialog}  classes={{ paper: classes.dialogPaper }} >
        <DialogTitle style={{textAlign:"center",color:"#ffffff", background:"linear-gradient(#1B1834, #381571, #794DFD)"}}></DialogTitle>
        <DialogContent>
          <div className="inputFieldValidation" style={{ paddingTop: '8%' }}>
          <label className="terv-label">
              Name
                       </label>
            <TextField
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => handleChange(setName, e.target.value)}
              InputLabelProps={{
                style: { fontSize: '14px', fontFamily: 'Poppins' },
              }}
            />
          </div>

          <div className="inputFieldValidation" style={{ paddingTop: '10%' }}>
          <label className="terv-label">
              Email Id
                       </label>
            <TextField
              label="Email Id"
              variant="outlined"
              value={email}
              onChange={(e) => handleChange(setEmail, e.target.value)}
              onBlur={(e) => {
                const input = e.target.value;
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
                if (!emailPattern.test(input)) {
                  setEmailError('Invalid email');
                } else {
                  setEmailError('');
                }
            }}
              InputLabelProps={{
                style: { fontSize: '14px', fontFamily: 'Poppins' },
              }}
            />
          </div>
          <span style={{color:"red"}}>{emailError}</span>
          <div className="inputFieldValidation" style={{ paddingTop: '8%' }}>
          <label className="terv-label">
          Phone Number
                       </label>
            <TextField
              label="Phone Number"
              variant="outlined"
              value={phoneNumber}
              onChange={(e) => {
                const input = e.target.value;
                const onlyNumbers = input.replace(/\D/g, ''); // Remove non-numeric characters
                setPhoneNumber(onlyNumbers);
                handleChange(setPhoneNumber,input);
            }}
            onBlur={(e) => {
              const number = e.target.value.trim();
              if (number.length !== 10) {
                setPhoneNumberError('Invalid Number');
              } else {
                setPhoneNumberError('');
              }
            }}
              InputLabelProps={{
                style: { fontSize: '14px', fontFamily: 'Poppins' },
              }}
            />
          </div>
          <span style={{color:"red"}}>{phoneNumberError}</span>
        </DialogContent>
        <div style={{paddingTop:"4%", paddingBottom:"4%", display:"flex", justifyContent:"center", color:"red"}}>
            {nameError}
          </div>
        <div style={{paddingTop:"4%", paddingBottom:"4%", display:"flex", justifyContent:"center"}}>
        <Button style={{backgroundColor:"#794DF5",color:"#ffffff",textTransform: "capitalize", fontSize:"12px"}} 
        onClick={() => {
          Update();
        }
        }>
        Send</Button>
        </div>
      </Dialog>
    </>
  );
};

export default FellowShip;
