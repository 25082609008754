import React, { useEffect, useState } from "react";
import api from "../../../api/baseConfig";
import ApiHelper from "../../../api/apiHelper";
import "react-toastify/dist/ReactToastify.css";
import "../EcommerceOrders/toastStyles.css";
import CustomTable from "../../CustomerManagement/CustomListTable";
import { useParams } from "react-router-dom";
import { sessionPrivileges } from "privileges";
import { toast } from "react-toastify";

export default function AllCategory(props) {
  const userPrivileges = sessionPrivileges();
  const Priv = sessionPrivileges().userprivileges || [];
  const [data, setData] = useState([]);
  const [subData, setSubData] = useState([]);
  let params = useParams();

  const activePage = props?.location?.state?.activePage;
  const itemsPerPage = props?.location?.state?.itemsPerPage;

  useEffect(() => {
    getAllCategory();
    window.scrollTo(0, 0);
  }, []);

  const getAllCategory = () => {
    ApiHelper.axiosCall(
      `${api.baseURL}${api.masterController.getAllCategory}`,
      "get"
    )
      .then((data) => {
        let tempData = [];
        data.forEach((datum) => {
          tempData.push({
            name: datum.name,
            description: datum.description,
            subCategoryCount: datum.subCount,
            id: datum.id,
          });
          if (datum.subCount === 0) {
            toast.warning("Please add Topic for " + datum.name);
          }
        });
        setData(tempData);
      })
      .catch((e) => {});
  };

  const deleteCategory = (id) => {
    ApiHelper.axiosCall(
      `${api.baseURL}${api.masterController.deleteCategory}${id}`,
      "post"
    )
      .then((d) => {
        getAllCategory();
      })
      .catch((e) => {});
  };

  const getTopicByCategory = async (id) => {
    await ApiHelper.axiosCall(
      `${api.baseURL}${api.masterController.getAllTopic}${id}`,
      "get"
    )
      .then((data) => {
        let tempData = [];
        data.forEach((datum) => {
          tempData.push({
            name: datum.name,
            description: datum.description,
            id: datum.id,
          });
        });
        setSubData(tempData);
      })
      .catch((e) => {});
  };

  return (
    <>
    {userPrivileges.isSuperAdmin && (
    <div className="page-content">
      <CustomTable
        header="Category"
        createLink="/create-category"
        data={data}
        dataLength={data.length}
        tableHeaders={[
          { label: "Category", val: "name" },
          { label: "Description", val: "description" },
          { label: "Topic Count", val: "subCategoryCount" },
          { label: "Topic", val: "subCategory", own: true },
        ]}
        ownLink="/sub/category/"
        searchPlaceholder="Search Category"
        deleteDatum={(id) => deleteCategory(id)}
        passEditFromRow
        editData={["name", "description", "id"]}
        accord
        showAdd={Priv.includes("ADD_CATEGORY")}
        hideEdit={!Priv.includes("EDIT_CATEGORY")}
        hideDelete={!Priv.includes("DELETE_CATEGORY")}
        getAllSubs={(id) => {
          getTopicByCategory(id);
        }}
        subTitle={"Topic"}
        subData={subData}
        createSubLink="/create-topic/"
        tableSubHeaders={[
          { label: "Sub Topic", val: "name" },
          { label: "Description", val: "description" },
        ]}
        passSubEditFromRow
        editSubData={["name", "description", "id"]}
        hoverable
        hideSubAdd={Priv.includes("ADD_TOPIC")}
        hideSubEdit={Priv.includes("EDIT_TOPIC")}
        hideSubDelete={Priv.includes("DELETE_TOPIC")}
        activePage={activePage}
        itemsPerPage={itemsPerPage}
      />
    </div>
    )}
    </>
  );
}
