import ApiHelper from "../../api/apiHelper"
import api from "../../api/baseConfig";

export async function VerifyPasscode({ passCode, assessmentId }) {
  return await ApiHelper.axiosCallPost(
    `${api.baseURL}${api.userManagement.verifyPass}`,
    'post',
    { assessmentId, passCode },
  ).catch(() => false)
}
