import {
  Box,
  Button,
  CircularProgress,
  Paper,
  IconButton,
  Tabs as MuiTabs,
  Tab,
  Grid,
  withStyles,
} from '@material-ui/core';
import {
  ChevronLeft,
  Flag,
  FlagOutlined,
  ChevronRight,
  DescriptionOutlined,
  PlayArrow,
  CodeOutlined,
} from '@material-ui/icons';
import { TProps } from './small-footer.props';
import styles from './small-footer.module.scss';

const TabBar = withStyles({
  root: {
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '0 !important',
    height: 64,
    position: 'relative',
    width: '100%',
  },
})(Paper);

const Tabs = withStyles({
  root: {
    borderRadius: 0,
  },
  indicator: {
    backgroundColor: 'currentColor !important',
    height: 4,
    top: '0 !important',
  },
})(MuiTabs);

const TabButton = withStyles({
  root: {
    fontSize: 12,
    margin: 'auto',
    minWidth: 'auto',
    opacity: 0.75,
    position: 'relative',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },
  selected: {
    color: 'inherit !important',
    opacity: 1,
  },
})(Tab);

const NavButton = withStyles({
  root: {
    borderColor: '#D8D6DB',
    boxShadow: '0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 4px 6px -4px rgba(24, 39, 75, 0.12) !important',
    color: '#888',
    height: 36,
    minWidth: 0,
    width: 40,
  },
})(Button);

const ConfirmButton = withStyles({
  root: {
    backgroundColor: '#1BA94C',
    color: '#fff',
    height: 36,
    minWidth: 0,
    '&:hover': {
      backgroundColor: '#15853c',
    },
  },
})(Button);

const RunCodeButton = withStyles({
  root: {
    borderColor: '#1BA94C',
    borderWidth: 2,
    color: '#1BA94C',
    height: 36,
    minWidth: 0,
    '&:hover': {
      borderColor: '#1BA94C',
      borderWidth: 2,
    },
  },
})(Button);

const FlagIconButton = withStyles({
  root: {
    color: '#C38A00',
  },
})(IconButton);

export function SmallFooter(props: TProps) {
  return (
    <>
      <div
        className={styles.smallFooter}>
        <Grid
          alignItems="center"
          className={styles.smallFooterContent}
          container
          justifyContent="space-between"
          wrap="nowrap">
          {!(props.currentQuestion.isCodingType && (props.currentTab.tab === 'solution'))
            ? (
              <Grid direction="row" wrap="nowrap" style={{ display: 'flex' }}>
                <NavButton
                  color="default"
                  disabled={props.disabled}
                  onClick={props.navPrevQuestion}
                  variant="outlined">
                  <ChevronLeft style={{ fontSize: 26 }} />
                </NavButton>
                <Box
                  mx={1}
                />
                <NavButton
                  color="default"
                  disabled={props.disabled}
                  onClick={props.navNextQuestion}
                  variant="outlined">
                  <ChevronRight style={{ fontSize: 26 }} />
                </NavButton>
              </Grid>
            )
            : (
              <Box
                mx={1}
              />
            )}
          <Grid direction="row" wrap="nowrap" style={{ display: 'flex' }}>
            {(props.currentQuestion.isCodingType && (props.currentTab.tab === 'solution')) && (
              <>
                <RunCodeButton
                  color="primary"
                  disabled={props.disabled || props.codeSolution.solution.trim().length == 0 || 
                    props.isAdmin === "true"
                  }
                  onClick={() => {
                    props.runCode(props.customInput || '', () => {
                      props.setOutputTab(1);
                      props.setIfOutputIsVisible(true);
                    });
                  }}
                  variant="outlined">
                  {props.runningCode
                    ? <CircularProgress color="inherit" size={18} />
                    : <PlayArrow />
                  }
                  &nbsp;&nbsp;
                  Run Code
                </RunCodeButton>
                <Box
                  mx={1}
                />
              </>
            )}
            {(!props.currentQuestion.isCodingType || (props.currentQuestion.isCodingType && (props.currentTab.tab === 'solution')))
              ? (
                <ConfirmButton
                  color="primary"
                  disabled = {
                    props.disabled || 
                  (props.currentQuestion.isCodingType
                  ? props?.codeSolution?.solution?.trim()?.length == 0
                  : props.currentQuestion.type === "DESC"
                  ? props?.descriptiveAnswer?.trim()?.length == 0
                  : !props.selectedOption?.length) 
                  || props.isAdmin === "true"
                  }
                  onClick={props.confirmSolution}
                  variant="contained">
                  {props.submittingSolution
                    ? <CircularProgress size={18} />
                    : 'Confirm'
                  }
                </ConfirmButton>
              )
              : (
                <FlagIconButton
                  disabled={props.disabled || props.isAdmin === "true"}
                  onClick={props.flagQuestion}>
                  {props.currentQuestion.flagged
                    ? <Flag style={{ fontSize: 26 }} />
                    : <FlagOutlined style={{ fontSize: 26 }} />
                  }
                </FlagIconButton>
              )
            }
          </Grid>
        </Grid>
        {props.currentQuestion.isCodingType && (
          <TabBar
            square>
            <Tabs
              disabled={props.disabled}
              onChange={(_e, newTab) => {
                props.currentTab.setTab(newTab);
              }}
              textColor="secondary"
              value={props.currentTab.tab}
              variant="fullWidth">
              <TabButton icon={<DescriptionOutlined />} label="Question" value="question" />
              <TabButton icon={<CodeOutlined />} label="Code" value="solution" />
            </Tabs>
          </TabBar>
        )}
        {props.currentQuestion.type === "DESC" && (
          <TabBar
            square>
            <Tabs
              disabled={props.disabled}
              onChange={(_e, newTab) => {
                props.currentTab.setTab(newTab);
              }}
              textColor="secondary"
              value={props.currentTab.tab}
              variant="fullWidth">
              <TabButton icon={<DescriptionOutlined />} label="Question" value="question" />
              <TabButton icon={<CodeOutlined />} label="Answer" value="solution" />
            </Tabs>
          </TabBar>
        )}
      </div>
      <div
        className={styles.smallFooterDummy}>
        <div className={styles.smallFooterContent} />
        {props.currentQuestion.isCodingType && (
          <TabBar square />
        )}
      </div>
    </>
  );
}
