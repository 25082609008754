import React, { useEffect } from "react";
import CustomListTable from "./CustomListTable";
import { connect } from "react-redux";
import { getCustomers } from "../../store/StudentAndCustomer/actions";
import api from "../../api/baseConfig";
import ApiHelper from "../../api/apiHelper";
// import CustomTable from "components/CustomTable";
import { sessionPrivileges } from "privileges";

function CustomersList(props) {
  const userPrivileges = sessionPrivileges();
  const Priv = sessionPrivileges().userprivileges || [];
  const activePage = props?.location?.state?.activePage;
  const itemsPerPage = props?.location?.state?.itemsPerPage;
  const deleteCustomer = (id) => {
    ApiHelper.axiosCall(
      `${api.baseURL}${api.customerManagement.delete}${id}`,
      "delete"
    )
      .then((d) => {
        props.dispatch(getCustomers());
      })
      .catch((e) => {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    {userPrivileges.showCustomerList && (
    <div className="page-content">
      <CustomListTable
        header="Customers"
        createLink="/customerManagement"
        data={
          props.Customers
            ? props.Customers.reduce((acc, val) => {
                acc.push({
                  name: val.name,
                  number: val.phoneNumber,
                  id: val.id,
                });
                return acc;
              }, [])
            : []
        }
        dataHeader={
          props.Customers
            ? props.Customers.reduce((acc, val) => {
                acc.push({
                  Customer_Name: val.name,
                  Contact_No: val.phoneNumber,
                  id: val.id,
                });
                return acc;
              }, [])
            : []
        }
        dataLength={props.Customers ? props.Customers.length : 0}
        tableHeaders={[
          { label: "Customer Name", val: "name" },
          { label: "Contact No", val: "number" },
        ]}
        searchPlaceholder="Search Customers"
        deleteDatum={(id) => deleteCustomer(id)}
        showAdd={Priv?.includes("ADD_CUSTOMER")}
        hideDelete={!Priv?.includes("DELETE_CUSTOMER")}
        hideEdit={!Priv?.includes("EDIT_CUSTOMER")}
        activePage={activePage}
        itemsPerPage={itemsPerPage}
      />
      {/* <CustomTable tableTitle="Customer list" hideAdd={false} /> */}
    </div>
    )}
    </>
  );
}

const mapStateToProps = (state) => ({
  Customers: state.StudentAndCustomerReducer.Customers,
});

export default connect(mapStateToProps, null)(CustomersList);
