import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { getCustomers } from "../../store/StudentAndCustomer/actions";
import styles from "./CopyModal.module.scss";
import { RequiredIndicator } from 'pages/utils/RequiredIndicator';
import { GetObjectFromString } from 'pages/utils/GetObjectFromString';
import { ReactSelectTervStyle } from 'pages/utils/ReactSelectTervStyle';
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import api from "../../api/baseConfig";
import apiHelper from "api/apiHelper";
import AdvancedHostSelectionAlt from "pages/cloudLab/component/AdvancedHostSelectionAlt";

const closeIcons = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#323036"/>
    </svg>
);

const CopyModal = ({
    isOpen,
    close,
    createCopy,
    qpIds,
    setChecked,
    setCheckedAll,
    ...props
}) => {
    const methods = useForm({ mode: "onBlur" });
    const {
        register,
        control,
        formState: { errors, isSubmitting },
        reset,
        watch,
        handleSubmit,
    } = methods;

    const customers = useMemo(
        () =>
          (props?.Customers || []).sort(
            ({ name: n1 = "" }, { name: n2 = "" }) =>
              n1?.toLowerCase() < n2?.toLowerCase()
          ),
        [props?.Customers]
      );
    
    const customersList = [];
        customers.forEach(function (item) {
        customersList.push({ label: item.name, value: item.id });
    });

    const submitCopy = async ({
        customer,
        userName,
    }) => {
        const payload = {
            customer,
            userName: userName.toString(),
            qpIds,
        };
        await apiHelper.axiosCallPost(
            `${api.baseURL}${api.QuestionPaperController.createQPcopy}`,
            "post",
            payload
        ).then((data) => {
            if(data){
                close();
                setChecked([]);
                setCheckedAll(false);
            }

        }).catch((e) => {
            console.log(e);
        });
      };

    return (
        <Dialog open={isOpen} onClose={close} fullWidth maxWidth="sm">
            <DialogTitle onClose={close} style={{textAlign: "center", backgroundColor: "#F9F9F9"}}>
            Copy question paper
            <span onClick={close} style={{float: "right", cursor: "pointer"}}>
            {closeIcons}
            </span>
            </DialogTitle>
            <DialogContent dividers style={{height: "396px"}}>
                <p className={styles.contents}>Please select customer and admin to copy selected question paper</p>
                <form onSubmit={handleSubmit(submitCopy)}>
                    <div className="terv-row">
                        <div>
                            <label className="terv-label" htmlFor="Customer">
                                {RequiredIndicator("Select Customer")}
                            </label>
                            <Controller
                             name="customer"
                             control={control}
                             rules={{ required: "Please select a customer"}}
                             render={({ field: { onChange, value }}) => (
                                 <Select
                                  inputId="Customer"
                                  onChange={(val) => {
                                      onChange(val.value);                                      
                                  }}
                                  value={GetObjectFromString(customersList, value)}
                                  options={customersList}
                                  styles={ReactSelectTervStyle("sm")}
                                 />
                             )}
                            />
                            {errors?.customer && (
                                <div style={{ color: "red" }}>{errors?.customer.message}</div>
                            )}
                        </div>
                    </div>
                    <div className="terv-row" style={{display:"block"}}>
                            <label className="terv-label" htmlFor="Admin">
                                {RequiredIndicator("Select Admin")}
                            </label>
                              <AdvancedHostSelectionAlt
                                customerId={
                                watch("customer")
                                }
                                hookFormControls={methods}
                                viewOnly={false}
                                studentFieldName="userName"
                              />
                            {errors?.admin && (
                                <div style={{ color: "red" }}>{errors?.admin.message}</div>
                            )}
                    </div>
                    <hr
                        style={{
                        backgroundColor: "#E3F2FF",
                        marginTop: "0.5rem",
                        marginBottom: "2rem",
                        }}
                    />
                    <Button
                     type="submit"
                     color="primary"
                     variant="contained"
                     disabled={isSubmitting}
                     classes={{ root: styles.submitButton }}
                    >
                        Copy
                    </Button>
                    {/* <Button
                     color="primary"
                     variant="contained"
                     disabled={isSubmitting}
                     classes={{ root: styles.cancelButton }}
                    >
                        Cancel
                    </Button> */}
                </form>
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    Customers: state.StudentAndCustomerReducer.Customers,
  });

export default connect(mapStateToProps, null)(withRouter(CopyModal));
