/** @format */

import React from "react";
import { ContentHeader } from "./ContentHeader";
import { HomeContent } from "./HomeContent";
import MarqueeComponent from "react-fast-marquee";
import {
  amazon,
  embed,
  plintron,
  simpleSolve,
  emerson,
  TDot,
  hcl,
  fss,
  national,
  ANBSystems,
  conSystems,
  ICTAcademy,
  blockchain,
  harapa,
  visAi,
  kaar,
  indianEagle,
  pinnacle,
  coats,
  agira,
  hackwit,
  lync,
  techfully,
  indusland,
  kmc,
} from "./PlacedCompanies";
import { useTranslation } from "react-i18next";

const PlacedCompanies1 = [
  plintron,
  embed,
  pinnacle,
  techfully,
  simpleSolve,
  emerson,
  indusland,
  TDot,
  ANBSystems,
  blockchain,
  ICTAcademy,
  conSystems,
];

const PlacedCompanies2 = [
  hackwit,
  hcl,
  coats,
  indianEagle,
  kaar,
  agira,
  visAi,
  harapa,
  kmc,
  lync,
  fss,
  national,
];

const MarqueeTechruit: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <ContentHeader
        title={t(HomeContent.Headers.Trusted.Main)}
        sub={t(HomeContent.Headers.Trusted.Sub)}
        description={t(HomeContent.Headers.Trusted.Description)}
      />
      <MarqueeComponent style={{ marginBottom: "-3rem" }}>
        {PlacedCompanies1.map((it: JSX.Element, ind: number) => (
          <div key={`Company1${ind}`} style={{ width: 180 }}>
            {it}
          </div>
        ))}
      </MarqueeComponent>
      <MarqueeComponent direction="right">
        {PlacedCompanies2.map((it: JSX.Element, ind: number) => (
          <div key={`Company2${ind}`} style={{ width: 180 }}>
            {it}
          </div>
        ))}
      </MarqueeComponent>
    </>
  );
};

export default MarqueeTechruit;
