export const SortData = (array, key, sortType) => {
  try {
    let sortArray = [...array];

    if (sortType === "asc") {
      sortArray.sort((a, b) =>
        (a[key] || "").trim().toLowerCase() >
        (b[key] || "").trim().toLowerCase()
          ? 1
          : -1
      );
    } else {
      sortArray.sort((a, b) =>
        (a[key] || "").trim().toLowerCase() <
        (b[key] || "").trim().toLowerCase()
          ? 1
          : -1
      );
    }
    return sortArray;
  } catch (e) {}
};

export const SortDataNum = (array, key, sortType) => {
  try {
    let sortArray = [...array];

    if (sortType === "asc") {
      sortArray.sort((a, b) =>
        (a[key] || "") -
        (b[key] || "")
      );
    } else {
      sortArray.sort((a, b) =>
        (b[key] || "") -
        (a[key] || "")
      );
    }
    return sortArray;
  } catch (e) {}
};
