import apiHelper from "api/apiHelper";
import api from "api/baseConfig";
import React, { useEffect, useState } from "react";
import TotalCard from "./TotalCard";
import ManualGradingTable from "./ManualGradingTable";
import { uid } from "react-uid";
import { Link } from "react-router-dom";

const totalCardKeys = [
  { label: "Students completed", value: "completedStudents", color: "#062DB7" },
  { label: "Grading completed", value: "gradingCompleted", color: "#1BA94C" },
  { label: "Grades published", value: "gradindPublished", color: "#794DF5" },
  { label: "Grading Left", value: "gradingLeft", color: "#D42216" },
];

function AllManualGradingNext(props) {
  const [allData, setAllData] = useState({ real: {}, copy: {} });
  useEffect(() => {
    window.scrollTo(0, 0);
    if (props?.location?.state?.assessmentId) {
      getAssessmentGradingStatus(props?.location?.state?.assessmentId);
    }
  }, []);

  const getAssessmentGradingStatus = async (id) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.userManagement.getAssessmentGradingStatus}${id}`,
        "get"
      );
      if (data) {
        setAllData({ real: data, copy: data });
      }
    } catch (error) {}
  };

  const customerId = props?.location?.state?.customerId;
  const activePage = props?.location?.state?.activePage?.allList;
  const activeTab = props?.location?.state?.activeTab;

  return (
    <div className="page-content">
      <div style={{ display: "flex", gap: "1.5rem", marginBottom: "1rem" }}>
        {totalCardKeys.map((key, i) => (
          <TotalCard
            title={
              key.value === "completedStudents"
                ? `${allData?.real?.[key.value] || 0}/${
                    allData?.real?.totalStudents || 0
                  }`
                : allData.real[key.value] || 0
            }
            description={key.label}
            color={key.color}
            id={uid(i)}
          />
        ))}
      </div>
      <ManualGradingTable
        tableHeaders={[
          { label: "Name", val: "firstName" },
          { label: "Start time", val: "startTime" },
          { label: "End time", val: "endTime" },
          { label: "Attempts", val: "attempts" },
          { label: "Exit Attempts", val: "existAttempt" },
          { label: "Grading", val: "manualGradingCompleted" },
        ]}
        data={allData}
        changeData={(data) => setAllData({ ...allData, copy: data })}
        assessmentName={allData?.real?.assessmentName}
        batchName={allData?.real?.batchName}
        completedOn={allData?.real?.completedOn}
        getAssessmentGradingStatus={getAssessmentGradingStatus}
      />
      <Link
        style={{ width: 202, color: "gray", padding: "20px 0 0 0" }}
        to={{
          pathname: "/assessments",
          state: {
            customer: customerId,
            activePage: activePage,
            activeTab: activeTab,
          },
        }}
        className="d-sm-inline-block btn-link"
      >
        <i className="mdi mdi-arrow-left mr-1" /> Back to Assessments{" "}
      </Link>
    </div>
  );
}

export default AllManualGradingNext;
