import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  root: {
    minWidth: 225,
    boxShadow: "0px 4px 16px rgba(158, 158, 158, 0.25)",
    borderRadius: 5,
  },
  title: { fontSize: 28, fontWeight: 500 },
});

const TotalCard = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.root} key={props.id}>
      <CardContent>
        <Typography className={classes.title} style={{ color: props.color }}>
          {props.title}
        </Typography>
        <Typography>{props.description}</Typography>
      </CardContent>
    </Card>
  );
};

export default TotalCard;
