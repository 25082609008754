import * as CustomIcons from "../../../assets/icons/icons";

export function DeleteAction(props) {
  let descc = props.desc;
  return (
    <span
      style={{
        color: "#51B960",
        cursor: "pointer",
        display: descc === "Admin" ? "none" : descc === "Mentor" ? "none" : descc === "Learner" ? "none" : "block"
      }}
      onClick={props.delete}
    >
      {CustomIcons.circleCrossIcon({ size: 19, color: "#EB5757" })}
    </span>
  );
}
