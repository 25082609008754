/** @format */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "pure-react-carousel/dist/react-carousel.es.css";
import { arrowLeft, arrowRight, circleCheckSolid } from "assets/icons/icons";
import styles from "./BannerTechruit.module.scss";
import mobileStyles from "./HeroCarouselMobile.module.scss";
import { HomeContent } from "./HomeContent";
import { withStyles } from "@material-ui/core";
import { Button } from "reactstrap";
import HalfCircle from "./HalfCircle.png";
import { CompanyLogos } from "./MobileResLogo";
import MobileBanner2 from "./MobileBanner2.png";
import NewBanner3 from "./NewBanner3.svg";
import NewBanner2 from "./NewBanner2.svg";
import MobileBanner4 from "./MobileBanner4.png";
import NascomBanner from "./TERV-Banner.jpg";
import newharappa from "./newharappa.png";
import Banner2NewGirl from "./Banner2NewGirl.png";
import asd from "./asd.png";
import newDhoni from "./newDhoni.png";
import sliderBanner from "../../../assets/Landing/hero-image.png.png";
import sliderBannerBg from "../../../assets/Landing/Frame.png";


import Godaddylogo from "./Godaddylogo.png";


import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";

interface Props {
  isMobile: boolean;
  isTab: boolean;
  onCliclk: () => void;
}


const BannerTechruit: React.FC<Props> = ({ isMobile, isTab, onCliclk }) => {

  // const scrollToHalf = () => {
  //   const yOffset = window.innerHeight * 6;

  //   window.scrollTo({
  //     top: yOffset,
  //     behavior: 'smooth' 
  //   });
  // };

  return (
    <div>
       <div className={styles.carouselProvider}>
    <div className={styles.carouselSlider}  style={{paddingTop:"5%",}}>
    <div className={styles.joinGroupContainer} 
    style={{ backgroundImage: `url(${sliderBannerBg})`, backgroundPosition: 'bottom right', backgroundRepeat: 'no-repeat', backgroundSize:"580px"  }}
    // style={{textAlign:"left",}}
    >
        <div
          style={{
            marginLeft: isMobile ? "0.5rem" : "0rem",
          }}
        >
          <p className={styles.joinGroupHeader}>
          Recruit Candidates
          </p>
          <p className={styles.joinGroupHeader}>
          <span style={{color:"#2FFF4B"}}>In Minutes</span>
          </p>
          <p className={styles.joinGroupList} >
          Assessment in Under 60 Seconds
          </p>
          <div style={{display:'flex', gap:"5%"}}>
          <Button
                color="primary"
                style={{
                  width: "150px",
                  height: "52px",
                }}
                onClick={onCliclk}
                // onClick={scrollToHalf}
              >
                Explore TESTS
              </Button>
              <Button
              color="success"
              outline
                style={{
                  width: "150px",
                  height: "52px",
                }}
                onClick={onCliclk}
                // onClick={scrollToHalf}
              >
                Use TECHRUIT for
              </Button>
        </div>
        </div>
        <div >
         <img src={sliderBanner}  style={{ width: '100%', height: '100%', }} loading="lazy"></img> 

        </div>
      </div>
    </div> 
    </div>
    </div>
  
  );
};

export default BannerTechruit;
