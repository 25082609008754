import React from "react";
import styles from "./TervButtons.module.scss";

type Props = {
  text: string;
  icon?: JSX.Element;
  icon1?: JSX.Element;
  color?: string;
  secondary?: boolean;
  tertiary?: boolean;
  onClick?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  disabledSubmission?:boolean;
  width?: number | string;
  justifyCenter?: boolean;
  style?: { [key: string]: string | number };
};

export const TervButtonFilled: React.FC<Props> = ({
  text,
  icon,
  icon1,
  type,
  color,
  secondary,
  tertiary,
  onClick,
  disabled,
  disabledSubmission,
  width,
  justifyCenter,
  style,
}): JSX.Element => (
  <button
    className={`${styles.buttonFilledMain} ${disabled && styles.disabled} ${
      secondary && styles.secondary
    } ${tertiary && styles.tertiary} ${disabledSubmission && styles.disabledSubmission}`}
    type={type}
    onClick={onClick}
    disabled={disabled}
    style={{
      ...(width && { width }),
      ...(justifyCenter && { margin: "auto" }),
      ...(color && { color }),
      ...(style && { ...style }),
    }}
  >
    {icon}
    {text}
    {icon1}
  </button>
);

export const TervButtonFilled2: React.FC<Props> = ({
  text,
  icon,
  icon1,
  type,
  color,
  secondary,
  tertiary,
  onClick,
  disabled,
  disabledSubmission,
  width,
  justifyCenter,
  style,
}): JSX.Element => (
  <button
    className={`${styles.buttonFilledMain2} ${disabled && styles.disabled} ${
      secondary && styles.secondary
    } ${tertiary && styles.tertiary} ${disabledSubmission && styles.disabledSubmission}`}
    type={type}
    onClick={onClick}
    disabled={disabled}
    style={{
      ...(width && { width }),
      ...(justifyCenter && { margin: "auto" }),
      ...(color && { color }),
      ...(style && { ...style }),
    }}
  >
    {icon}
    {text}
    {icon1}
  </button>
);

export const TervButtonOutlined: React.FC<Props> = ({
  text,
  icon,
  type,
  color,
  secondary,
  tertiary,
  onClick,
  disabled,
  width,
  justifyCenter,
  style,
  disabledSubmission,
}): JSX.Element => (
  <button
    className={`${styles.buttonOutlinedMain} ${disabled && styles.disabled} ${disabledSubmission && styles.disabledSubmission}`}
    type={type}
    onClick={onClick}
    disabled={disabled}
    style={{
      ...(width && { width }),
      ...(justifyCenter && { margin: "auto" }),
      ...(color && { color }),
      ...(style && { ...style }),
    }}
  >
    {icon}
    {text}
  </button>
);

export const TervButtonOutlined2: React.FC<Props> = ({
  text,
  icon,
  type,
  color,
  secondary,
  tertiary,
  onClick,
  disabled,
  width,
  justifyCenter,
  style,
  disabledSubmission,
}): JSX.Element => (
  <button
    className={`${styles.buttonOutlinedMain2} ${disabled && styles.disabled} ${disabledSubmission && styles.disabledSubmission}`}
    type={type}
    onClick={onClick}
    disabled={disabled}
    style={{
      ...(width && { width }),
      ...(justifyCenter && { margin: "auto" }),
      ...(color && { color }),
      ...(style && { ...style }),
    }}
  >
    {icon}
    {text}
  </button>
);
