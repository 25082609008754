import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import "./courseOverview.css";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CourseContent from "./course-content";
import CourseReview from "./course-review";
import CourseCertificate from "../buy-course/certificate";
import InstructorContent from "./instructor";
import { useHistory, useParams } from "react-router-dom";
import apiHelper from "../../api/apiHelper";
import api from "../../api/baseConfig";
import { useUserDetails } from "../../hooks/use-user-details";
import ReactPlayer from "react-player";
// import "react-player/dist/react-player.css";
import RelatedCourses from "../student-dashboard/RelatedCourses";
import * as CustomIcons from "../../assets/icons/icons";
import ReactHtmlParser from "html-react-parser";
import CardMedia from "@material-ui/core/CardMedia";
import { GetRandom } from "../utils/GetRandom";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { addToCartPrepare } from "../virtualLab/ViewPracticeQuestions";
import { CapitalizeFirstLetter } from "pages/utils/CapitalizeFirstLetter";
import { CircularProgress, Modal } from "@material-ui/core";
import { useSelector } from "react-redux";
import SignInModal from "components/VerticalLayout/SignInModal";
import { ModalToggle } from "pages/utils/ModalToggle";
import { CardBody } from "reactstrap";
import Confirmation from "./courseFormSubmit";
import Skeleton from "@material-ui/lab/Skeleton";
import { sessionPrivileges } from "privileges";
import Footer from "components/VerticalLayout/Footer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  grid: {
    background: "#20242D",
  },
  rating: {
    display: "flex",
    alignItems: "center",
  },
  card: {
    maxWidth: 480,
    boxShadow: "0px 0px 25px rgba(170, 170, 170, 0.25)",
    borderRadius: 5,
  },
  media: {
    height: 320,
    width: 480,
    backgroundSize: "contain",
  },
  colorPrimary: {
    backgroundColor: "#00695C",
  },
  barColorPrimary: {
    backgroundColor: "#F56F18;",
  },
  buttonWidth: {
    width: "100%",
  },
  priceTag: {
    display: "inline-flex",
    inlineSize: "-webkit-fill-available",
    position: "relative",
    margin: 5,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={4} variant="filled" {...props} />;
}

export default function CourseOverview() {
  const userPrivileges = sessionPrivileges();
  const userId = userPrivileges.userId;
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [relatedCourse, setRelatedCourse] = useState([]);
  const [isAdministrator] = useState(
    userPrivileges.isAdmin || userPrivileges.isSuperAdmin
  );
  const isLoggedIn = useSelector((state) => state.LoggedInReducer);

  const { courseId } = useParams();

  const bull = <span className={classes.bullet}>•</span>;

  const [courseDetails, setCourseDetails] = useState({});
  const [courseContent, setCourseContent] = useState([]);
  const [CourseOverall, setCourseOverall] = useState([]);
  const [PDFShow, setPDFShow] = useState(false);
  const [starValue, setStarValue] = useState(0);
  const [isCopied, setIsCopied] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [inCart, setIfInCart] = useState([]);
  const [addingToCart, setIfAddingToCart] = useState(false);
  const [loadingUrl, setLoadingUrl] = useState(false);
  const [externalLoadingUrl, setExternalLoadingUrl] = useState(false);
  const [handleSignInModalOpen, setHandleSignInModalOpen] = useState(false);
  const [head, setHead] = useState("");
  const [tokenValue, setTokenValue] = useState({});

  const contentVideoClick = (data) => {
    history.push(`/course-learning/${courseId}/${data.id}`);
  };

  const CustomizeTooltip = withStyles({
    tooltip: {
      padding: "10px 10px",
      color: "black",
      backgroundColor: "white",
      fontSize: "13px",
      lineHeight: "18px",
      maxWidth: 700,
      border: "none",
    },
  })(Tooltip);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleVideoPlayer = (data) => {
    const youTube = data.startsWith("https://www.youtube");

    if (youTube) {
      videoSrc = {
        type: "video",
        sources: [
          {
            src: data,
            provider: "youtube",
          },
        ],
      };
    } else {
      videoSrc = {
        type: "video",
        sources: [
          {
            src: data,
            type: "video/mp4",
            size: 720,
          },
        ],
      };
    }
  };

  useEffect(async () => {
    if (document.getElementById("adobe-dc-view")) {
      showPDF("/files/PDFTRON_about.pdf", "data.name");
    }
  }, [document.getElementById("adobe-dc-view"), PDFShow]);

  function showPDF(urlToPDF, PDFTitle) {
    var adobeDCView = new AdobeDC.View({
      clientId: "418c43e6737340d78c7b947a68ab1a0b",
      divId: "adobe-dc-view",
    });

    const viewerOptions = {
      embedMode: "FULL_WINDOW",
      defaultViewMode: "FIT_WIDTH",
      showDownloadPDF: false,
      showPrintPDF: false,
      showLeftHandPanel: true,
      showAnnotationTools: false,
    };
    var previewFilePromise = adobeDCView.previewFile(
      {
        content: { location: { url: urlToPDF } },
        metaData: { fileName: "PDF" },
      },
      viewerOptions
    );
  }
  const handleOpenPage = (data) => {
    if (data.type === "Link" || data.type === "Video") {
      setPDFShow(false);
      handleVideoPlayer(data.media);
    } else if (data.type === "Study material") {
      setPDFShow(true);
    }
  };

  useEffect(async () => {
    window.scroll(0, 0);
    setExternalLoadingUrl(true);
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.courseController.getCourseDetails}${userId}/${courseId}`,
        "get"
      );
      if (data) {
        setCourseDetails(data.courseDetails);
        setStarValue(data?.courseDetails?.courseRating);
        setCourseContent(data.modulesList);
        setCourseOverall(data.courseContentDetailsDTO);
        setIfInCart(data?.addedCart);
        if (data.lastViewedResource && data.lastViewedResource.media) {
          handleOpenPage(data.lastViewedResource);
        } else {
          handleOpenPage(data.modulesList[0].resourceList[0]);
        }
      }
      setExternalLoadingUrl(false);
    } catch (e) {
      setExternalLoadingUrl(false);
    }
  }, [courseId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const userDetails = useUserDetails();

  useEffect(() => {
    if (courseDetails.categoryId) {
      getRelatedCourses(courseDetails.categoryId);
    }
  }, [courseDetails, courseId]);

  const getRelatedCourses = async (id) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.courseController.getRelatedCourses}${id}`,
        "get"
      );
      if (data) {
        setRelatedCourse(data);
      }
    } catch (e) {}
  };

  const goToCourse = () => history.push(`/course-learning/${courseId}`);
  const userDetailsName = useSelector(
    (state) => state.UserProfileReducer.UserData
  );

  const externalCourse = async () => {
    setExternalLoadingUrl(true);
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.Prepare.externalCourseApi}${courseId}?firstName=${userDetailsName?.firstName}&lastName=${userDetailsName?.lastName}`,
        "get"
      );
      // setLoadingUrl(false);
      setTokenValue(data);
      // window.open(`${data}`, "_blank");
      setExternalLoadingUrl(false);
    } catch (e) {
      console.log(e);
    }
  };

  const externalCourseStart = async () => {
    setLoadingUrl(true);
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.Prepare.externalCourse}${courseId}?firstName=${userDetailsName?.firstName}&lastName=${userDetailsName?.lastName}`,
        "get"
      );
      window.open(data, "_blank");
      setLoadingUrl(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleEnroll = () => {
    if (userId) {
      if (courseDetails?.paidPackage)
        history.push({
          pathname: "/checkout",
          params: { items: courseId, productType: "course" },
        });
      else goToCourse();
    } else {
      setHandleSignInModalOpen(!handleSignInModalOpen);
      setHead("login");
      // window.location.pathname = "/login";
    }
  };
  const handleAddToCart = async () => {
    try {
      setIfAddingToCart(true);
      const res = await addToCartPrepare(courseId, "course");
      if (res) setIfInCart((pre) => [...pre, courseId]);
    } catch {
    } finally {
      setIfAddingToCart(false);
    }
  };
  useEffect(() => {
    if (userDetailsName?.firstName) {
      externalCourse();
    }
  }, [userDetailsName]);
  return (
    <>
      <div
        className={`course-overview ${isAdministrator ? "admin" : ""}`}
        style={{ overflow: "hidden" }}
      >
        <div className={`${isAdministrator ? "fullscreen" : ""}`}>
          <Grid container spacing={0} className={classes.grid}>
            <Grid item xs={12} sm={6}>
              <div
                className="video-player"
                style={{ padding: "32px 16px 32px 32px" }}
              >
                <Breadcrumbs className="pb-3">
                  <Link to={"/prepare"}>
                    <Typography color="textPrimary">Prepare</Typography>
                  </Link>
                  <Typography
                    className="breadcrumb-content"
                    color="textPrimary"
                  >
                    {externalLoadingUrl ? "" : courseDetails.name}
                  </Typography>
                </Breadcrumbs>

                {courseDetails.promotionalVideo ? (
                  <ReactPlayer url={courseDetails.promotionalVideo} width={"100%"} controls={true} 
                    config={{ file: { 
                            attributes: {
                              controlsList: 'nodownload'
                            }
                          }}}
                  />
                  ) : (
                  <div className="buyImage">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Card className={classes.card}>
                          <CardMedia
                            className={classes.media}
                            image={
                              courseDetails?.media
                                ? courseDetails.media.includes("default")
                                  ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${courseDetails.media}.jpeg`
                                  : courseDetails.media
                                : `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/default-${
                                    GetRandom(9) + 1
                                  }.jpeg`
                            }
                          />
                        </Card>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Grid>
            {externalLoadingUrl ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginLeft: "5rem" }}>
                  <h4 style={{ color: "white" }}>Loading...</h4>
                  <CircularProgress color="primary" />
                </div>
              </div>
            ) : (
              <Grid item xs={12} sm={6}>
                <div className="d-flex flex-column justify-content-between course-banner">
                  <div style={{ flex: "1 1 0%" }}>
                    <h2
                      className="prepare-desc bd-highlight"
                      style={{ textTransform: "capitalize" }}
                    >
                      {courseDetails.name}&nbsp;&nbsp;
                      {courseDetails.courseTags === "premium"
                        ? CustomIcons.premiumIcon
                        : courseDetails.courseTags === "special"
                        ? CustomIcons.specialIcon
                        : courseDetails.courseTags === "latest"
                        ? CustomIcons.latestIcon
                        : courseDetails.courseTags === "feature"
                        ? CustomIcons.feturedIcon
                        : ""}
                    </h2>
                    <CustomizeTooltip title={courseDetails.description}>
                      <p className="prepare bd-highlight prepareParagraph">
                        {courseDetails.description}
                      </p>
                    </CustomizeTooltip>
                    <div className={classes.rating}>
                      <Rating
                        name="size-large"
                        value={starValue}
                        onChange={(event, newValue) => {
                          setStarValue(newValue);
                        }}
                        size="large"
                        readOnly
                      ></Rating>
                      <span className="banner-rating small-font">
                        {courseDetails?.courseRating || ""}
                      </span>
                      <div className="pl-5 banner-language">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.66659 6.66671H18.3333M9.99992 19.1667C15.0624 19.1667 19.1666 15.0625 19.1666 10C19.1666 4.93754 15.0624 0.833374 9.99992 0.833374C4.93742 0.833374 0.833252 4.93754 0.833252 10C0.833252 15.0625 4.93742 19.1667 9.99992 19.1667ZM9.99992 19.1667C12.4999 19.1667 13.3333 15 13.3333 10C13.3333 5.00004 12.4999 0.833374 9.99992 0.833374C7.49992 0.833374 6.66658 5.00004 6.66658 10C6.66658 15 7.49992 19.1667 9.99992 19.1667ZM1.66659 13.3334H18.3333H1.66659Z"
                            stroke="#D8D6DB"
                          />
                        </svg>
                        <span className="lang small-font">
                          {courseDetails.languages}
                        </span>
                      </div>
                    </div>
                    <ul className="list-inline">
                      <li className="list-inline-item small-font">
                        {courseDetails.duration} {courseDetails.duration <= 1 ? "hour duration" : "hours duration"}
                      </li>
                      {(courseDetails.externalObjectId == null ||
                        courseDetails.externalObjectName == null) && (
                        <li className="list-inline-item small-font">
                          <span className="bull">{bull} </span>
                          {courseDetails.numberOfModules} {courseDetails.numberOfModules <=1 ? "chapter" : "chapters" }
                        </li>
                      )}
                      <li className="list-inline-item small-font">
                        <span className="bull"> {bull}</span>{" "}
                        {CapitalizeFirstLetter(courseDetails.level)}
                      </li>
                    </ul>
                    {courseDetails?.paidPackage ? (
                      !courseDetails?.purchased ? (
                        <div className="price-wraper">
                          <h2>
                            ₹
                            {courseDetails.strikingPrice == 0
                              ? courseDetails?.originalPrice || 0
                              : courseDetails.strikingPrice}{" "}
                            INR
                          </h2>
                          {courseDetails.strikingPrice == 0 ? (
                            ""
                          ) : (
                            <p>₹{courseDetails?.originalPrice || 0} INR</p>
                          )}
                          {courseDetails.strikingPrice == 0 ? (
                            ""
                          ) : (
                            <span>{`(Save ${Math.floor(
                              courseDetails?.discountAmount
                            )} %)`}</span>
                          )}
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  {isAdministrator && isLoggedIn.state ? (
                    <div className="mt-2">    
                       <Button
                        variant="outlined"
                        onClick={() => history.goBack()}
                        className="buy-button"
                      >
                        Back 
                      </Button>
                    </div>
                  ) : (
                    <div className="mt-2">
                      <Grid container spacing={2}>
                        {courseDetails?.paidPackage ? (
                          !courseDetails?.purchased ? (
                            <div className="mt-5">
                              {/* {courseDetails.status === "NotStarted" && ( */}
                              <Grid container spacing={5}>
                                <Grid item xs>
                                  <Button
                                    variant="outlined"
                                    onClick={handleEnroll}
                                    className="buy-button"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {courseDetails?.paidPackage
                                      ? !courseDetails?.purchased
                                        ? "Buy now"
                                        : "Start a course"
                                      : "Enroll course"}
                                  </Button>
                                </Grid>
                                {isLoggedIn.state && (
                                <Grid item xs>
                                  {(courseDetails.externalObjectId == null ||
                                    courseDetails.externalObjectName ==
                                      null) && (
                                    <div>
                                      <Button
                                        variant="outlined"
                                        className="cart-button"
                                        disabled={addingToCart}
                                        onClick={async () => {
                                          if (!inCart) {
                                            setIfAddingToCart(true);
                                            await addToCartPrepare(
                                              courseDetails?.id
                                            )
                                              .then((res) => {
                                                if (res !== false) {
                                                  setIfInCart(true);
                                                } else {
                                                  setIfInCart(false);
                                                }
                                              })
                                              .catch(() => {})
                                              .finally(() => {
                                                setIfAddingToCart(false);
                                              });
                                          }
                                        }}
                                        outline
                                        size="lg"
                                      >
                                        {courseDetails?.addedCart ? (
                                          "Incart"
                                        ) : addingToCart ? (
                                          <CircularProgress
                                            color="inherit"
                                            thickness={5}
                                            size={22}
                                            style={{ marginTop: 5 }}
                                          />
                                        ) : (
                                          <span onClick={() => setOpen(true)}>
                                            Add to cart
                                          </span>
                                        )}
                                      </Button>
                                      <Snackbar
                                        open={open}
                                        autoHideDuration={2000}
                                        onClose={handleClose}
                                      >
                                        <Alert
                                          onClose={handleClose}
                                          style={{ backgroundColor: "#794df5" }}
                                        >
                                          Added to cart Successfully
                                        </Alert>
                                      </Snackbar>
                                    </div>
                                  )}
                                </Grid>
                                )}
                                <Grid item xs>
                                  <Tooltip
                                    title="Copy Link"
                                    arrow
                                    placement="top-start"
                                  >
                                    <CopyToClipboard
                                      text={window.location.href}
                                    >
                                      {isCopied ? (
                                        <Button
                                          variant="outlined"
                                          className="text-light"
                                          onClick={() => {
                                            setIsCopied(true);
                                          }}
                                          style={{
                                            border: "1px solid #ffffff",
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="#ffffff"
                                          >
                                            <path
                                              d="M20 2H10C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4V6H16C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8V16H20C20.5304 16 21.0391 15.7893 21.4142 15.4142C21.7893 15.0391 22 14.5304 22 14V4C22 3.46957 21.7893 2.96086 21.4142 2.58579C21.0391 2.21071 20.5304 2 20 2Z"
                                              fill="#ffffff"
                                            />
                                            <path
                                              d="M4 22H14C15.103 22 16 21.103 16 20V10C16 8.897 15.103 8 14 8H4C2.897 8 2 8.897 2 10V20C2 21.103 2.897 22 4 22ZM6 12H12V14H6V12ZM6 16H12V18H6V16Z"
                                              fill="#ffffff"
                                            />
                                          </svg>
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="outlined"
                                          className="text-light"
                                          onClick={() => {
                                            setIsCopied(true);
                                          }}
                                          style={{
                                            border: "1px solid #ffffff",
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="#ffffff"
                                          >
                                            <path
                                              d="M20 2H10C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4V6H16C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8V16H20C20.5304 16 21.0391 15.7893 21.4142 15.4142C21.7893 15.0391 22 14.5304 22 14V4C22 3.46957 21.7893 2.96086 21.4142 2.58579C21.0391 2.21071 20.5304 2 20 2Z"
                                              fill="#ffffff"
                                            />
                                            <path
                                              d="M4 22H14C15.103 22 16 21.103 16 20V10C16 8.897 15.103 8 14 8H4C2.897 8 2 8.897 2 10V20C2 21.103 2.897 22 4 22ZM6 12H12V14H6V12ZM6 16H12V18H6V16Z"
                                              fill="#ffffff"
                                            />
                                          </svg>
                                        </Button>
                                      )}
                                    </CopyToClipboard>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                              {/* )} */}
                            </div>
                          ) : (
                            <div>
                              {courseDetails.status !== "NotStarted" ? (
                                <Grid item xs>
                                  {courseDetails.externalObjectId == null ||
                                  courseDetails.externalObjectName == null ? (
                                    <Button
                                      variant="outlined"
                                      onClick={
                                        courseDetails.externalObjectId ==
                                          null ||
                                        courseDetails.externalObjectName == null
                                          ? goToCourse
                                          : externalCourse
                                      }
                                      className="cart-button"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {loadingUrl
                                        ? "loading..."
                                        : "Continue learning"}
                                    </Button>
                                  ) : (
                                    <Confirmation
                                      status={courseDetails.status}
                                      loadingUrl={loadingUrl}
                                      setLoading={setLoadingUrl}
                                      apiLink={tokenValue?.apiUrl}
                                      apiToken={tokenValue?.value}
                                      externalCourseStart={externalCourseStart}
                                    />
                                  )}
                                </Grid>
                              ) : (
                                <Grid item xs>
                                  {courseDetails.externalObjectId == null ||
                                  courseDetails.externalObjectName == null ? (
                                    <Button
                                      variant="outlined"
                                      onClick={goToCourse}
                                      className="buy-button"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {loadingUrl
                                        ? "loading..."
                                        : "start course"}
                                    </Button>
                                  ) : (
                                    <Confirmation
                                      status={"start Course"}
                                      loadingUrl={loadingUrl}
                                      setLoading={setLoadingUrl}
                                      apiLink={tokenValue?.apiUrl}
                                      apiToken={tokenValue?.value}
                                      externalCourseStart={externalCourseStart}
                                    />
                                  )}
                                </Grid>
                              )}
                            </div>
                          )
                        ) : courseDetails.status !== "NotStarted" ? (
                          <Grid item xs>
                            {courseDetails.externalObjectId == null ||
                            courseDetails.externalObjectName == null ? (
                              <Button
                                variant="outlined"
                                onClick={goToCourse}
                                className="buy-button"
                                style={{ textTransform: "capitalize" }}
                              >
                                {courseDetails.status == "Inprogress"
                                  ? loadingUrl
                                    ? "Loading..."
                                    : "Continue Learning"
                                  : "course Restart"}
                              </Button>
                            ) : (
                              <Confirmation
                                status={courseDetails.status}
                                loadingUrl={loadingUrl}
                                setLoading={setLoadingUrl}
                                apiLink={tokenValue?.apiUrl}
                                apiToken={tokenValue?.value}
                                externalCourseStart={externalCourseStart}
                              />
                            )}
                          </Grid>
                        ) : (
                          <Grid item xs>
                            {courseDetails.externalObjectId == null ||
                            courseDetails.externalObjectName == null ? (
                              <Button
                                variant="outlined"
                                onClick={goToCourse}
                                className="buy-button"
                                style={{ textTransform: "capitalize" }}
                              >
                                {loadingUrl ? "loading..." : "Enroll course"}
                              </Button>
                            ) : (
                              <Confirmation
                                status={courseDetails.status}
                                loadingUrl={loadingUrl}
                                setLoading={setLoadingUrl}
                                apiLink={tokenValue?.apiUrl}
                                apiToken={tokenValue?.value}
                                externalCourseStart={externalCourseStart}
                              />
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  )}
                </div>
              </Grid>
            )}
          </Grid>
          <div className="container my-5 course-container">
            <Grid container spacing={8}>
              <Grid item xs={12} sm={8}>
                <AppBar position="static" className="course-tab" elevation={0}>
                  {courseDetails.externalObjectId == null ||
                  courseDetails.externalObjectName == null ? (
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="on"
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab label="Overview" />
                      <Tab label=" Content" />
                      <Tab label="Instructor" />
                      {courseDetails.review && <Tab label="Reviews" />}
                    </Tabs>
                  ) : (
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="on"
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab label="Overview" />
                      {courseDetails.InstructorContent && (
                        <Tab label="Instructor" />
                      )}
                      {courseDetails?.review && <Tab label="Reviews" />}
                      {courseDetails?.certificate && (
                        <Tab label="Certification" />
                      )}
                    </Tabs>
                  )}
                </AppBar>

                <TabPanel value={value} index={0}>
                  <div className="my-2">
                    <h2 className="sec-title">
                      Overview of {courseDetails.name}
                    </h2>
                    <div className="innerHtml">

                      {ReactHtmlParser(courseDetails.overviews || "")}
                      {/* <div
                        dangerouslySetInnerHTML={{
                          __html: courseDetails.overviews,
                        }}
                      /> */}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <CourseContent
                    course_content={courseContent}
                    course_overall={CourseOverall}
                    buyCourse={handleEnroll}
                    video_url={(data) => contentVideoClick(data)}
                  />
                </TabPanel>
                <TabPanel
                  value={value}
                  index={
                    courseDetails.externalObjectId == null ||
                    courseDetails.externalObjectName == null
                      ? 2
                      : 1
                  }
                >
                  <InstructorContent courseInstruction={courseDetails} />
                </TabPanel>
                <TabPanel
                  value={value}
                  index={
                    courseDetails.externalObjectId == null ||
                    courseDetails.externalObjectName == null
                      ? 3
                      : 2
                  }
                >
                  <CourseReview
                    courseId={courseId}
                    courseProgress={courseDetails?.progress}
                  />
                </TabPanel>
                <TabPanel
                  value={value}
                  index={
                    courseDetails.externalObjectId == null ||
                    courseDetails.externalObjectName == null
                      ? 4
                      : 3
                  }
                >
                  <CourseCertificate data={courseDetails} />
                </TabPanel>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card className="outcome-card" elevation={0}>
                  <CardContent className="course-outcomes">
                    <h2 className="outcomes-title">Outcomes of this course</h2>
                    <div className="center-align">
                      {ReactHtmlParser(
                        `${courseDetails.outcomes}`
                          .replace(/<br\s*>/gi, "")
                          .replace(/<p\s*><\/p>/gi, "") || ""
                      )}
                    </div>
                  </CardContent>
                </Card>
                <Card className="requirement-card" elevation={0}>
                  <CardContent className="course-requirement">
                    <h2 className="outcomes-title">
                      Requirements for this course
                    </h2>
                    <div className="center-align-requirement">
                    {ReactHtmlParser(
                        `${courseDetails.requirements}`
                          .replace(/<br\s*>/gi, "")
                          .replace(/<p\s*><\/p>/gi, "") || ""
                      )}                    
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {!isAdministrator && (
              <>
                <RelatedCourses relatedCourse={relatedCourse} />
                <div className="my-5">
                  <Button
                    variant="outlined"
                    className="explore-button"
                    onClick={() => history.push("/prepare")}
                  >
                    Explore more
                  </Button>
                </div>
              </>
            )}
          </div>
          {isAdministrator && <Footer inPageWithSidebar />}
        </div>
        <SignInModal
          head={head}
          modal={handleSignInModalOpen}
          setModal={setHandleSignInModalOpen}
          buyPrepare
          preapareId={courseId}
        ></SignInModal>
      </div>
    </>
  );
}
