import { connect, useSelector } from "react-redux";
import { Tabs } from "../../components/tabs/tabs";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { Field, reduxForm, getFormValues } from "redux-form";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";
import { Button, Row } from "reactstrap";
import EditorField from "../Tasks/EditorField";
import NumberInput from "pages/Tasks/NumberInput";
import SelectInput from "pages/Tasks/SelectInput";
import { QPInputField } from "./QPInputField";
import QPSection from "./QPSectionNew";
// import QPSection from "./QPSection";
import { useEffect, useState } from "react";
import { sessionPrivileges } from "privileges";

const _NAME = (value) => {
  if (!value || value.trim().length < 1) {
    return "Please enter a valid Name";
  }
};

const _CUST = (value) => {
  if (!value) {
    return "Please choose a Customer";
  }
};

const _DESC = (value) => {
  if (!value || value.trim().length < 1) {
    return "Please enter a valid Description";
  }
};

const _SEC = (value) => {
  if (!value || !value > 0) {
    return "Please choose sections";
  }
  if (value < 0) {
    return "Please choose valid numbers only";
  }
};

const numberField = ({ input, label, meta: { touched, error }, disabled }) => (
  <NumberInput
    input={{ ...input }}
    len={4}
    label={label}
    error={touched && error ? error : ""}
    disabled={disabled}
  />
);

let initialValues = {};
let oldName = "";

const asyncValidate = async (values) => {
  const Priv = sessionPrivileges().userprivileges || [];
  const customerId = Priv.includes("MANAGE_CUSTOMER")
    ? values?.collegeId
    : sessionStorage.getItem("customer_id");
  const encodedName = window.btoa(values.name);
  if (
    initialValues &&
    (!initialValues.hasOwnProperty("name") ||
      initialValues.name !== values.name) &&
    values?.name !== oldName
  ) {
    await Apihelper.axiosCall(
      `${api.baseURL}${api.QuestionPaperController.checkIfNameExists}${customerId}/${encodedName}`,
      "get"
    ).then((boo) => {
      if (boo) {
        throw {
          name: "That name is already taken. Please choose a different name",
        };
      }
    });
  }
};
const basicInfo = (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.72 14.4632L7.481 13.9552L18.084 3.22517C18.1676 3.13947 18.2142 3.0243 18.2136 2.90456C18.2131 2.78481 18.1654 2.67009 18.081 2.58517L17.446 1.94317C17.405 1.90101 17.3559 1.86744 17.3018 1.84443C17.2477 1.82142 17.1895 1.80942 17.1306 1.80914C17.0718 1.80886 17.0135 1.8203 16.9591 1.8428C16.9048 1.8653 16.8554 1.8984 16.814 1.94017L6.239 12.6422L5.719 14.4622L5.72 14.4632ZM18.703 0.671171L19.338 1.31417C20.214 2.20117 20.222 3.63217 19.354 4.51017L8.428 15.5682L4.664 16.6522C4.4342 16.7165 4.18826 16.687 3.98016 16.5702C3.77206 16.4534 3.6188 16.2588 3.554 16.0292C3.50578 15.864 3.50509 15.6887 3.552 15.5232L4.647 11.6832L15.544 0.654171C15.7512 0.445529 15.9979 0.280232 16.2696 0.167923C16.5414 0.0556136 16.8328 -0.00146091 17.1269 2.84128e-05C17.4209 0.00151774 17.7117 0.061541 17.9823 0.176597C18.2529 0.291654 18.4979 0.45944 18.703 0.670171V0.671171ZM7.184 1.82417C7.68 1.82417 8.082 2.23117 8.082 2.73317C8.08279 2.85184 8.06018 2.9695 8.01547 3.07942C7.97076 3.18935 7.90481 3.28938 7.82141 3.3738C7.73801 3.45823 7.63879 3.52538 7.52942 3.57143C7.42004 3.61748 7.30267 3.64152 7.184 3.64217H3.592C2.6 3.64217 1.796 4.45617 1.796 5.45917V16.3652C1.796 17.3692 2.6 18.1832 3.592 18.1832H14.368C15.36 18.1832 16.165 17.3692 16.165 16.3652V12.7302C16.165 12.2282 16.567 11.8212 17.063 11.8212C17.559 11.8212 17.961 12.2282 17.961 12.7312V16.3652C17.961 18.3732 16.352 20.0012 14.368 20.0012H3.592C1.608 20.0012 0 18.3732 0 16.3652V5.45917C0 3.45217 1.608 1.82417 3.592 1.82417H7.184V1.82417Z"
      fill="#9D9D9D"
    />
  </svg>
);
const section = (
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0V14H20V0H0ZM5 12C5 11.2044 4.68393 10.4413 4.12132 9.87868C3.55871 9.31607 2.79565 9 2 9V5C2.79565 5 3.55871 4.68393 4.12132 4.12132C4.68393 3.55871 5 2.79565 5 2H15C15 2.79565 15.3161 3.55871 15.8787 4.12132C16.4413 4.68393 17.2044 5 18 5V9C17.2044 9 16.4413 9.31607 15.8787 9.87868C15.3161 10.4413 15 11.2044 15 12H5ZM10 4C11.1 4 12 5.3 12 7C12 8.7 11.1 10 10 10C8.9 10 8 8.7 8 7C8 5.3 8.9 4 10 4Z"
      fill="#9D9D9D"
    />
  </svg>
);

const QuestionPaperForm = ({
  activeTab,
  toggleTab,
  handleSubmit,
  submitting,
  questionBanks,
  setQuestionBanks,
  sectionArray,
  assessmentQuestionPaperId,
  createdName,
  viewOnly,
  ...props
}) => {
  const [noOfSections, setNoOfSections] = useState(1);
  const [initialData, setInitialData] = useState({ ...sectionArray });
  const [initialValue, setInitialValue] = useState("");
  const [descValue, setDescValue] = useState("");
  const formValues = useSelector((state) =>
    getFormValues("questionPaperForm")(state)
  );
  const allCustomers = useSelector((state) =>
    state.StudentAndCustomerReducer.Customers
      ? state.StudentAndCustomerReducer.Customers.reduce((acc, val) => {
          acc.push({
            label: val.name,
            value: val.id,
          });
          return acc;
        }, [])
      : []
  );
  const customer = props?.customer;
  const activePage = props?.activePage;
  const itemsPerPage = props?.itemsPerPage;
  const userPrivileges = sessionPrivileges();
  const Priv = userPrivileges.userprivileges || [];

  const validIndicator = (value) => (
    <>
      {value}
      <span style={{ color: "red" }}>*</span>
    </>
  );

  if (createdName) {
    oldName = createdName;
  }

  if (props?.initialValues?.hasOwnProperty("id")) {
    initialValues = props.initialValues;
  }
  const handleInitialValues = (data) => {
    setInitialData({ ...data });
  };
  const updateInitialValues = (key, data) => {
    const temp = { ...initialData };
    temp[key] = { ...data };
    setInitialData({ ...temp });
  };
  useEffect(() => {
    setNoOfSections(Object.keys(sectionArray)?.length);
    setInitialData({ ...sectionArray });
  }, [sectionArray]);

  const handleClick = () => {
    if (initialValue?.length < 1 || descValue?.length < 1){
      window.scrollTo(0, 50);
    }
  };

  return (
    <div className="page-content">
      <Breadcrumbs
        title="Assessments"
        breadcrumbItem={
          assessmentQuestionPaperId
            ? "Update Question Paper"
            : "Create Question Paper"
        }
      />

      <Tabs
        onChange={(newTab) => toggleTab(newTab, handleSubmit)}
        activeTab={activeTab}
        tabs={[
          {
            name: "Basic Info",
            id: "basicInfo",
            svg: basicInfo,
          },
          !viewOnly && { name: "Sections", id: "sections", svg: section },
        ]}
        hideBadge
      >
        {activeTab === "basicInfo" ? (
          <form style={{ padding: "0 20px 0 20px" }} onSubmit={handleSubmit}>
            <div style={{ display: "flex", marginBottom: 20 }}>
              <div style={{ flex: 2, width: "50%" }}>
                <h5>Details</h5>
                <p style={{ color: "#A6A6AA" }}>
                  Enter the necessary Question Paper Details
                </p>
              </div>
              <div
                style={{
                  flex: 3,
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                  width: "50%",
                }}
              >
                <Field
                  name="name"
                  component={QPInputField}
                  label={validIndicator("Name")}
                  validate={[_NAME]}
                  disabled={viewOnly}
                  onChange={(e) => {
                    setInitialValue(e.target.value);
                  }}
                />
                <Field
                  name="description"
                  component={QPInputField}
                  label={validIndicator("Description")}
                  validate={[_DESC]}
                  type="textarea"
                  disabled={viewOnly}
                  onChange={(e) => {
                    setDescValue(e.target.value);
                  }}
                />
                {/* <Row>
                  <Field
                    name="noOfSections"
                    component={numberField}
                    label={validIndicator("Sections")}
                    validate={[_SEC]}
                    disabled={viewOnly}
                  />
                </Row> */}
                <div>
                  <label className="terv-label">Grading</label>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 16 }}
                  >
                    <label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <Field
                        name="gradingProcess"
                        component="input"
                        type="radio"
                        value="auto"
                        disabled={viewOnly}
                      />
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "#495057",
                          display: "block",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Auto Grading
                      </span>
                    </label>
                    <label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <Field
                        name="gradingProcess"
                        component="input"
                        type="radio"
                        value="manual"
                        disabled={viewOnly}
                      />{" "}
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "#495057",
                          display: "block",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Manual Grading
                      </span>
                    </label>
                    <label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <Field
                        name="gradingProcess"
                        component="input"
                        type="radio"
                        value="ai"
                        disabled={viewOnly}
                      />
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "#495057",
                          display: "block",
                          whiteSpace: "nowrap",
                        }}
                      >
                        AI Grading
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ backgroundColor: "#E3F2FF" }} />
            {Priv.includes("MANAGE_CUSTOMER") && (
              <>
                <div style={{ display: "flex", marginBottom: 20 }}>
                  <div style={{ flex: 2 }}>
                    <h5>Customer</h5>
                    <p style={{ color: "#A6A6AA" }}>Choose a Customer</p>
                  </div>
                  <div
                    style={{
                      flex: 3,
                      display: "flex",
                      flexDirection: "column",
                      gap: 20,
                    }}
                  >
                    <Row>
                      <Field
                        name="collegeId"
                        component={SelectInput}
                        label={validIndicator("Customer")}
                        placeholder="Choose Customer"
                        options={allCustomers}
                        validate={[_CUST]}
                        disabled={viewOnly}
                      />
                    </Row>
                  </div>
                </div>
                <hr style={{ backgroundColor: "#E3F2FF" }} />{" "}
              </>
            )}
            <div style={{ display: "flex", marginBottom: 20 }}>
              <div style={{ flex: 2, width: "50%" }}>
                <h5>Information</h5>
                <p style={{ color: "#A6A6AA" }}>Enter information if needed</p>
              </div>
              <div
                style={{
                  flex: 3,
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <Field
                  name="instruction"
                  component={EditorField}
                  label="Instructions"
                />
              </div>
            </div>
            <hr style={{ backgroundColor: "#E3F2FF" }} />
            <div style={{ display: "grid", placeItems: "center" }}>
              {viewOnly && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "15px 0px",
                  }}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 0.25C4.16797 0.25 0.25 4.16797 0.25 9C0.25 13.832 4.16797 17.75 9 17.75C13.832 17.75 17.75 13.832 17.75 9C17.75 4.16797 13.832 0.25 9 0.25ZM9.625 13.2188C9.625 13.3047 9.55469 13.375 9.46875 13.375H8.53125C8.44531 13.375 8.375 13.3047 8.375 13.2188V7.90625C8.375 7.82031 8.44531 7.75 8.53125 7.75H9.46875C9.55469 7.75 9.625 7.82031 9.625 7.90625V13.2188ZM9 6.5C8.75467 6.49499 8.52108 6.39402 8.34935 6.21875C8.17763 6.04348 8.08144 5.80788 8.08144 5.5625C8.08144 5.31712 8.17763 5.08152 8.34935 4.90625C8.52108 4.73098 8.75467 4.63001 9 4.625C9.24533 4.63001 9.47892 4.73098 9.65065 4.90625C9.82237 5.08152 9.91856 5.31712 9.91856 5.5625C9.91856 5.80788 9.82237 6.04348 9.65065 6.21875C9.47892 6.39402 9.24533 6.49499 9 6.5Z"
                      fill="#A29E9E"
                    />
                  </svg>
                  <span
                    style={{
                      color: "#A6A6AA",
                      fontSize: "15px",
                      paddingLeft: "5px",
                    }}
                  >
                    The question paper is assigned for active assessment and
                    hence cannot be updated
                  </span>
                </div>
              )}
              {!viewOnly && (
                <Button
                  color="primary"
                  type="submit"
                  disabled={submitting}
                  style={{ fontSize: 16, fontWeight: 500 }}
                  onClick={handleClick}
                >
                  Go to Section
                </Button>
              )}
            </div>
          </form>
        ) : (
          <QPSection
            totalSections={noOfSections}
            setNoOfSections={setNoOfSections}
            initialValues={initialData}
            handleInitialValues={handleInitialValues}
            updateInitialValues={updateInitialValues}
            questionBanks={questionBanks}
            setQuestionBanks={setQuestionBanks}
            assessmentQuestionPaperId={assessmentQuestionPaperId}
            gradingProcess={formValues?.gradingProcess}
            disabled={viewOnly}
            sessionPrivileges={sessionPrivileges}
          />
        )}
      </Tabs>
      <Link
        to={{
          pathname: "/create-question-paper",
          state: {
            customer: customer,
            activePage: activePage,
            itemsPerPage: itemsPerPage,
          },
        }}
        className="terv-back"
      >
        <i className="mdi mdi-arrow-left mr-1" /> Back to Question papers{" "}
      </Link>
    </div>
  );
};

const questionPaperForm = reduxForm({
  form: "questionPaperForm",
  asyncValidate,
  asyncBlurFields: ["name"],
  enableReinitialize: true,
})(QuestionPaperForm);

export default connect(null, null)(questionPaperForm);