import React, { useState } from "react";
import ModalComponent from "../ModalComponent";

export default function DownloadCariculam() {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <div
        className="parent-div mb-16 mt-20 flex justify-center "
        style={{
          margin: "0px",
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          marginBottom: "4rem",
          display: "flex",
          justifyContent: "center",
          color : "white",
        }}
      >
        <div
          className="relative z-[999] w-full sm:max-w-[950px]"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            position: "relative",
            zIndex: 999,
            width: "100%",
            maxWidth: "950px",
          }}
        >
          <div
            className="w-full bg-v5-green-100 p-6 text-center sm:p-8"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              borderRadius: "30px",
              width: "100%",
              backgroundColor: "#5E35B1",
              textAlign: "center",
              padding: "2rem",
              color: "white",
            }}
          >
            <div
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
              }}
            >
              <h3
                className="mb-5 font-manrope text-xl font-extrabold leading-tight text-black sm:text-[28px]"
                style={{
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  overflowWrap: "break-word",
                  margin: "0px",
                  marginBottom: "1.25rem",
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 800,
                  lineHeight: 1.25,
                  color: "rgb(0 0 0/1)",
                  fontSize: "28px",
                }}
              >
                <div
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                >
                  Build AirBnB-like, Netflix-like, or Amazon-like{" "}
                  <br
                    className="sm:block hidden"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "block",
                    }}
                  />
                  apps to master MERN, Java, and impress{" "}
                  <br
                    className="sm:block hidden"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "block",
                    }}
                  />
                  recruiters to land great jobs.
                </div>
              </h3>
              <div
                className="flex w-full flex-wrap justify-center gap-x-2 gap-y-2 px-4"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  columnGap: "0.5rem",
                  rowGap: "0.5rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
              >
                <div
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                >
                  <button
                    className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium natural sm h-[56px] whitespace-nowrap rounded-[10px] border-2 border-black px-4 py-2 font-manrope text-base font-bold capitalize text-black transition-all hover:border-v5-green-400 hover:bg-v5-green-400 hover:text-v5-green-100 w-[250px] sm:w-auto css-ysfxp3"
                    type="button"
                    tabIndex="0"
                    style={{
                      font: "inherit",
                      backgroundImage: "none",
                      outline: "0px",
                      margin: "0px",
                      textDecoration: "none",
                      transition:
                        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                      border: "1px solid white",
                      display: "inline-flex",
                      WebkitBoxAlign: "center",
                      alignItems: "center",
                      WebkitBoxPack: "center",
                      justifyContent: "center",
                      position: "relative",
                      boxSizing: "border-box",
                      WebkitTapHighlightColor: "transparent",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      userSelect: "none",
                      verticalAlign: "middle",
                      appearance: "none",
                      letterSpacing: "0.02857em",
                      minWidth: "64px",
                      padding: "5px 30px",
                      height: "56px",
                      whiteSpace: "nowrap",
                      borderRadius: "10px",
                      borderWidth: "2px",
                      borderColor: "rgb(0 0 0/1)",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      paddingBottom: "0.5rem",
                      paddingTop: "0.5rem",
                      fontFamily: "Manrope, sans-serif",
                      fontSize: "1rem",
                      lineHeight: "1.5rem",
                      fontWeight: 700,
                      textTransform: "capitalize",
                      color: "rgb(0 0 0/1)",
                      transitionDuration: "0.15s",
                      transitionProperty: "all",
                      transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
                      width: "auto",
                    }}
                    onClick={()=>{
                      setModalOpen(true);
                    }}
                  >
                    Download Curriculum
                    <svg
                      className="svg-inline--fa fa-angle-right ml-2 mt-1 text-xl"
                      aria-hidden="true"
                      focusable="false"
                      role="img"
                      viewBox="0 0 256 512"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        maxWidth: "100%",
                        display: "var(--fa-display, inline-block)",
                        height: "1em",
                        verticalAlign: "-0.125em",
                        overflow: "visible",
                        boxSizing: "content-box",
                        marginBottom: "2px",
                        marginTop: "0.25rem",
                        marginLeft: "0.5rem",
                        fontSize: "1.25rem",
                        lineHeight: "1.75rem",
                      }}
                    >
                      <path
                        d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"
                        fill="currentColor"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                        }}
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  margin: 0px;
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  tab-size: 4;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}

body {
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizelegibility;
  overflow-y: auto;
  min-height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  line-height: inherit;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}
.parent-div * {
        color: white !important;
}
`,
        }}
      />
      <ModalComponent 
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </>
  );
}
