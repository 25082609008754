import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { TervButtonFilled } from "components/TervButtons";

const CourseStatusUpdateModal = ({ message, isOpen, onClose, onInvoke }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Course approval</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <TervButtonFilled text="Cancel" onClick={onClose} secondary />
        <TervButtonFilled text="Send" onClick={onInvoke} />
      </DialogActions>
    </Dialog>
  );
};

export default CourseStatusUpdateModal;
