export default function AssuredReferrals() {
  return (
    <>
      <div
        className="flex gap-3"
        style={{
          margin: "0px",
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          display: "flex",
          gap: "0.75rem",
          marginBottom: "calc(1.5rem*0)",
          marginTop: "calc(3rem*(1 - 0))",
          color: "rgb(185, 207, 202)",
        }}
      >
        <div
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
          }}
        >
          <div
            className="h-full w-full rounded-[20px] p-4 text-v5-neutral-600 md:p-8 md:py-8 bg-v5-green-100"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              height: "100%",
              width: "100%",
              borderRadius: "20px",
              backgroundColor:"#5E35B1",
              color: "#FFFFFF",
              padding: "2rem",
              paddingBottom: "2rem",
              paddingTop: "2rem",
            }}
          >
            <div
              className="flex flex-col flex-nowrap items-stretch justify-between gap-4"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "stretch",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <div
                className="h-full text-center"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  height: "100%",
                  textAlign: "center",
                }}
              >
                <h5
                  className="mb-2 font-manrope leading-tight"
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflowWrap: "break-word",
                    margin: "0px",
                    fontWeight: 700,
                    fontSize: "1.25rem",
                    marginBottom: "0.5rem",
                    fontFamily: "Manrope, sans-serif",
                    lineHeight: 1.25,
                    color:"white",
                  }}
                >
                  {/* Assured Referrals* */}
                </h5>{" "}
              </div>
              <div
                className="min-h-[2px] w-full border-r-2 bg-v5-green-200"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  minHeight: "2px",
                  width: "100%",
                  borderRightWidth: "2px",
                  backgroundColor: "rgb(2 191 145/1)",
                }}
              />
              <div
                className="flex h-full flex-1 gap-2"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  display: "flex",
                  height: "100%",
                  flex: "1 1 0%",
                  gap: "0.5rem",
                }}
              >
                <div
                  className="mb-3 h-full px-4 text-center"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    marginBottom: "0.75rem",
                    height: "100%",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    textAlign: "center",
                  }}
                >
                  <h6
                    className="font-rubik text-xs font-semibold text-v5-green-400"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      overflowWrap: "break-word",
                      margin: "0px",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "0.75rem",
                      lineHeight: "1rem",
                      fontWeight: 600,
                      color: "white",
                    }}
                  >
                    DREAM JOBS
                  </h6>
                  <h6
                    className="font-manrope font-extrabold text-v5-neutral-600 md:text-xl"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      overflowWrap: "break-word",
                      margin: "0px",
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: 800,
                      color: "#FFFFFF",
                      fontSize: "1.25rem",
                      lineHeight: "1.75rem",
                    }}
                  >
                    6 - 10 LPA
                  </h6>
                </div>
                <div
                  className="h-full min-h-[40px] border-r-2 border-v5-green-200"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    height: "100%",
                    minHeight: "40px",
                    borderRightWidth: "2px",
                    borderColor: "rgb(2 191 145/1)",
                  }}
                />
                <div
                  className="h-full px-4 text-center"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    height: "100%",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    textAlign: "center",
                  }}
                >
                  <h6
                    className="font-rubik text-xs font-semibold text-v5-green-400"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      overflowWrap: "break-word",
                      margin: "0px",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "0.75rem",
                      lineHeight: "1rem",
                      fontWeight: 600,
                      color: "white",
                    }}
                  >
                    SUPER DREAM JOBS
                  </h6>
                  <h6
                    className="font-manrope font-extrabold text-v5-neutral-600 md:text-xl"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      overflowWrap: "break-word",
                      margin: "0px",
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: 800,
                      color: "#FFFFFF",
                      fontSize: "1.25rem",
                      lineHeight: "1.75rem",
                    }}
                  >
                    10 - 25+ LPA
                  </h6>
                </div>
              </div>
            </div>
            <p
              className="text-center text-xs text-v5-green-400 md:-mb-3 md:text-sm"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                textAlign: "center",
                color: "white",
                marginBottom: "-0.75rem",
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
              }}
            >
              *For more info, check{" "}
            </p>
          </div>
        </div>
        <div
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
          }}
        >
          <div
            className="h-full w-full rounded-[20px] p-4 text-v5-neutral-300 sm:w-max md:p-8 bg-v5-green-100"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              height: "100%",
              borderRadius: "20px",
              backgroundColor:"#5E35B1",
              color: "rgb(185 207 202/1)",
              width: "max-content",
              padding: "2rem",
            }}
          >
            <div
              className="flex h-full flex-col flex-nowrap items-center justify-between gap-4"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                height: "100%",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <div
                className="text-center"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  textAlign: "center",
                }}
              >
                <h6
                  className="font-rubik text-xs font-semibold text-v5-green-400"
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflowWrap: "break-word",
                    margin: "0px",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "0.75rem",
                    lineHeight: "1rem",
                    fontWeight: 600,
                    color: "white",
                  }}
                >
                  FULLY ONLINE
                </h6>
                <h6
                  className="font-manrope font-extrabold text-v5-neutral-600 md:text-xl"
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflowWrap: "break-word",
                    margin: "0px",
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: 800,
                    color: "#FFFFFF",
                    fontSize: "1.25rem",
                    lineHeight: "1.75rem",
                  }}
                >
                  9 months
                </h6>
              </div>
              <div
                className="min-h-[2px] w-full border-r-2 sm:block bg-v5-green-200"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  minHeight: "2px",
                  width: "100%",
                  borderRightWidth: "2px",
                  backgroundColor: "rgb(2 191 145/1)",
                  display: "block",
                }}
              />
              <div
                className="text-center"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  textAlign: "center",
                }}
              >
                <h6
                  className="font-rubik text-xs font-semibold text-v5-green-400"
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflowWrap: "break-word",
                    margin: "0px",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "0.75rem",
                    lineHeight: "1rem",
                    fontWeight: 600,
                    color: "white",
                  }}
                >
                  TRIAL SESSION
                </h6>
                <h6
                  className="font-manrope font-extrabold text-v5-neutral-600 md:text-xl"
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflowWrap: "break-word",
                    margin: "0px",
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: 800,
                    color: "#FFFFFF",
                    fontSize: "1.25rem",
                    lineHeight: "1.75rem",
                  }}
                >
                  Free
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  margin: 0px;
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  tab-size: 4;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}

body {
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizelegibility;
  overflow-y: auto;
  min-height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  line-height: inherit;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}
`,
        }}
      />
    </>
  );
}
