import React from "react";

export default function LongWayComp() {
  return (
    <>
      <div
        className="coach-heading-wrapper"
        style={{
          boxSizing: "border-box",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          maxWidth: "1280px",
          marginBottom: "140px",
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
        }}
      >
        <h2
          className="heading-48px coach-heading"
          style={{
            boxSizing: "border-box",
            marginTop: "0px",
            color: "#fff",
            letterSpacing: "-2px",
            fontFamily: "Inter, sans-serif",
            fontSize: "48px",
            fontWeight: 600,
            lineHeight: "117%",
            textAlign: "center",
            maxWidth: "960px",
            marginBottom: "24px",
          }}
        >
          There’s a{" "}
          <span
            className="purple-pink-grostesk-heading-span"
            style={{
              boxSizing: "border-box",
              WebkitTextFillColor: "transparent",
              backgroundImage:
                "linear-gradient(rgb(152, 109, 234), rgb(234, 105, 189) 80%)",
              backgroundClip: "text",
              fontFamily: "Spacegrotesk, sans-serif",
              fontWeight: 700,
            }}
          >
            long and successful career ahead{" "}
          </span>
          of you. Only if you’re ready for it.
        </h2>
        <div
          className="paragraph-18px coach-paragraph"
          style={{
            boxSizing: "border-box",
            color: "#fff",
            fontFamily: "Inter, sans-serif",
            fontSize: "18px",
            lineHeight: "156%",
            textAlign: "center",
            width: "100%",
            maxWidth: "300px",
            marginBottom: "50px",
            fontWeight: 600,
          }}
        >
          Does this sound like you?
        </div>
        <div
          className="w-layout-grid coach-points-grid"
          style={{
            boxSizing: "border-box",
            gridAutoColumns: "1fr",
            display: "grid",
            gap: "20px",
            gridTemplateRows: "auto",
            gridTemplateColumns: "1fr 1fr 1fr",
            width: "100%",
          }}
        >
          <div
            id="w-node-d0f29acb-f9ba-8cb9-0c87-bb54c20ba420-03403755"
            className="coach-points-wrapper"
            style={{
              boxSizing: "border-box",
              border: "1px solid #5E35B1",
              borderRadius: "12px",
              padding: "17px 24px",
              columnGap: "16px",
              backgroundColor: "#5E35B1",
              alignItems: "center",
              width: "100%",
              display: "flex",
              gridArea: "span 1 / span 1 / span 1 / span 1",
            }}
          >
            <img
              className="coach-point-icon"
              src="https://cdn.prod.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa45a_lightbulb-on.svg"
              style={{
                boxSizing: "border-box",
                border: "0px",
                verticalAlign: "middle",
                display: "inline-block",
                width: "100%",
                maxWidth: "32px",
              }}
            />
            <p
              className="paragraph-18px coach-point-paragraph"
              style={{
                boxSizing: "border-box",
                marginTop: "0px",
                color: "#fff",
                marginBottom: "0px",
                fontFamily: "Inter, sans-serif",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "156%",
              }}
            >
              You want to update your skills to stand out to recruiters
              globally.
            </p>
          </div>
          <div
            id="w-node-d0f29acb-f9ba-8cb9-0c87-bb54c20ba424-03403755"
            className="coach-points-wrapper"
            style={{
              boxSizing: "border-box",
              border: "1px solid #5E35B1",
              borderRadius: "12px",
              padding: "17px 24px",
              columnGap: "16px",
              backgroundColor: "#5E35B1",
              alignItems: "center",
              width: "100%",
              display: "flex",
              gridArea: "span 1 / span 1 / span 1 / span 1",
            }}
          >
            <img
              className="coach-point-icon"
              src="https://cdn.prod.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa45d_toggle-on.svg"
              style={{
                boxSizing: "border-box",
                border: "0px",
                verticalAlign: "middle",
                display: "inline-block",
                width: "100%",
                maxWidth: "32px",
              }}
            />
            <p
              className="paragraph-18px coach-point-paragraph"
              style={{
                boxSizing: "border-box",
                marginTop: "0px",
                color: "#fff",
                marginBottom: "0px",
                fontFamily: "Inter, sans-serif",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "156%",
              }}
            >
              You’re looking to make the switch from service to product.
            </p>
          </div>
          <div
            id="w-node-d0f29acb-f9ba-8cb9-0c87-bb54c20ba428-03403755"
            className="coach-points-wrapper"
            style={{
              boxSizing: "border-box",
              border: "1px solid #5E35B1",
              borderRadius: "12px",
              padding: "17px 24px",
              columnGap: "16px",
              backgroundColor: "#5E35B1",
              alignItems: "center",
              width: "100%",
              display: "flex",
              gridArea: "span 1 / span 1 / span 1 / span 1",
            }}
          >
            <img
              className="coach-point-icon"
              src="https://cdn.prod.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa45c_briefcase.svg"
              style={{
                boxSizing: "border-box",
                border: "0px",
                verticalAlign: "middle",
                display: "inline-block",
                width: "100%",
                maxWidth: "32px",
                
              }}
            />
            <p
              className="paragraph-18px coach-point-paragraph"
              style={{
                boxSizing: "border-box",
                marginTop: "0px",
                color: "#fff",
                marginBottom: "0px",
                fontFamily: "Inter, sans-serif",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "156%",
              }}
            >
              You want exciting challenges, projects and a fulfilling career.
            </p>
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font-family: sans-serif;
  height: 100%;
}

body {
  box-sizing: border-box;
  margin: 0px;
  background-color: rgb(255, 255, 255);
  min-height: 100%;
  color: #000;
  letter-spacing: normal;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 156%;
}
`,
        }}
      />
    </>
  );
}
