import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { Button, Input } from "reactstrap";
import Select from "react-select";
import styles from "../CustomerManagement/BulkUserUpload.module.scss";

const customStyles = {
  menu: (styles) => ({ ...styles, zIndex: 9999 }),
};

export default function SolutionsModal(props) {
  return (
    <Dialog open={props.isOpen} onClose={props.close} fullWidth maxWidth="sm">
      <DialogTitle classes={{ root: styles.header }}>
        Add Solution
        <CloseOutlined
          classes={{ root: styles.closeButton }}
          onClick={props.close}
        />
      </DialogTitle>
      <DialogContent
        style={{
          paddingTop: 20,
          display: "flex",
          flexDirection: "column",
          gap: 10,
          height: "50vh",
        }}
      >
        {!props.editValue ? (
          <Select
            options={props.availableLanguages}
            styles={customStyles}
            onChange={props.setLanguage}
          />
        ) : (
          <h5>{props.editorState.lang.label}</h5>
        )}
        <Input
          type="textarea"
          value={props.editorState.val}
          onChange={(e) => props.setEditorState(e.target.value)}
          style={{
            height: "40vh",
          }}
        />
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="primary"
          style={{ fontSize: 16 }}
          onClick={props.setSolutions}
          disabled={props.disabled}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
