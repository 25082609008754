import { useState, useEffect } from "react";
import Apihelper from "../../../api/apiHelper";
import api from "../../../api/baseConfig";
import { connect, useSelector } from "react-redux";
import { GetAllUsers } from "pages/CustomerManagement/utils/GetUsers";
import HandleStudentSelection from "./HandleStudentSelection";
import { validateStudentData } from "./ValidateStudentData";
import { sessionPrivileges } from "privileges";

const customStyles = {
  menu: (styles) => ({ ...styles, zIndex: 9999 }),
};

const returnParsedData = (formData, STUDENTS) => {
  let studentIds = [];
  if (formData.filterType === "all") {
    studentIds = STUDENTS.reduce((acc, val) => {
      acc.push(val.value);
      return acc;
    }, []);
  } else if (formData.filterType === "except") {
    studentIds =
      formData.selectedStudents && formData.selectedStudents.length > 0
        ? STUDENTS.reduce((acc, val) => {
            if (
              !formData.selectedStudents.find((stu) => stu.value === val.value)
            ) {
              acc.push(val.value);
            }
            return acc;
          }, [])
        : [];
  } else {
    studentIds = formData.selectedStudents
      ? formData.selectedStudents.reduce((acc, val) => {
          acc.push(val.value);
          return acc;
        }, [])
      : [];
  }

  return studentIds;
};

const AdvancedStudentsSelections = ({
  customerId = "",
  result,
  existingData,
  setAssignStudents,
}) => {
  const PRIV = sessionPrivileges().userprivileges || [];
  const [students, setStudents] = useState([]);
  const [adminStudents, setAdminStudents] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [initialValueSet, setInitialValueSet] = useState(false);
  const [studentsCount, setStudentCount] = useState(0);
  const [allFilterTypes, setAllFilterTypes] = useState([
    { label: "All", value: "all" },
    { label: "Only", value: "only" },
    { label: "Except", value: "except" },
    {
      label: "Custom Batch",
      value: "custom_batch",
    },
  ]);
  const [formData, setFormData] = useState({
    filterType: "",
    batchName: "",
    department: "",
    regNoFrom: "",
    regNoTo: "",
    selectedStudents: [],
  });
  const [studentErrors, setStudentErrors] = useState({});

  const adminStudentsFromRedux = useSelector((state) =>
    state.StudentAndCustomerReducer.UserStudents
      ? state.StudentAndCustomerReducer.UserStudents.students
      : []
  );

  const getRightStudents = () =>
    PRIV?.includes("MANAGE_CUSTOMER") ? students : adminStudents;

  const regNoLength = () =>
    getRightStudents() &&
    getRightStudents()[0] &&
    getRightStudents()[0]["registrationNo"]
      ? getRightStudents()[0]["registrationNo"].length
      : "";

  const getFilteredStudents = (students, from, to) =>
    students.filter(
      (stu) => +stu.registrationNo >= from && +stu.registrationNo <= to
    );
    
  useEffect(() => {
    if (
      formData.regNoFrom &&
      formData.regNoTo &&
      formData.regNoFrom.length === regNoLength() &&
      formData.regNoTo.length === regNoLength() &&
      formData.regNoFrom !== formData.regNoTo &&
      typeof +formData.regNoFrom === "number" &&
      typeof +formData.regNoTo === "number"
    ) {
      const filtered = getFilteredStudents(
        getRightStudents(),
        formData.regNoFrom,
        formData.regNoTo
      );
      setFilteredStudents(filtered);
    }
  }, [formData.regNoFrom, formData.regNoTo]);

  useEffect(() => {
    if (!PRIV.includes("MANAGE_CUSTOMER")) {
      setAdminStudents(
        adminStudentsFromRedux.reduce((acc, student) => {
          if (student?.firstName && student?.emailId) {
            acc.push({
              label: student.registrationNo
                ? `${student.firstName} ${student?.lastName || ""} , ${
                    student.registrationNo
                  }`
                : `${student.firstName} ${student?.lastName || ""}`,
              value: student.emailId,
              gender: student?.gender || "",
              registrationNo: student?.registrationNo || "",
              department: student?.department || "",
            });
          }
          return acc;
        }, [])
      );
    }
  }, [adminStudentsFromRedux]);

  useEffect(() => {
    if (!PRIV?.includes("MANAGE_CUSTOMER")) {
      getCustomerBatches(sessionStorage.getItem("customer_id"));
    }
  }, []);

  useEffect(() => {
    if (
      !PRIV?.includes("MANAGE_CUSTOMER") &&
      formData.filterType &&
      formData.filterType !== "all" &&
      formData.filterType !== "only" &&
      formData.filterType !== "except"
    ) {
      getDepartments(sessionStorage.getItem("customer_id"));
    }

    if (
      formData.filterType &&
      formData.filterType !== "custom_batch" &&
      formData.filterType !== "all" &&
      formData.filterType !== "only" &&
      formData.filterType !== "except"
    ) {
      getStudentsByBatch(formData.filterType);
    }
  }, [formData.filterType]);

  useEffect(() => {
    if (!PRIV?.includes("MANAGE_CUSTOMER")) {
      setInitialData(adminStudentsFromRedux);
    }
  }, [existingData.userIdList]);

  useEffect(() => {
    if (PRIV?.includes("MANAGE_CUSTOMER") && customerId) {
      getCustomerStudents(customerId);
      getDepartments(customerId);
      getCustomerBatches(customerId);
      //handleFormData("filterType", "");
    }
  }, [customerId]);

  useEffect(() => {
    if (formData?.department) {
      filterByDepartment(formData.department);
    }
  }, [formData.department]);

  const filterByDepartment = (department) => {
    setFilteredStudents(
      getRightStudents().reduce((acc, val) => {
        if (val?.department === department) {
          acc.push(val);
        }
        return acc;
      }, [])
    );
  };

  const checkIfBatchNameExists = async (batchName) => {
    const CUSTOMER = PRIV?.includes("MANAGE_CUSTOMER")
      ? customerId
      : sessionStorage.getItem("customer_id");
    if (CUSTOMER) {
      try {
        const boo = await Apihelper.axiosCall(
          `${api.baseURL}${api.customerManagement.checkIfBatchExists}${CUSTOMER}/${batchName}`
        );
        if (boo === true) {
          setStudentErrors({
            ...studentErrors,
            batchName:
              "There is already a batch under this name. Please choose a different name",
          });
        } else if (errors.batchName) {
          setStudentErrors({
            ...studentErrors,
            batchName: "",
          });
        }
      } catch (err) {}
    }
  };

  const getStudentsByBatch = async (batch) => {
    const encodedBatchName = window.btoa(batch);
    try {
      const data = await Apihelper.axiosCall(
        `${api.baseURL}${api.customerManagement.getStudentsByBatch}${encodedBatchName}/${
          PRIV?.includes("MANAGE_CUSTOMER")
            ? customerId
            : sessionStorage.getItem("customer_id")
        }`
      );
      if (data && data[0].batchUserList && data[0].batchUserList.length > 0) {
        handleFormData([
          {
            key: "selectedStudents",
            value: data[0].batchUserList.reduce((acc, item) => {
              if (item?.firstName && item?.emailId) {
                acc.push({
                  label: `${item?.firstName} ${item?.lastName}`,
                  value: item?.emailId,
                });
              }
              return acc;
            }, []),
          },
          { key: "department", value: data[0].department },
          { key: "batchName", value: batch },
        ]);
      }
    } catch (err) {}
  };

  const setInitialData = (users) => {
    let existing = [];
    users?.forEach?.((user) => {
      if (
        !initialValueSet &&
        existingData.userIdList &&
        existingData.userIdList.length > 0
      ) {
        if (
          (existingData.filterType === "only" ||
            existingData.filterType === "all" ||
            existingData.filterType === "custom_batch") &&
          existingData.userIdList.includes(user.emailId)
        ) {
          existing.push({
            label: user.firstName,
            value: user.emailId,
            gender: user.gender || "",
          });
        } else if (
          existingData.filterType === "except" &&
          !existingData.userIdList.includes(user.emailId)
        ) {
          existing.push({
            label: user.firstName,
            value: user.emailId,
            gender: user.gender || "",
          });
        }
      }
    });
    if (!initialValueSet && existingData.filterType) {
      setFormData({
        ...existingData,
        selectedStudents: existing,
      });
      setInitialValueSet(true);
    }
  };
  const getCustomerStudents = async (id) => {
    const allUsers = await GetAllUsers(id);
    setInitialData(allUsers);
    let temp = [];
    try {
      allUsers.forEach((user) => {
        if (
          user?.roles &&
          user?.roles?.length &&
          user?.roles?.[0]?.name === "LEARNER"
        ) {
          if (user?.firstName && user?.emailId) {
            temp.push({
              label: user?.registrationNo
                ? `${user?.firstName} ${user?.lastName || ""} , ${
                    user?.registrationNo
                  }`
                : `${user?.firstName} ${user?.lastName || ""}`,
              value: user?.emailId,
              gender: user?.gender || "",
              registrationNo: user?.registrationNo || "",
              department: user?.department || "",
            });
          }
        }
      });
      if (formData.selectedStudents && formData.selectedStudents.length > 0) {
        handleFormData("selectedStudents", []);
        handleFormData("filterType", "");
      }
      setStudents(temp);
    } catch {}
  };

  const getCustomerBatches = async (customer) => {
    try {
      const data = await Apihelper.axiosCall(
        `${api.baseURL}${api.customerManagement.getBatches}${customer}`
      );
      const temp = allFilterTypes.filter(
        (item) =>
          item.value === "all" ||
          item.value === "only" ||
          item.value === "except" ||
          item.value === "custom_batch"
      );
      if (data && data.length > 0) {
        data.forEach((datum) => {
          if (datum?.batchName) {
            temp.push({
              label: datum.batchName,
              value: datum.batchName,
            });
          }
        });
      }
      setAllFilterTypes(temp);
    } catch (err) {}
  };

  const getDepartments = async (id) => {
    try {
      const data = await Apihelper.axiosCall(
        `${api.baseURL}${api.userManagement.getDepartments}${id}`
      );
      if (data) {
        setDepartments(
          data.reduce((acc, dep) => {
            if (dep)
              acc.push({
                label: dep,
                value: dep,
              });
            return acc;
          }, [])
        );
      }
    } catch (err) {}
  };

  const returnData = (data, canSubmit) => {
    const userIdList = returnParsedData(data, getRightStudents());

    setStudentCount(userIdList.length);

    result(
      {
        userIdList,
        filterType: data.filterType,
        batchName: data.batchName,
        department: data.department,
        regNoFrom: data.regNoFrom,
        regNoTo: data.regNoTo,
      },
      canSubmit
    );
  };

  const handleFormData = (key, value) => {
    let updatedData = { ...formData };

    if (typeof key === "string") {
      updatedData[key] = value;
    } else {
      key.forEach((item) => {
        updatedData[item.key] = item.value;
      });
    }

    const { errors, canSubmit } = validateStudentData(updatedData);
    returnData(updatedData, canSubmit);

    setStudentErrors(errors);
    setFormData(updatedData);
  };

  const resetFilter = () => {
    if (formData.regNoFrom || formData.regNoTo) {
      setFormData({ ...formData, regNoFrom: "", regNoTo: "" });
      setFilteredStudents([]);
    }
  };

  return (
    <HandleStudentSelection
      students={
        filteredStudents.length > 0 ? filteredStudents : getRightStudents()
      }
      formData={formData}
      handleFormData={handleFormData}
      departments={departments}
      style={customStyles}
      setAssignStudents={setAssignStudents}
      resetFilter={resetFilter}
      errors={studentErrors}
      studentsCount={studentsCount}
      allFilterTypes={allFilterTypes}
      checkIfBatchNameExists={checkIfBatchNameExists}
    />
  );
};

export default connect(null, null)(AdvancedStudentsSelections);