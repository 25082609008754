export const parseRawHostData = (
  listOfStudents: { [key: string]: any }[]
) => {
  if (listOfStudents && listOfStudents.length > 0) {
    return listOfStudents.reduce((all, stu) => {
      if (
        stu?.firstName &&
        stu?.emailId &&
        (!stu.hasOwnProperty("roles") ||
          (stu?.roles &&
            stu?.roles?.length > 0 &&
            stu?.roles?.[0]?.name !== "LEARNER"))
      )
        all.push({
          label: stu.emailId
            ? `${stu.firstName} ${stu.lastName}`
            : `${stu.firstName} ${stu.lastName}`,
          value: stu.emailId,
        });
      return all;
    }, []);
  } else return [];
};
