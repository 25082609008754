// import { Calculator } from 'react-mac-calculator';
import styles from './calculator.module.scss';

export default function CalculatorComponent() {
	return (
		<div className={styles.calculator}>
			<div className={styles.content}>
				{/* <Calculator /> */}
			</div>
		</div>
	);
}
