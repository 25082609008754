import { Droppable } from "react-beautiful-dnd";
import styles from "./SectionCreation.module.scss";
import { SectionItem } from "./SectionItem";

export const SectionList = ({
  title,
  sectionValues,
  setSectionValues,
  editableSections,
  toggleEditable,
  handleField,
  removeField,
  removeLesson,
  handleModal,
  courseId,
  USER_ID,
  itemIndex,
  setItemIndex,
  setCurrentSection,
  setCurrentLesson,
  expanded,
  handleExpanded,
  currentLesson,
  setFormValue2,
  formValue2,
  selSec,
  setSelSec,
  selLes,
  setSelLes,
  updateSelectedLesson,
}) =>{
  return (
  <Droppable droppableId="droppableSections" type="SECTIONS">
    {(provided) => (
      <div
        className={styles.sec__sectionGroup}
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {sectionValues.map((section, index) => (
          <SectionItem
            editableSections={editableSections}
            toggleEditable={toggleEditable}
            itemIndex={index}
            index={index}
            field={section}
            courseId={courseId}
            USER_ID={USER_ID}
            handleField={handleField}
            removeField={removeField}
            removeLesson={removeLesson}
            handleModal={handleModal}
            setCurrentSection={setCurrentSection}
            sectionValues={sectionValues}
            setSectionValues={setSectionValues}
            setCurrentLesson={setCurrentLesson}
            expanded={expanded}
            handleExpanded={handleExpanded}
            currentLesson={currentLesson}
            setFormValue2={setFormValue2}
            formValue2={formValue2}
            selSec={selSec}
            setSelSec={setSelSec}
            selLes={selLes}
            setSelLes={setSelLes}
            updateSelectedLesson={updateSelectedLesson}
          />
        ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);}
