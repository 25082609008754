import { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Popover,
  Badge,
  Grid,
  Paper,
  CircularProgress,
  Checkbox,
  Chip,
  Tooltip,
  Button,
  IconButton,
  Switch,
  TextField,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import Select from "react-select";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import styles from "../../../components/DeleteModule.module.scss";

/** Data & Styles */
import useStyles from "./SelectQuestionsQPModalNewStyles";

import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";

import { getQuestionsByIds } from "pages/utils/CommonAPIs";
const paraStyle = {
  fontSize: 15,
  fontWeight: 500,
  color: "#5B5A5F",
  marginTop: 12,
  textAlign: "center",
};
const SelectQuestionsQPModalNew = ({
  open,
  handleOpenClose,
  listQB,
  selectedQB,
  changeSelectedQB,
  selectedQuestionId,
  setSelectedQuestionId,
  selectedQuestionList,
  setSelectedQuestionList,
  initialValues,
  questionExist,
  sectionIndex,
  defaultQuestionType,
}) => {
  const css = useStyles();

  const [tabValue, setTabValue] = useState(0);
  const [switchTocken, setSwitchTocken] = useState("");
  const [localSelectedQB, setLocalSelectedQB] = useState({
    label: "",
    value: "",
  });
  const [categoryList, setCategoryList] = useState({ idList: [], objList: [] });
  //const [topicList, setTopicList] = useState({ idList: [], objList: [] });
  const [complexityList, setComplexityList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [tempCheckedQuesId, setTempCheckedQuesId] = useState([]);
  const [tempSelectedCheckedQuesId, setTempSelectedCheckedQuesId] = useState(
    []
  );
  const [tempSelectedQuesList, setTempSelectedQuesList] = useState([]);

  const [filterMenu, setFilterMenu] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [category, setCategory] = useState("");
  //const [topic, setTopic] = useState("");
  const [complexity, setComplexity] = useState("");
  const [questionType, setQuestionType] = useState(
    defaultQuestionType ? defaultQuestionType : ""
  );
  const [randomCount, setRandomCount] = useState(0);
  const [randomCountError, setRandomCountError] = useState(false);
  const [randomCountErrorTxt, setRandomCountErrorTxt] = useState("");
  const [tostMsg, setTostMsg] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchString, setSearchString] = useState("");
  const [queryLimit, setQueryLimit] = useState(15);
  const [lazyloadIndex, setLazyloadIndex] = useState(0);
  const [lazyloading, setLazyloading] = useState(null);
  const [totalQuestion, setTotalQuestion] = useState(0);
  const [selectAll, setSelectAll] = useState("");
  const [isSelectAllChanged, setIsSelectAllChanged] = useState("");
  const [questionQB, setQuestionQB] = useState([]);
  const [questionQBId, setQuestionQBId] = useState([]);
  const [statusMsg, setStatusMsg] = useState("");
  const [loading, setloading] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [otherSelectedQues, setOtherSelectedQues] = useState([]);

  const updateQuestionsByIds = async (ids) => {
    try {
      const quesList = await getQuestionsByIds(ids);
      setSelectedQuestionList(quesList);
      setloading(false);
    } catch (e) {
      setloading(false);
    }
  };

  const scrollRef = useRef(null);

  const openFilterMenu = Boolean(filterAnchorEl);
  const customSelectMenuStyles = {
    option: (styles, state) => ({
      // fixed
      ...styles,
      backgroundColor: state.isSelected ? "#f7fbff" : "",
      fontWeight: state.isSelected ? 600 : 400,
    }),
  };
  const handleCloseDialog = () => {
    handleOpenClose();
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleFilterMenu = (e, t) => {
    setFilterAnchorEl(e.currentTarget);
    setFilterMenu(t);
  };
  const handleFilterMenuClose = () => {
    setFilterAnchorEl(null);
    setFilterMenu("");
  };
  const handleSearch = (txt) => {
    setSearchText(txt);
    if (txt.length > 3) {
      setLazyloadIndex(0);
      setSearchString(txt);
    } else {
      if (searchString !== "") {
        setLazyloadIndex(0);
        setSearchString("null");
      }
    }
  };

  const getRandom = (arr, n) => {
    let result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n < len) {
      while (n--) {
        let x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
      }
      return result;
    }
  };

  const goRandomize = () => {
    setRandomCountErrorTxt("");
    if (questionQBId.length > 1) {
      const questionList = [...questionQBId];
      if (tempCheckedQuesId.length > 0) {
        tempCheckedQuesId.map((id) => {
          const idIndex = questionList.indexOf(id);
          if (idIndex > -1) questionList.splice(idIndex, 1);
        });
      }
      if (otherSelectedQues.length > 0) {
        otherSelectedQues.map((q) => {
          if (q.id) {
            const idIndex = questionList.indexOf(q.id);
            if (idIndex > -1) questionList.splice(idIndex, 1);
          }
        });
      }
      if (randomCount < questionList.length) {
        let selectedRandomQues = getRandom(questionList, randomCount);
        if (selectedRandomQues.length > 0) {
          let quesIds = [...tempCheckedQuesId],
            quesList = [...tempSelectedQuesList];
          selectedRandomQues.map((qid) => {
            let i = quesIds.indexOf(qid);
            if (i === -1) {
              quesIds.push(qid);
              quesList.push(questionQB[questionQBId.indexOf(qid)]);
            }
          });
          setTempCheckedQuesId(quesIds);
          setTempSelectedQuesList(quesList);
          setTostMsg(`${randomCount} random question${randomCount > 1 ? 's' : ''} were selected`);
        } else {
          setRandomCountErrorTxt("Somting went wrong, Please try");
        }
      } else {
        setRandomCountErrorTxt(
          questionList.length > 1
            ? `Enter a number below ${questionList.length}`
            : `All question where selected`
        );
      }
    } else {
      setRandomCountError(true);
    }
  };

  const mapObjectListWithArrayList = (objList, idName) => {
    let array = [];
    if (objList && objList.length > 0)
      objList.map((list) => {
        array.push(list[idName]);
      });
    return { idList: array, objList: objList };
  };

  const getFilterAttributes = async () => {
    setloading(true);
    let userId = sessionStorage.getItem("user_id");
    await Apihelper.getQuestionBankFilter(
      `${api.baseURL}${api.questionBankController.getQBFilterList}${userId}?qbId=${localSelectedQB.value}&types=`,
      "get"
    ).then((res) => {
      if (res && res.typeList) {
        setStatusMsg("Loading questions....");
        setSwitchTocken("getQuestionFromQB");
        if (res.categoryList && res.categoryList.length > 0)
          setCategoryList(
            mapObjectListWithArrayList(res.categoryList, "categoryId")
          );
        setComplexityList(res.complexityList);
        setTypeList(res.typeList);
        setloading(false);
      } else {
        // setErrorMsg(res);
        // setError(true);
        open && setTimeout(() => setSwitchTocken("getFilterAttributes"), 3000);
      }
    });
  };

  const getQuestionFromQB = async (mode) => {
    setloading(true);
    let userId = sessionStorage.getItem("user_id");
    const payLoadParams = {
      search: searchString,
      qpId: localSelectedQB.value,
      limit: queryLimit,
      categoryIds: category ? category : [],
      pageIndex: lazyloadIndex,
      complexity: complexity ? complexity : [],
      topicIds: [],
      types: questionType ? [questionType] : [],
    };
    await Apihelper.getQuestionBankQues(
      `${api.baseURL}${api.questionBankController.getQBQuestions}${userId}`,
      "POST",
      payLoadParams
    ).then((res) => {
      if (res) {
        let resQuesList = res.questionList;
        setTotalQuestion(res.totalCount);
        setStatusMsg(
          res.totalCount < 1
            ? "Select another quesstion bank"
            : `Showing ${(lazyloadIndex + 1) * queryLimit > res.totalCount ? res.totalCount : (lazyloadIndex + 1) * queryLimit} of ${
                res.totalCount
              } questions`
        );
        if (resQuesList && resQuesList.length > 0) {
          let arrId = [];
          resQuesList.map((q) => {
            arrId.push(q.id);
          });
          if(mode === "new"){
            setQuestionQBId([...arrId]);
            setQuestionQB([...resQuesList]);
          } else {
            setQuestionQBId([...questionQBId, ...arrId]);
            setQuestionQB([...questionQB, ...resQuesList]);
          }
          if (res.totalCount > (lazyloadIndex + 1) * queryLimit) {
            setLazyloadIndex(1 + lazyloadIndex);
            setLazyloading(false);
          }
        } else {
          if(mode === "new"){
            setQuestionQBId([]);
            setQuestionQB([]);
          }
        }
        setloading(false);
      } else {
        setloading(false);
        //open && setTimeout(() => setSwitchTocken("getQuestionFromQB"), 3000);
      }
    });
  };

  const addToSelectedQues = (qid) => {
    setTempCheckedQuesId([...tempCheckedQuesId, qid]);
    setTempSelectedQuesList([
      ...tempSelectedQuesList,
      questionQB[questionQBId.indexOf(qid)],
    ]);
  };

  const removeFromSelectedQues = (index) => {
    let arrId = [...tempCheckedQuesId];
    let arrList = [...tempSelectedQuesList];
    arrId.splice(index, 1);
    arrList.splice(index, 1);
    setTempCheckedQuesId(arrId);
    setTempSelectedQuesList(arrList);
  };

  const tempSelectQuesSingle = (qid) => {
    if (tabValue === 0) {
      let i = tempCheckedQuesId.indexOf(qid);
      if (i === -1) {
        addToSelectedQues(qid);
      } else {
        removeFromSelectedQues(i);
      }
    } else {
      let i = tempSelectedCheckedQuesId.indexOf(qid);
      if (i === -1) {
        setTempSelectedCheckedQuesId([...tempSelectedCheckedQuesId, qid]);
      } else {
        let arrId = [...tempSelectedCheckedQuesId];
        arrId.splice(i, 1);
        setTempSelectedCheckedQuesId(arrId);
      }
    }
  };

  const commitChanges = () => {
    if (tabValue === 0 && tempCheckedQuesId.length > 0) {
      setSelectedQuestionId([ ...selectedQuestionId , ...tempCheckedQuesId]);
      setSelectedQuestionList([...selectedQuestionList , ...tempSelectedQuesList]);
      setTostMsg(`${tempCheckedQuesId.length} question${tempCheckedQuesId.length > 1 ? 's' : ''} added successfully`);
      setTabValue(1);
    } else if (tabValue === 1 && tempSelectedCheckedQuesId.length > 0)  {
        setTempCheckedQuesId([]);
        setTempSelectedQuesList([]);
        let arrId = [...selectedQuestionId],
          arrList = [...selectedQuestionList],
          removeCount = 0;
          tempSelectedCheckedQuesId.forEach((id) => {
            const index = arrId.indexOf(id);
            if (index > -1) {
              removeCount++;
              arrId.splice(index, 1);
              arrList.splice(index, 1);
            }
          });
          setSelectedQuestionId([...arrId]);
          setSelectedQuestionList([...arrList]);
          if (removeCount > 0) {
            setTostMsg(`${removeCount} question${removeCount > 1 ? 's' : ''} removed successfully`);
          }
          setTempSelectedCheckedQuesId([]);
        }
      };      
    const tableData = (q, i) => {
    const index =
      otherSelectedQues.length > 0
        ? otherSelectedQues.map((ques) => ques.id).indexOf(q.id)
        : -1;
    const otherSectionName = index > -1 ? otherSelectedQues[index].name : "";
    return (
      <tr key={`questionFromQB${i}`} className={`${otherSectionName !== ""}`}>
        <td className="center">
          <Checkbox
            color="primary"
            onClick={() => tempSelectQuesSingle(q.id)}
            disabled={otherSectionName !== ""}
            checked={
              tabValue === 0
                ? tempCheckedQuesId.indexOf(q.id) === -1
                  ? false
                  : true
                : tempSelectedCheckedQuesId.indexOf(q.id) === -1
                ? false
                : true
            }
          />
        </td>
        <td>
          <div className="center">{`${i + 1}.`}</div>
        </td>
        <td>
          <div className="qname">{q.name}</div>
          {otherSectionName !== "" && (
            <Chip size="small" label={otherSectionName} />
          )}
        </td>
        <td>
          <div className="bankname">{q.questionBankName}</div>
        </td>
        <td>
          <div className="type">
            {q.type === "MC"
              ? "Multi Choice"
              : q.type === "CD"
              ? "Coding"
              : q.type === "DESC"
              ? "Descriptive"
              : ""}
          </div>
        </td>
        <td>
          <div className="category">{q.category}</div>
        </td>
        <td>
          <div className="topic">{q.topic}</div>
        </td>
        <td className="center">
          <span
            style={{
              backgroundColor:
                q.complexity === "easy"
                  ? "#dcf0de"
                  : q.complexity === "medium"
                  ? "#fcf3da"
                  : q.complexity === "hard"
                  ? "#fbdcdc"
                  : "",
              padding: "4px 8px",
              textAlign: "center",
              minWidth: "106px",
              display: "inline-block",
              margin: "4px auto",
              fontWeight: 400,
              borderRadius: 3,
              color:
                q.complexity === "easy"
                  ? "#00B11A"
                  : q.complexity === "medium"
                  ? "#D4A207"
                  : q.complexity === "hard"
                  ? "#EB5757"
                  : "",
            }}
          >
            {q?.complexity?.slice(0, 1).toUpperCase() +
              q?.complexity?.slice(1, q.complexity.length)}
          </span>
        </td>
      </tr>
    );
  };
  const onScroll = (event) => {
    if (tabValue === 0)
      if (
        event.target.firstChild.offsetHeight - 20 <
        event.target.scrollTop + event.target.offsetHeight
      ) {
        setLazyloading(true);
      }
  };
  useEffect(() => {
    let timeOut = null;
    if (tostMsg !== "") {
      timeOut = setTimeout(() => setTostMsg(""), 2000);
    }
    return () => timeOut !== null && clearTimeout(timeOut);
  }, [tostMsg]);
  useEffect(() => {
    if (initialValues) {
      const otherQues = [];
      for (const key in initialValues) {
        if (key !== sectionIndex) {
          const tQuesList = initialValues[key];
          tQuesList &&
            tQuesList.questionList.length > 0 &&
            tQuesList.questionList.filter((que) => {
              otherQues.push({
                id: que.id,
                name: initialValues[key].name
                  ? initialValues[key].name
                  : sectionIndex,
              });
            });
        }
      }
      setOtherSelectedQues(otherQues);
    }
  }, [initialValues]);

  useEffect(() => {
    if (isSelectAllChanged !== "") {
      setIsSelectAllChanged("");
      if (tabValue === 0) {
        if (selectAll === "checked" && questionQBId.length > 1) {
          const quesIdList = [...questionQBId];
          if (otherSelectedQues.length > 0) {
            otherSelectedQues.map((q) => {
              if (q.id) {
                const idIndex = quesIdList.indexOf(q.id);
                if (idIndex > -1) quesIdList.splice(idIndex, 1);
              }
            });
          }
          if (quesIdList.length > 0) {
            let quesList = [];
            quesIdList.map((qid) =>
              quesList.push(questionQB[questionQBId.indexOf(qid)])
            );
            setTempCheckedQuesId(quesIdList);
            setTempSelectedQuesList(quesList);
          } else {
            setTempCheckedQuesId([]);
            setTempSelectedQuesList([]);
          }
        } else if (selectAll === "") {
          setTempCheckedQuesId([]);
          setTempSelectedQuesList([]);
        }
      } else if (tabValue === 1) {
        if (selectAll === "checked") {
          setTempSelectedCheckedQuesId([...selectedQuestionId]);
        } else if (selectAll === "") {
          setTempSelectedCheckedQuesId([]);
        }
      }
    }
  }, [isSelectAllChanged]);

  useEffect(() => {
    if (selectedQuestionId.length > 0) {
      setTempCheckedQuesId([...selectedQuestionId]);
    }
  }, [selectedQuestionId, tabValue]);

  useEffect(() => {
    setSelectAll("");
  }, [tabValue]);

  useEffect(() => {
    if (
      open &&
      selectedQuestionList.length === 0 &&
      selectedQuestionId.length > 0
    ) {
      setloading(true);
      updateQuestionsByIds(selectedQuestionId);
    }
  }, [open]);

  useEffect(() => {
    if (selectedQuestionList.length > 0) {
      setTempSelectedQuesList([...selectedQuestionList]);
    }
  }, [selectedQuestionList, tabValue]);

  useEffect(() => {
    if (localSelectedQB.value !== selectedQB.value) {
      setCategory("");
      setSearchString("");
      setSearchText("");
      setRandomCount(0);
      setRandomCountError(false);
      setComplexity("");
      setQuestionType(defaultQuestionType ? defaultQuestionType : "");
      setLazyloadIndex(0);
      setLocalSelectedQB(selectedQB);
      setQuestionQBId([]);
      setQuestionQB([]);
      setSwitchTocken("getFilterAttributes");
      setStatusMsg("Loading filters....");
    }
  }, [selectedQB]);

  useEffect(() => {
    if (
      category !== "" ||
      questionType !== "" ||
      complexity !== "" ||
      searchString !== ""
    ) {
      if (questionType === "null")
        setQuestionType(defaultQuestionType ? defaultQuestionType : "");
      if (category === "null") setCategory("");
      if (complexity === "null") setComplexity("");
      if (searchString === "null") setSearchString("");
      setLazyloadIndex(0);
      setQuestionQB([]);
      setSwitchTocken("getQuestionFromQB");
    }
  }, [category, questionType, complexity, searchString]);

  useEffect(() => {
    if (switchTocken === "getFilterAttributes") getFilterAttributes();
    if (switchTocken === "getQuestionFromQB") getQuestionFromQB("new");
    if (switchTocken !== "") {
      setSwitchTocken("");
    }
  }, [switchTocken]);

  useEffect(() => {
    if (lazyloading) {
      getQuestionFromQB("next");
    }
  }, [lazyloading]);

  useEffect(() => {
    if (!reRender || scrollRef.current === null) return;
    scrollRef.current.addEventListener("scroll", onScroll);
    return () =>
      scrollRef &&
      scrollRef.current &&
      scrollRef.current.removeEventListener("scroll", onScroll);
  }, [reRender]);

  useEffect(() => {
    if (!reRender) setReRender(true);
  }, []);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleCloseDialog}
        className={css.DialogAddQuestion}
      >
        <div className="MuiDialogTitle-root">
          <div className={css.tabsWrapper}>
            <Tabs
              value={tabValue}
              variant="fullWidth"
              onChange={handleTabChange}
              aria-label="questions"
            >
              <Tab
                value={0}
                label={
                  <span>
                    Available <span className="hide-sm">Questions</span>
                  </span>
                }
              />
              <Tab
                value={1}
                label={
                  <span style={{ position: "relative" }}>
                    Selected <span className="hide-sm">Questions</span>{" "}
                    <span className="count">{selectedQuestionId.length}</span>
                  </span>
                }
                icon={<Badge badgeContent={0} color="primary" />}
                iconPosition="end"
              ></Tab>
            </Tabs>
          </div>
        </div>
        <DialogContent>
          {loading && (
            <div className="loading">
              <CircularProgress />
            </div>
          )}
          <div className={css.dialogContent}>
            {tabValue === 0 ? (
              <div className={"ctrls"}>
                <Select
                  // menuIsOpen={true}
                  className="quesBank"
                  options={listQB}
                  value={selectedQB}
                  onChange={(value) => changeSelectedQB(value)}
                  placeholder="Select question bank"
                  styles={customSelectMenuStyles}
                />
                <div className="CustomSearch">
                  <Grid
                    item
                    container
                    spacing={4}
                    justify="center"
                    sx={{ marginTop: "16px" }}
                  >
                    <Grid item xs={12} sm={6} md={7}>
                      <TextField
                        size="small"
                        error={false}
                        label="Search questions"
                        type="text"
                        value={searchText}
                        onChange={(e) => handleSearch(e.target.value)}
                        fullWidth={true}
                      />
                      {/* <input
                        placeholder="Search Questions"
                        value={searchText}
                        onChange={(e) => handleSearch(e.target.value)}
                        autoComplete="off"
                      /> */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={5}>
                      <div className="buttonGroup">
                        <TextField
                          size="small"
                          label="Random select"
                          type="number"
                          value={randomCount}
                          className="first"
                          onChange={(e) => {
                            setRandomCountErrorTxt("");
                            setRandomCountError(false);
                            setRandomCount(e.target.value);
                          }}
                          fullWidth={true}
                          error={randomCountError}
                          // disabled={questionQB.length === 0}
                        />
                        {/* <input
                          placeholder="Enter a number"
                          type={"number"}
                          value={randomCount}
                          onChange={(e) => setRandomCount(e.target.value)}
                          autoComplete="off"
                        /> */}
                        <Button
                          // disabled={questionQBId.length < 5}
                          color="primary"
                          onClick={(e) => goRandomize()}
                          variant="contained"
                          className="last"
                        >
                          Randomize
                        </Button>
                      </div>
                      {randomCountErrorTxt ? (
                        <p className="infoText">{randomCountErrorTxt}</p>
                      ) : (
                        randomCountError && (
                          <p className="infoText">{`Enter a number below ${questionQB.length}`}</p>
                        )
                      )}
                    </Grid>
                  </Grid>
                </div>
                {tostMsg !== "" && (
                  <div className="tostMsg">
                    <div className="msg">{tostMsg}</div>
                  </div>
                )}
              </div>
            ) : (
              <div className="ctrls no">
                {tostMsg !== "" && (
                  <div className="tostMsg">
                    <div className="msg">{tostMsg}</div>
                  </div>
                )}
              </div>
            )}
            <div className={css.table}>
              <div ref={scrollRef} className="containers">
                <table
                  className={`table ${
                    tabValue === 0
                      ? questionQB.length > 0
                        ? ""
                        : "full"
                      : selectedQuestionList.length > 0
                      ? ""
                      : "full"
                  }`}
                >
                  <thead>
                    <tr>
                      <th className="first">
                        <Checkbox
                          color="primary"
                          onClick={() => {
                            if (selectAll === "") setSelectAll("checked");
                            else {
                              setSelectAll("");
                            }
                            setIsSelectAllChanged("yes");
                          }}
                          checked={selectAll === "checked"}
                        />
                      </th>
                      <th>
                        <div>
                          <span className="name only">S. No</span>
                        </div>
                      </th>
                      <th className="full">
                        <div>
                          <span className="name only">Question</span>
                        </div>
                      </th>
                      <th>
                        <div>
                          <span className="name only">Ques Bank</span>
                        </div>
                      </th>
                      <th>
                        <div>
                          <span className="name">Type</span>
                          {tabValue === 0 && (
                            <IconButton
                              component="span"
                              disabled={typeList.length === 0}
                              onClick={(e) => handleFilterMenu(e, "type")}
                            >
                              <KeyboardArrowDownIcon />
                            </IconButton>
                          )}
                          {typeList.length > 0 && (
                            <Menu
                              anchorEl={
                                filterMenu === "type" ? filterAnchorEl : null
                              }
                              open={
                                filterMenu === "type" ? openFilterMenu : false
                              }
                              onClose={() => handleFilterMenuClose()}
                              PaperProps={{ className: css.FilterMenu }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              {typeList.map((item, i) => (
                                <MenuItem
                                  key={`typeList${i}`}
                                  onClick={() =>
                                    setQuestionType(
                                      questionType === item ? "null" : item
                                    )
                                  }
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      color="primary"
                                      checked={questionType === item}
                                    />
                                  </ListItemIcon>
                                  <ListItemText>
                                    {typeList[i] === "CD"
                                      ? "Coding"
                                      : typeList[i] === "MC"
                                      ? "Multi Choice"
                                      : typeList[i]}
                                  </ListItemText>
                                </MenuItem>
                              ))}
                            </Menu>
                          )}
                        </div>
                      </th>
                      <th>
                        <div>
                          <span className="name">Category</span>
                          {tabValue === 0 && (
                            <IconButton
                              component="span"
                              disabled={categoryList.idList.length === 0}
                              onClick={(e) => handleFilterMenu(e, "category")}
                            >
                              <KeyboardArrowDownIcon />
                            </IconButton>
                          )}
                          {categoryList.idList.length > 0 && (
                            <Menu
                              anchorEl={
                                filterMenu === "category"
                                  ? filterAnchorEl
                                  : null
                              }
                              open={
                                filterMenu === "category"
                                  ? openFilterMenu
                                  : false
                              }
                              onClose={() => handleFilterMenuClose()}
                              PaperProps={{ className: css.FilterMenu }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              {categoryList.idList.map((item, i) => (
                                <MenuItem
                                  key={`categoryList${i}`}
                                  onClick={() =>
                                    setCategory(
                                      category === item ? "null" : item
                                    )
                                  }
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      color="primary"
                                      checked={category === item}
                                    />
                                    {/* {category === item ? (
                                      <CheckBoxIcon />
                                    ) : (
                                      <CheckBoxOutlineBlankIcon />
                                    )} */}
                                  </ListItemIcon>
                                  <ListItemText>
                                    {categoryList.objList[i].categoryName}
                                  </ListItemText>
                                </MenuItem>
                              ))}
                            </Menu>
                          )}
                        </div>
                      </th>
                      <th>
                        <div>
                          <span className="name only">Topic</span>
                        </div>
                      </th>
                      <th className="last">
                        <div>
                          <span className="name">Complexity</span>
                          {tabValue === 0 && (
                            <IconButton
                              component="span"
                              disabled={complexityList.length === 0}
                              onClick={(e) => handleFilterMenu(e, "complexity")}
                            >
                              <KeyboardArrowDownIcon />
                            </IconButton>
                          )}
                          {complexityList.length > 0 && (
                            <Menu
                              anchorEl={
                                filterMenu === "complexity"
                                  ? filterAnchorEl
                                  : null
                              }
                              open={
                                filterMenu === "complexity"
                                  ? openFilterMenu
                                  : false
                              }
                              onClose={() => handleFilterMenuClose()}
                              PaperProps={{ className: css.FilterMenu }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              {complexityList.map((item, i) => (
                                <MenuItem
                                  key={`complexityList${i}`}
                                  onClick={() =>
                                    setComplexity(
                                      complexity === item ? "null" : item
                                    )
                                  }
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      color="primary"
                                      checked={complexity === item}
                                    />
                                  </ListItemIcon>
                                  <ListItemText>
                                    {item?.slice(0, 1).toUpperCase() +
                                      item?.slice(1, item.length)}
                                  </ListItemText>
                                </MenuItem>
                              ))}
                            </Menu>
                          )}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabValue === 0 ? (
                      questionQB.length > 0 ? (
                        questionQB.map((q, i) => tableData(q, i))
                      ) : (
                        <tr>
                          <td colSpan={8} className="center">
                            <Box className="msg">No questions found. </Box>
                          </td>
                        </tr>
                      )
                    ) : selectedQuestionList.length > 0 ? (
                      selectedQuestionList.map((q, i) => tableData(q, i))
                    ) : (
                      <tr>
                        <td colSpan={8} className="center">
                          <Box className="msg">No questions were added. </Box>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="footerMenu">
            <div className="menuItem">
              <p className="status">
                {tabValue === 0
                  ? statusMsg
                  : `${selectedQuestionList.length} Questions were selected`}
              </p>
            </div>
            <div className="menuItem center"></div>
            <div className="menuItem">
              <Button
                variant="contained"
                className={tabValue === 0 ? "addButton" : "removeButton"}
                // disabled={checkboxEvenet.length === 0}
                onClick={() => commitChanges()}
              >
                {tabValue === 0 ? "Add questions" : "Remove questions"}
              </Button>
              <div className="spacing" />
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCloseDialog}
              >
                Close
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectQuestionsQPModalNew;
