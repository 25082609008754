import React, { useState, useEffect, useRef } from "react";
import { DragHandle } from "./DragHandle";
import { Draggable } from "react-beautiful-dnd";
import { LessonIcons } from "./Content";
import { PlusIcon, BXedit, BXTrash } from "assets/icons/icons";
import styles from "./SectionCreation.module.scss";
import { TervButtonOutlined2 } from "components/TervButtons";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ConformDelete } from "../../components/DeleteModelePre";
const paraStyle = {
  fontSize: 15,
  fontWeight: 500,
  color: "#5B5A5F",
  marginTop: 12,
  textAlign: "center",
};
const LessonButtonGroup = ({ handleDeleteConformation, remove, handle, editLesson }) => (
  <div className={styles.sectionIconGroup}>
    <span onClick={editLesson}>
      <BXedit size="24" color="#7C7887" />
    </span>
    <span onClick={()=>{
      handleDeleteConformation({
        type: "deleteContent",
        cb: remove
      })
    }}>
      <BXTrash size="24" color="#7C7887" />
    </span>
    <DragHandle handle={handle} />
  </div>
);
const timePad = (val) => ("0" + Math.floor(val)).slice(-2);
function timeFormat(t) {
  const time = parseFloat(t);
  if (time && time > 0) {
    const sec = time % 60;
    const hr = Math.floor(time / 3600);
    return `${hr > 0 ? `${timePad(hr)}:` : ""}${timePad(
      (time % 3600) / 60
    )}:${timePad(time % 60)}`;
  } else return `00:00`;
}

export const LessonItem = ({
  sectionValues,
  setSectionValues,
  lesson,
  ind,
  secInd,
  setCurrentLesson,
  handleModal,
  SetCurrentSection,
  remove,
  handleDeleteConformation,
  updateSelectedLesson,
}) => {
  const [mediaDelete, setMediaDelete] = useState({ type: "", index: null });
  const removeQuiz = (index) => {
    if (lesson.videoQuizz && lesson.videoQuizz.length > 0) {
      const vQuizz = [];
      lesson.videoQuizz.map((q, i) => {
        if (i !== index) vQuizz.push(lesson.videoQuizz[i]);
      });
      const tempSection = [ ...sectionValues ];
      const i1 = secInd, i2 = ind, s1 = "courseResource";
      if(tempSection && tempSection[i1] && tempSection[i1][s1] && tempSection[i1][s1][i2])
        tempSection[i1][s1][i2].videoQuizz = [...vQuizz];
      setSectionValues(tempSection);
    }
  };
  useEffect(() => {
    if (
      mediaDelete.type === "quizDeleteConform" &&
      mediaDelete.index !== null
    ) {
      removeQuiz(mediaDelete.index);
      setMediaDelete({ type: "", index: null });
    }
  }, [mediaDelete]);
  return (
    <Draggable key={`${secInd}-lesson-${ind}`} draggableId={`${secInd}-lesson-${ind}`} index={ind} >
      {(provided) => (
        <div className={styles.lessonItemInner} ref={provided.innerRef} {...provided.draggableProps}  >
          <div className={styles.lessonItemCtrl} >
            <div className={styles.draggableItem}>
              <div>
                <span className={styles.lessonIndex}>{ind + 1}.</span>
                <span className={styles.lessonIcon}>
                  {LessonIcons.find((l) => l.label === lesson.type)?.icon({
                    color: "#63606C",
                    size: 24,
                  })}
                </span>
                <span className={styles.lessonName}>{lesson.name}</span>
              </div>
              <div>
                {lesson.type === "Video" ||
                lesson.type === "Audio" ? (
                  <span className={styles.lessonDuration}>
                    {lesson.duration ? lesson.duration : "00:00"}
                  </span>
                ) : (
                  ""
                )}
                {lesson.type === "Study material" && (
                  <span className={styles.lessonDuration}>
                    {lesson.documentType}
                  </span>
                )}
                {lesson.type === "Scrom" && (
                  <span className={styles.lessonDuration}>{lesson.Scrom}</span>
                )}
                <LessonButtonGroup
                  handleDeleteConformation={handleDeleteConformation}
                  remove={remove}
                  editLesson={() => {
                    updateSelectedLesson({ type:"", lessonId:"", lessonIndex:ind, sectionIndex:secInd})
                    SetCurrentSection(secInd);
                    setCurrentLesson(lesson);
                    handleModal();
                  }}
                  handle={provided.dragHandleProps}
                />
              </div>
            </div>
            {lesson.type === "Video" ? (
              <TervButtonOutlined2
                text="Quiz"
                icon={<PlusIcon size="16" color="#1ba94c" />}
                onClick={() => lesson.media && updateSelectedLesson({ type:"videoQuiz", lessonId:lesson.media, lessonIndex:ind, sectionIndex:secInd})}
              />
            ): (<div style={{minWidth:89}}></div>)}
            {/* //updateSelectedLesson={updateSelectedLesson} */}
          </div>
          {lesson.videoQuizz && lesson.videoQuizz.length > 0 && (
            <div className={styles.lessonQuizCtrl} >
              {lesson.videoQuizz.map((q,i)=> (
                <div key={`quizChip${i}`} className={styles.quizChip}>
                  <div className={styles.name}>{q.quizzName}</div>
                  <div className={styles.time}>{timeFormat(q.timeLine ? q.timeLine : 0)}</div>
                  <IconButton aria-label="delete" size="small"
                    onClick={ ()=>setMediaDelete({type: "quizDelete", index: i}) } >
                    <CloseIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          )}
          <ConformDelete
            Header={
              mediaDelete.type === "quizDelete"
                ? "Delete quiz"
                : ""
            }
            Content={() =>
              mediaDelete.type === "quizDelete" ? (
                <p style={paraStyle}>
                  <div>Are you sure want to delete quiz?</div>
                  <div>You cannot recover once deleted</div>
                </p>
              ) : (
                ""
              )
            }
            isOpen={mediaDelete.type === "quizDelete"}
            handleClose={() => {
              setMediaDelete({
                type: "",
                index: null,
              });
            }}
            handleDelete={() => {
              if (mediaDelete.type === "quizDelete")
                setMediaDelete({
                  type: "quizDeleteConform",
                  index: mediaDelete.index,
                });
            }}
          />
        </div>
      )}
    </Draggable>
  );
};
