import { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useParams, Link } from "react-router-dom";


export default function confirmation(props) {
    const { courseId } = useParams();
    const externalCheck = window.location.href.includes('/course-external');
    const buttonCreate = () => {
      props?.setLoading(true)
      const form = document.createElement('form')
      const newInput = document.createElement('input')
      form.setAttribute('method', 'POST')
      form.setAttribute('action', props?.apiLink)
    //   form.setAttribute('target', '_blank')
      newInput.name ='session_token'
      newInput.value = props?.apiToken
      form.appendChild(newInput)
      document.body.appendChild(form)
      form.submit()
      window.open(`https://staging.terv.pro/course-external/${courseId}`)
    //   window.open(`http://localhost:3003/course-external/${courseId}`)
      props?.setLoading(false)
    }  
    // action={props?.apiLink} method="POST" target='_blank'
    return (
        <div>
        {props?.loadingUrl ? 
        <form id="form">
            <Button
            variant="outlined"
            className="buy-button"
            style={{ textTransform: "capitalize" }}
            >
                {'Loading...'}
            </Button>
        </form>
        :
            <form id="form">
                <Button
                    variant="outlined"
                    className="buy-button"
                    style={{ textTransform: "capitalize" }}
                    onClick={externalCheck ? props?.externalCourseStart : buttonCreate}
                >
                    { props?.status == 'start Course' ? 'start Course' : props?.status == 'Inprogress' ? 'Continue learning' : 'course restart'}
                </Button>
            <input id="auth_token" type="hidden"></input>
        </form>
        }
    </div>
    )
}