import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
  IconButton,
  Button as Button1,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  useMediaQuery,
} from "@material-ui/core";
import {
  Link,
  withRouter,
  useParams,
  useLocation,
} from "react-router-dom";
import { Close as CloseIcon, FlashAuto } from "@material-ui/icons";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { handleLoggedIn } from "../../store/loggedIn/actions";
import { getUserProfile } from "../../store/UserProfile/actions";
import { Button } from "reactstrap";
import qs from "query-string";
import moment from "moment";
import uuid from "react-uuid";
import ApiHelper from "../../api/apiHelper";
import api from "../..//api/baseConfig";
import jwt from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
import styles from "./SignInModal.module.scss";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { addToCart } from "pages/virtualLab/ViewPracticeQuestions";
import { useTranslation } from "react-i18next";
import { useLogoContext, handleLogoContext } from "../../context/logoContext";
import Cookies from "universal-cookie";
import { Snackbar } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  textField: {
    height: "50px",
    width: "400px",
  },
  input: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  textField1: {
    height: "50px",
    width: "190px",
    paddingBottom: "7px",
  },
  mobileTextField: {
    height: "50px",
    width: "160px",
    paddingBottom: "7px",
  },
  mobileTextField1: {
    height: "50px",
    width: "343px",
  },
  formControl: {
    width: "190px",
    height: "50px",
  },
  selectroot: {
    width: "190px",
    height: "50px",
  },
  mobileSelectRoot: {
    width: "160px",
    height: "50px",
  },
  selectInput: {
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  selectDialogContainer: {
    "& .MuiPaper-root": {
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#784df5",
        borderRadius: "10px",
        margin: "0 10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
      },
    },
  },
  mobileSignInContainer: {
    "& .MuiDialog-paper": {
      margin: "0 !important",
      marginRight: "50%",
    },
    "& .MuiDialog-scrollPaper": {
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

function SignIn({
  handleSignInModalOpen,
  signInQuestion,
  fromAssessment,
  assessmentId,
  solveValue,
  pkgId,
  fromLab,
  buyPkg,
  buyPrepare,
  preapareId,
  openInstructions,
  ...props
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cookies = new Cookies();

  const [emailInput, setEmailInput] = useState("");
  const [pwdInput, setPwdInput] = useState({
    password: "",
    showPassword: false,
  });
  const changeLogo = handleLogoContext();
  const logoImage = useLogoContext();
  const [signUpFirstNameInput, setSignUpFirstNameInput] = useState("");
  const [signUpLastNameInput, setSignUpLastNameInput] = useState("");
  const [signUpEmailInput, setSignUpEmailInput] = useState("");
  const [signUpPwdInput, setSignUpPwdInput] = useState({
    password: "",
    showPassword: false,
  });
  const [signUpDobInput, setSignUpDobInpt] = useState("");
  const [signUpGenderInput, setSignUpGenderInput] = useState("");
  const [signUpMobNoInput, setSignUpMobNoInput] = useState();

  const isSmallScreen = useMediaQuery("(max-width: 960px)");
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState({ email: "", pwd: "" });
  const [hasAccount, setHasAccount] = useState(false);

  const [otp , setOtp] = useState("");
  const [forgetModal , setForgetModal] = useState(false);
  const [forgetPasswordData , SetForgetPasswordData] = useState({
    email: "",
    otp: "",
    password : "",
    confirmPassword : ""
  });
  const [step , setStep] = useState(1);
  const [toastComponent , setToastComponent] = useState(false);
  const [toastMsg , setToastMsg] = useState("");
  const [toastType , setToastType] = useState("success");

  useEffect(() => {
    if (props.head === "signup") {
      setHasAccount(true);
    }
  }, []);
  const showSignUp = props.setShowSignUP;

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();

  const handleSignUpPasswordChange = (prop) => (event) => {
    setSignUpPwdInput({ ...signUpPwdInput, [prop]: event.target.value });
  };

  const handleSignUpClickShowPassword = () => {
    setSignUpPwdInput({
      ...signUpPwdInput,
      showPassword: !signUpPwdInput.showPassword,
    });
  };

  const handleSignUpMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleGenderChange = (event) => {
    setSignUpGenderInput(event.target.value);
  };

  const handleCheckedChange = (event) => {
    setChecked(event.target.checked);
  };

  const moveToTermsAndCondition = () => {
    history.push("/termsAndConditions");
  };
  const toastQueue = [];
  let isToastDisplayed = false;
  
  const showToast = (message) => {
    toastQueue.push(message);
    if (!isToastDisplayed) {
      displayNextToast();
    }
  };
  
  const displayNextToast = () => {
    if (toastQueue.length > 0) {
      const message = toastQueue.shift();
      toast.info(message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        style: {
          bottom: "30px"
        },
        onClose: () => {
          isToastDisplayed = false;
          displayNextToast();
        },
      });
      isToastDisplayed = true;
    }
  };
  const loginUser = async (e, email, password) => {
    if (e) {
      e.preventDefault();
    }
    let req = assessmentId
      ? `grant_type=password&username=${email || emailInput}&password=${
          password ? btoa(password) : btoa(pwdInput.password)
        }&assessmentId=${assessmentId}`
      : pkgId
      ? `grant_type=password&username=${email || emailInput}&password=${
          password ? btoa(password) : btoa(pwdInput.password)
        }&labId=${pkgId}`
      : `grant_type=password&username=${email || emailInput}&password=${
          password ? btoa(password) : btoa(pwdInput.password)
        }`;
    try {
      const response = await ApiHelper.login(
        `${api.baseURL}${api.loginValidation}`,
        req
      );
      if (response.type === "success") {
        showToast("Logging in!...", 
        );
      }
      if (response.type === "error") {
        if (response.data.data === "Incorrect password") {
          showToast("Invalid Credential...", 
          );
        }
        if (response.data.data === "user not found") {
          showToast(
            "Please, check your Email ID is correct or Create a new account...",
          );
        }
      }
      const userToken = response.data.data.access_token;
      const refreshToken = response.data.data.refresh_token;
      if (userToken && refreshToken) {
        const userData = await ApiHelper.axiosCallAuth(
          `${api.baseURL}${api.userManagement.getUserByEmail}${
            email || emailInput
          }`
        );
        const userId = userData.id;
        if (userId) {
          localStorage.removeItem("IsPartnerUser");
          var decoded = jwt(userToken);
          cookies.set("userToken", userToken, { path: "/" });
          cookies.set("refreshToken", refreshToken, {
            path: "/",
          });
          cookies.set("userId", userId, { path: "/" });
          const headerState = {
            state: true,
            dashboard: !!decoded?.authorities?.includes("LEARNER_PROFILE"),
            student: !!decoded?.authorities?.includes("LEARNER_PROFILE"),
            admin:
              !decoded?.authorities.includes("MANAGE_CUSTOMER") &&
              !decoded?.authorities.includes("GET_STUDENT_DASHBOARD"),
            prepare:
              !!decoded?.authorities?.includes("LEARNER_PROFILE") &&
              !!decoded?.authorities?.includes("VIEW_ASSESSMENT"),
            assessment:
              !!decoded?.authorities?.includes("LEARNER_PROFILE") &&
              !!decoded?.authorities?.includes("VIEW_ASSESSMENT"),
            practice:
              !!decoded?.authorities?.includes("LEARNER_PROFILE") &&
              !!decoded?.authorities?.includes("VIEW_PRACTICE_PACKAGE"),
            canViewProfile:
              !!decoded?.authorities?.includes("GET_VIEW_PROFILE"),
            canViewCart: !!decoded?.authorities?.includes("VIEW_CART"),
          };
          dispatch(handleLoggedIn(headerState));
          sessionStorage.setItem("user_id", decoded.userId);

          // console.log({ isLoggedIn: JSON.stringify(headerState) });
          // console.log({ user_response: JSON.stringify(response) });
          // console.log({ user_name: decoded.user_name });
          // console.log({ user_id: decoded.user_name });
          // console.log({ lc_token: userToken });
          // console.log({ userToken: userToken });
          // console.log({ privileges: decoded.authorities });

          // sessionStorage.setItem("isLoggedIn", JSON.stringify(headerState));
          // sessionStorage.setItem("user_response", JSON.stringify(response));
          // sessionStorage.setItem("user_name", decoded.user_name);
          // sessionStorage.setItem("user_id", decoded.user_name);
          // sessionStorage.setItem("lc_token", userToken);
          // sessionStorage.setItem("userToken", userToken);
          // sessionStorage.setItem("privileges", decoded.authorities);

          // var details = {
          //   user_name: decoded.user_name,
          //   designation: "",
          //   college_name: "",
          //   roles: ["USER"],
          // };
          // sessionStorage.setItem("role", JSON.stringify(details));
          // sessionStorage.setItem("applicable_authors", [decoded.user_name]);

          const session_id = uuid();
          sessionStorage.setItem("session_id", session_id);
          await ApiHelper.axiosCallPost(
            `${api.baseURL}terv/saveLoginActivity`,
            "post",
            {
              emailId: decoded.user_name,
              sessionId: session_id,
              name: "",
              loginTime: moment().format("yyyy-MM-DDTHH:mm:ss"),
            }
          )
            .then(async () => {
              const assessmentId = qs.parse(
                window.location.search
              )?.assessmentId;
              if (buyPkg) {
                dispatch(getUserProfile(decoded.user_name));

                history.push({
                  pathname: "/checkout",
                  params: { items: pkgId },
                });
              } else if (buyPrepare) {
                dispatch(getUserProfile(decoded.user_name));

                history.push({
                  pathname: "/checkout",
                  params: { items: preapareId, productType: "course" },
                });
              } else if (solveValue) {
                history.push({
                  pathname: "/lab",
                  params: { items: preapareId, productType: "course" },
                });
              } 
               else if (assessmentId) {
                window.location.href = `/taketest?id=${assessmentId}&userId=${decoded.userId}`;
              } else if (props?.goToStep3) {
                props?.setModal(false);
                props?.goToStep3();
              } else if (
                decoded?.authorities?.includes("LEARNER_PROFILE") &&
                pkgId
              ) {
                sessionStorage.setItem("viewPkgWithoutSignIn", "false");
                if (signInQuestion?.id) {
                  dispatch(getUserProfile(decoded.user_name));
                  history.push({
                    pathname: `/practice-lab`,
                    params: {
                      questionId: signInQuestion.id,
                      path: signInQuestion.path,
                    },
                    state: {
                      fromEdittrue: true,
                      id: signInQuestion.id,
                    },
                  });
                } else if (fromAssessment || fromLab) {
                  openInstructions();
                  if (fromLab) {
                    dispatch(getUserProfile(decoded.user_name));
                    // dispatch(handleLoggedIn(headerState));
                  }
                }
              } else {
                if (decoded?.authorities?.includes("MENTOR_PROFILE")) {
                  if (decoded?.authorities?.includes("VIEW_STUDENTS_REPORT")) {
                    window.location.pathname = "/students-report";
                  } else {
                    window.location.pathname = "/activeUsersList";
                  }
                } else if (decoded?.authorities?.includes("ADMIN_ROLE")) {
                  if (decoded?.authorities?.includes("GET_ADMIN_DASHBOARD")) {
                    window.location.pathname = "/dashboard";
                  } else {
                    window.location.pathname = "/activeUsersList";
                  }
                } else if (decoded?.authorities?.includes("LEARNER_PROFILE")) {
                  if (decoded?.authorities?.includes("GET_STUDENT_DASHBOARD")) {
                    if (window.location.search.includes("id")) {
                      window.location.href = `/taketest${window.location.search}&${decoded.userId}`;
                    } else {
                      window.location.pathname = "/dashboard";
                    }
                  } else if (
                    decoded?.authorities?.includes("VIEW_ASSESSMENT")
                  ) {
                    window.location.pathname = "/assessment";
                  } else if (
                    decoded?.authorities?.includes("VIEW_PRACTICE_PACKAGE")
                  ) {
                    window.location.pathname = "/lab";
                  }
                } else {
                  window.location.pathname = "/prepare";
                }
              }
            })
            .catch((e) => {
              window.location.pathname = "/login";
            });
        }
      }
    } catch (err) {}
  };

  const signUpUser = async (e) => {
    e.preventDefault();

    const email = signUpEmailInput;

    if (checked === true) {
      if (
        /^[a-zA-Z]*$/.test(signUpFirstNameInput) &&
        /^[a-zA-Z]*$/.test(signUpLastNameInput) &&
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
          signUpEmailInput
        )
      ) {
        let userDetails = {
          firstName: signUpFirstNameInput,
          lastName: signUpLastNameInput,
          emailId: signUpEmailInput,
          password: btoa(signUpPwdInput.password),
          userType: "learner",
          phoneNumber: signUpMobNoInput,
          dateOfBirth: signUpDobInput,
          gender: signUpGenderInput,
          customerId: "605b13d98443a50c4177d35d",
          updatedBy: signUpEmailInput,
          createdBy: signUpEmailInput,
        };
        let newUserDetails = {
          firstName: signUpFirstNameInput,
          lastName: signUpLastNameInput,
          emailId: signUpEmailInput,
          password: signUpPwdInput.password,
          userType: "learner",
          phoneNumber: signUpMobNoInput,
          dateOfBirth: signUpDobInput,
          gender: signUpGenderInput,
          customerId: "605b13d98443a50c4177d35d",
          updatedBy: signUpEmailInput,
          createdBy: signUpEmailInput,
        };

        await ApiHelper.signUpValidateUser(
          `${api.baseURL}${api.userManagement.checkUserEmail}${email}`
        ).then(async (response) => {
          if (response === true) {
            toast.error(
              "This email is already in use, Please try using different email Id...",
              {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: false,
              }
            );
          } else if (response === false) {
            ApiHelper.signup(
              `${api.baseURL}${api.userManagement.creatNewUser}`,
              newUserDetails
            )
              .then((res) => {
                toast.info("Successfully Signed Up!...", {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: false,
                });
                loginUser(null, email, signUpPwdInput.password);
              })

              .catch((e) => {
                toast.error("Oops.., Something went wrong...", {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: false,
                });
              });
          }
        });
      } else {
        toast.error(
          "Please check first & last name, email id are in correct format...",
          {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          }
        );
      }
    } else if (checked === false) {
      toast.info(
        "Please accept our terms and condition then press sign up...",
        {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        }
      );
    }
  };

  const handleClickShowPassword = () => {
    setPwdInput({ ...pwdInput, showPassword: !pwdInput.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const timeForResend = 300;  // 5 minutes in seconds change it you want
  const [timeLeft, setTimeLeft] = useState(timeForResend); 
  const [timerActive, setTimerActive] = useState(true);

  useEffect(() => {

    if (timerActive && timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    }
    if(timeLeft == 0)
      setTimerActive(false);
  }, [timeLeft, timerActive]);

  const handleResend = () => {
    handleForgetPassword();
    setTimeLeft(timeForResend);
    setTimerActive(true);
  };

  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleForgetPassword = async() =>{
    setStep(2);
    setTimeLeft(timeForResend);
    setTimerActive(true);
    // !!! note : change it to the backend api enpoint from the list api file when backend is done.
    const otp = await fetch("https://www.random.org/integers/?num=1&min=100000&max=999999&col=1&base=10&format=plain&rnd=new")
                .then(response => response.text())
                .catch(error => console.log('error', error));

    console.log(Number(otp)); // remove once backend api is done
    setOtp(otp);
    SetForgetPasswordData({...forgetPasswordData , otp : ""})
    setToastMsg("OTP sent to your email");
    setToastComponent(true);
    setToastType("info");
  };

  const handleVerifyOtp  = async() => {

    const postData = {
      email : forgetPasswordData.email,
      otp : forgetPasswordData.otp
    }

    // handle api call to verify OTP

    const response = await fetch("https://www.random.org/integers/?num=1&min=100000&max=999999&col=1&base=10&format=plain&rnd=new",
      {
        method: "POST",
        body : JSON.stringify(postData),
        headers: {
          "Content-Type": "application/json",
        }
      }
    )
    .then(response => response.text())
    .catch(error => console.log('error', error));

    if(Number(forgetPasswordData.otp) == Number(otp))
    {
      console.log("successfully verified");

      setStep(3);

      setToastMsg("OTP verified successfully");
      setToastComponent(true);
      setToastType("success");
    }
    else
    {
      console.log("Wrong OTP entered");
      setToastMsg("Incorrect OTP");
      setToastComponent(true);
      setToastType("error");

    }
  };

  const  handleChangePassword = async()=>{

    const postData = {
      email : forgetPasswordData.email,
      password : btoa(forgetPasswordData.password)
    }


    console.log(postData , "Post data"); // remove the logs

    //change api after backend is done for Changing password

    //handle response status with proper error message handling using toasts

    const response = await fetch("https://www.random.org/integers/?num=1&min=100000&max=999999&col=1&base=10&format=plain&rnd=new",
      {
        method : "POST",
        body : JSON.stringify(postData),
        headers: {
          "Content-Type": "application/json",
        }
      }
    )
    .then(response => response.text())
    .catch(error => console.log('error', error));


    setStep(1);
    setForgetModal(false);
    SetForgetPasswordData({
      email: "",
      otp: "",
      password : "",
      confirmPassword : ""
    });

    setToastMsg("Password reseted Successfully");
    setToastComponent(true);
    setToastType("success");
  };

  return (
    <>{!forgetModal ?
    <>
      {hasAccount ? (
        <>
          <Dialog
            fullScreen={isSmallScreen ? true : false}
            classes={{
              root: isSmallScreen
                ? classes.mobileSignInContainer
                : classes.selectDialogContainer,
            }}
            open={props.modal}
            onClose={() => {
              props.setModal(!props.modal);
              setErrors({ email: "", pwd: "" });
            }}
          >
            <ToastContainer />
            <DialogTitle
              className={
                isSmallScreen ? styles.mobileDialogTitle : styles.dialogTitle
              }
            >
              {isSmallScreen && (
                <IconButton
                  aria-label="menu"
                  className={classes.menuButton}
                  color="inherit"
                  edge="start"
                  style={{
                    position: "absolute",
                    width: "3rem",
                    right: "-1rem",
                    top: "0rem",
                  }}
                  onClick={() => {
                    props.setModal(!props.modal);
                    // if (sessionPrivileges.isStudent) {
                    //   setNavMenuOpen((o) => !o);
                    // } else {
                    //   setSidebarOpen((o) => !o);
                    // }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <p className={styles.dialogTitleHeader}>
                {t("Create a new account")}
              </p>
              <p className={styles.dialogSubTitle}>
                {t("Enter your details and signup")}
              </p>
            </DialogTitle>
            <form onSubmit={signUpUser} style={{ margin: "0 auto" }}>
              <DialogContent
                className={
                  isSmallScreen
                    ? styles.mobileDialogContent1
                    : styles.dialogContent1
                }
              >
                <div className={styles.userName}>
                  <span>
                    <TextField
                      required
                      label={t("First Name")}
                      variant="outlined"
                      value={signUpFirstNameInput || ""}
                      onChange={(e) => setSignUpFirstNameInput(e.target.value)}
                      classes={{
                        root: `${
                          isSmallScreen
                            ? classes.mobileTextField
                            : classes.textField1
                        } 
                        ${styles.textField} ${classes.selectLabel}`,
                      }}
                      InputLabelProps={{
                        style: { fontSize: "18px", fontFamily: "Poppins" },
                      }}
                    />
                  </span>
                  <span>
                    <TextField
                      required
                      label={t("Last Name")}
                      variant="outlined"
                      inputProps={{ name: "notASearchField" }}
                      value={signUpLastNameInput || ""}
                      onChange={(e) => setSignUpLastNameInput(e.target.value)}
                      classes={{
                        root: `${
                          isSmallScreen
                            ? classes.mobileTextField
                            : classes.textField1
                        } 
                        ${styles.textField} ${classes.selectLabel}`,
                      }}
                      InputLabelProps={{
                        style: { fontSize: "18px", fontFamily: "Poppins" },
                      }}
                    />
                  </span>
                </div>
                <div className={styles.signInputContainer}>
                  <TextField
                    required
                    label={t("Email")}
                    variant="outlined"
                    type="email"
                    name="notASearchField"
                    value={signUpEmailInput || ""}
                    onChange={(e) => setSignUpEmailInput(e.target.value)}
                    classes={{
                      root: `${
                        isSmallScreen
                          ? classes.mobileTextField1
                          : classes.textField
                      } 
                      ${styles.textField} ${classes.selectLabel}`,
                    }}
                    InputLabelProps={{
                      style: { fontSize: "18px", fontFamily: "Poppins" },
                    }}
                  />
                </div>
                <div className={styles.signInputContainer}>
                  <TextField
                    required
                    label={t("Password")}
                    variant="outlined"
                    type={signUpPwdInput.showPassword ? "text" : "password"}
                    value={signUpPwdInput.password || ""}
                    onChange={handleSignUpPasswordChange("password")}
                    classes={{
                      root: `${
                        isSmallScreen
                          ? classes.mobileTextField1
                          : classes.textField
                      } 
                      ${styles.textField} ${classes.selectLabel}`,
                    }}
                    InputLabelProps={{
                      style: { fontSize: "18px", fontFamily: "Poppins" },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleSignUpClickShowPassword}
                            onMouseDown={handleSignUpMouseDownPassword}
                            edge="end"
                          >
                            {signUpPwdInput.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={styles.dobAndGender}>
                  <span>
                    <TextField
                      id="date"
                      label={t("Date of birth")}
                      type="date"
                      variant="outlined"
                      inputProps={{
                        min: "1955-01-01",
                        max: yyyy + "-" + mm + "-" + dd,
                      }}
                      onChange={(e) => setSignUpDobInpt(e.target.value)}
                      classes={{
                        root: `${
                          isSmallScreen
                            ? classes.mobileTextField
                            : classes.textField1
                        } 
                        ${styles.textField} ${classes.selectLabel}`,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </span>
                  <span>
                    <FormControl
                      variant="outlined"
                      classes={{
                        root: isSmallScreen
                          ? classes.mobileSelectRoot
                          : classes.selectroot,
                      }}
                    >
                      <InputLabel>{t("Gender")}</InputLabel>
                      <Select
                        value={signUpGenderInput}
                        onChange={handleGenderChange}
                        label={t("Gender")}
                        InputLabelProps={{
                          style: {
                            fontSize: "18px",
                            fontFamily: "Poppins",
                            backgroundColor: "red",
                          },
                        }}
                        classes={{ root: classes.selectInput }}
                      >
                        <MenuItem value="Male">{t("Male")}</MenuItem>
                        <MenuItem value="Female">{t("Female")}</MenuItem>
                      </Select>
                    </FormControl>
                  </span>
                </div>
                <div className={styles.signInputContainer}>
                  <TextField
                    label={t("Mobile number")}
                    type="number"
                    variant="outlined"
                    onKeyDown={(e) =>
                      (e.keyCode === 69 || e.keyCode === 190) &&
                      e.preventDefault()
                    }
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    onChange={(e) => setSignUpMobNoInput(e.target.value)}
                    InputLabelProps={{
                      style: { fontSize: "18px", fontFamily: "Poppins" },
                    }}
                    classes={{
                      root: `${
                        isSmallScreen
                          ? classes.mobileTextField1
                          : classes.textField
                      } 
                      ${styles.textField} ${classes.selectLabel}`,
                    }}
                  />
                </div>
                <div
                  className={
                    isSmallScreen
                      ? styles.mobileAcceptTermsAndCondition
                      : styles.acceptTermsAndCondition
                  }
                >
                  <span>
                    <Checkbox
                      checked={checked}
                      color="primary"
                      onChange={handleCheckedChange}
                      className={styles.acceptTerm}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </span>
                  <span>
                    <p
                      onClick={moveToTermsAndCondition}
                      style={{ cursor: "pointer" }}
                      className={
                        isSmallScreen
                          ? styles.mobileAcceptTermText
                          : styles.acceptTermText
                      }
                    >
                      {t("I agree to terms and conditions")}
                    </p>
                  </span>
                </div>
                <div className={styles.dialogAction}>
                  <Button
                    className={
                      isSmallScreen
                        ? styles.mobileSignUpButton
                        : styles.signUpButton
                    }
                    style={{
                      backgroundColor: "#1BA94C",
                      border: "1px solid #1BA94C",
                      textTransform: "none",
                    }}
                  >
                    {t("Sign up")}
                  </Button>
                </div>
                <div className={styles.alreadyHaveAnAccount}>
                  <span>
                    <p className={styles.alreadyText}>
                      {t("Already have an account?")}
                    </p>
                  </span>
                  <span>
                    <p
                      className={styles.loginPageButton}
                      onClick={() => {
                        setHasAccount(false);
                      }}
                    >
                      {t("Login")}
                    </p>
                  </span>
                </div>
              </DialogContent>
            </form>
          </Dialog>
        </>
      ) : (
        <>
          <Dialog
            fullScreen={isSmallScreen ? true : false}
            classes={{
              root: isSmallScreen
                ? classes.mobileSignInContainer
                : classes.selectDialogContainer,
            }}
            open={props.modal}
            onClose={() => {
              props.setModal(!props.modal);
              setErrors({ email: "", pwd: "" });
            }}
          >
            <ToastContainer />
            <DialogTitle
              className={
                isSmallScreen ? styles.mobileDialogTitle : styles.dialogTitle
              }
            >
              {isSmallScreen && (
                <IconButton
                  aria-label="menu"
                  className={classes.menuButton}
                  color="inherit"
                  edge="start"
                  style={{
                    position: "absolute",
                    width: "3rem",
                    right: "-1rem",
                    top: "0rem",
                  }}
                  onClick={() => {
                    props.setModal(!props.modal);
                    // if (sessionPrivileges.isStudent) {
                    //   setNavMenuOpen((o) => !o);
                    // } else {
                    //   setSidebarOpen((o) => !o);
                    // }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <p className={styles.dialogTitleHeader}>
                Welcome to {logoImage.productName}
              </p>
              <p className={styles.dialogSubTitle}>
                {t("Enter your details and login")}
              </p>
            </DialogTitle>
            <form onSubmit={loginUser}>
              <DialogContent
                className={
                  isSmallScreen
                    ? styles.mobileDialogContent
                    : styles.dialogContent
                }
                style={{
                  height: showSignUp ? "290px" : "450px",
                }}
              >
                <div className="inputFieldValidation">
                  <TextField
                    required
                    label={t("Email")}
                    variant="outlined"
                    value={emailInput || ""}
                    InputLabelProps={{
                      style: { fontSize: "18px", fontFamily: "Poppins" },
                    }}
                    classes={{
                      root: `${
                        isSmallScreen
                          ? classes.mobileTextField1
                          : classes.textField
                      } 
                      ${styles.textField}`,
                    }}
                    onChange={(e) => setEmailInput(e.target.value)}
                    onBlur={() => {
                      !emailInput ||
                      !/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
                        emailInput
                      )
                        ? setErrors({
                            ...errors,
                            email: t("Please enter a valid email id"),
                          })
                        : setErrors({ ...errors, email: "", pwd: "" });
                    }}
                  />
                  <div>
                    {errors.email && (
                      <span style={{ color: "red" }}>{errors.email}</span>
                    )}
                  </div>
                </div>

                <div className={styles.signInPwdContainer}>
                  <TextField
                    required
                    label={t("Password")}
                    variant="outlined"
                    type={pwdInput.showPassword ? "text" : "password"}
                    value={pwdInput.password || ""}
                    InputLabelProps={{
                      style: { fontSize: "18px", fontFamily: "Poppins" },
                    }}
                    classes={{
                      root: `${
                        isSmallScreen
                          ? classes.mobileTextField1
                          : classes.textField
                      } 
                      ${styles.textField}`,
                    }}
                    onChange={(e) =>
                      setPwdInput({ ...pwdInput, password: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {pwdInput.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onBlur={() => {
                      !pwdInput.password
                        ? setErrors({
                            ...errors,
                            pwd: t("Please enter a valid password"),
                          })
                        : setErrors({ ...errors, pwd: "", email: "" });
                    }}
                  />
                  <div>
                    {errors.pwd && (
                      <span style={{ color: "red" }}>{errors.pwd}</span>
                    )}
                  </div>
                </div>
                <div
                  className={
                    isSmallScreen
                      ? styles.mobileForgotPassword
                      : styles.forgotPassword
                  }
                >
                  <p
                    onClick={() => {
                      setForgetModal(true);
                    }}
                  >{t("Forgot password?")}</p>
                </div>
                <div className={styles.dialogAction}>
                  <Button
                    color="primary"
                    disabled={emailInput && pwdInput ? false : true}
                    className={
                      isSmallScreen
                        ? styles.mobileSignInButton
                        : styles.signInButton
                    }
                  >
                    {t("Sign In")}
                  </Button>
                </div>
                {!showSignUp ? (
                  <div>
                    <div className={styles.orDivision}>
                      <span className={styles.line1}></span>
                      <span>or</span>
                      <span className={styles.line2}></span>
                    </div>
                    <div className={styles.newSignUp}>
                      <p className={styles.dontHaveAccount}>
                        {t("Dont have an account?")}
                      </p>
                      <Button1
                        variant="outlined"
                        style={{
                          border: "2px solid #1BA94C",
                          color: "#1BA94C",
                          textTransform: "none",
                        }}
                        className={
                          isSmallScreen
                            ? styles.mobileSignInButton
                            : styles.signUpButton
                        }
                        onClick={() => setHasAccount(!hasAccount)}
                      >
                        {t("Sign up")}
                      </Button1>
                    </div>
                  </div>
                ) : (
                  <div>{""}</div>
                )}
              </DialogContent>
            </form>
          </Dialog>
        </>
      )}
    </>
    :
    <>
    <Dialog
      fullScreen={isSmallScreen ? true : false}
      classes={{
        root: isSmallScreen
          ? classes.mobileSignInContainer
          : classes.selectDialogContainer,
      }}
      open={props.modal}
      onClose={() => {
              props.setModal(!props.modal);
              setErrors({ email: "", pwd: "" });
              setForgetModal(false);
              setStep(1);
            }}
    >
      <DialogTitle
        className={
          isSmallScreen ? styles.mobileDialogTitle : styles.dialogTitle
        }
      >
        <p className={styles.dialogTitleHeader}>
          {t("Forget Password")}
        </p>
        <p className={styles.dialogSubTitle}>
          {t(`${step == 1 ? "Enter your email to generate otp" : step == 2 ? "Enter OTP recieved on your email" : "Enter New Password"}`)}
        </p>
      </DialogTitle>
      <DialogContent
        className={
          isSmallScreen
            ? styles.mobileDialogContent1
            : styles.dialogContent1
        }
        style={
          {
            height : "250px",
          }
        }
      >
        { step != 3 &&
          <div className={styles.signInputContainer} style={{marginTop : "20px"}}>
          <TextField
            required
            label={t(`${step == 1 ? "Email" : "OTP"}`)}
            variant="outlined"
            type={step == 1 ? "email" : "number"}
            name={step == 1 ? "email" : "otp"}
            value={(step == 1 ? forgetPasswordData.email : forgetPasswordData.otp) || ""}
            onChange={(e) => {
              const data = {...forgetPasswordData}

              if(step == 1)
              data.email = e.target.value;
              else
              data.otp = e.target.value;

              SetForgetPasswordData(data);
              }}
            classes={{
              root: `${
                isSmallScreen
                  ? classes.mobileTextField1
                  : classes.textField
              } 
              ${styles.textField} ${classes.selectLabel}`,
            }}
            InputLabelProps={{
              style: { fontSize: "18px", fontFamily: "Poppins" },
            }}
          />
          </div>
        }
        {step == 3 &&
          <>
            <div className={styles.signInputContainer}>
              <TextField
                required
                label={t(`Set New Password`)}
                variant="outlined"
                type={"password"}
                name={"password"}
                value={forgetPasswordData.password || ""}
                onChange={(e) => {
                  const data = {...forgetPasswordData}

                  data.password = e.target.value;

                  SetForgetPasswordData(data);
                  }}
                classes={{
                  root: `${
                    isSmallScreen
                      ? classes.mobileTextField1
                      : classes.textField
                  } 
                  ${styles.textField} ${classes.selectLabel}`,
                }}
                InputLabelProps={{
                  style: { fontSize: "18px", fontFamily: "Poppins" },
                }}
              />
            </div>
            <div className={styles.signInputContainer}>
              <TextField
                required
                label={t(`Confirm New Password`)}
                variant="outlined"
                type={"password"}
                name={"confirmPassword"}
                value={forgetPasswordData.confirmPassword || ""}
                onChange={(e) => {
                  const data = {...forgetPasswordData}

                  data.confirmPassword = e.target.value;

                  SetForgetPasswordData(data);
                  }}
                classes={{
                  root: `${
                    isSmallScreen
                      ? classes.mobileTextField1
                      : classes.textField
                  } 
                  ${styles.textField} ${classes.selectLabel}`,
                }}
                InputLabelProps={{
                  style: { fontSize: "16px", fontFamily: "Poppins" },
                }}
              />
            </div>
            {
              forgetPasswordData.password != forgetPasswordData.confirmPassword &&
              <p className={""} style={{ color : "red" , fontSize : "10px" , textAlign : "left", width: "90%" ,marginBottom : "-10px"}}>
              {"Both Passwords should match"}
            </p>
            }

          </>
        }
        {step == 2 && (
          <div className={""} style={
            { textAlign: "left", 
              display : "flex" , 
              width : "90%" ,
              justifyContent : "space-between",
              alignItems : "center",
              verticalAlign : "middle",
              gap : "20px"
              }}>
            <p style={{ color: "red", fontSize: "12px" , alignSelf : "center" }}>{`Resend  OTP in ${formatTime()}`}</p>
            <Button
              color={timerActive ? "secondary" : "primary"}
              disabled={timerActive}
              onClick={handleResend}
              style={{height : "30px" , fontSize : "12px"}}
            >
              {t("Resend OTP")}
            </Button>
          </div>
        )}
        <div className={styles.dialogAction}>
          <Button
            color="primary"
            disabled={forgetPasswordData.email.length > 0 && (forgetPasswordData.password == forgetPasswordData.confirmPassword) ? false : true}
            className={
              isSmallScreen
                ? styles.mobileSignInButton
                : styles.signInButton
            }
            onClick={step == 1 ? handleForgetPassword : step == 2 ? handleVerifyOtp : handleChangePassword}
          >
            {t(`${step == 1 ? "Generate OTP" : step == 2 ? "Submit" : "Confirm Password"}`)}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
    </>
    }

    <Snackbar
        open={toastComponent}
        autoHideDuration={5000}
        draggable={true}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setToastComponent(!toastComponent)}
    >
      <MuiAlert
        variant="filled"
        elevation={6}
        onClose={() => setToastComponent(!toastComponent)}
        severity={toastType}
        draggable={true}
      >
      {toastMsg}
      </MuiAlert>
    </Snackbar>

    </>
  );
}

export default connect(null, null)(SignIn);
