import React, { useEffect, useState, useRef } from "react";
import NumberInput from "pages/Tasks/NumberInput";
import NumberInputRandom from "pages/Tasks/NumberInputQp";
import { uid } from "react-uid";
import { Card, CardBody, Collapse, Row, Button } from "reactstrap";
import { QPInputField } from "./QPInputField";
import { Field } from "redux-form";
import { SelectQuestionsModal } from "pages/virtualLab/SelectQuestionsModal/SelectQuestionsQPModal";
import { ErrorBlock } from "components/ErrorBlock";
import "./ViewQ.scss";
import Switch from "@material-ui/core/Switch";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import uuid from "react-uuid";
// import api from "api/baseConfig";
// import apiHelper from "api/apiCheck";

const questionTypes = [
  { value: "MC", label: "Multi Choice" },
  { value: "CD", label: "Coding" },
  { value: "DESC", label: "Descriptive" },
  { value: "all", label: "All" },
];

const validIndicator = (value) => (
  <>
    {value}
    <span style={{ color: "red" }}>*</span>
  </>
);

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export const SectionsArray = ({
  fields,
  collapses,
  toggleCollapse,
  SQMs,
  toggleSQMs,
  questionBanks,
  setCurrQuestionBank,
  setCurrBankQues,
  currBankQues,
  sectionQuestions,
  setSectionQuestions,
  existingQuestions = [],
  toRemove,
  loading,
  currBankQuesTotalCount,
  hasMore,
  setToRemove,
  submitSection,
  errors,
  setCheckedQuestions,
  questionsToWatch,
  formData,
  disabled,
  setNoOfSections,
  totalSections,
  deleteSection,
  formValueSetNew,
  randomState,
  setRandomState,
  setuniqueId,
  uniqueId,
}) => {
  const classes = useStyles();
  const [deleteValue, setDeleteValue] = useState();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [toastEnable, setToastEnable] = useState(false);
  const [questionsArray, setQuestionsArray] = useState([]);
  const [newQuestion, setNewQuestion] = useState();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeValue = () => {
    setNoOfSections(totalSections + 1);
  };
  const [deleteModal, setDeleteModal] = useState(false);

  const handleChange = (event) => {
    setRandomState({
      ...randomState,
      [event.target.name]: event.target.checked,
    });
    setToastEnable(event.target.checked);
  };
  const deleteModalFun = (index) => {
    setDeleteValue(formValueSetNew?.[index] || index);
    setDeleteModal(true);
    setOpen(true);
  };
  const deleteButtonValue = (deleteValue, index) => {
    deleteSection(deleteValue?.sectionOrder || index - 1);
    handleClose();
  };
  const handleClick = (index) => {
    if (
      (sectionQuestions[index]?.val?.length >= 4 ||
        (randomState?.[formValueSetNew?.[index]?.id]?.length || 0) >= 4) ==
      false
    ) {
      toast.error("Please add more than 5 questions to enable random switch", {
        position: "top-right",
        style: { backgroundColor: "#ff0000", color: "#fff", zIndex: 2000 },
      });
    }
  };
  return (
    <div style={{ padding: 20 }}>
      {formValueSetNew?.map((section, index) => (
        <div key={uid(index)} style={{ marginBottom: 20 }}>
          {deleteModal && (
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div style={modalStyle} className={classes.paper}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "26px",
                      color: "#19181B",
                    }}
                  >
                    {`Are you sure want to Delete this section ${deleteValue?.name}?`}
                  </p>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "26px",
                      color: "#63606C",
                      textAlign: "center",
                    }}
                  >
                    This section and content inside this section will be deleted
                    and cannot be restored
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ paddingRight: "24px" }}>
                    <button
                      style={{
                        background: "#F2F1F3",
                        paddingLeft: "38px",
                        paddingRight: "37px",
                        paddingTop: "8px",
                        paddingBottom: "7px",
                        border: "none",
                        borderRadius: "3px",
                      }}
                      onClick={handleClose}
                    >
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "26px",
                          color: "#4A4851",
                          textAlign: "center",
                        }}
                      >
                        Cancel
                      </span>
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        background: "#D42216",
                        color: "#ffffff",
                        paddingLeft: "38px",
                        paddingRight: "37px",
                        paddingTop: "8px",
                        paddingBottom: "7px",
                        border: "none",
                        borderRadius: "3px",
                      }}
                      onClick={() => {
                        deleteButtonValue(deleteValue, index);
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "26px",
                          color: "#ffffff",
                          textAlign: "center",
                        }}
                      >
                        Delete
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
          <div
            onClick={() => toggleCollapse(index)}
            style={{
              fontSize: 15,
              borderRadius: "5px 5px 0px 0px",
              boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.33)",
              cursor: "pointer",
              backgroundColor: "#3A3A3A",
              padding: 10,
              display: "flex",
              color: "#ffffff",
              justifyContent: "space-between",
            }}
          >
            <div>
              {formValueSetNew?.length > 0 && formValueSetNew[index]?.name
                ? `Section ${index + 1}-${formValueSetNew[index]?.name}`
                : `Section ${index + 1}`}
            </div>
            <div>
              {collapses[index] ? (
                <svg
                  width="16"
                  height="11"
                  viewBox="0 0 16 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.05859 10.1216L7.99759 5.18158L12.9366 10.1216L15.0586 7.99958L7.99759 0.939582L0.936593 7.99958L3.05859 10.1216Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  width="16"
                  height="11"
                  viewBox="0 0 16 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.9375 0.938965L7.9985 5.87896L3.0595 0.938965L0.9375 3.06096L7.9985 10.121L15.0595 3.06096L12.9375 0.938965Z"
                    fill="white"
                  />
                </svg>
              )}
            </div>
          </div>
          <Collapse isOpen={collapses[index]}>
            <Card>
              <CardBody
                style={{
                  border: "1px solid #B6B6B6",
                  borderRadius: "0px 0px 5px 5px",
                }}
              >
                <div style={{ display: "flex", marginBottom: 20 }}>
                  <div style={{ flex: 2 }}>
                    <h5>Section Details</h5>
                    <p style={{ color: "#A6A6AA" }}>
                      Enter the section details
                    </p>
                  </div>
                  <div
                    style={{
                      flex: 3,
                      display: "flex",
                      flexDirection: "column",
                      gap: 20,
                    }}
                  >
                    <Field
                      name={`section-${section?.sectionOrder || index}.name`}
                      type="text"
                      component={QPInputField}
                      label="Name"
                      disabled={disabled}
                    />
                    {errors.nameErr && errors.ind === index ? (
                      <ErrorBlock eType="span" errorText={errors.nameErr} />
                    ) : (
                      ""
                    )}
                    <Field
                      name={`section-${
                        section?.sectionOrder || index
                      }.description`}
                      type="textarea"
                      component={QPInputField}
                      label="Description"
                      disabled={disabled}
                    />
                    <Row>
                      <Field
                        name={`section-${
                          section?.sectionOrder || index
                        }.timeLimit`}
                        component={NumberInput}
                        label={validIndicator("Time Limit")}
                        error={
                          errors.timeErr && errors.ind === index
                            ? errors.timeErr
                            : ""
                        }
                        disabled={disabled}
                      />
                      <Field
                        name={`section-${
                          section?.sectionOrder || index
                        }.negativeMark`}
                        component={NumberInput}
                        label="Negative Mark"
                        disabled={disabled}
                      />
                      <Field
                        name={`section-${
                          section?.sectionOrder || index
                        }.cutOffMark`}
                        component={NumberInput}
                        label="Cut Off"
                        disabled={disabled}
                      />
                    </Row>
                    <div className="AlertQp">
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          color: "#2170D8",
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        {`No of questions added : ${
                          sectionQuestions[index]?.val?.length !== 0
                            ? sectionQuestions[index]?.val?.length || 0
                            : formData[`section-${index}`]?.questionList
                                ?.length || 0
                        }`}
                      </span>
                    </div>
                    <div
                      style={{
                        background: "#FAFBFC",
                        border: "1px solid #F2F1F3",
                        boxSizing: "border-box",
                        borderRadius: "5px",
                        padding: "15px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        handleClick(index);
                      }}
                    >
                      <div>
                        <h5
                          style={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "24px",
                            color: "#19181B",
                          }}
                        >
                          Enable Randomly generate questions for assessment
                        </h5>
                        <p
                          style={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "12px",
                            color: "#96939F",
                          }}
                        >
                          Random questions will be generated from selected
                          questions during assessment
                        </p>
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "60%" }}>
                            <div
                              style={
                                randomState?.[formValueSetNew?.[index]?.id]
                                  ? {
                                      fontFamily: "Poppins",
                                      fontStyle: "normal",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      color: "#323036",
                                    }
                                  : {
                                      fontFamily: "Poppins",
                                      fontStyle: "normal",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      color: "#D8D6DB",
                                    }
                              }
                            >
                              No of random questions to generate
                            </div>
                            <Field
                              name={`section-${
                                section?.sectionOrder || index
                              }.randomQuestionCount`}
                              component={NumberInputRandom}
                              disabled={
                                randomState?.[formValueSetNew?.[index]?.id]
                                  ? false
                                  : true
                              }
                            />
                          </div>
                          <div>
                            <div
                              style={
                                randomState?.[formValueSetNew?.[index]?.id]
                                  ? {
                                      fontFamily: "Poppins",
                                      fontStyle: "normal",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      color: "#323036",
                                    }
                                  : {
                                      fontFamily: "Poppins",
                                      fontStyle: "normal",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      color: "#D8D6DB",
                                    }
                              }
                            >
                              Marks for each question
                            </div>
                            <Field
                              name={`section-${
                                section?.sectionOrder || index
                              }.mark`}
                              component={NumberInputRandom}
                              disabled={
                                randomState?.[formValueSetNew?.[index]?.id]
                                  ? false
                                  : true
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <Switch
                          checked={randomState?.[formValueSetNew?.[index]?.id]}
                          onChange={handleChange}
                          color="primary"
                          name={formValueSetNew[index]?.id}
                          disabled={
                            sectionQuestions[index]?.val?.length >= 4 ||
                            randomState?.[formValueSetNew?.[index]?.id]
                              ? false
                              : true
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr style={{ backgroundColor: "#E3F2FF" }} />
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    gap: 10,
                    padding: "10px",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      gap: 10,
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      color="success"
                      onClick={() => {
                        toggleSQMs(index);
                      }}
                      style={{
                        fontSize: 15,
                      }}
                    >
                      Questions
                    </Button>

                    <Button
                      color="primary"
                      style={{
                        fontSize: 15,
                      }}
                      onClick={() => submitSection(index)}
                      disabled={disabled}
                    >
                      Save
                    </Button>
                  </div>
                  <button
                    style={{
                      fontSize: 15,
                      border: "#D42216 1px solid",
                      borderRadius: "5px",
                      color: "#D42216",
                      background: "none",
                      height: "43px",
                    }}
                    onClick={() => deleteModalFun(index)}
                  >
                    Delete section
                  </button>
                </div>
                <div style={{ display: "grid", placeItems: "center" }}>
                  {errors.questionErr && errors.ind === index ? (
                    <ErrorBlock eType="span" errorText={errors.questionErr} />
                  ) : (
                    ""
                  )}
                </div>
              </CardBody>
            </Card>
          </Collapse>
          <SelectQuestionsModal
            questionTypes={questionTypes}
            modalState={SQMs[index]}
            currBankQues={currBankQues[index] ? currBankQues[index] : []}
            existingQuestions={existingQuestions[index] || []}
            questionsType="all"
            loading={loading}
            currBankQuesTotalCount={currBankQuesTotalCount}
            hasMore={hasMore}
            multiSections
            onRemove={(cb) => {
              cb?.();
            }}
            toRemove={toRemove}
            remove={(list, val) => {
              let temp = [...sectionQuestions];
              temp[index].val = val;
              setSectionQuestions(temp);
              if (list.length > 0) {
                let temp = [...toRemove];
                if (temp.hasOwnProperty(index)) {
                  temp[index] = [...temp[index], ...list];
                } else {
                  temp[index] = list;
                }

                setToRemove(temp);
              }
            }}
            sectionIndex={index}
            findQuestion={(id) => {
              let secObj = {};
              let returnValue = null;
              sectionQuestions.forEach((sec, ind) => {
                secObj[ind] = sec.val;
              });
              existingQuestions.forEach((item, ind) => {
                secObj[ind] = [
                  ...item.reduce((acc, val) => {
                    if (!secObj[ind].includes(val.id)) {
                      acc.push(val.id);
                    }
                    return acc;
                  }, []),
                  ...secObj[ind],
                ];
              });
              questionsToWatch.forEach((item, ind) => {
                secObj[ind] = [
                  ...item.reduce((acc, val) => {
                    if (!secObj[ind].includes(val.id)) {
                      acc.push(val.id);
                    }
                    return acc;
                  }, []),
                  ...secObj[ind],
                ];
              });
              for (const key in secObj) {
                if (secObj[key].includes(id)) {
                  returnValue = key;
                }
              }
              return returnValue;
            }}
            setCheckedQuestions={setCheckedQuestions}
            setCurrQuestionBank={setCurrQuestionBank}
            QBlist={questionBanks}
            setQIds={(ids) => {
              // if(ids?.length !== 99999) {
              let temp = [...sectionQuestions];
              temp[index].val = ids;
              setSectionQuestions(temp);
              // }
            }}
            setModal={() => {
              toggleSQMs(index);
              setCurrBankQues([]);
            }}
            setQuestionsArray={setQuestionsArray}
            setNewQuestion={setNewQuestion}
          />
        </div>
      ))}

      <button
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          background: "none",
          border: "#794DF5 1px solid",
          padding: "10px",
        }}
        onClick={handleChangeValue}
      >
        <svg
          width="13"
          height="12"
          viewBox="0 0 13 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.25 5.25V0H5.75V5.25H0.5V6.75H5.75V12H7.25V6.75H12.5V5.25H7.25Z"
            fill="#794DF5"
          />
        </svg>
        <div
          style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "24px",
            color: "#794DF5",
            paddingLeft: "10px",
          }}
        >
          {totalSections == 0 ? "Add section" : "Add Another section"}
        </div>
      </button>
    </div>
  );
};
