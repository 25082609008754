import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { chevronDown } from "assets/icons/icons";
import { uid } from "react-uid";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { CapitalizeFirstLetter } from "pages/utils/CapitalizeFirstLetter";


const arrayIncludes = (arr1, arr2) => {
  let arrs = true;
  for(let i=0; i < arr1.length; i++){
    if(!arr2.includes(arr1[i])){
      arrs = false;
      break;
    }
  }
  return arrs;
}


export const Accordions = ({
  styles,
  privilegesByRole,
  privileges,
  setPrivileges,
}) => {

  return (
    <>
      {privilegesByRole.mods.map((mod, index) => (
        <MuiAccordion classes={{ root: styles.accordion }} key={uid(index)}>
          <MuiAccordionSummary
            classes={{
              content: styles.accordionSumm,
              root: styles.accordionSummRoot,
            }}
            expandIcon={chevronDown({ size: 14, color: "#794DF5" })}
          >
            <p>{mod == "questionBank" ? "Question Bank" : mod == "questionPaper" ? "Question Paper" : CapitalizeFirstLetter(mod)}</p>
          </MuiAccordionSummary>
          <MuiAccordionDetails classes={{ root: styles.accordionDet }}>
            <fieldset style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel 
                        control={
                          <Checkbox
                            color="primary"
                            checked={arrayIncludes(privilegesByRole.data[mod], privileges)}
                            onChange={(e) => {
                              const checkIncludes = privileges.filter((pr) => !privilegesByRole.data[mod].includes(pr))
                              setPrivileges(
                                e.target.checked
                                  ? [...privilegesByRole.data[mod], ...checkIncludes]
                                  : checkIncludes
                              )
                            }
                              
                            }
                            classes={{ checked: styles.checkboxPrimary }}
                          />  
                        }
                        label="Select all"
                        classes={{ label: styles.checkboxLabel }}
                      />
              {(privilegesByRole.data[mod] || []).map((priv, ind) => (
                <>
                  {priv !== "LEARNER_PROFILE" && (
  
                      
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={privileges.includes(priv)}
                            onChange={(e) =>{
                              setPrivileges(
                                e.target.checked
                                  ? [...privileges, priv]
                                  : privileges.filter((pr) => pr !== priv)
                              )
                            }}
                            classes={{ checked: styles.checkboxPrimary }}
                          />
                        }
                        label={priv}
                        key={uid(ind)}
                        classes={{ label: styles.checkboxLabel }}
                      />
                  )}
                </>
              ))}
            </fieldset>
          </MuiAccordionDetails>
        </MuiAccordion>
      ))}
    </>
  );
};
