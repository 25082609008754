import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { ReactSelectTervStyle } from "pages/utils/ReactSelectTervStyle";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import { useForm, Controller } from "react-hook-form";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Select from "react-select";
import PrivilegesBox from "./PrivilegesBox";
import styles from "./RoleManagement.module.scss";
import Breadcrumbs from "../../components/Common/Breadcrumb";

function RoleManagementDumb({
  roles,
  privilegesByRole,
  getPrivilegesByRole,
  createRole,
  editData,
  setPrivileges,
  setPrivilegeValue,
  privilegeValue,
  privileges,
  customerData,
  existingData,
  setExistingData,
  customer,
  activePage,
  itemsPerPage,
  checkIfRoleExists,
}) {
  const methods = useForm({ mode: "onBlur" });
  const {
    register,
    control,
    formState: { errors, isSubmitting },
    reset,
    watch,
    handleSubmit,
  } = methods;
  

  useEffect(() => {
    window.scrollTo(0, 0);
    if (editData?.edit) {
      reset({
        name: editData?.row?.name,
        id: editData?.row?.id,
        description: editData?.row?.description,
        ...(editData?.row?.roleType &&
          editData?.row?.roleType && {
            roleType: editData?.row?.roleType.toUpperCase(),
          }),
      });
      setPrivileges(editData?.row?.privilegesDetails);
    }
  }, [reset]);

  useEffect(() => {
    if (watch("roleType")) {
      getPrivilegesByRole(watch("roleType"));
    }
  }, [watch("roleType")]);

  return (
    <div className={`page-content`}>
      <Breadcrumbs title="Roles" breadcrumbItem="Create Role" />
      <form onSubmit={handleSubmit(createRole)} className={styles.form}>
        <div>
          <div className="terv-row">
            <div>
              <label className="terv-label" htmlFor="RoleType">
                {RequiredIndicator("Role Type ")}
              </label>
              <Controller
                name="roleType"
                control={control}
                rules={{ required: "Please select a role type" }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    inputId="RoleType"
                    onChange={(val) => {
                      onChange(val.value);
                      setPrivileges([]);
                    }}
                    value={GetObjectFromString(roles, value)}
                    options={roles}
                    styles={ReactSelectTervStyle("lg")}
                  />
                )}
              />
              {errors?.roleType && (
                <div style={{ color: "red" }}>{errors?.roleType.message}</div>
              )}
            </div>
          </div>
          <div className="terv-row">
            <div>
              <label className="terv-label" htmlFor="RoleName">
                {RequiredIndicator("Role Name")}
              </label>
              <input
                id="RoleName"
                {...register("name", {
                  required: "Please enter a valid role name",
                  validate: async (value) => {
                    if (value === editData?.row?.name) return true;
                    const boo = await checkIfRoleExists(value);
                    return (
                      !boo ||
                      "This name is already in use, please choose a different name"
                    );
                  },
                })}
                className="terv-form terv-form-lg"
              />
              {errors?.name && (
                <div style={{ color: "red" }}>{errors?.name.message}</div>
              )}
            </div>
          </div>
          <div className="terv-row">
            <div>
              <label className="terv-label" htmlFor="description">
                Description
              </label>
              <textarea
                id="description"
                {...register("description")}
                className="terv-form terv-form-lg"
              />
            </div>
          </div>
          <hr
            style={{
              backgroundColor: "#E3F2FF",
              width: "32rem",
              marginTop: "0.5rem",
              marginBottom: "2rem",
            }}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disableElevation
            classes={{ root: styles.submitButton }}
            disabled={isSubmitting}
          >
            {editData?.edit ? "Update" : "Add"}
          </Button>
        </div>
        <PrivilegesBox
          styles={styles}
          privilegesByRole={privilegesByRole}
          privileges={privileges}
          setPrivileges={setPrivileges}
          setPrivilegeValue={setPrivilegeValue}
          privilegeValue={privilegeValue}
        />
      </form>
      <Link
        to={
          {
            pathname: "/roleList",
            state: {
              customer: customer,
              activePage: activePage,
              itemsPerPage: itemsPerPage,
            }
          }
        }
        className="terv-back"
      >
        <i className="mdi mdi-arrow-left mr-1" /> Back to Roles List{" "}
      </Link>
    </div>
  );
}

export default RoleManagementDumb;
