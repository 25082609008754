import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { FormLayout } from "components/FormLayout";
import { ErrorBlock } from "components/ErrorBlock";
import CustomSelect from "components/CustomSelect";
import CustomSelectHost from "components/CustomSelectHost";
import { GetAllUsers } from "../../../pages/CustomerManagement/utils/GetUsers";
import { GetObjectArray } from "pages/utils/GetObjectArray";
import StyledNumberInput from "components/StyledNumberInput";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { ReactSelectTervStyle } from "pages/utils/ReactSelectTervStyle";
import "./Prepare.scss";
import { TervButtonFilled, TervButtonOutlined } from "components/TervButtons";
import { CreateOrUpdate } from "./Apis";
import { HandleDefaultMedia } from "../../utils/HandleDefaultMedia";
import { yupResolver } from "@hookform/resolvers/yup";
import { cloudLabFormValidation } from "./ValidationSchemas";
import { FormControlLabel, Checkbox, Collapse } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import { getCustomers } from "../../../store/StudentAndCustomer/actions";
import AdvancedStudentSelectionAlt from "./AdvancedStudentSelectionAlt";
import AdvancedHostSelectionAlt from "./AdvancedHostSelectionAlt";
import AdvancedHostSelection from "./AdvancedHostSelection";
import { sessionPrivileges } from "privileges";
import Alert from "@material-ui/lab/Alert";
import { useGetCustomers } from "hooks/useGetCustomers";
import { useHistory } from "react-router";
import { useGetCourse } from "../hooks/useCourse";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import { parseRawStudentData } from "./../../utils/ParseRawStudentData";
import { parseRawHostData } from "./../../utils/ParseRawHostData";
import Apihelper from "../../../api/apiHelper";
import api from "../../../api/baseConfig";

const CustomCheckbox = withStyles({
  root: {
    color: "#794df5",
    "&$checked": {
      color: "#794df5",
    },
  },
})(Checkbox);

const CustomFormLabel = withStyles({
  label: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "0.9375rem",
    lineHeight: "1.375rem",
    color: "#323036",
  },
})(FormControlLabel);

const CloudLabForm = (props) => {
  const Priv = sessionPrivileges().userprivileges || [];
  const userPrivileges = sessionPrivileges();
  const [formValue, setFormValue] = useState("");
  const [formValue1, setFormValue1] = useState("");
  const [data, setData] = useState([]);
  const [meetingId, setMeetingId] = useState("");
  const [cloudLabPin, setCloudLabPin] = useState("");
  const [customerStudents, setCustomerStudents] = useState([]);
  const [customerHost, setCustomerHost] = useState([]);
  const allCustomers = useGetCustomers();
  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(
      cloudLabFormValidation(
        props?.location?.state?.fromPrepare,
        props?.location?.state?.eDate
      )
    ),
  });
  const [customerId, setCustomerId] = useState(() =>
    Priv?.includes("MANAGE_CUSTOMER")
      ? props?.location?.params?.customerId || ""
      : sessionStorage.getItem("customer_id") || ""
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
  } = methods;

  // const { register, formState: { errors }, control, handleSubmit, reset, watch } = useForm();

  const hasId = useParams();
  const history = useHistory();
  const courseData = useGetCourse(hasId?.id);

  useEffect(() => {
    if (courseData?.meetingName || formValue?.meetingName) {
      const value = courseData?.meetingName ? courseData : formValue;
      reset({
        meetingName: value?.meetingName ?? "",
        description: value?.description ?? "",
        startDate: value?.startDate ?? "",
        duration: value?.duration ?? 0,
        maxVideoScreen: value?.maxVideoScreen ?? 0,
        joinSecurity: value?.joinSecurity ?? "",
        joinMeeting: value?.joinMeeting ?? "",
        cloudLabPin: value?.cloudLabPin ?? "",
        participantEdit: value?.participantEdit ?? "",
        quizz: value?.quizz ?? "",
        shareScreen: value?.shareScreen ?? "",
        viewAfterMeeting: value?.viewAfterMeeting ?? "",
        hostUsers: value.hostUsers ?? [],
        leaveInMid: value.leaveInMid ?? "",
        askDoubt: value.askDoubt ?? "",
        canvas: value.canvas ?? "",
        chat: value.chat ?? "",
        codeEditor: value.codeEditor ?? "",
        participantUsers: value.participantUsers ?? [],
        muteOnEntry: value.muteOnEntry ?? "",
        customerId: value.customerId ?? "",
        meetingId: courseData?.meetingId ?? "",
        meetingUrl: courseData?.meetingUrl ?? "",
        id: value?.id,
      });
    } else if (props?.location?.state?.fromPrepare) {
      const value = props?.location?.state;
      reset({
        customerId: value.customerId ?? "",
        participantUsers: value.participantUsers ?? [],
        joinSecurity: "enrolledUser",
      });
    }
  }, [reset, courseData]);

  useEffect(() => {
    var min = 100000;
    var max = 900000;
    const num = setCloudLabPin(Math.floor(Math.random() * min) + max);
  }, []);
  const cloudLabLink = `https://cloud-lab.terv.pro/`;

  const onSubmit = async (data) => {
    try {
      const result = await CreateOrUpdate({
        ...data,
        startDate: moment(data.startDate).format("yyyy-MM-DDTHH:mm:ss.SSS"),
        ...(customerId && { customerId }),
        cloudLabPin: data?.cloudLabPin ? data.cloudLabPin : cloudLabPin,
        createdInPrepare: props?.location?.state?.fromPrepare ? true : false,
      });

      if (!props?.location?.state?.fromPrepare) {
        history.push({
          pathname: "/cloudLab",
          state: {
            state: props?.location?.state?.customerId,
          },
        });
      }
      if (props?.location?.state?.fromPrepare) {
        let lesson = props?.location?.state?.currentLesson;
        lesson.externalResourceName = result.meetingName;
        lesson.externalResourceId = result.id;
        lesson.externalResource_isCreatedInPrepare = result.createdInPrepare;
        history.push({
          pathname: `/manage-prepare/${props?.location?.state?.courseId}`,
          state: {
            customerId: props?.location?.state?.customerId,
            activeTab: 2,
            currentStep: "editLesson",
            // prepPracticeId: result?.id,
            // prepPracticeRes: result,
            // selSec: props?.location?.state?.selSec,
            sectionValues: props?.location?.state?.sectionValues,
            createdFromPrepare: true,
            currentLesson: lesson,
            currentSection: props?.location?.state?.currentSection,
            contentName: props?.location?.state?.contentName,
          },
        });
      }
      // setCourseId(res.id);
    } catch (error) {
      console.log(`something went error ${error}`);
    }
  };

  if (courseData.cloudLabPin) {
    ("");
  } else {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const length = 12;
    let meeting = "";
    for (let i = 0; i < length; i++) {
      if (!meetingId) {
        const randomNum = Math.floor(Math.random() * characters.length);
        setMeetingId((meeting += characters[randomNum]));
      }
    }
  }

  const date = moment(formValue1).format("lll");
  const dateEdit = moment(courseData?.startDate).format("lll");

  const nameHandle = (e) => {
    setFormValue(e.target.value);
  };

  const dateHandle = (e) => {
    setFormValue1(e.target.value);
  };

  useEffect(() => {
    getCustomerStudents(customerId);
    getCustomerHost(customerId);
}, []);

const getCustomerHost = async (id) => {
  const customerId = userPrivileges.customerId;
  const allUsers = await GetAllUsers(customerId);
  setCustomerHost(parseRawHostData(allUsers));
};

const getCustomerStudents = async (id) => {
  const customerId = userPrivileges.customerId;
  const allUsers = await GetAllUsers(customerId);
  setCustomerStudents(parseRawStudentData(allUsers));
  if (customerStudents) {
    setData(customerStudents.map((datum) => ({
      label: datum?.batchName,
      value: datum?.batchName,
    }))
    );
  }
};

  return (
    <>
    {userPrivileges.isSuperAdmin && (
    <div
      className="pl-5 pr-5 pb-5"
      style={{ backgroundColor: "#F4F7FA", paddingTop: "5rem" }}
    >
      <p className="headerForm pl-2">Create a new meeting</p>
      <form className="manage-prepare__form" onSubmit={handleSubmit(onSubmit)}>
        <FormLayout
          description="Enter meeting name, Description, date time and duration"
          title="Meeting details"
        >
          <div className="terv-row">
            <div>
              <label className="terv-label">
                {RequiredIndicator("Meeting Name")}
              </label>
              <input
                {...register("meetingName", { required: "Please enter a Meeting Name" })}
                className="terv-form terv-form-lg"
                onChange={nameHandle}
              />
              {errors.meetingName && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.meetingName.message || ""}
                  maxWidth="12.5rem"
                />
              )}
            </div>
          </div>
          <div className="terv-row">
            <div>
              <label className="terv-label">
                {RequiredIndicator("Description ")}
              </label>
              <textarea
                {...register("description", { required: "Please enter a description" })}
                className="terv-form terv-form-lg"
                style={{ height: "100px" }}
              />
              {errors.description && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.description.message || ""}
                  maxWidth="12.5rem"
                />
              )}
            </div>
          </div>
          <div className="terv-row">
            <div>
              <label className="terv-label">
                {RequiredIndicator("Start date")}
              </label>
              <input
                type="datetime-local"
                {...register("startDate", { required: "Please enter a valid start date" })}
                className="terv-form terv-form-md"
                onChange={dateHandle}
              />
              {errors.startDate && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.startDate.message || ""}
                  maxWidth="12.5rem"
                />
              )}
            </div>
          </div>
          <div className="terv-row">
            <div>
              <label className="terv-label">
                {RequiredIndicator("Duration (in min)")}
              </label>
              <div style={{ display: "flex" }}>
                <Controller
                  name="duration"
                  control={control}
                  rules={{ required: "Please enter a valid duration"}}
                  disableNegative
                  render={({ field: { onChange, value, onBlur } }) => (
                    <StyledNumberInput
                      onChange={onChange}
                      onBlur={onBlur}
                      value={+value || 0}
                      className="terv-form-with-icon"
                    />
                  )}
                />
                <label style={{ padding: "11px 15px" }}>
                  Max limit is 60min
                </label>
              </div>
              {errors.duration && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.duration.message || ""}
                  maxWidth="12.5rem"
                />
              )}
            </div>
          </div>
        </FormLayout>
        <FormLayout
          title="Security"
          description="Enter security options for the meeting"
        >
          <div className="terv-row">
            <div>
              <label className="terv-label">
                {RequiredIndicator(
                  "Maximum no of participants video screening at a time"
                )}
              </label>
              <div style={{ display: "flex" }}>
                <Controller
                  name="maxVideoScreen"
                  control={control}
                  rules={{ required: "Please enter a valid no of video screening"}}
                  disableNegative
                  render={({ field: { onChange, value, onBlur } }) => (
                    <StyledNumberInput
                      onChange={onChange}
                      onBlur={onBlur}
                      value={+value || 0}
                      className="terv-form-with-icon"
                    />
                  )}
                />
                <label style={{ padding: "11px 15px" }}>Max limit is 5</label>
              </div>
              {errors.maxVideoScreen && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.maxVideoScreen.message || ""}
                  maxWidth="12.5rem"
                />
              )}
            </div>
          </div>
          {!props?.location?.state?.fromPrepare && (
            <div>
              <label className="terv-label">Security</label>
              <div className="terv-row">
              <div style={{paddingTop:'0.8%'}}>
              <input
                  value="pinUser"
                  name="joinSecurity"
                  {...register("joinSecurity")}
                  type="radio"
                />
              </div>
              <div style={{fontSize:"15px"}}>
              Only users who have meeting pin can join the meeting{" "}
              </div>
            </div>

           <div className="terv-row">
           <div style={{paddingTop:'0.8%'}}>
           <input
                  value="enrolledUser"
                  name="joinSecurity"
                  {...register("joinSecurity")}
                  type="radio"
                />
           </div>
           <div style={{fontSize:"15px"}}>
           Only users enrolled by host can join meeting{" "}
           </div>
           </div>

           </div>
          )}
        </FormLayout>
        <FormLayout
          description="Choose the features you want for this meeting"
          title="Features"
        >
          <Controller
            name="shareScreen"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomFormLabel
                control={
                  <CustomCheckbox
                    name="shareScreen"
                    color="default"
                    checked={!!value}
                    onChange={(_, c) => onChange(c)}
                  />
                }
                label="Share screen"
              />
            )}
          />
          <Controller
            name="codeEditor"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomFormLabel
                control={
                  <CustomCheckbox
                    name="codeEditor"
                    color="default"
                    checked={!!value}
                    onChange={(_, c) => onChange(c)}
                  />
                }
                label="Code editor"
              />
            )}
          />
          <Controller
            name="canvas"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomFormLabel
                control={
                  <CustomCheckbox
                    name="canvas"
                    color="default"
                    checked={!!value}
                    onChange={(_, c) => onChange(c)}
                  />
                }
                label="Canvas"
              />
            )}
          />
          <Controller
            name="quizz"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomFormLabel
                control={
                  <CustomCheckbox
                    name="quizz"
                    color="default"
                    checked={!!value}
                    onChange={(_, c) => onChange(c)}
                  />
                }
                label="Quizz"
              />
            )}
          />
          <Controller
            name="chat"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomFormLabel
                control={
                  <CustomCheckbox
                    name="chat"
                    color="default"
                    checked={!!value}
                    onChange={(_, c) => onChange(c)}
                  />
                }
                label="chat"
              />
            )}
          />
        </FormLayout>
        <FormLayout
          description="Choose a user who can host this meeting"
          title="Choose Host"
        >
          <div className="terv-row">
            {/* {sessionPrivileges.isSuperAdmin &&
              !props?.location?.state?.fromPrepare && ( */}
                <div>
                  <label className="terv-label">
                    {RequiredIndicator("Select Customer")}
                  </label>
                  <Controller
                    name="customerId"
                    control={control}
                    rules={{ required: "Please select a customer"}}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        onChange={(val) => onChange(val.value)}
                        value={GetObjectFromString(allCustomers, value)}
                        styles={ReactSelectTervStyle("md")}
                        options={allCustomers}
                      />
                    )}
                  />
                  {errors.customerId && (
                    <ErrorBlock
                      eType="div"
                      errorText={errors.customerId.message || ""}
                      maxWidth="12.5rem"
                    />
                  )}
                </div>
              {/* )} */}
          </div>
          <div>
            <label className="terv-label">
              {RequiredIndicator("Select Host")}
            </label>
            {/* {sessionPrivileges.isSuperAdmin ? (
              <div>
                <AdvancedHostSelectionAlt
                  customerId={watch("customerId")}
                  hookFormControls={methods}
                  viewOnly={false}
                  studentFieldName="hostUsers"
                />
              </div>
            ) : (
              <div>
                <AdvancedHostSelection
                  customerId={sessionStorage.getItem("customer_id")}
                  hookFormControls={methods}
                  viewOnly={false}
                  studentFieldName="hostUsers"
                />
              </div>
            )} */}
              <Controller
          name="hostUsers"
          rules={{ required: "Please select atleast one host" }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomSelectHost
              options={customerHost}
              onChange={(val) => onChange([val.value])}
              value={GetObjectArray(customerHost, value)}
              styles={ReactSelectTervStyle}
            />
          )}
        />
            {errors.hostUsers && (
              <ErrorBlock
                eType="div"
                errorText={errors.hostUsers.message || ""}
                maxWidth="12.5rem"
              />
            )}
          </div>
          <div style={{ paddingTop: "10px", width: "350px" }}>
            <Alert severity="warning">
              Only one person can be selected as host !
            </Alert>
          </div>
        </FormLayout>
        <FormLayout
          description="Choose the participants who can attend this meeting"
          title="Choose participants"
        >
          {props?.location?.state?.fromPrepare ? (
            <div style={{ paddingTop: "10px", width: "350px" }}>
              <Alert severity="warning">
                Students enrolled in this course can participate
              </Alert>
            </div>
          ) : (
            <>
              <label className="terv-label">
                {RequiredIndicator("Select Student")}
              </label>    
              <Controller
    name="participantUsers"
    control={control}
    rules={{ required: "Please select a students"}}
    render={({ field: { onChange, value } }) => (
      <CustomSelect
      inputId="participantUsers"
      options={customerStudents}
      isMulti
      onChange={(val) =>
        onChange(val ? val.map((it) => it.value) : [])
      }
      value={GetObjectArray(customerStudents, value)}
      styles={ReactSelectTervStyle("lg", true)}
      maxMenuHeight={200}
      maxWidth={200}
      isSearchable
    />
    )}
  />
              {/* <AdvancedStudentSelectionAlt
                customerId={
                  userPrivileges.isSuperAdmin
                    ? watch("customerId")
                    : sessionStorage.getItem("customer_id")
                }
                hookFormControls={methods}
                viewOnly={false}
                studentFieldName="participantUsers"
              /> */}
              {errors.participantUsers && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.participantUsers.message || ""}
                  maxWidth="12.5rem"
                />
              )}
            </>
          )}
        </FormLayout>
        <FormLayout
          title="Settings"
          description="Select the setting you want for this meeting"
        >
          <div className="terv-row">
            <div>
              <label className="terv-label" style={{ color: "#63606C" }}>
                PARTICIPANTS JOINING
              </label>
            </div>
          </div>
          <div className="terv-row">
            <div style={{paddingTop:'0.8%'}}>
              <input
                value="anyTime"
                name="joinMeeting"
                {...register("joinMeeting")}
                type="radio"
              />
            </div>
            <div style={{fontSize:"15px"}}>
            Allow participants to join any time{" "}
            </div>
          </div>

          <div className="terv-row">
            <div style={{paddingTop:'0.8%'}}>
              <input
                value="beforeStart"
                name="joinMeeting"
                {...register("joinMeeting")}
                type="radio"
              />
            </div>
            <div style={{fontSize:"15px"}}>
            Participants must enter before meeting starts{" "}
            </div>
          </div>
          <Controller
            name="muteOnEntry"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomFormLabel
                control={
                  <CustomCheckbox
                    name="muteOnEntry"
                    color="default"
                    checked={!!value}
                    onChange={(_, c) => onChange(c)}
                  />
                }
                label="Mute audio and video of participants upon entry"
              />
            )}
          />
          <Controller
            name="participantEdit"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomFormLabel
                control={
                  <CustomCheckbox
                    name="participantEdit"
                    color="default"
                    checked={!!value}
                    onChange={(_, c) => onChange(c)}
                  />
                }
                label="Host can allow participants to collaborate on canvas, code editor"
              />
            )}
          />
          <Controller
            name="leaveInMid"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomFormLabel
                control={
                  <CustomCheckbox
                    name="leaveInMid"
                    color="default"
                    checked={!!value}
                    onChange={(_, c) => onChange(c)}
                  />
                }
                label="Participants can leave the meeting in the middle and can re-enter"
              />
            )}
          />
          <Controller
            name="askDoubt"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomFormLabel
                control={
                  <CustomCheckbox
                    name="askDoubt"
                    color="default"
                    checked={!!value}
                    onChange={(_, c) => onChange(c)}
                  />
                }
                label="Participants can share image in chats and ask doubts/questions"
              />
            )}
          />
          <Controller
            name="viewAfterMeeting"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomFormLabel
                control={
                  <CustomCheckbox
                    name="viewAfterMeeting"
                    color="default"
                    checked={!!value}
                    onChange={(_, c) => onChange(c)}
                  />
                }
                label="Host can see chats, quizz results, saved code, saved canvas images after meeting"
              />
            )}
          />
        </FormLayout>
        <FormLayout
          title="Cloud lab pin"
          description="Copy this cloud lab pin , and share to participants"
        >
          <div>
            {courseData?.meetingId ? (
              <div className="terv-row">
                <div>
                  <div className="terv-form">
                    <div style={{ paddingTop: "10px" }}>
                      {cloudLabLink}
                      <input
                        className="terv-form-md"
                        {...register("meetingId")}
                        style={{ border: "none", backgroundColor: "#FAFBFC" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="terv-row">
                <div>
                  <div className="terv-form">
                    <div style={{ paddingTop: "10px" }}>
                      {cloudLabLink}
                      <input
                        {...register("meetingId")}
                        value={meetingId}
                        style={{ border: "none", backgroundColor: "#FAFBFC" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {courseData?.cloudLabPin ? (
              <div className="terv-row">
                <div>
                  <div className="terv-form">
                    <div style={{ paddingTop: "10px" }}>
                      <input
                        {...register("cloudLabPin")}
                        style={{ border: "none", backgroundColor: "#FAFBFC" }}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <CopyToClipboard
                      text={`
                  meetingName: ${
                    formValue ? formValue : courseData?.meetingName
                  }
                  date: ${formValue1 ? date : dateEdit}
                  pin : ${courseData?.cloudLabPin}
                  meeting url: ${cloudLabLink}${courseData?.meetingId}
                  `}
                    >
                      <label style={{ cursor: "pointer" }}>
                        <p style={{ color: "#2170D8", paddingTop: "10px" }}>
                          <span style={{ paddingRight: "10px" }}>
                            {" "}
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.3333 0.333313H5.66659C4.93125 0.333313 4.33325 0.931313 4.33325 1.66665V4.33331H1.66659C0.931252 4.33331 0.333252 4.93131 0.333252 5.66665V12.3333C0.333252 13.0686 0.931252 13.6666 1.66659 13.6666H8.33325C9.06859 13.6666 9.66659 13.0686 9.66659 12.3333V9.66665H12.3333C13.0686 9.66665 13.6666 9.06865 13.6666 8.33331V1.66665C13.6666 0.931313 13.0686 0.333313 12.3333 0.333313ZM1.66659 12.3333V5.66665H8.33325L8.33459 12.3333H1.66659ZM12.3333 8.33331H9.66659V5.66665C9.66659 4.93131 9.06859 4.33331 8.33325 4.33331H5.66659V1.66665H12.3333V8.33331Z"
                                fill="#2170D8"
                              />
                            </svg>
                          </span>
                          Copy meeting link
                        </p>
                      </label>
                    </CopyToClipboard>
                    <label>Copy this pin and share this to participants</label>
                  </div>
                </div>
              </div>
            ) : (
              <div className="terv-row">
                <div>
                  <div className="terv-form">
                    <div style={{ paddingTop: "10px" }}>
                      <input
                        value={cloudLabPin}
                        {...register("cloudLabPin")}
                        style={{ border: "none", backgroundColor: "#FAFBFC" }}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <CopyToClipboard
                      text={`
                Meeting Name: ${formValue}
                Date: ${date}
                Pin : ${cloudLabPin}
                meeting url: ${cloudLabLink}${meetingId}
                `}
                    >
                      <label style={{ cursor: "pointer" }}>
                        <p style={{ color: "#2170D8", paddingTop: "10px" }}>
                          <span style={{ paddingRight: "10px" }}>
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.3333 0.333313H5.66659C4.93125 0.333313 4.33325 0.931313 4.33325 1.66665V4.33331H1.66659C0.931252 4.33331 0.333252 4.93131 0.333252 5.66665V12.3333C0.333252 13.0686 0.931252 13.6666 1.66659 13.6666H8.33325C9.06859 13.6666 9.66659 13.0686 9.66659 12.3333V9.66665H12.3333C13.0686 9.66665 13.6666 9.06865 13.6666 8.33331V1.66665C13.6666 0.931313 13.0686 0.333313 12.3333 0.333313ZM1.66659 12.3333V5.66665H8.33325L8.33459 12.3333H1.66659ZM12.3333 8.33331H9.66659V5.66665C9.66659 4.93131 9.06859 4.33331 8.33325 4.33331H5.66659V1.66665H12.3333V8.33331Z"
                                fill="#2170D8"
                              />
                            </svg>
                          </span>
                          Copy meeting link
                        </p>
                      </label>
                    </CopyToClipboard>
                    <label>Copy this pin and share this to participants</label>
                  </div>
                </div>
              </div>
            )}
          </div>
        </FormLayout>
        <FormLayout hideDivider>
          <div style={{ display: "flex" }}>
            <TervButtonFilled
              text="Create"
              type="submit"
              width="6.125rem"
              // disabled={isSubmitting}
            />
            <div style={{ paddingLeft: "15px" }}>
              <a href="/cloudLab">
                <TervButtonFilled
                  text="Cancel"
                  type="button"
                  width="6.125rem"
                />
              </a>
            </div>
          </div>
        </FormLayout>
      </form>
    </div>
    )}
    </>
  );
};

export default CloudLabForm;