import { useState, useEffect, createRef } from "react";
import { Tabs } from "components/tabs/tabs";
import Select from "react-select";
import Pagination from "react-js-pagination";
import { paginationClasses } from "../utils/constants";
import { ToastContainer } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import {
  TimeBX,
  CourseChapters,
  penIcon,
  trashFilled,
  penFill2,
  hourGlass,
  approved,
  circleCrossIcon,
  fileReportOne,
  viewIcon,
} from "assets/icons/icons";
import CardsViewHeader from "components/CardsViewHeader";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { ReactSelectTervStyle } from "pages/utils/ReactSelectTervStyle";
import styles from "./AllCoursesDumb.module.scss";
import { Link, useHistory } from "react-router-dom";
import { uid } from "react-uid";
import cn from "classnames";
import { CapitalizeFirstLetter } from "pages/utils/CapitalizeFirstLetter";
import { statusValues } from "./Content";
import { Slide } from "@material-ui/core";
import AnimatedMount from "./AnimatedMount";
import CourseModal from "./CourseModal";
import { sessionPrivileges } from "privileges";
import Apihelper from "api/apiHelper";
import api from "api/baseConfig";

const CardButtons = ({
  id,
  name,
  toggleDeleteModal,
  toggleStatusModal,
  setDeleteCourseId,
  status,
  activeTab,
  customerId,
  userId,
  privileges,
  isCourseModal,
  handleCourseModal,
  CourseModalData,
  handleCourseModalData,
}) => {
  const history = useHistory();

  const getSummary = async (data) => {
    await Apihelper.axiosCall(
      `${api.baseURL}${api.Prepare.getCourse}${data}?userId=${userId}`,
      "get"
    )
      .then((resp) => {
        handleCourseModal(true);
        handleCourseModalData(resp);
      })
      .catch(function () {});
  };

  return (
    <div className={styles.cardButtons}>
      <Tooltip title="View Course" arrow placement="top-start">
        <a
          onClick={() => {
            getSummary(id);
          }}
        >
          <div>{viewIcon("#784df4")}</div>
        </a>
      </Tooltip>
      {status === "draft" && (
        <>
          {privileges.includes("EDIT_PREPARE") && (
            <Tooltip title="Edit Course" arrow placement="top-start">
              <Link
                to={{
                  pathname: `/manage-prepare/${id}`,
                  state: { fromEdit: true, customerId },
                }}
              >
                <div>{penIcon("#784df4")}</div>
              </Link>
            </Tooltip>
          )}
          {privileges.includes("DELETE_PREPARE") && (
            <Tooltip title="Delete Course" arrow placement="top-start">
              <div
                onClick={() => {
                  setDeleteCourseId(id);
                  toggleDeleteModal();
                }}
              >
                {trashFilled}
              </div>
            </Tooltip>
          )}
        </>
      )}

      {status == "published" || status == "draft" || status == "rejected" || status == "pending"
        ? privileges.includes("APPROVE_PREPARE") && (
            <Tooltip
              title={
                statusValues[status === "published" ? "approved" : status]
                  .tooltip
              }
              arrow
              placement="top-start"
            >
              <div
                onClick={() => {
                  toggleStatusModal({
                    id,
                    status:
                      statusValues[status === "published" ? "approved" : status]
                        .statusTo,
                    name,
                  });
                }}
              >
                {
                  statusValues[status === "published" ? "approved" : status]
                    .moveToIcon
                }
              </div>
            </Tooltip>
          )
        : ""}
      {status == "pending"
        ? privileges.includes("APPROVE_PREPARE") && (
            <Tooltip
              title={
                statusValues[status === "published" ? "reject" : "reject"]
                  .tooltip
              }
              arrow
              placement="top-start"
            >
              <div
                onClick={() => {
                  toggleStatusModal({
                    id,
                    status:
                      statusValues[status === "published" ? "reject" : "reject"]
                        .statusTo,
                    name,
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ionicon"
                  viewBox="0 0 512 512"
                >
                  <title>Ban</title>
                  <circle
                    cx="256"
                    cy="256"
                    r="208"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeWidth="32"
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeWidth="32"
                    d="M108.92 108.92l294.16 294.16"
                  />
                </svg>
              </div>
            </Tooltip>
          )
        : ""}
      {status == "published"
        ? privileges.includes("VIEW_PREPARE_REPORT") && (
            <Tooltip title="Report" arrow placement="top-start">
              <div
                onClick={() => {
                  history.push({
                    pathname: "/prepare-report",
                    state: {
                      id: id,
                      customerId: customerId,
                    },
                  });
                }}
              >
                {fileReportOne}
              </div>
            </Tooltip>
          )
        : ""}
    </div>
  );
};

const CourseCard = ({
  details: { name, id, media, numberOfModules, duration, status },
  key,
  toggleDeleteModal,
  toggleStatusModal,
  setDeleteCourseId,
  activeTab,
  customerId,
  userId,
  privileges,
  isCourseModal,
  handleCourseModal,
  CourseModalData,
  handleCourseModalData,
}) => (
  <div className={styles.courseCard} key={key}>
    {activeTab === "all" && (
      <span
        className={cn([styles.tag], {
          [styles.tagAP]: status === "published" || status === "approved",
          [styles.tagDF]: status === "draft",
          [styles.tagPN]: status === "pending",
          [styles.tagRJ]: status === "rejected",
        })}
      >
        {status === "published" || status === "approved"
          ? approved({
              size: 16,
              color: "#fff",
            })
          : status === "rejected"
          ? circleCrossIcon
          : status === "pending"
          ? hourGlass
          : status === "draft"
          ? penFill2({
              size: 16,
              color: "#fff",
            })
          : ""}
        &nbsp;
        {CapitalizeFirstLetter(status === "published" ? "approved" : status)}
      </span>
    )}
    <div className={styles.courseCardImg}>
      <div className={styles.courseCardBgImg}>
        <img src={media} alt="img" />
      </div>
    </div>
    <div className={styles.courseCardContent}>
      <div>
        <p className={styles.courseCardName}>{name}</p>
        <div className={styles.courseCardDetails}>
          {numberOfModules > 1 ? (
          <span>
            <CourseChapters size={{ height: 12, width: 16 }} color="#7C7887" />
            &nbsp;{numberOfModules} Chapters
          </span>
          ):(
          <span>
            <CourseChapters size={{ height: 12, width: 16 }} color="#7C7887" />
            &nbsp;{numberOfModules} Chapter
          </span>
          )}
          {duration > 1 ? (
          <span>
            <TimeBX size="16" color="#7C7887" />
            &nbsp;{duration} Hours
          </span>
          ):(
            <span>
            <TimeBX size="16" color="#7C7887" />
            &nbsp;{duration} Hour
          </span>
          )}
        </div>
      </div>

      <CardButtons
        id={id}
        name={name}
        setDeleteCourseId={setDeleteCourseId}
        toggleDeleteModal={toggleDeleteModal}
        toggleStatusModal={toggleStatusModal}
        status={status}
        activeTab={activeTab}
        customerId={customerId}
        userId={userId}
        privileges={privileges}
        isCourseModal={isCourseModal}
        handleCourseModal={handleCourseModal}
        CourseModalData={CourseModalData}
        handleCourseModalData={handleCourseModalData}
      />
    </div>
  </div>
);

const AllCoursesDumb = ({
  setActiveTab,
  activeTab,
  allCustomers,
  customerId,
  courses,
  handleCustomerChange,
  tabs,
  firstIndex,
  lastIndex,
  toggleDeleteModal,
  toggleStatusModal,
  setDeleteCourseId,
  activePage,
  handlePageChange,
  setCourses,
  customerName,
  setCustomerName,
}) => {
  const courseModaRef = createRef(null);
  const [courseSearch, setCourseSearch] = useState("");
  const [isCourseModal, setIsCourseModal] = useState(false);
  const [CourseModalData, setCourseModalData] = useState({});
  const handleCourseModal = (state) => {
    setIsCourseModal(state);
  };
  const handleCourseModalData = (data) => {
    setCourseModalData(data);
  };
  const userPrivileges = sessionPrivileges();
  const userId = userPrivileges.userId;
  const privileges = userPrivileges.userprivileges || [];
  const handleSearch = (query) => {
    let newList = [];
    if (query) {
      courses.modify[activeTab].forEach((e) => {
        if (e.name.toLowerCase().includes(query.toLowerCase())) {
          newList.push(e);
        }
      });
      setCourses({
        ...courses,
        modify:
          query?.length >= 2
            ? newList?.length > 0
              ? { ...courses?.modify, [activeTab]: newList }
              : ""
            : courses?.real,
      });
    } else {
      setCourses({
        ...courses,
        modify: courses?.real,
      });
    }
  };
  const activeSearch = () => {
    setActiveTab("all");
  };
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (courseSearch)
  //       setCourses(
  //         courses.real[activeTab].reduce((acc, val) => {
  //           if (val.name.toLowerCase().includes(courseSearch)) acc.push(val);
  //           return acc;
  //         }, [])
  //       );
  //   }, [500]);
  // }, [courseSearch]);
  return (
    <>
      <ToastContainer />
      <CourseModal
        ref={courseModaRef}
        isOpen={isCourseModal}
        toggle={handleCourseModal}
        data={CourseModalData}
      />
      <div className="page-content">
        <CardsViewHeader
          header="Prepare Management"
          handleSearch={handleSearch}
          handleClick={activeSearch}
          createLink={{
            pathname: "manage-prepare",
            state: { customerId, customerName },
          }}
        />
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 18,
              zIndex: 12,
            }}
          >
            {userPrivileges.isSuperAdmin && (
              <Select
                options={allCustomers}
                onChange={(e) => {
                  handleCustomerChange(e.value);
                  setCustomerName(e.label);
                }}
                value={GetObjectFromString(allCustomers, customerId)}
                styles={ReactSelectTervStyle("md")}
              />
            )}
          </div>

          <Tabs onChange={setActiveTab} activeTab={activeTab} tabs={tabs}>
            <AnimatedMount>
              <div
                className={styles.wrappedCards}
                style={{ paddingTop: "32px" }}
              >
                {courses?.modify[activeTab] ? (
                  courses?.modify[activeTab]
                    ?.slice(firstIndex, lastIndex)
                    .map((item, index) => (
                      <div>
                        <CourseCard
                          details={item}
                          key={uid(index)}
                          toggleDeleteModal={toggleDeleteModal}
                          toggleStatusModal={toggleStatusModal}
                          setDeleteCourseId={setDeleteCourseId}
                          activeTab={activeTab}
                          customerId={customerId}
                          userId={userId}
                          privileges={privileges}
                          isCourseModal={isCourseModal}
                          handleCourseModal={handleCourseModal}
                          CourseModalData={CourseModalData}
                          handleCourseModalData={handleCourseModalData}
                        />
                      </div>
                    ))
                ) : (
                  <div>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      No Prepare package Found
                    </p>
                  </div>
                )}
              </div>
            </AnimatedMount>
            {courses?.real[activeTab]?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5rem",
                }}
              >
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={6}
                  totalItemsCount={courses?.modify[activeTab]?.length}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  {...paginationClasses}
                />
              </div>
            )}
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default AllCoursesDumb;
