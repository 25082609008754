import React, { useState } from "react";
import cn from "classnames";
import { Grid, MenuItem } from "@material-ui/core";
import styles from "./tabsMobile.module.scss";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));
export function MobileTabs({
  actions,
  activeTab,
  children,
  className,
  contentClassName,
  filterMenu,
  filterMenuIsVisible,
  filterMenuWidth,
  green,
  hideBadge,
  noChildren = false,
  onChange,
  tabs,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dropdownName, setDropdownName] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event) => {
    setDropdownName(event.target.value);
  };

  return (
    <div
      className={cn(styles.tabs, className, {
        [styles.noChildren]: !!noChildren,
        [styles.purple]: !green,
        [styles.green]: green,
      })}
    >
      <Select
        onChange={handleChange}
        className={styles.tabButton}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        defaultValue={activeTab}
      >
        <MenuItem defaultValue="" disabled>
          Select Practice Tab
        </MenuItem>
        {tabs.map(({ name, badge, id }) => (
          <MenuItem
            defaultValue=""
            placeholder={name}
            label={name}
            key={id}
            value={id || "Active"}
            onClick={() => {
              onChange(id);
            }}
          >
            <span className={styles.text}>{name}</span>
            {!hideBadge && <span className={styles.badge}>{badge || 0}</span>}
          </MenuItem>
        ))}
      </Select>

      {!noChildren && (
        <div className={cn(styles.content, contentClassName)}>
          <Grid direction="row" wrap="nowrap" container>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                width: "100%",
              }}
            >
              {children}
            </div>
            {filterMenu && (
              <div
                className={cn(styles.filterMenu)}
                style={{
                  padding: `8px ${filterMenuIsVisible ? 8 : 0}px`,
                  width: filterMenuIsVisible ? filterMenuWidth + 16 : 0,
                }}
              >
                {filterMenu}
              </div>
            )}
          </Grid>
        </div>
      )}
      <style jsx="true" global="true">
        {`
          .dropbtn {
            background-color: #4caf50;
            color: white;
            padding: 16px;
            font-size: 16px;
            border: none;
            cursor: pointer;
          }

          .dropdown {
            position: relative;
            display: inline-block;
          }

          .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
          }

          .dropdown-content a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
          }

          .dropdown-content a:hover {
            background-color: #f1f1f1;
          }

          .dropdown:hover .dropdown-content {
            display: block;
          }

          .dropdown:hover .dropbtn {
            background-color: #3e8e41;
          }
        `}
      </style>
    </div>
  );
}

{
  /* <Select            
            onChange={handleChange}
            className={styles.tabButton}
            displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="" disabled>
              Placeholder
            </MenuItem>
            {tabs.map(({ name, badge, id }) => (
            <MenuItem placeholder={name} label={name} key={id} value={name || 'Active'}
            onClick={() => {
              onChange(id); 
            }}>
            <span className={styles.text}>{name}</span>
            {!hideBadge && <span className={styles.badge}>{badge || 0}</span>}
            </MenuItem>
             ))}
          </Select> */
}
