import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  withStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { CloseOutlined } from "@material-ui/icons";

const CustomHeader = withStyles({
  root: {
    backgroundColor: "#F9F9F9",
    width: "37.5rem",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #C4C4C4",
    "& > p": {
      margin: "0 auto",
      fontWeight: 500,
      fontSize: "1.125rem",
      lineHeight: "1.6875rem",
      textAlign: "center",
      color: "#323036",
    },
    "&  svg": {
      cursor: "pointer",
    },
  },
})(DialogTitle);

const CustomContent = withStyles({
  root: {
    overflow: "hidden",
    overflowY: "auto",
    "&.MuiDialogContent-root": {
      overflowY: "auto",
    },
  },
})(DialogContent);

const LessonCreation = ({
  currentLesson,
  currentStep,
  open,
  close,
  children,
}) => {
  useEffect(() => {
    const htmlEle = document.documentElement;
    if(open)
      htmlEle.setAttribute("style", "overflow:hidden");
    return () => {
      htmlEle.removeAttribute("style");
    };
  }, [open]);
  return (
    <Dialog open={open} onClose={close}>
      <CustomHeader disableTypography>
        {currentLesson.type === "Video" ? (
          <p>Add new video quiz</p>
        ) : (
          <p>Add new content</p>
        )}
        <span onClick={close}>
          <CloseOutlined />
        </span>
      </CustomHeader>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
export default LessonCreation;
