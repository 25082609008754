import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@material-ui/icons/Delete";
import { DummyUpload } from "components/DummyUpload";
import { ToastContainer } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SendMediaHof } from "pages/utils/SendMediaHOF";
import styles from "./AddMediaComponent.module.scss";
import { DefaultToast } from "components/DefaultToast";

export const AddMediaComponent = ({
  handleChange,
  value,
  mediaType,
  nameRequired,
  setAttachmentName,
  moduleName,
  customerName,
  currentId,
}) => {
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections && fileRejections.length > 0) {
      DefaultToast({
        message: `${
          fileRejections[0]?.errors?.[0]?.code === "file-too-large"
            ? "File size must be less than 10MB"
            : "Invalid file type"
        }`,
        style: {},
        classes: {
          className: styles.toast__error,
        },
        position: "bottom-start",
      });
    } else if (acceptedFiles && acceptedFiles.length > 0) {
      UploadVideo(acceptedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: mediaType,
    minSize: 0,
    maxFiles: 1,
    maxSize: 10600000,
  });
  console.log(mediaType);

  const UploadVideo = async (video) => {
    if (nameRequired) {
      setAttachmentName(video[0].name);
    }
    setUploading(true);
    const res = await SendMediaHof(video, moduleName, customerName, currentId);
    setUploading(false);
    if (res) {
      handleChange(res);
    }
    DefaultToast({
      message: "Successfully added",
      style: {},
      classes: {
        className: styles.toast__success,
      },
      position: "bottom-start",
    });
  };

  return (
    <>
      <div {...getRootProps()} className={styles.container}>
        {uploading ? (
          <CircularProgress />
        ) : !value ? (
          <>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>Drag 'n' drop some files here, or click to select files</p>
            )}
            <div className={styles.maxSize}>Max size - 10MB</div>
            <DummyUpload />
          </>
        ) : (
          <div className={styles.uploadedStyle}>
            <div>
              <span>
                {value.length > 5 ? `${value.slice(0, 10)}...` : value}
              </span>{" "}
              <span>{value?.match(/\.[0-9a-z]+$/i)?.[0]}</span>
            </div>
            <span onClick={() => handleChange("")}>
              <DeleteIcon style={{ fontSize: 24 }} />
            </span>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};
