export const StudentFilter = (
  datum: { [key: string]: any },
  from: number,
  to: number,
  dept: string,
  len: number
) => {
  if (!datum?.data) return datum;
  if (
    from &&
    to &&
    from.toString().length === len &&
    to.toString().length === len &&
    from.toString() !== to.toString() &&
    datum.data.hasOwnProperty("registrationNo")
  ) {
    return dept
      ? +datum.data.registrationNo >= from &&
          +datum.data.registrationNo <= to &&
          datum.data.dept === dept
      : +datum.data.registrationNo >= from && +datum.data.registrationNo <= to;
  }

  return dept ? datum?.data?.dept === dept : datum;
};
