import React from "react";
import cn from "classnames";
import styles from "./AssessmentListItem.module.scss";
import EditIcon from "../../icons/edit";

function AssessmentListItem({
  hideAction,
  actionButtonText,
  actionButtonVariant,
  onActionButtonClick,
  className,
  percentageCompleted,
  primaryInfo,
  secondaryInfo,
  status,
}) {
  return (
    <div
      className={cn(
        styles.listItem,
        percentageCompleted ? styles.type2 : styles.type1,
        {
          [className]: className,
        }
      )}
    >
      <div className={styles.iconContainer}>
        <EditIcon style={{ fontSize: 24, width: 24 }} />
      </div>
      <div
        className={cn(styles.primaryInfo, {
          [styles.noSecondaryInfo]: !secondaryInfo,
        })}
      >
        {primaryInfo}
      </div>
      {secondaryInfo && (
        <div className={styles.secondaryInfo}>{secondaryInfo}</div>
      )}
      {status && (
        <div className={styles.statusContainer}>
          <div
            className={cn(styles.status, {
              [styles.active]: status === "active",
            })}
          >
            <span>•</span>&nbsp;&nbsp;{status}
          </div>
        </div>
      )}
      {percentageCompleted && (
        <div className={styles.percentageCompleted}>{percentageCompleted}%</div>
      )}
      <div className={styles.actionContainer}>
        {!hideAction && (
          <button
            className={cn(styles.actionButton, {
              [styles.fill]: actionButtonVariant === "fill",
              [styles.outlined]: actionButtonVariant === "outlined",
            })}
            onClick={onActionButtonClick || (() => {})}
          >
            {actionButtonText}
          </button>
        )}
      </div>
    </div>
  );
}

export default AssessmentListItem;
