import { TRIGGER_COMPLETED_TOUR } from "./actionTypes";

export default function TourReducer(
  initialState: boolean,
  action: { type: string; payload: boolean }
) {
  switch (action.type) {
    case TRIGGER_COMPLETED_TOUR: {
      return action.payload;
    }
    default: {
      return false;
    }
  }
}
