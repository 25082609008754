import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Container,
  Label,
  Row,
  Col,
  Input,
  Button,
} from "reactstrap";
import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";
import RichTextEditor from "../../../components/RichTextEditor";
import { sessionPrivileges } from "privileges";

function Configuration(props) {
  const userPrivileges = sessionPrivileges();
  const [name, setName] = useState("");
  const [pageName, setPageName] = useState("");
  const [title, setTitle] = useState("");
  const [accessName, setAccessName] = useState("");
  const [description, setDescription] = useState("");

  const [nameErr, setNameErr] = useState("");
  const [pageErr, setPageErr] = useState("");
  const [titleErr, setTitleErr] = useState("");
  const [accessErr, setAccessErr] = useState("");
  const [descErr, setDescErr] = useState("");

  // const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     const {name, pageName, title, accessName, description} = values;
  //     const configuration = {name, pageName, title, accessName, description};

  //     await Apihelper.axiosCallPost(`${api.baseURL}`,
  //     "post",
  //     body
  //     ).then((data) => {

  //     });
  // };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
    {userPrivileges.isSuperAdmin && (
    <div className="page-content">
      <Container fluid>
        <Card style={{ marginRight: 10 }}>
          <CardBody>
            <form>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 2 }}>
                  <h4>Configuration</h4>
                </div>

                <div style={{ flex: 3 }}>
                  <Row>
                    <Col lg="12" className="mb-4">
                      <Label>
                        <span>
                          Name<span style={{ color: "red" }}>*</span>
                        </span>
                      </Label>
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                        placeholder="Enter Name"
                        onBlur={(e) => {
                          name === ""
                            ? setNameErr("Please enter a valid name")
                            : setNameErr("");
                        }}
                      />
                      {nameErr !== "" ? (
                        <span style={{ color: "red" }}>{nameErr}</span>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col lg="12" className="mb-4">
                      <Label>
                        <span>
                          Page Name<span style={{ color: "red" }}>*</span>
                        </span>
                      </Label>
                      <Input
                        id="pageName"
                        name="pageName"
                        type="text"
                        value={pageName}
                        onChange={(e) => setPageName(e.target.value)}
                        className="form-control"
                        placeholder="Enter Page Name"
                        onBlur={(e) => {
                          pageName === ""
                            ? setPageErr("Please enter a valid page name")
                            : setPageErr("");
                        }}
                      />
                      {pageErr !== "" ? (
                        <span style={{ color: "red" }}>{pageErr}</span>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col lg="12" className="mb-4">
                      <Label>
                        <span>
                          Title<span style={{ color: "red" }}>*</span>
                        </span>
                      </Label>
                      <Input
                        id="title"
                        name="title"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="form-control"
                        placeholder="Enter Title"
                        onBlur={(e) => {
                          title === ""
                            ? setTitleErr("Please enter a valid Title")
                            : setTitleErr("");
                        }}
                      />
                      {titleErr !== "" ? (
                        <span style={{ color: "red" }}>{titleErr}</span>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col lg="12" className="mb-4">
                      <Label>
                        <span>
                          Access Name<span style={{ color: "red" }}>*</span>
                        </span>
                      </Label>
                      <Input
                        id="accessName"
                        name="accessName"
                        type="text"
                        value={accessName}
                        onChange={(e) => setAccessName(e.target.value)}
                        className="form-control"
                        placeholder="Enter Access Name"
                        onBlur={(e) => {
                          accessName === ""
                            ? setAccessErr("Please enter a valid access name")
                            : setAccessErr("");
                        }}
                      />
                      {accessErr !== "" ? (
                        <span style={{ color: "red" }}>{accessErr}</span>
                      ) : (
                        ""
                      )}
                    </Col>

                    <Col lg={12} className="mb-5">
                      <Label>
                        Description<span style={{ color: "red" }}>*</span>
                      </Label>
                      <RichTextEditor
                        value={description}
                        onChange={(value) => {
                          setDescription(value);
                        }}
                        onBlur={(e) => {
                          description === ""
                            ? setDescErr(
                                "Please enter a valid configuration description"
                              )
                            : setDescErr("");
                        }}
                      />
                      {descErr !== "" ? (
                        <span style={{ color: "red" }}>{descErr}</span>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  style={{ width: 150, fontSize: 15 }}
                >
                  {props?.location?.state ? "Update" : "Create"}
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Container>
    </div>
    )}
    </>
  );
}
export default Configuration;
