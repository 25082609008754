import Cookies from "universal-cookie";
import jwt from "jwt-decode";
// import dispachLoggedInState from "./redex";
// import { useDispatch, useSelector } from "react-redux";
// import { handleLoggedIn } from "store/loggedIn/actions";

// const dispachLoggedInState = (headerState) => {
//   const loggedInReducer = useSelector((state) => state.LoggedInReducer);
//   if (headerState.state !== loggedInReducer.state) {
//     const dispatch = useDispatch();
//     dispatch(handleLoggedIn(headerState));
//   }
// };
function getToken() {
  const cookies = new Cookies();
  const userToken = cookies.get("userToken", { path: "/" });
  const decoded =
    userToken && typeof userToken === "string" ? jwt(userToken) : null;
  const privileges = decoded?.authorities ? decoded.authorities : [];
  const userName = decoded?.user_name ? decoded?.user_name : null;
  const userId = cookies.get("userId");
  return { privileges, userName, userId };
}
export const sessionPrivileges = (isClassComponent) => {
  // const loggedInReducer = useSelector((state) => state.LoggedInReducer);
  const uId = sessionStorage.getItem("user_id");
  const { privileges, userName, userId } = getToken();
  const headerState = {
    state: privileges.length > 0,
    dashboard: !!privileges?.includes("LEARNER_PROFILE"),
    student: !!privileges?.includes("LEARNER_PROFILE"),
    admin:
      !privileges.includes("MANAGE_CUSTOMER") &&
      !privileges.includes("GET_STUDENT_DASHBOARD"),
    prepare:
      !!privileges?.includes("LEARNER_PROFILE") &&
      !!privileges?.includes("VIEW_ASSESSMENT"),
    assessment:
      !!privileges?.includes("LEARNER_PROFILE") &&
      !!privileges?.includes("VIEW_ASSESSMENT"),
    practice:
      !!privileges?.includes("LEARNER_PROFILE") &&
      !!privileges?.includes("VIEW_PRACTICE_PACKAGE"),
    canViewProfile: !!privileges?.includes("GET_VIEW_PROFILE"),
    canViewCart: !!privileges?.includes("VIEW_CART"),
  };
  if (userId && uId !== userId) sessionStorage.setItem("user_id", userId);
  // if (isClassComponent !== true) dispachLoggedInState(headerState);
  return {
    isAdmin: privileges
      ? !privileges.includes("MANAGE_CUSTOMER") &&
        !privileges.includes("GET_STUDENT_DASHBOARD")
      : null,
    isSuperAdmin: privileges ? privileges.includes("MANAGE_CUSTOMER") : null,
    isStudent: privileges ? privileges.includes("LEARNER_PROFILE") : null,
    showDashboard: privileges
      ? privileges.includes("GET_ADMIN_DASHBOARD")
      : null,
    showViewProfile: privileges
      ? privileges.includes("GET_VIEW_PROFILE")
      : null,
    showCustomerList: privileges
      ? privileges.includes("VIEW_CUSTOMER_LIST")
      : null,
    showPracticePackages: privileges
      ? privileges.includes("VIEW_PRACTICE_PACKAGE_LIST")
      : null,
    showPrepare: privileges
      ? privileges.includes("VIEW_ADMIN_PREPARE_LIST")
      : null,
    canEditPracticePackages: privileges
      ? privileges.includes("EDIT_PRACTICE_PACKAGE")
      : null,
    canDeletePracticePackages: privileges
      ? privileges.includes("DELETE_PRACTICE_PACKAGE")
      : null,
    canEditAssessments: privileges
      ? privileges.includes("EDIT_ASSESSMENT")
      : null,
    showAssessments: privileges
      ? privileges.includes("VIEW_ASSESSMENT_LIST")
      : null,
    showQuestionBanks: privileges
      ? privileges.includes("VIEW_QUESTION_BANK_LIST")
      : null,
    showRoles: privileges ? privileges.includes("CREATE_ROLE") : null,
    showCompetency: privileges ? privileges.includes("ADD_COMPETENCY") : null,
    showUserList: privileges ? privileges.includes("VIEW_USERS_LIST") : null,
    showQPList: privileges
      ? privileges.includes("VIEW_QUESTION_PAPER_LIST")
      : null,
    showActiveUserList: privileges
      ? privileges.includes("VIEW_ACTIVE_USERS_LIST")
      : null,
    showStudentsReport: privileges
      ? privileges.includes("VIEW_STUDENTS_REPORT")
      : null,
    showStudentReport: privileges
      ? privileges.includes("VIEW_STUDENT_REPORT")
      : null,
    isStudentProfile: privileges
      ? privileges.includes("VIEW_PROFILE_DETAILS")
      : null,
    isStudentCart: privileges ? privileges.includes("VIEW_CART") : null,
    isConfiguration: privileges
      ? privileges.includes("CREATE_CONFIGURATION")
      : null,
    isLoggedIn: { ...headerState },
    customerId: sessionStorage.getItem("customer_id"),
    userprivileges: privileges,
    userId: userId ? userId : null,
    userName: userName ? userName : null,
  };
};
