import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import { Icon } from "@iconify/react";
import groupIcon from "@iconify/icons-el/group";
import studentIcon from "@iconify/icons-whh/student";
import { MenuItem } from "@material-ui/core";
import Select from "react-select";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import { connect } from "react-redux";
import { getCustomers } from "../../../store/StudentAndCustomer/actions";
import ReportTable from "../components/report-table/index";
import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";
import styles from "./index.module.scss";
import moment from "moment";
import { GetObjectFromString } from "../../../pages/utils/GetObjectFromString";
import { sessionPrivileges } from "privileges";

function AdminStudentsReport(props) {
  const userPrivileges = sessionPrivileges();
  const [dashboardData, setDashboardData] = useState({
    batchCount: "--",
    studentCount: "--",
  });
  const [studentsData, setStudentsData] = useState([]);
  const [loadingData, setLoadingStatus] = useState(false);
  const Priv = sessionPrivileges().userprivileges || [];
  const [customerId, setCustomerId] = useState(() =>
    Priv && Priv.includes("MANAGE_CUSTOMER")
      ? props?.location?.params?.customerId || "605b13d98443a50c4177d35d"
      : sessionStorage.getItem("customer_id") || ""
  );

  const customers = useMemo(
    () =>
      (props?.Customers || []).sort(
        ({ name: n1 = "" }, { name: n2 = "" }) =>
          n1?.toLowerCase() < n2?.toLowerCase()
      ),
    [props?.Customers]
  );

  const customersList = [];
  customers.forEach(function (item) {
    customersList.push({ label: item.name, value: item.id });
  });

  async function getData(id) {
    setLoadingStatus(true);

    await Apihelper.axiosCall(
      `${api.baseURL}adminReport/getAdminStudentReport/${id}`,
      "get"
    )
      .then((data) => {
        const dataList = [];
        (data?.studentOverAllReport || []).forEach(
          ({
            id,
            emailId,
            firstName,
            lastName,
            department,
            packageCompleted,
            packageEnrolled,
            assessmentCompleted,
            assessmentEnrolled,
            avgAssessmentMarks,
            overAllPerformance,
            lastLoginInfo,
            registrationNumber,
          }) => {
            dataList.push({
              id,
              emailId,
              name: `${firstName} ${lastName}`,
              registrationNumber,
              department,
              packagesCompletedEnrolled: `${packageCompleted ?? "_"}\xa0/\xa0${
                packageEnrolled ?? "_"
              }`,
              assesmentsCompletedEnrolled: `${
                assessmentCompleted ?? "_"
              }\xa0/\xa0${assessmentEnrolled ?? "_"}`,
              avgAssessmentMarks: `${parseFloat(avgAssessmentMarks).toFixed(
                2
              )}%`,
              overallPerformance: overAllPerformance,
              lastLoginInfo: lastLoginInfo
                ? `${moment(lastLoginInfo).format("DD-MM-yyyy")},\n${moment(
                    lastLoginInfo
                  ).format("hh:mm A")}`
                : "-",
            });
          }
        );

        setDashboardData({
          batchCount: data?.batchCount,
          studentCount: data?.studentCount,
        });
        setStudentsData(dataList);
      })
      .catch(() => {})
      .finally(() => {
        setLoadingStatus(false);
      });
  }

  useEffect(() => {
    if (Priv && Priv.includes("MANAGE_CUSTOMER")) {
      props.dispatch(getCustomers());
    } else {
      getData(sessionStorage.getItem("customer_id"));
    }
  }, []);

  useEffect(() => {
    setDashboardData({ batchCount: null, studentCount: null });
    setStudentsData([]);

    if (customerId) {
      getData(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    {userPrivileges.showStudentsReport && (
    <div className={cn("page-content", styles.page)}>
      <div className={styles.dashboard}>
        <div className={styles.dashboardInfoBox}>
          <div className={styles.dashboardInfoBoxTexts}>
            <div className={styles.dashboardInfoBoxTitle}>
              {dashboardData?.batchCount ?? "--"}
            </div>
            <div className={styles.dashboardInfoBoxDescription}>
              Overall Batch Count
            </div>
          </div>
          <div className={styles.dashboardInfoBoxIcon}>
            <Icon icon={groupIcon} style={{ color: "#420fd1", fontSize: 24 }} />
          </div>
        </div>
        <div className={styles.dashboardInfoBox}>
          <div className={styles.dashboardInfoBoxTexts}>
            <div className={styles.dashboardInfoBoxTitle}>
              {dashboardData?.studentCount ?? "--"}
            </div>
            <div className={styles.dashboardInfoBoxDescription}>
              Overall Student Count
            </div>
          </div>
          <div className={styles.dashboardInfoBoxIcon}>
            <Icon
              icon={studentIcon}
              style={{ color: "#bd2a20", fontSize: 18 }}
            />
          </div>
        </div>
      </div>
      {Priv && Priv.includes("MANAGE_CUSTOMER") && (
        <>
          <div className={styles.customerSelectContainer}>
            {/* <span>Customer:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> */}
            <div style={{ width: 207 }}>
              <Select
                placeholder="Choose a customer"
                value={GetObjectFromString(customersList, customerId)}
                onChange={(e) => {
                  setCustomerId(e.value);
                }}
                options={customersList}
                maxMenuHeight={120}
              />
            </div>

            {/* <Select
              classes={{ root: styles.customerSelect }}
              onChange={(e) => {
                setCustomerId(e?.target?.value);
              }}
              labelId="customer-select"
              defaultValue={customerId}>
              <MenuItem value="">
                <em>Select Customer</em>
              </MenuItem>
              {(customers || []).map(({ name, id }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select> */}
          </div>
          <br />
          <br />
        </>
      )}
      <ReportTable
        actions={
          (Priv && Priv.includes("VIEW_STUDENT_ASSESSMENT_REPORT")) ||
          (Priv && Priv.includes("VIEW_STUDENT_PRACTICE_PACKAGE_REPORT"))
            ? [
                {
                  icon: () => <ArrowForwardRoundedIcon />,
                  iconProps: {
                    size: "small",
                  },
                  tooltip: "View Report",
                  onClick: (_e, rowData) => {
                    props.history.push({
                      pathname: "/student-report",
                      params: {
                        customerId,
                        studentEmailId: rowData?.id,
                      },
                    });
                  },
                },
              ]
            : undefined
        }
        data={studentsData}
        exportFileName="Students overall report"
        title="Students Overall Report"
        columns={[
          {
            align: "left",
            field: "name",
            title: "Name",
          },
          {
            align: "left",
            field: "registrationNumber",
            title: "Registration Number",
          },
          {
            align: "left",
            field: "department",
            title: "Department",
          },
          {
            align: "center",
            field: "packagesCompletedEnrolled",
            title: "Packages\nCompleted\xa0/\xa0Enrolled",
          },
          {
            align: "center",
            field: "assesmentsCompletedEnrolled",
            title: "Assessments\nCompleted\xa0/\xa0Enrolled",
          },
          {
            align: "center",
            field: "avgAssessmentMarks",
            render: (d) => (
              <span
                style={{
                  backgroundColor: "#8BFFD5",
                  borderRadius: 5,
                  color: "#20222F",
                  height: 21,
                  padding: "0 6px",
                }}
              >
                {d?.avgAssessmentMarks || "-"}
              </span>
            ),
            title: "Avg\xa0Assessment\nMarks",
          },
          {
            align: "center",
            field: "overallPerformance",
            render: (d) =>
              d?.overallPerformance ? (
                <span
                  style={{
                    backgroundColor:
                      d?.overallPerformance === "Good"
                        ? "#EEFFEA"
                        : d?.overallPerformance === "Average"
                        ? "#FFF8E1"
                        : "#FFF4F3",
                    borderColor:
                      d?.overallPerformance === "Good"
                        ? "#B3DAC7"
                        : d?.overallPerformance === "Average"
                        ? "#FFCA99"
                        : "#FFD7D7",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderRadius: 3,
                    color:
                      d?.overallPerformance === "Good"
                        ? "#43B224"
                        : d?.overallPerformance === "Average"
                        ? "#886E1D"
                        : "#D42216",
                    height: 24,
                    padding: "0 6px",
                    width: 66,
                  }}
                >
                  {d?.overallPerformance || "-"}
                </span>
              ) : (
                ""
              ),
            title: "Overall\xa0Performance",
          },
          {
            align: "center",
            field: "lastLoginInfo",
            title: "Last\xa0Login\nInfo",
          },
        ]}
        isLoading={loadingData}
      />
      <br />
          </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  Customers: state.StudentAndCustomerReducer.Customers,
});

export default connect(mapStateToProps, null)(withRouter(AdminStudentsReport));
