import styles from "./hackathonCard.module.scss";
import { useMediaQuery } from "@material-ui/core";
import mobileStyles from "./hackathonCard.module.scss";
import CoverImage from "./CoverImage.png";

const HackathonMobileCard = () => {
  const smallScreen = useMediaQuery("(max-width: 767px)");
  return (
    <div className={styles.card}>
      <SVG />
    </div>
  );
};

export default HackathonMobileCard;

const SVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="425"
    height="130"
    viewBox="0 0 425 130"
    fill="none"
  >
    <g clipPath="url(#clip0_13040_52213)">
      <rect width="425" height="130" fill="#021244" />
      <rect
        x="375.473"
        y="-60.4922"
        width="90.593"
        height="30.098"
        transform="rotate(29.1288 323.473 -28.4922)"
        fill="#F79B65"
      />
      <rect
        x="-24.5176"
        y="75"
        width="90.593"
        height="33.5944"
        transform="rotate(37.5655 -24.5176 75)"
        fill="#DC30D0"
      />
    </g>
    <defs>
      <clipPath id="clip0_13040_52213">
        <rect width="500" height="130" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
