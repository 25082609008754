import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import styles from "./UserAssessmentMarks.module.scss";
import { penIcon, trashFilled } from "assets/icons/icons";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
// import { map } from "pages/Assessment/mocksubject";
// import { sessionPrivileges } from "privileges";
// import Apihelper from "../../api/apiHelper";
// import api from "../../api/baseConfig";
import { useMediaQuery } from "@material-ui/core";
import { uid } from "react-uid";
import Divider from "@material-ui/core/Divider";
import { useSelector } from "react-redux";
import moment from "moment";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  root: {
    background: "#ffffff",
    boxShadow: "0px 0px 25px rgba(170, 170, 170, 0.25)",
    borderRadius: "5px",
    transition: "transform 0.5s ease-out",
    maxWidth: "300px",
    maxHeight: "285px",
    height: "285px",
  },
}));

const CourseCard = ({
  details,
  setDeleteCourseId,
  toggleDeleteModal,
  customerId,
}) => {
  const [userToken, setUserToken] = useState("");
  const classes = useStyles();
  const smallScreen = useMediaQuery("(max-width: 767px)");
  useEffect(() => {
    if (!userToken) {
      const cookies = new Cookies();
      setUserToken(cookies.get("userToken"));
    }
  }, []);

  const userDetails = useSelector((state) => state.UserProfileReducer.UserData);

  const date = moment(details?.startDate).format("DD MMM yyyy");
  const time = moment(details?.startDate).format("hh:mm a");
  const endTime = moment(details?.endDate).format("hh:mm a");
  return (
    <Card className={!smallScreen ? classes.root : ""}>
      <CardContent className={styles.cardRowAdmin}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingTop: "10px",
          }}
        >
          <Typography
            style={{
              fontWeight: "500",
              fontSize: "16px",
              textTransform: "capitalize",
            }}
            className={styles.heading}
          >
            {details.meetingName}
          </Typography>
          {details.status == "active" && (
            <div>
              <Button
                variant="contained"
                disableElevation
                size="small"
                style={{ backgroundColor: "#EB5757", color: "#ffffff" }}
              >
                Live
              </Button>
            </div>
          )}
        </div>
        <Tooltip title={details.description} arrow placement="top-start">
          <Typography
            variant="h5"
            component="h2"
            className={styles.para}
            style={{
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "23px",
              color: "#63606C",
            }}
          >
            {details.description}
          </Typography>
        </Tooltip>
        <Typography
          style={{
            fontWeight: "normal",
            fontSize: "14px",
            paddingTop: "10px",
            lineHeight: "23px",
            color: "#19181B",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ paddingRight: "10px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M4.66667 7.33325H6V8.66659H4.66667V7.33325ZM4.66667 9.99992H6V11.3333H4.66667V9.99992ZM7.33333 7.33325H8.66667V8.66659H7.33333V7.33325ZM7.33333 9.99992H8.66667V11.3333H7.33333V9.99992ZM10 7.33325H11.3333V8.66659H10V7.33325ZM10 9.99992H11.3333V11.3333H10V9.99992Z"
                  fill="#63606C"
                />
                <path
                  d="M3.33333 14.6666H12.6667C13.402 14.6666 14 14.0686 14 13.3333V3.99992C14 3.26459 13.402 2.66659 12.6667 2.66659H11.3333V1.33325H10V2.66659H6V1.33325H4.66667V2.66659H3.33333C2.598 2.66659 2 3.26459 2 3.99992V13.3333C2 14.0686 2.598 14.6666 3.33333 14.6666ZM12.6667 5.33325L12.6673 13.3333H3.33333V5.33325H12.6667Z"
                  fill="#63606C"
                />
              </svg>
            </div>
            <p style={{ whiteSpace: "nowrap" }}>{`${date}, ${time}${
              endTime == "Invalid date" ? "" : "-"
            }${endTime == "Invalid date" ? "" : endTime}`}</p>
          </div>
        </Typography>
        <div style={{ display: "flex" }}>
          <AvatarGroup max={4}>
            {details?.participantUsers?.slice(0, 4).map((e) => (
              <Avatar alt={e} className={classes.small} src="" />
            ))}
          </AvatarGroup>
          <div style={{ paddingTop: "10px", paddingLeft: "5px" }}>
            <Typography
              style={{
                fontWeight: "normal",
                fontSize: "12px",
                color: "#63606C",
              }}
            >
              {details.noOfParticipants} Participants
            </Typography>
          </div>
        </div>
        {details.status == "active" ? (
          <div className={styles.cardButtons} style={{ padding: "6px 0px" }}>
            <a
              href={`${details.meetingUrl}?token=${userToken}&firstName=${userDetails.firstName}&lastName=${userDetails.lastName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="contained"
                size="lg"
                style={{ backgroundColor: " #794DF5", color: "#ffffff" }}
              >
                {"Start Meeting"}
              </Button>
            </a>
          </div>
        ) : details.status == "completed" ? (
          <div className={styles.cardButtons} style={{ padding: "6px 0px" }}>
            <Button
              variant="contained"
              size="lg"
              style={{ backgroundColor: " #794DF5", color: "#ffffff" }}
            >
              {"View Report"}
            </Button>
          </div>
        ) : (
          <div className={styles.cardButtons} style={{ padding: "11px 0px" }}>
            <Tooltip title="Edit course" arrow placement="top-start">
              <Link
                to={{
                  pathname: `/cloudLabForm/${details.id}`,
                  state: { fromEdit: true, customerId },
                }}
              >
                <div>{penIcon("#784df4")}</div>
              </Link>
            </Tooltip>
            <Tooltip title="Delete course" arrow placement="top-start">
              <div
                onClick={() => {
                  setDeleteCourseId(details.id);
                  toggleDeleteModal();
                }}
              >
                {trashFilled}
              </div>
            </Tooltip>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default function MeetingList({
  cloudLabList,
  totalCloud,
  setDeleteCourseId,
  toggleDeleteModal,
  customerId,
  courseSearch,
}) {
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [cloudLabMeetingId, setCloudLabMeetingId] = useState("");
  const [searchValue, setSearchValue] = useState([]);
  const [completedMeetings, setCompletedMeetings] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (courseSearch)
        setSearchValue(
          totalCloud.reduce((acc, val) => {
            if (
              cloudLabList.modify[`${val}`].meetingName
                .toLowerCase()
                .includes(courseSearch)
            )
              acc.push(val);
            return acc;
          }, [])
        );
    }, [500]);
  }, [courseSearch]);

  return (
    <div
      className={styles.wrappedCards}
      style={{ paddingLeft: "50px", paddingTop: "20px" }}
    >
      <Typography
        style={{ fontWeight: 600, fontSize: "16px", padding: "30px 10px" }}
      >
        Upcoming Meetings
      </Typography>
      <div className={styles.cardgrid}>
        {(courseSearch ? searchValue : totalCloud).map((item, index) => {
          return (
            <>
              {cloudLabList.modify[`${item}`].status !== "completed" && (
                <CourseCard
                  details={cloudLabList.modify[`${item}`]}
                  key={uid(index)}
                  setDeleteCourseId={setDeleteCourseId}
                  toggleDeleteModal={toggleDeleteModal}
                  customerId={customerId}
                />
              )}
            </>
          );
        })}
      </div>
      <div style={{ paddingTop: "50px" }}>
        <Divider />
        <div style={{ display: "flex" }}>
          <Typography
            style={{ fontWeight: 600, fontSize: "16px", padding: "30px 0px" }}
          >
            Completed Meetings
          </Typography>
          <div
            style={{ position: "absolute", right: "0px", paddingTop: "25px" }}
          >
            <Button
              variant="outlined"
              className={styles.viewAll}
              style={{
                width: "50px",
                padding: "0px 60px",
                whiteSpace: "nowrap",
              }}
              onClick={(e) => setCompletedMeetings(!completedMeetings)}
            >
              {!completedMeetings ? "View All" : "View Less"}
            </Button>
          </div>
        </div>
        {!completedMeetings ? (
          <div className={styles.cardgrid} style={{ paddingTop: "20px" }}>
            {(courseSearch ? searchValue : totalCloud.slice(0, 3)).map(
              (item, index) => {
                //{totalCloud.slice(0, 3).map((item, index) => {
                return (
                  <div>
                    {cloudLabList.modify[`${item}`].status == "completed" && (
                      <CourseCard
                        details={cloudLabList.modify[`${item}`]}
                        key={uid(index)}
                        setDeleteCourseId={setDeleteCourseId}
                        toggleDeleteModal={toggleDeleteModal}
                        customerId={customerId}
                      />
                    )}
                  </div>
                );
              }
            )}
          </div>
        ) : (
          <div className={styles.cardgrid} style={{ paddingTop: "20px" }}>
            {totalCloud.map((item, index) => {
              return (
                <div>
                  {cloudLabList.modify[`${item}`].status == "completed" && (
                    <CourseCard
                      details={cloudLabList.modify[`${item}`]}
                      key={uid(index)}
                      setDeleteCourseId={setDeleteCourseId}
                      toggleDeleteModal={toggleDeleteModal}
                      customerId={customerId}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
