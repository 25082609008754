import axios from "axios";
import Cookies from "universal-cookie";

function getToken() {
  const cookies = new Cookies();
  return cookies.get("userToken");
}

export const GET_CALL = async (url) => {
  try {
    const res = await axios({
      url,
      method: "GET",
      headers: {
        authorization: getToken(),
      },
    });
    return res.data;
  } catch (e) {}
};
