import Select from "react-select";
import { Input, Label } from "reactstrap";
import CustomSelect from "components/CustomSelect";
import { Controller } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import { ReactSelectTervStyle } from "pages/utils/ReactSelectTervStyle";
import { GetObjectArray } from "pages/utils/GetObjectArray";
import { sessionPrivileges } from "privileges";

const useStyles = makeStyles({
  resetButton: {
    fontSize: 19,
    cursor: "pointer",
    borderRadius: "1.5rem",
    padding: 1,
    "&:hover": {
      backgroundColor: "#f8edeb",
    },
    transition: "background-color 0.3s",
  },
});

export default function HandleStudentSelection({
  hookFormControls: {
    control,
    register,
    formState: { errors },
    watch,
    setValue,
  },
  allFilterTypes,
  returnParsedData,
  resetFilter,
  departments,
  students,
  checkIfBatchNameExists,
  viewOnly,
  studentFieldName,
  labelName,
}) {
  const classes = useStyles();

  return (
    <Grid container spacing={4}>     
        <Grid item xs={6}>
          {/* <label className="terv-label" style={{ position: "relative" }}>
            {RequiredIndicator({labelName})}&nbsp;&nbsp;
          </label> */}
          <Controller
            name={studentFieldName}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                options={students}
                onChange={(val) =>
                  onChange(
                    returnParsedData(
                      watch("filterType"),
                      val ? val.map((it) => it.value) : [],
                      students
                    )
                  )
                }
                value={GetObjectArray(students, value)}
                styles={ReactSelectTervStyle}
              />
            )}
          />
        </Grid>
    </Grid>
  );
}
