import React, { useEffect, useState } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "Main/Sidebar";
import { useMediaQuery, makeStyles, useTheme } from "@material-ui/core";
import Footer from "components/VerticalLayout/Footer";
import { connect, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { sessionPrivileges } from "privileges";

const drawerWidth = { lg: 275, sm: 72 };

const useStyles = makeStyles((theme) => {
  return {
    smallDrawer: {
      backgroundColor: "#1A1B41 !important",
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#794DF5",
        borderRadius: "10px",
        // margin: "0 10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
      },
    },
    drawer: {
      backgroundColor: "#1A1B41 !important",
      display: "none",
      position: "relative",
      zIndex: 0,
      [theme.breakpoints.up(896)]: {
        backgroundColor: "#1A1B41 !important",
        display: "block",
        width: drawerWidth.lg + 1,
        flexShrink: 0,
        whiteSpace: "nowrap",
        backgroundColor: "#1A1B41",
      },
    },
    drawerOpen: {
      backgroundColor: "#1A1B41 !important",
      display: "none",
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#794DF5",
        borderRadius: "10px",
        // margin: "0 10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
      },
      [theme.breakpoints.up(896)]: {
        backgroundColor: "#1A1B41 !important",
        display: "block",
        top: "3.8rem",
        height: "calc(100% 0 3.8rem)",
        width: drawerWidth.lg + 1,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    drawerClose: {
      backgroundColor: "#1A1B41 !important",
      top: "3.8rem",

      height: "calc(100% 0 3.8rem)",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: drawerWidth.sm + 1,
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    nestedListItem: {
      paddingLeft: theme.spacing(10),
    },
    nestedListItemTextActive: {
      "& > span": {
        color: "#76B5FF",
      },
    },
    nestedListItemActive: {
      "&::before": {
        content: '"."',
        color: "#76B5FF",
        transform: "scale(4)",
        paddingBottom: "0.4rem",
        marginLeft: "-0.625rem",
        marginRight: "0.625rem",
      },
    },
    listMenu: {
      marginTop: "3.125rem",
      marginBottom: "5.125rem",
    },
    nestedList: {
      backgroundColor: "#1F1616",
    },
    listActive: {
      borderLeft: "3px",
      borderLeftColor: "#fff",
      borderLeftStyle: "solid",
    },
    listItemText: {
      color: "#fff",
      fontSize: 15,
    },
    listItemIcon: {
      justifyContent: "center",
    },
    listIconMR: {
      paddingRight: 13,
    },
    collapsedMenu: {
      width: "13rem",
    },
    collapsedMenuItem: {
      fontFamily: "Poppins",
      fontWeight: 500,
    },
    listItemActive: {
      backgroundColor: "#0069E4",
      borderLeft: "3px",
      borderLeftColor: "#fff",
      borderLeftStyle: "solid",
      "&:hover": {
        transition: "none",
        backgroundColor: "#0069E4",
      },
    },
    menuIcon: {
      height: "2.5rem",
      width: "2.5rem",
      position: "absolute",
      right: "0.625rem",
      top: "0.625rem",
      backgroundColor: "#fff",
      "& svg": {
        color: "#000",
      },
      "&:hover": {
        backgroundColor: "#fff !important",
      },
    },
  };
});

function Layout(props) {
  const userPrivileges = sessionPrivileges();
  const isSmallScreen = useMediaQuery("(max-width: 896px)");
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const [isAdministrator] = useState(
    userPrivileges.isAdmin || userPrivileges.isSuperAdmin
  );
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const { courseId, resourceID } = useParams();
  const { id } = useParams();
  const { token } = useParams();
  const { returnLink } = useParams();
  const hasLoggedIn = useSelector((state) => state.LoggedInReducer);
  const isLogin =
    window.location.pathname === "/login" || window.location.pathname === "/";

  const isAdminLogin =
    window.location.pathname === "/login" || window.location.pathname === "/";
  const isMobileNavbar =
    window.location.pathname === `/hackathonSubmission/${id}` ||
    window.location.pathname === `/hackathonSubmissionform` ||
    window.location.pathname === `/hackathonSubmissionform/${id}`;

  const isAssessment =
    window.location.pathname === "/taketest" ||
    window.location.pathname === "/completedPublicAssessment" ||
    window.location.pathname === "/take-test" ||
    window.location.pathname === "/legal" ||
    window.location.pathname === "/privacy" ||
    window.location.pathname === "/termsAndConditions" ||
    window.location.pathname === "/practice-lab" ||
    window.location.pathname === `/course-learning/${courseId}` ||
    window.location.pathname === `/courselearning/practice/${courseId}`;

  const isPartnerUser = localStorage.getItem("IsPartnerUser");

  // useEffect(() => {
  //   if (!isLogin && !isAssessment) {
  //     if (!userPrivileges.isLoggedIn.state) window.location.pathname = "/login";
  //   }
  // }, []);
  return (
    <>
      {(!isAssessment && !isMobileNavbar) && (
        <Header drawerWidth={drawerWidth} setSidebarOpen={setOpen} />
      )}
      {(isMobileNavbar && !smallScreen) && (
        <Header drawerWidth={drawerWidth} setSidebarOpen={setOpen} />
      )}
      <div style={{ flex: "1 1 0%", minHeight: "100%" }}>
        <div
          className="main-content"
          style={{
            paddingLeft:
              isPartnerUser ||
              isSmallScreen ||
              isAssessment ||
              !hasLoggedIn.state ||
              hasLoggedIn.student ||
              isLogin
                ? 0
                : open
                ? drawerWidth.lg + 1
                : drawerWidth.sm + 1,
            paddingTop: isAssessment ? 0 : 60,
            display: "flex",
            flexDirection: "column",
            flex: "1 1 0%",
            minHeight: "100vh",
            transition: "padding 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
            width: "100%",
          }}
        >
          {props.children}
          {!isAssessment && !isLogin && <Footer inPageWithSidebar />}
        </div>
        {!isPartnerUser &&
        hasLoggedIn.state &&
        !hasLoggedIn.student &&
        !isLogin &&
        !isAssessment ? (
          <Sidebar
            classes={classes}
            theme={theme}
            open={open}
            setOpen={setOpen}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default connect(null, null)(Layout);
