import { Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Select, { components } from "react-select";
import customSelectStyles from "./CustomSelect.module.scss";

const customStyles = {
  valueContainer: (styles) => ({
    ...styles,
    pointerEvents: "none",
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    pointerEvents: "none",
  }),
};

const hasRegNo = (array) =>
  array.length > 0 ? array.every((item) => !!item.registrationNo) : false;

const someHasRegNo = (array) =>
  array.length > 0 ? array.some((item) => !!item.registrationNo) : false;

const removeRegNo = (str) => (str.includes(",") ? str.split(",")[0] : str);

const CustomSelectHost = ({
  options,
  value,
  onChange,
  styles,
  isDisabled,
  inputId,
  viewOnly,
  filterOption,
}) => {
  const handleInputChange = (inputValue, event) => {
    if (event.action === "input-change" && someHasRegNo(options)) {
      setTimeout(() => {
        const regIds = inputValue.replace(/\s+/g, "").split(",");
        const prevValue = value ? [...value] : [];
        onChange(
          options.reduce((acc, val) => {
            if (
              !acc.includes(val.registrationNo) &&
              regIds.includes(val.registrationNo)
            ) {
              acc.push({ ...val, label: removeRegNo(val.label) });
            }
            return acc;
          }, prevValue)
        );
      }, 1000);
    }
  };

  return (
    <Select
      inputId={inputId}
      options={options}
      value={value}
      onChange={(val) => {
        onChange(
          val
            ? someHasRegNo(options)
              ? val.reduce((acc, val) => {
                  acc.push({ ...val, label: removeRegNo(val.label) });
                  return acc;
                }, [])
              : val
            : []
        );
      }}
      isDisabled={isDisabled}
      aria-labelledby="aria-label"      
      isSearchable
      maxMenuHeight={120}
      styles={viewOnly === true ? { ...customStyles, ...styles } : styles}
      onInputChange={handleInputChange}
      closeMenuOnSelect={false}
      filterOption={filterOption}
    />
  );
};

export default CustomSelectHost;
