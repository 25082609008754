import cn from "classnames";
import { useContext, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
  Pagination as MuiPagination,
  PaginationItem as MuiPaginationItem,
} from "@material-ui/lab";
import {
  ArrowBack,
  Brightness2,
  Brightness2Outlined,
  EmojiObjectsOutlined,
  Home,
  CloseOutlined,
} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import ReactPlayer, { Player, LoadingSpinner, BigPlayButton } from "react-player";
import { PracticeLabContext } from "../../context/practice-lab.context";
import Stopwatch from "../stopwatch/stopwatch";
import styles from "./header.module.scss";
// import "../../../../../../node_modules/react-player/dist/react-player.css";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import { useHistory } from "react-router";
import { useQuestionInfo } from "../../hooks/use-question-info";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const Pagination = withStyles({
  ul: {
    color: "#fff",
  },
})(MuiPagination);

const PaginationItem = withStyles({
  root: {
    color: "#fff",
  },
})(MuiPaginationItem);

const LargeButton = withStyles({
  label: {
    whiteSpace: "nowrap",
  },
  disabled: {
    color: "#fff8 !important",
    borderColor: "#fff8 !important",
  },
})(Button);

const HomeButton = withStyles({
  root: {
    height: 28,
    color: "#fff !important",
    minWidth: "auto",
    padding: 8,
    width: 28,
  },
  startIcon: {
    fontSize: "32px !important",
    margin: 0,
  },
})(Button);

const HomeButtonLarge = withStyles({
  root: {
    minWidth: "auto",
  },
  label: {
    color: "#fff !important",
  },
})(Button);

function Spacer() {
  return <div className={styles.spacer} />;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const PaginationGrid = withStyles({
  root: {
    border: "1px solid #585050",
    borderRadius: 5,
    display: "inline-flex",
    height: 30,
    overflow: "hidden",
  },
})(Grid);

const PaginationButton = withStyles({
  root: {
    backgroundColor: "#00315C !important",
    border: "none",
    borderRadius: 0,
    height: "100%",
    minWidth: "auto",
    padding: 0,
    width: 72,
  },
  disabled: {
    border: "none !important",
    color: "#fff4 !important",
  },
})(Button);

export default function Header(props) {
  const history = useHistory();
  const classes = useStyles();
  const largeOrCompactScreen = useMediaQuery("(min-width: 900px)");
  const {
    firstQuestion,
    goToNextQuestion,
    goToPrevQuestion,
    isLightMode,
    largeScreen,
    lastQuestion,
    questionIdList,
    setCurrentQuestion,
    smallScreen,
    debugcCodeConform,
  } = useContext(PracticeLabContext);
  const [explanationIsVisible, setExplanationVisibility] = useState(false);
  const [optionSelected, setOptionSelected] = useState();
  const [currentQuestionValueNew, setCurrentQuestionValueNew] = useState();
  const [dropdownSelect, setDropdownSelect] = useState();
  const labId = sessionStorage.getItem("labId");
  const userId = sessionStorage.getItem("user_id");

  useEffect(() => {
    setOptionSelected(
      props?.learnPathFromApi
        ? props?.learnPathFromApi
        : props?.learningPathList
    );
  }, [optionSelected, props?.learnPathFromApi]);

  useEffect(() => {
    props?.setDropdownValue(parseInt(props?.currentPathOrder + 1));
  }, [props?.currentPathOrder]);
  useEffect(() => {
    if (optionSelect) {
      props?.setCurrentQuestionValue(optionSelected[dropdownSelect]);
      questionRedirect(optionSelected[dropdownSelect]);
    }
  }, [dropdownSelect]);

  const redirectObject = {
    pathname: props?.isFromPrepare
      ? `/courselearning/practice/${props?.courseId}`
      : "/view-practice-questions",
    state: {
      learningPathIndex: props?.location?.state?.learningPathIndex,
      prepCourseId: props?.prepCourseId,
      prepCourseName: props?.prepCourseName,
      prepCoursePer: props?.prepCoursePer,
      prepPracCompletionCriteria: props?.prepPracCompletionCriteria,
      prepPracResId: props?.prepPracResId,
      prepPracModId: props?.prepPracModId,
    },
  };
  const handleCancelApi = () => {
    if (props?.cancelToken?.current != null) {
      props.cancelToken.current = null;
    }
  };

  const handleChange = (event) => {
    setDropdownSelect(event.target.value);
    props?.setDropdownValue(event.target.value);
  };
  let questionRedirect = (e) => {
    setCurrentQuestionValueNew(e?.questionIdList?.[0]);
    props?.setDropdownValue(e?.pathOrder);
    props?.setMockAssessmentValue(e?.type);
    props?.setMockAssessmentId(e?.setMockAssessments);
    props?.setPathIndexMock(e?.pathOrder);
  };
  const optionSelect =
    optionSelected &&
    optionSelected?.map((e) => {
      return e;
    });
  return (
    <AppBar
      classes={{ root: styles.header }}
      position="fixed"
      style={{
        height: smallScreen ? 50 : 60,
        zIndex: 200,
      }}
    >
      <Toolbar
        classes={{ root: styles.toolbar }}
        style={{
          height: smallScreen ? 50 : 60,
          minHeight: 0,
        }}
      >
        <div className={`${styles.headerContent} headerDashboard`}>
          {props.debugcCodeConform ? (
            smallScreen ? (
              <Tooltip title="Dashboard">
                <Link to={redirectObject}>
                  <HomeButton
                    color="primary"
                    size="large"
                    startIcon={<Home />}
                    variant="contained"
                  />
                </Link>
              </Tooltip>
            ) : (
              <HomeButtonLarge
                color="primary"
                startIcon={<CloseIcon />}
                variant="contained"
                onClick={(e) => {
                  props.setDebugcCodeConform(false);
                }}
              >
                Close debug
              </HomeButtonLarge>
            )
          ) : smallScreen ? (
            <Tooltip title="Dashboard">
              <Link to={redirectObject}>
                <HomeButton
                  color="primary"
                  size="large"
                  startIcon={<Home />}
                  variant="contained"
                  onClick={handleCancelApi}
                />
              </Link>
            </Tooltip>
          ) : (
            <Link to={redirectObject}>
              <HomeButtonLarge
                color="primary"
                startIcon={<ArrowBack />}
                variant="contained"
                onClick={handleCancelApi}
              >
                Dashboard
              </HomeButtonLarge>
            </Link>
          )}
        </div>
        <Spacer />
        {largeScreen &&
          (optionSelect?.length > 0 ? (
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props?.dropdownValue - 1}
                onChange={handleChange}
                style={{
                  height: "40px",
                  width: "15rem",
                  // padding:'10px',
                  borderRadius: "6px",
                  background: "#242744",
                  color: "#fff",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {optionSelect?.length > 0 &&
                  optionSelect?.map((e, index) => (
                    <MenuItem
                      key={index}
                      value={index}
                      style={
                        e?.pathStatus == "InComplete"
                          ? {
                              background: "#323036",
                              color: "#fff",
                              width: "15rem",
                              paddingRight: "5px",
                            }
                          : {
                              background: "#323036",
                              color: "#fff",
                              width: "15rem",
                              paddingRight: "5px",
                            }
                      }
                      disabled={e?.pathStatus == "InComplete"}
                    >
                      <div style={{ display: "flex", width: "70%" }}>
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {e?.name}
                        </div>
                        <div style={{ position: "absolute", right: "5px" }}>
                          {e?.pathStatus == "InComplete" && (
                            <svg
                              width="16"
                              height="20"
                              viewBox="0 0 16 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8 0C5.243 0 3 2.243 3 5V7H2C0.897 7 0 7.897 0 9V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V9C16 7.897 15.103 7 14 7H13V5C13 2.243 10.757 0 8 0ZM5 5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V7H5V5ZM14.002 18H9V15.722C9.595 15.375 10 14.737 10 14C10 12.897 9.103 12 8 12C6.897 12 6 12.897 6 14C6 14.736 6.405 15.375 7 15.722V18H2V9H14L14.002 18Z"
                                fill="#D8D6DB"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ) : (
            <Typography
              noWrap
              style={{
                maxWidth: 180,
                marginLeft: 8,
                marginRight: 24,
              }}
            >
              {props.debugcCodeConform ? "" : props?.title}
            </Typography>
          ))}
        {props?.loading ? (
          <Skeleton height="44" style={{ width: 128 }} />
        ) : props.debugcCodeConform ? (
          <p style={{ fontSize: "16px", paddingTop: "10px" }}>Debug Window</p>
        ) : largeOrCompactScreen ? (
          <Pagination
            disabled={props?.executingCode || props?.submittingMCQAnswer}
            classes={{ ul: styles.paginationList }}
            count={questionIdList?.length}
            onChange={(_e, page) => {
              setCurrentQuestion?.(page - 1);
            }}
            page={props?.currentQuestionNum + 1}
            renderItem={(itemProps) => {
              return (
                <PaginationItem
                  className={cn("labPaginationItem", {
                    ...(itemProps?.type === "page" &&
                      props?.completedQuestions?.[itemProps?.page - 1] && {
                        [styles.completedQuestion]: true,
                      }),
                  })}
                  {...itemProps}
                />
              );
            }}
            shape="rounded"
          />
        ) : (
          <PaginationGrid>
            <PaginationButton
              disabled={
                firstQuestion ||
                props?.executingCode ||
                props?.submittingMCQAnswer
              }
              onClick={() => {
                goToPrevQuestion?.();
              }}
              variant="outlined"
            >
              {"Prev..."}
            </PaginationButton>
            <div
              style={{ height: "100%", width: 1, backgroundColor: "#585050" }}
            />
            <PaginationButton
              disabled={
                lastQuestion ||
                props?.executingCode ||
                props?.submittingMCQAnswer
              }
              onClick={() => {
                goToNextQuestion?.();
              }}
              variant="outlined"
            >
              {"Next"}
            </PaginationButton>
          </PaginationGrid>
        )}
        <Spacer />
        <div className={`${styles.headerContent} headerTools`}>
          {!smallScreen && props?.solutionMedia && (
            <>
              <Button
                color="primary"
                onClick={() => {
                  setExplanationVisibility(true);
                }}
                variant="contained"
                startIcon={<EmojiObjectsOutlined />}
              >
                {"Explanation"}
              </Button>
              <>&nbsp;&nbsp;&nbsp;</>
              <Dialog
                open={explanationIsVisible}
                classes={{ paper: styles.explantionModal }}
                onClose={() => {
                  setExplanationVisibility(false);
                }}
              >
                <DialogTitle>
                  <Grid
                    alignItems="center"
                    container
                    justifyContent="space-between"
                  >
                    <Typography
                      classes={{
                        root: cn(styles.explantionModalTitle, {
                          [styles.lightMode]: isLightMode,
                        }),
                      }}
                    >
                      {"Explanation"}
                    </Typography>
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={() => {
                        setExplanationVisibility(false);
                      }}
                    >
                      <CloseOutlined />
                    </IconButton>
                  </Grid>
                </DialogTitle>
                <DialogContent
                  classes={{ root: styles.explantionModalContent }}
                >
                  {/* <Player fluid src={props?.solutionMedia} width="50%">
                    <LoadingSpinner />
                    <BigPlayButton position="center" />
                  </Player> */}
                  <ReactPlayer url={props?.solutionMedia} width={"100%"} controls={true} 
                    config={{ file: { 
                            attributes: {
                              controlsList: 'nodownload'
                            }
                          }}}
                          />
                </DialogContent>
              </Dialog>
            </>
          )}
          <Tooltip title={`Toggle ${isLightMode ? "Dark Mode" : "Light Mode"}`}>
            <IconButton
              color="inherit"
              onClick={() => {
                props?.setIfLightTheme?.((lt) => !lt);
              }}
            >
              {isLightMode ? <Brightness2Outlined /> : <Brightness2 />}
            </IconButton>
          </Tooltip>
          {!smallScreen && <>&nbsp;&nbsp;&nbsp;&nbsp;</>}
          <Stopwatch compactMode={smallScreen} isLightMode={isLightMode} />
        </div>
      </Toolbar>
    </AppBar>
  );
}
