import {
  GoogleLogo,
  GoogleStars,
  TPStars,
  TrustPilotLogo,
} from "./CompanyPackageSVGs";

export const ReviewsList = () => [
  {
    logo: GoogleLogo,
    stars: GoogleStars,
    rating: 4.3,
    reviews: 538,
  },
  {
    logo: TrustPilotLogo,
    stars: TPStars,
    rating: 4.8,
    reviews: 400,
  },
];
