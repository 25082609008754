import { useState, useEffect } from "react";
import {
    Popover,
    Checkbox,
    FormGroup,
    FormControlLabel,
} from "@material-ui/core";
import { TervButtonFilled } from "components/TervButtons";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./DataTable.module.scss";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

  const useStyles = makeStyles((theme) => ({
    selectDialogContainer: {
      "& .MuiPaper-root": {
        "&::-webkit-scrollbar": {
          width: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#BDBBC3",
          borderRadius: "10px",
          margin: "0 10px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#fff",
        },
      },
    },
  }));

  const allFilters = [
    { label: "All", value: "All" },
    {
      label: "Aptitude",
      value: "Aptitude ",
    },
    {
      label: "Coding",
      value: "Coding",
    },
    {
      label: "Multi choice",
      value: "Multi choice",
    },
    {
        label: "Descriptive",
        value: "Descriptive",
      },
  ];

  const complexityFilters = [
    { label: "All", value: "All" },
    {
      label: "Easy",
      value: "Easy",
    },
    {
      label: "Medium",
      value: "Medium",
    },
    {
      label: "Hard",
      value: "Hard",
    },
  ];
  
  const DataTable = ({ 
    isOpen, 
    onClose, 
    onInvoke,
    anchorEl,
    save,
    unCheck,
    setComplexity,
    setQuestionType,
 }) => {
    const classes = useStyles();

    const [state, setState] = useState({});
    const [checked, setChecked] = useState("");
    const [checkVal, setCheckVal] = useState('N');
    const [checkApt, setCheckApt] = useState('N');
    const [checkCod, setCheckCod] = useState('N');
    const [checkMul, setCheckMul] = useState('N');
    const [checkDes, setCheckDes] = useState('N');
    const [all, setAll] = useState('N');
    const [easy, setEasy] = useState('N');
    const [medium, setMedium] = useState('N');
    const [hard, setHard] = useState('N');

    // <div>
    // const handleCheckBox = (event) => {
    //   setState({ ...state, [event.target.value]: event.target.checked });
    //   setComplexity(state);
    // };

    // const All = (e) => {
    //   setAll(!all);
    // };

    // const Hard = (e) => {
    //   setHard(!hard);
    // };

    // const Easy = (e) => {
    //   setEasy(!easy);
    // };

    // const Medium = (e) => {
    //   setMedium(!medium);
    // };
    // </div>

    const handleSendSelection = (event) => {
        setComplexity(event.target.value);
        setAll(all === 'Y' ? 'N' : 'Y')
      }
    
      const handleSendSelectionEasy = (event) => {
        setComplexity(event.target.value);
        setEasy(easy === 'Y' ? 'N' : 'Y')
      }

      const handleSendSelectionMed = (event) => {
        setComplexity(event.target.value);
        setMedium(medium === 'Y' ? 'N' : 'Y')
      }

      const handleSendSelectionHard = (event) => {
        setComplexity(event.target.value);
        setHard(hard === 'Y' ? 'N' : 'Y')
      }
      
    
    const handleSelectionApt = (event) => {
        setQuestionType(event.target.value);
        setChecked(event.target.checked);
        setCheckApt(checkApt === 'Y' ? 'N' : 'Y')
      }

      const handleSelectionCod = (event) => {
        setQuestionType(event.target.value);;
        setCheckCod(checkCod === 'Y' ? 'N' : 'Y')
      }
      
      const handleSelectionMul = (event) => {
        setQuestionType(event.target.value);
        setCheckMul(checkMul === 'Y' ? 'N' : 'Y')
      }

      const handleSelectionDes = (event) => {
        setQuestionType(event.target.value);
        setCheckDes(checkDes === 'Y' ? 'N' : 'Y');
      }

      const handleSelection = (event) => {
        setQuestionType(event.target.value);
        setCheckVal(checkVal === 'Y' ? 'N' : 'Y');
      }

    const saveFilter = () => {
      save();
      onClose();
    }

    const handleClearAll = () => {
      setCheckVal(checkVal === 'Y' ? 'N' : '');
      setCheckApt(checkApt === 'Y' ? 'N' : '');
      setCheckCod(checkCod === 'Y' ? 'N' : '');
      setCheckDes(checkDes === 'Y' ? 'N' : '');
      setCheckMul(checkMul === 'Y' ? 'N' : '');

      setAll(all === 'Y' ? 'N' : '');
      setEasy(easy === 'Y' ? 'N' : '');
      setMedium(medium === 'Y' ? 'N' : '');
      setHard(hard === 'Y' ? 'N' : '');  
    }
    
    return (
        <div>
      
        <Popover
        id="simple-menu"
        open={isOpen}
        // anchorEl={anchorEl}
        onClose={onClose}
        style={{ marginTop: 270, height: "25rem", }}
        anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{ root: classes.selectDialogContainer }}
      >
         <p
          className={styles.clearButton}
          onClick={() => {
            handleClearAll();
            unCheck();
          }}
        >
          Clear all
        </p>
        <Accordion key={1} style={{ width: 220 }}>
          <AccordionSummary
            classes={{ root: styles.accItem, expanded: styles.accExpanded }}
            expandIcon={<ExpandMoreIcon />}
            style={{
              color: "#000",
              fontWeight: 600,
              fontSize: "16px",
              backgroundColor: "transparent",
            }}
          >
            Question type
          </AccordionSummary>
          <AccordionDetails
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* {allFilters.map((element, i) => (
               <FormGroup  key={`allFilters${i}`}>
               <FormControlLabel 
                  control={
                    <Checkbox 
                    color="primary"
                    // onChange={(handleCheckBox, All)}
                    checked={checkVal === 'Y' ? true : false}
                    onClick={(event) => {
                      handleSelection(event);
                  }}
                  value={element.value}
                    />
                  }
                  label={element.label}
                />
                                    </FormGroup>
                     ))} */}
                   {/* {allFilters.map((data, index) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "baseline",
                  }}
                >
                  <div className="pt-1 pb-1">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "baseline",
                      }}
                    >
                      <div className="pr-2 pl-2">
                        <input
                          name={data}
                          className="checked"
                          {...register(`${data}`)}
                          style={{
                            width: "20px",
                            height: "15px",
                            borderRadius: "5px",
                          }}
                          type="checkbox"
                          // checked={}
                        />
                      </div>
                      <div>
                        <div>
                          <div>
                            <div className="filterOption">{data}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}

                <FormGroup>
               <FormControlLabel
                  control={
                    <Checkbox 
                    color="primary"
                    checked={checkVal === 'Y' ? true : false}
                    onChange={(event) => {
                      handleSelection(event);
                  }}
                  value="All"
                    />
                  }
                  label="All"
                />
               <FormControlLabel
                  control={
                    <Checkbox 
                    color="primary"
                    checked={checkApt === 'Y' ? true : false}
                    onChange={(event) => {
                      handleSelectionApt(event);
                  }}
                  value="Aptiude"
                    />
                  }
                  label="Aptitude"
                />
               <FormControlLabel
                  control={
                    <Checkbox 
                    color="primary"
                    checked={checkCod === 'Y' ? true : false}
                    onChange={(event) => {
                      handleSelectionCod(event);
                  }}
                  value="Coding"
                    />
                  }
                  label="Coding"
                />
               <FormControlLabel
                  control={
                    <Checkbox 
                    color="primary"
                    checked={checkMul === 'Y' ? true : false}
                    onChange={(event) => {
                      handleSelectionMul(event);
                  }}
                  value="Muli choice"
                    />
                  }
                  label="Muli choice"
                />
               <FormControlLabel
                  control={
                    <Checkbox 
                    color="primary"
                    checked={checkDes === 'Y' ? true : false}
                    onChange={(event) => {
                      handleSelectionDes(event);
                  }}
                  value="Descriptive"
                    />
                  }
                  label="Descriptive"
                />
                    </FormGroup>
                               

        </AccordionDetails>
        </Accordion>

        <Accordion key={2} style={{ width: 220 }}>
          <AccordionSummary
            classes={{ root: styles.accItem, expanded: styles.accExpanded }}
            expandIcon={<ExpandMoreIcon />}
            style={{
              color: "#000",
              fontWeight: 600,
              fontSize: "16px",
              backgroundColor: "transparent",
            }}
          >
            Complexity
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: "10px 10px",
              display: "flex",
              // flexWrap: "wrap",
              flexDirection: "column",
              // gap: ,
            }}
          >
              <FormGroup >
                <FormControlLabel
                  control={
                    <Checkbox 
                    color="primary"
                    // onChange={(handleCheckBox, All)}
                    checked={all === 'Y' ? true : false}
                    onChange={(event) => {
                      handleSendSelection(event);
                  }}
                  value="All"
                    />
                  }
                  label="All"
                />
                 <FormControlLabel
                  control={
                    <Checkbox 
                    color="primary"
                    // onChange={(handleCheckBox, Easy)}
                    checked={easy === 'Y' ? true : false}
                    onChange={(event) => {
                      handleSendSelectionEasy(event);
                  }}
                  value="Easy"
                    />
                  }
                  label="Easy"
                />
                 <FormControlLabel
                  control={
                    <Checkbox 
                    color="primary"
                    // onChange={(handleCheckBox, Medium)}
                    checked={medium === 'Y' ? true : false}
                    onChange={(event) => {
                      handleSendSelectionMed(event);
                  }}
                  value="Medium"
                    />
                  }
                  label="Medium"
                />
                 <FormControlLabel
                  control={
                    <Checkbox 
                    color="primary"
                    checked={hard === 'Y' ? true : false}
                    onChange={(event) => {
                        handleSendSelectionHard(event);
                    }}
                    // onChange={(handleCheckBox, Hard)}
                    value="Hard"
                    />
                  }
                  label="Hard"
                />
                    </FormGroup>
        </AccordionDetails>
        </Accordion>
        <div style={{ textAlign: "right", padding: 10, marginLeft: "4rem" }}>
        <TervButtonFilled 
        text="Save"
         onClick={() => {
          saveFilter();
          }} />
        </div>
      </Popover>
      </div>
    );
  };
  
  export default DataTable;
  