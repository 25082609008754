import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { sessionPrivileges } from "privileges";
import { emailRegex, USER_TYPES } from "pages/utils/constants";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import styles from "./UserManagementDumb.module.scss";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { ReactSelectTervStyle } from "pages/utils/ReactSelectTervStyle";
import AdvancedStudentSelectionAlt from "pages/Tasks/Components/AdvancedStudentSelectionAlt";
import { Button } from "@material-ui/core";
import { GetObjectArray } from "pages/utils/GetObjectArray";
import moment from "moment";
import StyledNumberInput from "components/StyledNumberInput";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { GENDER } from "pages/Prepare/Content";

const UserManagementDumb = ({
  userRoles,
  createUser,
  updateUser,
  getUserById,
  validateUserEmail,
  id,
  getAllRoles,
  getReportingTos,
  allCustomers,
  reportingTos,
  existingEmailId,
  customer,
  viewOnly,
  activePage,
  itemsPerPage,
}) => {
  const methods = useForm({ mode: "onBlur" });
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    watch,
    control,
    reset,
  } = methods;

  const [pwdVis, setPwdVis] = useState(false);
  const [cfmPwdVis, setCfmPwdVis] = useState(false);
  const userPrivileges = sessionPrivileges();

  useEffect(() => {
    if (watch("customerId")) {
      getAllRoles(watch("customerId"));
      getReportingTos(watch("customerId"));
    }
  }, [watch("customerId")]);

  useEffect(() => {
    if (id) {
      getUser(id);
    } else if (customer) {
      reset({ customerId: customer });
    }
  }, [reset]);

  const getUser = async (id) => {
    try {
      const userData = await getUserById(id);
      if (userData) {
        reset({
          firstName: userData.firstName,
          lastName: userData.lastName,
          emailId: userData.emailId,
          customerId: userData.customer?.id || "",
          phoneNumber: userData.phoneNumber,
          dateOfBirth: userData.dateOfBirth,
          password: userData.password,
          confirmPassword: userData.password,
          gender: userData.gender,
          userType:
            userData.userType === "student" ? "learner" : userData.userType,
          ...(userData.registrationNo && {
            registrationNo: userData.registrationNo,
          }),
          roleList: {
            label: userData.roles?.[0]?.name || "",
            value: userData.roles?.[0]?.name || "",
          },
          ...(userData.reportingTo && {
            reportingTo: userData.reportingTo,
          }),
          ...(userData.roles?.[0]?.name === "MENTOR" && {
            assignedStudents: userData.assignedStudents,
            filterType: userData.filterType,
          }),
          ...(userData.ugcgpa && {
            ugcgpa: userData.ugcgpa,
          }),

          ...(userData.department && {
            department: userData.department,
          }),
          ...(userData.section && {
            section: userData.section,
          }),
          ...(userData.xmark && {
            xmark: userData.xmark,
          }),
          ...(userData.xiimark && {
            xiimark: userData.xiimark,
          }),
        });
      }
    } catch (e) {}
  };

  return (
    <div className="page-content">
      <Breadcrumbs title="Users" breadcrumbItem="Create User" />
      <div className={styles.formWrapper}>
        <form
          onSubmit={handleSubmit(id ? updateUser : createUser)}
          className={styles.container}
        >
          <div className="terv-row">
            <div>
              <label className="terv-label" htmlFor="FirstName">
                {RequiredIndicator("First Name ")}
              </label>
              <input
                id="FirstName"
                {...register("firstName", {
                  required: true,
                  maxLength: 20,
                  minLength: 3,
                })}
                className="terv-form terv-form-md"
                disabled={userPrivileges.isAdmin || viewOnly}
              />
              {errors?.firstName?.type === "required" && (
                <div style={{ color: "red", maxWidth: 200 }}>
                  <p>Please enter a valid first name</p>
                </div>
              )}
              {errors?.firstName?.type === "maxLength" && (
                <div style={{ color: "red", maxWidth: 200 }}>
                  <p>First name cannot exceed 20 characters</p>
                </div>
              )}
              {errors?.firstName?.type === "minLength" && (
                <div style={{ color: "red", maxWidth: 200 }}>
                  <p>First name cannot be below 3 characters</p>
                </div>
              )}
            </div>
            <div>
              <label className="terv-label" htmlFor="LastName">
                {RequiredIndicator("Last Name ")}
              </label>
              <input
                id="LastName"
                {...register("lastName", {
                  required: "Please enter a valid last name",
                })}
                className="terv-form terv-form-md"
                disabled={userPrivileges.isAdmin || viewOnly}
              />
              {errors?.lastName && (
                <div style={{ color: "red", maxWidth: 200 }}>
                  {errors?.lastName.message}
                </div>
              )}
            </div>
          </div>
          <div className="terv-row">
            <div>
              <label className="terv-label" htmlFor="Email">
                {RequiredIndicator("Email ")}
              </label>
              <input
                id="Email"
                {...register("emailId", {
                  required: "Please enter a valid email",
                  pattern: {
                    value: emailRegex,
                    message: "Please enter a valid email",
                  },
                  validate: async (value) => {
                    if (value === existingEmailId) return true;
                    const res = await validateUserEmail(value);
                    return (
                      !res ||
                      "This email is already in use. Please enter a different email"
                    );
                  },
                })}
                className="terv-form terv-form-md"
                disabled={userPrivileges.isAdmin || viewOnly || !!id}
              />
              {errors?.emailId && (
                <div style={{ color: "red", maxWidth: 200 }}>
                  {errors?.emailId.message}
                </div>
              )}
            </div>
            <div>
              <label className="terv-label" htmlFor="RegNo">
                {RequiredIndicator("Registration Number ")}
              </label>
              <input
                id="RegNo"
                {...register("registrationNo", {
                  required: "Please enter a valid registration number",
                })}
                className="terv-form terv-form-md"
                disabled={userPrivileges.isAdmin || viewOnly}
              />
              {errors?.registrationNo && (
                <div style={{ color: "red", maxWidth: 200 }}>
                  {errors?.registrationNo.message}
                </div>
              )}
            </div>
          </div>
          <div className="terv-row">
            <div>
              <label className="terv-label" htmlFor="Customer">
                {RequiredIndicator("Customer ")}
              </label>
              <Controller
                name="customerId"
                control={control}
                rules={{
                  required: "Please choose a customer",
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    inputId="Customer"
                    value={GetObjectFromString(allCustomers, value)}
                    onChange={(val) => onChange(val.value)}
                    styles={ReactSelectTervStyle("lg")}
                    options={allCustomers}
                    maxMenuHeight={200}
                    isDisabled={userPrivileges.isAdmin || viewOnly}
                  />
                )}
              />
              {errors?.customerId && (
                <div style={{ color: "red", maxWidth: 200 }}>
                  {errors?.customerId.message}
                </div>
              )}
            </div>
          </div>
          <div className="terv-row">
            <div>
              <label className="terv-label" htmlFor="Customer">
                {RequiredIndicator("Gender")}
              </label>
              <Controller
                name="gender"
                control={control}
                rules={{
                  required: "Please choose a customer",
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    inputId="gender"
                    value={GetObjectFromString(GENDER, value)}
                    onChange={(val) => onChange(val.value)}
                    styles={ReactSelectTervStyle("md")}
                    options={GENDER}
                    maxMenuHeight={200}
                    isDisabled={viewOnly}
                  />
                )}
              />
              {errors?.gender && (
                <div style={{ color: "red", maxWidth: 200 }}>
                  {errors?.gender.message}
                </div>
              )}
            </div>
            <div>
              <label className="terv-label" htmlFor="Mobile">
                {RequiredIndicator("Mobile Number ")}
              </label>
              <input
                type="number"
                id="Mobile"
                {...register("phoneNumber", {
                  required: "Please enter a valid phone number",
                  pattern: {
                    value: /^[0-9]*$/g,
                    message: "Please enter a valid phone number",
                  },
                  minLength: {
                    value: 10,
                    message: "Mobile number must contain at least 10 digits",
                  },
                })}
                onInput={(e) => {
                  e.target.value = Math.max(
                    0,
                    parseInt(e.target.value)
                  )
                    .toString()
                    .slice(0, 10);
                }}
                className="terv-form terv-form-md"
                disabled={userPrivileges.isAdmin || viewOnly}
              />
              {errors?.phoneNumber && (
                <div style={{ color: "red", maxWidth: 200 }}>
                  {errors?.phoneNumber.message}
                </div>
              )}
            </div>
            <div>
              <label className="terv-label" htmlFor="DOB">
                {RequiredIndicator("Date of Birth ")}
              </label>
              <input
                id="DOB"
                {...register("dateOfBirth", {
                  required: "Please enter a valid date of birth",
                  validate: (val) =>
                    moment(val).diff(moment(), "minutes") < 0 ||
                    "Date of birth cannot be after today",
                })}
                className="terv-form terv-form-md"
                type="date"
                disabled={userPrivileges.isAdmin || viewOnly}
              />
              {errors?.dateOfBirth && (
                <div style={{ color: "red", maxWidth: 200 }}>
                  {errors?.dateOfBirth.message}
                </div>
              )}
            </div>
          </div>
          {userPrivileges.isSuperAdmin && (
            <>
              {" "}
              <div className="terv-row">
                <div>
                  <label className="terv-label" htmlFor="PWD">
                    {RequiredIndicator("Password ")}
                  </label>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: "Please enter a valid password",
                      minLength: {
                        value: 6,
                        message: "Password must contain at least 6 characters",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        inputId="PWD"
                        {...field}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {pwdVis ? (
                                <VisibilityIcon
                                  onClick={() => setPwdVis((pre) => !pre)}
                                  classes={{ root: "terv-password-icon" }}
                                />
                              ) : (
                                <VisibilityOffIcon
                                  onClick={() => setPwdVis((pre) => !pre)}
                                  classes={{ root: "terv-password-icon" }}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        classes={{ root: "terv-form-with-icon" }}
                        type={pwdVis ? "text" : "password"}
                        disabled={viewOnly}
                        autoComplete="new-password"
                      />
                    )}
                  />
                  {errors?.password && (
                    <div style={{ color: "red", maxWidth: 200 }}>
                      {errors?.password.message}
                    </div>
                  )}
                </div>
                <div>
                  <label className="terv-label" htmlFor="CNPWD">
                    {RequiredIndicator("Confirm Password ")}
                  </label>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{
                      required: "Please enter a valid confirm password",
                      validate: (val) =>
                        watch("password") === val || "Passwords don't match",
                    }}
                    render={({ field }) => (
                      <TextField
                        inputId="CNPWD"
                        {...field}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {cfmPwdVis ? (
                                <VisibilityIcon
                                  onClick={() => setCfmPwdVis((pre) => !pre)}
                                  classes={{ root: "terv-password-icon" }}
                                />
                              ) : (
                                <VisibilityOffIcon
                                  onClick={() => setCfmPwdVis((pre) => !pre)}
                                  classes={{ root: "terv-password-icon" }}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        classes={{ root: "terv-form-with-icon" }}
                        type={cfmPwdVis ? "text" : "password"}
                        disabled={viewOnly}
                        autoComplete="new-password"
                      />
                    )}
                  />
                  {errors?.confirmPassword && (
                    <div style={{ color: "red", maxWidth: 200 }}>
                      {errors?.confirmPassword.message}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="terv-row">
            <div>
              <label className="terv-label" htmlFor="UserType">
                {RequiredIndicator("User Type ")}
              </label>
              <Controller
                name="userType"
                control={control}
                rules={{ required: "Please choose a user type" }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    inputId="UserType"
                    options={USER_TYPES}
                    onChange={(val) => onChange(val.value)}
                    value={GetObjectFromString(USER_TYPES, value)}
                    styles={ReactSelectTervStyle("md")}
                    maxMenuHeight={200}
                    isDisabled={userPrivileges.isAdmin || viewOnly}
                    inputProps={{
                      autoComplete: "disabled", // disable autocomplete and autofill
                  }}
                  />
                )}
              />
              {errors?.userType && (
                <div style={{ color: "red", maxWidth: 200 }}>
                  {errors?.userType.message}
                </div>
              )}
            </div>
            <div>
              <label className="terv-label" htmlFor="Role">
                {RequiredIndicator("Role ")}
              </label>
              <Controller
                inputId="Role"
                name="roleList"
                control={control}
                rules={{ required: "Please choose a role" }}
                render={({ field }) => (
                  <Select
                    options={userRoles}
                    {...field}
                    styles={ReactSelectTervStyle("md")}
                    maxMenuHeight={200}
                    isDisabled={userPrivileges.isAdmin || viewOnly}
                    filterOption={(e) =>
                      watch("userType")?.toLowerCase() === "admin"
                        ? e?.data?.roleType?.toLowerCase() === "mentor" ||
                          e?.data?.roleType?.toLowerCase() === "admin"
                        : e?.data?.roleType?.toLowerCase() === "learner"
                    }
                  />
                )}
              />
              {errors?.roleList && (
                <div style={{ color: "red", maxWidth: 200 }}>
                  {errors?.roleList.message}
                </div>
              )}
            </div>
          </div>

          <div className="terv-row">
            <div>
              <label className="terv-label" htmlFor="RepTo">
                Reporting To
              </label>

              <p className="terv-sublabel">
                If you want the user to report to a person, select from the
                dropdown below
              </p>
              <Controller
                name="reportingTo"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    inputId="RepTo"
                    options={reportingTos}
                    value={GetObjectArray(reportingTos, value)}
                    onChange={(val) =>
                      onChange(val ? val.map((it) => it.value) : [])
                    }
                    isMulti
                    styles={ReactSelectTervStyle("lg", true)}
                    maxMenuHeight={200}
                    isDisabled={userPrivileges.isAdmin || viewOnly}
                  />
                )}
              />
            </div>
          </div>
          <hr className="terv-divider" />
          {watch("userType") === "learner" && (
            <>
              <div className="terv-row">
                <div>
                  <label className="terv-label" htmlFor="XMARK">
                    X mark
                  </label>
                  <Controller
                    name="xmark"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <StyledNumberInput
                        onChange={onChange}
                        value={value || 0}
                        inputId="XMARK"
                        className="terv-form-with-icon-sm"
                      />
                    )}
                  />
                </div>
                <div>
                  <label className="terv-label" htmlFor="XIIMARK">
                    XII mark
                  </label>
                  <Controller
                    name="xiimark"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <StyledNumberInput
                        onChange={onChange}
                        value={value || 0}
                        inputId="XIIMARK"
                        className="terv-form-with-icon-sm"
                      />
                    )}
                  />
                </div>
                <div>
                  <label className="terv-label" htmlFor="CGPA">
                    CGPA
                  </label>
                  <Controller
                    name="ugcgpa"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <StyledNumberInput
                      onChange={(val) => {
                        let newValue = parseFloat(val);
                        if (newValue > 10) {
                          newValue = 0; 
                        }
                        onChange(newValue);
                      }}
                        value={value || 0}
                        inputId="CGPA"
                        className="terv-form-with-icon-sm"
                      />
                    )}
                  />
                </div>
                <div>
                  <label className="terv-label" htmlFor="Dept">
                    {RequiredIndicator("Department ")}
                  </label>
                  <input
                    id="Dept"
                    {...register("department", {
                      required: "Please enter a valid department",
                    })}
                    className="terv-form terv-form-md"
                    disabled={userPrivileges.isAdmin || viewOnly}
                  />
                  {errors?.department && (
                    <div style={{ color: "red", maxWidth: 200 }}>
                      {errors?.department.message}
                    </div>
                  )}
                </div>
                <div>
                  <label className="terv-label" htmlFor="Section">
                    Section
                  </label>
                  <input
                    id="Section"
                    {...register("section")}
                    className="terv-form terv-form-md"
                    disabled={userPrivileges.isAdmin || viewOnly}
                  />
                </div>
              </div>
              <hr className="terv-divider" />
            </>
          )}
          {watch("roleList")?.roleType === "MENTOR" && (
            <>
              <div style={{ width: "32rem", marginBottom: "1.5rem" }}>
                <AdvancedStudentSelectionAlt
                  customerId={watch("customerId")}
                  hookFormControls={methods}
                  viewOnly={viewOnly}
                />
              </div>
              <hr className="terv-divider" />
            </>
          )}
          {!viewOnly && (
            <div style={{ width: "32rem" }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disableElevation
                classes={{ root: styles.submitButton }}
                disabled={isSubmitting}
              >
                {id ? "Update" : "Add"}
              </Button>
            </div>
          )}
        </form>
        <Link
          to={{
            pathname: "/userList",
            state: {
              customer: customer,
              activePage: activePage,
              itemsPerPage: itemsPerPage,
            },
          }}
          className="terv-back"
        >
          <i className="mdi mdi-arrow-left mr-1" /> Back to User List{" "}
        </Link>
      </div>
    </div>
  );
};

export default UserManagementDumb;
