import { useState, useEffect, useRef } from "react";
import { useSpring, config } from "react-spring";
import Select from "react-select";
import StyledNumberInput from "components/StyledNumberInput";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddMediaComponent } from "./AddMediaComponent";
import { LessonFormSchema } from "./ValidationSchemas";
import { ErrorBlock } from "components/ErrorBlock";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import { useHistory } from "react-router";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { ReactSelectTervStyle } from "pages/utils/ReactSelectTervStyle";
import { TervButtonOutlined, TervButtonFilled } from "components/TervButtons";
import Tooltip from "@material-ui/core/Tooltip";
import VideoPlayer from "./videoPlayer";
import styles from "./EditLesson.module.scss";
import AnimatedMount from "./AnimatedMount";
import { AddMediaComponentPre } from "./AddMediaComponentPre";

const timePad = (val) => ("0" + Math.floor(val)).slice(-2);
function timeFormat(time) {
  const sec = time % 60;
  const hr = Math.floor(time / 3600);
  return `${hr > 0 ? `${timePad(hr)}:` : ""}${timePad(
    (time % 3600) / 60
  )}:${timePad(time % 60)}`;
}
function timeObjectFormat(time) {
  return {hr: Math.floor(time / 3600), min: Math.floor((time % 3600) / 60), sec: Math.floor(time % 60)}
}

const EditLesson = ({
  handleChangeContent,
  currentLesson,
  addLesson,
  LessonIcons,
  setFormValue2,
  formValue2,
  customerName,
  courseId,
  customerId,
  practiceLabs,
  selectedPracticeName,
  setSelectedPracticeName,
  // selectedUserList,
  // sDate,
  // eDate,
  selSec,
  sectionValues,
  prepPracticeId,
  selCurr,
  prepPracticeRes,
  setPrepPracticeRes,
  createdFromPrepare,
  // cName,
  // cPer,
  currentSection,
  courseData,
  assessmentList,
  close,
}) => {
  const editIcon = (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.474 3.40783L15.592 5.52483L13.474 3.40783ZM14.836 1.54283L9.109 7.26983C8.81309 7.56533 8.61128 7.94181 8.529 8.35183L8 10.9998L10.648 10.4698C11.058 10.3878 11.434 10.1868 11.73 9.89083L17.457 4.16383C17.6291 3.99173 17.7656 3.78742 17.8588 3.56256C17.9519 3.33771 17.9998 3.09671 17.9998 2.85333C17.9998 2.60994 17.9519 2.36895 17.8588 2.14409C17.7656 1.91923 17.6291 1.71492 17.457 1.54283C17.2849 1.37073 17.0806 1.23421 16.8557 1.14108C16.6309 1.04794 16.3899 1 16.1465 1C15.9031 1 15.6621 1.04794 15.4373 1.14108C15.2124 1.23421 15.0081 1.37073 14.836 1.54283V1.54283Z"
        stroke="#1BA94C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 13V16C16 16.5304 15.7893 17.0391 15.4142 17.4142C15.0391 17.7893 14.5304 18 14 18H3C2.46957 18 1.96086 17.7893 1.58579 17.4142C1.21071 17.0391 1 16.5304 1 16V5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H6"
        stroke="#1BA94C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const deleteIcon = (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.34 8.3218C15.4361 8.23224 15.5136 8.12464 15.568 8.00513C15.6225 7.88563 15.6529 7.75656 15.6575 7.62531C15.6621 7.49405 15.6408 7.36318 15.5948 7.24015C15.5488 7.11713 15.4791 7.00437 15.3895 6.90831C15.2999 6.81224 15.1923 6.73476 15.0728 6.68029C14.9533 6.62581 14.8243 6.59541 14.693 6.59081C14.5618 6.58621 14.4309 6.60751 14.3079 6.6535C14.1848 6.69948 14.0721 6.76924 13.976 6.85881L11.05 9.58681L8.32201 6.65981C8.13948 6.47285 7.89102 6.36476 7.6298 6.35867C7.36859 6.35258 7.11536 6.44897 6.92431 6.62721C6.73327 6.80545 6.61957 7.05139 6.60755 7.3124C6.59553 7.57341 6.68615 7.82876 6.86001 8.02381L9.58801 10.9498L6.66101 13.6778C6.56155 13.7665 6.48082 13.8741 6.42354 13.9944C6.36626 14.1147 6.3336 14.2452 6.32747 14.3783C6.32135 14.5114 6.34188 14.6444 6.38786 14.7694C6.43384 14.8944 6.50434 15.009 6.59523 15.1065C6.68612 15.2039 6.79555 15.2822 6.91711 15.3367C7.03867 15.3912 7.16991 15.4209 7.3031 15.424C7.4363 15.4271 7.56877 15.4036 7.69274 15.3548C7.81671 15.306 7.92968 15.2329 8.02501 15.1398L10.951 12.4128L13.679 15.3388C13.7671 15.4401 13.8747 15.5226 13.9953 15.5814C14.116 15.6403 14.2472 15.6742 14.3813 15.6812C14.5153 15.6883 14.6494 15.6682 14.7756 15.6223C14.9017 15.5764 15.0173 15.5056 15.1155 15.414C15.2137 15.3225 15.2924 15.2121 15.3471 15.0895C15.4017 14.9669 15.4311 14.8345 15.4334 14.7003C15.4358 14.5661 15.4112 14.4328 15.3609 14.3083C15.3107 14.1839 15.2359 14.0708 15.141 13.9758L12.414 11.0498L15.34 8.3218Z"
        fill="#EB5757"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11C0 4.925 4.925 0 11 0C17.075 0 22 4.925 22 11C22 17.075 17.075 22 11 22C4.925 22 0 17.075 0 11ZM11 20C9.8181 20 8.64778 19.7672 7.55585 19.3149C6.46392 18.8626 5.47177 18.1997 4.63604 17.364C3.80031 16.5282 3.13738 15.5361 2.68508 14.4442C2.23279 13.3522 2 12.1819 2 11C2 9.8181 2.23279 8.64778 2.68508 7.55585C3.13738 6.46392 3.80031 5.47177 4.63604 4.63604C5.47177 3.80031 6.46392 3.13738 7.55585 2.68508C8.64778 2.23279 9.8181 2 11 2C13.3869 2 15.6761 2.94821 17.364 4.63604C19.0518 6.32387 20 8.61305 20 11C20 13.3869 19.0518 15.6761 17.364 17.364C15.6761 19.0518 13.3869 20 11 20Z"
        fill="#EB5757"
      />
    </svg>
  );
  const plusIcon = (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 6H8V0H6V6H0V8H6V14H8V8H14V6Z" fill="#1BA94C" />
    </svg>
  );
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    setFocus,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(LessonFormSchema(currentLesson.type)),
  });
  
  const [duration, setDuration] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [mediaName, setMediaName] = useState("");

  const videoContainer = useRef(null);
  const videoElement = useRef(null);

  const updateDuration = (duration) => {
    setDuration(duration);
    setValue("duration", timeFormat(parseFloat(duration)));
    const {hr, min, sec} = timeObjectFormat(parseFloat(duration));
    setValue("durationHour", hr);
    setValue("durationMin", min);
    setValue("durationSec", sec);
    setDisableSubmit(false);
  };
  const {
    isLoading,
    playerState,
    playerCtrls,
    togglePlay,
    toggleMute,
    getProgress,
    setProgress,
    onLoadedData,
    setProgressEvent,
    setVolumeEvent,
    toggleFullScreen,
    useStyles,
  } = VideoPlayer(videoContainer, videoElement, updateDuration);
  // const [duration, setDuration] = useState(0);
  // let timeOut = null;
  const css = useStyles();

  useEffect(() => {
    if (currentLesson.type === "Video" || currentLesson.type === "Audio") {
      const mName = currentLesson.media;
      if(mName){
        setMediaName(mName);
      }
    }
    updateDuration(duration);
  }, []);

  useEffect(() => {
    if (!mediaName) updateDuration(0);
  }, [mediaName]);

  useEffect(() => {
    if (currentLesson?.name) {
      reset({
        name: currentLesson.name,
        durationHour: +currentLesson.durationHour,
        durationMin: +currentLesson.durationMin,
        durationSec: +currentLesson.durationSec,
        media: currentLesson.media,
        documentType: currentLesson.documentType,
        externalResourceId: currentLesson?.externalResourceId,
        completionPercentage: currentLesson.completionPercentage,
        completionPercentage: currentLesson.completionPercentage,
        externalResourceName: currentLesson.externalResourceName,
        externalResource_isCreatedInPrepare:
          currentLesson.externalResource_isCreatedInPrepare,
      });
    }
  }, [reset]);

  useEffect(() => {
    if (prepPracticeRes) {
      // setValue("externalResourceId", prepPracticeRes?.id)
      // setSelectedPracticeName(prepPracticeRes?.name);
      if (currentLesson.type === "Live class") {
        //setSelectedPracticeName(prepPracticeRes?.meetingName);
        // currentLesson.externalResourceName = prepPracticeRes?.meetingName;
        // currentLesson.externalResourceId = prepPracticeRes?.id;
        // currentLesson.externalResource_isCreatedInPrepare = prepPracticeRes?.createdInPrepare;
        // currentLesson.name = contentName;
      } else {
        setSelectedPracticeName(prepPracticeRes?.name);
      }
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [values, setValues] = useState(false);

  const Submit = (formValues) => {
    addLesson({ ...currentLesson, ...formValues });
    setFormValue2(formValues);
  };
  
  const currentLessonType = LessonIcons.find(
    (i) => i.label === currentLesson.type
  );
  const history = useHistory();

  const DOCUMENTTYPE = [
    { label: "PDF", value: "pdf" },
   // { label: "PPT", value: "pptx" },
   // { label: "Words", value: "docx" },
  ];

  return (
    <div>
      <AnimatedMount>
        <form className={styles.main} onSubmit={handleSubmit(Submit)}>
          <p className={styles.changeBlock}>
            <span>Content type: {currentLesson.type}</span>
            <span onClick={handleChangeContent}>Change</span>
          </p>
          <div>
            <label className="terv-label">
              {RequiredIndicator("Content name")}
            </label>
            <input className={styles.nameInput} {...register("name")} />
            {errors.name && (
              <ErrorBlock eType="div" errorText={errors.name.message || ""} />
            )}
            {values && (
              <p style={{color:"red"}}>Please enter a valid name</p>
            )}
          </div>
          {currentLesson.type === "Video" || currentLesson.type === "Audio" ? (
            <>
              {mediaName && mediaName !== "" && (
              <div className={styles.fullSize} style={{opacity:0, display: "none"}}>
                <div className={ css.video + ` ${playerCtrls.isFullScreen ? "fullScreen" : ""}`} ref={videoContainer} >
                  <div className="player">
                    <video
                      className="video"
                      ref={videoElement}
                      // controls
                      onTimeUpdate={getProgress}
                      onLoadedData={onLoadedData}
                    >
                      <source src={mediaName} type={currentLesson.type === "Video" ? "video/mp4":"audio/mpeg"}></source>
                    </video>
                  </div>
                </div>
              </div>
            )}
            <div className={styles.columnForm}>
              <label className="terv-label">
                {RequiredIndicator("Duration")}
              </label>
              <StyledNumberInput
                disable={true}
                type="text"
                value={timeFormat(duration)}
              />
              {errors.durationMin && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.durationMin.message || ""}
                />
              )}
            </div>
            <div className={styles.columnForm} style={{height: 0, opacity: 0, position: "absolute" }}>
              <label className="terv-label">
                {RequiredIndicator("Duration in min(s)")}
              </label>
              <Controller
                name="durationHour"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <StyledNumberInput
                    value={value || 0}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
              <Controller
                name="durationMin"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <StyledNumberInput
                    value={value || 0}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
              <Controller
                name="durationSec"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <StyledNumberInput
                    value={value || 0}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>
            </>
          ) : currentLesson.type == "Study material" ? (
            <div>
              <div>
                <label className="terv-label">
                  {RequiredIndicator("Document Type")}
                </label>
                <Controller
                  name="documentType"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={GetObjectFromString(DOCUMENTTYPE, value)}
                        onChange={(val) => onChange(val.value)}
                        expanded
                        options={DOCUMENTTYPE}
                        styles={ReactSelectTervStyle("md", true)}
                      />
                    );
                  }}
                />

                {errors.documentType && (
                  <ErrorBlock
                    eType="div"
                    errorText={errors.documentType.message || ""}
                  />
                )}
              </div>
              <br />
              <div>
                <label className="terv-label">
                  {RequiredIndicator("Content")}
                </label>
                {currentLessonType?.value?.isMedia ? (
                  <Controller
                    name="media"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AddMediaComponent
                        handleChange={onChange}
                        value={value}
                        mediaType={`.${watch("documentType")}`}
                        moduleName={"prepare"}
                        customerName={customerName}
                        currentId={courseId}
                      />
                    )}
                  />
                ) : (
                  <input
                    {...register("media")}
                    className={styles.nameInput}
                  />
                )}

                {errors.media && (
                  <ErrorBlock
                    eType="div"
                    errorText={errors.media.message || ""}
                  />
                )}
              </div>
            </div>
          ) : currentLesson.type == "Scrom" ? (
            <div>
              <label className="terv-label">
                {RequiredIndicator("Secret Id")}
              </label>
              <input className={styles.nameInput} {...register("secretId")} />
              {errors.secretId && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.secretId.message || ""}
                />
              )}
            </div>
          ) : currentLesson.type == "Practice lab" ? (
            <>
              <div className={styles.columnForm} style={{ display: "flex" }}>
                <div>
                  <label className="terv-label">
                    {RequiredIndicator("Completion criteria(%)")}
                  </label>
                  <Controller
                    name="completionPercentage"
                    control={control}
                    render={({ field: { onChange, value, onBlur } }) => (
                      <StyledNumberInput
                        value={value || 0}
                        onChange={(val) => {
                          let newValue = parseFloat(val);
                          if (newValue > 100) {
                            newValue = 0; 
                          }
                          onChange(newValue);
                        }}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  {errors.completionPercentage && (
                    <ErrorBlock
                      eType="div"
                      errorText={errors.completionPercentage.message || ""}
                    />
                  )}
                </div>
              </div>
              <div className={styles.columnForm}>
                {!currentLesson.externalResource_isCreatedInPrepare ? (
                  <>
                    <div>
                      <div className={styles.orDivision}>
                        <div style={{ marginLeft: "0rem" }}>
                          {!currentLesson.externalResource_isCreatedInPrepare && (
                            <>
                              <label className="terv-label">
                                {RequiredIndicator("Select practice package")}
                              </label>
                              <Controller
                                name="externalResourceId"
                                control={control}
                                render={({
                                  field: { onChange, value, onBlur },
                                }) => (
                                  <Select
                                    value={GetObjectFromString(
                                      practiceLabs,
                                      value
                                    )}
                                    onChange={(val) => {
                                      onChange(val.value);
                                      // setSelectedPracticeName(val.label);
                                      currentLesson.externalResourceName =
                                        val.label;
                                      setValue(
                                        "externalResourceId",
                                        val.value
                                      );
                                    }}
                                    expanded
                                    options={practiceLabs}
                                    styles={ReactSelectTervStyle("", true)}
                                    onBlur={onBlur}
                                  />
                                )}
                              />
                              {errors.externalResourceId && (
                                <ErrorBlock
                                  eType="div"
                                  errorText={
                                    errors.externalResourceId.message || ""
                                  }
                                />
                              )}
                            </>
                          )}
                        </div>
                        <span style={{ marginTop: "1.5rem" }}>or</span>
                        <TervButtonOutlined
                          text={"Create a new package"}
                          type="button"
                          color="#1BA94C"
                          icon={plusIcon}
                          onClick={() => {
                            if(watch("name")?.length < 1){
                              setValues(true);
                            }
                            else {
                            currentLesson.name = watch("name");
                            currentLesson.completionPercentage = watch(
                              "completionPercentage"
                            );
                            history.push({
                              pathname: "/manage-lab",
                              state: {
                                customer: customerId,
                                customerName: customerName,
                                fromPrepare: true,
                                courseId: courseId,
                                // contentName: watch("name"),
                                // completionCriteria: watch("completionPercentage"),
                                selectedUserList: courseData.userIdList,
                                sDate: courseData.startDate,
                                eDate: courseData.endDate,
                                // selSec: selSec,
                                currentLesson: currentLesson,
                                sectionValues: sectionValues,
                                currentSection: currentSection,
                              },
                            });
                          }}}
                          style={{
                            border: "1px solid #1BA94C",
                            // marginLeft: "9.5rem",
                            marginTop: "1.7rem",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      {!open ? (
                        <div>
                          <label className="terv-label">
                            Package created
                          </label>
                          <div className={styles.packageContainer}>
                            <div className={styles.packageBox}>
                              <Tooltip
                                title={currentLesson.externalResourceName}
                                arrow
                                placement="top-start"
                              >
                                <p className={styles.packageNameStyle}>
                                  {currentLesson.externalResourceName}
                                </p>
                              </Tooltip>

                              <p
                                className={styles.editButton}
                                onClick={() => {
                                  currentLesson.name = watch("name");
                                  currentLesson.completionPercentage = watch(
                                    "completionPercentage"
                                  );
                                  history.push({
                                    pathname: "/manage-lab",
                                    state: {
                                      fromEdittrue: true,
                                      id: watch("externalResourceId"),
                                      // contentName: watch("name"),
                                      // completionCriteria: watch("completionPercentage"),
                                      courseId: courseId,
                                      customer: customerId,
                                      customerName: customerName,
                                      fromPrepare: true,
                                      // selSec: selSec,
                                      currentLesson: currentLesson,
                                      sectionValues: sectionValues,
                                      currentSection: currentSection,
                                      selectedUserList: courseData.userIdList,
                                      sDate: courseData.startDate,
                                      eDate: courseData.endDate,
                                    },
                                  });
                                }}
                              >
                                {editIcon}
                              </p>

                              <p
                                className={styles.deleteButton}
                                onClick={() => {
                                  setValue("externalResourceId", "");
                                  setSelectedPracticeName("");
                                  // setOpen(!open);
                                  setShow(!show);
                                }}
                              >
                                {deleteIcon}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <TervButtonOutlined
                            text={"Create a new package"}
                            type="button"
                            color="#1BA94C"
                            icon={plusIcon}
                            onClick={() => {
                              currentLesson.name = watch("name");
                              currentLesson.completionPercentage = watch(
                                "completionPercentage"
                              );
                              history.push({
                                pathname: "/manage-lab",
                                state: {
                                  customer: customerId,
                                  customerName: customerName,
                                  fromPrepare: true,
                                  courseId: courseId,
                                  // contentName: watch("name"),
                                  // completionCriteria: watch("completionPercentage"),
                                  selectedUserList: courseData.userIdList,
                                  sDate: courseData.startDate,
                                  eDate: courseData.endDate,
                                  // selSec: selSec,
                                  currentLesson: currentLesson,
                                  sectionValues: sectionValues,
                                  currentSection: currentSection,
                                },
                              });
                            }}
                            style={{
                              border: "1px solid #1BA94C",
                              marginLeft: "9.5rem",
                              marginTop: "1rem",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          ) : currentLesson.type == "Live class" ? (
            <>
              <div className={styles.columnForm}>
                {!currentLesson.externalResourceId &&
                !currentLesson.externalResource_isCreatedInPrepare ? (
                  <>
                    <div>
                      <label
                        className="terv-label"
                        style={{ "text-align": "center" }}
                      >
                        Create a new live class to add in content
                      </label>
                    </div>
                    <TervButtonOutlined
                      text={"Create a new Class"}
                      type="button"
                      color="#1BA94C"
                      icon={plusIcon}
                      onClick={() => {
                        currentLesson.name = watch("name");
                        history.push({
                          pathname: "/cloudLabForm",
                          state: {
                            customerId: customerId,
                            customerName: customerName,
                            fromPrepare: true,
                            courseId: courseId,
                            // contentName: watch("name"),
                            //completionCriteria: watch("completionPercentage"),
                            participantUsers: courseData.userIdList,
                            sDate: courseData.startDate,
                            eDate: courseData.endDate,
                            // selSec: selSec,
                            currentLesson: currentLesson,
                            sectionValues: sectionValues,
                            currentSection: currentSection,
                          },
                        });
                      }}
                      style={{
                        border: "1px solid #1BA94C",
                        marginLeft: "9.5rem",
                        marginTop: "1rem",
                      }}
                    />
                    {errors.externalResourceId && (
                      <ErrorBlock
                        eType="div"
                        errorText={errors.externalResourceId.message || ""}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <label className="terv-label">Live class created</label>
                    <div className={styles.packageContainer}>
                      <div className={styles.packageBox}>
                        <Tooltip
                          title={currentLesson.externalResourceName}
                          arrow
                          placement="top-start"
                        >
                          <p className={styles.packageNameStyle}>
                            {currentLesson.externalResourceName}
                          </p>
                        </Tooltip>

                        <p
                          className={styles.editButton}
                          onClick={() => {
                            let externalId = currentLesson?.externalResourceId
                              ? currentLesson.externalResourceId
                              : watch("externalResourceId");
                            currentLesson.name = watch("name");
                            history.push({
                              pathname: `/cloudLabForm/${externalId}`,

                              state: {
                                fromEdittrue: true,
                                id: externalId,
                                // contentName: watch("name"),
                                //completionCriteria: watch("completionPercentage"),
                                courseId: courseId,
                                customerId: customerId,
                                customerName: customerName,
                                fromPrepare: true,
                                // selSec: selSec,
                                currentLesson: currentLesson,
                                sectionValues: sectionValues,
                                currentSection: currentSection,
                                eDate: courseData.endDate,
                                participantUsers: courseData.userIdList,
                              },
                            });
                          }}
                        >
                          {editIcon}
                        </p>

                        <p
                          className={styles.deleteButton}
                          onClick={() => {
                            // setValue('externalResourceId', '');
                            // setSelectedPracticeName('');
                            delete currentLesson.externalResourceId;
                            delete currentLesson.externalResourceName;
                            delete currentLesson.externalResource_isCreatedInPrepare;
                          }}
                        >
                          {deleteIcon}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : currentLesson.type == "Assessment" ? (
            <>
              <div className={styles.columnForm}>
                <div>
                  <label className="terv-label">
                    {RequiredIndicator("Assessment Pass criteria (in%)")}
                  </label>
                  <Controller
                    name="completionPercentage"
                    control={control}
                    render={({ field: { onChange, value, onBlur } }) => (
                      <StyledNumberInput
                        value={value || 0}
                        onChange={(val) => {
                          let newValue = parseFloat(val);
                          if (newValue > 100) {
                            newValue = 0; 
                          }
                          onChange(newValue);
                        }}                        
                        onBlur={onBlur}
                      />
                    )}
                  />
                  {errors.completionPercentage && (
                    <ErrorBlock
                      eType="div"
                      errorText={errors.completionPercentage.message || ""}
                    />
                  )}
                </div>
                <div style={{ marginTop: "1.5rem" }}>
                  {/* {(!selectedPracticeName && !createdFromPrepare) && ( */}
                  {!currentLesson.externalResource_isCreatedInPrepare && (
                    <>
                      <label className="terv-label">
                        {RequiredIndicator("Select assessment")}
                      </label>
                      <Controller
                        name="externalResourceId"
                        control={control}
                        render={({ field: { onChange, value, onBlur } }) => (
                          <Select
                            value={GetObjectFromString(assessmentList, value)}
                            onChange={(val) => {
                              onChange(val.value);
                              currentLesson.externalResourceName = val.label;
                              setValue("externalResourceId", val.value);
                            }}
                            expanded
                            options={assessmentList}
                            styles={ReactSelectTervStyle("", true)}
                            onBlur={onBlur}
                          />
                        )}
                      />
                      {errors.externalResourceId && (
                        <ErrorBlock
                          eType="div"
                          errorText={errors.externalResourceId.message || ""}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {currentLesson.type !== "Practice lab" &&
            currentLesson.type !== "Live class" &&
            currentLesson.type !== "Assessment" &&
            currentLesson.type !== "Study material" &&
            currentLesson.type !== "Video" &&  (
              <div>
                <label className="terv-label">
                  {RequiredIndicator("Content")}
                </label>
                {currentLessonType?.value?.isMedia ? (
                  <Controller
                    name="media"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AddMediaComponent
                        handleChange={(e) => {
                          onChange(e);
                          if(currentLesson.type === "Video" || currentLesson.type === "Audio"){
                            setDisableSubmit(true);
                            setMediaName(e);
                          }
                        }}
                        value={value}
                        mediaType={currentLessonType.value.mime}
                        moduleName={"prepare"}
                        customerName={customerName}
                        currentId={courseId}
                      />
                    )}
                  />
                ) : (
                  <input
                    {...register("media")}
                    className={styles.nameInput}
                  />
                )}

                {errors.media && (
                  <ErrorBlock
                    eType="div"
                    errorText={errors.media.message || ""}
                  />
                )}
              </div>
            )}

{currentLesson.type == "Video" &&  (
              <div>
                <label className="terv-label">
                  {RequiredIndicator("Content")}
                </label>
                {currentLessonType?.value?.isMedia ? (
                  <Controller
                    name="media"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AddMediaComponentPre
                        handleChange={(e) => {
                          onChange(e);
                          if(currentLesson.type === "Video" || currentLesson.type === "Audio"){
                            setDisableSubmit(true);
                            setMediaName(e);
                          }
                        }}
                        value={value}
                        mediaType={currentLessonType.value.mime}
                        moduleName={"prepare"}
                        customerName={customerName}
                        currentId={courseId}
                      />
                    )}
                  />
                ) : (
                  <input
                    {...register("media")}
                    className={styles.nameInput}
                  />
                )}

                {errors.media && (
                  <ErrorBlock
                    eType="div"
                    errorText={errors.media.message || ""}
                  />
                )}
              </div>
            )}

          {currentLesson.type == "Practice lab" ? (
            <span
              style={{
                marginLeft: "22.5rem",
              }}
            >
              {!open ? (
                <TervButtonFilled
                  text="Add Content"
                  width="auto"
                  type="submit"
                  disabled={disableSubmit}
                />
              ) : (
                <div></div>
              )}
            </span>
          ) : (
            <TervButtonFilled text="Add Content" width="auto" type="submit" />
          )}
        </form>
      </AnimatedMount>
    </div>
  );
};

export default EditLesson;
