import React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Scroll from "react-scroll";
import uuid from "react-uuid";
import cn from "classnames";
import {
  Snackbar,
  useMediaQuery,
  ThemeProvider,
  Grid,
  Button,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import { Close, LockOutlined } from "@material-ui/icons";
import { createTheme } from "@material-ui/core/styles";
import { getData, postData, trackActivity } from "../../../api";
import { apiList } from "../../../api/list";
import apihelper from "../../../api/apiHelper";
import api from "../../../api/baseConfig";
import { useQuestionInfo } from "./hooks/use-question-info";
import { PracticeLabContext } from "./context/practice-lab.context";
import Header from "./components/header/header";
import TabBar from "./components/tab-bar/tab-bar";
import SectionSkeleton from "./components/section/section.skeleton";
import QuestionSection from "./sections/question/question";
import CodeSolutionSection from "./sections/code-solution/code-solution";
import HintsSection from "./sections/hints/hints";
import WorkspaceSection from "./sections/workspace/workspace";
import Section from "./components/section/section";
import TestCasesSection from "./sections/testcases/testcases";
import SolutionSection from "./sections/solution/solution";
import OutputSection from "./sections/output/output";
import styles from "./practice-lab.module.scss";
import Tour from "../Tour";
import { useSelector } from "react-redux";
import { toast, Slide, ToastContainer } from "react-toastify";
import { Info } from "@material-ui/icons";
import SignalWifiOffIcon from "@material-ui/icons/SignalWifiOff";
import { ModalToggle } from "pages/utils/ModalToggle";
import Modal from "@material-ui/core/Modal";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { Link } from "react-router-dom";
import Lock from "@material-ui/icons/Lock";
import { sessionPrivileges } from "privileges";

const scroll = Scroll.animateScroll;

function getMuiTheme(isLightMode, isSmallScreen) {
  return createTheme({
    palette: {
      type: isLightMode ? "light" : "dark",
      primary: isLightMode
        ? {
            contrastText: "#fff",
            main: "#794DF5",
          }
        : {
            contrastText: "#fff",
            main: "#1BA94C",
          },
    },
    overrides: {
      MuiAppBar: {
        root: {
          backgroundColor: isLightMode ? "#2A153D" : "#242744",
          borderRadius: "0 !important",
          boxShadow: "none",
        },
      },
      MuiToolbar: {
        root: {
          backgroundColor: isLightMode ? "#2A153D" : "#242744",
        },
      },
      MuiButton: {
        text: {
          padding: "6px 20px",
        },
        root: {
          color: "#fff",
          borderRadius: 4,
        },
        label: {
          fontSize: 14,
          lineHeight: 1.8,
          textTransform: "capitalize",
        },
        sizeSmall: {
          height: 28,
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: isLightMode
            ? "#F6F9FC"
            : isSmallScreen
            ? "#222"
            : "#001527",
          borderRadius: "6px !important",
          boxShadow: "none !important",
          overflow: "hidden",
        },
      },
    },
    props: {
      MuiButton: {
        disableElevation: true,
      },
    },
    typography: {
      fontFamily: "Poppins, sans-serif",
    },
  });
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#ffffff",
  },
  drawerPaper: {
    zIndex: 0,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  submission: {
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "32px",
    color: "#19181B",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "26px",
    color: "#323036",
  },
  paragraph: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#63606C",
    width: "35rem",
  },
  pos: {
    marginBottom: 12,
  },
  hackathonList: {
    width: "30rem",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
export default function PracticeLab(props) {
  const userPrivileges = sessionPrivileges();
  const classes = useStyles();
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const mediumScreen = useMediaQuery(
    "(min-width: 768px) and (max-width: 1023px)"
  );
  const largeScreen = useMediaQuery("(min-width: 1024px)");
  const [practiceLabData, setPracticeLabData] = useState(null);
  const [gettingPracticeLabData, setIfGettingPracticeLabData] = useState(true);
  const [questionIdList, setQuestionIdList] = useState([]);
  const [currentQuestionNum, setCurrentQuestionNum] = useState(1);
  const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
  const [nextQuestionId, setNextQuestionId] = useState(undefined);
  const [prevQuestionId, setPrevQuestionId] = useState(undefined);
  const [currentTab, setCurrentTab] = useState(0);
  const [isLightMode, setIfLightTheme] = useState(false);
  const [selectedOptions, updateSelectedOptions] = useState({});
  const [submittingMCQAnswer, setMCQAnswerSubmissionStatus] = useState(false);
  const [firstQuestion, setIfFirstQuestion] = useState(true);
  const [lastQuestion, setIfLastQuestion] = useState(false);
  const [hintsVisible, setHintsVisibility] = useState(false);
  const [testCasesVisible, setTestCasesVisibility] = useState(false);
  const [solutionVisible, setSolutionVisibility] = useState(false);
  const [customInput, setCustomInput] = useState("");
  const [codeTab, setCodeTab] = useState(0);
  const [workspaceTab, setWorkspaceTab] = useState(0);
  const [testCaseTab, setTestCaseTab] = useState(0);
  const [codingLanguages, setCodingLanguages] = useState([]);
  const [codingSolutions, setCodingSolutions] = useState({});
  // const [programName, setProgramName] = useState("");
  const [completedQuestions, updateCompletedQuestions] = useState([]);
  const [activity, setActivity] = useState({});
  const [submissionMessageIsVisible, setSubmissionMessageVisibility] =
    useState(false);
  const [completionMessageIsVisible, setCompletionMessageVisibility] =
    useState(false);
  const [sectionIsExpanded, setIfSectionIsExpanded] = useState(false);
  const labId = sessionStorage.getItem("labId");
  // const userId = sessionStorage.getItem("user_id");
  const userId = userPrivileges.userId;
  const userEmail = userPrivileges.userName;

  const [executingCode, setIfCodeIsExecuting] = useState(false);
  const [codeExecType, setCodeExecType] = useState("");
  const [codeExecTestCasesCount, setCodeExecTestCasesCount] = useState(0);
  const [codeExecs, setCodeExecs] = useState([]);
  const cancelToken = useRef(null);
  const [sessionId, setSessionId] = useState(null);
  const [showTour, setShowTour] = useState(false);
  const [debugcCodeConform, setDebugcCodeConform] = useState(false);
  const [langdebugValue, setLangdebugValue] = useState();
  const [online, setIfOnline] = useState(true);
  const [messageRes, setMessageRes] = useState("");
  const [resData, setResData] = useState([]);
  // const [resValue, setResvalue] = useState([]);
  const [customCheck, setCustomCheck] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentQuestionDetailsValue, setCurrentQuestionDetailsValue] =
    useState();
  const [mockAssessmentValue, setMockAssessmentValue] = useState();
  const [mockAssessmentQuestions, setMockAssessmentQuestions] = useState();
  const [mockAssessmentId, setMockAssessmentId] = useState();
  const [pathIndexMock, setPathIndexMock] = useState();
  const [currentQuestionValue, setCurrentQuestionValue] = useState([]);
  const [dropdownValue, setDropdownValue] = useState();
  const [learnPathValue, setLearnPathValue] = useState();
  const [type, setType] = useState("");
  const [currentPathOrder, setCurrentPathOrder] = useState("");
  const [popValue, setPopValue] = useState(false);
  const [questionsSet, setQuestionsSet] = useState();
  const [questionNumber, setQuestionNumber] = useState();
  const [codeErr, setCodeErr] = useState(false);

  const isFromPrepare = props?.location?.state?.isFromPrepare || false;
  const courseId = props?.location?.state?.courseId || "";
  const prepCourseId = props?.location?.state?.prepCourseId || "";
  const prepCourseName = props?.location?.state?.prepCourseName || "";
  const prepCoursePer = props?.location?.state?.prepCoursePer || "";
  const prepPracCompletionCriteria =
    props?.location?.state?.prepPracCompletionCriteria || "";
  const prepPracResId = props?.location?.state?.prepPracResId || "";
  const prepPracModId = props?.location?.state?.prepPracModId || "";
  const learningPathList = props?.location?.state?.paths || "";

  const isUnpurchasedPaidPackage = useMemo(
    () => practiceLabData?.paidPackage && !practiceLabData?.purchased,
    [practiceLabData]
  );

  const hasCompletedTour = useSelector((state) => state?.TourReducer);
  function showSnackbar() {
    const length = learningPathList.length;
    if (learnPathValue.length == 0) {
      setSubmissionMessageVisibility(true);
    } else {
      if (
        learningPathList[dropdownValue - 1]?.name ==
        learningPathList[length - 1]?.name
      ) {
        setSubmissionMessageVisibility(false);
      } else {
        setSubmissionMessageVisibility(true);
      }
    }
  }

  function QuestionLock() {
    return (
      <div className={styles.questionSolveButtonLink}>
        <Button disabled className={styles.questionSolveButton}>
          <Lock />
        </Button>
      </div>
    );
  }

  function hideSnackbar() {
    setSubmissionMessageVisibility(false);
  }

  const setCurrentQuestion = useCallback(
    async (qNum, qId) => {
      setHintsVisibility(false);
      setTestCasesVisibility(false);
      setSolutionVisibility(false);
      setIfSectionIsExpanded(false);
      setCodeTab(0);
      setTestCaseTab(0);
      setWorkspaceTab(0);
      setCurrentTab(0);
      setIfCodeIsExecuting(false);
      setCodeExecType("");
      setCodeExecTestCasesCount(0);
      setCodeExecs([]);

      let questionNum;
      let questionId;
      // setDropdownValue(false)
      if (qId) {
        questionNum = questionIdList?.findIndex((id) => id === qId);
        questionId = qId;
      } else {
        questionNum = qNum;
        questionId = questionIdList?.[qNum] || questionIdList?.[0];
      }

      if (!questionIdList?.[questionNum - 1]) {
        setIfFirstQuestion(true);
      } else {
        setIfFirstQuestion(false);
      }

      if (!questionIdList?.[questionNum + 1]) {
        setIfLastQuestion(true);
      } else {
        setIfLastQuestion(false);
      }

      if (questionIdList?.[questionNum]) {
        setCurrentQuestionNum(questionNum);
      } else {
        setCurrentQuestionNum(currentQuestionNum);
      }

      setPrevQuestionId(questionIdList?.[questionNum - 1] || questionId);
      setCurrentQuestionId(questionId);
      setNextQuestionId(questionIdList?.[questionNum + 1] || questionId);
    },
    [questionIdList, currentQuestionNum]
  );

  const goToPrevQuestion = useCallback(() => {
    if (currentQuestionNum > 0) {
      setCurrentQuestion(currentQuestionNum - 1);
    }
  }, [questionIdList, currentQuestionNum]);

  useEffect(() => {
    scroll.scrollToTop({ duration: 0 });
    setSessionId(uuid());
  }, []);

  useEffect(() => {
    scroll.scrollToTop({ duration: 0 });
  }, [currentQuestionId]);
  const redirectObject = {
    pathname: props?.isFromPrepare
      ? `/courselearning/practice/${props?.courseId}`
      : "/view-practice-questions",
    state: {
      learningPathIndex: props?.location?.state?.learningPathIndex,
      prepCourseId: props?.prepCourseId,
      prepCourseName: props?.prepCourseName,
      prepCoursePer: props?.prepCoursePer,
      prepPracCompletionCriteria: props?.prepPracCompletionCriteria,
      prepPracResId: props?.prepPracResId,
      prepPracModId: props?.prepPracModId,
    },
  };
  const handleCancelApi = () => {
    if (props?.cancelToken?.current != null) {
      props.cancelToken.current = null;
    }
  };
  function updateOnlineStatus() {
    if (navigator.onLine) {
      toast(
        <>
          <Info />
          &nbsp;&nbsp;
          <span>Back online</span>
        </>,
        {
          autoClose: 2000,
          className: styles.backOnline,
          hideProgressBar: true,
          position: "bottom-center",
          type: "info",
        }
      );

      setIfOnline(true);
    } else {
      setIfOnline(false);
    }
  }
  function goToFullScreen() {
    try {
      if (isAdmin === "false") {
        if (
          (doc?.fullScreenElement !== undefined &&
            doc?.fullScreenElement === null) ||
          (doc?.msFullscreenElement !== undefined &&
            doc?.msFullscreenElement === null) ||
          (doc?.mozFullScreen !== undefined && !doc?.mozFullScreen) ||
          (doc?.webkitIsFullScreen !== undefined && !doc?.webkitIsFullScreen)
        ) {
          if (docElem?.requestFullScreen) {
            docElem?.requestFullScreen();
          } else if (docElem?.mozRequestFullScreen) {
            docElem
              ?.mozRequestFullScreen({
                navigationUI: "hide",
              })
              .catch(() => {});
          } else if (docElem?.webkitRequestFullScreen) {
            docElem?.webkitRequestFullScreen();
          } else if (docElem?.msRequestFullscreen) {
            docElem?.msRequestFullscreen();
          }
        }
      }
      if (isAdmin === "false") {
        setIfInFullScreenMode(true);
      }
    } catch (e) {}
  }

  useEffect(() => {
    const head = document.getElementsByTagName("head")[0];

    const viewportMeta = document.createElement("meta");
    viewportMeta.setAttribute(
      "content",
      "width=device-width, initial-scale=1, viewport-fit=cover"
    );
    viewportMeta.setAttribute("name", "viewport");

    const themeMeta = document.createElement("meta");
    themeMeta.setAttribute("content", isLightMode ? "#2A153D" : "#242744");
    themeMeta.setAttribute("name", "theme-color");
    function disableCopyPaste(e) {
      if (
        (((e?.keyCode || e?.key) === 67 || (e?.keyCode || e?.key) === 86) &&
          (e?.metaKey || e?.ctrlKey)) ||
        (e?.shiftKey && e?.key == "Insert") ||
        e?.key == "f12" ||
        e?.keyCode == 123 ||
        (e?.ctrlKey && e?.keyCode === 88)
      ) {
        e.preventDefault();
      }
    }
    const restrictAction = (e) => {
      e?.preventDefault();
    };
    const restrictActionMenu = (e) => {
      e?.preventDefault();
    };
    goToFullScreen();

    document.addEventListener("contextmenu", restrictActionMenu, false);
    document.addEventListener("keydown", disableCopyPaste, false);
    document.addEventListener("keydown", disableCopyPaste, false);
    document.addEventListener("copy", restrictAction, false);
    document.addEventListener("cut", restrictAction, false);
    document.addEventListener("paste", restrictAction, false);
    document.addEventListener("select", disableCopyPaste, false);
    document.addEventListener("drag", restrictAction, false);
    document.addEventListener("drop", restrictAction, false);
    window.addEventListener("paste", restrictActionMenu, false);
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    head.appendChild(viewportMeta);
    head.appendChild(themeMeta);

    return () => {
      document.removeEventListener("contextmenu", restrictActionMenu);
      document.removeEventListener("keydown", disableCopyPaste);
      document.removeEventListener("keydown", disableCopyPaste);
      document.removeEventListener("copy", restrictAction);
      document.removeEventListener("cut", restrictAction);
      document.removeEventListener("paste", restrictAction);
      document.removeEventListener("select", disableCopyPaste);
      document.removeEventListener("drag", restrictAction);
      document.removeEventListener("drop", restrictAction);
      window.removeEventListener("paste", restrictActionMenu);

      head.removeChild(viewportMeta);
      head.removeChild(themeMeta);
    };
  }, []);

  useEffect(() => {
    const isPartnerUser = localStorage.getItem("IsPartnerUser");
    const externalLabId = localStorage.getItem("ExternalPracticePackageId");
    async function getPracticeLabData(value) {
      setIfGettingPracticeLabData(true);
      await getData(
        isPartnerUser
          ? apiList.practiceLab.getDataFor3rdParty(externalLabId, userId)
          : apiList.practiceLab.getData(labId, userId)
      )
        .then((apiData) => {
          trackActivity(apiData?.id, apiData?.name, "started-practice-lab");
          setCustomCheck(apiData?.customCheckEnable);
          const data = JSON.parse(JSON.stringify(apiData));
          const path = props?.location?.params?.path;
          setCurrentPathOrder(path);

          if (data?.learnPath) {
            const firstPracticePath = data?.learningPath?.findIndex(
              ({ type }) => type === "Practice"
            );

            data.questionsList = data.questionsList.filter(({ id: qid }) =>
              data?.learningPath?.[
                value
                  ? value
                  : (dropdownValue ? dropdownValue - 1 : path) ||
                    firstPracticePath
              ]?.questionIdList?.find?.((id) => qid === id)
            );

            data.questionIdListValue = data?.questionsList.map(({ id }) => id);
          }
          setQuestionsSet(data.questionsList);
          setCodingLanguages(data?.languagesList);
          setQuestionIdList(data?.questionIdListValue);
          setPracticeLabData(data);
          setMockAssessmentQuestions(data?.mockAssessmentsDetails);
          setLearnPathValue(data.learningPath);
          // setPracticeLabid(data.id);
          if (externalLabId) {
            sessionStorage.setItem("labId", data?.id);
          }

          const completedQuestionsData = [];
          data?.questionsList?.forEach((questionData) => {
            completedQuestionsData[
              data?.questionIdListValue?.findIndex(
                (qid) => qid === questionData?.id
              )
            ] = questionData?.solutionMap?.completed;
          });

          setIfGettingPracticeLabData(false);
          updateCompletedQuestions(completedQuestionsData);
          // setLoading(false)
        })
        .catch(() => {});
    }
    if (!props?.location?.state?.paths) {
      getPracticeLabData();
    }
  }, []);

  useEffect(() => {
    const isPartnerUser = localStorage.getItem("IsPartnerUser");
    const externalLabId = localStorage.getItem("ExternalPracticePackageId");
    async function getPracticeLabDataLp(value) {
      setIfGettingPracticeLabData(true);
      await getData(
        isPartnerUser
          ? apiList.practiceLab.getDataFor3rdParty(externalLabId, userId)
          : apiList.practiceLab.getData(labId, userId)
      )
        .then((apiData) => {
          trackActivity(apiData?.id, apiData?.name, "started-practice-lab");
          setCustomCheck(apiData?.customCheckEnable);
          const data = JSON.parse(JSON.stringify(apiData));
          const path = props?.location?.params?.path;
          setCurrentPathOrder(path);

          if (data?.learnPath) {
            const firstPracticePath = data?.learningPath?.findIndex(
              ({ type }) => type === "Practice"
            );

            data.questionsList = data.questionsList.filter(({ id: qid }) =>
              data?.learningPath?.[
                value
                  ? value
                  : (dropdownValue ? dropdownValue - 1 : path) ||
                    firstPracticePath
              ]?.questionIdList?.find?.((id) => qid === id)
            );

            data.questionIdListValue = data?.questionsList.map(({ id }) => id);
          }
          setQuestionsSet(data.questionsList);
          setCodingLanguages(data?.languagesList);
          setQuestionIdList(data?.questionIdListValue);
          setPracticeLabData(data);
          setMockAssessmentQuestions(data?.mockAssessmentsDetails);
          setLearnPathValue(data.learningPath);
          // setPracticeLabid(data.id);
          if (externalLabId) {
            sessionStorage.setItem("labId", data?.id);
          }

          const completedQuestionsData = [];
          data?.questionsList?.forEach((questionData) => {
            completedQuestionsData[
              data?.questionIdListValue?.findIndex(
                (qid) => qid === questionData?.id
              )
            ] = questionData?.solutionMap?.completed;
          });

          setIfGettingPracticeLabData(false);
          updateCompletedQuestions(completedQuestionsData);
          // setLoading(false)
        })
        .catch(() => {});
    }
    if (
      props?.location?.state?.paths?.length > 0 &&
      props?.location?.state?.paths !== undefined
    ) {
      if (dropdownValue !== undefined) {
        getPracticeLabDataLp();
      }
    }
  }, [dropdownValue]);
  const filteredCompletedQuestions = completedQuestions
    .filter((e) => e == true)
    .map((e) => {
      return e;
    });
  const goToNextQuestion = useCallback(() => {
    if (filteredCompletedQuestions.length == questionIdList?.length) {
      setCurrentQuestionValue(learningPathList[dropdownValue + 1]);
      setDropdownValue(dropdownValue + 1);
    }

    if (currentQuestionNum === questionIdList?.length - 1) {
      const incompleteQuestionNum = completedQuestions?.findIndex((c) => !c);

      if (incompleteQuestionNum > -1) {
        setCurrentQuestion(incompleteQuestionNum);
      }
    } else {
      if (currentQuestionNum < questionIdList?.length - 1) {
        setCurrentQuestion(currentQuestionNum + 1);
      }
    }
  }, [questionIdList, completedQuestions, currentQuestionNum]);

  const goToNextPath = useCallback(() => {
    setCurrentQuestionValue(learningPathList[dropdownValue + 1]);
    setDropdownValue(dropdownValue + 1);
    setType(learningPathList[dropdownValue]?.type);
  });

  useEffect(() => {
    if (questionIdList?.length) {
      if (props?.location?.params?.questionId) {
        setCurrentQuestion(null, props?.location?.params?.questionId);
      } else {
        setCurrentQuestion(0);
      }
    }
  }, [questionIdList, codingLanguages]);

  function setSelectedOption(questionId, option) {
    updateSelectedOptions((so) => {
      return {
        ...so,
        [questionId]: Array.isArray(option) ? option : [option],
      };
    });
  }

  function setSelectedOptions(questionId, option, remove) {
    updateSelectedOptions((so) => {
      return {
        ...so,
        [questionId]: remove
          ? so?.[questionId]?.filter((opt) => opt !== option) || []
          : [...(so?.[questionId] || []), option],
      };
    });
  }

  const initializeDetails = useCallback(
    (data) => {
      setActivity((a) => ({
        ...a,
        [data?.id]: isUnpurchasedPaidPackage
          ? {
              hint: true,
              solution: true,
              testCase: true,
            }
          : {
              hint: data?.userLabActivity?.hint,
              solution: data?.userLabActivity?.solution,
              ...(data?.type === "MC"
                ? {
                    workspaceCanvas: data?.userLabActivity?.workspaceCanvas,
                    workspaceText: data?.userLabActivity?.workspaceText,
                  }
                : {
                    testCase: data?.userLabActivity?.testCase,
                  }),
            },
      }));
      if (
        data?.type === "MC" &&
        !selectedOptions?.[data?.id] &&
        data?.solutionMap?.optionId
      ) {
        setSelectedOption(
          data?.id,
          data?.solutionMap?.optionId
            ?.replace("[", "")
            ?.replace("]", "")
            ?.split(", ")
        );
        // if(data?.){

        // }
        if (data?.userLabActivity?.workspaceCanvas) {
          sessionStorage.setItem(
            `practice-${data?.id}-ws-canvas`,
            data?.userLabActivity?.workspaceCanvas
          );
        }
        if (data?.userLabActivity?.workspaceText) {
          sessionStorage.setItem(
            `practice-${data?.id}-ws-text`,
            data?.userLabActivity?.workspaceText
          );
        }
      } else if (
        (data?.type === "CD" || data?.type === "DESC") &&
        !codingSolutions?.[data?.id]
      ) {
        setCodingSolutions((cs) => ({
          ...cs,
          [data?.id]: {
            langId: data?.solutionMap?.languageId || codingLanguages?.[0]?.id,
            lang: codingLanguages
              ?.find?.(
                ({ id }) =>
                  id ===
                  (data?.solutionMap?.languageId || codingLanguages?.[0]?.id)
              )
              ?.optionValue?.toLowerCase?.(),
            code: data?.solutionMap?.codeSolution
              ? window.atob(data?.solutionMap?.codeSolution)
              : data?.defaultSolutionList &&
                data?.defaultSolutionList.length > 0
              ? data?.defaultSolutionList.find(
                  (it) => it?.languageId === codingLanguages?.[0]?.id
                )?.solution
              : "",
          },
        }));
      }
    },
    [
      selectedOptions,
      codingSolutions,
      codingLanguages,
      isUnpurchasedPaidPackage,
    ]
  );
  const theme = useMemo(
    () => getMuiTheme(isLightMode, smallScreen),
    [smallScreen, isLightMode]
  );

  const {
    data: currentQuestionDetails,
    error: currentQuestionDetailsError,
    loading: loadingCurrentQuestion,
  } = useQuestionInfo(currentQuestionId, labId, initializeDetails, sessionId);

  const { data: _nextQuestionDetails } = useQuestionInfo(
    nextQuestionId,
    labId,
    initializeDetails,
    sessionId
  );

  const { data: _prevQuestionDetails } = useQuestionInfo(
    prevQuestionId,
    labId,
    initializeDetails,
    sessionId
  );

  // const isMCQ = useMemo (
  //   () => learningPathList.length > 0 ? currentQuestionDetailsValue?.type == 'MC' : currentQuestionDetails?.type === "MC"
  //   [currentQuestionDetails, currentQuestionDetailsValue]
  // );
  const isMCQ = useMemo(
    () => currentQuestionDetails?.type === "MC",
    [currentQuestionDetails]
  );

  const submitMCQAnswer = useCallback(async () => {
    if (!isUnpurchasedPaidPackage) {
      setMCQAnswerSubmissionStatus(true);
      await postData(apiList.practiceLab.saveMCQSolution, {
        userId: userEmail,
        labId,
        questionId: currentQuestionDetails?.id,
        optionId: selectedOptions?.[currentQuestionDetails?.id],
      })
        .then(() => {
          updateCompletedQuestions((cq) => {
            const tempCQ = cq;
            tempCQ[currentQuestionNum] = true;

            return tempCQ;
          });
          learningPathUpdate();
          popCheck();
          setQuestionNumber(currentQuestionNum, showSnackbar());
        })
        .catch(() => {
          updateCompletedQuestions((cq) => {
            const tempCQ = cq;
            tempCQ[currentQuestionNum] = false;

            return tempCQ;
          });
        })
        .finally(() => {
          setTimeout(() => {
            setMCQAnswerSubmissionStatus(false);
          }, 500);
        });
    }
  }, [selectedOptions, currentQuestionNum, currentQuestionDetails]);

  const popCheck = () => {
    const length = learningPathList.length;
    if (learnPathValue?.length > 0) {
      if (
        learningPathList[dropdownValue - 1]?.name ==
        learningPathList[length - 1]?.name
      ) {
        if (filteredCompletedQuestions.length == questionIdList?.length) {
          setPopValue(true);
        }
      }
    }
  };
  async function langDebug() {
    await apihelper
      .axiosCall(`${api.baseURL}${api.lab.labLanguage}${labId}`, "get")
      .then((data) => {
        setLangdebugValue(data);
      })
      .catch((e) => {
        console.log(`something went wrong ${e}`);
      });
  }

  useEffect(() => {
    langDebug();
  }, []);

  const getCodeExecStatus = useCallback(
    async (codeExecId, questionId, cancelTokenId) => {
      if (cancelTokenId === cancelToken.current) {
        await getData(
          apiList.practiceLab.getCodeExecStatus(codeExecId, questionId)
        )
          .then((data) => {
            if (cancelTokenId === cancelToken.current) {
              if (data?.status === "NotStarted") {
                setCodeExecTestCasesCount(data?.testCaseCount);
                setCodeExecs([]);

                setTimeout(() => {
                  getCodeExecStatus(codeExecId, questionId, cancelTokenId);
                }, 3000);
              } else if (data?.status === "Inprogress") {
                setCodeExecTestCasesCount(data?.testCaseCount);
                setTimeout(() => {
                  getCodeExecStatus(codeExecId, questionId, cancelTokenId);
                }, 3000);

                setCodeExecs(
                  data?.testCaseSolution?.map(
                    ({ submissions = [] }) => submissions?.[0]
                  ) || []
                );
              } else if (data?.status === "Completed") {
                setCodeExecTestCasesCount(data?.testCaseCount);
                cancelToken.current = null;
                const currentCodeExecs = data?.testCaseSolution?.map(
                    ({ submissions = [] }) => submissions?.[0]
                  ) || [];
               
                  // data?.testCaseSolution?.map(
                  //   ({ submissions = [] }) => submissions?.[0]
                  // ) || [];
                if (currentCodeExecs?.every((cd) => cd?.status?.id === 3)) {
                  updateCompletedQuestions((cq) => {
                    const tempCQ = cq;
                    tempCQ[currentQuestionNum] = true;

                    return tempCQ;
                  });
                } else {
                  updateCompletedQuestions((cq) => {
                    const tempCQ = cq;
                    tempCQ[currentQuestionNum] = false;

                    return tempCQ;
                  });
                }

                setCodeExecs(currentCodeExecs);
                setIfCodeIsExecuting(false);

                showSnackbar();
              }
            }
          })
          .catch(() => {});
      }
    },
    [currentQuestionId, currentQuestionNum, cancelToken]
  );

  const execCode = useCallback(async () => {
    setCodeErr(true);
    if (smallScreen) {
      setCurrentTab(2);
    }

    cancelToken.current = null;

    setCodeExecType(customInput ? "custom-input" : "sample");
    setCodeExecTestCasesCount(1);
    setCodeExecs([]);
    setIfCodeIsExecuting(true);

    await postData(apiList.practiceLab.execCode, {
      collegeId: practiceLabData.collegeId,
      inputVal: customInput,
      labId,
      languageId: codingSolutions?.[currentQuestionId]?.langId,
      questionId: currentQuestionId,
      solution: window.btoa(codingSolutions?.[currentQuestionId]?.code),
      userId: userEmail,
      // name : programName,
      // code : window.btoa(codingSolutions?.[currentQuestionId]?.code),
      // param : "sc"
    })
      .then((data) => {
        // setResvalue(data);
        setResData(data?.customCheckMessage);
        setCodeExecs(data?.submissions || []);
      })
      .catch(() => {})
      .finally(() => {
        setIfCodeIsExecuting(false);
      });
  }, [
    practiceLabData,
    customInput,
    codingSolutions,
    currentQuestionId,
    smallScreen,
    // programName,
  ]);

  const debugcCode = (e) => {
    setDebugcCodeConform(!debugcCodeConform);
  };

  const questionCustomCondition = useCallback(async () => {
    await postData(apiList.practiceLab.questionCustomCondition, {
      codeSolution: window.btoa(codingSolutions?.[currentQuestionId]?.code),
      collegeId: practiceLabData.collegeId,
      labId,
      languageId: codingSolutions?.[currentQuestionId]?.langId,
      questionId: currentQuestionId,
      userId: userEmail,
    })
      .then((data) => {
        setMessageRes(data);
      })
      .catch((e) => {
        console.log(`something went wrong ${e}`);
      });
  }, [practiceLabData]);

  const submitCDQAnswer = useCallback(async () => {
    setCodeErr(false);
    if (smallScreen) {
      setCurrentTab(2);
    }
    if (customCheck) {
      questionCustomCondition();
      setCodeExecType("test-case");
      setCodeExecTestCasesCount(0);
      setCodeExecs([]);
      setIfCodeIsExecuting(true);
      cancelToken.current = uuid();
      await postData(apiList.practiceLab.execCodeTestCases, {
        codeSolution: window.btoa(codingSolutions?.[currentQuestionId]?.code),
        collegeId: practiceLabData.collegeId,
        labId,
        languageId: codingSolutions?.[currentQuestionId]?.langId,
        questionId: currentQuestionId,
        userId: userEmail,
      })
        .then((codeExecId) => {
          getCodeExecStatus(codeExecId, currentQuestionId, cancelToken.current);
        })
        .catch(() => {
          setIfCodeIsExecuting(false);
        });
    } else {
      setCodeExecType("test-case");
      setCodeExecTestCasesCount(0);
      setCodeExecs([]);
      setIfCodeIsExecuting(true);
      cancelToken.current = uuid();
      await postData(apiList.practiceLab.execCodeTestCases, {
        codeSolution: window.btoa(codingSolutions?.[currentQuestionId]?.code),
        collegeId: practiceLabData.collegeId,
        labId,
        languageId: codingSolutions?.[currentQuestionId]?.langId,
        questionId: currentQuestionId,
        userId: userEmail,
      })
        .then((codeExecId) => {
          getCodeExecStatus(codeExecId, currentQuestionId, cancelToken.current);
        })
        .catch(() => {
          setIfCodeIsExecuting(false);
        });
    }
  }, [
    codingSolutions,
    currentQuestionId,
    practiceLabData,
    smallScreen,
    resData,
  ]);

  const learningPathUpdate = useCallback(async () => {
    await getData(
      apiList.practiceLab.getLearningPathUpdate(labId, userId)
    ).then((data) => {
      setLearnPathValue(data.learningPath);
    });
  });
  const logActivity = useCallback(
    async (type, timeSpent, calledAfterError) => {
      await postData(apiList.practiceLab.logActivity, {
        ...(activity?.[currentQuestionId]?.id && {
          id: activity?.[currentQuestionId]?.id,
        }),
        questionId: currentQuestionId,
        labId,
        userId: userEmail,
        workspaceCanvas: sessionStorage.getItem(
          `practice-${currentQuestionId}-ws-canvas`
        ),
        workspaceText: sessionStorage.getItem(
          `practice-${currentQuestionId}-ws-text`
        ),
        hint: type === "hint" ? true : activity?.[currentQuestionId]?.hint,
        testCase:
          type === "testCase" ? true : activity?.[currentQuestionId]?.testCase,
        solution:
          type === "solution" ? true : activity?.[currentQuestionId]?.solution,
        ...(type === "timeSpent" && { timeSpent }),
      })
        .then((data) => {
          if (!activity?.[currentQuestionId]?.id) {
            setToggleSequence((a) => ({
              ...a,
              [currentQuestionId]: {
                ...(a?.[currentQuestionId] || {}),
                id: data?.id,
              },
            }));
          }
        })
        .catch(() => {
          if (!calledAfterError) {
            logActivity(type, null, true);
          }
        });
    },
    [activity, currentQuestionId]
  );
  const handleClose = () => {
    setOpen(!open);
  };
  const newMockListForPath =
    learningPathList[dropdownValue - 1]?.mockAssesments || 0;
  var mockAssessmentForPath = [];
  for (let i = 0; i < newMockListForPath?.length; i++) {
    for (let a = 0; a < mockAssessmentQuestions?.length; a++) {
      if (newMockListForPath[i] == mockAssessmentQuestions[a]?.id)
        mockAssessmentForPath.push(mockAssessmentQuestions[a]);
    }
  }
  return (
    <div>
      <ThemeProvider theme={theme}>
        <style>
          {`
          body {
            background-color: ${isLightMode ? "#2A153D" : "#242744"} !important;
          }
        `}
        </style>
        <div
          className={cn(styles.page, {
            [styles.lightTheme]: isLightMode,
            [styles.darkTheme]: !isLightMode,
          })}
        >
          {!hasCompletedTour && (
            <Tour mcqQuestion={currentQuestionDetails?.type === "MC"} />
          )}
          <PracticeLabContext.Provider
            value={{
              codingLanguages,
              currentQuestionDetails,
              currentQuestionDetailsError,
              currentQuestionId,
              firstQuestion,
              goToNextQuestion,
              goToPrevQuestion,
              isLightMode,
              isMCQ,
              labId,
              largeScreen,
              lastQuestion,
              smallScreen,
              mediumScreen,
              questionIdList,
              setCurrentQuestion,
            }}
          >
            <Header
              completedQuestions={completedQuestions}
              currentQuestionNum={currentQuestionNum}
              executingCode={executingCode}
              id={currentQuestionId}
              labId={labId}
              learningPathList={learningPathList}
              loading={gettingPracticeLabData}
              setIfLightTheme={setIfLightTheme}
              solutionMedia={currentQuestionDetails?.solutionMedia}
              submittingMCQAnswer={submittingMCQAnswer}
              title={practiceLabData?.name}
              location={props?.location}
              debugcCodeConform={debugcCodeConform}
              setDebugcCodeConform={setDebugcCodeConform}
              setDropdownValue={setDropdownValue}
              dropdownValue={dropdownValue}
              cancelToken={cancelToken}
              isFromPrepare={isFromPrepare}
              courseId={courseId}
              prepCourseId={prepCourseId}
              prepCourseName={prepCourseName}
              prepCoursePer={prepCoursePer}
              prepPracCompletionCriteria={prepPracCompletionCriteria}
              prepPracResId={prepPracResId}
              prepPracModId={prepPracModId}
              initializeDetails={initializeDetails}
              setCodingSolutions={setCodingSolutions}
              // setProgramName={setProgramName}
              codingSolutions={codingSolutions}
              sessionId={sessionId}
              setActivity={setActivity}
              setSelectedOption={setSelectedOption}
              selectedOptions={selectedOptions}
              isUnpurchasedPaidPackage={isUnpurchasedPaidPackage}
              setCurrentQuestionDetailsValue={setCurrentQuestionDetailsValue}
              setMockAssessmentValue={setMockAssessmentValue}
              setMockAssessmentId={setMockAssessmentId}
              setPathIndexMock={setPathIndexMock}
              codingLanguages={codingLanguages}
              setCurrentQuestionValue={setCurrentQuestionValue}
              currentQuestionValue={currentQuestionValue}
              learnPathFromApi={learnPathValue}
              currentPathOrder={currentPathOrder}
            />
            {mockAssessmentValue !== "Mock Assessment" && (
              <div
                className={cn(styles.body, {
                  [styles.smallScreen]: smallScreen,
                  [styles.mediumScreen]: mediumScreen,
                  [styles.largeScreen]: largeScreen,
                  "practice-small-screen": smallScreen,
                  "practice-medium-screen": mediumScreen,
                  "practice-light-mode": isLightMode,
                  "practice-dark-mode": !isLightMode,
                })}
              >
                {isUnpurchasedPaidPackage &&
                currentQuestionDetails?.questionLock &&
                !loadingCurrentQuestion &&
                !currentQuestionDetails?.id ? (
                  <Section
                    gridPosition={[
                      [1, 3],
                      [1, 4],
                    ]}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      style={{ height: "100%" }}
                    >
                      <LockOutlined
                        style={{
                          color: "#888",
                          fontSize: 172,
                        }}
                      />
                    </Grid>
                  </Section>
                ) : loadingCurrentQuestion ||
                  !currentQuestionDetails ||
                  gettingPracticeLabData ? (
                  smallScreen ? (
                    <>
                      <SectionSkeleton
                        smallScreen
                        gridPosition={[
                          [1, 2],
                          [1, 2],
                        ]}
                      />
                    </>
                  ) : (
                    <>
                      <SectionSkeleton
                        gridPosition={[
                          [1, 2],
                          [1, 2],
                        ]}
                      />
                      <SectionSkeleton
                        gridPosition={[
                          [2, 3],
                          [1, 2],
                        ]}
                      />
                      <SectionSkeleton
                        gridPosition={[
                          [1, 2],
                          [2, 4],
                        ]}
                      />
                      <SectionSkeleton
                        gridPosition={[
                          [2, 3],
                          [2, 3],
                        ]}
                      />
                      <SectionSkeleton
                        gridPosition={[
                          [2, 3],
                          [3, 4],
                        ]}
                      />
                    </>
                  )
                ) : (
                  <>
                    {(smallScreen ? currentTab === 0 : true) && (
                      <QuestionSection
                        details={currentQuestionDetails}
                        goToNextQuestion={goToNextQuestion}
                        gridPosition={
                          isMCQ
                            ? [
                                [1, 2],
                                [1, 4],
                              ]
                            : sectionIsExpanded
                            ? [
                                [1, 2],
                                [1, 4],
                              ]
                            : [
                                [1, 2],
                                [1, 2],
                              ]
                        }
                        isMCQ={isMCQ}
                        questionId={currentQuestionId}
                        sectionIsExpanded={sectionIsExpanded}
                        selectedOptions={
                          selectedOptions?.[currentQuestionId] || []
                        }
                        setIfSectionIsExpanded={setIfSectionIsExpanded}
                        setSelectedOption={setSelectedOption}
                        setSelectedOptions={setSelectedOptions}
                        submitMCQAnswer={submitMCQAnswer}
                        submittingMCQAnswer={submittingMCQAnswer}
                        title={`Question ${currentQuestionNum + 1}`}
                        workSpaceIsDisabled={!practiceLabData?.showWorkspace}
                      />
                    )}
                    {(smallScreen
                      ? currentTab === (isMCQ ? 1 : 3)
                      : sectionIsExpanded
                      ? false
                      : true) && (
                      <HintsSection
                        activity={activity?.[currentQuestionId]}
                        available={currentQuestionDetails?.hintsProvided}
                        canView={
                          isUnpurchasedPaidPackage
                            ? true
                            : practiceLabData?.showHint
                        }
                        details={currentQuestionDetails}
                        gridPosition={[
                          [2, 3],
                          [1, 2],
                        ]}
                        id={currentQuestionDetails?.id}
                        isLightMode={isLightMode}
                        logActivity={logActivity}
                        questionId={currentQuestionId}
                        setActivity={setActivity}
                        setVisibility={setHintsVisibility}
                        visible={hintsVisible}
                      />
                    )}
                    {isMCQ ? (
                      <>
                        {(smallScreen
                          ? currentTab === 2
                          : sectionIsExpanded
                          ? false
                          : true) && (
                          <SolutionSection
                            activity={activity?.[currentQuestionId]}
                            available={currentQuestionDetails?.solutionProvided}
                            canView={
                              isUnpurchasedPaidPackage
                                ? true
                                : practiceLabData?.showSolution
                            }
                            canViewHint={
                              isUnpurchasedPaidPackage
                                ? true
                                : practiceLabData?.showHint
                            }
                            details={currentQuestionDetails}
                            gridPosition={[
                              [2, 3],
                              [2, 3],
                            ]}
                            hintsAvailable={
                              currentQuestionDetails?.hintsProvided
                            }
                            id={currentQuestionDetails?.id}
                            logActivity={logActivity}
                            questionId={currentQuestionId}
                            langdebugValue={langdebugValue}
                            setActivity={setActivity}
                            setVisibility={setSolutionVisibility}
                            visible={solutionVisible}
                          />
                        )}
                        {largeScreen && (
                          <WorkspaceSection
                            canView={practiceLabData?.showWorkspace}
                            gridPosition={
                              sectionIsExpanded
                                ? [
                                    [2, 3],
                                    [1, 4],
                                  ]
                                : [
                                    [2, 3],
                                    [3, 4],
                                  ]
                            }
                            id={currentQuestionDetails?.id}
                            isLightMode={isLightMode}
                            logActivity={
                              isUnpurchasedPaidPackage ? () => {} : logActivity
                            }
                            questionId={currentQuestionId}
                            setIfSectionIsExpanded={setIfSectionIsExpanded}
                            setWorkspaceTab={setWorkspaceTab}
                            workspaceTab={workspaceTab}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {(smallScreen
                          ? currentTab === 4
                          : sectionIsExpanded
                          ? false
                          : true) && (
                          <TestCasesSection
                            activity={activity?.[currentQuestionId]}
                            available={currentQuestionDetails?.testcaseProvided}
                            canView={
                              isUnpurchasedPaidPackage
                                ? true
                                : practiceLabData?.showTestCase
                            }
                            canViewHint={
                              isUnpurchasedPaidPackage
                                ? true
                                : practiceLabData?.showHint
                            }
                            customInput={customInput}
                            details={currentQuestionDetails}
                            gridPosition={[
                              [2, 3],
                              [2, 3],
                            ]}
                            hintsAvailable={
                              currentQuestionDetails?.hintsProvided
                            }
                            id={currentQuestionDetails?.id}
                            isLightMode={isLightMode}
                            logActivity={logActivity}
                            questionId={currentQuestionId}
                            setActivity={setActivity}
                            setCustomInput={setCustomInput}
                            setTestCaseTab={setTestCaseTab}
                            setVisibility={setTestCasesVisibility}
                            testCaseTab={testCaseTab}
                            visible={testCasesVisible}
                            debugcCodeConform={debugcCodeConform}
                          />
                        )}
                        {(smallScreen ? currentTab === 1 : true) && (
                          <CodeSolutionSection
                            activity={activity?.[currentQuestionId]}
                            available={currentQuestionDetails?.solutionProvided}
                            canView={
                              isUnpurchasedPaidPackage
                                ? true
                                : practiceLabData?.showSolution
                            }
                            canViewHint={
                              isUnpurchasedPaidPackage
                                ? true
                                : practiceLabData?.showHint
                            }
                            canViewTestCase={
                              isUnpurchasedPaidPackage
                                ? true
                                : practiceLabData?.showTestCase
                            }
                            codeTab={codeTab}
                            codingSolutions={codingSolutions}
                            details={currentQuestionDetails}
                            execCode={execCode}
                            debugcCode={debugcCode}
                            langDebug={langDebug}
                            langdebugValue={langdebugValue}
                            debugcCodeConform={debugcCodeConform}
                            executingCode={executingCode}
                            gridPosition={
                              sectionIsExpanded
                                ? [
                                    [2, 3],
                                    [1, 3],
                                  ]
                                : [
                                    [1, 2],
                                    [2, 4],
                                  ]
                            }
                            hintsAvailable={
                              currentQuestionDetails?.hintsProvided
                            }
                            id={currentQuestionDetails?.id}
                            logActivity={logActivity}
                            questionId={currentQuestionId}
                            setActivity={setActivity}
                            setCodeTab={setCodeTab}
                            setCodingSolutions={setCodingSolutions}
                            // setProgramName={setProgramName}
                            // programName={programName}
                            submitCDQAnswer={submitCDQAnswer}
                            testCaseAvailable={currentQuestionDetails?.id}
                          />
                        )}
                        {(smallScreen ? currentTab === 2 : true) && (
                          <OutputSection
                            codeExecs={codeExecs}
                            codeErr={codeErr}
                            messageRes={messageRes}
                            codeExecTestCasesCount={codeExecTestCasesCount}
                            codeExecType={codeExecType}
                            customInput={customInput}
                            executingCode={executingCode}
                            // resValue={resValue}
                            gridPosition={[
                              [2, 3],
                              [3, 4],
                            ]}
                            isLightMode={isLightMode}
                            questionId={currentQuestionId}
                            debugcCodeConform={debugcCodeConform}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
                {smallScreen && (
                  <div className={styles.tabFill}>
                    <div className={styles.tabFillContent} />
                  </div>
                )}
              </div>
            )}
            {(mockAssessmentValue == "Mock Assessment" ||
              type == "Mock Assessment") && (
              <div>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "20px",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  Mock Assessment
                </p>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "15px",
                    textAlign: "center",
                  }}
                >{`${mockAssessmentForPath.length} mock assessment available`}</p>
                <div
                  className={cn(styles.body, {
                    [styles.largeScreen]: smallScreen,
                    [styles.largeScreen]: mediumScreen,
                    [styles.largeScreen]: largeScreen,
                    "practice-small-screen": smallScreen,
                    "practice-medium-screen": mediumScreen,
                    "practice-light-mode": isLightMode,
                    "practice-dark-mode": !isLightMode,
                  })}
                >
                  <div
                    className={
                      smallScreen ? styles.cardGroupMobile : styles.cardGroup
                    }
                  >
                    {learningPathList[dropdownValue - 1]?.type ==
                      "Mock Assessment" &&
                      mockAssessmentForPath?.map((question, idx) => (
                        <div
                          className={styles.assessment}
                          key={idx}
                          style={{ backgroundColor: "#fff" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h4 className={styles.questionHeading}>
                                {question.name}
                              </h4>
                            </div>
                            <div className={styles.assessmentDuration}>
                              <div>{question?.timeLimit} mins</div>
                            </div>
                          </div>
                          <div>
                            <p>learning path Mock Assessment</p>
                          </div>
                          <div className={styles.questionDescription}>
                            <Link
                              to={{
                                pathname: "/taketest",
                                search: `?id=${
                                  question?.id
                                }&userId=${sessionStorage.getItem(
                                  "user_id"
                                )}&labId=${labId}&isMockAssessment=${true}&learningPathIndex=${pathIndexMock}`,
                              }}
                            >
                              <button
                                style={{
                                  border: "1px #794DF5",
                                  borderRadius: "4px",
                                  background: "#794DF5",
                                  padding: "10px",
                                  color: "#fff",
                                }}
                              >
                                Take assessment
                              </button>
                            </Link>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
            <TabBar
              disabled={
                isUnpurchasedPaidPackage &&
                !loadingCurrentQuestion &&
                !currentQuestionDetails?.id
              }
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          </PracticeLabContext.Provider>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: smallScreen ? "center" : "right",
          }}
          classes={{ root: styles.submissionMessage }}
          onClose={hideSnackbar}
          open={submissionMessageIsVisible}
          autoHideDuration={smallScreen ? 1000 : 3000}
          message={
            <div>
              <Grid container alignItems="center">
                <Typography color="inherit" noWrap>
                  Your solution was submitted
                </Typography>
                {!smallScreen && (
                  <>
                    <>&nbsp;&nbsp;&nbsp;&nbsp;</>
                    <IconButton
                      color="inherit"
                      edge="end"
                      onClick={hideSnackbar}
                      size="small"
                    >
                      <Close />
                    </IconButton>
                  </>
                )}
              </Grid>
              {!smallScreen && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  style={{ marginTop: 12 }}
                >
                  {filteredCompletedQuestions.length ==
                  questionIdList?.length ? (
                    learnPathValue?.length > 0 ? (
                      <Button
                        color={isLightMode ? "inherit" : "primary"}
                        onClick={() => {
                          goToNextPath();
                          hideSnackbar();
                        }}
                        size="medium"
                        variant="outlined"
                      >
                        {"Next Path"}
                      </Button>
                    ) : learnPathValue?.length > 0 ? (
                      <Button
                        color={isLightMode ? "inherit" : "primary"}
                        onClick={() => {
                          goToNextQuestion();
                          hideSnackbar();
                        }}
                        size="medium"
                        variant="outlined"
                      >
                        {`Next Question`}
                      </Button>
                    ) : (
                      questionIdList?.length - 1 !== (questionNumber || 0) && (
                        <Button
                          color={isLightMode ? "inherit" : "primary"}
                          onClick={() => {
                            goToNextQuestion();
                            hideSnackbar();
                          }}
                          size="medium"
                          variant="outlined"
                        >
                          {`Next Question`}
                        </Button>
                      )
                    )
                  ) : (
                    questionIdList?.length - 1 !== (questionNumber || 0) && (
                      <Button
                        color={isLightMode ? "inherit" : "primary"}
                        onClick={() => {
                          goToNextQuestion();
                          hideSnackbar();
                        }}
                        size="medium"
                        variant="outlined"
                      >
                        {`Next Question`}
                      </Button>
                    )
                  )}
                </Grid>
              )}
            </div>
          }
        />
        {!online && (
          <Box
            style={{
              backgroundColor: "#fffc",
            }}
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              position: "absolute",
              width: "100%",
              zIndex: 1000,
            }}
          >
            <Box
              style={{
                backgroundColor: "#fff",
              }}
              sx={{
                boxShadow: "rgba(0,0,0,0.1) 0 0 10px 1px",
                alignItems: "center",
                borderRadius: 8,
                display: "flex",
                flexDirection: "column",
                height: "200px",
                justifyContent: "center",
                position: "relative",
                width: "200px",
              }}
            >
              <SignalWifiOffIcon
                style={{
                  color: "#ff0000",
                  fontSize: 80,
                  marginBottom: 20,
                }}
              />
              <Typography variant="h5">No Internet</Typography>
            </Box>
          </Box>
        )}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={popValue}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
        >
          <div
            style={{
              background: "#fff",
              padding: "1.5rem",
              borderRadius: "4px",
              width: "30%",
            }}
          >
            <p style={{ fontWeight: 500, fontSize: "18px" }}>
              👏Awesome! This learning path is completed
            </p>
            <div style={{ display: "flex" }} className="pl-3">
              <div className="pr-2">
                <Link to={redirectObject}>
                  <button
                    style={{
                      border: "1px solid #1BA94C",
                      borderRadius: "4px",
                      background: "#fff",
                      padding: "10px",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "#1BA94C",
                    }}
                  >
                    Go to Dashboard
                  </button>
                </Link>
              </div>
              <Link to={"/lab"}>
                <button
                  style={{
                    border: "none",
                    background: "#794DF5",
                    borderRadius: "4px",
                    color: "#fff",
                    padding: "10px",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Continue
                </button>
              </Link>
            </div>
          </div>
        </Modal>
      </ThemeProvider>
    </div>
  );
}
