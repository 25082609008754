import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import apiHelper from "../../../api/apiHelper";
import api from "../../../api/baseConfig";
import { useParams } from "react-router-dom";
import "../hackathonStudent/hackathons.css";
import { TervButtonOutlined, TervButtonFilled } from "components/TervButtons";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import { SubmissionSchema } from "./ValidationSchemas";
import RichTextEditor from "../components/RichTextEditor/index";
import { AddMediaComponent } from "../components/AddMediaComponent";
import { DefaultToast } from "components/DefaultToast";
import { useGetHackathon } from "../hooks/projectSubmission";
import { useHistory, useLocation } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import clsx from "clsx";
import { ErrorBlock } from "components/ErrorBlock";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddCircleSharpIcon from "@material-ui/icons/AddCircleSharp";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import { object } from "prop-types";
// import { watch } from "fs";   value never used brooo
import { sessionPrivileges } from "privileges";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#ffffff",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    zIndex: 0,
  },
  hackathonList: {
    width: "30rem",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  contentMobile: {
    flexGrow: 1,
    padding: "5px",
  },
  submission: {
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "32px",
    color: "#19181B",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "26px",
    color: "#323036",
  },
  paragraph: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#63606C",
    width: "35rem",
  },
  pos: {
    marginBottom: 12,
  },
  hackathonList: {
    width: "60rem",
  },
}));

function ProjectSubmissionForm(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [hackathonDataDetails, setHackathonDataDetails] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [formValue, setFormValue1] = useState(false);
  const [hackathonList, setHackathonList] = useState();
  const [submissionId, setSubmissionId] = useState();
  const [mediaData, setMediaData] = useState();
  const [pasteLinkList, setPasteLinkList] = useState([{ value: null }]);

  const valuCheck = props?.location?.state?.hackathonDataDetails || "";
  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(SubmissionSchema),
  });
  const history = useHistory();

  const {
    register,
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = methods;
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { id } = useParams();
  const userPrivileges = sessionPrivileges();
  const userID = userPrivileges.userName;

  useEffect(() => {
    hackathonDetails();
  }, []);

  const hasId = useParams();
  const courseData = useGetHackathon(hasId?.id);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...pasteLinkList];
    list[index] = value;
    setPasteLinkList(list);
  };
  const handleRemoveClick = (index) => {
    const list = [...pasteLinkList];
    list.splice(index, 1);
    setPasteLinkList(list);
  };
  const handleAddClick = () => {
    setPasteLinkList([...pasteLinkList, { value: null }]);
  };

  useEffect(() => {
    if (courseData?.projectName) {
      setPasteLinkList(courseData?.link);
      reset({
        projectName: courseData?.projectName ?? "",
        projectDescription: courseData?.projectDescription ?? "",
        hackathonId: courseData?.hackathonId ?? "",
        userhackathonId: courseData?.userhackathonId ?? "",
        submissionFile: courseData?.submissionFile ?? [],
        link: courseData?.link ?? [],
        id: courseData?.id,
      });
    }
  }, [reset, courseData]);
  const submitted =
    props?.location?.state?.hackathonDataDetails?.submitted || false;
  const hackathonDetails = async () => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.Hackathon.projectFormDetails}${id}`,
        "get"
      );
      setHackathonDataDetails(data);
    } catch (e) {
      throw new Error(`Some error occurred: ${e}`);
    }
  };

  const hackathonSubmission = async (formValue) => {
    const url = `${api.baseURL}${api.Hackathon.hackathonSubmission}`;
    var LinkLength = [];
    for (var i = 0; i <= formValue?.link?.length; i++) {
      LinkLength.push(formValue?.link[i]?.value ?? []);
    }
    const removeValue = LinkLength.pop();
    const payload = {
      ...formValue,
      hackathonId: props?.location?.state?.hackathonDataDetails?.id,
      userId: userId,
      submissionFile: formValue?.submissionFile ?? [],
      // link: formValue?.link,
      link: pasteLinkList,
      // link: [{ label: formValue?.link, value: formValue.link }],
    };

    try {
      const data = await apiHelper.axiosCallPost(url, "post", payload);
      history.push(
        `/hackathonSubmission/${props?.location?.state?.hackathonDataDetails?.id}`
      );
    } catch (error) {
      throw new Error(`Some error occurred: ${error}`);
    }
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List component="nav" aria-label="main mailbox folders">
        <ListItem
          button
          onClick={() => {
            history.push(
              `/hackathonSubmission/${props?.location?.state?.hackathonDataDetails?.id}`
            );
          }}
        >
          <ArrowBackSharpIcon />
          <ListItemText primary="Back" />
        </ListItem>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <ListItem
            button
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
            style={
              selectedIndex === 0
                ? {
                    backgroundColor: "#1BA94C",
                    color: "#fff",
                    borderRadius: "10px",
                  }
                : {}
            }
          >
            <ListItemText primary="Submissions" />
          </ListItem>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <ListItem
            button
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
            style={
              selectedIndex === 1
                ? {
                    backgroundColor: "#1BA94C",
                    color: "#fff",
                    borderRadius: "10px",
                  }
                : {}
            }
          >
            <ListItemText primary="Team" />
          </ListItem>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <ListItem
            button
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
            style={
              selectedIndex === 2
                ? {
                    backgroundColor: "#1BA94C",
                    color: "#fff",
                    borderRadius: "10px",
                  }
                : {}
            }
          >
            <ListItemText primary="Participants" />
          </ListItem>
        </div>
      </List>
    </div>
  );

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List component="nav" aria-label="main mailbox folders">
        <div
          style={{ display: "flex" }}
          onClick={() => {
            history.push(
              `/hackathonSubmission/${props?.location?.state?.hackathonDataDetails?.id}`
            );
          }}
        >
          <ArrowBackSharpIcon />
          <h1 style={{ fontSize: "16px" }}>Back</h1>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <ListItem
            button
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
            style={
              selectedIndex === 0
                ? {
                    backgroundColor: "#1BA94C",
                    color: "#fff",
                    borderRadius: "10px",
                  }
                : {}
            }
          >
            <ListItemText primary="Submissions" />
          </ListItem>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <ListItem
            button
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
            style={
              selectedIndex === 1
                ? {
                    backgroundColor: "#1BA94C",
                    color: "#fff",
                    borderRadius: "10px",
                  }
                : {}
            }
          >
            <ListItemText primary="Team" />
          </ListItem>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <ListItem
            button
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
            style={
              selectedIndex === 2
                ? {
                    backgroundColor: "#1BA94C",
                    color: "#fff",
                    borderRadius: "10px",
                  }
                : {}
            }
          >
            <ListItemText primary="Participants" />
          </ListItem>
        </div>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const submissionCheck = watch("submissionFile");
  return (
    <div className={classes.root}>
      {smallScreen && (
        <div style={{ position: "absolute", top: "1rem" }}>
          <div style={{ display: "flex" }}>
            {["left"].map((anchor) => (
              <div key={anchor}>
                <Button onClick={toggleDrawer(anchor, true)}>
                  <svg
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.6665 0H0.333172V2.66667H21.6665V0ZM16.3332 6.66667H0.333172V9.33333H16.3332V6.66667ZM9.6665 13.3333H0.333172V16H9.6665V13.3333Z"
                      fill="#63606C"
                    />
                  </svg>
                </Button>
                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                >
                  {list(anchor)}
                </SwipeableDrawer>
              </div>
            ))}
            <div>
              <p className="mobileNavHeader pl-3">Hackathon submission</p>
            </div>
          </div>
        </div>
      )}
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>

        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={smallScreen ? classes.contentMobile : classes.content}>
        <div className="pt-3">
          <div>
            <form
              className={smallScreen ? "" : "manage-prepare__form"}
              onSubmit={handleSubmit(hackathonSubmission)}
            >
              <main className={classes.content}>
                <Typography className={classes.submission}>
                  {hackathonDataDetails?.name}
                </Typography>
                <Typography paragraph>
                  Submit your hackathon Files here
                </Typography>
                <div>
                  <label className="terv-label">
                    {RequiredIndicator("Name of your project")}
                  </label>
                  <input
                    {...register("projectName")}
                    className={
                      smallScreen
                        ? "terv-form terv-form-md"
                        : "terv-form terv-form-lg"
                    }
                    style={{ backgroundColor: "#ffffff" }}
                    disabled={submitted}
                  />
                  {errors.projectName && (
                    <ErrorBlock
                      eType="div"
                      errorText={errors.projectName.message || ""}
                    />
                  )}
                </div>
                <div className="pt-3" style={{ width: "45%" }}>
                  <label className="terv-label">
                    {RequiredIndicator("Project description")}
                  </label>
                  {!smallScreen && (
                    <p>Write desription of your project in under 300 words</p>
                  )}
                  {smallScreen && (
                    <p style={{ width: "340px" }}>
                      Write desription of your project in under 300 words
                    </p>
                  )}
                  <Controller
                    name="projectDescription"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RichTextEditor onChange={onChange} value={value || ""} />
                    )}
                  />
                  {errors.projectDescription && (
                    <ErrorBlock
                      eType="span"
                      errorText={errors.projectDescription.message || ""}
                    />
                  )}
                </div>
                {submitted &&
                  hackathonDataDetails?.submissionFile?.length > 0 &&
                  hackathonDataDetails?.submissionFile?.map((data, index) => (
                    <div className="pt-2 pb-2">
                      <Card
                        className={classes.hackathonList}
                        variant="outlined"
                        key={index}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <CardContent>
                            <Typography className={classes.title}>
                              {data?.slice(0, 30)}
                            </Typography>
                          </CardContent>
                        </div>
                      </Card>
                    </div>
                  ))}
                {!submitted &&
                  (valuCheck?.pdfSubmission ||
                    valuCheck?.pptSubmission ||
                    valuCheck?.videoSubmission) && (
                    <div className="pt-3 pb-3">
                      <div>
                        <label className="terv-label">
                          {RequiredIndicator("Upload your submission files")}
                        </label>
                        <p>{`Types of submission allowed ${
                          valuCheck?.pdfSubmission ? "PDF ," : ""
                        }${valuCheck?.pptSubmission ? "PPT ," : ""}${
                          valuCheck?.videoSubmission ? "Video" : ""
                        }`}</p>
                        <Controller
                          name="submissionFile"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <AddMediaComponent
                              handleChange={onChange}
                              value={value}
                              editingValue={courseData?.submissionFile ?? []}
                              setValue={setValue}
                              moduleName={"hackathon"}
                              isSubmited={courseData?.submitSelection}
                              mediaType={
                                (valuCheck?.pdfSubmission ||
                                  valuCheck?.pptSubmission) &&
                                valuCheck?.videoSubmission
                                  ? "acceptedFiles: application/pdf,.ppt,.pptx, .pdf, video/*"
                                  : valuCheck?.videoSubmission
                                  ? "video/*"
                                  : valuCheck?.pdfSubmission ||
                                    valuCheck?.pptSubmission
                                  ? "application/pdf,.ppt,.pptx,"
                                  : null
                              }
                            />
                          )}
                        />
                        {errors.submissionFile && (
                          <ErrorBlock
                            eType="div"
                            errorText={errors.submissionFile.message || ""}
                            maxWidth="12.5rem"
                          />
                        )}
                      </div>
                    </div>
                  )}
                {(valuCheck?.portfolioSubmission ||
                  valuCheck?.githubSubmission ||
                  valuCheck?.articleSubmission) && (
                  <div
                    style={
                      smallScreen ? { width: "75rem" } : { width: "30rem" }
                    }
                  >
                    <label className="terv-label">
                      Paste any link to your project
                    </label>
                    <div></div>
                    {pasteLinkList.map((x, index) => {
                      return (
                        <div style={{ paddingBottom: "1.5rem" }}>
                          <input
                            name="link"
                            value={typeof x == "object" ? "" : x}
                            style={{
                              width: "350px",
                              height: "40px",
                              background: "#FAFBFC",
                              border: "1px solid #DFE1E6",
                              boxSizing: "border-box",
                              borderRadius: "3px",
                            }}
                            onChange={(e) => handleInputChange(e, index)}
                          />

                          {pasteLinkList.length !== 1 && (
                            <HighlightOffIcon
                              style={{ color: "#EB5757", width: "26px" }}
                              onClick={() => handleRemoveClick(index)}
                            />
                          )}

                          {pasteLinkList.length - 1 === index && (
                            <h5
                              style={{
                                width: "217px",
                                fontSize: "14px",
                                fontFamily: "Poppins",
                                color: "#1BA94C",
                              }}
                            >
                              <br />
                              <AddCircleOutlineOutlinedIcon
                                style={{ color: "#1BA94C" }}
                                onClick={handleAddClick}
                              />
                              &nbsp; Add another link
                            </h5>
                          )}
                        </div>
                      );
                    })}
                    {/* <Controller
                name="link"
                control={control}
                render={({ field: { onChange, value } }) => (
              <CreatableSelect
                isMulti
                onChange={onChange}
                value={value}       
              />
              )}
            /> */}
                  </div>
                )}
                {!submitted ? (
                  <div style={{ display: "flex" }}>
                    <div className="pr-2 pt-2">
                      <TervButtonFilled
                        text={"Save draft"}
                        type="submit"
                        variant="outlined"
                        style={{ width: "74px" }}
                        disabled={submissionCheck?.length <= 0}
                      />
                    </div>
                    <div className="pr-2 pt-2">
                      <TervButtonOutlined
                        text={"Cancel"}
                        variant="outlined"
                        onClick={() => {
                          history.push(
                            `/hackathonSubmission/${props?.location?.state?.hackathonDataDetails?.id}`
                          );
                        }}
                        style={{ width: "74px" }}
                      />
                    </div>
                  </div>
                ) : (
                  <TervButtonOutlined
                    text={"Back to Hackathon"}
                    variant="outlined"
                    icon={
                      <svg
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.832 5.16706H2.9487L5.9737 1.53373C6.11515 1.36354 6.1832 1.14414 6.16288 0.923789C6.14256 0.703434 6.03554 0.500175 5.86536 0.358726C5.69518 0.217277 5.47578 0.149225 5.25543 0.169542C5.03507 0.189858 4.83181 0.296879 4.69036 0.467059L0.523698 5.46706C0.495665 5.50683 0.470597 5.54861 0.448698 5.59206C0.448698 5.63373 0.448698 5.65873 0.390365 5.70039C0.352593 5.79594 0.332816 5.89765 0.332031 6.00039C0.332816 6.10313 0.352593 6.20484 0.390365 6.30039C0.390365 6.34206 0.390364 6.36706 0.448698 6.40872C0.470597 6.45218 0.495665 6.49396 0.523698 6.53373L4.69036 11.5337C4.76872 11.6278 4.86683 11.7034 4.97774 11.7553C5.08864 11.8071 5.20961 11.8339 5.33203 11.8337C5.52674 11.8341 5.71544 11.7663 5.86536 11.6421C5.94975 11.5721 6.0195 11.4862 6.07062 11.3892C6.12175 11.2923 6.15325 11.1862 6.16331 11.077C6.17337 10.9679 6.16181 10.8578 6.12927 10.7532C6.09673 10.6485 6.04387 10.5513 5.9737 10.4671L2.9487 6.83373H12.832C13.053 6.83373 13.265 6.74593 13.4213 6.58965C13.5776 6.43337 13.6654 6.22141 13.6654 6.00039C13.6654 5.77938 13.5776 5.56742 13.4213 5.41114C13.265 5.25486 13.053 5.16706 12.832 5.16706Z"
                          fill="#3A3A3A"
                        />
                      </svg>
                    }
                    onClick={() => {
                      history.push(
                        `/hackathonSubmission/${props?.location?.state?.hackathonDataDetails?.id}`
                      );
                    }}
                    style={{ width: "10rem" }}
                  />
                )}
              </main>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ProjectSubmissionForm;
