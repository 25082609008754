import { useState, useEffect, forwardRef } from "react";
import { Controller, useForm } from "react-hook-form";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import CourseCard from "./course-card";
import VariousCourse from "./various-course";
import { GetItemWithMedia } from "pages/utils/GetItemWithMedia";
import apiHelper from "../../api/apiHelper";
import api from "../../api/baseConfig";
import "./prepares.css";
import PrepareHeroCard from "./prepareHeroCard";
import { Checkbox, useMediaQuery } from "@material-ui/core";
import { Tabs } from "../../components/tabs/tabsStudent";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import { Button, FormControlLabel, Radio, FormGroup } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useTranslation } from "react-i18next";
import CustomSearch from "../Tasks/CustomSearch";
import Switch from "@material-ui/core/Switch";
import { AdminStudentEnrolled } from "pages/Dashboard/SampleData";
import Skeleton from "@material-ui/lab/Skeleton";
const drawerWidth = 290;
const TabScrollButton = forwardRef((props, ref) => {
  const { direction, ...other } = props;
  return (
    <ButtonBase
      component="div"
      ref={ref}
      style={{ opacity: other.disabled ? 0 : 1 }}
      {...other}
    >
      {direction === "left" ? (
        <ArrowBackIosIcon
          fontSize="small"
          className="arrow-icons"
          style={{ fill: "black" }}
        />
      ) : (
        <ArrowForwardIosIcon
          fontSize="small"
          className="arrow-icons"
          style={{ fill: "black" }}
        />
      )}
    </ButtonBase>
  );
});
function Prepare(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      backgroundColor: "#ffffff",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      zIndex: 0,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    submission: {
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "32px",
      color: "#19181B",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "26px",
      color: "#323036",
    },
    paragraph: {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "26px",
      color: "#63606C",
      width: "35rem",
    },
    pos: {
      marginBottom: 12,
    },
    hackathonList: {
      width: "60rem",
    },
    hackathonListMobile: {
      width: "20rem",
    },
  }));
  const CustomFormLabel = withStyles({
    label: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "0.9375rem",
      lineHeight: "1.375rem",
      color: "#323036",
    },
  })(FormControlLabel);
  const CustomCheckbox = withStyles({
    root: {
      color: "#794df5",
      "&$checked": {
        color: "#794df5",
      },
    },
    icon: {
      borderRadius: "",
      width: 16,
      height: 16,
      boxShadow:
        "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: "#f5f8fa",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: "#ebf1f5",
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
      },
    },
    checkedIcon: {
      backgroundColor: "#137cbd",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: "#106ba3",
      },
    },
  })(Checkbox);
  const PriceSlider = withStyles({
    root: {
      color: "#1BA94C",
      height: 3,
      padding: "13px 0",
    },
    thumb: {
      height: 17,
      width: 17,
      backgroundColor: "#fff",
      border: "2px solid #1BA94C",
      marginTop: "-7px",
      marginLeft: -13,
      borderRadius: "5px",
      boxShadow: "#ebebeb 0 2px 2px",
      "&:focus, &:hover, &$active": {
        boxShadow: "#ccc 0 2px 3px 1px",
      },
    },
    active: {},
    track: {
      height: 3,
    },
    rail: {
      color: "#EBEBEB",
      opacity: 1,
      height: 3,
    },
  })(Slider);
  const id = sessionStorage.getItem("user_id");
  const [searchTerm, setSearchTerm] = useState("");
  const [courseSearch, setCourseSearch] = useState("");
  const [topPackages, setTopPackages] = useState([]);
  const [notStartedCount, setNotStartedCount] = useState(0);
  const [variousCourse, setVariousCourse] = useState([]);
  const [exploreCourse, setExploreCourse] = useState(false);
  const [activeTab, setActiveTab] = useState("ContinueLearning");
  const [courseTag, setCourseTag] = useState([""]);
  const [filterValue, setFilterValue] = useState([]);
  const [slidervalue, setSliderValue] = useState(["0", "5000"]);
  const [filterUpdateLength, setFilterUpdateLength] = useState([]);
  const [enrolled, setEnrolled] = useState(false);
  const [categoryNameDupValue, setCategoryNameDupValue] = useState([]);
  const [loadingCourse, setLoadingCourse] = useState(false);
  const [filterLength, setFilterLength] = useState(0);
  const [durationUpdate, setDurationUpdate] = useState("");

  const [durationValue, setDurationValue] = useState([]);
  const [languageValue, setLanguageValue] = useState([]);
  const [starValue, setStarValue] = useState([]);
  const [levelValue, setLevelValue] = useState([]);
  const [chapterValue, setChaptersValue] = useState([]);
  const [offerValue, setOfferValue] = useState([]);
  const [categoryValues, setCategoryValues] = useState([]);
  const [tagsValue, setTagsValue] = useState([]);
  const [selFilterType, setSelFilterType] = useState([]);
  const [customSearchValue, setCustomSearchValue] = useState("");
  const [searchValues, setSearchValues] = useState("");
   const [allData, setAllData] = useState([]);

  const tabList = [
    {
      name: "Continue Learning",
      id: "ContinueLearning",
    },
    {
      name: "Completed Course",
      id: "CompletedCourse",
    },
  ];
  const tabListAll = [
    {
      name: "All Course",
      id: "ContinueLearning",
    },
  ];
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const MdScreen = useMediaQuery("(max-width: 1070px)");
  const methods = useForm({
    mode: "onBlur",
  });
  const {
    register,
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
    value,
    setValue,
    getValues,
    watch,
  } = methods;
  const { t } = useTranslation();
  useEffect(() => {
    getTopPackages();
  }, []);

  let categoryName = Object.keys(topPackages);
  let filterName = Object.keys(filterValue);
  
  const userId = sessionStorage.getItem("user_id");
  const classes = useStyles();
  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const getTopPackages = async () => {
    setLoadingCourse(true);
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.courseController.getUserCourses}${id}`,
        "get"
      );
      if (data) {
        const newList = [];
        let noNotStarted = 0;
        data.forEach((course) => {
          if (course.status === "NotStarted") noNotStarted++;
          newList.push(GetItemWithMedia(course));
        });
        setNotStartedCount(noNotStarted);
        setTopPackages(newList);
        setVariousCourse(newList);
      }
      setLoadingCourse(false);
    } catch (e) {}
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleExploreCourse = () => {
    setExploreCourse(!exploreCourse);
  };
  const handleCourseChange = (e) => {
    setCourseSearch(e.target.value);
  };

  function valuetext(value) {
    return `${value}°C`;
  }
  useEffect(() => {
    watch((value, { name, type }) => setFilterValue(value));
  }, [watch]);

  useEffect(() => {
    if (exploreCourse) {
      prepareFilter(filterUpdate);
    }
  }, [filterValue, slidervalue]);

  var categoryNameDup = [];
  variousCourse.forEach((val) => {
    categoryNameDup.push(val?.category);
  });
  var categoryNameId = [];
  variousCourse.forEach((val) => {
    categoryNameId.push(val?.categoryId);
  });

  var valName = [];
  for (let valCategory of new Set(categoryNameDup)) {
    valName.push(valCategory);
  }
  var valNameId = [];
  for (let valId of new Set(...[categoryNameId])) {
    valNameId.push(valId);
  }
  const buildMap = (keys, values) => {
    const map = new Map();
    for (let i = 0; i < keys.length; i++) {
      map.set(keys[i], values[i]);
    }
    return map;
  };
filterCourseTags
  // const PackageList = topPackages.map(data => data.numberOfModules > 2)

  var filterUpdateCategory = buildMap(valName, valNameId);
  filterUpdateCategory = Array.from(filterUpdateCategory, ([keys, values]) => ({
    keys,
    values,
  }));
  var filterUpdate = filterName.filter((name) => {
    if (filterValue[name] == true) {
      return true;
    } else {
      return false;
    }
  });
  //filterCourseTag value assigned here

  var filterCourseTags = filterUpdate.filter((name) => {
    if (
      name == "latest" ||
      name == "special" ||
      name == "premium" ||
      name == "featured"
    ) {
      return true;
    } else {
      return false;
    }
  });

  //filter Languages value assigned here
  var filterLanguages = filterUpdate.filter((name) => {
    if (name == "tamil" || name == "hindi" || name == "english") {
      return true;
    } else {
      return false;
    }
  });

  //filter star value assigned here

  var filterStar = filterUpdate.filter((name) => {
    if (name == "1 & above" || name == "2 & above" || name == "3 & above" || name == "4 & above") {
      return true;
    } else {
      return false;
    }
  });

  var filterlevels = filterUpdate.filter((name) => {
    if (name == "beginner" || name == "intermediate" || name == "advance") {
      return true;
    } else {
      return false;
    }
  });

  var filterChapters = filterUpdate.filter((name) => {
    if (name == 1 || name == 2 || name == 3 || name == 4) {
      return true;
    } else {
      return false;
    }
  });

  var filterDuration = filterUpdate.filter((name) => {
    if (name == 1 || name == 2 || name == 3 || name == 4) {
      return true;
    } else {
      return false;
    }
  });

  // setDurationValue(filterDuration);

  var filterOffer = filterUpdate.filter((name) => {
    if (name == "25" || name == "50" || name == "75" || name == "100") {
      return true;
    } else {
      return false;
    }
  });
  var filterCategory = filterUpdate.filter((name) => 
      filterUpdateCategory.map((data) => name === data?.values).includes(true)
    );
  useEffect(() => {
    setFilterLength(filterUpdate.length);
  }, [filterUpdate.length]);

  useEffect(() => {
    setDurationUpdate(filterUpdate);
  },[]);

  useEffect(() => {
    if(selFilterType?.length > 0 || tagsValue?.length > 0) {
    prepareFilterValue();
    }
  },[selFilterType, tagsValue]);

  useEffect(() => {
    if(categoryValues?.length > 0 || offerValue?.length > 0) {
    prepareFilterValue();
    }
  },[categoryValues, offerValue]);

  useEffect(() => {
    if(chapterValue?.length > 0 || levelValue?.length > 0) {
    prepareFilterValue();
    }
  },[chapterValue, levelValue]);

  useEffect(() => {
    if(starValue?.length > 0 || languageValue?.length > 0) {
    prepareFilterValue();
    }
  },[starValue, languageValue]);

  function uncheckAll() {
    var inputs = document.querySelectorAll(".checked");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].checked = false;
    }
    // prepareFilterValue();
    setSelFilterType([]);
    setTagsValue([]);
    setStarValue([]);
    setLevelValue([]);
    setChaptersValue([]);
    setLevelValue([]);
    setOfferValue([]);
    setCategoryValues([]);
    setLanguageValue([]);
    setFilterValue("");
    prepareFilter();
  }

  const prepareFilterValue = async () => {
    const url = `${api.baseURL}${api.Prepare.filter}/${userId}`;
    const payload = {
      courseTags: tagsValue,
      languages: languageValue,
      userRatings: starValue,
      levels: levelValue,
      chapters: chapterValue,
      offers: offerValue,
      duration: selFilterType,
      priceStart: slidervalue[0],
      priceEnd: slidervalue[1],
      categoryId: categoryValues,
      nameAsc: filterValue.nameSort == "nameAsc" ? true : false,
      nameDesc: filterValue.nameSort == "nameDesc" ? true : false,
      dateAsc: filterValue.nameSort == "dateAsc" ? true : false,
      dateDesc: filterValue.nameSort == "dateDesc" ? true : false,
      free: filterValue?.freePrice == "free" ? true : false,
      paid: filterValue?.freePrice == "price" ? true : false,
    };

    try {
      const data = await apiHelper.axiosCallPost(url, "post", payload);

      if (data) {
        setTopPackages(data);

        data.forEach((e) => {
          setFilterUpdateLength(e?.categoryId);
        });

        // setFilterUpdateLength(data)
      }
    } catch (error) {
      throw new Error(`Some error occurred: ${error}`);
    }
  };

   const prepareFilter = async () => {
    const url = `${api.baseURL}${api.Prepare.filter}/${userId}`;
    const payload = {
      courseTags: filterCourseTags,
      languages: filterLanguages,
      userRating: filterStar,
      levels: filterlevels,
      chapters: filterChapters,
      offers: filterOffer,
      duration: filterDuration,
      priceStart: slidervalue[0],
      priceEnd: slidervalue[1],
      categoryId: filterCategory,
      nameAsc: filterValue.nameSort == "nameAsc" ? true : false,
      nameDesc: filterValue.nameSort == "nameDesc" ? true : false,
      dateAsc: filterValue.nameSort == "dateAsc" ? true : false,
      dateDesc: filterValue.nameSort == "dateDesc" ? true : false,
      free: filterValue?.freePrice == "free" ? true : false,
      paid: filterValue?.freePrice == "price" ? true : false,
    };

    try {
      const data = await apiHelper.axiosCallPost(url, "post", payload);

      if (data) {
        setTopPackages(data);

        data.forEach((e) => {
          setFilterUpdateLength(e?.categoryId);
        });

        // setFilterUpdateLength(data)
      }
    } catch (error) {
      throw new Error(`Some error occurred: ${error}`);
    }
  };
  const tags = ["latest", "premium", "special", "featured"];
  const userRating = [1, 2, 3, 4];
  const durations = [1, 2, 3, 4];
  const level = ["beginner", "intermediate", "advance"];
  const languages = ["english", "tamil", "hindi"];
  const numberOfModules = [ 1, 2, 3, 4];
  const offers = [ "25","50", "75","100"
  ];

  function checkAll() {
    var inputs = document.querySelectorAll(".checked");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].checked = true;
    }
  }

  var inputsCheckValue = document.querySelectorAll(".checked");

  useEffect(() => {
    var inputs = document.querySelectorAll(".checked");
    let checkedValue = Object.values(inputs).filter((key) => {
      if (key.checked == true) {
        return true;
      }
    });
    setFilterLength(checkedValue.length);
  }, [inputsCheckValue]);

  window.onload = function () {
    window.addEventListener("load", checkAll, false);
  };

  const searchData = (query) => {
    let arr = [];
    if (query && query.length > 0) {
      topPackages.forEach((e) => {
        const objArr = Object.keys(e);
        for (let i = 0; i < objArr.length; i++) {
          if (
            e[objArr[i]] &&
            typeof e[objArr[i]] === "string" &&
            e[objArr[i]].toLowerCase().includes(query.toLowerCase())
          ) {
            arr.push(e);
            break;
          }
        }
      });
      if (arr.length > 0) {
        setAllData(arr);
      } else {
        setAllData([]);
      }
    } else {
      setAllData(topPackages);
    }
  };

  // const searchData = (query) => {
  //   let arr = [];
  //   setSearchValues(query);
  //   if (query && query.length > 0) {
  //     topPackages.forEach((e) => {
  //       const objArr = (e.description);
  //       const objName = (e.name);
  //       for (let i = 0; i < objArr.length; i++) {
  //         if (
  //           (objArr && objArr.toLowerCase().includes(query.toLowerCase())) ||
  //           (objName && objName.toLowerCase().includes(query.toLowerCase()))
  //         ) {
  //           arr.push(e);
  //           break;
  //         }
  //       }
  //     });
  //     if (arr.length > 0) {
  //       setAllData(arr);
  //     } else {
  //       setAllData([]);
  //     }
  //   } else {
  //     setAllData(topPackages);
  //   }
  // };


  const drawer = (
    <form
      onSubmit={handleSubmit(prepareFilter)}
      className="scrollDesign"
      style={{ overflowY: "scroll" }}
    >
      <div className={classes.toolbar} />
      <List component="nav">
        <div
          style={{
            display: "flex",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "10px",
          }}
        >
          <ListItem>
            <Button type="submit">
              <p
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "26px",
                  color: "#252133",
                }}
              >
                Filter
              </p>
            </Button>
          </ListItem>
          <ListItem>
            <Button
              onClick={() => {
                uncheckAll();
              }}
            >
              <p
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "26px",
                  color: "#2C5DE5",
                  textTransform: "capitalize",
                  whiteSpace: "nowrap",
                }}
              >
                Clear filters
              </p>
            </Button>
          </ListItem>
        </div>
        <Divider />
        <div
          className="pl-2 pr-2 pt-2"
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "10px",
          }}
        >
          <p className="filterSubHeading">Sort by</p>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <div className="pt-1">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "baseline",
              }}
            >
              <div>
                <input
                  className="checked"
                  name="nameSort"
                  value="nameAsc"
                  style={{ width: "17px", height: "17px" }}
                  {...register("nameSort")}
                  type="radio"
                />
              </div>
              <div className="filterOption pl-2">Ascending - Decending</div>
            </div>
          </div>
          <div className="pt-1">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "baseline",
              }}
            >
              <input
                className="checked"
                name="nameSort"
                value="nameDesc"
                style={{ width: "17px", height: "17px" }}
                {...register("nameSort")}
                type="radio"
              />
              <div className="filterOption pl-2">Decending - Ascending</div>
            </div>
          </div>
          <div className="pt-1">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "baseline",
              }}
            >
              <input
                className="checked"
                // name="dateSort"
                name="nameSort"
                value="dateAsc"
                style={{ width: "17px", height: "17px" }}
                {...register("nameSort")}
                type="radio"
              />
              <div className="filterOption pl-2">
                Oldest course - Latest course
              </div>
            </div>
          </div>
          <div className="pt-1 pb-1">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "baseline",
              }}
            >
              <input
                className="checked"
                // name="dateSort"
                name="nameSort"
                value="dateDesc"
                style={{ width: "17px", height: "17px" }}
                {...register("nameSort")}
                type="radio"
              />
              <div className="filterOption pl-2">
                Latest course - Oldest course
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div
          className="pl-2 pr-2 pt-2"
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "10px",
          }}
        >
          <p className="filterSubHeading">Show Course</p>
        </div>
        <div className="pl-2">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "baseline",
            }}
          >
            <div className="pt-1 pb-1">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                <input
                  className="checked"
                  name="freePrice"
                  value="price"
                  style={{ width: "17px", height: "17px" }}
                  {...register("freePrice")}
                  type="radio"
                />
                <span className="pl-2 filterOption">Price</span>
              </div>
            </div>
          </div>
        </div>
        <div className="pl-2">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "baseline",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "baseline",
              }}
            >
              <div className="pt-1 pb-1">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "baseline",
                  }}
                >
                  <input
                    className="checked"
                    name="freePrice"
                    value="free"
                    style={{ width: "17px", height: "17px" }}
                    {...register("freePrice")}
                    type="radio"
                  />
                  <span className="pl-2 filterOption">Free</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <Divider />
        <div
          className="pl-2 pr-2 pt-2"
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "10px",
          }}
        >
          <p className="filterSubHeading">Categories</p>
        </div>
        {filterUpdateCategory?.map((data, index) => (
          <div key={index}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "baseline",
              }}
            >
              <div className="pt-1 pb-1">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "baseline",
                  }}
                >
                  <div className="pr-2 pl-2">
                    <input
                      className="checked"
                      name={data?.values}
                      {...register(`${data?.values}`)}
                      style={{
                        width: "20px",
                        height: "15px",
                        borderRadius: "5px",
                      }}
                      onChange={(e) => {
                        setCategoryValues(
                          e.target.checked
                            ? [...categoryValues, data?.values]
                            : categoryValues.filter((pr) => pr !== data?.values)
                        );
                      }}
                      type="checkbox"
                    />
                  </div>
                  <div>
                    <div>
                      <div style={{ display: "flex" }}>
                        <div className="filterOption">{data?.keys}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <Divider />
        <div
          className="pl-2 pr-2 pt-2"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <p className="filterSubHeading">Tags</p>
        </div>
        <div className="pt-1 pb-1">
          {tags.map((element, index) => (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                <div className="pt-1 pb-1">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "baseline",
                    }}
                  >
                    <div className="pr-2 pl-2">
                    <input
                        name={element}
                        className="checked"
                        {...register(`${element}`)}
                        style={{
                          width: "20px",
                          height: "15px",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => {
                          setTagsValue(
                            e.target.checked
                              ? [...tagsValue, element]
                              : tagsValue.filter((pr) => pr !== element)
                          );
                        }}
                        type="checkbox"
                      />
                    </div>
                    <div>
                      <div
                        style={{
                          backgroundColor: "#878391",
                          padding: "5px 20px",
                          borderRadius: "4px",
                        }}
                      >
                        <div style={{ display: "flex", color: "white" }}>
                          <div className="filterOptionTags">{element}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Divider />
        <div className="pl-2 pr-2 pt-2">
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <p className="filterSubHeading">Price</p>
          </div>
          <div className="pl-2">
            <p className="filterOption">
              INR {`${slidervalue[0]}`} - {`${slidervalue[1]}`}
            </p>
            <PriceSlider
              value={slidervalue}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={valuetext}
              defaultValue={30}
              step={50}
              marks
              min={100}
              max={5000}
            />
          </div>
        </div>
        <Divider />

        <div
          className="pl-2 pr-2 pt-2"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <p className="filterSubHeading">Ratings</p>
        </div>
        <div>
          <div>
          {userRating.map((element, index) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "baseline",
                  }}
                >
                  <div className="pt-1 pb-1">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "baseline",
                      }}
                    >
                      <div className="pr-2 pl-2">
                      <input
                        name={element}
                        className="checked"
                        {...register(`${element}`)}
                        style={{
                          width: "20px",
                          height: "15px",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => {
                          setStarValue(
                            e.target.checked
                              ? [...starValue, element]
                              : starValue.filter((pr) => pr !== element)
                          );
                        }}
                        type="checkbox"
                      />
                      </div>
                      <div>
                        <div>
                          <div>
                            <div className="filterOption">
                            {
                    element === 1
                      ? "1 & above"
                      : element === 2
                      ? "2 & above"
                      : element === 3
                      ? "3 & above"
                      : element === 4
                      ? "4 & above"
                      : element
                  }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Divider />
        <div
          className="pl-2 pr-2 pt-2"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <p className="filterSubHeading">Chapter</p>
        </div>
        <div>
          <div>
            {numberOfModules.map((element, index) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "baseline",
                  }}
                >
                  <div className="pt-1 pb-1">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "baseline",
                      }}
                    >
                      <div className="pr-2 pl-2">
                      <input
                        name={element}
                        className="checked"
                        {...register(`${element}`)}
                        style={{
                          width: "20px",
                          height: "15px",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => {
                          setChaptersValue(
                            e.target.checked
                              ? [...chapterValue, element]
                              : chapterValue.filter((pr) => pr !== element)
                          );
                        }}
                        type="checkbox"
                      />
                      </div>
                      <div>
                        <div>
                          <div>
                            <div className="filterOption">
                            {
                    element === 1
                      ? "0-4"
                      : element === 2
                      ? "4-8"
                      : element === 3
                      ? "8-12"
                      : element === 4
                      ? "12 or more"
                      : element
                  }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Divider />
        <div
          className="pl-2 pr-2 pt-2"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <p className="filterSubHeading">Offer</p>
        </div>
        <div>
          {offers.map((element, index) => (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                <div className="pt-1 pb-1">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "baseline",
                    }}
                  >
                    <div className="pr-2 pl-2">
                    <input
                        name={element}
                        className="checked"
                        {...register(`${element}`)}
                        style={{
                          width: "20px",
                          height: "15px",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => {
                          setOfferValue(
                            e.target.checked
                              ? [...offerValue, element]
                              : offerValue.filter((pr) => pr !== element)
                          );
                        }}
                        type="checkbox"
                      />
                    </div>
                    <div>
                      <div style={{ display: "flex" }}>
                        <div className="pl-2">
                          <div className="filterOption">
                          {
                    element === "25"
                      ? "0-25%"
                      : element === "50"
                      ? "25-50%"
                      : element === "75"
                      ? "50-75%"
                      : element === "100"
                      ? "75-100%"
                      : element
                  }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Divider />
        <div
          className="pl-2 pr-2 pt-2"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <p className="filterSubHeading">Level</p>
        </div>
        <div>
          {level.map((element, index) => (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                <div className="pt-1 pb-1">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "baseline",
                    }}
                  >
                    <div className="pr-2 pl-2">
                    <input
                        name={element}
                        className="checked"
                        {...register(`${element}`)}
                        style={{
                          width: "20px",
                          height: "15px",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => {
                          setLevelValue(
                            e.target.checked
                              ? [...levelValue, element]
                              : levelValue.filter((pr) => pr !== element)
                          );
                        }}
                        type="checkbox"
                      />
                    </div>
                    <div>
                      <div style={{ display: "flex" }}>
                        <div className="pl-2">
                          <div className="filterOption">{element}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <Divider />
        <div
          className="pl-2 pr-2 pt-2"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <p className="filterSubHeading">Duration</p>
        </div>
        <div>
          <div>
          {durations.map((element, i) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "baseline",
                  }}
                >
                  <div className="pt-1 pb-1">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "baseline",
                      }}
                    >
                      <div className="pr-2 pl-2">
                     <input
                        name={element}
                        className="checked"
                        {...register(`${element}`)}
                        style={{
                          width: "20px",
                          height: "15px",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => {
                          setSelFilterType(
                            e.target.checked
                              ? [...selFilterType, element]
                              : selFilterType.filter((pr) => pr !== element)
                          );
                        }}
                        type="checkbox"
                      />
                      </div>
                      <div>
                        <div>
                          <div>
                            <div className="filterOption">{
                    element === 1
                      ? "1 or less hour"
                      : element === 2
                      ? "1 - 2 hours"
                      : element === 3
                      ? "2 - 4 hours"
                      : element === 4
                      ? "more than 4 hours"
                      : element
                  }</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
           
          </div>
        </div>
        <Divider />

        <div
          className="pl-2 pr-2 pt-2"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <p className="filterSubHeading">Languages</p>
        </div>
        <div>
          {languages.map((element, index) => (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                <div className="pt-1 pb-1">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "baseline",
                    }}
                  >
                    <div className="pr-2 pl-2">
                    <input
                        name={element}
                        className="checked"
                        {...register(`${element}`)}
                        style={{
                          width: "20px",
                          height: "15px",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => {
                          setLanguageValue(
                            e.target.checked
                              ? [...languageValue, element]
                              : languageValue.filter((pr) => pr !== element)
                          );
                        }}
                        type="checkbox"
                      />
                    </div>
                    <div>
                      <div style={{ display: "flex" }}>
                        <div className="pl-2">
                          <div className="filterOption">{element}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="pt-5"></div>
      </List>
    </form>
  );

  return (
    <div className={`py-4 withFilter7489 ${smallScreen ? "smallScreen" : ""}`}>
      <Drawer
        open={exploreCourse}
        onClose={(e) => setExploreCourse(!exploreCourse)}
        classes={{
          paper: classes.drawerPaper,
        }}
        className={`fliter ${exploreCourse ? "openFilter" : "closeFilter"}`}
        variant={smallScreen ? "temporary" : "permanent"}
      >
        {drawer}
      </Drawer>
      <div
        className={`fliterContainer ${
          exploreCourse ? "openFilter" : "closeFilter"
        }`}
      >
        <Container>
          {exploreCourse ? (
            <>
              <div className="continue-card">
                <h4 className="continue-title">{t("Continue learning")}</h4>
                <h6 className="continue-desc">
                  {t("Start from where you left last time")}
                </h6>
                <div className="mb-4 d-flex justify-content-between align-items-center">
                  <div className="search-input">
                    <input
                      type="search"
                      placeholder="&#xf002; Search courses...."
                      aria-describedby="button-addon4"
                      className="form-controls"
                      style={{ fontFamily: "FontAwesome, Poppins" }}
                      value={courseSearch}
                      onChange={handleCourseChange}
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      className="viewAll"
                      onClick={(e) => setExploreCourse(!exploreCourse)}
                    >
                      View Less
                    </Button>
                  </div>
                </div>
              </div>
              <div>
                <div className="filterIndicate">
                  <div className="filterParagraph">
                    <span style={{ fontWeight: "bold" }}>{filterLength}</span>{" "}
                    Filters Selected
                  </div>
                </div>
              </div>
              <CourseCard
                ContinuePackages={topPackages}
                setExploreCourse={handleExploreCourse}
                exploreCourse={exploreCourse}
                courseSearch={courseSearch}
                activeTab={activeTab}
              />
            </>
          ) : (
            <>
              <PrepareHeroCard smallScreen={smallScreen} />
              {notStartedCount < 1 ||
              topPackages.length - notStartedCount > 0 ? (
                <>
                  <div className="continue-card">
                    <h4 className="continue-title">{t("Continue Learning")}</h4>
                    <h6 className="continue-desc">
                      {t("Start from where you left last time")}
                    </h6>
                    <div className="mb-4 d-flex justify-content-between align-items-center">
                      <div>
              <CustomSearch
              placeholder="Search Courses...."
              secondary
              value={customSearchValue}
              handlesearch={(e) => {
                searchData(e);
                setCustomSearchValue(e);
              }}
              />
                      
                      </div>
                      <div>
                        <Button
                          variant="outlined"
                          className="viewAll"
                          onClick={(e) => setExploreCourse(!exploreCourse)}
                        >
                          View All
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      paddingTop: "40px",
                      borderRadius: 6,
                      overflow: "hidden",
                      boxShadow:
                        "0px 4px 6px -4px rgb(24 39 75 / 12%), 0px 4px 6px -4px rgb(24 39 75 / 12%)",
                    }}
                  >
                    <Tabs
                      activeTab={activeTab}
                      tabs={exploreCourse ? tabListAll : tabList}
                      onChange={setActiveTab}
                      variant="scrollable"
                      scrollButtons="auto"
                      textColor="primary"
                      hideBadge={true}
                      ScrollButtonComponent={TabScrollButton}
                    />
                    <CourseCard
                      loadingCourse={loadingCourse}
                      ContinuePackages={topPackages}
                      setExploreCourse={handleExploreCourse}
                      exploreCourse={exploreCourse}
                      courseSearch={courseSearch}
                      activeTab={activeTab}
                      filterUpdate={filterUpdate}
                      allData={allData}
                      customSearchValue={customSearchValue}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
              {notStartedCount > 0 ? (
                <>
                  {topPackages.length - notStartedCount > 0 && (
                    <Divider style={{ margin: "40px 0 8px 0" }} />
                  )}
                  <div className="continue-card">
                    <h4 className="continue-title">
                      {t("Explore various courses")}
                    </h4>
                    <h6 className="continue-desc">
                      {t("Find a course which you want and improve your skill")}
                    </h6>
                    <div className="mb-4 d-flex justify-content-between align-items-center">
                      <div className="search-input">
                        <input
                          type="search"
                          placeholder="&#xf002; Search courses...."
                          aria-describedby="button-addon4"
                          className="form-controls"
                          style={{ fontFamily: "FontAwesome, Poppins" }}
                          value={searchTerm}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div>
                        <Button
                          variant="outlined"
                          className="viewAll"
                          onClick={(e) => setExploreCourse(!exploreCourse)}
                        >
                          View All
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <VariousCourse
                      topPackages={topPackages}
                      searchTerm={searchTerm}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </Container>
      </div>
    </div>
  );
}

export default Prepare;