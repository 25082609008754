import React, { useState, useEffect } from "react";
import Apihelper from "../../api/apiHelper";
import api from "../../api/baseConfig";
import PracticePackages from "./PracticePackages";
import { connect, useSelector } from "react-redux";
import { TrimString } from "pages/utils/TrimString";
import { sessionPrivileges } from "privileges";
import { GetItemWithMedia } from "pages/utils/GetItemWithMedia";

function AllPracticePackages(props) {
  const userPrivileges = sessionPrivileges();
  const [practicePackages, setPracticePackages] = useState({
    real: {
      featured: [],
      premium: [],
      special: [],
      latest: [],
      all: [],
    },
    modify: {
      featured: [],
      premium: [],
      special: [],
      latest: [],
      all: [],
    },
  });
  const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const [activePage, setActivePage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLabId, setDeleteLabId] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props?.location?.state?.activePage) {
      setActivePage(props?.location?.state?.activePage);
    }
    if (props?.location?.state?.activeTab) {
      setActiveTab(props?.location?.state?.activeTab);
    }
  }, []);

  useEffect(() => {
    if (userPrivileges.isSuperAdmin) {
      if (props?.location?.state?.customer) {
        setCustomerId(props.location.state.customer);
      }
      if (props?.location?.state?.customerName) {
        setCustomerName(props.location.state.customerName);
      }
    } else if (userPrivileges.isAdmin) {
      getCustomerLabs(sessionStorage.getItem("customer_id"));
      setCustomerName(sessionStorage.getItem("customer_name"));
    } else {
      getAdminLabs();
    }
  }, []);

  useEffect(() => {
    if (customerId) {
      getCustomerLabs(customerId);
    }
  }, [customerId]);

  const allCustomers = useSelector((state) =>
    state.StudentAndCustomerReducer.Customers
      ? state.StudentAndCustomerReducer.Customers.reduce((acc, val) => {
          acc.push({
            label: val.name,
            value: val.id,
          });
          return acc;
        }, [])
      : []
  );

  const parseRawData = (data) => {
    let packages = {
      featured: [],
      premium: [],
      special: [],
      latest: [],
      all: [],
    };
    data.forEach((item) => {
      if (item?.tags && packages.hasOwnProperty(item.tags)) {
        packages[item.tags].push(GetItemWithMedia(item));
      }
      packages["all"].push(GetItemWithMedia(item));
    });
    setPracticePackages({ real: packages, modify: packages });
  };

  const getCustomerLabs = async (id) => {
    try {
      const data = await Apihelper.axiosCall(
        `${api.baseURL}${api.lab.getCustomerLabs}${id}`,
        "get"
      );
      parseRawData(data);
    } catch (e) {}
  };

  const getAdminLabs = async () => {
    try {
      const data = await Apihelper.axiosCall(
        `${api.baseURL}${api.lab.getAllLab}`,
        "get"
      );
      parseRawData(data);
    } catch (e) {}
  };

  const deletePracticeLab = async () => {
    const url = `${api.baseURL}${api.lab.deleteLab}${deleteLabId}`;
    try {
      await Apihelper.axiosCallPost(TrimString(url), "post");

      if (userPrivileges.isAdmin) {
        getAdminLabs();
      } else {
        getCustomerLabs(customerId);
      }
    } catch (e) {}
  };

  const handlesearch = (query) => {
    let newList = [];
    if (query) {
      setActivePage(1)
      practicePackages.real[activeTab].forEach((e) => {
        if (e.name.toLowerCase().includes(query.toLowerCase())) {
          newList.push(e);
        }
      });
    }

    setPracticePackages({
      ...practicePackages,
      modify: {
        ...practicePackages.modify,
        [activeTab]: query ? newList : practicePackages.real[activeTab],
      },
    });
  };

  const lastIndex = activePage * 6;
  const firstIndex = lastIndex - 6;

  return (
    <>
    {userPrivileges.showPracticePackages && (
    <PracticePackages
      customerId={customerId}
      handleSearch={handlesearch}
      toggleDeleteModal={() => setDeleteModal((prev) => !prev)}
      deleteModal={deleteModal}
      deleteLabId={deleteLabId}
      setDeleteLabId={setDeleteLabId}
      deletePracticeLab={deletePracticeLab}
      allCustomers={allCustomers}
      setCustomerId={setCustomerId}
      customerName={customerName}
      setCustomerName={setCustomerName}
      firstIndex={firstIndex}
      lastIndex={lastIndex}
      practicePackages={practicePackages}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      activePage={activePage}
      setActivePage={setActivePage}
      isSA={userPrivileges.isSuperAdmin}
      UserId={userPrivileges.userId}
    />
    )}
    </>
  );
}

export default connect(null, null)(AllPracticePackages);
