import { Droppable } from "react-beautiful-dnd";
import { DragDropContext } from "react-beautiful-dnd";
import styles from "./SectionCreation.module.scss";
import { LessonItem } from "./LessonItem";

export const LessonsList = ({
  sectionValues,
  setSectionValues,
  itemIndex,
  setCurrentLesson,
  currentLesson,
  setCurrentSection,
  handleModal,
  removeLesson,
  selSec,
  setSelSec,
  selLes,
  setSelLes,
  handleDeleteConformation,
  updateSelectedLesson,
}) => {
  const onDragEnd = (e) => {
    const source = e.source.index,  destination = e.destination.index;
    if(source > -1 && destination > -1 && source !== destination) {
      const course = sectionValues[itemIndex].courseResource ? [...sectionValues[itemIndex].courseResource] : [];
      course[source] = { ...course[destination], resourceModuleOrder: source };
      course[destination] = { ...sectionValues[itemIndex].courseResource[source], resourceModuleOrder: destination };
      const tempSection = [ ...sectionValues ];
      tempSection[itemIndex].courseResource = course;
      setSectionValues(tempSection);
    }
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`droppableLessons-${itemIndex}`} type="LESSONS">
        {(provided) => (
          <div
            className={styles.section__expanded}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {sectionValues[itemIndex].courseResource.map((lesson, ind) => (
              <LessonItem
                lesson={lesson}
                ind={ind}
                secInd={itemIndex}
                setCurrentLesson={setCurrentLesson}
                currentLesson={currentLesson}
                SetCurrentSection={() => setCurrentSection(itemIndex)}
                handleModal={handleModal}
                remove={() => removeLesson(itemIndex, ind)}
                selSec={selSec}
                setSelSec={setSelSec}
                selLes={selLes}
                setSelLes={setSelLes}
                handleDeleteConformation={handleDeleteConformation}
                sectionValues={sectionValues}
                setSectionValues={setSectionValues}
                updateSelectedLesson={updateSelectedLesson}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
};
