import React, { useState, useEffect } from "react";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";
import QuestionPaperForm from "./QuestionPaperForm";
import { getAllQuestionBanks } from "pages/utils/CommonAPIs";
import { sessionPrivileges } from "privileges";
import { toast, ToastContainer } from "react-toastify";

export default function SubmitQP(props) {
  const [activeTab, setActiveTab] = useState("basicInfo");
  const [questionBanks, setQuestionBanks] = useState([]);
  const [QPinfo, setQPinfo] = useState({ name: "", id: "" });
  const [viewOnly, setViewOnly] = useState(false)
  const [sectionArray, setSectionArray] = useState({});
  const userPrivileges = sessionPrivileges();

  const toggleTab = (newTab, invoke = () => {}) => {
    if (newTab === "sections") {
      invoke();
    } else {
      setActiveTab(newTab);
    }
  };

  const [initialValues, setInitialValues] = useState({
    instruction: `<ul><li>During attempt, you can use the sections link on the navigation panel to access various sections.</li>
    <li>The navigation buttons can be used to move across various questions.</li>
    <li><b>DO NOT</b> attempt multiple sessions in multiple tabs, your answer might not be saved.</li>
    <li>You are free to choose the programming language from the list available to code.</li>
    <li><b>DO NOT</b> press any special keys or functional keys</li>
    </ul>`,
    collegeId: props?.location?.state?.customer
      ? props.location.state.customer
      : "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props?.location?.state?.edit) {
      handleEdit(props?.location?.state?.id, props?.location?.state?.type);
      checkIfQPActive(props?.location?.state?.id)
    }
  }, []);

  const parseEditData = (data) => {
    try {
      if (data.sectionList.length > 0) {
        let val = {};
        let valCount = 0;
        for (let i = 0; i < data.sectionList.length; i++) {
          for (let j = 0; j < data.sectionList[i].length; j++) {
            data.sectionList[i].questionList[j].remChecked = false;
          }
          const {
            id,
            cutOffMark,
            description,
            name,
            questionList,
            sectionOrder,
            timeLimit,
            negativeMark,
            randomQuestionCount,
            mark,
            enableRandom,
          } = data.sectionList[i];
          val[`section-${i}`] = {
            id,
            cutOffMark,
            name,
            description,
            questionList,
            sectionOrder,
            timeLimit,
            negativeMark,
            randomQuestionCount,
            mark,
            enableRandom,
          };
          valCount++;
        }
        if (data.sectionList.length < data.noOfSections) {
          for (
            let z = 0;
            z < data.noOfSections - data.sectionList.length;
            z++
          ) {
            val[`section-${valCount}`] = {
              id: {},
              cutOffMark: "",
              description: "",
              questionList: [],
              sectionOrder: "",
              name: `Un-Saved Section ${z + 1}`,
            };
          }
        }
        setSectionArray(val);
      }
      let tempIV = {};
      tempIV["name"] = data.name;
      tempIV["description"] = data.description;
      tempIV["instruction"] = data.instruction;
      tempIV["noOfSections"] = data.noOfSections;
      tempIV["id"] = data.id;
      tempIV["gradingProcess"] = data.gradingProcess;
      if (data?.collegeId) {
        tempIV["collegeId"] = data.collegeId;
      }

      setInitialValues(tempIV);
    } catch (e) {}
  };

  const handleEdit = async (id, type) => {
    let temp = [];
    try {
      const data = await Apihelper.axiosCallPost(
        `${api.baseURL}${api.QuestionPaperController.getAssessmentQuestionpaperbyId}/?id=${id}`,
        "post",
        {
          id,
        }
      );
      temp = data;
      temp["type"] = type;

      parseEditData(temp);
    } catch (e) {}
  };

  const checkIfQPActive = async (qpId) => {
    try {
      const res = await Apihelper.axiosCall(
        `${api.baseURL}${api.QuestionPaperController.checkQPActive}/${qpId}`,
        "GET"
      )
      setViewOnly(res)
      if(res){
        toast.error('The question paper is assigned for active assessment and hence cannot be updated', {
          position: "top-center",
          autoClose: 11000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (error) {}
  }

  const CreateQP = async (values) => {
    const body = {
      ...values,
      collegeId: values?.collegeId
        ? values?.collegeId
        : sessionStorage.getItem("customer_id"),
      active: true,
      id: values?.id || QPinfo?.id || {},
      createdBy: userPrivileges.userName,
      updatedBy: userPrivileges.userName,
    };
    try {
      const data = await Apihelper.axiosCallPost(
        `${api.baseURL}${api.QuestionPaperController.assessmentQuestionPaperCreate}`,
        "post",
        body
      );
      setQPinfo({ id: data.id, name: data.name });
      // const allQBs = await getAllQuestionBanks();
      // setQuestionBanks(allQBs);
      setActiveTab("sections");
    } catch (e) {}
  };
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop={true}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className={{maxHeight:"80px", overflow:"hidden"}}
      />
    <QuestionPaperForm
      activeTab={activeTab}
      toggleTab={toggleTab}
      initialValues={initialValues}
      viewOnly={viewOnly}
      onSubmit={CreateQP}
      setQuestionBanks={setQuestionBanks}
      questionBanks={questionBanks}
      assessmentQuestionPaperId={QPinfo.id}
      createdName={QPinfo.name}
      sectionArray={sectionArray}
      customer={props?.location?.state?.customer}
      activePage={props?.location?.state?.activePage}
      itemsPerPage={props?.location?.state?.itemsPerPage}
    />
    </div>
  );
}
