import React from "react";
import * as CustomIcons from "../../../assets/icons/icons";
  
export function ResetPassword(props) {
  return (
    <>
    <span
      style={{
        cursor: "pointer",
      }}
      onClick={props.clickEvent}
    >
      {CustomIcons.keyIcon}
    </span>
    </>
  );
}