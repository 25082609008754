import { useState } from 'react';
import { IconButton, Menu as MuiMenu, Tooltip, withStyles, Zoom } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import TimerIcon from '@material-ui/icons/Timer';
import Timer from './timer';
import styles from './stopwatch.compact.module.scss';

const menuListStyles = {
  height: '100%',
  padding: 0,
};

const menuPaperStyles = {
  borderRadius: 12,
  padding: '20px 24px 20px 24px',
};

const MenuLight = withStyles({
  paper: {
    backgroundColor: '#fff',
    border: '2px solid #aaa',
    color: "#000",
    ...menuPaperStyles,
  },
  list: menuListStyles,
})(MuiMenu);

const MenuDark = withStyles({
  paper: {
    backgroundColor: '#222',
    border: '2px solid #666',
    color: "#fff",
    ...menuPaperStyles,
  },
  list: menuListStyles,
})(MuiMenu);

export default function StopwatchRegular(props) {
  const [menuAnchor, setMenuAnchor] = useState(null);

  const Menu = props?.isLightMode ? MenuLight : MenuDark;

  function openMenu(event) {
    setMenuAnchor(event.currentTarget);
  }

  function closeMenu() {
    setMenuAnchor(null);
  }

  return (
    <div className={styles.stopwatch}>
      <Tooltip title="Stopwatch">
        <IconButton
          aria-controls="stopwatch-menu"
          aria-haspopup="true"
          color="inherit"
          edge="end"
          onClick={openMenu}>
          <TimerIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: 'bottom' }}
        id="stopwatch-menu"
        onClose={closeMenu}
        open={Boolean(menuAnchor)}
        TransitionComponent={Zoom}>
        <Timer time={props.time} size="compact" />
        <div className={styles.controls}>
          <Tooltip title={!(props?.isPaused && props?.isActive) ? 'Reset (Disabled)' : 'Reset'}>
            <span>
              <IconButton
                color="secondary"
                disabled={!(props?.isPaused && props?.isActive)}
                onClick={props?.handleReset}
                size="small">
                <CloseIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={props?.isActive ? (props?.isPaused ? 'Resume' : 'Pause') : 'Start'}>
            <IconButton
              color="inherit"
              onClick={() => {
                if (props?.isActive) {
                  props?.handlePauseResume?.();
                } else {
                  props?.handleStart?.();
                }
              }}
              size="small">
              {props?.isPaused ? <PlayArrowIcon /> : <PauseIcon />}
            </IconButton>
          </Tooltip>
        </div>
      </Menu>
    </div>
  );
}