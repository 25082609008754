import React from 'react'
import FaqComponent from './FaqComponent'

const Faq = () => {

    const list = [
        {
            question : "Who is eligible for the Terv Fellowship Program in Software Development?",
            answer : "The Terv Fellowship Program in Software Development is open to all graduates and working professionals."
        },
        {
            question : "What is the duration of this program?",
            answer : "The program will take on average 9 months to complete. The program duration can increase or decrease by 1-2 months based on your current expertise level"
        },
        {
            question : "Is this an online program?",
            answer : "Yes. This is a completely online program"
        },
        {
            question : "How does the program work?",
            answer : "This is an experiential learning program that dives deep into building professional-grade projects in a real-world developer environment. Both the programs give you the opportunity to connect with tech industry experts and have one-on-one sessions with them. With Terv, you can upskill with in-demand tech skills and build your career with confidence."
        },
        {
            question : "Will I get a certificate at the end of the program?",
            answer : "Yes, you will receive a Terv Fellowship Program completion certificate along with a verified GitHub page.  The GitHub page will be a personalised profile to showcase the details of the work you completed in the program. Also, all the code you write in the program will get automatically synced to your GitHub profile."
        }
    ]

  return (
    <div>
        <div
        className="undefined"
        style={{
        margin: "0px",
        border: "0px solid rgb(229, 231, 235)",
        boxSizing: "border-box",
        fontFamily: "Manrope, sans-serif",
        fontSize: "2rem",
        fontWeight: 800,
        font: "800 32px / 44px Manrope, sans-serif",
        lineHeight: 1.375,
        textAlign: "center",
        textTransform: "capitalize",
        color:"white"
        }}
        >
        FAQs{" "}
        <span
        id="faqs"
        style={{
        margin: "0px",
        border: "0px solid rgb(229, 231, 235)",
        boxSizing: "border-box",
        }}
        />
        </div>
        <div className='flex flex-col gap-5 mt-3'>
        {
            list.map((item)=>{
                return <FaqComponent question={item.question} answer={item.answer}/>
            })
        }
        </div>
    </div>
  )
}

export default Faq
