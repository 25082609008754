import { Field } from "redux-form";

const flexStyle = { display: "flex", gap: "1rem", alignItems: "center" };

const Input = ({ input }) => (
  <input className="terv-form terv-form-md" {...input} />
);

const Checkbox = ({ input: { onChange, value } }) => (
  <input
    type="checkbox"
    checked={!!value}
    onChange={(e) => onChange(e.target.checked)}
  />
);

const SecureExamBrowserSettingsOne = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "0.625rem",
        marginLeft: 17,
      }}
    >
      <div className="terv-row">
        <div>
          <label className="terv-label">Proctoring Server</label>
          <Field name="jitsiServer" component={Input} />
        </div>
        <div>
          <label className="terv-label">Proctoring Room</label>
          <Field name="jitsiRoom" component={Input} />
        </div>
      </div>
    </div>
  );
};

export default SecureExamBrowserSettingsOne;
