import { CSSProperties } from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import React from "react";

export const ErrorBlock = ({
  eType,
  errorText,
  maxWidth,
  className,
  style,
}: {
  eType: string;
  errorText: string;
  maxWidth?: number | string;
  className: string | undefined;
  style: CSSProperties | undefined;
}) =>
  React.createElement(
    eType,
    {
      style: {
        color: "red",
        ...(maxWidth && { maxWidth }),
        ...(style && { style }),
      },
      className: className ? className : "",
    },
    errorText
  );
