import { Fragment, useContext, useEffect, useMemo } from 'react';
import cn from 'classnames';
import Scroll from 'react-scroll';
import { Paper, withStyles } from '@material-ui/core';
import { TestContext } from '../../context/test.context';
import { TProps } from './section.props';
import styles from './section.module.scss';

const scroll = Scroll.animateScroll;

const SectionContainer = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
})(Paper);

export function Section(props: React.PropsWithChildren<TProps>) {
  const { largeScreen, lightMode, smallScreen } = useContext(TestContext);

  const questionId = useMemo(() => props?.questionId, [props]);
  const sectionId = useMemo(() => props?.sectionId, [props]);

  useEffect(() => {
    if (sectionId) {
      scroll.scrollToTop({
        containerId: sectionId,
        duration: 0,
      });
    }
  }, [questionId, sectionId]);

  return (
    <SectionContainer
      classes={{
        root: cn(
          styles.container,
          props?.noShadow && styles.noShadow,
          props?.className && props?.className,
          {
            [styles.lightMode]: lightMode,
            [styles.smallScreen]: smallScreen,
            [styles.noFreeScroll]: props?.disableFreeScroll,
          },
        ),
      }}
      style={{
        ...largeScreen && props?.gridPosition && {
          gridColumn: `${props?.gridPosition?.[0]?.[0]} / ${props?.gridPosition?.[0]?.[1]}`,
          gridRow: `${props?.gridPosition?.[1]?.[0]} / ${props?.gridPosition?.[1]?.[1]}`,
        },
        zIndex: 19,
        ...props?.style,
      }}>
      {props.topBar}
      <div
        id={props?.sectionId}
        className={cn(
          styles.content,
          smallScreen && styles.smallScreen,
          props?.contentClassName && props?.contentClassName,
          props?.noRichText && styles.noRichText,
        )}>
        {props.children}
      </div>
      {props.bottomBar}
    </SectionContainer>
  );
}
