import styles from "./EmptyState.module.scss";

export const EmptyState = ({ label = "No Data" }) => (
  <div className={styles.noDataContainer}>
    <div className={styles.noData}>
      <svg width="136" height="85" viewBox="0 0 136 85" fill="none">
        <path
          d="M68 72C95.062 72 117 67.0751 117 61C117 54.9249 95.062 50 68 50C40.938 50 19 54.9249 19 61C19 67.0751 40.938 72 68 72Z"
          fill="#CAC7C7"
        />
        <path
          d="M103.292 32.4106L87.7421 14.9137C86.9958 13.7211 85.9061 13 84.7582 13H51.3265C50.1786 13 49.0889 13.7211 48.3425 14.9122L32.793 32.4121V46.4665H103.292V32.4106Z"
          stroke="#BBBBBB"
        />
        <path
          d="M82.775 37.2343C82.775 34.7928 84.2984 32.7772 86.1881 32.7756H103.292V60.3657C103.292 63.5952 101.269 66.2421 98.7706 66.2421H37.3141C34.816 66.2421 32.793 63.5937 32.793 60.3657V32.7756H49.8966C51.7862 32.7756 53.3096 34.7882 53.3096 37.2297V37.2632C53.3096 39.7047 54.8499 41.6762 56.738 41.6762H79.3466C81.2348 41.6762 82.775 39.6865 82.775 37.2449V37.2343Z"
          fill="#F0F0F0"
          stroke="#B9B9B9"
        />
      </svg>
      <span>{label}</span>
    </div>
  </div>
);
