import React from "react";

export default function ApplyButton() {
  return (
    <>
     <div className="w-full flex justify-center mt-5 mb-5">
     <button
            className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium natural sm flex items-center whitespace-nowrap rounded-[10px] border-primary-200 bg-primary-200 py-2 font-manrope text-base font-bold capitalize text-black shadow-lg shadow-primary-200/50 hover:bg-primary-100 hover:shadow-xl hover:shadow-primary-100/50 z-[999] h-[64px] px-6 css-in0mll"
            type="contained"
            tabIndex="0"
            style={{
              font: "inherit",
              backgroundImage: "none",
              outline: "0px",
              border: "0px",
              margin: "0px",
              textDecoration: "none",
              transition:
                "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
              WebkitBoxAlign: "center",
              WebkitBoxPack: "center",
              justifyContent: "center",
              position: "relative",
              boxSizing: "border-box",
              WebkitTapHighlightColor: "transparent",
              cursor: "pointer",
              userSelect: "none",
              verticalAlign: "middle",
              appearance: "none",
              letterSpacing: "0.02857em",
              minWidth: "64px",
              padding: "5px 30px",
              zIndex: 999,
              display: "flex",
              height: "64px",
              alignItems: "center",
              whiteSpace: "nowrap",
              borderRadius: "10px",
              borderColor: "rgb(255 213 0/1)",
              backgroundColor: "rgb(255 213 0/1)",
              paddingBottom: "0.5rem",
              paddingTop: "0.5rem",
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
              fontFamily: "Manrope, sans-serif",
              fontSize: "1rem",
              lineHeight: "1.5rem",
              fontWeight: 700,
              textTransform: "capitalize",
              color: "rgb(0 0 0/1)",
              boxShadow:
                "var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),0 10px 15px -3px rgba(255,213,0,.5),0 4px 6px -4px rgba(255,213,0,.5)",
            }}
            onClick={() => {
              window.open("https://terv.pro/course-overview/67171bd5730a5a60ae7f1b05");
            }}
          >
            <div
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
              }}
            >
              <div
                className="pr-2 text-left"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  paddingRight: "0.5rem",
                  textAlign: "left",
                }}
              >
                <h6
                  className="font-extrabold"
                  style={{
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflowWrap: "break-word",
                    margin: "0px",
                    fontSize: "1rem",
                    fontWeight: 800,
                  }}
                >
                  Apply & Start for free
                </h6>
              </div>
            </div>
            <svg
              className="svg-inline--fa fa-angle-right ml-2 mt-1 text-xl"
              aria-hidden="true"
              fill="rgb(0, 0, 0)"
              focusable="false"
              role="img"
              viewBox="0 0 256 512"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                maxWidth: "100%",
                display: "var(--fa-display, inline-block)",
                height: "1em",
                verticalAlign: "-0.125em",
                overflow: "visible",
                boxSizing: "content-box",
                marginBottom: "2px",
                marginTop: "0.25rem",
                marginLeft: "0.5rem",
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
              }}
            >
              <path
                d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"
                fill="rgb(0, 0, 0)"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                }}
              />
            </svg>
          </button>
     </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  margin: 0px;
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  tab-size: 4;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}

body {
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizelegibility;
  overflow-y: auto;
  min-height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  line-height: inherit;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}
`,
        }}
      />
    </>
  );
}
