import React from "react";
import cn from "classnames";
import {
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  withStyles,
} from "@material-ui/core";
import styles from "./ChartContainer.module.scss";

const Select = withStyles({
  root: {
    backgroundColor: "#f8f8f8",
    color: "#3a3a3a",
    fontSize: 16,
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    height: 32,
    lineHeight: "32px",
    padding: "0 16px",
  },
})(MuiSelect);

const MenuItem = withStyles({
  root: {
    fontFamily: "Poppins, sans-serif",
  },
})(MuiMenuItem);

function ChartContainer({
  children,
  className,
  contentClassName,
  currentOption,
  description,
  onOptionChange = () => {},
  options = [],
  optionsContainerClassName,
  title,
  titleContainerClassName,
  isDisabled,
  restrictHeight
}) {
  return (
    <div
      className={cn(
        styles.container,
        { [className]: className },
        { [styles.containerDisabled]: isDisabled }
      )}
      style={{
        height: restrictHeight ? "400px" : "",
      }}
    >
      <div
        className={cn(styles.titleContainer, {
          [titleContainerClassName]: titleContainerClassName,
        })}
      >
        <h3 className={styles.title}>{title}</h3>
        {!!options?.length && (
          <div className={optionsContainerClassName}>
            <Select
              onChange={(e) => {
                onOptionChange({ name: e.target.name, value: e.target.value });
              }}
              value={currentOption || ""}
              variant="outlined"
            >
              {options.map(({ name, value }) => (
                <MenuItem key={value} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
      </div>
      <div className={styles.description}>{description}</div>
      <div
        className={cn(styles.content, { [contentClassName]: contentClassName })}
      >
        {children}
      </div>
    </div>
  );
}

export default ChartContainer;
