import React, {useState, useEffect} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Button } from "reactstrap";
import { CloseOutlined } from "@material-ui/icons";
import styles from "./QuestionBankActions.module.scss";
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import CustomizedTables from "./modalTableView";
import { Tabs } from "components/tabs/tabsQP";

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  scrollableTabs: {
    maxHeight: '400px', 
    overflowX: 'auto',
  },
}));

const QuestionPaperViewModal = ({ open, onClose, Status, Name, setQpModal, grading, viewContent, ...props }) => {
  const [valueRadio, setValueRadio] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setValueRadio(activeTab)
  },[activeTab])
  // const handleChange = (event) => {
  //   const newValueRadio = parseInt(event.target.value)
  //   setValueRadio(newValueRadio);
  // };
  const classes = useStyles();
  return (
  <Dialog
    open={open}
    onClose={() => {setQpModal(false)}}
    fullWidth
    maxWidth="md"
    aria-labelledby="max-width-dialog-title" 
  >
    <DialogTitle classes={{ root: styles.header }}>
      {props.header}
      <CloseOutlined classes={{ root: styles.closeButton }} onClick={() => setQpModal(false)} />
    </DialogTitle>
    <DialogTitle classes={{ root: styles.subHeaderContent }}>
      <div className={styles.flexContent }>
        <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 5C0 2.23858 2.23858 0 5 0H70C72.7614 0 75 2.23858 75 5V70C75 72.7614 72.7614 75 70 75H5C2.23858 75 0 72.7614 0 70V5Z" fill="#FB8B24"/>
          <path d="M48.25 23H42C42 20.2422 39.7578 18 37 18C34.2422 18 32 20.2422 32 23H25.75C23.6797 23 22 24.6797 22 26.75V54.25C22 56.3203 23.6797 58 25.75 58H48.25C50.3203 58 52 56.3203 52 54.25V26.75C52 24.6797 50.3203 23 48.25 23ZM29.5 51.125C28.4609 51.125 27.625 50.2891 27.625 49.25C27.625 48.2109 28.4609 47.375 29.5 47.375C30.5391 47.375 31.375 48.2109 31.375 49.25C31.375 50.2891 30.5391 51.125 29.5 51.125ZM29.5 43.625C28.4609 43.625 27.625 42.7891 27.625 41.75C27.625 40.7109 28.4609 39.875 29.5 39.875C30.5391 39.875 31.375 40.7109 31.375 41.75C31.375 42.7891 30.5391 43.625 29.5 43.625ZM29.5 36.125C28.4609 36.125 27.625 35.2891 27.625 34.25C27.625 33.2109 28.4609 32.375 29.5 32.375C30.5391 32.375 31.375 33.2109 31.375 34.25C31.375 35.2891 30.5391 36.125 29.5 36.125ZM37 21.125C38.0391 21.125 38.875 21.9609 38.875 23C38.875 24.0391 38.0391 24.875 37 24.875C35.9609 24.875 35.125 24.0391 35.125 23C35.125 21.9609 35.9609 21.125 37 21.125ZM47 49.875C47 50.2187 46.7188 50.5 46.375 50.5H35.125C34.7812 50.5 34.5 50.2187 34.5 49.875V48.625C34.5 48.2812 34.7812 48 35.125 48H46.375C46.7188 48 47 48.2812 47 48.625V49.875ZM47 42.375C47 42.7187 46.7188 43 46.375 43H35.125C34.7812 43 34.5 42.7187 34.5 42.375V41.125C34.5 40.7812 34.7812 40.5 35.125 40.5H46.375C46.7188 40.5 47 40.7812 47 41.125V42.375ZM47 34.875C47 35.2187 46.7188 35.5 46.375 35.5H35.125C34.7812 35.5 34.5 35.2187 34.5 34.875V33.625C34.5 33.2812 34.7812 33 35.125 33H46.375C46.7188 33 47 33.2812 47 33.625V34.875Z" fill="white"/>
        </svg>
        <div>
          <h4 style={{color:'#000000', paddingLeft:'18px'}}>{props.header}</h4>
          <p className={styles.subContent}>{props?.subContent}</p>
        </div>
      </div>
      <div className={styles.flexContentValue} style={{paddingTop:'5px'}}>
        <h3 style={{fontSize:'16px'}}>Grading Process:<span style={{color:'#000000', paddingLeft:'5px'}}> {grading}</span></h3>
        <h3 style={{fontSize:'16px'}}>No of section:<span style={{color:'#000000'}}> {viewContent?.sectionList?.length || 0}</span></h3>
      </div>
    </DialogTitle>
    <Divider style={{background:'#E3F2FF'}}/>
    {/* <FormControl component="fieldset" className="pl-3">
      <RadioGroup row aria-label="position" name="position" defaultValue="top" value={valueRadio} onChange={handleChange}>
      {viewContent?.sectionList?.map((e, idx) => (
          <FormControlLabel value={idx} control={<Radio color="primary" />} label={`${e?.name} (${e?.questionList?.length} question)`} />
      ))}
      </RadioGroup>
    </FormControl> */}
    <div className={classes.scrollableTabs}>
      <Tabs
        activeTab={activeTab}
        contentClassName={styles.tabContent}
        onChange={setActiveTab}
        tabs={viewContent?.sectionList}
      />
    </div>  
    <DialogContent>
      <CustomizedTables
        sectionList={viewContent?.sectionList}
        tableValue={valueRadio}
      />
    </DialogContent>
  </Dialog>
)}

export default QuestionPaperViewModal;