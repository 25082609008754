import {
  MenuItem,
  Select,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { TSectionSelectorProps } from './section-selector.props';
import styles from './section-selector.module.scss';

export function SectionSelector(props: TSectionSelectorProps) {

  return props?.smallScreen
    ? (
      <Tooltip
        title={props?.currentSection.id}>
        <Typography
          className={styles.smallScreenSelector}
          noWrap
          onClick={() => {
            if (!props.disabled) {
              props?.openSectionDrawer?.();
            }
          }}>
          {props?.currentSection.id}
        </Typography>
      </Tooltip>
    )
    : (
      <Select
        className={styles.container}
        disabled={props.disabled}
        MenuProps={{
          PopoverClasses: {
            root: styles.menuBackdrop,
          },
          transitionDuration: 0,
        }}
        classes={{
          root: styles.selector,
        }}
        onChange={(_e, value: any) => {
          props.setCurrentSectionDetails?.(
            value?.props?.value,
            props.setCurrentSectionDetailsCB,
          );
        }}
        value={props?.currentSection.id}
        variant="outlined">
        {props?.sectionIds.map((sectionId) => (
          <MenuItem
            key={sectionId}
            value={sectionId}>
            <Typography
              noWrap>
              {sectionId}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    );
}
