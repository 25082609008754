import TabsNext from "components/TabsNext";
import "./Header.scss";

const Header = ({ tabValue, tabChange }) => {
  return (
    <div className="manage-prepare__header--main">
      <div>
        <h4 className="terv-form-header manage-prepare__header">
          Create a new course
        </h4>
        <p className="manage-prepare__header-sub">
          Use tabs to navigate from one field to another
        </p>
      </div>
      <TabsNext
        tabs={[
          "Course Information",
          "Course Content",
          "Course Overviews",
          "Course Settings",
          "Course Pricing",
        ]}
        tabWidth={200}
        activeTab={tabValue}
        changeTab={(newTab) => tabChange(newTab)}
        color="#1ba94c"
      />
    </div>
  );
};

export default Header;
