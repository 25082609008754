import { useContext } from "react";
import cn from "classnames";
import {
  AppBar as MuiAppBar,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Toolbar as MuiToolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Fullscreen, FullscreenExit } from "@material-ui/icons";
import { PracticeLabContext } from "../../context/practice-lab.context";
import Section from "../../components/section/section";
import styles from "./question.module.scss";

// const replaceHTMLTags = /(<([^>]+)>)/gi;

const AnswerAppBar = withStyles({
  root: {
    alignItems: "center",
    backgroundColor: "transparent",
    display: "flex",
    height: 40,
    justifyContent: "center",
    fontSize: 16,
  },
})(MuiAppBar);

const SubmissionBar = withStyles({
  root: {
    height: 56,
  },
})(MuiAppBar);

const SubmissionToolBar = withStyles({
  root: {
    alignItems: "center",
    display: "flex",
    fontSize: 16,
    height: "100%",
    justifyContent: "flex-end",
    minHeight: "auto",
  },
})(MuiToolbar);

function Separator({ isLightMode, margin, width }) {
  return (
    <hr
      style={{
        backgroundColor: isLightMode ? "#0001" : "#fff2",
        flexShrink: 0,
        height: 1,
        border: "none",
        margin,
        width,
      }}
    />
  );
}

function CommonHeader({ details, isLightMode }) {
  return (
    <>
      <Typography
        classes={{ root: styles.name }}
        dangerouslySetInnerHTML={{ __html: details?.name }}
      />
      <Typography
        classes={{
          root: styles.question,
          [styles.lightMode]: isLightMode,
        }}
        dangerouslySetInnerHTML={{ __html: details?.question }}
      />
      <div style={{ marginTop: 0 }}>
        {!!details?.media && (
          <>
            <Separator isLightMode={isLightMode} margin="20px 0" width="100%" />
            <img
              src={details.media}
              style={{
                border: `1px solid ${isLightMode ? "#0001" : "#fff2"}`,
                borderRadius: 4,
                maxWidth: "60%",
                minWidth: "10%",
              }}
            />
          </>
        )}
      </div>
    </>
  );
}

export default function QuestionSection({
  details,
  goToNextQuestion,
  questionId,
  sectionIsExpanded,
  selectedOptions,
  setIfSectionIsExpanded,
  setSelectedOption,
  setSelectedOptions,
  submitMCQAnswer,
  submittingMCQAnswer,
  workSpaceIsDisabled,
  ...props
}) {
  const { isLightMode, isMCQ, smallScreen, largeScreen } = useContext(PracticeLabContext);
  return (
    <Section
      noContentTags={isMCQ}
      questionId={questionId}
      rightSection={
        largeScreen &&
        (isMCQ ? !workSpaceIsDisabled : true) && (
          <Tooltip title={sectionIsExpanded ? "Shrink" : "Expand"}>
            <IconButton
              onClick={() => {
                setIfSectionIsExpanded?.((e) => !e);
              }}
              color="inherit"
              style={{ marginRight: -8 }}
              size="small"
            >
              {sectionIsExpanded ? <FullscreenExit /> : <Fullscreen />}
            </IconButton>
          </Tooltip>
        )
      }
      sectionId="practice-lab-question"
      title="Question"
      {...props}
    >
      {isMCQ ? (
        <div className={styles.sectionContent}>
          <div className={styles.questionContainer}>
            <CommonHeader isLightMode={isLightMode} details={details} />
          </div>
          <Separator isLightMode={isLightMode} margin="0" width="100%" />
          <div
            className={cn(styles.answerContainer, {
              [styles.light]: isLightMode,
            })}
          >
            <AnswerAppBar
              position="relative"
              style={{
                color: isLightMode ? "#000" : "#fff",
                ...(smallScreen && {
                  display: "flex",
                  padding: 20,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }),
              }}
            >
              <Typography>{"Answer"}</Typography>
              {smallScreen && (
                <Button
                  color="primary"
                  disabled={submittingMCQAnswer || !selectedOptions?.[0]}
                  onClick={submitMCQAnswer}
                  size="small"
                  variant="contained"
                >
                  {submittingMCQAnswer ? "Submitting..." : "Submit"}
                </Button>
              )}
            </AnswerAppBar>
            <Separator
              isLightMode={isLightMode}
              margin="0 20px"
              width="calc(100% - 40px)"
            />
            <div className={styles.answerContent}>
              <FormControl component="div" style={{ width: "100%" }}>
                {details?.multiSelect ? (
                  <FormGroup style={{ width: "100%" }}>
                    {details?.optionsList?.map?.((option, idx) => (
                      <FormControlLabel
                        key={idx}
                        classes={{
                          root: styles.inputControl,
                          label: styles.inputControlLabel,
                        }}
                        control={
                          <Checkbox
                            classes={{ root: styles.inputControlInput }}
                            color="primary"
                            checked={selectedOptions?.includes(option?.id)}
                            onChange={(_e, checked) => {
                              setSelectedOptions?.(
                                details?.id,
                                option?.id,
                                !checked
                              );
                            }}
                          />
                        }
                        label={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: option?.optionValue,
                            }}
                          />
                        }
                      />
                    ))}
                  </FormGroup>
                ) : (
                  <RadioGroup
                    value={selectedOptions?.[0]}
                    onChange={(e) => {
                      setSelectedOption(details?.id, e.target.value);
                    }}
                    style={{ width: "100%" }}
                  >
                    {details?.optionsList?.map?.((option, idx) => (
                      <FormControlLabel
                        key={idx}
                        classes={{
                          root: styles.inputControl,
                          label: styles.inputControlLabel,
                        }}
                        value={option?.id}
                        control={
                          <Radio
                            classes={{ root: styles.inputControlInput }}
                            color="primary"
                          />
                        }
                        label={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: option?.optionValue,
                            }}
                          />
                        }
                      />
                    ))}
                  </RadioGroup>
                )}
              </FormControl>
            </div>
          </div>
          {!smallScreen && (
            <>
              <Separator isLightMode={isLightMode} margin="0" width="100%" />
              <SubmissionBar position="relative">
                <SubmissionToolBar
                  style={{
                    color: "#fff",
                    ...(isLightMode && {
                      backgroundColor: "#fff",
                    }),
                  }}
                >
                  <Button
                    color="primary"
                    disabled={submittingMCQAnswer || !selectedOptions?.[0]}
                    onClick={submitMCQAnswer}
                    variant="contained"
                  >
                    {submittingMCQAnswer ? "Submitting..." : "Submit"}
                  </Button>
                </SubmissionToolBar>
              </SubmissionBar>
            </>
          )}
        </div>
      ) : (
        <>
          <CommonHeader details={details} />
          <br />
          {details?.inputFormat && (
            <>
              <h5 className={styles.heading}>Input Format:</h5>
              <div className={styles.sampleText}>{details.inputFormat}</div>
            </>
          )}
          <br />
          {details?.inputConstraints && (
            <>
              <h5 className={styles.heading}>Input Constraints:</h5>
              <div className={styles.sampleText}>{details.inputConstraints}</div>
            </>
          )}
          <br />
          {details?.outputFormat && (
            <>
              <h5 className={styles.heading}>Output Format:</h5>
              <div className={styles.sampleText}>{details.outputFormat}</div>
            </>
          )}
          <br />
          {details?.sampleInput && (
            <>
              <h5 className={styles.heading}>Sample Input:</h5>
              <div className={styles.sampleText}>{details.sampleInput}</div>
            </>
          )}
          <br />
          {details?.sampleOutput && (
            <>
              <h5 className={styles.heading}>Sample Output:</h5>
              <div className={styles.sampleText}>{details.sampleOutput}</div>
            </>
          )}
        </>
      )}
    </Section>
  );
}
