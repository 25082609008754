import { useState, useEffect } from "react";
import CustomListTableBatch from "./../CustomerManagement/CustomListTableBatch";
import apiHelper from "api/apiHelper";
import api from "api/baseConfig";
import { sessionPrivileges } from "privileges";

function CustomBatchList(props) {
  const userPrivileges = sessionPrivileges();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const activePage = props?.location?.state?.activePage;
  const itemsPerPage = props?.location?.state?.itemsPerPage;

  const getAllBatches = async (id) => {
    setLoading(true);
    const customerId = userPrivileges.isSuperAdmin
      ? id
      : sessionStorage.getItem("customer_id");
    try {
      const res = await apiHelper.axiosCall(
        `${api.baseURL}${api.customBatchMgmt.readAll}${customerId}`
      );
      setLoading(false);
      if (res) {
        setData(
          res.map((re) => ({
            id: re?.id,
            name: re?.batchName,
            dept: re?.department || "-",
            students: (re?.batchUserList || []).map((i) => i?.emailId),
            noOfStudents: re?.batchUserList?.length || 0,
            maleCount: (re?.batchUserList || []).filter((i) =>
              i?.gender ? i.gender.toLowerCase() === "male" : false
            ).length,
            femaleCount: (re?.batchUserList || []).filter((i) =>
              i?.gender ? i.gender.toLowerCase() === "female" : false
            ).length,
            filterBy: re?.addFilter || "",
            byAssessment: re?.selectAssessment || "",
            byPackage: re?.selectPackage || "",
            regFrom: re?.regNoFrom || "",
            regTo: re?.regNoFrom || "",
          }))
        );
      }
    } catch (error) {}
  };

  const deleteBatch = async (id, customer) => {
    try {
      const res = await apiHelper.axiosCallPost(
        `${api.baseURL}${api.customBatchMgmt.delete}${id}`,
        "DELETE",
        {}
      );
      if (res) {
        getAllBatches(customer);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (userPrivileges.isAdmin) {
      getAllBatches();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    {(userPrivileges.isSuperAdmin || userPrivileges.isAdmin) && (
    <div className="page-content">
      <CustomListTableBatch
        header="Custom Batches"
        createLink="/custombatchManagement"
        data={data}
        dataLength={data.length}
        tableHeaders={[
          { label: "Batch Name", val: "name" },
          { label: "Department", val: "dept" },
          { label: "No of students", val: "noOfStudents" },
          { label: "Male", val: "maleCount" },
          { label: "Female", val: "femaleCount" },
        ]}
        setCustomer={props?.location?.state?.customer}
        setUsers={(newCustomer) => getAllBatches(newCustomer)}
        searchPlaceholder="Search"
        deleteDatum={deleteBatch}
        showAdd
        showCustomers={userPrivileges.isSuperAdmin}
        passEditFromRow
        editData={[
          "name",
          "students",
          "id",
          "filterBy",
          "byAssessment",
          "byPackage",
          "dept",
          "regFrom",
          "regTo",
        ]}
        activePage={activePage}
        itemsPerPage={itemsPerPage}
        loading={loading}
        isCustomBatch={true}
      />
    </div>
    )}
    </>
  );
}

export default CustomBatchList;
