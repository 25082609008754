import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import "./composeMail.scss";
import styles from "./ComposeMail1.module.scss";
import { RequiredIndicator } from "../../utils/RequiredIndicator";
import { Link } from "react-router-dom";
import Select from "react-select";
import api from "../../../api/baseConfig";
import apiHelper from "../../../api/apiHelper";
import { GetObjectFromString } from "../../utils/GetObjectFromString";
import { ReactSelectTervStyle } from "../../utils/ReactSelectTervStyle";
import StyledNumberInput from "./../../../components/StyledNumberInput";
import CustomSelect from "./../../../components/CustomSelect";
import { TervButtonFilled } from "./../../../components/TervButtons";
import { GetObjectArray } from "../../utils/GetObjectArray";
import { ErrorBlock } from "./../../../components/ErrorBlock";
import { StudentFilter } from "../../utils/StudentFilter";
import { sessionPrivileges } from "privileges";

const ComposeMailDumb = ({
  checkDuplicate,
  composeMail,
  updateBatch,
  adminStudents,
  students,
  departments,
  customerStudents,
  customer,
  editData,
  activePage,
  itemsPerPage,
  allCustomers,
  getStudentsByCustomer,
  studentFilter,
  allFilterTypes,
  getCustomerBatches,
  commType,
  setCustomerName,
  setCustomer,
  setSelectedStudents,
  viewOnly,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    control,
    reset,
    setValue,
  } = useForm({ mode: "onBlur" });

  const Priv = sessionPrivileges().userprivileges || [];

  const getStudentsByBatch = async (batch) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.customerManagement.getStudentsByBatch}${batch}/${
          Priv.includes("MANAGE_CUSTOMER")
            ? customer
            : sessionStorage.getItem("customer_id")
        }`
      );
      if (data && data[0].batchUserList && data[0].batchUserList.length > 0) {
        const userIdValues = data[0].batchUserList.reduce((acc, item) => {
          if (item?.firstName && item?.emailId) {
            acc.push(item?.emailId);
          }
          return acc;
        }, []);
        setValue("department", data[0].department);
        setValue("userIdList", userIdValues);
      }
    } catch (err) {}
  };

  const setParsedStudentData = (filterType, STUDENTS) => {
    if (filterType === "all") {
      setSelectedStudents(
        students.reduce((acc, val) => {
          acc.push(val.value);
          return acc;
        }, [])
      );
    } else if (filterType === "except") {
      setSelectedStudents(
        watch("userIdList") && watch("userIdList").length > 0
          ? STUDENTS.reduce((acc, val) => {
              if (!watch("userIdList").find((stu) => stu === val.value)) {
                acc.push(val.value);
              }
              return acc;
            }, [])
          : []
      );
    }
  };

  const [filteredStudents, setFilteredStudents] = useState([]);

  useEffect(() => {
    setValue("type", "mail");
  });

  useEffect(() => {
    if (customer) {
      reset({ customerId: customer });
    }
  }, [reset]);

  useEffect(() => {
    if (editData?.edit) {
      reset({
        type: editData?.row.mailDetails?.type,
        customerId: editData?.customer,
        userIdList: editData?.row.mailDetails?.filterType,
      });
    }
  }, [reset]);

  useEffect(() => {
    if (
      watch("filterType") &&
      watch("filterType") !== "custom_batch" &&
      watch("filterType") !== "all" &&
      watch("filterType") !== "only" &&
      watch("filterType") !== "except"
    ) {
      getStudentsByBatch(watch("filterType"));
    }
  }, [watch("filterType")]);

  useEffect(() => {
    if (watch("filterType") === "all" || watch("filterType") === "except") {
      setParsedStudentData(watch("filterType"), students);
    }
  }, [watch("filterType"), watch("userIdList")]);

  const getRightStudents = () => students;

  const regNoLength = () =>
    getRightStudents()[0] && getRightStudents()[0]["registrationNo"]
      ? getRightStudents()[0]["registrationNo"].length
      : "";

  const getFilteredStudents = (students, from, to) =>
    students.filter(
      (stu) => +stu.registrationNo >= from && +stu.registrationNo <= to
    );

  useEffect(() => {
    if (watch("regNoFrom") && watch("regNoTo")) {
      const filtered = getFilteredStudents(
        getRightStudents(),
        watch("regNoFrom"),
        watch("regNoTo")
      );
      setFilteredStudents(filtered);
    }
    if (watch("regNoFrom") === 0 && watch("regNoTo") === 0) {
      setFilteredStudents([]);
    }
  }, [watch("regNoFrom"), watch("regNoTo")]);

  return (
    <div className="page-content">
      <form onSubmit={handleSubmit(composeMail)} className="customBatch__form">
        <h4 className="terv-form-header">Compose a mail</h4>
        <div className="terv-row terv-row-lg">
          <div
            className={styles.communicationTypeContainer}
            style={{
              height: errors.type ? "80px" : "50px",
            }}
          >
            <label className="terv-label" htmlFor="communication_type">
              {RequiredIndicator("Communication type ")}
            </label>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Controller
                name="type"
                control={control}
                rules={{ required: "Please select a communication type" }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    inputId="communication_type"
                    options={commType}
                    onChange={(val) => {
                      onChange(val.value);
                    }}
                    // value={GetObjectFromString(commType, value)}
                    styles={ReactSelectTervStyle("smd")}
                    isDisabled={viewOnly}
                    defaultValue={commType[0]}
                  />
                )}
              />
              {errors.type && (
                <div style={{ color: "red", maxWidth: 600 }}>
                  {errors.type.message}
                </div>
              )}
            </div>
          </div>
          {Priv.includes("MANAGE_CUSTOMER") && (
            <div>
              <label className="terv-label" htmlFor="select_customer">
                Select customer
              </label>
              <Controller
                name="customerId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    inputId="select_customer"
                    options={allCustomers}
                    onChange={(val) => {
                      onChange(val.value);
                      setCustomer(val.value);
                      getCustomerBatches(val.value);
                      getStudentsByCustomer(val.value);
                      setCustomerName(val.label);
                    }}
                    value={GetObjectFromString(allCustomers, value)}
                    styles={ReactSelectTervStyle("smd")}
                    isDisabled={viewOnly}
                  />
                )}
              />
            </div>
          )}
          <div>
            <label className="terv-label" htmlFor="add_students">
              {RequiredIndicator("Add students ")}
            </label>
            <Controller
              name="filterType"
              control={control}
              rules={{ required: "Please select a students filter" }}
              render={({ field: { onChange, value } }) => (
                <Select
                  inputId="add_students"
                  options={allFilterTypes}
                  onChange={(val) => {
                    onChange(val.value);
                  }}
                  value={GetObjectFromString(allFilterTypes, value)}
                  styles={ReactSelectTervStyle("smd")}
                  // isDisabled={viewOnly}
                />
              )}
            />
          </div>
          {errors.filterType && (
            <div
              style={{
                color: "red",
                maxWidth: 600,
                marginLeft: Priv.includes("MANAGE_CUSTOMER") ? "22rem" : "0rem",
              }}
            >
              {errors.filterType.message}
            </div>
          )}
          {watch("filterType") === "custom_batch" && (
            <div>
              <label className="terv-label" htmlFor="custom_batch_name">
                {RequiredIndicator("Custom batch name ")}
              </label>
              <input
                id="custom_batch_name"
                {...register("batchName", {
                  required: "Please enter a valid batch name",
                  validate: checkDuplicate,
                })}
                className="terv-form terv-form-smd"
                //   style={{ pointerEvents: editData?.type === "viewOnly" ? "none" : "" }}
              />
              {errors.batchName && (
                <div style={{ color: "red", maxWidth: 200 }}>
                  {errors.batchName.message}
                </div>
              )}
            </div>
          )}
          {watch("filterType") &&
            watch("filterType") !== "all" &&
            watch("filterType") !== "only" &&
            watch("filterType") !== "except" && (
              <div
              // style={{ pointerEvents: editData?.type === "viewOnly" ? "none" : "" }}
              >
                <label className="terv-label" htmlFor="batch_filter_by_dept">
                  Filter by department
                </label>
                <Controller
                  name="department"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      inputId="batch_filter_by_dept"
                      options={departments}
                      onChange={(val) => {
                        onChange(val ? val.value : "");
                      }}
                      value={GetObjectFromString(departments, value)}
                      styles={ReactSelectTervStyle("smd")}
                      isClearable
                      isDisabled={viewOnly}
                    />
                  )}
                />
              </div>
            )}
        </div>
        {watch("filterType") === "custom_batch" && (
          <div
            className="terv-row terv-row-lg"
            //   style={{ pointerEvents: editData?.type === "viewOnly" ? "none" : "" }}
          >
            <div>
              <label className="terv-label" htmlFor="filterByRegNo">
                Filter by Reg No
              </label>
              <div className="customBatch_RegFilter">
                <Controller
                  name="regNoFrom"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <StyledNumberInput
                      onChange={onChange}
                      value={value || 0}
                      inputId="filterByRegNo"
                      className="terv-form-with-icon-sm"
                    />
                  )}
                />
                <Controller
                  name="regNoTo"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <StyledNumberInput
                      onChange={onChange}
                      value={value || 0}
                      inputId="filterByRegNo"
                      className="terv-form-with-icon-sm"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        )}
        {watch("filterType") && watch("filterType") !== "all" && (
          <div className="terv-row terv-row-lg">
            <div>
              <label className="terv-label" htmlFor="all_students">
                {RequiredIndicator("Add students ")}
              </label>
              <Controller
                name="userIdList"
                control={control}
                rules={{ required: "Please select a student" }}
                render={({ field: { onChange, value } }) => (
                  <CustomSelect
                    inputId="all_students"
                    options={students}
                    isMulti
                    onChange={(val) =>
                      onChange(val ? val.map((it) => it.value) : [])
                    }
                    value={GetObjectArray(students, value)}
                    styles={ReactSelectTervStyle("xlg", true)}
                    // viewOnly={editData?.type === "viewOnly" ? true : false}
                    // filterOption={(val) =>
                    //   StudentFilter(
                    //     val,
                    //     +watch("regNoFrom"),
                    //     +watch("regNoTo"),
                    //     watch("department"),
                    //     students[0]?.["registrationNo"]?.length || 0
                    //   )
                    // }
                  />
                )}
              />
              {errors.userIdList && (
                <div style={{ color: "red", maxWidth: 200 }}>
                  {errors.userIdList.message}
                </div>
              )}
            </div>
          </div>
        )}
        <hr className="terv-divider" />
        <TervButtonFilled
          text={"Compose a mail"}
          type="submit"
          disabled={isSubmitting}
        />
      </form>
      <div className="custom__batch__back">
        <Link
          to={{
            pathname: "/communication",
            state: {
              customer: customer,
            },
            // state: {
            //   customer: customer,
            //   activePage: activePage,
            //   itemsPerPage: itemsPerPage,
            // },
          }}
          className="terv-back"
        >
          <i className="mdi mdi-arrow-left mr-1" /> Back to Mail List{" "}
        </Link>
      </div>
    </div>
  );
};

export default ComposeMailDumb;
