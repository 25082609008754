import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import Apihelper from "../../api/apiHelper";
import api from "../../api/baseConfig";

import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Dialog, DialogContent,  } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button } from "reactstrap";
import Select from "react-select";
import styles from "./TestcaseReport.module.scss";

function TestcaseReport({ Customers }) {
  const [customerId, setCustomerId] = useState("");

  const [assessments, setAssessments] = useState([]);
  const [assessmentId, setAssessmentId] = useState("");

  const [packages, setPackages] = useState([]);
  const [packageId, setPackageId] = useState("");
  
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "none",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  const classes = useStyles();

  const customersList = useMemo(
    () =>
      Customers?.map(({ name = "", id }) => ({
        label: name,
        value: id,
      })).sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      ),
    [Customers]
  );

  const assessmentsList = assessments.map(({ name, id }) => ({
    label: name,
    value: id,
  }));
  const packagesList = packages.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  useEffect(() => {
    if (customerId) {
      fetchAssessmentsByCustomer(customerId.value);
      fetchPackagesByCustomer(customerId.value);
    }
  }, [customerId]);
  // useEffect(() => {
  //   if (customerId) {
  //     fetchPackagesByCustomer(customerId.value);
  //   }
  // }, [customerId]);

  const fetchAssessmentsByCustomer = async (customerId) => {
    setLoading(true);
    try {
      const response = await Apihelper.axiosCall(
        `${api.baseURL}${api.assessmentController.getAssessmentNameListByCustomer}${customerId}`,
        "get"
      );
      setAssessments(response || []);
    } catch (error) {
      console.error("Error fetching assessments:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchPackagesByCustomer = async (customerId) => {
    setLoading(true);
    try {
      const response = await Apihelper.axiosCall(
        `${api.baseURL}${api.lab.getCustomerLabs}${customerId}`,
        "get"
      );
      setPackages(response || []);
    } catch (error) {
      console.error("Error fetching assessments:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleOnclickAssessment = async (assessmentId, customerId) => {
      setDownloadLoading(true)
        await Apihelper.axiosCall(
          `${api.baseURL}${api.assessmentController.getTestcaseReport}${assessmentId}/${customerId}`, "get"
        ).then((response) => {
          if (response?.resourceURL) {
            window.location = response?.resourceURL;
          }
        }).catch((e)=> {
          console.log(e.error);
          //toast
        }).finally(()=>{
          setDownloadLoading(false);
        })
  }
  const handleOnclickPackage = async (assessmentId, customerId) => {
      setDownloadLoading(true)
        await Apihelper.axiosCall(
          `${api.baseURL}${api.lab.getTestcaseReport}${packageId.value}/${customerId}`, "get"
        ).then((response) => {
          if (response?.resourceURL) {
            window.location = response?.resourceURL;
          }
        }).catch((e)=> {
          console.log(e.error);
          //toast
        }).finally(()=>{
          setDownloadLoading(false);
        })
      console.log("Package Report.....", packageId.value);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <div className="page-content">
      {
      downloadLoading ? (
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          onClose={handleClose}
          open
          closeAfterTransition
          BackdropComponent={Backdrop}
        >
          <DialogContent className={classes.paper}>
            <img
              src="https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/export.gif"
              width="150px"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <DialogContentText id="alert-dialog-description">
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Please Hold On, While we are preparing your testcase report
              </p>
              <p style={{ fontSize: "16px", textAlign: "center" }}>
                Report will be downloaded in a while .
              </p>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : ""
      }
      <Paper elevation={2} className={styles.headerBox}>
        <h3>Assessment Testcase Report</h3>
        <div className={styles.selectWrapper}>
          <Select
            className={styles.selectBox}
            placeholder="Choose a Customer"
            value={customerId}
            onChange={(e) => { setCustomerId(e); setAssessmentId(""); setAssessments([])}}
            options={customersList}
            maxMenuHeight={180}
            isLoading={!customersList?.length}
          />
          <Select
            className={styles.selectBox}
            placeholder="Choose an Assessment"
            value={assessmentId}
            onChange={(e) => setAssessmentId(e)}
            options={assessmentsList}
            isDisabled={!customerId}
            isLoading={loading}
          />
          <Button
            color="primary"
            style={{
              boxShadow:
                "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
              borderRadius: 5,
              fontSize: 13,
              fontWeight: 500,
            }}
            disabled={!assessmentId}
            onClick={ (e)=> handleOnclickAssessment(assessmentId.value, customerId.value) }
          >
            <i className="fas fa-download" />
            &nbsp; Download
          </Button>
        </div>
      </Paper>
      <Paper elevation={2} className={styles.headerBox}>
        <h3>Practice Package Testcase Report</h3>
        <div className={styles.selectWrapper}>
          <Select
            className={styles.selectBox}
            placeholder="Choose a Customer"
            value={customerId}
            onChange={(e) => { setCustomerId(e); setPackageId(""); setPackages([])}}
            options={customersList}
            maxMenuHeight={180}
            isLoading={!customersList?.length}
          />
          <Select
            className={styles.selectBox}
            placeholder="Choose an Package"
            value={packageId}
            onChange={(e) => setPackageId(e)}
            options={packagesList}
            isDisabled={!customerId}
            isLoading={loading}
          />
          <Button
            color="primary"
            style={{
              boxShadow:
                "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
              borderRadius: 5,
              fontSize: 13,
              fontWeight: 500,
            }}
            disabled={!packageId}
            onClick={ (e)=> handleOnclickPackage(packageId.value, customerId.value) }
          >
            <i className="fas fa-download" />
            &nbsp; Download
          </Button>
        </div>
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Customers: state.StudentAndCustomerReducer.Customers,
});

export default connect(mapStateToProps)(TestcaseReport);
