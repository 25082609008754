import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import GridItem from "../../../components/GridItem/GridItem";
import DataTableContainer from "../../../components/DataTableContainer/DataTableContainer";
import { Tabs } from "../../../../../components/tabs/tabs";
import Calendar from "react-calendar";
import { TabContent, TabPane } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import "react-calendar/dist/Calendar.css";
import { useHistory } from "react-router-dom";
import moment from "moment";
import cn from "classnames";
import api from "../../../../../api/baseConfig";
import Apihelper from "../../../../../api/apiHelper";
import styles from "./eventCalender.module.scss";

const useStyles = makeStyles((theme) => ({
  calendarNavigator: {
    marginLeft: "1rem",
    width: "300px",
    border: "0px",
    // "& .react-calendar__navigation": {
    //   display: "none",
    // },
    "& .react-calendar__navigation__arrow": {
      color: "#2170D8",
      fontWeight:"600",
    },
    "& .react-calendar__navigation__label": {
      color: "#2170D8",
      fontSize: "13px",
      fontWeight:"600",
    },
    "& .react-calendar__viewContainer": {
      width: "300px",
    },
    "& .react-calendar__tile--now": {
      background: "transparent",
      border: "1px solid #2170D8",
      color: "#000",
      "&:hover": {
        background: "#2170D8",
        border: "1px solid #2170D8",
      },
    },
    "& .react-calendar__month-view__days__day--neighboringMonth": {
      pointerEvents: "none",
    },
  },
  selectList: {
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#D8D6DB",
      borderRadius: "10px",
      margin: "0 10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
    },
  },
}));

export const EventCalender = withRouter(
  ({ showReal, isMobile, currentCustomer, userPrivileges, ...props }) => {
    const classes = useStyles();

    const ITEM_HEIGHT = 48;

    const dotSVG = (
      <svg
        width="6"
        height="6"
        viewBox="0 0 6 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="3" cy="3" r="3" fill="#089A79" />
      </svg>
    );

    const chevronDown = (
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.75 0.75L5 5.25L9.25 0.75"
          stroke="#2170D8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );

    const chevronLeft = (
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.29303 0.293031L0.586032 6.00003L6.29303 11.707L7.70703 10.293L3.41403 6.00003L7.70703 1.70703L6.29303 0.293031Z"
          fill="#2170D8"
        />
      </svg>
    );

    const chevronRight = (
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.70697 11.707L7.41397 5.99997L1.70697 0.292969L0.292969 1.70697L4.58597 5.99997L0.292969 10.293L1.70697 11.707Z"
          fill="#2170D8"
        />
      </svg>
    );

    const vector = (
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.192 0.344238L5.94897 4.58624L1.70697 0.344238L0.292969 1.75824L4.53497 6.00024L0.292969 10.2422L1.70697 11.6562L5.94897 7.41424L10.192 11.6562L11.606 10.2422L7.36397 6.00024L11.606 1.75824L10.192 0.344238Z"
          fill="#63606C"
        />
      </svg>
    );

    const vector1 = (
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 20H16C17.103 20 18 19.103 18 18V4C18 2.897 17.103 2 16 2H14V0H12V2H6V0H4V2H2C0.897 2 0 2.897 0 4V18C0 19.103 0.897 20 2 20ZM13 14H5V12H13V14ZM2 5H16V7H2V5Z"
          fill="#96939F"
        />
      </svg>
    );

    const rawMonths = [
      { label: "January", value: 1 },
      { label: "February", value: 2 },
      { label: "March", value: 3 },
      { label: "April", value: 4 },
      { label: "May", value: 5 },
      { label: "June", value: 6 },
      { label: "July", value: 7 },
      { label: "August", value: 8 },
      { label: "September", value: 9 },
      { label: "October", value: 10 },
      { label: "November", value: 11 },
      { label: "December", value: 12 },
    ];
    const history = useHistory();

    const [monthMenu, setMonthMenu] = useState("");
    const [monthNum, setMonthNumber] = useState();
    const [yearMenu, setYearMenu] = useState("");
    const [activeTab, setActiveTab] = useState("liveClass");
    const [currentDate, setCurrentDate] = useState();
    const [convertedDate, setConvertedDate] = useState("");
    const [allowDefault, setAllowDefault] = useState(false);
    const [tabMenuAnchor, setTabMenuAnchor] = useState(null);
    const [tabYearMenuAnchor, setTabYearMenuAnchor] = useState(null);
    const [viewEvents, setViewEvents] = useState(false);
    const [resData, setResData] = useState();
    const [value, onChange] = useState(new Date());
    const open = Boolean(tabMenuAnchor);

    const setMonthYearName = () => {
      if (!allowDefault) {
        const d = new Date();
        let month = d.getMonth();
        setMonthNumber(month);
        for (let i = 0; i < rawMonths.length; i++) {
          if (rawMonths[i].value === month + 1) {
            setMonthMenu(rawMonths[i].label);
          }
        }
        let year = d.getFullYear();
        setYearMenu(year);
      }
    };

    const getEvents = async (str) => {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      let cDate = [date.getFullYear(), mnth, day].join("-");
      setCurrentDate(cDate);
      const checkTime = moment(cDate).format("LL");
      setConvertedDate(checkTime);
      let userId = sessionStorage.getItem("user_id"); //sessionStorage.getItem("applicable_authors")
      await Apihelper.getCoursesDetails(
        `${api.baseURL}${api.dashboard.getEvents}${userId}?customerId=${
          currentCustomer ? currentCustomer : ""
        }&dateString=${cDate}`,
        "get"
      )
        .then((datum) => {
          setResData(datum);
        })
        .catch((e) => {});
    };

    const incrementDateByOne = async () => {
      var tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      var date = new Date(tomorrow),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      let cDate = [date.getFullYear(), mnth, day].join("-");
      setCurrentDate(cDate);
      const checkTime = moment(cDate).format("LL");
      setConvertedDate(checkTime);
      let userId = sessionStorage.getItem("user_id"); //sessionStorage.getItem("applicable_authors")
      await Apihelper.getCoursesDetails(
        `${api.baseURL}${api.dashboard.getEvents}${userId}?customerId=${
          currentCustomer ? currentCustomer : ""
        }&dateString=${cDate}`,
        "get"
      )
        .then((datum) => {
          setResData(datum);
        })
        .catch((e) => {});
    };

    const decrementDateByOne = async () => {
      var tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() - 1);
      var date = new Date(tomorrow),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      let cDate = [date.getFullYear(), mnth, day].join("-");
      setCurrentDate(cDate);
      const checkTime = moment(cDate).format("LL");
      setConvertedDate(checkTime);
      let userId = sessionStorage.getItem("user_id"); //sessionStorage.getItem("applicable_authors")
      await Apihelper.getCoursesDetails(
        `${api.baseURL}${api.dashboard.getEvents}${userId}?customerId=${
          currentCustomer ? currentCustomer : ""
        }&dateString=${cDate}`,
        "get"
      )
        .then((datum) => {
          setResData(datum);
        })
        .catch((e) => {});
    };

    const setDescription = (element) => {
      var date = new Date(),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      let cDate = [date.getFullYear(), mnth, day].join("-");
      const checkTime = moment(cDate).format("LL");
      const tempStartDate = moment(element?.startDate).format("LL");
      const tempEndDate = moment(element?.endDate).format("LL");
      const tStartDate = new Date(tempStartDate);
      const tEndDate = new Date(tempEndDate);
      const cTime = new Date(checkTime);
      if (
        tStartDate.getTime() === cTime.getTime() &&
        tEndDate.getTime() !== cTime.getTime()
      ) {
        return `Starting ${convertedDate}`;
      }
      if (
        tStartDate.getTime() === cTime.getTime() &&
        tEndDate.getTime() === cTime.getTime()
      ) {
        return `Ending ${convertedDate}`;
      }
      if (
        tStartDate.getTime() !== cTime.getTime() &&
        tEndDate.getTime() === cTime.getTime()
      ) {
        return `Ending ${convertedDate}`;
      }
      if (
        tStartDate.getTime() !== cTime.getTime() &&
        tEndDate.getTime() !== cTime.getTime() &&
        tEndDate.getTime() > cTime.getTime()
      ) {
        return `In Progress ${convertedDate}`;
      }
      // return "desc"
    };

    useEffect(() => {
      setMonthYearName();
    });

    useEffect(() => {
      setViewEvents(false);
    }, [currentCustomer]);

    useEffect(() => {
      if (value) {
        getEvents(value);
      }
    }, [value]);

    const yearOption = [
      { label: "2023", value: "2023" },
      { label: "2022", value: "2022" },
      { label: "2021", value: "2021" },
      { label: "2020", value: "2020" },
      { label: "2019", value: "2019" },
      { label: "2018", value: "2018" },
    ];

    return (
      <GridItem
        gridPosition={[
          isMobile ? [1, -1] : [7, 13],
          showReal ? (isMobile ? [4, 5] : [3, 4]) : isMobile ? [5, 6] : [3, 4],
        ]}
      >
        <DataTableContainer>
          {!viewEvents ? (
            <>
              <p className={styles.eventsTitle}>
                To view any events click one day on calendar
              </p>
              <div className={styles.eventsCalendarContainer} style={{justifyContent:"center"}}>
              {/* "Calendar default navigation buttons are working fine, So don't need customized buttons" */}
                {/* <div
                  style={{
                    display: "flex",
                    // height: "2rem",
                    width: "12rem",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                    marginLeft: "5rem",
                    gap: "0.5rem",
                  }}
                >
                  <Button
                    className={styles.monthSelector}
                    id="month-button"
                    aria-controls="month-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={() => {
                      setTabMenuAnchor(true);
                    }}
                  >
                    <span>{`${monthMenu}`}</span>
                    <span style={{ marginLeft: "3px" }}>{chevronDown}</span>
                  </Button>
                  <Menu
                    anchorEl={tabMenuAnchor}
                    id="month-menu"
                    keepMounted
                    transitionDuration={0}
                    onClose={() => {
                      setTabMenuAnchor(null);
                    }}
                    open={tabMenuAnchor}
                    className={styles.monthMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                    // style={{
                    //     backgroundColor: "yellow"
                    // }}
                  >
                    {rawMonths.map?.((val, i) => (
                      <MenuItem
                        key={`rawMonth${i}${val.value}`}
                        onClick={() => {
                          setAllowDefault(true);
                          setMonthMenu(val.label);
                          setMonthNumber(val.value);
                          setTabMenuAnchor(null);
                        }}
                        // selected={props.codeSolution.languageId === languageItem.id}
                        value={val.label}
                      >
                        <Typography style={{ textTransform: "capitalize" }}>
                          {val.label}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                  <Button
                    className={styles.monthSelector}
                    id="month-button"
                    aria-controls="year-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={() => {
                      setTabYearMenuAnchor(true);
                    }}
                  >
                    <span>{`${yearMenu}`}</span>
                    <span style={{ marginLeft: "3px" }}>{chevronDown}</span>
                  </Button>
                  <Menu
                    anchorEl={tabYearMenuAnchor}
                    id="year-menu"
                    keepMounted
                    transitionDuration={0}
                    onClose={() => {
                      setTabYearMenuAnchor(null);
                    }}
                    open={tabYearMenuAnchor}
                    className={styles.yearMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                    // style={{
                    //     backgroundColor: "yellow"
                    // }}
                  >
                    {yearOption?.map?.((val) => (
                      <MenuItem
                        key={`yearOption${val.value}`}
                        onClick={() => {
                          setAllowDefault(true);
                          setYearMenu(val.value);
                          setTabYearMenuAnchor(null);
                        }}
                        // selected={props.codeSolution.languageId === languageItem.id}
                        value={val.value}
                      >
                        <Typography style={{ textTransform: "capitalize" }}>
                          {val.label}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </div> */}
                <Calendar
                  onChange={(e) => {
                    setViewEvents(true);
                    onChange(e);
                  }}
                  // activeStartDate={new Date(yearMenu, allowDefault ?
                  //     monthNum - 1 : monthNum, 1)}
                  value={value}
                  className={`${classes.calendarNavigator} ${styles.calendar}`}
                />
              </div>
            </>
          ) : (
            <>
              <div className={styles.dateNavigation}>
                <div
                  className={styles.chevronButton}
                  onClick={() => {
                    decrementDateByOne();
                  }}
                >
                  {chevronLeft}
                </div>
                <div className={styles.dataInfoContainer}>
                  <p className={styles.dataInfo}>{convertedDate}</p>
                </div>
                <div
                  className={styles.chevronButton}
                  onClick={() => {
                    incrementDateByOne();
                  }}
                >
                  {chevronRight}
                </div>
                <p
                  className={styles.vectorButton}
                  onClick={() => {
                    setViewEvents(false);
                  }}
                >
                  {vector}
                </p>
              </div>
              <div>
                <Tabs
                  onChange={(newTab) => {
                    setActiveTab(newTab);
                    // this.setState({ tab: newTab, activePages: { allList: 1 } });
                  }}
                  activeTab={activeTab}
                  hideBadge={true}
                  tabs={[
                    // {
                    //   name: "CloudLab",
                    //   id: "liveClass",
                    // },
                    userPrivileges.showPrepare
                      ? {
                          name: "Prepare",
                          id: "prepare",
                        }
                      : {},
                    userPrivileges.showPracticePackages
                      ? {
                          name: "Practice",
                          id: "practicePackage",
                        }
                      : {},
                    userPrivileges.showAssessments
                      ? {
                          name: "Assessment",
                          id: "assessment",
                        }
                      : {},
                  ]}
                >
                  <TabContent
                    activeTab={activeTab}
                    style={{
                      padding: 10,
                      backgroundColor: "rgba(255, 255, 255, 1)",
                    }}
                  >
                    <TabPane tabId={activeTab}>
                      {activeTab === "liveClass" && (
                        <div className={styles.boxContainer}>
                          {resData?.calendarLive?.length > 0 ? (
                            <>
                              {resData?.calendarLive?.map((element, index) => (
                                <div
                                  key={`calendarLive${index}`}
                                  className={cn(styles.box, {
                                    [styles.blue]: "blue",
                                  })}
                                >
                                  <div className={styles.titleContainer}>
                                    <div style={{ marginTop: "2px" }}>
                                      <h2 className={styles.title}>
                                        {element.name}
                                      </h2>
                                      <h2 className={styles.description}>
                                        {setDescription(element)}
                                      </h2>
                                    </div>
                                    <div className={styles.viewButton}>
                                      <p
                                        onClick={() => {
                                          history.push({
                                            pathname: "/cloudLab",
                                            state: {
                                              customer: currentCustomer,
                                              currentDate: convertedDate,
                                            },
                                          });
                                        }}
                                      >
                                        View
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <div className={styles.noEventsContainer}>
                              <div className={styles.noEventsLogo}>
                                {vector1}
                              </div>
                              <p className={styles.noEventsMessage}>
                                No class available
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                      {activeTab === "prepare" && (
                        <div className={styles.boxContainer}>
                          {resData?.calendarPrepare?.length > 0 ? (
                            <>
                              {resData?.calendarPrepare?.map(
                                (element, index) => (
                                  <div
                                    key={`calendarPrepare${index}`}
                                    className={cn(styles.box, {
                                      [styles.green]: "green",
                                    })}
                                  >
                                    <div className={styles.titleContainer}>
                                      <div style={{ marginTop: "2px" }}>
                                        <h2 className={styles.title}>
                                          {element.name}
                                        </h2>
                                        <h2 className={styles.description}>
                                          {setDescription(element)}
                                        </h2>
                                      </div>
                                      <div className={styles.viewButton}>
                                        <p
                                          onClick={() => {
                                            history.push({
                                              pathname: `/manage-prepare/${element?.id}`,
                                            });
                                          }}
                                        >
                                          View
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : (
                            <div className={styles.noEventsContainer}>
                              <div className={styles.noEventsLogo}>
                                {vector1}
                              </div>
                              <p className={styles.noEventsMessage}>
                                No package available
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                      {activeTab === "practicePackage" && (
                        <div className={styles.boxContainer}>
                          {resData?.calendarPractice?.length > 0 ? (
                            <>
                              {resData?.calendarPractice?.map(
                                (element, index) => (
                                  <div
                                    key={`calendarPractice${index}`}
                                    className={cn(styles.box, {
                                      [styles.ink]: "ink",
                                    })}
                                  >
                                    <div className={styles.titleContainer}>
                                      <div style={{ marginTop: "2px" }}>
                                        <h2 className={styles.title}>
                                          {element.name}
                                        </h2>
                                        <h2 className={styles.description}>
                                          {setDescription(element)}
                                        </h2>
                                      </div>
                                      <div className={styles.viewButton}>
                                        <p
                                          onClick={() => {
                                            history.push({
                                              pathname: "/manage-lab",
                                              state: {
                                                fromEdittrue: true,
                                                id: element.id,
                                              },
                                            });
                                          }}
                                        >
                                          View
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : (
                            <div className={styles.noEventsContainer}>
                              <div className={styles.noEventsLogo}>
                                {vector1}
                              </div>
                              <p className={styles.noEventsMessage}>
                                No package available
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                      {activeTab === "assessment" && (
                        <div
                          className={`${styles.boxContainer} ${classes.selectList}`}
                        >
                          {resData?.calendarAssessment?.length > 0 ? (
                            <>
                              {resData?.calendarAssessment?.map(
                                (element, index) => (
                                  <div
                                    key={`calendarAssessment${index}`}
                                    className={cn(styles.box, {
                                      [styles.red]: "red",
                                    })}
                                  >
                                    <div className={styles.titleContainer}>
                                      <div style={{ marginTop: "2px" }}>
                                        <h2 className={styles.title}>
                                          {element.name}
                                        </h2>
                                        <h2 className={styles.description}>
                                          {setDescription(element)}
                                        </h2>
                                      </div>
                                      <div className={styles.viewButton}>
                                        <p
                                          onClick={() => {
                                            history.push({
                                              pathname: `/edit-assessment/${element.id}`,
                                              state: {
                                                fromEdittrue: true,
                                              },
                                            });
                                          }}
                                        >
                                          View
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : (
                            <div className={styles.noEventsContainer}>
                              <div className={styles.noEventsLogo}>
                                {vector1}
                              </div>
                              <p className={styles.noEventsMessage}>
                                No assessment available
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </TabPane>
                  </TabContent>
                </Tabs>
              </div>
            </>
          )}
        </DataTableContainer>
      </GridItem>
    );
  }
);
