import { Label, Input } from "reactstrap";

export const QPInputField = ({
  input,
  label,
  type,
  meta: { touched, error },
  disabled,
}) => (
  <div key={input}>
    <Label className="terv-label">{label}</Label>
    <Input
      {...input}
      type={type}
      disabled={disabled}
      className="terv-formNew terv-form-full"
    />
    {touched && error && (
      <span className="terv-formHelperTextNew" style={{ color: "red" }}>
        {error}
      </span>
    )}
  </div>
);
