import React, { useRef, useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { PrettoSliderMob } from "./PrettoSliderMob";
import { CloseOutlined } from "@material-ui/icons";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "reactstrap";
import Fab from "@material-ui/core/Fab";
import Style from "./ImageMobCropper.module.scss";
import Cropper from "react-cropper";
import { DropZonetextMob } from "./DropZonetextMob";
import { SendMediaHof } from "../../pages/utils/SendMediaHOF";
import { dataURItoFile } from "../../pages/utils/dataURIhelpers";
import "cropperjs/dist/cropper.css";
import * as CustomIcons from "../../assets/icons/icons.js";
import { DropzoneArea } from "material-ui-dropzone";
import { uid } from "react-uid";

const defImgsNames = [
  18, 19, 20, 21, 22,
];

const tabStyle = { fontFamily: "Poppins", fontSize: 16, fontWeight: 500 };

function ImageMobCropper({ isOpen, close, moduleName, customerName, currentId, handleCroppedImage = () => false }) {
  const [currentImage, setCurrentImage] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [galleryImage, setGalleryImage] = useState("");
  const [tabNumber, setTabNumber] = useState(0);

  const cropperRef = useRef(null);
  const sliderValue = useRef(0);

  const uploadImage = async () => {
    setLoading(true);
    const media = currentImage;
    const resp = await SendMediaHof([dataURItoFile(media, "croppedImage")], moduleName, customerName, currentId);
    setLoading(false);
    handleCroppedImage(resp);
    handleRemoveImage();
    close();
  };

  const handleGalleryImage = () => {
    handleCroppedImage(galleryImage);
    handleRemoveImage();
    close();
  };

  const handleRemoveImage = () => {
    if (currentImage) setCurrentImage("");
    if (croppedImage) setCroppedImage("");
    if (cropperRef.current) cropperRef.current = null;
  };

  const handleDropzoneChange = (files) => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setCurrentImage(reader.result));
      reader.readAsDataURL(files[0]);
    }
  };

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImage(cropper?.getCroppedCanvas().toDataURL());
  };

  const handleSliderChange = (event, value) => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    let zoomLevel = null;
    if (value !== sliderValue.current) {
      zoomLevel = value < sliderValue.current ? -0.1 : 0.1;
      sliderValue.current = value;
      cropper.zoom(zoomLevel);
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={close} fullWidth maxWidth="md">
        <DialogTitle classes={{ root: Style.header }}>
          <CloseOutlined
            classes={{ root: Style.closeButton }}
            onClick={close}
          />
          Upload image
        </DialogTitle>
        <DialogContent>
          <main className={Style.mainWrapper}>
            <Tabs
              value={tabNumber}
              onChange={(e, no) => setTabNumber(no)}
              indicatorColor="primary"
              textColor="primary"
              centered
              style={{ marginBottom: 15 }}
            >
              <Tab label="Upload" style={tabStyle} />

              <Tab label="Gallery" style={tabStyle} />
            </Tabs>
            {tabNumber === 0 ? (
              currentImage ? (
                <>
                  <Cropper
                    style={{ height: 350, width: "100%" }}
                    src={currentImage}
                    guides={false}
                    crop={onCrop}
                    ref={cropperRef}
                  />
                  <div className={Style.rangeSlider}>
                    <span className={Style.imgIcon}>
                      {CustomIcons.imageIcon({ size: 15 })}
                    </span>
                    <div className={Style.sliderWidth}>
                      <PrettoSliderMob
                        valueLabelDisplay="auto"
                        aria-label="pretto slider"
                        step={0.1}
                        min={0}
                        max={2.0}
                        defaultValue={0}
                        onChange={handleSliderChange}
                      />
                    </div>
                    <span className={Style.imgIconLeft}>
                      {CustomIcons.imageIcon({ size: 20 })}
                    </span>
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 15,
                      fontWeight: "500",
                    }}
                  >
                    {`
                  ${
                    cropperRef?.current?.cropper
                      ?.getData()
                      ?.width.toString()
                      .split(".")[0]
                  } X
                  ${
                    cropperRef?.current?.cropper
                      ?.getData()
                      ?.height.toString()
                      .split(".")[0]
                  } 
                  `}{" "}
                    pixels
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 10,
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={handleRemoveImage}
                      className={Style.diffImage}
                      outline
                    >
                      Select different image
                    </Button>
                    <Button
                      color="primary"
                      onClick={uploadImage}
                      disabled={currentImage && loading}
                      className={Style.uploadButton}
                    >
                      {loading ? (
                        <CircularProgress
                          size={18}
                          classes={{ root: Style.loader }}
                        />
                      ) : (
                        "Upload"
                      )}
                    </Button>{" "}
                  </div>
                </>
              ) : (
                <DropzoneArea
                  acceptedFiles={["image/*"]}
                  dropzoneText={<DropZonetextMob />}
                  onChange={handleDropzoneChange}
                  maxFileSize={10000000}
                  showPreviewsInDropzone={false}
                  showAlerts={true}
                  dropzoneClass={Style.dropzoneContainer}
                />
              )
            ) : (
              <div
                className={Style.gridSystem}
                style={{ position: "relative" }}
              >
                {defImgsNames.map((ele, index) => (
                  <img
                    key={uid(ele)}
                    id={`${ele}${index + 2}`}
                    src={`https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/default-${ele}.jpeg`}
                    width={160}
                    onClick={() => {
                      setGalleryImage(`default-${ele}`);
                    }}
                    style={{
                      backgroundColor:
                        galleryImage === `default-${ele}` ? "#784df5" : "",
                      padding: galleryImage === `default-${ele}` ? 5 : 3,
                    }}
                  />
                ))}
                {galleryImage && (
                  <Fab
                    color="primary"
                    style={{
                      backgroundColor: "#784df5",
                      fontFamily: "Poppins",
                      position: "fixed",
                      top: "50%",
                      right: "22%",
                      transform: "translateX(-50%)",
                    }}
                    onClick={handleGalleryImage}
                  >
                    <DoneAllIcon />
                  </Fab>
                )}
              </div>
            )}
          </main>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ImageMobCropper;
