import styles from "./summaryTable.module.scss";
import { sessionPrivileges } from "privileges";
import { useMediaQuery } from "@material-ui/core";

export default function SummaryTable({
  assessmentData,
  tableHeader,
  tableData,
  viewModal,
}) {
  const userPrivileges = sessionPrivileges();
  const smallScreen = useMediaQuery("(max-width: 767px)");
  return (
    <div className={styles.tableContainer}>
      <table className={smallScreen ? styles.tableMobile : styles.table}>
        <thead>
          <tr>
            {(tableHeader || []).map((d, i) => (
              <th key={i}>{d}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {(tableData || []).map(({ data = [], modal }, i) => (
            <tr key={i}>
              {data.map((d, i2) => (
                <td key={i2}>{d}</td>
              ))}
              <td>
                {userPrivileges.isAdmin || userPrivileges.isSuperAdmin ? (
                  <button
                    className={styles.view}
                    onClick={() => {
                      viewModal(modal);
                    }}
                  >
                    View
                  </button>
                ) : (
                  <button
                    className={styles.view}
                    onClick={() => {
                      viewModal(modal);
                    }}
                    disabled={assessmentData?.showSolution === false}
                  >
                    {assessmentData?.showSolution === true && "View"}
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
