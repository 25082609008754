import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
  IconButton,
  Button as Button1,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  useMediaQuery,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { handleLoggedIn } from "../../store/loggedIn/actions";
import { getUserProfile } from "../../store/UserProfile/actions";
import { Button } from "reactstrap";
import qs from "query-string";
import moment from "moment";
import uuid from "react-uuid";
import ApiHelper from "../../api/apiHelper";
import api from "../..//api/baseConfig";
import jwt from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
import styles from "./SignInModal.module.scss";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { addToCart } from "pages/virtualLab/ViewPracticeQuestions";
import { useTranslation } from "react-i18next";
import { useLogoContext, handleLogoContext } from "context/logoContext";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  textField: {
    height: "50px",
    width: "400px",
  },
  input: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  textField1: {
    height: "50px",
    width: "190px",
    paddingBottom: "7px",
  },
  mobileTextField: {
    height: "50px",
    width: "160px",
    paddingBottom: "7px",
  },
  mobileTextField1: {
    height: "50px",
    width: "343px",
  },
  formControl: {
    width: "190px",
    height: "50px",
  },
  selectroot: {
    width: "190px",
    height: "50px",
  },
  mobileSelectRoot: {
    width: "160px",
    height: "50px",
  },
  selectInput: {
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  selectDialogContainer: {
    "& .MuiPaper-root": {
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#784df5",
        borderRadius: "10px",
        margin: "0 10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
      },
    },
  },
  mobileSignInContainer: {
    "& .MuiDialog-paper": {
      margin: "0 !important",
      marginRight: "50%",
    },
    "& .MuiDialog-scrollPaper": {
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

function SignIn({
  handleSignInModalOpen,
  signInQuestion,
  fromAssessment,
  assessmentId,
  pkgId,
  fromLab,
  buyPkg,
  buyPrepare,
  preapareId,
  openInstructions,
  setVerifiedValueCheck,
  setFormValue,
  getPassword,
  ...props
}) {
  const classes = useStyles();
const cookies = new Cookies();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const logoImage = useLogoContext();
  const [emailInput, setEmailInput] = useState("");
  const [pwdInput, setPwdInput] = useState({
    password: "",
    showPassword: false,
  });

  const [signUpFirstNameInput, setSignUpFirstNameInput] = useState("");
  const [signUpLastNameInput, setSignUpLastNameInput] = useState("");
  const [signUpEmailInput, setSignUpEmailInput] = useState("");
  const [signUpPwdInput, setSignUpPwdInput] = useState({
    password: "",
    showPassword: false,
  });
  const [signUpDobInput, setSignUpDobInpt] = useState("");
  const [signUpGenderInput, setSignUpGenderInput] = useState("");
  const [signUpMobNoInput, setSignUpMobNoInput] = useState();

  const isSmallScreen = useMediaQuery("(max-width: 960px)");

  const [checked, setChecked] = useState(false);

  const [errors, setErrors] = useState({ email: "", pwd: "" });

  const [hasAccount, setHasAccount] = useState(false);

  useEffect(() => {
    if (props.head === "signup") {
      setHasAccount(true);
    }
  }, []);
  const showSignUp = props.setShowSignUP;
  const [loginInProgress, setLoginInProgress] = useState(false);

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();

  const handleSignUpPasswordChange = (prop) => (event) => {
    setSignUpPwdInput({ ...signUpPwdInput, [prop]: event.target.value });
  };

  const handleSignUpClickShowPassword = () => {
    setSignUpPwdInput({
      ...signUpPwdInput,
      showPassword: !signUpPwdInput.showPassword,
    });
  };

  const handleSignUpMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleGenderChange = (event) => {
    setSignUpGenderInput(event.target.value);
  };

  const handleCheckedChange = (event) => {
    setChecked(event.target.checked);
  };

  const moveToTermsAndCondition = () => {
    history.push("/termsAndConditions");
  };
  useEffect(() => {
      getPassword(pwdInput.password);
      // setVerifiedValueCheck(true);
    },[pwdInput]);

  const loginUser = async (e, email, password) => {
    setVerifiedValueCheck(true);
    if (e) {
      e.preventDefault();
    }
    if (loginInProgress) {
      return;
    }
    setLoginInProgress(true);

    let req = assessmentId
      ? `grant_type=password&username=${email || emailInput}&password=${
          password ? btoa(password) : btoa(pwdInput.password)
        }&assessmentId=${assessmentId}`
      : pkgId
      ? `grant_type=password&username=${email || emailInput}&password=${
          password ? btoa(password) : btoa(pwdInput.password)
        }&labId=${pkgId}`
      : `grant_type=password&username=${email || emailInput}&password=${
          password ? btoa(password) : btoa(pwdInput.password)
        }`;

    try {
      const response = await ApiHelper.login(
        `${api.baseURL}${api.loginValidation}?studentOnly=true`,
        req
      );
      if (response.type === "error") {
        if (
          response.data.data == "Sorry! only students can take this assessment"
        ) {
          setErrors({
            ...errors,
            email: "Sorry! only students can take this assessment",
          });
        } else {
          setErrors({
            email: "",
          });
        }
      }
      const showToast = async (message) => {
        toast.info(message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      
        return new Promise(resolve => setTimeout(resolve, 3000));
      };
      
      if (response.type === "success") {
        await showToast("Logging in!...");
      }
      if (response.type === "error") {
        if (response.data.data === "Incorrect password") {
          toast.info("Invalid Credential...", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        }
        if (response.data.data === "user not found") {
          toast.info(
            "Please, check your Email ID is correct or Create a new account...",
            {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            }
          );
        }
      }

      const userToken = response.data.data.access_token;
      const refreshToken = response.data.data.refresh_token;
      if (userToken && refreshToken) {
        const userData = await ApiHelper.axiosCallAuth(
          `${api.baseURL}${api.userManagement.getUserByEmail}${
            email || emailInput
          }`
        );
        setFormValue(userData);
        const userId = userData.id;
        if (userId) {
          localStorage.removeItem("IsPartnerUser");
          var decoded = jwt(userToken);
          cookies.set("userToken", userToken, { path: "/" });
          cookies.set("refreshToken", refreshToken, {
            path: "/",
          });
          cookies.set("userId", userId, { path: "/" });
          const headerState = {
            state: true,
            dashboard: !!decoded?.authorities?.includes("LEARNER_PROFILE"),
            student: !!decoded?.authorities?.includes("LEARNER_PROFILE"),
            admin:
              !decoded?.authorities.includes("MANAGE_CUSTOMER") &&
              !decoded?.authorities.includes("GET_STUDENT_DASHBOARD"),
            prepare:
              !!decoded?.authorities?.includes("LEARNER_PROFILE") &&
              !!decoded?.authorities?.includes("VIEW_ASSESSMENT"),
            assessment:
              !!decoded?.authorities?.includes("LEARNER_PROFILE") &&
              !!decoded?.authorities?.includes("VIEW_ASSESSMENT"),
            practice:
              !!decoded?.authorities?.includes("LEARNER_PROFILE") &&
              !!decoded?.authorities?.includes("VIEW_PRACTICE_PACKAGE"),
            canViewProfile:
              !!decoded?.authorities?.includes("GET_VIEW_PROFILE"),
            canViewCart: !!decoded?.authorities?.includes("VIEW_CART"),
          };
          dispatch(handleLoggedIn(headerState));
          sessionStorage.setItem("user_id", decoded.userId);
          const session_id = uuid();
          sessionStorage.setItem("session_id", session_id);
        await ApiHelper.axiosCallPost(
          `${api.baseURL}terv/saveLoginActivity`,
          "post",
          {
            emailId: decoded.user_name,
            sessionId: session_id,
            name: "",
            loginTime: moment().format("yyyy-MM-DDTHH:mm:ss"),
          }
        )
          .then(async () => {
            const assessmentId = qs.parse(window.location.search)?.assessmentId;
            if (buyPkg) {
              dispatch(getUserProfile(decoded.user_name));

              history.push({
                pathname: "/checkout",
                params: { items: pkgId },
              });
            } else if (buyPrepare) {
              dispatch(getUserProfile(decoded.user_name));

              history.push({
                pathname: "/checkout",
                params: { items: preapareId, productType: "course" },
              });
            } else if (assessmentId) {
              window.location.href = `/taketest?id=${assessmentId}&userId=${sessionStorage.getItem(
                "user_id"
              )}`;
            } else if (props?.goToStep3) {
              props?.setModal(false);
              props?.goToStep3();
            } else if (
              sessionStorage.getItem("viewPkgWithoutSignIn") === "true" &&
              decoded?.authorities?.includes("LEARNER_PROFILE")
            ) {
              sessionStorage.setItem("viewPkgWithoutSignIn", "false");
              if (signInQuestion?.id) {
                dispatch(getUserProfile(decoded.user_name));
                history.push({
                  pathname: `/practice-lab`,
                  params: {
                    questionId: signInQuestion.id,
                    path: signInQuestion.path,
                  },
                  state: {
                    fromEdittrue: true,
                    id: signInQuestion.id,
                  },
                });
              } else if (fromAssessment || fromLab) {
                openInstructions();
                if (fromLab) {
                  dispatch(getUserProfile(decoded.user_name));
                  // dispatch(handleLoggedIn(headerState));
                }
              }
            } else {
              if (decoded?.authorities?.includes("MENTOR_PROFILE")) {
                if (decoded?.authorities?.includes("VIEW_STUDENTS_REPORT")) {
                  window.location.pathname = "/students-report";
                } else {
                  window.location.pathname = "/activeUsersList";
                }
              } else if (decoded?.authorities?.includes("ADMIN_ROLE")) {
                if (decoded?.authorities?.includes("GET_ADMIN_DASHBOARD")) {
                  window.location.pathname = "/dashboard";
                } else {
                  window.location.pathname = "/activeUsersList";
                }
              } else if (decoded?.authorities?.includes("LEARNER_PROFILE")) {
                if (decoded?.authorities?.includes("GET_STUDENT_DASHBOARD")) {
                  if (window.location.search.includes("id")) {
                    window.location.href = `/taketest${
                      window.location.search
                    }&${sessionStorage.getItem("user_id")}`;
                  } else {
                    window.location.pathname = "/dashboard";
                  }
                } else if (decoded?.authorities?.includes("VIEW_ASSESSMENT")) {
                  window.location.pathname = "/assessment";
                } else if (
                  decoded?.authorities?.includes("VIEW_PRACTICE_PACKAGE")
                ) {
                  window.location.pathname = "/lab";
                }
              } else {
                window.location.pathname = "/prepare";
              }
            }
          })
          .catch((e) => {
            window.location.pathname = "/login";
          });
      }
    }
  } catch (err) {
  } finally {
    setLoginInProgress(false);
  }
};

  const signUpUser = async (e) => {
    e.preventDefault();
    const email = signUpEmailInput;

    if (checked === true) {
      if (
        /^[a-zA-Z]*$/.test(signUpFirstNameInput) &&
        /^[a-zA-Z]*$/.test(signUpLastNameInput) &&
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
          signUpEmailInput
        )
      ) {
        let userDetails = {
          firstName: signUpFirstNameInput,
          lastName: signUpLastNameInput,
          emailId: signUpEmailInput,
          password: signUpPwdInput.password,
          userType: "learner",
          phoneNumber: signUpMobNoInput,
          dateOfBirth: signUpDobInput,
          gender: signUpGenderInput,
          customerId: "605b13d98443a50c4177d35d",
          updatedBy: signUpEmailInput,
          createdBy: signUpEmailInput,
        };

        await ApiHelper.signUpValidateUser(
          `${api.baseURL}${api.userManagement.checkUserEmail}${email}`
        ).then(async (response) => {
          if (response === true) {
            toast.error(
              "This email is already in use, Please try using different email Id...",
              {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: false,
              }
            );
          } else if (response === false) {
            ApiHelper.signup(
              `${api.baseURL}${api.userManagement.creatNewUser}`,
              userDetails
            )
              .then((res) => {
                toast.info("Successfully Signed Up!...", {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: false,
                });
                loginUser(null, email, signUpPwdInput.password);
              })

              .catch((e) => {
                toast.error("Oops.., Something went wrong...", {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: false,
                });
              });
          }
        });
      } else {
        toast.error(
          "Please check first & last name, email id are in correct format...",
          {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          }
        );
      }
    } else if (checked === false) {
      toast.info(
        "Please accept our terms and condition then press sign up...",
        {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        }
      );
    }
  };

  const handleClickShowPassword = () => {
    setPwdInput({ ...pwdInput, showPassword: !pwdInput.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {hasAccount ? (
        <>
          <Dialog
            fullScreen={isSmallScreen ? true : false}
            classes={{
              root: isSmallScreen
                ? classes.mobileSignInContainer
                : classes.selectDialogContainer,
            }}
            open={props.modal}
            onClose={() => {
              props.setModal(!props.modal);
              setErrors({ email: "", pwd: "" });
            }}
          >
            <ToastContainer />
            <DialogTitle
              className={
                isSmallScreen ? styles.mobileDialogTitle : styles.dialogTitle
              }
            >
              {isSmallScreen && (
                <IconButton
                  aria-label="menu"
                  className={classes.menuButton}
                  color="inherit"
                  edge="start"
                  style={{
                    position: "absolute",
                    width: "3rem",
                    right: "-1rem",
                    top: "0rem",
                  }}
                  onClick={() => {
                    props.setModal(!props.modal);
                    // if (sessionPrivileges.isStudent) {
                    //   setNavMenuOpen((o) => !o);
                    // } else {
                    //   setSidebarOpen((o) => !o);
                    // }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <p className={styles.dialogTitleHeader}>
                {t("Create a new account")}
              </p>
              <p className={styles.dialogSubTitle}>
                {t("Enter your details and signup")}
              </p>
            </DialogTitle>
            <form onSubmit={signUpUser} style={{ margin: "0 auto" }}>
              <DialogContent
                className={
                  isSmallScreen
                    ? styles.mobileDialogContent1
                    : styles.dialogContent1
                }
              >
                <div className={styles.userName}>
                  <span>
                    <TextField
                      required
                      label={t("First Name")}
                      variant="outlined"
                      value={signUpFirstNameInput || ""}
                      onChange={(e) => setSignUpFirstNameInput(e.target.value)}
                      classes={{
                        root: `${
                          isSmallScreen
                            ? classes.mobileTextField
                            : classes.textField1
                        } 
                        ${styles.textField} ${classes.selectLabel}`,
                      }}
                      InputLabelProps={{
                        style: { fontSize: "18px", fontFamily: "Poppins" },
                      }}
                    />
                  </span>
                  <span>
                    <TextField
                      required
                      label={t("Last Name")}
                      variant="outlined"
                      inputProps={{ name: "notASearchField" }}
                      value={signUpLastNameInput || ""}
                      onChange={(e) => setSignUpLastNameInput(e.target.value)}
                      classes={{
                        root: `${
                          isSmallScreen
                            ? classes.mobileTextField
                            : classes.textField1
                        } 
                        ${styles.textField} ${classes.selectLabel}`,
                      }}
                      InputLabelProps={{
                        style: { fontSize: "18px", fontFamily: "Poppins" },
                      }}
                    />
                  </span>
                </div>
                <div className={styles.signInputContainer}>
                  <TextField
                    required
                    label={t("Email")}
                    variant="outlined"
                    type="email"
                    name="notASearchField"
                    value={signUpEmailInput || ""}
                    onChange={(e) => setSignUpEmailInput(e.target.value)}
                    classes={{
                      root: `${
                        isSmallScreen
                          ? classes.mobileTextField1
                          : classes.textField
                      } 
                      ${styles.textField} ${classes.selectLabel}`,
                    }}
                    InputLabelProps={{
                      style: { fontSize: "18px", fontFamily: "Poppins" },
                    }}
                  />
                </div>
                <div className={styles.signInputContainer}>
                  <TextField
                    required
                    label={t("Password")}
                    variant="outlined"
                    type={signUpPwdInput.showPassword ? "text" : "password"}
                    value={signUpPwdInput.password || ""}
                    onChange={handleSignUpPasswordChange("password")}
                    classes={{
                      root: `${
                        isSmallScreen
                          ? classes.mobileTextField1
                          : classes.textField
                      } 
                      ${styles.textField} ${classes.selectLabel}`,
                    }}
                    InputLabelProps={{
                      style: { fontSize: "18px", fontFamily: "Poppins" },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleSignUpClickShowPassword}
                            onMouseDown={handleSignUpMouseDownPassword}
                            edge="end"
                          >
                            {signUpPwdInput.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={styles.dobAndGender}>
                  <span>
                    <TextField
                      id="date"
                      label={t("Date of birth")}
                      type="date"
                      variant="outlined"
                      inputProps={{
                        min: "1955-01-01",
                        max: yyyy + "-" + mm + "-" + dd,
                      }}
                      onChange={(e) => setSignUpDobInpt(e.target.value)}
                      classes={{
                        root: `${
                          isSmallScreen
                            ? classes.mobileTextField
                            : classes.textField1
                        } 
                        ${styles.textField} ${classes.selectLabel}`,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </span>
                  <span>
                    <FormControl
                      variant="outlined"
                      classes={{
                        root: isSmallScreen
                          ? classes.mobileSelectRoot
                          : classes.selectroot,
                      }}
                    >
                      <InputLabel>{t("Gender")}</InputLabel>
                      <Select
                        value={signUpGenderInput}
                        onChange={handleGenderChange}
                        label={t("Gender")}
                        InputLabelProps={{
                          style: {
                            fontSize: "18px",
                            fontFamily: "Poppins",
                            backgroundColor: "red",
                          },
                        }}
                        classes={{ root: classes.selectInput }}
                      >
                        <MenuItem value="Male">{t("Male")}</MenuItem>
                        <MenuItem value="Female">{t("Female")}</MenuItem>
                      </Select>
                    </FormControl>
                  </span>
                </div>
                <div className={styles.signInputContainer}>
                  <TextField
                    label={t("Mobile number")}
                    type="number"
                    variant="outlined"
                    onKeyDown={(e) =>
                      (e.keyCode === 69 || e.keyCode === 190) &&
                      e.preventDefault()
                    }
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    onChange={(e) => setSignUpMobNoInput(e.target.value)}
                    InputLabelProps={{
                      style: { fontSize: "18px", fontFamily: "Poppins" },
                    }}
                    classes={{
                      root: `${
                        isSmallScreen
                          ? classes.mobileTextField1
                          : classes.textField
                      } 
                      ${styles.textField} ${classes.selectLabel}`,
                    }}
                  />
                </div>
                <div
                  className={
                    isSmallScreen
                      ? styles.mobileAcceptTermsAndCondition
                      : styles.acceptTermsAndCondition
                  }
                >
                  <span>
                    <Checkbox
                      checked={checked}
                      color="primary"
                      onChange={handleCheckedChange}
                      className={styles.acceptTerm}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </span>
                  <span>
                    <p
                      onClick={moveToTermsAndCondition}
                      style={{ cursor: "pointer" }}
                      className={
                        isSmallScreen
                          ? styles.mobileAcceptTermText
                          : styles.acceptTermText
                      }
                    >
                      {t("I agree to terms and conditions")}
                    </p>
                  </span>
                </div>
                <div className={styles.dialogAction}>
                  <Button
                    className={
                      isSmallScreen
                        ? styles.mobileSignUpButton
                        : styles.signUpButton
                    }
                    style={{
                      backgroundColor: "#1BA94C",
                      border: "1px solid #1BA94C",
                      textTransform: "none",
                    }}
                  >
                    {t("Sign up")}
                  </Button>
                </div>
                <div className={styles.alreadyHaveAnAccount}>
                  <span>
                    <p className={styles.alreadyText}>
                      {t("Already have an account?")}
                    </p>
                  </span>
                  <span>
                    <p
                      className={styles.loginPageButton}
                      onClick={() => {
                        setHasAccount(false);
                      }}
                    >
                      {t("Login")}
                    </p>
                  </span>
                </div>
              </DialogContent>
            </form>
          </Dialog>
        </>
      ) : (
        <>
          <Dialog
            fullScreen={isSmallScreen ? true : false}
            classes={{
              root: isSmallScreen
                ? classes.mobileSignInContainer
                : classes.selectDialogContainer,
            }}
            open={props.modal}
            onClose={() => {
              props.setModal(!props.modal);
              setErrors({ email: "", pwd: "" });
            }}
          >
            <ToastContainer />
            <DialogTitle
              className={
                isSmallScreen ? styles.mobileDialogTitle : styles.dialogTitle
              }
            >
              {isSmallScreen && (
                <IconButton
                  aria-label="menu"
                  className={classes.menuButton}
                  color="inherit"
                  edge="start"
                  style={{
                    position: "absolute",
                    width: "3rem",
                    right: "-1rem",
                    top: "0rem",
                  }}
                  onClick={() => {
                    props.setModal(!props.modal);
                    // if (sessionPrivileges.isStudent) {
                    //   setNavMenuOpen((o) => !o);
                    // } else {
                    //   setSidebarOpen((o) => !o);
                    // }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <p className={styles.dialogTitleHeader}>
                Welcome to {logoImage?.productName}
              </p>
              <p className={styles.dialogSubTitle}>
                {t("Enter your details and login")}
              </p>
            </DialogTitle>
            <form onSubmit={loginUser}>
              <DialogContent
                className={
                  isSmallScreen
                    ? styles.mobileDialogContent
                    : styles.dialogContent
                }
                style={{
                  height: showSignUp ? "290px" : "450px",
                }}
              >
                <div className="inputFieldValidation">
                  <TextField
                    required
                    label={t("Email")}
                    variant="outlined"
                    value={emailInput || ""}
                    InputLabelProps={{
                      style: { fontSize: "18px", fontFamily: "Poppins" },
                    }}
                    classes={{
                      root: `${
                        isSmallScreen
                          ? classes.mobileTextField1
                          : classes.textField
                      } 
                      ${styles.textField}`,
                    }}
                    onChange={(e) => setEmailInput(e.target.value)}
                    onBlur={() => {
                      !emailInput ||
                      !/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
                        emailInput
                      )
                        ? setErrors({
                            ...errors,
                            email: t("Please enter a valid email id"),
                          })
                        : setErrors({ ...errors, email: "", pwd: "" });
                    }}
                  />
                  <div>
                    {errors.email && (
                      <span style={{ color: "red" }}>{errors.email}</span>
                    )}
                  </div>
                </div>

                <div className={styles.signInPwdContainer}>
                  <TextField
                    required
                    label={t("Password")}
                    variant="outlined"
                    type={pwdInput.showPassword ? "text" : "password"}
                    value={pwdInput.password || ""}
                    InputLabelProps={{
                      style: { fontSize: "18px", fontFamily: "Poppins" },
                    }}
                    classes={{
                      root: `${
                        isSmallScreen
                          ? classes.mobileTextField1
                          : classes.textField
                      } 
                      ${styles.textField}`,
                    }}
                    onChange={(e) =>
                      setPwdInput({ ...pwdInput, password: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {pwdInput.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onBlur={() => {
                      !pwdInput.password
                        ? setErrors({
                            ...errors,
                            pwd: t("Please enter a valid password"),
                          })
                        : setErrors({ ...errors, pwd: "", email: "" });
                    }}
                  />
                  <div>
                    {errors.pwd && (
                      <span style={{ color: "red" }}>{errors.pwd}</span>
                    )}
                  </div>
                </div>
                <div
                  className={
                    isSmallScreen
                      ? styles.mobileForgotPassword
                      : styles.forgotPassword
                  }
                >
                  <p>{t("Forgot password?")}</p>
                </div>
                <div className={styles.dialogAction}>
                  <Button
                    color="primary"
                    disabled={emailInput && pwdInput ? false : true || loginInProgress}
                    className={
                      isSmallScreen
                        ? styles.mobileSignInButton
                        : styles.signInButton
                    }
                    // onClick={()=> {
                    //   setVerifiedValueCheck(true);
                    // }}
                    onClick={() => loginUser()}
                  >
                    {t("Sign In")}
                  </Button>
                </div>
                {!showSignUp ? (
                  <div>
                    <div className={styles.orDivision}>
                      <span className={styles.line1}></span>
                      <span>or</span>
                      <span className={styles.line2}></span>
                    </div>
                    <div className={styles.newSignUp}>
                      <p className={styles.dontHaveAccount}>
                        {t("Dont have an account?")}
                      </p>
                      <Button1
                        variant="outlined"
                        style={{
                          border: "2px solid #1BA94C",
                          color: "#1BA94C",
                          textTransform: "none",
                        }}
                        className={
                          isSmallScreen
                            ? styles.mobileSignInButton
                            : styles.signUpButton
                        }
                        onClick={() => setHasAccount(!hasAccount)}
                      >
                        {t("Sign up")}
                      </Button1>
                    </div>
                  </div>
                ) : (
                  <div>{""}</div>
                )}
              </DialogContent>
            </form>
          </Dialog>
        </>
      )}
    </>
  );
}

export default connect(null, null)(SignIn);
