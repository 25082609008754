import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "react-js-pagination";
import { useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CheckModal } from "./CheckModal";
import { InstructionModal } from "./InstructionModal";
import { SebInstructions } from "./SebInstructions";
import { VerifyPasscode } from "./VerifyPassword";
import { getSafe } from "../Taketest/common/testHelper";
import ApiHelper from "../../api/apiHelper";
import api from "../../api/baseConfig";
import * as CustomIcons from "../../assets/icons/icons";
import testlogo from "../../assets/images/assessmenttest/pen.svg";
import { paginationClasses } from "../utils/constants";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import styles from "../CardStyles.module.scss";
import { TabPane, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import StyledDate from "pages/Ecommerce/EcommerceOrders/StyledDate";
import { EmptyState } from "components/EmptyState";
import classes from "./cardModal.module.scss";
import SignInModal from "components/VerticalLayout/SignInModal";
import { ModalToggle } from "pages/utils/ModalToggle";
import { useTranslation } from "react-i18next";
import { sessionPrivileges } from "privileges";

export default function CardModal(props) {
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const mediumScreen = useMediaQuery(
    "(min-width: 768px) and (max-width: 1023px)"
  );
  const { t } = useTranslation();
  const largeScreen = useMediaQuery("(min-width: 1024px)");
  const Priv = sessionPrivileges().userprivileges || [];
  const [whichCourse, setWhichCourse] = useState({
    name: "",
    reason: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [assessmentData, setAssessmentData] = useState({});

  const [sectionData, setSectionData] = useState([]);
  const [authDetails, setAuthDetails] = useState({
    passCode: "",
    imageEnabled: "",
    userVerified: "",
    userPhoto: "",
  });

  const [password, setPassword] = useState("");
  const [checkModal, setCheckModal] = useState(false);
  const [auth, setAuth] = useState(undefined);
  const [openSignin, setOpenSignin] = useState(false);

  const takeAssessment = (a) => {
    assessmentData.id !== a?.id ? setAssessmentData(a) : null;
    getResData(a.id);
    setShowModal(ModalToggle);
  };

  const handleAssessment = (assessment) => {
    if (assessment.courseId) {
      if (assessment.courseProgress === 100) {
        takeAssessment(assessment);
      } else {
        if (!whichCourse.name || whichCourse.name !== assessment.courseName) {
          setWhichCourse({
            name: assessment.courseName,
            reason: !whichCourse.reason,
          });
        } else {
          setWhichCourse({
            ...whichCourse,
            reason: !whichCourse.reason,
          });
        }
      }
    } else {
      takeAssessment(assessment);
    }
  };

  async function handlePassword() {
    let isAuth = await VerifyPasscode({
      passCode: password,
      assessmentId: assessmentData.id,
    });
    if (isAuth) {
      toast.success("Passcode is valid", {
        position: "bottom-center",
        style: { zIndex: 2000 },
      });
    } else {
      toast.error("Passcode is invalid", {
        position: "bottom-center",
        style: { backgroundColor: "#ff0000", color: "#fff", zIndex: 2000 },
      });
    }
    setAuth(isAuth);
  }
  const getResData = async (id) => {
    await ApiHelper.axiosCall(
      `${api.baseURL}${api.userManagement.getInfo}${id}`,
      "get"
    )
      .then((res) => {
        let temp = [];
        res.sectionList.forEach((r) => {
          temp.push({
            secName: r.name,
            secDescription: r.description,
            noOfQuestions: r.questionIdList.length,
          });
        });

        let defpassCode = false;
        defpassCode = getSafe(() => res.settings.passcodeEnabled, false);
        let imageEnabledD = getSafe(() => res.settings.imageEnabled, true);
        setAuthDetails({
          ...authDetails,
          passCode: defpassCode,
          imageEnabled: imageEnabledD,
          userVerified: !imageEnabledD,
          isSEB: !!res.settings?.secureBrowserEnabled,
          isProctoringAI: res?.settings?.proctoringAI,
          sebUrl: res.seburl,
        });
        setSectionData(temp);
      })
      .catch((e) => {});
  };

  const handleSignInModal = () => setOpenSignin(ModalToggle);
  return (
    <>
      <SignInModal
        modal={openSignin}
        setModal={() => {
          handleSignInModal();
        }}
        fromAssessment
        assessmentId={assessmentData.id}
        openInstructions={() => {
          handleSignInModal();
          takeAssessment(assessmentData);
        }}
      />
      <TabPane tabId={props.tabId} style={{ marginTop: 10, marginBottom: 10 }}>
        <Modal
          isOpen={whichCourse.reason}
          toggle={() =>
            setWhichCourse({
              ...whichCourse,
              reason: !whichCourse.reason,
            })
          }
          centered
        >
          <ModalBody style={{ fontSize: 14 }}>
            Since you've not completed the course{" "}
            <strong>{whichCourse.name}</strong>, you can't attend this
            assessment
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() =>
                setWhichCourse({
                  ...whichCourse,
                  reason: !whichCourse.reason,
                })
              }
            >
              Okay
            </Button>
          </ModalFooter>
        </Modal>
        <CheckModal
          assessmentData={assessmentData}
          isOpen={checkModal}
          close={() => setCheckModal(false)}
          onTakeTest={() => {
            setShowModal(false);
          }}
          authDetails={authDetails}
          callback={props.callback}
          setUserVerifiedPhoto={(photo) => {
            setAuthDetails({
              ...authDetails,
              userVerified: true,
              userPhoto: photo,
            });
          }}
        />
        <InstructionModal
          showModal={showModal}
          toggle={() => {
            setAuth(false);
            setShowModal(!showModal);
          }}
          assessmentData={assessmentData}
          authDetails={authDetails}
          isSEB={authDetails.isSEB}
          sebUrl={authDetails.sebUrl}
          sectionData={sectionData}
          setPassword={(pas) => setPassword(pas)}
          handlePassword={handlePassword}
          auth={auth}
          setCheckModal={() => setCheckModal(!checkModal)}
        />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "5vw",
            justifyContent: "center",
          }}
        >
          {props.data[props.tabId]?.slice(
            props.firstIndices[props.tabId],
            props.lastIndices[props.tabId]
          )?.length > 0 ? (
            props.data[props.tabId]
              .slice(
                props.firstIndices[props.tabId],
                props.lastIndices[props.tabId]
              )
              .map((j) => (
                <>
                  {props.gridViews ? (
                    <div>
                      <Card
                        key={j.id}
                        classes={{ root: styles.CARD_NEW }}
                        style={{ position: "relative" }}
                      >
                        <CardMedia
                          style={{ height: 150 }}
                          image={
                            j.mediaPath
                              ? j.mediaPath.includes("default")
                                ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${j.mediaPath}.jpeg`
                                : j.mediaPath
                              : `https://terv-assets.s3.ap-south-1.amazonaws.com/PracticePackages/image-${
                                  Math.floor(Math.random() * 9) + 1
                                }.jpg`
                          }
                          title={`${j.mediaPath}`}
                        />
                        <div
                          style={{
                            height: 150,
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            background:
                              "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))",
                            zIndex: 1,
                          }}
                        />
                        <CardContent style={{ padding: 0 }}>
                          <p
                            className={styles.CardHeader}
                            style={{
                              marginLeft: 10,
                              marginBottom: 5,
                            }}
                          >
                            {j.name}
                          </p>

                          {props.tabId === "active" ? (
                            <div
                              style={{
                                textDecoration: "none",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {props.tabId === "active" && (
                                <StyledDate SD={j.startDate} ED={j.endDate} />
                              )}
                            </div>
                          ) : (
                            props.tabId === "completed" &&
                            !j?.showResultOnFinish && (
                              <div
                                style={{
                                  backgroundColor: "#fffedb",
                                  borderRadius: 5,
                                  padding: "8px 12px",
                                  margin: 12,
                                }}
                              >
                                {t(
                                  "This assessment is on review. A detailed report will be available soon."
                                )}
                              </div>
                            )
                          )}
                          {(Priv?.includes("START_ASSESSMENT") ||
                            sessionStorage.getItem("viewPkgWithoutSignIn") ===
                              "true") &&
                          (props.tabId === "active" || props.tabId === "all") &&
                          !j.marks?.includes("/") ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 10,
                              }}
                            >
                              {j.userAssessmentStatus !== "Evaluating" ? (
                                <Tooltip
                                  title="Take test"
                                  arrow
                                  placement="top-start"
                                >
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      if (
                                        sessionStorage.getItem(
                                          "viewPkgWithoutSignIn"
                                        ) === "true"
                                      ) {
                                        handleSignInModal();
                                        setAssessmentData(j);
                                      } else {
                                        handleAssessment(j);
                                      }
                                    }}
                                    style={{
                                      marginTop:
                                        sessionStorage.getItem(
                                          "viewPkgWithoutSignIn"
                                        ) === "true"
                                          ? "5rem"
                                          : 0,
                                    }}
                                  >
                                    <img
                                      src={testlogo}
                                      height={16}
                                      width={16}
                                    />
                                    &nbsp; {t("Take test")}
                                  </Button>
                                </Tooltip>
                              ) : (
                                <span
                                  style={{
                                    textAlign: "center",
                                    fontSize: 15,
                                    fontWeight: 500,
                                  }}
                                >
                                  Evaluation in progress...
                                </span>
                              )}
                            </div>
                          ) : props.tabId === "completed" ? (
                            j?.showResultOnFinish ? (
                              <>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    gap: 10,
                                    marginTop: 15,
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    className={classes.ribbon}
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <span>
                                      {CustomIcons.Ribbon({
                                        size: 20,
                                        color: "",
                                      })}
                                      &nbsp;&nbsp;
                                      {j.marks && j.marks}
                                      &nbsp; marks scored
                                    </span>
                                  </div>
                                  <Link
                                    to={{
                                      pathname: "/assessment-report",
                                      params: { assessmentId: j?.id },
                                      state: j,
                                      userId: props.data.userId,
                                    }}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: 30,
                                    }}
                                  >
                                    <Tooltip
                                      title="Report"
                                      arrow
                                      placement="top-start"
                                    >
                                      <Button
                                        color="primary"
                                        className=""
                                        style={{
                                          backgroundColor: "transparent",
                                          color: "#784df5",
                                        }}
                                      >
                                        Report
                                      </Button>
                                    </Tooltip>{" "}
                                  </Link>
                                </div>
                              </>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button disabled>{t("On review")}</Button>
                              </div>
                            )
                          ) : props.tabId === "upcoming" ? (
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: 15,
                                fontWeight: 500,
                              }}
                            >
                              <StyledDate SD={j.startDate} ED={j.endDate} />
                              Will start shortly
                            </div>
                          ) : (
                            ""
                          )}
                        </CardContent>
                      </Card>
                    </div>
                  ) : (
                    <div
                      className="listGroup"
                      style={{ paddingBottom: "30px" }}
                    >
                      <tr
                        className="equalHMRWrap eqWrap"
                        key={j.id}
                        style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}
                      >
                        <td className="equalHM eq">
                          <img
                            src={`https://terv-assets.s3.ap-south-1.amazonaws.com/PracticePackages/image-${
                              Math.floor(Math.random() * 9) + 1
                            }.jpg`}
                            className="img"
                            alt={`${j.media}`}
                            style={{ borderRadius: "10px 0px 0px 10px" }}
                            width="150"
                            height="120"
                          />
                        </td>
                        <td
                          className="equalHM2 eq"
                          style={{ fontSize: "16px" }}
                        >
                          <div>
                            <p
                              className="namePosition"
                              style={{ fontWeight: "600" }}
                            >
                              {j.name}
                            </p>
                          </div>
                        </td>
                        {/* <td className="equalHM eq" style={{fontSize: '15px'}}>
                      <div>
                        <p className="namePosition1" style={{textOverflow: "ellipsis"}}>{j.description}</p>
                      </div>
                    </td> */}
                        {props.tabId === "inactive" ? (
                          <td className="equalHM-1 eq">
                            {props.tabId === "active" && (
                              <StyledDate SD={j.startDate} ED={j.endDate} />
                            )}
                          </td>
                        ) : (
                          props.tabId === "inactive" &&
                          !j?.showResultOnFinish && (
                            <td className="namePosition2">
                              {props.tabId === "active" && (
                                <StyledDate SD={j.startDate} ED={j.endDate} />
                              )}
                            </td>
                          )
                        )}
                        {props.tabId === "active" ? (
                          <td
                            className="equalHM-1 eq"
                            style={{ paddingTop: 10 }}
                          >
                            {props.tabId === "active" && (
                              <StyledDate SD={j.startDate} ED={j.endDate} />
                            )}
                          </td>
                        ) : (
                          props.tabId === "completed" &&
                          !j?.showResultOnFinish && (
                            <div style={{ display: "flex" }}>
                              <td className="completedAssessment equalHM3 eq">
                                {t(
                                  "This assessment is on review. A detailed report will be available soon."
                                )}
                              </td>
                              <div className="namePosition2 equalHM eq">
                                <Button disabled>{t("On review")}</Button>
                              </div>
                            </div>
                          )
                        )}
                        {(Priv?.includes("START_ASSESSMENT") ||
                          sessionStorage.getItem("viewPkgWithoutSignIn") ===
                            "true") &&
                        (props.tabId === "active" || props.tabId === "all") &&
                        !j.marks?.includes("/") ? (
                          <td className="item namePosition2 equalHM eq">
                            {j.userAssessmentStatus !== "Evaluating" ? (
                              <Tooltip
                                title="Take test"
                                arrow
                                placement="top-start"
                              >
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    handleAssessment(j);
                                  }}
                                >
                                  <img src={testlogo} height={16} width={16} />
                                  &nbsp; Take test
                                </Button>
                              </Tooltip>
                            ) : (
                              <span
                                style={{
                                  textAlign: "center",
                                  fontSize: 15,
                                  fontWeight: 500,
                                }}
                              >
                                Evaluation in progress...
                              </span>
                            )}
                          </td>
                        ) : props.tabId === "completed" ? (
                          j?.showResultOnFinish ? (
                            <div style={{ display: "flex" }}>
                              <div
                                className="namePosition2 equalHM3 eq"
                                style={{ maxWidth: "100%" }}
                              >
                                <div
                                  className={classes.ribbon}
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <span>
                                    {CustomIcons.Ribbon({
                                      size: 20,
                                      color: "",
                                    })}
                                    &nbsp;&nbsp;
                                    {j.marks && j.marks}
                                    &nbsp; marks scored
                                  </span>
                                </div>
                              </div>
                              <td className="namePosition2 equalHM eq">
                                <Link
                                  to={{
                                    pathname: "/assessment-report",
                                    params: { assessmentId: j?.id },
                                    state: j,
                                    userId: props.data.userId,
                                  }}
                                  // style={{
                                  //   display: "flex",
                                  //   justifyContent: "center",
                                  //   marginTop: 30,
                                  // }}
                                >
                                  <Tooltip
                                    title="Report"
                                    arrow
                                    placement="top-start"
                                  >
                                    <Button
                                      color="primary"
                                      className=""
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "#784df5",
                                        paddingLeft: "25px",
                                        paddingRight: "25px",
                                      }}
                                    >
                                      Report
                                    </Button>
                                  </Tooltip>{" "}
                                </Link>
                              </td>
                            </div>
                          ) : (
                            ""
                          )
                        ) : props.tabId === "upcoming" ? (
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: 15,
                              fontWeight: 500,
                            }}
                          >
                            Starts in {moment().endOf(j.startDate).fromNow()}
                          </div>
                        ) : (
                          ""
                        )}
                      </tr>
                    </div>
                  )}
                </>
              ))
          ) : (
            <EmptyState label="No Assessments" />
          )}
        </div>
        {!!props.data[props.tabId]?.slice(
          props.firstIndices[props.tabId],
          props.lastIndices[props.tabId]
        )?.length && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "54px",
            }}
          >
            <Pagination
              {...paginationClasses}
              activePage={props.activePage}
              itemsCountPerPage={props.itemsPerPage}
              totalItemsCount={props.data[props.tabId].length}
              pageRangeDisplayed={5}
              onChange={(pageNo) => props.handlePageChange(pageNo, props.tabId)}
            />
          </div>
        )}
      </TabPane>
      <style jsx="true" global="true">
        {`
          .modal-dialog {
            min-width: 996px;
          }
          .modal-header {
            background: #141a22;
          }
          .modal-title {
            text-align: center;
            color: #fff;
            width: 100%;
            font-size: 16px;
            font-weight: 400;
          }
          table {
            border-collapse: collapse;
            width: 100%;
            background: #ffffff;
            box-shadow: 0px 4px 8px rgba(135, 135, 135, 0.2);
            border-radius: 5px;
          }
          th {
            background: #c5eafc;
          }
          td,
          th {
            padding: 8px;
            color: #000;
          }
          .instruction-list ul li {
            list-style-type: square;
          }
          .listGroup {
            width: 1074px;
            height: 98px;
            padding-bottom: 10px;
            border-left: none;
          }
          li {
            list-style-type: none;
          }
          .container {
            display: grid;
            grid-template-columns: 20% 20% 20% 20% 20%;
          }
          .itemPosition {
            padding-top: 0px;
          }

          .item-1 {
            padding-top: 20px;
          }

          .item-2 {
            margin: 0;
          }

          .itemList {
            font-size: 16px;
            padding: 10px;
            margin: 5px;
          }
          .styleList {
            background-color: rgb(255, 255, 255);
            border-radius: 7px;
            box-shadow: rgb(0 0 0 / 20%) 0px 2px 2px 0px;
            display: flex;
            border-top: 4px solid rgb(93, 224, 180);
            padding: 10px;
            margin: 5px;
          }
          listGroup {
            width: 1074px;
            height: 98px;
            left: 66px;
            top: 32px;
          }

          li {
            list-style-type: none;
          }

          .equalHMRWrap {
            justify-content: space-between;
            flex-wrap: wrap;
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
            padding-right: 20px;
          }

          .eqWrap {
            display: flex;
          }

          .equalHM {
            width: 14%;
          }

          .equalHM-1 {
            width: 200px;
            top: 10px;
          }
          .equalHM-2 {
            width: 10vw;
            top: 10px;
          }
          .equalHM2 {
            width: 20vw;
            top: 10px;
          }
          .equalHM3 {
            width: 20vw;
            top: 10px;
          }

          .eq {
            padding: 0px;
          }

          .itemPosition {
            padding-top: 0px;
          }

          .item-1 {
            padding-top: 20px;
          }
          .itemList {
            font-size: 16px;
            padding: 10px;
            margin: 5px;
          }

          .styleList {
            background-color: rgb(255, 255, 255);
            border-radius: 7px;
            box-shadow: rgb(0 0 0 / 20%) 0px 2px 2px 0px;
            display: flex;
            border-top: 4px solid rgb(93, 224, 180);
            padding: 10px;
            margin: 5px;
          }

          .dateList {
            text-align: center;
            padding: 5px;
          }

          .namePosition {
            padding-top: 36px;
          }
          .report {
            padding-top: 20px;
          }
          .namePosition1 {
            padding-top: 55px;
          }

          .completedAssessment {
            padding-top: 22px;
          }
          .namePosition2 {
            padding-top: 50px;
          }

          @media only screen and (max-width: 620px) {
            .listGroup {
              width: 100%;
            }
          }
        `}
      </style>
    </>
  );
}
