import React from "react";

export default function Placements() {
  return (
    <>
      <div
        className="-mb-10"
        style={{
          margin: "auto",
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          marginTop: "40px",
          marginBottom: "40px",
          width : "50%",
          height : "150px",
        }}
      >
        <div
          className="mb-20 w-full mx-auto sm:p-0 bg-v5-green-500 text-v5-neutral-200 flex justify-center rounded-[20px] sm:flex-nowrap"
          style={{
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            borderRadius: "20px",
            backgroundColor: "#5E35B1",
            color: "rgb(242 251 247/1)",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            padding: "0px",
            display: "flex",
            height: "100%",
          }}
        >
          <div
            className="my-2 flex flex-col justify-center px-2 text-center md:w-1/5"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              marginBottom: "0.5rem",
              marginTop: "0.5rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              textAlign: "center",
              width: "20%",
            }}
          >
            <h4
              className="text-v5-green-100 font-manrope font-extrabold"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                fontSize: "1.5rem",
                fontFamily: "Manrope, sans-serif",
                fontWeight: 800,
                color: "rgb(45 248 197/1)",
              }}
            >
              95%
            </h4>
            <p
              className="whitespace-nowrap font-rubik text-xs leading-tight"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                whiteSpace: "nowrap",
                fontFamily: "Rubik, sans-serif",
                fontSize: "0.75rem",
                lineHeight: 1.25,
              }}
            >
              <div
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                }}
              >
                placed within 9
                <br
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                />{" "}
                months of graduation
              </div>
            </p>
          </div>
          <div
            className="hidden h-full min-h-[100px] border-r border-v5-green-100 border-opacity-20 sm:block"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              height: "100%",
              minHeight: "100px",
              borderRightWidth: "1px",
              borderColor: "rgb(45 248 197/0.2)",
              display: "block",
            }}
          />
          <div
            className="my-2 flex flex-col justify-center px-2 text-center md:w-1/5"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              marginBottom: "0.5rem",
              marginTop: "0.5rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              textAlign: "center",
              width: "20%",
            }}
          >
            <h4
              className="text-v5-green-100 font-manrope font-extrabold"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                fontSize: "1.5rem",
                fontFamily: "Manrope, sans-serif",
                fontWeight: 800,
                color: "rgb(45 248 197/1)",
              }}
            >
              10 LPA
            </h4>
            <p
              className="whitespace-nowrap font-rubik text-xs leading-tight"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                whiteSpace: "nowrap",
                fontFamily: "Rubik, sans-serif",
                fontSize: "0.75rem",
                lineHeight: 1.25,
              }}
            >
              <div
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                }}
              >
                average dream
                <br
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                />
                job CTC
              </div>
            </p>
          </div>
          <div
            className="hidden h-full min-h-[100px] border-r border-v5-green-100 border-opacity-20 sm:block"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              height: "100%",
              minHeight: "100px",
              borderRightWidth: "1px",
              borderColor: "rgb(45 248 197/0.2)",
              display: "block",
            }}
          />
          <div
            className="my-2 flex flex-col justify-center px-2 text-center md:w-1/5"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              marginBottom: "0.5rem",
              marginTop: "0.5rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              textAlign: "center",
              width: "20%",
            }}
          >
            <h4
              className="text-v5-green-100 font-manrope font-extrabold"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                fontSize: "1.5rem",
                fontFamily: "Manrope, sans-serif",
                fontWeight: 800,
                color: "rgb(45 248 197/1)",
              }}
            >
              21 LPA
            </h4>
            <p
              className="whitespace-nowrap font-rubik text-xs leading-tight"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                whiteSpace: "nowrap",
                fontFamily: "Rubik, sans-serif",
                fontSize: "0.75rem",
                lineHeight: 1.25,
              }}
            >
              <div
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                }}
              >
                average super-dream
                <br
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                />
                job CTC{" "}
              </div>
            </p>
          </div>
          <div
            className="hidden h-full min-h-[100px] border-r border-v5-green-100 border-opacity-20 sm:block"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              height: "100%",
              minHeight: "100px",
              borderRightWidth: "1px",
              borderColor: "rgb(45 248 197/0.2)",
              display: "block",
            }}
          />
          <div
            className="my-2 flex flex-col justify-center px-2 text-center md:w-1/5"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              marginBottom: "0.5rem",
              marginTop: "0.5rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              textAlign: "center",
              width: "20%",
            }}
          >
            <h4
              className="text-v5-green-100 font-manrope font-extrabold"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                fontSize: "1.5rem",
                fontFamily: "Manrope, sans-serif",
                fontWeight: 800,
                color: "rgb(45 248 197/1)",
              }}
            >
              1000+
            </h4>
            <p
              className="whitespace-nowrap font-rubik text-xs leading-tight"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                whiteSpace: "nowrap",
                fontFamily: "Rubik, sans-serif",
                fontSize: "0.75rem",
                lineHeight: 1.25,
              }}
            >
              <div
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                }}
              >
                Hiring
                <br
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                />
                Partners
              </div>
            </p>
          </div>
          <div
            className="hidden h-full min-h-[100px] border-r border-v5-green-100 border-opacity-20 sm:block"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              height: "100%",
              minHeight: "100px",
              borderRightWidth: "1px",
              borderColor: "rgb(45 248 197/0.2)",
              display: "block",
            }}
          />
          <div
            className="my-2 flex flex-col justify-center px-2 text-center md:w-1/5"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              marginBottom: "0.5rem",
              marginTop: "0.5rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              textAlign: "center",
              width: "20%",
            }}
          >
            <h4
              className="text-v5-green-100 font-manrope font-extrabold"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                fontSize: "1.5rem",
                fontFamily: "Manrope, sans-serif",
                fontWeight: 800,
                color: "rgb(45 248 197/1)",
              }}
            >
              81%
            </h4>
            <p
              className="whitespace-nowrap font-rubik text-xs leading-tight"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                overflowWrap: "break-word",
                margin: "0px",
                whiteSpace: "nowrap",
                fontFamily: "Rubik, sans-serif",
                fontSize: "0.75rem",
                lineHeight: 1.25,
              }}
            >
              <div
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                }}
              >
                Average
                <br
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                />
                Salary Hike
              </div>
            </p>
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  margin: 0px;
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  tab-size: 4;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}

body {
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizelegibility;
  overflow-y: auto;
  min-height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  line-height: inherit;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}
`,
        }}
      />
    </>
  );
}
