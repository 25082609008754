import { SET_LOGGED_IN } from "./actionTypes";

export type StateType = {
  state: boolean;
  prepare: boolean;
  assessment: boolean;
  practice: boolean;
  dashboard: boolean;
  cloudLab: boolean;
};

export type ActionType = {
  type: string;
  payload: StateType;
};

export const handleLoggedIn = (newState: StateType): ActionType => {
  return {
    type: SET_LOGGED_IN,
    payload: newState,
  };
};
