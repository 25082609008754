import React, { Component } from "react"
import mainLogo from "../../assets/images/completed.svg"
import {Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom"
import Breadcrumbs from "components/Common/Breadcrumb"
import "./completedTestStyles.scss";
import {STUDENT_COMPLETED_ASSESSMENT} from "./completedImage";

  
export default class CompletedTest extends Component {
  constructor(props) {
    super(props)
    const queryParams = new URLSearchParams(this.props.location.search);
    if (queryParams.get("isMock")) {
      this.isMock = queryParams.get("isMock");
    }
    if (queryParams.get("learningPathIndex")) {
      this.learningPathIndex = queryParams.get("learningPathIndex");
    }
  }
  render() {
    return (
      <>
      <div className="container-sCompletedAssessment">
        <div className="sCompletedAssessmentContent">
          <div className="container-sCompletedImage">
            {STUDENT_COMPLETED_ASSESSMENT}
          </div>
          <div className="container-sAssessmentTitle">
            <span className="container-sCompleted">
              {this.props.location.state.isHackathon ?
                <p>Hackathon Completed</p> :
                <p>{this.isMock ? "Mock Assessment Completed" : "Assessment Completed"}</p>
              }
            </span>
          </div>
          <div className="container-sAssessmentStatus">
            <p>
              Your test has been successfully submitted
            </p>
          </div>
          <div>
          {localStorage.getItem("AssessmentFromPrepare") ?
        <Link
            to={{
              pathname: `course-learning/${localStorage.getItem("AssessmentFromPrepare")}`
            }}
           
            style={{ color: '#FFF' }}
            className="btn  d-none d-sm-inline-block btn-link">
            <Button  style={{
              backgroundColor: "#794DF5",
              position: "relative",
              border: "1px solid #794DF5",
              textAlign: 'center',
              fontSize: 18,
              padding: '6px 16px',
              margin: '0 auto 100px auto',
              display:'flex'
            }}
            onClick={
              localStorage.removeItem("AssessmentFromPrepare")
            }> 
              <div>
                <i className="mdi mdi-arrow-left mr-1" />Back to prepare
              </div>
            </Button>
          </Link>
          :
          (this.props.location.state.isHackathon ?
            <Link
            to={{
              pathname: this.props.location.state.isHackathon &&
              `hackathoninfo/${this.props?.location?.state?.hackathonId}`,
              state: {
                learningPathIndex: this.learningPathIndex,
                prepCourseId: this.props?.location?.state?.prepCourseId,
                prepCourseName: this.props?.location?.state?.prepCourseName,
                prepCoursePer: this.props?.location?.state?.prepCoursePer,
                prepPracCompletionCriteria: this.props?.location?.state?.prepPracCompletionCriteria,
                prepPracResId: this.props?.location?.state?.prepPracResId,
                prepPracModId: this.props?.location?.state?.prepPracModId,
              },
            }}
            style={{ color: '#FFF' }}
            className="btn  d-none d-sm-inline-block btn-link">
            <Button  style={{
              backgroundColor: "#794DF5",
              position: "relative",
              border: "1px solid #794DF5",
              textAlign: 'center',
              fontSize: 18,
              padding: '6px 16px',
              margin: '0 auto 100px auto',
              display:'flex'
            }}> 
              <div>
                <i className="mdi mdi-arrow-left mr-1" />Back to hackathon
              </div>
            </Button>
          </Link> 
          :
            <Link
            to={this.isMock ? {
              pathname: this.props?.location?.state?.isFromPrepare ? 
              `/courselearning/practice/${this.props?.location?.state?.courseId}` : "/view-practice-questions",
              state: {
                learningPathIndex: this.learningPathIndex,
                prepCourseId: this.props?.location?.state?.prepCourseId,
                prepCourseName: this.props?.location?.state?.prepCourseName,
                prepCoursePer: this.props?.location?.state?.prepCoursePer,
                prepPracCompletionCriteria: this.props?.location?.state?.prepPracCompletionCriteria,
                prepPracResId: this.props?.location?.state?.prepPracResId,
                prepPracModId: this.props?.location?.state?.prepPracModId,
              },
            } : "/assessment" 
            }
            style={{ color: '#FFF' }}
            className="btn  d-none d-sm-inline-block btn-link">
            <Button  style={{
              backgroundColor: "#794DF5",
              position: "relative",
              border: "1px solid #794DF5",
              textAlign: 'center',
              fontSize: 18,
              padding: '6px 16px',
              margin: '0 auto 100px auto'
            }}> 
              <div>
                <i className="mdi mdi-arrow-left mr-1" /> {this.isMock ? "Back to Learning Path" : "Back to Assessments"}{" "}
              </div>
            </Button>
          </Link>          
          )
        
        }
          </div>
        </div>
      </div>
    </>
      
    )
  }
}
