import { useState, useEffect, useCallback } from "react";
import cn from "classnames";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { Icon } from "@iconify/react";
import crownIcon from "@iconify/icons-fa-solid/crown";
import { useUserDetails } from "../../hooks/use-user-details";
import {
  FormControlLabel,
  Typography,
  Grid,
  Checkbox,
  Dialog,
} from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";
import styles from "./index.module.scss";
import { useMediaQuery } from "@material-ui/core";
import { sessionPrivileges } from "privileges";
import { useHistory } from "react-router-dom";

function getMoney(amount) {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(parseFloat(amount).toFixed(2));
}

function TextField({ label, value }) {
  return (
    <div className={styles.textField}>
      <label>{label}</label>
      <input readOnly value={value} />
    </div>
  );
}

function Checkout(props) {
  const userDetails = useUserDetails();
  const userPriviage = sessionPrivileges();
  const history = useHistory();
  const [practicePackages, setPracticePackages] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [selectedPackageId, setSelectedPackageId] = useState("");
  const [selectedId, setSelectedId] = useState([]);
  const [paymentDialog, setPaymentDialog] = useState({
    visible: false,
    type: "success",
  });
  const [selectedPackageDescription, setSelectedPackageDescription] =
    useState("");
  const [readTC, setTCReadStatus] = useState(false);
  const [amountDetails, setAmountDetails] = useState({
    discount: 0,
    subtotal: 0,
    total: 0,
    products: [],
  });
  const smallScreen = useMediaQuery("(max-width: 767px)");

  const productType = props?.location?.params?.productType;
  const pay = useCallback(async () => {
    const productId = Array.isArray(props?.location?.params?.items)
      ? props?.location?.params?.items
      : selectedId;
    await Apihelper.axiosCallPost(
      `${api.baseURL}${api.checkout.order}`,
      "post",
      {
        userName: userDetails?.name,
        email: userDetails?.email,
        mobileNumber: userDetails?.phone,
        userId: userDetails?.email,
        productId,
        paymentAmount: amountDetails.total,
        ...(productId.length === 1 && {
          productType: productType || "package",
        }),
      }
    )
      .then((data) => {
        if (data) {
          if (data?.message) {
            if (data?.message === "Successfully purchased the product") {
              setPaymentDialog({ visible: true, type: "success" });
            }
          } else {
            const productId = Array.isArray(props?.location?.params?.items)
              ? props?.location?.params?.items
              : [props?.location?.params?.items];

            const options = {
              // key: "rzp_test_sBOkk7x2lNtv7h",
              key: "rzp_live_Ty0HyuvqE5i4vJ",
              amount: amountDetails.total * 100,
              currency: "INR",
              name: "Terv",
              description: "Terv Purchase",
              image: "",
              order_id: data?.orderId,
              handler: async function (response) {
                await Apihelper.axiosCallPost(
                  `${api.baseURL}${api.checkout.pay}`,
                  "post",
                  {
                    paymentId: response?.razorpay_payment_id,
                    rezorPayOrderId: response?.razorpay_order_id,
                    rezorPaySignature: response?.razorpay_signature,
                    userName: userDetails?.name,
                    email: userDetails?.email,
                    mobileNumber: userDetails?.phone,
                    userId: userDetails?.email,
                    productId,
                    paymentAmount: amountDetails.total,
                    ...(productId.length === 1 && {
                      productType: productType || "package",
                    }),
                  }
                )
                  .then((resData) => {
                    if (
                      resData?.message === "Successfully purchased the product"
                    ) {
                      setPaymentDialog({ visible: true, type: "success" });
                    } else {
                      setPaymentDialog({ visible: true, type: "error" });
                    }
                  })
                  .catch(() => {
                    setPaymentDialog({ visible: true, type: "error" });
                  });
              },
              prefill: {
                name: userDetails?.name,
                email: userDetails?.email,
                contact: `+91 ${userDetails?.phone}`,
              },
              notes: {
                address: "",
              },
              theme: {
                color: "#007aff",
              },
            };

            var rzp1 = new window.Razorpay(options);

            rzp1.open();

            rzp1.on("payment.failed", function (error) {
              setPaymentDialog({ visible: true, type: "error" });
            });
          }
        }
      })
      .catch((e) => {
        setPaymentDialog({ visible: true, type: "error" });
      });
  }, [amountDetails]);

  async function getPackages() {
    await Apihelper.axiosCallPost(
      `${api.baseURL}${api.checkout.getPackages(
        {
          userId: userPriviage.userId,
          entireCart: !props?.location?.params?.items?.length,
          type: productType || "package",
        },
        props?.location?.params?.items
      )}`,
      "get"
    )
      .then((data = []) => {
        setPracticePackages(data);
        setSelectedPackageId(data?.[0]?.id || "");
        setSelectedId(data.map((val) => val.id));
      })
      .catch(() => {});
  }

  useEffect(() => {
    const firstPurchasedValue = (practicePackages[0] || {}).purchased;
    if(firstPurchasedValue === true) {
      history.push({
        pathname: `/prepare`,
      });
    }
  },[practicePackages]);

  async function getAmount() {
    await Apihelper.axiosCallPost(
      `${api.baseURL}${api.checkout.getAmount(
        {
          userId: userPriviage.userId,
          entireCart: !props?.location?.params?.items?.length,
          type: productType || "package",
        },
        props?.location?.params?.items
      )}`,
      "get"
    )
      .then((data) => {
        setAmountDetails({
          discount: data?.discount,
          subtotal: data?.subtotal,
          total: data?.total,
          products: Object.entries(data?.products || []).map((product) => [
            product[0] || "",
            product[1] || 0,
          ]),
        });
      })
      .catch(() => {});
  }

  useEffect(() => {
    getPackages();
    getAmount();
  }, []);

  useEffect(() => {
    setSelectedPackageDescription(
      practicePackages?.[
        practicePackages?.findIndex(({ id }) => id === selectedPackageId)
      ]?.description || ""
    );
  }, [selectedPackageId]);

  return (
    <>
      <div
        className={cn(
          "page-content",
          smallScreen ? styles.pageMobile : styles.page
        )}
      >
        <div
          className={
            smallScreen ? styles.packagesDetailsMobile : styles.packagesDetails
          }
        >
          <div
            className={
              smallScreen
                ? styles.packagesDetailsContentMobile
                : styles.packagesDetailsContent
            }
          >
            <h3 className={styles.packagesDetailsHeading}>Package details</h3>
            <div className={styles.packagesContainer}>
              {(practicePackages || []).map((item, idx) => (
                <div
                  key={idx}
                  className={cn(styles.package, {
                    [styles.selected]: selectedPackageId === item?.id,
                  })}
                  onClick={() => {
                    setSelectedPackageId(item?.id);
                  }}
                >
                  {practicePackages.length > 1 && (
                  <div className={styles.packageSno}>{idx + 1}.</div>
                  )}
                  <img
                    className={styles.packageImage}
                    src={
                      item?.media
                        ? item?.media?.includes("default")
                          ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${item?.media}.jpeg`
                          : item?.media
                        : `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/default-${
                            idx + 1 > 10 ? 10 % (idx + 1) : idx + 1
                          }.jpeg`
                    }
                  />
                  <div className={styles.packageTexts}>
                    <div
                      className={
                        smallScreen
                          ? styles.packageNameMobile
                          : styles.packageName
                      }
                    >
                      {item?.name}
                    </div>
                    <div>&nbsp;</div>
                    <div className={styles.packagePrice}>
                      <div
                        className={
                          smallScreen
                            ? styles.packageOriginalPriceMobile
                            : styles.packageOriginalPrice
                        }
                      >
                        {getMoney(
                          item?.discountAmount === 100
                            ? 0
                            : item?.strikingPrice || item?.originalPrice || 0
                        )}
                      </div>
                      &nbsp;&nbsp;&nbsp;
                      {item?.strikingPrice > 0 && item?.discountAmount > 0 && (
                        <div
                          className={
                            smallScreen
                              ? styles.packageStrikingPriceMobile
                              : styles.packageStrikingPrice
                          }
                        >
                          {getMoney(item?.originalPrice || 0)}
                        </div>
                      )}
                    </div>
                  </div>
                  {item?.tags && (
                    <div className={styles[`${item?.tags}Tag`]}>
                      {item?.tags === "premium" && (
                        <>
                          <Icon
                            icon={crownIcon}
                            style={{ color: "#ffcc4d", fontSize: "12px" }}
                          />
                          &nbsp;&nbsp;
                        </>
                      )}
                      {item?.tags.charAt(0).toUpperCase() + item?.tags.slice(1)}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <br />
            <h3 className={styles.packagesDetailsHeading}>Description</h3>
            <div className={styles.packageDescriptionContainer}>
              <p
                dangerouslySetInnerHTML={{ __html: selectedPackageDescription }}
              />
            </div>
          </div>
        </div>
        <div
          className={
            smallScreen ? styles.purchaseDetailsMobile : styles.purchaseDetails
          }
        >
          <div className={styles.purchaseDetailsContent}>
            <h2 className={styles.title}>{"Checkout details"}</h2>
            <p className={styles.description}>
              Complete your purchase by providing your details
            </p>
            <TextField label="Your Name" value={userDetails?.name} />
            <br />
            <TextField label="Your Email" value={userDetails?.email} />
            <br />
            <TextField label="Mobile Number" value={userDetails?.phone} />
            <br />
            <div className={styles.couponContainer}>
              <TextField
                label="Coupon Code"
                type="text"
                onChange={setCouponCode}
              />
              <Button
                color="primary"
                disabled={!couponCode}
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  height: 40,
                }}
              >
                {"Apply"}
              </Button>
            </div>
            <table className={styles.amountTable}>
              <tbody>
                {amountDetails.products.map((product, idx) => (
                  <tr key={idx}>
                    <td>
                      {amountDetails.products.length > 1 && `${idx + 1}. `}
                      {product[0]}
                    </td>
                    <td>{getMoney(product[1])}</td>
                  </tr>
                ))}
                <tr>
                  <td>Subtotal</td>
                  <td>{getMoney(amountDetails.subtotal)}</td>
                </tr>
                <tr>
                  <td>Discount</td>
                  <td>-&nbsp;{getMoney(amountDetails.discount)}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{getMoney(amountDetails.total)}</td>
                </tr>
              </tbody>
            </table>
            <FormControlLabel
              classes={{ label: styles.tc }}
              control={
                <Checkbox
                  color="primary"
                  onChange={(_e, checked) => {
                    setTCReadStatus(checked);
                  }}
                  checked={readTC}
                />
              }
              label="I Read and accept the terms and conditions"
            />
            <Button
              color="primary"
              disabled={!readTC || !selectedPackageId}
              // disabled
              onClick={pay}
              style={{
                fontSize: 16,
                fontWeight: 500,
                height: 40,
                width: "100%",
              }}
            >
              <>Pay&nbsp;now</>
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        classes={{
          paper: cn(styles.dialog, {
            [styles.success]: paymentDialog.type === "success",
            [styles.error]: paymentDialog.type === "error",
          }),
        }}
        open={paymentDialog?.visible}
      >
        <div className={styles.dialogIcon}>
          {paymentDialog.type === "success" ? <Check /> : <Close />}
        </div>
        <div className={styles.dialogPackagesList}>
          {amountDetails?.products?.slice(0, 3)?.map((product, idx) => (
            <Grid
              key={idx}
              justifyContent="space-between"
              container
              style={{ padding: "14px 24px" }}
            >
              <Typography noWrap>{product[0]}</Typography>
              <Typography
                noWrap
                style={{
                  color:
                    paymentDialog.type === "success" ? "#1BA94C" : "#D42216",
                }}
              >
                {getMoney(product[1])}
              </Typography>
            </Grid>
          ))}
        </div>
        <div className={styles.dialogTitle}>
          {paymentDialog.type === "success" && "Your order is completed"}
          {paymentDialog.type === "error" && "Order failed"}
        </div>
        <div className={styles.dialogDescription}>
          {paymentDialog.type === "success" &&
            "Woohoo! Your payment was successfull and your order is completed"}
          {paymentDialog.type === "error" &&
            "Your order was failed, Your bank server didnt response. Please try again later"}
        </div>
        {paymentDialog.type === "success" && (
          <Button
            size="lg"
            color="primary"
            onClick={() => {
              props.history.push(
                productType === "course"
                  ? `/course-overview/${props?.location?.params?.items}`
                  : "/lab"
              );
            }}
            style={{ margin: "auto", width: "auto", minWidth: 180 }}
          >
            {"Done"}
          </Button>
        )}
        {paymentDialog.type === "error" && (
          <Button
            size="lg"
            color="primary"
            onClick={() => {
              setPaymentDialog({ visible: false });
            }}
            style={{ margin: "auto", width: "auto", minWidth: 180 }}
          >
            {"Close"}
          </Button>
        )}
      </Dialog>
    </>
  );
}

export default withRouter(Checkout);
