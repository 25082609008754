import React from "react";
import styles from "./ContentHeader.module.scss";

type Props = {
  title: string;
  description: string;
  sub: string;
  titleRev?: boolean;
};

export const ContentHeader: React.FC<Props> = ({
  title,
  description,
  sub,
  titleRev,
}) => (
  <div className={styles.content__header__container}>
    <p className={styles.content__header__title_wrap}>
      {titleRev ? (
        <>
          <span className={styles.content__header__sub}>{sub}</span>{" "}
          <span className={styles.content__header__title}>{title}</span>
        </>
      ) : (
        <>
          <span className={styles.content__header__title}>{title}</span>{" "}
          <span className={styles.content__header__sub}>{sub}</span>
        </>
      )}
    </p>
    <p className={styles.content__header__description}>{description}</p>
  </div>
);
