import apiHelper from "api/apiHelper";
import api from "api/baseConfig";
import { sessionPrivileges } from "privileges";
import React, { useEffect, useState } from "react";
import CustomListTableRoles from "./CustomListTableRoles";

function RoleList(props) {
  const [roles, setRoles] = useState([]);
  const userPrivileges = sessionPrivileges();
  const getAllRoles = async (id) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.roleManagement.getCreatedRoles}${id}`
      );
      // if (data && data.length > 0) {
        setRoles(
          data.map((datum) => ({
            name: datum.name,
            id: datum.id,
            customerName: datum.customerName,
            customerId: datum.customerId,
            roleType: datum.roleType || "-",
            assigned: datum.usersAssigned || 0,
            description: datum.description || "-",
            privileges: (datum.privileges || []).reduce((acc, val, ind) => {
              acc +=
                ind + 1 === (datum.privileges || []).length ? val : `${val}, `;
              return acc;
            }, ""),
            privilegesDetails: (datum.privilegesDetails || []).map(
              (it) => it?.name
            ),
          }))
        );
      // }
    } catch (e) {}
  };

  const deleteRole = async (id, customerId) => {
    try {
      const res = await apiHelper.axiosCall(
        `${api.baseURL}${api.roleManagement.deleteRole}${id}`,
        "delete"
      );
      if (res) {
        getAllRoles(customerId);
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (userPrivileges.isAdmin) {
      getAllRoles(sessionStorage.getItem("customer_id"));
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    {userPrivileges.showRoles && (
    <div className="page-content">
      <CustomListTableRoles
        header="Roles"
        createLink="/roleManagement"
        data={roles}
        dataLength={roles.length}
        tableHeaders={[
          { label: "Role name", val: "name" },
          { label: "Role type", val: "roleType" },
          { label: "Description", val: "description" },
          {
            label: "Users assigned",
            val: "assigned",
          },
          { label: "Privileges", val: "privileges" },
        ]}
        setCustomer={props?.location?.state?.customer}
        setUsers={( newCustomer) => getAllRoles(newCustomer)}
        searchPlaceholder="Search Roles"
        deleteDatum={deleteRole}
        showCustomers={userPrivileges.isSuperAdmin}
        showAdd
        hideShowView
        passEditFromRow
        editData={[
          "name",
          "description",
          "roleType",
          "privilegesDetails",
          "id",
          "customerName",
          "customerId",
        ]}
        // setCustomer={props?.location?.state?.customer}
        activePage={props?.location?.state?.activePage}
        itemsPerPage={props?.location?.state?.itemsPerPage}
      />
    </div>
    )}
    </>
  );
}

export default RoleList;
