import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import * as CustomIcons from "../../../assets/icons/icons";
import cn from "classnames";
import { Tabs } from "../../../components/tabs/tabs";
import CustomTable from "../../CustomerManagement/CustomListTable";
import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";
import { connect } from "react-redux";
import { getCustomers } from "../../../store/StudentAndCustomer/actions";
import styles from "./index.module.scss";
import { GetObjectFromString } from "../../../pages/utils/GetObjectFromString";
import Select from "react-select";
import { sessionPrivileges } from "privileges";

function AdminOverallReport(props) {
  const userPrivileges = sessionPrivileges();
  const Priv = sessionPrivileges().userprivileges || [];
  const [pageData, setPageData] = useState({
    assessmentCount: "--",
    ppCount: "--",
    assessmentList: [],
    packageList: [],
  });
  const [activeTab, setActiveTab] = useState(() =>
    Priv.includes("VIEW_STUDENT_ASSESSMENT_REPORT") ? "assessment" : "practice"
  );
  const [customerId, setCustomerId] = useState(() =>
    Priv && Priv.includes("MANAGE_CUSTOMER")
      ? props?.location?.params?.customerId || "605b13d98443a50c4177d35d"
      : sessionStorage.getItem("customer_id") || ""
  );

  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const overAllReport = true;
  // const activePage = props?.location?.state?.activePage;
  // const itemsPerPage = props?.location?.state?.itemsPerPage;

  const customers = useMemo(
    () =>
      (props?.Customers || []).sort(
        ({ name: n1 = "" }, { name: n2 = "" }) =>
          n1?.toLowerCase() < n2?.toLowerCase()
      ),
    [props?.Customers]
  );

  const customersList = [];
  customers.forEach(function (item) {
    customersList.push({ label: item.name, value: item.id });
  });

  async function getData(customer) {
    await Apihelper.axiosCall(
      `${api.baseURL}${api.assessmentController.getAdminOverallReport}${customer}`,
      "get"
    )
      .then((data) => {
        let temp = [];
        data.assessmentList.forEach((item) => {
          temp.push({
            id: item.id,
            assessmentName: item.name,
            studentsEnrolled: item.enrolled,
            completed: item.completed,
            attended: item.attended,
            notAttended: item.notAttended,
          });
        });
        let tempTwo = [];
        data.pacakgeList.forEach((item) => {
          tempTwo.push({
            id: item.id,
            packageName: item.name,
            studentsEnrolled: item.enrolled,
            completed: item.completed,
            started: item.started,
            notStarted: item.notStarted,
          });
        });
        setPageData({
          assessmentCount: data?.assessmentCount,
          ppCount: data?.packageCount,
          assessmentList: temp,
          packageList: tempTwo,
        });
      })
      .catch(() => {});
  }

  useEffect(() => {
    if (Priv && Priv.includes("MANAGE_CUSTOMER")) {
      props.dispatch(getCustomers());
    } else {
      getData(sessionStorage.getItem("customer_id"));
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // setDashboardData({ assesmentCount: null, ppCount: null });
    setPageData({
      assessmentCount: null,
      ppCount: null,
      assessmentList: [],
      packageList: [],
    });

    if (customerId) {
      getData(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (props?.location?.state?.customer) {
      setCustomerId(props?.location?.state?.customer);
    }
    if (props?.location?.state?.activePage) {
      setActivePage(props?.location?.state?.activePage);
    }
    if (props?.location?.state?.itemsPerPage) {
      setItemsPerPage(props?.location?.state?.itemsPerPage);
    }
  }, []);

  const tabList = [
    ...(Priv && Priv.includes("VIEW_STUDENT_ASSESSMENT_REPORT")
      ? [
          {
            name: "Assessments",
            id: "assessment",
            ...(pageData?.assessmentList?.length && {
              badge: pageData?.assessmentList?.length,
            }),
          },
        ]
      : []),
    ...(Priv && Priv.includes("VIEW_STUDENT_PRACTICE_PACKAGE_REPORT")
      ? [
          {
            name: "Practice Packages",
            id: "practice",
            ...(pageData?.packageList?.length && {
              badge: pageData?.packageList?.length,
            }),
          },
        ]
      : []),
  ];

  return (
    <>
    {(userPrivileges.isSuperAdmin || userPrivileges.isAdmin) && (
    <div className={cn("page-content", styles.page)}>
      <div className={styles.dashboard}>
        <div className={styles.dashboardInfoBox}>
          <div className={styles.dashboardInfoBoxTexts}>
            <div className={styles.dashboardInfoBoxTitle}>
              {pageData?.assessmentCount ?? "--"}
            </div>
            <div className={styles.dashboardInfoBoxDescription}>
              Assessments
            </div>
          </div>
          <div className={styles.dashboardInfoBoxIcon}>
            <span>{CustomIcons.notesIcon}</span>
          </div>
        </div>
        <div className={styles.dashboardInfoBox}>
          <div className={styles.dashboardInfoBoxTexts}>
            <div className={styles.dashboardInfoBoxTitle}>
              {pageData?.ppCount ?? "--"}
            </div>
            <div className={styles.dashboardInfoBoxDescription}>
              Practice Packages
            </div>
          </div>
          <div className={styles.dashboardInfoBoxIcon}>
            <span>{CustomIcons.diagramIcon}</span>
          </div>
        </div>
      </div>
      {Priv && Priv.includes("MANAGE_CUSTOMER") && (
        <>
          <div className={styles.customerSelectContainer}>
            <div style={{ width: 207 }}>
              <Select
                placeholder="Choose a customer"
                value={GetObjectFromString(customersList, customerId)}
                onChange={(e) => {
                  setCustomerId(e.value);
                  setActivePage(1);
                  setItemsPerPage(10);
                }}
                options={customersList}
                maxMenuHeight={120}
              />
            </div>
          </div>
          <br />
          <br />
        </>
      )}
      <Tabs
        activeTab={activeTab}
        contentClassName={styles.tabContent}
        onChange={setActiveTab}
        hideBadge={
          !Priv.includes("VIEW_STUDENT_ASSESSMENT_REPORT") ||
          !Priv.includes("VIEW_STUDENT_PRACTICE_PACKAGE_REPORT")
        }
        tabs={tabList}
      >
        {Priv.includes("VIEW_STUDENT_ASSESSMENT_REPORT") &&
          activeTab === "assessment" && (
            <CustomTable
              header="Assessment List"
              data={pageData?.assessmentList}
              dataLength={pageData?.assessmentList?.length}
              tableHeaders={[
                { label: "Assessment Name", val: "assessmentName" },
                { label: "Students Enrolled", val: "studentsEnrolled" },
                { label: "Completed", val: "completed" },
                { label: "Attended", val: "attended" },
                { label: "Not Attended", val: "notAttended" },
              ]}
              searchPlaceholder="Search..."
              overAllReport={overAllReport}
              customerId={customerId}
              activePage={activePage}
              itemsPerPage={itemsPerPage}
            />
          )}
        {Priv.includes("VIEW_STUDENT_PRACTICE_PACKAGE_REPORT") &&
          activeTab === "practice" && (
            <CustomTable
              header="Practice Packages List"
              data={pageData?.packageList}
              dataLength={pageData?.packageList?.length}
              tableHeaders={[
                { label: "Practice Package Name", val: "packageName" },
                { label: "Students Enrolled", val: "studentsEnrolled" },
                { label: "Completed", val: "completed" },
                { label: "Attended", val: "started" },
                { label: "Not Attended", val: "notStarted" },
              ]}
              searchPlaceholder="Search..."
              customerId={customerId}
              report={true}
              overAllReport={overAllReport}
            />
          )}
      </Tabs>
    </div>
    )}
    </>
  );
}

const mapStateToProps = (state) => ({
  Customers: state.StudentAndCustomerReducer.Customers,
});

export default connect(mapStateToProps, null)(withRouter(AdminOverallReport));
