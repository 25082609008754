import React, { useState, useEffect } from "react";
import api from "../../api/baseConfig";
import ApiHelper from "../../api/apiHelper";
import moment from "moment";
import CustomTable from "./CustomListTable";
import TotalCard from "pages/Ecommerce/EcommerceOrders/TotalCard";
import { Row, Col } from "reactstrap";
import Skeleton from "@material-ui/lab/Skeleton";
import { sessionPrivileges } from "privileges";

const offlineDot = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" fill="#CBCBD4" />
  </svg>
);

const onlineDot = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" fill="#1BA94C" />
  </svg>
);

const awayDot = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" fill="#EEB60C" />
  </svg>
);

const inActiveDot = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" fill="#FF5F17" />
  </svg>
);

export default function ActiveUsersList() {
  const userId = sessionPrivileges().userId;
  const userPrivileges = sessionPrivileges();
  const [loading, setLoading] = useState(false);
  // const Priv = sessionPrivileges().userprivileges || [];
  const [activeUsers, setActiveUsers] = useState([]);
  const [TotalActiveUsers, setTotalActiveUsers] = useState({
    totalOnline: "",
    totalAway: "",
    totalInactive: "",
    totalOffline: "",
  });

  useEffect(() => {
    getActiveUsers();
    window.scrollTo(0, 0);
  }, []);

  const getActiveUsers = async () => {
    setLoading(true);
    try {
      const data = await ApiHelper.axiosCall(
        `${api.baseURL}${api.assessmentController.activeUsersList}${userId}`,
        "get"
      );
      setLoading(false);
      let temp = [];
      data.loginUsers.forEach((item) => {
        temp.push({
          name: item.name,
          department: item.department,
          registerNo: item.registrationNo,
          email: item.emailId,
          status:
            item.status === "InActive" ? (
              <span style={{ color: "#FF5F17" }}>{inActiveDot} InActive</span>
            ) : item.status === "Offline" ? (
              <span style={{ color: "#A6A6AA" }}>{offlineDot} Offline</span>
            ) : item.status === "Away" ? (
              <span style={{ color: "#EEB60C" }}>{awayDot} Away</span>
            ) : (
              <span style={{ color: "#1BA94C" }}>{onlineDot} Online</span>
            ),
          startTime:
            item.loginTime || ""
              ? moment(item.loginTime).format("MMMM Do YYYY, h:mm A")
              : "",
        });
      });
      setActiveUsers(temp);
      setTotalActiveUsers({
        totalOnline: data.onlineUsersCount,
        totalAway: data.awayUsersCount,
        totalInactive: data.inactiveUsersCount,
        totalOffline: data.offlineUsersCount,
      });
    } catch (e) {}
  };

  return (
    <>
    {userPrivileges.showActiveUserList && (
    <div className="page-content">
      <Row className="mb-4">
        <Col>
          {!loading ? (
            <TotalCard
              title={TotalActiveUsers.totalOnline}
              description="Users Online"
              color="#1BA94C"
            />
          ) : (
            <Skeleton width={230} height={200} />
          )}
        </Col>
        <Col>
          {!loading ? (
            <TotalCard
              title={TotalActiveUsers.totalAway}
              description="Users Away"
              color="#EEB60C"
            />
          ) : (
            <Skeleton width={230} height={200} />
          )}
        </Col>
        <Col>
          {!loading ? (
            <TotalCard
              title={TotalActiveUsers.totalInactive}
              description="Users Inactive"
              color="#FF5F17"
            />
          ) : (
            <Skeleton width={230} height={200} />
          )}
        </Col>
        <Col>
          {!loading ? (
            <TotalCard
              title={TotalActiveUsers.totalOffline}
              description="Users Offline"
              color="#A6A6AA"
            />
          ) : (
            <Skeleton width={230} height={200} />
          )}
        </Col>
      </Row>
      {!loading ? (
        <CustomTable
          getActiveUsers={getActiveUsers}
          header="User Active Details"
          data={activeUsers}
          // showCustomers={Priv.includes("ADD_USER")}
          dataLength={activeUsers.length}
          tableHeaders={[
            { label: "Name", val: "name" },
            { label: "Department", val: "department" },
            { label: "Reg No", val: "registerNo" },
            { label: "Email", val: "email" },
            { label: "Status", val: "status" },
            { label: "Last Login Details", val: "startTime" },
          ]}
          searchPlaceholder="Search..."
        />
      ) : (
        <div>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </div>
      )}
    </div>
    )}
    </>
  );
}
