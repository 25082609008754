import React, { useState, useEffect } from "react";
import "./adminpage.scss";
import logo from "./chittilogo.svg";
import banner from "./bannerLogin.svg";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
  IconButton,
  Button as Button1,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  useMediaQuery,
  Grid,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { handleLoggedIn } from "store/loggedIn/actions";
import { getUserProfile } from "store/UserProfile/actions";
import { Button } from "reactstrap";
import qs from "query-string";
import moment from "moment";
import uuid from "react-uuid";
import ApiHelper from "api/apiHelper";
import api from "api/baseConfig";
import jwt from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { addToCart } from "pages/virtualLab/ViewPracticeQuestions";
import { useTranslation } from "react-i18next";
import { useLogoContext, handleLogoContext } from "context/logoContext";
import Cookies from "universal-cookie";
import { useLocation } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
// import {chittiLogo} from "./SVGs"

const useStyles = makeStyles((theme) => ({
  textField: {
    height: "50px",
    width: "400px",
  },
  input: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  textField1: {
    height: "50px",
    width: "190px",
    paddingBottom: "7px",
  },
  mobileTextField: {
    height: "50px",
    width: "160px",
    paddingBottom: "7px",
  },
  mobileTextField1: {
    height: "50",
    width: "80%",
  },
  formControl: {
    width: "190px",
    height: "50px",
  },
  selectroot: {
    width: "190px",
    height: "50px",
  },
  mobileSelectRoot: {
    width: "160px",
    height: "50px",
  },
  selectInput: {
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  selectDialogContainer: {
    "& .MuiPaper-root": {
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#784df5",
        borderRadius: "10px",
        margin: "0 10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
      },
    },
  },
  mobileSignInContainer: {
    "& .MuiDialog-paper": {
      margin: "0 !important",
      marginRight: "50%",
    },
    "& .MuiDialog-scrollPaper": {
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

const adminLogin = ({
  handleSignInModalOpen,
  signInQuestion,
  fromAssessment,
  assessmentId,
  pkgId,
  fromLab,
  buyPkg,
  buyPrepare,
  preapareId,
  openInstructions,
  ...props
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const search = useLocation().search;
  const paramToken = new URLSearchParams(search).get("token");
  const returnLink = new URLSearchParams(search).get("returnLink");
  const cookies = new Cookies();
  cookies.set("returnLink", `${paramToken && returnLink ? returnLink : ""}`, {
    path: "/",
  });
  const tokenValue = paramToken
    ? paramToken
    : cookies.get("userToken", { path: "/" });
  const refreshTokenValue = paramToken
    ? ""
    : cookies.get("refreshToken", { path: "/" });
  const [userToken, setUserToken] = useState(tokenValue);
  const [refreshToken, setRefreshToken] = useState(refreshTokenValue);
  const [emailInput, setEmailInput] = useState("");
  const [pwdInput, setPwdInput] = useState({
    password: "",
    showPassword: false,
  });
  const changeLogo = handleLogoContext();
  const logoImage = useLogoContext();
  const [signUpFirstNameInput, setSignUpFirstNameInput] = useState("");
  const [signUpLastNameInput, setSignUpLastNameInput] = useState("");
  const [signUpEmailInput, setSignUpEmailInput] = useState("");
  const [signUpPwdInput, setSignUpPwdInput] = useState({
    password: "",
    showPassword: false,
  });
  const [signUpDobInput, setSignUpDobInpt] = useState("");
  const [signUpGenderInput, setSignUpGenderInput] = useState("");
  const [signUpMobNoInput, setSignUpMobNoInput] = useState();

  const isSmallScreen = useMediaQuery("(max-width: 960px)");
  const bannerScreen = useMediaQuery("(max-width: 420px)");
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState({ email: "", pwd: "" });
  const [hasAccount, setHasAccount] = useState(false);
  // const smallScreen = useMediaQuery("(max-width: 1023px)");

  useEffect(() => {
    if (props.head === "signup") {
      setHasAccount(true);
    }
  }, []);

  const showSignUp = props.setShowSignUP;

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();

  const handleSignUpPasswordChange = (prop) => (event) => {
    setSignUpPwdInput({ ...signUpPwdInput, [prop]: event.target.value });
  };

  const handleSignUpClickShowPassword = () => {
    setSignUpPwdInput({
      ...signUpPwdInput,
      showPassword: !signUpPwdInput.showPassword,
    });
  };

  const handleSignUpMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleGenderChange = (event) => {
    setSignUpGenderInput(event.target.value);
  };

  const handleCheckedChange = (event) => {
    setChecked(event.target.checked);
  };

  const moveToTermsAndCondition = () => {
    history.push("/termsAndConditions");
  };

  const loginUser = async (e, email, password) => {
    if (e) {
      e.preventDefault();
    }
    let req = assessmentId
      ? `grant_type=password&username=${email || emailInput}&password=${
          password ? btoa(password) : btoa(pwdInput.password)
        }&assessmentId=${assessmentId}`
      : pkgId
      ? `grant_type=password&username=${email || emailInput}&password=${
          password ? btoa(password) : btoa(pwdInput.password)
        }&labId=${pkgId}`
      : `grant_type=password&username=${email || emailInput}&password=${
          password ? btoa(password) : btoa(pwdInput.password)
        }`;
    try {
      const response = await ApiHelper.login(
        `${api.baseURL}${api.loginValidation}`,
        req
      );
      if (response.type === "success") {
        toast.info("Logging in!...", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      }
      if (response.type === "error") {
        if (response.data.data === "Incorrect password") {
          toast.info("Invalid Credential...", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        }
        if (response.data.data === "user not found") {
          toast.info(
            "Please, check your Email ID is correct or Create a new account...",
            {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            }
          );
        }
      }
      if (response.data.data.access_token)
        setUserToken(response.data.data.access_token);
      if (response.data.data.access_token)
        setRefreshToken(response.data.data.access_token);
      // const userToken = response.data.data.access_token;
      // const refreshToken = response.data.data.refresh_token;
    } catch (err) {}
  };

  const signUpUser = async (e) => {
    e.preventDefault();

    const email = signUpEmailInput;

    if (checked === true) {
      if (
        /^[a-zA-Z]*$/.test(signUpFirstNameInput) &&
        /^[a-zA-Z]*$/.test(signUpLastNameInput) &&
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
          signUpEmailInput
        )
      ) {
        let userDetails = {
          firstName: signUpFirstNameInput,
          lastName: signUpLastNameInput,
          emailId: signUpEmailInput,
          password: btoa(signUpPwdInput.password),
          userType: "learner",
          phoneNumber: signUpMobNoInput,
          dateOfBirth: signUpDobInput,
          gender: signUpGenderInput,
          customerId: "605b13d98443a50c4177d35d",
          updatedBy: signUpEmailInput,
          createdBy: signUpEmailInput,
        };

        await ApiHelper.signUpValidateUser(
          `${api.baseURL}${api.userManagement.checkUserEmail}${email}`
        ).then(async (response) => {
          if (response === true) {
            toast.error(
              "This email is already in use, Please try using different email Id...",
              {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: false,
              }
            );
          } else if (response === false) {
            ApiHelper.signup(
              `${api.baseURL}${api.userManagement.creatNewUser}`,
              userDetails
            )
              .then((res) => {
                toast.info("Successfully Signed Up!...", {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: false,
                });
                loginUser(null, email, signUpPwdInput.password);
              })

              .catch((e) => {
                toast.error("Oops.., Something went wrong...", {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: false,
                });
              });
          }
        });
      } else {
        toast.error(
          "Please check first & last name, email id are in correct format...",
          {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          }
        );
      }
    } else if (checked === false) {
      toast.info(
        "Please accept our terms and condition then press sign up...",
        {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        }
      );
    }
  };

  const handleClickShowPassword = () => {
    setPwdInput({ ...pwdInput, showPassword: !pwdInput.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(async () => {
    if (userToken) {
      const decoded = jwt(userToken);
      if (decoded && decoded.user_name) {
        const userData = await ApiHelper.axiosCallAuth(
          `${api.baseURL}${api.userManagement.getUserByEmail}${decoded.user_name}`
        );
        const userId = userData.id;
        if (userId) {
          localStorage.removeItem("IsPartnerUser");
          cookies.set("userToken", userToken, { path: "/" });
          cookies.set("refreshToken", refreshToken, {
            path: "/",
          });
          cookies.set("userId", userId, { path: "/" });
          const headerState = {
            state: true,
            dashboard: !!decoded?.authorities?.includes("LEARNER_PROFILE"),
            student: !!decoded?.authorities?.includes("LEARNER_PROFILE"),
            admin:
              !decoded?.authorities.includes("MANAGE_CUSTOMER") &&
              !decoded?.authorities.includes("GET_STUDENT_DASHBOARD"),
            prepare:
              !!decoded?.authorities?.includes("LEARNER_PROFILE") &&
              !!decoded?.authorities?.includes("VIEW_ASSESSMENT"),
            assessment:
              !!decoded?.authorities?.includes("LEARNER_PROFILE") &&
              !!decoded?.authorities?.includes("VIEW_ASSESSMENT"),
            practice:
              !!decoded?.authorities?.includes("LEARNER_PROFILE") &&
              !!decoded?.authorities?.includes("VIEW_PRACTICE_PACKAGE"),
            canViewProfile:
              !!decoded?.authorities?.includes("GET_VIEW_PROFILE"),
            canViewCart: !!decoded?.authorities?.includes("VIEW_CART"),
          };
          dispatch(handleLoggedIn(headerState));
          sessionStorage.setItem("user_id", decoded.userId);
          const session_id = uuid();
          sessionStorage.setItem("session_id", session_id);
          await ApiHelper.axiosCallPost(
            `${api.baseURL}terv/saveLoginActivity`,
            "post",
            {
              emailId: decoded.user_name,
              sessionId: session_id,
              name: "",
              loginTime: moment().format("yyyy-MM-DDTHH:mm:ss"),
            }
          )
            .then(async () => {
              const assessmentId = qs.parse(
                window.location.search
              )?.assessmentId;
              if (buyPkg) {
                dispatch(getUserProfile(decoded.user_name));

                history.push({
                  pathname: "/checkout",
                  params: { items: pkgId },
                });
              } else if (buyPrepare) {
                dispatch(getUserProfile(decoded.user_name));

                history.push({
                  pathname: "/checkout",
                  params: { items: preapareId, productType: "course" },
                });
              } else if (assessmentId) {
                window.location.href = `/taketest?id=${assessmentId}&userId=${decoded.userId}`;
              } else if (props?.goToStep3) {
                props?.setModal(false);
                props?.goToStep3();
              } else if (
                decoded?.authorities?.includes("LEARNER_PROFILE") &&
                pkgId
              ) {
                sessionStorage.setItem("viewPkgWithoutSignIn", "false");
                if (signInQuestion?.id) {
                  dispatch(getUserProfile(decoded.user_name));
                  history.push({
                    pathname: `/practice-lab`,
                    params: {
                      questionId: signInQuestion.id,
                      path: signInQuestion.path,
                    },
                    state: {
                      fromEdittrue: true,
                      id: signInQuestion.id,
                    },
                  });
                } else if (fromAssessment || fromLab) {
                  openInstructions();
                  if (fromLab) {
                    dispatch(getUserProfile(decoded.user_name));
                    // dispatch(handleLoggedIn(headerState));
                  }
                }
              } else {
                if (decoded?.authorities?.includes("MENTOR_PROFILE")) {
                  if (decoded?.authorities?.includes("VIEW_STUDENTS_REPORT")) {
                    window.location.pathname = "/students-report";
                  } else {
                    window.location.pathname = "/activeUsersList";
                  }
                } else if (decoded?.authorities?.includes("ADMIN_ROLE")) {
                  if (decoded?.authorities?.includes("GET_ADMIN_DASHBOARD")) {
                    window.location.pathname = "/dashboard";
                  } else {
                    window.location.pathname = "/activeUsersList";
                  }
                } else if (decoded?.authorities?.includes("LEARNER_PROFILE")) {
                  if (decoded?.authorities?.includes("GET_STUDENT_DASHBOARD")) {
                    // if (window.location.search.includes("id")) {
                    //   window.location.href = `/taketest${window.location.search}&${decoded.userId}`;
                    // } else {
                    //   window.location.pathname = "/dashboard";
                    // }
                    window.location.pathname = "/dashboard";
                  } else if (
                    decoded?.authorities?.includes("VIEW_ASSESSMENT")
                  ) {
                    window.location.pathname = "/assessment";
                  } else if (
                    decoded?.authorities?.includes("VIEW_PRACTICE_PACKAGE")
                  ) {
                    window.location.pathname = "/lab";
                  }
                } else {
                  window.location.pathname = "/prepare";
                }
              }
            })
            .catch((e) => {
              window.location.pathname = "/login";
            });
        }
      }
    }
  }, [userToken]);

  return (
    <div style={{ height: "100%" }}>
      {paramToken ? (
        <div className="loading">
          <CircularProgress />
          <p>Loading....</p>
        </div>
      ) : isSmallScreen ? (
        <div>
          <Grid item xs={6}>
            <div
              style={{
                background: "#5138ED",
                width: "100%",
                height: "50%",
                position: "fixed",
                top: "0px",
              }}
            >
              <div className={bannerScreen ? "MobSideBanner" : "sideBanner"}>
                <div style={{ paddingTop: "50%" }}>
                  <div className="flexLogo">
                    <div
                      style={{
                        background: "#ffffff",
                        border: "1px solid #ffffff",
                        borderRadius: "1%",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25",
                        width: "90%",
                        textAlign: "center",
                      }}
                    >
                      <img src={logo} alt="logo" width="160px" height="105px" />
                      {bannerScreen ? (
                        <p
                          //  className="pt-4"
                          style={{
                            color: "#7D7D7D",
                            fontSize: "20px",
                            fontFamily: "poppins",
                            fontStyle: "normal",
                            textAlign: "left",
                            fontWeight: "500",
                            paddingLeft: "10%",
                          }}
                        >
                          Manage everything on your admin portal
                        </p>
                      ) : (
                        <p
                          className="pt-4"
                          style={{
                            color: "#7D7D7D",
                            fontSize: "24px",
                            fontFamily: "poppins",
                            fontStyle: "normal",
                            textAlign: "center",
                            fontWeight: "500",
                          }}
                        >
                          Manage everything on your admin portal
                        </p>
                      )}

                      <div
                        style={{
                          // background: "#ffffff",
                          // border: "1px solid #ffffff",
                          // borderRadius: "1%",
                          // boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25",
                          // width: "90%",
                          textAlign: "center",
                        }}
                      >
                        <form>
                          <div className="py-4">
                            <TextField
                              required
                              label={t("Email")}
                              variant="outlined"
                              value={emailInput || ""}
                              InputLabelProps={{
                                style: {
                                  fontSize: "18px",
                                  fontFamily: "Poppins",
                                },
                              }}
                              classes={{
                                // root: `${
                                //   isSmallScreen
                                //     ? classes.mobileTextField1
                                //     : classes.textField
                                // } `,
                                root: `${classes.mobileTextField1} `,
                                //   ${styles.textField}`,
                              }}
                              onChange={(e) => setEmailInput(e.target.value)}
                              onBlur={() => {
                                !emailInput ||
                                !/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
                                  emailInput
                                )
                                  ? setErrors({
                                      ...errors,
                                      email: t("Please enter a valid email id"),
                                    })
                                  : setErrors({
                                      ...errors,
                                      email: "",
                                      pwd: "",
                                    });
                              }}
                              color={errors.email && `red`}
                              error={errors.email}
                              helperText={errors.email && errors.email}
                            />
                          </div>
                          <div className="py-4">
                            <TextField
                              required
                              label={t("Password")}
                              variant="outlined"
                              type={pwdInput.showPassword ? "text" : "password"}
                              value={pwdInput.password || ""}
                              InputLabelProps={{
                                style: {
                                  fontSize: "18px",
                                  fontFamily: "Poppins",
                                },
                              }}
                              classes={{
                                // root: `${
                                //   isSmallScreen
                                //     ? classes.mobileTextField1
                                //     : classes.textField
                                // } `,
                                root: `${classes.mobileTextField1} `,
                                //   ${styles.textField}`,
                              }}
                              onChange={(e) =>
                                setPwdInput({
                                  ...pwdInput,
                                  password: e.target.value,
                                })
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {pwdInput.showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              onBlur={() => {
                                !pwdInput.password
                                  ? setErrors({
                                      ...errors,
                                      pwd: t("Please enter a valid password"),
                                    })
                                  : setErrors({
                                      ...errors,
                                      pwd: "",
                                      email: "",
                                    });
                              }}
                              color={errors.pwd && `red`}
                              error={errors.pwd}
                              helperText={errors.pwd && errors.pwd}
                            />
                          </div>
                        </form>
                      </div>
                      <br />
                    </div>
                    <br />
                    <br />
                    <div className="py-4">
                      <button
                        disabled={emailInput && pwdInput ? false : true}
                        className="MobloginButton"
                        onClick={loginUser}
                      >
                        Login
                      </button>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </div>
      ) : (
        <div style={{ height: "100%" }}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <div
                style={{
                  background: "#5138ED",
                  width: "50%",
                  height: "100vh",
                  position: "fixed",
                  top: "0px",
                }}
              >
                <div className="sideBanner">
                  <div className="flexLogo">
                    <div
                      style={{
                        background: "#ffffff",
                        border: "1px solid #ffffff",
                        borderRadius: "50%",
                        width: "183px",
                      }}
                    >
                      <img src={logo} alt="logo" width="180px" height="175px" />
                    </div>
                    <p
                      className="pt-4"
                      style={{
                        color: "#ffffff",
                        fontSize: "30px",
                        width: "60%",
                        textAlign: "center",
                      }}
                    >
                      Manage everything on your admin portal
                    </p>
                  </div>
                  {/* <img src={banner} alt="logo" width="1050px" height="1045px"/> */}
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="flexForm px-5" style={{}}>
                <div
                  style={{
                    textAlign: "center",
                    margin: "24px 0",
                  }}
                >
                  <img
                    src={logo}
                    alt="logo"
                    style={{ height: "100%", maxHeight: "120px" }}
                  />
                </div>
                <div className="pt-3 text-center">
                  <h1 style={{ fontSize: "24px" }}>Welcome</h1>
                  <p>Enter your details and login</p>
                </div>
                <div className="pt-2 text-center">
                  <form onSubmit={loginUser}>
                    <div className="py-4">
                      <TextField
                        required
                        label={t("Email")}
                        variant="outlined"
                        value={emailInput || ""}
                        InputLabelProps={{
                          style: { fontSize: "18px", fontFamily: "Poppins" },
                        }}
                        classes={{
                          // root: `${
                          //   isSmallScreen
                          //     ? classes.mobileTextField1
                          //     : classes.textField
                          // } `,
                          root: `${classes.textField} `,
                          //   ${styles.textField}`,
                        }}
                        onChange={(e) => setEmailInput(e.target.value)}
                        onBlur={() => {
                          !emailInput ||
                          !/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
                            emailInput
                          )
                            ? setErrors({
                                ...errors,
                                email: t("Please enter a valid email id"),
                              })
                            : setErrors({ ...errors, email: "", pwd: "" });
                        }}
                        color={errors.pwd ? "error" : "primary"}
                        error={errors.pwd ? true : false}
                        helperText={errors.pwd}
                      />
                      {/* <div className="pb-2 pt-2 text-left">
                    {errors.email && (
                      <span style={{ color: "error" }}>{errors.email}</span>
                    )}
                  </div> */}
                    </div>
                    <div className="py-4">
                      <TextField
                        required
                        label={t("Password")}
                        variant="outlined"
                        type={pwdInput.showPassword ? "text" : "password"}
                        value={pwdInput.password || ""}
                        InputLabelProps={{
                          style: { fontSize: "18px", fontFamily: "Poppins" },
                        }}
                        classes={{
                          // root: `${
                          //   isSmallScreen
                          //     ? classes.mobileTextField1
                          //     : classes.textField
                          // } `,
                          root: `${classes.textField} `,
                          //   ${styles.textField}`,
                        }}
                        onChange={(e) =>
                          setPwdInput({ ...pwdInput, password: e.target.value })
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {pwdInput.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onBlur={() => {
                          !pwdInput.password
                            ? setErrors({
                                ...errors,
                                pwd: t("Please enter a valid password"),
                              })
                            : setErrors({ ...errors, pwd: "", email: "" });
                        }}
                        color={errors.pwd ? "error" : "primary"}
                        error={errors.pwd ? true : false}
                        helperText={errors.pwd}
                      />
                      {/* <div className="pb-2 pt-2">
                    {errors.pwd && (
                      <span style={{ color: "red" }}>{errors.pwd}</span>
                    )}
                  </div> */}
                    </div>
                    <div className="py-4">
                      <button
                        disabled={emailInput && pwdInput ? false : true}
                        className="loginButton"
                      >
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default adminLogin;
