import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Profile from "../../assets/images/users/default-avatar.svg";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import ReactHtmlParser from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));
export default function CourseContent(props) {
  const classes = useStyles();
  return (
    <>
      <h2 className="sec-title">Instructor Information</h2>
      {/* <ListItem>
        <ListItemAvatar>
          <Avatar alt={"name"} src={Profile} className={classes.large} />
        </ListItemAvatar>
        <ListItemText>
          <strong style={{paddingLeft: 20}}>
            {" "}
            &nbsp;
            {props.courseInstruction.authorName || "Author Name"}
          </strong>
        </ListItemText>
      </ListItem> */}
      <div className="instructor-details">
        {ReactHtmlParser(props.courseInstruction.instructorDetails || "")}
      </div>
    </>
  );
}
