import { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import {
  pendingDots,
  penIcon,
  circleCheckSolid,
  outlinedEye,
} from "assets/icons/icons";
import { Button, Table } from "reactstrap";
import { SortData } from "../../utils/SortData";
import CustomSearch from "../../Tasks/CustomSearch";
import Pagination from "react-js-pagination";
import { paginationClasses } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { uid } from "react-uid";
import Select from "react-select";
import moment from "moment";
import GradingSummaryModal from "./GradingSummaryModel";
import { GetAssementReview } from "./GetAssessmentReview";
import { GetTotalMarks } from "./GetTotalMarks";
import { parsePublishDetails } from "./parsePublishDetails";

const arrowStyle = {
  color: "#adb5bd",
  cursor: "pointer",
};
const entriesCount = [5, 10, 15, 20];

const filterOptions = [
  { label: "All", value: "all" },
  { label: "Completed", value: "completed" },
  { label: "Published", value: "published" },
  { label: "Pending", value: "pending" },
  { label: "Not Attended", value: "notattended" },
];

const createIcontext = (icon, text, color) => (
  <span
    style={{
      color: `#${color}`,
      fontWeight: 550,
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "0.1rem",
    }}
  >
    <span style={{ marginBottom: "0.2rem" }}>
      {icon({ size: 16, color: `#${color}` })}
    </span>
    &nbsp;
    <span>{text}</span>
  </span>
);

const pendingSpan = createIcontext(pendingDots, "Pending", "BB4C06");

const publishedSpan = createIcontext(circleCheckSolid, "Published", "059135");

const ManualGradingTable = ({
  data,
  changeData,
  tableHeaders,
  assessmentName = "Assessment",
  batchName,
  completedOn,
  getAssessmentGradingStatus,
}) => {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [downArrow, setDownArrow] = useState(false);
  const [publishModal, setPublishModal] = useState({
    state: false,
    type: "summary",
  });
  const [assessmentDetails, setAssessmentDetails] = useState({
    questionsAnswered: "",
    questionsGraded: "",
    rightAnswers: "",
    wrongAnswers: "",
  });
  const history = useHistory();
  const toggleArrow = () => setDownArrow((prev) => !prev);

  const sortData = (value, sortType) => {
    if (value && data.real?.studentList?.length > 0) {
      const sortArray = SortData(data.real.studentList, value, sortType);
      changeData({ ...data, studentList: sortArray });
    }
  };

  const searchData = (query) => {
    let arr = [];
    if (query && query.length > 0) {
      data.real.studentList.forEach((e) => {
        const objArr = Object.keys(e);
        for (let i = 0; i < objArr.length; i++) {
          if (
            e[objArr[i]] &&
            typeof e[objArr[i]] === "string" &&
            e[objArr[i]].toLowerCase().includes(query.toLowerCase())
          ) {
            arr.push(e);
            break;
          }
        }
      });
      if (arr.length > 0) {
        changeData({ ...data, studentList: arr });
      }
    } else {
      changeData(data.real);
    }
  };

  const handleFilterChange = (value) => {
    switch (value.value) {
      case "all": {
        changeData(data.real);
        break;
      }
      case "completed": {
        changeData(
          (data.real?.studentList || []).reduce(
            (acc, val) => {
              if (val.manualGradingCompleted && !val.manualGradingPublished) {
                acc["studentList"].push(val);
              }
              return acc;
            },
            { ...data.real, studentList: [] }
          )
        );
        break;
      }
      case "pending": {
        changeData(
          (data.real?.studentList || []).reduce(
            (acc, val) => {
              if (!val.manualGradingCompleted && !val.manualGradingPublished && val.startTime)
               {
                acc["studentList"].push(val);
              }
              return acc;
            },
            { ...data.real, studentList: [] }
          )
        );
        break;
      }
      case "published": {
        changeData(
          (data.real?.studentList || []).reduce(
            (acc, val) => {
              if (val.manualGradingPublished) {
                acc["studentList"].push(val);
              }
              return acc;
            },
            { ...data.real, studentList: [] }
          )
        );
        break;
      }
      case "notattended": {
        changeData(
          (data.real?.studentList || []).reduce(
            (acc, val) => {
              if (!val.startTime) {
                acc["studentList"].push(val);
              }
              return acc;
            },
            { ...data.real, studentList: [] }
          )
        );
        break;
      }
    }
  };

  const getAssessmentDetails = async (userId, userName) => {
    try {
      const res = await GetAssementReview(userId, data.real?.id);
      if (res) {
        setAssessmentDetails({
          ...parsePublishDetails(res),
          rightAnswers: res.rightAnswerCount,
          wrongAnswers: res.wrongAnswerCount,
          marks: GetTotalMarks(res),
          total: res.TotalMarks,
          assessmentId: data.real?.id,
          studentId: userId,
          studentName: userName,
        });
      }
    } catch (e) {}
  };

  const togglePublishModal = (id, name, type = "summary") => {
    if (id && name) {
      getAssessmentDetails(id, name);
    }
    setPublishModal({ state: !publishModal.state, type });
  };

  const getFirstPendingStudent = () => {
    const student = (data.real?.studentList || []).find(
      (stu) => stu.startTime && stu.manualGradingCompleted === false
    );
    return {
      assessmentName: data.real?.assessmentName,
      department: data.real?.department,
      completedOn: data.real?.completedOn,
      userId: student?.userId,
      id: student?.id,
      startTime: student?.startTime,
      assessmentId: data.real?.id,
      graded: student?.manualGradingCompleted,
      published: student?.manualGradingPublished,
      studentName: `${student?.firstName || ""} ${student?.lastName || ""}`,
      allStudents: (data.real?.studentList || []).map((student) => ({
        id: student?.userId,
        name: `${student?.firstName || ""} ${student?.lastName || ""}`,
        startTime: student?.startTime,
        graded: student?.manualGradingCompleted,
        published: student?.manualGradingPublished,
        completedOn: student?.endTime,
        assessmentName: data.real?.assessmentName,
      })),
    };
  };

  const lastIndex = activePage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  return (
    <Paper>
      <div
        style={{
          padding: 20,
          position: "relative",
          borderBottom: "1px solid lavender",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", gap: "0.7rem", alignItems: "center" }}>
            <div>
              <h4 style={{ color: "#000" }}>{assessmentName}</h4>
            </div>
            <div>
              <h5>{batchName}</h5>
            </div>
            <div>
              {" "}
              <h5>Completed on {moment.utc(completedOn).format("LLL")}</h5>
            </div>
          </div>
          <Button
            color="success"
            outline
            style={{
              fontSize: "0.9rem",
              fontWeight: 500,
            }}
            onClick={() =>
              history.push({
                pathname: "/resume-grading",
                state: getFirstPendingStudent(),
              })
            }
            disabled={!getFirstPendingStudent()?.userId}
          >
            Resume Grading
          </Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          padding: 15,
          paddingBottom: 5,
        }}
      >
        <CustomSearch
          placeholder="Search"
          secondary
          handlesearch={searchData}
        />
        <Select
          options={filterOptions}
          onChange={handleFilterChange}
          styles={{ control: (pre) => ({ ...pre, width: "20rem" }) }}
          placeholder="Filter"
        />
        <Button
          color="primary"
          style={{ fontSize: "0.9rem" }}
          onClick={togglePublishModal.bind(this, null, null, "publish")}
        >
          Publish
        </Button>
      </div>
      <div style={{ backgroundColor: "#fff", padding: 10 }}>
        <Table striped>
          <thead style={{ background: "#C5EAFC" }}>
            <tr>
              <th style={{ color: "#000" }}>S.No</th>
              {tableHeaders.map((item, ind) => (
                <th
                  style={{
                    color: "#000",
                    fontSize: "0.9rem",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                  key={uid(ind)}
                >
                  {item.label}
                  {downArrow ? (
                    <KeyboardArrowDown
                      style={arrowStyle}
                      onClick={() => {
                        sortData(item.val, "dsc");
                        toggleArrow();
                      }}
                    />
                  ) : (
                    <KeyboardArrowUp
                      style={arrowStyle}
                      onClick={() => {
                        sortData(item.val, "asc");
                        toggleArrow();
                      }}
                    />
                  )}
                </th>
              ))}
              <th
                style={{
                  color: "#000",
                  fontSize: "0.9rem",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.copy?.studentList &&
              data.copy.studentList.length > 0 &&
              data.copy.studentList
                .slice(firstIndex, lastIndex)
                .map((datum, index) => (
                  <tr key={uid(index)}>
                    <td>{index + 1}</td>
                    {tableHeaders.map((item, ind) => (
                      <td
                        key={uid(ind)}
                        style={{ color: "#000", textAlign: "left" }}
                      >
                        {item.val === "firstName" ? (
                          `${datum["firstName"]} ${datum["lastName"]}`
                        ) : item.val === "manualGradingCompleted" ? (
                          datum["manualGradingPublished"] ? (
                            publishedSpan
                          ) : datum[item.val] ? (
                            <span
                              style={{
                                fontWeight: 550,
                              }}
                            >
                              Completed
                            </span>
                          ) : datum["startTime"] ? (
                            pendingSpan
                          ) : (
                            <span style={{ color: "#A6A6AA" }}>
                              Not attended
                            </span>
                          )
                        ) : item.val === "startTime" ||
                          item.val === "endTime" ? (
                          moment(datum[item.val]).format("LLL") ===
                          "Invalid date" ? (
                            "-"
                          ) : (
                            moment(datum[item.val]).format("LLL")
                          )
                        ) : (
                          datum[item.val]
                        )}
                      </td>
                    ))}
                    <td style={{ textAlign: "center" }}>
                      {datum["manualGradingPublished"] ? (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            history.push({
                              pathname: "/resume-grading",
                              state: {
                                assessmentName: data?.real?.assessmentName,
                                department: data?.real?.department,
                                completedOn: data?.real?.completedOn,
                                userId: datum?.userId || "",
                                id: datum?.id || "",
                                assessmentId: data?.real?.id,
                                graded: data.real?.studentList?.map((student) => ({
                                  graded: student?.manualGradingCompleted
                                })),
                                published: data.real?.studentList?.map((student) => ({
                                  published: student?.manualGradingPublished
                                })),
                                studentName: `${datum?.firstName || ""} ${
                                  datum?.lastName || ""
                                }`,
                                allStudents: data.real?.studentList?.map(
                                  (student) => ({
                                    id: student?.userId,
                                    name: `${student?.firstName || ""} ${
                                      student?.lastName || ""
                                    }`,
                                    startTime: student?.startTime,
                                    graded: student?.manualGradingCompleted,
                                    published: student?.manualGradingPublished,
                                    completedOn: student?.endTime,
                                    assessmentName: data.real?.assessmentName,
                                  })
                                ),
                                type: "viewOnly",
                              },
                            })
                          }}
                        >
                          {outlinedEye}
                        </span>
                      ) : datum["manualGradingCompleted"] ? (
                        <Button
                          color="primary"
                          outline
                          style={{ fontWeight: 500, fontSize: "0.9rem" }}
                          onClick={togglePublishModal.bind(
                            this,
                            datum.id,
                            `${datum.firstName} ${datum?.lastName}`,
                            "summary"
                          )}
                        >
                          Publish
                        </Button>
                      ) : (
                        <span
                          style={{
                            color: "#51B960",
                            cursor: "pointer",
                            display: datum["startTime"] ? "inline" : "none",
                          }}
                          onClick={() =>
                            history.push({
                              pathname: "/resume-grading",
                              state: {
                                assessmentName: data?.real?.assessmentName,
                                department: data?.real?.department,
                                completedOn: data?.real?.completedOn,
                                userId: datum?.userId || "",
                                id: datum?.id || "",
                                assessmentId: data?.real?.id,
                                graded: data.real?.studentList?.map((student) => ({
                                  graded: student?.manualGradingCompleted
                                })),
                                published: data.real?.studentList?.map((student) => ({
                                  published: student?.manualGradingPublished
                                })),
                                studentName: `${datum?.firstName || ""} ${
                                  datum?.lastName || ""
                                }`,
                                allStudents: data.real?.studentList?.map(
                                  (student) => ({
                                    id: student?.userId,
                                    name: `${student?.firstName || ""} ${
                                      student?.lastName || ""
                                    }`,
                                    startTime: student?.startTime,
                                    graded: student?.manualGradingCompleted,
                                    published: student?.manualGradingPublished,
                                    completedOn: student?.endTime,
                                    assessmentName: data.real?.assessmentName,
                                  })
                                ),
                              },
                            })
                          }
                        >
                          {penIcon("#51B960")}
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
        <div
          style={{
            borderTop: "1px solid lavender",
            padding: 20,
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            Showing{" "}
            <select
              style={{
                height: 30,
                width: 100,
                borderRadius: 5,
                backgroundColor: "#F5F5F5",
                border: "1px solid lavender",
              }}
              onChange={(e) => {
                let val = +e.target.value.match(/\d+/g);
                setItemsPerPage(val);
              }}
            >
              {entriesCount.map((count, ind) => (
                <option
                  key={uid(ind)}
                  selected={
                    !data.real?.studentList?.length
                      ? 0
                      : itemsPerPage === count
                      ? count
                      : ""
                  }
                >
                  {count}
                </option>
              ))}
            </select>{" "}
            out of {data.real?.studentList?.length} entries
          </div>
          <div
            style={{
              marginRight: 10,
            }}
          >
            <Pagination
              {...paginationClasses}
              activePage={activePage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={data.real?.studentList?.length}
              pageRangeDisplayed={5}
              onChange={setActivePage}
            />
          </div>
        </div>
      </div>
      <GradingSummaryModal
        isOpen={publishModal.state}
        close={togglePublishModal}
        type={publishModal.type}
        data={data.real?.studentList || []}
        getAssessmentGradingStatus={getAssessmentGradingStatus}
        assessmentDetails={assessmentDetails}
        from="table"
      />
    </Paper>
  );
};

export default ManualGradingTable;
