const KEYS = {
  MC: {
    key: "MC",
    deepKeyOne: "optionIdentifier",
    deepKeyTwo: "answer",
  },
  CD: {
    key: "CD",
    deepKeyOne: "inputText",
    deepKeyTwo: "outputText",
  },
  DESC: {
    key: "DESC",
    deepKeyOne: "answerKey",
    deepKeyTwo: "answerKeyPoints",
  },
};

let Hash = {},
  Array = [];

export function DisableCheck(
  type,
  hintModal,
  hintValue,
  hints,
  editState,
  options,
  opValue,
  solutionModal,
  solValue,
  solutions
) {
  Hash = opValue;
  Array = options;
  let disable = false;
  if (editState) {
    hintModal
      ? (disable = hintValue["desc"] !== hints[hintValue.id]["desc"])
      : solutionModal
      ? (disable = solValue.val !== solutions[solValue.id]["val"])
      : (disable = checks(KEYS[type], "EDIT"));
  } else {
    hintModal
      ? (disable = hintValue?.desc?.trim()?.length > 0 ? true : false)
      : solutionModal
      ? (disable = solValue.val && solValue.lang.value)
      : (disable = checks(KEYS[type], "NEW"));
  }
  return disable;
}

const checks = ({ key, deepKeyOne, deepKeyTwo }, type) => {
  let boo;
  if (type === "NEW") {
    key === "MC"
      ? (boo = Hash[key][deepKeyOne] ? true : false)
      : (boo = Hash[key][deepKeyOne] && Hash[key][deepKeyTwo]);
  } else {
    boo =
      Hash[key][deepKeyOne] !== Array[Hash[key].id][deepKeyOne] ||
      Hash[key][deepKeyTwo] !== Array[Hash[key].id][deepKeyTwo];
  }
  return boo;
};
