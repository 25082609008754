import React from "react";

export default function JoinCommunityBox() {
  return (
    <>
      <div
        style={{
          margin: "0px",
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          color: "rgb(242 251 247/1)",
          fontFamily: "Manrope, sans-serif",
          fontSize: "2rem",
          fontWeight: 800,
          font: "800 32px / 44px Manrope, sans-serif",
          lineHeight: 1.375,
          textAlign: "center",
          textTransform: "capitalize",
        }}
      >
        Join A Community Of 10000+ Tech Professionals
      </div>
      <p
        className="mb-10 leading-loose sm:px-44 text-v5-neutral-100 font-light"
        style={{
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          overflowWrap: "break-word",
          margin: "0px",
          marginBottom: "2.5rem",
          fontWeight: 300,
          lineHeight: 2,
          color: "rgb(255 255 255/1)",
          paddingLeft: "11rem",
          paddingRight: "11rem",
          textAlign: "center",
        }}
      >
        <strong
          className="font-bold"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            fontWeight: 700,
          }}
        >
          Meet and grow together
        </strong>{" "}
        with a community of passionate developers who have taken their careers
        to new heights with Terv.
      </p>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  margin: 0px;
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  tab-size: 4;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}

body {
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizelegibility;
  overflow-y: auto;
  min-height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  line-height: inherit;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}
`,
        }}
      />
    </>
  );
}