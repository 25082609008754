import { sessionPrivileges } from "privileges";
import * as yup from "yup";
import { CheckIfCourseNameExists } from "../../Prepare/Apis";

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);
const currentYear = new Date().getFullYear(); // 2020
const previousYear = currentYear - 1;

const isSA = sessionPrivileges().isSuperAdmin;

export const Form1Schema = (isEdit, editData, regPublic, project) =>
  yup.object().shape({
    name: yup
      .string()
      .required("Please enter a valid name")
      .test(
        "checkDuplCourseName",
        "This name is already in use. Please enter a different name",
        async (value) => {
          if (!value) return true;
          else if (isEdit && value === editData?.name) return true;
          const res = await CheckIfCourseNameExists(value);
          return !res;
        }
      ),
    description: yup.string().required("Please enter a valid description"),
    isEdit: yup.boolean(),
    hackathonStartDate: yup
      .date()
      .nullable()
      .typeError("Please enter a valid start date")
      .min(
        yup.ref("registrationStartDate"),
        "Hackathon start date can't be before registration start date"
      ),
    hackathonEndDate: yup
      .date()
      .nullable()
      .typeError("Please enter a valid end date")
      .min(
        yup.ref("hackathonStartDate"),
        "End date can't be before start date"
      ),
    registrationStartDate: yup
      .date()
      .nullable()
      .typeError("Please enter a valid start date")
      .min(isEdit ? previousYear : yesterday, "Start date must be after today"),
    // .max(yup.ref("hackathonStartDate"),"registraion start date can't be before hackathon start date"),
    registrationEndDate: yup
      .date()
      .nullable()
      .typeError("Please enter a valid end date")
      .min(
        yup.ref("registrationStartDate"),
        "End date can't be before start date"
      ),
    registrationType: yup
      .string()
      .nullable()
      .required("Please select any one of registration type"),
    type: yup
      .string()
      .nullable()
      .required("Please select any one of hackathon type"),
    customerId:
      isSA && yup.string().nullable().required("Please select customer"),
    cardImage: yup.string().required("Please select card image"),
    assessmentId:
      project == "false" && yup.string().required("please select assessmentId"),
    userIdList:
      regPublic == "false" &&
      yup.array().nullable().required("please select student"),
  });

export const Form3Schema = (data, regType) =>
  yup.object().shape({
    overview: yup
      .string()
      .required("Please enter valid overviews")
      .test(
        "len",
        "Please enter valid overviews",
        (val) => !val.replace(/<(.|\n)*?>/g, "").trim().length < 1
      ),
    details:
      regType == "Project" &&
      yup
        .string()
        .required("Please enter valid details")
        .test(
          "len",
          "Please enter valid requirements",
          (val) => !val.replace(/<(.|\n)*?>/g, "").trim().length < 1
        ),
    challengeDescription:
      regType == "Challenge" &&
      yup
        .string()
        .required("Please enter valid challenge description")
        .test(
          "len",
          "Please enter valid challengeDescription",
          (val) => !val.replace(/<(.|\n)*?>/g, "").trim().length < 1
        ),
  });

export const Form4Schema = yup.object().shape({
  participantType: yup
    .string()
    .nullable()
    .required("Please select any one of participant type"),
  prizeType: yup
    .string()
    .nullable()
    .required("Please select any one of Prize type"),
  firstPrize: yup.string().nullable().required("Must enter a first prize"),
  secondPrize: yup.string().nullable().required("Must enter a second prize"),
  thirdPrize: yup.string().nullable().required("Must enter a third prize"),
});

export const SubmissionSchema = yup.object().shape({
  projectName: yup.string().required("Please enter a Project name"),
  projectDescription: yup
    .string()
    .required("Please enter a Project description")
    .test(
      "len",
      "Please enter a Project description",
      (val) => val !== "<p><br></p>"
    ),
  submissionFile: yup
    .array()
    .nullable()
    .required("submission file is required"),
});
