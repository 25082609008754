import { useState } from 'react';
import {
  Collapse,
  Grid,
  Typography,
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { TProps } from './collapsible-summary-section.props';

export function CollapsibleSummarySection(props: React.PropsWithChildren<TProps>) {
  const [open, setOpen] = useState(true);

  return (
    <>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent={props.smallScreen ? 'space-between' : 'center'}
        style={{
          ...props.smallScreen
            ? {
              backgroundColor: '#fffd',
            }
            : {
              position: 'sticky',
              top: 0,
              zIndex: 10,
            },
          cursor: 'pointer',
          padding: '0 16px',
          width: '100%',
        }}
        onClick={() => {
          setOpen((o) => (!o));
        }}>
        <Typography
          align={(props.smallScreen || props.alignTitleLeft)
            ? 'left'
            : 'center'
          }
          noWrap
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: (props.smallScreen || props.alignTitleLeft) ? 'left' : 'center',
            backgroundColor: '#fffd',
            paddingBottom: 16,
            ...props.alignTitleLeft && {
              paddingLeft: 16,
              paddingRight: 16,
            },
            paddingTop: 16,
            fontWeight: 500,
            textTransform: 'capitalize',
            width: '100%',
          }}>
          {props.sectionId}
          {!!props.totalQuestions && (
            <>
              <span
                style={{
                  display: 'inline-block',
                  fontWeight: 400,
                  fontSize: 20,
                  lineHeight: 0,
                  color: '#BDBBC3',
                }}>
                &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
              </span>
              <span
                style={{
                  display: 'inline-block',
                  fontWeight: 400,
                  fontSize: 12,
                  color: '#4A4851',
                }}>
                {`${props.totalQuestions} ${props.totalQuestions === 1 ? 'question' : 'questions'}`}
              </span>
            </>
          )}
        </Typography>
        {props.smallScreen && (open
          ? <ExpandLess />
          : <ExpandMore />
        )}
      </Grid>
      <Collapse in={props.alwaysOpen || open}>
        {props.children}
      </Collapse>
    </>
  );
}
