import React, { useState } from "react";
import cn from "classnames";
import { Grid } from "@material-ui/core";
import styles from "./tabsStudent.module.scss";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { sessionPrivileges } from "privileges";

export function Tabs({
  actions,
  activeTab,
  children,
  className,
  contentClassName,
  filterMenu,
  filterMenuIsVisible,
  filterMenuWidth,
  green,
  hideBadge,
  noChildren = false,
  onChange,
  tabs = [],
  changeLayout,
  changeLayoutList,
  viewListButton = [],
}) {
  const [active, setActive] = useState(true);
  const userPrivileges = sessionPrivileges();

  const activeList = (e) => {
    setActive(false);
  };
  const activeGrid = (e) => {
    setActive(true);
  };
  function buttonGrid() {
    activeGrid();
    changeLayout();
  }
  function buttonList() {
    activeList();
    changeLayoutList();
  }
  return (
    <div
      className={cn(styles.tabsHackathon, [styles.green], {
        [styles.green]: green,
      })}
    >
      <div className={styles.tabbar}>
        {tabs.map(({ name, badge, id }) => (
          <button
            className={cn(styles.tabButton, {
              [styles.active]: id === activeTab,
            })}
            key={id}
            onClick={() => {
              onChange(id);
            }}
          >
            <span className={styles.text}>{name}</span>
            {!hideBadge && <span className={styles.badge}>{badge || 0}</span>}
          </button>
        ))}

        {viewListButton.length > 0 ? (
          <div style={{ paddingTop: "10px" }} className="ToggleData">
            {viewListButton.map(({ gridButton, listButton }) => (
              <ToggleButtonGroup exclusive style={{ display: "flex" }}>
                <Button
                  className={active ? "viewButtonActive" : "viewButton"}
                  value="grid"
                  style={{
                    borderRightStyle: "none",
                    borderRightWwidth: "thin",
                  }}
                  onClick={buttonGrid}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6H5C5.26522 6 5.51957 5.89464 5.70711 5.70711C5.89464 5.51957 6 5.26522 6 5V1C6 0.734784 5.89464 0.48043 5.70711 0.292893C5.51957 0.105357 5.26522 0 5 0ZM1 5V1H5V5H1Z"
                      fill="#838285"
                    />
                    <path
                      d="M13 0H9C8.73478 0 8.48043 0.105357 8.29289 0.292893C8.10536 0.48043 8 0.734784 8 1V5C8 5.26522 8.10536 5.51957 8.29289 5.70711C8.48043 5.89464 8.73478 6 9 6H13C13.2652 6 13.5196 5.89464 13.7071 5.70711C13.8946 5.51957 14 5.26522 14 5V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0ZM9 5V1H13V5H9Z"
                      fill="#838285"
                    />
                    <path
                      d="M5 8H1C0.734784 8 0.48043 8.10536 0.292893 8.29289C0.105357 8.48043 0 8.73478 0 9V13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14H5C5.26522 14 5.51957 13.8946 5.70711 13.7071C5.89464 13.5196 6 13.2652 6 13V9C6 8.73478 5.89464 8.48043 5.70711 8.29289C5.51957 8.10536 5.26522 8 5 8ZM1 13V9H5V13H1Z"
                      fill="#838285"
                    />
                    <path
                      d="M13 8H9C8.73478 8 8.48043 8.10536 8.29289 8.29289C8.10536 8.48043 8 8.73478 8 9V13C8 13.2652 8.10536 13.5196 8.29289 13.7071C8.48043 13.8946 8.73478 14 9 14H13C13.2652 14 13.5196 13.8946 13.7071 13.7071C13.8946 13.5196 14 13.2652 14 13V9C14 8.73478 13.8946 8.48043 13.7071 8.29289C13.5196 8.10536 13.2652 8 13 8ZM9 13V9H13V13H9Z"
                      fill="#838285"
                    />
                  </svg>
                  <span
                    className={styles.text}
                    style={{
                      paddingLeft: "10px",
                      color: "#6D6C6C",
                      fontWeight: 600,
                      fontSize: 15,
                    }}
                  >
                    {gridButton}
                  </span>
                </Button>
                <Button
                  className={active ? "viewButton" : "viewButtonActive"}
                  onClick={buttonList}
                  style={{
                    borderLeftRadius: "0px",
                    borderLeftColor: "#1BA94C",
                  }}
                >
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 15 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.75 8.25V7.125H14.25V8.25H0.75ZM0.75 3.75H14.25V4.875H0.75V3.75ZM14.25 0.375V1.5H0.75V0.375H14.25ZM0.75 10.5V11.625H14.25V10.5H0.75Z"
                      fill="#838285"
                    />
                  </svg>
                  <span
                    className={styles.text}
                    style={{
                      paddingLeft: "10px",
                      color: "#6D6C6C",
                      fontWeight: 600,
                      fontSize: 15,
                    }}
                  >
                    {listButton}
                  </span>
                </Button>
              </ToggleButtonGroup>
            ))}
          </div>
        ) : (
          ""
        )}
        {window.location.pathname == "/hackathonstudent" ? (
          <p
            className={styles.hackathon}
            style={{ position: "absolute", right: "1px" }}
          >
            You registered for 12 hackathons
          </p>
        ) : (
          ""
        )}
        {userPrivileges?.isSuperAdmin && actions && (
          <div className={styles.actions}>{actions}</div>
        )}
      </div>
      {!noChildren && (
        <div className={cn(styles.tabContent, contentClassName)}>
          <Grid direction="row" wrap="nowrap" container>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                width: "100%",
              }}
            >
              {children}
            </div>
            {filterMenu && (
              <div
                className={cn(styles.filterMenu)}
                style={{
                  padding: `8px ${filterMenuIsVisible ? 8 : 0}px`,
                  width: filterMenuIsVisible ? filterMenuWidth + 16 : 0,
                }}
              >
                {filterMenu}
              </div>
            )}
          </Grid>
        </div>
      )}
      <style jsx="true" global="true">
        {`
          .viewButton {
            width: 109px;
            height: 40px;
            left: 0px;
            top: 0px;
            background-color: white;
            border: 2px solid #a6a6aa;
            box-sizing: border-box;
            border-radius: 3px 0px 0px 3px;
          }
          .viewButtonActive {
            width: 109px;
            height: 40px;
            left: 0px;
            top: 0px;
            background-color: #f3faf6;
            border: 2px solid #1ba94c;
            box-sizing: border-box;
            border-radius: 3px 0px 0px 3px;
          }
          .viewButton:hover {
            background-color: #f3faf6;
            border: 2px solid #1ba94c;
          }
          .ToggleData {
            padding-left: 350px;
          }
        `}
      </style>
    </div>
  );
}
