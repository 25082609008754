import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Popover,
  Badge,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { FilterMenuQB } from "./FilterMenuQB";

const typeFilters = ["all","DESC", "MC","CD"];

const complexityFilters = [ "all","easy","medium", "hard"];

export function SelectQuestionsModalQB({
  multiSections,
  setCheckedQuestions,
  sectionIndex = null,
  findQuestion = null,
  canPreview = false,
  previewQuestions,
  setPreviewQuestions,
  ...props
}) {
  const [activeTab, setActiveTab] = useState("availableQuestions");
  const [questionBank, setQuestionBank] = useState("");
  const [randomSelect, setRandomSelect] = useState("");
  const [currBankSelQues, setCurrBankSelQues] = useState([]);
  const [tabState, setTabState] = useState([]);
  const [lastIndex, setLastIndex] = useState(0);
  const [newQuestionType, setNewQuestionType] = useState("all");
  const [selFilters, setSelFilters] = useState(typeFilters);
  const [selFiltersComplex, setSelFiltersComplex] = useState(complexityFilters);
  const [selFilterType, setSelFilterType] = useState([]);
  const [infinityScroll, setInfinityScroll] = useState(true);
  const [selFilterComplexity, setSelFilterComplexity] = useState([]);

  const resetSQM = () => {
    setQuestionBank("");
    setCurrBankSelQues([]);
    setTabState([]);
    if (randomSelect) setRandomSelect("");
    if (lastIndex) setLastIndex(0);

    props.setReset(false);
  };

  useEffect(() => {
    setQuestionsType(props.questionsType);
  }, [props?.questionsType]);

  useEffect(() => {
    if (props.reset) {
      resetSQM();
    }
  }, [props?.reset]);

  useEffect(() => {
    if (activeTab === "availableQuestions") {
      setQuestionsType(
        props?.disableType ? props.questionsType : newQuestionType
      );
    }
  }, [props.currBankQues]);

  useEffect(() => {
    if (questionBank.value === "all") {
      setTabState((prev) => {
        return [...new Set([...tabState, ...prev])];
      });
    }
  }, [props.currBankQues]);

  useEffect(() => {
    if (
      props.existingQuestions &&
      props.existingQuestions.length > 0 &&
      currBankSelQues.length === 0
    ) {
      setCurrBankSelQues(props.existingQuestions);
    }
  }, [props?.existingQuestions]);

  useEffect(() => {
    setLastIndex(tabState.length);
  }, [tabState]);
  
  const handleFilterNew = () => {
    setTabState([]);
    setInfinityScroll(false);
    props.setCurrQuestionBank(
      questionBank.value,
      0,
      "",
      selFilterType,
      selFilterComplexity
    );
  };

  const handleClearAll = () => {
    setTabState([]);
    setInfinityScroll(true);
    setSelFilterType([]);
    setSelFilterComplexity([]);
    if (questionBank.value === "all") {
      props.setCurrQuestionBank(questionBank.value, 0, "");
    } else {
      props.setCurrQuestionBank(questionBank.value);
    }
  };


  const setQuestionsType = (type) => {
    if (type === "all") {
      if (activeTab === "availableQuestions") {
        setTabState(props.currBankQues);
      }
    } else {
      if (activeTab === "availableQuestions") {
        setTabState(props.currBankQues.filter((q) => q.type === type));
      } else {
        setTabState(currBankSelQues.filter((q) => q.type === type));
      }
    }
  };

  const setQuestionsWatch = () => {
    setCheckedQuestions(sectionIndex, currBankSelQues);
  };

  return (
    <>
            <FilterMenuQB
              questions={
                activeTab === "availableQuestions"
                  ? questionBank.value === "all"
                    ? tabState
                    : props.currBankQues
                  : currBankSelQues
              }
              tabState={tabState}
              setTabState={setTabState}
              selFilters={selFilters}
              setSelFilters={setSelFilters}
              setSelFiltersComplex={setSelFiltersComplex}
              selFiltersComplex={selFiltersComplex}
              selFilterType={selFilterType}
              setSelFilterType={setSelFilterType}
              selFilterComplexity={selFilterComplexity}
              setSelFilterComplexity={setSelFilterComplexity}
              handleFilterNew={handleFilterNew}
              handleClearAll={handleClearAll}
              activeTab={activeTab}
              setCurrBankSelQues={setCurrBankSelQues}
              setQuestionsWatch={setQuestionsWatch}
              remove={props.remove}
              multiSections={multiSections}
            />
    </>
  );
}
