import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import { Button } from "reactstrap";
import { ArrowBack } from "@material-ui/icons";
import { Tabs } from "../../../components/tabs/tabs";
import ReportTable from "../components/report-table/index";
import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";
import styles from "./index.module.scss";
import { sessionPrivileges } from "privileges";

function UserInfo({ name, department }) {
  return (
    <div className={styles.userInfo}>
      <div className={styles.userInfoName}>{name}</div>
      <div className={styles.userInfoDot} />
      <div className={styles.userInfoDepartment}>{department}</div>
    </div>
  );
}

function AdminStudentReport(props) {
  const Priv = sessionPrivileges().userprivileges || [];
  const [pageData, setPageData] = useState({
    name: "--",
    department: "--",
    assessmentsList: [],
    practiceList: [],
  });
  const [loadingPageData, setPageLoadingStatus] = useState(false);
  const [activeTab, setActiveTab] = useState(() =>
    Priv && Priv.includes("VIEW_STUDENT_ASSESSMENT_REPORT")
      ? "assessment"
      : "practice"
  );

  async function getData() {
    setPageLoadingStatus(true);
    await Apihelper.axiosCall(
      `${api.baseURL}adminReport/getAdminIndividualStudentReport/${props?.location?.params?.studentEmailId}`,
      "get"
    )
      .then((data) => {
        setPageData({
          name: `${data?.firstName} ${data?.lastName}`,
          department: data?.department,
          assessmentsList: data?.assessmentList || [],
          practiceList: data?.packageList || [],
        });
      })
      .catch(() => {})
      .finally(() => {
        setPageLoadingStatus(false);
      });
  }

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  const tabList = [
    ...(Priv && Priv.includes("VIEW_STUDENT_ASSESSMENT_REPORT")
      ? [
          {
            name: "Assessments",
            id: "assessment",
            ...(pageData.assessmentsList?.length && {
              badge: pageData.assessmentsList?.length,
            }),
          },
        ]
      : []),
    ...(Priv && Priv.includes("VIEW_STUDENT_PRACTICE_PACKAGE_REPORT")
      ? [
          {
            name: "Practice Packages",
            id: "practice",
            ...(pageData.practiceList?.length && {
              badge: pageData.practiceList?.length,
            }),
          },
        ]
      : []),
  ];

  return (
    <div className={cn("page-content", styles.page)}>
      <Tabs
        activeTab={activeTab}
        contentClassName={styles.tabContent}
        onChange={setActiveTab}
        hideBadge={
          !(Priv && Priv.includes("VIEW_STUDENT_ASSESSMENT_REPORT")) ||
          !(Priv && Priv.includes("VIEW_STUDENT_PRACTICE_PACKAGE_REPORT"))
        }
        tabs={tabList}
      >
        {Priv &&
          Priv.includes("VIEW_STUDENT_ASSESSMENT_REPORT") &&
          activeTab === "assessment" && (
            <ReportTable
              data={pageData.assessmentsList}
              exportFileName={`${pageData?.department} - ${pageData?.name}'s Report`}
              title={
                <UserInfo
                  name={pageData?.name}
                  department={pageData?.department}
                />
              }
              columns={[
                {
                  align: "left",
                  field: "name",
                  sorting: false,
                  title: "Assessment\xa0Name",
                },
                {
                  align: "right",
                  field: "totalQuestions",
                  title: "Total\xa0Questions",
                },
                {
                  align: "right",
                  field: "correct",
                  render: (d) => (
                    <span style={{ color: "#246611" }}>{d?.correct}</span>
                  ),
                  title: "Correct",
                },
                {
                  align: "right",
                  field: "wrong",
                  render: (d) => (
                    <span style={{ color: "#D42216" }}>{d?.wrong}</span>
                  ),
                  title: "Wrong",
                },
                {
                  align: "right",
                  field: "unattempted",
                  title: "Un\xa0attempted",
                },
                {
                  align: "right",
                  field: "totalMarks",
                  render: (d) => (
                    <span
                      style={{
                        backgroundColor: "#8BFFD5",
                        borderRadius: 5,
                        color: "#20222F",
                        height: 21,
                        padding: "0 6px",
                      }}
                    >
                      {`${parseFloat(d?.totalMarks).toFixed(2) || 0}%`}
                    </span>
                  ),
                  title: "Total\xa0Marks",
                },
                {
                  align: "center",
                  field: "timeTaken",
                  render: (d) => parseFloat(d?.timeTaken).toFixed(1),
                  title: "Time\xa0taken\n(in\xa0min)",
                },
                {
                  align: "center",
                  field: "performance",
                  render: (d) =>
                    d?.performance ? (
                      <span
                        style={{
                          backgroundColor:
                            d?.performance === "Good"
                              ? "#EEFFEA"
                              : d?.performance === "Average"
                              ? "#FFF8E1"
                              : "#FFF4F3",
                          borderColor:
                            d?.performance === "Good"
                              ? "#B3DAC7"
                              : d?.performance === "Average"
                              ? "#FFCA99"
                              : "#FFD7D7",
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: 3,
                          color:
                            d?.performance === "Good"
                              ? "#43B224"
                              : d?.performance === "Average"
                              ? "#886E1D"
                              : "#D42216",
                          height: 24,
                          padding: "0 6px",
                          width: 66,
                        }}
                      >
                        {d?.performance}
                      </span>
                    ) : null,
                  title: "Performance",
                },
              ]}
              isLoading={loadingPageData}
            />
          )}
        {Priv &&
          Priv.includes("VIEW_STUDENT_PRACTICE_PACKAGE_REPORT") &&
          activeTab === "practice" && (
            <ReportTable
              data={pageData.practiceList}
              title={
                <UserInfo
                  name={pageData?.name}
                  department={pageData?.department}
                />
              }
              columns={[
                {
                  align: "left",
                  field: "name",
                  sorting: false,
                  title: "Package\xa0Name",
                },
                {
                  align: "right",
                  field: "totalQuestions",
                  title: "Total\xa0Questions",
                },
                {
                  align: "right",
                  field: "completedQuestions",
                  render: (d) => (
                    <span style={{ color: "#246611" }}>
                      {d?.completedQuestions}
                    </span>
                  ),
                  title: "Completed",
                },
                {
                  align: "right",
                  field: "inProgress",
                  render: (d) => (
                    <span style={{ color: "#D42216" }}>{d?.inProgress}</span>
                  ),
                  title: "In Progress",
                },
                {
                  align: "right",
                  field: "totalMarks",
                  render: (d) => (
                    <span
                      style={{
                        backgroundColor: "#8BFFD5",
                        borderRadius: 5,
                        color: "#20222F",
                        height: 21,
                        padding: "0 6px",
                      }}
                    >
                      {`${d?.totalMarks}\xa0/\xa0${d?.allocatedMarks}`}
                    </span>
                  ),
                  title: "Total\xa0Marks",
                },
                {
                  align: "center",
                  field: "avgAccuracy",
                  render: (d) => parseFloat(d?.avgAccuracy).toFixed(1),
                  title: "Avg\naccuracy%",
                },
                {
                  align: "center",
                  field: "performance",
                  render: (d) =>
                    d?.performance ? (
                      <span
                        style={{
                          backgroundColor:
                            d?.performance === "Good"
                              ? "#EEFFEA"
                              : d?.performance === "Average"
                              ? "#FFF8E1"
                              : "#FFF4F3",
                          borderColor:
                            d?.performance === "Good"
                              ? "#B3DAC7"
                              : d?.performance === "Average"
                              ? "#FFCA99"
                              : "#FFD7D7",
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: 3,
                          color:
                            d?.performance === "Good"
                              ? "#43B224"
                              : d?.performance === "Average"
                              ? "#886E1D"
                              : "#D42216",
                          height: 24,
                          padding: "0 6px",
                          width: 66,
                        }}
                      >
                        {d?.performance}
                      </span>
                    ) : null,
                  title: "Performance",
                },
                {
                  align: "center",
                  field: "status",
                  render: (d) =>
                    d?.status ? (
                      d?.status === "Completed" ? (
                        <div className={styles.completedStatus}>Completed</div>
                      ) : d?.status === "In-Progress" ? (
                        <div className={styles.inProgressStatus}>
                          In&nbsp;progress
                        </div>
                      ) : (
                        <div className={styles.incompleteStatus}>
                          Incomplete
                        </div>
                      )
                    ) : (
                      ""
                    ),
                  title: "Package\nStatus%",
                },
              ]}
              isLoading={loadingPageData}
            />
          )}
      </Tabs>
      <div>
        <Button
          outline
          style={{ display: "inline-block !important", fontSize: 16 }}
          onClick={() => {
            props.history.push({
              pathname: "/students-report",
              params: {
                customerId: props?.location?.params?.customerId,
              },
            });
          }}
        >
          <ArrowBack />
          &nbsp;&nbsp;
          {"Go Back"}
        </Button>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default withRouter(AdminStudentReport);
