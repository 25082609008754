import { useState } from "react";



export default function RoadMap() {

    const [ programFoundations , setProgramFoundations] = useState(false);

    const [ program1 , setProgram1] = useState(false);
    const [ program2 , setProgram2] = useState(false);

    const [ dsa , setDsa] = useState(false);
    const [ dsa1 , setDsa1] = useState(false);
    const [ dsa2 , setDsa2] = useState(false);
    const [ dsa3 , setDsa3] = useState(false);

    const [ techStack , setTechStack] = useState(false);
    const [ techStackAdv , setTechStackAdv] = useState(false);

    const [systemDesign , setSysytemDesign] = useState(false);
    const [ sd1 , setSd1] = useState(false);
    const [ sd2 , setSd2] = useState(false);

    const [ interview , setInterview] = useState(false);
    const [ externship , setExternship] = useState(false);
    
    const [ lld , setLld] = useState(false);
    const [lld1 , setLld1] = useState(false);
    const [lld2 , setLld2] = useState(false);


    const [ hld , setHld] = useState(false);
    const [hld1 , setHld1] = useState(false);
    const [hld2 , setHld2] = useState(false);
    const [hld3 , setHld3] = useState(false);

    const [ advanceInterview , setAdvanceInterview] = useState(false);
    const [ advanceLld , setAdvanceLld] = useState(false);
    const [ advanceHld , setAdvanceHld] = useState(false);

  return (
    <>
      <div
        className="parent-div mb-20 rounded-[50px] bg-v5-green-500 p-4 text-white sm:p-6 "
        style={{
          margin: "0px",
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          marginBottom: "5rem",
          borderRadius: "50px",
          backgroundColor: "#5E35B1",
          color: "white !important",
          textColor: "white",
          padding: "1.5rem",
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <h1
          className="mt-10 mb-12 text-center font-manrope text-2xl font-extrabold capitalize leading-tight sm:text-4xl"
          style={{
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            overflowWrap: "break-word",
            margin: "0px",
            marginTop: "2.5rem",
            marginBottom: "3rem",
            textAlign: "center",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 800,
            textTransform: "capitalize",
            fontSize: "2.25rem",
            lineHeight: "2.5rem",
            color : "white",
          }}
        >
          Curriculum to crack dream jobs{" "}
          <br
            className="hidden sm:block"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              display: "block",
            }}
          />
          (6 To 18 LPA) in top Product-Based{" "}
          <br
            className="hidden sm:block"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              display: "block",
            }}
          />
          companies
        </h1>
        <div
          className="mb-16 rounded-[20px] bg-v5-green-500 text-white"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            marginBottom: "4rem",
            borderRadius: "20px",
            backgroundColor: "#5E35B1",
            color: "white",
          }}
        >
          <div
            className="flex w-full flex-wrap items-stretch gap-y-4 gap-x-4 pt-4 pb-7 text-v5-neutral-200 sm:px-6 sm:pb-7"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              alignItems: "stretch",
              columnGap: "1rem",
              rowGap: "1rem",
              paddingTop: "1rem",
              color: "rgb(242 251 247/1)",
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
              paddingBottom: "1.75rem",
            }}
          >
            <div
              className="w-full"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              <div
                className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-700 css-1nci3hw"
                style={{
                  margin: "0px",
                  boxSizing: "border-box",
                  transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                  border: "0px",
                  color: "rgba(0, 0, 0, 0.87)",
                  boxShadow: "none",
                  position: "relative",
                  overflowAnchor: "none",
                  marginTop: "0px",
                  marginBottom: "0px",
                  backgroundColor: "#242145",
                  borderRadius: "30px",
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  borderBottomLeftRadius: "30px",
                }}
              >
                <div
                  id="panel1a-header"
                  className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                  aria-controls="panel1a-content"
                  aria-expanded="true"
                  role="button"
                  tabIndex="0"
                  style={{
                    outline: "0px",
                    border: "0px",
                    margin: "0px",
                    borderRadius: "0px",
                    textDecoration: "none",
                    transition:
                      "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    WebkitBoxAlign: "center",
                    alignItems: "center",
                    WebkitBoxPack: "center",
                    justifyContent: "center",
                    position: "relative",
                    boxSizing: "border-box",
                    WebkitTapHighlightColor: "transparent",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    userSelect: "none",
                    verticalAlign: "middle",
                    appearance: "none",
                    display: "flex",
                    minHeight: "48px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "0.5rem",
                    color: "rgb(242 251 247/1)",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                  }}
                  onClick={()=>{setProgramFoundations(!programFoundations)}}
                >
                  <div
                    className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      WebkitBoxFlex: "1",
                      flexGrow: 1,
                      margin: "8px 0px",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        className="flex items-start gap-x-4"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                          display: "flex",
                          alignItems: "flex-start",
                          columnGap: "1rem",
                        }}
                      >
                        <div
                          className="w-[40px] min-w-[40px]"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            width: "40px",
                            minWidth: "40px",
                          }}
                        >
                          <div
                            className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflow: "hidden",
                              position: "relative",
                              display: "inline-block",
                              verticalAlign: "top",
                              maxHeight: "100%",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                maxWidth: "100px",
                                display: "block",
                              }}
                            >
                              <img
                                aria-hidden="true"
                                role="presentation"
                                src="data:image/svg+xml;charset=utf-8,<svg height='102' width='100' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                  maxWidth: "100%",
                                  display: "block",
                                  position: "static",
                                }}
                              />
                            </div>
                            <img
                              aria-hidden="true"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADbElEQVQ4y5WV328UVRTHvzvblhDjg8H4LzRaoi8+Gi3+ipqoEQorlP4QF14gErp07p12V6OBRNm2u3tntC0sm0J9KNYIaJNqwXW7xaBPhkSqtBAEqokP6mLQUs3O19yZKd1tG6MP35yTufd+8p0755wBlACUqIeS41DiGpScgxJzfvw3Le4R16HEaWQ8BoC01EkJtiBsST+uJrksl9W5EiUosx5QcsxbUHIeSpYNR5TDjlkl2KKs1/wY5FVRzAfQCXggH+jCFi764i6SCaInkM5VF4M9y537Z5SOHnAe/kZvgUhZ7saTbez96nkePPeip0Nfv8CHj+8g0hZrHJOGI+4odOesXIqwpRsKYPeO7GPq1+c4fOtJ5m4+xeGFp9n3zaO8TzQR6dd9x72BdJ7uZuhdWQ31LDuC6JUuxjuJG4KYsYgZSczFifejxM5tbPwoypPfNfLD6Q0cvbiBH8828u3JZ1j3zmuEY+nX9q/Ae1UfSIyZNG7EGL4SY/hyB42fTNadaGfNnpcZubCHb15tpXW5ndZsO9/68RU+0vkEsXcXQ4PdRCb44lVA7fDnGI3rWh00fjFZO9rGNWYrkR8gPqtQPkts3ELsjxIDy4HeHUpixCTOdxJ5izjbRRS7iWMxoifOmi9SXDOZYl0hxTqdf6lobN1ExKJElUNdCkE5hPTDpMXIqWaOTT/GDy48zlPTjTxy8VmGz/YQZzLEmRQxkSIKNhFZDVhZVxnB2sOSu77dyTeutNCabWNitoWxmSgfuuSw4VI/12t9/x4fvHqYa1ubiI4VwIoWSptENkF8HtzTxADx6QBRGGTkVo4tt3Nsns+x+c8c29zjXBfdQuxbCXQDoOsBjyRYU0izNt/n3VU438e7z6X50m9Zbv79KJtuHuWmUpaRhWO8Z8dmYu+rxGDc9YG6bNQyYDZBY8qmMaloFBVRULzrvMOtt4fY+vcQt/81xO0LQ2znMNft3kbEdxP9XX4datZSH0tt20V/F0MnDjA04gsjB1g7epD3Fw+xYSrJhmKSDxSTXD+V5NrMflYZ0nFpOHjt4ze6dlqplEn0dK7U4v5FQ0r8oYETftvo8SXKsOWqCjkrFYyusnfWN/UJYFv1sGUp6MWK4fk/5BsqwZHegIWX2PI0lPzhv43/qt/ANdhyHI6ohy3wD0bDTgDZNOfXAAAAAElFTkSuQmCC"
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                pointerEvents: "none",
                                display: "block",
                                verticalAlign: "middle",
                                margin: "0px",
                                padding: "0px",
                                height: "100%",
                                maxWidth: "none",
                                width: "100%",
                                transition: "opacity 500ms linear",
                                inset: "0px",
                                opacity: 0,
                                backgroundColor: "transparent",
                                position: "absolute",
                                objectFit: "cover",
                              }}
                            />
                            <picture
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "block",
                                maxWidth: "100%",
                              }}
                            >
                              <source
                                type="image/webp"
                                sizes="(min-width: 50px) 50px, 100vw"
                                srcSet="https://www.crio.do/static/67a16af729870422250885b5562bf3d4/d5ef2/One.webp 50w"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                              <img
                                placeholder="blurred"
                                height={41}
                                width={40}
                                alt="Curriculum"
                                sizes="(min-width: 50px) 50px, 100vw"
                                src="https://www.crio.do/static/67a16af729870422250885b5562bf3d4/14f19/One.png"
                                srcSet="/static/67a16af729870422250885b5562bf3d4/14f19/One.png 50w"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  display: "block",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  maxWidth: "none",
                                  position: "absolute",
                                  width: "100%",
                                  transition: "opacity 0.25s linear",
                                  transform: "translateZ(0px)",
                                  willChange: "opacity",
                                  objectFit: "contain",
                                  opacity: 1,
                                }}
                              />
                            </picture>
                            <noscript
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              {
                                '<picture><source type="image/webp" srcSet="/static/67a16af729870422250885b5562bf3d4/d5ef2/One.webp 50w" sizes="(min-width: 50px) 50px, 100vw"/><img placeholder="blurred" width="100" height="102" data-main-image="" style="object-fit:contain;opacity:0" sizes="(min-width: 50px) 50px, 100vw" decoding="async" loading="lazy" src="/static/67a16af729870422250885b5562bf3d4/14f19/One.png" srcSet="/static/67a16af729870422250885b5562bf3d4/14f19/One.png 50w" alt="Curriculum"/></picture>'
                              }
                            </noscript>
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        >
                          <h6
                            className="transition-all sm:w-full md:text-[18px]"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontWeight: 700,
                              transitionDuration: "0.15s",
                              transitionProperty: "all",
                              transitionTimingFunction:
                                "cubic-bezier(0.4, 0, 0.2, 1)",
                              width: "100%",
                              fontSize: "18px",
                              color : "white",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              Programming Foundations
                            </div>
                          </h6>
                          <p
                            className="text-sm text-v5-neutral-300"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              color: "rgb(185 207 202/1)",
                            }}
                          >
                            4 to 8 weeks
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      transition:
                        "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                      display: "flex",
                      color: "rgba(0, 0, 0, 0.54)",
                      transform: `${programFoundations ? 'rotate(180deg)' :'rotate(0deg)'}`,
                    }}
                  >
                    <div
                      className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        display: "flex",
                        height: "24px",
                        width: "24px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "9999px",
                        backgroundColor: "#5E35B1",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      <svg
                        className="svg-inline--fa fa-chevron-right w-[10px] rotate-180 transition-all"
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        viewBox="0 0 320 512"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          maxWidth: "100%",
                          display: "var(--fa-display, inline-block)",
                          height: "1em",
                          verticalAlign: "-0.125em",
                          overflow: "visible",
                          boxSizing: "content-box",
                          width: "10px",
                          transform:
                            "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                          transitionDuration: "0.15s",
                          transitionProperty: "all",
                          transitionTimingFunction:
                            "cubic-bezier(0.4, 0, 0.2, 1)",
                        }}
                      >
                        <path
                          d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                          fill="currentColor"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {programFoundations &&  
                  <div
        className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
        style={{
          margin: "0px",
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          overflow: "visible",
          transition: "height 300ms cubic-bezier(0.4, 0, 0.2, 1)",
          minHeight: "0px",
          height: "auto",
          transitionDuration: "293ms",
          color: "rgba(0, 0, 0, 0.87)",
        }}
      >
        <div
          className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            display: "flex",
            width: "100%",
          }}
        >
          <div
            className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              width: "100%",
            }}
          >
            <div
              id="panel1a-content"
              className="MuiAccordion-region"
              aria-labelledby="panel1a-header"
              role="region"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
              }}
            >
              <div
                className="MuiAccordionDetails-root bg-v5-green-700 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  padding: "8px 16px 16px",
                  borderBottomLeftRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor: "#242145",
                  paddingBottom: "1rem",
                  paddingTop: "0px",
                  lineHeight: 1.625,
                  color: "rgb(185 207 202/1)",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                }}
              >
                <div
                  className="md:pl-[54px]"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    paddingLeft: "54px",
                  }}
                >
                  <div
                    className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      marginBottom: "1rem",
                      color: "rgb(185 207 202/1)",
                      fontSize: "16px",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    >
                      Kickstart your Programmer journey by creating a solid
                      foundation of programming and language skills. Start using
                      standard industry developer technologies.
                    </div>
                  </div>
                  <div
                    className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      marginBottom: "1rem",
                      color: "rgb(185 207 202/1)",
                      fontSize: "16px",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    />
                  </div>
                </div>
                <div
                  className="md:pl-[54px] md:pt-1"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    paddingLeft: "54px",
                    paddingTop: "0.25rem",
                  }}
                >
                  <h6
                    className="font-manrope text-white"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      overflowWrap: "break-word",
                      margin: "0px",
                      fontWeight: 700,
                      fontSize: "1rem",
                      fontFamily: "Manrope, sans-serif",
                      color: "rgb(255 255 255/1)",
                    }}
                  />
                  <div
                    className="mt-10 flex flex-wrap items-stretch gap-5 md:my-4"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "stretch",
                      gap: "1.25rem",
                      marginBottom: "1rem",
                      marginTop: "1rem",
                    }}
                  >
                    <div
                      className="mt-[-10px] w-full"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        marginTop: "-10px",
                        width: "100%",
                      }}
                    >
                      <div
                        className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-500 css-1nci3hw"
                        style={{
                          margin: "0px",
                          boxSizing: "border-box",
                          transition:
                            "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                          border: "none",
                          color: "rgba(0, 0, 0, 0.87)",
                          boxShadow: "none",
                          position: "relative",
                          overflowAnchor: "none",
                          marginTop: "0px",
                          marginBottom: "0px",
                          backgroundColor: "#5E35B1",
                          borderRadius: "30px",
                          borderTopLeftRadius: "30px",
                          borderTopRightRadius: "30px",
                          borderBottomRightRadius: "30px",
                          borderBottomLeftRadius: "30px",
                        }}
                      >
                        <div
                          id="panel1a-header"
                          className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                          aria-controls="panel1a-content"
                          aria-expanded="true"
                          role="button"
                          tabIndex="0"
                          style={{
                            outline: "0px",
                            border: "0px",
                            margin: "0px",
                            borderRadius: "0px",
                            textDecoration: "none",
                            transition:
                              "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                            WebkitBoxAlign: "center",
                            alignItems: "center",
                            WebkitBoxPack: "center",
                            justifyContent: "center",
                            position: "relative",
                            boxSizing: "border-box",
                            WebkitTapHighlightColor: "transparent",
                            backgroundColor: "transparent",
                            cursor: "pointer",
                            userSelect: "none",
                            verticalAlign: "middle",
                            appearance: "none",
                            display: "flex",
                            minHeight: "48px",
                            width: "100%",
                            overflow: "hidden",
                            padding: "0.5rem",
                            color: "rgb(242 251 247/1)",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                          }}
                        >
                          <div
                            className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              display: "flex",
                              WebkitBoxFlex: "1",
                              flexGrow: 1,
                              margin: "8px 0px",
                            }}
                            onClick={()=>setProgram1(!program1)}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              <div
                                className="flex items-start gap-x-4"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  display: "flex",
                                  alignItems: "flex-start",
                                  columnGap: "1rem",
                                }}
                              >
                                <div
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                  }}
                                >
                                  <h6
                                    className="transition-all sm:w-full"
                                    style={{
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      overflowWrap: "break-word",
                                      margin: "0px",
                                      fontWeight: 700,
                                      fontSize: "1rem",
                                      transitionDuration: "0.15s",
                                      transitionProperty: "all",
                                      transitionTimingFunction:
                                        "cubic-bezier(0.4, 0, 0.2, 1)",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      Language Fundamentals
                                    </div>
                                  </h6>
                                  <p
                                    className="text-sm text-v5-neutral-300"
                                    style={{
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      overflowWrap: "break-word",
                                      margin: "0px",
                                      fontSize: "0.875rem",
                                      lineHeight: "1.25rem",
                                      color: "rgb(185 207 202/1)",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              transition:
                                "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                              display: "flex",
                              color: "rgba(0, 0, 0, 0.54)",
                              transform: `${program1 ? 'rotate(180deg)' :'rotate(0deg)'}`,
                            }}
                          >
                            <div
                              className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-700 text-v5-neutral-300"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "flex",
                                height: "24px",
                                width: "24px",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "9999px",
                                backgroundColor: "#242145",
                                color: "rgb(185 207 202/1)",
                              }}
                            >
                              <svg
                                className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                                aria-hidden="true"
                                focusable="false"
                                role="img"
                                viewBox="0 0 320 512"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  maxWidth: "100%",
                                  display: "var(--fa-display, inline-block)",
                                  height: "1em",
                                  verticalAlign: "-0.125em",
                                  overflow: "visible",
                                  boxSizing: "content-box",
                                  width: "10px",
                                  transform:
                                    "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                                  transitionDuration: "0.15s",
                                  transitionProperty: "all",
                                  transitionTimingFunction:
                                    "cubic-bezier(0.4, 0, 0.2, 1)",
                                }}
                              >
                                <path
                                  d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                  fill="currentColor"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                  }}
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                        {program1 &&
                          <div
                          className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            overflow: "visible",
                            transition:
                              "height 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                            minHeight: "0px",
                            height: "auto",
                            transitionDuration: "262ms",
                          }}
                        >
                          <div
                            className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div
                              className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                width: "100%",
                              }}
                            >
                              <div
                                id="panel1a-content"
                                className="MuiAccordion-region"
                                aria-labelledby="panel1a-header"
                                role="region"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              >
                                <div
                                  className="MuiAccordionDetails-root bg-v5-green-500 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    padding: "8px 16px 16px",
                                    borderBottomLeftRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                    backgroundColor: "#5E35B1",
                                    paddingBottom: "1rem",
                                    paddingTop: "0px",
                                    lineHeight: 1.625,
                                    color: "rgb(185 207 202/1)",
                                    paddingLeft: "1.5rem",
                                    paddingRight: "1.5rem",
                                  }}
                                >
                                  <hr
                                    className="mb-5 border-b border-crio-green-400 opacity-20"
                                    style={{
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      backgroundColor: "rgb(227, 225, 213)",
                                      borderTopWidth: "1px",
                                      color: "inherit",
                                      height: "0px",
                                      margin: "0px",
                                      padding: "0px",
                                      marginBottom: "1.25rem",
                                      borderBottomWidth: "1px",
                                      borderColor: "rgb(14 178 159/1)",
                                      opacity: 0.2,
                                    }}
                                  />
                                  <div
                                    className="mt-2"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      marginTop: "0.5rem",
                                    }}
                                  >
                                    <div
                                      className="mb-4 text-[#B9CFCA]"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        marginBottom: "1rem",
                                        color: "rgb(185 207 202/1)",
                                      }}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        Start with Hello World and get familiar
                                        with the syntax and constructs of Java
                                        or JavaScript based on the
                                        specialization you choose
                                      </div>
                                    </div>
                                    <div
                                      className="mb-4 text-[#B9CFCA]"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        marginBottom: "1rem",
                                        color: "rgb(185 207 202/1)",
                                      }}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        marginBottom: "1rem",
                                        marginTop: "0.75rem",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        columnGap: "0.75rem",
                                        rowGap: "0.75rem",
                                      }}
                                    >
                                      <div
                                        className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          borderRadius: "5px",
                                          borderColor: "rgb(185 207 202/1)",
                                          padding: "0.25rem",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          borderWidth: "2px",
                                        }}
                                      >
                                        <h6
                                          className="text-xs"
                                          style={{
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            overflowWrap: "break-word",
                                            margin: "0px",
                                            fontWeight: 700,
                                            fontSize: "0.75rem",
                                            lineHeight: "1rem",
                                          }}
                                        >
                                          Loops
                                        </h6>
                                      </div>
                                      <div
                                        className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          borderRadius: "5px",
                                          borderColor: "rgb(185 207 202/1)",
                                          padding: "0.25rem",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          borderWidth: "2px",
                                        }}
                                      >
                                        <h6
                                          className="text-xs"
                                          style={{
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            overflowWrap: "break-word",
                                            margin: "0px",
                                            fontWeight: 700,
                                            fontSize: "0.75rem",
                                            lineHeight: "1rem",
                                          }}
                                        >
                                          Conditionals
                                        </h6>
                                      </div>
                                      <div
                                        className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          borderRadius: "5px",
                                          borderColor: "rgb(185 207 202/1)",
                                          padding: "0.25rem",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          borderWidth: "2px",
                                        }}
                                      >
                                        <h6
                                          className="text-xs"
                                          style={{
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            overflowWrap: "break-word",
                                            margin: "0px",
                                            fontWeight: 700,
                                            fontSize: "0.75rem",
                                            lineHeight: "1rem",
                                          }}
                                        >
                                          Arrays
                                        </h6>
                                      </div>
                                      <div
                                        className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          borderRadius: "5px",
                                          borderColor: "rgb(185 207 202/1)",
                                          padding: "0.25rem",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          borderWidth: "2px",
                                        }}
                                      >
                                        <h6
                                          className="text-xs"
                                          style={{
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            overflowWrap: "break-word",
                                            margin: "0px",
                                            fontWeight: 700,
                                            fontSize: "0.75rem",
                                            lineHeight: "1rem",
                                          }}
                                        >
                                          Objects
                                        </h6>
                                      </div>
                                      <div
                                        className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          borderRadius: "5px",
                                          borderColor: "rgb(185 207 202/1)",
                                          padding: "0.25rem",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          borderWidth: "2px",
                                        }}
                                      >
                                        <h6
                                          className="text-xs"
                                          style={{
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            overflowWrap: "break-word",
                                            margin: "0px",
                                            fontWeight: 700,
                                            fontSize: "0.75rem",
                                            lineHeight: "1rem",
                                          }}
                                        >
                                          Strings
                                        </h6>
                                      </div>
                                      <div
                                        className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          borderRadius: "5px",
                                          borderColor: "rgb(185 207 202/1)",
                                          padding: "0.25rem",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          borderWidth: "2px",
                                        }}
                                      >
                                        <h6
                                          className="text-xs"
                                          style={{
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            overflowWrap: "break-word",
                                            margin: "0px",
                                            fontWeight: 700,
                                            fontSize: "0.75rem",
                                            lineHeight: "1rem",
                                          }}
                                        >
                                          Data Types
                                        </h6>
                                      </div>
                                      <div
                                        className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          borderRadius: "5px",
                                          borderColor: "rgb(185 207 202/1)",
                                          padding: "0.25rem",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          borderWidth: "2px",
                                        }}
                                      >
                                        <h6
                                          className="text-xs"
                                          style={{
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            overflowWrap: "break-word",
                                            margin: "0px",
                                            fontWeight: 700,
                                            fontSize: "0.75rem",
                                            lineHeight: "1rem",
                                          }}
                                        >
                                          Libraries
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
                        }
                      </div>
                    </div>
                    <div
                      className="mt-[-10px] w-full"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        marginTop: "-10px",
                        width: "100%",
                      }}
                    >
                      <div
                        className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-500 css-1nci3hw"
                        style={{
                          margin: "0px",
                          boxSizing: "border-box",
                          transition:
                            "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                          border: "none",
                          color: "rgba(0, 0, 0, 0.87)",
                          boxShadow: "none",
                          position: "relative",
                          overflowAnchor: "none",
                          marginTop: "0px",
                          marginBottom: "0px",
                          backgroundColor: "#5E35B1",
                          borderRadius: "30px",
                          borderTopLeftRadius: "30px",
                          borderTopRightRadius: "30px",
                          borderBottomRightRadius: "30px",
                          borderBottomLeftRadius: "30px",
                        }}
                      >
                        <div
                          id="panel1a-header"
                          className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                          aria-controls="panel1a-content"
                          aria-expanded="true"
                          role="button"
                          tabIndex="0"
                          style={{
                            outline: "0px",
                            border: "0px",
                            margin: "0px",
                            borderRadius: "0px",
                            textDecoration: "none",
                            transition:
                              "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                            WebkitBoxAlign: "center",
                            alignItems: "center",
                            WebkitBoxPack: "center",
                            justifyContent: "center",
                            position: "relative",
                            boxSizing: "border-box",
                            WebkitTapHighlightColor: "transparent",
                            backgroundColor: "transparent",
                            cursor: "pointer",
                            userSelect: "none",
                            verticalAlign: "middle",
                            appearance: "none",
                            display: "flex",
                            minHeight: "48px",
                            width: "100%",
                            overflow: "hidden",
                            padding: "0.5rem",
                            color: "rgb(242 251 247/1)",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                          }}
                        >
                          <div
                            className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              display: "flex",
                              WebkitBoxFlex: "1",
                              flexGrow: 1,
                              margin: "8px 0px",
                            }}
                            onClick={()=>setProgram2(!program2)}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              <div
                                className="flex items-start gap-x-4"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  display: "flex",
                                  alignItems: "flex-start",
                                  columnGap: "1rem",
                                }}
                              >
                                <div
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                  }}
                                >
                                  <h6
                                    className="transition-all sm:w-full"
                                    style={{
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      overflowWrap: "break-word",
                                      margin: "0px",
                                      fontWeight: 700,
                                      fontSize: "1rem",
                                      transitionDuration: "0.15s",
                                      transitionProperty: "all",
                                      transitionTimingFunction:
                                        "cubic-bezier(0.4, 0, 0.2, 1)",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      Developer Essentials
                                    </div>
                                  </h6>
                                  <p
                                    className="text-sm text-v5-neutral-300"
                                    style={{
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      overflowWrap: "break-word",
                                      margin: "0px",
                                      fontSize: "0.875rem",
                                      lineHeight: "1.25rem",
                                      color: "rgb(185 207 202/1)",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              transition:
                                "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                              display: "flex",
                              color: "rgba(0, 0, 0, 0.54)",
                              transform: `${program2 ? 'rotate(180deg)' :'rotate(0deg)'}`,
                            }}
                          >
                            <div
                              className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-700 text-v5-neutral-300"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "flex",
                                height: "24px",
                                width: "24px",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "9999px",
                                backgroundColor: "#242145",
                                color: "rgb(185 207 202/1)",
                              }}
                            >
                              <svg
                                className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                                aria-hidden="true"
                                focusable="false"
                                role="img"
                                viewBox="0 0 320 512"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  maxWidth: "100%",
                                  display: "var(--fa-display, inline-block)",
                                  height: "1em",
                                  verticalAlign: "-0.125em",
                                  overflow: "visible",
                                  boxSizing: "content-box",
                                  width: "10px",
                                  transform:
                                    "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                                  transitionDuration: "0.15s",
                                  transitionProperty: "all",
                                  transitionTimingFunction:
                                    "cubic-bezier(0.4, 0, 0.2, 1)",
                                }}
                              >
                                <path
                                  d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                  fill="currentColor"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                  }}
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                        { program2 &&
                          <div
                          className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            overflow: "visible",
                            transition:
                              "height 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                            minHeight: "0px",
                            height: "auto",
                            transitionDuration: "251ms",
                          }}
                        >
                          <div
                            className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div
                              className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                width: "100%",
                              }}
                            >
                              <div
                                id="panel1a-content"
                                className="MuiAccordion-region"
                                aria-labelledby="panel1a-header"
                                role="region"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              >
                                <div
                                  className="MuiAccordionDetails-root bg-v5-green-500 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    padding: "8px 16px 16px",
                                    borderBottomLeftRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                    backgroundColor: "#5E35B1",
                                    paddingBottom: "1rem",
                                    paddingTop: "0px",
                                    lineHeight: 1.625,
                                    color: "rgb(185 207 202/1)",
                                    paddingLeft: "1.5rem",
                                    paddingRight: "1.5rem",
                                  }}
                                >
                                  <hr
                                    className="mb-5 border-b border-crio-green-400 opacity-20"
                                    style={{
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      backgroundColor: "rgb(227, 225, 213)",
                                      borderTopWidth: "1px",
                                      color: "inherit",
                                      height: "0px",
                                      margin: "0px",
                                      padding: "0px",
                                      marginBottom: "1.25rem",
                                      borderBottomWidth: "1px",
                                      borderColor: "rgb(14 178 159/1)",
                                      opacity: 0.2,
                                    }}
                                  />
                                  <div
                                    className="mt-2"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      marginTop: "0.5rem",
                                    }}
                                  >
                                    <div
                                      className="mb-4 text-[#B9CFCA]"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        marginBottom: "1rem",
                                        color: "rgb(185 207 202/1)",
                                      }}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        Gain skills that are must-haves for any
                                        developer through hands-on activities
                                        and live workshops.
                                      </div>
                                    </div>
                                    <div
                                      className="mb-4 text-[#B9CFCA]"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        marginBottom: "1rem",
                                        color: "rgb(185 207 202/1)",
                                      }}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        marginBottom: "1rem",
                                        marginTop: "0.75rem",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        columnGap: "0.75rem",
                                        rowGap: "0.75rem",
                                      }}
                                    >
                                      <div
                                        className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          borderRadius: "5px",
                                          borderColor: "rgb(185 207 202/1)",
                                          padding: "0.25rem",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          borderWidth: "2px",
                                        }}
                                      >
                                        <h6
                                          className="text-xs"
                                          style={{
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            overflowWrap: "break-word",
                                            margin: "0px",
                                            fontWeight: 700,
                                            fontSize: "0.75rem",
                                            lineHeight: "1rem",
                                          }}
                                        >
                                          HTTP
                                        </h6>
                                      </div>
                                      <div
                                        className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          borderRadius: "5px",
                                          borderColor: "rgb(185 207 202/1)",
                                          padding: "0.25rem",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          borderWidth: "2px",
                                        }}
                                      >
                                        <h6
                                          className="text-xs"
                                          style={{
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            overflowWrap: "break-word",
                                            margin: "0px",
                                            fontWeight: 700,
                                            fontSize: "0.75rem",
                                            lineHeight: "1rem",
                                          }}
                                        >
                                          Rest API
                                        </h6>
                                      </div>
                                      <div
                                        className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          borderRadius: "5px",
                                          borderColor: "rgb(185 207 202/1)",
                                          padding: "0.25rem",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          borderWidth: "2px",
                                        }}
                                      >
                                        <h6
                                          className="text-xs"
                                          style={{
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            overflowWrap: "break-word",
                                            margin: "0px",
                                            fontWeight: 700,
                                            fontSize: "0.75rem",
                                            lineHeight: "1rem",
                                          }}
                                        >
                                          Deployment
                                        </h6>
                                      </div>
                                      <div
                                        className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          borderRadius: "5px",
                                          borderColor: "rgb(185 207 202/1)",
                                          padding: "0.25rem",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          borderWidth: "2px",
                                        }}
                                      >
                                        <h6
                                          className="text-xs"
                                          style={{
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            overflowWrap: "break-word",
                                            margin: "0px",
                                            fontWeight: 700,
                                            fontSize: "0.75rem",
                                            lineHeight: "1rem",
                                          }}
                                        >
                                          Linux
                                        </h6>
                                      </div>
                                      <div
                                        className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          borderRadius: "5px",
                                          borderColor: "rgb(185 207 202/1)",
                                          padding: "0.25rem",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          borderWidth: "2px",
                                        }}
                                      >
                                        <h6
                                          className="text-xs"
                                          style={{
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            overflowWrap: "break-word",
                                            margin: "0px",
                                            fontWeight: 700,
                                            fontSize: "0.75rem",
                                            lineHeight: "1rem",
                                          }}
                                        >
                                          GIT
                                        </h6>
                                      </div>
                                      <div
                                        className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          borderRadius: "5px",
                                          borderColor: "rgb(185 207 202/1)",
                                          padding: "0.25rem",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          borderWidth: "2px",
                                        }}
                                      >
                                        <h6
                                          className="text-xs"
                                          style={{
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            overflowWrap: "break-word",
                                            margin: "0px",
                                            fontWeight: 700,
                                            fontSize: "0.75rem",
                                            lineHeight: "1rem",
                                          }}
                                        >
                                          SQL
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
                  </div>
                }
              </div>
            </div>
            <div
              className="w-full"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              <div
                className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-700 css-1nci3hw"
                style={{
                  margin: "0px",
                  boxSizing: "border-box",
                  transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                  border: "none",
                  color: "rgba(0, 0, 0, 0.87)",
                  boxShadow: "none",
                  position: "relative",
                  overflowAnchor: "none",
                  marginTop: "0px",
                  marginBottom: "0px",
                  backgroundColor: "#242145",
                  borderRadius: "30px",
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  borderBottomLeftRadius: "30px",
                }}
              >
                <div
                  id="panel1a-header"
                  className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                  aria-controls="panel1a-content"
                  aria-expanded="true"
                  role="button"
                  tabIndex="0"
                  style={{
                    outline: "0px",
                    border: "0px",
                    margin: "0px",
                    borderRadius: "0px",
                    textDecoration: "none",
                    transition:
                      "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    WebkitBoxAlign: "center",
                    alignItems: "center",
                    WebkitBoxPack: "center",
                    justifyContent: "center",
                    position: "relative",
                    boxSizing: "border-box",
                    WebkitTapHighlightColor: "transparent",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    userSelect: "none",
                    verticalAlign: "middle",
                    appearance: "none",
                    display: "flex",
                    minHeight: "48px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "0.5rem",
                    color: "rgb(242 251 247/1)",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                  }}
                  onClick={()=>{setDsa(!dsa)}}
                >
                  <div
                    className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      WebkitBoxFlex: "1",
                      flexGrow: 1,
                      margin: "8px 0px",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        className="flex items-start gap-x-4"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                          display: "flex",
                          alignItems: "flex-start",
                          columnGap: "1rem",
                        }}
                      >
                        <div
                          className="w-[40px] min-w-[40px]"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            width: "40px",
                            minWidth: "40px",
                          }}
                        >
                          <div
                            className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflow: "hidden",
                              position: "relative",
                              display: "inline-block",
                              verticalAlign: "top",
                              maxHeight: "100%",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                maxWidth: "100px",
                                display: "block",
                              }}
                            >
                              <img
                                aria-hidden="true"
                                role="presentation"
                                src="data:image/svg+xml;charset=utf-8,<svg height='102' width='100' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                  maxWidth: "100%",
                                  display: "block",
                                  position: "static",
                                }}
                              />
                            </div>
                            <img
                              aria-hidden="true"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADCklEQVQ4y41VzUtUURT/vTca1DboD4jCVZuIoojEPsjKKCpMDQs1zDQ/Kufd+2Yko1qEVs7c98xq56KIoIUYSBK0CGphUW0KikBTKIhgNmEF835x352PN5NEi8vvvHPO/O6552sAJQAlqqDkFJSYg5ILUGLB4L9O3kd8hhITSIccAFJSCxl4gvAkl0axhL5MViID5VQBSj4KDUouQokslMwW0BNZ2xfZmO9kLa+oM/aor1jMkU4jJDKEQXg8oVF/09L6G0li+AKRSphvJfIR6ZPz1xjqFlEgM+EbsvwTRxLc9KCdV54f5JrxTiKVpOXLUlL9GyMbLJCpnMETtH0T2YHJE+TvtXz8axtXvj1NDLbSHuknoqRRMo3mhshNnig89cXcFo5ndhIfBfHJYeWJPYz1HCNuJvJPzhcnKGCxYmWVGxGsmWklvsSJ7y7te23Exhqi6QgxVk4YOSVEKhKhlsdcWvc7aF1pJLZup127ixjsLItQLEVYRpaSxGiSFZc6aF/qJmQX0d3C2MBJ4mo34ZVFGJWjZEi7YWVX3I5z+fU+WoOniJsucStB3EkSaoDLB9toe/2EcqOVLpIWyJRkhe/w2ss6/vyxjj0z+4mrvbR9l5bnEDdcbr7bzrp3zcSHOK2HvcTweWLULc2lJov5DnFtgKeeNpBczdFvu1nxtZ+xy/WscFrCnC1Tce57d5yVC2eJeYfWqzOs7DhIa7iP8N1iPsNSh33nBque9bD6WwsxL4hZh1ZTHe0De4m0oH1bhJFhtp/4LonpTlpba2j1NhNjyUD7mD7MN3RKBBiXxBuHeN1N6+JRYn010XjE5HFEEFNx4n0f8aSDaKwjNlQTTrtuoxyhDHJFEaa50zKwVZzoaiZ21BINh4mhcyZP6dBODPUS9YeM/XSznpogMtcsXQ6m4wOMJoiUY3JTknRhdHr0UnGdW+NfHL0fmnA6d4NeQcXVtJRcwL/suaDEJOC5VfBkprTrRfmsli7Z8ikxfhn4MlywCAVPTkDJ2f9b/yV/A3Pw5BR8UQVP4A9IWyPKFeUbjAAAAABJRU5ErkJggg=="
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                pointerEvents: "none",
                                display: "block",
                                verticalAlign: "middle",
                                margin: "0px",
                                padding: "0px",
                                height: "100%",
                                maxWidth: "none",
                                width: "100%",
                                transition: "opacity 500ms linear",
                                inset: "0px",
                                opacity: 0,
                                backgroundColor: "transparent",
                                position: "absolute",
                                objectFit: "cover",
                              }}
                            />
                            <picture
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "block",
                                maxWidth: "100%",
                              }}
                            >
                              <source
                                type="image/webp"
                                sizes="(min-width: 50px) 50px, 100vw"
                                srcSet="https://www.crio.do/static/c09793a19fa228714e5bebfdab9424c2/d5ef2/Two.webp 50w"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                              <img
                                placeholder="blurred"
                                height={41}
                                width={40}
                                alt="Curriculum"
                                sizes="(min-width: 50px) 50px, 100vw"
                                src="https://www.crio.do/static/c09793a19fa228714e5bebfdab9424c2/14f19/Two.png"
                                srcSet="/static/c09793a19fa228714e5bebfdab9424c2/14f19/Two.png 50w"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  display: "block",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  maxWidth: "none",
                                  position: "absolute",
                                  width: "100%",
                                  transition: "opacity 0.25s linear",
                                  transform: "translateZ(0px)",
                                  willChange: "opacity",
                                  objectFit: "contain",
                                  opacity: 1,
                                }}
                              />
                            </picture>
                            <noscript
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              {
                                '<picture><source type="image/webp" srcSet="/static/c09793a19fa228714e5bebfdab9424c2/d5ef2/Two.webp 50w" sizes="(min-width: 50px) 50px, 100vw"/><img placeholder="blurred" width="100" height="102" data-main-image="" style="object-fit:contain;opacity:0" sizes="(min-width: 50px) 50px, 100vw" decoding="async" loading="lazy" src="/static/c09793a19fa228714e5bebfdab9424c2/14f19/Two.png" srcSet="/static/c09793a19fa228714e5bebfdab9424c2/14f19/Two.png 50w" alt="Curriculum"/></picture>'
                              }
                            </noscript>
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        >
                          <h6
                            className="transition-all sm:w-full md:text-[18px]"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontWeight: 700,
                              transitionDuration: "0.15s",
                              transitionProperty: "all",
                              transitionTimingFunction:
                                "cubic-bezier(0.4, 0, 0.2, 1)",
                              width: "100%",
                              fontSize: "18px",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              Data Structure & Algorithms
                            </div>
                          </h6>
                          <p
                            className="text-sm text-v5-neutral-300"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              color: "rgb(185 207 202/1)",
                            }}
                          >
                            20 weeks
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      transition:
                        "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                      display: "flex",
                      color: "rgba(0, 0, 0, 0.54)",
                      transform: `${dsa ? 'rotate(180deg)' :'rotate(0deg)'}`,
                    }}
                  >
                    <div
                      className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        display: "flex",
                        height: "24px",
                        width: "24px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "9999px",
                        backgroundColor: "#5E35B1",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      <svg
                        className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        viewBox="0 0 320 512"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          maxWidth: "100%",
                          display: "var(--fa-display, inline-block)",
                          height: "1em",
                          verticalAlign: "-0.125em",
                          overflow: "visible",
                          boxSizing: "content-box",
                          width: "10px",
                          transform:
                            "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                          transitionDuration: "0.15s",
                          transitionProperty: "all",
                          transitionTimingFunction:
                            "cubic-bezier(0.4, 0, 0.2, 1)",
                        }}
                      >
                        <path
                          d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                          fill="currentColor"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {dsa &&
                    <div
                  className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    minHeight: "0px",
                    height: "auto",
                    transitionDuration: "313ms",
                  }}
                >
                  <div
                    className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      <div
                        id="panel1a-content"
                        className="MuiAccordion-region"
                        aria-labelledby="panel1a-header"
                        role="region"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          className="MuiAccordionDetails-root bg-v5-green-700 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            padding: "8px 16px 16px",
                            borderBottomLeftRadius: "30px",
                            borderBottomRightRadius: "30px",
                            backgroundColor: "#242145",
                            paddingBottom: "1rem",
                            paddingTop: "0px",
                            lineHeight: 1.625,
                            color: "rgb(185 207 202/1)",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                          }}
                        >
                          <div
                            className="md:pl-[54px]"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingLeft: "54px",
                            }}
                          >
                            <div
                              className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                color: "rgb(185 207 202/1)",
                                fontSize: "16px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              >
                                Master essential Data Structures and Algorithms
                                concepts from an{" "}
                                <strong
                                  className="text-v5-green-100"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    fontWeight: "bolder",
                                    color: "rgb(45 248 197/1)",
                                  }}
                                >
                                  interview perspective
                                </strong>{" "}
                                through our carefully designed curriculum,
                                exclusive videos, in-depth mentorship, live
                                sessions, and mock assessments.
                              </div>
                            </div>
                            <div
                              className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                color: "rgb(185 207 202/1)",
                                fontSize: "16px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="md:pl-[54px] md:pt-1"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingLeft: "54px",
                              paddingTop: "0.25rem",
                            }}
                          >
                            <h6
                              className="font-manrope text-white"
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                overflowWrap: "break-word",
                                margin: "0px",
                                fontWeight: 700,
                                fontSize: "1rem",
                                fontFamily: "Manrope, sans-serif",
                                color: "white",
                              }}
                            />
                            <div
                              className="mt-10 flex flex-wrap items-stretch gap-5 md:my-4"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "stretch",
                                gap: "1.25rem",
                                marginBottom: "1rem",
                                marginTop: "1rem",
                              }}
                            >
                              <div
                                className="mt-[-10px] w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  marginTop: "-10px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-500 css-1nci3hw"
                                  style={{
                                    margin: "0px",
                                    boxSizing: "border-box",
                                    transition:
                                      "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                    border: "none",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    boxShadow: "none",
                                    position: "relative",
                                    overflowAnchor: "none",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    backgroundColor: "#5E35B1",
                                    borderRadius: "30px",
                                    borderTopLeftRadius: "30px",
                                    borderTopRightRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                    borderBottomLeftRadius: "30px",
                                  }}
                                >
                                  <div
                                    id="panel1a-header"
                                    className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                                    aria-controls="panel1a-content"
                                    aria-expanded="true"
                                    role="button"
                                    tabIndex="0"
                                    style={{
                                      outline: "0px",
                                      border: "0px",
                                      margin: "0px",
                                      borderRadius: "0px",
                                      textDecoration: "none",
                                      transition:
                                        "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                      WebkitBoxAlign: "center",
                                      alignItems: "center",
                                      WebkitBoxPack: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      boxSizing: "border-box",
                                      WebkitTapHighlightColor: "transparent",
                                      backgroundColor: "transparent",
                                      cursor: "pointer",
                                      userSelect: "none",
                                      verticalAlign: "middle",
                                      appearance: "none",
                                      display: "flex",
                                      minHeight: "48px",
                                      width: "100%",
                                      overflow: "hidden",
                                      padding: "0.5rem",
                                      color: "rgb(242 251 247/1)",
                                      paddingLeft: "1.5rem",
                                      paddingRight: "1.5rem",
                                    }}
                                  >
                                    <div
                                      className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                                      style={{
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        WebkitBoxFlex: "1",
                                        flexGrow: 1,
                                        margin: "8px 0px",
                                      }}
                                      onClick={() => setDsa1(!dsa1)}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        <div
                                          className="flex items-start gap-x-4"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            columnGap: "1rem",
                                          }}
                                        >
                                          <div
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            <h6
                                              className="transition-all sm:w-full"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontWeight: 700,
                                                fontSize: "1rem",
                                                transitionDuration: "0.15s",
                                                transitionProperty: "all",
                                                transitionTimingFunction:
                                                  "cubic-bezier(0.4, 0, 0.2, 1)",
                                                width: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                }}
                                              >
                                                DSA Basics
                                              </div>
                                            </h6>
                                            <p
                                              className="text-sm text-v5-neutral-300"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontSize: "0.875rem",
                                                lineHeight: "1.25rem",
                                                color: "rgb(185 207 202/1)",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        transition:
                                          "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                        display: "flex",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        transform: `${dsa1 ? 'rotate(180deg)' :'rotate(0deg)'}`,
                                      }}
                                    >
                                      <div
                                        className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-700 text-v5-neutral-300"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          display: "flex",
                                          height: "24px",
                                          width: "24px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "9999px",
                                          backgroundColor: "#242145",
                                          color: "rgb(185 207 202/1)",
                                        }}
                                      >
                                        <svg
                                          className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                                          aria-hidden="true"
                                          focusable="false"
                                          role="img"
                                          viewBox="0 0 320 512"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            maxWidth: "100%",
                                            display:
                                              "var(--fa-display, inline-block)",
                                            height: "1em",
                                            verticalAlign: "-0.125em",
                                            overflow: "visible",
                                            boxSizing: "content-box",
                                            width: "10px",
                                            transform:
                                              "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                                            transitionDuration: "0.15s",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                              "cubic-bezier(0.4, 0, 0.2, 1)",
                                          }}
                                        >
                                          <path
                                            d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                            fill="currentColor"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  {dsa1 &&
                                    <div
                                    className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      minHeight: "0px",
                                      height: "auto",
                                      transitionDuration: "303ms",
                                    }}
                                  >
                                    <div
                                      className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          id="panel1a-content"
                                          className="MuiAccordion-region"
                                          aria-labelledby="panel1a-header"
                                          role="region"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <div
                                            className="MuiAccordionDetails-root bg-v5-green-500 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              padding: "8px 16px 16px",
                                              borderBottomLeftRadius: "30px",
                                              borderBottomRightRadius: "30px",
                                              backgroundColor: "#5E35B1",
                                              paddingBottom: "1rem",
                                              paddingTop: "0px",
                                              lineHeight: 1.625,
                                              color: "rgb(185 207 202/1)",
                                              paddingLeft: "1.5rem",
                                              paddingRight: "1.5rem",
                                            }}
                                          >
                                            <hr
                                              className="mb-5 border-b border-crio-green-400 opacity-20"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                backgroundColor:
                                                  "rgb(227, 225, 213)",
                                                borderTopWidth: "1px",
                                                color: "inherit",
                                                height: "0px",
                                                margin: "0px",
                                                padding: "0px",
                                                marginBottom: "1.25rem",
                                                borderBottomWidth: "1px",
                                                borderColor:
                                                  "rgb(14 178 159/1)",
                                                opacity: 0.2,
                                              }}
                                            />
                                            <div
                                              className="mt-2"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                marginTop: "0.5rem",
                                              }}
                                            >
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                    }}
                                                  >
                                                    Build problem solving skills
                                                    with the commonly used Data
                                                    Structures. Learn the basics
                                                    of{" "}
                                                    <strong
                                                      className="text-v5-green-100"
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        fontWeight: "bolder",
                                                        color:
                                                          "rgb(45 248 197/1)",
                                                      }}
                                                    >
                                                      Space and Time complexity
                                                    </strong>{" "}
                                                    which drive optimization.
                                                    <ul
                                                      style={{
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        listStyle: "none",
                                                        padding: "0px",
                                                        listStyleType: "disc",
                                                        margin: "1em 0px",
                                                        display: "block",
                                                        paddingLeft: "40px",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        Programming Essentials
                                                      </li>
                                                      <li
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        Develop logic building
                                                        skills by solving simple
                                                        math, recursion,
                                                        implementation problems
                                                      </li>
                                                      <li
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        Learn Time & Space
                                                        complexity
                                                      </li>
                                                      <li
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        Get introduced to data
                                                        structures & traversals
                                                        - Linkedlist & Trees
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                />
                                              </div>
                                              <div
                                                className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  marginTop: "0.75rem",
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  columnGap: "0.75rem",
                                                  rowGap: "0.75rem",
                                                }}
                                              >
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Logic Building
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Collections
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Space and Time Complexity
                                                  </h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    </div>
                                  }
                                </div>
                              </div>
                              <div
                                className="mt-[-10px] w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  marginTop: "-10px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-500 css-1nci3hw"
                                  style={{
                                    margin: "0px",
                                    boxSizing: "border-box",
                                    transition:
                                      "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                    border: "none",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    boxShadow: "none",
                                    position: "relative",
                                    overflowAnchor: "none",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    backgroundColor: "#5E35B1",
                                    borderRadius: "30px",
                                    borderTopLeftRadius: "30px",
                                    borderTopRightRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                    borderBottomLeftRadius: "30px",
                                  }}
                                >
                                  <div
                                    id="panel1a-header"
                                    className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                                    aria-controls="panel1a-content"
                                    aria-expanded="true"
                                    role="button"
                                    tabIndex="0"
                                    style={{
                                      outline: "0px",
                                      border: "0px",
                                      margin: "0px",
                                      borderRadius: "0px",
                                      textDecoration: "none",
                                      transition:
                                        "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                      WebkitBoxAlign: "center",
                                      alignItems: "center",
                                      WebkitBoxPack: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      boxSizing: "border-box",
                                      WebkitTapHighlightColor: "transparent",
                                      backgroundColor: "transparent",
                                      cursor: "pointer",
                                      userSelect: "none",
                                      verticalAlign: "middle",
                                      appearance: "none",
                                      display: "flex",
                                      minHeight: "48px",
                                      width: "100%",
                                      overflow: "hidden",
                                      padding: "0.5rem",
                                      color: "rgb(242 251 247/1)",
                                      paddingLeft: "1.5rem",
                                      paddingRight: "1.5rem",
                                    }}
                                  >
                                    <div
                                      className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                                      style={{
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        WebkitBoxFlex: "1",
                                        flexGrow: 1,
                                        margin: "8px 0px",
                                      }}
                                      onClick={()=>setDsa2(!dsa2)}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        <div
                                          className="flex items-start gap-x-4"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            columnGap: "1rem",
                                          }}
                                        >
                                          <div
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            <h6
                                              className="transition-all sm:w-full"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontWeight: 700,
                                                fontSize: "1rem",
                                                transitionDuration: "0.15s",
                                                transitionProperty: "all",
                                                transitionTimingFunction:
                                                  "cubic-bezier(0.4, 0, 0.2, 1)",
                                                width: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                }}
                                              >
                                                DSA Intermediate
                                              </div>
                                            </h6>
                                            <p
                                              className="text-sm text-v5-neutral-300"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontSize: "0.875rem",
                                                lineHeight: "1.25rem",
                                                color: "rgb(185 207 202/1)",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        transition:
                                          "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                        display: "flex",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        transform: `${dsa2 ? 'rotate(180deg)' :'rotate(0deg)'}`,
                                      }}
                                    >
                                      <div
                                        className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-700 text-v5-neutral-300"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          display: "flex",
                                          height: "24px",
                                          width: "24px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "9999px",
                                          backgroundColor: "#242145",
                                          color: "rgb(185 207 202/1)",
                                        }}
                                      >
                                        <svg
                                          className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                                          aria-hidden="true"
                                          focusable="false"
                                          role="img"
                                          viewBox="0 0 320 512"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            maxWidth: "100%",
                                            display:
                                              "var(--fa-display, inline-block)",
                                            height: "1em",
                                            verticalAlign: "-0.125em",
                                            overflow: "visible",
                                            boxSizing: "content-box",
                                            width: "10px",
                                            transform:
                                              "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                                            transitionDuration: "0.15s",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                              "cubic-bezier(0.4, 0, 0.2, 1)",
                                          }}
                                        >
                                          <path
                                            d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                            fill="currentColor"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  {dsa2 &&
                                    <div
                                    className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      minHeight: "0px",
                                      height: "auto",
                                      transitionDuration: "383ms",
                                    }}
                                  >
                                    <div
                                      className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          id="panel1a-content"
                                          className="MuiAccordion-region"
                                          aria-labelledby="panel1a-header"
                                          role="region"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <div
                                            className="MuiAccordionDetails-root bg-v5-green-500 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              padding: "8px 16px 16px",
                                              borderBottomLeftRadius: "30px",
                                              borderBottomRightRadius: "30px",
                                              backgroundColor: "#5E35B1",
                                              paddingBottom: "1rem",
                                              paddingTop: "0px",
                                              lineHeight: 1.625,
                                              color: "rgb(185 207 202/1)",
                                              paddingLeft: "1.5rem",
                                              paddingRight: "1.5rem",
                                            }}
                                          >
                                            <hr
                                              className="mb-5 border-b border-crio-green-400 opacity-20"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                backgroundColor:
                                                  "rgb(227, 225, 213)",
                                                borderTopWidth: "1px",
                                                color: "inherit",
                                                height: "0px",
                                                margin: "0px",
                                                padding: "0px",
                                                marginBottom: "1.25rem",
                                                borderBottomWidth: "1px",
                                                borderColor:
                                                  "rgb(14 178 159/1)",
                                                opacity: 0.2,
                                              }}
                                            />
                                            <div
                                              className="mt-2"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                marginTop: "0.5rem",
                                              }}
                                            >
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                >
                                                  Master problem solving
                                                  patterns and practice reducing
                                                  the most frequently asked
                                                  problems to known patterns
                                                </div>
                                              </div>
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              className="md:pt-1"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                paddingTop: "0.25rem",
                                              }}
                                            >
                                              <h6
                                                className="font-manrope text-white"
                                                style={{
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  overflowWrap: "break-word",
                                                  margin: "0px",
                                                  fontWeight: 700,
                                                  fontSize: "1rem",
                                                  fontFamily:
                                                    "Manrope, sans-serif",
                                                  color: "white",
                                                }}
                                              />
                                              <div
                                                className="mb-4 mt-1 grid justify-items-center gap-5 md:grid-cols-2"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  marginTop: "0.25rem",
                                                  display: "grid",
                                                  justifyItems: "center",
                                                  gap: "1.25rem",
                                                  gridTemplateColumns:
                                                    "repeat(2, minmax(0px, 1fr))",
                                                }}
                                              >
                                                <div
                                                  className="w-full"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <div
                                                    className="h-full w-full rounded-[20px] bg-v5-green-700 text-v5-neutral-300 p-4"
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      height: "100%",
                                                      width: "100%",
                                                      borderRadius: "20px",
                                                      backgroundColor:
                                                        "#242145",
                                                      padding: "1rem",
                                                      color:
                                                        "rgb(185 207 202/1)",
                                                    }}
                                                  >
                                                    <h6
                                                      className="mb-2 text-white"
                                                      style={{
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        overflowWrap:
                                                          "break-word",
                                                        margin: "0px",
                                                        fontWeight: 700,
                                                        fontSize: "1rem",
                                                        marginBottom: "0.5rem",
                                                        color:
                                                          "white",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        Arrays & Strings
                                                      </div>
                                                    </h6>
                                                    <div
                                                      className="md:text-md text-sm"
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        fontSize: "0.875rem",
                                                        lineHeight: "1.25rem",
                                                      }}
                                                    >
                                                      <div
                                                        className="mb-2 flex items-start gap-x-2"
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                          marginBottom:
                                                            "0.5rem",
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                          columnGap: "0.5rem",
                                                        }}
                                                      >
                                                        <div
                                                          className="leading-relaxed"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            lineHeight: 1.625,
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                            }}
                                                          >
                                                            <ul
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                listStyle:
                                                                  "none",
                                                                padding: "0px",
                                                                listStyleType:
                                                                  "disc",
                                                                margin:
                                                                  "1em 0px",
                                                                display:
                                                                  "block",
                                                                paddingLeft:
                                                                  "30px",
                                                              }}
                                                            >
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Learn various
                                                                patterns of
                                                                problems solving
                                                                in arrays &
                                                                strings and
                                                                apply them in a
                                                                wide variety of
                                                                problems.
                                                              </li>
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Learn the art of
                                                                debugging that
                                                                is critical for
                                                                your timed
                                                                interviews.
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        <div
                                                          className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            marginBottom:
                                                              "1rem",
                                                            marginTop:
                                                              "0.75rem",
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            columnGap:
                                                              "0.75rem",
                                                            rowGap: "0.75rem",
                                                          }}
                                                        >
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Matrix
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Two Pointers
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Sliding Window
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Sorting
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Prefix Sum
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Bit Manipulation
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Binary Search
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="w-full"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <div
                                                    className="h-full w-full rounded-[20px] bg-v5-green-700 text-v5-neutral-300 p-4"
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      height: "100%",
                                                      width: "100%",
                                                      borderRadius: "20px",
                                                      backgroundColor:
                                                        "#242145",
                                                      padding: "1rem",
                                                      color:
                                                        "rgb(185 207 202/1)",
                                                    }}
                                                  >
                                                    <h6
                                                      className="mb-2 text-white"
                                                      style={{
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        overflowWrap:
                                                          "break-word",
                                                        margin: "0px",
                                                        fontWeight: 700,
                                                        fontSize: "1rem",
                                                        marginBottom: "0.5rem",
                                                        color:
                                                          "white",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        Stack, Queue and Linked
                                                        List
                                                      </div>
                                                    </h6>
                                                    <div
                                                      className="md:text-md text-sm"
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        fontSize: "0.875rem",
                                                        lineHeight: "1.25rem",
                                                      }}
                                                    >
                                                      <div
                                                        className="mb-2 flex items-start gap-x-2"
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                          marginBottom:
                                                            "0.5rem",
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                          columnGap: "0.5rem",
                                                        }}
                                                      >
                                                        <div
                                                          className="leading-relaxed"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            lineHeight: 1.625,
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                            }}
                                                          >
                                                            <ul
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                listStyle:
                                                                  "none",
                                                                padding: "0px",
                                                                listStyleType:
                                                                  "disc",
                                                                margin:
                                                                  "1em 0px",
                                                                display:
                                                                  "block",
                                                                paddingLeft:
                                                                  "30px",
                                                              }}
                                                            >
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Learn Linked
                                                                List
                                                                implementation
                                                                and applications
                                                              </li>
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Apply Queue,
                                                                Stack to solve
                                                                real world
                                                                problems
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        <div
                                                          className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            marginBottom:
                                                              "1rem",
                                                            marginTop:
                                                              "0.75rem",
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            columnGap:
                                                              "0.75rem",
                                                            rowGap: "0.75rem",
                                                          }}
                                                        >
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Data Structure
                                                              Application
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Linked List
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Stack
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Queue
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="col-span-full w-full"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    gridColumn: "1 / -1",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <div
                                                    className="h-full w-full rounded-[20px] bg-v5-green-700 text-v5-neutral-300 p-4"
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      height: "100%",
                                                      width: "100%",
                                                      borderRadius: "20px",
                                                      backgroundColor:
                                                        "#242145",
                                                      padding: "1rem",
                                                      color:
                                                        "rgb(185 207 202/1)",
                                                    }}
                                                  >
                                                    <h6
                                                      className="mb-2 text-white"
                                                      style={{
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        overflowWrap:
                                                          "break-word",
                                                        margin: "0px",
                                                        fontWeight: 700,
                                                        fontSize: "1rem",
                                                        marginBottom: "0.5rem",
                                                        color:
                                                          "white",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        Hash, Trees
                                                      </div>
                                                    </h6>
                                                    <div
                                                      className="md:text-md text-sm"
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        fontSize: "0.875rem",
                                                        lineHeight: "1.25rem",
                                                      }}
                                                    >
                                                      <div
                                                        className="mb-2 flex items-start gap-x-2"
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                          marginBottom:
                                                            "0.5rem",
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                          columnGap: "0.5rem",
                                                        }}
                                                      >
                                                        <div
                                                          className="leading-relaxed"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            lineHeight: 1.625,
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                            }}
                                                          >
                                                            <ul
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                listStyle:
                                                                  "none",
                                                                padding: "0px",
                                                                listStyleType:
                                                                  "disc",
                                                                margin:
                                                                  "1em 0px",
                                                                display:
                                                                  "block",
                                                                paddingLeft:
                                                                  "30px",
                                                              }}
                                                            >
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Apply Hash to
                                                                solve real world
                                                                problems
                                                              </li>
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Learn BFS/DFS to
                                                                solve multiple
                                                                Tree problems
                                                                effectively
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        <div
                                                          className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            marginBottom:
                                                              "1rem",
                                                            marginTop:
                                                              "0.75rem",
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            columnGap:
                                                              "0.75rem",
                                                            rowGap: "0.75rem",
                                                          }}
                                                        >
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Data Structure
                                                              Application
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Hash
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Binary Tree
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Binary Search Tree
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              BFS
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              DFS
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Recursion
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    </div>
                                  }
                                </div>
                              </div>
                              <div
                                className="mt-[-10px] w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  marginTop: "-10px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-500 css-1nci3hw"
                                  style={{
                                    margin: "0px",
                                    boxSizing: "border-box",
                                    transition:
                                      "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                    border: "none",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    boxShadow: "none",
                                    position: "relative",
                                    overflowAnchor: "none",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    backgroundColor: "#5E35B1",
                                    borderRadius: "30px",
                                    borderTopLeftRadius: "30px",
                                    borderTopRightRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                    borderBottomLeftRadius: "30px",
                                  }}
                                >
                                  <div
                                    id="panel1a-header"
                                    className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                                    aria-controls="panel1a-content"
                                    aria-expanded="true"
                                    role="button"
                                    tabIndex="0"
                                    style={{
                                      outline: "0px",
                                      border: "0px",
                                      margin: "0px",
                                      borderRadius: "0px",
                                      textDecoration: "none",
                                      transition:
                                        "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                      WebkitBoxAlign: "center",
                                      alignItems: "center",
                                      WebkitBoxPack: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      boxSizing: "border-box",
                                      WebkitTapHighlightColor: "transparent",
                                      backgroundColor: "transparent",
                                      cursor: "pointer",
                                      userSelect: "none",
                                      verticalAlign: "middle",
                                      appearance: "none",
                                      display: "flex",
                                      minHeight: "48px",
                                      width: "100%",
                                      overflow: "hidden",
                                      padding: "0.5rem",
                                      color: "rgb(242 251 247/1)",
                                      paddingLeft: "1.5rem",
                                      paddingRight: "1.5rem",
                                    }}
                                  >
                                    <div
                                      className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                                      style={{
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        WebkitBoxFlex: "1",
                                        flexGrow: 1,
                                        margin: "8px 0px",
                                      }}
                                      onClick={()=>setDsa3(!dsa3)}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        <div
                                          className="flex items-start gap-x-4"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            columnGap: "1rem",
                                          }}
                                        >
                                          <div
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            <h6
                                              className="transition-all sm:w-full"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontWeight: 700,
                                                fontSize: "1rem",
                                                transitionDuration: "0.15s",
                                                transitionProperty: "all",
                                                transitionTimingFunction:
                                                  "cubic-bezier(0.4, 0, 0.2, 1)",
                                                width: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                }}
                                              >
                                                DSA Advanced
                                              </div>
                                            </h6>
                                            <p
                                              className="text-sm text-v5-neutral-300"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontSize: "0.875rem",
                                                lineHeight: "1.25rem",
                                                color: "rgb(185 207 202/1)",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        transition:
                                          "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                        display: "flex",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        transform: `${dsa3 ? 'rotate(180deg)' :'rotate(0deg)'}`,
                                      }}
                                    >
                                      <div
                                        className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-700 text-v5-neutral-300"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          display: "flex",
                                          height: "24px",
                                          width: "24px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "9999px",
                                          backgroundColor: "#242145",
                                          color: "rgb(185 207 202/1)",
                                        }}
                                      >
                                        <svg
                                          className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                                          aria-hidden="true"
                                          focusable="false"
                                          role="img"
                                          viewBox="0 0 320 512"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            maxWidth: "100%",
                                            display:
                                              "var(--fa-display, inline-block)",
                                            height: "1em",
                                            verticalAlign: "-0.125em",
                                            overflow: "visible",
                                            boxSizing: "content-box",
                                            width: "10px",
                                            transform:
                                              "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                                            transitionDuration: "0.15s",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                              "cubic-bezier(0.4, 0, 0.2, 1)",
                                          }}
                                        >
                                          <path
                                            d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                            fill="currentColor"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  {dsa3 &&
                                    <div
                                    className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      minHeight: "0px",
                                      height: "auto",
                                      transitionDuration: "361ms",
                                    }}
                                  >
                                    <div
                                      className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          id="panel1a-content"
                                          className="MuiAccordion-region"
                                          aria-labelledby="panel1a-header"
                                          role="region"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <div
                                            className="MuiAccordionDetails-root bg-v5-green-500 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              padding: "8px 16px 16px",
                                              borderBottomLeftRadius: "30px",
                                              borderBottomRightRadius: "30px",
                                              backgroundColor: "#5E35B1",
                                              paddingBottom: "1rem",
                                              paddingTop: "0px",
                                              lineHeight: 1.625,
                                              color: "rgb(185 207 202/1)",
                                              paddingLeft: "1.5rem",
                                              paddingRight: "1.5rem",
                                            }}
                                          >
                                            <hr
                                              className="mb-5 border-b border-crio-green-400 opacity-20"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                backgroundColor:
                                                  "rgb(227, 225, 213)",
                                                borderTopWidth: "1px",
                                                color: "inherit",
                                                height: "0px",
                                                margin: "0px",
                                                padding: "0px",
                                                marginBottom: "1.25rem",
                                                borderBottomWidth: "1px",
                                                borderColor:
                                                  "rgb(14 178 159/1)",
                                                opacity: 0.2,
                                              }}
                                            />
                                            <div
                                              className="mt-2"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                marginTop: "0.5rem",
                                              }}
                                            >
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                >
                                                  Step up your{" "}
                                                  <strong
                                                    className="text-v5-green-100"
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      fontWeight: "bolder",
                                                      color:
                                                        "rgb(45 248 197/1)",
                                                    }}
                                                  >
                                                    interview cracking ability
                                                  </strong>{" "}
                                                  by learning advanced Data
                                                  structures and Algorithms
                                                  through our carefully designed
                                                  curriculum, exclusive videos,
                                                  in-depth mentorship, live
                                                  sessions, practice with
                                                  curated problems and timed
                                                  assessments.
                                                </div>
                                              </div>
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              className="md:pt-1"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                paddingTop: "0.25rem",
                                              }}
                                            >
                                              <h6
                                                className="font-manrope text-white"
                                                style={{
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  overflowWrap: "break-word",
                                                  margin: "0px",
                                                  fontWeight: 700,
                                                  fontSize: "1rem",
                                                  fontFamily:
                                                    "Manrope, sans-serif",
                                                  color: "white",
                                                }}
                                              />
                                              <div
                                                className="mb-4 mt-1 grid justify-items-center gap-5 md:grid-cols-2"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  marginTop: "0.25rem",
                                                  display: "grid",
                                                  justifyItems: "center",
                                                  gap: "1.25rem",
                                                  gridTemplateColumns:
                                                    "repeat(2, minmax(0px, 1fr))",
                                                }}
                                              >
                                                <div
                                                  className="w-full"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <div
                                                    className="h-full w-full rounded-[20px] bg-v5-green-700 text-v5-neutral-300 p-4"
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      height: "100%",
                                                      width: "100%",
                                                      borderRadius: "20px",
                                                      backgroundColor:
                                                        "#242145",
                                                      padding: "1rem",
                                                      color:
                                                        "rgb(185 207 202/1)",
                                                    }}
                                                  >
                                                    <h6
                                                      className="mb-2 text-white"
                                                      style={{
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        overflowWrap:
                                                          "break-word",
                                                        margin: "0px",
                                                        fontWeight: 700,
                                                        fontSize: "1rem",
                                                        marginBottom: "0.5rem",
                                                        color:
                                                          "white",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        Heap, Graphs, Greedy and
                                                        Backtracking
                                                      </div>
                                                    </h6>
                                                    <div
                                                      className="md:text-md text-sm"
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        fontSize: "0.875rem",
                                                        lineHeight: "1.25rem",
                                                      }}
                                                    >
                                                      <div
                                                        className="mb-2 flex items-start gap-x-2"
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                          marginBottom:
                                                            "0.5rem",
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                          columnGap: "0.5rem",
                                                        }}
                                                      >
                                                        <div
                                                          className="leading-relaxed"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            lineHeight: 1.625,
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                            }}
                                                          >
                                                            <ul
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                listStyle:
                                                                  "none",
                                                                padding: "0px",
                                                                listStyleType:
                                                                  "disc",
                                                                margin:
                                                                  "1em 0px",
                                                                display:
                                                                  "block",
                                                                paddingLeft:
                                                                  "30px",
                                                              }}
                                                            >
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Deep dive into
                                                                graph problem
                                                                patterns and
                                                                implement common
                                                                algorithms like
                                                                Prim’s,
                                                                Kruskal’s and
                                                                Dijkstra's
                                                              </li>
                                                              Learn common
                                                              problem solving
                                                              approaches like
                                                              greedy &
                                                              backtracking
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Learn common
                                                                problem solving
                                                                approaches like
                                                                greedy &
                                                                backtracking
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        <div
                                                          className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            marginBottom:
                                                              "1rem",
                                                            marginTop:
                                                              "0.75rem",
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            columnGap:
                                                              "0.75rem",
                                                            rowGap: "0.75rem",
                                                          }}
                                                        >
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Graph BFS
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Graph DFS
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Heaps
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Shortest Path
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Graph Partitioning
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Greedy Method
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Advanced recursion
                                                              and backtracking
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Topological Sort
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="w-full"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <div
                                                    className="h-full w-full rounded-[20px] bg-v5-green-700 text-v5-neutral-300 p-4"
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      height: "100%",
                                                      width: "100%",
                                                      borderRadius: "20px",
                                                      backgroundColor:
                                                        "#242145",
                                                      padding: "1rem",
                                                      color:
                                                        "rgb(185 207 202/1)",
                                                    }}
                                                  >
                                                    <h6
                                                      className="mb-2 text-white"
                                                      style={{
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        overflowWrap:
                                                          "break-word",
                                                        margin: "0px",
                                                        fontWeight: 700,
                                                        fontSize: "1rem",
                                                        marginBottom: "0.5rem",
                                                        color:
                                                          "white",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        Dynamic Programming and
                                                        Advanced DS
                                                      </div>
                                                    </h6>
                                                    <div
                                                      className="md:text-md text-sm"
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        fontSize: "0.875rem",
                                                        lineHeight: "1.25rem",
                                                      }}
                                                    >
                                                      <div
                                                        className="mb-2 flex items-start gap-x-2"
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                          marginBottom:
                                                            "0.5rem",
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                          columnGap: "0.5rem",
                                                        }}
                                                      >
                                                        <div
                                                          className="leading-relaxed"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            lineHeight: 1.625,
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                            }}
                                                          >
                                                            <ul
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                listStyle:
                                                                  "none",
                                                                padding: "0px",
                                                                listStyleType:
                                                                  "disc",
                                                                margin:
                                                                  "1em 0px",
                                                                display:
                                                                  "block",
                                                                paddingLeft:
                                                                  "30px",
                                                              }}
                                                            >
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Learn the art of
                                                                DP and practice
                                                                problems using
                                                                Memoization and
                                                                Tabulation
                                                              </li>
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Get introduced
                                                                to advanced DS
                                                                like Trie,
                                                                Segment Trees
                                                                and solve
                                                                related problems
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        <div
                                                          className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            marginBottom:
                                                              "1rem",
                                                            marginTop:
                                                              "0.75rem",
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            columnGap:
                                                              "0.75rem",
                                                            rowGap: "0.75rem",
                                                          }}
                                                        >
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              DP Memoization
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              DP Tabulations
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Knapsack
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Spanning Tree
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Trie
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Segment Tree
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    </div>
                }
              </div>
            </div>
            <div
              className="w-full"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              <div
                className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-700 css-1nci3hw"
                style={{
                  margin: "0px",
                  boxSizing: "border-box",
                  transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                  border: "none",
                  color: "rgba(0, 0, 0, 0.87)",
                  boxShadow: "none",
                  position: "relative",
                  overflowAnchor: "none",
                  marginTop: "0px",
                  marginBottom: "0px",
                  backgroundColor: "#242145",
                  borderRadius: "30px",
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  borderBottomLeftRadius: "30px",
                }}
              >
                <div
                  id="panel1a-header"
                  className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                  aria-controls="panel1a-content"
                  aria-expanded="true"
                  role="button"
                  tabIndex="0"
                  style={{
                    outline: "0px",
                    border: "0px",
                    margin: "0px",
                    borderRadius: "0px",
                    textDecoration: "none",
                    transition:
                      "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    WebkitBoxAlign: "center",
                    alignItems: "center",
                    WebkitBoxPack: "center",
                    justifyContent: "center",
                    position: "relative",
                    boxSizing: "border-box",
                    WebkitTapHighlightColor: "transparent",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    userSelect: "none",
                    verticalAlign: "middle",
                    appearance: "none",
                    display: "flex",
                    minHeight: "48px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "0.5rem",
                    color: "rgb(242 251 247/1)",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                  }}
                  onClick={()=>{setTechStack(!techStack)}}
                >
                  <div
                    className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      WebkitBoxFlex: "1",
                      flexGrow: 1,
                      margin: "8px 0px",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        className="flex items-start gap-x-4"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                          display: "flex",
                          alignItems: "flex-start",
                          columnGap: "1rem",
                        }}
                      >
                        <div
                          className="w-[40px] min-w-[40px]"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            width: "40px",
                            minWidth: "40px",
                          }}
                        >
                          <div
                            className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflow: "hidden",
                              position: "relative",
                              display: "inline-block",
                              verticalAlign: "top",
                              maxHeight: "100%",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                maxWidth: "100px",
                                display: "block",
                              }}
                            >
                              <img
                                aria-hidden="true"
                                role="presentation"
                                src="data:image/svg+xml;charset=utf-8,<svg height='102' width='100' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                  maxWidth: "100%",
                                  display: "block",
                                  position: "static",
                                }}
                              />
                            </div>
                            <img
                              aria-hidden="true"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADZUlEQVQ4y41VW2tcVRT+9olPSvAneHkwNUgpQQXfrKIiSZs20YmZhHppU5O0TGl1Zu8zZ2K11EtjLnP2mcnFSrygoIJgYkM1oFRftE9VCwUpVCuRluJDpLRR6JxPzt47c2ZAxIfFXnPWt7699l7fXgNoCWjZBq1OQsuL0GoVWq7a9b9sAyN/g5aLCA0HgLJKnDVEkoiUM2lNN/j/+k2lpuUadKEN0OqEA64jUjVEstZSljUvlMaHVjVo6czGk1iCQSXBmPi6I12BIdJmtxhaxZjyY7wTEHMBMWlAGxXYdUoR8wENZtKPEcrY5dIQu/JjmyDj1qWDLKzuYPdPWeJDRcz4lqjiSD/I88lzWeZ/6Wbr8iFivljPNTjDHkl6ZRlj+RWOXukl/76Tf7CDm757hnjjAEUSn3yReH+MHReG+ef1dpJ3cfhyL1Heb3PNtcnYESoiLMRieYI4PcFtvz/HzZ9miEceo3iimxgdIHb10jt2gDhd5b3nc9z+Yx8x2EX07CTmXJWO0B4plMTbJYqvI2LhMHHfViKbIabzabzq01t8k/himujcRjz8OHE0R1R8OkKi7pgLzxMLL7Fl3wC9oX7irVJ6d0m8XCBmi/SO5djS22WlM1NM4+4O0y4mNhfQ25OhODxCzAa2skg2xYUaord/kDg+ZjdpzK8L2bad3kxA7M4Scphi1u2u0wQv+eaPEM/vstj6CZuObEnFlDTHvP3bId5yIkeEAUWYViemC0RYYutyjred2murLatmnW5U6JUl8XGR/Rf6eGO9nWev3887zowSs76pVFSLxLtjbP9hL89f7WDtr3b2Xxog3pP0phsrdLIR5UKMlXHe8/Mhfnb1Qb6+2smbizuIp3ZS7Buk2N1HBEO89czLnLzSyaVrW3n3Qg/xbIai6jfJpq5D76NXiW/miK+OED1dxAOPEqURYuIF4jUrcHxZIb7XRDBIbHmIYvTppPONhO4dW1nE3idH6c2X6HVvJ8YP2k4nOqvaJyiOB7xpaZxeNkOR35PcY0oWmaYkU2aDVNrHHhZSjTU0pS7wxE8wM6Yya7Yx1xLCFZfgxleDaZmuuuF3Y9x+W3dN+RyI/DZEaq2upbouZfOwbRq+ik14i11DRZkBC+NEahFa/fr/xn/T38BFROokKrINkcQ/p7wq4TQLPiYAAAAASUVORK5CYII="
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                pointerEvents: "none",
                                display: "block",
                                verticalAlign: "middle",
                                margin: "0px",
                                padding: "0px",
                                height: "100%",
                                maxWidth: "none",
                                width: "100%",
                                transition: "opacity 500ms linear",
                                inset: "0px",
                                opacity: 0,
                                backgroundColor: "transparent",
                                position: "absolute",
                                objectFit: "cover",
                              }}
                            />
                            <picture
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "block",
                                maxWidth: "100%",
                              }}
                            >
                              <source
                                type="image/webp"
                                sizes="(min-width: 50px) 50px, 100vw"
                                srcSet="https://www.crio.do/static/9f9b145a00dbbb84d26a5eef990d82f3/d5ef2/Three.webp 50w"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                              <img
                                placeholder="blurred"
                                height={41}
                                width={40}
                                alt="Curriculum"
                                sizes="(min-width: 50px) 50px, 100vw"
                                src="https://www.crio.do/static/9f9b145a00dbbb84d26a5eef990d82f3/14f19/Three.png"
                                srcSet="/static/9f9b145a00dbbb84d26a5eef990d82f3/14f19/Three.png 50w"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  display: "block",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  maxWidth: "none",
                                  position: "absolute",
                                  width: "100%",
                                  transition: "opacity 0.25s linear",
                                  transform: "translateZ(0px)",
                                  willChange: "opacity",
                                  objectFit: "contain",
                                  opacity: 1,
                                }}
                              />
                            </picture>
                            <noscript
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              {
                                '<picture><source type="image/webp" srcSet="/static/9f9b145a00dbbb84d26a5eef990d82f3/d5ef2/Three.webp 50w" sizes="(min-width: 50px) 50px, 100vw"/><img placeholder="blurred" width="100" height="102" data-main-image="" style="object-fit:contain;opacity:0" sizes="(min-width: 50px) 50px, 100vw" decoding="async" loading="lazy" src="/static/9f9b145a00dbbb84d26a5eef990d82f3/14f19/Three.png" srcSet="/static/9f9b145a00dbbb84d26a5eef990d82f3/14f19/Three.png 50w" alt="Curriculum"/></picture>'
                              }
                            </noscript>
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        >
                          <h6
                            className="transition-all sm:w-full md:text-[18px]"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontWeight: 700,
                              transitionDuration: "0.15s",
                              transitionProperty: "all",
                              transitionTimingFunction:
                                "cubic-bezier(0.4, 0, 0.2, 1)",
                              width: "100%",
                              fontSize: "18px",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              Tech Stack Specialization & Work-like Projects
                            </div>
                          </h6>
                          <p
                            className="text-sm text-v5-neutral-300"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              color: "rgb(185 207 202/1)",
                            }}
                          >
                            16 to 28 weeks
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      transition:
                        "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                      display: "flex",
                      color: "rgba(0, 0, 0, 0.54)",
                      transform: `${techStack ? 'rotate(180deg)' :'rotate(0deg)'}`,
                    }}
                  >
                    <div
                      className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        display: "flex",
                        height: "24px",
                        width: "24px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "9999px",
                        backgroundColor: "#5E35B1",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      <svg
                        className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        viewBox="0 0 320 512"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          maxWidth: "100%",
                          display: "var(--fa-display, inline-block)",
                          height: "1em",
                          verticalAlign: "-0.125em",
                          overflow: "visible",
                          boxSizing: "content-box",
                          width: "10px",
                          transform:
                            "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                          transitionDuration: "0.15s",
                          transitionProperty: "all",
                          transitionTimingFunction:
                            "cubic-bezier(0.4, 0, 0.2, 1)",
                        }}
                      >
                        <path
                          d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                          fill="currentColor"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {techStack &&
                    <div
                  className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    minHeight: "0px",
                    height: "auto",
                    transitionDuration: "470ms",
                  }}
                >
                  <div
                    className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      <div
                        id="panel1a-content"
                        className="MuiAccordion-region"
                        aria-labelledby="panel1a-header"
                        role="region"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          className="MuiAccordionDetails-root bg-v5-green-700 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            padding: "8px 16px 16px",
                            borderBottomLeftRadius: "30px",
                            borderBottomRightRadius: "30px",
                            backgroundColor: "#242145",
                            paddingBottom: "1rem",
                            paddingTop: "0px",
                            lineHeight: 1.625,
                            color: "rgb(185 207 202/1)",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                          }}
                        >
                          <div
                            className="[object Object],[object Object]"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                            }}
                          >
                            <div
                              className="mb-4 flex justify-between gap-2 border-b-2 border-[#0f2922] md:justify-start md:gap-10"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                display: "flex",
                                borderBottomWidth: "2px",
                                borderColor: "rgb(15 41 34/1)",
                                justifyContent: "flex-start",
                                gap: "2.5rem",
                              }}
                            >
                              <div
                                className="relative flex w-full items-center justify-center gap-1 py-3 md:w-auto md:cursor-pointer md:flex-col md:items-start md:justify-start md:gap-1 md:px-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  position: "relative",
                                  display: "flex",
                                  paddingBottom: "0.75rem",
                                  paddingTop: "0.75rem",
                                  width: "auto",
                                  cursor: "pointer",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  gap: "0.25rem",
                                  paddingLeft: "0.5rem",
                                  paddingRight: "0.5rem",
                                }}
                              >
                                <div
                                  className="rounded-[5px] px-[10px] py-1 md:text-xs text-[6px] font-semibold uppercase text-black bg-[#2DF8C5]"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    borderRadius: "5px",
                                    backgroundColor: "rgb(45 248 197/1)",
                                    paddingBottom: "0.25rem",
                                    paddingTop: "0.25rem",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    fontWeight: 600,
                                    textTransform: "uppercase",
                                    color: "rgb(0 0 0/1)",
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  Option 1
                                </div>
                                <div
                                  className="text-[14px] font-[700] leading-[27px] md:text-[16px] text-white"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    fontWeight: 700,
                                    lineHeight: "27px",
                                    color: "white",
                                    fontSize: "16px",
                                  }}
                                >
                                  Fullstack Specialization
                                </div>
                                <div
                                  className="absolute bottom-[-2px] left-[-1px] z-10 h-[6px] w-full rounded-tr-[8px] rounded-tl-[8px] bg-[#009378]"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    position: "absolute",
                                    bottom: "-2px",
                                    left: "-1px",
                                    zIndex: 10,
                                    height: "6px",
                                    width: "100%",
                                    borderTopRightRadius: "8px",
                                    borderTopLeftRadius: "8px",
                                    backgroundColor: "rgb(0 147 120/1)",
                                  }}
                                />
                              </div>
                              <div
                                className="relative flex w-full items-center justify-center gap-1 py-3 md:w-auto md:cursor-pointer md:flex-col md:items-start md:justify-start md:gap-1 md:px-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  position: "relative",
                                  display: "flex",
                                  paddingBottom: "0.75rem",
                                  paddingTop: "0.75rem",
                                  width: "auto",
                                  cursor: "pointer",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  gap: "0.25rem",
                                  paddingLeft: "0.5rem",
                                  paddingRight: "0.5rem",
                                }}
                              >
                              </div>
                            </div>
                            <div
                              className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                color: "rgb(185 207 202/1)",
                                fontSize: "16px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              >
                                Build products like developers at AirBnB, Amazon
                                & Netflix to specialize in{" "}
                                <strong
                                  className="text-v5-green-100"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    fontWeight: "bolder",
                                    color: "rgb(45 248 197/1)",
                                  }}
                                >
                                  Full-Stack Development
                                </strong>
                                .
                              </div>
                            </div>
                          </div>
                          <div
                            className="md:pt-1"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingTop: "0.25rem",
                            }}
                          >
                            <h6
                              className="font-manrope text-white"
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                overflowWrap: "break-word",
                                margin: "0px",
                                fontWeight: 700,
                                fontSize: "1rem",
                                fontFamily: "Manrope, sans-serif",
                                color: "white",
                              }}
                            />
                            <div
                              className="mb-4 mt-1 grid justify-items-center gap-5 md:grid-cols-2"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                marginTop: "0.25rem",
                                display: "grid",
                                justifyItems: "center",
                                gap: "1.25rem",
                                gridTemplateColumns:
                                  "repeat(2, minmax(0px, 1fr))",
                              }}
                            >
                              <div
                                className="w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="h-full w-full rounded-[20px] bg-v5-green-500 text-v5-neutral-300 p-4"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "20px",
                                    backgroundColor: "#5E35B1",
                                    padding: "1rem",
                                    color: "rgb(185 207 202/1)",
                                  }}
                                >
                                  <h6
                                    className="mb-2 text-white"
                                    style={{
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      overflowWrap: "break-word",
                                      margin: "0px",
                                      fontWeight: 700,
                                      fontSize: "1rem",
                                      marginBottom: "0.5rem",
                                      color: "white",
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      Build an AirBnB-like static frontend to
                                      master HTML, CSS & more
                                    </div>
                                  </h6>
                                  <div
                                    className="md:text-md text-sm"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      fontSize: "0.875rem",
                                      lineHeight: "1.25rem",
                                    }}
                                  >
                                    <div
                                      className="mb-2 flex items-start gap-x-2"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        marginBottom: "0.5rem",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        columnGap: "0.5rem",
                                      }}
                                    >
                                      <div
                                        className="leading-relaxed"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          lineHeight: 1.625,
                                        }}
                                      >
                                        <div
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <ul
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              listStyle: "none",
                                              padding: "0px",
                                              listStyleType: "disc",
                                              margin: "1em 0px",
                                              display: "block",
                                              paddingLeft: "20px",
                                            }}
                                          >
                                            <li
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              Build a responsive front end for{" "}
                                              <strong
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  fontWeight: "bolder",
                                                }}
                                              >
                                                QTrip
                                              </strong>
                                              , an AirBnB-like travel planning
                                              website, and learn HTML, CSS,
                                              Bootstrap and more
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      <div
                                        className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          marginBottom: "1rem",
                                          marginTop: "0.75rem",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          columnGap: "0.75rem",
                                          rowGap: "0.75rem",
                                        }}
                                      >
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            HTML
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            CSS
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Flexbox
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Bootstrap
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="h-full w-full rounded-[20px] bg-v5-green-500 text-v5-neutral-300 p-4"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "20px",
                                    backgroundColor: "#5E35B1",
                                    padding: "1rem",
                                    color: "rgb(185 207 202/1)",
                                  }}
                                >
                                  <h6
                                    className="mb-2 text-white"
                                    style={{
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      overflowWrap: "break-word",
                                      margin: "0px",
                                      fontWeight: 700,
                                      fontSize: "1rem",
                                      marginBottom: "0.5rem",
                                      color: "white",
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      Build an AirBnB-like dynamic frontend to
                                      master Rest API, Routing, Filters & more
                                    </div>
                                  </h6>
                                  <div
                                    className="md:text-md text-sm"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      fontSize: "0.875rem",
                                      lineHeight: "1.25rem",
                                    }}
                                  >
                                    <div
                                      className="mb-2 flex items-start gap-x-2"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        marginBottom: "0.5rem",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        columnGap: "0.5rem",
                                      }}
                                    >
                                      <div
                                        className="leading-relaxed"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          lineHeight: 1.625,
                                        }}
                                      >
                                        <div
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <ul
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              listStyle: "none",
                                              padding: "0px",
                                              listStyleType: "disc",
                                              margin: "1em 0px",
                                              display: "block",
                                              paddingLeft: "20px",
                                            }}
                                          >
                                            <li
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              Add life to the web pages of{" "}
                                              <strong
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  fontWeight: "bolder",
                                                }}
                                              >
                                                QTrip
                                              </strong>{" "}
                                              by making them dynamic and by
                                              integrating with a backend and 3rd
                                              party plugins.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      <div
                                        className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          marginBottom: "1rem",
                                          marginTop: "0.75rem",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          columnGap: "0.75rem",
                                          rowGap: "0.75rem",
                                        }}
                                      >
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            API Calls
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Routing
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Filters
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Data Persistence
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            3rd Party Libraries
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="h-full w-full rounded-[20px] bg-v5-green-500 text-v5-neutral-300 p-4"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "20px",
                                    backgroundColor: "#5E35B1",
                                    padding: "1rem",
                                    color: "rgb(185 207 202/1)",
                                  }}
                                >
                                  <h6
                                    className="mb-2 text-white"
                                    style={{
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      overflowWrap: "break-word",
                                      margin: "0px",
                                      fontWeight: 700,
                                      fontSize: "1rem",
                                      marginBottom: "0.5rem",
                                      color: "white",
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      Build a Google News-like newsfeed
                                      aggregator
                                    </div>
                                  </h6>
                                  <div
                                    className="md:text-md text-sm"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      fontSize: "0.875rem",
                                      lineHeight: "1.25rem",
                                    }}
                                  >
                                    <div
                                      className="mb-2 flex items-start gap-x-2"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        marginBottom: "0.5rem",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        columnGap: "0.5rem",
                                      }}
                                    >
                                      <div
                                        className="leading-relaxed"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          lineHeight: 1.625,
                                        }}
                                      >
                                        <div
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <ul
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              listStyle: "none",
                                              padding: "0px",
                                              listStyleType: "disc",
                                              margin: "1em 0px",
                                              display: "block",
                                              paddingLeft: "20px",
                                            }}
                                          >
                                            <li
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              Apply your learnings from QTrip
                                              Static and QTrip Dynamic to build
                                              your first independent project,{" "}
                                              <strong
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  fontWeight: "bolder",
                                                }}
                                              >
                                                XBoard
                                              </strong>{" "}
                                              - a newsfeed that aggregates
                                              articles from popular media
                                              outlets.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      <div
                                        className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          marginBottom: "1rem",
                                          marginTop: "0.75rem",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          columnGap: "0.75rem",
                                          rowGap: "0.75rem",
                                        }}
                                      >
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            JavaScript
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            DOM Manipulation
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Developer Tools
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Binary Search Tree
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            REST
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Deployment
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="h-full w-full rounded-[20px] bg-v5-green-500 text-v5-neutral-300 p-4"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "20px",
                                    backgroundColor: "#5E35B1",
                                    padding: "1rem",
                                    color: "rgb(185 207 202/1)",
                                  }}
                                >
                                  <h6
                                    className="mb-2 text-white"
                                    style={{
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      overflowWrap: "break-word",
                                      margin: "0px",
                                      fontWeight: 700,
                                      fontSize: "1rem",
                                      marginBottom: "0.5rem",
                                      color: "white",
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      Build a React powered frontend for an
                                      Amazon-like shopping app
                                    </div>
                                  </h6>
                                  <div
                                    className="md:text-md text-sm"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      fontSize: "0.875rem",
                                      lineHeight: "1.25rem",
                                    }}
                                  >
                                    <div
                                      className="mb-2 flex items-start gap-x-2"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        marginBottom: "0.5rem",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        columnGap: "0.5rem",
                                      }}
                                    >
                                      <div
                                        className="leading-relaxed"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          lineHeight: 1.625,
                                        }}
                                      >
                                        <div
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <ul
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              listStyle: "none",
                                              padding: "0px",
                                              listStyleType: "disc",
                                              margin: "1em 0px",
                                              display: "block",
                                              paddingLeft: "20px",
                                            }}
                                          >
                                            <li
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              Build the frontend for{" "}
                                              <strong
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  fontWeight: "bolder",
                                                }}
                                              >
                                                QKart
                                              </strong>{" "}
                                              - an online ecommerce store to
                                              learn React, and create a rich
                                              shopping experience.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      <div
                                        className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          marginBottom: "1rem",
                                          marginTop: "0.75rem",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          columnGap: "0.75rem",
                                          rowGap: "0.75rem",
                                        }}
                                      >
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Components
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Component lifecycle
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Component state management
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Component styling
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Props
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Routing
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            React Hooks
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Material UI
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="h-full w-full rounded-[20px] bg-v5-green-500 text-v5-neutral-300 p-4"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "20px",
                                    backgroundColor: "#5E35B1",
                                    padding: "1rem",
                                    color: "rgb(185 207 202/1)",
                                  }}
                                >
                                  <h6
                                    className="mb-2 text-white"
                                    style={{
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      overflowWrap: "break-word",
                                      margin: "0px",
                                      fontWeight: 700,
                                      fontSize: "1rem",
                                      marginBottom: "0.5rem",
                                      color: "white",
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      Build the frontend for a Spotify like app
                                    </div>
                                  </h6>
                                  <div
                                    className="md:text-md text-sm"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      fontSize: "0.875rem",
                                      lineHeight: "1.25rem",
                                    }}
                                  >
                                    <div
                                      className="mb-2 flex items-start gap-x-2"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        marginBottom: "0.5rem",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        columnGap: "0.5rem",
                                      }}
                                    >
                                      <div
                                        className="leading-relaxed"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          lineHeight: 1.625,
                                        }}
                                      >
                                        <div
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <ul
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              listStyle: "none",
                                              padding: "0px",
                                              listStyleType: "disc",
                                              margin: "1em 0px",
                                              display: "block",
                                              paddingLeft: "20px",
                                            }}
                                          >
                                            <li
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              Apply your learnings in React to
                                              build an independent project, the
                                              frontend of{" "}
                                              <strong
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  fontWeight: "bolder",
                                                }}
                                              >
                                                QTify
                                              </strong>{" "}
                                              - an audio-streaming platform like
                                              Spotify.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      <div
                                        className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          marginBottom: "1rem",
                                          marginTop: "0.75rem",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          columnGap: "0.75rem",
                                          rowGap: "0.75rem",
                                        }}
                                      >
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Figma
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Material UI
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            React
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            REST API
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Deployment
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="h-full w-full rounded-[20px] bg-v5-green-500 text-v5-neutral-300 p-4"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "20px",
                                    backgroundColor: "#5E35B1",
                                    padding: "1rem",
                                    color: "rgb(185 207 202/1)",
                                  }}
                                >
                                  <h6
                                    className="mb-2 text-white"
                                    style={{
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      overflowWrap: "break-word",
                                      margin: "0px",
                                      fontWeight: 700,
                                      fontSize: "1rem",
                                      marginBottom: "0.5rem",
                                      color: "white",
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      Build a Node JS powered backend for a
                                      Flipkart-like app
                                    </div>
                                  </h6>
                                  <div
                                    className="md:text-md text-sm"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      fontSize: "0.875rem",
                                      lineHeight: "1.25rem",
                                    }}
                                  >
                                    <div
                                      className="mb-2 flex items-start gap-x-2"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        marginBottom: "0.5rem",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        columnGap: "0.5rem",
                                      }}
                                    >
                                      <div
                                        className="leading-relaxed"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          lineHeight: 1.625,
                                        }}
                                      >
                                        <div
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <ul
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              listStyle: "none",
                                              padding: "0px",
                                              listStyleType: "disc",
                                              margin: "1em 0px",
                                              display: "block",
                                              paddingLeft: "20px",
                                            }}
                                          >
                                            <li
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              Build a scalable NodeJS backend
                                              for{" "}
                                              <strong
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  fontWeight: "bolder",
                                                }}
                                              >
                                                QKart
                                              </strong>{" "}
                                              using the Express.js framework
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      <div
                                        className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          marginBottom: "1rem",
                                          marginTop: "0.75rem",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          columnGap: "0.75rem",
                                          rowGap: "0.75rem",
                                        }}
                                      >
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Express JS
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Node JS
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            MongoDB
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            REST API
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-span-full w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  gridColumn: "1 / -1",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="h-full w-full rounded-[20px] bg-v5-green-500 text-v5-neutral-300 p-4"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "20px",
                                    backgroundColor: "#5E35B1",
                                    padding: "1rem",
                                    color: "rgb(185 207 202/1)",
                                  }}
                                >
                                  <h6
                                    className="mb-2 text-white"
                                    style={{
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      overflowWrap: "break-word",
                                      margin: "0px",
                                      fontWeight: 700,
                                      fontSize: "1rem",
                                      marginBottom: "0.5rem",
                                      color: "white",
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      Build the backend for a Netflix-like OTT
                                      app
                                    </div>
                                  </h6>
                                  <div
                                    className="md:text-md text-sm"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      fontSize: "0.875rem",
                                      lineHeight: "1.25rem",
                                    }}
                                  >
                                    <div
                                      className="mb-2 flex items-start gap-x-2"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        marginBottom: "0.5rem",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        columnGap: "0.5rem",
                                      }}
                                    >
                                      <div
                                        className="leading-relaxed"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          lineHeight: 1.625,
                                        }}
                                      >
                                        <div
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <ul
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              listStyle: "none",
                                              padding: "0px",
                                              listStyleType: "disc",
                                              margin: "1em 0px",
                                              display: "block",
                                              paddingLeft: "20px",
                                            }}
                                          >
                                            <li
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              Apply your learnings from QKart
                                              Backend to build your second
                                              independent project using Express
                                              and Node JS, the backend for{" "}
                                              <strong
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  fontWeight: "bolder",
                                                }}
                                              >
                                                XFlix
                                              </strong>{" "}
                                              - a video streaming platform like
                                              Netflix.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      <div
                                        className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          marginBottom: "1rem",
                                          marginTop: "0.75rem",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          columnGap: "0.75rem",
                                          rowGap: "0.75rem",
                                        }}
                                      >
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Express JS
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Node JS
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            MongoDB
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            REST API
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Deployment
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    </div>
                }
              </div>
            </div>
            <div
              className="w-full"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              <div
                className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-700 css-1nci3hw"
                style={{
                  margin: "0px",
                  boxSizing: "border-box",
                  transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                  border: "none",
                  color: "rgba(0, 0, 0, 0.87)",
                  boxShadow: "none",
                  position: "relative",
                  overflowAnchor: "none",
                  marginTop: "0px",
                  marginBottom: "0px",
                  backgroundColor: "#242145",
                  borderRadius: "30px",
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  borderBottomLeftRadius: "30px",
                }}
              >
                <div
                  id="panel1a-header"
                  className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                  aria-controls="panel1a-content"
                  aria-expanded="true"
                  role="button"
                  tabIndex="0"
                  style={{
                    outline: "0px",
                    border: "0px",
                    margin: "0px",
                    borderRadius: "0px",
                    textDecoration: "none",
                    transition:
                      "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    WebkitBoxAlign: "center",
                    alignItems: "center",
                    WebkitBoxPack: "center",
                    justifyContent: "center",
                    position: "relative",
                    boxSizing: "border-box",
                    WebkitTapHighlightColor: "transparent",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    userSelect: "none",
                    verticalAlign: "middle",
                    appearance: "none",
                    display: "flex",
                    minHeight: "48px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "0.5rem",
                    color: "rgb(242 251 247/1)",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                  }}
                  onClick={()=>{setTechStackAdv(!techStackAdv)}}
                >
                  <div
                    className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      WebkitBoxFlex: "1",
                      flexGrow: 1,
                      margin: "8px 0px",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        className="flex items-start gap-x-4"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                          display: "flex",
                          alignItems: "flex-start",
                          columnGap: "1rem",
                        }}
                      >
                        <div
                          className="w-[40px] min-w-[40px]"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            width: "40px",
                            minWidth: "40px",
                          }}
                        >
                          <div
                            className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflow: "hidden",
                              position: "relative",
                              display: "inline-block",
                              verticalAlign: "top",
                              maxHeight: "100%",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                maxWidth: "100px",
                                display: "block",
                              }}
                            >
                              <img
                                aria-hidden="true"
                                role="presentation"
                                src="data:image/svg+xml;charset=utf-8,<svg height='102' width='100' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                  maxWidth: "100%",
                                  display: "block",
                                  position: "static",
                                }}
                              />
                            </div>
                            <img
                              aria-hidden="true"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADFklEQVQ4y51VTU9TQRS97zEVjfI7+AeGGDckakgMKkm1SAtUI4GWj7ZQ+6ZFSoEW5DMyr+1Co6ALNS40BBMSEolCYqJuNJrowsSPmKA7NoQVPWZmHhQICHFx827enHvumXl3ziMSFpGwyknwORLWDxL8FwlrS/Bd8m3PnySsGZpUHER0i8tkhWwLZHPop5MLa/fYidPYFRKxcqluVi0IvkaCr5OwimHzdcp3r1MuoSOf0O+2YnTNmkM6TyT4qtOpQMLSkUuA8t2gyRjMgQBYlxcsVg8j06oVyrVsHJt4VasI18iRXFDAXEKRGTc7UMIb4erwgHVfgXnNA/OKGyzeABbyoCTuhzEShmqcixecYygoLqeL7FiQClwdl5QiqYzGOkF3e0GDEVAmrPPRCMy+FrDOOrhCHhhD7arWOd9CkTAXL7BILQ4FasCu+1QRPUiBLdk49kag7K0NtiRA93thplpUU5fERi4XVTqEcBSCRX2g8S61ZZa6CvOGH2W3gvC8j8L9rhNlY80we/xgA00whkOg8U6wqHfjPHVsI+zy6rO5fQP0wkblh160LfpQkanCifQZRF7X49SnFGghC7rTo7CyZm/CqFd3nk7h8KsJNP/pxVdcwOhsBcafV+AbziH4J4mjixOg6T6F/bfCDcKpFI5IwuUkPuIshp8dV/EFVQgs9ziEqQMQyi2PhmHcS4IWbFR+HsBDdKDp6XkVj9CO01/6QS+zMKaSB9myT4+K1QwaCsNYFKhZHsTJJ00q3L8HYcovPRIBJQJ7fpTi2IRrYbqrQf46ULIV9CgNWsyjNBtCaS6kcnqcAfW0guprYV6shqzZOTabgy2H2dXqBrPqYWaCWu10P8x0C8x0AHS/H8ZIROUs5gNruwgjHdwx2MV7rK9eNg5jsB3MaoAr7EEJ96vhVVfOagRT7xrVrFIurms2r57aMl/b1xyiXnW+pjSHyZhek5it5qDdZlUSzmu5kvh/7csRJa2Q7Hg52XxFy95qngcxWF7ESY4slwbLSSU2nyHBvxetne/zC9jMf5DN5yhrlZNt0V/rISVivijRqgAAAABJRU5ErkJggg=="
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                pointerEvents: "none",
                                display: "block",
                                verticalAlign: "middle",
                                margin: "0px",
                                padding: "0px",
                                height: "100%",
                                maxWidth: "none",
                                width: "100%",
                                transition: "opacity 500ms linear",
                                inset: "0px",
                                opacity: 0,
                                backgroundColor: "transparent",
                                position: "absolute",
                                objectFit: "cover",
                              }}
                            />
                            <picture
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "block",
                                maxWidth: "100%",
                              }}
                            >
                              <source
                                type="image/webp"
                                sizes="(min-width: 50px) 50px, 100vw"
                                srcSet="https://www.crio.do/static/bbefc37ad2598a28500569b54694445e/d5ef2/Four.webp 50w"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                              <img
                                placeholder="blurred"
                                height={41}
                                width={40}
                                alt="Curriculum"
                                sizes="(min-width: 50px) 50px, 100vw"
                                src="https://www.crio.do/static/bbefc37ad2598a28500569b54694445e/14f19/Four.png"
                                srcSet="/static/bbefc37ad2598a28500569b54694445e/14f19/Four.png 50w"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  display: "block",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  maxWidth: "none",
                                  position: "absolute",
                                  width: "100%",
                                  transition: "opacity 0.25s linear",
                                  transform: "translateZ(0px)",
                                  willChange: "opacity",
                                  objectFit: "contain",
                                  opacity: 1,
                                }}
                              />
                            </picture>
                            <noscript
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              {
                                '<picture><source type="image/webp" srcSet="/static/bbefc37ad2598a28500569b54694445e/d5ef2/Four.webp 50w" sizes="(min-width: 50px) 50px, 100vw"/><img placeholder="blurred" width="100" height="102" data-main-image="" style="object-fit:contain;opacity:0" sizes="(min-width: 50px) 50px, 100vw" decoding="async" loading="lazy" src="/static/bbefc37ad2598a28500569b54694445e/14f19/Four.png" srcSet="/static/bbefc37ad2598a28500569b54694445e/14f19/Four.png 50w" alt="Curriculum"/></picture>'
                              }
                            </noscript>
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        >
                          <h6
                            className="transition-all sm:w-full md:text-[18px]"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontWeight: 700,
                              transitionDuration: "0.15s",
                              transitionProperty: "all",
                              transitionTimingFunction:
                                "cubic-bezier(0.4, 0, 0.2, 1)",
                              width: "100%",
                              fontSize: "18px",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              Tech Stack Specialisation (Advanced)
                            </div>
                          </h6>
                          <p
                            className="text-sm text-v5-neutral-300"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              color: "rgb(185 207 202/1)",
                            }}
                          >
                            4 to 8 weeks
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      transition:
                        "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                      display: "flex",
                      color: "rgba(0, 0, 0, 0.54)",
                      transform: `${techStackAdv ? 'rotate(180deg)' :'rotate(0deg)'}`,
                    }}
                  >
                    <div
                      className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        display: "flex",
                        height: "24px",
                        width: "24px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "9999px",
                        backgroundColor: "#5E35B1",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      <svg
                        className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        viewBox="0 0 320 512"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          maxWidth: "100%",
                          display: "var(--fa-display, inline-block)",
                          height: "1em",
                          verticalAlign: "-0.125em",
                          overflow: "visible",
                          boxSizing: "content-box",
                          width: "10px",
                          transform:
                            "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                          transitionDuration: "0.15s",
                          transitionProperty: "all",
                          transitionTimingFunction:
                            "cubic-bezier(0.4, 0, 0.2, 1)",
                        }}
                      >
                        <path
                          d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                          fill="currentColor"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {techStackAdv &&
                    <div
                  className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    minHeight: "0px",
                    height: "auto",
                    transitionDuration: "326ms",
                  }}
                >
                  <div
                    className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      <div
                        id="panel1a-content"
                        className="MuiAccordion-region"
                        aria-labelledby="panel1a-header"
                        role="region"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          className="MuiAccordionDetails-root bg-v5-green-700 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            padding: "8px 16px 16px",
                            borderBottomLeftRadius: "30px",
                            borderBottomRightRadius: "30px",
                            backgroundColor: "#242145",
                            paddingBottom: "1rem",
                            paddingTop: "0px",
                            lineHeight: 1.625,
                            color: "rgb(185 207 202/1)",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                          }}
                        >
                          <div
                            className="[object Object],[object Object]"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                            }}
                          >
                            <div
                              className="mb-4 flex justify-between gap-2 border-b-2 border-[#0f2922] md:justify-start md:gap-10"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                display: "flex",
                                borderBottomWidth: "2px",
                                borderColor: "rgb(15 41 34/1)",
                                justifyContent: "flex-start",
                                gap: "2.5rem",
                              }}
                            >
                              <div
                                className="relative flex w-full items-center justify-center gap-1 py-3 md:w-auto md:cursor-pointer md:flex-col md:items-start md:justify-start md:gap-1 md:px-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  position: "relative",
                                  display: "flex",
                                  paddingBottom: "0.75rem",
                                  paddingTop: "0.75rem",
                                  width: "auto",
                                  cursor: "pointer",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  gap: "0.25rem",
                                  paddingLeft: "0.5rem",
                                  paddingRight: "0.5rem",
                                }}
                              >
                                <div
                                  className="rounded-[5px] px-[10px] py-1 md:text-xs text-[6px] font-semibold uppercase text-black bg-[#2DF8C5]"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    borderRadius: "5px",
                                    backgroundColor: "rgb(45 248 197/1)",
                                    paddingBottom: "0.25rem",
                                    paddingTop: "0.25rem",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    fontWeight: 600,
                                    textTransform: "uppercase",
                                    color: "rgb(0 0 0/1)",
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  Option 1
                                </div>
                                <div
                                  className="text-[14px] font-[700] leading-[27px] md:text-[16px] text-white"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    fontWeight: 700,
                                    lineHeight: "27px",
                                    color: "white",
                                    fontSize: "16px",
                                  }}
                                >
                                  Frontend Advanced
                                </div>
                                <div
                                  className="absolute bottom-[-2px] left-[-1px] z-10 h-[6px] w-full rounded-tr-[8px] rounded-tl-[8px] bg-[#009378]"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    position: "absolute",
                                    bottom: "-2px",
                                    left: "-1px",
                                    zIndex: 10,
                                    height: "6px",
                                    width: "100%",
                                    borderTopRightRadius: "8px",
                                    borderTopLeftRadius: "8px",
                                    backgroundColor: "rgb(0 147 120/1)",
                                  }}
                                />
                              </div>
                              <div
                                className="relative flex w-full items-center justify-center gap-1 py-3 md:w-auto md:cursor-pointer md:flex-col md:items-start md:justify-start md:gap-1 md:px-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  position: "relative",
                                  display: "flex",
                                  paddingBottom: "0.75rem",
                                  paddingTop: "0.75rem",
                                  width: "auto",
                                  cursor: "pointer",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  gap: "0.25rem",
                                  paddingLeft: "0.5rem",
                                  paddingRight: "0.5rem",
                                }}
                              >
                              </div>
                            </div>
                            <div
                              className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                color: "rgb(185 207 202/1)",
                                fontSize: "16px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              >
                                Take your Frontend developer skills to the next
                                level by learning{" "}
                                <strong
                                  className="text-v5-green-100"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    fontWeight: "bolder",
                                    color: "rgb(45 248 197/1)",
                                  }}
                                >
                                  architectural design
                                </strong>{" "}
                                aspects
                              </div>
                            </div>
                          </div>
                          <div
                            className="md:pt-1"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingTop: "0.25rem",
                            }}
                          >
                            <h6
                              className="font-manrope text-white"
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                overflowWrap: "break-word",
                                margin: "0px",
                                fontWeight: 700,
                                fontSize: "1rem",
                                fontFamily: "Manrope, sans-serif",
                                color: "white",
                              }}
                            />
                            <div
                              className="mb-4 mt-1 grid justify-items-center gap-5 md:grid-cols-2"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                marginTop: "0.25rem",
                                display: "grid",
                                justifyItems: "center",
                                gap: "1.25rem",
                                gridTemplateColumns:
                                  "repeat(2, minmax(0px, 1fr))",
                              }}
                            >
                              <div
                                className="col-span-full w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  gridColumn: "1 / -1",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="h-full w-full rounded-[20px] bg-v5-green-500 text-v5-neutral-300 px-4 pb-4 pt-1"
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "20px",
                                    backgroundColor: "#5E35B1",
                                    paddingLeft: "1rem",
                                    paddingRight: "1rem",
                                    paddingBottom: "1rem",
                                    paddingTop: "0.25rem",
                                    color: "rgb(185 207 202/1)",
                                  }}
                                >
                                  <h6
                                    className="mb-2 text-white"
                                    style={{
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      overflowWrap: "break-word",
                                      margin: "0px",
                                      fontWeight: 700,
                                      fontSize: "1rem",
                                      marginBottom: "0.5rem",
                                      color: "white",
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    />
                                  </h6>
                                  <div
                                    className="md:text-md text-sm"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      fontSize: "0.875rem",
                                      lineHeight: "1.25rem",
                                    }}
                                  >
                                    <div
                                      className="mb-2 flex items-start gap-x-2"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        marginBottom: "0.5rem",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        columnGap: "0.5rem",
                                      }}
                                    >
                                      <div
                                        className="leading-relaxed"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          lineHeight: 1.625,
                                        }}
                                      >
                                        <div
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <ul
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              listStyle: "none",
                                              padding: "0px",
                                              listStyleType: "disc",
                                              margin: "1em 0px",
                                              display: "block",
                                              paddingLeft: "20px",
                                            }}
                                          >
                                            <li
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              Master essential concepts required
                                              to be a strong FE developer
                                              through a series of hands-on
                                              workshops, activities and
                                              projects.
                                            </li>
                                            <li
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                              }}
                                            >
                                              Learn concepts like{" "}
                                              <strong
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  fontWeight: "bolder",
                                                }}
                                              >
                                                Caching, Security, Website
                                                performance optimization, Redux
                                                State Management, Server Side
                                                Rendering
                                              </strong>{" "}
                                              using NextJS and others
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      <div
                                        className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          marginBottom: "1rem",
                                          marginTop: "0.75rem",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          columnGap: "0.75rem",
                                          rowGap: "0.75rem",
                                        }}
                                      >
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Redux
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            NextJS
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            CDN
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Advanced CSS
                                          </h6>
                                        </div>
                                        <div
                                          className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            borderRadius: "5px",
                                            borderColor: "rgb(185 207 202/1)",
                                            padding: "0.25rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            borderWidth: "2px",
                                          }}
                                        >
                                          <h6
                                            className="text-xs"
                                            style={{
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              overflowWrap: "break-word",
                                              margin: "0px",
                                              fontWeight: 700,
                                              fontSize: "0.75rem",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            SEO
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    </div>
                }
              </div>
            </div>
            <div
              className="w-full"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              <div
                className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-700 css-1nci3hw"
                style={{
                  margin: "0px",
                  boxSizing: "border-box",
                  transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                  border: "none",
                  color: "rgba(0, 0, 0, 0.87)",
                  boxShadow: "none",
                  position: "relative",
                  overflowAnchor: "none",
                  marginTop: "0px",
                  marginBottom: "0px",
                  backgroundColor: "#242145",
                  borderRadius: "30px",
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  borderBottomLeftRadius: "30px",
                }}
              >
                <div
                  id="panel1a-header"
                  className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                  aria-controls="panel1a-content"
                  aria-expanded="true"
                  role="button"
                  tabIndex="0"
                  style={{
                    outline: "0px",
                    border: "0px",
                    margin: "0px",
                    borderRadius: "0px",
                    textDecoration: "none",
                    transition:
                      "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    WebkitBoxAlign: "center",
                    alignItems: "center",
                    WebkitBoxPack: "center",
                    justifyContent: "center",
                    position: "relative",
                    boxSizing: "border-box",
                    WebkitTapHighlightColor: "transparent",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    userSelect: "none",
                    verticalAlign: "middle",
                    appearance: "none",
                    display: "flex",
                    minHeight: "48px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "0.5rem",
                    color: "rgb(242 251 247/1)",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                  }}
                  onClick={()=>{setSysytemDesign(!systemDesign)}}
                >
                  <div
                    className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      WebkitBoxFlex: "1",
                      flexGrow: 1,
                      margin: "8px 0px",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        className="flex items-start gap-x-4"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                          display: "flex",
                          alignItems: "flex-start",
                          columnGap: "1rem",
                        }}
                      >
                        <div
                          className="w-[40px] min-w-[40px]"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            width: "40px",
                            minWidth: "40px",
                          }}
                        >
                          <div
                            className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflow: "hidden",
                              position: "relative",
                              display: "inline-block",
                              verticalAlign: "top",
                              maxHeight: "100%",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                maxWidth: "100px",
                                display: "block",
                              }}
                            >
                              <img
                                aria-hidden="true"
                                role="presentation"
                                src="data:image/svg+xml;charset=utf-8,<svg height='102' width='100' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                  maxWidth: "100%",
                                  display: "block",
                                  position: "static",
                                }}
                              />
                            </div>
                            <img
                              aria-hidden="true"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADOUlEQVQ4y42VXWgcVRiG30kQrYIYEKVgoRdC8ELUSor2J9lkN+7mVzSmMX8tadqQpElVzOac3aYxtCFBzLq7ZzZtSkWMN/WqNE1JtBpIKaW50SCCDYVKI7mQXgWastKweWXm7OyvES8+zpmZc555vzPf9w6gBKBEMZSchxKrUHINSqzp8b/CWSP+hBIziNoMABFpTdZhCsKUTI8y51rkRM5aJdahBosBJa/ZD5SMQ4kElEzoUSRgZsyjOgxTJApi1rOstfEk9DpskAZu2WGKrYy36vFckJg6RUwFiWiQCAUdpXqPSu6xwCmYlr+VAjmwsJ8Y6aMxcIxobmb5hSbun+sjQgFrnwNlCpqCWTcckBWRQa2ou504UMFCl5t4rYK9l3y8wTp23jpCRGVaqZmE2qpUUl2msskAcWGY6Gi2gQU+H7HPy57Ldfxps56Rex/kA00NzD8vO81+Fk1+xCc7DxFvlxOllsIy+q96beCZ3z8kwgEaWV/dStmBWYrGPyGaGmjU1hB7K9n9XRVLhuuJshoWdTTwhcGj9P/ayYV4NcdWGolwcBtgVBDng8RQr07PXUnsf4f9l6sZXm1jzfIY3csT3DtzmofvfsZTj0bYstRDhGV+PWYBg906NZeHeKOcge+r2PZVFZ95r5rweIk3XRxZ8PGbh+18/crHNnB7hVadDR7nE+5K7mh8l0+1HeLpX1r4ygkfscdDw11Jw+Pl6FI9bzyu4qc/txBfbpdy8kZh2M9X586w4cEUWzen2bJxkc93NRKlHsLtpVHh4cRyHX/4u57Hbh/RwFg+MF1D9lcOcOeVs3xpdpTPfjtMVNcSJWXEQQ+fbqjl5EodFza87LDqMBRkQSy3bJRTfxl1OOEnQn5iYoAvhk7StXiWNXfCfP+vKfbFI5x+3MXWm0dzgElGuvXSKo2YDvsYLg5x94+f0/XHOb71W5QvL4a4a26cz30t9NmbciurffPNIadjrBb8YiBLtT2PSKe7Ms3hkQW8nkw5nrYjmbYmUyaMmNRWFtNzfZ1pcY4oMQuYgWKYcl0r+jeTzTVcmWOyqbZdR0zaBgt7YsoZKHn//9l/1m9gFaacR0wUwxT4ByCyKdEUjpM5AAAAAElFTkSuQmCC"
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                pointerEvents: "none",
                                display: "block",
                                verticalAlign: "middle",
                                margin: "0px",
                                padding: "0px",
                                height: "100%",
                                maxWidth: "none",
                                width: "100%",
                                transition: "opacity 500ms linear",
                                inset: "0px",
                                opacity: 0,
                                backgroundColor: "transparent",
                                position: "absolute",
                                objectFit: "cover",
                              }}
                            />
                            <picture
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "block",
                                maxWidth: "100%",
                              }}
                            >
                              <source
                                type="image/webp"
                                sizes="(min-width: 50px) 50px, 100vw"
                                srcSet="https://www.crio.do/static/7850a3d20172aa186ed5b69aa4fff5e3/d5ef2/Five.webp 50w"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                              <img
                                placeholder="blurred"
                                height={41}
                                width={40}
                                alt="Curriculum"
                                sizes="(min-width: 50px) 50px, 100vw"
                                src="https://www.crio.do/static/7850a3d20172aa186ed5b69aa4fff5e3/14f19/Five.png"
                                srcSet="/static/7850a3d20172aa186ed5b69aa4fff5e3/14f19/Five.png 50w"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  display: "block",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  maxWidth: "none",
                                  position: "absolute",
                                  width: "100%",
                                  transition: "opacity 0.25s linear",
                                  transform: "translateZ(0px)",
                                  willChange: "opacity",
                                  objectFit: "contain",
                                  opacity: 1,
                                }}
                              />
                            </picture>
                            <noscript
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              {
                                '<picture><source type="image/webp" srcSet="/static/7850a3d20172aa186ed5b69aa4fff5e3/d5ef2/Five.webp 50w" sizes="(min-width: 50px) 50px, 100vw"/><img placeholder="blurred" width="100" height="102" data-main-image="" style="object-fit:contain;opacity:0" sizes="(min-width: 50px) 50px, 100vw" decoding="async" loading="lazy" src="/static/7850a3d20172aa186ed5b69aa4fff5e3/14f19/Five.png" srcSet="/static/7850a3d20172aa186ed5b69aa4fff5e3/14f19/Five.png 50w" alt="Curriculum"/></picture>'
                              }
                            </noscript>
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        >
                          <h6
                            className="transition-all sm:w-full md:text-[18px]"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontWeight: 700,
                              transitionDuration: "0.15s",
                              transitionProperty: "all",
                              transitionTimingFunction:
                                "cubic-bezier(0.4, 0, 0.2, 1)",
                              width: "100%",
                              fontSize: "18px",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              System Design
                            </div>
                          </h6>
                          <p
                            className="text-sm text-v5-neutral-300"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              color: "rgb(185 207 202/1)",
                            }}
                          >
                            8 weeks
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      transition:
                        "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                      display: "flex",
                      color: "rgba(0, 0, 0, 0.54)",
                      transform: `${systemDesign ? 'rotate(180deg)' :'rotate(0deg)'}`,
                    }}
                  >
                    <div
                      className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        display: "flex",
                        height: "24px",
                        width: "24px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "9999px",
                        backgroundColor: "#5E35B1",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      <svg
                        className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        viewBox="0 0 320 512"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          maxWidth: "100%",
                          display: "var(--fa-display, inline-block)",
                          height: "1em",
                          verticalAlign: "-0.125em",
                          overflow: "visible",
                          boxSizing: "content-box",
                          width: "10px",
                          transform:
                            "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                          transitionDuration: "0.15s",
                          transitionProperty: "all",
                          transitionTimingFunction:
                            "cubic-bezier(0.4, 0, 0.2, 1)",
                        }}
                      >
                        <path
                          d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                          fill="currentColor"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {systemDesign &&
                    <div
                  className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    minHeight: "0px",
                    height: "auto",
                    transitionDuration: "268ms",
                  }}
                >
                  <div
                    className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      <div
                        id="panel1a-content"
                        className="MuiAccordion-region"
                        aria-labelledby="panel1a-header"
                        role="region"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          className="MuiAccordionDetails-root bg-v5-green-700 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            padding: "8px 16px 16px",
                            borderBottomLeftRadius: "30px",
                            borderBottomRightRadius: "30px",
                            backgroundColor: "#242145",
                            paddingBottom: "1rem",
                            paddingTop: "0px",
                            lineHeight: 1.625,
                            color: "rgb(185 207 202/1)",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                          }}
                        >
                          <div
                            className="md:pl-[54px]"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingLeft: "54px",
                            }}
                          >
                            <div
                              className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                color: "rgb(185 207 202/1)",
                                fontSize: "16px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="md:pl-[54px] md:pt-1"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingLeft: "54px",
                              paddingTop: "0.25rem",
                            }}
                          >
                            <h6
                              className="font-manrope text-white"
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                overflowWrap: "break-word",
                                margin: "0px",
                                fontWeight: 700,
                                fontSize: "1rem",
                                fontFamily: "Manrope, sans-serif",
                                color: "white",
                              }}
                            />
                            <div
                              className="mt-10 flex flex-wrap items-stretch gap-5 md:my-4"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "stretch",
                                gap: "1.25rem",
                                marginBottom: "1rem",
                                marginTop: "1rem",
                              }}
                            >
                              <div
                                className="mt-[-30px] w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  marginTop: "-30px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-500 css-1nci3hw"
                                  style={{
                                    margin: "0px",
                                    boxSizing: "border-box",
                                    transition:
                                      "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                    border: "none",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    boxShadow: "none",
                                    position: "relative",
                                    overflowAnchor: "none",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    backgroundColor: "#5E35B1",
                                    borderRadius: "30px",
                                    borderTopLeftRadius: "30px",
                                    borderTopRightRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                    borderBottomLeftRadius: "30px",
                                  }}
                                >
                                  <div
                                    id="panel1a-header"
                                    className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                                    aria-controls="panel1a-content"
                                    aria-expanded="true"
                                    role="button"
                                    tabIndex="0"
                                    style={{
                                      outline: "0px",
                                      border: "0px",
                                      margin: "0px",
                                      borderRadius: "0px",
                                      textDecoration: "none",
                                      transition:
                                        "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                      WebkitBoxAlign: "center",
                                      alignItems: "center",
                                      WebkitBoxPack: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      boxSizing: "border-box",
                                      WebkitTapHighlightColor: "transparent",
                                      backgroundColor: "transparent",
                                      cursor: "pointer",
                                      userSelect: "none",
                                      verticalAlign: "middle",
                                      appearance: "none",
                                      display: "flex",
                                      minHeight: "48px",
                                      width: "100%",
                                      overflow: "hidden",
                                      padding: "0.5rem",
                                      color: "rgb(242 251 247/1)",
                                      paddingLeft: "1.5rem",
                                      paddingRight: "1.5rem",
                                    }}
                                  >
                                    <div
                                      className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                                      style={{
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        WebkitBoxFlex: "1",
                                        flexGrow: 1,
                                        margin: "8px 0px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        <div
                                          className="flex items-start gap-x-4"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            columnGap: "1rem",
                                          }}
                                        >
                                          <div
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            <h6
                                              className="transition-all sm:w-full"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontWeight: 700,
                                                fontSize: "1rem",
                                                transitionDuration: "0.15s",
                                                transitionProperty: "all",
                                                transitionTimingFunction:
                                                  "cubic-bezier(0.4, 0, 0.2, 1)",
                                                width: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                }}
                                              >
                                                LLD Foundations
                                              </div>
                                            </h6>
                                            <p
                                              className="text-sm text-v5-neutral-300"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontSize: "0.875rem",
                                                lineHeight: "1.25rem",
                                                color: "rgb(185 207 202/1)",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        transition:
                                          "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                        display: "flex",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        transform: `${sd1 ? 'rotate(180deg)' :'rotate(0deg)'}`,
                                      }}
                                    >
                                      <div
                                        className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-700 text-v5-neutral-300"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          display: "flex",
                                          height: "24px",
                                          width: "24px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "9999px",
                                          backgroundColor: "#242145",
                                          color: "rgb(185 207 202/1)",
                                        }}
                                      >
                                        <svg
                                          className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                                          aria-hidden="true"
                                          focusable="false"
                                          role="img"
                                          viewBox="0 0 320 512"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            maxWidth: "100%",
                                            display:
                                              "var(--fa-display, inline-block)",
                                            height: "1em",
                                            verticalAlign: "-0.125em",
                                            overflow: "visible",
                                            boxSizing: "content-box",
                                            width: "10px",
                                            transform:
                                              "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                                            transitionDuration: "0.15s",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                              "cubic-bezier(0.4, 0, 0.2, 1)",
                                          }}
                                        >
                                          <path
                                            d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                            fill="currentColor"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      minHeight: "0px",
                                      height: "auto",
                                      transitionDuration: "337ms",
                                    }}
                                  >
                                    <div
                                      className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          id="panel1a-content"
                                          className="MuiAccordion-region"
                                          aria-labelledby="panel1a-header"
                                          role="region"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <div
                                            className="MuiAccordionDetails-root bg-v5-green-500 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              padding: "8px 16px 16px",
                                              borderBottomLeftRadius: "30px",
                                              borderBottomRightRadius: "30px",
                                              backgroundColor: "#5E35B1",
                                              paddingBottom: "1rem",
                                              paddingTop: "0px",
                                              lineHeight: 1.625,
                                              color: "rgb(185 207 202/1)",
                                              paddingLeft: "1.5rem",
                                              paddingRight: "1.5rem",
                                            }}
                                          >
                                            <hr
                                              className="mb-5 border-b border-crio-green-400 opacity-20"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                backgroundColor:
                                                  "rgb(227, 225, 213)",
                                                borderTopWidth: "1px",
                                                color: "inherit",
                                                height: "0px",
                                                margin: "0px",
                                                padding: "0px",
                                                marginBottom: "1.25rem",
                                                borderBottomWidth: "1px",
                                                borderColor:
                                                  "rgb(14 178 159/1)",
                                                opacity: 0.2,
                                              }}
                                            />
                                            <div
                                              className="mt-2"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                marginTop: "0.5rem",
                                              }}
                                            >
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                >
                                                  Master the skills of{" "}
                                                  <strong
                                                    className="text-v5-green-100"
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      fontWeight: "bolder",
                                                      color:
                                                        "rgb(45 248 197/1)",
                                                    }}
                                                  >
                                                    Object Modeling
                                                  </strong>{" "}
                                                  using{" "}
                                                  <strong
                                                    className="text-v5-green-100"
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      fontWeight: "bolder",
                                                      color:
                                                        "rgb(45 248 197/1)",
                                                    }}
                                                  >
                                                    Design patterns
                                                  </strong>{" "}
                                                  and{" "}
                                                  <strong
                                                    className="text-v5-green-100"
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      fontWeight: "bolder",
                                                      color:
                                                        "rgb(45 248 197/1)",
                                                    }}
                                                  >
                                                    SOLID Principles.
                                                  </strong>
                                                </div>
                                              </div>
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              className="md:pt-1"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                paddingTop: "0.25rem",
                                              }}
                                            >
                                              <h6
                                                className="font-manrope text-white"
                                                style={{
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  overflowWrap: "break-word",
                                                  margin: "0px",
                                                  fontWeight: 700,
                                                  fontSize: "1rem",
                                                  fontFamily:
                                                    "Manrope, sans-serif",
                                                  color: "white",
                                                }}
                                              />
                                              <div
                                                className="mb-4 mt-1 grid justify-items-center gap-5 md:grid-cols-2"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  marginTop: "0.25rem",
                                                  display: "grid",
                                                  justifyItems: "center",
                                                  gap: "1.25rem",
                                                  gridTemplateColumns:
                                                    "repeat(2, minmax(0px, 1fr))",
                                                }}
                                              >
                                                <div
                                                  className="w-full"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <div
                                                    className="h-full w-full rounded-[20px] bg-v5-green-700 text-v5-neutral-300 p-4"
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      height: "100%",
                                                      width: "100%",
                                                      borderRadius: "20px",
                                                      backgroundColor:
                                                        "#242145",
                                                      padding: "1rem",
                                                      color:
                                                        "rgb(185 207 202/1)",
                                                    }}
                                                  >
                                                    <h6
                                                      className="mb-2 text-white"
                                                      style={{
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        overflowWrap:
                                                          "break-word",
                                                        margin: "0px",
                                                        fontWeight: 700,
                                                        fontSize: "1rem",
                                                        marginBottom: "0.5rem",
                                                        color:
                                                          "white",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        Build a Hackerrank-like
                                                        App to master Basics of
                                                        Low Level Design
                                                      </div>
                                                    </h6>
                                                    <div
                                                      className="md:text-md text-sm"
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        fontSize: "0.875rem",
                                                        lineHeight: "1.25rem",
                                                      }}
                                                    >
                                                      <div
                                                        className="mb-2 flex items-start gap-x-2"
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                          marginBottom:
                                                            "0.5rem",
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                          columnGap: "0.5rem",
                                                        }}
                                                      >
                                                        <div
                                                          className="leading-relaxed"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            lineHeight: 1.625,
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                            }}
                                                          >
                                                            <ul
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                listStyle:
                                                                  "none",
                                                                padding: "0px",
                                                                listStyleType:
                                                                  "disc",
                                                                margin:
                                                                  "1em 0px",
                                                                display:
                                                                  "block",
                                                                paddingLeft:
                                                                  "20px",
                                                              }}
                                                            >
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Build{" "}
                                                                <strong
                                                                  style={{
                                                                    margin:
                                                                      "0px",
                                                                    border:
                                                                      "0px solid rgb(229, 231, 235)",
                                                                    boxSizing:
                                                                      "border-box",
                                                                    fontWeight:
                                                                      "bolder",
                                                                  }}
                                                                >
                                                                  QContest
                                                                </strong>
                                                                , a
                                                                hackerrank-like
                                                                contest platform
                                                                by applying your{" "}
                                                                <strong
                                                                  style={{
                                                                    margin:
                                                                      "0px",
                                                                    border:
                                                                      "0px solid rgb(229, 231, 235)",
                                                                    boxSizing:
                                                                      "border-box",
                                                                    fontWeight:
                                                                      "bolder",
                                                                  }}
                                                                >
                                                                  Core Java
                                                                </strong>{" "}
                                                                and Low Level
                                                                design skills
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        <div
                                                          className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            marginBottom:
                                                              "1rem",
                                                            marginTop:
                                                              "0.75rem",
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            columnGap:
                                                              "0.75rem",
                                                            rowGap: "0.75rem",
                                                          }}
                                                        >
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              SOLID Principles
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Object Oriented
                                                              Design
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Object Modeling
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Clean Architecture
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Design Patterns
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="w-full"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <div
                                                    className="h-full w-full rounded-[20px] bg-v5-green-700 text-v5-neutral-300 p-4"
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      height: "100%",
                                                      width: "100%",
                                                      borderRadius: "20px",
                                                      backgroundColor:
                                                        "#242145",
                                                      padding: "1rem",
                                                      color:
                                                        "rgb(185 207 202/1)",
                                                    }}
                                                  >
                                                    <h6
                                                      className="mb-2 text-white"
                                                      style={{
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        overflowWrap:
                                                          "break-word",
                                                        margin: "0px",
                                                        fontWeight: 700,
                                                        fontSize: "1rem",
                                                        marginBottom: "0.5rem",
                                                        color:
                                                          "white",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        Create an Spotify like
                                                        application by applying
                                                        machine coding
                                                        techniques
                                                      </div>
                                                    </h6>
                                                    <div
                                                      className="md:text-md text-sm"
                                                      style={{
                                                        margin: "0px",
                                                        border:
                                                          "0px solid rgb(229, 231, 235)",
                                                        boxSizing: "border-box",
                                                        fontSize: "0.875rem",
                                                        lineHeight: "1.25rem",
                                                      }}
                                                    >
                                                      <div
                                                        className="mb-2 flex items-start gap-x-2"
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                          marginBottom:
                                                            "0.5rem",
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                          columnGap: "0.5rem",
                                                        }}
                                                      >
                                                        <div
                                                          className="leading-relaxed"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            lineHeight: 1.625,
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                            }}
                                                          >
                                                            <ul
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                listStyle:
                                                                  "none",
                                                                padding: "0px",
                                                                listStyleType:
                                                                  "disc",
                                                                margin:
                                                                  "1em 0px",
                                                                display:
                                                                  "block",
                                                                paddingLeft:
                                                                  "20px",
                                                              }}
                                                            >
                                                              <li
                                                                style={{
                                                                  margin: "0px",
                                                                  border:
                                                                    "0px solid rgb(229, 231, 235)",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                Build the
                                                                JukeBox
                                                                application,
                                                                which can play
                                                                songs from a
                                                                playlist, using
                                                                clean
                                                                architecture
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          margin: "0px",
                                                          border:
                                                            "0px solid rgb(229, 231, 235)",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                      >
                                                        <div
                                                          className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                          style={{
                                                            margin: "0px",
                                                            border:
                                                              "0px solid rgb(229, 231, 235)",
                                                            boxSizing:
                                                              "border-box",
                                                            marginBottom:
                                                              "1rem",
                                                            marginTop:
                                                              "0.75rem",
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            columnGap:
                                                              "0.75rem",
                                                            rowGap: "0.75rem",
                                                          }}
                                                        >
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              OOP
                                                            </h6>
                                                          </div>
                                                          <div
                                                            className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                            style={{
                                                              margin: "0px",
                                                              border:
                                                                "0px solid rgb(229, 231, 235)",
                                                              boxSizing:
                                                                "border-box",
                                                              borderRadius:
                                                                "5px",
                                                              borderColor:
                                                                "rgb(185 207 202/1)",
                                                              padding:
                                                                "0.25rem",
                                                              paddingLeft:
                                                                "0.75rem",
                                                              paddingRight:
                                                                "0.75rem",
                                                              borderWidth:
                                                                "2px",
                                                            }}
                                                          >
                                                            <h6
                                                              className="text-xs"
                                                              style={{
                                                                border:
                                                                  "0px solid rgb(229, 231, 235)",
                                                                boxSizing:
                                                                  "border-box",
                                                                overflowWrap:
                                                                  "break-word",
                                                                margin: "0px",
                                                                fontWeight: 700,
                                                                fontSize:
                                                                  "0.75rem",
                                                                lineHeight:
                                                                  "1rem",
                                                              }}
                                                            >
                                                              Machine Coding
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="mt-[-10px] w-full"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  marginTop: "-10px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-500 css-1nci3hw"
                                  style={{
                                    margin: "0px",
                                    boxSizing: "border-box",
                                    transition:
                                      "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                    border: "none",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    boxShadow: "none",
                                    position: "relative",
                                    overflowAnchor: "none",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    backgroundColor: "#5E35B1",
                                    borderRadius: "30px",
                                    borderTopLeftRadius: "30px",
                                    borderTopRightRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                    borderBottomLeftRadius: "30px",
                                  }}
                                >
                                  <div
                                    id="panel1a-header"
                                    className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                                    aria-controls="panel1a-content"
                                    aria-expanded="true"
                                    role="button"
                                    tabIndex="0"
                                    style={{
                                      outline: "0px",
                                      border: "0px",
                                      margin: "0px",
                                      borderRadius: "0px",
                                      textDecoration: "none",
                                      transition:
                                        "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                      WebkitBoxAlign: "center",
                                      alignItems: "center",
                                      WebkitBoxPack: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      boxSizing: "border-box",
                                      WebkitTapHighlightColor: "transparent",
                                      backgroundColor: "transparent",
                                      cursor: "pointer",
                                      userSelect: "none",
                                      verticalAlign: "middle",
                                      appearance: "none",
                                      display: "flex",
                                      minHeight: "48px",
                                      width: "100%",
                                      overflow: "hidden",
                                      padding: "0.5rem",
                                      color: "rgb(242 251 247/1)",
                                      paddingLeft: "1.5rem",
                                      paddingRight: "1.5rem",
                                    }}
                                  >
                                    <div
                                      className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                                      style={{
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        WebkitBoxFlex: "1",
                                        flexGrow: 1,
                                        margin: "8px 0px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        <div
                                          className="flex items-start gap-x-4"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            columnGap: "1rem",
                                          }}
                                        >
                                          <div
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            <h6
                                              className="transition-all sm:w-full"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontWeight: 700,
                                                fontSize: "1rem",
                                                transitionDuration: "0.15s",
                                                transitionProperty: "all",
                                                transitionTimingFunction:
                                                  "cubic-bezier(0.4, 0, 0.2, 1)",
                                                width: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                }}
                                              >
                                                HLD Foundations
                                              </div>
                                            </h6>
                                            <p
                                              className="text-sm text-v5-neutral-300"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                overflowWrap: "break-word",
                                                margin: "0px",
                                                fontSize: "0.875rem",
                                                lineHeight: "1.25rem",
                                                color: "rgb(185 207 202/1)",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        transition:
                                          "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                        display: "flex",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        transform: `${sd2 ? 'rotate(180deg)' :'rotate(0deg)'}`,
                                      }}
                                    >
                                      <div
                                        className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-700 text-v5-neutral-300"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          display: "flex",
                                          height: "24px",
                                          width: "24px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "9999px",
                                          backgroundColor: "#242145",
                                          color: "rgb(185 207 202/1)",
                                        }}
                                      >
                                        <svg
                                          className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                                          aria-hidden="true"
                                          focusable="false"
                                          role="img"
                                          viewBox="0 0 320 512"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            maxWidth: "100%",
                                            display:
                                              "var(--fa-display, inline-block)",
                                            height: "1em",
                                            verticalAlign: "-0.125em",
                                            overflow: "visible",
                                            boxSizing: "content-box",
                                            width: "10px",
                                            transform:
                                              "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                                            transitionDuration: "0.15s",
                                            transitionProperty: "all",
                                            transitionTimingFunction:
                                              "cubic-bezier(0.4, 0, 0.2, 1)",
                                          }}
                                        >
                                          <path
                                            d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                            fill="currentColor"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                            }}
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                      minHeight: "0px",
                                      height: "auto",
                                      transitionDuration: "277ms",
                                    }}
                                  >
                                    <div
                                      className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                                      style={{
                                        margin: "0px",
                                        border: "0px solid rgb(229, 231, 235)",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                                        style={{
                                          margin: "0px",
                                          border:
                                            "0px solid rgb(229, 231, 235)",
                                          boxSizing: "border-box",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          id="panel1a-content"
                                          className="MuiAccordion-region"
                                          aria-labelledby="panel1a-header"
                                          role="region"
                                          style={{
                                            margin: "0px",
                                            border:
                                              "0px solid rgb(229, 231, 235)",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <div
                                            className="MuiAccordionDetails-root bg-v5-green-500 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                                            style={{
                                              margin: "0px",
                                              border:
                                                "0px solid rgb(229, 231, 235)",
                                              boxSizing: "border-box",
                                              padding: "8px 16px 16px",
                                              borderBottomLeftRadius: "30px",
                                              borderBottomRightRadius: "30px",
                                              backgroundColor: "#5E35B1",
                                              paddingBottom: "1rem",
                                              paddingTop: "0px",
                                              lineHeight: 1.625,
                                              color: "rgb(185 207 202/1)",
                                              paddingLeft: "1.5rem",
                                              paddingRight: "1.5rem",
                                            }}
                                          >
                                            <hr
                                              className="mb-5 border-b border-crio-green-400 opacity-20"
                                              style={{
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                backgroundColor:
                                                  "rgb(227, 225, 213)",
                                                borderTopWidth: "1px",
                                                color: "inherit",
                                                height: "0px",
                                                margin: "0px",
                                                padding: "0px",
                                                marginBottom: "1.25rem",
                                                borderBottomWidth: "1px",
                                                borderColor:
                                                  "rgb(14 178 159/1)",
                                                opacity: 0.2,
                                              }}
                                            />
                                            <div
                                              className="mt-2"
                                              style={{
                                                margin: "0px",
                                                border:
                                                  "0px solid rgb(229, 231, 235)",
                                                boxSizing: "border-box",
                                                marginTop: "0.5rem",
                                              }}
                                            >
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      margin: "0px",
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                    }}
                                                  >
                                                    Understand essential
                                                    components/concepts of a
                                                    modern tech stack used
                                                    universally by internet
                                                    companies with bonus
                                                    hands-on exercises
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="mb-4 text-[#B9CFCA]"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  color: "rgb(185 207 202/1)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                  }}
                                                />
                                              </div>
                                              <div
                                                className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                                                style={{
                                                  margin: "0px",
                                                  border:
                                                    "0px solid rgb(229, 231, 235)",
                                                  boxSizing: "border-box",
                                                  marginBottom: "1rem",
                                                  marginTop: "0.75rem",
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  columnGap: "0.75rem",
                                                  rowGap: "0.75rem",
                                                }}
                                              >
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Architecture of Web
                                                    Applications
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Docker
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Kafka
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Micro-Services
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    CI/CD
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Cloud
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    CAP Theorem
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    SQL/NoSQL
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Scalability
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Load Balancing
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Partitioning
                                                  </h6>
                                                </div>
                                                <div
                                                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                                  style={{
                                                    margin: "0px",
                                                    border:
                                                      "0px solid rgb(229, 231, 235)",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    borderColor:
                                                      "rgb(185 207 202/1)",
                                                    padding: "0.25rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    borderWidth: "2px",
                                                  }}
                                                >
                                                  <h6
                                                    className="text-xs"
                                                    style={{
                                                      border:
                                                        "0px solid rgb(229, 231, 235)",
                                                      boxSizing: "border-box",
                                                      overflowWrap:
                                                        "break-word",
                                                      margin: "0px",
                                                      fontWeight: 700,
                                                      fontSize: "0.75rem",
                                                      lineHeight: "1rem",
                                                    }}
                                                  >
                                                    Caching
                                                  </h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    </div>
                }
              </div>
            </div>
            <div
              className="w-full"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              <div
                className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-700 css-1nci3hw"
                style={{
                  margin: "0px",
                  boxSizing: "border-box",
                  transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                  border: "none",
                  color: "rgba(0, 0, 0, 0.87)",
                  boxShadow: "none",
                  position: "relative",
                  overflowAnchor: "none",
                  marginTop: "0px",
                  marginBottom: "0px",
                  backgroundColor: "#242145",
                  borderRadius: "30px",
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  borderBottomLeftRadius: "30px",
                }}
              >
                <div
                  id="panel1a-header"
                  className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                  aria-controls="panel1a-content"
                  aria-expanded="true"
                  role="button"
                  tabIndex="0"
                  style={{
                    outline: "0px",
                    border: "0px",
                    margin: "0px",
                    borderRadius: "0px",
                    textDecoration: "none",
                    transition:
                      "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    WebkitBoxAlign: "center",
                    alignItems: "center",
                    WebkitBoxPack: "center",
                    justifyContent: "center",
                    position: "relative",
                    boxSizing: "border-box",
                    WebkitTapHighlightColor: "transparent",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    userSelect: "none",
                    verticalAlign: "middle",
                    appearance: "none",
                    display: "flex",
                    minHeight: "48px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "0.5rem",
                    color: "rgb(242 251 247/1)",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                  }}
                  onClick={()=>{setInterview(!interview)}}
                >
                  <div
                    className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      WebkitBoxFlex: "1",
                      flexGrow: 1,
                      margin: "8px 0px",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        className="flex items-start gap-x-4"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                          display: "flex",
                          alignItems: "flex-start",
                          columnGap: "1rem",
                        }}
                      >
                        <div
                          className="w-[40px] min-w-[40px]"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            width: "40px",
                            minWidth: "40px",
                          }}
                        >
                          <div
                            className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflow: "hidden",
                              position: "relative",
                              display: "inline-block",
                              verticalAlign: "top",
                              maxHeight: "100%",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                maxWidth: "100px",
                                display: "block",
                              }}
                            >
                              <img
                                aria-hidden="true"
                                role="presentation"
                                src="data:image/svg+xml;charset=utf-8,<svg height='102' width='100' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                  maxWidth: "100%",
                                  display: "block",
                                  position: "static",
                                }}
                              />
                            </div>
                            <img
                              aria-hidden="true"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADgElEQVQ4y42VXWwUVRTH/zutRYzRN0h84q1C290EY8IGNK0IbflQ8aNqbSFtrLappGl3d+6dbWMMIWKItHRmt2KiMcZEeJCHCoHAg9HESOKDLxpBqPaD4jbE6kq7XaSd+ZuZuzuz2xDCw8k5N3Pvb//n3HPPAqYATFENU56HKaZhylmYYlb5e1lxj5iBKcYx6jEAHJdukIUlCEtS+dXx3UyWeEmYIgtTrwZMedb7YMo8TGErk3bIEnZFSre1lLBhSRveWtiapb7DKu71LF+AXoQHUkAHlnBcH0q5a4P4cIg4liRGBDEi1XokSc3b76lzYAplCphHAJP0YcMGH0rH2HWhhacvN/DSTJTfTm6l9VMzN37eQ4wMURuTxKgPpQ8NYKIAS3LDqV5enXuCXHmc9vImLt+p8YwrG3lrqY67PnmJOJygdiJZqlRB/UVKOhgWxMkEMRnje389Rzu3ifMLEc4vhnlrKcxsLsLllTpenazlw/uaCKOHoY+SLEnZcRWq2xqVxJhO/DpAzMSJ2UHKmy+QTg0X74SZ+TvCqbkI/10KM78SZn3fU0S0kdqRPiJtFJVS1dBN9ZgkxuPEjTjx2wBx9m1iWue2T/dy8koNv/r+SQ59FiVZy6X/6tho1BObt7Oiu404Majq6UL9vnJv8bsC8Jd+4oNWVlzrJ871seXyK8zlajmfDXPhdphz/0T4WMuzRHQ7Q/tfJcYKtfSB3mVI4ps4cT1G/BEn/tS9WlZm4sSUwZczb3BhsY5kDY+e2kJsaaL29DNEd/s9FH6pB8CJGPF7jLgWY+XEADFh8LWbrfz5xmY+2tZI1O8kGnYQhw4GCgttEzyn45K4kCBmYir1jE7M6cpPJFiZSXDrx7uJtj18sL2JoYPt5ekqhW7beFDVPq7SLxLEeC/xdRdxuosVJzv5SGaA6398i1Vv7uGajl2s6n6eGE3Qb5eStnFKnl6Q/tEE0dtBNO4mojuY/mEbX0w1EK/v5drOZmrvdhHp1Y0tHZTBiq/GjdNSFdu9sMP9XDfcw7V6Kx840Myqnn1BzUwRwNxzZcOh/KEHh8YMwhr0FGmHuhl6/51iMzv+gFBnci7wYuEX8t5YKhlhJePJDhXjdNJGylCxVdxTFCXOAJZRDUtmlZq7DM7VA7VUueWnTY+Rkt6AhRdYchymnLq/8V/2NzANS55HSlTDEvgfnO1EW125zvQAAAAASUVORK5CYII="
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                pointerEvents: "none",
                                display: "block",
                                verticalAlign: "middle",
                                margin: "0px",
                                padding: "0px",
                                height: "100%",
                                maxWidth: "none",
                                width: "100%",
                                transition: "opacity 500ms linear",
                                inset: "0px",
                                opacity: 0,
                                backgroundColor: "transparent",
                                position: "absolute",
                                objectFit: "cover",
                              }}
                            />
                            <picture
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "block",
                                maxWidth: "100%",
                              }}
                            >
                              <source
                                type="image/webp"
                                sizes="(min-width: 50px) 50px, 100vw"
                                srcSet="https://www.crio.do/static/eb0d36d5b4d5094eb33fc01d215a1e93/d5ef2/Six.webp 50w"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                              <img
                                placeholder="blurred"
                                height={41}
                                width={40}
                                alt="Curriculum"
                                sizes="(min-width: 50px) 50px, 100vw"
                                src="https://www.crio.do/static/eb0d36d5b4d5094eb33fc01d215a1e93/14f19/Six.png"
                                srcSet="/static/eb0d36d5b4d5094eb33fc01d215a1e93/14f19/Six.png 50w"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  display: "block",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  maxWidth: "none",
                                  position: "absolute",
                                  width: "100%",
                                  transition: "opacity 0.25s linear",
                                  transform: "translateZ(0px)",
                                  willChange: "opacity",
                                  objectFit: "contain",
                                  opacity: 1,
                                }}
                              />
                            </picture>
                            <noscript
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              {
                                '<picture><source type="image/webp" srcSet="/static/eb0d36d5b4d5094eb33fc01d215a1e93/d5ef2/Six.webp 50w" sizes="(min-width: 50px) 50px, 100vw"/><img placeholder="blurred" width="100" height="102" data-main-image="" style="object-fit:contain;opacity:0" sizes="(min-width: 50px) 50px, 100vw" decoding="async" loading="lazy" src="/static/eb0d36d5b4d5094eb33fc01d215a1e93/14f19/Six.png" srcSet="/static/eb0d36d5b4d5094eb33fc01d215a1e93/14f19/Six.png 50w" alt="Curriculum"/></picture>'
                              }
                            </noscript>
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        >
                          <h6
                            className="transition-all sm:w-full md:text-[18px]"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontWeight: 700,
                              transitionDuration: "0.15s",
                              transitionProperty: "all",
                              transitionTimingFunction:
                                "cubic-bezier(0.4, 0, 0.2, 1)",
                              width: "100%",
                              fontSize: "18px",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              Interview Blitz
                            </div>
                          </h6>
                          <p
                            className="text-sm text-v5-neutral-300"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              color: "rgb(185 207 202/1)",
                            }}
                          >
                            4 weeks
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      transition:
                        "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                      display: "flex",
                      color: "rgba(0, 0, 0, 0.54)",
                      transform: `${interview ? 'rotate(180deg)' :'rotate(0deg)'}`,
                    }}
                  >
                    <div
                      className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        display: "flex",
                        height: "24px",
                        width: "24px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "9999px",
                        backgroundColor: "#5E35B1",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      <svg
                        className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        viewBox="0 0 320 512"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          maxWidth: "100%",
                          display: "var(--fa-display, inline-block)",
                          height: "1em",
                          verticalAlign: "-0.125em",
                          overflow: "visible",
                          boxSizing: "content-box",
                          width: "10px",
                          transform:
                            "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                          transitionDuration: "0.15s",
                          transitionProperty: "all",
                          transitionTimingFunction:
                            "cubic-bezier(0.4, 0, 0.2, 1)",
                        }}
                      >
                        <path
                          d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                          fill="currentColor"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {interview &&
                    <div
                  className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    minHeight: "0px",
                    height: "auto",
                    transitionDuration: "296ms",
                  }}
                >
                  <div
                    className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      <div
                        id="panel1a-content"
                        className="MuiAccordion-region"
                        aria-labelledby="panel1a-header"
                        role="region"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          className="MuiAccordionDetails-root bg-v5-green-700 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            padding: "8px 16px 16px",
                            borderBottomLeftRadius: "30px",
                            borderBottomRightRadius: "30px",
                            backgroundColor: "#242145",
                            paddingBottom: "1rem",
                            paddingTop: "0px",
                            lineHeight: 1.625,
                            color: "rgb(185 207 202/1)",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                          }}
                        >
                          <div
                            className="md:pl-[54px]"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingLeft: "54px",
                            }}
                          >
                            <div
                              className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                color: "rgb(185 207 202/1)",
                                fontSize: "16px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              >
                                <div
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                  }}
                                >
                                  Sprint through the last leg of revision and
                                  interview cracking by working on the key
                                  aspects required for interview
                                </div>
                                <ul
                                  style={{
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    listStyle: "none",
                                    padding: "0px",
                                    listStyleType: "disc",
                                    margin: "1em 0px",
                                    display: "block",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  <li
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    Practice multiple timed mock assessments
                                  </li>
                                  <li
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    Take up mock interviews to prepare you for
                                    the real ones
                                  </li>
                                  <li
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    Answer the top 100 Technical Questions to
                                    grow in confidence
                                  </li>
                                  <li
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    Perfect the art of cracking DSA interviews
                                    through multiple DSA Blitz sessions
                                  </li>
                                  <li
                                    style={{
                                      margin: "0px",
                                      border: "0px solid rgb(229, 231, 235)",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    Prepare to defend every single skill keyword
                                    added to your resume
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                color: "rgb(185 207 202/1)",
                                fontSize: "16px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                            </div>
                            <div
                              className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                marginTop: "0.75rem",
                                display: "flex",
                                flexWrap: "wrap",
                                columnGap: "0.75rem",
                                rowGap: "0.75rem",
                              }}
                            >
                              <div
                                className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  borderRadius: "5px",
                                  borderColor: "rgb(185 207 202/1)",
                                  padding: "0.25rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  borderWidth: "2px",
                                }}
                              >
                                <h6
                                  className="text-xs"
                                  style={{
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    overflowWrap: "break-word",
                                    margin: "0px",
                                    fontWeight: 700,
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  DSA Mocks
                                </h6>
                              </div>
                              <div
                                className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  borderRadius: "5px",
                                  borderColor: "rgb(185 207 202/1)",
                                  padding: "0.25rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  borderWidth: "2px",
                                }}
                              >
                                <h6
                                  className="text-xs"
                                  style={{
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    overflowWrap: "break-word",
                                    margin: "0px",
                                    fontWeight: 700,
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  DSA Blitz
                                </h6>
                              </div>
                              <div
                                className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  borderRadius: "5px",
                                  borderColor: "rgb(185 207 202/1)",
                                  padding: "0.25rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  borderWidth: "2px",
                                }}
                              >
                                <h6
                                  className="text-xs"
                                  style={{
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    overflowWrap: "break-word",
                                    margin: "0px",
                                    fontWeight: 700,
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  Mock Interviews
                                </h6>
                              </div>
                              <div
                                className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  borderRadius: "5px",
                                  borderColor: "rgb(185 207 202/1)",
                                  padding: "0.25rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  borderWidth: "2px",
                                }}
                              >
                                <h6
                                  className="text-xs"
                                  style={{
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    overflowWrap: "break-word",
                                    margin: "0px",
                                    fontWeight: 700,
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  Take home Prep
                                </h6>
                              </div>
                              <div
                                className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  borderRadius: "5px",
                                  borderColor: "rgb(185 207 202/1)",
                                  padding: "0.25rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  borderWidth: "2px",
                                }}
                              >
                                <h6
                                  className="text-xs"
                                  style={{
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    overflowWrap: "break-word",
                                    margin: "0px",
                                    fontWeight: 700,
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  Specialization Specific Prep
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    </div>
                }
              </div>
            </div>
            <div
              className="w-full"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              <div
                className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded Mui-expanded rounded-[20px] md:rounded-[30px] bg-v5-green-700 css-1nci3hw"
                style={{
                  margin: "0px",
                  boxSizing: "border-box",
                  transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                  border: "none",
                  color: "rgba(0, 0, 0, 0.87)",
                  boxShadow: "none",
                  position: "relative",
                  overflowAnchor: "none",
                  marginTop: "0px",
                  marginBottom: "0px",
                  backgroundColor: "#242145",
                  borderRadius: "30px",
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  borderBottomLeftRadius: "30px",
                }}
              >
                <div
                  id="panel1a-header"
                  className="MuiButtonBase-root MuiAccordionSummary-root Mui-expanded w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6 css-1uaukoe"
                  aria-controls="panel1a-content"
                  aria-expanded="true"
                  role="button"
                  tabIndex="0"
                  style={{
                    outline: "0px",
                    border: "0px",
                    margin: "0px",
                    borderRadius: "0px",
                    textDecoration: "none",
                    transition:
                      "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    WebkitBoxAlign: "center",
                    alignItems: "center",
                    WebkitBoxPack: "center",
                    justifyContent: "center",
                    position: "relative",
                    boxSizing: "border-box",
                    WebkitTapHighlightColor: "transparent",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    userSelect: "none",
                    verticalAlign: "middle",
                    appearance: "none",
                    display: "flex",
                    minHeight: "48px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "0.5rem",
                    color: "rgb(242 251 247/1)",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                  }}
                  onClick={()=>{setExternship(!externship)}}
                >
                  <div
                    className="MuiAccordionSummary-content  Mui-expanded css-1n11r91"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      WebkitBoxFlex: "1",
                      flexGrow: 1,
                      margin: "8px 0px",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        className="flex items-start gap-x-4"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                          display: "flex",
                          alignItems: "flex-start",
                          columnGap: "1rem",
                        }}
                      >
                        <div
                          className="w-[40px] min-w-[40px]"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            width: "40px",
                            minWidth: "40px",
                          }}
                        >
                          <div
                            className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflow: "hidden",
                              position: "relative",
                              display: "inline-block",
                              verticalAlign: "top",
                              maxHeight: "100%",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                maxWidth: "100px",
                                display: "block",
                              }}
                            >
                              <img
                                aria-hidden="true"
                                role="presentation"
                                src="data:image/svg+xml;charset=utf-8,<svg height='102' width='100' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                  maxWidth: "100%",
                                  display: "block",
                                  position: "static",
                                }}
                              />
                            </div>
                            <img
                              aria-hidden="true"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADpUlEQVQ4y42V208cVRzHv2cWpE8mPpj4D2h6WXB9aEyLCV0kLRdLtEaEBVsihdZisoWd2Tm7S42NDzUaWnZml4vYB6MJmsbUIFYjpMbYVBNvjdVq0pYKxVrbeCFtWKjMfHUusOCmqQ/f5Jc55/fJd87lewBTAoa+FoYchSGnYegzMOT/lDvX6Rn1GQAM6RSzMHV6kszXOmH8R6ZOxdQp3DGZ7zH0WRcKQ475H3IwpAVDt2BKC6bu1qI/YQUGE1ZgKGkpWWdcWjiStJBOeHO9+TmfMeYAc54TaQtTtwMZR3HPQSZJvBQj4lFi/z7iUJzF/TrXvdnJ+4ajhJmyYUobhm77f5aDR5a2C00nbPT2EK8eINIplnTv4v0HG1k/0sjUSAWPfVnOr65u5uJikMZEOaF1UMn4MAfqLIFHd2DSvmeomzvGdvLFLx7nsZ8qeXYyxNmFEMmgK2txA2/OBWnZQWbGNxENNbwr2Upkk7a/xjZg6raS0YneHjv9dS3JB2j/vZ7WrfW8ZZXy8vUHOXk1xL/mynhjrpS5+VKS6zgwsYlorGOJGikA0gUeTnH4bDUXFjbw+o0Qb86XcT4X5BsfbuTgexuZmwvywrWHOH7xYWbOVTJ09AmKSA2LtWYHmD8Fy8DeFN/6Yavr8I/ZMk79HuLImc3sGAqz2qhm6UdNvPdkG3GykzijEu+0c01kG4u0lkKgu6N9SVa838K2z+sYmojw7k/aiRN7iGw78VoH8V2MuBynmFKpXIuz6PhuljTdBuiqTxLjGnFOJc7HiSlHKgPZXQwM76YyrVJMxogLMeKXOJV37wQ8LKmc1hi4EqMyGfOaf1aJ77uJH53ah53vJmY0Ksfb7wA8IonPNGI6Rlz05QAu+eBpjbgSJ36NE38mqLzdxjXN21gULwTaPtDGKY2YUYkpzWv8Tfcgl1Ti2yjx8XPE0VaKg09TPFXL4u2PsqirqfAcLgM/dRx1Ed9EiRN7iddbKV5ooHh2O0X9VioVYSqPhKlsqaKor6OI7KDocTYusRK44toMxGzR1UBRU0VlSyWV8jCVcBXFY7UUzU9SPP8MkdxDHIoSfZoDyrtz7rTpXj2ZW6YbCRuvqLbo3Emxr4U4sJd4eb/X3J8kBhylPNCyEd0PCD+xYMoPvKRwU8eCE1GDKU8DSQvZhBdnS1GV1vP1qvhyXY45wBUBK/O7lS4M1tVaFa5+wMq1zqYspfaKJ2BJcub2T4KefwJMfdQ19i/rH/eIRNMXIAl0AAAAAElFTkSuQmCC"
                              style={{
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                pointerEvents: "none",
                                display: "block",
                                verticalAlign: "middle",
                                margin: "0px",
                                padding: "0px",
                                height: "100%",
                                maxWidth: "none",
                                width: "100%",
                                transition: "opacity 500ms linear",
                                inset: "0px",
                                opacity: 0,
                                backgroundColor: "transparent",
                                position: "absolute",
                                objectFit: "cover",
                              }}
                            />
                            <picture
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                display: "block",
                                maxWidth: "100%",
                              }}
                            >
                              <source
                                type="image/webp"
                                sizes="(min-width: 50px) 50px, 100vw"
                                srcSet="https://www.crio.do/static/af99c165080cf5c74a34a19cb796158c/d5ef2/Seven.webp 50w"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                              <img
                                placeholder="blurred"
                                height={41}
                                width={40}
                                alt="Curriculum"
                                sizes="(min-width: 50px) 50px, 100vw"
                                src="https://www.crio.do/static/af99c165080cf5c74a34a19cb796158c/14f19/Seven.png"
                                srcSet="/static/af99c165080cf5c74a34a19cb796158c/14f19/Seven.png 50w"
                                style={{
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  pointerEvents: "none",
                                  display: "block",
                                  verticalAlign: "middle",
                                  inset: "0px",
                                  margin: "0px",
                                  padding: "0px",
                                  height: "100%",
                                  maxWidth: "none",
                                  position: "absolute",
                                  width: "100%",
                                  transition: "opacity 0.25s linear",
                                  transform: "translateZ(0px)",
                                  willChange: "opacity",
                                  objectFit: "contain",
                                  opacity: 1,
                                }}
                              />
                            </picture>
                            <noscript
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              {
                                '<picture><source type="image/webp" srcSet="/static/af99c165080cf5c74a34a19cb796158c/d5ef2/Seven.webp 50w" sizes="(min-width: 50px) 50px, 100vw"/><img placeholder="blurred" width="100" height="102" data-main-image="" style="object-fit:contain;opacity:0" sizes="(min-width: 50px) 50px, 100vw" decoding="async" loading="lazy" src="/static/af99c165080cf5c74a34a19cb796158c/14f19/Seven.png" srcSet="/static/af99c165080cf5c74a34a19cb796158c/14f19/Seven.png 50w" alt="Curriculum"/></picture>'
                              }
                            </noscript>
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        >
                          <h6
                            className="transition-all sm:w-full md:text-[18px]"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontWeight: 700,
                              transitionDuration: "0.15s",
                              transitionProperty: "all",
                              transitionTimingFunction:
                                "cubic-bezier(0.4, 0, 0.2, 1)",
                              width: "100%",
                              fontSize: "18px",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                              }}
                            >
                              Terv Externship
                            </div>
                          </h6>
                          <p
                            className="text-sm text-v5-neutral-300"
                            style={{
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              overflowWrap: "break-word",
                              margin: "0px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              color: "rgb(185 207 202/1)",
                            }}
                          >
                            4 weeks
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="MuiAccordionSummary-expandIconWrapper Mui-expanded css-1fx8m19"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      transition:
                        "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                      display: "flex",
                      color: "rgba(0, 0, 0, 0.54)",
                      transform: `${externship ? 'rotate(180deg)' :'rotate(0deg)'}`,
                    }}
                  >
                    <div
                      className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        display: "flex",
                        height: "24px",
                        width: "24px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "9999px",
                        backgroundColor: "#5E35B1",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      <svg
                        className="svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all"
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        viewBox="0 0 320 512"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          maxWidth: "100%",
                          display: "var(--fa-display, inline-block)",
                          height: "1em",
                          verticalAlign: "-0.125em",
                          overflow: "visible",
                          boxSizing: "content-box",
                          width: "10px",
                          transform:
                            "translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
                          transitionDuration: "0.15s",
                          transitionProperty: "all",
                          transitionTimingFunction:
                            "cubic-bezier(0.4, 0, 0.2, 1)",
                        }}
                      >
                        <path
                          d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                          fill="currentColor"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                          }}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {externship &&
                    <div
                  className="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-c4sutr"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    minHeight: "0px",
                    height: "auto",
                    transitionDuration: "255ms",
                  }}
                >
                  <div
                    className="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5"
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      className="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      <div
                        id="panel1a-content"
                        className="MuiAccordion-region"
                        aria-labelledby="panel1a-header"
                        role="region"
                        style={{
                          margin: "0px",
                          border: "0px solid rgb(229, 231, 235)",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          className="MuiAccordionDetails-root bg-v5-green-700 rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6 css-u7qq7e"
                          style={{
                            margin: "0px",
                            border: "0px solid rgb(229, 231, 235)",
                            boxSizing: "border-box",
                            padding: "8px 16px 16px",
                            borderBottomLeftRadius: "30px",
                            borderBottomRightRadius: "30px",
                            backgroundColor: "#242145",
                            paddingBottom: "1rem",
                            paddingTop: "0px",
                            lineHeight: 1.625,
                            color: "rgb(185 207 202/1)",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                          }}
                        >
                          <div
                            className="md:pl-[54px]"
                            style={{
                              margin: "0px",
                              border: "0px solid rgb(229, 231, 235)",
                              boxSizing: "border-box",
                              paddingLeft: "54px",
                            }}
                          >
                            <div
                              className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                color: "rgb(185 207 202/1)",
                                fontSize: "16px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              >
                                <div
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    marginBottom: "5px",
                                  }}
                                >
                                  You will actually work on a real project
                                  statement given by some of the top tech
                                  companies in India.
                                </div>
                                <div
                                  style={{
                                    margin: "0px",
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                  }}
                                >
                                  Here are some of the past companies who
                                  provided externships for our learner
                                  community:
                                </div>
                              </div>
                            </div>
                            <div
                              className="mb-4 text-[#B9CFCA] text-[12px] md:text-[16px]"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                color: "rgb(185 207 202/1)",
                                fontSize: "16px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                }}
                              />
                            </div>
                            <div
                              className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3"
                              style={{
                                margin: "0px",
                                border: "0px solid rgb(229, 231, 235)",
                                boxSizing: "border-box",
                                marginBottom: "1rem",
                                marginTop: "0.75rem",
                                display: "flex",
                                flexWrap: "wrap",
                                columnGap: "0.75rem",
                                rowGap: "0.75rem",
                              }}
                            >
                              <div
                                className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  borderRadius: "5px",
                                  borderColor: "rgb(185 207 202/1)",
                                  padding: "0.25rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  borderWidth: "2px",
                                }}
                              >
                                <h6
                                  className="text-xs"
                                  style={{
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    overflowWrap: "break-word",
                                    margin: "0px",
                                    fontWeight: 700,
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  CRED
                                </h6>
                              </div>
                              <div
                                className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  borderRadius: "5px",
                                  borderColor: "rgb(185 207 202/1)",
                                  padding: "0.25rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  borderWidth: "2px",
                                }}
                              >
                                <h6
                                  className="text-xs"
                                  style={{
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    overflowWrap: "break-word",
                                    margin: "0px",
                                    fontWeight: 700,
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  Jumbotail
                                </h6>
                              </div>
                              <div
                                className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  borderRadius: "5px",
                                  borderColor: "rgb(185 207 202/1)",
                                  padding: "0.25rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  borderWidth: "2px",
                                }}
                              >
                                <h6
                                  className="text-xs"
                                  style={{
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    overflowWrap: "break-word",
                                    margin: "0px",
                                    fontWeight: 700,
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  Groww
                                </h6>
                              </div>
                              <div
                                className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  borderRadius: "5px",
                                  borderColor: "rgb(185 207 202/1)",
                                  padding: "0.25rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  borderWidth: "2px",
                                }}
                              >
                                <h6
                                  className="text-xs"
                                  style={{
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    overflowWrap: "break-word",
                                    margin: "0px",
                                    fontWeight: 700,
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  Vicara
                                </h6>
                              </div>
                              <div
                                className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  borderRadius: "5px",
                                  borderColor: "rgb(185 207 202/1)",
                                  padding: "0.25rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  borderWidth: "2px",
                                }}
                              >
                                <h6
                                  className="text-xs"
                                  style={{
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    overflowWrap: "break-word",
                                    margin: "0px",
                                    fontWeight: 700,
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  Slice
                                </h6>
                              </div>
                              <div
                                className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  borderRadius: "5px",
                                  borderColor: "rgb(185 207 202/1)",
                                  padding: "0.25rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  borderWidth: "2px",
                                }}
                              >
                                <h6
                                  className="text-xs"
                                  style={{
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    overflowWrap: "break-word",
                                    margin: "0px",
                                    fontWeight: 700,
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  Recko
                                </h6>
                              </div>
                              <div
                                className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                                style={{
                                  margin: "0px",
                                  border: "0px solid rgb(229, 231, 235)",
                                  boxSizing: "border-box",
                                  borderRadius: "5px",
                                  borderColor: "rgb(185 207 202/1)",
                                  padding: "0.25rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  borderWidth: "2px",
                                }}
                              >
                                <h6
                                  className="text-xs"
                                  style={{
                                    border: "0px solid rgb(229, 231, 235)",
                                    boxSizing: "border-box",
                                    overflowWrap: "break-word",
                                    margin: "0px",
                                    fontWeight: 700,
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  and many more..
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  margin: 0px;
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  tab-size: 4;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}

body {
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizelegibility;
  overflow-y: auto;
  min-height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  line-height: inherit;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}
.parent-div * {
        color: white !important;
      }
`,
        }}
      />
    </>
  );
}
