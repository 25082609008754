import React, { useState, useEffect, useRef } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { Card } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { DefaultEditor } from "react-simple-wysiwyg";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useHistory, useParams } from "react-router-dom";
import apiHelper from "api/apiHelper";
import { useUserDetails } from "hooks/use-user-details";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  micIcon,
  PracIcon,
  LiveClassIcon,
  AssessmentIcon,
} from "./../../assets/icons/icons";
import Modal from '@material-ui/core/Modal';

import api from "../../api/baseConfig";
import moment from "moment";
import emptyStateImg from "../buy-course/sub-discussion/emptyStateImg.svg";
import { data } from "@tensorflow/tfjs";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    // flexWrap: "wrap",
    // minWidth: 300,
    // width: "100%",
    // "& > * + *": {
    //   marginTop: theme.spacing(2),
    // },
    "& .MuiAccordionSummary-root": {
      justufyContent: "space-between",
      padding: "0",
      alignItems: "center",

      "& .MuiAccordionSummary-content": {
        justifyContent: "space-between",
        alignItems: "center",
        "& p": {
          margin: "0",
        },
      },
    },
  },
}));



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={4} variant="filled" {...props} />;
}

export default function CourseNotes(props, { childNotes }) {
  const classes = useStyles();
  const addNoteElement = useRef(null);
  const [editorState, setEditorState] = useState("");
  const [editorStates, setEditorStates] = useState("");
  const [courseNote, setCourseNote] = useState([]);
  const userDetails = useUserDetails();
  const [open, setOpen] = React.useState(false);
  const [show,setShow] = useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [showContent, setShowContent] = React.useState(true);
  const [backButtonContent, setBackButtonContent] = React.useState(false);
  const [courseResourseId, setCourseResourseId] = useState("");
  const [courseResourceId, setCourseResourceId] = useState("");
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState("Default Text");

  const courseResource = props.data;

  const [VideoCurDuration, setVideoCurDuration] = useState("");
  const [AudioCurDuration, setAudioCurDuration] = useState("");
  const [AddNoteDuration, setAddNoteDuration] = useState("");
  const { courseId, resourceID } = useParams();
  const [currentDur, setCurrentDur] = useState();
  const [currentDurAud, setCurrentDurAud] = useState();
  const [courseDetails, setCourseDetails] = useState();
  const [notesTextArea, setNotesTextArea] = useState("");
  const [notesEditTextArea, setNotesEditTextArea] = useState("");
  const [discussionModuleList, setDiscussionModuleList] = useState([]);
  const [discussionCount, setDiscussionCount] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [viewCourseNote, setViewCourseNote] = useState(false);
  const [moreVert, setMoreVert] = useState(false);
  const [contentShow, setContentShow] = useState(true);
  const [courseType, setCourseType] = useState(null)
  const lastViewedResource = props.lastViewedResource;
  const courseDataDetails = props.courseDataDetails;
  const [editNoteId, setEditNoteId] = useState({id:"",content:""});

  function simpleTimeFormat(time) {
    const timePad = (val) => ("0" + Math.floor(val)).slice(-2);
    const sec = time % 60;
    const hr = Math.floor(time / 3600);
    return `${hr > 0 ? `${timePad(hr)}:` : ""}${timePad(
      (time % 3600) / 60
    )}:${timePad(time % 60)}`;
  }

  const getCourseAllContent = async () => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.courseController.getCourseDiscussion}${courseId}`,
        "get"
      );
      if (data !== []) {
        setDiscussionModuleList(data.modules);
        setDiscussionCount(data.courseDiscussionCount);
      }
    } catch (e) {}
  };

  useEffect(() => {
    const resourseId = courseResource.id;
    setCourseResourseId(resourseId);
    getCourseDetails();
    getShowAllContent();
    getCourseAllContent();
  }, []);

  useEffect(() => {
    setVideoCurDuration(props.cur_duration);
  }, [props.cur_duration]);

  useEffect(() => {
    setAudioCurDuration(props.cur_durationAud);
  }, []);

  const getCourseDetails = async () => {
    try {
      const data = await apiHelper.axiosCallPostsave(
        `${api.baseURL}${api.courseController.getCourseNotes}${courseId}?courseResourceId=${props.data.id}`,
        "get"
      );
      if (data) {
        setCourseDetails(data);
      }
    } catch (e) {
      
    }
  };

  const handleAccChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getShowAllContent = async () => {
    setShowContent(false);
    // setBackButtonContent(true);
    try {
      const data = await apiHelper.axiosCall(
        // `${api.baseURL}${api.courseController.getCourseNotes}${
        //   courseResourseId || courseId
        // }`,
        `${api.baseURL}${api.courseController.getCourseNotes}${courseId}`,
        "get"
      );
      if (data) {
        setCourseNote(data);
      }
    } catch (e) {}
  };

  const handleSaveNote = async () => {
    const addNotes = {
      userId: userDetails.email,
      courseId: courseId,
      courseModuleId: courseResourseId.moduleId,
      courseResourceId: courseResource.id,
      courseResourceId: courseResourseId.id,
      notesTaken: `${AddNoteDuration} min`,
      // notesTaken: `${timeFormatter((currentDurAud-(currentDurAud%60))/60)}:${timeFormatter(currentDurAud%60)} min`,
      courseResourceName: courseResourseId.resourceName,
      notesContent: notesTextArea,
      isActive: true,
    };
    const notes = await apiHelper.axiosCallPostsave(
      `${api.baseURL}${api.courseController.createCourseNotes}`,
      "post",
      addNotes
    );

    if (notes !== "") {
      setEditorState("");
      getCourseDetails();
      setNotesTextArea("");
    }

    getShowAllContent();
  };

  const handleSaveNoteAud = async () => {
    const addNotes = {
      userId: userDetails.email,
      courseId: courseId,
      courseModuleId: courseResourseId.moduleId,
      courseResourceId: courseResource.id,
      courseResourceId: courseResourseId.id,
      // notesTaken: `${timeFormatter((currentDur-(currentDur%60))/60)}:${timeFormatter(currentDur%60)} min`,
      notesTaken: `${timeFormatter((currentDurAud-(currentDurAud%60))/60)}:${timeFormatter(currentDurAud%60)} min`,
      courseResourceName: courseResourseId.resourceName,
      notesContent: notesTextArea,
      isActive: true,
    };
    const notes = await apiHelper.axiosCallPostsave(
      `${api.baseURL}${api.courseController.createCourseNotes}`,
      "post",
      addNotes
    );
    if (notes !== "") {
      setEditorState("");
      getCourseDetails();
      setNotesTextArea("");
    }

    getShowAllContent();
  };

  const handleMoreVert = () => {
    setMoreVert(!moreVert);
  };

  const handleNotes = (e) => {
    setNotesTextArea(e.target.value);
  };
  const handleNotesEdit = (e) => {
    setNotesTextArea(e.target.value);
    // setNotesEditTextArea(e.target.value);
  };

  const getRecord = (id) => {
    return courseNote.find((item) => item.id === id);
  };
  const handleEditNote = (data) => {
    const editedValue = courseNote.indexOf(getRecord(data.id));
    const selectedItem = courseNote[editedValue];
    setNotesTextArea(selectedItem.notesContent?selectedItem.notesContent:"");
    setEditOpen(editOpen);
    setEditNoteId({id:data.id,content:data.notesContent});
  };

  const handleReplySaveNote = async (id) => {
    const addReplyNotes = {
      id: id === "" ? editNoteId.id : id ,
      userId: userDetails.email,
      courseId: courseId,
      courseModuleId: courseResource.courseModuleId,
      courseResourceId: courseResource.id,
      notesTaken: ` 00:26 min`,
      notesTakenAud: ` 00:26 min`,
      courseResourceName: courseResource.name,
      notesContent: notesTextArea,
      // notesContent: editorStates.getCurrentContent().getPlainText(),
      isActive: true,
    };

    const notes = await apiHelper.axiosCallPostsave(
      `${api.baseURL}${api.courseController.updateCourseNotes}`,
      "put",
      addReplyNotes
    );

    if (notes !== "") {
      setEditorState("");
      getCourseDetails();
      setNotesEditTextArea("");
    }
    setEditNoteId({id:"",content:""});
    getShowAllContent();
  };

  const handleDeleteNote = (id) => {
    setAlertOpen(true);
    sessionStorage.setItem("notesId", id);
  };

  const handleDelete = async () => {
    const id = sessionStorage.getItem("notesId");
    try {
      const data = await apiHelper.axiosCallPost(
        `${api.baseURL}${api.courseController.deleteCourseNotes}${id}`,
        "delete"
      );
      if (data) {
        setOpen(true);
        setAlertOpen(false);
        getCourseDetails();
        setCourseNote(courseNote.filter((item) => item.id !== id));
      }
    } catch (e) {}
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChangePage = (id, modId, resName, resType) => {
    setViewCourseNote(!viewCourseNote);
    setContentShow(false);
    setBackButtonContent(true);
    const courseData = { id: id, moduleId: modId, resourceName: resName };
    props?.setCourseTypeForNotes(resType ? resType : null)
//     setCourseResourceId(courseData);
    setCourseResourseId(courseData, getShowAllContent());
    // setViewDiscussion(true);
    // setContentShow(false);
    // setEdit(false);
  };

  const backButton = () => {
    props?.setCourseTypeForNotes(null)
    getCourseDetails();
    setBackButtonContent(false);
    setContentShow(true);
    setViewCourseNote(false);

    // setShowContent(true);
  };

  useEffect(() => {
    if(courseDataDetails && props.handleNotesContent == true){
      handleChangePage(
        courseDataDetails.id,
        courseDataDetails.modId,
        courseDataDetails.name
      );
      setBackButtonContent(false);
      AddNote(props.cur_duration);
      AddNoteAud(props.cur_durationAud);
      setTimeout(() => {
        addNoteElement.current?.click();
      }, 100);
    }else if (props.handleNotesContent == true) {
      handleChangePage(
        lastViewedResource.id,
        lastViewedResource.courseModuleId,
        lastViewedResource.name
      );
      setBackButtonContent(false);
      AddNote(props.cur_duration);
      AddNoteAud(props.cur_durationAud);
      setTimeout(() => {
        addNoteElement.current?.click();
      }, 100);
    }
  }, [props.handleNotesContent]);

  const AddNote = (data) => {
    setAddNoteDuration(data);
    var curr = props.getDuration();
    setCurrentDur(curr);
    if (currentDur > 0.6) {
      // var mins = ((currentDur * 100) / 60).toFixed(0);
      // var sec = (currentDur * 100) % 60;
      // var time = `${mins}.${sec}`;
      // setCurrentDur(time);
    }
  };

  const AddNoteAud = (data) => {
    setAddNoteDuration(data);
    var currAud = props.getDurationAudio();
    setCurrentDurAud(currAud);
    if (currentDurAud > 0.6) {
      // var mins = ((currentDur * 100) / 60).toFixed(0);
      // var sec = (currentDur * 100) % 60;
      // var time = `${mins}.${sec}`;
      // setCurrentDur(time);
    }
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const save = (val) => {
    setValue(val);
    setEdit(false);
  };

  const close = () => {
    setEdit(false);
  };

  const timeFormatter = (num) => {
    if (`${num}`.length > 1) return `${num}`;
    else return `0${num}`;
  };
  return (
    <>
      <Box
        className={classes.root}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflowY: "scroll",
          overflow: "hidden"
        }}
        style={{background: '#FFFFFF', padding:'0px 0px',height:'100%', border: "1px solid #F2F1F3"}}
      >
       
        {backButtonContent && (
          <div
            style={{
              padding: "16px 16px 12px 16px",
              borderBottom: "1px solid #D8D6DB",
            }}
          >
            <a onClick={backButton}>
              <KeyboardBackspaceIcon /> Back to section 
            </a>
          </div>
        )}
        <Box sx={{ flex: "1 1 0%" }}>
          {contentShow && (
            <div >
              {discussionModuleList.map((data, index) => {
                return (
                  <div >
                    <Accordion
                      // className={classes.accordionStyle}
                      expanded={expanded === `panel${index}`}
                      onChange={handleAccChange(`panel${index}`)}
                      style={{backgroundColor: "#F6F6F7",
                      borderBottom:  " 0.5px solid #D8D6DB",
                      paddingTop:"2%",
                      paddingBottom:"2%",}}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        style={{ borderBottom: "1px solid rgb(242, 241, 243)" }}
                      >
                        <Typography className={classes.heading} style={{paddingLeft:'5px'}}>
                          {data.moduleName}
                        </Typography>
                        <Typography
                          className={classes.secondaryHeading}
                          // class="mx-auto"
                        >
                          {data.moduleResources.length} 
                          {data.moduleResources.length <= 1 ? " Content" : " Contents"}
                        </Typography>
                      </AccordionSummary>

                      {data.moduleResources.length > 0 &&
                        data.moduleResources.map((resourceData) => {
                          return (
                            <div style={{ background: '#FFFFFF', borderBottom: "0.5px solid #D8D6DB",}}>
                            <AccordionDetails style={{padding:"0px 0px 0px 0px",}}>
                            <div style={{width:"100%"}}>
                                <ul
                                  class="list-inline list-group"
                                >
                                  <li class="list-group-item border-0 bg-white">
                                    {(resourceData.resourceType.toLowerCase() === "Video".toLowerCase() ||
                                      resourceData.resourceType.toLowerCase() === "Link".toLowerCase()) && (
                                      <a>
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M10.0001 1.6665C5.40508 1.6665 1.66675 5.40484 1.66675 9.99984C1.66675 14.5948 5.40508 18.3332 10.0001 18.3332C14.5951 18.3332 18.3334 14.5948 18.3334 9.99984C18.3334 5.40484 14.5951 1.6665 10.0001 1.6665ZM10.0001 16.6665C6.32425 16.6665 3.33341 13.6757 3.33341 9.99984C3.33341 6.324 6.32425 3.33317 10.0001 3.33317C13.6759 3.33317 16.6667 6.324 16.6667 9.99984C16.6667 13.6757 13.6759 16.6665 10.0001 16.6665Z"
                                            fill="#7C7887"
                                          />
                                          <path
                                            d="M7.5 14.1668L14.1667 10.0002L7.5 5.8335V14.1668Z"
                                            fill="#7C7887"
                                          />
                                        </svg>

                                        <span
                                          style={{ color: "rgb(50, 48, 54)" }}

                                          // class="text-muted"
                                        >
                                          &nbsp;{resourceData.resourceName}(
                                          {
                                            courseNote.filter((e) => e.courseResourceId == resourceData.resourceId).length
                                          }
                                          )
                                        </span>
                                      </a>
                                    )}
                                    {resourceData.resourceType.toLowerCase() ===
                                      "Study material".toLowerCase() && (
                                      <a>
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M17.5001 2.5H11.6667C11.0242 2.5 10.4434 2.75083 10.0001 3.15C9.55675 2.75083 8.97591 2.5 8.33342 2.5H2.50008C2.03925 2.5 1.66675 2.87333 1.66675 3.33333V15.8333C1.66675 16.2942 2.03925 16.6667 2.50008 16.6667H7.29842C7.73675 16.6667 8.16675 16.845 8.47675 17.155L9.41092 18.0892C9.41842 18.0967 9.42841 18.0992 9.43591 18.1067C9.50758 18.1725 9.58758 18.2308 9.68091 18.27C9.68175 18.27 9.68175 18.27 9.68258 18.27C9.78342 18.3117 9.89091 18.3333 10.0001 18.3333C10.1092 18.3333 10.2167 18.3117 10.3176 18.27C10.3184 18.27 10.3184 18.27 10.3192 18.27C10.4126 18.2308 10.4926 18.1725 10.5642 18.1067C10.5717 18.0992 10.5817 18.0967 10.5892 18.0892L11.5234 17.155C11.8334 16.845 12.2634 16.6667 12.7017 16.6667H17.5001C17.9609 16.6667 18.3334 16.2942 18.3334 15.8333V3.33333C18.3334 2.87333 17.9609 2.5 17.5001 2.5ZM7.29842 15H3.33341V4.16667H8.33342C8.79342 4.16667 9.16675 4.54083 9.16675 5V15.5742C8.61841 15.205 7.96425 15 7.29842 15ZM16.6667 15H12.7017C12.0359 15 11.3817 15.205 10.8334 15.5742V5C10.8334 4.54083 11.2067 4.16667 11.6667 4.16667H16.6667V15Z"
                                            fill="#7C7887"
                                          />
                                        </svg>

                                        <span
                                          // class="text-muted"
                                          style={{ color: "rgb(50, 48, 54)" }}
                                        >
                                          &nbsp;{resourceData.resourceName}(
                                          {
                                            courseNote.filter(
                                              (e) =>
                                                e.courseResourceId ==
                                                resourceData.resourceId
                                            ).length
                                          }
                                          ){/* ({courseNote.length}) */}
                                        </span>
                                      </a>
                                    )}
                                    {resourceData.resourceType.toLowerCase() === "audio".toLowerCase() && (
                                      <a>
                                        {micIcon({
                                          size: 20,
                                          color: "#7C7887",
                                        })}
                                        <span
                                          // class="text-muted"
                                          style={{ color: "rgb(50, 48, 54)" }}
                                        >
                                          &nbsp;{resourceData.resourceName}(
                                          {
                                            courseNote.filter(
                                              (e) =>
                                                e.courseResourceId ==
                                                resourceData.resourceId
                                            ).length
                                          }
                                          ){/* {courseNote.length}) */}
                                        </span>
                                      </a>
                                    )}
                                    {resourceData.resourceType.toLowerCase() ===
                                      "Practice lab".toLowerCase() && (
                                      <a>
                                        {PracIcon({
                                          size: 20,
                                          color: "#7C7887",
                                        })}
                                        <span
                                          // class="text-muted"
                                          style={{ color: "rgb(50, 48, 54)" }}
                                        >
                                          {" "}
                                          &nbsp;{resourceData.resourceName}(
                                          {
                                            courseNote.filter(
                                              (e) =>
                                                e.courseResourceId ==
                                                resourceData.resourceId
                                            ).length
                                          }
                                          ){/* {courseNote.length}) */}
                                        </span>
                                      </a>
                                    )}
                                    {resourceData.resourceType.toLowerCase() ===
                                      "Live class".toLowerCase() && (
                                      <a>
                                        {LiveClassIcon({
                                          size: 20,
                                          color: "#7C7887",
                                        })}
                                        <span
                                          // class="text-muted"
                                          style={{ color: "rgb(50, 48, 54)" }}
                                        >
                                          &nbsp;{resourceData.resourceName}(
                                          {
                                            courseNote.filter(
                                              (e) => e.courseResourceId == resourceData.resourceId ).length
                                          }
                                          ){/* ({courseNote.length}) */}
                                        </span>
                                      </a>
                                    )}
                                    {resourceData.resourceType.toLowerCase() ===
                                      "Assessment".toLowerCase() && (
                                      <a>
                                        {AssessmentIcon({
                                          size: 20,
                                          color: "#7C7887",
                                        })}
                                        <span
                                          // class="text-muted"
                                          style={{ color: "rgb(50, 48, 54)" }}
                                        >
                                          &nbsp;{resourceData.resourceName}(
                                          {
                                            courseNote.filter(
                                              (e) =>
                                                e.courseResourceId ==
                                                resourceData.resourceId
                                            ).length
                                          }
                                          ){/* ({courseNote.length}) */}
                                        </span>
                                      </a>
                                    )}
                                    {/* {resourceData.resourceDiscussionCount && ( */}

                                    <a
                                      class="float-right discussion-color"
                                      style={{
                                        textDecoration: "none",
                                    color: "#2170D8",
                                    fontWeight: 500,
                                    fontSize: '15px',
                                      }}
                                      onClick={() =>
                                        handleChangePage(
                                          resourceData.resourceId,
                                          data.moduleId,
                                          resourceData.resourceName,
                                          resourceData.resourceType
                                        )
                                      }
                                    >
                                      View 
                                    </a>
                                    {/* )} */}
                                  </li>
                                </ul>
                              </div>
                            </AccordionDetails>
                            </div>
                          );
                        })}
                    </Accordion>
                  </div>
                );
              })}
            </div>
          )}
          {viewCourseNote && (
            <div>
              {courseNote.length > 0 ? (
                courseNote
                  ?.filter((e) => e.courseResourceId == courseResourseId.id)
                  .map((data, index) => {
                    return (
                      <div key={data.id}
                      style={{
                        borderBottom: "1px solid #D8D6DB",
                      }}>
                          
                          {(props?.courseTypeForNotes ? props?.courseTypeForNotes === "video" : props.courseType == "video") ? (
                         <div>
                           <li
                          style={{ display: "flex", gap: "198px",  background: "#F6F6F7",paddingLeft:"2%",paddingTop: "2%",}}
                        >
                         <p style={{paddingLeft:"3%",color:"#63606C",fontSize:"12px",paddingTop:"2%"}}>
                            notes at
                          </p>
                                 <div key={props.data.id}>
                                 <p
                                style={{
                                  backgroundColor: "#dcdcdc",
                                  borderRadius: "4px",
                                  width: "66px",
                                  whiteSpace: "nowrap",
                                  textAlign: "center",
                                  paddingTop: "2%",
                                }}
                              > 
                                   {data.notesTaken}
                                  
                              </p>
                             
                             
                              </div>
                              </li>
                              <div style={{ borderRadius:'3px', background:"white", paddingTop:"2%",display: "flex"}}>
                          <span style={{paddingLeft:"5%",width:"500px"}}>
                            {data.notesContent}
                            </span>
                            <a>
                            <span 
                            // style={{paddingLeft:"20%"}}
                                    // onClick={()=> setShow(true)}
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                    href={`#multiCollapseExample_${index}`}>
                              <svg width="4" height="12" viewBox="0 0 4 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.99996 4.6665C1.26663 4.6665 0.666626 5.2665 0.666626 5.99984C0.666626 6.73317 1.26663 7.33317 1.99996 7.33317C2.73329 7.33317 3.33329 6.73317 3.33329 5.99984C3.33329 5.2665 2.73329 4.6665 1.99996 4.6665ZM1.99996 0.666504C1.26663 0.666504 0.666626 1.2665 0.666626 1.99984C0.666626 2.73317 1.26663 3.33317 1.99996 3.33317C2.73329 3.33317 3.33329 2.73317 3.33329 1.99984C3.33329 1.2665 2.73329 0.666504 1.99996 0.666504ZM1.99996 8.6665C1.26663 8.6665 0.666626 9.2665 0.666626 9.99984C0.666626 10.7332 1.26663 11.3332 1.99996 11.3332C2.73329 11.3332 3.33329 10.7332 3.33329 9.99984C3.33329 9.2665 2.73329 8.6665 1.99996 8.6665Z" fill="#63606C"/>
    </svg>
    
                              </span></a>

                            <div style={{paddingRight:'5%'}}>
                            <div style={{ background:"white",}}
                            className="collapse"
                            id={`multiCollapseExample_${index}`}>
                             
                              <span>
                                <div>
                                  <a>
                                  <span
                                    style={{borderBottom: "1px solid #D8D6DB",}}
                                    // onClick={() => handleEditNote(data)}
                                    onClick={() => {
                                      handleEditNote(data);
                                      setShow(!show);
                                    }
                                      }
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                    href={`#collapseEdit`}
                                    
                                  >
                                    Edit
                                  </span>
                                  </a>
                                </div>
                                <div>
                                  <a>
                                  <span
                                  style={{borderBottom: "1px solid #D8D6DB",}}
                                  data-toggle="collapse"
                                  href={`#multiCollapseExample_${index}`}
                                  onClick={() => handleDeleteNote(data.id)}
                                  >
                                    Delete
                                  </span>
                                  </a>
                                </div>
                              </span>
                              </div>
                            
                            </div>
                            </div>
                              </div>
                          ): (props?.courseTypeForNotes ? props?.courseTypeForNotes === "audio" : props.courseType == "audio") ? (
                            <div>
                            <li
                           style={{ display: "flex", gap: "198px",  background: "#F6F6F7",paddingLeft:"2%",paddingTop: "2%",}}
                         >
                          <p style={{paddingLeft:"3%",color:"#63606C",fontSize:"12px",paddingTop:"2%"}}>
                             notes at
                           </p>
                                  <div key={props.data.id}>
                                  <p
                                 style={{
                                   backgroundColor: "#dcdcdc",
                                   borderRadius: "4px",
                                   width: "66px",
                                   whiteSpace: "nowrap",
                                   textAlign: "center",
                                   paddingTop: "2%",
                                 }}
                               > 
                                    {data.notesTaken}
                                   
                               </p>
                              
                              
                               </div>
                               </li>
                               <div style={{ borderRadius:'3px', background:"white", paddingTop:"2%",display: "flex"}}>
                           <span style={{paddingLeft:"5%",width:"500px"}}>
                             {data.notesContent}
                             </span>
                             <a>
                             <span 
                             // style={{paddingLeft:"20%"}}
                                     // onClick={()=> setShow(true)}
                                     data-toggle="collapse"
                                     aria-expanded="false"
                                     href={`#multiCollapseExample_${index}`}>
                               <svg width="4" height="12" viewBox="0 0 4 12" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M1.99996 4.6665C1.26663 4.6665 0.666626 5.2665 0.666626 5.99984C0.666626 6.73317 1.26663 7.33317 1.99996 7.33317C2.73329 7.33317 3.33329 6.73317 3.33329 5.99984C3.33329 5.2665 2.73329 4.6665 1.99996 4.6665ZM1.99996 0.666504C1.26663 0.666504 0.666626 1.2665 0.666626 1.99984C0.666626 2.73317 1.26663 3.33317 1.99996 3.33317C2.73329 3.33317 3.33329 2.73317 3.33329 1.99984C3.33329 1.2665 2.73329 0.666504 1.99996 0.666504ZM1.99996 8.6665C1.26663 8.6665 0.666626 9.2665 0.666626 9.99984C0.666626 10.7332 1.26663 11.3332 1.99996 11.3332C2.73329 11.3332 3.33329 10.7332 3.33329 9.99984C3.33329 9.2665 2.73329 8.6665 1.99996 8.6665Z" fill="#63606C"/>
     </svg>
     
                               </span></a>
 
                             <div style={{paddingRight:'5%'}}>
                             <div style={{ background:"white",}}
                             className="collapse"
                             id={`multiCollapseExample_${index}`}>
                              
                               <span>
                                 <div>
                                   <a>
                                   <span
                                     style={{borderBottom: "1px solid #D8D6DB",}}
                                    //  onClick={() => handleEditNote(data)}
                                     onClick={() => {
                                      handleEditNote(data);
                                      setShow(!show);
                                    }
                                      }
                                     data-toggle="collapse"
                                     aria-expanded="false"
                                     href={`#collapseEdit`}
                                     
                                   >
                                     Edit
                                   </span>
                                   </a>
                                 </div>
                                 <div>
                                   <a>
                                   <span
                                   style={{borderBottom: "1px solid #D8D6DB",}}
                                   data-toggle="collapse"
                                   href={`#multiCollapseExample_${index}`}
                                   onClick={() => handleDeleteNote(data.id)}
                                   >
                                     Delete
                                   </span>
                                   </a>
                                 </div>
                               </span>
                               </div>
                             
                             </div>
                             </div>
                               </div>
                          ):(
                            <div>
                              <li
                          style={{ display: "flex", gap: "250px",  background: "#F6F6F7",paddingLeft:"2%", paddingTop:"2%"}}
                        >
                          <p style={{paddingLeft:"3%",color:"#63606C",fontSize:"12px",paddingTop:"2%"}}>
                            notes 
                          </p>
                     </li>
                     <div style={{ borderRadius:'3px', background:"white", paddingTop:"2%",display: "flex"}}>
                          <span style={{paddingLeft:"5%",width:"500px"}}>
                            {data.notesContent}
                            </span>
                            <a>
                            <span 
                            // style={{paddingLeft:"20%"}}
                                    // onClick={()=> setShow(true)}
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                    href={`#multiCollapseExample_${index}`}>
                              <svg width="4" height="12" viewBox="0 0 4 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.99996 4.6665C1.26663 4.6665 0.666626 5.2665 0.666626 5.99984C0.666626 6.73317 1.26663 7.33317 1.99996 7.33317C2.73329 7.33317 3.33329 6.73317 3.33329 5.99984C3.33329 5.2665 2.73329 4.6665 1.99996 4.6665ZM1.99996 0.666504C1.26663 0.666504 0.666626 1.2665 0.666626 1.99984C0.666626 2.73317 1.26663 3.33317 1.99996 3.33317C2.73329 3.33317 3.33329 2.73317 3.33329 1.99984C3.33329 1.2665 2.73329 0.666504 1.99996 0.666504ZM1.99996 8.6665C1.26663 8.6665 0.666626 9.2665 0.666626 9.99984C0.666626 10.7332 1.26663 11.3332 1.99996 11.3332C2.73329 11.3332 3.33329 10.7332 3.33329 9.99984C3.33329 9.2665 2.73329 8.6665 1.99996 8.6665Z" fill="#63606C"/>
    </svg>
    
                              </span></a>

                            <div style={{paddingRight:'5%'}}>
                            <div style={{ background:"white",}}
                            className="collapse"
                            id={`multiCollapseExample_${index}`}>
                             
                              <span>
                                <div>
                                  <a>
                                  <span
                                    style={{borderBottom: "1px solid #D8D6DB",}}
                                    onClick={() => {
                                      handleEditNote(data);
                                      setShow(!show);
                                    }
                                      }
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                    href={`#collapseEdit`}
                                    
                                  >
                                    Edit
                                  </span>
                                  </a>
                                </div>
                                <div>
                                  <a>
                                  <span
                                  style={{borderBottom: "1px solid #D8D6DB",}}
                                  data-toggle="collapse"
                                  href={`#multiCollapseExample_${index}`}
                                  onClick={() => handleDeleteNote(data.id)}
                                  >
                                    Delete
                                  </span>
                                  </a>
                                </div>
                              </span>
                              </div>
                            
                            </div>
                            </div>
                            </div>
                          )}
    
                        
                         {/* <div style={{ borderRadius:'3px', background:"white", paddingTop:"2%"}}>
                          <span style={{paddingLeft:"5%"}}>
                            {data.notesContent}
                            </span>
                            </div> */}
                       
                      

                    
                          <div className="m-2">
                          <div
                            className="collapse"
                            id={`multiCollapseExample_${index}`}
                          >
                            <textarea
                              style={{ width: "100%", height: "150px" }}
                              defaultValue={data.notesContent}
                              vlaue={notesTextArea}
                              onChange={handleNotesEdit}
                            ></textarea>{" "}
                            <div style={{ display: "flex" }}>
                              <button
                                type="button"
                                className="btn btn-success m-3 "
                                style={{ width: "104px" }}
                                onClick={() => handleReplySaveNote(data.id)}
                                data-toggle="collapse"
                                href={`#multiCollapseExample_${index}`}
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-secondary m-3"
                                style={{ width: "104px" }}
                                data-toggle="collapse"
                                href={`#multiCollapseExample_${index}`}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                       
                      </div>
                    );
                  })
              ) : (
                <div>
                  <div style={{ padding: "20% 0 0 30%" }}>
                    <img src={emptyStateImg} alt="" />
                  </div>
                  <p style={{textAlign:'center'}}>No notes available</p>
                  <p style={{textAlign:'center'}}>Click the button below to Add notes</p>
                </div>
              )}
            </div>
          )}
        </Box>

        

        {viewCourseNote && (
          
          <div key={props.data.id}>
             
              {(props?.courseTypeForNotes ? props?.courseTypeForNotes === "video" : props.courseType == "video") ? (
                 <div
                 class="fixed-bottom"
                 key={props.data.id}
                 style={{
                   position:"absolute",
                   paddingBottom:"55px",
                 }}
               >
                {!show ? (
                 <div className="collapse" id="collapseExample" style={{ margin: "0" , backgroundColor:"#ffffff"}}>
                 <div className="card card-body" style={{ margin: "0" ,}}>
                 <p>Add notes at {`${AddNoteDuration}`} </p>
                 <textarea
                  style={{ height: "150px" }}
                  onChange={handleNotes}
                  value={notesTextArea}
                ></textarea>
              </div>
              <button
                type="button"
                className="btn btn-success m-3 "
                style={{ width: "104px" }}
                onClick={handleSaveNote}
                data-toggle="collapse"
                href="#collapseExample"
                disabled={!notesTextArea}
              >
                Add
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary m-3"
                style={{ width: "104px" }}
                data-toggle="collapse"
                href="#collapseExample"
              >
                Cancel
              </button>
            </div>
                ):(
                  <div></div>
                )}
            </div>
                ) : (props?.courseTypeForNotes ? props?.courseTypeForNotes === "audio" : props.courseType == "audio") ? (
                // props?.courseTypeForNotes === "audio" ? (
                  <div
                  class="fixed-bottom"
                  key={props.data.id}
                  style={{
                    position:"absolute",
                    paddingBottom:"55px"
                  }}
                >
                  {!show ? (
                  <div className="collapse" id="collapseExample" style={{backgroundColor:"#ffffff" }}>
              <div className="card card-body" style={{ margin: "0", backgroundColor:"#ffffff" }}>
                  <p>Add notes at {`${timeFormatter((currentDurAud-(currentDurAud%60))/60)}:${timeFormatter(currentDurAud%60)}`} </p>
                  <textarea
                  style={{ height: "150px" }}
                  onChange={handleNotes}
                  value={notesTextArea}
                ></textarea>
              </div>           
              <button
                type="button"
                className="btn btn-success m-3 "
                style={{ width: "104px" }}
                onClick={handleSaveNoteAud}
                data-toggle="collapse"
                href="#collapseExample"
                disabled={notesTextArea.trim() === ""}
              >
                Add
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary m-3"
                style={{ width: "104px" }}
                data-toggle="collapse"
                href="#collapseExample"
              >
                Cancel
              </button>
            </div>
                  ):(
                  <div></div>
                  )}
            </div>
                ) : (
                  <div
                  class="fixed-bottom"
                  key={props.data.id}
                  style={{
                    position:"absolute",
                    paddingBottom:"55px"
                  }}
                >
                  {!show ? (
                  <div className="collapse" id="collapseExample" style={{backgroundColor:"#ffffff" }}>
              <div className="card card-body" style={{ margin: "0", backgroundColor:"#ffffff" }}>
                  <p>Add notes </p>
                  <textarea
                    style={{ height: "150px" }}
                    onChange={handleNotes}
                    value={notesTextArea}
                ></textarea>
              </div> 
              <button
                type="button"
                className="btn btn-success m-3 "
                style={{ width: "104px" }}
                onClick={handleSaveNote}
                data-toggle="collapse"
                href="#collapseExample"
                disabled={!notesTextArea}
              >
                Add
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary m-3"
                style={{ width: "104px" }}
                data-toggle="collapse"
                href="#collapseExample"
              >
                Cancel
              </button>
            </div>
            
                  ):(
                  <div></div>
                  )}
            </div>
                )}
             
            

         
             <div
                  className="m-2"
               
               >
              <div   class="fixed-bottom"
 
 style={{
   position:"absolute",
   paddingBottom:"55px"
 }}>
            <div className="collapse" id="collapseEdit" style={{ backgroundColor:"#ffffff" }}>
              <div className="card card-body" style={{ margin: "0",backgroundColor:"#ffffff" }}>
              <textarea
                  style={{ height: "150px" }}
                  onChange={handleNotesEdit}
                  value={notesTextArea}
                ></textarea>
              </div>
              <button
                type="button"
                className="btn btn-success m-3 "
                style={{ width: "104px" }}
                onClick = {() => {handleReplySaveNote("")}}
                data-toggle="collapse"
                href="#collapseEdit"
                disabled={notesTextArea.trim() === ""}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary m-3"
                style={{ width: "104px" }}
                data-toggle="collapse"
                href="#collapseEdit"
              >
                Cancel
              </button>
            </div>
            </div>
            <div
              class="fixed-bottom"
              key={props.data.id}
              style={{
                padding: "12px 16px 16px 16px",
                borderTop: "1px solid #D8D6DB",
                textAlign: "center",
                position:"absolute",
                backgroundColor:"#ffffff"
              }}
            >
              {(props?.courseTypeForNotes ? props?.courseTypeForNotes === "video" : props.courseType == "video") ? (
                <div 
                // class="fixed-bottom" style={{position:"absolute",paddingBottom:"20px"}}
                key={props.data.id}>
                  <Button
                    variant="filled"
                    // className="add-note-button"
                    ref={addNoteElement}
                    style={{
                      whiteSpace: "nowrap",
                      backgroundColor: "#794DF5",
                      color: "#fff",
                      textTransform: "none",
                      width: "321px",
                    }}
                    data-toggle="collapse"
                    href="#collapseExample"
                    onClick={() => AddNote(simpleTimeFormat(VideoCurDuration))}
                  >
                    <AddIcon />
                   
                      <>Add a note at  {`${simpleTimeFormat(VideoCurDuration)}`} min</>
                      {/* <p></p> */}
                 
                  </Button>
                </div>
              ) : (props?.courseTypeForNotes ? props?.courseTypeForNotes === "audio" : props.courseType == "audio") ? (
                <div key={props.data.id}>
                <Button
                  variant="filled"
                  // className="add-note-button"
                  ref={addNoteElement}
                  style={{
                    whiteSpace: "nowrap",
                    backgroundColor: "#794DF5",
                    color: "#fff",
                    textTransform: "none",
                    width: "321px",
                  }}
                  data-toggle="collapse"
                  href="#collapseExample"
                  onClick={() => AddNoteAud(AudioCurDuration)}
                >
                  <AddIcon />
                  {/* {currentDurAud ?
                    <>Add a note at  {`${timeFormatter((currentDurAud-(currentDurAud%60))/60)}:${timeFormatter(currentDurAud%60)}`} min</>
                  :
                    <>Add a note</>
                  } */}
                  <>Add a note at  {`${timeFormatter((currentDurAud-(currentDurAud%60))/60)}:${timeFormatter(currentDurAud%60)}`} min</>
                </Button>
              </div>
              ):(
                <Button
                  variant="filled"
                  ref={addNoteElement}
                  style={{
                    whiteSpace: "nowrap",
                    backgroundColor: "#794DF5",
                    color: "#fff",
                    textTransform: "none",
                    width: "321px",
                  }}
                  // className="add-note-button"
                  data-toggle="collapse"
                  href="#collapseExample"
                  onClick={() => AddNote(VideoCurDuration)}
                >
                  <AddIcon />
                  Add a notes
                </Button>
              )}
            </div>
          </div>
          </div>
        )}
      </Box>

      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} style={{ backgroundColor: "#794df5" }}>
          Course Notes deleted Successfully
        </Alert>
      </Snackbar>
      
      {/* <Modal
               open={alertOpen}
               onClose={() => setAlertOpen(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
                <div
                  style={modalStyle}
                  className={classes.paper}
                >
               
               
                  <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
                
                  
                  < br />
                  <p style={paraStyle}>
                        <div><svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
<path d="M8.5 8V40C8.5 41.0609 8.92143 42.0783 9.67157 42.8284C10.4217 43.5786 11.4391 44 12.5 44H36.5C37.5609 44 38.5783 43.5786 39.3284 42.8284C40.0786 42.0783 40.5 41.0609 40.5 40V16.684C40.4999 16.1511 40.3934 15.6236 40.1866 15.1325C39.9799 14.6414 39.6771 14.1965 39.296 13.824L30.416 5.14C29.6687 4.40932 28.6651 4.00013 27.62 4H12.5C11.4391 4 10.4217 4.42143 9.67157 5.17157C8.92143 5.92172 8.5 6.93913 8.5 8V8Z" stroke="#BDBBC3" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.5 26H30.5" stroke="#BDBBC3" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.5 34H24.5" stroke="#BDBBC3" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M28.5 4V12C28.5 13.0609 28.9214 14.0783 29.6716 14.8284C30.4217 15.5786 31.4391 16 32.5 16H40.5" stroke="#BDBBC3" strokeWidth="2" stroke-linejoin="round"/>
</svg></div>
                    <div>  Are you sure want to delete this Submission?</div>
                    <div style={{alignItems:'center'}}>  Once deleted cannot be recovered
</div>
                    </p>
                   
                   
                  </div><br />
                  <div
                    style={{ 
                      display:'flex',
                      justifyContent: 'center',
                    }}
                  >
                    
                    <div style={{paddingRight:'24px'}}>
                      <button
                        style={{
                          background: '#F2F1F3', 
                          paddingLeft:'38px',
                          paddingRight:'37px',
                          paddingTop:'8px',
                          paddingBottom:'7px',
                          border:'none',
                          borderRadius:'3px'
                        }}
                        onClick={() => setAlertOpen(false)}
                        >
                          <span 
                          style={{
                            fontFamily: 'Poppins',
                            fontStyle:'normal',
                            fontWeight:'normal',
                            fontSize: '14px',
                            lineHeight: '26px',
                            color: '#4A4851',
                            textAlign:'center'
                            }}>
                              Cancel
                            </span>
                        </button>
                    </div>
                    <div>
                      <button 
                        style={{
                          background: '#D42216',
                          color:'#ffffff',
                          paddingLeft:'38px',
                          paddingRight:'37px',
                          paddingTop:'8px',
                          paddingBottom:'7px',
                          border:'none',
                          borderRadius:'3px'
                        }}
                        onClick={handleDelete}
                      >
                        <span 
                          style={{
                            fontFamily: 'Poppins',
                            fontStyle:'normal',
                            fontWeight:'normal',
                            fontSize: '14px',
                            lineHeight: '26px',
                            color: '#ffffff',
                            textAlign:'center'
                            }}>
                              Delete
                            </span>
                      </button>
                    </div>
                  </div>
                </div>
            </Modal> */}

      <Dialog
        open={alertOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAlertOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <span style={{ 
                      display:'flex',
                      justifyContent: 'center',
                    }}>Delete note?</span></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <span style={{alignItems:'center',paddingLeft:'5%'}}>
            Are you sure to delete this note?
            </span>
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            Once deleted cannot be recovered
          </DialogContentText>
        </DialogContent>

        <DialogActions>
        <div
                    style={{ 
                      display:'flex',
                      justifyContent: 'center',
                      paddingRight:'24px',
                    }}
                  >
                    
                    <div style={{paddingRight:'24px'}}>
                      <button
                        style={{
                          background: '#F2F1F3', 
                          paddingLeft:'38px',
                          paddingRight:'37px',
                          paddingTop:'8px',
                          paddingBottom:'7px',
                          border:'none',
                          borderRadius:'3px'
                        }}
                        onClick={() => setAlertOpen(false)}
                        >
                          <span 
                          style={{
                            fontFamily: 'Poppins',
                            fontStyle:'normal',
                            fontWeight:'normal',
                            fontSize: '14px',
                            lineHeight: '26px',
                            color: '#4A4851',
                            textAlign:'center'
                            }}>
                              Cancel
                            </span>
                        </button>
                    </div>
                    <div >
                      <button 
                        style={{
                          background: '#D42216',
                          color:'#ffffff',
                          paddingLeft:'38px',
                          paddingRight:'37px',
                          paddingTop:'8px',
                          paddingBottom:'7px',
                          border:'none',
                          borderRadius:'3px'
                        }}
                        onClick={handleDelete}
                      >
                        <span 
                          style={{
                            fontFamily: 'Poppins',
                            fontStyle:'normal',
                            fontWeight:'normal',
                            fontSize: '14px',
                            lineHeight: '26px',
                            color: '#ffffff',
                            textAlign:'center'
                            }}>
                              Delete
                            </span>
                      </button>
                    </div>
                    </div>
          {/* <Button onClick={() => setAlertOpen(false)} color="primary">
            Disagree
          </Button>
          <Button onClick={handleDelete} color="primary">
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}