import { useCallback, useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@material-ui/icons/Delete";
import { DummyUpload } from "components/DummyUpload";
import { ToastContainer } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SendMediaHof } from "pages/utils/SendMediaHOF";
import styles from "./AddMediaComponent.module.scss";
import { DefaultToast } from "components/DefaultToast";
import { useMediaQuery } from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export const AddMediaComponent = ({
  handleChange,
  value,
  mediaType,
  nameRequired,
  setAttachmentName,
  setValue,
  isSubmited,
  editingValue,
}) => {
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const [uploading, setUploading] = useState(false);
  const [task, setTask] = useState();
  const [fileName, setFileName] = useState();
  const [deleteFile, setDeleteFile] = useState([])
  

  const mediaFile = useRef([]);
  const mediaFileName = useRef([]);
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections && fileRejections.length > 0) {
      DefaultToast({
        message: `${
          fileRejections[0]?.errors?.[0]?.code === "file-too-large"
            ? "File size must be less than 10MB" :
            fileRejections.length > 0 ?
            "Multi upload restrict"
            : "Invalid file type"
        }`,
        style: {},
        classes: {
          className: styles.toast__error,
        },
        position: "bottom-start",
      });
    } else if (acceptedFiles && acceptedFiles.length > 0) {
      UploadVideo(acceptedFiles);

    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: mediaType,
    minSize: 0,
    maxFiles: 1,
    maxSize: 10000000,
  });

  const UploadVideo = async (video) => {
    if (nameRequired) {
      setAttachmentName(video[0].name);
    }
    setUploading(true);
    const res = await SendMediaHof(video);
    setUploading(false);
    if (res) {
      mediaFile.current = [...mediaFile.current, res];
      mediaFileName.current = [...mediaFileName.current, video[0].name];
      handleChange(mediaFile.current, mediaFileName.current);
      setValue("submissionFile", mediaFile.current);
      mediaFileName.current = mediaFileName.current.filter((e) => e !== undefined)
      setFileName(mediaFileName.current)
     
    }
    DefaultToast({
      message: "Successfully added",
      style: {},
      classes: {
        className: styles.toast__success,
      },
      position: "bottom-start",
    });
  };
  
  const removeProjectFile = (index) => {
    const newTasks = [...mediaFile.current];
    const fileNameDel = [...mediaFileName.current]
    newTasks.splice(index, 1);
    fileNameDel.splice(index, 1);
    setValue("submissionFile", newTasks);
    setFileName(fileNameDel);
    setDeleteFile(fileNameDel)
    mediaFile.current = [];
  };

  useEffect(() => {
    mediaFileName.current = [fileName]
  },[deleteFile])

  useEffect(() => {
    if(editingValue?.length > 0) {
      mediaFileName.current = [editingValue]
      mediaFile.current = [editingValue]
    }
  },[editingValue])
  return (
    <>
     {fileName ?
    <div>
        {fileName.filter((e) => e?.length > 0).map((e, index) => (
          <div className="pt-3 pb-2">
          <div className={smallScreen ? styles.uploadedStyleMobile : styles.uploadedStyle} key={index}>
            <div>
              <span>
                {e}
              </span>{" "}
              {/* <span>{value?.match(/\.[0-9a-z]+$/i)?.[0]}</span> */}
            </div>
            <span onClick={() => {removeProjectFile(index)}}>
            <HighlightOffIcon
                              style={{ color: "#EB5757" }}
                             
                            />
             
            </span>
          </div>
          </div>
        ))}
    </div>
    :
    (value && !fileName) && (
      <div>
        {value.filter((e) => e?.length > 0).map((e, index) => (
          <div className="pt-3 pb-2">
          <div className={smallScreen ? styles.uploadedStyleMobile : styles.uploadedStyle} key={index}>
            <div>
              <span>
                {typeof e == 'string' ? e?.split('/')?.[4] || "" : e}
              </span>{" "}
              {/* <span>{e?.match(/\.[0-9a-z]+$/i)?.[0]}</span> */}
            </div>
            <span onClick={() => {removeProjectFile(index)}}>
            <HighlightOffIcon
                              style={{ color: "#EB5757" }}
                             
                            />
             
            </span>
          </div>
          </div>
        ))}
      </div>
    )
  }
      {!isSubmited && (
        <div
          {...getRootProps()}
          className={smallScreen ? styles.containerMobile : styles.container}
        >
          {uploading ? (
            <CircularProgress />
          ) : !value ? (
            <>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
              )}
              <div className={styles.maxSize}>Max size - 10MB</div>
              <DummyUpload />
            </>
          ) : (
            <>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
              )}
              <div className={styles.maxSize}>Max size - 10MB</div>
              <DummyUpload />
            </>
          )}
        </div>
      )}
      <ToastContainer />
    </>
  );
};
