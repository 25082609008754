import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import "./composeMail.scss";
import styles from "./ComposeMail1.module.scss";
import { RequiredIndicator } from "../../utils/RequiredIndicator";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import api from "../../../api/baseConfig";
import apiHelper from "../../../api/apiHelper";
import { Button } from "@material-ui/core";
import { GetObjectFromString } from "../../utils/GetObjectFromString";
import { ReactSelectTervStyle } from "../../utils/ReactSelectTervStyle";
import StyledNumberInput from "./../../../components/StyledNumberInput";
import CustomSelect from "./../../../components/CustomSelect";
import { emailRegex } from "../..//utils/constants";
import {
  TervButtonFilled,
  TervButtonOutlined,
} from "./../../../components/TervButtons";
import RichTextEditor from "./../../../components/RichTextEditor";
import { AddMediaComponent } from "./../../../components/AddMediaComponent";
import ScheduleMailModal from "./ScheduleMail";
import MailTemplateModal from "./MailTemplates";
import { dataURItoFile } from "../../utils/dataURIhelpers";
import { SendMediaHof } from "../../utils/SendMediaHOF";
import { GetObjectArray } from "../../utils/GetObjectArray";
import { ErrorBlock } from "./../../../components/ErrorBlock";
import { StudentFilter } from "../../utils/StudentFilter";
import { sessionPrivileges } from "privileges";

const SendMailDumb = ({
  checkDuplicate,
  sendMail,
  scheduledTime,
  setScheduledTime,
  students,
  studentsCount,
  setDraft,
  setScheduled,
  selectedStudents,
  templates,
  setAttachmentName,
  setShowMailLoadingMsg,
  editData,
  viewOnly,
  customer,
  customerName,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    control,
    reset,
    setValue,
    getValue,
  } = useForm({ mode: "onBlur" });

  const Priv = sessionPrivileges().userprivileges || [];

  const checkMailImg = async () => {
    let m,
      urls = [],
      str = watch("mailMessage"),
      rex = /src="(data:image\/[^;]+;base64[^"]+)"/g;

    while ((m = rex.exec(str))) {
      urls.push(m[1]);
    }

    if (urls.length > 0) {
      const resp = await SendMediaHof([
        dataURItoFile(...urls, "signatureImage"),
      ]);
      const finalRes = watch("mailMessage").replace(...urls, resp);
      setValue("mailMessage", finalRes);
      urls = [];
    }
  };

  const handleUpdateChange = () => {
    if (selectedStudents) {
      setValue("updateStudentList", selectedStudents);
    }
  };

  const bxCaretDown = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9987 11.3333L12.6654 6H3.33203L7.9987 11.3333Z"
        fill="white"
      />
    </svg>
  );

  const handleSetTemplate = (tempName, tempMsg) => {
    setValue("subject", tempName);
    setValue("mailMessage", tempMsg);
  };

  const [sendOption, setSendOption] = useState(false);
  const [updateStudentList, setUpdateStudentList] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [mailTemplateModal, setMailTemplateModal] = useState(false);

  const submitScheduleTimer = ({ scheduleTimer }) => {
    setScheduled(true);
    setScheduledTime(scheduleTimer);
    setDraft(false);
  };

  useEffect(() => {
    if (editData?.edit) {
      reset({
        carbonCopy: editData?.row?.mailDetails?.cc,
        updateStudentList: editData?.row?.mailDetails?.bcc,
        subject: editData?.row?.mailDetails?.subject,
        mailMessage: editData?.row?.mailDetails?.message,
      });
    }
  }, [reset]);
  return (
    <div className="page-content">
      <form onSubmit={handleSubmit(sendMail)} className="customBatch__form">
        <h4 className="terv-form-header">Compose a mail</h4>
        <ScheduleMailModal
          isOpen={scheduleModal}
          toggle={() => {
            setScheduleModal(!scheduleModal);
          }}
          scheduledTime={scheduledTime}
          setScheduledTime={setScheduledTime}
          setScheduled={setScheduled}
          submitScheduleTimer={submitScheduleTimer}
          disabled={isSubmitting}
          setShowMailLoadingMsg={setShowMailLoadingMsg}
        />
        <MailTemplateModal
          isOpen={mailTemplateModal}
          toggle={() => {
            setMailTemplateModal(!mailTemplateModal);
          }}
          templates={templates}
          handleSetTemplate={(tempName, tempMsg) => {
            handleSetTemplate(tempName, tempMsg);
          }}
        />
        <div className="terv-row terv-row-lg">
          <div>
            <label className="terv-label" htmlFor="carbon_copy">
              CC
            </label>
            <Controller
              name="carbonCopy"
              control={control}
              // rules={{
              //   validate: checkUniqueMail,
              //   pattern: {
              //     value: emailRegex,
              //     message: "Please enter a valid email",
              //   },
              // }}
              render={({ field: { onChange, value, onBlur } }) => (
                <CreatableSelect
                  inputId="carbon_copy"
                  isMulti
                  isSearchable
                  options={[{}]}
                  onBlur={onBlur}
                  onChange={(val) => {
                    onChange(val ? val.map((it) => it.value) : []);
                    // onChange(val ? val.value : []);
                  }}
                  value={GetObjectArray(value)}
                  styles={ReactSelectTervStyle("xlg", true)}
                  maxMenuHeight={120}
                  isDisabled={viewOnly}
                />
              )}
            />
            {errors.carbonCopy && (
              <div style={{ color: "red", maxWidth: 400 }}>
                {errors.carbonCopy.message}
              </div>
            )}
          </div>
          <div>
            <label className="terv-label" htmlFor="blind_carbon_copy">
              {RequiredIndicator("BCC ")}
            </label>
            {!updateStudentList ? (
              <div className={styles.studentsInfoContainer}>
                <p className={styles.studentsCountInfo}>
                  {studentsCount} students selected
                </p>
                <p
                  className={styles.updateStudentsButton}
                  onClick={() => {
                    setUpdateStudentList(true);
                    handleUpdateChange();
                  }}
                >
                  Update
                </p>
              </div>
            ) : (
              <>
                <Controller
                  name="updateStudentList"
                  control={control}
                  rules={{
                    required: "Please select a student",
                    // pattern: {
                    //     value: emailRegex,
                    //     message: "Please enter a valid email",
                    // },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      inputId="blind_carbon_copy"
                      isMulti
                      isSearchable
                      options={students}
                      onChange={(val) => {
                        onChange(val ? val.map((it) => it.value) : []);
                      }}
                      value={GetObjectArray(students, value)}
                      styles={ReactSelectTervStyle("xlg", true)}
                      maxMenuHeight={120}
                      isDisabled={viewOnly}
                    />
                  )}
                />
              </>
            )}
            {errors.updateStudentList && (
              <div style={{ color: "red", maxWidth: 300 }}>
                {errors.updateStudentList.message}
              </div>
            )}
          </div>
          <div>
            <label className="terv-label" htmlFor="mail_subject">
              {RequiredIndicator("Subject ")}
            </label>
            <input
              id="mail_subject"
              {...register("subject", {
                required: "Please enter a subject for the mail",
              })}
              className="terv-form terv-form-lg"
              style={{ width: "41.5rem" }}
              //   style={{ pointerEvents: editData?.type === "viewOnly" ? "none" : "" }}
              disabled={viewOnly}
            />
            {errors.subject && (
              <div style={{ color: "red", maxWidth: 300 }}>
                {errors.subject.message}
              </div>
            )}
          </div>
          <div>
            <label className="terv-label" htmlFor="mail_message">
              {RequiredIndicator("Mail ")}
            </label>
            <Controller
              name="mailMessage"
              control={control}
              rules={{ required: "Please enter a message for your mail" }}
              render={({ field: { onChange, value } }) => (
                <RichTextEditor
                  id="mail_message"
                  value={value || ""}
                  onChange={(val) => {
                    onChange(val);
                    checkMailImg();
                  }}
                  className={styles.mailEditorStyle}
                  // error={errors._packDescError}
                />
              )}
            />
            {errors.mailMessage && (
              <div style={{ color: "red", maxWidth: 300 }}>
                {errors.mailMessage.message}
              </div>
            )}
          </div>
          <div>
            <label className="terv-label" htmlFor="mail_attachment">
              Upload files
            </label>
            <Controller
              name="mailAttachment"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AddMediaComponent
                  handleChange={onChange}
                  value={value}
                  mediaType={"video/*, application/*, image/*"}
                  nameRequired
                  setAttachmentName={setAttachmentName}
                  moduleName={"communication"}
                  customerName={customerName}
                  // currentId={courseId}
                />
              )}
            />
          </div>
        </div>
        <hr className="terv-divider" />
        <div style={{ display: "flex", gap: "1.5rem" }}>
          <TervButtonFilled
            text={"Send"}
            type="button"
            icon1={bxCaretDown}
            onClick={() => {
              setSendOption(!sendOption);
            }}
            disabled={isSubmitting || viewOnly}
          />
          <TervButtonOutlined
            text={"Save to draft"}
            type="submit"
            onClick={() => {
              setDraft(true);
              setScheduled(false);
              setSendOption(false);
              setShowMailLoadingMsg(
                "Please Hold On, While your mails is being saved as draft"
              );
            }}
            disabled={isSubmitting || viewOnly}
          />
          <TervButtonOutlined
            text={"Use mail template"}
            type="button"
            color="#1BA94C"
            onClick={() => {
              setMailTemplateModal(!mailTemplateModal);
              setSendOption(false);
            }}
            style={{ border: "1px solid #1BA94C" }}
          />
        </div>
        {sendOption ? (
          <div className={styles.sendOptionContainer}>
            <Button
              type="submit"
              className={styles.sendOptionButtons}
              id="submitButton"
              onClick={() => {
                setShowMailLoadingMsg(
                  "Please Hold On, While your mail is being sent"
                );
              }}
            >
              {"Send now"}
            </Button>
            <Button
              className={styles.sendOptionButtons}
              onClick={() => {
                setScheduleModal(!scheduleModal);
              }}
            >
              Schedule Mail
            </Button>
          </div>
        ) : (
          ""
        )}
        {/* <div>
                <TervButtonFilled
                text={"Send now"}
                type="submit"
                onClick={handlePopOver}
                disabled={isSubmitting}
            />
                </div>
                <Button type="button" className={styles.scheduleMailButton}>
                    Schedule mail
                </Button>
            </Box>
        </Menu>
        </div> */}
      </form>
      <div className="custom__batch__back">
        <Link
          to={{
            pathname: "/communication",
            state: {
              customer: customer,
            },
            // state: {
            //   customer: customer,
            //   activePage: activePage,
            //   itemsPerPage: itemsPerPage,
            // },
          }}
          className="terv-back"
        >
          <i className="mdi mdi-arrow-left mr-1" /> Back to Mail List{" "}
        </Link>
      </div>
    </div>
  );
};

export default SendMailDumb;
