import { useState, useEffect } from "react";
import {
  List,
  Menu,
  MenuItem,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import cn from "classnames";
import { uid } from "react-uid";
import { useHistory } from "react-router-dom";
import Links from "./links";
import { ModalToggle } from "pages/utils/ModalToggle";
import { connect, useSelector, useDispatch } from "react-redux";
import { handleSideBar } from "../store/SideMenu/actions";
import { useParams } from "react-router-dom";

const Sidebar = ({ classes, theme, open, setOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery("(max-width: 896px)");
  const collapsedLinks = useSelector((state) => state.SideBarReducer);
  const links = Links();

  const [menuRef, setMenuRef] = useState({
    manageAssessments: false,
    manageCustomer: false,
    master: false,
    report: false,
  });

  const handleDrawerClose = () => setOpen(ModalToggle);

  const handleRouteChange = (item) => {
    history.push(item.to);
    if (item.label === "Practice Packages" || item.label === "Dashboard") {
      dispatch(
        handleSideBar({
          manageAssessments: false,
          manageCustomer: false,
          master: false,
          report: false,
          utility: false,
        })
      );
    }
  };

  const handleClick = (item, event) => {
    item.isExpandable
      ? open
        ? dispatch(
            handleSideBar({
              [item.id]: !collapsedLinks[item.id],
            })
          )
        : setMenuRef({ ...menuRef, [item.id]: event.currentTarget })
      : handleRouteChange(item);
  };

  const pathOnly = window.location.pathname.split("/");
  return (
    <Drawer
      onClose={handleDrawerClose}
      open={open}
      variant={isSmallScreen ? "temporary" : "permanent"}
      className={
        !isSmallScreen &&
        cn(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })
      }
      classes={{
        ...(!isSmallScreen
          ? {
              paper: cn({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }
          : {
              paper: classes.smallDrawer,
            }),
      }}
    >
      <IconButton
        classes={{ root: classes.menuIcon }}
        size="small"
        centerRipple
        onClick={handleDrawerClose}
      >
        <MenuIcon />
      </IconButton>
      <List classes={{ root: classes.listMenu }}>
        {links.map((link, ind) => (
          <>
            {link.shouldAppear && (
              <ListItem
                button
                key={uid(link.label)}
                onClick={(event) => handleClick(link, event)}
                disableRipple
                classes={{
                  root: cn(classes.listItem, {
                    [classes.listItemActive]: link.isActive.includes(
                      `/${pathOnly[1]}`
                    ),
                  }),
                }}
              >
                <ListItemIcon
                  classes={{
                    root: cn(classes.listItemIcon, {
                      [classes.listIconMR]: theme.direction === "ltr",
                    }),
                  }}
                >
                  {link.icon}
                </ListItemIcon>
                <ListItemText
                  primary={link.label}
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
            )}
            {link.isExpandable ? (
              open ? (
                <Collapse
                  in={collapsedLinks[link.id]}
                  timeout="auto"
                  unmountOnExit
                  key={uid(`collapse-${link.label}`)}
                >
                  <List
                    component="div"
                    disablePadding
                    classes={{
                      root: cn(classes.nestedList, {
                        [classes.listActive]: link.isActive.includes(
                          `/${pathOnly[1]}`
                        ),
                      }),
                    }}
                    key={uid(ind)}
                  >
                    {link.nested.map((nestedLink, idx) => (
                      <>
                        {nestedLink.shouldAppear && (
                          <ListItem
                            button
                            classes={{
                              root: cn(classes.nestedListItem, {
                                [classes.nestedListItemActive]:
                                  nestedLink.isActive.includes(
                                    `/${pathOnly[1]}`
                                  ),
                              }),
                            }}
                            onClick={handleRouteChange.bind(this, nestedLink)}
                            disableRipple
                            key={uid(idx)}
                          >
                            <ListItemText
                              primary={nestedLink.label}
                              classes={{
                                primary: classes.listItemText,
                                root: cn({
                                  [classes.nestedListItemTextActive]:
                                    nestedLink.isActive.includes(
                                      `/${pathOnly[1]}`
                                    ),
                                }),
                              }}
                            />
                          </ListItem>
                        )}
                      </>
                    ))}
                  </List>
                </Collapse>
              ) : (
                <Menu
                  id={`collapsed-menu-${link.label}`}
                  key={uid(`collapsed-menu-${link.label}`)}
                  anchorEl={menuRef[link.id]}
                  keepMounted
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(menuRef[link.id])}
                  onClose={() => setMenuRef({ ...menuRef, [link.id]: null })}
                  classes={{ paper: classes.collapsedMenu }}
                >
                  {link.nested.map((nestedLink, idx) => (
                    <MenuItem
                      onClick={handleRouteChange.bind(this, nestedLink)}
                      key={uid(idx)}
                      classes={{ root: classes.collapsedMenuItem }}
                      style={{
                        display: nestedLink.shouldAppear ? "block" : "none",
                      }}
                    >
                      {nestedLink.label}
                    </MenuItem>
                  ))}
                </Menu>
              )
            ) : (
              ""
            )}
          </>
        ))}
      </List>
    </Drawer>
  );
};

export default connect(null, null)(Sidebar);
