import React, { useState, useEffect, forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Rating from "@material-ui/lab/Rating";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import * as CustomIcons from "../../assets/icons/icons";
import { PriceContainerPrepare } from "pages/LoginPage/New/PriceContainerPrepare";
import styles from "./Prepare.module.scss";
import "./prepares.css";
import { useMediaQuery } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { Tabs } from "../../components/tabs/tabsStudent";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { NoFound } from "assets/icons/icons";

const useStyles = makeStyles({
  root: {
    width: 320,
    boxShadow: "0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08)",
    borderRadius: 5,
    position: "relative",
    height: 390,
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
      transform: "scale(1.03)",
      boxShadow: "0 12px 24px rgba(0, 0, 0, 0.16), 0 6px 6px rgba(0, 0, 0, 0.08)",
        },
  },
  mobileRoot: {
    width: 325,
    boxShadow:
      "0px 0px 25px rgba(170, 170, 170, 0.25), 0 -1px 4px -1px rgba(0, 0, 0, 0.24)",
    borderRadius: 5,
    position: "relative",
    height: 450,
  },
  media: {
    height: "150px",
  },
  progressRoot: {
    height: 7,
    borderRadius: 50,
  },
  colorPrimary: {
    backgroundColor: "#DFDFDF",
  },
  barColorPrimary: {
    backgroundColor: "#F56F18;",
  },
  buttonWidth: {
    width: "100%",
  },
  hours: {
    color: "#4A4851",
    marginBottom: "5px",
  },
  chapter: {
    marginRight: "12px",
  },
  intermediate: {
    marginRight: "10px",
  },
  completedChapter: {
    fontWeight: 500,
    fontSize: 11,
    color: "#7C7887",
    textAlign: "center",
    display: "block",
  },
  dividers: {
    margin: "10px 0",
  },
  percentText: {
    fontWeight: 500,
    fontSize: 15,
    color: "#19181B",
    margin: 0,
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
const TabScrollButton = forwardRef((props, ref) => {
  const { direction, ...other } = props;

  return (
    <ButtonBase
      component="div"
      ref={ref}
      style={{ opacity: other.disabled ? 0 : 1 }}
      {...other}
    >
      {direction === "left" ? (
        <ArrowBackIosIcon
          fontSize="small"
          className="arrow-icons"
          style={{ fill: "black" }}
        />
      ) : (
        <ArrowForwardIosIcon
          fontSize="small"
          className="arrow-icons"
          style={{ fill: "black" }}
        />
      )}
    </ButtonBase>
  );
});

export default function CourseCard({
  ContinuePackages,
  exploreCourse,
  courseSearch,
  activeTab,
  customSearchValue,
  allData,
  filterUpdate,
  loadingCourse,
}) {
  const [searchValue, setSearchValue] = useState([]);
  const [value, setValue] = useState(2);
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const handlePageRedirect = (data) => {
    if (
      (data.status === "Inprogress" || data.status === "Completed") &&
      !data?.externalObjectName
    )
      history.push(`/course-overview/${data.id}`);
    else if (data.status === "NotStarted" || data?.externalObjectName)
      history.push(`/course-overview/${data.id}`);
  };

  useEffect(() => {
    setTimeout(() => {
      if (courseSearch)
        setSearchValue(
          ContinuePackages.reduce((acc, val) => {
            if (val.name.toLowerCase().includes(courseSearch)) acc.push(val);
            return acc;
          }, [])
        );
    }, [500]);
  }, [courseSearch]);

  const checkPrepareFile =
    (courseSearch ? searchValue : ContinuePackages).length == 0;
  return (
    <>
      <div className="mx-auto bg-white pb-4">
        {checkPrepareFile ? (
          <div className="centerAlign pt-5">
            <Card style={{ border: "none", boxShadow: "none" }}>
              <CardContent style={{ flex: "1 1 0%" }}>
                <div className="centerAlign">{NoFound}</div>
                <p className="cardHeader">No Continue courses found</p>
              </CardContent>
            </Card>
          </div>
        ) : activeTab == "ContinueLearning" && !checkPrepareFile ? (
          <TabPanel className={classes.grid}>
            {!exploreCourse ? (
              // <Grid container>
              <div className="cardGrid_Course">
                {(customSearchValue ? allData : ContinuePackages).length > 0 &&
                  (customSearchValue ? allData : ContinuePackages)
                    .filter(
                      (e) =>
                        e.status !== "NotStarted" && e.status !== "Completed"
                    )
                    .slice(0, 3)
                    .map((data, index) => {
                      return (
                        <div key={data.id}>
                          <Card
                            className={
                              smallScreen ? classes.mobileRoot : classes.root
                            }
                          >
                            <CardMedia
                              className={classes.media}
                              // image={data.media}
                              image={
                                data?.media
                                  ? data.media.includes("cloudfront")
                                    ? data.media
                                    : data.media.includes("digitaloceanspaces")
                                    ? data.media
                                    : `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${data.media}.jpeg`
                                  : `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/default-${
                                      GetRandom(9) + 1
                                    }.jpeg`
                              }
                              title={data.name}
                            />

                            {/* <CardMedia
                              className={classes.media}
                              image={data.media}
                              title={data.name}
                            /> */}
                            <div className={styles.courseTagName}>
                              {data.courseTags === "premium"
                                ? CustomIcons.premiumIcon
                                : data.courseTags === "special"
                                ? CustomIcons.specialIcon
                                : data.courseTags === "latest"
                                ? CustomIcons.latestIcon
                                : data.courseTags === "feature"
                                ? CustomIcons.feturedIcon
                                : ""}
                            </div>
                            <CardContent style={{ flex: "1 1 0%" }}>
                              <h2 className="course-name" style={{paddingBottom:"2%"}}>{data.name}</h2>
                              <Grid container spacing={0}>
                                <Grid item xs={6}>
                                  <div className={classes.hours}>
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="hours-svg"
                                    >
                                      <g clipPath="url(#clip0)">
                                        <path
                                          d="M8.00016 2.33331C4.32416 2.33331 1.3335 5.32398 1.3335 8.99998C1.3335 12.676 4.32416 15.6666 8.00016 15.6666C11.6762 15.6666 14.6668 12.676 14.6668 8.99998C14.6668 5.32398 11.6762 2.33331 8.00016 2.33331ZM8.00016 14.3333C5.0595 14.3333 2.66683 11.9406 2.66683 8.99998C2.66683 6.05931 5.0595 3.66665 8.00016 3.66665C10.9408 3.66665 13.3335 6.05931 13.3335 8.99998C13.3335 11.9406 10.9408 14.3333 8.00016 14.3333Z"
                                          fill="#7C7887"
                                        />
                                        <path
                                          d="M8.66683 5.66669H7.3335V9.66669H11.3335V8.33335H8.66683V5.66669Z"
                                          fill="#7C7887"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0">
                                          <rect
                                            width="16"
                                            height="16"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>

                                    {data.duration > 1 ? (
                                  <span className="duration">
                                    {" "}
                                    {data.duration} hours
                                  </span>
                                  ):(
                                    <span className="duration">
                                    {" "}
                                    {data.duration} hour
                                  </span>
                                  )}
                                  </div>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <div className={classes.chapter}>
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="hours-svg"
                                    >
                                      <path
                                        d="M1.3335 4.00002C1.3335 4.00002 2.3335 2.66669 4.66683 2.66669C7.00016 2.66669 8.00016 4.00002 8.00016 4.00002V13.3334C8.00016 13.3334 7.00016 12.6667 4.66683 12.6667C2.3335 12.6667 1.3335 13.3334 1.3335 13.3334V4.00002Z"
                                        stroke="#7C7887"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M8 4.00002C8 4.00002 9 2.66669 11.3333 2.66669C13.6667 2.66669 14.6667 4.00002 14.6667 4.00002V13.3334C14.6667 13.3334 13.6667 12.6667 11.3333 12.6667C9 12.6667 8 13.3334 8 13.3334V4.00002Z"
                                        stroke="#7C7887"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    {data.numberOfModules > 1 ? (
                                    <span className="duration">
                                      {" "}
                                      {data.numberOfModules} Chapters
                                    </span>
                                    ):(
                                    <span className="duration">
                                      {" "}
                                      {data.numberOfModules} Chapter
                                    </span>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container spacing={0}>
                                <Grid item xs={6} sm={6}>
                                  <Rating
                                    name="size-small"
                                    defaultValue={
                                      data.courseRating ? data.courseRating : 0
                                    }
                                    size="small"
                                    readOnly
                                  >
                                    {4}
                                  </Rating>
                                  <span className="text-muted continue-rating">
                                    {" "}
                                    {data.courseRating ? data.courseRating : ""}
                                  </span>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <div>
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.6665 4H9.99984V11.3333H8.6665V4ZM11.3332 2H12.6665V11.3333H11.3332V2ZM5.99984 6H7.33317V11.3333H5.99984V6ZM2.6665 12.6667H13.3332V14H2.6665V12.6667ZM3.33317 8H4.6665V11.3333H3.33317V8Z"
                                        fill="#7C7887"
                                      />
                                    </svg>
                                    <span
                                      className="duration"
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {" "}
                                      {data.level}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>

                              <Divider className={classes.dividers} />
                              <>
                                {data.status === "Inprogress" ||
                                data.status == "Completed" ? (
                                  <>
                                    <Box display="flex" alignItems="center">
                                      <Box width="100%" mr={1}>
                                        <LinearProgress
                                          value={data.progress}
                                          variant="determinate"
                                          classes={{
                                            root: classes.progressRoot,
                                            colorPrimary: classes.colorPrimary,
                                            barColorPrimary:
                                              classes.barColorPrimary,
                                          }}
                                        />
                                      </Box>
                                      <Box minWidth={35}>
                                        <h2 className={classes.percentText}>
                                          {`${Math.round(data.progress)}%`}
                                        </h2>
                                      </Box>
                                    </Box>
                                    <span className={classes.completedChapter}>
                                      {`${data.modulesCompleted}/${data.numberOfModules}`}{" "}
                                      {data.modulesCompleted <= 1 ? "chapter completed" : "chapters completed"}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {data.status !== "Completed" && (
                                      <PriceContainerPrepare
                                        paidPackage={data.paidPackage}
                                        originalPrice={data.originalPrice}
                                        strikingPrice={data.strikingPrice}
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            </CardContent>
                            <CardActions>
                              {!data.rewatch ? (
                                data.progress == 100 ? (
                                  <Button
                                    variant="outlined"
                                    className="rewatch-disable"
                                    onClick={() => handlePageRedirect(data)}
                                    disabled
                                  >
                                    {data.status === "Inprogress"
                                      ? t("Continue learning")
                                      : data.status === "NotStarted"
                                      ? t("Preview")
                                      : t("Completed")}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    className={
                                      data.status === "Inprogress"
                                        ? "continue-button"
                                        : data.status === "NotStarted"
                                        ? "preview-button"
                                        : "continue-button"
                                    }
                                    onClick={() => handlePageRedirect(data)}
                                  >
                                    {data.status === "Inprogress"
                                      ? t("Continue learning")
                                      : data.status === "NotStarted"
                                      ? t("Preview")
                                      : t("Completed")}
                                  </Button>
                                )
                              ) : (
                                <Button
                                  variant="outlined"
                                  className={
                                    data.status === "Inprogress"
                                      ? "continue-button"
                                      : data.status === "NotStarted"
                                      ? "preview-button"
                                      : "continue-button"
                                  }
                                  onClick={() => handlePageRedirect(data)}
                                >
                                  {data.status === "Inprogress"
                                    ? t("Continue learning")
                                    : data.status === "NotStarted"
                                    ? t("Preview")
                                    : t("Completed")}
                                </Button>
                              )}
                            </CardActions>
                          </Card>
                        </div>
                      );
                      // return (
                      //   <>
                      //     <Grid item xs={12} md={6} lg={4} key={data.id}>
                      //     </Grid>
                      //   </>
                      // );
                    })}
              </div>
            ) : (
              <div className="cardGrid_Course">
                {(customSearchValue ? allData : ContinuePackages).length > 0 &&
                  (customSearchValue ? allData : ContinuePackages)
                    .filter((e) => e.status !== "Completed")
                    .map((data, index) => {
                      return (
                        <div key={data.id}>
                          <Card
                            className={
                              smallScreen ? classes.mobileRoot : classes.root
                            }
                          >
                            <CardMedia
                              className={classes.media}
                              image={
                                data?.media
                                  ? data.media.includes("cloudfront")
                                    ? data.media
                                    : data.media.includes("digitaloceanspaces")
                                    ? data.media
                                    : `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${data.media}.jpeg`
                                  : `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/default-${
                                      GetRandom(9) + 1
                                    }.jpeg`
                              }
                              title={data.name}
                            />
                            <div className={styles.courseTagName}>
                              {data.courseTags === "premium"
                                ? CustomIcons.premiumIcon
                                : data.courseTags === "special"
                                ? CustomIcons.specialIcon
                                : data.courseTags === "latest"
                                ? CustomIcons.latestIcon
                                : data.courseTags === "feature"
                                ? CustomIcons.feturedIcon
                                : ""}
                            </div>
                            <CardContent style={{ flex: "1 1 0%" }}>
                              <h2 className="course-name" style={{paddingBottom:"2%"}}>{data.name}</h2>
                              <Grid container spacing={0}>
                                <Grid item xs={6}>
                                  <div className={classes.hours}>
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="hours-svg"
                                    >
                                      <g clipPath="url(#clip0)">
                                        <path
                                          d="M8.00016 2.33331C4.32416 2.33331 1.3335 5.32398 1.3335 8.99998C1.3335 12.676 4.32416 15.6666 8.00016 15.6666C11.6762 15.6666 14.6668 12.676 14.6668 8.99998C14.6668 5.32398 11.6762 2.33331 8.00016 2.33331ZM8.00016 14.3333C5.0595 14.3333 2.66683 11.9406 2.66683 8.99998C2.66683 6.05931 5.0595 3.66665 8.00016 3.66665C10.9408 3.66665 13.3335 6.05931 13.3335 8.99998C13.3335 11.9406 10.9408 14.3333 8.00016 14.3333Z"
                                          fill="#7C7887"
                                        />
                                        <path
                                          d="M8.66683 5.66669H7.3335V9.66669H11.3335V8.33335H8.66683V5.66669Z"
                                          fill="#7C7887"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0">
                                          <rect
                                            width="16"
                                            height="16"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>

                                    {data.duration > 1 ? (
                                  <span className="duration">
                                    {" "}
                                    {data.duration} hours
                                  </span>
                                  ):(
                                    <span className="duration">
                                    {" "}
                                    {data.duration} hour
                                  </span>
                                  )}
                                  </div>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <div className={classes.chapter}>
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="hours-svg"
                                    >
                                      <path
                                        d="M1.3335 4.00002C1.3335 4.00002 2.3335 2.66669 4.66683 2.66669C7.00016 2.66669 8.00016 4.00002 8.00016 4.00002V13.3334C8.00016 13.3334 7.00016 12.6667 4.66683 12.6667C2.3335 12.6667 1.3335 13.3334 1.3335 13.3334V4.00002Z"
                                        stroke="#7C7887"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M8 4.00002C8 4.00002 9 2.66669 11.3333 2.66669C13.6667 2.66669 14.6667 4.00002 14.6667 4.00002V13.3334C14.6667 13.3334 13.6667 12.6667 11.3333 12.6667C9 12.6667 8 13.3334 8 13.3334V4.00002Z"
                                        stroke="#7C7887"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    {data.numberOfModules > 1 ? (
                                    <span className="duration">
                                      {" "}
                                      {data.numberOfModules} Chapters
                                    </span>
                                    ):(
                                    <span className="duration">
                                      {" "}
                                      {data.numberOfModules} Chapter
                                    </span>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container spacing={0}>
                                <Grid item xs={6} sm={6}>
                                  <Rating
                                    name="size-small"
                                    defaultValue={
                                      data.courseRating ? data.courseRating : 0
                                    }
                                    size="small"
                                    readOnly
                                  >
                                    {4}
                                  </Rating>
                                  <span className="text-muted continue-rating">
                                    {" "}
                                    {data.courseRating ? data.courseRating : ""}
                                  </span>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <div>
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.6665 4H9.99984V11.3333H8.6665V4ZM11.3332 2H12.6665V11.3333H11.3332V2ZM5.99984 6H7.33317V11.3333H5.99984V6ZM2.6665 12.6667H13.3332V14H2.6665V12.6667ZM3.33317 8H4.6665V11.3333H3.33317V8Z"
                                        fill="#7C7887"
                                      />
                                    </svg>
                                    <span className="duration">
                                      {" "}
                                      {data.level}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>

                              <Divider className={classes.dividers} />
                              <>
                                {data.status === "Inprogress" ||
                                data.status === "Completed" ? (
                                  <>
                                    <Box display="flex" alignItems="center">
                                      <Box width="100%" mr={1}>
                                        <LinearProgress
                                          value={data.progress}
                                          variant="determinate"
                                          classes={{
                                            root: classes.progressRoot,
                                            colorPrimary: classes.colorPrimary,
                                            barColorPrimary:
                                              classes.barColorPrimary,
                                          }}
                                        />
                                      </Box>
                                      <Box minWidth={35}>
                                        <h2 className={classes.percentText}>
                                          {`${Math.round(data.progress)}%`}
                                        </h2>
                                      </Box>
                                    </Box>
                                    <span className={classes.completedChapter}>
                                      {`${data.modulesCompleted}/${data.numberOfModules}`}{" "}
                                      chapters completed
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {data.purchased == false && (
                                      <PriceContainerPrepare
                                        paidPackage={data.paidPackage}
                                        originalPrice={data.originalPrice}
                                        strikingPrice={data.strikingPrice}
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            </CardContent>
                            <CardActions>
                              {!data.rewatch ? (
                                data.progress == 100 ? (
                                  <Button
                                    variant="outlined"
                                    className="rewatch-disable"
                                    onClick={() => handlePageRedirect(data)}
                                    disabled
                                  >
                                    {data.status === "Inprogress"
                                      ? t("Continue learning")
                                      : data.status === "NotStarted"
                                      ? t("Preview")
                                      : t("Completed")}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    className={
                                      data.status === "Inprogress"
                                        ? "continue-button"
                                        : data.status === "NotStarted"
                                        ? "preview-button"
                                        : "continue-button"
                                    }
                                    onClick={() => handlePageRedirect(data)}
                                  >
                                    {data.status === "Inprogress"
                                      ? t("Continue learning")
                                      : data.status === "NotStarted"
                                      ? t("Preview")
                                      : t("Completed")}
                                  </Button>
                                )
                              ) : (
                                <Button
                                  variant="outlined"
                                  className={
                                    data.status === "Inprogress"
                                      ? "continue-button"
                                      : data.status === "NotStarted"
                                      ? "preview-button"
                                      : "continue-button"
                                  }
                                  onClick={() => handlePageRedirect(data)}
                                >
                                  {data.status === "Inprogress"
                                    ? t("Continue learning")
                                    : data.status === "NotStarted"
                                    ? t("Preview")
                                    : t("Completed")}
                                </Button>
                              )}
                            </CardActions>
                          </Card>
                        </div>
                      );
                    })}
              </div>
            )}
            {loadingCourse ? (
              <Card style={{ border: "none", boxShadow: "none" }}>
                <CardContent style={{ flex: "1 1 0%" }}>
                  <p className="cardHeader">Course Loading...</p>
                </CardContent>
              </Card>
            ) : (
              !exploreCourse &&
              (courseSearch ? searchValue : ContinuePackages).filter(
                (e) => e.status !== "NotStarted" && e.status !== "Completed"
              ).length == 0 && (
                <div className="centerAlign pt-5 pb-5">
                  <Card style={{ border: "none", boxShadow: "none" }}>
                    <CardContent style={{ flex: "1 1 0%" }}>
                      <div className="centerAlign">{NoFound}</div>
                      <p className="cardHeader">No Continue courses found</p>
                    </CardContent>
                  </Card>
                </div>
              )
            )}
            {exploreCourse &&
              (courseSearch ? searchValue : ContinuePackages).filter(
                (e) => e.status !== "Completed"
              ).length == 0 && (
                <div className="centerAlign pt-5 pb-5">
                  <Card style={{ border: "none", boxShadow: "none" }}>
                    <CardContent style={{ flex: "1 1 0%" }}>
                      <div className="centerAlign">{NoFound}</div>
                      <p className="cardHeader">No courses found</p>
                    </CardContent>
                  </Card>
                </div>
              )}
          </TabPanel>
        ) : (
          <TabPanel className={classes.grid}>
            {!exploreCourse ? (
              <div className="cardGrid_Course">
                {(customSearchValue ? allData : ContinuePackages).length > 0 &&
                  (customSearchValue ? allData : ContinuePackages)
                    .filter(
                      (e) =>
                        e.status !== "NotStarted" && e.status == "Completed"
                    )
                    .slice(0, 3)
                    .map((data, index) => {
                      return (
                        <div key={data.id}>
                          <Card
                            className={
                              smallScreen ? classes.mobileRoot : classes.root
                            }
                          >
                            <CardMedia
                              className={classes.media}
                              // image={data.media}
                              image={
                                data?.media
                                  ? data.media.includes("cloudfront")
                                    ? data?.media
                                    : `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${data.media}.jpeg`
                                  : `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/default-${
                                      GetRandom(9) + 1
                                    }.jpeg`
                              }
                              title={data.name}
                            />
                            <div className={styles.courseTagName}>
                              {data.courseTags === "Premium"
                                ? CustomIcons.premiumIcon
                                : data.courseTags === "Special"
                                ? CustomIcons.specialIcon
                                : data.courseTags === "Latest"
                                ? CustomIcons.latestIcon
                                : data.courseTags === "Feature"
                                ? CustomIcons.feturedIcon
                                : ""}
                            </div>
                            <CardContent style={{ flex: "1 1 0%" }}>
                              <h2 className="course-name" style={{paddingBottom:"2%"}}>{data.name}</h2>
                              <Grid container spacing={0}>
                                <Grid item xs={6}>
                                  <div className={classes.hours}>
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="hours-svg"
                                    >
                                      <g clipPath="url(#clip0)">
                                        <path
                                          d="M8.00016 2.33331C4.32416 2.33331 1.3335 5.32398 1.3335 8.99998C1.3335 12.676 4.32416 15.6666 8.00016 15.6666C11.6762 15.6666 14.6668 12.676 14.6668 8.99998C14.6668 5.32398 11.6762 2.33331 8.00016 2.33331ZM8.00016 14.3333C5.0595 14.3333 2.66683 11.9406 2.66683 8.99998C2.66683 6.05931 5.0595 3.66665 8.00016 3.66665C10.9408 3.66665 13.3335 6.05931 13.3335 8.99998C13.3335 11.9406 10.9408 14.3333 8.00016 14.3333Z"
                                          fill="#7C7887"
                                        />
                                        <path
                                          d="M8.66683 5.66669H7.3335V9.66669H11.3335V8.33335H8.66683V5.66669Z"
                                          fill="#7C7887"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0">
                                          <rect
                                            width="16"
                                            height="16"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>

                                    {data.duration > 1 ? (
                                  <span className="duration">
                                    {" "}
                                    {data.duration} hours
                                  </span>
                                  ):(
                                    <span className="duration">
                                    {" "}
                                    {data.duration} hour
                                  </span>
                                  )}
                                  </div>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <div className={classes.chapter}>
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="hours-svg"
                                    >
                                      <path
                                        d="M1.3335 4.00002C1.3335 4.00002 2.3335 2.66669 4.66683 2.66669C7.00016 2.66669 8.00016 4.00002 8.00016 4.00002V13.3334C8.00016 13.3334 7.00016 12.6667 4.66683 12.6667C2.3335 12.6667 1.3335 13.3334 1.3335 13.3334V4.00002Z"
                                        stroke="#7C7887"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M8 4.00002C8 4.00002 9 2.66669 11.3333 2.66669C13.6667 2.66669 14.6667 4.00002 14.6667 4.00002V13.3334C14.6667 13.3334 13.6667 12.6667 11.3333 12.6667C9 12.6667 8 13.3334 8 13.3334V4.00002Z"
                                        stroke="#7C7887"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    {data.numberOfModules > 1 ? (
                                    <span className="duration">
                                      {" "}
                                      {data.numberOfModules} Chapters
                                    </span>
                                    ):(
                                    <span className="duration">
                                      {" "}
                                      {data.numberOfModules} Chapter
                                    </span>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container spacing={0}>
                                <Grid item xs={6} sm={6}>
                                  <Rating
                                    name="size-small"
                                    value={data.courseRating}
                                    size="small"
                                    readOnly
                                  />
                                  <span className="text-muted continue-rating">
                                    {data.courseRating ? data.courseRating : ""}
                                  </span>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <div>
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.6665 4H9.99984V11.3333H8.6665V4ZM11.3332 2H12.6665V11.3333H11.3332V2ZM5.99984 6H7.33317V11.3333H5.99984V6ZM2.6665 12.6667H13.3332V14H2.6665V12.6667ZM3.33317 8H4.6665V11.3333H3.33317V8Z"
                                        fill="#7C7887"
                                      />
                                    </svg>
                                    <span className="duration">
                                      {" "}
                                      {data.level}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>

                              <Divider className={classes.dividers} />
                              <>
                                {data.status === "Inprogress" ||
                                data.status == "Completed" ? (
                                  <>
                                    <Box display="flex" alignItems="center">
                                      <Box width="100%" mr={1}>
                                        <LinearProgress
                                          value={data.progress}
                                          variant="determinate"
                                          classes={{
                                            root: classes.progressRoot,
                                            colorPrimary: classes.colorPrimary,
                                            barColorPrimary:
                                              classes.barColorPrimary,
                                          }}
                                        />
                                      </Box>
                                      <Box minWidth={35}>
                                        <h2 className={classes.percentText}>
                                          {`${Math.round(data.progress)}%`}
                                        </h2>
                                      </Box>
                                    </Box>
                                    <span className={classes.completedChapter}>
                                      {`${data.modulesCompleted}/${data.numberOfModules}`}{" "}
                                      {data.modulesCompleted <= 1 ? "chapter completed" : "chapters completed"}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {data.status !== "Completed" && (
                                      <PriceContainerPrepare
                                        paidPackage={data.paidPackage}
                                        originalPrice={data.originalPrice}
                                        strikingPrice={data.strikingPrice}
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            </CardContent>
                            <CardActions>
                              {!data.rewatch ? (
                                data.progress == 100 ? (
                                  <Button
                                    variant="outlined"
                                    className="rewatch-disable"
                                    onClick={() => handlePageRedirect(data)}
                                    disabled
                                  >
                                    {data.status === "Inprogress"
                                      ? t("Continue learning")
                                      : data.status === "NotStarted"
                                      ? t("Preview")
                                      : t("Completed")}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    className={
                                      data.status === "Inprogress"
                                        ? "continue-button"
                                        : data.status === "NotStarted"
                                        ? "preview-button"
                                        : "continue-button"
                                    }
                                    onClick={() => handlePageRedirect(data)}
                                  >
                                    {data.status === "Inprogress"
                                      ? t("Continue learning")
                                      : data.status === "NotStarted"
                                      ? t("Preview")
                                      : t("Completed")}
                                  </Button>
                                )
                              ) : (
                                <Button
                                  variant="outlined"
                                  className={
                                    data.status === "Inprogress"
                                      ? "continue-button"
                                      : data.status === "NotStarted"
                                      ? "preview-button"
                                      : "continue-button"
                                  }
                                  onClick={() => handlePageRedirect(data)}
                                >
                                  {data.status === "Inprogress"
                                    ? t("Continue learning")
                                    : data.status === "NotStarted"
                                    ? t("Preview")
                                    : t("Completed")}
                                </Button>
                              )}
                            </CardActions>
                          </Card>
                        </div>
                      );
                    })}
              </div>
            ) : (
              <div className="cardGrid_Course">
                {ContinuePackages.length > 0 &&
                  ContinuePackages.filter(
                    (e) => e.status !== "NotStarted" && e.status == "Completed"
                  ).map((data, index) => {
                    return (
                      <div key={data.id}>
                        <Card
                          className={
                            smallScreen ? classes.mobileRoot : classes.root
                          }
                        >
                          <CardMedia
                            className={classes.media}
                            image={data.media}
                            title={data.name}
                          />
                          <div className={styles.courseTagName}>
                            {data.courseTags === "Premium"
                              ? CustomIcons.premiumIcon
                              : data.courseTags === "Special"
                              ? CustomIcons.specialIcon
                              : data.courseTags === "Latest"
                              ? CustomIcons.latestIcon
                              : data.courseTags === "Feature"
                              ? CustomIcons.feturedIcon
                              : ""}
                          </div>
                          <CardContent style={{ flex: "1 1 0%" }}>
                            <h2 className="course-name" style={{paddingBottom:"2%"}}>{data.name}</h2>
                            <Grid container spacing={0}>
                              <Grid item xs={6}>
                                <div className={classes.hours}>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="hours-svg"
                                  >
                                    <g clipPath="url(#clip0)">
                                      <path
                                        d="M8.00016 2.33331C4.32416 2.33331 1.3335 5.32398 1.3335 8.99998C1.3335 12.676 4.32416 15.6666 8.00016 15.6666C11.6762 15.6666 14.6668 12.676 14.6668 8.99998C14.6668 5.32398 11.6762 2.33331 8.00016 2.33331ZM8.00016 14.3333C5.0595 14.3333 2.66683 11.9406 2.66683 8.99998C2.66683 6.05931 5.0595 3.66665 8.00016 3.66665C10.9408 3.66665 13.3335 6.05931 13.3335 8.99998C13.3335 11.9406 10.9408 14.3333 8.00016 14.3333Z"
                                        fill="#7C7887"
                                      />
                                      <path
                                        d="M8.66683 5.66669H7.3335V9.66669H11.3335V8.33335H8.66683V5.66669Z"
                                        fill="#7C7887"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0">
                                        <rect
                                          width="16"
                                          height="16"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  {data.duration > 1 ? (
                                  <span className="duration">
                                    {" "}
                                    {data.duration} hours
                                  </span>
                                  ):(
                                    <span className="duration">
                                    {" "}
                                    {data.duration} hour
                                  </span>
                                  )}
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6}>
                                <div className={classes.chapter}>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="hours-svg"
                                  >
                                    <path
                                      d="M1.3335 4.00002C1.3335 4.00002 2.3335 2.66669 4.66683 2.66669C7.00016 2.66669 8.00016 4.00002 8.00016 4.00002V13.3334C8.00016 13.3334 7.00016 12.6667 4.66683 12.6667C2.3335 12.6667 1.3335 13.3334 1.3335 13.3334V4.00002Z"
                                      stroke="#7C7887"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M8 4.00002C8 4.00002 9 2.66669 11.3333 2.66669C13.6667 2.66669 14.6667 4.00002 14.6667 4.00002V13.3334C14.6667 13.3334 13.6667 12.6667 11.3333 12.6667C9 12.6667 8 13.3334 8 13.3334V4.00002Z"
                                      stroke="#7C7887"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  {data.numberOfModules > 1 ? (
                                    <span className="duration">
                                      {" "}
                                      {data.numberOfModules} Chapters
                                    </span>
                                    ):(
                                    <span className="duration">
                                      {" "}
                                      {data.numberOfModules} Chapter
                                    </span>
                                    )}
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={0}>
                              <Grid item xs={6} sm={6}>
                                <Rating
                                  name="size-small"
                                  defaultValue={
                                    data.courseRating ? data.courseRating : 0
                                  }
                                  size="small"
                                  readOnly
                                ></Rating>
                                <span className="text-muted continue-rating">
                                  {" "}
                                  {data.courseRating ? data.courseRating : ""}
                                </span>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.6665 4H9.99984V11.3333H8.6665V4ZM11.3332 2H12.6665V11.3333H11.3332V2ZM5.99984 6H7.33317V11.3333H5.99984V6ZM2.6665 12.6667H13.3332V14H2.6665V12.6667ZM3.33317 8H4.6665V11.3333H3.33317V8Z"
                                      fill="#7C7887"
                                    />
                                  </svg>
                                  <span className="duration">
                                    {" "}
                                    {data.level}
                                  </span>
                                </div>
                              </Grid>
                            </Grid>

                            <Divider className={classes.dividers} />
                            <>
                              {data.status === "Inprogress" ||
                              data.status === "Completed" ? (
                                <>
                                  <Box display="flex" alignItems="center">
                                    <Box width="100%" mr={1}>
                                      <LinearProgress
                                        value={data.progress}
                                        variant="determinate"
                                        classes={{
                                          root: classes.progressRoot,
                                          colorPrimary: classes.colorPrimary,
                                          barColorPrimary:
                                            classes.barColorPrimary,
                                        }}
                                      />
                                    </Box>
                                    <Box minWidth={35}>
                                      <h2 className={classes.percentText}>
                                        {`${Math.round(data.progress)}%`}
                                      </h2>
                                    </Box>
                                  </Box>
                                  <span className={classes.completedChapter}>
                                    {`${data.modulesCompleted}/${data.numberOfModules}`}{" "}
                                    chapters completed
                                  </span>
                                </>
                              ) : (
                                <>
                                  {data.purchased == false && (
                                    <PriceContainerPrepare
                                      paidPackage={data.paidPackage}
                                      originalPrice={data.originalPrice}
                                      strikingPrice={data.strikingPrice}
                                    />
                                  )}
                                </>
                              )}
                            </>
                          </CardContent>
                          <CardActions>
                            {!data.rewatch ? (
                              data.progress == 100 ? (
                                <Button
                                  variant="outlined"
                                  className="rewatch-disable"
                                  onClick={() => handlePageRedirect(data)}
                                  disabled
                                >
                                  {data.status === "Inprogress"
                                    ? t("Continue learning")
                                    : data.status === "NotStarted"
                                    ? t("Preview")
                                    : t("Completed")}
                                </Button>
                              ) : (
                                <Button
                                  variant="outlined"
                                  className={
                                    data.status === "Inprogress"
                                      ? "continue-button"
                                      : data.status === "NotStarted"
                                      ? "preview-button"
                                      : "continue-button"
                                  }
                                  onClick={() => handlePageRedirect(data)}
                                >
                                  {data.status === "Inprogress"
                                    ? t("Continue learning")
                                    : data.status === "NotStarted"
                                    ? t("Preview")
                                    : t("Completed")}
                                </Button>
                              )
                            ) : (
                              <Button
                                variant="outlined"
                                className={
                                  data.status === "Inprogress"
                                    ? "continue-button"
                                    : data.status === "NotStarted"
                                    ? "preview-button"
                                    : "continue-button"
                                }
                                onClick={() => handlePageRedirect(data)}
                              >
                                {data.status === "Inprogress"
                                  ? t("Continue learning")
                                  : data.status === "NotStarted"
                                  ? t("Preview")
                                  : t("Completed")}
                              </Button>
                            )}
                          </CardActions>
                        </Card>
                      </div>
                    );
                  })}
              </div>
            )}
          </TabPanel>
        )}
      </div>
    </>
  );
}
