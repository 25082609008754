import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const canSee =
        sessionStorage.getItem("viewPkgWithoutSignIn") === "true" ||
        !!sessionStorage.getItem("user_id") ||
        window.location.pathname.includes("/login") ||
        window.location.pathname.includes("/companySpecific") ||
        window.location.pathname.includes("/crack-wipro-nlth1") ||
        window.location.pathname.includes("/crack-wipro-nlth2") ||
        window.location.pathname.includes("/crack-wipro-nlth3") ||
        window.location.pathname.includes("/aptitude-pro") ||
        window.location.pathname.includes("/verbal-pro") ||
        window.location.pathname.includes("/logical-pro") ||
        window.location.pathname.includes("/crack-accenture-pro") ||
        window.location.pathname.includes("/Tcsnqt") ||
        window.location.pathname.includes("/Tcsdigital") ||
        window.location.pathname.includes("/Tcscodevita") ||
        window.location.pathname.includes("/LandT") ||
        window.location.pathname.includes("/Infytq") ||
        window.location.pathname.includes("/cts") ||
        window.location.pathname.includes("/java") ||
        window.location.pathname.includes("/python") ||
        window.location.pathname.includes("/capgemini") ||
        window.location.pathname.includes("/cprogramming") ||
        window.location.pathname.includes("/page-404") ||
        window.location.pathname.includes("/board-of-advisors") ||
        window.location.href?.includes("/termsAndConditions") ||
        window.location.href?.includes("/privacy") ||
        window.location.href?.includes("/legal") ||
        window.location.href?.includes("/take-test") ||
        window.location.href?.includes("/taketest") ||
        window.location.href?.includes("/completedPublicAssessment") ||
        window.location.href?.includes("/view-practice-questions") ||
        window.location.href?.includes("/course-overview") ||
        window.location.href?.includes("/fellowship-program") ||
        window.location.href?.includes("/techruit");
      return (
        <>
          {canSee ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )}
        </>
      );
    }}
  />
);

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};
export default AppRoute;
