import React, { Component } from "react";
import { Button, TabContent, TabPane } from "reactstrap";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Tabs } from "../../../components/tabs/tabs";
import CustomSearch from "../../Tasks/CustomSearch";
import { connect } from "react-redux";
import qs from "query-string";
import Select from "react-select";
import * as CustomIcons from "../../../assets/icons/icons";
import styles from "../../CardStyles.module.scss";
import StyledDate from "./StyledDate";
import Tooltip from "@material-ui/core/Tooltip";
import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";
import AssessmentModal from "./AssessmentModal";
import DelConfirmModal from "./DelConfirmModal";
import Pagination from "react-js-pagination";
import { paginationClasses } from "../../utils/constants";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toastStyles.css";
import "../../Tasks/AllQuestionBanks.scss";
import { NewHeader } from "components/NewHeader";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { sessionPrivileges } from "privileges";

const buttonStyle = {
  width: 40,
  height: 35,
  border: "1px solid #784df4",
  textAlign: "center",
  borderRadius: 5,
  padding: 6,
  color: "#784df4",
  cursor: "pointer",
};

const customStyles = {
  control: (styles) => ({
    ...styles,
    width: "207px",
    backgroundColor: "#fafbfc",
  }),
};

class AllAssessments extends Component {
  constructor(props) {
    super(props);
    this.userPrivileges = sessionPrivileges(true);
    this.Priv = this.userPrivileges.userprivileges || [];
    this.state = {
      modal: false,
      tab: "Assessments",
      mockAssessments: [],
      realMockAssessments: [],
      summaryDetails: {
        Othercount: "",
        TotalNoOfStudents: "",
        TotalCompleted: "",
        TotalSkipped: "",
      },
      delModal: false,
      AssessmentDel: {},
      assessments: [],
      realAssessments: [],
      propmtValue: {
        title: "Delete Assessment",
        body: "Are you sure you want to delete this Assessment?",
        button: "No",
      },
      activePages: {
        allList: 1,
      },
      customer: "",
      customerName: "",
      itemsPerPage: 6,
    };
  }
  handlererender = async (e) => {
    await Apihelper.axiosCall(
      `${api.baseURL}${api.assessmentController.deleteAsessment}?id=${e.id}`,
      "post"
    )
      .then((resp) => {
        if (resp !== false) {
          this.setState({
            delModal: !this.state.delModal,
          });
          toast.success(
            <>
              Deleted <span style={{ fontWeight: 500 }}>{e.name}</span>{" "}
              <i className="fas fa-check"></i>
            </>,
            {
              position: "bottom-start",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          this.getAssessmentsByCustomer(this.state.customer);
          this.getMockAssessmentsByCustomer(this.state.customer);
        } else {
          this.setState({
            propmtValue: {
              title: "Oops Error Occured ",
              body: "Seems this Assessment is currently being used",
              button: "Cancel",
            },
          });
        }
      })
      .catch(function () {});
  };

  actTabAndPage = () => {
    const queryParams = new URLSearchParams(this.props.location.search);
    if (queryParams.get("activePage")) {
      this.setState({
        activePages: { allList: queryParams.get("activePage") },
      });
    }
    if (queryParams.get("activeTab")) {
      this.setState({ tab: queryParams.get("activeTab") });
    }
  };

  componentDidMount() {
    const queryParams = new URLSearchParams(this.props.location.search);
  
    if (this.userPrivileges.isSuperAdmin) {
      if (this.props?.location?.state?.customer) {
        this.setState({ customer: this.props?.location?.state?.customer });
      } else
      if (this.props?.location?.state?.customerName) {
        this.setState({
          customerName: this.props?.location?.state?.customerName,
        });
      } else {
        this.setState({
             customer: "605b13d98443a50c4177d35d",
             customerName: "VarsityCollege",
           })
      };

      if (this.props?.location?.search) {
        if (queryParams.get("customer")) {
          this.setState({ customer: queryParams.get("customer") });
        }
      }
    } else if (this.userPrivileges.isAdmin) {
      this.getAssessmentsByCustomer(sessionStorage.getItem("customer_id"));
      this.getMockAssessmentsByCustomer(sessionStorage.getItem("customer_id"));
      this.setState({ customerName: sessionStorage.getItem("customer_name") });
      if (this.props?.location?.state?.customerName) {
        this.setState({
          customerName: this.props?.location?.state?.customerName,
        });
      }
    } else {
      this.getAllAssessments();
      this.getMockAssessmentsByCustomer(this.props?.location?.state?.customer);
    }
    if (this.props?.location?.state?.activePage) {
      this.setState({
        activePages: { allList: this.props?.location?.state?.activePage },
      });
    }
    if (this.props?.location?.state?.activeTab) {
      this.setState({ tab: this.props?.location?.state?.activeTab });
    }

    // if (this.props?.location?.search) {
    if (qs.parse(window.location.search)?.activePage) {
      this.setState({
        activePages: {
          allList: Number(qs.parse(window.location.search)?.activePage),
        },
      });
    }

    if (qs.parse(window.location.search)?.activeTab) {
      this.setState({ tab: qs.parse(window.location.search)?.activeTab });
    }

    // setTimeout(
    //   () => this.actTabAndPage(),
    //   3000
    // );
    // this.actTabAndPage();
    // }
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.customer !== this.state.customer) {
      this.getAssessmentsByCustomer(this.state.customer);
      this.getMockAssessmentsByCustomer(this.state.customer);
    }
  }

  getAssessmentsByCustomer = (customer) => {
    Apihelper.axiosCall(
      `${api.baseURL}${api.assessmentController.getAssessmentbyCustomer}${customer}`,
      "get"
    )
      .then((data) => {
        if (data)
          this.setState({
            assessments: data,
            realAssessments: data,
          });
        else throw "getAssessmentbyCustomer Error";
      })
      .catch((err) => {});
  };

  getMockAssessmentsByCustomer = (customer) => {
    Apihelper.axiosCall(
      `${api.baseURL}${api.assessmentController.getMockAssessmentList}${customer}`,
      "get"
    )
      .then((data) => {
        if (data)
          this.setState({
            mockAssessments: data,
            realMockAssessments: data,
          });
      })
      .catch((err) => {});
  };

  getAllAssessments() {
    try {
      Apihelper.axiosCall(
        `${api.baseURL}${api.assessmentController.getAllAssessment}`,
        "get"
      ).then((data) => {
        if (data)
          this.setState({
            assessments: data,
            realAssessments: data,
          });
        else throw "getAllAssessment Error";
      });
    } catch (e) {}
  }

  getSummary = async (data) => {
    if (this.state.summaryDetails.id !== data.id) {
      await Apihelper.axiosCall(
        `${api.baseURL}${api.assessmentController.getsummary}${data.id}`,
        "get"
      )
        .then((resp) => {
          let temp = { ...this.state.summaryDetails, ...resp };
          temp["name"] = data.name;
          temp["SD"] = data.startDate;
          temp["ED"] = data.endDate;
          temp["allData"] = data;
          temp["id"] = data.id;
          this.setState((prevState) => ({
            modal: !prevState.modal,
            summaryDetails: temp,
          }));
        })
        .catch(function () {});
    } else {
      this.setState((pre) => ({
        modal: !pre.modal,
      }));
    }
  };

  delConfirmation = (confirmation) => {
    if (confirmation === true) {
      this.handlererender(this.state.AssessmentDel);
    } else {
      this.setState({
        delModal: !this.state.delModal,
        propmtValue: {
          title: "Delete Assessment",
          body: "Are you sure you want to delete this Assessment?",
          button: "No",
        },
      });
    }
  };

  setDelAssessment = (row) => {
    this.setState({
      AssessmentDel: row,
      delModal: !this.state.delModal,
    });
  };

  handlesearch = (query) => {
    let arr = [];
    if (this.state.tab !== "Mock Assessments") {
      if (query.length >= 1) {
        this.setState({
          activePages: {
            allList: 1,
          },
        });
        this.state.realAssessments.forEach((e) => {
          if (e.name.toLowerCase().includes(query.toLowerCase())) {
            arr.push(e);
          }
        });
      }
      if (arr.length > 0) {
        this.setState({
          activePages: {
            allList: 1,
          },
        });
        this.setState({ assessments: arr });
      } else if (query.length >= 2 && arr.length == 0) {
        this.setState({
          activePages: {
            allList: 1,
          },
        });
        this.setState({ assessments: arr });
      } else {
        this.setState({ assessments: this.state.realAssessments });
      }
    } else {
      if (query.length >= 1) {
        this.state.realMockAssessments.forEach((e) => {
          if (e.name.toLowerCase().includes(query.toLowerCase())) {
            arr.push(e);
          }
        });
      }
      if (arr.length > 0) {
        this.setState({ mockAssessments: arr });
      } else if (query.length >= 2 && arr.length == 0) {
        this.setState({ mockAssessments: arr });
      } else {
        this.setState({ mockAssessments: this.state.realMockAssessments });
      }
    }
  };

  handlePageChange = (pageNo, from) => {
    let temp = { ...this.state.activePages };
    switch (from) {
      case "ALL":
        temp.allList = +pageNo;
        this.setState({ activePages: temp });
        break;
    }
  };

  moveToCompleted = async (e) => {
    this.setState({ loadingSync: true });
    const url = `${api.baseURL}${api.assessmentController.updatePendingUserAssessment}`;
    await Apihelper.axiosCall(`${url}${e.id}`, "post")
      .then((resp) => {
        toast.info("Update in progress !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({ loadingSync: false });
      })
      .catch((e) => {});
  };

  render() {
    let lastIndices = {
      ALL: this.state.activePages.allList * this.state.itemsPerPage,
    };
    let firstIndices = {
      ALL: lastIndices.ALL - this.state.itemsPerPage,
    };

    const customers = this.props.Customers
      ? this.props.Customers.reduce((acc, val) => {
          acc.push({
            label: val.name,
            value: val.id,
          });
          return acc;
        }, [])
      : [];

    return (
      <>
      {this.userPrivileges?.showAssessments && (
      <React.Fragment>
        <ToastContainer />
        <AssessmentModal
          isOpen={this.state.modal}
          toggle={() =>
            this.setState((pre) => ({
              modal: !pre.modal,
            }))
          }
          summary={this.state.summaryDetails}
          customerId={this.state.customer}
          activePage={this.state.activePages}
          activeTab={this.state.tab}
          data={this.state}
          customerName={this.statecustomerName}
        />
        <DelConfirmModal
          isOpen={this.state.delModal}
          toggle={this.delConfirmation}
          data={this.state.propmtValue}
        />
        <div className="page-content" style={{ position: "relative" }}>
          <NewHeader name="Assessments" />
          {this.Priv.includes("ADD_ASSESSMENT") && (
            <Button
              color="primary"
              onClick={() =>
                this.props.history.push({
                  pathname: "/create-assessment",
                  state: {
                    customerId: this.state.customer,
                    customerName: this.state.customerName,
                  },
                })
              }
              style={{
                position: "absolute",
                top: "20px",
                right: 10,
                borderRadius: 5,
                fontSize: 13,
                fontWeight: 500,
              }}
            >
              <i className="fas fa-plus" />
              &nbsp; Create New
            </Button>
          )}
          <CustomSearch
            handlesearch={(e) => this.handlesearch(e)}
            boxshadow
            placeholder="Search Assessments"
            primary
            style={{ position: "absolute", top: 20, left: "31.7%" }}
          />

          <div style={{ position: "relative" }}>
            {this.Priv.includes("MANAGE_CUSTOMER") && (
              <div
                style={{
                  position: "absolute",
                  top: 8,
                  right: "5%",
                  zIndex: 12,
                }}
              >
                <Select
                  placeholder="Choose a customer"
                  options={customers}
                  onChange={(val) => {
                    this.setState({
                      customer: val.value,
                      customerName: val.label,
                    });
                    this.setState({
                      activePages: {
                        allList: 1,
                      },
                    });
                    this.setState({
                      tab: "Assessments",
                    });
                  }}
                  value={GetObjectFromString(customers, this.state.customer)}
                  styles={customStyles}
                />
              </div>
            )}
          </div>
          <Tabs
            onChange={(newTab) => {
              this.setState({ tab: newTab, activePages: { allList: 1 } });
            }}
            activeTab={this.state.tab}
            tabs={[
              {
                name: "All Assessments",
                badge: this.state.assessments.length,
                id: "Assessments",
              },
              {
                name: "Mock Assessments",
                badge: this.state?.mockAssessments?.length,
                id: "Mock Assessments",
              },
            ]}
          >
            <TabContent
              activeTab={this.state.tab}
              style={{ padding: 10, backgroundColor: "rgba(255, 255, 255, 1)" }}
            >
              <TabPane tabId={this.state.tab}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "2vw",
                    minHeight: "60vh",
                    justifyContent: "center",
                  }}
                >
                  {(this.state.tab === "Mock Assessments"
                    ? this.state?.mockAssessments
                    : this.state?.assessments
                  )?.length > 0 ? (
                    (this.state.tab === "Mock Assessments"
                      ? this.state?.mockAssessments
                      : this.state?.assessments
                    )
                      .slice(firstIndices.ALL, lastIndices.ALL)
                      .map((j) => (
                        <>
                          <Card key={j.id} classes={{ root: styles.CARD_NEW }}>
                            <CardActionArea>
                              <CardMedia
                                style={{ height: 150 }}
                                image={
                                  j.media
                                    ? j.media.includes("default")
                                      ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${j.media}.jpeg`
                                      : j.media
                                    : `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/default-${19}.jpeg`
                                }
                                title={`${j.id}`}
                              />
                              <CardContent
                                style={{ padding: "0 12px 16px 12px" }}
                              >
                                <p
                                  className={styles.CardHeader}
                                  style={{ margin: "8px 0" }}
                                >
                                  {j.name}
                                </p>
                                <StyledDate SD={j.startDate} ED={j.endDate} />
                              </CardContent>
                            </CardActionArea>

                            <CardActions
                              className={styles.cardButtons}
                              style={{
                                display: "flex",
                                gap: 10,
                                justifyContent: "center",
                              }}
                            >
                              {this.Priv.includes("VIEW_ASSESSMENT") && (
                                <Tooltip
                                  title="View Assessment"
                                  arrow
                                  placement="top-start"
                                >
                                  <div
                                    onClick={() => {
                                      this.getSummary(j);
                                    }}
                                    style={buttonStyle}
                                  >
                                    {CustomIcons.eyeFilled}
                                  </div>
                                </Tooltip>
                              )}{" "}
                              {this.Priv.includes("DELETE_ASSESSMENT") && (
                                <Tooltip
                                  placement="top-start"
                                  arrow
                                  title="Delete"
                                >
                                  <div
                                    style={{
                                      ...buttonStyle,
                                    }}
                                    onClick={() => this.setDelAssessment(j)}
                                  >
                                    {CustomIcons.trashFilled}
                                  </div>
                                </Tooltip>
                              )}{" "}
                              {this.Priv.includes("VIEW_ASSESSMENT_REPORT") && (
                                <Tooltip
                                  title="Report"
                                  arrow
                                  placement="top-start"
                                >
                                  <div
                                    style={buttonStyle}
                                    onClick={() => {
                                      const activePage = this.state.activePages;
                                      const activeTab = this.state.tab;
                                      this.props.history.push({
                                        pathname: `/report`,
                                        state: {
                                          assessmentId: j.id,
                                          name: j.name,
                                          customerId: `${this.state.customer}`,
                                          activePage: activePage,
                                          activeTab: activeTab,
                                        },
                                      });
                                    }}
                                  >
                                    {CustomIcons.fileReportOne}
                                  </div>
                                </Tooltip>
                              )}{" "}
                              {this.Priv.includes(
                                "ASSESSMENT_MANUAL_GRADING"
                              ) &&
                                j.gradingProcess === "manual" && (
                                  <Tooltip
                                    title="Manual Grading"
                                    arrow
                                    placement="top-start"
                                  >
                                    <div
                                      style={buttonStyle}
                                      onClick={() => {
                                        const activePage =
                                          this.state.activePages;
                                        const activeTab = this.state.tab;
                                        this.props.history.push({
                                          pathname: `/manual-grading`,
                                          state: {
                                            assessmentId: j.id,
                                            customerId: `${this.state.customer}`,
                                            activePage: activePage,
                                            activeTab: activeTab,
                                          },
                                        });
                                      }}
                                    >
                                      {CustomIcons.manualGradingOne}
                                    </div>
                                  </Tooltip>
                                )}
                              {j.assessmentExpired === true && (
                                <Tooltip
                                  title={
                                    this.state.loadingSync
                                      ? "Moving is Progress"
                                      : "Move to Completed"
                                  }
                                  arrow
                                  placement="top-start"
                                >
                                  <div
                                    style={buttonStyle}
                                    onClick={() => this.moveToCompleted(j)}
                                  >
                                    {this.state.loadingSync
                                      ? CustomIcons.stopSync
                                      : CustomIcons.syncIcon}
                                  </div>
                                </Tooltip>
                              )}
                            </CardActions>
                          </Card>
                        </>
                      ))
                  ) : (
                    <h4>
                      {this.Priv.includes("MANAGE_CUSTOMER")
                        ? this.state.customer
                          ? "No Assessments available for this Customer."
                          : "Choose a Customer from the list above."
                        : "No Assessments available"}
                    </h4>
                  )}
                </div>
                {(this.state.tab === "Mock Assessments"
                  ? this.state?.mockAssessments
                  : this.state?.assessments
                )?.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginRight: 10,
                    }}
                  >
                    <Pagination
                      activePage={this.state.activePages.allList}
                      itemsCountPerPage={this.state.itemsPerPage}
                      {...paginationClasses}
                      totalItemsCount={
                        (this.state.tab === "Mock Assessments"
                          ? this.state?.mockAssessments
                          : this.state?.assessments
                        )?.length
                      }
                      pageRangeDisplayed={5}
                      onChange={(pageNo) =>
                        this.handlePageChange(pageNo, "ALL")
                      }
                    />
                  </div>
                )}
              </TabPane>
            </TabContent>
          </Tabs>
        </div>
      </React.Fragment>
      )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Customers: state.StudentAndCustomerReducer.Customers,
});

export default connect(mapStateToProps, null)(AllAssessments);
