import * as CustomIcons from "../../../assets/icons/icons";
import Style from "../../Ecommerce/EcommerceOrders/AllManualGrading.module.scss";
import Avatar from "../../../assets/images/users/default-avatar.svg";
import moment from "moment";

const GradingHeader = ({
  studentName,
  hackathonName,
  hackathonStartDate,
  hackathonEndDate,
  projectDetails
}) => {
  // const currentStudent = student?.allStudents?.find((stu) => (
  //   stu.id === student.id
  // ))
  return (
    <div className={Style.topSection}>
      <div className={Style.flexOne}>
        <h2 className={Style.title}>{hackathonName}</h2>
        <span className={Style.sub}>
        {CustomIcons.bull}&nbsp;Start date: <span style={{fontFamily: "Poppins",fontStyle: "normal",fontWeight: 600,fontSize: "14px",lineHeight: "24px",color: "#323036"}}>
          {moment(hackathonStartDate).format("DD MMM YYYY, HH:mm a")}
          </span>
        </span>
        <span className={Style.sub}>
        {CustomIcons.bull}&nbsp;End date: <span style={{fontFamily: "Poppins",fontStyle: "normal",fontWeight: 600,fontSize: "14px",lineHeight: "24px",color: "#323036"}}>
            {moment(hackathonEndDate).format("DD MMM YYYY, HH:mm a")}
            </span>
        </span>
      </div>
      <div style={{ backgroundColor: "#eee", height: "0.04rem" }} />

      <div className={Style.flexTwo}>
        <img src={Avatar} alt="profile-dp" className={Style.avatar} />
        <span className={Style.name}>{studentName}</span>
        <span className={Style.sub}>
          &nbsp;{CustomIcons.bull}&nbsp;Evaluation status -
        </span>
        {
        projectDetails?.evaluationStatus == "Pending" ? (
        <span className={Style.pending}>Pending</span>
        ) : 
        projectDetails?.evaluationStatus == "graded" ? (
        <span className={Style.completed}>Completed</span>
        ) : projectDetails?.evaluationStatus == "Completed" ?  (
        <span className={Style.completed}>Completed</span>
        ) : <></>
        }
      </div>
    </div>
  );
};

export default GradingHeader;
