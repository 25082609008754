import { sessionPrivileges } from "privileges";
import * as yup from "yup";
import { CheckIfCloudLabNameExists } from "./Apis";

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

const isSA = sessionPrivileges().isSuperAdmin;

export const Form1Schema = (isEdit, editData) =>
  yup.object().shape({
    name: yup
      .string()
      .required("Please enter a valid name")
      .test(
        "checkDuplCourseName",
        "This name is already in use. Please enter a different name",
        async (value) => {
          if (!value) return true;
          else if (isEdit && value === editData?.name) return true;
          const res = await CheckIfCourseNameExists(value);
          return !res;
        }
      ),
    description: yup.string().required("Please enter a valid description"),
    startDate: yup
      .date()
      .nullable()
      .typeError("Please enter a valid start date")
      .min(yesterday, "Start date must be after today"),
    endDate: yup
      .date()
      .nullable()
      .typeError("Please enter a valid end date")
      .min(yup.ref("startDate"), "End date can't be before start date"),
    duration: yup.number().positive().required("Please enter a valid duration"),
    level: yup.string().required("Please choose a level"),
    category: yup.object().required("Please choose a category"),
    media: yup.string().required("Please choose an image"),
  });

export const LessonFormSchema = (type) =>
  yup.object().shape({
    name: yup.string().required("Please enter a valid name"),
    ...((type === "Video" || type === "Audio") && {
      durationMin: yup
        .number()
        .positive()
        .required("Please enter a valid duration"),
    }),
    ...(type === "Study material" && {
      documentType: yup.string().required("Please choose a document type"),
    }),
    ...(type === "Scrom" && {
      secretId: yup.string().required("Please enter secret id"),
    }),
    media: yup.string().required("Please upload a content"),
  });

export const Form3Schema = yup.object().shape({
  overviews: yup
    .string()
    .required("Please enter valid overviews")
    .test(
      "len",
      "Please enter valid overviews",
      (val) => val !== "<p><br></p>"
    ),
  outcomes: yup
    .string()
    .required("Please enter valid outcomes")
    .test("len", "Please enter valid outcomes", (val) => val !== "<p><br></p>"),
  requirements: yup
    .string()
    .required("Please enter valid requirements")
    .test(
      "len",
      "Please enter valid requirements",
      (val) => val !== "<p><br></p>"
    ),
});

export const Form5Schema = yup.object().shape({
  paidPackage: yup.boolean(),
  originalPrice: yup.number().when("paidPackage", {
    is: true,
    then: yup
      .number()
      .positive("Original price must be positive")
      .required("Please enter a valid original price"),
  }),
  strikingPrice: yup.number().when("paidPackage", {
    is: true,
    then: yup
      .number()
      .positive("Striking price must be positive")
      .required("Please enter a valid striking price")
      .lessThan(
        yup.ref("originalPrice"),
        "Striking price cannot be greater than original price"
      ),
  }),
  ...(isSA && {
    customerId: yup.string().required("Please choose a customer"),
  }),
});

export const cloudLabFormValidation = (fromPrepare, courseEndDate) =>
  yup.object().shape({
    meetingName: yup
      .string()
      .max(50, "Meeting Name is Too Long!")
      .required("Please enter a Meeting Name"),
    description: yup.string().required("Please enter a description"),
    startDate: yup
      .date()
      .nullable()
      .typeError("Please enter a valid start date")
      .min(yesterday, "Start date must be after today"),
    duration: yup
      .number()
      .positive()
      .required("Please enter a valid duration")
      .max(60, "duration must be below 60mins"),
    maxVideoScreen: yup
      .number()
      .positive()
      .required("Please enter a valid no of video screening")
      .max(5, "video screening must be below 5"),
    hostUsers: yup.array().required("Please select a host"),
    participantUsers: yup.array().required("Please select a participant"),
    ...(isSA && {
      customerId: yup.string().required("Please select a customer"),
    }),
    ...(fromPrepare && {
      startDate: yup
        .date()
        .nullable()
        .max(
          courseEndDate,
          "Start date can not be greater than course's end date"
        ),
    }),
  });
