import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import UserInputComponents from  "./UserInputComponents";
import { paginationClasses } from "../utils/constants";
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Pagination from "react-js-pagination";
import Pdf from "react-to-pdf";

import {  Dialog, DialogContent, DialogActions, Menu, MenuItem, Accordion, AccordionSummary, AccordionDetails, Button, CircularProgress  } from "@material-ui/core";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import api from "../../api/baseConfig";
import ApiHelper from "../../api/apiHelper";
import { sessionPrivileges } from "privileges";
import { EmptyState } from "components/EmptyState";

import Styles from "./style.scss";
import useStyles from  "./UserInputStyles.js";
import { HomeLocationIcon, EmailIcon, PhoneIcon } from "assets/icons/icons";
import avatar from "../../assets/images/users/default-avatar.svg";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from "react-select";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { FilterList } from "@material-ui/icons";

function SimStudentsList(props) {
  const userPrivileges = sessionPrivileges();
  const allCustomers = useSelector((state) =>
    state.StudentAndCustomerReducer.Customers
      ? state.StudentAndCustomerReducer.Customers.reduce((acc, val) => {
          acc.push({
            label: val.name,
            value: val.id,
          });
          return acc;
        }, [])
      : []
  );
  const [currentCustomer, setCurrentCustomer] = useState("");
  const css = useStyles();
  const ref = React.createRef();
  const [switchToken, setSwitchToken] = useState("");
  const [searchInit, setSearchInit] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [openData, setOpenData] = useState(null);
  const [printHeight, setPrintHeight] = useState("auto");
  const [printStatus, setPrintStatus] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [errorComponentList, setErrorComponentList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [tableOn, setTableOn] = useState("loading");
  const [favourite, setFavourite] = useState({
    favourite: {value: "", validation:{key:"favourite", placeholder:"inner", withInput:true, name:"Favourite", data:[{name:"", id:""}, {name:"Sample export", id:"dasfadf"}], min:3, max:48, req: false, dataType:"obj", type:"select-single" }},
  });
  const [favouriteName, setFavouriteName] = useState("");
  const [favouriteInList, setFavouriteInList] = useState(false);
  const [favouriteListAction, setFavouriteListAction] = useState("");
  const defaultFavouriteList = [
    "name","fathersName","gender","dob","registerno","pincode","emailId","phoneNo","bloodGroup",
    "address","state","city","languages","degree","department","collegeName","cgpaOrPercentage",
    "historyOfArrear","currentArrear","contactName","conPhoneno","relationship"
  ];
  const [favouriteList, setFavouriteList] = useState([...defaultFavouriteList]);
  const [favouriteListFull, setFavouriteListFull] = useState([
    {"id":"","name":true,"fathersName":true,"gender":true,"dob":true,"registerno":true,"bloodGroup":true,"address":true,"city":true,"state":true,"pincode":true,"phoneNo":true,"emailId":true,"languages":true,"contactName":true,"conPhoneno":true,"relationship":true,"degree":true,"department":true,"collegeName":true,"cgpaOrPercentage":true,"historyOfArrear":true,"currentArrear":true,"filterName":"BBB","active":true}
  ]);
  const personal = [
	{id: "name", nameId: "Name"},
	{id: "gender", nameId: "Gender"},
	{id: "dob", nameId: "DOB"},
	{id: "fathersName", nameId: "Father's Name"},
	{id: "registerno", nameId: "Register No"},
	{id: "emailId", nameId: "Email"},
	{id: "phoneNo", nameId: "Phone No"},
	{id: "bloodGroup", nameId: "Blood Group"},
	{id: "address", nameId: "Address"},
	{id: "city", nameId: "City"},
	{id: "state", nameId: "State"},
	{id: "pincode", nameId: "Pincode"},
	{id: "languages", nameId: "Languages"},
  ]
  const education = [
	{id: "degree", nameId: "Degree"},
	{id: "department", nameId: "Department"},
	{id: "collegeName", nameId: "College Name"},
	{id: "cgpaOrPercentage", nameId: "CGPA/Percentage"},
	{id: "historyOfArrear", nameId: "History of Arrear"},
	{id: "currentArrear", nameId: "Current Arrear"},
  ]
  const emergency = [
	{id: "contactName", nameId: "Contact Name"},
	{id: "conPhoneno", nameId: "Phone"},
	{id: "relationship", nameId: "Relationship"},
  ]

  const defaultFilter = {
    search: {value: "", validation:{key:"search", placeholder:"inner", name:"Search...", min:3, max:48, length:48 }},
    languagesKnown: {value: [], validation:{key:"languagesKnown", name:"Language", data:[], type:"checkbox" }},
    gender: {value: [], validation:{key:"gender", name:"Gender", data:[], type:"checkbox" }},
    bloodGroup: {value: [], validation:{key:"bloodGroup", name:"Blood Group", data:[], type:"checkbox" }},
    degree: {value: [], validation:{key:"degree", name:"Degree", data:[], type:"checkbox" }},
    department: {value: [], validation:{key:"department", name:"Department", data:[], type:"checkbox" }},
    collegeName: {value: [], validation:{key:"collegeName", name:"College", data:[], type:"checkbox" }},
    cgpa: {value: [], validation:{key:"cgpa", name:"CGPA", data:[], valueType:"integer", type:"checkbox" }},
    percentage: {value: [], validation:{key:"percentage", name:"Percentage", data:[], valueType:"integer", type:"checkbox" }},
    historyArrear: {value: {condition:"", val:""}, validation:{key:"historyArrear", name:"History of arrear", minValue:0, maxValue:100, data:[], type:"condition" }},
    currentArrear: {value: {condition:"", val:""}, validation:{key:"currentArrear", name:"Current arrear", minValue:0, maxValue:100, data:[], type:"condition" }},
  };
  const defaultFilterObj = {
    languagesKnown: [],
    gender: [],
    bloodGroup: [],
    degree: [],
    department: [],
    collegeName: [],
    cgpa: [],
    percentage: [],
    historyArrear: {
        condition: "",
        val: ""
    },
    currentArrear: {
        condition: "",
        val: ""
    }
  };
  const [filterValues, setFilterValues] = useState({
    languagesKnown: ["Tamil","English","Hindi"],
    gender: ["Male","Female","Other"],
    bloodGroup: ["O+", "O-", "A+", "A-", "B+", "B-", "AB+", "AB-"],
    degree: ["SSLC","HSC","DIPLOMA","UG","PG"],
    department: ["Computer Science and Engineering ","Electronics and Communications Engineering","Electrical and Electronics Engineering"],
    collegeName: ["Sethu Institute of Technology","K.S.R College of Engineering","National Engineering College","VSB Engineering"],
    cgpa: [{name:"Below 4", value:4 },{name:"4 to 6", value:6 },{name:"6 to 7", value:7},{name:"7 to 8", value:8},{name:"Above 9", value:9}],
    percentage: [{name:"Below 40%", value:40 },{name:"40 to 60%", value:60 },{name:"60 to 70%", value:70},{name:"70 to 80%", value:80},{name:"Above 80%", value:90}],
    historyArrear: ["Nill","Below","Above","Equal","Equal & below","Equal & above"],
    currentArrear: ["Nill","Below","Above","Equal","Equal & below","Equal & above"],
  });
  const [filterObj, setFilterObj] = useState({...defaultFilterObj});
  const [filterBackup, setFilterBackup] = useState(null);
  const [filter, setFilter] = useState({...defaultFilter});
  const [filterExpandIndex, setFilterExpandIndex] = useState(-1);
  const [tableList, setTableList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openFilter = Boolean(anchorEl);

  const handleCloseFilter = (action) => {
    setFilterExpandIndex(-1);
    if(action === "reset") {
      const backup = {...filterBackup};
      setFilter(backup);
      setFilterBackup(null);
    }
    setAnchorEl(null);
  };
  const handleOpenFilter = (event) => {
    const backup = JSON.stringify(filter);
    setFilterBackup(JSON.parse(backup));
    if(openFilter)
      setAnchorEl(null);
    else
      setAnchorEl(event.currentTarget && event.currentTarget.nextElementSibling ? event.currentTarget.nextElementSibling : event.currentTarget);
  };

  const traceData = (keys, keyIndex, dataName, val, data) => {
    if(keys[keyIndex+1]) data[keys[keyIndex]] = traceData(keys, keyIndex+1, dataName, val, data[keys[keyIndex]]);
    else data[keys[keyIndex]][dataName] = val;
    return data;
  }

  const updateData = ({keys, dataName, value}) => {
    if(keys && keys.length > 0 ){
      const tempData = traceData(keys, 0, dataName, value, {...filter});
      setFilter(tempData);
    }
  }

  const updateFavourite = ({keys, dataName, value}) => {
    if(keys && keys.length > 0 ){
      const tempData = traceData(keys, 0, dataName, value, {...favourite});
      setFavourite(tempData);
    }
  }

  const updateFilterList = () => {
    handleCloseFilter("none");
    const data = {...filter};
    let tempUser = {};
    for(const key in data) {
      if(key !== "search"){
        if(data[key]["value"] !== undefined)
          tempUser[key] = data[key]["value"]
        else if(Array.isArray(data[key])) {
          const tempArray = [];
          data[key].length > 0 && data[key].map((d,i) => {
            if(data[key][i]["value"] !== undefined) tempArray.push(data[key][i]["value"]);
            else if(typeof data[key][i] === "object") {
              const tempObj = {};
              for (const subKey in data[key][i]) {
                if(data[key][i][subKey]["value"] !== undefined ) {
                  tempObj[subKey] = data[key][i][subKey]["value"];
                }
              }
              tempArray.push({...tempObj})
            }
          });
          tempUser[key] = tempArray;
        } else if(typeof data[key] === "object") {
          const tempObj = {};
          for (const subKey in data[key]) {
            if(data[key][subKey]["value"] !== undefined) tempObj[subKey] = data[key][subKey]["value"];
          }
          tempUser[key] = tempObj;
        }
      }
    }
    setFilterObj({...tempUser});
  }

  const errorComponentUpdate = (component, type) => {
    const tempList = [...errorComponentList];
    const index = tempList.indexOf(component);
    if(type === "remove" && index > -1){
      tempList.splice(index, 1);
      setErrorComponentList(tempList);
    } else if(type === "add" && index === -1)
      setErrorComponentList([...tempList, component]);
  }
  const resetFavourite = () => {
    setFavouriteList([...defaultFavouriteList]);
    const temp = {...favourite}
    temp.favourite.value = "";
    setFavourite(temp);
    setFavouriteName("");
    toast.success("Favourite resetted successfully", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
    });
  }
  const getFavouriteList = async () => {
    try {
      const data = await ApiHelper.axiosCallTokenData(
        `${api.baseURL}sims/getFilterById`,
        "GET"
      );
      if (data) {
        const favData = [{name:"", id:""}];
        if(data.length > 0){
          setFavouriteListFull(data);
          data.map((fav,i)=>{
            favData.push({name:fav.filterName, id:fav.id})
          })
          const temp = {...favourite}
          temp.favourite.validation.data = favData;
          setFavourite(temp);
        } else {
          setFavouriteListFull([]);
        }
      }
    } catch (e) {
      console.log("Error: while feaching favourite list");
      console.log(e);
      setTableOn("loaded")
    }
  }
  const deleteFavourite = async () => {
    setTableOn("loadingExport");
    try {
      const data = await ApiHelper.axiosCallTokenData(
        `${api.baseURL}sims/deleteFilter/${favourite.favourite.value}`,
        "Delete"
      );
      if (data) {
        setTableOn("loaded");
        const temp = {...favourite}
        if(temp.favourite.validation.data.length > 0)
          temp.favourite.validation.data = temp.favourite.validation.data.filter((obj)=>obj.id !== favourite.favourite.value);
        temp.favourite.value = "";
        setFavourite(temp);
        setFavouriteName("");
        toast.success("Favourite deleted successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
        setFavouriteListAction("");
      } else setTableOn("loaded")
    } catch (e) {
      console.log("Error: while delete favourite data");
      console.log(e);
      setTableOn("loaded")
    }
  }
  const addUpdateFavourite = async () => {
    setTableOn("loadingExport");
    try {
      let payload = { ...(favourite.favourite.value ? {id:favourite.favourite.value}:{}), filterName: favouriteName }
      if(favouriteList.length > 0){
        favouriteList.map((list, i)=>{
          payload[list] = true;
        })
      }
      const data = await ApiHelper.axiosCallTokenData(
        `${api.baseURL}sims/saveUserFilter/`,
        "POST",
        payload
      );
      if (data) {
        setTableOn("loaded");
        if(favourite.favourite.value){
          const temp = {...favourite}
          if(temp.favourite.validation.data.length > 0){
            temp.favourite.validation.data.map((list,i)=>{
              if(list.id === favourite.favourite.value){
                temp.favourite.validation.data[i].name=favouriteName;
              }
            });
            setFavourite(temp);
            const tempFull = {};
            defaultFavouriteList.map((dList,j)=>{
              tempFull[dList] = favouriteList.indexOf(dList) > -1 ? true : false
            });
            if(favouriteListFull.length > 0){
              const tempFullList = [...favouriteListFull]
              favouriteListFull.map((list,i)=>{
                if(list.id === favourite.favourite.value) {
                  tempFullList[i] = {...tempFullList[i], ...tempFull, filterName: favouriteName}
                }
              })
              setFavouriteListFull(tempFullList);
            }
          }
        }else if(data.id){
          const temp = {...favourite}
          temp.favourite.validation.data.push({name:favouriteName, id:data.id})
          temp.favourite.value = data.id;
          setFavourite(temp);
        }
        toast.success(favourite.favourite.value ? "Favourite updated successfully" : "New favourite added successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
        });
        setFavouriteListAction("");
      } else setTableOn("loaded")
    } catch (e) {
      console.log("Error: while saving favourite data");
      console.log(e);
      setTableOn("loaded")
    }
  }
  const exportDocs = async () => {
    setTableOn("loadingExport");
    try {
      let userFilterView = {};
      const userFilter = {
        search: `${searchString}`.trim(),
        ...(filterObj.cgpa && filterObj.cgpa.length > 0 && { cgpa: filterObj.cgpa }),
        ...(filterObj.historyArrear.val && { historyArrear: filterObj.historyArrear.val }),
        ...(filterObj.currentArrear.val && { currentArrear: filterObj.currentArrear.val }),
        ...(filterObj.gender && filterObj.gender.length > 0 && { gender: filterObj.gender }),
        ...(filterObj.degree && filterObj.degree.length > 0 && { degree: filterObj.degree }),
        ...(filterObj.historyArrear.condition && { historyOfArrear: filterObj.historyArrear.condition }),
        ...(filterObj.currentArrear.condition && { currentOfArrear: filterObj.currentArrear.condition }),
        ...(filterObj.percentage && filterObj.percentage.length > 0 && { percentage: filterObj.percentage }),
        ...(filterObj.bloodGroup && filterObj.bloodGroup.length > 0 && { bloodGroup: filterObj.bloodGroup }),
        ...(filterObj.department && filterObj.department.length > 0 && { department: filterObj.department }),
        ...(filterObj.collegeName && filterObj.collegeName.length > 0 && { collegeName: filterObj.collegeName }),
        ...(filterObj.languagesKnown && filterObj.languagesKnown.length > 0 && { languagesKnown: filterObj.languagesKnown })
      }
      if(favouriteList.length > 0){
        favouriteList.map((list, i)=>{
          userFilterView[list] = true;
        })
      }
      const data = await ApiHelper.axiosCallTokenData(
        `${api.baseURL}sims/downloadUserDetails/${currentCustomer}`,
        "POST",
        {userFilterView, userFilter}
      );
      if (data) {
        toast.success("Documents exported succesfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
        });
        setTableOn("loaded");
      } else setTableOn("loaded")
    } catch (e) {
      console.log("Error: while exporting document");
      console.log(e);
      setTableOn("loaded")
    }
  }
  const getUsersInfoList = async (name) => {
    try {
      const data = await ApiHelper.axiosCallTokenData(
        `${api.baseURL}sims/getAllUsersByCustomer/${currentCustomer}?userId=${userPrivileges.userId}`,
        "POST",
        {
          search: `${searchString}`.trim(),
          ...(filterObj.cgpa && filterObj.cgpa.length > 0 && { cgpa: filterObj.cgpa }),
          ...(filterObj.historyArrear.val && { historyArrear: filterObj.historyArrear.val }),
          ...(filterObj.currentArrear.val && { currentArrear: filterObj.currentArrear.val }),
          ...(filterObj.gender && filterObj.gender.length > 0 && { gender: filterObj.gender }),
          ...(filterObj.degree && filterObj.degree.length > 0 && { degree: filterObj.degree }),
          ...(filterObj.historyArrear.condition && { historyOfArrear: filterObj.historyArrear.condition }),
          ...(filterObj.currentArrear.condition && { currentOfArrear: filterObj.currentArrear.condition }),
          ...(filterObj.percentage && filterObj.percentage.length > 0 && { percentage: filterObj.percentage }),
          ...(filterObj.bloodGroup && filterObj.bloodGroup.length > 0 && { bloodGroup: filterObj.bloodGroup }),
          ...(filterObj.department && filterObj.department.length > 0 && { department: filterObj.department }),
          ...(filterObj.collegeName && filterObj.collegeName.length > 0 && { collegeName: filterObj.collegeName }),
          ...(filterObj.languagesKnown && filterObj.languagesKnown.length > 0 && { languagesKnown: filterObj.languagesKnown }),
          pageIndex: activePage-1,
          limit: itemsCountPerPage
        }
      );
      if (data) {
        setTotalItemsCount(data.totalCount ?  data.totalCount : 0)
        if(data.requestList && data.requestList.length>0){
          setTableList([...data.requestList]);
        } else 
          setTableList([]);
      } else return false;
      setTableOn("loaded")
    } catch (e) {
      console.log("Error: while feaching student data");
      console.log(e);
      setTableOn("loaded")
    }
  }
  const handleCheckBox = (v) => {
    const tempFavouriteList = [...favouriteList];
    const index = tempFavouriteList.indexOf(v)
    if(index > -1)
      tempFavouriteList.splice(index,1);
    else
      tempFavouriteList.push(v);
    setFavouriteList(tempFavouriteList);
  };
  const getFilterValues = async () => {
    try {
      const data = await ApiHelper.axiosCallTokenData(
        `${api.baseURL}sims/getDetailsByCustomer/${currentCustomer}`,
        "GET"
      );
      if (data) {
        setFilterValues({
          ...filterValues, 
          languagesKnown: data.languagesKnown && data.languagesKnown.length > 0 ? [...data.languagesKnown]: [],
          degree: data.degreeName && data.degreeName.length > 0 ? [...data.degreeName]: [],
          department: data.department && data.department.length > 0 ? [...data.department]: [],
          collegeName: data.collegeName && data.collegeName.length > 0 ? [...data.collegeName]: [],
        });
      } else return false;
    } catch (e) {
    }
  }//filter

  useEffect(()=>{
    if(switchToken !== "") {
      if(switchToken === "filter") {
        getFilterValues();
      }
    }
  },[switchToken]);

  useEffect(()=>{
    if(currentCustomer !== "") {
      const tempFilter = {...filter};
      Object.keys(filterValues).map((key, index)=>{
        if(tempFilter[key]) tempFilter[key] = {...tempFilter[key], validation: {...tempFilter[key]["validation"], data: filterValues[key] }};
      })
      setFilter(tempFilter);
      if(switchToken === "filter") {
        setSwitchToken("")
      } else {
        setTableOn("loading");
        setActivePage(1);
        getUsersInfoList("filterValues");
      }
    }
  },[filterValues]);

  useEffect(()=>{
    if(currentCustomer !== "") {
      setTableOn("loading");
      setActivePage(1);
      getFilterValues();
    }
  },[currentCustomer]);

  useEffect(()=>{
    if(searchInit){
      setTableOn("loading");
      setActivePage(1);
      getUsersInfoList("filterObj");
    }
  },[filterObj]);

  useEffect(()=>{
    setTableOn("loading");
    const timeout = setTimeout(() => {
      if(searchInit){
        setActivePage(1);
        getUsersInfoList("searchString");
      }
    }, 1200);
    return () => timeout && clearTimeout(timeout);
  },[searchString]);

  useEffect(()=>{
    if(searchInit) {
      setTableOn("loading");
      getUsersInfoList("activePage");
    }
  },[activePage]);

  useEffect(()=>{
    if(searchInit) {
      setTableOn("loading");
      setActivePage(1);
      getUsersInfoList("itemsCountPerPage");
    } else {
      setSearchInit(true)
    }
  },[itemsCountPerPage]);
  const getFavouriteName = (id) => {
    let favName = "";
    favourite.favourite.validation.data.map((lItem, i)=>{
      if(lItem.id === id){
        favName = lItem.name
      }
    })
    if(id && favouriteListFull.length > 0){
      const tempFavouriteList = []
      favouriteListFull.map((lItem, i)=>{
        if(lItem.id === id) {
          defaultFavouriteList.map((dList,j)=>{
            if(lItem[dList]) tempFavouriteList.push(dList);
          })
        }
      })
      setFavouriteList(tempFavouriteList);
    } else setFavouriteList(defaultFavouriteList);
    setFavouriteName(favName);
  }
  useEffect(()=>{
    if(favouriteListAction && favourite.favourite.value) {
      if(favourite.favourite.validation && favourite.favourite.validation.data && favourite.favourite.validation.data.length > 0){
        let favName = "";
        favourite.favourite.validation.data.map((lItem, i)=>{
          if(lItem.id === favourite.favourite.value){
            favName = lItem.name
          }
        })
        setFavouriteName(favName);
      } else setFavouriteName("");
    } else setFavouriteName("");
  },[favouriteListAction]);

  useEffect(()=>{
    if(favouriteName.trim()) {
      if(favourite.favourite && favourite.favourite.validation && favourite.favourite.validation.data && favourite.favourite.validation.data.length > 0){
        let isInList = false;
        favourite.favourite.validation.data.map((lItem, i)=>{
          if(favouriteListAction === "new" && lItem.name && lItem.name.trim().toLowerCase() === favouriteName.trim().toLowerCase()){
            isInList = true
          }
        })
        setFavouriteInList(isInList)
      } else {
        setFavouriteInList(false)
      }
    } else {
      setFavouriteInList(false)
    }
  },[favouriteName]);

  useEffect(()=>{
    if(openExport)
      getFavouriteList()
  },[openExport]);

  useEffect(()=>{
    if(userPrivileges.customerId) setCurrentCustomer(userPrivileges.customerId);
  },[]);

  return (
    <>
    {(userPrivileges.isSuperAdmin || userPrivileges.isAdmin) && (
    <div id="sims-page" className="flex-1 flex-box flex-col flex-gap-2">
      <div className="header flex-auto">
        <div className="headerbox">
            <div className="flex-box flex-100 flex-middle flex-gap-2">
              <div className="flex-2"><h1>Student Information management system</h1></div>
            </div>
            <div className="flex-box flex-100 flex-middle flex-gap-2" style={{marginTop:16}}>
              <div className="flex-1">
                <div className="flex-box flex-100 flex-middle flex-gap-2">
                <div className="flex-1 flex-box flex-100 flex-middle search-box" style={{maxWidth:320}}>
                  <button><span className="bx bx-search search-icon"></span></button>
                  {filter.search && (
                    <UserInputComponents type="text" value={searchString} errorShow={errorShow}
                      setValue={(v)=>setSearchString(v)}
                      updateError={errorComponentUpdate} validation={filter.search.validation} />
                  )}
                </div>
                <div className="flex-1" style={{maxWidth:320}}>
                  {userPrivileges.isSuperAdmin === true && (
                    <Select
                      options={allCustomers}
                      value={GetObjectFromString(allCustomers, currentCustomer)}
                      onChange={(val) => {
                        setCurrentCustomer(val.value);
                      }}
                      placeholder="Choose a customer"
                      maxMenuHeight={200}
                    />
                  )}
                </div>
                <div className="flex-1"></div>
                </div>
              </div>
              <div className="flex-auto">
                <div className="flex-box flex-middle flex-gap-2">
                  <button className="flex-box flex-middle flex-gap-1 buttonGary" onClick={handleOpenFilter}>
                    <span className="bx bx-filter"></span>
                    <span className="name">Filter</span>
                  </button>
                  <button className="flex-box flex-middle flex-gap-1 buttonGary" onClick={()=>{setOpenExport(true)}}>
                    <span className="bx bx-export"></span>
                    <span className="name">Export</span>
                  </button>
                </div>
              </div>
              <Menu
                id={`filter-menu`}
                elevation={0}
                anchorOrigin={{ vertical:'bottom', horizontal:'left' }}
                transformOrigin={{ vertical:'top', horizontal:'right' }}
                classes={{ paper:css.dropdownMenu }} 
                PaperProps={{ style:{ minWidth:anchorEl && anchorEl.offsetWidth? anchorEl.offsetWidth :"auto", maxHeight:"72vh" } }}
                anchorEl={anchorEl}
                open={openFilter}
                onClose={() => handleCloseFilter("reset")}
                getContentAnchorEl={null}
              >
                <div id="filter-list">
                  <div className="flex-box flex-100 flex-col" style={{maxHeight:"72vh",height:"100%",overflow:"hidden"}}>
                    <div className="flex-auto flex-box flex-center head">
                      <Button onClick={()=>{ setFilterExpandIndex(-1); setFilter({...defaultFilter}); setSwitchToken("filter"); }} variant="text">Clear all</Button>
                    </div>
                    <div className="flex-1" style={{overflow:"auto"}}>
                      <div className="MuiList-root MuiMenu-list MuiList-paddings">
                        {Object.keys(filter).map((key, i) => key!=="search" && (
                        <Accordion key={`filter-accordion-${i}`} expanded = {filterExpandIndex === i } >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={()=> setFilterExpandIndex(filterExpandIndex === i ? -1 : i)} aria-controls={`panel${i}a-content`} id={`panel${i}a-header`} >
                            <div className="flex-box flex-gap-1 flex-100 flex-middle">
                              <div className="flex-1">{filter[key].validation.name}</div>
                              {(filter[key] === "historyArrear" || filter[key] === "currentArrear") ?
                                filter[key].value && filter[key].value.condition !== "" && (
                                  <div className="flex-0 flex-box flex-middle"><span className="available"></span></div>
                              ) : filter[key].value && filter[key].value.length > 0 && (
                                  <div className="flex-0 flex-box flex-middle"><span className="available"></span></div>
                              )}
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <UserInputComponents type={filter[key].validation.type} value={filter[key].value} errorShow={errorShow}
                              setValue={(v)=>updateData({keys: [key], dataName:"value", value: v})}
                              updateError={errorComponentUpdate} validation={filter[key].validation} />
                          </AccordionDetails>
                        </Accordion>
                        ))}
                      </div>
                    </div>
                    <div className="flex-auto flex-box flex-col flex-middle flex-center flex-middle foot">
                      <div style={{height: filterExpandIndex === -1 ? 0 : 0}}></div>
                      <Button variant="contained" onClick={updateFilterList} disableElevation>Apply</Button>
                      <div style={{height: filterExpandIndex === -1 ? 0 : 16}}></div>
                    </div>
                  </div>
                </div>
              </Menu>
            </div>
        </div>
      </div>
      <div className="sims-content flex-1 flex-box flex-col">
        <div className="contentbox flex-1 flex-box flex-col">
          {tableOn === "loading" ? (
            <div className="flex-1 flex-box flex-middle flex-center">
              <CircularProgress />
            </div>
          ) : tableList && tableList.length > 0 ? (
            <div className="flex-1">
              <div className="tablearea">
                <table>
                  <thead>
                    <tr>
                      <th style={{width:46}}>S.no</th>
                      <th style={{minWidth:120}}>Name</th>
                      <th style={{minWidth:120}}>Email</th>
                      <th style={{minWidth:88, maxWidth:108}}>Phone</th>
                      <th style={{width:88}}>City</th>
                      <th style={{width:64}}>Blood</th>
                      <th style={{width:78}}>Language</th>
                      <th style={{width:64}}>Gender</th>
                      <th style={{width:96}}>Degree</th>
                      <th style={{minWidth:96, maxWidth:142}}>Department</th>
                      <th style={{width:64}}>College</th>
                      <th style={{width:78}}>CGPA<br />/Percentage</th>
                      <th style={{width:78}}>History of<br />arrear(s)</th>
                      <th style={{width:78}}>Current<br />arrear(s)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableList.map((itemStudent,i)=>(
                    <tr className="rel" key={`tableListItemStudent-${i}`}>
                      <td><div className="rel"><div className="ellips center">{i+1}</div></div></td>
                      <td><div className="rel"><div className="ellips">{itemStudent.firstName && `${itemStudent.firstName} ${itemStudent.lastName}`}</div></div></td>
                      <td><div className="rel"><div className="ellips">{itemStudent.emailId ? `${itemStudent.emailId}`: "-"}</div></div></td>
                      <td><div className="rel"><div className="ellips">{itemStudent.phoneNumber ? `${itemStudent.phoneNumber}` : "-"}</div></div></td>
                      <td><div className="rel"><div className="ellips">{itemStudent.address && itemStudent.address.city ? `${itemStudent.address.city}` : "-"}</div></div></td>
                      <td><div className="rel"><div className="ellips">{itemStudent.bloodGroup ? `${itemStudent.bloodGroup}` : "-"}</div></div></td>
                      <td><div className="rel"><div className="ellips">{itemStudent.languagesKnown && itemStudent.languagesKnown.length>0? itemStudent.languagesKnown.join() :`-`}</div></div></td>
                      <td><div className="rel"><div className="ellips">{itemStudent.gender ? `${itemStudent.gender}` : "-"}</div></div></td>
                      <td><div className="rel"><div className="ellips">{itemStudent.college && itemStudent.college.length>0? (itemStudent.college[itemStudent.college.length-1].degree ? itemStudent.college[itemStudent.college.length-1].degree : `-`) :`-`}</div></div></td>
                      <td><div className="rel"><div className="ellips">{itemStudent.college && itemStudent.college.length>0? (itemStudent.college[itemStudent.college.length-1].department ? `${itemStudent.college[itemStudent.college.length-1].department} ${itemStudent.college[itemStudent.college.length-1].yearOfPassing ? itemStudent.college[itemStudent.college.length-1].yearOfPassing :""}` : `-`) :`-`}</div></div></td>
                      <td><div className="rel"><div className="ellips">{itemStudent.college && itemStudent.college.length>0? (itemStudent.college[itemStudent.college.length-1].collegeName ? itemStudent.college[itemStudent.college.length-1].collegeName : `-`) :`-`}</div></div></td>
                      
                      <td><div className="rel"><div className="ellips"><div className="highlight green">{itemStudent.college && itemStudent.college.length>0? (itemStudent.college[itemStudent.college.length-1].cgpa ? itemStudent.college[itemStudent.college.length-1].cgpa : itemStudent.college[itemStudent.college.length-1].percentage ? itemStudent.college[itemStudent.college.length-1].percentage : `-`) :`-`}</div></div></div></td>
                      <td><div className="rel"><div className="ellips">{itemStudent.college && itemStudent.college.length>0? (itemStudent.college[itemStudent.college.length-1].historyArrear ? itemStudent.college[itemStudent.college.length-1].historyArrear : `0`) :`-`}</div></div></td>
                      <td><div className="rel"><div className="ellips">{itemStudent.college && itemStudent.college.length>0? (itemStudent.college[itemStudent.college.length-1].currentArrear ? itemStudent.college[itemStudent.college.length-1].currentArrear : `0`) :`-`}</div></div></td>
                      <button className="row-button" onClick={()=>{setOpenData(itemStudent); setOpenProfile(true)}}> </button>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            ):(<div className="flex-1 flex-box flex-middle flex-center"><EmptyState label="No student information available" /></div>)}
          {totalItemsCount && totalItemsCount > 0 ? (
            <div className="flex-auto pad">
              <div className="flex-box flex-100 flex-gap-2" style={{padding: "32px 16px 16px 16px" ,fontSize: 14, fontWeight: 500}}>
                <div className="flex-1">
                  <div className="flex-box flex-100 flex-gap-1 flex-middle">
                    <span>Showing</span>
                    <select style={{ height: 30, width: 100, borderRadius: 5, backgroundColor: "#F5F5F5", border: "1px solid lavender"}}
                      value={itemsCountPerPage}
                      onChange={(e) => { setItemsCountPerPage(parseInt(e.target.value))}}
                    >
                        <option selected={itemsCountPerPage === 10}>10</option>
                        <option selected={itemsCountPerPage === 20}>20</option>
                        <option selected={itemsCountPerPage === 30}>30</option>
                        <option selected={itemsCountPerPage === 40}>40</option>
                        <option selected={itemsCountPerPage === 50}>50</option>
                    </select>
                    <span>entries</span>
                  </div>
                </div>
                <div className="flex-1 flex-box flex-100 flex-middle flex-col">
                  <Pagination
                    {...paginationClasses}
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={(n) => setActivePage(n)}
                  />
                  <p style={{textAlign:"center"}}>Showing {((activePage-1) * itemsCountPerPage)+1} to {activePage * itemsCountPerPage} of total {totalItemsCount} entries</p>
                </div>
                <div className="flex-1 flex-box flex-100 flex-middle"></div>
              </div>
            </div>
          ):(<></>)}
        </div>
      </div>
      <Dialog open={openProfile} id="print-resume" onClose={()=>{setOpenProfile(false);setOpenData(null);}}>
        <div className="header flex-box flex-100 flex-middle flex-gap-2">
          <div className="flex-1">
            <h2>Student information</h2>
          </div>
          <div className="flex-auto">
            <button onClick={()=>{setOpenProfile(false);setOpenData(null);}}><span className="bx bx-x"></span></button>
          </div>
        </div>
        <DialogContent>
          {openData && (
          <div className={`content-container ${printStatus==="printing"? "fullpage": ""}`}>
            <div style={{width:"100%", minWidth:"100%", backgroundColor:"#F6F2FF"}}>
            <div style={{width:"100%", minWidth:"100%", backgroundColor:"#F6F2FF"}} ref={ref}>
              <div className="content-bar"></div>
              <div className="resume" style={{height:printHeight}}>
                <div className="section">
                  <div className="flex-box flex-100 flex-gap-2 flex-middle">
                    <div className="flex-1">
                      <div className="profile-image">
                        <img
                          src={avatar}
                          alt={openData.firstName ? `${openData.firstName} ${openData.lastName}` : "name"}
                          className="rounded-circle img-thumbnail"
                        />
                      </div>
                    </div>
                    <div className="flex-2">
                      <h1 className="txt-8 txt-primary">{openData.firstName && `${openData.firstName} ${openData.lastName}`}</h1>
                      <h3 className="txt-7 txt-gray">Reg Number : {openData.registrationNo && `${openData.registrationNo}`}</h3>
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="flex-box flex-100 flex-gap-1 flex-middle">
                    <div className="flex-1">
                      {openData.phoneNumber && (
                      <div className="flex-box flex-gap-1 flex-middle txt-gray txt-3">
                        <PhoneIcon />
                        <span>+91 {`${openData.phoneNumber}`}</span>
                      </div>
                      )}
                    </div>
                    <div className="flex-1">
                      {openData.emailId && (
                      <div className="flex-box flex-gap-1 flex-middle flex-center txt-gray txt-3">
                        <EmailIcon />
                        <span>{`${openData.emailId}`}</span>
                      </div>
                      )}
                    </div>
                    <div className="flex-1">
                      {openData.address && (
                      <div className="flex-box flex-gap-1 flex-middle flex-right txt-gray txt-3">
                        <HomeLocationIcon />
                        <span>{openData.address.city && `${openData.address.city}`} {openData.address.pincode && ` - ${openData.address.pincode}`}</span>
                      </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="section"><div className="br" /></div>
                {openData.careerObjective && (
                  <div className="section">
                    <h2 className="txt-5 txt-primary">Career Objective</h2>
                    <p className="txt-3 txt-black">{`${openData.careerObjective}`}</p>
                  </div>
                )}
                <div className="section">
                  <div className="flex-box flex-100 flex-gap-2">
                    <div className="flex-1">
                    {openData.skills && (
                      <div className="section top">
                        <h2 className="txt-5 txt-primary">Technical Skills</h2>
                        <div className="list">
                          {openData.skills.length > 0 && openData.skills.map((sk,i)=>(<div key={`sk${i}`} className="list-item txt-3 txt-black flex-box flex-gap-1"><div className="flex-1">{`${sk.skills}`}</div><div className="flex-0" style={{whiteSpace:"nowrap"}}>{`${sk.rating}(10)`}</div> </div>))}
                        </div>
                      </div>
                    )}
                    {openData.areaOfInterests && (
                      <div className="section">
                        <h2 className="txt-5 txt-primary">Area of interest</h2>
                        <div className="list">
                          {openData.areaOfInterests && openData.areaOfInterests.length > 0 && openData.areaOfInterests.map((interest,i)=>(<div key={`int${i}`} className="list-item txt-3 txt-black">{`${interest}`} </div>))}
                        </div>
                      </div>
                    )}
                    {openData.personalStrengths && (
                      <div className="section">
                        <h2 className="txt-5 txt-primary">Personal strength</h2>
                        <div className="list">
                          {openData.personalStrengths && openData.personalStrengths.length > 0 && openData.personalStrengths.map((strength,i)=>(<div key={`strength${i}`} className="list-item txt-3 txt-black">{`${strength}`} </div>))}
                        </div>
                      </div>
                    )}
                    {openData.languagesKnown && (
                      <div className="section">
                        <h2 className="txt-5 txt-primary">Languages</h2>
                        <div className="list">
                          {openData.languagesKnown && openData.languagesKnown.length > 0 && openData.languagesKnown.map((lang,i)=>(<div key={`lang${i}`} className="list-item txt-3 txt-black">{`${lang}`} </div>))}
                        </div>
                      </div>
                    )}
                      <div className="section">
                        <h2 className="txt-5 txt-primary">Personal Details</h2>
                        <div className="list">
                          <div className="list-item">
                            <div className="flex-box flex-100 flex-gap-1 txt-3 txt-black">
                              <div className="flex-1">Gender</div>
                              <div> : </div>
                              <div className="flex-60">{openData.gender && `${openData.gender}`}</div>
                            </div>
                          </div>
                          <div className="list-item">
                            <div className="flex-box flex-100 flex-gap-1 txt-3 txt-black">
                              <div className="flex-1">DOB</div>
                              <div> : </div>
                              <div className="flex-60">{openData.dateOfBirth && `${openData.dateOfBirth}`}</div>
                            </div>
                          </div>
                          <div className="list-item">
                            <div className="flex-box flex-100 flex-gap-1 txt-3 txt-black">
                              <div className="flex-1">Blood</div>
                              <div> : </div>
                              <div className="flex-60">{openData.dateOfBirth && `${openData.dateOfBirth}`}</div>
                            </div>
                          </div>
                          <div className="list-item">
                            <div className="flex-box flex-100 flex-gap-1 txt-3 txt-black">
                              <div className="flex-1">State</div>
                              <div> : </div>
                              <div className="flex-60">{openData.address && openData.address.state && `${openData.address.state}`}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {openData.emergencyContact && openData.emergencyContact.length > 0 && (
                      <div className="section">
                        <h2 className="txt-5 txt-primary">Emergency Contact</h2>
                        {openData.emergencyContact.map((contact, i)=>(
                          <div key={`emergencyContactList-${i}`} className="list">
                            <div className="list-item">
                              <div className="flex-box flex-100 flex-gap-1 txt-3 txt-black">
                                <div className="flex-1">Name</div>
                                <div> : </div>
                                <div className="flex-60">{contact.contactName && `${contact.contactName}`}</div>
                              </div>
                            </div>
                            <div className="list-item">
                              <div className="flex-box flex-100 flex-gap-1 txt-3 txt-black">
                                <div className="flex-1">Phone</div>
                                <div> : </div>
                                <div className="flex-60">{contact.phoneNo && `+91 ${contact.phoneNo}`}</div>
                              </div>
                            </div>
                            <div className="list-item">
                              <div className="flex-box flex-100 flex-gap-1 txt-3 txt-black">
                                <div className="flex-1">Relation</div>
                                <div> : </div>
                                <div className="flex-60">{contact.relationship && `${contact.relationship}`}</div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      )}
                      {(openData.profileLink || openData.linkedIn) && (
                      <div className="section">
                        <h2 className="txt-5 txt-primary">Profile</h2>
                        <div className="list group bottom">
                          {openData.profileLink && (
                          <div className="list-item-2">
                            <div className="flex-box flex-100 flex-middle flex-gap-1 txt-3">
                              <div className="flex-auto txt-gray"><i style={{fontSize:18}} class='bx bx-link-alt'></i></div>
                              <div className="flex-1"><a className="txt-black" href={openData.profileLink}>Profile </a></div>
                            </div>
                          </div>)}
                          {openData.linkedIn && (
                          <div className="list-item-2">
                            <div className="flex-box flex-100 flex-middle flex-gap-1 txt-3">
                              <div className="flex-auto txt-gray"><i style={{fontSize:18}} class='bx bxl-linkedin'></i></div>
                              <div className="flex-1"><a className="txt-black" href={openData.linkedIn}>Linkedin </a></div>
                            </div>
                          </div>)}
                        </div>
                      </div>
                      )}
                    </div>
                    <div className="flex-2">
                      <div className="section top">
                        <h2 className="txt-5 txt-primary">Education Details</h2>
                        {openData.sslc && openData.sslc.schoolName && (
                        <div className="group top">
                          <div className="txt-3 txt-black b">{`SSLC - ${openData.sslc.yearOfPassing}`}</div>
                          <div className="txt-2 txt-gray">{openData.sslc.board && `${openData.sslc.board}`}</div>
                          <div className="txt-3 txt-black">{openData.sslc.schoolName && `${openData.sslc.schoolName}`} - {openData.sslc.cgpa ? `CGPA ${openData.sslc.cgpa}` : openData.sslc.percentage && `${openData.sslc.percentage}%`}</div>
                        </div>)}
                        {openData.hsc && openData.hsc.length > 0  && openData.hsc.map((hsc,i)=>(
                        <div key={`hsc${i}`} className="group">
                          <div className="txt-3 txt-black b">{`SSLC - ${hsc.yearOfPassing}`}</div>
                          <div className="txt-2 txt-gray">{hsc.board && `${hsc.board}`}</div>
                          <div className="txt-3 txt-black">{hsc.schoolName && `${hsc.schoolName}`} - {hsc.cgpa ? `CGPA ${hsc.cgpa}` : hsc.percentage && `${hsc.percentage}%`}</div>
                        </div>))}
                        {openData.college && openData.college.length > 0  && openData.college.map((college,i)=>(
                        <div key={`college${i}`} className={`group ${openData.college.length-1 === i ? "bottom" : ""} `}>
                          <div className="txt-3 txt-black b">{`${college.degree} - ${college.yearOfPassing}`}</div>
                          <div className="txt-2 txt-gray">{college.department && `${college.department}`}</div>
                          <div className="txt-3 txt-black">{college.collegeName && `${college.collegeName}`} - {college.cgpa ? `CGPA ${college.cgpa}` : college.percentage && `${college.percentage}%`}</div>
                        </div>))}
                      </div>
                      {openData.projects && openData.projects.length>0 && (
                      <div className="section">
                        <h2 className="txt-5 txt-primary">Projects</h2>
                        {openData.projects.map((projects, i)=>(
                        <div key={`college${i}`} className={`group ${openData.college.length === 0 && "top"} ${openData.college.length-1 === i && "bottom"} `}>
                          <div className="txt-4 txt-black mb-1 b">{projects.title}</div>
                          <div className="txt-3 txt-black mb-1">{projects.description}</div>
                          <div className="txt-2 txt-gray">{projects.technologiesOrToolsUsed.join()}</div>
                        </div>))}
                      </div>)}
                      {openData.certifications && openData.certifications.length>0 && (
                      <div className="section">
                        <h2 className="txt-5 txt-primary">Certification</h2>
                        {openData.certifications.map((certifications, i)=>(
                        <div key={`certifications${i}`} className={`group ${openData.college.length === 0 && "top"} ${openData.college.length-1 === i && "bottom"} `}>
                          <div className="txt-4 txt-black mb-1 b">{certifications.name}</div>
                          <div className="txt-2 txt-gray">{certifications.credentialId}</div>
                        </div>))}
                      </div>)}
                      {openData.workshops && openData.workshops.length>0 && (
                      <div className="section">
                        <h2 className="txt-5 txt-primary">Workshops</h2>
                        {openData.workshops.map((workshop, i)=>(
                        <div key={`workshop${i}`} className={`group ${openData.college.length === 0 && "top"} ${openData.college.length-1 === i && "bottom"} `}>
                          <div className="txt-3 txt-black">{workshop}</div>
                        </div>))}
                      </div>)}
                      {openData.industrialVisit && openData.industrialVisit.length>0 && (
                      <div className="section">
                        <h2 className="txt-5 txt-primary">Industrial Visit</h2>
                        {openData.industrialVisit.map((industrialVisit, i)=>(
                        <div key={`industrialVisit${i}`} className={`group ${openData.college.length === 0 && "top"} ${openData.college.length-1 === i && "bottom"} `}>
                          {/* <div className="txt-4 txt-black mb-1 b">TVS</div> */}
                          <div className="txt-3 txt-black">{industrialVisit}</div>
                        </div>))}
                      </div>)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-bar"></div>
            </div>
            </div>
          </div>)}
        </DialogContent>
        <DialogActions>
          <div className="flex-box flex-100 flex-gap-1 flex-right">
            <Pdf targetRef={ref} options={{unit: 'px',format: [430,900]}}  filename={`${openData && openData.registrationNo ? `${openData.registrationNo}-` : ``}${openData && (openData.firstName || openData.lastName) ? `${openData.firstName}_${openData.lastName}-` : ``}resume.pdf`} onComplete={()=> {setPrintStatus("")}}>
              {({ toPdf }) => (
                <button className="flex-box flex-middle flex-gap-1 buttonGary"
                  onClick={()=> {
                    setPrintHeight(ref.current.clientHeight);
                    setPrintStatus("printing");
                    toPdf();
                  }}>
                  <i class='bx bx-printer'></i>
                  <span className="name">Download</span>
                </button>
              )}
            </Pdf>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog open={openExport} id="print-resume" className="printResume" onClose={()=>{setOpenExport(false)}}>
        <div className="header white flex-box flex-100 flex-middle flex-gap-2">
          <div className="flex-1">
            <h2>{favouriteListAction === "new" ? "Add favourite" : favouriteListAction === "edit" ? "Update favourite" : "Export"}</h2>
          </div>
          <div className="flex-auto">
            <button onClick={()=>{setOpenExport(false)}}><span className="bx bx-x"></span></button>
          </div>
        </div>
        <DialogContent>
          {tableOn === "loadingExport" ? (
            <div className="absolute w-100 h-100 flex-1 flex-box flex-middle flex-center">
              <CircularProgress />
            </div>
          ) : ""}
          <div className="export">
            <div className="flex-box flex-100" style={{marginBottom: 32}}>
              <div className="flex-1">
                {favouriteListAction !== "" ? (
                <div id="profile-page" className="flex-box flex-2 flex-bottom flex-center flex-gap-2">
                  <UserInputComponents type="text" value={favouriteName} errorShow={errorShow}
                    setValue={(v)=>{setFavouriteName(v)}} addError={favouriteInList ? "This name already in favourite list" : ""}
                    updateError={errorComponentUpdate} validation={{...favourite.favourite.validation, placeholder:"outer", name:"Favourite name", req:true}} />
                  <div className="flex-0">
                    <Button variant="outline" color="success" className="buttonGary" onClick={()=>setFavouriteListAction("")}>Cancel</Button>
                    {favouriteInList ? (<p className="errorTxt"> </p>) : ""}
                  </div>
                </div>
                ) : (
                <div id="profile-page" className="flex-box flex-2 flex-middle flex-center flex-gap-2">
                  <UserInputComponents type="select" value={favourite.favourite.value} errorShow={errorShow}
                    setValue={(v)=>{updateFavourite({keys: ["favourite"], dataName:"value", value: v});getFavouriteName(v)}}
                    updateError={errorComponentUpdate} validation={favourite.favourite.validation} />
                  <div className="flex-0">
                      <Button variant="outline" color="success" className="buttonOutlineSuccess" onClick={()=>setFavouriteListAction(favourite.favourite.value === "" ? "new" : "edit")}>{favourite.favourite.value !== "" ? "Edit" : "Add New" }</Button>
                  </div>
                  {favourite.favourite.value !== "" && (
                  <div className="flex-0">
                      <Button variant="outline" color="success" className="buttonOutlineError" onClick={()=>deleteFavourite()}>Delete</Button>
                  </div>
                  )}
                </div>
                )}
              </div>
              <div className="flex-1"></div>
            </div>
            <div className="flex-box flex-100 flex-col">
              <h3>Personal</h3>
              <FormGroup onChange={(e)=> handleCheckBox(e.target.value)}>
                <div className="flex-box flex-100 flex-wrap flex-gap-1">
                  {personal.map((list, i) => (
                    <div className="favouriteList">
                      <FormControlLabel
                        value={list.id}
                        control={<Checkbox color="primary" checked={favouriteList.indexOf(list.id) > -1} />}
                        label={list.nameId}
                        labelPlacement="end"
                      />
                    </div>
                  ))}
                </div>
              </FormGroup>
              <h3>Emergency contact</h3>
              <FormGroup onChange={(e)=> handleCheckBox(e.target.value)}>
                <div className="flex-box flex-100 flex-wrap flex-gap-1">
                  {emergency.map((list, i) => (
                    <div className="favouriteList">
                      <FormControlLabel
                        value={list.id}
                        control={<Checkbox color="primary" checked={favouriteList.indexOf(list.id) > -1} />}
                        label={list.nameId}
                        labelPlacement="end"
                      />
                    </div>
                  ))}
                </div>
              </FormGroup>
              <h3>Educational Details</h3>
              <FormGroup onChange={(e)=> handleCheckBox(e.target.value)}>
                <div className="flex-box flex-100 flex-wrap flex-gap-1">
                  {education.map((list, i) => (
                    <div className="favouriteList">
                      <FormControlLabel
                        value={list.id}
                        control={<Checkbox color="primary" checked={favouriteList.indexOf(list.id) > -1} />}
                        label={list.nameId}
                        labelPlacement="end"
                      />
                    </div>
                  ))}
                </div>
              </FormGroup>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {favouriteListAction !== "" ? (
            <div className="flex-box flex-100 flex-middle flex-center flex-gap-2">
              <Button variant="outline" color="success" className="buttonGary" onClick={()=>setFavouriteListAction("")}>Cancel</Button>
              <Button variant="outline" color="success" className="buttonPrimary" disabled={!(favouriteName && favouriteName.length > 2) || favouriteInList} onClick={()=>addUpdateFavourite()}>{favouriteListAction === "edit" ? "Update favourite" : "Add favourite"}</Button>
            </div>
          ):(
            <div className="flex-box flex-100 flex-middle flex-center flex-gap-2">
              <button className="flex-box flex-middle buttonGary" onClick={()=> resetFavourite()}>
                <span className="name">Reset</span>
              </button>
              <button className="flex-box flex-middle buttonPrimary" onClick={()=> exportDocs()}>
                <span className="name">Export</span>
              </button>
            </div>
          )}
        </DialogActions>
      </Dialog>
      <ToastContainer autoClose={3500} />
    </div>
    )}
    </>
  );
}

const mapStateToProps = (state) => ({
  Customers: state.StudentAndCustomerReducer.Customers,
});

export default connect(mapStateToProps, null)(SimStudentsList);