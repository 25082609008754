import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import styles from "./DeleteModule.module.scss";
import * as CustomIcons from "../assets/icons/icons";

const paraStyle = {
  fontSize: 15,
  fontWeight: 500,
  color: "#5B5A5F",
  marginTop: 12,
  textAlign: "center",
};

export function DeleteModule({ Header, Close, Delete, isOpen, Name = "Item" }) {
  return (
    <Dialog open={isOpen} onClose={Close}>
      <DialogContent classes={{ root: styles.dialogContent }}>
        <div className={styles.dialogMain}>
          <span>{CustomIcons.deleteModalIcon}</span>
          <div className={styles.dialogTitle}>Delete {Name}</div>
        </div>
        <p style={paraStyle}>
          <div>Are you sure want to delete this {Header} ?</div>
          <div>This action is irreversible.</div>
        </p>
      </DialogContent>
      <DialogActions classes={{ root: styles.dialogActions }}>
        <Button
          variant="outlined"
          onClick={Close}
          disableElevation
          classes={{ root: styles.buttonNo }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            Delete();
            Close();
          }}
          disableElevation
          classes={{ root: styles.buttonYes }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}


