import apiHelper from "api/apiHelper";
import api from "api/baseConfig";
import { DefaultToast } from "components/DefaultToast";
import { TrimString } from "pages/utils/TrimString";
import React, { useState, useEffect } from "react";
import RoleManagementDumb from "./RoleManagementDumb";

function RoleManagementSmart(props) {
  const [roles, setRoles] = useState([]);
  const [privilegesByRole, setPrivilegesByRole] = useState({
    mods: [],
    data: {},
  });
  const [privileges, setPrivileges] = useState([]);
  const [privilegeValue, setPrivilegeValue] = useState(false);

  useEffect(() => {
    getAllRoles();
  }, []);

  const customerData = sessionStorage.getItem("customer_id");
  const [existingData, setExistingData] = useState({});

  const checkIfRoleExists = async (roleName) => {
    try {
      const res = await apiHelper.roleValidation(
        `${api.baseURL}${api.roleManagement.checkRoleAlreadyCreated}${customerData}/${roleName}`
      );
      return res;
    } catch (e) {}
  };

  const getAllRoles = async () => {
    const url = TrimString(
      `${api.baseURL}${api.roleManagement.getAllRoles}${
        props?.location?.state?.customer ||
        props?.location?.state?.row?.customerId ||
        sessionStorage.getItem("customer_id")
      }`
    );
    try {
      const data = await apiHelper.axiosCall(url);
      if (data && data.length > 0) {
        setRoles(data.map((datum) => ({ label: datum, value: datum })));
      }
    } catch (e) {}
  };

  const getPrivilegesByRole = async (role) => {
    const url = TrimString(
      `${api.baseURL}${api.roleManagement.getPrivileges}${role}/${
        props?.location?.state?.customer ||
        props?.location?.state?.row?.customerId ||
        sessionStorage.getItem("customer_id")
      }`
    );
    try {
      const privs = await apiHelper.axiosCall(url);

      if (privs) {
        const keyList = [];
        for (const key in privs) {
          if (privs[key] && privs[key]?.length > 0) {
            keyList.push(key);
          }
        }
        setPrivilegesByRole({
          mods: keyList,
          data: privs,
        });
        setExpandedItems(
          keyList.reduce((acc, val) => {
            acc[val] = false;
            return acc;
          }, {})
        );
      }
    } catch (e) {}
  };

  const createRole = async (roleData) => {
    if(privileges?.length === 0) {
        setPrivilegeValue(true);
    }
    else {
    setPrivilegeValue(false);
    const data = {
      ...roleData,
      active: true,
      customerName:
        props?.location?.state?.customerName ||
        props?.location?.state?.row?.customerName ||
        sessionStorage.getItem("customer_name"),
      privileges:
        roleData.roleType === "LEARNER" ? [...privileges] : privileges,
    };

    try {
      await apiHelper.axiosCallPost(
        `${api.baseURL}${api.roleManagement.createRole}`,
        "post",
        data
      );
      DefaultToast({ 
      message: "Successfully created Role!",
      style: {backgroundColor:"#1ba94c", color:"#ffffff"},
      position: "top-right", 
     });
      props.history.push({
        pathname: "/roleList",
        state: {
          customer:
            props?.location?.state?.customer ||
            props?.location?.state?.row?.customerId ||
            sessionStorage.getItem("customer_id"),
        },
      });
    } catch (e) {}
  }
  };

  return (
    <RoleManagementDumb
      roles={roles}
      privilegesByRole={privilegesByRole}
      getPrivilegesByRole={getPrivilegesByRole}
      createRole={createRole}
      editData={props?.location?.state}
      setPrivilegeValue={setPrivilegeValue}
      privilegeValue={privilegeValue}
      privileges={privileges}
      setPrivileges={setPrivileges}
      existingData={existingData}
      customerData={customerData}
      setExistingData={setExistingData}
      checkIfRoleExists={checkIfRoleExists}
      customer={props?.location?.state?.customer}
      activePage={props?.location?.state?.activePage}
      itemsPerPage={props?.location?.state?.itemsPerPage}
    />
  );
}

export default RoleManagementSmart;
