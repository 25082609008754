import {
  BXVideos,
  BXBookOpen,
  BXLink,
  BXMic,
  BXNote,
  BXLive,
  FALaptop,
  BXScrom,
  reject,
} from "./SVGs";
import { boxArrowRight, paperPlane, checkIcon } from "assets/icons/icons";

export const defaultFormValues = {
  formValue1: {},
  formValue2: {},
  formValue3: {},
  formValue4: {},
  formValue5: {},
};

export const LANGUAGES = [
  { label: "Tamil", value: "tamil" },
  { label: "English", value: "english" },
  { label: "Telugu", value: "telugu" },
];

export const GENDER = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" },
];

export const MOTHERTONGUE = [
  { label: "Tamil", value: "tamil" },
  { label: "Telugu", value: "telugu" },
  { label: "Kannada", value: "kannada" },
  { label: "Malayalam", value: "malayalam" },
  { label: "English", value: "english" },
  { label: "Hindi", value: "hindi" },
  { label: "Marathi", value: "marathi" },
  { label: "Bengali", value: "bengali"},
  { label: "others", value: "others" },
];
export const LEVELS = [
  { label: "Beginner", value: "beginner" },
  { label: "Intermediate", value: "intermediate" },
  { label: "Advanced", value: "advanced" },
];
export const Winner = [
  { label: "1st", value: "first" },
  { label: "2nd", value: "second" },
  { label: "3rd", value: "third" },
];
export const DOCUMENTTYPE = [
  { label: "PDF", value: "pdf" },
  { label: "PPT", value: "ppt" },
  { label: "Words", value: "words" },
];
export const COURSETAG = [
  { label: "Feature", value: "feature" },
  { label: "Premium", value: "premium" },
  { label: "Latest", value: "latest" },
  { label: "Special", value: "special" },
];
export const LessonIcons = [
  {
    label: "Video",
    icon: BXVideos,
    value: { aka: "video", mime: "video/*", isMedia: true },
  },
  {
    label: "Study material",
    icon: BXBookOpen,
    value: {
      aka: "doc",
      mime: "application/*",
      isMedia: true,
      pdf: "application/pdf",
    },
  },
  { label: "Link", icon: BXLink, value: { aka: "link", mime: "link" } },
  { label: "Scrom", icon: BXScrom, value: { aka: "link", mime: "link" } },
  {
    label: "Audio",
    icon: BXMic,
    value: { aka: "audio", mime: "audio/*", isMedia: true },
  },
  { label: "Assessment", icon: BXNote, value: { aka: "a/q", mime: "" } },
  // { label: "Live class", icon: BXLive, value: { aka: "live", mime: "live" } },
  { label: "Practice lab", icon: FALaptop, value: { aka: "lab", mime: "" } },
  // { label: "Practice lab", icon: FALaptop, value: { aka: "lab", mime: "" } },
];

export const CourseSettings = [
  {
    label: "COURSE SETTINGS",
    fields: [
      {
        fieldHeader: "Course Review",
        fieldDesc: "Learners can review the course",
        name: "review",
      },
      {
        fieldHeader: "Retake/Rewatch Course",
        fieldDesc: "Learners can retake the course after completion",
        name: "rewatch",
      },
    ],
  },
  {
    label: "COURSE RESTRICTIONS",
    fields: [
      {
        fieldHeader: "Section Restriction",
        fieldDesc:
          "Learners can get access to next section after completion of previous section",
        name: "sectionRestrict",
      },
      // {
      //   fieldHeader: "Content restriction",
      //   fieldDesc:
      //     "Learners can get access to next content after completion of previous content",
      //   name: "contentRestrict",
      // },
      // {
      //   fieldHeader: "Practice lab",
      //   fieldDesc: "Learners can get access to lab content for one time",
      //   name: "practiceLab",
      // },
      // {
      //   fieldHeader: "Assessment",
      //   fieldDesc:
      //     "Learners must pass the assessment to get access to next content",
      //   name: "assessment",
      // },
    ],
  },
  {
    label: "FEATURES",
    fields: [
      {
        fieldHeader: "Enable Discussion",
        fieldDesc: "Course discussion form will be enabled",
        name: "enableDiscussion",
      },
      {
        fieldHeader: "Enable Notes",
        fieldDesc: "Learners can take notes in this course",
        name: "enableNotes",
      },
      {
        fieldHeader: "Enable Skip Content",
        fieldDesc: "Learners can skip content",
        name: "enableSkipContent",
      },
    ],
  }
  // {
  //   label: "",
  //   fields: [
  //     {
  //       fieldHeader: "Bidyut",
  //       fieldDesc: "Add this course to bidyut",
  //       name: "enableBidyut",
  //     },
  //   ],
  // },
];

export const statusValues = {
  approved: {
    tooltip: "Move to Draft",
    statusTo: "draft",
    moveToIcon: boxArrowRight("#794df5"),
  },
  draft: {
    tooltip: "Send for Approval",
    statusTo: "pending",
    moveToIcon: paperPlane("#794df5"),
  },
  pending: {
    tooltip: "Approve Course",
    statusTo: "published",
    moveToIcon: checkIcon("#794df5"),
  },
  reject: {
    tooltip: "Reject Course",
    statusTo: "rejected",
    moveToIcon: reject,
  },
  rejected: {
    tooltip: "Move to Draft",
    statusTo: "draft",
    moveToIcon: boxArrowRight("#794df5"),
  },
};
