import { sessionPrivileges } from "privileges";
import {
  customerMgmt,
  masterIcon,
  notePadIcon,
  packageIcon,
  speedometer,
  fileReportAlt,
  BookreaderBX,
  utilityIcon,
  cloudLab,
  Hackathon,
  simsIcon,
} from "assets/icons/icons";
import { useEffect, useState } from "react";
import ApiHelper from "../api/apiHelper";
import api from "../api/baseConfig";

const Links = () => {
  const userPrivileges = sessionPrivileges();
  const [userDataValue, setUserDataValue] = useState("");

  useEffect(() => {
      const userId = userPrivileges.userName;
      getUserById(userId);
  }, []);

  const getUserById = async (id) => {
    try {
      const userData = await ApiHelper.axiosCallAuth(
        `${api.baseURL}${api.userManagement.getUserByEmail}${id}`
      );
      setUserDataValue(userData?.customer?.entitlement);
      return userData;
    } catch (e) {
      console.log(e);
    }
  };


  return [
    {
      label: "Dashboard",
      shouldAppear: userPrivileges.showDashboard,
      to: "/dashboard",
      icon: speedometer,
      isActive: ["/dashboard"],
    },
    {
      label: "Prepare Management",
      shouldAppear: userPrivileges.isAdmin ? userDataValue?.prepare : userPrivileges.showPrepare,
      to: "/view-prepare",
      icon: BookreaderBX({ size: 24, color: "#fff" }),
      isActive: ["/view-prepare", "/manage-prepare", "/prepare-report"],
    },
    {
      label: "Practice Packages",
      shouldAppear: userPrivileges.isAdmin ? userDataValue?.practice : userPrivileges.showPracticePackages,
      to: "/manage-allLabs",
      icon: packageIcon,
      isActive: ["/manage-allLabs", "/manage-lab", "/practice-report"],
    },
    {
      label: "Manage Assessments",
      id: "manageAssessments",
      shouldAppear: userPrivileges.isAdmin ? userDataValue?.assessment :
        userPrivileges.showAssessments ||
        userPrivileges.showQuestionBanks ||
        userPrivileges.showQPList,
      isExpandable: true,
      icon: notePadIcon,
      isActive: [
        "/assessments",
        "/create-question-bank",
        "/create-question-paper",
        "/create-assessment",
        "/edit-assessment",
        "/manual-grading",
        "/resume-grading",
        "/view-questions",
        "/viewquestion",
        "/create-question",
        "/create-new-qb",
        "/edit-question",
        "/create-qp",
        "/report",
      ],
      nested: [
        {
          label: "Assessments",
          shouldAppear: userPrivileges.showAssessments,
          to: "/assessments",
          isActive: [
            "/assessments",
            "/create-assessment",
            "/edit-assessment",
            "/report",
            "/manual-grading",
            "/resume-grading",
          ],
        },
        {
          label: "Question Papers",
          shouldAppear: userPrivileges.showQPList,
          to: "/create-question-paper",
          isActive: ["/create-question-paper", "/create-qp"],
        },
        {
          label: "Question Banks",
          shouldAppear: userPrivileges.showQuestionBanks,
          to: "/create-question-bank",
          isActive: [
            "/create-question-bank",
            "/view-questions",
            "/viewquestion",
            "/create-question",
            "/create-new-qb",
            "/edit-question",
          ],
        },
      ],
    },
    {
      label: "Hackathon",
      id: "Hackathon",
      shouldAppear: userPrivileges.isSuperAdmin,
      to: "/Hackathon",
      icon: Hackathon,
      isActive: [
        "/Hackathon",
        "/hackathon-form",
        "/hackathonReport",
        "/HackathonGrading",
      ],
    },
    // {
    //   label: "Cloud Lab",
    //   id: "Cloud lab",
    //   shouldAppear: userPrivileges.isSuperAdmin,
    //   to: "/cloudLab",
    //   icon: cloudLab,
    //   isActive: ["/cloudLab", "/cloudLabForm"],
    // },
    {
      label: "Customer Management",
      id: "manageCustomer",
      shouldAppear:
        userPrivileges.showCustomerList ||
        userPrivileges.showUserList ||
        userPrivileges.showRoles,
      isExpandable: true,
      icon: customerMgmt,
      isActive: [
        "/customerList",
        "/customerManagement",
        "/userList",
        "/userManagement",
        "/roleList",
        "/roleManagement",
        "/customBatchList",
        "/custombatchManagement",
      ],
      nested: [
        {
          label: "Customer",
          shouldAppear: userPrivileges.showCustomerList,
          to: "/customerList",
          isActive: ["/customerList", "/customerManagement"],
        },
        {
          label: "User",
          shouldAppear: userPrivileges.showUserList,
          to: "/userList",
          isActive: ["/userList", "/userManagement"],
        },
        {
          label: "Custom Batch",
          shouldAppear: true,
          to: "/customBatchList",
          isActive: ["/customBatchList", "/custombatchManagement"],
        },
        {
          label: "Roles",
          shouldAppear: userPrivileges.showRoles,
          to: "/roleList",
          isActive: ["/roleList", "/roleManagement"],
        },
      ],
    },
    {
      label: "Master",
      id: "master",
      shouldAppear: userPrivileges.isSuperAdmin,
      // shouldAppear: userPrivileges.showCompetency,
      isExpandable: true,
      icon: masterIcon,
      isActive: [
        "/competency",
        "/create-competency",
        "/edit-competency/",
        "/create-sub-competency",
        "/edit-sub-competency",
        "/domain",
        "/create-domain",
        "/create-sub-domain",
        "/edit-sub-domain",
        "/proficiency",
        "/edit-proficiency",
        "/create-proficiency",
        "/taxonomy",
        "/edit-taxonomy",
        "/create-Taxonomy",
        "/create-sub-taxonomy",
        "/edit-sub-taxonomy",
        "/category",
        "/create-category",
        "/edit-category",
        "/create-topic",
        "/edit-topic",
        "/configuration",
      ],
      nested: [
        {
          label: "Competency",
          shouldAppear: true,
          to: "/competency",
          isActive: ["/competency", "/create-competency"],
        },
        {
          label: "Domain",
          shouldAppear: true,
          to: "/domain",
          isActive: ["/domain", "/create-domain"],
        },
        {
          label: "Proficiency",
          shouldAppear: true,
          to: "/proficiency",
          isActive: ["/proficiency", "/create-proficiency"],
        },
        {
          label: "Taxonomy",
          shouldAppear: true,
          to: "/taxonomy",
          isActive: ["/taxonomy", "/create-Taxonomy"],
        },
        {
          label: "Category",
          shouldAppear: true,
          to: "/category",
          isActive: ["/category", "/create-category"],
        },
        {
          label: "Configuration",
          shouldAppear: userPrivileges.isConfiguration,
          to: "/configuration",
          isActive: ["/configuration"],
        },
      ],
    },
    {
      label: "Report",
      id: "report",
      shouldAppear:
        userPrivileges.showStudentsReport ||
        userPrivileges.showStudentReport ||
        userPrivileges.showActiveUserList,
      isExpandable: true,
      icon: fileReportAlt,
      isActive: [
        "/activeUsersList",
        "/students-report",
        "/student-report",
        "/overall-report",
        "/purchase-report",
      ],
      nested: [
        {
          label: "Active Users List",
          shouldAppear: userPrivileges.showActiveUserList,
          to: "/activeUsersList",
          isActive: ["/activeUsersList"],
        },
        {
          label: "Students Report",
          shouldAppear:
            userPrivileges.showStudentsReport ||
            userPrivileges.showStudentReport,
          to: "/students-report",
          isActive: ["/students-report", "/student-report"],
        },
        {
          label: "Overall Report",
          shouldAppear: userPrivileges.showActiveUserList,
          to: "/overall-report",
          isActive: ["/overall-report"],
        },
        {
          label: "Purchase Report",
          shouldAppear: userPrivileges.isSuperAdmin,
          to: "/purchase-report",
          isActive: ["/purchase-report"],
        },
        {
          label: "Enquiry report",
          shouldAppear: userPrivileges.isSuperAdmin,
          to: "/enquiry-report",
          isActive: ["/enquiry-report"],
        },
        // {
        //   label: "User activity log",
        //   shouldAppear: userPrivileges.isSuperAdmin,
        //   to: "/user-activity-log",
        //   isActive: ["/user-activity-log"],
        // },
      ],
    },
    {
      label: "Utility",
      id: "utility",
      shouldAppear: userPrivileges.isSuperAdmin || userPrivileges.isAdmin,
      isExpandable: true,
      icon: utilityIcon,
      isActive: [
        "/userAssessmentMarks",
        "/liveAssessment",
        "/communication",
        "/composeMail",
      ],
      nested: [
        {
          label: "Communication",
          shouldAppear: userPrivileges.isSuperAdmin || userPrivileges.isAdmin,
          to: "/communication",
          isActive: ["/communication", "/composeMail"],
        },
        {
          label: "Assessment Report",
          shouldAppear: userPrivileges.isSuperAdmin,
          to: "/userAssessmentMarks",
          isActive: ["/userAssessmentMarks"],
        },
        {
          label: "Live Assessment",
          shouldAppear: userPrivileges.isSuperAdmin || userPrivileges.isAdmin,
          to: "/liveAssessment",
          isActive: ["/liveAssessment"],
        },
      ],
    },
    {
      label: "SIMS",
      id: "sims",
      shouldAppear: userPrivileges.isSuperAdmin,
      to: "/sims",
      icon: simsIcon,
      isActive: ["/sims"],
    },
  ];
};
export default Links;
