import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { CircularProgress } from "@material-ui/core";
import PracticeCard from "../virtualLab/lab-components/card";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";
import styles from "./index.module.scss";
import { sessionPrivileges } from "privileges";

function getMoney(amount) {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(parseFloat(amount).toFixed(2));
}

function Cart() {
  const userPrivileges = sessionPrivileges();
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState();

  async function getCart() {
    setLoading(true);

    await Apihelper.axiosCall(
      `${api.baseURL}${api.cart.get(userPrivileges.userId)}`,
      "get"
    )
      .then((data = []) => {
        let totalPrice = 0;

        const pcs = data.map((d, index) => {
          totalPrice +=
            d?.discountAmount === 100
              ? 0
              : d?.strikingPrice || d?.originalPrice || 0;

          if (!d.media) {
            d.imageIndex = index + 1 > 10 ? 10 % (index + 1) : index + 1;
          }

          return d;
        });

        setCartItems(
          pcs.sort(({ tags: t1 }, { tags: t2 }) => {
            const f1 = t1 === "featured";
            const f2 = t2 === "featured";

            return f1 === f2 ? 0 : f1 ? -1 : 1;
          })
        );
        setTotalAmount(totalPrice);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }

  async function removeFromCart(id) {
    return Apihelper.axiosCallPost(
      `${api.baseURL}${api.cart.remove(id)}?userId=${userPrivileges.userId}`,
      "post"
    )
      .then(() => {
        getCart();
      })
      .catch(() => false);
  }

  useEffect(() => {
    getCart();
  }, []);

  return (
    <div className={styles.page}>
      <h2 className={styles.pageTitle}>My Cart</h2>
      <div className={styles.pageContent}>
        {loading ? (
          <div style={{ margin: "auto" }}>
            <div className={styles.noPackagesContainer}>
              <div className={styles.noPackages}>
                <CircularProgress color="primary" variant="indeterminate" />
                <br />
                <span>LOADING...</span>
              </div>
            </div>
          </div>
        ) : cartItems.length ? (
          <>
            <div className={styles.packagesDetails}>
              {cartItems.map((practiceCard) => (
                <PracticeCard
                  className={styles.card}
                  completedQuestions={practiceCard?.questionCount}
                  description={practiceCard?.description}
                  discountAmount={practiceCard?.discountAmount}
                  endDate={practiceCard.endDate}
                  featured={practiceCard?.featured}
                  id={practiceCard.id}
                  imageIndex={practiceCard?.imageIndex}
                  imageUrl={practiceCard?.media}
                  key={practiceCard.id}
                  onRemoveFromCartButtonClick={removeFromCart}
                  originalPrice={practiceCard?.originalPrice}
                  progress={parseInt(practiceCard?.progress)}
                  strikingPrice={practiceCard?.strikingPrice}
                  tag={practiceCard?.tags}
                  title={practiceCard?.name}
                  totalQuestions={practiceCard?.questionCount}
                />
              ))}
            </div>
            <div className={styles.orderDetails}>
              <div className={styles.orderDetailsContent}>
                <h2 className={styles.title}>{"Checkout"}</h2>
                <p className={styles.description}>
                  {`${cartItems?.length} packages in this cart`}
                </p>
                {cartItems.slice(0, 2).map((practiceCard) => (
                  <div className={styles.summaryPracticeCard}>
                    <img
                      src={
                        practiceCard?.media
                          ? practiceCard?.media?.includes("default")
                            ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${practiceCard?.media}.jpeg`
                            : practiceCard?.media
                          : `https://d2ndap9rlps54r.cloudfront.net/PracticePackages/image-${practiceCard?.imageIndex}.jpg`
                      }
                    />
                    <div className={styles.summaryPracticeCardTexts}>
                      <div className={styles.summaryPracticeCardName}>
                        {practiceCard?.name}
                      </div>
                      <div className={styles.summaryPracticeCardPrice}>
                        <div
                          className={styles.summaryPracticeCardOriginalPrice}
                        >
                          {getMoney(
                            practiceCard?.discountAmount === 100
                              ? 0
                              : practiceCard?.strikingPrice ||
                                  practiceCard?.originalPrice ||
                                  0
                          )}
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        {practiceCard?.strikingPrice > 0 &&
                          practiceCard?.discountAmount > 0 && (
                            <div
                              className={
                                styles.summaryPracticeCardStrikingPrice
                              }
                            >
                              {getMoney(practiceCard?.originalPrice || 0)}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ))}
                <div className={styles.overview}>
                  {!!totalAmount && (
                    <>
                      <span className={styles.overviewTotalText}>
                        Total:&nbsp;&nbsp;&nbsp;
                      </span>
                      <span className={styles.overviewTotal}>
                        {getMoney(totalAmount)}
                      </span>
                    </>
                  )}
                </div>
                {!!totalAmount && <br />}
                <Link to="/checkout">
                  <Button
                    color="primary"
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      height: 40,
                      width: "100%",
                    }}
                  >
                    {"Checkout"}
                  </Button>
                </Link>
              </div>
            </div>
          </>
        ) : (
          <div style={{ margin: "auto" }}>
            <div className={styles.noPackagesContainer}>
              <div className={styles.noPackages}>
                <svg width="136" height="85" viewBox="0 0 136 85" fill="none">
                  <path
                    d="M68 72C95.062 72 117 67.0751 117 61C117 54.9249 95.062 50 68 50C40.938 50 19 54.9249 19 61C19 67.0751 40.938 72 68 72Z"
                    fill="#CAC7C7"
                  />
                  <path
                    d="M103.292 32.4106L87.7421 14.9137C86.9958 13.7211 85.9061 13 84.7582 13H51.3265C50.1786 13 49.0889 13.7211 48.3425 14.9122L32.793 32.4121V46.4665H103.292V32.4106Z"
                    stroke="#BBBBBB"
                  />
                  <path
                    d="M82.775 37.2343C82.775 34.7928 84.2984 32.7772 86.1881 32.7756H103.292V60.3657C103.292 63.5952 101.269 66.2421 98.7706 66.2421H37.3141C34.816 66.2421 32.793 63.5937 32.793 60.3657V32.7756H49.8966C51.7862 32.7756 53.3096 34.7882 53.3096 37.2297V37.2632C53.3096 39.7047 54.8499 41.6762 56.738 41.6762H79.3466C81.2348 41.6762 82.775 39.6865 82.775 37.2449V37.2343Z"
                    fill="#F0F0F0"
                    stroke="#B9B9B9"
                  />
                </svg>
                <span>Cart is Empty</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default withRouter(Cart);
