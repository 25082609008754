import axios from "axios";
import moment from "moment";
import { apiList } from "./list";
import Cookies from "universal-cookie";

function getToken() {
  const cookies = new Cookies();
  return cookies.get("userToken");
}

export async function getData(url: string): Promise<any> {
  const userToken = getToken();
  const res = await axios({
    url,
    method: "GET",
    headers: {
      ...(userToken && { authorization: `Bearer ${userToken}` }),
    },
  }).catch(() => ({ data: undefined }));

  return res?.data;
}
export async function getDataAuth(url: string): Promise<any> {
  const userToken = getToken();
  const res = await axios({
    url,
    method: "GET",
  }).catch(() => ({ data: undefined }));

  return res?.data;
}
export async function postData(url: string, data: any): Promise<any> {
  const userToken = getToken();
  const res = await axios({
    url,
    method: "POST",
    headers: {
      ...(userToken && { authorization: `Bearer ${userToken}` }),
    },
    data,
  }).catch((e) => {
    throw new Error(`Some error occured ${e}`);
  });

  return res?.data;
}

type TActivityType =
  | "viewed-all-practice-packages"
  | "viewed-all-assessments"
  | "viewed-practice-package"
  | "viewed-practice-package-report"
  | "viewed-assessment-report"
  | "started-practice-lab"
  | "started-assessment"
  | "viewed-dashboard"
  | "tried-practice-package";

export async function trackActivity(
  id: string,
  name: string,
  type: TActivityType
): Promise<any> {
  const userToken = getToken();
  const res = await axios({
    url: apiList.activityTracker,
    method: "POST",
    headers: {
      ...(userToken && { authorization: `Bearer ${userToken}` }),
    },
    data: {
      accessTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
      productId: id,
      productName: name,
      productType: type,
      sessionId: sessionStorage.getItem("session_id"),
    },
  }).catch(() => ({ data: undefined }));

  return res?.data;
}
