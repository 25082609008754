import useSWR from "swr";
import { GetCustomers } from "./GetCustomers";
import api from "../api/baseConfig";

export const useGetCustomers = (type?: string | undefined) => {
  const { data, error } = useSWR(
    `${api.baseURL}${api.customerManagement.getAll}`,
    GetCustomers,
    { dedupingInterval: 600000, refreshInterval: 600000 }
  );

  return error
    ? `Error occured ${error}`
    : !data
    ? "Fetching..."
    : type === "raw"
    ? data
    : data.map((datum: any) => ({ label: datum.name, value: datum.id }));
};
