import React, { useState, useEffect } from "react";
import CustomBatchDumb from "./CustomBatchDumb";
import apiHelper from "api/apiHelper";
import api from "api/baseConfig";
import { sessionPrivileges } from "privileges";
import { GetAllUsers } from "pages/CustomerManagement/utils/GetUsers";
import { GetAllUsersID } from "pages/CustomerManagement/utils/GetAllUsersId";
import { parseRawStudentData } from "./../utils/ParseRawStudentData";
import { useSelector, connect } from "react-redux";
import { DefaultToast } from "components/DefaultToast";

function CustomBatchManagementSmart(props) {
  const userPrivileges = sessionPrivileges();
  const [customerStudents, setCustomerStudents] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [assessments, setAssessments] = useState([]);
  const [packages, setPackages] = useState([]);

  const addFilterBy = [
    { label: "Assessment", value: "assessment" },
    { label: "Practice Package", value: "practicePackage" },
    { label: "None", value: "none" },
  ];
  const filterByPercentage = [
    { label: "75% - 100%", value: 1 },
    { label: "50% - 75%", value: 2 },
    { label: "25% - 50%", value: 3 },
    { label: "0% - 25%", value: 4 },
  ];

  const activePage = props?.location?.state?.activePage;
  const itemsPerPage = props?.location?.state?.itemsPerPage;

  const adminStudents = useSelector((state) =>
    state.StudentAndCustomerReducer.UserStudents
      ? parseRawStudentData(
          state.StudentAndCustomerReducer.UserStudents.students
        )
      : []
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userPrivileges.isSuperAdmin && props?.location?.state?.customer) {
      getStudentsByCustomer(props?.location?.state?.customer);
    }

    if (customerId) {
      getDepartments(customerId);
      getAssessmentsByCustomer(customerId);
      getLabsByCustomer(customerId);
    }
  }, []);

  const getStudentsByCustomer = async (id) => {
    const users = await GetAllUsersID(id);
    setCustomerStudents(parseRawStudentData(users));
  };

  const customerId = userPrivileges.isAdmin
    ? sessionStorage.getItem("customer_id")
    : props?.location?.state?.customer;

  const createBatch = async ({
    batchName,
    userIdList,
    addFilter,
    selectAssessment,
    selectPackage,
    department,
    regNoFrom,
    regNoTo,
  }) => {
    const payload = {
      batchName,
      userIdList,
      collegeId: customerId,
      addFilter,
      selectAssessment,
      selectPackage,
      department,
      regNoFrom,
      regNoTo,
    };
    try {
      await apiHelper.axiosCallPost(
        `${api.baseURL}${api.customBatchMgmt.create}`,
        "post",
        payload
      );
      props.history.push({
        pathname: "/customBatchList",
        state: { customer: props?.location?.state?.customer || "" },
      });
      DefaultToast({
        message: "Successfully created",
        style: {backgroundColor:"#1ba94c", color:"#ffffff"},
        position: "top-right",
      });
    } catch (error) {}
  };
  const updateBatch = async ({
    batchName,
    userIdList,
    id,
    addFilter,
    selectAssessment,
    selectPackage,
    department,
    regNoFrom,
    regNoTo,
  }) => {
    const payload = {
      batchName,
      userIdList,
      collegeId: customerId,
      id,
      addFilter,
      selectAssessment,
      selectPackage,
      department,
      regNoFrom,
      regNoTo,
    };
    try {
      await apiHelper.axiosCallPost(
        `${api.baseURL}${api.customBatchMgmt.update}`,
        "put",
        payload
      );
      props.history.push({
        pathname: "/customBatchList",
        state: { customer: props?.location?.state?.customer || "" },
      });
      DefaultToast({
        message: "Successfully updated",
        style: {backgroundColor:"#1ba94c", color:"#ffffff"},
        position: "top-right",
      });
      console.log("test pass");
    } catch (error) {}
  };

  const checkDuplicate = async (name) => {
    if (props?.location?.state?.row?.name === name) return true;
    try {
      const res = await apiHelper.axiosCall(
        `${api.baseURL}${api.customBatchMgmt.checkDuplicate}${name}/${customerId}`
      );
      return !res || "This name is already in use. Please choose another name.";
    } catch (e) {
      return true;
    }
  };

  const getDepartments = async (id) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.userManagement.getDepartments}${id}`
      );
      if (data) {
        setDepartments(
          data.reduce((acc, dep) => {
            if (dep)
              acc.push({
                label: dep,
                value: dep,
              });
            return acc;
          }, [])
        );
      }
    } catch (error) {}
  };

  const getAssessmentsByCustomer = async (id) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.assessmentController.getAssessmentbyCustomer}${id}`,
        "get"
      );
      if (data) {
        setAssessments(
          data.reduce((acc, dep) => {
            if (dep)
              acc.push({
                label: dep.name,
                value: dep.id,
              });
            return acc;
          }, [])
        );
      }
    } catch (error) {}
  };

  const getLabsByCustomer = async (id) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.lab.getCustomerLabs}${id}`,
        "get"
      );
      if (data) {
        setPackages(
          data.reduce((acc, dep) => {
            if (dep)
              acc.push({
                label: dep.name,
                value: dep.id,
              });
            return acc;
          }, [])
        );
      }
    } catch (error) {}
  };

  const getStudentsByAssessment = async (assessmentId) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.assessmentController.getStudentsByAssessment}${customerId}/${assessmentId}`,
        "get"
      );
      if (data) {
        setCustomerStudents(
          data.reduce((acc, dep) => {
            if (dep)
              acc.push({
                label: dep.registrationNo
                  ? `${dep.firstName} ${dep.lastName} , ${dep.registrationNo}`
                  : `${dep.firstName} ${dep.lastName}`,
                value: dep.emailId,
                gender: dep.gender || "",
                registrationNo: dep.registrationNo || "",
                dept: dep.department || "",
              });
            return acc;
          }, [])
        );
      }
    } catch (error) {}
  };

  const getStudentsByPractice = async (labId) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.lab.getStudentsByPractice}${customerId}/${labId}`,
        "get"
      );
      if (data) {
        setCustomerStudents(
          data.reduce((acc, dep) => {
            if (dep)
              acc.push({
                label: dep.registrationNo
                  ? `${dep.firstName} ${dep.lastName} , ${dep.registrationNo}`
                  : `${dep.firstName} ${dep.lastName}`,
                value: dep.emailId,
                gender: dep.gender || "",
                registrationNo: dep.registrationNo || "",
                dept: dep.department || "",
              });
            return acc;
          }, [])
        );
      }
    } catch (error) {}
  };

  return (
    <CustomBatchDumb
      checkDuplicate={checkDuplicate}
      createBatch={createBatch}
      updateBatch={updateBatch}
      students={userPrivileges.isSuperAdmin ? customerStudents : adminStudents}
      adminStudents={adminStudents}
      editData={props?.location?.state}
      departments={departments}
      customer={props?.location?.state?.customer}
      activePage={activePage}
      itemsPerPage={itemsPerPage}
      addFilterBy={addFilterBy}
      assessments={assessments}
      packages={packages}
      filterByPercentage={filterByPercentage}
      getStudentsByAssessment={getStudentsByAssessment}
      getStudentsByPractice={getStudentsByPractice}
      getStudentsByCustomer={getStudentsByCustomer}
      customerId={customerId}
    />
  );
}

export default connect(null, null)(CustomBatchManagementSmart);
