import { Paper, Tabs as MuiTabs, Tab, withStyles } from '@material-ui/core';

const TabBar = withStyles({
  root: {
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '0 !important',
    minHeight: 'auto',
    marginLeft: -14,
    position: 'relative',
  },
})(Paper);

const Tabs = withStyles({
  root: {
    borderRadius: 0,
    height: 40,
    minHeight: 'auto',
  },
  indicator: {
    height: 3,
  },
})(MuiTabs);

const TabButton = withStyles({
  root: {
    backgroundColor: 'transparent',
    color: '#fff !important',
    fontSize: 14,
    height: 40,
    margin: 'auto',
    minHeight: 'auto',
    minWidth: 'auto',
    position: 'relative',
    padding: '0 14px',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },
  selected: {
    backgroundColor: '#0D487B',
  },
})(Tab);

export default function TabsComponent(props) {
  return (
    <TabBar square>
      <Tabs
        onChange={(e, newTab) => {
          props?.setTab?.(newTab, e);
        }}
        indicatorColor="primary"
        value={props?.tab}
        textColor="primary">
        {props?.tabs?.map(({ name }, idx) => (
          <TabButton key={idx} label={name} />
        ))}
      </Tabs>
    </TabBar>
  );
}
