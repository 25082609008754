import cn from "classnames";
import styles from "./InsightBox.module.scss";

function InsightBox({ color, description, Icon, title }) {
  return (
    <div
      className={cn(styles.box, {
        [styles.blue]: color === "blue",
        [styles.green]: color === "green",
        [styles.ink]: color === "ink",
        [styles.red]: color === "red",
      })}
    >
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.icon}>
          <Icon />
        </div>
      </div>
      <h2 className={styles.description}>{description}</h2>
    </div>
  );
}

export default InsightBox;
