import React from "react";
import one from './assets/PointOne.png';
import two from './assets/PointTwo.png';
import three from './assets/PointThree.png';
import four from './assets/PointFour.png';
import five from './assets/PointFive.png';
import six from './assets/PointSix.png';


export default function CardSection() {
  return (
    <>
      <div
        className="container relative z-[999] bg-[#242145]"
        style={{
          margin: "0px",
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          marginLeft: "auto",
          marginRight: "auto",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          width: "100%",
          maxWidth: "1180px",
          position: "relative",
          zIndex: 999,
        }}
      >
        <div
          className="flex flex-wrap justify-center gap-x-4 gap-y-4"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            columnGap: "1rem",
            rowGap: "1rem",
          }}
        >
          <div
            className="relative w-full md:p-2 sm:w-auto sm:max-w-[320px] flex md:flex-col gap-3 gap-y-3 rounded-[30px] bg-v5-green-500 InfoCard-module--card--bS7T6"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              position: "relative",
              display: "flex",
              gap: "0.75rem",
              rowGap: "0.75rem",
              borderRadius: "30px",
              backgroundColor: "#5E35B1",
              width: "auto",
              maxWidth: "320px",
              flexDirection: "column",
              padding: "1.5rem",
            }}
          >
            <div
              className="undefined"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
              }}
            >
              <div
                className="flex w-[60px] items-center justify-center"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  display: "flex",
                  width: "60px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    position: "relative",
                    display: "inline-block",
                    verticalAlign: "top",
                    maxHeight: "100%",
                  }}
                >
                  <div
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      maxWidth: "200px",
                      display: "block",
                    }}
                  >
                    <img
                      aria-hidden="true"
                      role="presentation"
                      src={one}
                      style={{
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        pointerEvents: "none",
                        verticalAlign: "middle",
                        inset: "0px",
                        margin: "0px",
                        padding: "0px",
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        maxWidth: "100%",
                        display: "block",
                        position: "static",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex flex-col gap-2"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <p
                className="text-v5-neutral-300 leading-relaxed"
                style={{
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  overflowWrap: "break-word",
                  margin: "0px",
                  lineHeight: 1.625,
                  color: "rgb(185 207 202/1)",
                }}
              >
                <div
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                >
                  <p
                    className="text-v5-neutral-100"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      overflowWrap: "break-word",
                      margin: "0px",
                      color: "rgb(255 255 255/1)",
                    }}
                  >
                    <strong
                      className="font-bold"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        fontWeight: 700,
                      }}
                    >
                      6+ work-like projects & 20+ micro skilling exercises
                    </strong>
                    <br
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    />
                    <span
                      className="text-[#B9CFCA]"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      {" "}
                      Master fullstack & backend development in a
                    </span>
                    <span
                      className="text-[#2DF8C5] font-bold"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        fontWeight: 700,
                        color: "#242145",
                      }}
                    >
                      {" "}
                      real work-like environment.
                    </span>
                  </p>
                </div>
              </p>
            </div>
          </div>
          <div
            className="relative w-full md:p-2 sm:w-auto sm:max-w-[320px] flex md:flex-col gap-3 gap-y-3 rounded-[30px] bg-v5-green-500 InfoCard-module--card--bS7T6"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              position: "relative",
              display: "flex",
              gap: "0.75rem",
              rowGap: "0.75rem",
              borderRadius: "30px",
              backgroundColor: "#5E35B1",
              width: "auto",
              maxWidth: "320px",
              flexDirection: "column",
              padding: "1.5rem",
            }}
          >
            <div
              className="undefined"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
              }}
            >
              <div
                className="flex w-[60px] items-center justify-center"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  display: "flex",
                  width: "60px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    position: "relative",
                    display: "inline-block",
                    verticalAlign: "top",
                    maxHeight: "100%",
                  }}
                >
                  <div
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      maxWidth: "200px",
                      display: "block",
                    }}
                  >
                    <img
                      aria-hidden="true"
                      role="presentation"
                      src={two}
                      style={{
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        pointerEvents: "none",
                        verticalAlign: "middle",
                        inset: "0px",
                        margin: "0px",
                        padding: "0px",
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        maxWidth: "100%",
                        display: "block",
                        position: "static",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex flex-col gap-2"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <p
                className="text-v5-neutral-300 leading-relaxed"
                style={{
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  overflowWrap: "break-word",
                  margin: "0px",
                  lineHeight: 1.625,
                  color: "rgb(185 207 202/1)",
                }}
              >
                <div
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                >
                  <p
                    className="text-v5-neutral-100"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      overflowWrap: "break-word",
                      margin: "0px",
                      color: "rgb(255 255 255/1)",
                    }}
                  >
                    <strong
                      className="font-bold"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        fontWeight: 700,
                      }}
                    >
                      Live chat support & dedicated success managers
                    </strong>
                    <br
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    />
                    <span
                      className="text-[#2DF8C5] font-bold"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        fontWeight: 700,
                        color: "#242145",
                      }}
                    >
                      {" "}
                      12+ hours of daily live technical support
                    </span>
                    <span
                      className="text-[#B9CFCA]"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      {" "}
                      to ensure a smooth & effortless learning experience.
                    </span>
                  </p>
                </div>
              </p>
            </div>
          </div>
          <div
            className="relative w-full md:p-2 sm:w-auto sm:max-w-[320px] flex md:flex-col gap-3 gap-y-3 rounded-[30px] bg-v5-green-500 InfoCard-module--card--bS7T6"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              position: "relative",
              display: "flex",
              gap: "0.75rem",
              rowGap: "0.75rem",
              borderRadius: "30px",
              backgroundColor: "#5E35B1",
              width: "auto",
              maxWidth: "320px",
              flexDirection: "column",
              padding: "1.5rem",
            }}
          >
            <div
              className="undefined"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
              }}
            >
              <div
                className="flex w-[60px] items-center justify-center"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  display: "flex",
                  width: "60px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    position: "relative",
                    display: "inline-block",
                    verticalAlign: "top",
                    maxHeight: "100%",
                  }}
                >
                  <div
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      maxWidth: "200px",
                      display: "block",
                    }}
                  >
                    <img
                      aria-hidden="true"
                      role="presentation"
                      src={three}
                      style={{
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        pointerEvents: "none",
                        verticalAlign: "middle",
                        inset: "0px",
                        margin: "0px",
                        padding: "0px",
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        maxWidth: "100%",
                        display: "block",
                        position: "static",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex flex-col gap-2"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <p
                className="text-v5-neutral-300 leading-relaxed"
                style={{
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  overflowWrap: "break-word",
                  margin: "0px",
                  lineHeight: 1.625,
                  color: "rgb(185 207 202/1)",
                }}
              >
                <div
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                >
                  <p
                    className="text-v5-neutral-100"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      overflowWrap: "break-word",
                      margin: "0px",
                      color: "rgb(255 255 255/1)",
                    }}
                  >
                    <strong
                      className="font-bold"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        fontWeight: 700,
                      }}
                    >
                      80+ live guided sessions
                    </strong>
                    <br
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    />
                    <span
                      className="text-[#B9CFCA]"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      {" "}
                      Mentorship by
                    </span>
                    <span
                      className="text-[#2DF8C5] font-bold"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        fontWeight: 700,
                        color: "#242145",
                      }}
                    >
                      {" "}
                      industry experts
                    </span>
                    <span
                      className="text-[#B9CFCA]"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      {" "}
                      from top tech companies.
                    </span>
                  </p>
                </div>
              </p>
            </div>
          </div>
          <div
            className="relative w-full md:p-2 sm:w-auto sm:max-w-[320px] flex md:flex-col gap-3 gap-y-3 rounded-[30px] bg-v5-green-500 InfoCard-module--card--bS7T6"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              position: "relative",
              display: "flex",
              gap: "0.75rem",
              rowGap: "0.75rem",
              borderRadius: "30px",
              backgroundColor: "#5E35B1",
              width: "auto",
              maxWidth: "320px",
              flexDirection: "column",
              padding: "1.5rem",
            }}
          >
            <div
              className="undefined"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
              }}
            >
              <div
                className="flex w-[60px] items-center justify-center"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  display: "flex",
                  width: "60px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    position: "relative",
                    display: "inline-block",
                    verticalAlign: "top",
                    maxHeight: "100%",
                  }}
                >
                  <div
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      maxWidth: "200px",
                      display: "block",
                    }}
                  >
                    <img
                      aria-hidden="true"
                      role="presentation"
                      src={four}
                      style={{
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        pointerEvents: "none",
                        verticalAlign: "middle",
                        inset: "0px",
                        margin: "0px",
                        padding: "0px",
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        maxWidth: "100%",
                        display: "block",
                        position: "static",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex flex-col gap-2"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <p
                className="text-v5-neutral-300 leading-relaxed"
                style={{
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  overflowWrap: "break-word",
                  margin: "0px",
                  lineHeight: 1.625,
                  color: "rgb(185 207 202/1)",
                }}
              >
                <div
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                >
                  <p
                    className="text-v5-neutral-100"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      overflowWrap: "break-word",
                      margin: "0px",
                      color: "rgb(255 255 255/1)",
                    }}
                  >
                    <strong
                      className="font-bold"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        fontWeight: 700,
                      }}
                    >
                      Exclusive Career Services
                    </strong>
                    <br
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    />
                    <span
                      className="text-[#B9CFCA]"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      Extensive mock interviews, resume building and mock
                      assessments with{" "}
                    </span>
                    <span
                      className="text-[#2DF8C5] font-bold"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        fontWeight: 700,
                        color: "#242145",
                      }}
                    >
                      detailed interview prep sprints
                    </span>
                    <span
                      className="text-[#B9CFCA]"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      {" "}
                      to ace top tech jobs.
                    </span>
                  </p>
                </div>
              </p>
            </div>
          </div>
          <div
            className="relative w-full md:p-2 sm:w-auto sm:max-w-[320px] flex md:flex-col gap-3 gap-y-3 rounded-[30px] bg-v5-green-500 InfoCard-module--card--bS7T6"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              position: "relative",
              display: "flex",
              gap: "0.75rem",
              rowGap: "0.75rem",
              borderRadius: "30px",
              backgroundColor: "#5E35B1",
              width: "auto",
              maxWidth: "320px",
              flexDirection: "column",
              padding: "1.5rem",
            }}
          >
            <div
              className="undefined"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
              }}
            >
              <div
                className="flex w-[60px] items-center justify-center"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  display: "flex",
                  width: "60px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    position: "relative",
                    display: "inline-block",
                    verticalAlign: "top",
                    maxHeight: "100%",
                  }}
                >
                  <div
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      maxWidth: "200px",
                      display: "block",
                    }}
                  >
                    <img
                      aria-hidden="true"
                      role="presentation"
                      src={five}
                      style={{
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        pointerEvents: "none",
                        verticalAlign: "middle",
                        inset: "0px",
                        margin: "0px",
                        padding: "0px",
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        maxWidth: "100%",
                        display: "block",
                        position: "static",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex flex-col gap-2"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <p
                className="text-v5-neutral-300 leading-relaxed"
                style={{
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  overflowWrap: "break-word",
                  margin: "0px",
                  lineHeight: 1.625,
                  color: "rgb(185 207 202/1)",
                }}
              >
                <div
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                >
                  <p
                    className="text-v5-neutral-100"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      overflowWrap: "break-word",
                      margin: "0px",
                      color: "rgb(255 255 255/1)",
                    }}
                  >
                    <strong
                      className="font-bold"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        fontWeight: 700,
                      }}
                    >
                      300+ DSA problems & 20+ System Design challenges
                    </strong>
                    <br
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    />
                    <span
                      className="text-[#B9CFCA]"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      {" "}
                      Build problem-solving skills to
                    </span>
                    <span
                      className="text-[#2DF8C5] font-bold"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        fontWeight: 700,
                        color: "#242145",
                      }}
                    >
                      {" "}
                      ace technical interviews.
                    </span>
                  </p>
                </div>
              </p>
            </div>
          </div>
          <div
            className="relative w-full md:p-2 sm:w-auto sm:max-w-[320px] flex md:flex-col gap-3 gap-y-3 rounded-[30px] bg-v5-green-500 InfoCard-module--card--bS7T6"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              position: "relative",
              display: "flex",
              gap: "0.75rem",
              rowGap: "0.75rem",
              borderRadius: "30px",
              backgroundColor: "#5E35B1",
              width: "auto",
              maxWidth: "320px",
              flexDirection: "column",
              padding: "1.5rem",
            }}
          >
            <div
              className="undefined"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
              }}
            >
              <div
                className="flex w-[60px] items-center justify-center"
                style={{
                  margin: "0px",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  display: "flex",
                  width: "60px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    position: "relative",
                    display: "inline-block",
                    verticalAlign: "top",
                    maxHeight: "100%",
                  }}
                >
                  <div
                    style={{
                      margin: "0px",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      maxWidth: "200px",
                      display: "block",
                    }}
                  >
                    <img
                      aria-hidden="true"
                      role="presentation"
                      src={six}
                      style={{
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        pointerEvents: "none",
                        verticalAlign: "middle",
                        inset: "0px",
                        margin: "0px",
                        padding: "0px",
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        maxWidth: "100%",
                        display: "block",
                        position: "static",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex flex-col gap-2"
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <p
                className="text-v5-neutral-300 leading-relaxed"
                style={{
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  overflowWrap: "break-word",
                  margin: "0px",
                  lineHeight: 1.625,
                  color: "rgb(185 207 202/1)",
                }}
              >
                <div
                  style={{
                    margin: "0px",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                  }}
                >
                  <p
                    className="text-v5-neutral-100"
                    style={{
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      overflowWrap: "break-word",
                      margin: "0px",
                      color: "rgb(255 255 255/1)",
                    }}
                  >
                    <strong
                      className="font-bold"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        fontWeight: 700,
                      }}
                    >
                      Active community of 5000+ learners
                    </strong>
                    <br
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    />
                    <span
                      className="text-[#2DF8C5] font-bold"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        fontWeight: 700,
                        color: "#242145",
                      }}
                    >
                      {" "}
                      Connect, network, and collaborate
                    </span>
                    <span
                      className="text-[#B9CFCA]"
                      style={{
                        margin: "0px",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                        color: "rgb(185 207 202/1)",
                      }}
                    >
                      {" "}
                      with alumni placed in top tech companies.
                    </span>
                  </p>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  margin: 0px;
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  tab-size: 4;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}

body {
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizelegibility;
  overflow-y: auto;
  min-height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  line-height: inherit;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}
`,
        }}
      />
    </>
  );
}
