import { SET_USER_PROFILE } from "./actionTypes";

export default function UserProfileReducer(state = {}, action) {
  switch (action.type) {
    case SET_USER_PROFILE: {
      return {
        ...state,
        UserData: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
