import React, { useState, useEffect, forwardRef } from "react";
import HackathonCard from "./hackathonHeader";
import "./hackathons.css";
import {
  WinnerCup,
  FirstPrize,
  SecondPrize,
  ThirdPrize,
} from "../../Prepare/SVGs";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import styles from "./Prepare.module.scss";
import cn from "classnames";
import { CapitalizeFirstLetter } from "pages/utils/CapitalizeFirstLetter";
import { TervButtonOutlined, TervButtonFilled } from "components/TervButtons";
import { Tabs } from "components/tabs/tabsHackathon";
import apiHelper from "../../../api/apiHelper";
import api from "../../../api/baseConfig";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import HackathonContent from "./hackathonContent/overview";
import HackathonContentChallenge from "./hackathonContent/overviewChallenge";
import HackathonRules from "./hackathonContent/rules";
import HackathonSubmission from "./hackathonContent/submission";
import HackathonJudgingCriteria from "./hackathonContent/JudgingCriteria";
import HackathonFAQs from "./hackathonContent/FAQs";
import HackathonFormat from "./hackathonContent/Format"
import { ModalToggle } from "pages/utils/ModalToggle";
import { CheckModal } from "../../Assessment/CheckModal";
import { InstructionModal } from "../../Assessment/InstructionModal";
import Skeleton from "@material-ui/lab/Skeleton";
import SignInModal from "components/VerticalLayout/SignInModal";
import { useMediaQuery } from "@material-ui/core";
import { GreenToast } from "components/GreenToast";
import HackathonMobileCard from "./hackathonMobileHeader";
import { makeStyles } from '@material-ui/core/styles';

const TabScrollButton = forwardRef((props, ref) => {
  const { direction, ...other } = props;

  return (
    <ButtonBase
      component="div"
      ref={ref}
      style={{ opacity: other.disabled ? 0 : 1 }}
      {...other}
    >
      {direction === "left" ? (
        <ArrowBackIosIcon
          fontSize="small"
          className="arrow-icons"
          style={{ fill: "black" }}
        />
      ) : (
        <ArrowForwardIosIcon
          fontSize="small"
          className="arrow-icons"
          style={{ fill: "black" }}
        />
      )}
    </ButtonBase>
  );
});

const useStyles = makeStyles({
  root: {
    minWidth: 75,
    maxWidth: 1250,
  },
})

function hackathonRegister() {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("Overview");
  const [hackathonDataDetails, setHackathonDataDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [openSignin, setOpenSignin] = useState(false);
  const [checkModal, setCheckModal] = useState(false);
  const [authDetails, setAuthDetails] = useState({
    passCode: "",
    imageEnabled: "",
    userVerified: true,
    userPhoto: "",
  });
  const [assessmentData, setAssessmentData] = useState({});
  const [userAssessmentStatus, setUserAssessmentStatus] = useState();
  const [sectionData, setSectionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useState(undefined);
  const { id } = useParams();
  const tabList = [
    {
      name: "Overview",
      id: "Overview",
    },
    {
      name: "Rules",
      id: "Rules",
    },
    {
      name: "Submission",
      id: "Submission",
    },
    {
      name: "Judging criteria",
      id: "Judging criteria",
    },
    {
      name: "FAQs",
      id: "FAQs",
    },
  ];

  const tabListTwo = [
    {
      name: "Overview",
      id: "Overview",
    },
    {
      name: "Format",
      id: "Format",
    },
  
    {
      name: "FAQs",
      id: "FAQs",
    },
  ];
  useEffect(() => {
    hackathonDetails();
  }, []);
  const smallScreen = useMediaQuery("(max-width: 767px)");

  const takeAssessment = (a) => {
    getResData();
    setShowModal(ModalToggle);
  };

  const handleAssessment = (assessment) => {
    if (assessment.courseId) {
      if (assessment.courseProgress === 100) {
        takeAssessment(assessment);
      } else {
        if (!whichCourse.name || whichCourse.name !== assessment.courseName) {
          setWhichCourse({
            name: assessment.courseName,
            reason: !whichCourse.reason,
          });
        } else {
          setWhichCourse({
            ...whichCourse,
            reason: !whichCourse.reason,
          });
        }
      }
    } else {
      takeAssessment(assessment);
    }
  };

  const hackathonDetails = async () => {
    setLoading(true);
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.Hackathon.getHackathonDetails}${id}`,
        "get"
      );
      setHackathonDataDetails(data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const hackathonRegistration = async () => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.Hackathon.hackathonRegister}${id}`,
        "post"
      );
      GreenToast({ message: "Hackathon Registered successfully!" });
      hackathonDetails();
    } catch (e) {
      console.log(e);
    }
  };
  async function handlePassword() {
    let isAuth = await VerifyPasscode({
      passCode: password,
      assessmentId: hackathonDataDetails?.assessmentId,
    });
    if (isAuth) {
      toast.success("Passcode is valid", {
        position: "bottom-center",
        style: { zIndex: 2000 },
      });
    } else {
      toast.error("Passcode is invalid", {
        position: "bottom-center",
        style: { backgroundColor: "#ff0000", color: "#fff", zIndex: 2000 },
      });
    }
    setAuth(isAuth);
  }
  const handleSignInModal = () => setOpenSignin(ModalToggle);

  const getResData = async () => {
    await apiHelper
      .axiosCall(
        `${api.baseURL}${api.userManagement.getInfo}${hackathonDataDetails.assessmentId}`,
        "get"
      )
      .then((res) => {
        setAssessmentData(res);
        setUserAssessmentStatus(res.userAssessmentStatus);
        let temp = [];
        res.sectionList.forEach((r) => {
          temp.push({
            secName: r.name,
            secDescription: r.description,
            noOfQuestions: r.questionIdList.length,
          });
        });
        setSectionData(temp);
        let defpassCode = false;
        defpassCode = getSafe(() => res.settings.passcodeEnabled, false);
        let imageEnabledD = getSafe(() => res.settings.imageEnabled, true);
        setAuthDetails({
          ...authDetails,
          passCode: defpassCode,
          imageEnabled: imageEnabledD,
          userVerified: true,
          isSEB: !!res.settings?.secureBrowserEnabled,
          sebUrl: res.seburl,
        });
      })
      .catch((e) => {});
  };
  const userId = sessionStorage.getItem("user_id");
  const matches = useMediaQuery("(max-width:1720px)");
  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      {hackathonDataDetails?.coverImage ? (
        <img
          src={`${hackathonDataDetails?.coverImage}`}
          style={{ width: "100%", height: "10rem", objectFit: "cover" }}
        />
      ) : (
        <div>

          
           {smallScreen ? 
          <HackathonMobileCard /> : <HackathonCard /> }
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h3
              className="hackathonHeaderCover"
              style={{ color: "#ffffff", textTransform: "capitalize" }}
            >
              {hackathonDataDetails?.name}
            </h3>
          </div>
        </div>
      )}

      <SignInModal
        modal={openSignin}
        setModal={() => {
          handleSignInModal();
        }}
        fromAssessment
        assessmentId={hackathonDataDetails?.assessmentId}
        openInstructions={() => {
          handleSignInModal();
          takeAssessment(assessmentData);
        }}
      />
      <CheckModal
        hackathonSubmission={true}
        assessmentId={hackathonDataDetails?.assessmentId}
        assessmentData={assessmentData}
        hackathonId={id}
        isOpen={checkModal}
        close={() => setCheckModal(false)}
        onTakeTest={() => {
          setShowModal(false);
        }}
        authDetails={authDetails}
        callback={`/taketest?id=${hackathonDataDetails?.assessmentId}&userId=${userId}`}
        setUserVerifiedPhoto={(photo) => {
          setAuthDetails({
            ...authDetails,
            userVerified: true,
            userPhoto: photo,
          });
        }}
      />
      <InstructionModal
        hackathonSubmission={true}
        showModal={showModal}
        toggle={() => {
          setAuth(false);
          setShowModal(!showModal);
        }}
        assessmentData={assessmentData}
        authDetails={authDetails}
        isSEB={false}
        sebUrl={authDetails.sebUrl}
        sectionData={sectionData}
        setPassword={(pas) => setPassword(pas)}
        handlePassword={handlePassword}
        auth={auth}
        setCheckModal={() => setCheckModal(!checkModal)}
      />
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src="/assets/loading.gif" width="25%" />
          <h3 style={{ fontWight: "20px" }}>Hackathon Loading.....</h3>
        </div>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={7}>

            <div className="pl-3">
              <div className={smallScreen ? "paddingCard1" : "paddingCard"}>
                <div className="pt-1">
                  <h3
                    className={
                      smallScreen ? "hackathonHeaderMobile" : "hackathonHeader"
                    }
                    style={{ textTransform: "capitalize" }}
                  >
                    {hackathonDataDetails?.name}
                  </h3>
                  <p className="hackathonParagraph">
                    {hackathonDataDetails?.description}
                  </p>
                  
                  {smallScreen &&

<Grid item xs={12} lg={5}>
  <div className="paddingCard">
    <div className={matches ? "" : "stickyCard paddingContainer"}>
      <Card
        variant="outlined"
        style={{ background: "#F2F1F3", padding: "1rem" }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-start" }}
          className="p-1"
        >
          <span
            className={cn([styles.tagCard], {
              [styles.tagSD]:
                hackathonDataDetails?.status === "Scheduled",
              [styles.tagDF]: hackathonDataDetails?.status === "Live",
              [styles.tagPN]:
                hackathonDataDetails?.status === "Completed",
            })}
          >
            {CapitalizeFirstLetter(
              hackathonDataDetails?.status || ""
            )}
          </span>
          <div className="pl-2">
            <span>
              {hackathonDataDetails?.registration ===
                "Registration closed" && (
                <div
                  className={cn([styles.tagCard], [styles.tagRAP])}
                >
                  <span className="pr-2">
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4" cy="4" r="4" fill="#DE5445" />
                    </svg>
                  </span>
                  {CapitalizeFirstLetter("Registration closed")}
                </div>
              )}
              {hackathonDataDetails?.registration ===
                "Registration open" && (
                <div
                  className={cn([styles.tagCard], [styles.tagRAP])}
                >
                  <span className="pr-2">
                    <svg
                      width="9"
                      height="8"
                      viewBox="0 0 9 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4.5" cy="4" r="4" fill="#1BA94C" />
                    </svg>
                  </span>
                  {CapitalizeFirstLetter("Registration open")}
                </div>
              )}
            </span>
          </div>
        </div>
        <Grid container spacing={3} className="pl-3">
          <Grid item xs={6}>
            <div className="hackathonCardParagraph">
              Registration starts
            </div>
            <p
              className="hackathonCardHeader"
              style={
                smallScreen
                  ? { whiteSpace: "wrap" }
                  : { whiteSpace: "nowrap" }
              }
            >
              {moment(
                hackathonDataDetails?.registrationStartDate
              ).format("DD MMM YYYY, hh:mm A")}{" "}
            </p>
          </Grid>
          <Grid item xs={6}>
            <div className="hackathonCardParagraph">
              Registration ends
            </div>
            <p
              className="hackathonCardHeader"
              style={
                smallScreen
                  ? { whiteSpace: "wrap" }
                  : { whiteSpace: "nowrap" }
              }
            >
              {" "}
              {moment(
                hackathonDataDetails?.registrationEndDate
              ).format("DD MMM YYYY, hh:mm A")}{" "}
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={1} className="pl-3">
          <Grid item xs={6}>
            <div className="hackathonCardParagraph">
              Hackathon starts
            </div>
            <p className="hackathonCardHeader">
              {" "}
              {moment(
                hackathonDataDetails?.hackathonStartDate
              ).format("DD MMM YYYY, hh:mm A")}{" "}
            </p>
          </Grid>
          <Grid item xs={6}>
            <div className="hackathonCardParagraph">
              Hackathon ends
            </div>
            <p className="hackathonCardHeader">
              {" "}
              {moment(hackathonDataDetails?.hackathonEndDate).format(
                "DD MMM YYYY, hh:mm A"
              )}{" "}
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={1} className="pl-3">
          <Grid item xs={6}>
            <div className="hackathonCardParagraph">Submission</div>
            <p className="hackathonCardHeader">Online</p>
          </Grid>
          <Grid item xs={6}>
            <div className="hackathonCardParagraph">
              Registered users
            </div>
            <p className="hackathonCardHeader">
              {hackathonDataDetails?.registeredUsersCount}{" "}
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={1} className="pl-3">
          <Grid item xs={6}>
            <div className="hackathonCardParagraph">Team size</div>
            <p className="hackathonCardHeader">
              {hackathonDataDetails?.teamSize || "Individual"}{" "}
            </p>
          </Grid>
          <Grid item xs={6}>
            <div className="hackathonCardParagraph">
              Hackathon type
            </div>
            <p className="hackathonCardHeader">
              {hackathonDataDetails?.type}
            </p>
          </Grid>
        </Grid>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {hackathonDataDetails?.registration ==
          "Registration open" ? (
            hackathonDataDetails?.status == "Scheduled" ? (
              hackathonDataDetails?.userRegistered ? (
                <TervButtonFilled
                  text={"Registered"}
                  variant="outlined"
                  onClick={""}
                  style={{ width: "15rem" }}
                />
              ) : (
                <TervButtonFilled
                  text={"Register for Hackathon"}
                  variant="outlined"
                  onClick={() => {
                    hackathonRegistration();
                  }}
                  style={{ width: "15rem" }}
                />
              )
            ) : hackathonDataDetails?.status == "Live" ? (
              hackathonDataDetails?.userRegistered ? (
                hackathonDataDetails?.type == "Challenge" ? (
                  hackathonDataDetails?.submitted == true ? (
                    <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 14 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.47689 5.9987C6.24422 5.9987 6.81022 5.4327 6.81022 4.66536C6.81022 3.89803 6.24422 3.33203 5.47689 3.33203C4.70955 3.33203 4.14355 3.89803 4.14355 4.66536C4.14355 5.4327 4.70889 5.9987 5.47689 5.9987Z"
                          fill="#1BA94C"
                        />
                        <path
                          d="M12.333 0.667969H1.66634C0.931008 0.667969 0.333008 1.22864 0.333008 1.91797V10.0846C0.333008 10.774 0.931008 11.3346 1.66634 11.3346H12.333C13.0683 11.3346 13.6663 10.774 13.6663 10.0846V1.91797C13.6663 1.22864 13.0683 0.667969 12.333 0.667969ZM12.333 10.0013L1.66634 9.99397V2.0013L12.333 2.00864V10.0013Z"
                          fill="#1BA94C"
                        />
                        <path
                          d="M8.33333 4H11V5.33333H8.33333V4ZM9 6.66667H11V8H9V6.66667ZM7.95333 8.35733C7.95333 7.44133 6.836 6.5 5.47667 6.5C4.11733 6.5 3 7.44133 3 8.35733V8.66667H7.95333V8.35733Z"
                          fill="#1BA94C"
                        />
                      </svg> You already completed this hackathon
                    </div>
                  </div>
                  ) : (
                    <TervButtonFilled
                      text={"Take Challange"}
                      color="primary"
                      onClick={() => {
                        if (
                          sessionStorage.getItem(
                            "viewPkgWithoutSignIn"
                          ) === "true"
                        ) {
                          handleSignInModal();
                          setAssessmentData(
                            hackathonDataDetails.assessmentId
                          );
                        } else {
                          handleAssessment(
                            hackathonDataDetails.assessmentId
                          );
                        }
                      }}
                      style={{
                        marginTop:
                          sessionStorage.getItem(
                            "viewPkgWithoutSignIn"
                          ) === "true"
                            ? "5rem"
                            : 0,
                      }}
                    />
                  )
                ) : (
                  <Link
                    to={{
                      pathname: `/hackathonSubmission/${hackathonDataDetails?.id}`,
                      state: hackathonDataDetails?.id,
                    }}
                  >
                    <TervButtonFilled
                      text={
                        hackathonDataDetails?.submitted
                          ? "View Submission"
                          : "Submit Resource"
                      }
                      variant="outlined"
                      onClick={""}
                      style={{ width: "15rem" }}
                    />
                  </Link>
                )
              ) : (
                <TervButtonFilled
                  text={"Register for Hackathon"}
                  variant="outlined"
                  onClick={() => {
                    hackathonRegistration();
                  }}
                  style={{ width: "15rem" }}
                />
              )
            ) : hackathonDataDetails?.userRegistered ? (
              <Link
                to={{
                  pathname: `/hackathonSubmission/${hackathonDataDetails?.id}`,
                  state: hackathonDataDetails?.id,
                }}
              >
                <TervButtonFilled
                  text={"View Submission"}
                  variant="outlined"
                  onClick={""}
                  style={{ width: "15rem" }}
                />
              </Link>
            ) : (
              <p>Sorry, Hackathond ends try some other </p>
            )





          ) : hackathonDataDetails?.userRegistered ? (
            hackathonDataDetails?.type == "challenge" ? (
              hackathonDataDetails?.submitted == true ? (
                ""
              ) : (
                <TervButtonFilled
                  text={"Take challenge"}
                  variant="outlined"
                  onClick={() => {
                    takeAssessment(
                      hackathonDataDetails?.assessmentId
                    );
                  }}
                  style={{ width: "15rem" }}
                />
              )
            ) : (
              <Link
                to={{
                  pathname: `/hackathonSubmission/${hackathonDataDetails?.id}`,
                  state: hackathonDataDetails?.id,
                }}
              >
               
              </Link>
            )
          ) : (
            // <p>Registration Scheduled</p>

           <p>Sorry, Registration ends for this Hackathon</p>
          )}
        </div>
      </Card>
    </div>
  </div>
</Grid>
}                     
                   <div  >
                  <Card variant="outlined" className={smallScreen ? "rootCardMobile" : "rootCard"}>
                    
                    <div
                      className={smallScreen ? "prizeCardMobile" : "prizeCard"}
                    >
                      <div
                        className={smallScreen ? " " : "pl-5"}
                        style={{ display: "flex" }}
                      >
                        {smallScreen ? <FirstPrize /> : <WinnerCup />}
                        <div className="pl-3">
                          <div>1st Prize</div>
                          <p className="prizeHackathon">
                            {" "}
                            {hackathonDataDetails?.prizeType == "product"
                              ? `${hackathonDataDetails?.prizes?.firstPrize}`
                              : hackathonDataDetails?.prizeType == "points"
                              ? `points - ${hackathonDataDetails?.prizes?.firstPrize || ""}`
                              : `Rupees - ${hackathonDataDetails?.prizes?.firstPrize || ""}`}{" "}
                          </p>
                        </div>
                      </div>
                      <div
                        className={smallScreen ? "" : "pl-5"}
                        style={{ display: "flex" }}
                      >
                        {smallScreen ? <SecondPrize /> : <WinnerCup />}
                        <div className="pl-3">
                          <div>2nd Prize</div>
                          <p className="prizeHackathon">
                            {hackathonDataDetails?.prizeType == "product"
                              ? `${hackathonDataDetails?.prizes?.secondPrize}`
                              : hackathonDataDetails?.prizeType == "points"
                              ? `points - ${hackathonDataDetails?.prizes?.secondPrize || ""}`
                              : `Rupees - ${hackathonDataDetails?.prizes?.secondPrize || ""}`}
                          </p>
                        </div>
                      </div>
                      <div
                        className={smallScreen ? "" : "pl-5"}
                        style={{ display: "flex" }}
                      >
                        {smallScreen ? <ThirdPrize /> : <WinnerCup />}
                        <div className="pl-3">
                          <div>3rd Prize</div>
                          <p className="prizeHackathon">
                            {hackathonDataDetails?.prizeType == "product"
                              ? `${hackathonDataDetails?.prizes?.thirdPrize}`
                              : hackathonDataDetails?.prizeType == "points"
                              ? `points - ${hackathonDataDetails?.prizes?.thirdPrize || ""}`
                              : `Rupees - ${hackathonDataDetails?.prizes?.thirdPrize || ""}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Card>
                  </div>
                  
                </div>
              </div>
              {hackathonDataDetails?.type == "Challenge" ? (
              <div>
              <Tabs
                activeTab={activeTab}
                tabs={tabListTwo}
                onChange={setActiveTab}
                variant="scrollable"
                scrollButtons="auto"
                textColor="primary"
                hideBadge={true}
                ScrollButtonComponent={TabScrollButton}
              />
              {activeTab === "Overview" && (
                <HackathonContentChallenge overview={hackathonDataDetails} />
              )}
              {activeTab === "Format" && (
                <HackathonFormat overview={hackathonDataDetails} />
              )}
               {activeTab === "FAQs" && (
                <HackathonFAQs overview={hackathonDataDetails} />
              )}
              </div>
              ) : (
                <div>
                <Tabs
                  activeTab={activeTab}
                  tabs={tabList}
                  onChange={setActiveTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  textColor="primary"
                  hideBadge={true}
                  ScrollButtonComponent={TabScrollButton}
                />
                {activeTab === "Overview" && (
                  <HackathonContent overview={hackathonDataDetails} />
                )}
                {activeTab === "Rules" && (
                  <HackathonRules overview={hackathonDataDetails} />
                )}
                {activeTab === "Submission" && (
                  <HackathonSubmission overview={hackathonDataDetails} />
                )}
                {activeTab === "Judging criteria" && (
                  <HackathonJudgingCriteria overview={hackathonDataDetails} />
                )}
                 {activeTab === "FAQs" && (
                  <HackathonFAQs overview={hackathonDataDetails} />
                )}
                </div>
              )}
            </div>
          </Grid>
           {!smallScreen &&

          <Grid item xs={12} lg={5}>
            <div className="paddingCard">
              <div className={matches ? "" : "stickyCard paddingContainer"}>
                <Card
                  variant="outlined"
                  style={{ background: "#F2F1F3", padding: "1rem" }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    className="p-1"
                  >
                    <span
                      className={cn([styles.tagCard], {
                        [styles.tagSD]:
                          hackathonDataDetails?.status === "Scheduled",
                        [styles.tagDF]: hackathonDataDetails?.status === "Live",
                        [styles.tagPN]:
                          hackathonDataDetails?.status === "Completed",
                      })}
                    >
                      {CapitalizeFirstLetter(
                        hackathonDataDetails?.status || ""
                      )}
                    </span>
                    <div className="pl-2">
                      <span>
                        {hackathonDataDetails?.registration ===
                          "Registration closed" && (
                          <div
                            className={cn([styles.tagCard], [styles.tagRAP])}
                          >
                            <span className="pr-2">
                              <svg
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="4" cy="4" r="4" fill="#DE5445" />
                              </svg>
                            </span>
                            {CapitalizeFirstLetter("Registration closed")}
                          </div>
                        )}
                        {hackathonDataDetails?.registration ===
                          "Registration open" && (
                          <div
                            className={cn([styles.tagCard], [styles.tagRAP])}
                          >
                            <span className="pr-2">
                              <svg
                                width="9"
                                height="8"
                                viewBox="0 0 9 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="4.5" cy="4" r="4" fill="#1BA94C" />
                              </svg>
                            </span>
                            {CapitalizeFirstLetter("Registration open")}
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                  <Grid container spacing={3} className="pl-3">
                    <Grid item xs={6}>
                      <div className="hackathonCardParagraph">
                        Registration starts
                      </div>
                      <p
                        className="hackathonCardHeader"
                        style={
                          smallScreen
                            ? { whiteSpace: "wrap" }
                            : { whiteSpace: "nowrap" }
                        }
                      >
                        {moment(
                          hackathonDataDetails?.registrationStartDate
                        ).format("DD MMM YYYY, hh:mm A")}{" "}
                      </p>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="hackathonCardParagraph">
                        Registration ends
                      </div>
                      <p
                        className="hackathonCardHeader"
                        style={
                          smallScreen
                            ? { whiteSpace: "wrap" }
                            : { whiteSpace: "nowrap" }
                        }
                      >
                        {" "}
                        {moment(
                          hackathonDataDetails?.registrationEndDate
                        ).format("DD MMM YYYY, hh:mm A")}{" "}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="pl-3">
                    <Grid item xs={6}>
                      <div className="hackathonCardParagraph">
                        Hackathon starts
                      </div>
                      <p className="hackathonCardHeader">
                        {" "}
                        {moment(
                          hackathonDataDetails?.hackathonStartDate
                        ).format("DD MMM YYYY, hh:mm A")}{" "}
                      </p>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="hackathonCardParagraph">
                        Hackathon ends
                      </div>
                      <p className="hackathonCardHeader">
                        {" "}
                        {moment(hackathonDataDetails?.hackathonEndDate).format(
                          "DD MMM YYYY, hh:mm A"
                        )}{" "}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="pl-3">
                    <Grid item xs={6}>
                      <div className="hackathonCardParagraph">Submission</div>
                      <p className="hackathonCardHeader">Online</p>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="hackathonCardParagraph">
                        Registered users
                      </div>
                      <p className="hackathonCardHeader">
                        {hackathonDataDetails?.registeredUsersCount}{" "}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="pl-3">
                    <Grid item xs={6}>
                      <div className="hackathonCardParagraph">Team size</div>
                      <p className="hackathonCardHeader">
                        {hackathonDataDetails?.teamSize || "Individual"}{" "}
                      </p>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="hackathonCardParagraph">
                        Hackathon type
                      </div>
                      <p className="hackathonCardHeader">
                        {hackathonDataDetails?.type}
                      </p>
                    </Grid>
                  </Grid>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {hackathonDataDetails?.registration ==
                    "Registration open" ? (
                      hackathonDataDetails?.status == "Scheduled" ? (
                        hackathonDataDetails?.userRegistered ? (
                          <TervButtonFilled
                            text={"Registered"}
                            variant="outlined"
                            onClick={""}
                            style={{ width: "15rem" }}
                          />
                        ) : (
                          <TervButtonFilled
                            text={"Register for Hackathon"}
                            variant="outlined"
                            onClick={() => {
                              hackathonRegistration();
                            }}
                            style={{ width: "15rem" }}
                          />
                        )
                      ) : hackathonDataDetails?.status == "Live" ? (
                        hackathonDataDetails?.userRegistered ? (
                          hackathonDataDetails?.type == "Challenge" ? (
                            hackathonDataDetails?.submitted == true ? (
                              <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div>
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 14 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.47689 5.9987C6.24422 5.9987 6.81022 5.4327 6.81022 4.66536C6.81022 3.89803 6.24422 3.33203 5.47689 3.33203C4.70955 3.33203 4.14355 3.89803 4.14355 4.66536C4.14355 5.4327 4.70889 5.9987 5.47689 5.9987Z"
                                    fill="#1BA94C"
                                  />
                                  <path
                                    d="M12.333 0.667969H1.66634C0.931008 0.667969 0.333008 1.22864 0.333008 1.91797V10.0846C0.333008 10.774 0.931008 11.3346 1.66634 11.3346H12.333C13.0683 11.3346 13.6663 10.774 13.6663 10.0846V1.91797C13.6663 1.22864 13.0683 0.667969 12.333 0.667969ZM12.333 10.0013L1.66634 9.99397V2.0013L12.333 2.00864V10.0013Z"
                                    fill="#1BA94C"
                                  />
                                  <path
                                    d="M8.33333 4H11V5.33333H8.33333V4ZM9 6.66667H11V8H9V6.66667ZM7.95333 8.35733C7.95333 7.44133 6.836 6.5 5.47667 6.5C4.11733 6.5 3 7.44133 3 8.35733V8.66667H7.95333V8.35733Z"
                                    fill="#1BA94C"
                                  />
                                </svg> You already completed this hackathon
                              </div>
                            </div>
                            ) : (
                              <TervButtonFilled
                                text={"Take Challange"}
                                color="primary"
                                onClick={() => {
                                  if (
                                    sessionStorage.getItem(
                                      "viewPkgWithoutSignIn"
                                    ) === "true"
                                  ) {
                                    handleSignInModal();
                                    setAssessmentData(
                                      hackathonDataDetails.assessmentId
                                    );
                                  } else {
                                    handleAssessment(
                                      hackathonDataDetails.assessmentId
                                    );
                                  }
                                }}
                                style={{
                                  marginTop:
                                    sessionStorage.getItem(
                                      "viewPkgWithoutSignIn"
                                    ) === "true"
                                      ? "5rem"
                                      : 0,
                                }}
                              />
                            )
                          ) : (
                            <Link
                              to={{
                                pathname: `/hackathonSubmission/${hackathonDataDetails?.id}`,
                                state: hackathonDataDetails?.id,
                              }}
                            >
                              <TervButtonFilled
                                text={
                                  hackathonDataDetails?.submitted
                                    ? "View Submission"
                                    : "Submit Resource"
                                }
                                variant="outlined"
                                onClick={""}
                                style={{ width: "15rem" }}
                              />
                            </Link>
                          )
                        ) : (
                          <TervButtonFilled
                            text={"Register for Hackathon"}
                            variant="outlined"
                            onClick={() => {
                              hackathonRegistration();
                            }}
                            style={{ width: "15rem" }}
                          />
                        )
                      ) : hackathonDataDetails?.userRegistered ? (
                        <Link
                          to={{
                            pathname: `/hackathonSubmission/${hackathonDataDetails?.id}`,
                            state: hackathonDataDetails?.id,
                          }}
                        >
                          <TervButtonFilled
                            text={"View Submission"}
                            variant="outlined"
                            onClick={""}
                            style={{ width: "15rem" }}
                          />
                        </Link>
                      ) : (
                        <p>Sorry, Hackathond ends try some other </p>
                      )





                    ) : hackathonDataDetails?.userRegistered ? (
                      hackathonDataDetails?.type == "challenge" ? (
                        hackathonDataDetails?.submitted == true ? (
                          ""
                        ) : (
                          <TervButtonFilled
                            text={"Take challenge"}
                            variant="outlined"
                            onClick={() => {
                              takeAssessment(
                                hackathonDataDetails?.assessmentId
                              );
                            }}
                            style={{ width: "15rem" }}
                          />
                        )
                      ) : (
                        // <Link
                        //   to={{
                        //     pathname: `/hackathonSubmission/${hackathonDataDetails?.id}`,
                        //     state: hackathonDataDetails?.id,
                        //   }}
                        // >
                        //  <p>Hackathon completed</p>
                        // </Link>
                        <p>Hackathon completed</p>
                      )
                    ) : (
                      // <p>Registration Scheduled</p>

                      <p>Sorry, Registration ends for this Hackathon</p>
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </Grid>
}
        </Grid>
                    
      )}
    </div>
  );
}
export default hackathonRegister;
