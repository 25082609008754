/** @format */

import React from "react";
import { ContentHeader } from "./ContentHeader";
import { HomeContent } from "./HomeContent";
import styles from "./LearnGroup.module.scss";
import { uid } from "react-uid";
import { RadioChecked } from "../../../assets/icons/icons";
import { withStyles, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next"
import {
  useMediaQuery,
} from "@material-ui/core";
// import video from "../../../assets/Landing/project.mp4";
import LearnerImage1 from "../../../assets/Landing/Frame2.png";
import LearnerImage2 from "../../../assets/Landing/Frame 3.png";
import LearnerImage3 from "../../../assets/Landing/Frame 4.png";
import LearnerImage4 from "../../../assets/Landing/Frame 5.png";
import LearnerImage5 from "../../../assets/Landing/certificate2.png";
import LearnerImage6 from "../../../assets/Landing/global.png";
import vector from "../../../assets/Landing/Vector.png";

interface Props {
  whatsAppModal?: () => void;
  telegramAppModal?: () => void;
  isMobile: boolean;
  isTab: boolean;
}

const LearnGroup: React.FC<Props> = ({ isMobile, isTab }) => {
  const { t } = useTranslation();
  const smallScreen = useMediaQuery("(max-width: 1000px)");

  return (
    <div className={styles.main}>
      {smallScreen ? (
       <div>
       {/* Grid 1 */}
         <div className={styles.joinGroupContainer}>
         <div>
            <img src={LearnerImage1}  style={{ width: '100%', height: '100%', }}></img> 
   
           </div>
         <div style={{paddingTop:"2%"}}>
                {/* <p className={styles.header}>About Us</p> */}
              <h4 className={styles.Title}>
              Learn by Doing
              </h4>
              <p className={styles.sub}>
              Become a Super-Hero with fully integrated, new age learning platform with multi- lingual programming features in a seamless execution environment bringing you
              close to the real world with assistance to solve every questions with hints, test cases, video explanation and solutions. All in one workspace with no installation or setup time.
              </p>
            </div>
           
         </div>
       {/* Grid 2 */}
         <div className={styles.joinGroupContainer}>
         <div>
            {/* <img src={LearnerImage2}  style={{ width: '100%', height: '100%', }}></img>  */}
            <video style={{ width: '100%' }} autoPlay loop muted>
         <source src="/project.mp4" type="video/mp4" />
      </video>
            </div>
   
           <div style={{paddingTop:"2%"}}>
                {/* <p className={styles.header}>About Us</p> */}
              <h4 className={styles.Title}>
              Learn by Visualizing
              </h4>
              <p className={styles.sub}>
              Learn in a fun & faster way with our highly engaging visual videos connecting to real world problem statements providing
               an in-depth conceptual understanding. Step-by-step code walkthrough helps you visualize what happens in the computer memory with each and every line of code execution.
              </p>
            </div>
         </div>
        {/* Grid 3 */}
        <div className={styles.joinGroupContainer}>
        <div >
            <img src={LearnerImage3}  style={{ width: '100%', height: '100%', }}></img> 
           </div>
        <div style={{paddingTop:"2%"}}>
                {/* <p className={styles.header}>About Us</p> */}
              <h4 className={styles.Title}>
              Time - Space Complexity Analysis
              </h4>
              <p className={styles.sub}>
              Become a Super-Hero with fully integrated, new age learning platform with multi- lingual programming features in a seamless execution environment bringing you close
               to the real world with assistance to solve every questions with hints, test cases, video explanation and solutions. All in one workspace with no installation or setup time.
              </p>
            </div>
          
         </div>
        {/* Grid 4 */}
        <div className={styles.joinGroupContainer}>
        <div style={{ backgroundImage: `url(${vector})`, backgroundPosition: 'left', backgroundRepeat: 'no-repeat'  }}>
         <div style={{paddingTop:"14%"}}>
            <img src={LearnerImage4}  style={{ width: '100%', height: '100%', }}></img> 
            </div>
   
           </div>
           <div style={{paddingTop:"2%"}}>
                {/* <p className={styles.header}>About Us</p> */}
              <h4 className={styles.Title}>
              Rich performance analytics
              </h4>
              <p className={styles.sub}>
              With performance analytics, you can see the strengths, weaknesses and progress with unmatched clarity. Individual and topic-level insights help you discover the areas of improvement time to time. Get awarded with points, badges, and rank up against the other candidates.
              </p>
            </div>
         </div>
        {/* Grid 5 */}
        <div className={styles.joinGroupContainer}>
        <div >
            <img src={LearnerImage5}  style={{ width: '100%', height: '100%', }}></img> 
   
           </div>
        <div style={{paddingTop:"2%"}}>
                {/* <p className={styles.header}>About Us</p> */}
              <h4 className={styles.Title}>
              Industry recognized certification
              </h4>
              <p className={styles.sub}>
              Accelerate your career by earning an industry-recognized professional certification. With over 100,000+ professionals empowered, TERV certifications are recognized by the industry’s top employers. Certifications are awarded upon completion of our hand-picked courses.
              </p>
            </div>
        
         </div>
         {/* Grid 5 */}
         <div className={styles.joinGroupContainer}>
         <div >
            <img src={LearnerImage6}  style={{ width: '100%', height: '100%', }}></img> 
   
           </div>
           <div style={{paddingTop:"2%"}}>
                {/* <p className={styles.header}>About Us</p> */}
              <h4 className={styles.Title}>
              Job Support & vast employer network 
              </h4>
              <p className={styles.sub}>
              Don't settle. Our global community of hiring partners includes the best companies across a range of hot industries. You get to engage with potential employer ahead of completion through 1-1 mock interview with feedback session and frequent networking sessions.
              </p>
            </div>
         </div>
         </div>
      ):(
      <div>
    {/* Grid 1 */}
      <div className={styles.joinGroupContainer}>
      <div >
             {/* <p className={styles.header}>About Us</p> */}
           <h4 className={styles.Title}>
           Learn by Doing 
           </h4>
           <p className={styles.sub}>
           Become a Super-Hero with fully integrated, new age learning platform with multi- lingual programming features in a seamless execution environment bringing you close to th2
           real world with assistance to solve every questions with hints, test cases, video explanation and solutions. All in one workspace with no installation or setup time.
           </p>
         </div>
         <div style={{width:"50%"  }}>
         <img src={LearnerImage1}  style={{ width: '100%', height: '100%', }}></img> 

        </div>
      </div>
    {/* Grid 2 */}
      <div className={styles.joinGroupContainer}>
      <div>
         {/* <img src={LearnerImage2}  style={{ width: '100%', height: '100%', }}></img>  */}
         <video style={{ width: '70%', }} autoPlay loop muted>
         <source src="/project.mp4" type="video/mp4" />
      </video>
         </div>

        <div >
             {/* <p className={styles.header}>About Us</p> */}
           <h4 className={styles.Title}>
           Learn by Visualizing
           </h4>
           <p className={styles.sub}>
           Learn in a fun & faster way with our highly engaging visual videos connecting to real world problem statements providing an in-depth conceptual understanding. Step-by-step code walkthrough helps you visualize what happens in the computer memory with each and every line of code execution.
           </p>
         </div>
      </div>
     {/* Grid 3 */}
     <div className={styles.joinGroupContainer}>
     <div >
             {/* <p className={styles.header}>About Us</p> */}
           <h4 className={styles.Title}>
           Time - Space Complexity Analysis
           </h4>
           <p className={styles.sub}>
           Become a Super-Hero with fully integrated, new age learning platform with multi- lingual programming features in a seamless execution environment bringing you 
           close to the real world with assistance to solve every questions with hints, test cases, video explanation and solutions. All in one workspace with no installation or setup time.
           </p>
         </div>
        <div >
         <img src={LearnerImage3}  style={{ width: '100%', height: '100%', }}></img> 

        </div>
      </div>
     {/* Grid 4 */}
     <div className={styles.joinGroupContainer}>
     <div style={{ backgroundImage: `url(${vector})`, backgroundPosition: 'top left', backgroundRepeat: 'no-repeat'  }}>
      <div style={{paddingTop:"14%"}}>
         <img src={LearnerImage4}  style={{ width: '100%', height: '100%', }}></img> 
         </div>

        </div>
        <div >
             {/* <p className={styles.header}>About Us</p> */}
           <h4 className={styles.Title}>
           Rich performance analytics 
           </h4>
           <p className={styles.sub}>
           With performance analytics, you can see the strengths, weaknesses and progress with unmatched clarity. Individual and topic-level insights help you discover the areas of improvement time to time. Get awarded with points, badges, and rank up against the other candidates.
           </p>
         </div>
      </div>
     {/* Grid 5 */}
     <div className={styles.joinGroupContainer}>
     <div >
             {/* <p className={styles.header}>About Us</p> */}
           <h4 className={styles.Title}>
           Industry recognized certification
           </h4>
           <p className={styles.sub}>
           Accelerate your career by earning an industry-recognized professional certification. With over 100,000+ professionals
            empowered, TERV certifications are recognized by the industry’s top employers. Certifications are awarded upon completion of our hand-picked courses.
           </p>
         </div>
        <div>
         <img src={LearnerImage5}  style={{ width: '100%', height: '100%', }}></img> 

        </div>
      </div>
      {/* Grid 6 */}
      <div className={styles.joinGroupContainer}>
      <div >
         <img src={LearnerImage6}  style={{ width: '100%', height: '100%', }}></img> 

        </div>
        <div >
             {/* <p className={styles.header}>About Us</p> */}
           <h4 className={styles.Title}>
           Job Support & vast employer network
           </h4>
           <p className={styles.sub}>
           Don't settle. Our global community of hiring partners includes the best companies across a range of hot industries.
            You get to engage with potential employer ahead of completion through 1-1 mock interview with feedback session and frequent networking sessions.
           </p>
         </div>
      </div>
      </div>
      )}
    </div>
  );
};

export default LearnGroup;