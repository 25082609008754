import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@material-ui/icons/ArrowDropUpRounded";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  adornRoot: {
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#F5F9FB",
  },
  textFieldRoot: {
    fontFamily: "Poppins",
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0.3rem",
    },
  },
  iconButtonRoot: {
    height: "1.25rem",
    width: "1.25rem",
    border: "1px solid #E2E5EB",
    borderRadius: 5,
  },
  iconButtonRootOne: {
    marginTop: "0.5rem",
  },
  iconButtonRootTwo: {
    marginBottom: "0.5rem",
  },
});

interface Props {
  value: number;
  max: number;
  onChange: (a: number) => void;
  onBlur?: () => void;
  inputId?: string;
  disableNegative?: boolean;
  disablePostive?: boolean;
  className?: string;
  disable?: boolean;
  type: undefined | string;
}

const StyledNumberInput: React.FC<Props> = ({
  value,
  onChange,
  onBlur,
  inputId,
  disableNegative,
  max,
  disablePostive,
  className,
  disable,
  type,
}) => {
  const classes = useStyles();
  const [min, setMin] = useState(0);
  return (
    <TextField
      variant="outlined"
      size="small"
      value={value}
      id={inputId}
      type={type ? type : "number"}
      onChange={(e) => onChange(+e.target.value)}
      onBlur={onBlur}
      classes={{ root: `${classes.textFieldRoot} ${className}` }}
      disabled={disable}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" classes={{ root: classes.adornRoot }}>
            <IconButton
              size="small"
              classes={{
                root: `${classes.iconButtonRoot} ${classes.iconButtonRootOne}`,
              }}
              onClick={() => onChange(value + 1)}
              disabled={disablePostive || disable || value == max}
            >
              <ArrowDropUpRoundedIcon />
            </IconButton>
            <IconButton
              size="small"
              classes={{
                root: `${classes.iconButtonRoot} ${classes.iconButtonRootTwo}`,
              }}
              onClick={() => onChange(value - 1)}
              disabled={disableNegative || disable || value == min}
            >
              <ArrowDropDownRoundedIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default StyledNumberInput;
