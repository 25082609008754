import { Skeleton } from "@material-ui/lab";
import { circleCheckSolid, circleCrossIcon } from "assets/icons/icons";
import { uid } from "react-uid";
import styles from "./GradingCenter.module.scss";
import GradingCD from "./GradingCD";

const GradingCenter = ({ currentQuestion, activeSection, loading }) => {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "1rem 2rem",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
        gridRow: "2/-1",
        gridColumn: "1/10",
        margin: "1rem 1rem 0 1rem",
      }}
    >
      {loading ? (
        <Skeleton animation="wave" height={30} width="80%" />
      ) : (
        <div style={{ fontSize: "1.2rem", fontWeight: "500", color: "#000" }}>
          {activeSection}
        </div>
      )}
      <hr />
      {loading ? (
        <Skeleton animation="wave" height={150} width="80%" />
      ) : (
        <>
          <div style={{ fontSize: "0.9rem", fontWeight: "600", color: "#000" }}>
            Question {currentQuestion?.serialNo}
          </div>
          <p
            style={{ fontSize: "1rem", color: "#000" }}
            dangerouslySetInnerHTML={{ __html: currentQuestion?.question }}
          />
        </>
      )}

      {loading ? (
        <Skeleton animation="wave" height={250} width="80%" />
      ) : currentQuestion?.type === "MC" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "35rem",
          }}
        >
          {(currentQuestion?.optionsList || []).map((ele, ind) => (
            <div
              key={uid(ind)}
              className={`${styles.option}
            ${
              currentQuestion?.solutionMap?.selectedOption &&
              currentQuestion?.solutionMap?.selectedOption.replace(
                /[^a-z0-9]/g,
                ""
              ) === ele?.id
                ? currentQuestion?.solutionMap?.isCorrectOption
                  ? styles.correctOption
                  : styles.wrongOption
                : ""
            } ${ele.answer && styles.correctOption}
            `}
            >
              <p
                dangerouslySetInnerHTML={{ __html: ele?.optionValue }}
                style={{ padding: 0, margin: 0, maxWidth: "25rem" }}
              />
              {currentQuestion?.solutionMap?.selectedOption &&
                currentQuestion?.solutionMap?.selectedOption.replace(
                  /[^a-z0-9]/g,
                  ""
                ) === ele?.id && (
                  <div>
                    {currentQuestion?.solutionMap?.isCorrectOption
                      ? circleCheckSolid({ size: 16, color: "#5AC62F" })
                      : circleCrossIcon({ size: 16, color: "#EB5757" })}{" "}
                    Student's choice
                  </div>
                )}
            </div>
          ))}
        </div>
      ) : currentQuestion?.type === "CD" || currentQuestion?.type == "DESC" ? (
        <GradingCD currentQuestion={currentQuestion} />
      ) : (
        ""
      )}
    </div>
  );
};

export default GradingCenter;
