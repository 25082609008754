import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { GetObjectFromString } from "../utils/GetObjectFromString";
import Select from "react-select";
import { Button } from "reactstrap";
import styles from "./UserAssessmentMarks.module.scss";
import Apihelper from "../../api/apiHelper";
import api from "../../api/baseConfig";
import { connect } from "react-redux";
import { getCustomers } from "../../store/StudentAndCustomer/actions";
import { GetAllUsers } from "../CustomerManagement/utils/GetUsers";
import SummaryTable from "../virtualLab/report-components/summaryTable";
import ViewSolutionModal from "../virtualLab/report-components/viewSolutionModal";
import Skeleton from "@material-ui/lab/Skeleton";
import { sessionPrivileges } from "privileges";

const correctIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10H0ZM9.42933 14.28L15.1867 7.08267L14.1467 6.25067L9.23733 12.3853L5.76 9.488L4.90667 10.512L9.42933 14.2813V14.28Z"
      fill="#5AC62F"
    />
  </svg>
);
const wrongIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99977 0.759964C7.54917 0.759964 5.19894 1.73346 3.4661 3.4663C1.73326 5.19913 0.759766 7.54936 0.759766 9.99996C0.759766 12.4506 1.73326 14.8008 3.4661 16.5336C5.19894 18.2665 7.54917 19.24 9.99977 19.24C12.4504 19.24 14.8006 18.2665 16.5334 16.5336C18.2663 14.8008 19.2398 12.4506 19.2398 9.99996C19.2398 7.54936 18.2663 5.19913 16.5334 3.4663C14.8006 1.73346 12.4504 0.759964 9.99977 0.759964ZM15.2677 13.3671L13.3658 15.269L9.99977 11.9019L6.63267 15.2679L4.73077 13.366L8.09897 9.99996L4.73187 6.63286L6.63377 4.73206L9.99977 8.09806L13.3669 4.73096L15.2688 6.63286L11.9006 9.99996L15.2677 13.3671Z"
      fill="#E22B12"
    />
  </svg>
);
const emptyIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="18"
      height="18"
      rx="4"
      stroke="#848484"
      strokeWidth="2"
    />
  </svg>
);

const ChooseOption = (props) => {
  return (
    <Paper elevation={3} className={styles.optionEmptySec}>
      <div className={styles.bluebg}></div>
      <p className={styles.optionCenter}>{props.centerContent}</p>
    </Paper>
  );
};

const ValueBoardBox = ({ title, value, hideLast }) => {
  return (
    <div className={styles.dashMainBox}>
      <div className={styles.dashboardBox}>
        <span className={styles.dashTitle}>{title}</span>
        <span className={styles.dashValue}>{value}</span>
      </div>
      {!hideLast && <div className={styles.divLiner}></div>}
    </div>
  );
};

function UserAssessmentMarks(props) {
  const Priv = sessionPrivileges().userprivileges || [];
  const privileges = sessionPrivileges();
  const [loading, setLoading] = useState(false);
  const [loadingDot, setLoadingDot] = useState(false);
  const [loadingStd, setLoadingStd] = useState(false);
  const [data, setData] = useState(null);
  const [assessment, setAssessment] = useState([]);
  const [students, setStudents] = useState([]);
  const [sectionTab, setSectionTab] = useState({});
  const [questionList, setQuestionList] = useState([]);
  const [questionData, setQuestionData] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [assessmentId, setAssessmentId] = useState("");
  const [studentId, setStudentId] = useState("");
  const [customerId, setCustomerId] = useState(() =>
    Priv.includes("MANAGE_CUSTOMER")
      ? props?.location?.params?.customerId || ""
      : sessionStorage.getItem("customer_id") || ""
  );

  const customers = useMemo(
    () =>
      (props?.Customers || []).sort(
        ({ name: n1 = "" }, { name: n2 = "" }) =>
          n1?.toLowerCase() < n2?.toLowerCase()
      ),
    [props?.Customers]
  );

  const customersList = [];
  customers.forEach(function (item) {
    customersList.push({ label: item.name, value: item.id });
  });

  const assessmentList = [];
  assessment.forEach(function (item) {
    assessmentList.push({ label: item.name, value: item.id });
  });

  useEffect(() => {
    if (assessmentId && studentId) {
      getAssessmentReport();
    }
  }, [assessmentId, studentId]); 
  
  const studentList = [];
  students?.forEach(function (item) {
    studentList.push({
      label: item.firstName + " " + item.lastName,
      value: item.userId,
    });
  });

  useEffect(() => {
    if (Priv.includes("MANAGE_CUSTOMER")) {
      props.dispatch(getCustomers());
    } else {
      getAssessmentsByCustomer(sessionStorage.getItem("customer_id"));
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (customerId && customerId !== "") {
      getAssessmentsByCustomer(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (assessmentId && assessmentId !== "") {
      getStudentsByAssessment();
    }
  }, [assessmentId]);

  useEffect(() => {
    if (studentId && studentId !== "") {
      getAssessmentReport();
    }
  }, [studentId]);

  //getStudents
  async function getStudentsByAssessment() {
    setLoadingStd(true);
    await Apihelper.axiosCall(
      `${api.baseURL}${api.assessmentController.getAssessmentUsersDetail}${assessmentId}`,
      "get"
    )
      .then((users) => {
        setLoadingStd(false);
        setStudents(users);
        // setStudentId(users?.find((e) => e.id === users["id"]));
      })
      .catch((e) => {});
  }

  //getAssessmentReport
  async function getAssessmentReport() {
    setLoading(true);
    await Apihelper.axiosCall(
      `${api.baseURL}${api.assessmentController.getAssessmentSectionSummary}${assessmentId}/${studentId}`,
      "get"
    )
      .then((d) => {
        setLoading(false);
        setData(d);
        //question-summary
        if (d && d.sectionSummary && d.sectionSummary.length) {
          const apiQuestionList = d?.sectionSummary;
          const questionList = apiQuestionList[0]?.questionSummary;
          tableData(questionList);
          setSectionTab(0);
        }
      })
      .catch((e) => {});
  }
  //getAssessmentsList
  async function getAssessmentsByCustomer(customer) {
    setLoadingDot(true);
    await Apihelper.axiosCall(
      `${api.baseURL}${api.assessmentController.getAssessmentNameListByCustomer}${customer}`,
      "get"
    )
      .then((data) => {
        setLoadingDot(false);
        setAssessment(data);
        setAssessmentId(data.find((e) => e.id === data["id"]));
      })
      .catch((e) => {});
  }

  const tableData = (questionList = []) => {
    const row = [];
    questionList.forEach((list, i) => {
      row.push({
        data: [
          i + 1,
          list.questionName,
          list.status === "wrong"
            ? wrongIcon
            : list.status === "correct"
            ? correctIcon
            : emptyIcon,
          list.difficulty,
          list.completedTime,
          `${list.marksGained}/${list.marksAllocated}`,
        ],
        modal: list,
      });
    });
    setQuestionList(row);
  };

  const toggleViewModal = () => setViewModal((p) => !p);

  const resetHandle = () => {
    tableData();
    setCustomerId("");
    setStudentId("");
    setAssessmentId("");
    setQuestionList("");
    setData(null);
  };
  return (
    <>
     {privileges.isSuperAdmin && (
    <div className="page-content">
      <ViewSolutionModal
        close={() => {
          toggleViewModal();
        }}
        isOpen={viewModal}
        questionData={questionData}
      />
      <Paper elevation={1} className={styles.headerBox}>
        <h3>User Assessment Report</h3>
        <div className={styles.selectWrapper}>
          <div className={styles.selectBox}>
            <Select
              placeholder="Choose a Customer"
              value={GetObjectFromString(customersList, customerId)}
              onChange={(e) => {
                setCustomerId(e.value);
                setQuestionList("");
                setStudentId("");
                setData(null);
              }}
              options={customersList}
              maxMenuHeight={120}
            />
          </div>
          <div className={styles.selectBox}>
            <Select
              placeholder="Choose a Assessment"
              value={GetObjectFromString(assessmentList, assessmentId)}
              onChange={(e) => {
                setAssessmentId(e.value);
                setQuestionList("");
                setData(null);
              }}
              options={assessmentList}
              maxMenuHeight={120}
              isDisabled={!customerId}
              isLoading={loadingDot}
            />
          </div>
          <div className={styles.selectBox}>
            <Select
              placeholder="Choose a Student"
              value={GetObjectFromString(studentList, studentId)}
              onChange={(e) => {
                setStudentId(e.value);
              }}
              options={studentList}
              maxMenuHeight={120}
              isDisabled={!assessmentId}
              isLoading={loadingStd}
            />
          </div>
          <div className={styles.selectBox}>
            <div className={styles.resetLink} onClick={() => resetHandle()}>
              Reset Selection
            </div>
          </div>
        </div>
      </Paper>
      {loading ? (
        <div>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </div>
      ) : data ? (
        <div>
        {data?.sectionSummary?.length > 0 ? (
        <Paper elevation={1} className={styles.mainBox}>
          <h3 className={styles.nameOfUser}>
            {""} {data?.assessmentName}
          </h3>
          <div className={styles.dashboardBoxWrapper}>
            <ValueBoardBox
              title="No of Section"
              value={data?.numberOfSection || 0}
            />
            <ValueBoardBox
              title="Total Questions"
              value={data?.totalNumberOfQuestion || 0}
            />
            <ValueBoardBox
              title="Right Answer"
              value={data?.totalRightAnswer || 0}
            />
            <ValueBoardBox
              title="Wrong Answer"
              value={data?.totalWrongAnswer || 0}
            />
            <ValueBoardBox
              title="Un Attempted"
              value={data?.totalUnattemptedQuestion || 0}
            />
            <ValueBoardBox
              title="Total Score"
              value={`${parseFloat(data?.totalScore || 0).toFixed(1)}%`}
            />
            <ValueBoardBox
              title="Time Taken"
              value={`${(data?.totalTimeTaken / 60 || 0).toFixed(2)} Min`}
              hideLast
            />
          </div>

          {data?.sectionSummary?.length > 0 && (
          <div className={styles.sectionTab}>
            {data?.sectionSummary.map((j, id) => (
              <Button
                color={sectionTab === id ? "primary" : "default"}
                className={styles.sectionTabBtn}
                onClick={() => {
                  setSectionTab(id);
                  tableData(j.questionSummary);
                }}
              >
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    textTransform: "capitalize",
                  }}
                >
                  {j.sectionName}
                </span>
                <span style={{ fontSize: 16 }}>
                  {j.noOfQuestions} questions
                </span>
              </Button>
            ))}
          </div>
          )}

          <SummaryTable
            tableHeader={[
              "Q.No",
              "Question Name",
              "Status",
              "Difficulty",
              "Completed Time(sec)",
              "Marks",
              "Solution",
            ]}
            tableData={questionList || []}
            viewModal={(data) => {
              toggleViewModal();
              setQuestionData(data);
            }}
            assessmentData={data}
          />
        </Paper>
        ):(
          <ChooseOption
          centerContent={ "No matching record found for the selected assessment." }
        />
        )}
        </div>
      ) : (
        <ChooseOption
          centerContent={
              "Choose options from dropdown above..."
          }
        />
      )}
    </div>
     )}
    </>
  );
}

const mapStateToProps = (state) => ({
  Customers: state.StudentAndCustomerReducer.Customers,
});

export default connect(mapStateToProps, null)(withRouter(UserAssessmentMarks));
