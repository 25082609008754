/** @format */

import { useState, useEffect } from "react";
import BookACall from "./BookACall";
import Courses from "./Courses";
import Banner from "./Banner";
import DifferentLearning from "./DifferentLearning";
import NewLearning from "./NewLearning";
import LearnGroup from "./LearnGroup";
import FellowShip from "./FellowShip";
import CookieConsent from "react-cookie-consent";
import Features from "./Features";
import Footer from "./Footer";
import HeroCarousel from "./HeroCarousel";
import JoinGroup from "./JoinGroup";
import Learning from "./Learning";
import Marquee from "./Marquee";
import { PerfectCourseBanner } from "./PerfectCourseBanner";
import Resources from "./Resources";
import Testimonials from "./Testimonials";
import TandC from "./TandC";
import SafePayments from "./SafePayments";
import SignInModal from "components/VerticalLayout/SignInModal";
import { ModalToggle } from "pages/utils/ModalToggle";
import WhatsappGroupModal from "./WhatsappGroupModal";
import TelegramGroupModal from "./TelegramGroupModal";
import FloatWhatsapp from "./FloatWhatsapp";
import { HomeContent } from "./HomeContent";
import { connect, useSelector, useDispatch } from "react-redux";
import { handleLoggedIn } from "../../../store/loggedIn/actions";
import styles from "./Home.module.scss";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

function Techruit() {
  const [openSignin, setOpenSignin] = useState(false);
  // const [whatsappModal, setWhatsappModal] = useState(false);
  // const [telegramModal, setTelegramModal] = useState(false);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isTab = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const dispatch = useDispatch();

  const isLoggedIn = useSelector(
    (state: { [key: string]: any }) => state.LoggedInReducer,
  );

  useEffect(() => {
    if (sessionStorage.length > 0) {
      sessionStorage.clear();
    }

    if (localStorage.length > 0) {
      localStorage.clear();
    }

    if (isLoggedIn) {
      dispatch(
        handleLoggedIn({
          state: false,
          prepare: false,
          assessment: false,
          practice: false,
          dashboard: false,
          cloudLab: false,
        }),
      );
    }
  }, []);

  const handleOpenSignin = () => setOpenSignin(ModalToggle);

  // const whatsappToggle = () => setWhatsappModal(ModalToggle);
  // const telegramToggle = () => setTelegramModal(ModalToggle);

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept all cookies"
        cookieName="tervCookies"
        containerClasses={styles.cookieContainer}
        style={{ ...commonCookieStyles, ...cookieContainerStyle }}
        buttonStyle={{ ...commonCookieStyles, ...cookieButtonStyle }}
        expires={150}
      >
        {HomeContent.Cookie.description}
      </CookieConsent>
      <main className={styles.home__main}>
        {/* <HeroCarousel isMobile={isMobile} isTab={isTab} /> */}
      
        <div className={styles.content__main}>

          <Marquee />
          <NewLearning isMobile={isMobile} />
          <LearnGroup isMobile={isMobile} isTab={isTab} />
          <Courses isMobile={isMobile} isTab={isTab} /> 
          <div style={{paddingTop:"2%"}}>
          <FellowShip isMobile={isMobile} />
          </div>
          <JoinGroup isMobile={isMobile} isTab={isTab} />
          


          {/* <Learning /> */}
          {/* <Courses isMobile={isMobile} isTab={isTab} /> */}
          {/* <PerfectCourseBanner /> */}
          {/* <DifferentLearning isMobile={isMobile} /> */}
          {/* <Features /> */}
          {/* <JoinGroup isMobile={isMobile} isTab={isTab} /> */}
          <Resources />
   
          <BookACall isMobile={isMobile} isTab={isTab} />
        </div>
        <Footer isMobile={isMobile} isTab={isTab} />
        
        <TandC isMobile={isMobile} isTab={isTab} />
        <SafePayments isMobile={isMobile}/>
        {/* <FloatWhatsapp isMobile={isMobile} isTab={isTab} /> */}
      </main>
      <SignInModal
        fromAssessment={false}
        fromLab={false}
        openInstructions={null}
        modal={openSignin}
        setModal={handleOpenSignin}
        signInQuestion={null}
        pkgId={null}
        assessmentId={null}
      />
      {/* <WhatsappGroupModal
        appName="Whatsapp"
        isOpen={whatsappModal}
        close={whatsappToggle}
      />
      <TelegramGroupModal
        appName="Telegram"
        isOpen={telegramModal}
        close={telegramToggle}
      /> */}
    </>
  );
}

export default connect(null, null)(Techruit);

const cookieContainerStyle = {
  background: "#0b2239",
  margin: "0rem",
  fontWeight: 400,
  fontSize: "0.9rem",
  zIndex: 2000,
};

const cookieButtonStyle = {
  backgroundColor: "#1ba94c",
  color: "#fff",
  fontSize: "0.9rem",
  fontWeight: 500,
  borderRadius: 3,
};

const commonCookieStyles = { padding: "0.5rem 1rem" };
