import Select from "react-select";
import { Input, Label } from "reactstrap";
import CustomSelect from "components/CustomSelect";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";

const useStyles = makeStyles({
  resetButton: {
    fontSize: 19,
    cursor: "pointer",
    borderRadius: "1.5rem",
    padding: 1,
    "&:hover": {
      backgroundColor: "#f8edeb",
    },
    transition: "background-color 0.3s",
  },
});

const validIndicator = (value) => (
  <>
    {value}
    <span style={{ color: "red" }}>*</span>
  </>
);

export default function HandleStudentSelection({
  students,
  departments,
  formData,
  handleFormData,
  style,
  resetFilter,
  errors,
  studentsCount,
  allFilterTypes,
  setAssignStudents,
  checkIfBatchNameExists,
}) {
  const classes = useStyles();

  const shouldDisableSelect =
    formData.filterType &&
    formData.filterType !== "custom_batch" &&
    formData.filterType !== "all" &&
    formData.filterType !== "only" &&
    formData.filterType !== "except";
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Label>{validIndicator("Assign Students")}</Label>
        <Select
          options={allFilterTypes}
          onChange={(value) => {
            const newFilterType = value.value;
            const selectedStudents = newFilterType === "all" ? formData.selectedStudents : [];
            
            handleFormData([
              { key: "filterType", value: newFilterType },
              { key: "selectedStudents", value: selectedStudents },
            ]);
          }}
          value={GetObjectFromString(allFilterTypes, formData.filterType)}
          styles={style}
        />
        {setAssignStudents == true && formData.filterType?.length == 0 ? (
          <span style={{ color: "red" }}>Please choose a valid filter</span>
        ):(
          <span></span>
        )}
      </Grid>
      {formData.filterType === "custom_batch" && (
        <Grid item xs={6}>
          <Label>{validIndicator("Create a new batch name:")}</Label>
          <Input
            value={formData.batchName}
            onChange={(e) => handleFormData("batchName", e.target.value)}
            onBlur={(e) => checkIfBatchNameExists(e.target.value)}
          />
          {errors.batchName && (
            <span style={{ color: "red" }}>{errors.batchName}</span>
          )}
        </Grid>
      )}
      {formData.filterType &&
        formData.filterType !== "all" &&
        formData.filterType !== "only" &&
        formData.filterType !== "except" && (
          <Grid item xs={6}>
            <Label>Department</Label>
            <Select
              options={departments}
              onChange={(value) => handleFormData("department", value.value)}
              value={GetObjectFromString(departments, formData.department)}
              styles={style}
              isDisabled={shouldDisableSelect}
            />
          </Grid>
        )}
      {formData.filterType === "custom_batch" && (
        <Grid item xs={6}>
          <Label>
            Filter by reg no&nbsp;&nbsp;
            <RotateLeftIcon
              classes={{ root: classes.resetButton }}
              onClick={resetFilter}
            />
          </Label>
          <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
            <Input
              value={formData.regNoFrom}
              onChange={(e) => handleFormData("regNoFrom", e.target.value)}
            />
            <div>To</div>
            <Input
              value={formData.regNoTo}
              onChange={(e) => handleFormData("regNoTo", e.target.value)}
            />
          </div>
        </Grid>
      )}
      {formData.filterType && formData.filterType !== "all" && (
        <Grid item xs={formData.filterType === "custom_batch" ? 12 : 6}>
          <Label stule={{ position: "relative" }}>
            {validIndicator("Select students")}&nbsp;&nbsp;
            <Badge
              badgeContent={studentsCount}
              max={9999}
              color="primary"
              style={{ marginTop: 10, marginLeft: 15 }}
            />
          </Label>
          <CustomSelect
            options={students}
            onChange={(arr) => {
              handleFormData("selectedStudents", arr);
            }}
            value={formData.selectedStudents}
            styles={style}
            isDisabled={shouldDisableSelect}
          />
         {setAssignStudents == true && formData.selectedStudents?.length == 0 ? (
          <span style={{ color: "red" }}>{errors.selectedStudents}</span>
        ):(
          <span></span>
        )}
        </Grid>
      )}
    </Grid>
  );
}
