import { useState, useEffect } from "react";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Profile from "../../../assets/images/users/default-avatar.svg";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ReplyIcon from "@material-ui/icons/Reply";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { useParams } from "react-router-dom";
import apiHelper from "api/apiHelper";
import api from "../../../api/baseConfig";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ViewDiscussion from "./view-discussion";
import { useUserDetails } from "hooks/use-user-details";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import {
  micIcon,
  PracIcon,
  LiveClassIcon,
  AssessmentIcon,
} from "./../../../assets/icons/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  cardStyle: {
    maxWidth: "700px",
    backgroundColor: "#F6F6F7",
    border: " 1px solid #D8D6DB",
    boxSizing: "border-box",
    borderRadius: "5px",
  },

  accordionStyle: {
    maxWidth: "720px",
    backgroundColor: "#F6F6F7",
    // border: " 0.5px solid #D8D6DB",
    borderBottom: " 0.5px solid #D8D6DB",
    paddingTop: "2%",
    paddingBottom: "2%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "68.33%",
    flexShrink: 0,
    fontWeight: "700",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "26px",
    color: "#323036",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: "68.33%",
    flexShrink: 0,
    fontWeight: "700",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "13px",
    lineHeight: "26px",
  },
  replyCardStyle: {
    maxWidth: "650px",
    backgroundColor: "#F6F6F7",
    border: " 1px solid #D8D6DB",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={4} variant="filled" {...props} />;
}
export default function ShowDiscussion({ resourceName, ...props }) {
  const classes = useStyles();
  const [edit, setEdit] = useState(true);
  const [open, setOpen] = useState(false);
  const userDetails = useUserDetails();
  const { courseId } = useParams();
  // var courseResource =[];
  const [courseResource, setCourseResource] = useState([]);
  const [content, setContent] = useState(false);
  const [viewDiscussion, setViewDiscussion] = useState(false);
  const [editorState, setEditorState] = useState("");
  const [editorStates, setEditorStates] = useState("");
  const [contentShow, setContentShow] = useState(true);
  const [resourceDiscussion, setResourceDiscussion] = useState([]);
  const [discussionModuleList, setDiscussionModuleList] = useState([]);
  const [discussionCount, setDiscussionCount] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [courseResourceId, setCourseResourceId] = useState({});

  useEffect(() => {
    setCourseResource(props.data);
  }, [props.data]);

  useEffect(() => {
    getCourseAllContent();
  }, []);

  const handleSaveNote = async (data) => {
    const addDiscussion = {
      userId: userDetails.email,
      courseId: data.courseId,
      courseModuleId: data.courseModuleId,
      courseResourceId: data.courseResourceId,
      courseDiscussionId: data.id,
      isActive: true,
      courseResourceName: data.courseResourceName,
      userName: userDetails.name,
      discussionContent: editorStates.getCurrentContent().getPlainText(),
    };

    const notes = await apiHelper.axiosCallPostsave(
      `${api.baseURL}${api.courseController.saveDiscussion}`,
      "post",
      addDiscussion
    );
    if (notes !== "") {
      props.discussion_update();
      setEditorStates("");
      setOpen(true);
    }
  };
  const handleReplyDiscussion = () => {};

  const getCourseAllContent = async () => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.courseController.getCourseDiscussion}${courseId}`,
        "get"
      );
      if (data !== []) {
        setDiscussionModuleList(data.modules);
        setDiscussionCount(data.courseDiscussionCount);
      }
    } catch (e) {}
  };

  const handleback = () => {
    setEdit(true);
    setContentShow(false);
    getCourseAllContent();
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangePage = (id, modId, resName) => {
    const courseData = { id: id, moduleId: modId, resourceName: resName };
    setCourseResourceId(courseData);
    setViewDiscussion(true);
    setContentShow(false);
    setEdit(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setEditorStates("");
  };

  return (
    <>
      {contentShow && (
        <div
          className="view180"
          style={{
            background: "#FFFFFF",
            padding: "0px 0px",
            height: "100%",
            border: "1px solid #F2F1F3",
          }}
        >
          {/* <Button
            variant="outlined"
            className="back-button"
            onClick={handleback}
          >
            Back
          </Button> */}
          {/* <br /> */}
          {/* <Button variant="outlined" className="show-note-button mt-3">
            All content discussions
          </Button> */}

          <p className="text-muted mt-3">
            {discussionCount} discussions for this course
          </p>

          {discussionModuleList.map((data, index) => {
            return (
              <div style={{ padding: "0px 0px 0px 0px" }}>
                <Accordion
                  className={classes.accordionStyle}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {data.moduleName}
                    </Typography>
                    <Typography className={classes.secondaryHeading}>
                      {data.moduleDiscussionCount} Discussions
                    </Typography>
                  </AccordionSummary>

                  {data.moduleResources.length > 0 &&
                    data.moduleResources.map((resourceData) => {
                      return (
                        <div style={{ background: "#FFFFFF" }}>
                          <AccordionDetails
                            style={{ padding: "2px 12px 2px 12px" }}
                          >
                            <div
                              className="discussion-accordion"
                              style={{ border: "1px solid #F2F1F3" }}
                            >
                              <ul
                                className="list-inline list-group"
                                style={{
                                  paddingTop: "15px",
                                  paddingBottom: "15px",
                                  paddingLeft: "12px",
                                  paddingRight: "12px",
                                }}
                              >
                                <li className="list-group-item border-0 bg-white">
                                  {(resourceData.resourceType.toLowerCase() ===
                                    "Video".toLowerCase() ||
                                    resourceData.resourceType.toLowerCase() ===
                                      "Link".toLowerCase()) && (
                                    <a>
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.0001 1.6665C5.40508 1.6665 1.66675 5.40484 1.66675 9.99984C1.66675 14.5948 5.40508 18.3332 10.0001 18.3332C14.5951 18.3332 18.3334 14.5948 18.3334 9.99984C18.3334 5.40484 14.5951 1.6665 10.0001 1.6665ZM10.0001 16.6665C6.32425 16.6665 3.33341 13.6757 3.33341 9.99984C3.33341 6.324 6.32425 3.33317 10.0001 3.33317C13.6759 3.33317 16.6667 6.324 16.6667 9.99984C16.6667 13.6757 13.6759 16.6665 10.0001 16.6665Z"
                                          fill="#7C7887"
                                        />
                                        <path
                                          d="M7.5 14.1668L14.1667 10.0002L7.5 5.8335V14.1668Z"
                                          fill="#7C7887"
                                        />
                                      </svg>

                                      <span className="text-muted">
                                        &nbsp;{resourceData.resourceName}(
                                        {resourceData.resourceDiscussionCount})
                                      </span>
                                    </a>
                                  )}
                                  {resourceData.resourceType.toLowerCase() ===
                                    "Study material".toLowerCase() && (
                                    <a>
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M17.5001 2.5H11.6667C11.0242 2.5 10.4434 2.75083 10.0001 3.15C9.55675 2.75083 8.97591 2.5 8.33342 2.5H2.50008C2.03925 2.5 1.66675 2.87333 1.66675 3.33333V15.8333C1.66675 16.2942 2.03925 16.6667 2.50008 16.6667H7.29842C7.73675 16.6667 8.16675 16.845 8.47675 17.155L9.41092 18.0892C9.41842 18.0967 9.42841 18.0992 9.43591 18.1067C9.50758 18.1725 9.58758 18.2308 9.68091 18.27C9.68175 18.27 9.68175 18.27 9.68258 18.27C9.78342 18.3117 9.89091 18.3333 10.0001 18.3333C10.1092 18.3333 10.2167 18.3117 10.3176 18.27C10.3184 18.27 10.3184 18.27 10.3192 18.27C10.4126 18.2308 10.4926 18.1725 10.5642 18.1067C10.5717 18.0992 10.5817 18.0967 10.5892 18.0892L11.5234 17.155C11.8334 16.845 12.2634 16.6667 12.7017 16.6667H17.5001C17.9609 16.6667 18.3334 16.2942 18.3334 15.8333V3.33333C18.3334 2.87333 17.9609 2.5 17.5001 2.5ZM7.29842 15H3.33341V4.16667H8.33342C8.79342 4.16667 9.16675 4.54083 9.16675 5V15.5742C8.61841 15.205 7.96425 15 7.29842 15ZM16.6667 15H12.7017C12.0359 15 11.3817 15.205 10.8334 15.5742V5C10.8334 4.54083 11.2067 4.16667 11.6667 4.16667H16.6667V15Z"
                                          fill="#7C7887"
                                        />
                                      </svg>

                                      <span className="text-muted">
                                        &nbsp;{resourceData.resourceName}(
                                        {resourceData.resourceDiscussionCount})
                                      </span>
                                    </a>
                                  )}
                                  {resourceData.resourceType.toLowerCase() ===
                                    "Audio".toLowerCase() && (
                                    <a>
                                      {micIcon({ size: 20, color: "#7C7887" })}
                                      <span className="text-muted">
                                        &nbsp;{resourceData.resourceName}(
                                        {resourceData.resourceDiscussionCount})
                                      </span>
                                    </a>
                                  )}
                                  {resourceData.resourceType.toLowerCase() ===
                                    "Practice lab".toLowerCase() && (
                                    <a>
                                      {PracIcon({ size: 20, color: "#7C7887" })}
                                      <span className="text-muted">
                                        &nbsp;{resourceData.resourceName}(
                                        {resourceData.resourceDiscussionCount})
                                      </span>
                                    </a>
                                  )}
                                  {resourceData.resourceType.toLowerCase() ===
                                    "Live class".toLowerCase() && (
                                    <a>
                                      {LiveClassIcon({
                                        size: 20,
                                        color: "#7C7887",
                                      })}
                                      <span className="text-muted">
                                        &nbsp;{resourceData.resourceName}(
                                        {resourceData.resourceDiscussionCount})
                                      </span>
                                    </a>
                                  )}
                                  {resourceData.resourceType.toLowerCase() ===
                                    "Assessment".toLowerCase() && (
                                    <a>
                                      {AssessmentIcon({
                                        size: 20,
                                        color: "#7C7887",
                                      })}
                                      <span className="text-muted">
                                        &nbsp;{resourceData.resourceName}(
                                        {resourceData.resourceDiscussionCount})
                                      </span>
                                    </a>
                                  )}

                                  {/* {resourceData.resourceDiscussionCount && ( */}
                                  <a
                                    // className="float-right discussion-color"

                                    // href=""
                                    style={{
                                      textDecoration: "none",
                                      color: "#2170D8",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      float: "right",
                                    }}
                                    onClick={() =>
                                      handleChangePage(
                                        resourceData.resourceId,
                                        data.moduleId,
                                        resourceData.resourceName
                                      )
                                    }
                                  >
                                    {resourceData.resourceDiscussionCount == "0"
                                      ? "Add"
                                      : "View"}
                                  </a>
                                  {/* )} */}
                                </li>
                              </ul>
                            </div>
                          </AccordionDetails>
                        </div>
                      );
                    })}
                </Accordion>
              </div>
            );
          })}
        </div>
      )}
      {viewDiscussion && (
        <ViewDiscussion
          dataType={discussionModuleList}
          resource={courseResourceId}
          // discussionValue={discussionValue}
          // handleChangePage={handleChangePage}
          show_view_dis={() => (
            setContentShow(true),
            setViewDiscussion(false),
            getCourseAllContent()
          )}
        />
      )}

      {edit && (
        <div>
          {/* <Button
            variant="outlined"
            className="show-discussion-button mt-3"
            onClick={handleAdd}
          >
            Show discussion of all content
          </Button> */}

          {/* <p className="text-muted mt-3">
            {courseResource.courseDiscussionCount} Discussion for{" "}
            <strong>{resourceName}</strong>
          </p> */}

          {courseResource.length > 0 &&
            courseResource.map((resourceData, index) => {
              !resourceData.editor_text && resourceData.editor_text;
              return (
                <div className="mt-3">
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        alt={"name"}
                        src={Profile}
                        className={classes.large}
                      />
                    </ListItemAvatar>
                    <ListItemText>
                      <strong> &nbsp;{resourceData.userName}</strong>
                    </ListItemText>
                  </ListItem>
                  <Card className={classes.cardStyle}>
                    <ListItem>
                      <ListItemText>
                        {resourceData.discussionContent}{" "}
                      </ListItemText>
                    </ListItem>
                  </Card>
                  <a>
                    {" "}
                    <p
                      className="m-3"
                      data-toggle="collapse"
                      aria-expanded="false"
                      href={`#multiCollapseExample_${index}`}
                      onClick={() => handleReplyDiscussion(resourceData)}
                    >
                      <ReplyIcon /> Reply for this
                    </p>
                  </a>
                  <div
                    className="collapse"
                    id={`multiCollapseExample_${index}`}
                  >
                    <div className="card card-body">
                      <Editor
                        editorStyle={{
                          width: "100%",
                          minHeight: 100,
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderColor: "lightgray",
                        }}
                        editorStates={editorStates}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={(editorStates) =>
                          setEditorStates(editorStates)
                        }
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-success m-3 "
                      style={{ width: "104px" }}
                      onClick={() => handleSaveNote(resourceData)}
                      data-toggle="collapse"
                      href={`#multiCollapseExample_${index}`}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary m-3"
                      style={{ width: "104px" }}
                      data-toggle="collapse"
                      href={`#multiCollapseExample_${index}`}
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>

                  {resourceData.discussionReply.length > 0 &&
                    resourceData.discussionReply.map((data) => (
                      <div className="menu">
                        <div className="ml-5 ">
                          {/* <a className="comment-border-link">
                          <span className="sr-only">Jump to comment-1</span>
                        </a> */}
                          {/* <hr className="fancy-line"></hr> */}
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar
                                alt={"name"}
                                src={Profile}
                                className={classes.large}
                              />
                            </ListItemAvatar>
                            <ListItemText>
                              <strong> &nbsp;{data.userName}</strong>
                            </ListItemText>
                          </ListItem>
                          <Card className={classes.replyCardStyle}>
                            <ListItem>
                              <ListItemText>
                                {data.discussionContent}{" "}
                              </ListItemText>
                            </ListItem>
                          </Card>
                        </div>
                      </div>
                    ))}
                </div>
              );
            })}

          {/* <div className="text-center">
            View 6 more replys <ExpandMoreIcon />
          </div> */}

          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
            <Alert onClose={handleClose} style={{ backgroundColor: "#794df5" }}>
              Course Discussion Created Successfully
            </Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
}
