import { Redirect } from "react-router-dom";

import Prepare from "../pages/student-dashboard/Prepare";
import CourseOverview from "../pages/course-overview";
import CourseExternal from "../pages/course-overview/course-external"
import CoursePlayer from "../pages/buy-course/index";

import UserProfile from "../pages/Authentication/UserProfile";
import EditUserProfile from "../pages/Authentication/EditUserProfile";
import Checkout from "../pages/checkout/index";
import Cart from "../pages/cart/index";
import DashBoard from "../pages/Dashboard/Dashboard";
import SamlPage from "../pages/saml/saml";
import LoginPage from "../pages/LoginPage/New/Home";
import Techruit from "pages/LoginPage/New/Techruit";
import CustomerManagement from "../pages/CustomerManagement/CustomerManagementSmart";
import UserManagement from "../pages/CustomerManagement/UserManagementSmart";
import CustomBatchManagement from "../pages/CustomBatchManagement/CustomBatchSmart";
import CustomBatchList from "../pages/CustomBatchManagement/CustomBatchList";
import UserList from "../pages/CustomerManagement/UserList";
import RoleManagement from "../pages/CustomerManagement/RoleManagementSmart";
import RoleList from "../pages/CustomerManagement/RoleList";
import ActiveUsersList from "../pages/CustomerManagement/ActiveUsersList";
import CustomerList from "../pages/CustomerManagement/CustomersList";
import SimStudentsList from "../pages/Sims/StudentsList";
import ConsolidatedReport from "../pages/Ecommerce/EcommerceOrders/ConsolidatedReport";
import ConsolidatedReportLogin from "../pages/Ecommerce/EcommerceOrders/ConsolidatedReportLogin";
import Configuration from "../pages/Ecommerce/EcommerceOrders/Configuration";
// import Taketest from "../pages/Taketest/takeTest";
import Taketest from "../pages/Test/test";
import TestDesc from "../pages/Taketest/testDesc";
import Managelab from "../pages/virtualLab/PracticePackageForm";
import ManageAllLabs from "../pages/virtualLab/AllPracticePackages";
import Lab from "../pages/virtualLab/lab";
import LabQuestions from "../pages/virtualLab/LabQuestions";
import PracticeLab from "../pages/virtualLab/practice-lab/practice-lab";
import AssessmentReport from "../pages/virtualLab/AssessmentReport";
import PracticeSummary from "../pages/virtualLab/PracticeSummary";
import ViewPracticeQuestions from "../pages/virtualLab/ViewPracticeQuestions";
import CodeLab from "../pages/virtualLab/codeLab";
import CreateQP from "../pages/QuestionWizard/SubmitQP";
import CreateNewQuestionBank from "../pages/QuestionWizard/CreateQuestionBank";
import QuestionBankQuestionList from "../pages/QuestionWizard/QuestionBankQuestions";
import ViewQuestionComponent from "../pages/QuestionWizard/ViewQuestion";
import ViewReport from "../pages/Ecommerce/EcommerceOrders/ViewReport";
import ViewResult from "../pages/Ecommerce/EcommerceOrders/ViewResult";
import Blobs from "../pages/Ecommerce/EcommerceOrders/Blobs";
import AssessmentList from "../pages/Assessment/AssessmentList";
import SubjectList from "../pages/Assessment/subjectList";
import AllAssessments from "../pages/Ecommerce/EcommerceOrders/AllAssessments";
import AllQuestionPapers from "../pages/Ecommerce/EcommerceOrders/AllQuestionPapers";
import CreateQuestionBankComponent from "../pages/Tasks/AllQuestionBanks";
import CreateQuestionComponent from "../pages/Tasks/CreateQuestion";
import CreateQuestionComponentSub from "../pages/Tasks/CreateQuestionSub";
import EditQuestionComponent from "../pages/Tasks/EditQuestion";
import EditAssessmentComponent from "../pages/Tasks/EditAssessment";
import CreateAssessment from "../pages/Tasks/CreateAssessment";

import AdminPracticeReport from "../pages/report/practice/index";
import AdminAssessmentReport from "../pages/report/assessment/index";
import AdminStudentsReport from "../pages/report/students/index";
import AdminStudentReport from "../pages/report/student/index";
import AdminOverallReport from "../pages/report/overall/index";
import AdminEnquiryReport from "../pages/report/enquiry/index";
import AdminPurchaseReport from "../pages/report/purchase/index";

import Competency from "../pages/Ecommerce/Master/AllCompetencies";
import CreateCompetency from "../pages/Ecommerce/Master/CreateCompetency";
import CreateSubCompetency from "../pages/Ecommerce/Master/CreateSubCompetency";

import Domain from "../pages/Ecommerce/Master/AllDomains";
import CreateDomain from "../pages/Ecommerce/Master/CreateDomain";
import CreateSubDomain from "../pages/Ecommerce/Master/CreateSubDomain";

import Proficiency from "../pages/Ecommerce/Master/AllProficiency";
import CreateProficiency from "../pages/Ecommerce/Master/CreateProficiency";

import Taxonomy from "../pages/Ecommerce/Master/AllTaxonomy";
import CreateTaxonomy from "../pages/Ecommerce/Master/CreateTaxonomy";

import Category from "../pages/Ecommerce/Master/AllCategory";
import CreateCategory from "../pages/Ecommerce/Master/CreateCategory";

import CreateTopic from "../pages/Ecommerce/Master/CreateTopic";

import UserRoles from "../pages/Ecommerce/Master/UserRole";
import CreateSubTaxonomy from "../pages/Ecommerce/Master/CreateSubTaxonomy";
import ComingSoon from "../pages/Assessment/comingsoon.js";
import ShowDetail from "../pages/Assessment/showDetails.js";
import Viewreport from "../pages/Assessment/viewReport";
import CompletedTest from "../pages/Assessment/completedTest.js";
import sso from "./sso";
import AllManualGrading from "pages/Ecommerce/EcommerceOrders/AllManualGradingNext";
import ResumeGrading from "pages/Ecommerce/EcommerceOrders/ResumeGrading";

import TermsAndConditions from "../pages/TermsAndConditions/termsAndConditions.js";
import Privacy from "../pages/TermsAndConditions/privacy.js";
import Legal from "../pages/TermsAndConditions/legal.js";

import PublicAssessmentStudent from "../pages/PublicAssessment/publicAssessmentStudent.js";
import CompletedPublicAssessment from "../pages/PublicAssessment/completedPublicAssessment.js";

import PrepareAdmin from "pages/Prepare";
import AllPrepare from "pages/Prepare/AllCoursesSmart";
import PrepareReport from "pages/Ecommerce/EcommerceOrders/PrepareReport";

import UserAssessmentMarks from "../pages/Utility/UserAssessmentMarks";
import UserActivityLog from "../pages/Utility/userActivityLog/index";

import LiveAssessment from "../pages/Utility/liveAssessmentView/index";
import CompanyPackage from "../pages/virtualLab/CompanyPackage";
import AptitudePackage from "../pages/virtualLab/AptitudePackage";
import VerbalPackage from "../pages/virtualLab/VerbalPackage";
import LogicalPackage from "../pages/virtualLab/LogicalPackage";
import AccenturePackage from "./../pages/virtualLab/AccenturePackage";
import Cprogramming from "./../pages/virtualLab/Cprogramming";
import Java from "./../pages/virtualLab/Java";
import Python from "./../pages/virtualLab/Python";
import PythonNew from "pages/virtualLab/PythonNew";
import Dsc from "./../pages/virtualLab/Dsc";
import Dsp from "./../pages/virtualLab/Dsp";
import Capgemini from "./../pages/virtualLab/Capgemini";
import Cts from "./../pages/virtualLab/Cts";
import Infytq from "./../pages/virtualLab/Infytq";
import LandT from "./../pages/virtualLab/LandT";
import Tcscodevita from "./../pages/virtualLab/Tcscodevita";
import Tcsdigital from "./../pages/virtualLab/Tcsdigital";
import Tcsnqt from "./../pages/virtualLab/Tcsnqt";

import CommunicationsList from "../pages/Utility/communicationMod/index";
import ComposeMailSmart from "../pages/Utility/communicationMod/ComposeMailSmart";
import CreateTemplate from "../pages/Utility/communicationMod/CreateTemplate";
import Page404 from "pages/Utility/Page404";
import CloudLab from "pages/cloudLab/cloudLab";
import BoardOfMembers from "pages/LoginPage/New/BoardOfMembers";
import cloudLabForm from "pages/cloudLab/component/cloudLabForm";
import hackathon from "pages/hackathon/AllCoursesSmart";
import hackathonForm from "pages/hackathon/hackathonForm/hackathonForm";
import hackathonStudent from "pages/hackathon/hackathonStudent/hackathonList";
import hackathonRegister from "pages/hackathon/hackathonStudent/hackathonRegister";
import HackathonSubmission from "pages/hackathon/hackathonForm/projectSubmission";
import ProjectSubmissionForm from "pages/hackathon/hackathonForm/hackathonSubmissionForm";
import HackathonReport from "../pages/hackathon/hackathonReport/hackathonReport";
import HackathonGrading from "../pages/hackathon/hackathonReport/ResumeGrading";
import HackathonChallengeReport from "../pages/hackathon/component/reportChallange/UserAssessmentMarks";
import adminLogin from "pages/LoginPage/New/adminLogin";
import JavaNew from "pages/virtualLab/JavaNew";
import DataStructure from "pages/virtualLab/DataStructure";
import PythonCourse from "pages/virtualLab/PythonCourse";
import JavaCourse from "pages/virtualLab/JavaCourse";
import fellowShipProgram from "pages/fellowShipProgram/fellowShipProgram";
import TestcaseReport from "pages/TestcaseReport/TestcaseReport"

const authProtectedRoutes = [
  { path: "/prepare", component: Prepare },
  { path: "/course-overview/:courseId", component: CourseOverview },
  { path: "/course-external/:courseId", component: CourseExternal },
  { path: "/course-learning/:courseId", component: CoursePlayer },
  { path: "/course-learning/:courseId/:resourceID", component: CoursePlayer },
  {
    path: "/courselearning/practice/:courseId",
    component: ViewPracticeQuestions,
  },
  { path: "/sso/:token", component: sso },
  { path: "/samlservice", component: SamlPage },
  { path: "/checkout", component: Checkout },
  { path: "/cart", component: Cart },
  { path: "/dashboard", component: DashBoard },
  { path: "/login", component: LoginPage },
  {path: "/adminLogin", component: adminLogin},
  { path: "/customerManagement", component: CustomerManagement },
  { path: "/customBatchManagement", component: CustomBatchManagement },
  { path: "/roleManagement", component: RoleManagement },
  { path: "/roleList", component: RoleList },
  { path: "/customBatchList", component: CustomBatchList },
  { path: "/customerList", component: CustomerList },
  { path: "/userList", component: UserList },
  { path: "/userManagement", component: UserManagement },
  { path: "/comingSoon", component: ComingSoon },
  { path: "/showdetail", component: ShowDetail },
  { path: "/viewreportuser", component: Viewreport },
  { path: "/consolidatedReport", component: ConsolidatedReport },
  { path: "/consolidatedReportLogin", component: ConsolidatedReportLogin },
  { path: "/activeUsersList", component: ActiveUsersList },
  { path: "/configuration", component: Configuration },
  { path: "/completedtest", component: CompletedTest },
  { path: "/assessment", component: AssessmentList },
  { path: "/subjectlist", component: SubjectList },
  { path: "/report", component: AdminAssessmentReport },
  { path: "/students-report", component: AdminStudentsReport },
  { path: "/student-report", component: AdminStudentReport },
  { path: "/practice-report", component: AdminPracticeReport },
  { path: "/overall-report", component: AdminOverallReport },
  { path: "/enquiry-report", component: AdminEnquiryReport },
  { path: "/purchase-report", component: AdminPurchaseReport },
  { path: "/viewResults", component: ViewResult },
  { path: "/blobs", component: Blobs },
  { path: "/assessments", component: AllAssessments },
  { path: "/resume-grading", component: ResumeGrading },
  { path: "/manual-grading", component: AllManualGrading },
  { path: "/create-question-paper", component: AllQuestionPapers },
  { path: "/create-assessment", component: CreateAssessment },
  { path: "/create-new-qb", component: CreateNewQuestionBank },
  { path: "/view-questions", component: QuestionBankQuestionList },
  { path: "/viewquestion", component: ViewQuestionComponent },
  { path: "/userRole", component: UserRoles },
  { path: "/competency", component: Competency },
  { path: "/create-competency", component: CreateCompetency },
  { path: "/edit-competency/:id", component: CreateCompetency },
  { path: "/create-sub-competency/:parentId", component: CreateSubCompetency },
  { path: "/edit-sub-competency/:id", component: CreateSubCompetency },
  
  { path: "/sims", component: SimStudentsList },

  { path: "/domain", component: Domain },
  { path: "/create-domain", component: CreateDomain },
  { path: "/edit-domain/:id", component: CreateDomain },
  { path: "/create-sub-domain/:parentId", component: CreateSubDomain },
  { path: "/edit-sub-domain/:id", component: CreateSubDomain },

  { path: "/proficiency", component: Proficiency },
  { path: "/create-proficiency", component: CreateProficiency },
  { path: "/edit-proficiency/:id", component: CreateProficiency },

  { path: "/taxonomy", component: Taxonomy },
  { path: "/create-taxonomy", component: CreateTaxonomy },
  { path: "/edit-taxonomy/:id", component: CreateTaxonomy },
  { path: "/create-sub-taxonomy/:parentId", component: CreateSubTaxonomy },
  { path: "/edit-sub-taxonomy/:id", component: CreateSubTaxonomy },

  { path: "/category", component: Category },
  { path: "/create-category", component: CreateCategory },
  { path: "/edit-category/:id", component: CreateCategory },

  { path: "/create-topic/:parentId", component: CreateTopic },
  { path: "/edit-topic/:id", component: CreateTopic },

  { path: "/taketest", component: Taketest },
  { path: "/testDesc", component: TestDesc },
  { path: "/create-qp", component: CreateQP },
  { path: "/create-question-bank", component: CreateQuestionBankComponent },
  { path: "/create-question", component: CreateQuestionComponent },
  { path: "/create-question-sub", component: CreateQuestionComponentSub },
  { path: "/edit-question/:questionId", component: EditQuestionComponent },
  { path: "/edit-assessment/:questionId", component: EditAssessmentComponent },
  { path: "/profile", component: UserProfile },
  { path: "/edit-user-profile", component: EditUserProfile },
  { path: "/manage-lab", component: Managelab },
  { path: "/lab-questions", component: LabQuestions },
  { path: "/practice-lab", component: PracticeLab },
  { path: "/assessment-report", component: AssessmentReport },
  { path: "/practice-summary", component: PracticeSummary },
  { path: "/view-practice-questions", component: ViewPracticeQuestions },
  { path: "/view-practice-questions/:id", component: ViewPracticeQuestions },
  { path: "/manage-allLabs", component: ManageAllLabs },
  { path: "/codeLab", component: CodeLab },
  { path: "/lab", component: Lab },
  { path: "/manage-prepare", component: PrepareAdmin },
  { path: "/manage-prepare/:id", component: PrepareAdmin },
  { path: "/view-prepare", component: AllPrepare },
  { path: "/prepare-report", component: PrepareReport },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },

  { path: "/termsAndConditions", component: TermsAndConditions },
  { path: "/privacy", component: Privacy },
  { path: "/legal", component: Legal },

  { path: "/take-test", component: PublicAssessmentStudent },
  { path: "/completedPublicAssessment", component: CompletedPublicAssessment },

  { path: "/userAssessmentMarks", component: UserAssessmentMarks },
  { path: "/user-activity-log", component: UserActivityLog },

  { path: "/crack-wipro-nlth", component: CompanyPackage },
  { path: "/crack-wipro-nlth1", component: CompanyPackage },
  { path: "/crack-wipro-nlth2", component: CompanyPackage },
  { path: "/crack-wipro-nlth3", component: CompanyPackage },
  { path: "/aptitude-pro", component: AptitudePackage },
  { path: "/verbal-pro", component: VerbalPackage },
  { path: "/logical-pro", component: LogicalPackage },
  { path: "/crack-accenture-pro", component: AccenturePackage },
  { path: "/cprogramming", component: Cprogramming },
  { path: "/pythonCourse", component: PythonCourse },
  { path: "/javaCourse", component: JavaCourse },
  // { path: "/data", component: JavaCourse },
  { path: "/Java", component: Java },
  { path: "/JavaNew", component: JavaNew },
  { path: "/techruit", component: DataStructure },
  { path: "/dataStru", component: DataStructure },
  { path: "/Python", component: Python },
  { path: "/PythonNew", component: PythonNew },
  { path: "/Dsc", component: Dsc },
  { path: "/Dsp", component: Dsp },
  { path: "/Capgemini", component: Capgemini },
  { path: "/Cts", component: Cts },
  { path: "/Infytq", component: Infytq },
  { path: "/LandT", component: LandT },
  { path: "/Tcscodevita", component: Tcscodevita },
  { path: "/Tcsdigital", component: Tcsdigital },
  { path: "/Tcsnqt", component: Tcsnqt },

  { path: "/liveAssessment", component: LiveAssessment },

  { path: "/communication", component: CommunicationsList },
  { path: "/composeMail", component: ComposeMailSmart },
  { path: "/createTemplate", component: CreateTemplate },
  { path: "/page-404", component: Page404 },

  { path: "/cloudLab", component: CloudLab },
  { path: "/board-of-advisors", component: BoardOfMembers },
  { path: "/cloudLabForm", component: cloudLabForm },
  { path: "/cloudLabForm/:id", component: cloudLabForm },

  { path: "/hackathon", component: hackathon },
  { path: "/hackathon-form", component: hackathonForm },
  { path: "/hackathon-form/:id", component: hackathonForm },
  { path: "/hackathonStudent", component: hackathonStudent },
  { path: "/hackathoninfo/:id", component: hackathonRegister },
  { path: "/hackathonSubmission/:id", component: HackathonSubmission },
  { path: "/hackathonSubmissionform", component: ProjectSubmissionForm },
  { path: "/hackathonSubmissionform/:id", component: ProjectSubmissionForm },
  { path: "/hackathonReport/:id", component: HackathonReport },
  { path: "/HackathonGrading/:id", component: HackathonGrading },
  { path: "/HackathonChallengeReport/:id", component: HackathonChallengeReport},
  { path: "/fellowship-program", component: fellowShipProgram },
  { path: "/testcase-report", component: TestcaseReport },
];

export { authProtectedRoutes };
