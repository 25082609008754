export const GetObjectFromString = (array, toFind) => {
  let object = "";
  try {
    array.forEach((element) => {
      if (element.value === toFind) {
        object = element;
      }
    });
  } catch (e) {
  }
  return object;
};
