import type { ActionType } from "./actions";
import { SET_SIDE_BAR } from "./actionTypes";

const initialState = {
    state: false,
    manageAssessments: false,
    manageCustomer: false,
    master: false,
    report: false,
    utility: false,
}

export default function SideBarReducer(
    state = initialState,
    action: ActionType
) {
    switch (action.type) {
        case SET_SIDE_BAR: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}