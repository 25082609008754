import { Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Select, { components } from "react-select";
import customSelectStyles from "./CustomSelect.module.scss";

const customStyles = {
  valueContainer: (styles) => ({
    ...styles,
    pointerEvents: "none",
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    pointerEvents: "none",
  }),
};

const Menu = (props) => {
  const isSelectAll = (options) =>
    options.length > 0 ? options.every((item) => item.checked) : false;

  const handleSelectAll = () => {
    if (isSelectAll(props.getValue())) {
      props.setValue([]);
    } else {
      props.setValue(
        props.options.reduce((acc, val) => {
          acc.push({
            ...val,
            checked: true,
          });
          return acc;
        }, [])
      );
    }
  };

  const handleCheckGender = (options, gender) =>
    options.length > 0
      ? options.every((item) => item?.gender?.toLowerCase() === gender)
      : false;

  const handleGenderSelect = (gender) => {
    if (handleCheckGender(props.getValue(), gender)) {
      props.setValue([]);
    } else {
      props.setValue(
        props.options.reduce((acc, val) => {
          if (val["gender"]?.toLowerCase() === gender) {
            acc.push(val);
          }
          return acc;
        }, [])
      );
    }
  };

  return (
    <components.Menu {...props}>
      <div>
        <div className={customSelectStyles.menuTopActionBar}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  name="all"
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  color="primary"
                  checked={isSelectAll(props.getValue())}
                  onChange={handleSelectAll}
                />
              }
              classes={{ label: customSelectStyles.labelStyle }}
              label="Select all"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="Male"
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  color="primary"
                  checked={handleCheckGender(props.getValue(), "male")}
                  onChange={handleGenderSelect.bind(this, "male")}
                />
              }
              classes={{ label: customSelectStyles.labelStyle }}
              label="Male"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="Female"
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  color="primary"
                  checked={handleCheckGender(props.getValue(), "female")}
                  onChange={handleGenderSelect.bind(this, "female")}
                />
              }
              classes={{ label: customSelectStyles.labelStyle }}
              label="Female"
            />
          </FormGroup>
        </div>
        <div>{props.children}</div>
      </div>
    </components.Menu>
  );
};

const hasRegNo = (array) =>
  array.length > 0 ? array.every((item) => !!item.registrationNo) : false;

const someHasRegNo = (array) =>
  array.length > 0 ? array.some((item) => !!item.registrationNo) : false;

const removeRegNo = (str) => (str.includes(",") ? str.split(",")[0] : str);

const CustomSelect = ({
  options,
  value,
  onChange,
  styles,
  isDisabled,
  inputId,
  viewOnly,
  filterOption,
}) => {
  const handleInputChange = (inputValue, event) => {
    if (event.action === "input-change" && someHasRegNo(options)) {
      setTimeout(() => {
        const regIds = inputValue.replace(/\s+/g, "").split(",");
        const prevValue = value ? [...value] : [];
        onChange(
          options.reduce((acc, val) => {
            if (
              !acc.includes(val.registrationNo) &&
              regIds.includes(val.registrationNo)
            ) {
              acc.push({ ...val, label: removeRegNo(val.label) });
            }
            return acc;
          }, prevValue)
        );
      }, 1000);
    }
  };

  return (
    <Select
      inputId={inputId}
      options={options}
      components={{
        Menu,
      }}
      isMulti
      value={value}
      onChange={(val) => {
        onChange(
          val
            ? someHasRegNo(options)
              ? val.reduce((acc, val) => {
                
                  acc.push({ ...val, label: removeRegNo(val.label) });
                  return acc;
                }, [])
              : val
            : []
        );
      }}
      isDisabled={isDisabled}
      isSearchable
      maxMenuHeight={120}
      styles={viewOnly === true ? { ...customStyles, ...styles } : styles}
      onInputChange={handleInputChange}
      closeMenuOnSelect={false}
      filterOption={filterOption}
    />
  );
};

export default CustomSelect;
