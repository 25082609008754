import { AntInfo } from "assets/icons/icons";
import { TervButtonFilled } from "components/TervButtons";
import styles from "./SebInstructions.module.scss";
import { useState } from "react";

const downloadSEB = () => {
  window.location.href = "https://safeexambrowser.org/download_en.html";
};

const downloadConfig = (link) => {
  window.location.href = link;
};

const InstructionsBox = () => (
  <div className={styles.instructionBox}>
    <p>
      <AntInfo size="24" color="#FDBC1F" /> Instructions
    </p>
    <ul>
      <li>
        This assessment can be taken only in <span>SAFE EXAM BROWSER</span>
      </li>
      <li>
        Download <span>SAFE EXAM BROWSER</span> before taking assessment
      </li>
      <li>
      Follow below actions to start the assessment in <span>SAFE EXAM BROWSER</span>
      </li>
    </ul>
  </div>
);

const LaunchSeb = ({ closeModal, setValidateCheck }) => (
  <div className={styles.launchMain}>
    <div className={styles.launchIns}>
      <AntInfo size="44" color="#FDBC1F" />
      <p>
        Secure Exam Browser will close the running applications in your system.
        Please save your work before proceeding. Once you launch, you will not
        able to leave the test window without exiting the test. Please prepare
        accordingly
      </p>
    </div>
    <ul className={styles.launchUl}>
      <li>
        Please lauch the test only once you are ready to attend the Assessment.
      </li>
      <li>
        When you click on “Launch Test”, the browser will ask for your
        permission to open the Secure Exam Browser.
      </li>
      <li>
        In the browser permission window, please click “Open Secure Exam
        Browser” to go to the assessment
      </li>
    </ul>
    <div className={styles.launchButtons}>
      <TervButtonFilled text="Cancel" onClick={closeModal} tertiary />
      <TervButtonFilled text="Launch assessment" onClick={setValidateCheck} />
    </div>
  </div>
);

const SebInstructions = ({ type, sebUrl, closeModal, setValidateCheck }) => {
  const [launch, setLaunch] = useState(false);

  return (
    <>
      {type === "launch" || launch ? (
        <LaunchSeb
          setLaunch={setLaunch}
          closeModal={closeModal}
          setValidateCheck={setValidateCheck}
        />
      ) : (
        <>
          <InstructionsBox />
          <div className={styles.main}>
            <p>
              1. If you not installed/Download Safe exam browser, click below
              button
            </p>
            <TervButtonFilled
              text="Download safe exam browser"
              onClick={downloadSEB}
            />
            <p>
              2. If you installed Safe exam browser, click below button to
              proceed
            </p>
            <TervButtonFilled
              text="Proceed to test"
              secondary
              onClick={() => setLaunch(true)}
            />
            <p>
              3. If Proceed test not works, download this SEB config file and
              double click the file to start the exam
            </p>
            <span onClick={() => sebUrl && downloadConfig(sebUrl)}>
              Download SEB config file
            </span>
          </div>
        </>
      )}
    </>
  );
};

export default SebInstructions;
