import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import "./courseOverview.css";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CourseContent from "./course-content";
import CourseReview from "./course-review";
import InstructorContent from "./instructor";
import { useHistory, useParams } from "react-router-dom";
import apiHelper from "../../api/apiHelper";
import api from "../../api/baseConfig";
import { useUserDetails } from "../../hooks/use-user-details";
import { Player, LoadingSpinner, BigPlayButton } from "react-player";
// import "react-player/dist/react-player.css";
import RelatedCourses from "../student-dashboard/RelatedCourses";
import * as CustomIcons from "../../assets/icons/icons";
import ReactHtmlParser from "html-react-parser";
import CardMedia from "@material-ui/core/CardMedia";
import { GetRandom } from "../utils/GetRandom";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { addToCartPrepare } from "../virtualLab/ViewPracticeQuestions";
import { CapitalizeFirstLetter } from "pages/utils/CapitalizeFirstLetter";
import { CircularProgress, Modal, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import SignInModal from "components/VerticalLayout/SignInModal";
import { ModalToggle } from "pages/utils/ModalToggle";
import { CardBody } from "reactstrap";
import Confirmation from "./courseFormSubmit";
import Skeleton from "@material-ui/lab/Skeleton";
// import { Any } from "react-spring/node_modules/@react-spring/types";
import { sessionPrivileges } from "privileges";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    background: "#20242D",
    justifyContent: "space-around",
  },
  rating: {
    display: "flex",
    alignItems: "center",
  },
  card: {
    maxWidth: 400,
    boxShadow: "0px 0px 25px rgba(170, 170, 170, 0.25)",
    borderRadius: 5,
  },
  media: {
    height: 300,
    width: 422,
  },
  colorPrimary: {
    backgroundColor: "#00695C",
  },
  barColorPrimary: {
    backgroundColor: "#F56F18;",
  },
  buttonWidth: {
    width: "100%",
  },
  priceTag: {
    display: "inline-flex",
    inlineSize: "-webkit-fill-available",
    position: "relative",
    margin: 5,
  },
}));

export default function CourseOverview() {
  const userPrivileges = sessionPrivileges();
  const userId = userPrivileges.userId;
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [relatedCourse, setRelatedCourse] = useState([]);

  const { courseId } = useParams();

  const bull = <span className={classes.bullet}>•</span>;

  const [courseDetails, setCourseDetails] = useState({});
  const [courseContent, setCourseContent] = useState([]);
  const [CourseOverall, setCourseOverall] = useState([]);
  const [PDFShow, setPDFShow] = useState(false);
  const [starValue, setStarValue] = useState(0);
  const [isCopied, setIsCopied] = useState(false);
  const [inCart, setIfInCart] = useState([]);
  const [addingToCart, setIfAddingToCart] = useState(false);
  const [loadingUrl, setLoadingUrl] = useState(false);
  const [externalLoadingUrl, setExternalLoadingUrl] = useState(false);
  const [handleSignInModalOpen, setHandleSignInModalOpen] = useState(false);
  const [head, setHead] = useState("");
  const [tokenValue, setTokenValue] = useState({});
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const contentVideoClick = (data) => {
    history.push(`/course-learning/${courseId}/${data.id}`);
  };

  const externalCheck = window.location.href.includes("/course-external");

  const CustomizeTooltip = withStyles({
    tooltip: {
      padding: "10px 10px",
      color: "black",
      backgroundColor: "white",
      fontSize: "13px",
      lineHeight: "18px",
      maxWidth: 700,
      border: "none",
    },
  })(Tooltip);

  const handleVideoPlayer = (data) => {
    const youTube = data.startsWith("https://www.youtube");

    if (youTube) {
      videoSrc = {
        type: "video",
        sources: [
          {
            src: data,
            provider: "youtube",
          },
        ],
      };
    } else {
      videoSrc = {
        type: "video",
        sources: [
          {
            src: data,
            type: "video/mp4",
            size: 720,
          },
        ],
      };
    }
  };

  useEffect(async () => {
    if (document.getElementById("adobe-dc-view")) {
      showPDF("/files/PDFTRON_about.pdf", "data.name");
    }
  }, [document.getElementById("adobe-dc-view"), PDFShow]);

  function showPDF(urlToPDF, PDFTitle) {
    var adobeDCView = new AdobeDC.View({
      clientId: "418c43e6737340d78c7b947a68ab1a0b",
      divId: "adobe-dc-view",
    });

    const viewerOptions = {
      embedMode: "FULL_WINDOW",
      defaultViewMode: "FIT_WIDTH",
      showDownloadPDF: false,
      showPrintPDF: false,
      showLeftHandPanel: true,
      showAnnotationTools: false,
    };
    var previewFilePromise = adobeDCView.previewFile(
      {
        content: { location: { url: urlToPDF } },
        metaData: { fileName: "PDF" },
      },
      viewerOptions
    );
  }
  const handleOpenPage = (data) => {
    if (data.type === "Link" || data.type === "Video") {
      setPDFShow(false);
      handleVideoPlayer(data.media);
    } else if (data.type === "Study material") {
      setPDFShow(true);
    }
  };

  useEffect(async () => {
    window.scroll(0, 0);
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.courseController.getCourseDetails}${userId}/${courseId}`,
        "get"
      );
      if (data) {
        setCourseDetails(data.courseDetails);
        setStarValue(data?.courseDetails?.courseRating);
        setCourseContent(data.modulesList);
        setCourseOverall(data.courseContentDetailsDTO);
        setIfInCart(data?.addedCart);
        if (data.lastViewedResource && data.lastViewedResource.media) {
          handleOpenPage(data.lastViewedResource);
        } else {
          handleOpenPage(data.modulesList[0].resourceList[0]);
        }
      }
    } catch (e) {}
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const userDetails = useUserDetails();

  useEffect(() => {
    if (courseDetails.categoryId) {
      getRelatedCourses(courseDetails.categoryId);
    }
  }, [courseDetails]);

  const getRelatedCourses = async (id) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.courseController.getRelatedCourses}${id}`,
        "get"
      );
      if (data) {
        setRelatedCourse(data);
      }
    } catch (e) {}
  };

  const goToCourse = () => history.push(`/course-learning/${courseId}`);
  const userDetailsName = useSelector(
    (state) => state.UserProfileReducer.UserData
  );

  const externalCourse = async () => {
    setExternalLoadingUrl(true);
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.Prepare.externalCourseApi}${courseId}?firstName=${userDetailsName?.firstName}&lastName=${userDetailsName?.lastName}`,
        "get"
      );
      // setLoadingUrl(false);
      setTokenValue(data);
      // window.open(`${data}`, "_blank");
      setExternalLoadingUrl(false);
    } catch (e) {
      console.log(e);
    }
  };

  const externalCourseStart = async () => {
    setLoadingUrl(true);
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.Prepare.externalCourse}${courseId}?firstName=${userDetailsName?.firstName}&lastName=${userDetailsName?.lastName}`,
        "get"
      );
      window.open(data, "_blank");
      setLoadingUrl(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleEnroll = () => {
    if (userId) {
      if (courseDetails?.paidPackage)
        history.push({
          pathname: "/checkout",
          params: { items: courseId, productType: "course" },
        });
      else goToCourse();
    } else {
      setHandleSignInModalOpen(!handleSignInModalOpen);
      setHead("login");
      // window.location.pathname = "/login";
    }
  };
  const handleAddToCart = async () => {
    try {
      setIfAddingToCart(true);
      const res = await addToCartPrepare(courseId, "course");
      if (res) setIfInCart((pre) => [...pre, courseId]);
    } catch {
    } finally {
      setIfAddingToCart(false);
    }
  };
  useEffect(() => {
    if (userDetailsName?.firstName) {
      externalCourse();
    }
  }, [userDetailsName]);
  return (
    <>
      <div className="mainExternal">
        <div className="secondaryExternal">
          <div className="course-External" style={{ overflow: "hidden" }}>
            <Box className="containerExternal" style={{ position: "relative" }}>
              <Box
                className="container"
                sx={{
                  left: "50%",
                  transform: "translateX(-50%)",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  minHeight: "100%",
                  position: "absolute",
                  maxWidth: "800px",
                }}
              >
                <div>
                  <div className="video-player" style={{ padding: "32px 0" }}>
                    {courseDetails.promotionalVideo ? (
                      <Player
                        src={courseDetails.promotionalVideo}
                        fluid
                        width="50%"
                      >
                        <LoadingSpinner />
                        <BigPlayButton position="center" />
                      </Player>
                    ) : (
                      <div className="buyImageExternal">
                        <div style={{ paddingTop: "30px" }}>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "center",
                            }}
                          >
                            <Card className={classes.card}>
                              <CardMedia
                                className={classes.media}
                                image={
                                  courseDetails?.media
                                    ? courseDetails.media.includes("default")
                                      ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${courseDetails.media}.jpeg`
                                      : courseDetails.media
                                    : `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/default-${
                                        GetRandom(9) + 1
                                      }.jpeg`
                                }
                              />
                            </Card>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {!externalCheck && externalLoadingUrl ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginLeft: "5rem" }}>
                      <h4 style={{ color: "white" }}>Loading...</h4>
                      <CircularProgress color="primary" />
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    <div style={{ flex: "1 1 0%" }}>
                      <div
                        className={
                          smallScreen
                            ? "d-flex flex-column bd-highlight mb-5 course-banner"
                            : "d-flex bd-highlight mb-5 course-banner"
                        }
                        style={
                          smallScreen
                            ? {
                                border: "1px solid #63606C",
                                borderRadius: "8px",
                                padding: "24px",
                              }
                            : {
                                border: "1px solid #63606C",
                                borderRadius: "8px",
                                padding: "24px",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }
                        }
                      >
                        <div style={{ display: "flex" }}>
                          <div>
                            <h2
                              className="prepare-desc bd-highlight"
                              style={{ textTransform: "capitalize" }}
                            >
                              {courseDetails.name}&nbsp;&nbsp;
                              {courseDetails.courseTags === "premium"
                                ? CustomIcons.premiumIcon
                                : courseDetails.courseTags === "special"
                                ? CustomIcons.specialIcon
                                : courseDetails.courseTags === "latest"
                                ? CustomIcons.latestIcon
                                : courseDetails.courseTags === "feature"
                                ? CustomIcons.feturedIcon
                                : ""}
                            </h2>
                            <ul
                              className="list-inline"
                              style={{ margin: "16px 0 0 0" }}
                            >
                              <li className="list-inline-item small-font">
                                {courseDetails.duration} hours duration
                              </li>
                              {(courseDetails.externalObjectId == null ||
                                courseDetails.externalObjectName == null) && (
                                <li className="list-inline-item small-font">
                                  <span className="bull">{bull} </span>
                                  {courseDetails.numberOfModules} chapters
                                </li>
                              )}
                              <li className="list-inline-item small-font">
                                <span className="bull"> {bull}</span>{" "}
                                {CapitalizeFirstLetter(courseDetails.level)}
                              </li>
                              <li className="list-inline-item small-font">
                                <span className="bull"> {bull}</span>{" "}
                                {courseDetails.languages}
                              </li>
                            </ul>
                            {courseDetails?.paidPackage ? (
                              !courseDetails?.purchased ? (
                                <div className="price-wraper">
                                  <h2>
                                    ₹
                                    {courseDetails.strikingPrice == 0
                                      ? courseDetails?.originalPrice || 0
                                      : courseDetails.strikingPrice}{" "}
                                    INR
                                  </h2>
                                  {courseDetails.strikingPrice == 0 ? (
                                    ""
                                  ) : (
                                    <p>
                                      ₹{courseDetails?.originalPrice || 0} INR
                                    </p>
                                  )}
                                  {courseDetails.strikingPrice == 0 ? (
                                    ""
                                  ) : (
                                    <span>{`(Save ${Math.floor(
                                      courseDetails?.discountAmount
                                    )} %)`}</span>
                                  )}
                                </div>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="mt-2">
                          <div>
                            {courseDetails?.paidPackage ? (
                              !courseDetails?.purchased ? (
                                <div className="mt-5">
                                  {/* {courseDetails.status === "NotStarted" && ( */}
                                  <div container spacing={5}>
                                    <div item xs>
                                      <Button
                                        variant="outlined"
                                        onClick={handleEnroll}
                                        className="buy-button"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {courseDetails?.paidPackage
                                          ? !courseDetails?.purchased
                                            ? "Buy now"
                                            : "Start a course"
                                          : "Enroll course"}
                                      </Button>
                                    </div>
                                    <div item xs>
                                      {(courseDetails.externalObjectId ==
                                        null ||
                                        courseDetails.externalObjectName ==
                                          null) && (
                                        <Button
                                          variant="outlined"
                                          className="cart-button"
                                          disabled={addingToCart}
                                          onClick={async () => {
                                            if (!inCart) {
                                              setIfAddingToCart(true);
                                              await addToCartPrepare(
                                                courseDetails?.id
                                              )
                                                .then((res) => {
                                                  if (res !== false) {
                                                    setIfInCart(true);
                                                  } else {
                                                    setIfInCart(false);
                                                  }
                                                })
                                                .catch(() => {})
                                                .finally(() => {
                                                  setIfAddingToCart(false);
                                                });
                                            }
                                          }}
                                          outline
                                          size="lg"
                                        >
                                          {courseDetails?.addedCart ? (
                                            "Incart"
                                          ) : addingToCart ? (
                                            <CircularProgress
                                              color="inherit"
                                              thickness={5}
                                              size={22}
                                              style={{ marginTop: 5 }}
                                            />
                                          ) : (
                                            "Add to cart"
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                    <div item xs>
                                      <Tooltip
                                        title="Copy Link"
                                        arrow
                                        placement="top-start"
                                      >
                                        <CopyToClipboard
                                          text={window.location.href}
                                        >
                                          {isCopied ? (
                                            <Button
                                              variant="outlined"
                                              className="text-light"
                                              onClick={() => {
                                                setIsCopied(true);
                                              }}
                                              style={{
                                                border: "1px solid #ffffff",
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="#ffffff"
                                              >
                                                <path
                                                  d="M20 2H10C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4V6H16C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8V16H20C20.5304 16 21.0391 15.7893 21.4142 15.4142C21.7893 15.0391 22 14.5304 22 14V4C22 3.46957 21.7893 2.96086 21.4142 2.58579C21.0391 2.21071 20.5304 2 20 2Z"
                                                  fill="#ffffff"
                                                />
                                                <path
                                                  d="M4 22H14C15.103 22 16 21.103 16 20V10C16 8.897 15.103 8 14 8H4C2.897 8 2 8.897 2 10V20C2 21.103 2.897 22 4 22ZM6 12H12V14H6V12ZM6 16H12V18H6V16Z"
                                                  fill="#ffffff"
                                                />
                                              </svg>
                                            </Button>
                                          ) : (
                                            <Button
                                              variant="outlined"
                                              className="text-light"
                                              onClick={() => {
                                                setIsCopied(true);
                                              }}
                                              style={{
                                                border: "1px solid #ffffff",
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="#ffffff"
                                              >
                                                <path
                                                  d="M20 2H10C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4V6H16C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8V16H20C20.5304 16 21.0391 15.7893 21.4142 15.4142C21.7893 15.0391 22 14.5304 22 14V4C22 3.46957 21.7893 2.96086 21.4142 2.58579C21.0391 2.21071 20.5304 2 20 2Z"
                                                  fill="#ffffff"
                                                />
                                                <path
                                                  d="M4 22H14C15.103 22 16 21.103 16 20V10C16 8.897 15.103 8 14 8H4C2.897 8 2 8.897 2 10V20C2 21.103 2.897 22 4 22ZM6 12H12V14H6V12ZM6 16H12V18H6V16Z"
                                                  fill="#ffffff"
                                                />
                                              </svg>
                                            </Button>
                                          )}
                                        </CopyToClipboard>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  {/* )} */}
                                </div>
                              ) : (
                                <div>
                                  {courseDetails.status !== "NotStarted" ? (
                                    <div item xs>
                                      {courseDetails.externalObjectId == null ||
                                      courseDetails.externalObjectName ==
                                        null ? (
                                        <Button
                                          variant="outlined"
                                          onClick={
                                            courseDetails.externalObjectId ==
                                              null ||
                                            courseDetails.externalObjectName ==
                                              null
                                              ? goToCourse
                                              : externalCourse
                                          }
                                          className="cart-button"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {loadingUrl
                                            ? "loading..."
                                            : "Continue learning"}
                                        </Button>
                                      ) : (
                                        <Confirmation
                                          status={courseDetails.status}
                                          loadingUrl={loadingUrl}
                                          setLoading={setLoadingUrl}
                                          apiLink={tokenValue.apiUrl}
                                          apiToken={tokenValue.value}
                                          externalApiCall={externalCourseApi}
                                          externalCourseStart={
                                            externalCourseStart
                                          }
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <div item xs>
                                      {courseDetails.externalObjectId == null ||
                                      courseDetails.externalObjectName ==
                                        null ? (
                                        <Button
                                          variant="outlined"
                                          onClick={goToCourse}
                                          className="buy-button"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {loadingUrl
                                            ? "loading..."
                                            : "start course"}
                                        </Button>
                                      ) : (
                                        <Confirmation
                                          status={"start Course"}
                                          loadingUrl={loadingUrl}
                                          setLoading={setLoadingUrl}
                                          apiLink={tokenValue.apiUrl}
                                          apiToken={tokenValue.value}
                                          externalApiCall={externalCourseApi}
                                          externalCourseStart={
                                            externalCourseStart
                                          }
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                              )
                            ) : courseDetails.status !== "NotStarted" ? (
                              <div item xs>
                                {courseDetails.externalObjectId == null ||
                                courseDetails.externalObjectName == null ? (
                                  <Button
                                    variant="outlined"
                                    onClick={goToCourse}
                                    className="buy-button"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {courseDetails.status == "Inprogress"
                                      ? loadingUrl
                                        ? "Loading..."
                                        : "Continue Learning"
                                      : "course Restart"}
                                  </Button>
                                ) : (
                                  <Confirmation
                                    status={courseDetails.status}
                                    loadingUrl={loadingUrl}
                                    setLoading={setLoadingUrl}
                                    apiLink={tokenValue?.apiUrl}
                                    apiToken={tokenValue?.value}
                                    externalApiCall={externalCourseApi}
                                    externalCourseStart={externalCourseStart}
                                  />
                                )}
                              </div>
                            ) : (
                              <div item xs>
                                {courseDetails.externalObjectId == null ||
                                courseDetails.externalObjectName == null ? (
                                  <Button
                                    variant="outlined"
                                    onClick={goToCourse}
                                    className="buy-button"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {loadingUrl
                                      ? "loading..."
                                      : "Enroll course"}
                                  </Button>
                                ) : (
                                  <Confirmation
                                    status={courseDetails.status}
                                    loadingUrl={loadingUrl}
                                    setLoading={setLoadingUrl}
                                    apiLink={tokenValue.apiUrl}
                                    apiToken={tokenValue.value}
                                    externalApiCall={externalCourseApi}
                                    externalCourseStart={externalCourseStart}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        padding: "40px 0",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.99998 0.332031C3.32398 0.332031 0.333313 3.3227 0.333313 6.9987C0.333313 10.6747 3.32398 13.6654 6.99998 13.6654C10.676 13.6654 13.6666 10.6747 13.6666 6.9987C13.6666 3.3227 10.676 0.332031 6.99998 0.332031ZM7.66665 10.332H6.33331V6.33203H7.66665V10.332ZM7.66665 4.9987H6.33331V3.66536H7.66665V4.9987Z"
                            fill="#F2F1F3"
                          />
                        </svg>
                      </div>
                      <p
                        style={{
                          margin: 0,
                          color: "#F2F1F3",
                          paddingLeft: "9px",
                        }}
                      >
                        While clicking continue learning you will redirect to
                        course provider page
                      </p>
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </Box>
            <SignInModal
              head={head}
              modal={handleSignInModalOpen}
              setModal={setHandleSignInModalOpen}
              buyPrepare
              preapareId={courseId}
            ></SignInModal>
          </div>
        </div>
      </div>
    </>
  );
}
