import React from "react";
import { RupeeSymbol } from "assets/icons/icons";
import styles from "./PriceContainer.module.scss";
import { CalculateDiscount } from "./../../utils/CalculateDiscount";

interface Props {
  originalPrice: string;
  strikingPrice: string;
}

export const PriceContainer: React.FC<Props> = ({
  strikingPrice,
  originalPrice,
}) => {
  const discountPrice = CalculateDiscount(
    +originalPrice,
    +strikingPrice
  ).toFixed();

  return (
    <div className={styles.priceContainer}>
      <div>
        {originalPrice == "" ? "" :
        <RupeeSymbol size={{ width: 13, height: 19 }} color="#000" />
        }
        <span className={styles.price}>
          {+strikingPrice == 0 ? originalPrice == "" ? "Free" : originalPrice + "INR": strikingPrice} 
        </span>
      </div>
      {+strikingPrice != 0 && (
        <div>
          <RupeeSymbol size={{ width: 10, height: 14 }} color="#F91818" />
          <span className={styles.priceStriked}>{originalPrice} INR</span>
          <span>(save {discountPrice === "NaN" ? 0 : discountPrice} %)</span>
        </div>
      )}
    </div>
  );
};
