import { useContext, useEffect, useMemo } from "react";
import cn from "classnames";
import Scroll from "react-scroll";
import {
  AppBar,
  Button,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { PracticeLabContext } from "../../context/practice-lab.context";
import styles from "./section.module.scss";

const scroll = Scroll.animateScroll;

const TitleBar = withStyles({
  root: {
    height: "40px !important",
    padding: "0 !important",
  },
})(AppBar);

const TitleBarToolBar = withStyles({
  root: {
    height: "100%",
    minHeight: "auto",
    padding: "0 20px !important",
  },
})(Toolbar);

const Title = withStyles({
  root: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 550,
    lineHeight: "21px",
  },
})(Typography);

const SectionContainer = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
})(Paper);

const VisibilityButton = withStyles({
  root: {
    backgroundColor: "transparent !important",
    minWidth: "auto !important",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  label: {
    height: "100% !important",
  },
})(Button);

const DebugButtons = withStyles({
  root: {
    backgroundColor:"#14301E",
    color: "#ffffff",
    padding: "0 8px 0 12px",
  },
  label: {
    whiteSpace: "nowrap",
  },
  disabled: {
    color: "#fff8 !important",
    backgroundColor:"#14301E",
  },
})(Button);

function Spacer() {
  return <div style={{ margin: "auto", width: 1 }} />;
}

export default function Section(props) {
  const theme = useTheme();
  const { isLightMode, smallScreen, largeScreen } =
    useContext(PracticeLabContext);

  const questionId = useMemo(() => props?.questionId, [props]);
  const sectionId = useMemo(() => props?.sectionId, [props]);
  const visible = useMemo(() => props?.visible, [props]);

  useEffect(() => {
    if (sectionId) {
      scroll.scrollToTop({
        containerId: sectionId,
        duration: 0,
      });
    }
  }, [questionId, sectionId, visible]);

  return (
    <SectionContainer
      classes={{
        root: cn(styles.container, {
          [styles.lightMode]: isLightMode,
          [styles.smallScreen]: smallScreen,
          [styles.noFreeScroll]: props?.disableFreeScroll,
          ["hintsAnchor"]: props?.title === "Hints",
          ["questionAnchor"]: props?.title?.includes("Question"),
          ["codeSolutionAnchor"]: props?.title === "codeSolutionAnchor",
          ["outputAnchor"]: props?.title === "Output",
          ["solutionSec"]: props?.title === "Solution",
          ["workspaceSec"]: props?.title === "Workspace - Notes" || props?.title === "Workspace",
          ["testCasesAnchor"]:
            props?.title === "Test cases" || props?.title === "Custom Input",
        }, "questionsAndAns"),
      }}
      style={{
        ...(largeScreen && {
          gridColumn: `${props?.gridPosition?.[0]?.[0]} / ${props?.gridPosition?.[0]?.[1]}`,
          gridRow: `${props?.gridPosition?.[1]?.[0]} / ${props?.gridPosition?.[1]?.[1]}`,
        }),
        zIndex: 19,
      }}
    >
      <TitleBar position="relative" style={{ height: smallScreen ? 48 : 40 }}>
        <TitleBarToolBar>
          {props?.header || (
            <Title style={{ color: "#FFFFFF" }}>{props?.title}</Title>
          )}
          <Spacer />
          {props?.setVisibility ? (
            <Tooltip
              title={
                props?.visible ? `Hide ${props?.title}` : `Show ${props?.title}`
              }
            >
              <VisibilityButton
                className={cn(styles.checkbox, {
                  [styles.checked]: props?.visible,
                })}
                onClick={(e) => {
                  props?.setVisibility?.(!props?.visible, e);
                }}
              >
                <div className={styles.checkboxSwitch}>
                  <div className={styles.checkboxText}>Hide</div>
                  <div
                    className={styles.checkboxButton}
                    style={{
                      borderColor: theme.palette.primary.light,
                      backgroundColor: theme.palette.primary.light,
                    }}
                  />
                  <div className={styles.checkboxText}>Show</div>
                </div>
              </VisibilityButton>
            </Tooltip>
          ) : (
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex" }}>
                <div style={{ paddingTop: "23px", paddingRight: "37px" }}>
                  <p>{props?.loadingDetails || null}</p>
                </div>
                {/* <div>{props?.rightSection || props?.compiledInfo || null}</div> */}
                <div>{props?.rightSection || null}</div>
              </div>
              <div>
                <p>{props?.loadingDetails1 || null}</p>
              </div>
              {props.debugSetup && props.debugcCodeConform &&
              <div style={{ display: "flex" }}>
                <DebugButtons>
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 0L0 5.33333H8L4 0ZM0 6.66667H8V8H0V6.66667Z" fill="white"/>
                  </svg><div className="pl-2 pr-2">Start</div>
                </DebugButtons>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <DebugButtons>
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.666992 0V8L7.33366 4L0.666992 0Z" fill="white"/>
</svg>
<div className="pl-2 pr-2">Continue</div>
                </DebugButtons>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <DebugButtons>
                <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.666504 0.666016V7.33268L5.33317 3.99935L0.666504 0.666016ZM6.6665 7.33268V0.666016H5.33317V7.33268H6.6665Z" fill="white"/>
</svg>
<div className="pl-2 pr-2">Step over</div>
                </DebugButtons>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <DebugButtons>
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.66683 3.99935L5.00016 0.666016V7.33268L9.66683 3.99935ZM0.333496 0.666016V7.33268L5.00016 3.99935L0.333496 0.666016Z" fill="white"/>
</svg>
<div className="pl-2 pr-2">Step into</div>
                </DebugButtons>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <DebugButtons>
                <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0V6.67L4 3.335L0 0Z" fill="white"/>
<path d="M3.66699 0V6.66667L8.33366 3.33333L3.66699 0ZM9.66699 6.66667V0H8.33366V6.66667H9.66699Z" fill="white"/>
</svg>
<div className="pl-2 pr-2">Step out</div>
                </DebugButtons>
               </div>
              }
            </div>
          )}
          
        </TitleBarToolBar>
      </TitleBar>
      {props?.noContentTags ? (
        props?.children
      ) : (
        <div
          id={props?.sectionId}
          className={cn(styles.content, props?.noRichText && styles.noRichText)}
        >
          {props?.children}
        </div>
      )}
    </SectionContainer>
  );
}
