import cn from "classnames";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { Icon } from "@iconify/react";
import fileIcon from "@iconify/icons-akar-icons/file";
import package16 from "@iconify/icons-octicon/package-16";
import resultsDemographics from "@iconify/icons-foundation/results-demographics";
import chartAverage from "@iconify/icons-carbon/chart-average";
import emojiNeutral from "@iconify/icons-bi/emoji-neutral";
import emojiSmile from "@iconify/icons-bi/emoji-smile";
import emojiFrown from "@iconify/icons-bi/emoji-frown";
import ProgressBar from "../../../Dashboard/components/ProgressBar/ProgressBar";
import styles from "./index.module.scss";

export default function Dashboard({ reportDashboardData, isAssessment }) {
  return (
    <div className={styles.dashboard}>
      <div className={styles.dashboardSection1}>
        <div className={styles.assessmentNameContainer}>
          <div
            className={cn(
              styles.dashboardIconBox,
              isAssessment ? styles.blue : styles.gray
            )}
          >
            <Icon
              icon={isAssessment ? fileIcon : package16}
              style={{ color: "#fff", fontSize: 24 }}
            />
          </div>
          <div className={styles.assessmentName}>
            {reportDashboardData?.name}
          </div>
        </div>
        <div className={styles.assessmentInfoGrid}>
          <div>
            <span>Enrolled: </span>
            <span>{reportDashboardData?.enrolled}</span>
          </div>
          <div>
            <span>Completed: </span>
            <span>{reportDashboardData?.completed}</span>
          </div>
          <div>
            <span>{isAssessment ? "Attended: " : "Started: "}</span>
            <span>
              {isAssessment
                ? reportDashboardData?.attended
                : reportDashboardData?.started}
            </span>
          </div>
          <div>
            <span>{isAssessment ? "Not attended: " : "Not Started: "} </span>
            <span>
              {isAssessment
                ? reportDashboardData?.notAttended
                : reportDashboardData?.notStarted}
            </span>
          </div>
        </div>
        <div className={styles.completedProgressContainer}>
          <ProgressBar
            orange
            percentage={reportDashboardData?.completedProgress}
          />
          <div className={styles.completedProgressText}>
            {`${parseFloat(reportDashboardData?.completedProgress)?.toFixed(
              0
            )}% completed`}
          </div>
        </div>
      </div>
      <div className={styles.dashboardSection2}>
        <div className={styles.dashboardBox}>
          <div className={styles.dashboardBoxValuesContainer}>
            <div className={styles.dashboardBoxValueFlex}>
              <div
                className={styles.dashboardBoxValue}
                style={{ color: "#1BA94C" }}
              >
                {isAssessment
                  ? reportDashboardData?.passStudentCount
                  : reportDashboardData?.completed}
              </div>
              <div className={styles.dashboardBoxText}>
                {isAssessment ? "Pass" : "Completed"}
              </div>
            </div>
            <div className={styles.dashboardBoxValueFlex}>
              <div
                className={styles.dashboardBoxValue}
                style={{ color: "#D42216" }}
              >
                {isAssessment
                  ? reportDashboardData?.failStudentCount
                  : reportDashboardData?.pending}
              </div>
              <div className={styles.dashboardBoxText}>
                {isAssessment ? "Fail" : "Pending"}
              </div>
            </div>
          </div>
          <div className={cn(styles.dashboardIconBox, styles.red)}>
            <Icon
              icon={resultsDemographics}
              style={{ color: "#A6170D", fontSize: 24 }}
            />
          </div>
        </div>
        <div className={styles.dashboardBox}>
          <div className={styles.dashboardBoxValueFlex}>
            <div
              className={styles.dashboardBoxValue}
              style={{ color: "#1138BE" }}
            >
              {`${parseFloat(
                isAssessment
                  ? reportDashboardData?.overAllAvgMark
                  : reportDashboardData?.overAllAccuracy
              )?.toFixed(2)}%`}
            </div>
            <div className={styles.dashboardBoxText}>
              {isAssessment ? "Overall Avg mark" : "Overall Accuracy"}
            </div>
          </div>
          <div className={cn(styles.dashboardIconBox, styles.lightBlue)}>
            <Icon
              icon={chartAverage}
              style={{ color: "#3000B6", fontSize: 24 }}
            />
          </div>
        </div>
      </div>
      <div className={styles.dashboardSection3}>
        <div className={cn(styles.dashboardBox, styles.date)}>
          <Grid container alignItems="center" justifyContent="space-between">
            <div className={cn(styles.dateTime, styles.start)}>
              <span>
                {moment(
                  reportDashboardData?.startDate?.replace("+00:00", "+05:30")
                ).format("DD-MM-YYYY")}
              </span>
              <span>
                {moment(
                  reportDashboardData?.startDate?.replace("+00:00", "+05:30")
                ).format("hh : mm A")}
              </span>
            </div>
            &nbsp;&nbsp;{"TO"}&nbsp;&nbsp;
            <div className={cn(styles.dateTime, styles.end)}>
              <span>
                {moment(
                  reportDashboardData?.endDate?.replace("+00:00", "+05:30")
                ).format("DD-MM-YYYY")}
              </span>
              <span>
                {moment(
                  reportDashboardData?.endDate?.replace("+00:00", "+05:30")
                ).format("hh : mm A")}
              </span>
            </div>
          </Grid>
        </div>
        <div className={styles.dashboardBox}>
          <Grid container alignItems="center" justifyContent="space-between">
            <div
              className={styles.dashboardBoxText}
              style={{ lineHeight: 1.5 }}
            >
              Overall
              <br />
              performance
            </div>
            <div className={styles.overAllPerformanceIconContainer}>
              {reportDashboardData?.overAllPerformance === "Good" && (
                <Icon
                  icon={emojiSmile}
                  style={{ color: "#1ba94c", fontSize: 32 }}
                />
              )}
              {reportDashboardData?.overAllPerformance === "Average" && (
                <Icon
                  icon={emojiNeutral}
                  style={{ color: "#eeb60c", fontSize: 32 }}
                />
              )}
              {reportDashboardData?.overAllPerformance === "Poor" && (
                <Icon
                  icon={emojiFrown}
                  style={{ color: "#d42216", fontSize: 32 }}
                />
              )}
              <span
                style={{
                  color:
                    reportDashboardData?.overAllPerformance === "Good"
                      ? "#1ba94c"
                      : reportDashboardData?.overAllPerformance === "Average"
                      ? "#eeb60c"
                      : "#d42216",
                  fontSize: 16,
                  marginTop: 4,
                  textTransform: "capitalize",
                }}
              >
                {reportDashboardData?.overAllPerformance}
              </span>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
}
