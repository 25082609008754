import { useMemo, useState } from 'react';
import { useSolutionInfo } from '../../hooks/use-solution-info';
import TabbedSection from '../../components/tabbed-section/tabbed-section';
import Confirmation from '../../components/confirmation/confirmation';

export default function HintsSection({
  activity,
  available,
  canView,
  details,
  hintViewReductionPercentage = 25,
  id,
  logActivity,
  questionId,
  setActivity,
  setVisibility,
  visible,
  ...props
}) {
  const { data, loading } = useSolutionInfo(id, 'hint', visible);
  const hints = useMemo(() => Object.values(data?.[0]?.hints?.map((h) => (Object.values(h))) || {}), [data]);
  const [confirmationAnchor, setConfirmationAnchor] = useState(null);

  return (
    <>
      <TabbedSection
        available={available}
        canView={canView}
        disabledText="Hints are disabled"
        loading={loading}
        questionId={questionId}
        sectionId="practice-lab-hint"
        setVisibility={(isVisible, e) => {
          if (activity?.hint) {
            setVisibility(isVisible);
          } else {
            setConfirmationAnchor(e?.currentTarget);
          }
        }}
        tabIndexLabel="Hint"
        tabs={hints}
        title="Hints"
        unAvailableText="Hints were not provided"
        visible={visible}
        {...props}
      />
      <Confirmation
        anchor={confirmationAnchor}
        confirmation
        description={`Viewing the hint will reduce your score by ${hintViewReductionPercentage}%. Are you sure you want to view hint?`}
        onContinue={() => {
          setVisibility((v) => {
            if (!v) {
              logActivity('hint');
            }

            return !v;
          });
          setActivity((a) => ({
            ...a,
            [id]: {
              ...(a?.[id] || {}),
              hint: true,
            },
          }));
        }}
        setAnchor={setConfirmationAnchor}
        title="Show Hint?"
      />
    </>
  );
}