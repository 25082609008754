import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { TervButtonFilled } from 'components/TervButtons';
import { toast } from "react-toastify";

const ModalComponent = ({modalOpen , setModalOpen}) => {
  const [email , setEmail] = useState('');

  const success = () =>{
    setEmail('');
    setModalOpen(false)
    toast.success("The Document will be shared to your E-mail shortly", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
    });
  }

  return (
    <div className=''>
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            style={{
              width : "30%",
              height: "200px",
              display : "flex",
              justifyContent : "center",
              alignItems : "center",
              borderRadius : "15px",
              border : "1px solid black",
              margin : "auto",
              backgroundColor : "#242145",
            }}
        >
        <div className='w-full'>
          <div className='w-[70%] mx-auto mb-2 p-2'>
            <input 
              type="email"
              placeholder='Enter your Email'
              style={{
                padding : "10px",
                borderRadius : "15px",
                width : "100%",
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className='w-full flex justify-center mt-2'>
            <TervButtonFilled
              type="submit" 
              text='Submit'
              onClick={success}
              disabled={email.length == 0}
            />
          </div>
        </div>

        </Modal>
    </div>
  )
}

export default ModalComponent