import { useState, useEffect } from "react";
import { Tabs } from "components/tabs/tabs";
import Select from "react-select";
import Pagination from "react-js-pagination";
import { paginationClasses } from "../utils/constants";
import { ToastContainer } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import {
  manualGradingOne,
  penIcon,
  trashFilled,
  fileReportOne,
  eyeFilled,
} from "assets/icons/icons";
import CardsViewHeader from "components/CardsViewHeader";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { ReactSelectTervStyle } from "pages/utils/ReactSelectTervStyle";
import styles from "./AllCoursesDumb.module.scss";
import { Link, useHistory } from "react-router-dom";
import { uid } from "react-uid";
import cn from "classnames";
import { CapitalizeFirstLetter } from "pages/utils/CapitalizeFirstLetter";
import AnimatedMount from "../Prepare/AnimatedMount";
import moment from "moment";
import { removeAllListeners } from "process";
import { sessionPrivileges } from "privileges";

const CardButtons = ({
  id,
  name,
  toggleDeleteModal,
  toggleStatusModal,
  setDeleteCourseId,
  status,
  activeTab,
  customerId,
}) => {
  const history = useHistory();
  const privileges = sessionPrivileges().userprivileges || [];

  return (
    <div className={styles.cardButtons}>
      <>
        <Tooltip title="Report" arrow placement="top-start">
          <Link
            to={{
              pathname: `/hackathonReport/${id}`,
              state: { customerId },
            }}
          >
            <div>{fileReportOne}</div>
          </Link>
        </Tooltip>
        {activeTab !== "Completed" && (
          <Tooltip title="Edit hackathon" arrow placement="top-start">
            <Link
              to={{
                pathname: `/hackathon-form/${id}`,
                state: { fromEdit: true, customerId },
              }}
            >
              <div>{penIcon("#784df4")}</div>
            </Link>
          </Tooltip>
        )}
        <Tooltip title="Delete Hackathon" arrow placement="top-start">
          <div
            onClick={() => {
              setDeleteCourseId(id);
              toggleDeleteModal();
            }}
          >
            {trashFilled}
          </div>
        </Tooltip>
      </>
    </div>
  );
};

const CourseCard = ({
  details: {
    name,
    id,
    media,
    hackathonEndDate,
    status,
    registeredUsersCount,
    submittedUsersCount,
    hackathonStartDate,
  },
  key,
  toggleDeleteModal,
  toggleStatusModal,
  setDeleteCourseId,
  activeTab,
  customerId,
}) => (
  <div className={styles.courseCard} key={key}>
    {activeTab === "all" && (
      <span
        className={cn([styles.tag], {
          [styles.tagAP]: status === "Live",
          [styles.tagDF]: status === "Completed",
          [styles.tagPN]: status === "Scheduled",
        })}
      >
        {status === "Completed" && CapitalizeFirstLetter("Completed")}
        {status === "Live" && CapitalizeFirstLetter("Live")}
        {status === "Scheduled" && CapitalizeFirstLetter("Scheduled")}
      </span>
    )}
    <img src={media} alt="img" />

    <div className={styles.courseCardContent}>
      <div>
        <p className={styles.courseCardName}>{name}</p>
        {status === "Live" && (
          <div className={styles.courseCardDetails}>
            <span style={{ fontSize: "14px" }}>Ends in :</span>
            <span style={{ fontSize: "14px", fontWeight: "500" }}>
              {moment(hackathonEndDate).format("DD MMMM YYYY")}
            </span>
          </div>
        )}
        {status === "Completed" && (
          <div className={styles.courseCardDetails}>
            <span style={{ fontSize: "14px" }}>Completed on:</span>
            <span style={{ fontSize: "14px", fontWeight: "500" }}>
              {moment(hackathonEndDate).format("DD MMMM YYYY")}
            </span>
          </div>
        )}
        {status === "Scheduled" && (
          <div className={styles.courseCardDetails}>
            <span style={{ fontSize: "14px" }}>Starts :</span>
            <span style={{ fontSize: "14px", fontWeight: "500" }}>
              {moment(hackathonStartDate).format("DD MMMM YYYY")}
            </span>
          </div>
        )}
        <div className={styles.courseCardDetailsrow}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "14px", fontWeight: "500" }}>
              {registeredUsersCount}
            </span>
            <span>Enrolled</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "14px", fontWeight: "500" }}>
              {submittedUsersCount}
            </span>
            <span>Submissions</span>
          </div>
        </div>
      </div>
      <CardButtons
        id={id}
        name={name}
        setDeleteCourseId={setDeleteCourseId}
        toggleDeleteModal={toggleDeleteModal}
        toggleStatusModal={toggleStatusModal}
        status={status}
        activeTab={activeTab}
        customerId={customerId}
      />
    </div>
  </div>
);

const AllCoursesDumb = ({
  setActiveTab,
  activeTab,
  allCustomers,
  customerId,
  courses,
  handleCustomerChange,
  tabs,
  firstIndex,
  lastIndex,
  toggleDeleteModal,
  toggleStatusModal,
  setDeleteCourseId,
  activePage,
  handlePageChange,
  setCourses,
  setActivePages,
  setCustomerName,
}) => {
  const handleSearch = (query) => {
    let newList = [];
    if (query) {
      courses.real[activeTab]?.forEach((e) => {
        if (e.name.toLowerCase().includes(query.toLowerCase())) {
          newList.push(e);
        }
      });
      setCourses({
        ...courses,
        modify:
          query.length >= 2
            ? newList.length > 0
              ? { ...courses.modify, [activeTab]: newList }
              : ""
            : courses.real,
      });
    } else {
      setCourses({
        ...courses,
        modify: courses.real,
      });
    }
  };
  const activeSearch = () => {
    setActiveTab("all");
    setActivePages((prev) => ({ ...prev, [activeTab]: 1 }));
  };
  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <CardsViewHeader
          header="Hackathon"
          searchPlaceholder="Hackathon"
          handleSearch={handleSearch}
          handleClick={activeSearch}
          createLink={{ pathname: "hackathon-form", state: { customerId } }}
        />
        <Tabs
          onChange={setActiveTab}
          activeTab={activeTab}
          tabs={tabs}
          actions={
            <Select
              options={allCustomers}
              onChange={(val) => {
                handleCustomerChange(val.value);
                setCustomerName(val.label);
                setActiveTab("all");
                setActivePages((prev) => ({ ...prev, [activeTab]: 1 }));
              }}
              value={GetObjectFromString(allCustomers, customerId)}
              styles={ReactSelectTervStyle("md")}
            />
          }
        >
          <AnimatedMount>
            <div className={styles.wrappedCards} style={{ paddingTop: "50px" }}>
              {courses.modify[activeTab] &&
              courses.real[activeTab].length > 0 ? (
                courses?.modify[activeTab]
                  ?.slice(firstIndex, lastIndex)
                  .map((item, index) => (
                    <div>
                      <CourseCard
                        details={item}
                        key={uid(index)}
                        toggleDeleteModal={toggleDeleteModal}
                        toggleStatusModal={toggleStatusModal}
                        setDeleteCourseId={setDeleteCourseId}
                        activeTab={activeTab}
                        customerId={customerId}
                      />
                    </div>
                  ))
              ) : (
                <div>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    No Hackathon Found
                  </p>
                </div>
              )}
            </div>
          </AnimatedMount>
          {courses.real[activeTab].length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5rem",
              }}
            >
              <Pagination
                activePage={activePage}
                itemsCountPerPage={6}
                totalItemsCount={courses.real[activeTab].length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                {...paginationClasses}
              />
            </div>
          )}
        </Tabs>
      </div>
    </>
  );
};

export default AllCoursesDumb;
