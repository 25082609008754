/** @format */

import React from "react";
import { ContentHeader } from "./ContentHeader";
import { HomeContent } from "./HomeContent";
import MarqueeComponent from "react-fast-marquee";
import {
  amazon,
  cognizant,
  accenture,
  adobe,
  infosys,
  capgemini,
  tcs,
  zoho,
  freshworks,
  google,
  hcl,
  hp,
  ibm,
  ms,
  morganstanley,
  msigma,
  paypal,
  paytm,
  sopia,
  techmahindra,
  boeing,
  verizon,
  walmart,
  wipro,
} from "./PlacedCompanies";
import { useTranslation } from "react-i18next";

const PlacedCompanies1 = [
  amazon,
  cognizant,
  accenture,
  adobe,
  infosys,
  capgemini,
  tcs,
  google,
  hp,
  ibm,
  ms,
];

const PlacedCompanies2 = [
  morganstanley,
  paypal,
  sopia,
  techmahindra,
  boeing,
  verizon,
  walmart,
  wipro,
  zoho,
];

const Marquee: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <ContentHeader
        title={t(HomeContent.Headers.Prospects.Main)}
        sub={t(HomeContent.Headers.Prospects.Sub)}
        description={t(HomeContent.Headers.Prospects.Description)}
      />
      <MarqueeComponent style={{ marginBottom: "-3rem" }}>
        {PlacedCompanies1.map((it: JSX.Element, ind: number) => (
          <div key={`Company1${ind}`} style={{ width: 180 }}>
            {it}
          </div>
        ))}
      </MarqueeComponent>
      <MarqueeComponent direction="right">
        {PlacedCompanies2.map((it: JSX.Element, ind: number) => (
          <div key={`Company2${ind}`} style={{ width: 180 }}>
            {it}
          </div>
        ))}
      </MarqueeComponent>
    </>
  );
};

export default Marquee;
