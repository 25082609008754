import React from "react";
import styles from "./FormLayout.module.scss";

interface Props {
  title?: string;
  description?: string;
  hideDivider?: string;
  children: React.ReactNode;
}

export const FormLayout: React.FC<Props> = ({
  title,
  description,
  hideDivider = false,
  children,
}) => (
  <>
    <div className={styles.container}>
      <div className={styles.childOne}>
        {title && <h4>{title}</h4>}
        {description && <p>{description}</p>}
      </div>
      <div className={styles.childTwo}>{children}</div>
    </div>
    {!hideDivider && <hr className={styles.divider} />}
  </>
);
