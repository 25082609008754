import cn from "classnames";
import moment from "moment";
import styles from "./index.module.scss";
import { CapitalizeFirstLetter } from "../../../utils/CapitalizeFirstLetter";
import { WinnerCup } from "../../../Prepare/SVGs";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import api from "api/baseConfig";
import apiHelper from "api/apiHelper";
import { GreenToast } from "components/GreenToast";
import { useParams, Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

export default function Dashboard({ reportDashboardData, winner }) {
  const { id } = useParams();
  const submitMarks = async () => {
    const url = `${api.baseURL}${api.Hackathon.saveTopperRank}`;
    const payload = [
      {
        userId: `${winner[`First`]?.userId}`,
        hackathonId: `${id}`,
        topper: `${winner[`First`]?.winner}`,
      },
      {
        userId: `${winner[`Second`]?.userId}`,
        hackathonId: `${id}`,
        topper: `${winner[`Second`]?.winner}`,
      },
      {
        userId: `${winner[`Third`]?.userId}`,
        hackathonId: `${id}`,
        topper: `${winner[`Third`]?.winner}`,
      },
    ];

    try {
      const data = await apiHelper.axiosCallPost(url, "post", payload);
      GreenToast({ message: "successfully published the winners!" });
    } catch (error) {
      throw new Error(`Some error occurred: ${error}`);
    }
  };

  return (
    <>
      <div className={styles.dashboard}>
        <div className={styles.dashboardSection1}>
          <div style={{ display: "flex" }}>
            <div
              className={cn([styles.tag], {
                [styles.tagAP]: reportDashboardData.status === "Live",
                [styles.tagDF]: reportDashboardData.status === "Completed",
                [styles.tagPN]: reportDashboardData.status === "Scheduled",
              })}
            >
              {reportDashboardData.status === "Completed" &&
                CapitalizeFirstLetter("Completed")}
              {reportDashboardData.status === "Live" &&
                CapitalizeFirstLetter("Live")}
              {reportDashboardData.status === "Scheduled" &&
                CapitalizeFirstLetter("Scheduled")}
            </div>
            <div
              className={cn([styles.tag2], {
                // [styles.tagAP]: data.status === "Completed",
                // [styles.tagDF]: data.status === "live",
                // [styles.tagPN]: data.status === "Scheduled",
              })}
            >
              <div style={{ display: "flex" }}>
                <span className="pr-2">
                  {reportDashboardData.registration === "Registration open" && (
                    <svg
                      width="9"
                      height="8"
                      viewBox="0 0 9 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4.5" cy="4" r="4" fill="#1BA94C" />
                    </svg>
                  )}
                  {reportDashboardData.registration ===
                    "Registration closed" && (
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4" cy="4" r="4" fill="#DE5445" />
                    </svg>
                  )}
                </span>
                {reportDashboardData.registration === "Registration open"
                  ? CapitalizeFirstLetter("Registration Open")
                  : reportDashboardData.registration === "Registration closed"
                  ? CapitalizeFirstLetter("Registration closed")
                  : ""}
              </div>
            </div>
          </div>
          <div className={styles.assessmentNameContainer}>
            <div></div>
            <div className={styles.assessmentName}>
              {CapitalizeFirstLetter(reportDashboardData?.name)}
            </div>
          </div>
          <p>REGISTRATION DETAILS</p>
          <div className={styles.assessmentInfoGrid}>
            <div>
              <span>Assigned users: </span>
              <span>{reportDashboardData?.assignedUsersCount}</span>
            </div>
            <div>
              <span>Registered users:</span>
              <span>{reportDashboardData?.registeredUsersCount}</span>
            </div>
            <div>
              <span>Submitted users: </span>
              <span>{reportDashboardData?.submittedUsersCount}</span>
            </div>
            <div>
              <span>Not submitted Users:</span>
              <span>{reportDashboardData?.notSubmittedUsersCount}</span>
            </div>
          </div>
        </div>
        <div className={styles.dashboardSection2}>
          <div className={styles.dashboardBox}>
            <div className={styles.dashboardBoxValuesContainer}>
              <div className={styles.dashboardBoxValueFlex}>
                <div
                  className={styles.dashboardBoxValue}
                  style={{
                    fontWeight: 500,
                    fontSize: "24px",
                    lineHeight: "36px",
                  }}
                >
                  {reportDashboardData?.registeredUsersCount}
                </div>
                <div className={styles.dashboardBoxText}>Registered</div>
              </div>
              <div className={styles.dashboardBoxValueFlex}>
                <div
                  className={styles.dashboardBoxValue}
                  style={{
                    fontWeight: 500,
                    fontSize: "24px",
                    lineHeight: "36px",
                  }}
                >
                  {reportDashboardData?.submittedUsersCount}
                </div>
                <div className={styles.dashboardBoxText}>No of submissions</div>
              </div>
            </div>
          </div>
          <div className={styles.dashboardBox}>
            <div className={styles.dashboardBoxValuesContainer}>
              <div className={styles.dashboardBoxValueFlex}>
                <div className={styles.dashboardBoxText}>Team size</div>
                <div
                  className={styles.dashboardBoxValue}
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "36px",
                  }}
                >
                  {reportDashboardData?.type == "Project"
                    ? reportDashboardData?.teamSize
                    : "Only individuals"}
                </div>
              </div>
              <div className={styles.dashboardBoxValueFlex}>
                <div className={styles.dashboardBoxText}>Hackathon type</div>
                <div
                  className={styles.dashboardBoxValue}
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "36px",
                  }}
                >
                  {reportDashboardData?.type}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dashboardDateBox}>
          <div className={styles.startDateHack}>
            <div style={{ color: "#7C7887" }}>Registration starts</div>
            <div style={{ color: "#089A79" }}>
              {moment(reportDashboardData?.registrationStartDate).format(
                "DD MMM yyyy, hh:mm a"
              )}
            </div>
          </div>
          <div className={styles.startDateHack}>
            <div style={{ color: "#7C7887" }}>Registration ends</div>
            <div style={{ color: "#DE5445" }}>
              {moment(reportDashboardData?.registrationEndDate).format(
                "DD MMM yyyy, hh:mm a"
              )}
            </div>
          </div>
          <div className={styles.startDate}>
            <div style={{ color: "#7C7887" }}>Hackathon starts</div>
            <div style={{ color: "#089A79" }}>
              {moment(reportDashboardData?.hackathonStartDate).format(
                "DD MMM yyyy, hh:mm a"
              )}
            </div>
          </div>
          <div className={styles.startDate}>
            <div style={{ color: "#7C7887" }}>Hackathon ends</div>
            <div style={{ color: "#DE5445" }}>
              {moment(reportDashboardData?.hackathonEndDate).format(
                "DD MMM yyyy, hh:mm a"
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-3 pb-3">
        <div className={styles.dashboardPrizeBox}>
          <Card variant="outlined" className="pl-5">
            <div className={styles.dashboardGridBox}>
              <div
                style={{
                  display: "flex",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  alignItems: "center",
                }}
              >
                <WinnerCup />
                <div className="pl-3">
                  <p>1st Prize</p>
                  <p className="prizeHackathon">
                    {" "}
                    {reportDashboardData?.prizeType == "product"
                      ? `${reportDashboardData?.prizes?.firstPrize}`
                      : reportDashboardData?.prizeType == "points"
                      ? `points - ${reportDashboardData?.prizes?.firstPrize}`
                      : `Rupees - ${reportDashboardData?.prizes?.firstPrize}`}{" "}
                  </p>
                </div>
                <div className="pl-5" style={{ display: "flex" }}>
                  <WinnerCup />
                  <div className="pl-3">
                    <p>2nd Prize</p>
                    <p className="prizeHackathon">
                      {reportDashboardData?.prizeType == "product"
                        ? `${reportDashboardData?.prizes?.secondPrize}`
                        : reportDashboardData?.prizeType == "points"
                        ? `points - ${reportDashboardData?.prizes?.secondPrize}`
                        : `Rupees - ${reportDashboardData?.prizes?.secondPrize}`}
                    </p>
                  </div>
                </div>
                <div className="pl-5" style={{ display: "flex" }}>
                  <WinnerCup />
                  <div className="pl-3">
                    <p>3rd Prize</p>
                    <p className="prizeHackathon">
                      {reportDashboardData?.prizeType == "product"
                        ? `${reportDashboardData?.prizes?.thirdPrize}`
                        : reportDashboardData?.prizeType == "points"
                        ? `points - ${reportDashboardData?.prizes?.thirdPrize}`
                        : `Rupees - ${reportDashboardData?.prizes?.thirdPrize}`}
                    </p>
                  </div>
                </div>
                <div className="pl-5">
                  <div className="pt-2">
                    {reportDashboardData?.adminEvaluation == "Completed" ? (
                      <Button
                        onClick={submitMarks}
                        style={{
                          border: "1px solid #1BA94C",
                          textTransform: "capitalize",
                          paddingLeft: "2rem",
                          paddingRight: "2rem",
                        }}
                      >
                        Publish Winners
                      </Button>
                    ) : (
                      <Tooltip
                        title="publish winners will be enabled once winners are selected"
                        arrow
                        placement="top-start"
                      >
                        <Button
                          style={{
                            backgroundColor: "#D8D6DB",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            paddingLeft: "2rem",
                            paddingRight: "2rem",
                          }}
                        >
                          Publish Winners
                        </Button>
                      </Tooltip>
                    )}
                    {reportDashboardData?.publishedResult == "true" && (
                      <div style={{ color: "green" }}>
                        Winners has been paublished
                      </div>
                    )}
                  </div>
                  {/* <div className="pt-2">
                          <Link
                            to={{
                              pathname: `/HackathonGrading/${id}`,
                            }}
                          >
                            <Button style={{border:"1px solid #1BA94C", textTransform: "capitalize", paddingLeft:"2rem", paddingRight:"2rem"}}>Start Evaluation</Button>
                          </Link>
                          </div> */}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
