import { Table } from "reactstrap";
import "./ViewQ.scss";

function ViewQuestionTable({ headers, values }) {
  return (
    <Table striped bordered>
      <thead
        style={{
          background: "#C5EAFC",
        }}
      >
        <tr>
          <th>S.No</th>
          {headers.map((item) => (
            <th>{item.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {values.map((ele, index) => (
          <tr>
            <td>{index + 1}</td>
            {headers.map((item) => (
              
                item.value === "answer"
                  ? <td>{ele[item.value]
                    ? "Right"
                    : "Wrong"}</td>
                  : <td className="view-ques" dangerouslySetInnerHTML={{__html: ele[item.value]}}></td>
              
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default ViewQuestionTable;
