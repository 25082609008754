import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import WorkIcon from '@material-ui/icons/Work';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import apiHelper from 'api/apiHelper';
import api from "api/baseConfig";
import Select from 'react-select';
import SkillsTableComponent from './SkillsTableComponent';
import { Snackbar, TextField } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import FieldCopy from './FieldCopy';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        animation: `$progressBarAnimation 1s ease-in-out forwards`, // Trigger animation
      },
    },
    completed: {
      '& $line': {
        animation: `$progressBarAnimation 1s ease-in-out forwards`, // Trigger animation
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
    '@keyframes progressBarAnimation': {
      '0%': {
        width: '0%', // Start with 0 width
        backgroundImage: 'linear-gradient(95deg, #6A3BF8 0%, #774BF8 50%, #8A61F8 100%)'
      },
      '100%': {
        width: '100%', // Full width on completion
        backgroundImage: 'linear-gradient(95deg, #6A3BF8 0%, #774BF8 50%, #8A61F8 100%)'
      },
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      animation: `$iconColorChange 0.1s ease-in-out 1s forwards`, // Trigger color change after 2s delay
    },
    completed: {
      animation: `$iconColorChange 0.1s ease-in-out 1s forwards`, // Trigger color change after 2s delay
    },
    '@keyframes iconColorChange': {
      '0%': {
        backgroundColor: '#ccc', // Initial color before animation
        backgroundImage: 'none',
      },
      '100%': {
        backgroundImage: 'linear-gradient(136deg, #6D3BF8 0%, #774BF8 50%, #8B61F8 100%)', // Final gradient after connector connects
      },
    },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: completed ? <Check /> : <WorkIcon />,
    2: completed ? <Check /> : <WbIncandescentIcon />,
    3: completed ? <Check /> : <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Choose Job Role', 'Assign Skils', 'Generate Link'];
}

export default function ThreeStepComponent() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skills , setSkills] = useState([]);
  const [jobRoles , setJobRoles] = useState([]);
  const [skillList , setSkillList] = useState([]);
  const [showLink , setShowLink] = useState(false);
  const [link , setLink] = useState("");
  const [toast , setToast] = useState(false);
  const [toastMsg , setToastMsg] = useState("");
  const [toastType , setToastType] = useState("success");

  useEffect(()=>{
    setData((prevData) => ({ ...prevData, skills: skillList }));
  },[skillList]);

  const timeLimit = [
    {label : "30 minutes" , value : 30},
    {label : "60 minutes" , value : 60},
    {label : "90 minutes" , value : 90},
  ]

  const [data , setData] = useState({
    jobRole: '',
    skills : [],
    totalQuestions : 0,
    timeLimit : 0,
    assessmentName : '',
    email : '',
  });
  const steps = getSteps();

  function createAssessmentData(input) {
    // Extract the job role and skills information from the input
    const jobRoleLabel = input.jobRole?.label || "Job Role";
    const skillsLabel = input.skills[0]?.skill?.label || "Skills";
    
    // Calculate question counts based on skill levels
    const easyCount = input.skills.reduce((sum, item) => sum + (item.easy || 0), 0);
    const mediumCount = input.skills.reduce((sum, item) => sum + (item.medium || 0), 0);
    const hardCount = input.skills.reduce((sum, item) => sum + (item.hard || 0), 0);
    const questionCount = easyCount + mediumCount + hardCount;

    return {
        skills: skillsLabel,
        jobRole: jobRoleLabel,
        questionCount: questionCount,
        hasSplitup: true,  // Assuming that question distribution by difficulty is present
        easyCount: easyCount,
        mediumCount: mediumCount,
        hardCount: hardCount,
        emailId: data.email,  // Default email for example
        collegeId: "66e7f4d6cbb4a2658b7e5992",  // Static ID for example
        assessmentName: input.assessmentName || `Techruit Assessment ${(Math.random()*10000).toString(5)}`,
        assessmentType: "public",
        timeLimit: input.timeLimit?.value || 30,  // Default to 30 minutes if timeLimit is undefined
        createdBy: "superadmin@vc.in",
        showResultOnFinish: true,
        showReview: true,
        showSummary: true,
        showSolution: true,
        createdDate: new Date().toISOString(),
        updatedDate: new Date().toISOString(),
        updatedBy: "superadmin@vc.in",
        timeType: "duration"
    };
}

  const generateAssesmentLink = async () => {
    
    try {
      const response = await apiHelper.axiosCallPost(
        `${api.baseURL}${api.homePage.generateAssessment}`,
        "POST",
        createAssessmentData(data)
      );
      if(response.active){
        setLink(response.assessmentURL || "");
        setShowLink(true);
      }
      else{
        setShowLink(false);
        setToast(true);
        setToastMsg("Fill up all the fields to generate the Link");
        setToastType("error");
      }
    }
    catch(err){
      console.log(err);
    }
  }

  const handleNext = async() => {
    if(activeStep === steps.length -1){
      if(!showLink){
        await generateAssesmentLink();
      }
      else
      {
        setToastMsg("Email sent successfully");
        setToastType("success");
        setToast(true);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setLink("");
    setShowLink(false);
    setSkillList([]);
    setData({
      jobRole: '',
      skills : [],
      totalQuestions : 0,
      timeLimit : 0,
      assessmentName : '',
      email : '',
    });
  };

  const handleOnChange = (val = null , field)=>{
    const newData = {...data}
    newData[field] = val
    setData(newData);
  }
  
  function getStepContent(step) {
    switch (step) {
        case 0:
            return (<div style={{width : "60%", margin : "auto"}}>
                <Select 
                    placeholder="Job Role"
                    options={jobRoles} 
                    value={data.jobRole} 
                    onChange={(val)=>handleOnChange(val , "jobRole")}
                    fullWidth={true}
                    />
            </div>);
        case 1:
            return (<div >
                <SkillsTableComponent
                  skillList={skills}
                  setSkillList={setSkillList}
                  index={0}
                />
            </div>);
        case 2:
            return (<div style={{
              width : "80%", 
              margin : "-20px auto 0px auto" , 
              display : "flex" , 
              justifyContent : `${!link.length ? "center" : "space-around"}`
              }}>
              <div style={{ width : "40%" ,display : "flex" , flexDirection :  "column", gap : "10px",justifyContent : "space-around"
                }}>
                <Select 
                    placeholder="Time Limit"
                    options={timeLimit} 
                    value={data.timeLimit} 
                    onChange={(val)=>handleOnChange(val , "timeLimit")}
                    styles={{zIndex : 12}}
                    isSearchable={false}
                    isDisabled={link.length > 0}
                    menuPosition='fixed'
                    menuPortalTarget={document.body}
                />
                <TextField label="Assessment Name" variant="outlined" size='small' style={{marginTop : "20px" }}
                  value={data.assessmentName}
                  disabled={link.length > 0}
                  InputLabelProps={{
                      style: { fontSize: '14px', paddingLeft: '2px', paddingRight : "2px" }  // Adjust font size and left padding
                  }}
                  InputProps={{
                      style: { paddingLeft: '2px' }  // Adjust padding inside input field
                  }}
                  onChange={(e) => setData({...data,  assessmentName : (e.target.value)})}
                />
                <TextField label="Email Id" variant="outlined" size='small' style={{marginTop : "20px" }}
                  value={data.email}
                  disabled={link.length > 0}
                  InputLabelProps={{
                      style: { fontSize: '14px', paddingLeft: '2px', paddingRight : "2px" }  // Adjust font size and left padding
                  }}
                  InputProps={{
                      style: { paddingLeft: '2px' }  // Adjust padding inside input field
                  }}
                  onChange={(e) => setData({...data,  email : (e.target.value)})}
                />
              </div>
              {link.length > 0 &&
                <div style={{width : "40%" , display : "flex" , justifyContent : "flex-end"}}>
                  <FieldCopy
                    link={link}                    
                  />
                </div>
              }
            </div>);
        default:
            return 'Unknown step';
    }
  }

  const fetchSkills = async () => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.homePage.getHomePageSkills}`,
        "get"
      );

      if (data) {
        const skills = data.map((item) => ({ value: item.id, label: item.name }));
        setSkills(skills);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchJobRoles = async () => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.homePage.getHomePageRole}`,
        "get"
      );

      if (data) {
        const jobRoles = data.map((item) => ({ value: item.id, label: item.jobRole}));
        setJobRoles(jobRoles);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchSkills();
    fetchJobRoles();
  }, []);

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div style={{ width: "60%", margin: "20px auto", padding: "20px", display: "flex", justifyContent: "center" }}>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <div style={{ margin: "20px auto", padding: "20px" , width: "100%"  }}>
                {getStepContent(activeStep)}
            </div>
            <div style={{ display: "flex", justifyContent: "center" , width: "60%" , margin:"0px auto" , gap : "20px" , marginTop:"20px"}}>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                className={classes.button}
                style={{backgroundColor:"rgba(121, 77, 253, 1)",color:"rgba(255, 255, 255, 1)",textTransform:"none"}}
              >
                {activeStep === steps.length - 1 ? !showLink ? 'Generate Link' : 'Send Email' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
      <Snackbar
          open={toast}
          autoHideDuration={toastType === "error" ? 1000 : 5000}
          draggable={true}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => setToast(!toast)}
        >
          <MuiAlert
            variant="filled"
            elevation={6}
            onClose={() => setToast(!toast)}
            severity={toastType}
            draggable={true}
          >
          {toastMsg}
          </MuiAlert>
        </Snackbar>
    </div>
  );
}
