import cn from 'classnames';
import { Button as MuiButton, withStyles } from '@material-ui/core';
import { TProps } from './question-button.props';
import styles from './question-button.module.scss';

const Button = withStyles({
  root: {
    minWidth: 0,
    padding: 0,
  },
})(MuiButton);

export function QuestionButton(props: TProps) {
  return (
    <Button
      className={cn(
        styles.button,
        props.large && styles.large,
        props.flagged && styles.flagged,
        props.answered && styles.answered,
        props.currentQuestion && styles.currentQuestion,
      )}
      color="inherit"
      disableRipple
      onClick={() => {
        props?.onClick?.(props.sectionId || '', props.questionNum || 0);
      }}>
      {props.questionNum || ' '}
    </Button>
  );
}
