import React, { Component } from "react";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { saveQuestion } from "../../store/question/actions";
import CreateQueComponent from "./QuestionForm";
import { SendMediaHof } from "pages/utils/SendMediaHOF";
import { sessionPrivileges } from "privileges";

class CreateQuestionComponent extends Component {
  constructor(props) {
    super(props);
    this.userPrivileges = sessionPrivileges(true);
    this.UserEmail = this.userPrivileges.userName;
    this.state = {
      saveState: "Save",
      startDate: new Date(),
      endDate: new Date(),
      inputFields: [{ name: "", file: "" }],
      typeValues: [],
      hints: [],
      solutions: [],
      defaultSolutions: [],
      qbId: this.props.location?.state?.id,
      customerName: this.props.location?.state?.customerName,
      customCheckList: [],
      categoryName: "",
    };
  }

  handleCallBack = (data) => {
    this.setState({
      customCheckList: [...this.state.customCheckList, data],
    });
  };

  onSubmit = async (val) => {
    
    if (val.selectedLanguages) {
      val.selectedLanguages.map((o, i) => {
        let str = val.selectedLanguages[i].value;
        lang.push(str);
      });
      val.languages = lang;
    }
    val.createdBy = this.UserEmail;
    val.updatedBy = this.UserEmail;
    val["questionBankId"] = this.props.location?.state.id;
    this.setState({ saveState: "Saving..." });
    if (val?.solutionMedia) {
      val.solutionMedia = await SendMediaHof(
        val?.solutionMedia,
        "questionBank",
        this.state.customerName,
        this.state.qbId
      );
    }
    let spliced = [...this.state.typeValues];
    spliced.forEach((v) => delete v.id);
    switch (val.type) {
      case "MC": {
        val.optionsList = spliced;
        break;
      }
      case "CD": {
        val.testCaseList = spliced;
        break;
      }
      case "DESC": {
        val.answerKey = spliced;
        break;
      }
      case "AUDIO": { // New case for AUDIO type
        const formData = new FormData();
        console.log("value inside submit bro" , val)
        val.active = true;
        
        if(val.questionSubType != "3"){
          formData.append("audiofile", val.audiofile[0]);
        }
        delete val.audiofile;

        formData.append("data", JSON.stringify(val));
  
        const audioUrl = `${api.baseURL}${api.questionController.createQuestionAB}`; // Define your audio endpoint
        try {
          const audioResponse = await Apihelper.axiosCallPost(audioUrl, "post", formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          console.log("Audio Response" , audioResponse);
          toast.success("Audio question successfully set", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.props.history.push({
            pathname: `/view-questions`,
            state: this.props.location?.state,
          });
          return; // Exit after handling audio question
        } catch (e) {
          console.error("Error while submitting audio question:", e);
          toast.error("Failed to set audio question", {
            position: "top-right",
            autoClose: 5000,
          });
        }
        return; // Exit after handling audio question
      }
    }
    if (this.state.solutions.length > 0) {
      val["solutionList"] = this.state.solutions;
    }

    if (this.state.defaultSolutions.length > 0) {
      val["defaultSolutionList"] = this.state.defaultSolutions;
    }

    if (this.state.hints.length > 0) {
      val.hints = this.state.hints;
    }

    const response = await this.props.saveQuestion(val);

    let url =
      response.payload.type === "CD"
        ? `${api.baseURL}${api.questionController.createQuestionCoding}`
        : response.payload.type === "MC"
        ? `${api.baseURL}${api.questionController.createQuestionMCQ}`
        : response.payload.type === "DESC"
        ? `${api.baseURL}${api.questionController.createQuestionDescriptive}`
        : `${api.baseURL}${api.questionController.createQuestionDescriptive}`;
    let body = {
      ...response.payload,
      category: this.state.categoryName,
      customCheckList: this.state.customCheckList,
    }; // add new data into api
    try {
      Apihelper.axiosCallPost(url, "post", body).then((data) => {
        toast.success("Sucessfully set", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const { history } = this.props;
        history.push({
          pathname: `/view-questions`,
          state: this.props.location?.state,
        });
      });
    } catch (e) {}
  };
  render() {
    return (
      <>
        <CreateQueComponent
          onSubmit={this.onSubmit}
          initialValues={{}}
          idAndName={this.props.location?.state}
          saveState={this.state.saveState}
          handleCallBack={this.handleCallBack}
          setTypeValues={(list) => {
            this.setState({ typeValues: list });
          }}
          setCategoryName={(name) => {
            this.setState({ categoryName: name });
          }}
          setHints={(hints) => {
            let tempHints = [];

            hints.forEach((item, index) => {
              let hintObject = {};
              hintObject[`hint ${index + 1}`] = item.desc;
              tempHints.push(hintObject);
            });
            this.setState({ hints: tempHints });
          }}
          setSols={(sols) => {
            let tempSols = sols.reduce((acc, item) => {
              acc.push({
                solution: item.val,
                languageId: item.lang.value,
              });
              return acc;
            }, []);
            this.setState({ solutions: tempSols });
          }}
          setDefSols={(sols) => {
            const tempSols = sols.reduce((acc, item) => {
              acc.push({
                solution: item.val,
                languageId: item.lang.value,
              });
              return acc;
            }, []);
            this.setState({ defaultSolutions: tempSols });
          }}
        />
      </>
    );
  }
}
export default connect(null, { saveQuestion })(CreateQuestionComponent);
