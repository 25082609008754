import React, { useEffect, useState } from "react";
import "./completedPublicAssessmentStyles.scss";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import { COMPLETED_ASSESSMENT } from "./publicAssessmentImages";
import { notStartIcon, expiredImage } from 'assets/icons/icons'
import Card from "pages/virtualLab/report-components/card";
import moment from "moment";
import { useLogoContext } from "context/logoContext";
import { useGetCustomerLogo } from "hooks/useGetCustomerLogo";

function completedPublicAssessment(props) {
  const history = useHistory();
  const logoImage = useLogoContext();
  const customerLogo = useGetCustomerLogo();
  const alreadyCompleted =
    qs.parse(window.location.search)?.alreadyCompleted || false;
  const assessmentName = qs.parse(window.location.search)?.name || "";
  const expired = qs.parse(window.location.search)?.expired || false;
  const notStart = qs.parse(window.location.search)?.notStart || false;
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
  }, []);


  const moveToTervLogin = () => {
    history.push("/dashboard");
  };
  
  return (
    <>
      <div className="container-completedAssessment">
        <div className="completedAssessmentContent">
          <div className="container-completedImage">
            {notStart ? notStartIcon : expired ? expiredImage : COMPLETED_ASSESSMENT }
          </div>
          <p />
          <div className="container-assessmentTitle">
            {expired ? (
              <span style={{textAlign:'center'}}>Sorry, This assessment has been expired already!</span>
            ) : notStart ?
            (
              <span style={{textAlign:'center'}}>Assessment has not started, please come back when the assessment starts</span>
            ) : (
              <>
                <span>
                  <strong>'{assessmentName}'</strong>
                </span>
                <span className="container-completed">
                  <p>
                    {alreadyCompleted ? "already completed" : " completed"}
                  </p>
                </span>
              </>
            )}
          </div>
          {expired ? (
            <>
              <p />
              <p />
            </>
          ) : (
            <div className="container-assessmentStatus">
              <p>
                {alreadyCompleted
                  ? "You have already completed this assessment" :
                  notStart ?
                  "Assessment yet to begin"
                  :
                  "Your assessment test has been completed successfully"
                }
              </p>
            </div>
          )}
          {notStart && (
            <div className="pb-2">
              <div style={{backgroundColor:'#F9F8FA'}}>
                <div style={{display:'flex'}} className="pt-3 pb-3 pr-5 pl-2">
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 5C0 2.23858 2.23858 0 5 0H55C57.7614 0 60 2.23858 60 5V55C60 57.7614 57.7614 60 55 60H5C2.23858 60 0 57.7614 0 55V5Z" fill="#30D6B0"/>
                    <path d="M42.5714 46H17.4286C15.5357 46 14 44.4643 14 42.5714V17.4286C14 15.5357 15.5357 14 17.4286 14H42.5714C44.4643 14 46 15.5357 46 17.4286V42.5714C46 44.4643 44.4643 46 42.5714 46ZM31.0071 24.4214L21.3143 34.1143L20.8643 38.1929C20.8071 38.7357 21.2643 39.2 21.8143 39.1429L25.8929 38.6929L35.5857 29C35.75 28.8357 35.75 28.5643 35.5857 28.3929L31.6214 24.4286C31.4429 24.2571 31.1714 24.2571 31.0071 24.4214ZM38.6429 23.5071L36.4929 21.3571C35.8214 20.6857 34.7357 20.6857 34.0714 21.3571L32.4214 23.0071C32.2571 23.1714 32.2571 23.4429 32.4214 23.6143L36.3857 27.5786C36.55 27.7429 36.8214 27.7429 36.9929 27.5786L38.6429 25.9286C39.3071 25.2643 39.3071 24.1786 38.6429 23.5071Z" fill="white"/>
                  </svg>
                  <div className="pl-2 pt-2">
                    <h5>{props?.location?.state?.assessmentData?.assessmentName}</h5>
                    <p>{`Active from ${moment.utc(props?.location?.state?.assessmentData?.startDate).format('MMM DD, hh:mm a')} - ${moment.utc(props?.location?.state?.assessmentData?.endDate).format('MMM DD, hh:mm a')}`}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {expired && (
            <div className="pb-2">
              <div style={{backgroundColor:'#F9F8FA'}}>
                <div style={{display:'flex'}} className="pt-3 pb-3 pr-5 pl-2">
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 5C0 2.23858 2.23858 0 5 0H55C57.7614 0 60 2.23858 60 5V55C60 57.7614 57.7614 60 55 60H5C2.23858 60 0 57.7614 0 55V5Z" fill="#30D6B0"/>
                    <path d="M42.5714 46H17.4286C15.5357 46 14 44.4643 14 42.5714V17.4286C14 15.5357 15.5357 14 17.4286 14H42.5714C44.4643 14 46 15.5357 46 17.4286V42.5714C46 44.4643 44.4643 46 42.5714 46ZM31.0071 24.4214L21.3143 34.1143L20.8643 38.1929C20.8071 38.7357 21.2643 39.2 21.8143 39.1429L25.8929 38.6929L35.5857 29C35.75 28.8357 35.75 28.5643 35.5857 28.3929L31.6214 24.4286C31.4429 24.2571 31.1714 24.2571 31.0071 24.4214ZM38.6429 23.5071L36.4929 21.3571C35.8214 20.6857 34.7357 20.6857 34.0714 21.3571L32.4214 23.0071C32.2571 23.1714 32.2571 23.4429 32.4214 23.6143L36.3857 27.5786C36.55 27.7429 36.8214 27.7429 36.9929 27.5786L38.6429 25.9286C39.3071 25.2643 39.3071 24.1786 38.6429 23.5071Z" fill="white"/>
                  </svg>
                  <div className="pl-2 pt-2">
                    <h5>{props?.location?.state?.assessmentData?.assessmentName}</h5>
                    <p>{`Starts on ${moment.utc(props?.location?.state?.assessmentData?.startDate).format('MMM DD, HH:mm a')} - ${moment.utc(props?.location?.state?.assessmentData?.endDate).format('MMM DD, HH:mm a')}`}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div>
            <Button
              style={{
                backgroundColor: "#794df5",
                border: "1px solid #794df5",
                width: "171px",
                height: "50px",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: 600,
                fontFamily: "Poppins",
                color: "#ffffff",
              }}
              onClick={moveToTervLogin}
            >
              {`Explore ${customerLogo?.productName}`}
            </Button>
          </div>
        </div>
      </div>
    </>
  );


  
}

export default completedPublicAssessment;
