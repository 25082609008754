class QuestionValidations {
  QNAME = (value) => {
    if (!value || value.trim().length <= 1) {
      return "Please enter a valid Question Name";
    }
  };
  CATEGORY = (value) => {
    if (!value) {
      return "Please choose a Category";
    }
  };
  QUESTIONS = (value) => {
    if (!value || value.replace(/<(.|\n)*?>/g, "").trim().length < 1) {
      return "Please enter a valid Question";
    }
  };
  PROFICIENCY = (value) => {
    if (!value) {
      return "Please choose a Proficiency";
    }
  };
  TAXONOMY = (value) => {
    if (!value) {
      return "Please choose a Taxonomy";
    }
  };
  SUBTAX = (value) => {
    if (!value) {
      return "Please choose a Level";
    }
  };
  COMPLEXITY = (value) => {
    if (!value) {
      return "Please choose a Complexity";
    }
  };
  CATE = (value) => {
    if (!value) return "Please choose a Category";
  };
  TOPIC = (value) => {
    if (!value) return "Please choose a Topic";
  };
  MARKS = (value, allValues) => {
    if (!value) {
      return "Please enter a valid marks";
    }

    if(value < 0) {
      return "Negative values are Invalid";
    }
    
    if (
      value &&
      value.length > 0 &&
      allValues &&
      allValues.marks &&
      allValues.answerKey &&
      Array.isArray(allValues.answerKey)
    ) {
      let totes = 0;
      allValues.answerKey.forEach((dl) => {
        if (dl.answerKeyPoints) {
          totes += +dl.answerKeyPoints;
        }
      });
      if (+totes !== +allValues.marks) return "Marks must be equal to Points";
    }
  };
}

export default new QuestionValidations();
