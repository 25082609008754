import tervTable from './tervTable.png';
export default function TableComponent() {
  return (
    <>
      <div
        className="mb-10 flex justify-center overflow-hidden rounded-[20px]"
        style={{
          margin: "0px",
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          marginBottom: "2.5rem",
          display: "flex",
          justifyContent: "center",
          overflow: "hidden",
          borderRadius: "20px",
        }}
      >
        <div
          className="hidden max-w-[900px] sm:block"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            maxWidth: "900px",
            display: "block",
          }}
        >
          <div
            className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              overflow: "hidden",
              position: "relative",
              display: "inline-block",
              verticalAlign: "top",
            }}
          >
            <div
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                maxWidth: "1920px",
                display: "block",
              }}
            >
              <img
                aria-hidden="true"
                role="presentation"
                src="data:image/svg+xml;charset=utf-8,<svg height='1064' width='1920' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                style={{
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  pointerEvents: "none",
                  verticalAlign: "middle",
                  inset: "0px",
                  margin: "0px",
                  padding: "0px",
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  maxWidth: "100%",
                  display: "block",
                  position: "static",
                }}
              />
            </div>
            <img
              aria-hidden="true"
              src="data:image/webp;base64,UklGRugAAABXRUJQVlA4WAoAAAAQAAAAEwAACgAAQUxQSBQAAAABD3Di/4iIICQgaP6/9iCi/ylxJFZQOCCuAAAAcAUAnQEqFAALAC4lGIxGERERAQASJZACdL175XS/74B4gH6Ae//doH6qgwNab6+s26RdgAD+/0QP035tY4SnwAB3tFGyyb7/n2sLP7P0cTuRvZqIgX0rL//EnKCk1kES5P+wkW+WHAa+t/8q/fn/TuIeq9+f9O4ZCScfy8blzgsCHjdamm+3e3vqzf+zyEs5+jzZb0b/R5st6N9X6609PrzXc0VkDezJ+Ic74OAA"
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                pointerEvents: "none",
                display: "block",
                verticalAlign: "middle",
                margin: "0px",
                padding: "0px",
                height: "100%",
                maxWidth: "none",
                width: "100%",
                transition: "opacity 500ms linear",
                inset: "0px",
                opacity: 0,
                backgroundColor: "transparent",
                position: "absolute",
                objectFit: "cover",
              }}
            />
            <img
              height={497}
              width={896}
              alt="A table displaying different course USPs, illustrating how Crio.Do's Fellowship program excels compared to competitors."
              sizes="(min-width: 1920px) 1920px, 100vw"
              src={tervTable}
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                pointerEvents: "none",
                display: "block",
                verticalAlign: "middle",
                inset: "0px",
                margin: "0px",
                padding: "0px",
                height: "100%",
                maxWidth: "none",
                position: "absolute",
                width: "100%",
                transition: "opacity 0.25s linear",
                transform: "translateZ(0px)",
                willChange: "opacity",
                objectFit: "cover",
                opacity: 1,
              }}
            />
            <noscript
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
              }}
            >{`<img width="1920" height="1064" data-main-image="" style="object-fit:cover;opacity:0" sizes="(min-width: 1920px) 1920px, 100vw" decoding="async" loading="lazy" src="/static/f75139f39364ca9c3791d9d907af40b4/c9944/Table-Desktop.webp" srcSet="/static/f75139f39364ca9c3791d9d907af40b4/36df3/Table-Desktop.webp 750w,
/static/f75139f39364ca9c3791d9d907af40b4/dd69f/Table-Desktop.webp 1080w,
/static/f75139f39364ca9c3791d9d907af40b4/f4983/Table-Desktop.webp 1366w,
/static/f75139f39364ca9c3791d9d907af40b4/c9944/Table-Desktop.webp 1920w" alt="A table displaying different course USPs, illustrating how Crio.Do&#x27;s Fellowship program excels compared to competitors."/>`}</noscript>
          </div>
        </div>
        <div
          className="max-w-full sm:hidden"
          style={{
            margin: "0px",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            maxWidth: "100%",
            display: "none",
          }}
        >
          <div
            className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
            style={{
              margin: "0px",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              overflow: "hidden",
              position: "relative",
              display: "inline-block",
              verticalAlign: "top",
            }}
          >
            <div
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                maxWidth: "1000px",
                display: "block",
              }}
            >
              <img
                aria-hidden="true"
                role="presentation"
                src="data:image/svg+xml;charset=utf-8,<svg height='1586' width='1000' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
                style={{
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  pointerEvents: "none",
                  verticalAlign: "middle",
                  inset: "0px",
                  margin: "0px",
                  padding: "0px",
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  maxWidth: "100%",
                  display: "block",
                  position: "static",
                }}
              />
            </div>
            <img
              aria-hidden="true"
              src="data:image/webp;base64,UklGRpoBAABXRUJQVlA4WAoAAAAQAAAAEwAAHwAAQUxQSB8AAAABFyAQSPzFotlhjYiIAzNt27hFVmjjf+yO6H8W56lgAFZQOCBUAQAAkAgAnQEqFAAgAD4xFohCoiEhGAwEACADBLIATpA5Pt///ai/zNxy3cp+gHb/qsz6AMgEy0F/v2ez4Cy9jHufYtvoCBVHQhWBgsnjEUwA/vrXrIT9PIK62/9aRVzqWg33KN+DNbg+/JJ/Qyl90PEmnuNnbx32tiC/FYWuze1eHvLRAfxj45Oi1iZh2A3/2hFNw01xOf/z8WYxOriZWp4+256djP7H/9IJVoSyfirtI1mKJeXXeWyuobjNMIGa/XtK0L9A48f+30/8l0l9Ueg2dUuGAHl19HYV/Dwy6Sn0zEMorDrERyRdZUUKbLfvzTD8btSagJ9CW+efRJiBm3jXKnc/DDv8ZcgB3+49SEd2XUqmfvkmtv+/EhQdt41koBDz5R3kgYYK0qYz6R8yhLfMooR3+0837SwJl006aFwNEQAkRlz9gwWcVXG5cEEwB/4pWQAAAA=="
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                pointerEvents: "none",
                display: "block",
                verticalAlign: "middle",
                margin: "0px",
                padding: "0px",
                height: "100%",
                maxWidth: "none",
                width: "100%",
                transition: "opacity 500ms linear",
                inset: "0px",
                opacity: 1,
                backgroundColor: "transparent",
                position: "absolute",
                objectFit: "cover",
              }}
            />
            <img
              height={1586}
              width={1000}
              alt="A table displaying different course USPs, illustrating how Crio.Do's Fellowship program excels compared to competitors."
              sizes="(min-width: 1000px) 1000px, 100vw"
              src="https://www.crio.do/static/d13f456f4e750940e8e5747b49e121e5/b8c8a/Table-Mobile.webp"
              srcSet={`/static/d13f456f4e750940e8e5747b49e121e5/b3b9a/Table-Mobile.webp 750w,
/static/d13f456f4e750940e8e5747b49e121e5/b8c8a/Table-Mobile.webp 1000w,
/static/d13f456f4e750940e8e5747b49e121e5/53382/Table-Mobile.webp 1080w,
/static/d13f456f4e750940e8e5747b49e121e5/bb03d/Table-Mobile.webp 1344w`}
              style={{
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
                pointerEvents: "none",
                display: "block",
                verticalAlign: "middle",
                inset: "0px",
                margin: "0px",
                padding: "0px",
                height: "100%",
                maxWidth: "none",
                position: "absolute",
                width: "100%",
                transition: "opacity 0.25s linear",
                transform: "translateZ(0px)",
                willChange: "opacity",
                objectFit: "cover",
                opacity: 0,
              }}
            />
            <noscript
              style={{
                margin: "0px",
                border: "0px solid rgb(229, 231, 235)",
                boxSizing: "border-box",
              }}
            >{`<img width="1000" height="1586" data-main-image="" style="object-fit:cover;opacity:0" sizes="(min-width: 1000px) 1000px, 100vw" decoding="async" loading="lazy" src="/static/d13f456f4e750940e8e5747b49e121e5/b8c8a/Table-Mobile.webp" srcSet="/static/d13f456f4e750940e8e5747b49e121e5/b3b9a/Table-Mobile.webp 750w,
/static/d13f456f4e750940e8e5747b49e121e5/b8c8a/Table-Mobile.webp 1000w,
/static/d13f456f4e750940e8e5747b49e121e5/53382/Table-Mobile.webp 1080w,
/static/d13f456f4e750940e8e5747b49e121e5/bb03d/Table-Mobile.webp 1344w" alt="A table displaying different course USPs, illustrating how Crio.Do&#x27;s Fellowship program excels compared to competitors."/>`}</noscript>
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  margin: 0px;
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  tab-size: 4;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}

body {
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizelegibility;
  overflow-y: auto;
  min-height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  line-height: inherit;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  overflow-x: hidden;
}
`,
        }}
      />
    </>
  );
}

