import React from 'react'

const LogoStr = React.createContext();
const LogoAction = React.createContext()

function saveCurrentUser(user) {
  sessionStorage.setItem("currentUser", JSON.stringify(user));
}

function getCurrentUser() {
  let userStr = sessionStorage.getItem("currentUser");
  try {
    if (userStr === null) return defaultUser;
    else return JSON.parse(userStr);
  } catch (ex) {
    return defaultUser;
  }
}

export default function LogoContext({ children }) {
  const [logo, setLogo] = React.useState("");
  return (
    <LogoStr.Provider value={logo}>
      <LogoAction.Provider value={setLogo}>{children}</LogoAction.Provider>
    </LogoStr.Provider>
  );
}

export const useLogoContext = () => {
  const context = React.useContext(LogoStr);
  if (context === undefined) {
    throw new Error("useLogoContext >> LogoContextProvider")
  }
  return context
}

export const handleLogoContext = () => {
  const context = React.useContext(LogoAction)
  if (context === undefined) {
    throw new Error("useLogoActionContext >> LogoContextProvider")
  }
  return context
}