import apiHelper from "api/apiHelper";
import api from "api/baseConfig";

export const GetAssementReview = async (studentId, assessmentId) => {
  try {
    const data = await apiHelper.axiosCall(
      `${api.baseURL}${api.userManagement.getAssementReview}${studentId}/${assessmentId}`
    );
    return data;
  } catch (e) {}
};
