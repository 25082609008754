import React from "react";
import "./privacyStyles.scss";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { sessionPrivileges } from "privileges";
import { useLogoContext } from "context/logoContext";

function studentsPrivacy() {
  const Priv = sessionPrivileges().userprivileges || [];
  let history = useHistory();
  const logoImage = useLogoContext();

  return (
    <>
      <div
        className="privacy"
        style={
          Priv && Priv.includes("ADMIN_ROLE")
            ? { marginLeft: 225 }
            : Priv && Priv.includes("ATTEND_ASSESSMENT")
            ? { marginLeft: 225 }
            : { marginLeft: 225 }
        }
      >
        <h1 className="privacyheader">Privacy</h1>
        <div className="topics">
          <div className="subtopic-content1">
            <p>
              Your confidentiality is important to us. We at {logoImage?.productName} are
              committed to good privacy practices regarding your personal
              information. Your loyalty as a learner, customer or visitor is
              important, and we hope that our concerns regarding your privacy,
              as described in this policy (the “Privacy Policy”, “Privacy
              Statement” or “Privacy”), demonstrate our commitment to provide a
              safe and secure user experience.
            </p>
          </div>
          <div className="subtopic-content1">
            <p>
              The following guidelines set forth have been established in order
              to provide consistent quality assurance. The information below
              will explain how user information is collected, protected and
              used. {logoImage?.productName} will update all information contained herein as and
              when deemed necessary without notice to you. Please be aware that
              by accessing our website and/or by registering as a user of
              {logoImage?.productName}, you signify your agreement to this Privacy Policy.
            </p>
          </div>
          <p className="topic-heading1">
            User Registration / Membership Information
          </p>
          <div className="subtopic-content1">
            <p>
              When you register as a user of our programs / services, we ask for
              personal and demographic information from you. At a minimum, we
              may ask for your name, email address, contact address, zip code
              and any other information from which your identity is discernible.
              We may also afford you the opportunity to provide descriptive,
              cultural, preferential, and/or life style information about you,
              but it is solely up to whether you furnish such information. We do
              this so we can better understand what you like and tailor our
              services to suit you better. The more information you volunteer,
              the more we can tailor our services to your interests. Once you
              register with {logoImage?.productName} and sign in to our programs / services, you
              may no longer be anonymous to us. The information you provide
              helps us, and our third-party service providers make our services
              more practical and useful for you.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              We may also gather certain information about your use of {logoImage?.productName},
              such as what areas you visit and what programs/ services you
              access. Moreover, there is information about your computer
              hardware and software that is or may be collected by us. This
              information can include without limitation your IP address,
              browser type, domain names, access times, and referring website
              addresses, but is not linked to your personal information. We
              would be collecting all information with no such intentions of
              using it commercially or in any other harmful ways.
            </p>
          </div>
          <p className="topic-heading1">
            How We Collect & Use User Information
          </p>
          <div className="subtopic-content1">
            <p>
              In the course of going through our programs / services, we would
              have access to and possession of the coursework, assignments,
              comments, feedback, and other related stuff you may be submitting
              online or sending us through emails / messages. We use the
              information we gather on {logoImage?.productName}, whether personal, demographic,
              collective or technical, for the purpose of operating and
              improving {logoImage?.productName} sites, fostering a positive user experience,
              and delivering the products and services that we offer.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              We may also use the information we gather to inform you of other
              products or services available from us or our affiliated companies
              or to contact you about your opinion of current products and
              services or potential new products and services that may be
              offered with your consent. We may use your contact information in
              order to send you e-mail or other communications regarding updates
              at {logoImage?.productName} sites, and if you have information on new {logoImage?.productName}
              opportunities which may be of interest to you. The nature and
              frequency of these messages will vary depending upon the
              information we have about you.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              In addition, you have the option to make yourself part of our
              mailing list for our programs / services, through which you may
              want to receive additional communications, information and
              promotions, including without limitation, free informational
              newsletters from us relating to topics that may be of special
              interest to you, such as training, learning career management
              advice. We may have areas on the {logoImage?.productName} sites where you can
              submit your feedback. Any feedback you submit in these areas
              becomes our property, and we can use such feedback (such as
              success stories) for marketing purposes or to contact you for
              further information.
            </p>
          </div>
          <p className="topic-heading1">Affiliates/Service Providers</p>
          <div className="subtopic-content1">
            <p>
              By becoming a member / user, you authorize us and our third-party
              service providers to send you email updates and information about
              promotions offered by us, our service providers and our
              advertisers. We take care to require that our third-party service
              providers only use our member’s data as necessary to provide the
              particular service and to give you updates on that service
              provider’s products and services. Please remember that {logoImage?.productName}
              may also share non-personal, aggregate, or summary, information
              regarding our users with other third parties not mentioned in this
              policy. This kind of information does not identify you
              individually.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              All information made available to {logoImage?.productName} may be made available
              to our contractors and consultants, and professional advisors
              (such as lawyers or accountants), but only to the extent necessary
              for them to perform services on our behalf, and only under a duty
              of confidentiality.
            </p>
          </div>
          <p className="topic-heading1">
            Co-registration with Other Sites/Other Service Providers
          </p>
          <div className="subtopic-content1">
            <p>
              As a member, we may offer you the ability to co-register with top
              quality third-party partner sites. In some instances where
              {logoImage?.productName} provides co-registration, the co-registration partners
              allow you to opt-out of their membership. If you choose to
              co-register with our partners and accept their terms of service,
              then you are automatically registered as a member of their select
              websites and your Personally Identifiable Information is
              transferred to those organizations. As a result, you may receive
              information from one of these websites as any of their members
              would. We advise you to review their privacy and data collection
              policies for further information and before you sign up / register
              with them for their services. In order to cancel your membership
              with any of these partners, you will need to go directly to the
              partner’s site.
            </p>
          </div>
          <p className="topic-heading1">Links to Other Sites</p>
          <div className="subtopic-content1">
            <p>
              The {logoImage?.productName} sites contain links to other websites over which we
              have no control. We are not responsible for the privacy policies
              or practices of other websites to which you choose to link from
              our sites. It is always a good idea to check the Privacy Policy
              and Legal Terms of Use of any site before offering any of your
              personal information.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              Keep in mind that, while {logoImage?.productName} encourages all third parties
              involved – including but not limited to its advertisers – to
              adhere to our policies regarding the privacy of our visitors and
              members / users, and to otherwise handle personal information in a
              responsible manner, we cannot and do not assume any responsibility
              for any actions or omissions of third parties, including the
              manner in which they use information received either from {logoImage?.productName}
              or independently. Nevertheless, in the event you encounter any
              third party associated with, or who claims association with
              {logoImage?.productName}, who you feel is improperly collecting or using
              information about you, please inform us immediately.
            </p>
          </div>
          <p className="topic-heading1">Surveys & User Research</p>
          <div className="subtopic-content1">
            <p>
              {logoImage?.productName} conducts email and web-delivered surveys from time to
              time, as well as instant polls, to gather information about our
              audience / users. It is important to remember that taking these
              surveys and polls is entirely optional. Unless explicitly
              specified, you have no obligation to respond to them. We share
              only the aggregate results of these surveys, not individual data,
              with our visitors, advertisers, and partners to help them better
              understand our services and monitor the reach of their advertising
              on our service. We also store some of the information that our
              users provide in these surveys to help us understand your
              interests and to make the site more useful for you and others.
            </p>
          </div>
          <p className="topic-heading1">Information Disclosure</p>
          <div className="subtopic-content1">
            <p>
              We disclose information to third parties if you consent to such
              disclosure. For example, if you indicate that you would like to
              receive information about the opportunities, products or services
              of third parties at the time you register for a account, we supply
              your contact information to third parties such as educational
              institutes, academic service providers, colleges, schools,
              employers, recruiters, career counselors, data aggregators,
              marketers or others for the purpose of sending you e-mail or
              otherwise communicating with you. We use data we have about you
              (such as the interests and preferences you have expressed) to
              determine whether you might be interested in the opportunities,
              products or services of a particular third-party.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              We may disclose information to companies and individuals we employ
              to perform functions on our behalf. Examples include those
              providing marketing assistance, support or customer services.
              These companies and individuals will have access to your personal
              information as necessary to perform their functions, but they may
              not share that information with any other third party.
            </p>
          </div>
          <p className="topic-heading1">Legal Compliance</p>
          <div className="subtopic-content1">
            <p>
              Please note that {logoImage?.productName} reserves the right to disclose
              information submitted by or concerning any visitor or member as we
              reasonably feel is necessary to protect our systems or business.
              You acknowledge that {logoImage?.productName} may, in its sole discretion, archive
              or disclose content, including e-mail addresses, IP addresses,
              service provider information, time stamps, and any other
              information, if required to do so by law or in the belief that
              such disclosure or archiving is necessary to comply with legal
              process. respond to claims that any content violates the rights of
              third-parties. protect the property, safety and rights of {logoImage?.productName}
              users or the general public. to enforce the terms.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              We may also disclose and transfer information to a third party who
              acquires all or a substantial portion of our business, whether
              such acquisition is by way of merger, consolidation or purchase of
              all or a substantial portion of our assets. Finally, {logoImage?.productName}
              reserves the right to assign, sell, license, or otherwise transfer
              to a third party your personally identifiable information in
              connection with an assignment, sale, joint venture, or other
              transfer or disposition of any portion or all of {logoImage?.productName}’s or its
              affiliated entities’ assets or stock.
            </p>
          </div>
          <p className="topic-heading1">Posting to Public Areas of {logoImage?.productName}</p>
          <div className="subtopic-content1">
            <p>
              Please remember that if you post any of your personal information
              in public areas of {logoImage?.productName} such as in online forums, such
              information may be collected and used by others over whom we have
              no control. We are not responsible for the use made by third
              parties of information you post or otherwise make available in
              public areas of {logoImage?.productName}. Unless warranted, we advise users not to
              disclose information that can be used to contact the user such as
              full name, home address, or phone number.
            </p>
          </div>
          <p className="topic-heading1">Use of Cookies</p>
          <div className="subtopic-content1">
            <p>
              On the Internet, a “cookie” is a piece of information (a file)
              that a website transfers to a user’s computer for record-keeping
              purposes. The use of cookies is common on the Internet. {logoImage?.productName}
              may set and access {logoImage?.productName} cookies on your computer. {logoImage?.productName} may
              use this information for the following general purposes: to tailor
              the advertising and content you see, inform you about new and
              unread items, improve our services, conduct research, and provide
              anonymous reporting for internal and external needs. You may
              configure your browser to reject cookies, but this may interfere
              with some functionality of the {logoImage?.productName} sites, hinder performance
              and negatively impact your experience on {logoImage?.productName}.
            </p>
          </div>
          <p className="topic-heading1">Log Files</p>
          <div className="subtopic-content1">
            <p>
              {logoImage?.productName} logs IP addresses for system administration purposes.
              This information is used to diagnose server problems, monitor
              traffic patterns, analyze trends, administer the website, track
              member user patterns, and identify the most popular areas of the
              website to deliver content most relevant to registered members. IP
              addresses are not linked to personally identifiable information
              and are only used to gather broad demographic information for
              aggregate use, except in the case of fraud.
            </p>
          </div>
          <p className="topic-heading1">Data Security</p>
          <div className="subtopic-content1">
            <p>
              The security of personal information is a high priority at
              {logoImage?.productName}. We maintain our website and all associated data with
              technical, administrative and physical safeguards to protect
              against loss, unauthorized access, destruction, misuse,
              modification and improper disclosure. No computer system or
              information can ever be fully protected against every possible
              hazard but {logoImage?.productName} is committed to providing reasonable and
              appropriate security controls to protect our website and all
              information against foreseeable hazards.
            </p>
          </div>
          <p className="topic-heading1">Mandatory Communications</p>
          <div className="subtopic-content1">
            <p>
              {logoImage?.productName} sends all newly registered members / users a welcome
              email to verify their email addresses. At times, {logoImage?.productName} sends
              service announcements to its registered employers and
              professionals. Members cannot unsubscribe from service
              announcements that discuss upcoming changes that affect their
              {logoImage?.productName} accounts. In addition, we communicate with registered
              members via personal emails or phone calls to provide requested
              services.
            </p>
          </div>
          <p className="topic-heading1">Updating/Deleting Your Information</p>
          <div className="subtopic-content1">
            <p>
              You may edit, review, correct, update or change your account
              information at any time by logging into personal {logoImage?.productName} account.
              If you wish to terminate your membership at any time, for any
              reason, contact us through our contact form and your membership
              will be terminated upon receipt of your termination request. Keep
              in mind, however, that there will be residual information that
              will remain within {logoImage?.productName} databases, access logs, and other
              records, which may or may not contain such personally identifiable
              information. The residual information will not be used for
              commercial purposes. however, {logoImage?.productName} reserves the right, from
              time to time, to re-contact former customers or users of {logoImage?.productName}.
            </p>
          </div>
          <p className="topic-heading1">Changes to this Privacy Policy</p>
          <div className="subtopic-content1">
            <p>
              {logoImage?.productName} may, from time to time, update the Privacy Policy without
              notice to you. Your use of the site after such notice will be
              deemed acceptance of the changes. Be sure to review this agreement
              periodically to ensure familiarity with the most current version.
            </p>
          </div>
          <p className="topic-heading1">Still Have Questions?</p>
          <div className="subtopic-content1">
            <p>
              If you have any other questions or suggestions about our privacy
              policy, please email us or call us using the contact details on
              the website.
            </p>
          </div>
        </div>
        <div className="privacyBackButton-Container">
          <Button
            variant="outlined"
            style={{
              border: "1px solid #794DF5",
              color: "#794DF5",
              textTransform: "none",
              marginTop: "20px",
              width: "110px",
              height: "43px",
              fontSize: "18px",
              fontWeight: 600,
              fontFamily: "Poppins",
            }}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </div>
      </div>
    </>
  );
}

export default studentsPrivacy;
