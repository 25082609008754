import useSWR from 'swr';
import { Config } from '../../../../config';
import { getData } from '../../../../api';
import { apiList } from '../../../../api/list';

export function useSolutionInfo(questionId: string, type: string, canRun: boolean) {
  const { data, error } = useSWR((questionId && canRun) ? [questionId, type] : null, {
    async fetcher(a: string, b: string) {
      return await getData(apiList.practiceLab.getSolutionInfo(a, b))
        .catch(() => ({}));
    },
    dedupingInterval: Config.sessionDuration,
    onError() {
      return {};
    },
  });

  return { data, error, loading: !data && !error };
}