import { Table } from "reactstrap";
import { Checkbox, Chip, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { uid } from "react-uid";
import { CustomSwitch } from "./../../Prepare/CustomSwitch";
import { Info } from "@material-ui/icons";

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: "#19181b",
    fontFamily: "Poppins",
    fontWeight: 400,
    maxWidth: "10.25rem",
  },
})(Tooltip);

export default function ModalTable({
  handleSingleCheck,
  selectAll,
  activeTab,
  tabState,
  currBankSelQues,
  lastIndex,
  multiSections,
  shouldDisable,
  canPreview,
  isAll,
  lastBookElementRef,
  previewTooltip,
  previewQuestions,
  setPreviewQuestions,
}) {
const getValue=(ques)=>{
  return activeTab === "availableQuestions"
  ? (currBankSelQues.find((item) => item.id === ques.id) ? true : false) : ques.remChecked;
}
  return (
    <>
      <Table borderless style={{ marginTop: 10 }}>
        <thead style={{ backgroundColor: "#c5eafc" }}>
          <tr>
            <th>
              <Checkbox
                color="primary"
                style={{ padding: 0 }}
                onClick={selectAll}
                checked={
                  activeTab === "availableQuestions"
                    ? tabState.length > 0
                      ? multiSections
                        ? tabState
                            .filter((q) => !shouldDisable(q.id))
                            .every(
                              (q) =>
                                !!currBankSelQues.find((ele) => ele.id === q.id)
                            )
                        : tabState.every(
                            (item) =>
                              !!currBankSelQues.find(
                                (ele) => ele.id === item.id
                              )
                          )
                      : false
                    : activeTab === "selectedQuestions" &&
                      currBankSelQues.length > 0
                    ? currBankSelQues.every((item) => item.remChecked)
                    : false
                }
              />
            </th>
            <th style={{ fontSize: 15 }}>S. No</th>
            <th style={{ fontSize: 15 }}>Question Name</th>
            <th style={{ fontSize: 15 }}>Question Bank</th>
            <th style={{ fontSize: 15 }}>Type</th>
            <th style={{ fontSize: 15 }}>Category</th>
            <th style={{ fontSize: 15 }}>Topic</th>
            <th style={{ fontSize: 15 }}>Complexity</th>
            {canPreview && (
              <th style={{ fontSize: 15 }}>
                Show preview&nbsp;
                <CustomTooltip
                  title="These questions will be available to solve before buying the package"
                  arrow
                  classes={{ tooltip: previewTooltip }}
                >
                  <Info />
                </CustomTooltip>
              </th>
            )}
          </tr>
        </thead>
        {tabState?.length > 0 && lastIndex > 0 && (
          <tbody>
            {tabState?.map((ques, ind) => (
              <tr
                key={uid(ind)}
                style={{
                  userSelect: !!shouldDisable(ques.id) ? "none" : "",
                }}
                ref={lastBookElementRef}
              >
                <td
                  onClick={() => {
                    !shouldDisable(ques.id) && handleSingleCheck(ques.id);
                  }}
                >
                  <Checkbox
                    color="primary"
                    checked={getValue(ques)}
                    style={{ padding: 0 }}
                    disabled={!!shouldDisable(ques.id)}
                  />
                </td>
                <td>{ind + 1}</td>
                <td>
                  {ques.name}&nbsp;&nbsp;
                  {!!shouldDisable(ques.id) ? (
                    <Chip
                      size="small"
                      label={`Section ${shouldDisable(ques.id)}`}
                      style={{ fontWeight: 500 }}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td>{ques.questionBankName}</td>
                <td>
                  {ques.type === "MC"
                    ? "Multi Choice"
                    : ques.type === "CD"
                    ? "Coding"
                    : ques.type === "DESC"
                    ? "Descriptive"
                    : ""}
                </td>
                <td>{ques.category}</td>
                <td>{ques.topic}</td>
                <td>
                  <div
                    style={{
                      backgroundColor:
                        ques.complexity === "easy"
                          ? "#dcf0de"
                          : ques.complexity === "medium"
                          ? "#fcf3da"
                          : ques.complexity === "hard"
                          ? "#fbdcdc"
                          : "",
                      padding: "5px 15px 5px 15px",
                      width: 91,
                      textAlign: "center",
                      fontWeight: 500,
                      borderRadius: 5,
                      color:
                        ques.complexity === "easy"
                          ? "#00B11A"
                          : ques.complexity === "medium"
                          ? "#D4A207"
                          : ques.complexity === "hard"
                          ? "#EB5757"
                          : "",
                    }}
                  >
                    {ques?.complexity?.slice(0, 1).toUpperCase() +
                      ques?.complexity?.slice(1, ques.complexity.length)}
                  </div>
                </td>
                {canPreview && (
                  <td>
                    <CustomSwitch
                      checked={previewQuestions.includes(ques.id)}
                      onChange={(e, checked) => {
                        if (checked) {
                          setPreviewQuestions([...previewQuestions, ques.id]);
                        } else {
                          setPreviewQuestions(
                            previewQuestions.filter((pq) => pq !== ques.id)
                          );
                        }
                      }}
                      disabled={!currBankSelQues.find((s) => s.id === ques.id)}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        )}
      </Table>
    </>
  );
}
