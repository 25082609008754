import React, { useEffect } from "react";
import styles from "./BoardOfMembers.module.scss"
import { memberOne, memberTwo} from "./Member";
const LinkedIcon = () => (
    <svg 
    width="32" 
    height="32" 
    viewBox="0 0 32 32" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
        <path d="M27.2609 26.9919H22.5203V19.641C22.5203 17.8881 22.4887 15.6316 20.0547 15.6316C17.5856 15.6316 17.2079 17.5415 17.2079 19.5134V26.9914H12.4672V11.875H17.0182V13.9408H17.0819C17.5374 13.1698 18.1955 12.5355 18.9862 12.1055C19.7769 11.6755 20.6707 11.4659 21.5722 11.499C26.377 11.499 27.2628 14.6283 27.2628 18.6993L27.2609 26.9919ZM7.11829 9.80875C6.57418 9.80885 6.04226 9.64919 5.5898 9.34996C5.13734 9.05073 4.78469 8.62537 4.57638 8.12767C4.36807 7.62998 4.31348 7.0823 4.41953 6.55389C4.52558 6.02548 4.78752 5.54007 5.17218 5.15906C5.55685 4.77804 6.04699 4.51852 6.58062 4.41332C7.11425 4.30812 7.66739 4.36197 8.17011 4.56805C8.67283 4.77413 9.10254 5.12318 9.40491 5.57108C9.70728 6.01897 9.86872 6.54559 9.86882 7.08433C9.86889 7.44204 9.79778 7.79626 9.65959 8.12677C9.5214 8.45728 9.31883 8.7576 9.06342 9.01058C8.80801 9.26357 8.50476 9.46426 8.17101 9.60121C7.83727 9.73816 7.47956 9.80869 7.11829 9.80875ZM9.48858 26.9919H4.74305V11.875H9.48858V26.9919ZM29.6242 0.00191416H2.36092C1.74212 -0.00500023 1.14583 0.23162 0.703133 0.659778C0.260438 1.08794 0.00755212 1.6726 0 2.2853V29.3921C0.00729365 30.0051 0.260031 30.5902 0.702711 31.0188C1.14539 31.4474 1.74181 31.6845 2.36092 31.6779H29.6242C30.2446 31.6856 30.8427 31.4492 31.2871 31.0206C31.7315 30.5921 31.9859 30.0063 31.9945 29.3921V2.28335C31.9857 1.66943 31.7311 1.0841 31.2866 0.655952C30.8422 0.227805 30.2443 -0.00813922 29.6242 -4.24263e-05" fill="#0A66C2"/>
    </svg>
);

const BoardOfMembers = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={styles.main}>
            <section className={styles.sectionOne}>
                <div className={styles.container}>
                    <h2>Meet Our Board of Advisors</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectur adips Lorem ipsum dolor sit amet, consectur adips</p> */}
                </div>
            </section>
            <section>
                <div className={styles.containerTwo}>
                    <div>
                        <img src='/assets/Mitchrosin.svg' alt='Mitch Rosin'/>
                    </div>
                    <div className={styles.contentBox}>
                        <h3>Mitch Rosin</h3>
                        <p>A highly motivated and results‐driven global education and workforce executive consultant with strong business acumen in strategic planning, operations, marketing, budgeting, negotiations, business process, product development and project management. Unique combination of international experience including sales consulting, marketing, product development and business strategy with a concentration in training and development, e-learning and emerging education and workforce markets, public and private.</p>
                        <a href="https://www.linkedin.com/in/mitch-r-1862a49/"
                        target="_blank" rel="noopener noreferrer">
                            <LinkedIcon />
                        </a>
                    </div>
                </div>
            </section>
            <section>
                <div className={styles.containerThree}>
                    <div>
                        <img src='/assets/Ajay.svg' alt='ajay'/>
                    </div>
                    <div className={styles.contentBox}>
                        <h3>Dr. Ajai Garg</h3>
                        <p>A thought leader & trusted Business Growth Strategist, having expertise in driving and articulating a vision, delivering strategic innovation, commercial stewardship to building robust administrative & operational system, setting the organizational rhythm, Strategic planning, Business process improvements and ensuring compliance to local/global regulations & policies. Serving in the capacity of Sr. Director in Ministry of Electronics & Information Technology, backed by rich & prolific career journey of 25 years in the various Administrative accountabilities, imbibed strong ethos of IITs as Research scholar. Carved out a mission to contribute for the optimal growth of economy by blending with Technology and yielding Innovation Ecosystem.</p>
                        <a href="https://www.linkedin.com/in/ajai-garg-29593437/"
                        target="_blank" rel="noopener noreferrer">
                            <LinkedIcon />
                        </a>
                    </div>
                </div>
            </section>
            <section>
                <div className={styles.containerTwo}>
                    <div>
                        <img src='/assets/GVsuresh.svg' alt='Dr.G V Suresh image'/>
                    </div>
                    <div className={styles.contentBox}>
                        <h3>Dr.G V Suresh</h3>
                        <p>A Doctorate in Business Anthropology, holds a Masters in Business Administration from IIMB and Masters in Socio-Cultural Anthropology and also Masters in Public Administration carries with him a wide varied industry experience and exposure. His work stints with Dell software Group, SonicWall Inc, GE Medicals, HP, Birla Soft, etc. at varies HR roles honed him as a lead HR professional and consultant today.
                            He heads Anthrolabs, a culture research and management consulting firm specializing in Concept and Strategy. He is recognized in the HR Circle for his innovative policies and practices like the 4 days’ work week, Work from Home model, Inclusion & Diversity, etc. few decades back, which is turning into practice today.
                        </p>
                        <a href="https://www.linkedin.com/in/gvsuresh/"
                        target="_blank" rel="noopener noreferrer">
                            <LinkedIcon />
                        </a>
                    </div>
                </div>
            </section>
            <section>
                <div className={styles.containerThree}>
                    <div>
                        { memberOne}
                    </div>
                    <div className={styles.contentBox}>
                        <h3>Arpita De</h3>
                        <p>A Techno - researcher with a strong expertise in R & D projects in the areas of Mobile and Electronic sectors for the designing and implementation of various products to be indigenously designed in India to boost manufacturing and reduce imports. An expert in establishing Center of Excellence, works on Government policy, advocacy and procurement, project planning, research supervision. A public speaker, have a diversified professional background of the Industry and the Academia and have experience in the CDM, Solar energy, Electric Vehicles and the Mobile and the Electronics Industry.</p>
                        <a href="https://www.linkedin.com/in/arpita-de/"
                        target="_blank" rel="noopener noreferrer">
                            <LinkedIcon />
                        </a>
                    </div>
                </div>
            </section>
            <section>
                <div className={styles.containerTwo}>
                    <div>
                        {memberOne}
                    </div>
                    <div className={styles.contentBox}>
                        <h3>Vinay Hari</h3>
                        <p>A Senior Management and a Social Infrastructure development specialist.  Vinay carries close 20 years of experience with exposure to industries such as Pharma, Healthcare, Education, Skills, Telecom, E-Governance, etc. An effective communicator who has worked with both Public and private sector organisations.</p>
                        <a href="https://www.linkedin.com/in/vinay-h-5935746/"
                        target="_blank" rel="noopener noreferrer">
                            <LinkedIcon />
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BoardOfMembers
