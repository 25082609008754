import styles from "./AssessmentCard.module.scss";
import stylesMobile from "./AssessmentMobileCard.module.scss";
import {useMediaQuery} from '@material-ui/core';
import { useTranslation } from "react-i18next";


const AssessmentHeroCard = () => {
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const { t } = useTranslation();
  const SVGMobile = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="126" height="90" viewBox="0 0 276 150" fill="none">
  <path d="M3.6525 179L158.821 178.439L200.182 178.43L213.884 178.427L255.325 178.418L444.648 178.382C444.648 178.382 487.951 139.669 441.695 102.9C393.737 64.7783 370.417 95.8249 355.674 42.1633C318.569 -92.9141 -48.3076 -14.2965 47.9203 100.647C82.5695 142.034 -20.283 120.036 3.6525 179Z" fill="#F8FCFF"/>
  <path d="M128 167.217L251.234 167.855L279.467 167.999L266.945 142.958L254.032 131.61L236.423 126.133L228.273 115.048H191.228L176.714 127.143L155 134.185L142.314 146.088L142.013 146.534L128.384 166.654L128 167.217Z" fill="#1F3C88"/>
  <path opacity="0.1" d="M128.383 165.018L137.813 164.016L159.988 149.41L187.705 145.235L196.314 133.301L197.554 124.626L208.808 121.785L213.163 113.413H191.227L176.713 125.507L154.999 132.55L142.313 144.453L142.012 144.898L128.383 165.018Z" fill="#DEE5F8"/>
  <path d="M227.749 133.567L246.01 144.262L251.234 167.855L279.467 167.999L266.945 142.958L254.032 131.61L236.423 126.133L228.273 115.048L227.749 133.567Z" fill="#102048"/>
  <path d="M198.41 110.901L198.376 107.856L202.787 108.168L202.914 110.685C201.508 110.975 199.909 111.05 198.41 110.901Z" fill="#F9B384"/>
  <path d="M193.635 115.032C194.832 113.073 196.466 112.42 198.418 112.172L198.409 110.902C199.909 111.051 201.508 110.977 202.913 110.685L203.133 115.032H193.635V115.032Z" fill="#102048"/>
  <path d="M220.307 111.199L220.711 112.121C222.663 112.368 224.41 113.42 226.014 115.032H216.759L215.774 111.21C217.355 111.479 218.861 111.459 220.307 111.199Z" fill="#102048"/>
  <path d="M215.775 111.21L214.992 108.169L218.891 107.961L220.308 111.199C218.861 111.459 217.354 111.479 215.775 111.21Z" fill="#F9B384"/>
  <path d="M196.374 63.61C194.052 78.7222 194.71 93.7659 197.934 108.948H203.48C202.818 99.0934 202.312 87.3802 203.861 79.6236L214.443 109.077H220.501L210.518 67.1453L207.086 60.4208L199.044 59.45L196.374 63.61Z" fill="#102048"/>
  <path d="M188.991 31.3752L187.501 26.9042L183.466 27.8464C183.162 29.3438 184.975 31.6153 186.322 33.316L188.991 31.3752Z" fill="#F9B384"/>
  <path d="M202.596 47.3338L207.243 47.3885L207.704 41.3033L207.758 40.575L203.091 40.5333L202.925 42.8415L202.596 47.3338Z" fill="#F9B384"/>
  <path d="M202.95 42.4214C204.471 43.5065 206.46 42.8305 207.704 41.3012L207.758 40.5729L203.091 40.5312L202.95 42.4214Z" fill="#102048"/>
  <path d="M204.036 41.9129C206.394 42.4518 209.026 40.9397 210.065 38.9311L210.205 35.3255L208.886 33.4542L203.272 33.9375C203.298 33.944 203.294 34.0224 203.272 34.1563C203.206 34.5058 202.991 35.2504 202.791 36.1485C202.302 38.3335 201.91 41.4263 204.036 41.9129Z" fill="#F9B384"/>
  <path d="M207.987 45.0324C207.987 45.0324 204.521 47.8051 201.505 43.8542L195.785 62.8488C199.287 65.3227 203.714 66.1776 209.026 65.4828L207.987 45.0324Z" fill="white"/>
  <g opacity="0.3">
  <path opacity="0.3" d="M207.243 47.3891C206.763 47.5622 206.272 47.6675 205.782 47.7214C205.293 47.7712 204.799 47.7598 204.323 47.6626C203.848 47.5695 203.378 47.4201 202.947 47.1817C202.72 47.0821 202.523 46.9302 202.307 46.8029C202.094 46.669 201.909 46.4991 201.694 46.3563C201.853 46.5538 202.018 46.7522 202.2 46.9351C202.397 47.0992 202.582 47.2879 202.81 47.416C203.239 47.7173 203.746 47.8986 204.26 48.0047C205.296 48.2023 206.401 47.9712 207.243 47.3891Z" fill="#1F3C88"/>
  </g>
  <path d="M189.146 30.5417C192.283 35.8374 195.341 40.4905 198.106 42.7081C198.517 43.0371 199.04 43.1841 199.565 43.1571C200.605 43.1033 201.679 43.2306 202.874 43.5139C202.623 53.0162 201.722 61.8178 198.489 67.214C196.542 66.6522 194.179 65.75 192.874 64.6486C193.938 61.0381 195.196 55.8608 196.133 51.3383L185.768 32.8287L189.146 30.5417Z" fill="#FF3E29"/>
  <path d="M202.791 36.1485C206.36 38.1799 209.326 34.4788 209.326 34.4788L203.271 34.1562C203.206 34.5057 202.99 35.2503 202.791 36.1485Z" fill="#E28B6D"/>
  <path d="M209.271 38.2363C208.833 37.6704 208.633 36.7192 209.028 34.8046C207.728 36.2751 205.796 36.6188 202.963 35.359C202.963 35.359 201.12 34.638 201.541 32.7593C201.922 31.061 203.344 30.1596 204.453 31.546C206.969 30.8112 209.444 30.6797 210.554 32.9675C210.554 32.9675 214.089 34.8046 211.074 37.7856L209.271 38.2363Z" fill="#102048"/>
  <path d="M214.425 77.4805L215.034 77.3449L214.399 74.4979L218.599 73.4764L219.156 76.0745L219.766 75.9439L219.075 72.7188L213.657 74.0366L214.425 77.4805Z" fill="#1F3C88"/>
  <path d="M216.897 68.5063C217 68.6508 217.382 71.5828 217.382 71.5828C217.382 71.5828 219.08 73.1749 217.936 74.1522C216.792 75.1296 215.406 75.0928 214.747 74.3694C214.517 73.6615 214.499 72.8336 214.781 71.8359L214.122 68.5789L216.897 68.5063Z" fill="#F9B384"/>
  <path d="M211.319 77.2863L212.995 84.3864L213.64 87.1274L224.524 83.0384L223.09 77.0528L222.407 74.2L211.319 77.2863Z" fill="#ACBFEE"/>
  <path d="M180.813 30.0796L186.593 33.5839L186.852 31.5517L182.495 28.9104L180.813 30.0796Z" fill="#6F3428"/>
  <path d="M183.932 29.7828L185.414 30.681L188.933 25.68L186.739 24.35L183.932 29.7828Z" fill="#BB9132"/>
  <path d="M186.439 24.1716L189.223 25.8593C189.846 26.2373 190.653 26.101 191.118 25.5392L195.776 19.9055L188.688 15.6083L185.847 22.3443C185.564 23.0163 185.816 23.7936 186.439 24.1716Z" fill="#FBC343"/>
  <path d="M186.781 20.6174L187.165 20.4573C186.095 17.8821 186.282 15.7895 187.068 15.3282C187.477 15.0882 188.043 15.3715 188.622 16.1047L188.948 15.8467C187.924 14.5485 187.145 14.8008 186.857 14.9689C185.899 15.5323 185.61 17.7981 186.781 20.6174Z" fill="#FBC343"/>
  <path d="M192.328 23.9846C195.369 23.7193 197.245 22.4137 197.3 21.3049C197.316 20.9718 197.18 20.1651 195.555 19.8573L195.477 20.2655C196.395 20.4395 196.908 20.811 196.884 21.2837C196.838 22.1941 195.07 23.3265 192.291 23.5698L192.328 23.9846Z" fill="#FBC343"/>
  <path opacity="0.6" d="M189.748 19.2565C189.992 18.8336 189.979 18.3698 189.72 18.2196C189.46 18.0702 189.052 18.2914 188.808 18.7136C188.564 19.1365 188.576 19.6002 188.835 19.7505C189.095 19.9007 189.503 19.6794 189.748 19.2565Z" fill="white"/>
  <path d="M183.468 27.7387C183.367 28.4597 183.822 29.1031 184.783 29.1031C185.384 29.1031 186.225 28.2792 186.403 27.3011C186.549 26.4944 186.238 25.9661 185.669 25.731C185.084 25.4901 183.659 26.3793 183.468 27.7387Z" fill="#F9B384"/>
  <path d="M201.857 14.6314L202.054 15.2544L196.993 16.8489L196.796 16.2259L201.857 14.6314Z" fill="#FBC343"/>
  <path d="M191.022 8L191.944 13.2249L191.301 13.3384L190.378 8.11359L191.022 8Z" fill="#FBC343"/>
  <path d="M197.394 9.38334L197.944 9.73547L195.084 14.2038L194.534 13.8516L197.394 9.38334Z" fill="#FBC343"/>
  <path d="M210.9 56.8847L211.732 69.4324C207.087 69.2242 203.968 67.699 203.968 67.699C203.564 60.2102 204.526 51.8299 207.49 44.2938C215.02 47.0918 217.143 56.3614 218.26 70.1354L213.85 70.6465L210.9 56.8847Z" fill="#FF3E29"/>
  <path d="M209.198 39.4843C210.614 39.9897 211.52 39.8754 211.595 38.5216C211.638 37.7452 210.838 37.1263 210.133 37.4545C209.827 37.5966 209.541 37.8668 209.268 38.2367L209.198 39.4843Z" fill="#F9B384"/>
  <path opacity="0.15" d="M212.994 84.3858L213.639 87.1267L224.524 83.0377L223.089 77.0521C221.75 79.8224 218.917 83.2582 212.994 84.3858Z" fill="#102048"/>
  <path d="M197.076 47.0583C196.889 47.6536 196.726 48.2545 196.568 48.8571C196.406 49.458 196.264 50.0638 196.122 50.6696C195.982 51.2763 195.849 51.8838 195.73 52.4953C195.613 53.1068 195.498 53.7184 195.412 54.3373L195.57 54.3732C195.762 53.7788 195.925 53.1779 196.084 52.5761C196.243 51.9744 196.387 51.3686 196.525 50.7619C196.66 50.1544 196.795 49.547 196.91 48.9354C197.03 48.3247 197.145 47.7124 197.234 47.0943L197.076 47.0583Z" fill="#102048"/>
  <path d="M210.676 52.6603C210.655 53.2636 210.659 53.8654 210.669 54.4671C210.673 55.0689 210.699 55.6698 210.724 56.2707C210.752 56.8717 210.786 57.4718 210.836 58.0719C210.887 58.672 210.939 59.2713 211.023 59.8698L211.185 59.8616C211.211 59.2582 211.207 58.6565 211.199 58.0548C211.191 57.453 211.167 56.8521 211.138 56.2512C211.105 55.6502 211.073 55.0501 211.02 54.45C210.971 53.8499 210.918 53.2506 210.838 52.6521L210.676 52.6603Z" fill="#102048"/>
  <path d="M204.874 82.2311C204.687 81.5445 204.477 80.866 204.261 80.1899C204.049 79.5114 203.811 78.8435 203.588 78.1691C203.352 77.4996 203.111 76.8317 202.863 76.1671C202.608 75.5041 202.352 74.8419 202.066 74.1896L201.913 74.2435C202.095 74.9318 202.306 75.6094 202.52 76.2871C202.74 76.9623 202.967 77.6351 203.199 78.3055C203.443 78.9717 203.672 79.6437 203.929 80.3058C204.182 80.9696 204.44 81.6318 204.721 82.285L204.874 82.2311Z" fill="#708CD5"/>
  <path d="M210.047 38.7347C210.119 38.6326 210.187 38.5281 210.259 38.4309C210.332 38.3362 210.4 38.2383 210.483 38.1721C210.564 38.0995 210.638 38.0652 210.715 38.0856C210.8 38.0962 210.886 38.1885 210.982 38.2652L211.062 38.2146C211.008 38.0937 210.946 37.9582 210.785 37.8855C210.617 37.8096 210.424 37.8945 210.324 37.9909C210.117 38.1966 210.006 38.4391 209.958 38.7004L210.047 38.7347Z" fill="#102048"/>
  <path d="M186.46 27.9541C186.308 27.3809 186.386 26.8624 186.633 26.7971C186.881 26.7318 187.262 26.9996 187.414 27.5736C187.566 28.1467 187.74 28.973 187.349 29.1575C186.657 29.485 186.611 28.528 186.46 27.9541Z" fill="#F9B384"/>
  <path d="M272.536 44.688C272.536 44.9877 272.478 45.2778 272.373 45.5519H263.106C263.099 45.5456 263.096 45.5392 263.09 45.5328C263.09 45.5392 263.087 45.5456 263.083 45.5519C263.083 45.5519 262.835 45.5519 262.385 45.5583C257.798 45.5838 232.153 45.7273 232.153 45.5009C232.153 42.8868 234.152 40.7699 236.617 40.7699C237.194 40.7699 237.745 40.8847 238.249 41.0951C238.717 40.6934 239.317 40.4575 239.964 40.4575C240.901 40.4575 241.733 40.9548 242.231 41.7168C242.237 41.704 242.243 41.6881 242.25 41.6753C242.728 40.6584 243.659 39.8008 244.864 39.2397C245.83 38.787 246.968 38.5256 248.192 38.5256C248.546 38.5256 248.894 38.5479 249.231 38.5893C250.529 36.0581 253.063 34.3365 255.977 34.3365C259.704 34.3365 262.816 37.1547 263.507 40.8911C263.925 40.6169 264.419 40.4575 264.948 40.4575C266.01 40.4575 266.931 41.0919 267.387 42.026C267.846 41.8602 268.353 41.7678 268.889 41.7678C270.33 41.7678 271.573 42.4373 272.166 43.4064C272.402 43.7922 272.536 44.2289 272.536 44.688Z" fill="#DEE5F8"/>
  <path opacity="0.4" d="M272.163 43.407C270.981 43.0914 269.715 43.101 268.478 43.1169C265.717 43.1488 262.96 43.1807 260.199 43.2126C256.893 43.2508 253.947 41.3603 250.568 41.6664C248.948 41.8162 247.3 42.4761 245.744 42.5686C244.511 42.6419 243.009 42.4219 242.247 41.6759C242.725 40.659 243.656 39.8014 244.861 39.2403C245.827 38.7876 246.965 38.5262 248.19 38.5262C248.543 38.5262 248.891 38.5485 249.229 38.59C250.526 36.0587 253.061 34.3372 255.975 34.3372C259.701 34.3372 262.813 37.1554 263.505 40.8917C263.922 40.6175 264.416 40.4581 264.946 40.4581C266.007 40.4581 266.929 41.0925 267.384 42.0266C267.844 41.8608 268.35 41.7684 268.886 41.7684C270.327 41.7684 271.57 42.4379 272.163 43.407Z" fill="white"/>
  <path d="M152.515 62.3823C152.515 62.5799 152.476 62.7712 152.406 62.9497H146.324C146.317 62.9465 146.314 62.9434 146.311 62.937C146.311 62.9434 146.308 62.9465 146.308 62.9497C146.308 62.9497 126 63.0804 126 62.9147C126 61.1995 127.31 59.8096 128.93 59.8096C129.309 59.8096 129.669 59.8861 130.001 60.0231C130.31 59.7617 130.702 59.6055 131.126 59.6055C131.742 59.6055 132.287 59.9307 132.615 60.4312C133.055 59.4621 134.117 58.7129 135.443 58.4483C135.787 58.375 136.154 58.3367 136.53 58.3367C136.763 58.3367 136.989 58.3494 137.212 58.3781C138.063 56.7172 139.728 55.5854 141.64 55.5854C144.089 55.5854 146.129 57.4377 146.585 59.8893C146.859 59.7075 147.184 59.6055 147.532 59.6055C148.227 59.6055 148.832 60.0231 149.132 60.6352C149.432 60.5269 149.767 60.4631 150.12 60.4631C151.44 60.4631 152.515 61.3239 152.515 62.3823Z" fill="#DEE5F8"/>
  <path opacity="0.4" d="M152.513 62.3831C152.513 62.5807 152.475 62.772 152.405 62.9505H148.879C148.662 62.6126 148.509 62.23 148.244 61.9272C147.039 60.55 144.53 61.653 143.077 60.5436C142.812 60.3427 142.598 60.0781 142.324 59.8932C141.479 59.3321 140.357 59.6828 139.379 59.9506C138.378 60.2248 137.249 60.3746 136.35 59.855C135.875 59.5808 135.502 59.0133 135.441 58.4491C135.786 58.3757 136.152 58.3375 136.529 58.3375C136.761 58.3375 136.988 58.3502 137.211 58.3789C138.062 56.718 139.726 55.5862 141.639 55.5862C144.087 55.5862 146.128 57.4385 146.583 59.89C146.858 59.7083 147.183 59.6063 147.53 59.6063C148.225 59.6063 148.831 60.0239 149.131 60.636C149.43 60.5276 149.765 60.4639 150.119 60.4639C151.439 60.4639 152.513 61.3246 152.513 62.3831Z" fill="white"/>
  <path d="M171.618 17.8201C171.618 18.0624 141.638 17.8679 141.638 17.8679C141.634 17.8615 141.634 17.8583 141.634 17.852C141.628 17.8583 141.622 17.8615 141.615 17.8679H132.635C132.533 17.6033 132.479 17.3228 132.479 17.0327C132.479 15.4673 134.06 14.2017 136.011 14.2017C136.53 14.2017 137.025 14.291 137.468 14.4504C137.911 13.5482 138.804 12.9329 139.833 12.9329C140.343 12.9329 140.822 13.0859 141.226 13.3505C141.899 9.72893 144.915 7 148.527 7C148.552 7 148.578 7 148.603 7.00319C151.396 7.02869 153.819 8.68964 155.066 11.1221C155.391 11.0806 155.729 11.0583 156.073 11.0583C158.751 11.0583 161.03 12.3495 161.85 14.1507C162.334 13.4143 163.138 12.9329 164.046 12.9329C164.674 12.9329 165.254 13.1624 165.71 13.5514C166.198 13.3473 166.734 13.2357 167.292 13.2357C169.023 13.2357 170.515 14.3133 171.206 15.869C171.471 16.4588 171.618 17.1219 171.618 17.8201Z" fill="#DEE5F8"/>
  <path opacity="0.4" d="M171.206 15.8705C169.858 16.3232 168.34 16.3806 166.896 16.4124C163.526 16.4858 159.943 16.5049 157.077 14.7355C156.082 14.1235 155.206 13.3105 154.134 12.8482C152.343 12.0736 150.041 12.2808 148.721 10.8462C147.867 9.91847 147.714 8.41374 148.367 7.333C148.437 7.21823 148.514 7.10665 148.603 7.00464C151.396 7.03014 153.819 8.69109 155.065 11.1235C155.391 11.0821 155.728 11.0598 156.073 11.0598C158.751 11.0598 161.03 12.3509 161.849 14.1521C162.334 13.4157 163.137 12.9343 164.046 12.9343C164.674 12.9343 165.254 13.1639 165.71 13.5528C166.198 13.3488 166.733 13.2372 167.291 13.2372C169.022 13.2372 170.514 14.3147 171.206 15.8705Z" fill="white"/>
  <path d="M79.63 119.119C79.5881 118.996 79.511 118.887 79.4082 118.807C79.3053 118.727 79.1813 118.679 79.0514 118.669L75.2507 118.367L73.606 114.726C73.5537 114.609 73.4685 114.509 73.3607 114.439C73.253 114.369 73.1274 114.332 72.9989 114.332C72.8705 114.332 72.7448 114.369 72.637 114.439C72.5292 114.509 72.4439 114.608 72.3914 114.725L70.7467 118.367L66.946 118.669C66.8184 118.679 66.6963 118.725 66.5944 118.803C66.4926 118.881 66.4152 118.986 66.3717 119.107C66.3282 119.227 66.3203 119.358 66.3489 119.482C66.3776 119.607 66.4416 119.721 66.5334 119.811L69.342 122.549L68.3487 126.85C68.3186 126.98 68.3282 127.116 68.3765 127.241C68.4247 127.366 68.5093 127.473 68.6192 127.549C68.7292 127.625 68.8595 127.666 68.9931 127.667C69.1267 127.668 69.2576 127.629 69.3687 127.555L72.9987 125.135L76.6287 127.555C76.7423 127.63 76.8762 127.669 77.0125 127.666C77.1487 127.663 77.2808 127.618 77.391 127.538C77.5011 127.458 77.584 127.346 77.6286 127.217C77.6731 127.088 77.6771 126.949 77.64 126.817L76.4207 122.551L79.4447 119.829C79.6427 119.651 79.7154 119.372 79.63 119.119Z" fill="#089A79" fill-opacity="0.2"/>
  <path d="M133.298 36.7873C133.256 36.6639 133.179 36.5554 133.076 36.4754C132.973 36.3953 132.849 36.347 132.719 36.3366L128.919 36.0346L127.274 32.394C127.222 32.2767 127.136 32.1771 127.029 32.1072C126.921 32.0373 126.795 32.0001 126.667 32C126.538 31.9999 126.413 32.037 126.305 32.1068C126.197 32.1766 126.112 32.2761 126.059 32.3933L124.415 36.0346L120.614 36.3366C120.486 36.3468 120.364 36.3935 120.262 36.4711C120.161 36.5488 120.083 36.6542 120.04 36.7747C119.996 36.8951 119.988 37.0256 120.017 37.1504C120.046 37.2753 120.11 37.3892 120.201 37.4786L123.01 40.2166L122.017 44.518C121.987 44.6482 121.996 44.7844 122.044 44.9091C122.093 45.0337 122.177 45.141 122.287 45.2169C122.397 45.2929 122.527 45.3341 122.661 45.3351C122.795 45.3361 122.926 45.2969 123.037 45.2226L126.667 42.8026L130.297 45.2226C130.41 45.298 130.544 45.3369 130.68 45.3339C130.817 45.3309 130.949 45.2862 131.059 45.2059C131.169 45.1256 131.252 45.0135 131.297 44.8847C131.341 44.7559 131.345 44.6165 131.308 44.4853L130.089 40.2186L133.113 37.4973C133.311 37.3186 133.383 37.04 133.298 36.7873Z" fill="#D99229" fill-opacity="0.2"/>
  <path d="M264.63 111.119C264.588 110.996 264.511 110.887 264.408 110.807C264.305 110.727 264.181 110.679 264.051 110.669L260.251 110.367L258.606 106.726C258.554 106.609 258.468 106.509 258.361 106.439C258.253 106.369 258.127 106.332 257.999 106.332C257.871 106.332 257.745 106.369 257.637 106.439C257.529 106.509 257.444 106.608 257.391 106.725L255.747 110.367L251.946 110.669C251.818 110.679 251.696 110.725 251.594 110.803C251.493 110.881 251.415 110.986 251.372 111.107C251.328 111.227 251.32 111.358 251.349 111.482C251.378 111.607 251.442 111.721 251.533 111.811L254.342 114.549L253.349 118.85C253.319 118.98 253.328 119.116 253.376 119.241C253.425 119.366 253.509 119.473 253.619 119.549C253.729 119.625 253.859 119.666 253.993 119.667C254.127 119.668 254.258 119.629 254.369 119.555L257.999 117.135L261.629 119.555C261.742 119.63 261.876 119.669 262.012 119.666C262.149 119.663 262.281 119.618 262.391 119.538C262.501 119.458 262.584 119.346 262.629 119.217C262.673 119.088 262.677 118.949 262.64 118.817L261.421 114.551L264.445 111.829C264.643 111.651 264.715 111.372 264.63 111.119Z" fill="#DE5445" fill-opacity="0.2"/>
  </svg>
  );
  const SVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="276" height="150" viewBox="0 0 276 150" fill="none">
  <path d="M3.6525 179L158.821 178.439L200.182 178.43L213.884 178.427L255.325 178.418L444.648 178.382C444.648 178.382 487.951 139.669 441.695 102.9C393.737 64.7783 370.417 95.8249 355.674 42.1633C318.569 -92.9141 -48.3076 -14.2965 47.9203 100.647C82.5695 142.034 -20.283 120.036 3.6525 179Z" fill="#F8FCFF"/>
  <path d="M128 167.217L251.234 167.855L279.467 167.999L266.945 142.958L254.032 131.61L236.423 126.133L228.273 115.048H191.228L176.714 127.143L155 134.185L142.314 146.088L142.013 146.534L128.384 166.654L128 167.217Z" fill="#1F3C88"/>
  <path opacity="0.1" d="M128.383 165.018L137.813 164.016L159.988 149.41L187.705 145.235L196.314 133.301L197.554 124.626L208.808 121.785L213.163 113.413H191.227L176.713 125.507L154.999 132.55L142.313 144.453L142.012 144.898L128.383 165.018Z" fill="#DEE5F8"/>
  <path d="M227.749 133.567L246.01 144.262L251.234 167.855L279.467 167.999L266.945 142.958L254.032 131.61L236.423 126.133L228.273 115.048L227.749 133.567Z" fill="#102048"/>
  <path d="M198.41 110.901L198.376 107.856L202.787 108.168L202.914 110.685C201.508 110.975 199.909 111.05 198.41 110.901Z" fill="#F9B384"/>
  <path d="M193.635 115.032C194.832 113.073 196.466 112.42 198.418 112.172L198.409 110.902C199.909 111.051 201.508 110.977 202.913 110.685L203.133 115.032H193.635V115.032Z" fill="#102048"/>
  <path d="M220.307 111.199L220.711 112.121C222.663 112.368 224.41 113.42 226.014 115.032H216.759L215.774 111.21C217.355 111.479 218.861 111.459 220.307 111.199Z" fill="#102048"/>
  <path d="M215.775 111.21L214.992 108.169L218.891 107.961L220.308 111.199C218.861 111.459 217.354 111.479 215.775 111.21Z" fill="#F9B384"/>
  <path d="M196.374 63.61C194.052 78.7222 194.71 93.7659 197.934 108.948H203.48C202.818 99.0934 202.312 87.3802 203.861 79.6236L214.443 109.077H220.501L210.518 67.1453L207.086 60.4208L199.044 59.45L196.374 63.61Z" fill="#102048"/>
  <path d="M188.991 31.3752L187.501 26.9042L183.466 27.8464C183.162 29.3438 184.975 31.6153 186.322 33.316L188.991 31.3752Z" fill="#F9B384"/>
  <path d="M202.596 47.3338L207.243 47.3885L207.704 41.3033L207.758 40.575L203.091 40.5333L202.925 42.8415L202.596 47.3338Z" fill="#F9B384"/>
  <path d="M202.95 42.4214C204.471 43.5065 206.46 42.8305 207.704 41.3012L207.758 40.5729L203.091 40.5312L202.95 42.4214Z" fill="#102048"/>
  <path d="M204.036 41.9129C206.394 42.4518 209.026 40.9397 210.065 38.9311L210.205 35.3255L208.886 33.4542L203.272 33.9375C203.298 33.944 203.294 34.0224 203.272 34.1563C203.206 34.5058 202.991 35.2504 202.791 36.1485C202.302 38.3335 201.91 41.4263 204.036 41.9129Z" fill="#F9B384"/>
  <path d="M207.987 45.0324C207.987 45.0324 204.521 47.8051 201.505 43.8542L195.785 62.8488C199.287 65.3227 203.714 66.1776 209.026 65.4828L207.987 45.0324Z" fill="white"/>
  <g opacity="0.3">
  <path opacity="0.3" d="M207.243 47.3891C206.763 47.5622 206.272 47.6675 205.782 47.7214C205.293 47.7712 204.799 47.7598 204.323 47.6626C203.848 47.5695 203.378 47.4201 202.947 47.1817C202.72 47.0821 202.523 46.9302 202.307 46.8029C202.094 46.669 201.909 46.4991 201.694 46.3563C201.853 46.5538 202.018 46.7522 202.2 46.9351C202.397 47.0992 202.582 47.2879 202.81 47.416C203.239 47.7173 203.746 47.8986 204.26 48.0047C205.296 48.2023 206.401 47.9712 207.243 47.3891Z" fill="#1F3C88"/>
  </g>
  <path d="M189.146 30.5417C192.283 35.8374 195.341 40.4905 198.106 42.7081C198.517 43.0371 199.04 43.1841 199.565 43.1571C200.605 43.1033 201.679 43.2306 202.874 43.5139C202.623 53.0162 201.722 61.8178 198.489 67.214C196.542 66.6522 194.179 65.75 192.874 64.6486C193.938 61.0381 195.196 55.8608 196.133 51.3383L185.768 32.8287L189.146 30.5417Z" fill="#FF3E29"/>
  <path d="M202.791 36.1485C206.36 38.1799 209.326 34.4788 209.326 34.4788L203.271 34.1562C203.206 34.5057 202.99 35.2503 202.791 36.1485Z" fill="#E28B6D"/>
  <path d="M209.271 38.2363C208.833 37.6704 208.633 36.7192 209.028 34.8046C207.728 36.2751 205.796 36.6188 202.963 35.359C202.963 35.359 201.12 34.638 201.541 32.7593C201.922 31.061 203.344 30.1596 204.453 31.546C206.969 30.8112 209.444 30.6797 210.554 32.9675C210.554 32.9675 214.089 34.8046 211.074 37.7856L209.271 38.2363Z" fill="#102048"/>
  <path d="M214.425 77.4805L215.034 77.3449L214.399 74.4979L218.599 73.4764L219.156 76.0745L219.766 75.9439L219.075 72.7188L213.657 74.0366L214.425 77.4805Z" fill="#1F3C88"/>
  <path d="M216.897 68.5063C217 68.6508 217.382 71.5828 217.382 71.5828C217.382 71.5828 219.08 73.1749 217.936 74.1522C216.792 75.1296 215.406 75.0928 214.747 74.3694C214.517 73.6615 214.499 72.8336 214.781 71.8359L214.122 68.5789L216.897 68.5063Z" fill="#F9B384"/>
  <path d="M211.319 77.2863L212.995 84.3864L213.64 87.1274L224.524 83.0384L223.09 77.0528L222.407 74.2L211.319 77.2863Z" fill="#ACBFEE"/>
  <path d="M180.813 30.0796L186.593 33.5839L186.852 31.5517L182.495 28.9104L180.813 30.0796Z" fill="#6F3428"/>
  <path d="M183.932 29.7828L185.414 30.681L188.933 25.68L186.739 24.35L183.932 29.7828Z" fill="#BB9132"/>
  <path d="M186.439 24.1716L189.223 25.8593C189.846 26.2373 190.653 26.101 191.118 25.5392L195.776 19.9055L188.688 15.6083L185.847 22.3443C185.564 23.0163 185.816 23.7936 186.439 24.1716Z" fill="#FBC343"/>
  <path d="M186.781 20.6174L187.165 20.4573C186.095 17.8821 186.282 15.7895 187.068 15.3282C187.477 15.0882 188.043 15.3715 188.622 16.1047L188.948 15.8467C187.924 14.5485 187.145 14.8008 186.857 14.9689C185.899 15.5323 185.61 17.7981 186.781 20.6174Z" fill="#FBC343"/>
  <path d="M192.328 23.9846C195.369 23.7193 197.245 22.4137 197.3 21.3049C197.316 20.9718 197.18 20.1651 195.555 19.8573L195.477 20.2655C196.395 20.4395 196.908 20.811 196.884 21.2837C196.838 22.1941 195.07 23.3265 192.291 23.5698L192.328 23.9846Z" fill="#FBC343"/>
  <path opacity="0.6" d="M189.748 19.2565C189.992 18.8336 189.979 18.3698 189.72 18.2196C189.46 18.0702 189.052 18.2914 188.808 18.7136C188.564 19.1365 188.576 19.6002 188.835 19.7505C189.095 19.9007 189.503 19.6794 189.748 19.2565Z" fill="white"/>
  <path d="M183.468 27.7387C183.367 28.4597 183.822 29.1031 184.783 29.1031C185.384 29.1031 186.225 28.2792 186.403 27.3011C186.549 26.4944 186.238 25.9661 185.669 25.731C185.084 25.4901 183.659 26.3793 183.468 27.7387Z" fill="#F9B384"/>
  <path d="M201.857 14.6314L202.054 15.2544L196.993 16.8489L196.796 16.2259L201.857 14.6314Z" fill="#FBC343"/>
  <path d="M191.022 8L191.944 13.2249L191.301 13.3384L190.378 8.11359L191.022 8Z" fill="#FBC343"/>
  <path d="M197.394 9.38334L197.944 9.73547L195.084 14.2038L194.534 13.8516L197.394 9.38334Z" fill="#FBC343"/>
  <path d="M210.9 56.8847L211.732 69.4324C207.087 69.2242 203.968 67.699 203.968 67.699C203.564 60.2102 204.526 51.8299 207.49 44.2938C215.02 47.0918 217.143 56.3614 218.26 70.1354L213.85 70.6465L210.9 56.8847Z" fill="#FF3E29"/>
  <path d="M209.198 39.4843C210.614 39.9897 211.52 39.8754 211.595 38.5216C211.638 37.7452 210.838 37.1263 210.133 37.4545C209.827 37.5966 209.541 37.8668 209.268 38.2367L209.198 39.4843Z" fill="#F9B384"/>
  <path opacity="0.15" d="M212.994 84.3858L213.639 87.1267L224.524 83.0377L223.089 77.0521C221.75 79.8224 218.917 83.2582 212.994 84.3858Z" fill="#102048"/>
  <path d="M197.076 47.0583C196.889 47.6536 196.726 48.2545 196.568 48.8571C196.406 49.458 196.264 50.0638 196.122 50.6696C195.982 51.2763 195.849 51.8838 195.73 52.4953C195.613 53.1068 195.498 53.7184 195.412 54.3373L195.57 54.3732C195.762 53.7788 195.925 53.1779 196.084 52.5761C196.243 51.9744 196.387 51.3686 196.525 50.7619C196.66 50.1544 196.795 49.547 196.91 48.9354C197.03 48.3247 197.145 47.7124 197.234 47.0943L197.076 47.0583Z" fill="#102048"/>
  <path d="M210.676 52.6603C210.655 53.2636 210.659 53.8654 210.669 54.4671C210.673 55.0689 210.699 55.6698 210.724 56.2707C210.752 56.8717 210.786 57.4718 210.836 58.0719C210.887 58.672 210.939 59.2713 211.023 59.8698L211.185 59.8616C211.211 59.2582 211.207 58.6565 211.199 58.0548C211.191 57.453 211.167 56.8521 211.138 56.2512C211.105 55.6502 211.073 55.0501 211.02 54.45C210.971 53.8499 210.918 53.2506 210.838 52.6521L210.676 52.6603Z" fill="#102048"/>
  <path d="M204.874 82.2311C204.687 81.5445 204.477 80.866 204.261 80.1899C204.049 79.5114 203.811 78.8435 203.588 78.1691C203.352 77.4996 203.111 76.8317 202.863 76.1671C202.608 75.5041 202.352 74.8419 202.066 74.1896L201.913 74.2435C202.095 74.9318 202.306 75.6094 202.52 76.2871C202.74 76.9623 202.967 77.6351 203.199 78.3055C203.443 78.9717 203.672 79.6437 203.929 80.3058C204.182 80.9696 204.44 81.6318 204.721 82.285L204.874 82.2311Z" fill="#708CD5"/>
  <path d="M210.047 38.7347C210.119 38.6326 210.187 38.5281 210.259 38.4309C210.332 38.3362 210.4 38.2383 210.483 38.1721C210.564 38.0995 210.638 38.0652 210.715 38.0856C210.8 38.0962 210.886 38.1885 210.982 38.2652L211.062 38.2146C211.008 38.0937 210.946 37.9582 210.785 37.8855C210.617 37.8096 210.424 37.8945 210.324 37.9909C210.117 38.1966 210.006 38.4391 209.958 38.7004L210.047 38.7347Z" fill="#102048"/>
  <path d="M186.46 27.9541C186.308 27.3809 186.386 26.8624 186.633 26.7971C186.881 26.7318 187.262 26.9996 187.414 27.5736C187.566 28.1467 187.74 28.973 187.349 29.1575C186.657 29.485 186.611 28.528 186.46 27.9541Z" fill="#F9B384"/>
  <path d="M272.536 44.688C272.536 44.9877 272.478 45.2778 272.373 45.5519H263.106C263.099 45.5456 263.096 45.5392 263.09 45.5328C263.09 45.5392 263.087 45.5456 263.083 45.5519C263.083 45.5519 262.835 45.5519 262.385 45.5583C257.798 45.5838 232.153 45.7273 232.153 45.5009C232.153 42.8868 234.152 40.7699 236.617 40.7699C237.194 40.7699 237.745 40.8847 238.249 41.0951C238.717 40.6934 239.317 40.4575 239.964 40.4575C240.901 40.4575 241.733 40.9548 242.231 41.7168C242.237 41.704 242.243 41.6881 242.25 41.6753C242.728 40.6584 243.659 39.8008 244.864 39.2397C245.83 38.787 246.968 38.5256 248.192 38.5256C248.546 38.5256 248.894 38.5479 249.231 38.5893C250.529 36.0581 253.063 34.3365 255.977 34.3365C259.704 34.3365 262.816 37.1547 263.507 40.8911C263.925 40.6169 264.419 40.4575 264.948 40.4575C266.01 40.4575 266.931 41.0919 267.387 42.026C267.846 41.8602 268.353 41.7678 268.889 41.7678C270.33 41.7678 271.573 42.4373 272.166 43.4064C272.402 43.7922 272.536 44.2289 272.536 44.688Z" fill="#DEE5F8"/>
  <path opacity="0.4" d="M272.163 43.407C270.981 43.0914 269.715 43.101 268.478 43.1169C265.717 43.1488 262.96 43.1807 260.199 43.2126C256.893 43.2508 253.947 41.3603 250.568 41.6664C248.948 41.8162 247.3 42.4761 245.744 42.5686C244.511 42.6419 243.009 42.4219 242.247 41.6759C242.725 40.659 243.656 39.8014 244.861 39.2403C245.827 38.7876 246.965 38.5262 248.19 38.5262C248.543 38.5262 248.891 38.5485 249.229 38.59C250.526 36.0587 253.061 34.3372 255.975 34.3372C259.701 34.3372 262.813 37.1554 263.505 40.8917C263.922 40.6175 264.416 40.4581 264.946 40.4581C266.007 40.4581 266.929 41.0925 267.384 42.0266C267.844 41.8608 268.35 41.7684 268.886 41.7684C270.327 41.7684 271.57 42.4379 272.163 43.407Z" fill="white"/>
  <path d="M152.515 62.3823C152.515 62.5799 152.476 62.7712 152.406 62.9497H146.324C146.317 62.9465 146.314 62.9434 146.311 62.937C146.311 62.9434 146.308 62.9465 146.308 62.9497C146.308 62.9497 126 63.0804 126 62.9147C126 61.1995 127.31 59.8096 128.93 59.8096C129.309 59.8096 129.669 59.8861 130.001 60.0231C130.31 59.7617 130.702 59.6055 131.126 59.6055C131.742 59.6055 132.287 59.9307 132.615 60.4312C133.055 59.4621 134.117 58.7129 135.443 58.4483C135.787 58.375 136.154 58.3367 136.53 58.3367C136.763 58.3367 136.989 58.3494 137.212 58.3781C138.063 56.7172 139.728 55.5854 141.64 55.5854C144.089 55.5854 146.129 57.4377 146.585 59.8893C146.859 59.7075 147.184 59.6055 147.532 59.6055C148.227 59.6055 148.832 60.0231 149.132 60.6352C149.432 60.5269 149.767 60.4631 150.12 60.4631C151.44 60.4631 152.515 61.3239 152.515 62.3823Z" fill="#DEE5F8"/>
  <path opacity="0.4" d="M152.513 62.3831C152.513 62.5807 152.475 62.772 152.405 62.9505H148.879C148.662 62.6126 148.509 62.23 148.244 61.9272C147.039 60.55 144.53 61.653 143.077 60.5436C142.812 60.3427 142.598 60.0781 142.324 59.8932C141.479 59.3321 140.357 59.6828 139.379 59.9506C138.378 60.2248 137.249 60.3746 136.35 59.855C135.875 59.5808 135.502 59.0133 135.441 58.4491C135.786 58.3757 136.152 58.3375 136.529 58.3375C136.761 58.3375 136.988 58.3502 137.211 58.3789C138.062 56.718 139.726 55.5862 141.639 55.5862C144.087 55.5862 146.128 57.4385 146.583 59.89C146.858 59.7083 147.183 59.6063 147.53 59.6063C148.225 59.6063 148.831 60.0239 149.131 60.636C149.43 60.5276 149.765 60.4639 150.119 60.4639C151.439 60.4639 152.513 61.3246 152.513 62.3831Z" fill="white"/>
  <path d="M171.618 17.8201C171.618 18.0624 141.638 17.8679 141.638 17.8679C141.634 17.8615 141.634 17.8583 141.634 17.852C141.628 17.8583 141.622 17.8615 141.615 17.8679H132.635C132.533 17.6033 132.479 17.3228 132.479 17.0327C132.479 15.4673 134.06 14.2017 136.011 14.2017C136.53 14.2017 137.025 14.291 137.468 14.4504C137.911 13.5482 138.804 12.9329 139.833 12.9329C140.343 12.9329 140.822 13.0859 141.226 13.3505C141.899 9.72893 144.915 7 148.527 7C148.552 7 148.578 7 148.603 7.00319C151.396 7.02869 153.819 8.68964 155.066 11.1221C155.391 11.0806 155.729 11.0583 156.073 11.0583C158.751 11.0583 161.03 12.3495 161.85 14.1507C162.334 13.4143 163.138 12.9329 164.046 12.9329C164.674 12.9329 165.254 13.1624 165.71 13.5514C166.198 13.3473 166.734 13.2357 167.292 13.2357C169.023 13.2357 170.515 14.3133 171.206 15.869C171.471 16.4588 171.618 17.1219 171.618 17.8201Z" fill="#DEE5F8"/>
  <path opacity="0.4" d="M171.206 15.8705C169.858 16.3232 168.34 16.3806 166.896 16.4124C163.526 16.4858 159.943 16.5049 157.077 14.7355C156.082 14.1235 155.206 13.3105 154.134 12.8482C152.343 12.0736 150.041 12.2808 148.721 10.8462C147.867 9.91847 147.714 8.41374 148.367 7.333C148.437 7.21823 148.514 7.10665 148.603 7.00464C151.396 7.03014 153.819 8.69109 155.065 11.1235C155.391 11.0821 155.728 11.0598 156.073 11.0598C158.751 11.0598 161.03 12.3509 161.849 14.1521C162.334 13.4157 163.137 12.9343 164.046 12.9343C164.674 12.9343 165.254 13.1639 165.71 13.5528C166.198 13.3488 166.733 13.2372 167.291 13.2372C169.022 13.2372 170.514 14.3147 171.206 15.8705Z" fill="white"/>
  <path d="M79.63 119.119C79.5881 118.996 79.511 118.887 79.4082 118.807C79.3053 118.727 79.1813 118.679 79.0514 118.669L75.2507 118.367L73.606 114.726C73.5537 114.609 73.4685 114.509 73.3607 114.439C73.253 114.369 73.1274 114.332 72.9989 114.332C72.8705 114.332 72.7448 114.369 72.637 114.439C72.5292 114.509 72.4439 114.608 72.3914 114.725L70.7467 118.367L66.946 118.669C66.8184 118.679 66.6963 118.725 66.5944 118.803C66.4926 118.881 66.4152 118.986 66.3717 119.107C66.3282 119.227 66.3203 119.358 66.3489 119.482C66.3776 119.607 66.4416 119.721 66.5334 119.811L69.342 122.549L68.3487 126.85C68.3186 126.98 68.3282 127.116 68.3765 127.241C68.4247 127.366 68.5093 127.473 68.6192 127.549C68.7292 127.625 68.8595 127.666 68.9931 127.667C69.1267 127.668 69.2576 127.629 69.3687 127.555L72.9987 125.135L76.6287 127.555C76.7423 127.63 76.8762 127.669 77.0125 127.666C77.1487 127.663 77.2808 127.618 77.391 127.538C77.5011 127.458 77.584 127.346 77.6286 127.217C77.6731 127.088 77.6771 126.949 77.64 126.817L76.4207 122.551L79.4447 119.829C79.6427 119.651 79.7154 119.372 79.63 119.119Z" fill="#089A79" fill-opacity="0.2"/>
  <path d="M133.298 36.7873C133.256 36.6639 133.179 36.5554 133.076 36.4754C132.973 36.3953 132.849 36.347 132.719 36.3366L128.919 36.0346L127.274 32.394C127.222 32.2767 127.136 32.1771 127.029 32.1072C126.921 32.0373 126.795 32.0001 126.667 32C126.538 31.9999 126.413 32.037 126.305 32.1068C126.197 32.1766 126.112 32.2761 126.059 32.3933L124.415 36.0346L120.614 36.3366C120.486 36.3468 120.364 36.3935 120.262 36.4711C120.161 36.5488 120.083 36.6542 120.04 36.7747C119.996 36.8951 119.988 37.0256 120.017 37.1504C120.046 37.2753 120.11 37.3892 120.201 37.4786L123.01 40.2166L122.017 44.518C121.987 44.6482 121.996 44.7844 122.044 44.9091C122.093 45.0337 122.177 45.141 122.287 45.2169C122.397 45.2929 122.527 45.3341 122.661 45.3351C122.795 45.3361 122.926 45.2969 123.037 45.2226L126.667 42.8026L130.297 45.2226C130.41 45.298 130.544 45.3369 130.68 45.3339C130.817 45.3309 130.949 45.2862 131.059 45.2059C131.169 45.1256 131.252 45.0135 131.297 44.8847C131.341 44.7559 131.345 44.6165 131.308 44.4853L130.089 40.2186L133.113 37.4973C133.311 37.3186 133.383 37.04 133.298 36.7873Z" fill="#D99229" fill-opacity="0.2"/>
  <path d="M264.63 111.119C264.588 110.996 264.511 110.887 264.408 110.807C264.305 110.727 264.181 110.679 264.051 110.669L260.251 110.367L258.606 106.726C258.554 106.609 258.468 106.509 258.361 106.439C258.253 106.369 258.127 106.332 257.999 106.332C257.871 106.332 257.745 106.369 257.637 106.439C257.529 106.509 257.444 106.608 257.391 106.725L255.747 110.367L251.946 110.669C251.818 110.679 251.696 110.725 251.594 110.803C251.493 110.881 251.415 110.986 251.372 111.107C251.328 111.227 251.32 111.358 251.349 111.482C251.378 111.607 251.442 111.721 251.533 111.811L254.342 114.549L253.349 118.85C253.319 118.98 253.328 119.116 253.376 119.241C253.425 119.366 253.509 119.473 253.619 119.549C253.729 119.625 253.859 119.666 253.993 119.667C254.127 119.668 254.258 119.629 254.369 119.555L257.999 117.135L261.629 119.555C261.742 119.63 261.876 119.669 262.012 119.666C262.149 119.663 262.281 119.618 262.391 119.538C262.501 119.458 262.584 119.346 262.629 119.217C262.673 119.088 262.677 118.949 262.64 118.817L261.421 114.551L264.445 111.829C264.643 111.651 264.715 111.372 264.63 111.119Z" fill="#DE5445" fill-opacity="0.2"/>
  </svg>
  );
  
  return (
    <div>
      {smallScreen ? (
        <div className={stylesMobile.main}>
          <div>
            <h4>{t("Assessments")}</h4>
            <p>
              {t(
                "Try our daily contests, hackathons, company specific mock tests. With Aptitude, Coding, Written test and 6 other modules on a single platform, we've got you covered.",
              )}
            </p>
          </div>
          <SVGMobile />
        </div>
      ) : (
        <div className={styles.main}>
          <div>
            <h4>{t("Assessments")}</h4>
            <p>
              {t(
                "Try our daily contests, hackathons, company specific mock tests. With Aptitude, Coding, Written test and 6 other modules on a single platform, we've got you covered.",
              )}
            </p>
          </div>
          <SVG />
        </div>
      )}
    </div>
  );
};

export default AssessmentHeroCard;
