import { Accordions } from "./Accordions";

const PrivilegesBox = ({
  styles,
  privilegesByRole,
  privileges,
  setPrivileges,
  setPrivilegeValue,
  privilegeValue,
}) => {
  return (
    <div className={styles.privBox}>
      <h4>Privileges</h4>
      <p>Select the privileges for this role from below box</p>
      {privilegeValue === true && (
      <p style={{color: "red"}}>Please select any one Privilege</p>
      )}
      <div className={styles.privAccordions}>
        <Accordions
          styles={styles}
          privilegesByRole={privilegesByRole}
          privileges={privileges}
          setPrivileges={setPrivileges}
        />
      </div>
    </div>
  );
};

export default PrivilegesBox;
