/** @format */

import { useState } from "react";
import cn from "classnames";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import {
  Grid,
  LinearProgress as MuiLinearProgress,
  Typography,
  withStyles,
} from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import { Icon } from "@iconify/react";
import crownIcon from "@iconify/icons-fa-solid/crown";
import styles from "./card.module.scss";
import { useTranslation } from "react-i18next";
import { sessionPrivileges } from "privileges";

const LinearProgress = withStyles({
  root: {
    backgroundColor: "#DFDFDF",
    borderRadius: 10,
    flexGrow: 1,
    height: 7,
  },
  bar: {
    backgroundColor: "#F56F18",
    borderRadius: 10,
  },
})(MuiLinearProgress);

function PracticeCard({
  className,
  description,
  discountAmount,
  expired,
  id,
  imageIndex = 1,
  imageUrl,
  onPracticeButtonClick = undefined,
  onRemoveFromCartButtonClick = undefined,
  onTryButtonClick = undefined,
  onBuyButtonClick = undefined,
  originalPrice,
  paidPackage,
  progress,
  purchased,
  strikingPrice,
  tag,
  title,
  totalQuestions,
  isLoggedIn,
}) {
  const [removeButtonIsLoading, setRemoveButtonLoadingStatus] = useState(false);
  const Priv = sessionPrivileges().userprivileges || [];
  const { t } = useTranslation();

  return (
    <div className={cn(styles.card, className)}>
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={
            imageUrl
              ? imageUrl?.includes("default")
                ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${imageUrl}.jpeg`
                : imageUrl
              : `https://d2ndap9rlps54r.cloudfront.net/PracticePackages/image-${imageIndex}.jpg`
          }
        />
        <div className={styles.imageOverlay} />
        {tag && (
          <div className={styles[`${tag}Tag`]}>
            {tag === "premium" && (
              <>
                <Icon
                  icon={crownIcon}
                  style={{ color: "#ffcc4d", fontSize: "12px" }}
                />
                &nbsp;&nbsp;
              </>
            )}
            {tag.charAt(0).toUpperCase() + tag.slice(1)}
          </div>
        )}
      </div>
      <div className={styles.content}>
        <h4 className={styles.title}>{title}</h4>
        <h4
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description || "" }}
        />
        <div className={styles.spacer} />
        {(
          !onRemoveFromCartButtonClick
            ? paidPackage
              ? purchased
              : true
            : false
        ) ? (
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            style={{ height: 36 }}
          >
            <div style={{ flexGrow: 1 }}>
              <LinearProgress variant="determinate" value={progress} />
              <Typography
                align="center"
                style={{
                  color: "#71717C",
                  fontSize: 12,
                  fontWeight: 400,
                  marginTop: 6,
                }}
              >
                {`${totalQuestions} question${totalQuestions > 1 ? "s" : ""}`}
              </Typography>
            </div>
            &nbsp;&nbsp;&nbsp;
            <Typography
              style={{
                color: "#000",
                fontSize: 15,
                fontWeight: 500,
                marginTop: -22,
              }}
            >
              {parseInt(progress)}%
            </Typography>
          </Grid>
        ) : (
          <Grid
            alignItems="baseline"
            container
            direction="column"
            className={styles.priceContainer}
            justifyContent="center"
            wrap="nowrap"
            style={{
              height: 56,
              paddingTop: 6,
            }}
          >
            <Typography
              style={{ color: "#000", fontSize: 24, fontWeight: "bold" }}
            >
              <span style={{ fontFamily: "Roboto, sans-serif" }}>₹</span>
              &nbsp;
              {strikingPrice || originalPrice}
              &nbsp;
              {"INR"}
            </Typography>
            {strikingPrice > 0 && discountAmount > 0 && (
              <Grid
                alignItems="baseline"
                container
                wrap="nowrap"
                style={{
                  marginTop: -2,
                  whiteSpace: "nowrap",
                }}
              >
                <Typography
                  style={{ color: "#F91818", fontSize: 16, fontWeight: 500 }}
                >
                  <span style={{ fontFamily: "Roboto, sans-serif" }}>₹</span>
                  &nbsp;
                  <span style={{ textDecoration: "line-through" }}>
                    {originalPrice}
                    &nbsp;
                    {"INR"}
                  </span>
                </Typography>
                &nbsp;
                <Typography
                  style={{
                    color: "#545260",
                    fontSize: 10,
                    fontWeight: 400,
                    letterSpacing: 0.35,
                  }}
                >
                  (Save {parseFloat(discountAmount).toFixed(0)}%)
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        {onRemoveFromCartButtonClick ? (
          <Button
            className={styles.button}
            color="primary"
            disabled={removeButtonIsLoading}
            onClick={async () => {
              setRemoveButtonLoadingStatus(true);

              await onRemoveFromCartButtonClick(id).finally(() => {
                setRemoveButtonLoadingStatus(false);
              });
            }}
            outline
            size="lg"
          >
            {removeButtonIsLoading ? (
              <span style={{ color: "#794DF5 !important" }}>
                <CircularProgress color="inherit" thickness={5} size={14} />
                &nbsp;&nbsp;&nbsp;
                <>Removing</>
              </span>
            ) : (
              "Remove from cart"
            )}
          </Button>
        ) : (paidPackage ? purchased : true) ? (
          Priv?.includes("START_PRACTICE_PACKAGE") ||
          sessionStorage.getItem("viewPkgWithoutSignIn") === "true" ? (
            <Button
              className={cn(styles.button, {
                [styles.expired]: expired,
              })}
              color="primary"
              disabled={expired}
              onClick={onPracticeButtonClick}
              outline
              size="lg"
            >
              {expired ? t("Package Expired") : t("Practice Now")}
            </Button>
          ) : (
            ""
          )
        ) : (
          <Grid container wrap="nowrap">
            <Link
              style={{ flexGrow: 1 }}
              to={
                isLoggedIn.state && {
                  pathname: "/checkout",
                  params: { items: [id] },
                }
              }
              onClick={!isLoggedIn.state && onBuyButtonClick}
            >
              <Button
                className={styles.button}
                color="primary"
                size="lg"
                style={{ width: "100%" }}
              >
                Buy Now
              </Button>
            </Link>
            <Button
              className={`${styles.button} ${styles.tryButton}`}
              color="primary"
              outline
              onClick={onTryButtonClick}
              size="lg"
              style={{
                marginLeft: 16,
                padding: 0,
                width: "calc(50% - 8px)",
                flexShrink: 0,
              }}
            >
              <span className={styles.freeStar}>
                <span className={styles.freeStarText}>Free</span>
              </span>
              <CardGiftcardIcon
                style={{ color: "inherit", fontSize: 26, marginTop: -2 }}
              />
              <span>&nbsp;&nbsp;Try Now</span>
            </Button>
          </Grid>
        )}
      </div>
    </div>
  );
}

export default PracticeCard;
