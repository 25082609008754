import { Dialog, DialogContent, Button } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import apiHelper from "api/apiHelper";
import api from "api/baseConfig";
import { useState } from "react";
import { uid } from "react-uid";
import styles from "./SummaryModal.module.scss";
import { Skeleton } from "@material-ui/lab";
import { FileIcon } from "assets/icons/icons";
import { useHistory } from "react-router-dom";

const items = [
  { label: "Questions answered", value: "questionsAnswered" },
  { label: "Questions graded", value: "questionsGraded" },
  { label: "Correct answers", value: "rightAnswers" },
  { label: "Wrong answers", value: "wrongAnswers" },
  { label: "Partial correct", value: "partialCorrect" },
];

const Publish = ({ toPublish, publishGrade, close, assessmentDetails }) => (
  <div className={styles.publishContainer}>
    <div className={styles.publishFile}>
      <FileIcon size={40} color="#794DF5" />
    </div>
    <p className={styles.publishP1}>Publish grade</p>
    <p className={styles.publishP2}>
      Are you sure you want to publish the{" "}
      {`grade(s) of ${assessmentDetails?.studentName || toPublish()} ${
        !assessmentDetails?.studentName ? "students" : ""
      }?`}
    </p>
    <div>
      <Button
        disableElevation
        classes={{ root: styles.cancelButton }}
        onClick={close}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        disableElevation
        classes={{ root: styles.publishButton }}
        onClick={publishGrade.bind(this, assessmentDetails?.studentId)}
      >
        Publish
      </Button>
    </div>
  </div>
);

const Summary = ({ assessmentDetails, setShouldPublish, close }) => (
  <div>
    <p className={styles.title}>Grading summary</p>
    <div className={styles.markList}>
      {items.map((item, ind) => (
        <div key={uid(ind)} className={styles.markItem}>
          {!assessmentDetails?.[item.value] &&
          assessmentDetails?.[item.value] !== 0 ? (
            <Skeleton width={'20rem'}/>
          ) : (
            <>
              <p>{item.label}</p>
              <p>{assessmentDetails?.[item.value]}</p>
            </>
          )}
        </div>
      ))}
    </div>
    <div>
      <Button
        color="primary"
        variant="contained"
        disableElevation
        classes={{ root: styles.publishButton }}
        onClick={setShouldPublish.bind(this, true)}
      >
        Publish
      </Button>
      <Button
        color="primary"
        variant="outlined"
        disableElevation
        classes={{ root: styles.saveButton }}
        onClick={close}
      >
        Save
      </Button>
    </div>
  </div>
);

function GradingSummaryModal({
  isOpen,
  close,
  assessmentDetails,
  type,
  data,
  from = "",
  getAssessmentGradingStatus,
  loading,
}) {
  const [shouldPublish, setShouldPublish] = useState(false);

  const history = useHistory();

  const publishGrade = async (id) => {
    const url = id
      ? `${api.baseURL}${api.userManagement.publishGrade}${assessmentDetails.assessmentId}/?userId=${id}`
      : `${api.baseURL}${api.userManagement.publishGrade}${assessmentDetails.assessmentId}`;
    try {
      await apiHelper.axiosCallPost(url, "put");
      close();

      if (from === "table") {
        getAssessmentGradingStatus(assessmentDetails.assessmentId);
      } else {
        history.push({
          pathname: "/manual-grading",
          state: {
            assessmentId: assessmentDetails.assessmentId,
          },
        });
      }
    } catch (e) {}
  };

  const toPublish = () => {
    let totes = 0;
    (data || []).forEach((datum) => {
      if (datum.manualGradingCompleted && !datum.manualGradingPublished) {
        totes++;
      }
    });
    return totes;
  };

  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogContent classes={{ root: styles.dialogContent }}>
        <Collapse
          in={type === "summary" && !shouldPublish}
          mountOnEnter
          unmountOnExit
        >
          <Summary
            assessmentDetails={assessmentDetails}
            setShouldPublish={setShouldPublish}
            close={close}
          />
        </Collapse>
        <Collapse
          in={type === "publish" || shouldPublish}
          mountOnEnter
          unmountOnExit
        >
          <Publish
            toPublish={toPublish}
            publishGrade={publishGrade}
            close={() => {
              close();
              if (shouldPublish) {
                setShouldPublish(false);
              }
            }}
            assessmentDetails={assessmentDetails}
          />
        </Collapse>
      </DialogContent>
    </Dialog>
  );
}

export default GradingSummaryModal;
