import { sessionPrivileges } from "privileges";
import * as yup from "yup";
import { CheckIfCourseNameExists } from "./Apis";

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);
const currentYear = new Date().getFullYear(); // 2020
const previousYear = currentYear - 1;
const isSA = () => {
  return sessionPrivileges().isSuperAdmin;
};

export const Form1Schema = (isEdit, editData, customerId) =>
  yup.object().shape({
    name: yup
      .string()
      .required("Please enter a valid name")
      .test(
        "checkDuplCourseName",
        "This name is already in use. Please enter a different name",
        async (value) => {
          if (!value) return true;
          else if (isEdit && value === editData?.name) return true;
          const res = await CheckIfCourseNameExists(value, customerId);
          return !res;
        }
      ),
    description: yup
      .string()
      .required("Please enter a valid description"),
      // .min(100, "Please enter atleast 100 characters"),
      // .max(500, "Please enter below 500 characters"),
    isEdit: yup.boolean(),
    filterType: yup.string().required("select assign student"),
    startDate: yup
      .date()
      .nullable()
      .typeError("Please enter a valid start date")
      .min(isEdit ? previousYear : yesterday, "Start date must be after today"),
    endDate: yup
      .date()
      .nullable()
      .typeError("Please enter a valid end date")
      .min(yup.ref("startDate"), "End date can't be before start date"),
    duration: yup.number().positive().required("Please enter a valid duration"),
    level: yup.string().required("Please choose a level"),
    category: yup.object().required("Please choose a category"),
    media: yup.string().required("Please choose a website image"),
    ...(isSA() && {
      customerId: yup.string().required("Please choose a customer"),
    }),
  });

export const LessonFormSchema = (type) =>
  yup.object().shape({
    name: yup.string().required("Please enter a valid name"),
    ...((type === "Video" || type === "Audio") && {
      durationHour: yup
        .number(),
    }),
    ...((type === "Video" || type === "Audio") && {
      durationMin: yup
        .number()
        .required("Please enter a valid duration"),
    }),
    ...((type === "Video" || type === "Audio") && {
      durationSec: yup
        .number()
        .required("Please enter a valid duration"),
    }),
    ...(type === "Study material" && {
      documentType: yup.string().required("Please choose a document type"),
    }),
    ...(type === "Scrom" && {
      secretId: yup.string().required("Please enter secret id"),
    }),
    ...((type === "Video" || type === "Audio" || type === "Study material") && {
      media: yup.string().required("Please upload a content"),
    }),
    ...(type === "Practice lab" && {
      completionPercentage: yup.number().required("Please enter a percentage"),
      externalResourceId: yup.string().required("Please choose a practice"),
    }),
    ...(type === "Live class" && {
      externalResourceId: yup.string().required("Please create a live class"),
    }),
    ...(type === "Assessment" && {
      completionPercentage: yup.number().required("Please enter a percentage"),
      externalResourceId: yup.string().required("Please choose assessment"),
    }),
    ...(type === "Link" && {
      media: yup.string().required("Please enter content"),
    }),
  });

export const Form3Schema = yup.object().shape({
  overviews: yup
    .string()
    .required("Please enter valid overviews")
    .max(500, "Overviews must be at most 500 words")
    .test(
      "len",
      "Please enter valid overviews",
      (val) => !val.replace(/<(.|\n)*?>/g, "").trim().length < 1
    ),
  outcomes: yup
    .string()
    .required("Please enter valid outcomes")
    .max(500, "Outcomes must be at most 500 words")
    .test(
      "len",
      "Please enter valid outcomes",
      (val) => !val.replace(/<(.|\n)*?>/g, "").trim().length < 1
    ),
  requirements: yup
    .string()
    .required("Please enter valid requirements")
    .max(500, "Requirements must be at most 500 words")
    .test(
      "len",
      "Please enter valid requirements",
      (val) => !val.replace(/<(.|\n)*?>/g, "").trim().length < 1
    ),
});

export const Form5Schema = yup.object().shape({
  paidPackage: yup.boolean(),
  originalPrice: yup.number().when("paidPackage", {
    is: true,
    then: yup
      .number()
      .positive("Original price must be positive")
      .required("Please enter a valid original price"),
  }),
  // strikingPrice: yup.number().when("paidPackage", {
  //   is: true,
  //   then: yup
  //     .number()
  //     .required("Please enter a valid striking price")
  //     .lessThan(
  //       yup.ref("originalPrice"),
  //       "Striking price cannot be greater than original price"
  //     ),
  // }),
  // ...(isSA && {
  //   customerId: yup.string().required("Please choose a customer"),
  // }),
});
