import { useState, useEffect, useRef } from "react";
import styles from "./CompanyPackage.module.scss";
// import Footer from "../LoginPage/New/FooterCompanyPacakage";
import BannerTechruit from "pages/LoginPage/New/BannerTechruit";
import Marquee from "../LoginPage/New/Marquee";
import MarqueeTechruit from "pages/LoginPage/New/MarqueeTechruit";
import EmployeeTechruit from "pages/LoginPage/New/EmplyeeTechruit";
import TruTechruit from "pages/LoginPage/New/TruTechruit";
import Footer from "../LoginPage/New/Footer";
import TandC from "../LoginPage/New/TandC";
import SafePayments from "../LoginPage/New/SafePayments";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import FilterTechruit from "pages/LoginPage/New/FilterTechruit";
import DiscoverTechruit from "pages/LoginPage/New/DiscoverTechruit";
import CustomerTechruit from "pages/LoginPage/New/CustomerTechruit";
import HiringTechruit from "pages/LoginPage/New/HiringTechruit";
import AssessmentTechruit from "pages/LoginPage/New/AssessmentTechruit";
import ThreeStepAssessment from "pages/LoginPage/New/ThreeStepAssessment";


const Cprogramming = () => {

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isTab = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const divRef = useRef(null);

  const handleScrollClick = () => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.container} style={{ background: "#ffffff" }}>
      <BannerTechruit isMobile={isMobile} isTab={isTab} onCliclk={handleScrollClick}/>
      <MarqueeTechruit />
      <EmployeeTechruit isMobile={isMobile} isTab={isTab} />
      <div ref={divRef}>
      {/* <AssessmentTechruit isMobile={isMobile} isTab={isTab} /> */}
      <ThreeStepAssessment 
      />
      </div>
      {/* <TruTechruit isMobile={isMobile} isTab={isTab}/> */}
      <FilterTechruit />
      <CustomerTechruit  isMobile={isMobile} isTab={isTab}  />
      <DiscoverTechruit onCliclk={handleScrollClick}/>
      <HiringTechruit isMobile={isMobile} isTab={isTab}/>
     
     
    
  
      

      <Footer isMobile={isMobile} isTab={isTab} />
      <TandC isMobile={isMobile} isTab={isTab} />
      <SafePayments isMobile={isMobile} isTab={isTab}  />

    </div>
  );
};

export default Cprogramming;
