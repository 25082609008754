import { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Drawer from "@material-ui/core/Drawer";
import Profile from "../../../assets/images/users/default-avatar.svg";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ReplyIcon from "@material-ui/icons/Reply";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import apiHelper from "api/apiHelper";
import api from "../../../api/baseConfig";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useUserDetails } from "hooks/use-user-details";
import Snackbar from "@material-ui/core/Snackbar";
import Box from "@material-ui/core/Box";
import MuiAlert from "@material-ui/lab/Alert";
import { useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import emptyStateImg from "./emptyStateImg.svg";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  cardStyle: {
    maxWidth: "700px",
    backgroundColor: "#F6F6F7",
    border: " 1px solid #D8D6DB",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  replyCardStyle: {
    maxWidth: "650px",
    backgroundColor: "#F6F6F7",
    border: " 1px solid #D8D6DB",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
}));
function Alert(props) {
  return <MuiAlert elevation={4} variant="filled" {...props} />;
}

export default function ViewDiscussion(props, dataType) {
  const classes = useStyles();
  const userDetails = useUserDetails();
  const [editorStates, setEditorStates] = useState("");
  const { courseId } = useParams();
  const [open, setOpen] = useState(false);
  const [editorState, setEditorState] = useState("");
  const [courseResourceData, setCourseResourceData] = useState([]);
  const [viewDiscussion, setViewDiscussion] = useState(true);
  const [addDiscussion, setAddDiscussion] = useState(true);
  const [viewReplies, setViewReplies] = useState(false);
  const [commentBody, setCommentBody] = useState("");
  const [replyBody, setReplyBody] = useState();
  const [cdate, setDate] = useState(dt);
  const [buttonCondition, setButtonCondition] = useState("");
  const [expanded, setExpanded] = useState(false);
  // const discussionValue = {
  //   discussionId: props.discussionValue.discussionId,
  //   // discussionName: props.discussionValue.discussionName,
  //   // discussionModId: props.discussionValue.discussionModId,
  //   discussionsIcon: props.discussionValue.discussionsIcon,
  //   handleChangePage: props.handleChangePage,
  //   testModId: props.discussionValue.testModId,
  //   testModName: props.discussionValue.testModName,
  //   // setViewDiscussion: props.setViewDiscussion,
  // };
  // const addDiscussionElement = useRef(null);

  const backBtns = () => {
    props.show_view_dis();
  };

  const dt = null;
  const handelDate = () => {
    let dt = new Date().toLocaleDateString();
    setDate(dt);
  };

  const handleViewReplies = () => {
    setButtonCondition("addReplies");
    setViewReplies(!viewReplies);
  };

  const handleReplyTextArea = (e) => {
    setReplyBody(e.target.value);
  };
  const handleCommentTextArea = (e) => {
    setCommentBody(e.target.value);
  };

  useEffect(() => {
    getCourseResourceDiscussion();
    handelDate();
  }, []);

  const getCourseResourceDiscussion = async () => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.courseController.getCourseResourceDiscussion}${props.resource.id}`,
        "get"
      );
      if (data !== []) {
        setCourseResourceData(data);
      }
    } catch (e) {}
  };

  const handleAddDiscussion = () => {
    setButtonCondition("addDiscussion");
    setAddDiscussion(false);
    setCommentBody("");
  };
  const handleReplyDiscussion = () => {
    setReplyBody("");
  };

  const handleSaveNote = async () => {
    setAddDiscussion(true);
    const addDiscussion = {
      userId: userDetails.email,
      courseId: courseId,
      courseModuleId: props.resource.moduleId,
      courseResourceId: props.resource.id,
      isActive: true,
      courseResourceName: props.resource.resourceName,
      userName: userDetails.name,
      // discussionContent: editorState.getCurrentContent().getPlainText(),
      discussionContent: commentBody,
    };

    const notes = await apiHelper.axiosCallPostsave(
      `${api.baseURL}${api.courseController.saveDiscussion}`,
      "post",
      addDiscussion
    );
    if (notes !== "") {
      getCourseResourceDiscussion();
      setEditorState("");
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleReplySave = async (data) => {
    const addReplyDiscussion = {
      userId: userDetails.email,
      courseId: data.courseId,
      courseModuleId: data.courseModuleId,
      courseResourceId: data.courseResourceId,
      courseDiscussionId: data.id,
      isActive: true,
      courseResourceName: data.courseResourceName,
      userName: userDetails.name,
      discussionContent: replyBody,
      // discussionContent: editorStates.getCurrentContent().getPlainText(),
    };

    const notes = await apiHelper.axiosCallPostsave(
      `${api.baseURL}${api.courseController.saveDiscussion}`,
      "post",
      addReplyDiscussion
    );
    if (notes !== "") {
      setEditorStates("");
      getCourseResourceDiscussion();

      setEditorState("");
      setOpen(true);
    }
  };

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <>
      {viewDiscussion && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              padding: "16px 16px 12px 16px",
              borderBottom: "1px solid #D8D6DB",
            }}
          >
            <a onClick={backBtns}>
              <KeyboardBackspaceIcon /> Back to sections df
            </a>
          </div>
          <Box sx={{ flex: "1 1 0%", overflowY: "auto" }}>
            <div style={{ padding: "16px" }}>
              {props.dataType.map((dataTypes) => {
                {
                  dataTypes.moduleResources.map((dataTypeModule) => {
                    {
                      (dataTypeModule.resourceType.toLowerCase() ===
                        "Video".toLowerCase() ||
                        dataTypeModule.resourceType.toLowerCase() ===
                          "Link".toLowerCase()) && (
                        <a>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.0001 1.6665C5.40508 1.6665 1.66675 5.40484 1.66675 9.99984C1.66675 14.5948 5.40508 18.3332 10.0001 18.3332C14.5951 18.3332 18.3334 14.5948 18.3334 9.99984C18.3334 5.40484 14.5951 1.6665 10.0001 1.6665ZM10.0001 16.6665C6.32425 16.6665 3.33341 13.6757 3.33341 9.99984C3.33341 6.324 6.32425 3.33317 10.0001 3.33317C13.6759 3.33317 16.6667 6.324 16.6667 9.99984C16.6667 13.6757 13.6759 16.6665 10.0001 16.6665Z"
                              fill="#7C7887"
                            />
                            <path
                              d="M7.5 14.1668L14.1667 10.0002L7.5 5.8335V14.1668Z"
                              fill="#7C7887"
                            />
                          </svg>

                          <span className="text-muted">
                            {props.resource.resourceName}
                          </span>
                        </a>
                      );
                    }
                  });
                }
              })}

              {props.resource.resourceName}

              {courseResourceData.length > 0 ? (
                courseResourceData.map((resourceData, index) => {
                  return (
                    <div
                      className="mt-3"
                      style={{
                        border: "1px solid #E7E6E8",
                        borderRadius: "3px",
                      }}
                    >
                      <div>
                        {/* <Accordion
                  className={classes.accordionStyle}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                > */}

                        <ListItem style={{ alignItems: " flex-start" }}>
                          <ListItemAvatar>
                            <Avatar
                              alt={"name"}
                              src={Profile}
                              className={classes.large}
                              style={{ width: "24px", height: "24px" }}
                            />
                          </ListItemAvatar>

                          <ListItemText>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              className="discussionItemTitle"
                            >
                              <h4>{resourceData.userName}</h4>
                              <p>{cdate}</p>
                            </Box>
                            <div className="discussionItemBody mb-2">
                              {/* <h5> {props.resource.resourceName}</h5> */}
                              {resourceData.discussionContent}{" "}
                            </div>
                            {/* <AccordionSummary> */}
                            <Box
                              sx={{ display: "flex", gap: "16px" }}
                              className="discussionItemFooter"
                            >
                              {/* {resourceData.discussionReply.length > 0 && ( */}

                              <Button
                                variant="text"
                                style={{ color: "#2170D8", padding: "0" }}
                                href={`#multiCollapseExample_${index}`}
                                data-toggle="collapse"
                                aria-expanded="false"
                                // onClick={handleViewReplies}
                              >
                                View {resourceData.discussionReply.length}{" "}
                                replies
                              </Button>

                              {/* )} */}
                              <Button
                                variant="outlined"
                                style={{
                                  color: "#323036",
                                  padding: "1px 16px",
                                }}
                                href={`#multiCollapseExamples_${index}`}
                                data-toggle="collapse"
                                aria-expanded="false"
                                onClick={handleReplyDiscussion}
                              >
                                Reply
                              </Button>
                            </Box>
                            {/* </AccordionSummary> */}
                          </ListItemText>
                        </ListItem>
                        <div
                          className="collapse"
                          id={`multiCollapseExamples_${index}`}
                        >
                          <div className="card card-body">
                            <textarea
                              value={replyBody}
                              onChange={handleReplyTextArea}
                            ></textarea>{" "}
                          </div>
                          <button
                            type="button"
                            className="btn btn-success m-3 "
                            style={{ width: "104px" }}
                            onClick={() => handleReplySave(resourceData)}
                            data-toggle="collapse"
                            href={`#multiCollapseExamples_${index}`}
                            disabled={!replyBody}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary m-3"
                            style={{ width: "104px" }}
                            data-toggle="collapse"
                            href={`#multiCollapseExamples_${index}`}
                            onClick={(editorStates) => setEditorStates("")}
                          >
                            Cancel
                          </button>
                        </div>
                        <div
                          className="collapse"
                          id={`multiCollapseExample_${index}`}
                        >
                          {resourceData.discussionReply.length > 0 &&
                            resourceData.discussionReply.map((data, index) => {
                              return (
                                // <AccordionDetails>
                                <div className="menu">
                                  <div className="ml-5">
                                    <div>
                                      <ListItem style={{ alignItem: "center" }}>
                                        <ListItemAvatar
                                          style={{
                                            minWidth: "40px",
                                          }}
                                        >
                                          <Avatar
                                            alt={"name"}
                                            src={Profile}
                                            className={classes.large}
                                            style={{
                                              width: "32px",
                                              height: "32px",
                                            }}
                                          />
                                        </ListItemAvatar>
                                        <ListItemText>
                                          <h4
                                            style={{
                                              fontSize: "14px",
                                              margin: "0",
                                            }}
                                          >
                                            {data.userName}
                                          </h4>
                                        </ListItemText>
                                      </ListItem>

                                      <ListItem>
                                        <ListItemText>
                                          <p style={{ fontSize: "12px" }}>
                                            {data.discussionContent}{" "}
                                          </p>
                                        </ListItemText>
                                      </ListItem>
                                    </div>

                                    {/* </Card> */}
                                  </div>
                                </div>
                                // </AccordionDetails>
                              );
                            })}
                        </div>
                        {/* </Accordion> */}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <div style={{ padding: "20% 0 0 30%" }}>
                    <img src={emptyStateImg} alt="no found" />
                  </div>
                  <p style={{ textAlign: "center" }}>No discussion available</p>
                  <p style={{ textAlign: "center" }}>
                    Click the button below to discussion notes
                  </p>
                </div>
              )}

              {/* <div className="text-center">View 6 more replys <ExpandMoreIcon /></div> */}

              {/* <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  style={{ backgroundColor: "#794df5" }}
                >
                  Course Discussion Created Successfully
                </Alert>
              </Snackbar> */}
            </div>
          </Box>
          <div className="collapse" id="collapseExample">
            <div className="card card-body" style={{ margin: "0" }}>
              {props.resource.resourceName}
              <textarea
                style={{ height: "150px" }}
                value={commentBody}
                onChange={handleCommentTextArea}
              ></textarea>
            </div>
            <button
              type="button"
              className="btn btn-success m-3 "
              style={{ width: "104px" }}
              onClick={handleSaveNote}
              data-toggle="collapse"
              href="#collapseExample"
              disabled={!commentBody}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary m-3"
              style={{ width: "104px" }}
              data-toggle="collapse"
              href="#collapseExample"
              onClick={() => setAddDiscussion(true)}
            >
              Cancel
            </button>
          </div>
          <div
            style={{
              padding: "12px 16px 16px 16px",
              borderTop: "1px solid #D8D6DB",
              textAlign: "center",
            }}
          >
            {addDiscussion && (
              <Button
                // ref={addDiscussionElement}
                variant="filled"
                data-toggle="collapse"
                href="#collapseExample"
                style={{
                  whiteSpace: "nowrap",
                  backgroundColor: "#794DF5",
                  color: "#fff",
                  textTransform: "none",
                  width: "321px",
                }}
                startIcon={<AddIcon />}
                onClick={handleAddDiscussion}
              >
                Add new discussion
              </Button>
            )}
          </div>
        </Box>
      )}
    </>
  );
}
