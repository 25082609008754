import React, { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Rating from "@material-ui/lab/Rating";
import LinearProgress from "@material-ui/core/LinearProgress";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import { PriceContainer } from "pages/LoginPage/New/PriceContainer";
import * as CustomIcons from "../../assets/icons/icons";
import styles from "./Prepare.module.scss";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "./prepares.css";
import { useTranslation } from "react-i18next";
import { NoFound } from "assets/icons/icons";
// import { sessionPrivileges } from "privileges";

const TabScrollButton = forwardRef((props, ref) => {
  const { direction, ...other } = props;

  return (
    <ButtonBase
      component="div"
      ref={ref}
      style={{ opacity: other.disabled ? 0 : 1 }}
      {...other}
    >
      {direction === "left" ? (
        <ArrowBackIosIcon
          fontSize="small"
          className="arrow-icons"
          style={{ fill: "black" }}
        />
      ) : (
        <ArrowForwardIosIcon
          fontSize="small"
          className="arrow-icons"
          style={{ fill: "black" }}
        />
      )}
    </ButtonBase>
  );
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    width: 320,
    boxShadow: "0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08)",
    borderRadius: 5,
    position: "relative",
    height: 376,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
      transform: "scale(1.03)",
      boxShadow: "0 12px 24px rgba(0, 0, 0, 0.16), 0 6px 6px rgba(0, 0, 0, 0.08)",
        },
  },
  media: {
    height: "150px",
  },
  colorPrimary: {
    backgroundColor: "#DFDFDF",
  },
  barColorPrimary: {
    backgroundColor: "#F56F18;",
  },
  buttonWidth: {
    width: "100%",
  },
  priceTag: {
    display: "inline-flex",
    inlineSize: "-webkit-fill-available",
    position: "relative",
    margin: 3,
  },
  hours: {
    color: "#4A4851",
    marginBottom: "5px",
  },
  chapter: {
    marginRight: "12px",
  },

  grid: {
    padding: "0px !important",
  },
  free: {
    fontSize: 22,
    fontWeight: "bold",
  },
  completedChapter: {
    fontSize: 14,
  },
}));

export default function VariousCourse({ topPackages, searchTerm }) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [searchValue, setSearchValue] = useState([]);
  const history = useHistory();
  // const Priv = sessionPrivileges().userprivileges || [];
  const { t } = useTranslation();

  const handlePageRedirect = (data) =>
    history.push(`/course-overview/${data.id}`);

  const categoriesOnly = topPackages.reduce((acc, val) => {
    if (
      val.category &&
      !acc.includes(val.category) &&
      val.status == "NotStarted"
    )
      acc.push(val.category);
    return acc;
  }, []);

  const currentPackages = topPackages.reduce((acc, val) => {
    if (tabValue === 0) acc.push(val);
    else if (val.category === categoriesOnly[tabValue - 1]) acc.push(val);
    return acc;
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (searchTerm)
        setSearchValue(
          currentPackages.reduce((acc, val) => {
            if (val.name.includes(searchTerm)) acc.push(val);
            return acc;
          }, [])
        );
    }, [500]);
  }, [searchTerm]);

  const handleChange = (_, newValue) => setTabValue(newValue);
  const checkPrepareFile =
    (searchTerm ? searchValue : currentPackages).filter(
      (e) => e.status == "NotStarted"
    ).length == 0;
  return (
    <React.Fragment>
      <div className="course141">
        <AppBar position="static" className="various-course" elevation={0}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable auto tabs example"
            ScrollButtonComponent={TabScrollButton}
          >
            <Tab
              label="All courses"
              style={{ textTransform: "capitalize" }}
              {...a11yProps(0)}
            />
            {categoriesOnly.map((label, i) => (
              <Tab
                key={`categoriesOnly${i}`}
                label={label}
                style={{ textTransform: "capitalize" }}
                {...a11yProps(i + 1)}
              />
            ))}
          </Tabs>
        </AppBar>
        {checkPrepareFile && (
          <div className="centerAlign pt-5 bg-white">
            <Card style={{ border: "none", boxShadow: "none" }}>
              <CardContent style={{ flex: "1 1 0%" }}>
                <div className="centerAlign">{NoFound}</div>
                <p className="cardHeader">No courses found</p>
              </CardContent>
            </Card>
          </div>
        )}
        <TabPanel
          value={tabValue}
          index={tabValue}
          className={`bg-white ${classes.grid}`}
        >
          <div
            className="cardGrid_Course"
            style={
              checkPrepareFile ? { paddingTop: "0px" } : { paddingTop: "32px" }
            }
          >
            {(searchTerm ? searchValue : currentPackages).length > 0 &&
              (searchTerm ? searchValue : currentPackages)
                .filter((e) => e.status == "NotStarted")
                .map((data, index) => {
                  return (
                    <Card key={data.id} className={classes.root}>
                      <CardMedia
                        className={classes.media}
                        image={data.media}
                        title={data.name}
                      />
                      <div className={styles.courseTagName}>
                        {data.courseTags === "premium"
                          ? CustomIcons.premiumIcon
                          : data.courseTags === "special"
                          ? CustomIcons.specialIcon
                          : data.courseTags === "latest"
                          ? CustomIcons.latestIcon
                          : data.courseTags === "feature"
                          ? CustomIcons.feturedIcon
                          : ""}
                      </div>
                      <CardContent style={{ flex: "1 1 0%" }}>
                        <h2 className="course-name">{data.name}</h2>
                        <Grid container spacing={0}>
                          <Grid item xs={6} sm={6}>
                            <div className={classes.hours}>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="hours-svg"
                              >
                                <g clipPath="url(#clip0)">
                                  <path
                                    d="M8.00016 2.33331C4.32416 2.33331 1.3335 5.32398 1.3335 8.99998C1.3335 12.676 4.32416 15.6666 8.00016 15.6666C11.6762 15.6666 14.6668 12.676 14.6668 8.99998C14.6668 5.32398 11.6762 2.33331 8.00016 2.33331ZM8.00016 14.3333C5.0595 14.3333 2.66683 11.9406 2.66683 8.99998C2.66683 6.05931 5.0595 3.66665 8.00016 3.66665C10.9408 3.66665 13.3335 6.05931 13.3335 8.99998C13.3335 11.9406 10.9408 14.3333 8.00016 14.3333Z"
                                    fill="#7C7887"
                                  />
                                  <path
                                    d="M8.66683 5.66669H7.3335V9.66669H11.3335V8.33335H8.66683V5.66669Z"
                                    fill="#7C7887"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect width="16" height="16" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>

                              <span className="duration">
                                {data.duration} 
                                {data.duration <= 1 ? " Hour" : " Hours"}
                              </span>
                            </div>
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <div style={{ whiteSpace: "nowrap" }}>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="hours-svg"
                              >
                                <path
                                  d="M1.3335 4.00002C1.3335 4.00002 2.3335 2.66669 4.66683 2.66669C7.00016 2.66669 8.00016 4.00002 8.00016 4.00002V13.3334C8.00016 13.3334 7.00016 12.6667 4.66683 12.6667C2.3335 12.6667 1.3335 13.3334 1.3335 13.3334V4.00002Z"
                                  stroke="#7C7887"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8 4.00002C8 4.00002 9 2.66669 11.3333 2.66669C13.6667 2.66669 14.6667 4.00002 14.6667 4.00002V13.3334C14.6667 13.3334 13.6667 12.6667 11.3333 12.6667C9 12.6667 8 13.3334 8 13.3334V4.00002Z"
                                  stroke="#7C7887"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <span
                                className="duration"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {" "}
                                {data.numberOfModules} 
                                {data.numberOfModules <= 1 ? " Chapter" : " Chapters"}
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={0} className="pb-1">
                          <Grid item xs={6} sm={6}>
                            <Rating
                              name="size-small"
                              defaultValue={
                                data.courseRating ? data.courseRating : 0
                              }
                              size="small"
                              readOnly
                            >
                              {/* {4} */}
                            </Rating>
                            <span className="text-muted continue-rating">
                              {data.courseRating ? data.courseRating : ""}
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div style={{ whiteSpace: "nowrap" }}>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.6665 4H9.99984V11.3333H8.6665V4ZM11.3332 2H12.6665V11.3333H11.3332V2ZM5.99984 6H7.33317V11.3333H5.99984V6ZM2.6665 12.6667H13.3332V14H2.6665V12.6667ZM3.33317 8H4.6665V11.3333H3.33317V8Z"
                                  fill="#7C7887"
                                />
                              </svg>
                              <span
                                className="level"
                                style={{ textTransform: "capitalize" }}
                              >
                                {" "}
                                {data.level}
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                        <Divider />
                        <>
                          {data.status === "Inprogress" ? (
                            <>
                              <Box
                                display="flex"
                                alignItems="center"
                                marginTop="4px"
                              >
                                <Box width="100%" mr={1}>
                                  <LinearProgress
                                    value={data.progress}
                                    variant="determinate"
                                    classes={{
                                      colorPrimary: classes.colorPrimary,
                                      barColorPrimary: classes.barColorPrimary,
                                    }}
                                  />
                                </Box>
                                <Box minWidth={35}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {`${Math.round(data.progress)}%`}
                                  </Typography>
                                </Box>
                              </Box>
                              <Typography
                                variant="p"
                                className={classes.completedChapter}
                              >
                                {`${data.modulesCompleted}/${data.numberOfModules}`}{" "}
                                chapters completed
                              </Typography>
                            </>
                          ) : (
                            <>
                              {data?.paidPackage ? (
                                !data?.purchased ? (
                                
                                  <div>
                                      <PriceContainer
                                    originalPrice={data.originalPrice}
                                    strikingPrice={data.strikingPrice}
                                  />
                                  </div>
                                ) : (
                                  <>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      marginTop="4px"
                                    >
                                      <Box width="100%" mr={1}>
                                        <LinearProgress
                                          value={data.progress}
                                          variant="determinate"
                                          classes={{
                                            colorPrimary: classes.colorPrimary,
                                            barColorPrimary:
                                              classes.barColorPrimary,
                                          }}
                                        />
                                      </Box>
                                      <Box minWidth={35}>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                        >
                                          {`${Math.round(data.progress)}%`}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Typography
                                      variant="p"
                                      className={classes.completedChapter}
                                    >
                                      {`${data.modulesCompleted}/${data.numberOfModules}`}{" "}
                                      chapters completed
                                    </Typography>
                                  </>
                                )
                              ) : (
                                // <PriceContainer
                                //   originalPrice={data.originalPrice}
                                //   strikingPrice={data.strikingPrice}
                                // />
                                <div style={{
                                  paddingTop:"5%",
                                  textAlign:"center",
                                }}>
                                 <Typography
                                variant="p"
                                className={classes.free}
                              >
                               
                                Free
                                
                              </Typography></div>
                                
                              )}
                            </>
                          )}
                        </>
                      </CardContent>
                      {/* {Priv.includes("START_PREPARE_STUDENT") && ( */}
                      <CardActions>
                        <Button
                          variant="outlined"
                          className={
                            data.status === "Inprogress"
                              ? "continue-button"
                              : data.status === "NotStarted"
                              ? "preview-button"
                              : "continue-button"
                          }
                          onClick={() => handlePageRedirect(data)}
                        >
                          {data.status === "Inprogress"
                            ? t("Continue learning")
                            : data.status === "NotStarted"
                            ? t("Preview")
                            : t("Completed")}
                        </Button>
                      </CardActions>
                      {/* )} */}
                    </Card>
                  );
                })}
          </div>
        </TabPanel>
      </div>
    </React.Fragment>
  );
}
