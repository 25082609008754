import React, { useState, useEffect } from "react";
import "./QuestionForm.scss";
import { Col, Label } from "reactstrap";

const validIndicator = <span style={{ color: "red" }}>*</span>;

export default function NumberInput({ label, len, showValid, ...props }) {
  const [value, setValue] = useState("");
  const [count, setCount] = useState(0);


  const handleChange = (val) => {
    if (val === "plus") {
      let foo = +value + 1;
      props.input.onChange(foo);
      setValue(foo);
    } else if (val === "minus") {
      let foo = +value - 1;
      if(foo > -1) {
      props.input.onChange(foo);
      setValue(foo);
      }
      else if (props?.checkNegative === true) {
        props.input.onChange(foo);
        setValue(foo);
      }
    } else {
      let foo = +val;
      props.input.onChange(foo);
      setValue(foo);
    }
  };

  useEffect(() => {
    if (!count && props?.input?.value) {
      setValue(props?.input?.value);
      setCount((c) => c + 1);
    }
  }, [props?.input?.value]);
  return (
    <Col lg={len}>
      <Label>
        {label}
        {showValid && validIndicator}
      </Label>
      <div className="input-group inline-group" style={{ padding: "0px" }}>
        <div className="input-group-prepend">
          <div
            className="btn"
            style={{ backgroundColor: "#F5F9FB", border: "1px solid lavender" }}
            onClick={(e) => {
              e.preventDefault();
              handleChange("minus");
            }}
            disabled={props?.disabled}
          >
            <i className="fa fa-minus"></i>
          </div>
        </div>
        <input
          className="form-control quantity numberInput"
          name={props?.input?.name}
          onChange={(e) => handleChange(e.target.value)}
          value={props?.checkNegative === true && value > 100 ? 0 : value}
          type="number"
          disabled={props?.disabled}
        />

        <div className="input-group-append" style={{display: props?.checkNegative === true ? "none" : "block" }}>
          <div
            className="btn"
            onClick={(e) => {
              e.preventDefault();
              handleChange("plus");
            }}
            style={{ backgroundColor: "#F5F9FB", border: "1px solid lavender" }}
            disabled={props?.disabled}
          >
            <i className="fa fa-plus"></i>
          </div>
        </div>
        {props.error && <span style={{ color: "red" }}>{props.error} </span>}
      </div>
    </Col>
  );
}
