import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { Button } from "reactstrap";
import { InputField } from "../QuestionWizard/InputField";
import { CloseOutlined } from "@material-ui/icons";
import animation from "./ChangePasswordModal.module.scss";
import styles from "../CustomerManagement/BulkUserUpload.module.scss";

function ChangePasswordModal(props) {
  return (
    <>
      <Dialog open={props.isOpen} onClose={props.close}>
        <DialogTitle classes={{ root: styles.header }}>
          Change Password
          <CloseOutlined
            classes={{ root: styles.closeButton }}
            onClick={props.close}
          />
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              width: 400,
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <InputField
              type="password"
              id="oldpwd"
              label="Old Password"
              value={props.oldPassword}
              changeFunc={(value) => props.updateState(value, "oldPassword")}
              onBlur={props.checkCurrentPassword}
              validation={(err) => props.updateErrors(err, "oldPassword")}
              validationMessage="Please enter a valid Old Password"
              error={props.errors.oldPassword}
              len={12}
            />
            {/* {props.oldPasswordIsCorrect === "not_found" ? (
              <span style={{ color: "red", marginLeft: 19 }}>
                You've entered the wrong password.
              </span>
            ) : props.oldPasswordIsCorrect === "found" ? (
              <span style={{ color: "green", marginLeft: 19 }}>
                Password validated!
              </span>
            ) : (
              ""
            )} */}

            <InputField
              type="password"
              id="pwd"
              label="New Password"
              // disabled={
              //   props.oldPasswordIsCorrect === "not_found" ||
              //   !props.oldPasswordIsCorrect
              // }
              value={props.newPassword}
              changeFunc={(value) => props.updateState(value, "newPassword")}
              validation={(err) => props.updateErrors(err, "newPassword")}
              validationMessage="Please enter a valid New Password"
              error={props.errors.newPassword}
              len={12}
            />
            <InputField
              type="password"
              id="cnpwd"
              label="Confirm Password"
              // disabled={
              //   props.oldPasswordIsCorrect === "not_found" ||
              //   !props.oldPasswordIsCorrect
              // }
              value={props.confirmPassword}
              changeFunc={(value) =>
                props.updateState(value, "confirmPassword")
              }
              validation={(err) => props.updateErrors(err, "confirmPassword")}
              validationMessage="Passwords don't match"
              error={props.errors.confirmPassword}
              pwdValue={props.newPassword}
              len={12}
            />
          </div>
          <hr />
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            disabled={
              !(
                props.newPassword && props.newPassword === props.confirmPassword
              )
            }
            onClick={props.update}
          >
            Update
          </Button>
          <Button color="primary" outline onClick={props.close}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ChangePasswordModal;
