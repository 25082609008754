import React from "react";
import { RupeeSymbol } from "assets/icons/icons";
import styles from "./PriceContainer.module.scss";
import { CalculateDiscount } from "./../../utils/CalculateDiscount";

interface Props {
  originalPrice: string;
  strikingPrice: string;
  paidPackage: Boolean;
}

export const PriceContainerPrepare: React.FC<Props> = ({
  strikingPrice,
  originalPrice,
  paidPackage,
}) => {
  const discountPrice = CalculateDiscount(
    +originalPrice,
    +strikingPrice
  ).toFixed();

  return (
    <div className={styles.priceContainer}>
      <div>
        {originalPrice == "" ? "" :
          paidPackage && <RupeeSymbol size={{ width: 13, height: 19 }} color="#000" />
        }
        <span className={styles.price}>
          {paidPackage ? (+strikingPrice == 0 ? originalPrice == "" ? "Free" : originalPrice + "INR": strikingPrice) : 'Free'} 
        </span>
      </div>
      {(paidPackage && +strikingPrice != 0) && (
        <div>
          <RupeeSymbol size={{ width: 10, height: 14 }} color="#F91818" />
          <span className={styles.priceStriked}>{originalPrice} INR</span>
          <span>(save {discountPrice === "NaN" ? 0 : discountPrice} %)</span>
        </div>
      )}
    </div>
  );
};