import cn from "classnames";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { CollapsibleSummarySection } from "../collapsible-summary-section/collapsible-summary-section";
import { QuestionButton } from "../question-button/question-button";
import { TProps } from "./summary.props";
import styles from "./summary.module.scss";

export function Summary(props: TProps) {
  return (
    <Dialog
      classes={{
        container: cn(
          styles.summaryContainer,
          props.smallScreen && styles.smallScreen
        ),
        root: cn(styles.summaryRoot, props.smallScreen && styles.smallScreen),
        paper: cn(styles.summary, props.smallScreen && styles.smallScreen),
      }}
      onClose={() => {
        if (!props.submittingTest) {
          props.setIfOpen(false);
        }
      }}
      open={props.open}
    >
      <DialogTitle
        style={{
          backgroundColor: "#fff",
          borderBottom: "1px solid #ddd",
          textAlign: "center",
        }}
      >
        {props.gradingOnProcess
          ? "Grading on Process..."
          : "Assessment Summary"}
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: "#fff",
          padding: "0 0px 24px 0px",
        }}
      >
        {props.gradingOnProcess ? (
          <Box paddingX={2}>
            <br />
            <Grid container alignItems="center" justifyContent="center">
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Player
                  autoplay
                  loop
                  style={{
                    height: "180px",
                    marginBottom: -30,
                    marginTop: -30,
                  }}
                  src="https://assets9.lottiefiles.com/temp/lf20_csjVxr.json"
                />
                <Typography>Your assessment is being graded.</Typography>
                <p />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <>
            <br />
            <Box paddingX={2}>
              {props.smallScreen ? (
                <Grid
                  container
                  wrap="nowrap"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: 8,
                    display: "grid",
                    gap: 32,
                    gridTemplateColumns: "1fr 1fr 1fr",
                    overflowX: "auto",
                    margin: "auto",
                    padding: 24,
                    width: "100%",
                  }}
                >
                  <Grid
                    direction="row"
                    container
                    wrap="nowrap"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      margin: 0,
                    }}
                  >
                    <Typography variant="h5" noWrap>
                      {props.numFlaggedQuestions}
                    </Typography>
                    <Box my={1} />
                    <Grid
                      direction="row"
                      container
                      wrap="nowrap"
                      style={{
                        alignItems: "center",
                        display: "inline-flex",
                        margin: 0,
                        width: "auto",
                      }}
                    >
                      <QuestionButton questionNum={0} flagged />
                      <Typography
                        style={{
                          fontSize: 14,
                          marginLeft: 12,
                        }}
                        noWrap
                      >
                        Flagged
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    container
                    wrap="nowrap"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      margin: 0,
                    }}
                  >
                    <Typography variant="h5" noWrap>
                      {props.numAnsweredQuestions}
                    </Typography>
                    <Box my={1} />
                    <Grid
                      direction="row"
                      container
                      wrap="nowrap"
                      style={{
                        alignItems: "center",
                        display: "inline-flex",
                        margin: 0,
                        width: "auto",
                      }}
                    >
                      <QuestionButton questionNum={0} answered />
                      <Typography
                        style={{
                          fontSize: 14,
                          marginLeft: 12,
                        }}
                        noWrap
                      >
                        Answered
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    container
                    wrap="nowrap"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      margin: 0,
                    }}
                  >
                    <Typography variant="h5" noWrap>
                      {props.numQuestions - props.numAnsweredQuestions}
                    </Typography>
                    <Box my={1} />
                    <Grid
                      direction="row"
                      container
                      wrap="nowrap"
                      style={{
                        alignItems: "center",
                        display: "inline-flex",
                        margin: 0,
                        width: "auto",
                      }}
                    >
                      <QuestionButton questionNum={0} />
                      <Typography
                        style={{
                          fontSize: 14,
                          marginLeft: 12,
                        }}
                        noWrap
                      >
                        Not Answered
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  wrap="nowrap"
                  style={{
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: 8,
                    display: "flex",
                    overflowX: "auto",
                    margin: "auto",
                    padding: 24,
                    width: "100%",
                  }}
                >
                  {Object.values(props.sections)
                    .map(({ questions }) => questions.map(({ id }) => id))
                    .map((questions, idx) => (
                      <Grid
                        direction="column"
                        container
                        wrap="nowrap"
                        style={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          margin: "0px 12px",
                        }}
                      >
                        <Typography variant="h5" noWrap>
                          {`${
                            Object.entries(props.answeredQuestions).filter(
                              (item) => questions.includes(item[0])
                            ).length
                          }`}
                          &nbsp;/&nbsp;
                          {`${questions?.length}`}
                        </Typography>
                        <Typography
                          variant="body1"
                          noWrap
                          style={{
                            marginTop: 12,
                            marginBottom: -4,
                          }}
                        >
                          {props?.sectionIds?.[idx]}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              )}
            </Box>
            {props.sectionIds.map((sectionId) => (
              <div className={styles.summarySection} key={sectionId}>
                <CollapsibleSummarySection
                  sectionId={sectionId}
                  alignTitleLeft
                  totalQuestions={
                    props.sections?.[sectionId]?.questions?.length
                  }
                  smallScreen={props.smallScreen}
                  alwaysOpen={!props.smallScreen}
                >
                  <div className={styles.summaryQuestionsContainer}>
                    {props.sections?.[sectionId]?.questions?.map?.(
                      ({ id: questionId }, idx) => (
                        <QuestionButton
                          sectionId={sectionId}
                          key={questionId}
                          large
                          questionNum={idx + 1}
                          currentQuestion={
                            props.currentQuestion.num === idx + 1 &&
                            props.currentSection.id === sectionId
                          }
                          flagged={props.flaggedQuestions?.[questionId]}
                          answered={props.answeredQuestions?.[questionId]}
                        />
                      )
                    )}
                  </div>
                </CollapsibleSummarySection>
              </div>
            ))}
          </>
        )}
      </DialogContent>
      {!props.gradingOnProcess && (
        <DialogActions
          classes={{
            root: cn(
              styles.summaryFooter,
              props.smallScreen && styles.smallScreen
            ),
          }}
        >
          <Typography align="center">
            Are you sure you want to submit the test?
          </Typography>
          <Box my={1.5} />
          <Grid
            container
            direction={props.smallScreen ? "column" : "row"}
            style={{
              justifyContent: "center",
              alignItems: "center",
              margin: 0,
            }}
          >
            <Button
              color="primary"
              disabled={props.submittingTest}
              fullWidth={props.smallScreen}
              onClick={() => {
                props.submitAssessment(false, false);
              }}
              size="large"
              style={{
                maxWidth: 320,
                minWidth: 160,
              }}
              variant="contained"
            >
              {props.submittingTest ? (
                <CircularProgress size={18} />
              ) : (
                "Submit test"
              )}
            </Button>
            <Box mx={1} my={1} />
            <Button
              color="primary"
              disabled={props.submittingTest}
              fullWidth={props.smallScreen}
              size="large"
              style={{
                maxWidth: 320,
                minWidth: 160,
              }}
              onClick={() => {
                props.setIfOpen(false);
              }}
              variant="outlined"
            >
              Continue test
            </Button>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
}
