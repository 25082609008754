
import MentorComponent from '../MentorComponent'
const Slider = () => {

    const list = [
        {
            image : "https://img.freepik.com/premium-photo/ganjaman-coffee-shop_739292-31204.jpg",
            name  : "Siva",
            companyName : "Terv",
            subject : "JAVA",
        },
        {
            image : "https://preview.redd.it/ironman-drawn-by-me-using-colored-pencils-v0-hvhlbmna6vid1.jpeg?width=640&crop=smart&auto=webp&s=c20ec9f9d9437c887294549504edcd312e9e5fb8",
            name  : "Siva",
            companyName : "Terv",
            subject : "REACT"
        },
        {
            image : "img",
            name  : "Siva",
            companyName : "Terv",
            subject : "REACT"
        },
        {
            image : "img",
            name  : "Siva",
            companyName : "Terv",
            subject : "REACT"
        },
        {
            image : "https://img.freepik.com/premium-photo/ganjaman-coffee-shop_739292-31204.jpg",
            name  : "Siva",
            companyName : "Terv",
            subject : "JAVA",
        },
        {
            image : "https://preview.redd.it/ironman-drawn-by-me-using-colored-pencils-v0-hvhlbmna6vid1.jpeg?width=640&crop=smart&auto=webp&s=c20ec9f9d9437c887294549504edcd312e9e5fb8",
            name  : "Siva",
            companyName : "Terv",
            subject : "REACT"
        },
        {
            image : "img",
            name  : "Siva",
            companyName : "Terv",
            subject : "REACT"
        },
        {
            image : "img",
            name  : "Siva",
            companyName : "Terv",
            subject : "REACT"
        },
    ]

  return (
    <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width : "100%",
        backgroundColor : "white",
        margin : "40px 0px",
        padding : "40px",
        borderRadius : "80px",
    }}>
        {
            list.map((item)=>{
                return (
                <MentorComponent 
                    companyName={item.companyName}
                    imageUrl={item.image}
                    name={item.name}     
                    subject={item.subject}              
                />)
            })
        }
    </div>
  )
}

export default Slider
