import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';

import { TrimString } from "../utils/TrimString";
import { toast, ToastContainer } from "react-toastify";
import ResetPasswordModel from "../../pages/CustomerManagement/ResetPasswordModel";
import ChangePasswordModel from "../../pages/Authentication/ChangePasswordModal";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// API
import Apihelper from "../../api/apiHelper";
import api from "../../api/baseConfig";

import avatar from "../../assets/images/users/default-avatar.svg";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// css
import "./UserProfile.css";
import useStyles from  "./UserProfileStyles.js";

// import ImageCropper from "components/ImageCropper";
// import { ProfileCompletion } from "./ProfileCompletetion";

// import { sessionPrivileges } from "privileges";

function UserProfile(props) {
  // const privileges = sessionPrivileges();
  // const userprivileges = privileges.userprivileges || [];
  const [userEmail, setUserEmail] = useState("");
  const [user, setUser] = useState({
    id: "",
    firstName: "",
    lastName: "",
    gender: "",
    phoneNumber: "",
    emailId: "",
    customer: {name: ""},
  });
  const [passChangeId, setPassChangeId] = useState("");
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalTable, setModalTable] = useState(false);
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    newPassword: "",
  });
  
  const css = useStyles();

  const getUserInfo = async(email) => {
    try {
      const data = await Apihelper.axiosCallNoUserId(
        `${api.baseURL}${api.userManagement.getUserInfo}${email}`,
        "get"
      );
      if (data && data.gender && data.gender !== "") {
        setUser({...user, gender: data.gender, phoneNumber: data.phoneNumber});
      }
    } catch (e) {
      console.log("Error: while feaching user infomation.")
      console.log(e);
    }
  }
  
  useEffect(()=>{
    if(user.emailId !== "") getUserInfo(user.emailId)
  },[user.emailId]);
  
  useEffect(()=>{
    const tempUser = props.UserData;
    if(tempUser && tempUser.id)
      setUser({...tempUser});
  },[props.UserData]);

  const updateUserPassword = async () => {
    const email = passChangeId;
    const url = `${api.baseURL}${
      api.userManagement.resetPassword
    }${user.emailId}/${password.newPassword}/${password.confirmPassword}`;
    await Apihelper.axiosCallPost(TrimString(url), "put")
    .then((data) => {
      if (data) {
        toast.success("Password reseted successfully", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
        setModal(false);
      } else {
        toast.error("There was error", {
          position: "bottom-center",
          autoClose: 300000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      }
    });
  };

  return (
    <React.Fragment>
       <ResetPasswordModel
        isOpen={modal}
        onClose={() => {
          setModal(false);
          setPassword({ newPassword: "", confirmPassword: "" });
          // setModalTable(false);
        }}
        newPassword={password.newPassword}
        confirmPassword={password.confirmPassword}
        updateState={(newValue, type) =>
          setPassword({
            ...password,
            [type]: newValue,
          })
        }
        updateErrors={(error, type) => setErrors({ [type]: error })}
        errors={errors}
        update={(e) => {
          e.preventDefault(), updateUserPassword();
          setPassword({ newPassword: "", confirmPassword: "" });
        }}
      />
      <div id="profile-page">
        <div className="profile-container">
          <div className="profile-hero ">
            <div className="hero-img blur" style={{backgroundImage: "url('/assets/profile-hero-img.png')"}}></div>
          </div>
          <div className="profile-body">
            <div className="profile-header">
              <div className="flex-box flex-100 flex-bottom">
                <div className="flex-50 flex-box">
                  <div className="flex-auto">
                    <div className="profile-image">
                      <img
                        src={avatar}
                        alt={user.id && `${user.firstName} ${user.lastName}`}
                        className="rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="profile-info">
                      <h3>{user.id && `${user.firstName} ${user.lastName}`}</h3>
                      <p>{user.id && `${user.emailId}`}</p>
                    </div>
                  </div>
                  <div className="flex-1"></div>
                </div>
                <div className="flex-50 flex-box">
                  <div className="flex-auto auto">
                    <Button variant="contained" className={css.buttonPrimary}
                      disableElevation onClick={() => props.history.push("/edit-user-profile") }>
                      Update profile
                    </Button>
                    <p style={{color: "#A6A6AA", marginTop: "8px", textAlign: "center"}}>{`45% profile updated`}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="br"></div>
            <div className="profile-content">
              <div className="flex-box flex-100 flex-gap-2">
                <div className="flex-1">
                  <div className="profile-item">
                    <h5>Customer</h5>
                    <p>{user.customer.name}</p>
                  </div>
                  <div className="profile-item">
                    <h5>Gender</h5>
                    <p>{user.gender}</p>
                  </div>
                  <div className="profile-item">
                    <div className="flex-box flex-100 flex-middle flex-gap-2">
                      <div className="flex-1">
                        <h5>Mobile number</h5>
                        <p>+91 {user.phoneNumber}</p>
                      </div>
                      <div className="flex-1 flex-self-center center">
                        <Button variant="outlined" className={css.buttonSuccess} disableElevation disabled>
                          Verify mobile
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="profile-item">
                    <div className="flex-box flex-100 flex-middle flex-gap-2">
                      <div className="flex-1">
                        <h5>Email</h5>
                        <p>{user.emailId}</p>
                      </div>
                      <div className="flex-1 flex-self-center center">
                        <Button variant="outlined" className={css.buttonSuccess} disableElevation disabled>
                          Verify email
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="profile-item">
                    <div className="flex-box flex-100 flex-middle flex-gap-2">
                      <div className="flex-1">
                        <h5>Password</h5>
                        <p>***************</p>
                      </div>
                      <div className="flex-1 flex-self-center center">
                        <Button variant="text" color="primary" className={css.buttonText} onClick={() => setModal(true)}>
                          Change password
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex-box">
                  <div className="auto">
                    <div className="profile-feature-item success flex-box flex-100 flex-middle flex-gap-4">
                      <div className="flex-1">
                        <p>Personal info</p>
                      </div>
                      <div className="">
                        <div className="item-status flex-box flex-middle flex-gap-1">
                          <CheckCircleIcon />
                          <p>Complete</p>
                        </div>
                      </div>
                    </div>
                    <div className="profile-feature-item success flex-box flex-100 flex-middle flex-gap-4">
                      <div className="flex-1">
                        <p>Education details</p>
                      </div>
                      <div className="">
                        <div className="item-status flex-box flex-middle flex-gap-1">
                          <CheckCircleIcon />
                          <p>Complete</p>
                        </div>
                      </div>
                    </div>
                    <div className="profile-feature-item pending flex-box flex-100 flex-middle flex-gap-4">
                      <div className="flex-1">
                        <p>Skills, interest and profile</p>
                      </div>
                      <div className="">
                        <div className="item-status flex-box flex-middle flex-gap-1">
                          <CheckCircleIcon />
                          <p>Pending</p>
                        </div>
                      </div>
                    </div>
                    <div className="profile-feature-item pending flex-box flex-100 flex-middle flex-gap-4">
                      <div className="flex-1">
                        <p>Projects and Certification</p>
                      </div>
                      <div className="">
                        <div className="item-status flex-box flex-middle flex-gap-1">
                          <CheckCircleIcon />
                          <p>Pending</p>
                        </div>
                      </div>
                    </div>
                    <div className="profile-feature-item success flex-box flex-100 flex-middle flex-gap-4">
                      <div className="flex-1">
                        <p>Achievements/Internships</p>
                      </div>
                      <div className="">
                        <div className="item-status flex-box flex-middle flex-gap-1">
                          <CheckCircleIcon />
                          <p>Complete</p>
                        </div>
                      </div>
                    </div>
                    <div className="profile-feature-item pending flex-box flex-100 flex-middle flex-gap-4">
                      <div className="flex-1">
                        <p>Workshops & Industrial visits</p>
                      </div>
                      <div className="">
                        <div className="item-status flex-box flex-middle flex-gap-1">
                          <CheckCircleIcon />
                          <p>Pending</p>
                        </div>
                      </div>
                    </div>
                    <div className="profile-feature-item pending flex-box flex-100 flex-middle flex-gap-4">
                      <div className="flex-1">
                        <p>Career objective</p>
                      </div>
                      <div className="">
                        <div className="item-status flex-box flex-middle flex-gap-1">
                          <CheckCircleIcon />
                          <p>Pending</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { UserData } = state.UserProfileReducer;
  return { UserData };
};

export default withRouter(connect(mapStateToProps)(UserProfile));
