import { toast } from "react-toastify";

export const DefaultToast = ({
  style = {},
  message = "Success!",
  position = "top-right",
  classes,
}) =>
  toast(message, {
    position,
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    style,
    ...classes,
  });
