import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getCustomers } from "../../store/StudentAndCustomer/actions";
import CustomerManagementDumb from "./CustomerManagementDumb";
import { STATE_LIST } from "./stateNames";
import api from "../../api/baseConfig";
import apiHelper from "../../api/apiHelper";
import { toast } from "react-toastify";
import { TrimString } from "pages/utils/TrimString";
import { sessionPrivileges } from "privileges";

function CustomerManagementSmart(props) {
  const userPrivileges = sessionPrivileges();
  const userID = userPrivileges.userName;
  const activePage = props?.location?.state?.activePage;
  const itemsPerPage = props?.location?.state?.itemsPerPage;

  const dispatch = useDispatch();

  const [logo, setLogo] = useState("");
  const [existingData, setExistingData] = useState({});

  const handleAfterSuccess = () => {
    dispatch(getCustomers());
    props.history.push("/customerList");
  };

  const checkIfEmailExists = async (email) => {
    try {
      const res = await apiHelper.axiosCall(
        `${api.baseURL}${api.customerManagement.checkCustomerEmail}${email}`
      );
      return res;
    } catch (e) {}
  };

  const checkIfNameExists = async (name) => {
    const encodedName = window.btoa(name);
    try {
      const res = await apiHelper.customerNameValidation(
        `${api.baseURL}${api.customerManagement.checkCustomerName}${encodedName}`
      );
    
      return res;
    } catch (e) {}
  };

  const updateCustomer = async (customerData) => {
    const data = {
      ...existingData,
      ...customerData,
      logo,
      updatedBy: userID,
      entitlement: {
        prepare: customerData.prepare,
        practice: customerData.practice,
        assessment: customerData.assessment,
        resumeBuilder: customerData.resumeBuilder,
      },
    };
    delete data.prepare;
    delete data.practice;
    delete data.assessment;
    delete data.resumeBuilder;
    const url = TrimString(`${api.baseURL}${api.customerManagement.update}
    ${props?.location?.state?.id}`);
    try {
      await apiHelper.axiosCallPost(url, "put", data);
      handleAfterSuccess();
    } catch (e) {}
  };

  const createCustomer = async (customerData) => {
    const data = {
      ...customerData,
      logo,
      createdBy: userID,
      updatedBy: userID,
      entitlement: {
        prepare: customerData.prepare,
        practice: customerData.practice,
        assessment: customerData.assessment,
        resumeBuilder: customerData.resumeBuilder,
      },
    };
    delete data.prepare;
    delete data.practice;
    delete data.assessment;
    delete data.resumeBuilder;
    const url = TrimString(`${api.baseURL}${api.customerManagement.create}`);
    try {
      await apiHelper.axiosCallPost(url, "post", data);
      toast.success("Sucessfully Created Customer", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });

      handleAfterSuccess();
    } catch (e) {}
  };

  return (
    <>
    {userPrivileges.showCustomerList && (
    <CustomerManagementDumb
      createCustomer={createCustomer}
      updateCustomer={updateCustomer}
      id={props?.location?.state?.id}
      viewOnly={props?.location?.state?.type === "viewOnly"}
      states={STATE_LIST}
      logo={logo}
      setLogo={setLogo}
      existingData={existingData}
      setExistingData={setExistingData}
      checkIfEmailExists={checkIfEmailExists}
      checkIfNameExists={checkIfNameExists}
      activePage={activePage}
      itemsPerPage={itemsPerPage}
    />
    )}
    </>
  );
}

export default CustomerManagementSmart;
