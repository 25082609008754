import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "reactstrap";
import qs from "query-string";
import cn from "classnames";
import { ArrowBack } from "@material-ui/icons";
import { Tabs } from "../../../components/tabs/tabs";
import { TabsPractice } from "../../../components/tabs/tabsPractice";
import ReportTable from "../components/report-table/index";
import Dashboard from "../components/dashboard/index";
import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";
import styles from "./index.module.scss";
import equal from "fast-deep-equal";
import { sessionPrivileges } from "privileges";

function AdminPracticeReport(props) {
  const labId =
    props?.location?.state?.practiceLabId ||
    sessionStorage.getItem("practice-report-id");
  const { tab } = qs.parse(window.location.search);
  const [reportDashboardData, setReportDashboardData] = useState({});
  const [reportDepartmentPerfData, setReportDepartmentPerfData] = useState([]);
  const [reportStudentPerfData, setReportStudentPerfData] = useState([]);
  const [reportSectionPerfData, setReportSectionPerfData] = useState({});
  const [reportAssessmentData, setReportAssessmentData] = useState([]);
  const [deptPerfDataLoading, setDeptPerfDataLoadingStatus] = useState([]);
  const [stuPerfDataLoading, setStuPerfDataLoadingStatus] = useState([]);
  const [secPerfDataLoading, setSecPerfDataLoadingStatus] = useState([]);
  const [assessmentDataLoading, setAssessmentDataLoadingStatus] = useState([]);
  const [activeTab, setActiveTab] = useState(
    () => tab || "department-performance"
  );
  const [activeSection, setActiveSection] = useState("");
  const [activeTopic, setActiveTopic] = useState("all");
  const [sections, setSections] = useState([]);
  const [topics, setTopics] = useState([]);
  const [loadingCsvExport, setCsvExportLoading] = useState(false);
  const [loadingHtmlExport, setHtmlExportLoading] = useState(false);
  const [loadingPdfExport, setPdfExportLoading] = useState(false);
  const [exportMock, setExportMock] = useState(true);
  const [emptyValueCheck, setEmptyValueCheck] = useState();
  const [departmentTotal, setDepartmentTotal] = useState();
  const [mockTotal, setMockTotal] = useState();
  // const [practiceStudentPerformanceData, setPracticeStudentPerformanceData] = useState()
  const overAllReport = props?.location?.state?.overAllReport;

  const customerId = props?.location?.state?.customerId;
  const activePage = props?.location?.state?.activePage;
  const activeTabb = props?.location?.state?.activeTab;

  async function exportPDF() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&reportType=all`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?&reportType=all`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }
  async function exportHTML() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=html&reportType=all`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=html&resourceType=all`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }
  async function exportCSV() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=csv&reportType=all`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=csv&reportType=all`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportStudentPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&reportType=student`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?reportType=student`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }
  async function exportDepartmentPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&reportType=department`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?reportType=department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }
  async function exportSectionPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&reportType=section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?reportType=section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }
  async function exportMockPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&reportType=mock`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?reportType=mock`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }
  async function exportDeptStudPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&reportType=student,department`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?reportType=student,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }
  async function exportDeptSecPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&reportType=section,department`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?reportType=section,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }
  async function exportDeptMockPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&reportType=mock,department`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?reportType=mock,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }
  async function exportStudSecPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&reportType=student,section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?reportType=student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }
  async function exportStudMockPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&reportType=student,mock`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?reportType=student,mock`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }
  async function exportSecMockPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&reportType=mock,section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?reportType=mock,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }
  async function exportDeptSecStudPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&reportType=department,student,section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?reportType=department,student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }
  async function exportDeptStudMockPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&reportType=department,student,section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?reportType=department,student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }
  async function exportDeptSecMockPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&reportType=mock,department,section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?reportType=mock,department,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }
  async function exportDepartmentPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=html&reportType=department`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=html&reportType=department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }
  async function exportStudentPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=html&reportType=student`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=html&reportType=student`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }
  async function exportSectionPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=html&reportType=section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=html&reportType=section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }
  async function exportMockPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=html&reportType=mock`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=html&reportType=mock`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }
  async function exportDeptStudPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=html&reportType=student,department`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=html&reportType=student,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }
  async function exportDeptSecPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=html&reportType=section,department`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=html&reportType=section,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }
  async function exportDeptMockPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=html&reportType=mock,department`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=html&reportType=mock,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }
  async function exportStudSecPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=html&reportType=student,section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=html&reportType=student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }
  async function exportStudMockPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=html&reportType=student,mock`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=html&reportType=student,mock`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }
  async function exportSecMockPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=html&reportType=mock,section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=html&reportType=mock,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }
  async function exportDeptSecStudPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=html&reportType=department,student,section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=html&reportType=department,student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }
  async function exportDeptStudMockPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=html&reportType=department,student,section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=html&reportType=department,student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }
  async function exportDeptSecMockPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=html&reportType=mock,department,section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=html&reportType=mock,department,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }
  async function exportDepartmentPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=csv&reportType=department`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=csv&reportType=department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportStudentPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=csv&reportType=student`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=csv&reportType=student`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportSectionPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=csv&reportType=section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=csv&reportType=section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportMockPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=csv&reportType=mock`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=csv&reportType=mock`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportDeptStudPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=csv&reportType=student,department`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=csv&reportType=student,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportDeptSecPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=csv&reportType=section,department`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=csv&reportType=section,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportDeptMockPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=csv&reportType=mock,department`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=csv&reportType=mock,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportStudSecPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=csv&reportType=student,section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=csv&reportType=student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportStudMockPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=csv&reportType=student,mock`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=csv&reportType=student,mock`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportSecMockPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=csv&reportType=mock,section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=csv&reportType=mock,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportDeptSecStudPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=csv&reportType=department,student,section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=csv&reportType=department,student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportDeptStudMockPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=csv&reportType=department,student,section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=csv&reportType=department,student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportDeptSecMockPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadPracticeReport/${labId}?labId=${labId}&resourceType=csv&reportType=mock,department,section`
        : `${api.baseURL}adminReport/downloadPracticeReport/${labId}?resourceType=csv&reportType=mock,department,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function getReportDasboardData(id) {
    if (id) {
      await Apihelper.axiosCall(
        `${api.baseURL}adminReport/getAdminPracticeReportDashboard/${id}`,
        "get"
      )
        .then((data) => {
          setReportDashboardData(data);
        })
        .catch(() => {});
    }
  }

  console.log(reportDashboardData?.enrolled, reportDashboardData?.setDepartmentTotal);

  const getReportDepartmentData = async ({
    id,
    page,
    pageLimit,
    totalCount,
    searchkey,
  }) => {
    try {
      if (id) {
        setDeptPerfDataLoadingStatus(true);
        const url =
          page !== undefined && page !== null && totalCount !== undefined
            ? `${
                api.baseURL
              }adminReport/getAdminPracticeReportDepartment/${id}?pageIndex=${page + 1}&limit=${pageLimit}&searchkey=${searchkey}`
            : `${api.baseURL}adminReport/getAdminPracticeReportDepartment/${id}`;

        const data = await Apihelper.axiosCall(url, "get");
        const dataList = [];
        (data?.departmentPerformance || []).forEach(
          ({
            department,
            attempted,
            notAttempted,
            avgMarks,
            avgTimeTaken,
            firstClass,
            secondClass,
            thirdClass,
            fourthClass,
            enrolled,
            completed,
            inprogress,
            incomplete,
            avgAccuracy,
          }) => {
            dataList.push({
              "0_25": fourthClass,
              "26_50": thirdClass,
              "51_75": secondClass,
              "76_100": firstClass,
              attempted,
              avgAccuracy: parseFloat(avgAccuracy)?.toFixed(1),
              avgMarks,
              completed,
              enrolled,
              incomplete,
              inProgress: inprogress,
              notAttempted,
              sectionBranch: department,
            });
          }
        );
        // setReportStudentPerfData(dataList);
        setDepartmentTotal(dataList?.length);
        // setPracticeStudentPerformanceData(data.departmentPerformance)
        return {
          data: dataList,
          page,
          totalCount: data?.departmentPerformanceCount,
          // totalCount: dataList?.length,
        };
      }
    } catch (error) {
          } finally {
      setDeptPerfDataLoadingStatus(false);
    }
  };

  const getReportStudentData = async ({
    id,
    page,
    pageLimit,
    totalCount,
    searchkey,
  }) => {
    try {
      if (id) {
        setStuPerfDataLoadingStatus(true);
        const url =
          page !== undefined && page !== null && totalCount !== undefined
            ? `${
                api.baseURL
              }adminReport/getAdminPracticeReportStudent/${id}?labId=${labId}&pageIndex=${
                page + 1
              }&limit=${pageLimit}&searchkey=${searchkey}`
            : `${api.baseURL}adminReport/getAdminPracticeReportStudent/${id}?labId=${labId}`;
        const data = await Apihelper.axiosCall(url, "get");
        const dataList = [];

        (data?.studentPerformance || []).forEach(
          ({
            completedCount,
            department,
            earnedPoints,
            firstName,
            hintsWatched,
            incompleteCount,
            lastName,
            correct,
            emailId,
            wrong,
            performance,
            registrationNumber,
            solutionWatched,
            totalPoints,
            totalQuestion,
          }) => {
            dataList.push({
              correct,
              wrong,
              department,
              emailId,
              hintsViewed: hintsWatched,
              attempted: completedCount,
              unAttempted: incompleteCount,
              name: `${firstName} ${lastName}`,
              performance,
              registrationNumber,
              solutionsViewed: solutionWatched,
              totalPoints: `${earnedPoints} / ${totalPoints}`,
              totalQuestion,
            });
          }
        );
        setReportStudentPerfData(dataList);
        return {
          data: dataList,
          page,
          totalCount,
        };
      }
    } catch (error) {
    } finally {
      setStuPerfDataLoadingStatus(false);
    }
  };

  const getReportSectionData = async ({
    id,
    page,
    pageLimit,
    totalCount,
    currentSection,
    currentTopic,
    searchkey,
  }) => {
    try {
      if (id) {
        setSecPerfDataLoadingStatus(true);
        const url =
          page !== undefined && page !== null && totalCount !== undefined
            ? `${
                api.baseURL
              }adminReport/getAdminPracticeReportCategory/${id}?labId=${labId}&pageIndex=${
                page + 1
              }&limit=${pageLimit}&searchkey=${searchkey}`
            : `${api.baseURL}adminReport/getAdminPracticeReportCategory/${id}?labId=${labId}`;

        const data = await Apihelper.axiosCall(url, "get");
        const tempPerf = data?.sectionPerformance?.[activeSection?.no || 0];
        const newTopic = [];
        const newListTopic = [];
        for (let i = 0; i < data?.sectionPerformance?.length; i++) {
          newTopic.push(data?.sectionPerformance?.[i]);
          newListTopic.push(newTopic[i]?.Topic[0]);
        }
        const tempStudents = (
          tempPerf?.adminStudentPerformanceDTOList || []
        ).map((item) => ({
          name: `${item.firstName} ${item.lastName}`,
          registrationNumber: item.registrationNumber,
          department: item.department,
        }));
        const tempSections = (newListTopic || []).map((item, index) => ({
          name: item,
          id: item,
          no: index,
        }));
        // const tempPerf = data?.sectionPerformance?.[]
        const allSections = (
          tempPerf?.adminStudentPerformanceDTOList || []
        ).reduce((acc, section, ind) => {
          (section?.sectionDetails || []).forEach((secItem) => {
            const itemToAdd = {
              accuracy: secItem.accuracy,
              completed: secItem.completed,
              inProgress: secItem.inProgress,
              totalPoints: secItem.totalPoints,
              totalQuestion: secItem.totalQuestion,
              ...tempStudents[ind],
            };
            if (Array.isArray(acc[secItem.topicName]))
              acc[secItem.topicName].push(itemToAdd);
            else acc[secItem.topicName] = [itemToAdd];
          });
          return acc;
        }, {});
        const areSectionsEqual = equal(sections, tempSections);
        if (!areSectionsEqual) {
          setSections(tempSections);
        }
        const ActiveSection = currentSection || tempSections?.[0]?.name;

        if (!activeSection) {
          setActiveSection(ActiveSection);
        }
        //setReportSectionPerfData(dataObject);
        return {
          data: allSections[ActiveSection] || tempStudents,
          page,
          totalCount,
        };
      }
    } catch (error) {
    } finally {
      setSecPerfDataLoadingStatus(false);
    }
  };

  const getReportAssessmentData = async ({
    id,
    page,
    pageLimit,
    totalCount,
    searchkey,
  }) => {
    try {
      if (id) {
        setAssessmentDataLoadingStatus(true);
        const url =
          page !== undefined && page !== null && totalCount !== undefined
            ? `${
                api.baseURL
              }assessment/admin/getMockAssessmentListByLabId/${id}?labId=${labId}&pageIndex=${
                page > 0 ? page + 10 : page
              }&limit=${pageLimit}&searchkey=${searchkey}`
            : `${api.baseURL}assessment/admin/getMockAssessmentListByLabId/${id}?labId=${labId}`;

        const data = await Apihelper.axiosCall(url, "get");

        setMockTotal(data?.length);
        return {
          data: data.map((assessment) => ({
            attended: assessment?.assessmentAttended,
            completed: assessment?.assessmentCompleted,
            enrolled: assessment?.assessmentEnrolled,
            id: assessment?.mockAssessmentId,
            name: assessment?.mockAssessmentName,
            notCompleted: assessment?.assessmentNotCompleted,
          })),
          page,
          totalCount,
        };
      }
    } catch (error) {
    } finally {
      setAssessmentDataLoadingStatus(false);
    }
  };
  const Priv = sessionPrivileges().userprivileges || [];
  useEffect(() => {
    getReportDasboardData(labId);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // setReportDepartmentPerfData([]);
    // setReportStudentPerfData([]);
    // setReportSectionPerfData([]);
    setSections([]);
    setTopics([]);
  }, [activeTab]);

  useEffect(() => {
    setTopics(
      (reportSectionPerfData?.[activeSection]?.topics || []).map((t) => ({
        name: t,
        id: t,
      }))
    );
    setActiveTopic("all");
  }, [activeSection]);
  return (
    <div className={cn("page-content", styles.page)}>
      <Dashboard reportDashboardData={reportDashboardData} />
      <Tabs
        activeTab={activeTab}
        contentClassName={styles.tabContent}
        hideBadge
        onChange={setActiveTab}
        tabs={[
          { name: "Department Performance", id: "department-performance" },
          { name: "Student Performance", id: "student-performance" },
          { name: "Section Performance", id: "section-performance" },
          ...(Priv.includes("ADD_MOCK_ASSESSMENT")
            ? [{ name: "Mock Assessments", id: "mock-assessments" }]
            : []),
        ]}
      >
        {activeTab === "department-performance" && (
          <ReportTable
            customExport
            loadingCsvExport={loadingCsvExport}
            loadingHtmlExport={loadingHtmlExport}
            loadingPdfExport={loadingPdfExport}
            exportCSV={exportCSV}
            exportPDF={exportPDF}
            exportHTML={exportHTML}
            exportDepartmentPerformancePdf={exportDepartmentPerformancePdf}
            exportStudentPerformancePdf={exportStudentPerformancePdf}
            exportSectionPerformancePdf={exportSectionPerformancePdf}
            exportMockPerformancePdf={exportMockPerformancePdf}
            exportDeptStudPerformancePdf={exportDeptStudPerformancePdf}
            exportDeptSecPerformancePdf={exportDeptSecPerformancePdf}
            exportDeptMockPerformancePdf={exportDeptMockPerformancePdf}
            exportStudSecPerformancePdf={exportStudSecPerformancePdf}
            exportStudMockPerformancePdf={exportStudMockPerformancePdf}
            exportSecMockPerformancePdf={exportSecMockPerformancePdf}
            exportDeptSecStudPerformancePdf={exportDeptSecStudPerformancePdf}
            exportDeptStudMockPerformancePdf={exportDeptStudMockPerformancePdf}
            exportDeptSecMockPerformancePdf={exportDeptSecMockPerformancePdf}
            exportDepartmentPerformanceHtml={exportDepartmentPerformanceHtml}
            exportStudentPerformanceHtml={exportStudentPerformanceHtml}
            exportSectionPerformanceHtml={exportSectionPerformanceHtml}
            exportMockPerformanceHtml={exportMockPerformanceHtml}
            exportDeptStudPerformanceHtml={exportDeptStudPerformanceHtml}
            exportDeptSecPerformanceHtml={exportDeptSecPerformanceHtml}
            exportDeptMockPerformanceHtml={exportDeptMockPerformanceHtml}
            exportStudSecPerformanceHtml={exportStudSecPerformanceHtml}
            exportStudMockPerformanceHtml={exportStudMockPerformanceHtml}
            exportSecMockPerformanceHtml={exportSecMockPerformanceHtml}
            exportDeptSecStudPerformanceHtml={exportDeptSecStudPerformanceHtml}
            exportDeptStudMockPerformanceHtml={
              exportDeptStudMockPerformanceHtml
            }
            exportDeptSecMockPerformanceHtml={exportDeptSecMockPerformanceHtml}
            exportDepartmentPerformanceCsv={exportDepartmentPerformanceCsv}
            exportStudentPerformanceCsv={exportStudentPerformanceCsv}
            exportSectionPerformanceCsv={exportSectionPerformanceCsv}
            exportMockPerformanceCsv={exportMockPerformanceCsv}
            exportDeptStudPerformanceCsv={exportDeptStudPerformanceCsv}
            exportDeptSecPerformanceCsv={exportDeptSecPerformanceCsv}
            exportDeptMockPerformanceCsv={exportDeptMockPerformanceCsv}
            exportStudSecPerformanceCsv={exportStudSecPerformanceCsv}
            exportStudMockPerformanceCsv={exportStudMockPerformanceCsv}
            exportSecMockPerformanceCsv={exportSecMockPerformanceCsv}
            exportDeptSecStudPerformanceCsv={exportDeptSecStudPerformanceCsv}
            exportDeptStudMockPerformanceCsv={exportDeptStudMockPerformanceCsv}
            exportDeptSecMockPerformanceCsv={exportDeptSecMockPerformanceCsv}
            fetchData={getReportDepartmentData}
            exportMock={true}
            labId={labId}
            data={reportDepartmentPerfData}
            totalCount={departmentTotal || 0}
            title="Department wise performance"
            columns={[
              {
                align: "left",
                field: "sectionBranch",
                sorting: false,
                title: "Section\xa0/\xa0Branch",
              },
              {
                align: "right",
                field: "enrolled",
                title: "Enrolled",
              },
              {
                align: "right",
                field: "completed",
                render: (d) => (
                  <span style={{ color: "#246611" }}>{d?.completed}</span>
                ),
                title: "Completed",
              },
              {
                align: "right",
                field: "inProgress",
                render: (d) => (
                  <span style={{ color: "#C5970B" }}>{d?.inProgress}</span>
                ),
                title: "In\xa0progress",
              },
              {
                align: "right",
                field: "incomplete",
                render: (d) => (
                  <span style={{ color: "#D42216" }}>{d?.incomplete}</span>
                ),
                title: "Incomplete",
              },
              {
                align: "right",
                field: "avgMarks",
                render: (d) => (
                  <span
                    style={{
                      backgroundColor: "#8BFFD5",
                      borderRadius: 5,
                      color: "#20222F",
                      height: 21,
                      padding: "0 6px",
                    }}
                  >
                    {`${d?.avgMarks || 0}%`}
                  </span>
                ),
                title: "Avg\xa0marks",
              },
              {
                align: "center",
                field: "avgAccuracy",
                title: "Avg\xa0Accuracy",
              },
              {
                align: "center",
                field: "0_25",
                title: "0\xa0-\xa025",
              },
              {
                align: "center",
                field: "26_50",
                title: "26\xa0-\xa050",
              },
              {
                align: "center",
                field: "51_75",
                title: "51\xa0-\xa075",
              },
              {
                align: "center",
                field: "76_100",
                title: "76\xa0-\xa0100",
              },
            ]}
            isLoading={deptPerfDataLoading}
          />
        )}
        {activeTab === "student-performance" && (
          <ReportTable
            customExport
            loadingCsvExport={loadingCsvExport}
            loadingHtmlExport={loadingHtmlExport}
            loadingPdfExport={loadingPdfExport}
            exportCSV={exportCSV}
            exportPDF={exportPDF}
            exportHTML={exportHTML}
            exportDepartmentPerformancePdf={exportDepartmentPerformancePdf}
            exportStudentPerformancePdf={exportStudentPerformancePdf}
            exportSectionPerformancePdf={exportSectionPerformancePdf}
            exportMockPerformancePdf={exportMockPerformancePdf}
            exportDeptStudPerformancePdf={exportDeptStudPerformancePdf}
            exportDeptSecPerformancePdf={exportDeptSecPerformancePdf}
            exportDeptMockPerformancePdf={exportDeptMockPerformancePdf}
            exportStudSecPerformancePdf={exportStudSecPerformancePdf}
            exportStudMockPerformancePdf={exportStudMockPerformancePdf}
            exportSecMockPerformancePdf={exportSecMockPerformancePdf}
            exportDeptSecStudPerformancePdf={exportDeptSecStudPerformancePdf}
            exportDeptStudMockPerformancePdf={exportDeptStudMockPerformancePdf}
            exportDeptSecMockPerformancePdf={exportDeptSecMockPerformancePdf}
            exportDepartmentPerformanceHtml={exportDepartmentPerformanceHtml}
            exportStudentPerformanceHtml={exportStudentPerformanceHtml}
            exportMockPerformanceHtml={exportMockPerformanceHtml}
            exportDeptStudPerformanceHtml={exportDeptStudPerformanceHtml}
            exportDeptSecPerformanceHtml={exportDeptSecPerformanceHtml}
            exportDeptMockPerformanceHtml={exportDeptMockPerformanceHtml}
            exportStudSecPerformanceHtml={exportStudSecPerformanceHtml}
            exportStudMockPerformanceHtml={exportStudMockPerformanceHtml}
            exportSecMockPerformanceHtml={exportSecMockPerformanceHtml}
            exportDeptSecStudPerformanceHtml={exportDeptSecStudPerformanceHtml}
            exportDeptStudMockPerformanceHtml={
              exportDeptStudMockPerformanceHtml
            }
            exportDeptSecMockPerformanceHtml={exportDeptSecMockPerformanceHtml}
            exportDepartmentPerformanceCsv={exportDepartmentPerformanceCsv}
            exportStudentPerformanceCsv={exportStudentPerformanceCsv}
            exportSectionPerformanceCsv={exportSectionPerformanceCsv}
            exportMockPerformanceCsv={exportMockPerformanceCsv}
            exportDeptStudPerformanceCsv={exportDeptStudPerformanceCsv}
            exportDeptSecPerformanceCsv={exportDeptSecPerformanceCsv}
            exportDeptMockPerformanceCsv={exportDeptMockPerformanceCsv}
            exportStudSecPerformanceCsv={exportStudSecPerformanceCsv}
            exportStudMockPerformanceCsv={exportStudMockPerformanceCsv}
            exportSecMockPerformanceCsv={exportSecMockPerformanceCsv}
            exportDeptSecStudPerformanceCsv={exportDeptSecStudPerformanceCsv}
            exportDeptStudMockPerformanceCsv={exportDeptStudMockPerformanceCsv}
            exportDeptSecMockPerformanceCsv={exportDeptSecMockPerformanceCsv}
            fetchData={getReportStudentData}
            totalCount={reportDashboardData?.enrolled || 0}
            labId={labId}
            title="Student wise performance"
            data={reportStudentPerfData}
            columns={[
              {
                align: "left",
                field: "name",
                title: "Name",
              },
              {
                align: "left",
                field: "registrationNumber",
                title: "Registration\xa0Number",
              },
              {
                align: "left",
                field: "emailId",
                title: "Email\xa0Id",
              },
              {
                align: "left",
                field: "department",
                title: "Department",
              },
              {
                align: "center",
                field: "totalQuestion",
                title: "Total Questions",
              },
              {
                align: "right",
                field: "attempted",
                render: (d) => (
                  <span style={{ color: "#246611" }}>
                    {d?.attempted || "-"}
                  </span>
                ),
                title: "Attempted",
              },
              {
                align: "right",
                field: "unAttempted",
                render: (d) => (
                  <span style={{ color: "#D42216" }}>
                    {d?.unAttempted || "-"}
                  </span>
                ),
                title: "UnAttempted",
              },
              {
                align: "right",
                field: "correct",
                render: (d) => (
                  <span style={{ color: "#246611" }}>{d?.correct || "-"}</span>
                ),
                title: "Correct",
              },
              {
                align: "right",
                field: "wrong",
                render: (d) => (
                  <span style={{ color: "#D42216" }}>{d?.wrong || "-"}</span>
                ),
                title: "Wrong",
              },
              {
                align: "right",
                field: "totalPoints",
                render: (d) =>
                  d?.attempted ? (
                    <span
                      style={{
                        backgroundColor: "#8BFFD5",
                        borderRadius: 5,
                        color: "#20222F",
                        height: 21,
                        padding: "0 6px",
                      }}
                    >
                      {d?.totalPoints || "-"}
                    </span>
                  ) : (
                    "-"
                  ),
                title: "Total\xa0Points",
              },
              {
                align: "center",
                field: "performance",
                render: (d) =>
                  d?.attempted ? (
                    <span
                      style={{
                        backgroundColor:
                          d?.performance === "Good"
                            ? "#EEFFEA"
                            : d?.performance === "Average"
                            ? "#FFF8E1"
                            : "#FFF4F3",
                        borderColor:
                          d?.performance === "Good"
                            ? "#B3DAC7"
                            : d?.performance === "Average"
                            ? "#FFCA99"
                            : "#FFD7D7",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderRadius: 3,
                        color:
                          d?.performance === "Good"
                            ? "#43B224"
                            : d?.performance === "Average"
                            ? "#886E1D"
                            : "#D42216",
                        height: 24,
                        padding: "0 6px",
                        width: 66,
                      }}
                    >
                      {d?.performance || "-"}
                    </span>
                  ) : (
                    "-"
                  ),
                title: "Performance",
              },
              {
                align: "center",
                field: "hintsViewed",
                title: "Hints\xa0viewed",
              },
              {
                align: "center",
                field: "solutionsViewed",
                title: "Solutions\xa0viewed",
              },
            ]}
            isLoading={stuPerfDataLoading}
          />
        )}
        {activeTab === "section-performance" && (
          <>
            <TabsPractice
              activeTab={activeSection?.id || activeSection}
              className={styles.sectionsTab}
              contentClassName={styles.tabContent}
              green
              hideBadge
              isLoading={secPerfDataLoading}
              noChildren
              onChange={setActiveSection}
              tabs={sections}
            />
            {!!(topics || []).length && (
              <Tabs
                activeTab={activeTopic}
                className={styles.sectionsTab}
                contentClassName={styles.tabContent}
                hideBadge
                isLoading={secPerfDataLoading}
                noChildren
                onChange={setActiveTopic}
                tabs={topics}
              />
            )}
            <ReportTable
              customExport
              loadingCsvExport={loadingCsvExport}
              loadingHtmlExport={loadingHtmlExport}
              loadingPdfExport={loadingPdfExport}
              exportCSV={exportCSV}
              exportPDF={exportPDF}
              exportHTML={exportHTML}
              exportDepartmentPerformancePdf={exportDepartmentPerformancePdf}
              exportStudentPerformanceHtml={exportStudentPerformanceHtml}
              exportStudentPerformancePdf={exportStudentPerformancePdf}
              exportSectionPerformancePdf={exportSectionPerformancePdf}
              exportMockPerformancePdf={exportMockPerformancePdf}
              exportDeptStudPerformancePdf={exportDeptStudPerformancePdf}
              exportDeptSecPerformancePdf={exportDeptSecPerformancePdf}
              exportDeptMockPerformancePdf={exportDeptMockPerformancePdf}
              exportStudSecPerformancePdf={exportStudSecPerformancePdf}
              exportStudMockPerformancePdf={exportStudMockPerformancePdf}
              exportSecMockPerformancePdf={exportSecMockPerformancePdf}
              exportDeptSecStudPerformancePdf={exportDeptSecStudPerformancePdf}
              exportDeptStudMockPerformancePdf={
                exportDeptStudMockPerformancePdf
              }
              exportDeptSecMockPerformancePdf={exportDeptSecMockPerformancePdf}
              exportDepartmentPerformanceHtml={exportDepartmentPerformanceHtml}
              exportSectionPerformanceHtml={exportSectionPerformanceHtml}
              exportMockPerformanceHtml={exportMockPerformanceHtml}
              exportDeptStudPerformanceHtml={exportDeptStudPerformanceHtml}
              exportDeptSecPerformanceHtml={exportDeptSecPerformanceHtml}
              exportDeptMockPerformanceHtml={exportDeptMockPerformanceHtml}
              exportStudSecPerformanceHtml={exportStudSecPerformanceHtml}
              exportStudMockPerformanceHtml={exportStudMockPerformanceHtml}
              exportSecMockPerformanceHtml={exportSecMockPerformanceHtml}
              exportDeptSecStudPerformanceHtml={
                exportDeptSecStudPerformanceHtml
              }
              exportDeptStudMockPerformanceHtml={
                exportDeptStudMockPerformanceHtml
              }
              exportDeptSecMockPerformanceHtml={
                exportDeptSecMockPerformanceHtml
              }
              exportDepartmentPerformanceCsv={exportDepartmentPerformanceCsv}
              exportStudentPerformanceCsv={exportStudentPerformanceCsv}
              exportSectionPerformanceCsv={exportSectionPerformanceCsv}
              exportMockPerformanceCsv={exportMockPerformanceCsv}
              exportDeptStudPerformanceCsv={exportDeptStudPerformanceCsv}
              exportDeptSecPerformanceCsv={exportDeptSecPerformanceCsv}
              exportDeptMockPerformanceCsv={exportDeptMockPerformanceCsv}
              exportStudSecPerformanceCsv={exportStudSecPerformanceCsv}
              exportStudMockPerformanceCsv={exportStudMockPerformanceCsv}
              exportSecMockPerformanceCsv={exportSecMockPerformanceCsv}
              exportDeptSecStudPerformanceCsv={exportDeptSecStudPerformanceCsv}
              exportDeptStudMockPerformanceCsv={
                exportDeptStudMockPerformanceCsv
              }
              exportDeptSecMockPerformanceCsv={exportDeptSecMockPerformanceCsv}
              // exportDepartmentPerformanceHtml = {exportDepartmentPerformanceHtml}

              activeSection={activeSection?.id || activeSection}
              activeTopic={activeTopic}
              fetchData={getReportSectionData}
              totalCount={reportDashboardData?.enrolled || 0}
              labId={labId}
              title="Section wise performance"
              columns={[
                {
                  align: "left",
                  field: "name",
                  title: "Name",
                },
                {
                  align: "left",
                  field: "registrationNumber",
                  title: "Registration\xa0Number",
                },
                {
                  align: "left",
                  field: "department",
                  title: "Department",
                },
                {
                  align: "right",
                  field: "totalQuestion",
                  render: (d) => (
                    <span style={{ color: "#246611" }}>
                      {d?.totalQuestion || `-`}
                    </span>
                  ),
                  title: "Total\xa0Question",
                },
                {
                  align: "right",
                  field: "completed",
                  render: (d) => (
                    <span style={{ color: "#246611" }}>
                      {d?.completed || `-`}
                    </span>
                  ),
                  title: "Completed",
                },
                {
                  align: "right",
                  field: "inProgress",
                  render: (d) => (
                    <span style={{ color: "#D42216" }}>
                      {d?.inProgress || `-`}
                    </span>
                  ),
                  title: "In\xa0progress",
                },
                {
                  align: "right",
                  field: "totalPoints",
                  render: (d) =>
                    d?.completed ? (
                      <span
                        style={{
                          backgroundColor: "#8BFFD5",
                          borderRadius: 5,
                          color: "#20222F",
                          height: 21,
                          padding: "0 6px",
                        }}
                      >
                        {`${parseInt(d?.totalPoints || 0)}%`}
                      </span>
                    ) : (
                      "-"
                    ),
                  title: "Total\xa0Points",
                },
                {
                  align: "center",
                  field: `${parseInt("accuracy" || 0)}`,
                  render: (d) => (
                    <span style={{ color: "#D42216" }}>
                      {d?.accuracy || `-`}
                    </span>
                  ),
                  title: "Accuracy",
                },
              ]}
              isLoading={secPerfDataLoading}
            />
          </>
        )}
        {/* {!!(topics || []).length && (
              <Tabs
                activeTab={activeTopic}
                className={styles.sectionsTab}
                contentClassName={styles.tabContent}
                hideBadge
                isLoading={secPerfDataLoading}
                noChildren
                onChange={setActiveTopic}
                tabs={topics}
              />
            )} */}
        {activeTab === "mock-assessments" && (
          <ReportTable
            customExport
            loadingCsvExport={loadingCsvExport}
            loadingHtmlExport={loadingHtmlExport}
            loadingPdfExport={loadingPdfExport}
            exportCSV={exportCSV}
            exportPDF={exportPDF}
            exportHTML={exportHTML}
            exportDepartmentPerformancePdf={exportDepartmentPerformancePdf}
            exportStudentPerformancePdf={exportStudentPerformancePdf}
            exportSectionPerformancePdf={exportSectionPerformancePdf}
            exportMockPerformancePdf={exportMockPerformancePdf}
            exportDeptStudPerformancePdf={exportDeptStudPerformancePdf}
            exportDeptSecPerformancePdf={exportDeptSecPerformancePdf}
            exportDeptMockPerformancePdf={exportDeptMockPerformancePdf}
            exportStudSecPerformancePdf={exportStudSecPerformancePdf}
            exportStudMockPerformancePdf={exportStudMockPerformancePdf}
            exportSecMockPerformancePdf={exportSecMockPerformancePdf}
            exportDeptSecStudPerformancePdf={exportDeptSecStudPerformancePdf}
            exportDeptStudMockPerformancePdf={exportDeptStudMockPerformancePdf}
            exportDeptSecMockPerformancePdf={exportDeptSecMockPerformancePdf}
            exportDepartmentPerformanceHtml={exportDepartmentPerformanceHtml}
            exportStudentPerformanceHtml={exportStudentPerformanceHtml}
            exportMockPerformanceHtml={exportMockPerformanceHtml}
            exportDeptStudPerformanceHtml={exportDeptStudPerformanceHtml}
            exportDeptSecPerformanceHtml={exportDeptSecPerformanceHtml}
            exportDeptMockPerformanceHtml={exportDeptMockPerformanceHtml}
            exportStudSecPerformanceHtml={exportStudSecPerformanceHtml}
            exportStudMockPerformanceHtml={exportStudMockPerformanceHtml}
            exportSecMockPerformanceHtml={exportSecMockPerformanceHtml}
            exportDeptSecStudPerformanceHtml={exportDeptSecStudPerformanceHtml}
            exportDeptStudMockPerformanceHtml={
              exportDeptStudMockPerformanceHtml
            }
            exportDeptSecMockPerformanceHtml={exportDeptSecMockPerformanceHtml}
            exportDepartmentPerformanceCsv={exportDepartmentPerformanceCsv}
            exportStudentPerformanceCsv={exportStudentPerformanceCsv}
            exportSectionPerformanceCsv={exportSectionPerformanceCsv}
            exportMockPerformanceCsv={exportMockPerformanceCsv}
            exportDeptStudPerformanceCsv={exportDeptStudPerformanceCsv}
            exportDeptSecPerformanceCsv={exportDeptSecPerformanceCsv}
            exportDeptMockPerformanceCsv={exportDeptMockPerformanceCsv}
            exportStudSecPerformanceCsv={exportStudSecPerformanceCsv}
            exportStudMockPerformanceCsv={exportStudMockPerformanceCsv}
            exportSecMockPerformanceCsv={exportSecMockPerformanceCsv}
            exportDeptSecStudPerformanceCsv={exportDeptSecStudPerformanceCsv}
            exportDeptStudMockPerformanceCsv={exportDeptStudMockPerformanceCsv}
            exportDeptSecMockPerformanceCsv={exportDeptSecMockPerformanceCsv}
            fetchData={getReportAssessmentData}
            labId={labId}
            totalCount={mockTotal || 0}
            title="Mock Assessments"
            columns={[
              {
                align: "left",
                field: "name",
                title: "Name",
              },
              {
                align: "right",
                field: "enrolled",
                title: "Enrolled",
              },
              {
                align: "right",
                field: "attended",
                title: "Attended",
              },
              {
                align: "right",
                field: "completed",
                render: (d) => (
                  <span style={{ color: "#246611" }}>{d?.completed}</span>
                ),
                title: "Completed",
              },
              {
                align: "right",
                field: "notCompleted",
                render: (d) => (
                  <span style={{ color: "#D42216" }}>{d?.notCompleted}</span>
                ),
                title: "Not\xa0Completed",
              },
              {
                align: "center",
                field: "id",
                render: (d) => (
                  <Button
                    color="primary"
                    onClick={() => {
                      props.history.push({
                        pathname: "/report",
                        state: {
                          assessmentId: d?.id,
                          labId: labId,
                        },
                      });
                    }}
                  >
                    Report
                  </Button>
                ),
                title: "Report",
              },
            ]}
            isLoading={assessmentDataLoading}
          />
        )}
      </Tabs>
      <br />
      <br />
      <div>
        <Button
          outline
          style={{ display: "inline-block !important", fontSize: 16 }}
          onClick={() => {
              props.history.push({
                pathname:
                  overAllReport ? "/overall-report" : "/manage-allLabs",
                state: {
                  customer: customerId,
                  activePage: activePage,
                  activeTab: activeTabb,
                },
              });
          }}
        >
          <ArrowBack />
          &nbsp;&nbsp;
          {"Go Back"}
        </Button>
      </div>
      <br />
      <br />
      <ToastContainer />
    </div>
  );
}

export default withRouter(AdminPracticeReport);
