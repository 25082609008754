import React, { useState, useEffect } from "react";
import useStyles from  "./UserProfileStyles.js";
import moment from 'moment';
import EditUserInput from  "./EditUserInput.js";
import { DeleteOutlineIcon } from "assets/icons/icons";
import { ConformDelete } from "../../components/DeleteModelePre";
import { Button, Menu, MenuItem, Icon } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const paraStyle = {
  fontSize: 15,
  fontWeight: 500,
  color: "#5B5A5F",
  marginTop: 12,
  textAlign: "center",
};

export default function EditUserEducation({pageCount, userData, handlePageCount, submitUserData, history}) {
  // const [pageCount, setPageCount] = useState(1);
  const css = useStyles();
  const [anchorKeys, setAnchorKeys] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openFilter = Boolean(anchorEl);
  const handleCloseFilter = () => {
    setAnchorEl(null);
  };
  const handleOpenFilter = (event, keys) => {
    if(openFilter)
      setAnchorEl(null);
    else{
      setAnchorEl(event.currentTarget);
      if(keys.length > 0) setAnchorKeys(keys);
    }
  };

  const getYearList = () => {
    const tempYear = [], currYear = moment().year();
    for(let i=0; i<10; i++) tempYear.push(currYear-i)
    return tempYear;
  }
  
  const defaultData = {
    hsc: {
      schoolName: {value: "", validation:{key:"schoolName", placeholder:"outer", name:"School name", min:3, max:48, type:"text"}},
      cgpa: {value: null, validation:{key:"cgpa", placeholder:"outer", name:"", minValue:0, maxValue:10, type:"float"}},
      percentage: {value: null, validation:{key:"percentage", placeholder:"outer", name:"", minValue:0, maxValue:100, type:"float"}},
      yearOfPassing: {value: "", validation:{key:"yearOfPassing", placeholder:"outer", name:"Year of passing", data:getYearList(), type:"select-single"}},
      board: {value: "", validation:{key:"board", placeholder:"outer", name:"Board", data:["Central Board of Secondary Education (CBSE)", "Indian School Certificate (ISC)", "Indian School Certificate Examinations (ICSE)", "National Institute of Open Schooling (NIOS)", "State Board"], type:"select-single"}},
    },
    college : {
      collegeName: {value: "", validation:{key:"collegeName", placeholder:"outer", name:"College name", min:3, max:48, type:"text"}},
      department: {value: "", validation:{key:"department", placeholder:"outer", name:"Department", min:3, max:48, type:"text"}},
      cgpa: {value: null, validation:{key:"cgpa", placeholder:"outer", name:"", minValue:0, maxValue:10, type:"float"}},
      percentage: {value: null, validation:{key:"percentage", placeholder:"outer", name:"", minValue:0, maxValue:100, type:"float"}},
      yearOfPassing: {value: "", validation:{key:"yearOfPassing", placeholder:"outer", name:"Year of passing", data:getYearList(), type:"select-single"}},
      historyArrear: {value: 0, validation:{key:"historyArrear", placeholder:"outer", name:"History of arrear", minValue:0, maxValue:100, type:"integer"}},
      currentArrear: {value: 0, validation:{key:"currentArrear", placeholder:"outer", name:"Current arrear", minValue:0, maxValue:100, type:"integer"}},
      degree: {value: ""}
    }
  }
  const [userId, setUserId] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [errorComponentList, setErrorComponentList] = useState([]);
  const [pageData, setPageData] = useState({
    sslc: {
      schoolName: {value: "", validation:{key:"schoolName", placeholder:"outer", name:"School name", min:3, max:48, type:"text"}},
      cgpa: {value: null, validation:{key:"cgpa", placeholder:"outer", name:"", minValue:0, maxValue:10, type:"float"}},
      percentage: {value: null, validation:{key:"percentage", placeholder:"outer", name:"", minValue:0, maxValue:100, type:"float"}},
      yearOfPassing: {value: "", validation:{key:"yearOfPassing", placeholder:"outer", name:"Year of passing", data:getYearList(), type:"select-single"}},
      board: {value: "", validation:{key:"board", placeholder:"outer", name:"Board", data:["Central Board of Secondary Education (CBSE)", "Indian School Certificate (ISC)", "Indian School Certificate Examinations (ICSE)", "National Institute of Open Schooling (NIOS)", "State Board"], type:"select-single"}},
    },
    hsc: [],
    college: [],
  }); 
  const [dataDelete, setDataDelete] = useState({ type: "", index: null });

  const addData = (key, data, type) => {
    const tempPageData = {...pageData};
    if(type && type.key) data[type.key]["value"] = type.value;
    tempPageData[key].push(data);
    setPageData(tempPageData);
  }
  const removeData = (key, index) => {
    const tempPageData = {...pageData}
    tempPageData[key].splice(index, 1);
    setPageData(tempPageData)
  }
  const traceData = (keys, keyIndex, dataName, val, data) => {
    if(keys[keyIndex+1] !== undefined) data[keys[keyIndex]] = traceData(keys, keyIndex+1, dataName, val, data[keys[keyIndex]]);
    else data[keys[keyIndex]][dataName] = val;
    return data;
  }
  const updateData = ({keys, dataName, value}) => {
    if(keys && keys.length > 0 ){
      const tempData = traceData(keys, 0, dataName, value, {...pageData});
      setPageData(tempData);
    }
  }
  const updateDataList = (lists) => {
    if(lists.length>0){
      let tempData = {};
      lists.map((list)=>{
        const {keys, dataName, value} = list;
        if(keys && keys.length > 0 ){
          tempData = traceData(keys, 0, dataName, value, {...pageData});
        }
      });
      setPageData(tempData);
    }
  }
  const handleFilterMenuItem = (val) => {
    if(val === "cgpa")
      updateDataList([
        {keys: [...anchorKeys, "cgpa"], dataName:"value", value: parseFloat(`0`).toFixed(2)},
        {keys: [...anchorKeys, "percentage"], dataName:"value", value: null}
      ])
    else if(val === "percentage")
      updateDataList([
        {keys: [...anchorKeys, "percentage"], dataName:"value", value: 0},
        {keys: [...anchorKeys, "cgpa"], dataName:"value", value: null}
      ])
      setAnchorEl(null);
      setAnchorKeys([]);
  };
  
  const errorComponentUpdate = (component, type) => {
    const tempList = [...errorComponentList];
    const index = tempList.indexOf(component);
    if(type === "remove" && index > -1){
      tempList.splice(index, 1);
      setErrorComponentList(tempList);
    } else if(type === "add" && index === -1)
      setErrorComponentList([...tempList, component]);
  }
  const submitPageDataVal = async () => {
    const isSubmit = await submitUserData(pageData, "users/saveUserEduc/");
    if(isSubmit){
      handlePageCount(pageCount+1)
    }
  }
  const goNextPage = () => {
    if(errorComponentList.length > 0)
      setErrorShow(true);
    else 
      submitPageDataVal()
  }
  const goPrevPage = () => {
    handlePageCount(pageCount-1)
  }

  useEffect(() => {
    if((dataDelete.type === "hscDeleteConform" || dataDelete.type === "diplomaDeleteConform" || dataDelete.type === "collegeDeleteConform") && dataDelete.index !== null ) {
      removeData(dataDelete.index.key, dataDelete.index.index);
      setDataDelete({ type: "", index: null });
    }
  }, [dataDelete]);

  useEffect(() => {
    if(userData.id) {
      let tempPageData = {...pageData};
      for(const key in tempPageData) {
        if(tempPageData[key]["value"] !== undefined) {
          if(Array.isArray(tempPageData[key])) {
            if(Array.isArray(userData[key])) tempPageData[key]["value"] = [...userData[key]];
            else tempPageData[key]["value"] = [];
          } else tempPageData[key]["value"] = userData[key] ? userData[key] : tempPageData[key]["value"];
        } else if(tempPageData[key] && Array.isArray(tempPageData[key]) && defaultData[key]) {
          const tempArray = [];
          const fromUserData = userData[key] && Array.isArray(userData[key]) ? [...userData[key]] : [];
          if(fromUserData.length > 0 ) {
            fromUserData.map((data, i) => {
              let defaultKeyData = {...defaultData[key]};
              for(const defaultKey in defaultKeyData) {
                defaultKeyData[defaultKey] = {...defaultKeyData[defaultKey], value: fromUserData[i] && fromUserData[i][defaultKey] ? fromUserData[i][defaultKey] : ""};
              }
              tempArray.push(defaultKeyData);
            });
          } else {
            // tempArray.push({...defaultData[key]});
          }
          tempPageData[key] = tempArray;
        } else if(typeof tempPageData[key] === "object") {
          for (const subKey in tempPageData[key]) {
            if(tempPageData[key][subKey]["value"] !== undefined) tempPageData[key][subKey]["value"] = userData[key] && userData[key][subKey] ? userData[key][subKey] : "";
          }
        }
      }
      if(tempPageData["sslc"]["percentage"]){
        if(tempPageData["sslc"]["percentage"]["value"] === "") tempPageData["sslc"]["percentage"]["value"] = null;
      }
      
      if (tempPageData.hsc.length > 0){
        tempPageData.hsc.map((data, i)=> {
          if (data["percentage"]){
            if(data["percentage"]["value"] === "") {
              tempPageData["hsc"][i]["percentage"]["value"] = null;
            }else if(data["percentage"]["value"]) {
              tempPageData["hsc"][i]["percentage"]["value"] = parseFloat(`${tempPageData["hsc"][i]["percentage"]["value"]}`).toFixed(2);
            }
            if(data["cgpa"]["value"]) {
              tempPageData["hsc"][i]["cgpa"]["value"] = parseFloat(`${tempPageData["hsc"][i]["cgpa"]["value"]}`).toFixed(2);
            }
          }
        })
      }
      
      if (pageData.college.length > 0){
        pageData.college.map((data, i)=> {
          if (data["percentage"]){
            if(data["percentage"]["value"] === "") {
              tempPageData["college"][i]["percentage"]["value"] = null;
            }else if(data["percentage"]["value"]) {
              tempPageData["college"][i]["percentage"]["value"] = parseFloat(`${tempPageData["college"][i]["percentage"]["value"]}`).toFixed(2);
            }
            if(data["cgpa"]["value"]) {
              tempPageData["college"][i]["cgpa"]["value"] = parseFloat(`${tempPageData["college"][i]["cgpa"]["value"]}`).toFixed(2);
            }
          }
        })
      }
      setPageData(tempPageData);
      setUserId(userData.id);
    }
  },[userData]);

  return (
    <React.Fragment>
      {userId && (
        <React.Fragment>
          <div className="form-group">
            <h4>Education details</h4>
            <h5>SSLC</h5>
            <div className="flex-box flex-100 flex-gap-2">
              <div className="flex-3">
                <div className="form-row flex-box flex-100 flex-gap-2">
                  {pageData.sslc.schoolName && (
                    <EditUserInput type="text" value={pageData.sslc.schoolName.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["sslc","schoolName"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.sslc.schoolName.validation} />
                  )}
                </div>
                <div className="form-row flex-box flex-100 flex-gap-2">
                  {(pageData.sslc.percentage || pageData.sslc.cgpa) && (
                    <div className="flex-1">
                      <Button variant="text" className="h6-button" endIcon={<ExpandMoreIcon />} onClick={(e)=>handleOpenFilter(e, ["sslc"])}>
                        {(pageData.sslc.percentage && pageData.sslc.percentage.value !== undefined && pageData.sslc.percentage.value !== null ) ? "Percentage" : "CGPA" }
                      </Button>
                      {pageData.sslc.percentage && pageData.sslc.percentage.value !== undefined && pageData.sslc.percentage.value !== null ? (
                        <EditUserInput type="number" value={pageData.sslc.percentage.value} errorShow={errorShow}
                          setValue={(v)=>updateData({keys: ["sslc", "percentage"], dataName:"value", value: v})}
                          updateError={errorComponentUpdate} validation={pageData.sslc.percentage.validation} />
                        ):(
                        <EditUserInput type="number" value={pageData.sslc.cgpa.value} errorShow={errorShow}
                          setValue={(v)=>updateData({keys: ["sslc", "cgpa"], dataName:"value", value: v})}
                          updateError={errorComponentUpdate} validation={pageData.sslc.cgpa.validation} />
                      )}
                    </div>
                  )}
                  {pageData.sslc.yearOfPassing && (
                    <EditUserInput type="select" value={pageData.sslc.yearOfPassing.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["sslc","yearOfPassing"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.sslc.yearOfPassing.validation} />
                  )}
                  {pageData.sslc.board && (
                    <EditUserInput type="select" value={pageData.sslc.board.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["sslc","board"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={pageData.sslc.board.validation} />
                  )}
                </div>
              </div>
              <div className="flex-1"></div>
            </div>
          </div>
          {/* //schoolName, cgpa, yearOfPassing, board */}
          <div className="form-group">
            <h5>HSC</h5>
            {pageData.hsc.length > 0 && pageData.hsc.map((data, i)=>(
              <div key={`hsc-${i}`} className="flex-box flex-100 flex-gap-2" style={{margin:"24px 0"}}>
                <div className="flex-3">
                  <div className="form-row flex-box flex-100 flex-gap-2">
                    {data.schoolName && (
                      <EditUserInput type="text" value={data.schoolName.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["hsc", i, "schoolName"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.schoolName.validation} />
                    )}
                  </div>
                  <div className="form-row flex-box flex-100 flex-gap-2">
                    <div className="flex-1">
                      <Button variant="text" className="h6-button" endIcon={<ExpandMoreIcon />} onClick={(e)=>handleOpenFilter(e, ["hsc", i])}>
                        {(data.percentage && data.percentage.value !== undefined && data.percentage.value !== null ) ? "Percentage" : "CGPA" }
                      </Button>
                      {data.percentage && data.percentage.value !== undefined && data.percentage.value !== null ? (
                        <EditUserInput type="number" value={data.percentage.value} errorShow={errorShow}
                          setValue={(v)=>updateData({keys: ["hsc", i, "percentage"], dataName:"value", value: v})}
                          updateError={errorComponentUpdate} validation={data.percentage.validation} />
                        ):(
                        <EditUserInput type="number" value={data.cgpa.value} errorShow={errorShow}
                          setValue={(v)=>updateData({keys: ["hsc", i, "cgpa"], dataName:"value", value: v})}
                          updateError={errorComponentUpdate} validation={data.cgpa.validation} />
                      )}
                    </div>
                    {data.yearOfPassing && (
                      <EditUserInput type="select" value={data.yearOfPassing.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["hsc", i, "yearOfPassing"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.yearOfPassing.validation} />
                    )}
                    {data.board && (
                      <EditUserInput type="select" value={data.board.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["hsc", i, "board"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.board.validation} />
                    )}
                  </div>
                </div>
                <div className="flex-1">
                {i === 0 && (
                  <div>
                    <h6>&nbsp;</h6>
                    <Button variant="text" className={css.buttonDeleteIcon} disableElevation onClick={()=>setDataDelete({type: "hscDelete", index: {key: "hsc",index : i}})}>
                      <DeleteOutlineIcon />
                    </Button>
                  </div>
                )}
                </div>
              </div>
            ))}
          </div>
          {pageData.hsc.length === 0 && (
            <div className={`form-group last-0`}>
              <Button variant="outlined" className={css.buttonSmSuccess} disableElevation onClick={()=>addData("hsc", defaultData.hsc)}>
                Add HSC
              </Button>
            </div>
          )}
          <div className="form-group">
            <h5>Diploma</h5>
            {pageData.college.length > 0 && pageData.college.map((data, i)=> data.degree.value === "Diploma" && (
              <div key={`college-${i}`} className="flex-box flex-100 flex-gap-2" style={{margin:"24px 0"}}>
                <div className="flex-3">
                  <div className="form-row flex-box flex-100 flex-gap-2">
                    {data.collegeName && (
                      <EditUserInput type="text" value={data.collegeName.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["college", i, "collegeName"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.collegeName.validation} />
                    )}
                    {data.department && (
                      <EditUserInput type="text" value={data.department.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["college", i, "department"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.department.validation} />
                    )}
                  </div>
                  <div className="form-row flex-box flex-100 flex-gap-2">
                    <div className="flex-1">
                      <Button variant="text" className="h6-button" endIcon={<ExpandMoreIcon />} onClick={(e)=>handleOpenFilter(e, ["college", i])}>
                        {(data.percentage && data.percentage.value !== undefined && data.percentage.value !== null ) ? "Percentage" : "CGPA" }
                      </Button>
                      {data.percentage && data.percentage.value !== undefined && data.percentage.value !== null ? (
                        <EditUserInput type="number" value={data.percentage.value} errorShow={errorShow}
                          setValue={(v)=>updateData({keys: ["college", i, "percentage"], dataName:"value", value: v})}
                          updateError={errorComponentUpdate} validation={data.percentage.validation} />
                        ):(
                        <EditUserInput type="number" value={data.cgpa.value} errorShow={errorShow}
                          setValue={(v)=>updateData({keys: ["college", i, "cgpa"], dataName:"value", value: v})}
                          updateError={errorComponentUpdate} validation={data.cgpa.validation} />
                      )}
                    </div>
                    {data.yearOfPassing && (
                      <EditUserInput type="select" value={data.yearOfPassing.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["college", i, "yearOfPassing"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.yearOfPassing.validation} />
                    )}
                    {data.historyArrear && (
                      <EditUserInput type="number" value={data.historyArrear.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["college", i, "historyArrear"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.historyArrear.validation} />
                    )}
                    {data.currentArrear && (
                      <EditUserInput type="number" value={data.currentArrear.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["college", i, "currentArrear"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.currentArrear.validation} />
                    )}
                  </div>
                </div>
                <div className="flex-1">
                  <div>
                    <h6>&nbsp;</h6>
                    <Button variant="text" className={css.buttonDeleteIcon} disableElevation onClick={()=>setDataDelete({type: "collegeDelete", index: {key: "college",index : i}})}>
                      <DeleteOutlineIcon />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {pageData.college.filter((college)=> college.degree.value === "Diploma").length === 0 && (
            <div className={`form-group last-0`}>
            <Button variant="outlined" className={css.buttonSmSuccess} disableElevation onClick={()=>addData("college", defaultData.college, {key: "degree", value: "diploma"})}>
                Add Diploma
              </Button>
            </div>
          )}
          {/* {collegeName, department, cgpa, percentage, historyArrear, currentArrear, degree} */}
          <div className="form-group">
            <h5>UG</h5>
            {pageData.college.length > 0 && pageData.college.map((data, i)=> data.degree.value === "UG" && (
              <div key={`college-${i}`} className="flex-box flex-100 flex-gap-2" style={{margin:"24px 0"}}>
                <div className="flex-3">
                  <div className="form-row flex-box flex-100 flex-gap-2">
                    {data.collegeName && (
                      <EditUserInput type="text" value={data.collegeName.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["college", i, "collegeName"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.collegeName.validation} />
                    )}
                    {data.department && (
                      <EditUserInput type="text" value={data.department.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["college", i, "department"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.department.validation} />
                    )}
                  </div>
                  <div className="form-row flex-box flex-100 flex-gap-2">
                    <div className="flex-1">
                      <Button variant="text" className="h6-button" endIcon={<ExpandMoreIcon />} onClick={(e)=>handleOpenFilter(e, ["college", i])}>
                        {(data.percentage && data.percentage.value !== undefined && data.percentage.value !== null ) ? "Percentage" : "CGPA" }
                      </Button>
                      {data.percentage && data.percentage.value !== undefined && data.percentage.value !== null ? (
                        <EditUserInput type="number" value={data.percentage.value} errorShow={errorShow}
                          setValue={(v)=>updateData({keys: ["college", i, "percentage"], dataName:"value", value: v})}
                          updateError={errorComponentUpdate} validation={data.percentage.validation} />
                        ):(
                        <EditUserInput type="number" value={data.cgpa.value} errorShow={errorShow}
                          setValue={(v)=>updateData({keys: ["college", i, "cgpa"], dataName:"value", value: v})}
                          updateError={errorComponentUpdate} validation={data.cgpa.validation} />
                      )}
                    </div>
                    {data.yearOfPassing && (
                      <EditUserInput type="select" value={data.yearOfPassing.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["college", i, "yearOfPassing"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.yearOfPassing.validation} />
                    )}
                    {data.historyArrear && (
                      <EditUserInput type="number" value={data.historyArrear.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["college", i, "historyArrear"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.historyArrear.validation} />
                    )}
                    {data.currentArrear && (
                      <EditUserInput type="number" value={data.currentArrear.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["college", i, "currentArrear"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.currentArrear.validation} />
                    )}
                  </div>
                </div>
                <div className="flex-1">
                  <div>
                    <h6>&nbsp;</h6>
                    <Button variant="text" className={css.buttonDeleteIcon} disableElevation onClick={()=>setDataDelete({type: "collegeDelete", index: {key: "college",index : i}})}>
                      <DeleteOutlineIcon />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={`form-group ${pageData.college.filter((college)=> college.degree.value === "UG").length === 0 ? "last-0" : "last"}`}>
            <Button variant="outlined" className={css.buttonSmSuccess} disableElevation onClick={()=>addData("college", defaultData.college, {key: "degree", value: "UG"})}>
              Add UG
            </Button>
          </div>
          <div className="form-group">
            <h5>PG</h5>
            {pageData.college.length > 0 && pageData.college.map((data, i)=> data.degree.value === "PG" && (
              <div key={`college-${i}`} className="flex-box flex-100 flex-gap-2" style={{margin:"24px 0"}}>
                <div className="flex-3">
                  <div className="form-row flex-box flex-100 flex-gap-2">
                    {data.collegeName && (
                      <EditUserInput type="text" value={data.collegeName.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["college", i, "collegeName"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.collegeName.validation} />
                    )}
                    {data.department && (
                      <EditUserInput type="text" value={data.department.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["college", i, "department"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.department.validation} />
                    )}
                  </div>
                  <div className="form-row flex-box flex-100 flex-gap-2">
                    <div className="flex-1">
                      <Button variant="text" className="h6-button" endIcon={<ExpandMoreIcon />} onClick={(e)=>handleOpenFilter(e, ["college", i])}>
                        {(data.percentage.value !== undefined && data.percentage.value !== null ) ? "Percentage" : "CGPA" }
                      </Button>
                      {data.percentage.value !== undefined && data.percentage.value !== null ? (
                        <EditUserInput type="number" value={data.percentage.value} errorShow={errorShow}
                          setValue={(v)=>updateData({keys: ["college", i, "percentage"], dataName:"value", value: v})}
                          updateError={errorComponentUpdate} validation={data.percentage.validation} />
                        ):(
                        <EditUserInput type="number" value={data.cgpa.value} errorShow={errorShow}
                          setValue={(v)=>updateData({keys: ["college", i, "cgpa"], dataName:"value", value: v})}
                          updateError={errorComponentUpdate} validation={data.cgpa.validation} />
                      )}
                    </div>
                    {data.yearOfPassing && (
                      <EditUserInput type="number" value={data.yearOfPassing.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["college", i, "yearOfPassing"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.yearOfPassing.validation} />
                    )}
                    {data.historyArrear && (
                      <EditUserInput type="number" value={data.historyArrear.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["college", i, "historyArrear"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.historyArrear.validation} />
                    )}
                    {data.currentArrear && (
                      <EditUserInput type="number" value={data.currentArrear.value} errorShow={errorShow}
                        setValue={(v)=>updateData({keys: ["college", i, "currentArrear"], dataName:"value", value: v})}
                        updateError={errorComponentUpdate} validation={data.currentArrear.validation} />
                    )}
                  </div>
                </div>
                <div className="flex-1">
                  <div>
                    <h6>&nbsp;</h6>
                    <Button variant="text" className={css.buttonDeleteIcon} disableElevation onClick={()=>setDataDelete({type: "collegeDelete", index: {key: "college",index : i}})}>
                      <DeleteOutlineIcon />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={`form-group ${pageData.college.filter((college)=> college.degree.value === "PG").length === 0 ? "last-0" : "last"}`}>
            <Button variant="outlined" className={css.buttonSmSuccess} disableElevation onClick={()=>addData("college", defaultData.college, {key: "degree", value: "PG"})}>
              Add PG
            </Button>
          </div>
          <Menu
            id={`filter-menu`}
            elevation={0}
            anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
            transformOrigin={{ vertical:'top', horizontal:'center' }}
            classes={{ paper:css.dropdownMenu }} 
            anchorEl={anchorEl}
            open={openFilter}
            onClose={handleCloseFilter}
            getContentAnchorEl={null}
          >
            <MenuItem onClick={(e)=>handleFilterMenuItem("cgpa")} >CGPA</MenuItem>
            <MenuItem onClick={(e)=>handleFilterMenuItem("percentage")} >Percentage</MenuItem>
          </Menu>
          <div className="form-group fill-width">
            <div className="form-row flex-box flex-100 flex-gap-2 flex-center">
              <Button variant="outlined" className={css.buttonPrimary} disableElevation 
                onClick={goPrevPage}>
                Prev
              </Button>
              <Button variant="contained" className={css.buttonPrimary} disableElevation
                onClick={goNextPage}>
                Next
              </Button>
            </div>
          </div>
        </React.Fragment>
      )}
      <ConformDelete //diplomaDelete
        Header={
          dataDelete.type === "hscDelete" ? "Delete HSC" :
            "diplomaDelete" ? "Delete diploma" :
            "collegeDelete" ? "Delete college" : ""
        }
        Content={() =>
          dataDelete.type === "hscDelete" ? (
            <p style={paraStyle}><div>Are you sure want to delete HSC?</div><div>You cannot recover once deleted</div></p>
          ) : dataDelete.type === "diplomaDelete" ? (
            <p style={paraStyle}><div>Are you sure want to delete diploma?</div><div>You cannot recover once deleted</div></p>
          ) : dataDelete.type === "collegeDelete" ? (
            <p style={paraStyle}><div>Are you sure want to delete college?</div><div>You cannot recover once deleted</div></p>
          ) : (
            ""
          )
        }
        isOpen={dataDelete.type === "hscDelete" || dataDelete.type === "diplomaDelete" || dataDelete.type === "collegeDelete"}
        handleClose={() => {
          setDataDelete({
            type: "",
            index: null,
          });
        }}
        handleDelete={() => {
          if (dataDelete.type === "hscDelete") setDataDelete({ type: "hscDeleteConform", index: dataDelete.index });
          if (dataDelete.type === "diplomaDelete") setDataDelete({ type: "diplomaDeleteConform", index: dataDelete.index });
          if (dataDelete.type === "collegeDelete") setDataDelete({ type: "collegeDeleteConform", index: dataDelete.index });
        }}
      />
    </React.Fragment>
  );
};