import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import styles from "./index.module.scss";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { Field, reduxForm, getFormValues, change } from "redux-form";
import { connect, useDispatch } from "react-redux";
import EditorField from "../../Tasks/EditorField";
import api from "../../../api/baseConfig";
import apiHelper from "../../../api/apiHelper";
import { toast } from "react-toastify";
import { sessionPrivileges } from "privileges";

const renderField = ({
  input,
  label,
  type,
  disabled,
  len,
  styles,
  meta: { touched, error },
}) => (
  <Col lg={len || 12} style={styles}>
    <Label>{label}</Label>
    <Input
      type={type || "text"}
      {...input}
      disabled={disabled}
      style={{ backgroundColor: disabled ? "#F2F2F2" : "" }}
    />
    {touched && error && <span style={{ color: "red" }}>{error}</span>}
  </Col>
);

const renderCustomSelectField = ({
  label,
  options,
  disabled,
  input,
  len,
  noPadding,
  meta: { touched, error },
}) => (
  <Col lg={len} style={{ paddingLeft: noPadding && 0 }}>
    <Label>{label}</Label>
    <Select
      isDisabled={disabled}
      options={options}
      onChange={(e) => {
        input.onChange(e.value);
      }}
      value={GetObjectFromString(options, input.value)}
    />
    {touched && error && <span style={{ color: "red" }}>{error}</span>}
  </Col>
);

const validTemplateType = (val) => {
  if (!val || !val.length > 0) {
    return "Please Select a Template for";
  }
};
const SubjectName = (value) => {
  if (value === undefined || !value.length > 0) {
    return "Please enter a valid Subject Name";
  }
};
const _DESC = (value) => {
  if (!value) {
    return "Please enter a valid Compose Mail";
  }
};

const CUST = (val) => {
  if (!val || !val.length > 0) {
    return "Please select a Customer";
  }
};

const templateOptions = [
  { label: "Mail", value: "mail" },
  { label: "Message", value: "message" },
];

let initialValues = {};

const CreateTemplate = (props) => {
  const { handleSubmit, formValues, submitting } = props;
  initialValues = {
    customer: props?.location?.state?.row?.template?.customer,
    type: props?.location?.state?.row?.templateFor,
    message: props?.location?.state?.row?.template?.message,
    name: props?.location?.state?.row?.subject,
    id: props?.location?.state?.row?.id,
  };

  const Priv = sessionPrivileges().userprivileges || [];

  const customersCondensed = props.Customers
    ? props.Customers.reduce((acc, val) => {
        acc.push({
          label: val.name,
          value: val.name,
        });
        return acc;
      }, [])
    : [];

  const submit = (values) => {
    let payload = {
      ...values,
    };
    apiHelper
      .axiosCallPost(
        `${api.baseURL}${api.communicationModule.saveTemplate}`,
        "post",
        payload
      )
      .then(() => {
        if (props?.location?.state?.edit) {
          toast.success("Sucessfully Updated!...", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            progress: false,
          });
        } else {
          toast.success("Sucessfully Created!...", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            progress: false,
          });
        }
        props.history.push({
          pathname: "/communication",
        });
      })
      .catch((e) => {});
  };

  return (
    <div className="page-content">
      <Paper
        style={{ padding: 20, width: 742, margin: "0 auto" }}
        elevation={0}
      >
        <h2 className={styles.headFont}>Create a new template</h2>
        <form onSubmit={handleSubmit(submit)}>
          <div className={styles.dropdownWrap}>
            <label>Template for :</label>
            <Field
              name="type"
              id="type"
              component={renderCustomSelectField}
              options={templateOptions}
              validate={[validTemplateType]}
            />
          </div>
          <Row className="mt-3">
            <Field
              name="name"
              id="name"
              label="Name"
              component={renderField}
              validate={[SubjectName]}
            />
          </Row>
          {Priv.includes("MANAGE_CUSTOMER") && (
            <Row className="mt-3">
              <Field
                name="customer"
                id="customer"
                component={renderCustomSelectField}
                options={customersCondensed}
                label="Customer"
                validate={[CUST]}
              />
            </Row>
          )}
          <Row className="mt-3 ml-1">
            <Field
              name="message"
              label="Compose your mail template"
              component={EditorField}
              validate={[_DESC]}
            />
          </Row>
          <div className="mt-3">
            <Button color="primary" type="submit" disabled={submitting}>
              Create a template
            </Button>
            <Button
              color="primary"
              className={styles.custBtn}
              onClick={() => {
                props.history.push({
                  pathname: "/communication",
                });
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Paper>
      <Link
        to={{
          pathname: "/communication",
          state: {
            customer: props?.location?.state?.row?.template?.customer,
          },
        }}
        style={{ marginLeft: "14%" }}
        className="terv-back"
      >
        <i className="mdi mdi-arrow-left mr-1" /> Back to Communication{" "}
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => ({
  initialValues,
  formValues: getFormValues("mailForm")(state),
  Customers: state.StudentAndCustomerReducer.Customers,
});
const mailForm = reduxForm({
  form: "mailForm",
  enableReinitialize: true,
})(CreateTemplate);

export default connect(mapStateToProps, null)(mailForm);
