import React from "react";
import swal from "sweetalert";
//import count from './Login';
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import styles from "./proctring.module.scss";
import "@tensorflow/tfjs";
import "./Detections.css";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";
import Modal from "@material-ui/core/Modal";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import Draggable from "react-draggable";
import { CloseOutlined } from "@material-ui/icons";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import { IconButton } from "@material-ui/core";
import moment from "moment";

var count_facedetect = 0;

export default class Detection extends React.Component {
  constructor() {
    super();

    this.cameraNumber = 0;

    this.state = {
      imageDataURL: null,
      detectType: null,
      open: false,
      captureTrue: true,
      isLive: true,
    };
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
  }
  handleOpenDialog() {
    this.setState({
      openDialog: true,
    });
  }
  videoRef = React.createRef();
  canvasRef = React.createRef();
  componentDidMount() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.setState({ imageDataURL: null });
      const webCamPromise = navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode: "user",
            width: 500,
            height: 300,
          },
        })
        .then((stream) => {
          window.stream = stream;
          this.videoRef.current.srcObject = stream;
          return new Promise((resolve, reject) => {
            this.videoRef.current.onloadedmetadata = () => {
              resolve();
            };
          });
        });
      const modelPromise = cocoSsd.load();
      Promise.all([modelPromise, webCamPromise])
        .then((values) => {
          this.detectFrame(this.videoRef.current, values[0]);
        })
        .catch((error) => {
          //console.error(error);
        });
    }
  }
  componentWillUnmount() {
    const stream = this.videoRef.current.srcObject;
    const tracks = stream.getTracks();

    tracks.forEach(function (track) {
      track.stop();
    });

    this.videoRef.current.srcObject = null;
  }

  detectFrame = (video, model) => {
    model.detect(video).then((predictions) => {
      if (this.canvasRef.current) {
        this.renderPredictions(predictions);
        requestAnimationFrame(() => {
          this.detectFrame(video, model);
        });
      } else {
        return false;
      }
    });
  };

  capturePicture = (prediction) => {
    var canvas = document.createElement("canvas");
    canvas.width = 530;
    canvas.height = 530;
    var contex = canvas.getContext("2d");
    contex.drawImage(this.videoRef.current, 0, 0, canvas.width, canvas.height);
    var imageLink = canvas.toDataURL();
    this.setState({ imageDataURL: imageLink });
    this.imageCaptureSend(imageLink, prediction);
  };

  imageCaptureRestrict = () => {
    setTimeout(() => {
      this.setState({ captureTrue: true });
    }, 10000);
  };

  imageCaptureSend = async (image, prediction) => {
    if (this.state.captureTrue && this.props?.timerStart) {
      const d = new Date();
      let time = d.getTime();
      let body = {
        logName: prediction,
        time: moment().format("yyyy-MM-DDThh:mm:ss"),
        duration: this.props.elapsedTime,
        uploadURL: image,
      };
      try {
        this.setState({ captureTrue: false });
        this.imageCaptureRestrict();
        const data = await Apihelper.axiosCallPost(
          `${api.baseURL}${api.AiProctoring.AiSaveCall}${this.props.userId}/${this.props.assessmentId}`,
          "post",
          body
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  renderPredictions = (predictions) => {
    //var count=100;
    const ctx = this.canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    // Font options.
    const font = "16px sans-serif";
    ctx.font = font;
    ctx.textBaseline = "top";
    predictions.forEach((prediction) => {
      const x = prediction.bbox[0];
      const y = prediction.bbox[1];
      const width = prediction.bbox[2];
      const height = prediction.bbox[3];
      // Draw the bounding box.
      ctx.strokeStyle = "#00FFFF";
      ctx.lineWidth = 2;
      ctx.strokeRect(x, y, width, height);
      // Draw the label background.
      ctx.fillStyle = "#00FFFF";
      const textWidth = ctx.measureText(prediction.class).width;
      const textHeight = parseInt(font, 10); // base 10
      ctx.fillRect(x, y, textWidth + 8, textHeight + 8);

      var multiple_face = 0;
      for (let i = 0; i < predictions.length; i++) {
        //Face,object detection
        if (predictions[i].class === "cell phone") {
          if (this.props?.timerStart) {
            this.setState({ detectType: "cell phone" });
            this.setState({ open: true });
            count_facedetect = count_facedetect + 1;
          }
        } else if (predictions[i].class === "book") {
          if (this.props?.timerStart) {
            this.setState({ detectType: "book" });
            this.setState({ open: true });
            count_facedetect = count_facedetect + 1;
          }
        } else if (predictions[i].class === "laptop") {
          if (this.props?.timerStart) {
            this.setState({ detectType: "laptop" });
            this.setState({ open: true });
            count_facedetect = count_facedetect + 1;
          }
        } else if (predictions[i].class !== "person") {
          if (this.props?.timerStart) {
            this.setState({ detectType: "out of frame" });
            this.setState({ open: true });
            count_facedetect = count_facedetect + 1;
          }
        }
      }
    });

    predictions.forEach((prediction) => {
      const x = prediction.bbox[0];
      const y = prediction.bbox[1];
      // Draw the text last to ensure it's on top.
      ctx.fillStyle = "#000000";
      if (
        prediction.class === "person" ||
        prediction.class === "cell phone" ||
        prediction.class === "book" ||
        prediction.class === "laptop"
      ) {
        ctx.fillText(prediction.class, x, y);
      }
      if (
        prediction.class === "cell phone" ||
        prediction.class === "book" ||
        prediction.class === "laptop" ||
        prediction.class !== "person"
      ) {
        ctx.fillText(prediction.class, x, y);
        this.capturePicture(
          prediction.class === "cell phone"
            ? "cell phone"
            : prediction.class === "book"
            ? "book"
            : prediction.class === "laptop"
            ? "laptop"
            : "out of frame"
        );
      }
    });
    sessionStorage.setItem("count_facedetect", count_facedetect);
  };

  getListOfVideoInputs = async () => {
    // Get the details of audio and video output of the device
    const enumerateDevices = await navigator.mediaDevices.enumerateDevices();

    //Filter video outputs (for devices with multiple cameras)
    return enumerateDevices.filter((device) => device.kind === "videoinput");
  };

  render() {
    return (
      <>
        <Draggable>
          <div style={{ position: "fixed", width: "300px", zIndex: 100 }}>
            <div
              style={{ position: "relative", width: "100%", paddingTop: "80%" }}
            >
              {this.state.isLive && (
                <IconButton
                  aria-label="delete"
                  size="small"
                  className={
                    this.props?.timerStart ? "xmarkBlock" : "xmarkHide"
                  }
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    zIndex: 1,
                  }}
                  onClick={() => this.setState({ isLive: false })}
                >
                  <CloseOutlined />
                </IconButton>
              )}
              <video
                className={
                  this.state.isLive && this.props?.timerStart
                    ? "size"
                    : "noSize"
                }
                autoPlay
                playsInline
                muted
                ref={this.videoRef}
              />
            </div>
            {!this.state.isLive && (
              <div
                style={{ border: "10px" }}
                onClick={() => this.setState({ isLive: true })}
              >
                <div>
                  <svg
                    width="132"
                    height="216"
                    viewBox="0 0 132 216"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_dd_13225_52281)">
                      <rect
                        x="84"
                        y="66"
                        width="48"
                        height="48"
                        rx="5"
                        fill="#D8D6DB"
                      />
                      <path
                        d="M103.5 84.5C103.5 86.981 105.519 89 108 89C110.481 89 112.5 86.981 112.5 84.5C112.5 82.019 110.481 80 108 80C105.519 80 103.5 82.019 103.5 84.5ZM116 99H117V98C117 94.141 113.859 91 110 91H106C102.14 91 99 94.141 99 98V99H116Z"
                        fill="#63606C"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_dd_13225_52281"
                        x="0"
                        y="0"
                        width="216"
                        height="216"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feMorphology
                          radius="4"
                          operator="erode"
                          in="SourceAlpha"
                          result="effect1_dropShadow_13225_52281"
                        />
                        <feOffset dy="18" />
                        <feGaussianBlur stdDeviation="44" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0 0.294118 0 0 0 0.14 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_13225_52281"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feMorphology
                          radius="6"
                          operator="erode"
                          in="SourceAlpha"
                          result="effect2_dropShadow_13225_52281"
                        />
                        <feOffset dy="8" />
                        <feGaussianBlur stdDeviation="14" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0 0.294118 0 0 0 0.12 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="effect1_dropShadow_13225_52281"
                          result="effect2_dropShadow_13225_52281"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect2_dropShadow_13225_52281"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>
              </div>
            )}
            <canvas ref={this.canvasRef} width="0" height="0" />
          </div>
        </Draggable>
        {/* <div style={{position:'absolute'}}>
          <canvas
            className="size"
            id="newImage"
            ref={this.canvasRef}
            width="300"
            height="400"
          >
            <img src={this.state.imageDataURL} alt='image'/>
          </canvas>
        </div> */}
        <Dialog open={this.state.open} onClose={!this.state.open}>
          <div
            style={{
              background: " #DE5445",
              height: "4px",
            }}
          ></div>
          <DialogContent classes={{ root: styles.dialogContent }}>
            <div className={styles.dialogMain}>
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="#FFF3F1" />
                <path
                  d="M34.2763 15.0586C34.1527 14.9346 34.0058 14.8362 33.844 14.7691C33.6822 14.7021 33.5088 14.6677 33.3337 14.668H22.667C22.4919 14.6677 22.3184 14.7021 22.1566 14.7691C21.9949 14.8362 21.8479 14.9346 21.7243 15.0586L15.0577 21.7253C14.9336 21.8489 14.8352 21.9958 14.7682 22.1576C14.7011 22.3194 14.6667 22.4928 14.667 22.668V33.3346C14.667 33.6893 14.807 34.028 15.0577 34.2773L21.7243 40.944C21.8479 41.068 21.9949 41.1664 22.1566 41.2335C22.3184 41.3005 22.4919 41.3349 22.667 41.3346H33.3337C33.6883 41.3346 34.027 41.1946 34.2763 40.944L40.943 34.2773C41.0671 34.1537 41.1654 34.0068 41.2325 33.845C41.2995 33.6832 41.3339 33.5098 41.3337 33.3346V22.668C41.3339 22.4928 41.2995 22.3194 41.2325 22.1576C41.1654 21.9958 41.0671 21.8489 40.943 21.7253L34.2763 15.0586ZM29.3337 34.668H26.667V32.0013H29.3337V34.668ZM29.3337 29.3346H26.667V21.3346H29.3337V29.3346Z"
                  fill="#DE5445"
                />
              </svg>
            </div>
            <p classes={{ root: styles.dialogPara }}>
              <div>Warning! Some unusual action has been identified,</div>
              <div>This assessment is under monitoring</div>
            </p>
          </DialogContent>
          <DialogActions classes={{ root: styles.dialogActions }}>
            <Button
              variant="contained"
              onClick={() => this.setState({ open: false })}
              disableElevation
              classes={{ root: styles.buttonYes }}
            >
              I understand
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
