import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import apiHelper from "../../../api/apiHelper";
import api from "../../../api/baseConfig";
import { useParams, Link } from "react-router-dom";
import "../hackathonStudent/hackathons.css";
import { TervButtonOutlined, TervButtonFilled } from "components/TervButtons";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmissionSchema } from "./ValidationSchemas";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GreenToast, RedToast } from "components/GreenToast";
import { DefaultToast } from "components/DefaultToast";
import InfoIcon from "@material-ui/icons/Info";
import Modal from "@material-ui/core/Modal";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import { useHistory } from "react-router-dom";

import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import ProjectSubmissionForm from "./hackathonSubmissionForm";
import { FormControlLabel, Radio } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import { useMediaQuery } from "@material-ui/core";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import clsx from "clsx";
import { ReactComponent as Hackathonemptystate } from "./Hackathonemptystate.svg";

import styles from "./projectSubmission.module.scss";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#ffffff",
  },

  paper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    zIndex: 0,
    background: "#F2F1F3",
    border: "none",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  submission: {
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "32px",
    color: "#19181B",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "26px",
    color: "#323036",
  },
  paragraph: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#63606C",
    width: "35rem",
  },
  pos: {
    marginBottom: 12,
  },
  hackathonList: {
    width: "60rem",
  },
  hackathonListMobile: {
    width: "22.5rem",
  },
}));

const CustomFormLabel = withStyles({
  label: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "0.9375rem",
    lineHeight: "1.375rem",
    color: "#323036",
  },
})(FormControlLabel);

const CustomCheckbox = withStyles({
  root: {
    color: "#794df5",
    "&$checked": {
      color: "#794df5",
    },
  },
  icon: {
    borderRadius: "",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
})(Radio);

const paraStyle = {
  fontSize: 16,
  fontWeight: 500,
  color: "#323036",
  marginTop: 12,
  textAlign: "center",
  fontFamily: "Poppins",
  fontStyle: "normal",
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function ProjectSubmission(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [hackathonDataDetails, setHackathonDataDetails] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [submission, setSubmission] = useState(false);
  const [formValue, setFormValue1] = useState(false);
  const [hackathonList, setHackathonList] = useState();
  const [submissionId, setSubmissionId] = useState([]);
  const [projectId, setProjectId] = useState([]);
  const [userHackathonId, setUserHackathonId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hackathonId, setHackathonId] = useState();
  const [hackathonListLength, setHackathonListLength] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const history = useHistory();

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(SubmissionSchema),
  });

  const {
    register,
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
  } = methods;
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { id } = useParams();

  useEffect(() => {
    hackathonDetails();
    getHackathonSubmissionList();
  }, []);

  useEffect(() => {
    if (formValue) {
      const value = hackathonList || formValue;
      reset({
        projectSubmission: value?.projectSubmission ?? "",
      });
    }
  }, [reset]);

  const hackathonDetails = async () => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.Hackathon.getHackathonDetails}${id}`,
        "get"
      );
      setHackathonDataDetails(data);
    } catch (e) {}
  };

  const getHackathonSubmissionList = async (hackathonListIndex) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.Hackathon.getHackathonSubmissionList}/${id}`,
        "get"
      );
      if (data) {
        const newList = [];
        data.forEach((course) => {
          newList.push(course);
        });
        setHackathonList(newList);
        setHackathonListLength(data.length);
        setSubmissionId(newList[`${hackathonListIndex}`]?.id);
        setUserHackathonId(newList[`${hackathonListIndex}`]?.userHackathonId);
        setHackathonId(newList[`${hackathonListIndex}`]?.hackathonId);
      }
    } catch (e) {}
  };

  const hackathonProjectSubmission = async () => {
    const url = `${api.baseURL}${api.Hackathon.projectSubmit}`;
    const payload = {
      id: userHackathonId,
      submissionsSelected: [submissionId],
    };
    try {
      const data = await apiHelper.axiosCallPost(url, "post", payload);
      toast.success("Successfully submitted Your Project!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
      hackathonDetails();
      getHackathonSubmissionList();
    } catch (error) {
      throw new Error(`Some error occurred: ${error}`);
      toast.error("failed to submit your project", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
    } finally {
    }
  };

  const handleClose = () => {
    setOpen(close);
  };

  const submissionDeleteModal = (i) => {
    setDeleteModal(true);
    setOpen(true);
  };

  async function submissionDelete(i) {
    await apiHelper
      .axiosCall(
        `${api.baseURL}${api.Hackathon.submissionDelete}/${
          hackathonList[`${i}`]?.id
        }`,
        "delete"
      )
      .then((data) => {
        toast.error("draft delete successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
        getHackathonSubmissionList();
        setButtonDisable(true);
        handleClose();
      })
      .catch(() => {});
  }

  // const submissionDelete = async (i) => {
  //   setButtonDisable(true)
  //   try {
  //     const data = await apiHelper.axiosCall(
  //       `${api.baseURL}${api.Hackathon.submissionDelete}/${
  //         hackathonList[`${i}`]?.id
  //       }`,
  //       "delete"
  //     );
  //     RedToast({ message: "draft delete successfully" });
  //     getHackathonSubmissionList();
  //     setButtonDisable(true)
  //     handleClose();
  //   } catch (e) {
  //     throw new Error(`Some error occurred: ${e}`);
  //   }
  // }
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const submitReports = hackathonList?.some((e) => e.submitSelection);
  const drawer = (
    <div className="pt-5">
      <div className={classes.toolbar}>
        <List component="nav" aria-label="main mailbox folders">
          <div
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            className="pt-3"
          >
            <ListItem
              button
              onClick={() => {
                history.push(`/hackathonInfo/${props?.location?.state}`);
              }}
            >
              <ArrowBackSharpIcon />
              <ListItemText primary="Back" />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
              style={
                selectedIndex === 0
                  ? {
                      backgroundColor: "#1BA94C",
                      color: "#fff",
                      borderRadius: "10px",
                    }
                  : {}
              }
            >
              <ListItemText primary="Submissions" />
            </ListItem>
          </div>
          <div
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            className="pt-3"
          >
            <ListItem
              button
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
              style={
                selectedIndex === 1
                  ? {
                      backgroundColor: "#1BA94C",
                      color: "#fff",
                      borderRadius: "10px",
                    }
                  : {}
              }
            >
              <ListItemText primary="Team" />
            </ListItem>
          </div>
          <div
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            className="pt-3"
          >
            <ListItem
              button
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
              style={
                selectedIndex === 2
                  ? {
                      backgroundColor: "#1BA94C",
                      color: "#fff",
                      borderRadius: "10px",
                    }
                  : {}
              }
            >
              <ListItemText primary="Participants" />
            </ListItem>
          </div>
        </List>
      </div>
    </div>
  );

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List component="nav" aria-label="main mailbox folders">
        <ListItem
          button
          onClick={() => {
            history.push(
              `/hackathonRegister/${props?.location?.state?.hackathonDataDetails?.id}`
            );
          }}
        >
          <ArrowBackSharpIcon />
          <ListItemText primary="Back" />
        </ListItem>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <ListItem
            button
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
            style={
              selectedIndex === 0
                ? {
                    backgroundColor: "#1BA94C",
                    color: "#fff",
                    borderRadius: "10px",
                  }
                : {}
            }
          >
            <ListItemText primary="Submissions" />
          </ListItem>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <ListItem
            button
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
            style={
              selectedIndex === 1
                ? {
                    backgroundColor: "#1BA94C",
                    color: "#fff",
                    borderRadius: "10px",
                  }
                : {}
            }
          >
            <ListItemText primary="Team" />
          </ListItem>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <ListItem
            button
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
            style={
              selectedIndex === 2
                ? {
                    backgroundColor: "#1BA94C",
                    color: "#fff",
                    borderRadius: "10px",
                  }
                : {}
            }
          >
            <ListItemText primary="Participants" />
          </ListItem>
        </div>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div className={classes.root} style={{ flex: "1 1 0%", height: "100%" }}>
      <ToastContainer />
      {smallScreen && (
        <div style={{ position: "absolute", top: "1rem" }}>
          <div style={{ display: "flex" }}>
            {["left"].map((anchor) => (
              <div key={anchor}>
                <Button onClick={toggleDrawer(anchor, true)}>
                  <svg
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.6665 0H0.333172V2.66667H21.6665V0ZM16.3332 6.66667H0.333172V9.33333H16.3332V6.66667ZM9.6665 13.3333H0.333172V16H9.6665V13.3333Z"
                      fill="#63606C"
                    />
                  </svg>
                </Button>
                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                >
                  {list(anchor)}
                </SwipeableDrawer>
              </div>
            ))}
            <div>
              <p className="mobileNavHeader pl-3">Hackathon submission</p>
            </div>
          </div>
        </div>
      )}
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      {submission ? (
        <ProjectSubmissionForm
          setSubmission={setSubmission}
          getHackathonSubmissionList={getHackathonSubmissionList}
        />
      ) : (
        <div style={{ flex: "1 1 0%" }}>
          {loading ? (
            <>
              <Skeleton height={100} />
            </>
          ) : (
            <main className={classes.content} style={{ height: "100%" }}>
              <Typography className={classes.submission}>
                {hackathonDataDetails?.name}
              </Typography>
              {!smallScreen && (
                <Typography paragraph>
                  You can add maximum 2 drafts and submit only one
                </Typography>
              )}
              {smallScreen && (
                <h5 className={styles.submit}>
                  You can add maximum 2 drafts and submit only one
                </h5>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 1 0%",
                }}
              >
                <div className="pt-3">
                  <div>
                    {hackathonList?.length > 0 &&
                      hackathonList?.map((data, index) => (
                        <div className="pt-2 pb-2 ">
                          <Card
                            className={
                              smallScreen
                                ? classes.hackathonListMobile
                                : classes.hackathonList
                            }
                            variant="outlined"
                            key={index}
                            style={
                              data.submitSelection
                                ? { background: "#FAFBFC" }
                                : { background: "#fff" }
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div className="pl-3">
                                <div>
                                  {!hackathonDataDetails?.submitted ? (
                                    <input
                                      value={`hackathonId${index}`}
                                      name="projectSubmission"
                                      {...register("projectSubmission")}
                                      style={{
                                        transform: "scale(1.5)",
                                        backgroudColor: "#323036",
                                      }}
                                      onClick={() => {
                                        getHackathonSubmissionList(index);
                                      }}
                                      type="radio"
                                    />
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              </div>

                              <CardContent>
                                <Typography className={classes.title}>
                                  {data?.projectName}
                                </Typography>
                                <Typography className={classes.paragraph}>
                                  {hackathonDataDetails?.submitted ? (
                                    <div>
                                      {`Submitted on ${moment(
                                        data?.createdOn
                                      ).format("DD MMM YYYY, HH:mm A")}`}
                                    </div>
                                  ) : (
                                    <div>
                                      {`created on ${moment(
                                        data?.createdOn
                                      ).format("DD MMM YYYY, HH:mm A")}`}
                                    </div>
                                  )}
                                </Typography>
                                {smallScreen && (
                                  <div
                                    className="pt-3"
                                    style={{ display: "flex" }}
                                  >
                                    {!hackathonDataDetails?.submitted ? (
                                      <Link
                                        to={{
                                          pathname: `/hackathonSubmissionform/${data.id}`,
                                          state: {
                                            fromEdit: true,
                                            submissionId,
                                            hackathonDataDetails:
                                              hackathonDataDetails,
                                          },
                                        }}
                                      >
                                        <TervButtonOutlined
                                          text={"View"}
                                          variant="outlined"
                                          // onClick={}
                                          style={{ width: "16.5rem" }}
                                        />
                                      </Link>
                                    ) : data?.submitSelection ? (
                                      <Button
                                        style={{
                                          color: "#089A79",
                                          background: "#F1FFFC",
                                          fontWeight: "500",
                                          fontSize: "11px",
                                          textTransform: "capitalize",
                                          position: "absolute",
                                        }}
                                      >
                                        <CheckCircleIcon
                                          style={{ width: "15px" }}
                                        />
                                        You submitted this resource
                                      </Button>
                                    ) : (
                                      <div></div>
                                    )}
                                    <div>
                                      {!hackathonDataDetails?.submitted ? (
                                        <Button
                                          onClick={() =>
                                            submissionDeleteModal(index, 1)
                                          }
                                          style={{
                                            color: "#DE5445",
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <svg
                                            width="18"
                                            height="20"
                                            viewBox="0 0 18 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg "
                                            style={{
                                              borderRadius: "10",
                                              borderColor: "red",
                                            }}
                                          >
                                            <path
                                              d="M3 5H2V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H14C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18V5H3ZM7 17H5V8H7V17ZM13 17H11V8H13V17ZM13.618 2L12 0H6L4.382 2H0V4H18V2H13.618Z"
                                              fill="#D42216"
                                            />
                                          </svg>
                                        </Button>
                                      ) : (
                                        ""
                                      )}

                                      <div>
                                        {data?.submitSelection ? (
                                          <div
                                            style={{
                                              paddingTop: "60px",
                                              paddingRight: "30px",
                                            }}
                                          >
                                            <Link
                                              to={{
                                                pathname: `/hackathonSubmissionform/${data.id}`,
                                                state: {
                                                  fromEdit: true,
                                                  submissionId,
                                                  hackathonDataDetails:
                                                    hackathonDataDetails,
                                                },
                                              }}
                                            >
                                              <TervButtonOutlined
                                                text={"View"}
                                                variant="outlined"
                                                // onClick={}
                                                style={{ width: "19.5rem" }}
                                              />
                                            </Link>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </CardContent>
                              {!smallScreen && (
                                <CardActions>
                                  <div style={{ display: "flex" }}>
                                    {!hackathonDataDetails?.submitted ? (
                                      <Button
                                        onClick={() =>
                                          submissionDeleteModal(index)
                                        }
                                      >
                                        <svg
                                          width="18"
                                          height="20"
                                          viewBox="0 0 18 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          border="1px solid red"
                                        >
                                          <path
                                            d="M3 5H2V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H14C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18V5H3ZM7 17H5V8H7V17ZM13 17H11V8H13V17ZM13.618 2L12 0H6L4.382 2H0V4H18V2H13.618Z"
                                            fill="#D42216"
                                          />
                                        </svg>
                                      </Button>
                                    ) : !data?.submitSelection ? (
                                      <div
                                        style={{
                                          color: "white",
                                          fontWeight: "600",
                                          fontSize: "14px",
                                          textTransform: "capitalize",
                                          width: "227px",
                                        }}
                                      ></div>
                                    ) : (
                                      <Button
                                        style={{
                                          color: "#089A79",
                                          background: "#F1FFFC",
                                          fontWeight: "500",
                                          lineHeight: "24px",
                                          fontSize: "13px",
                                          textTransform: "capitalize",
                                          fontFamily: "Poppins",
                                          fontStyle: "normal",
                                        }}
                                      >
                                        <svg
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.00016 0.332031C3.32416 0.332031 0.333496 3.3227 0.333496 6.9987C0.333496 10.6747 3.32416 13.6654 7.00016 13.6654C10.6762 13.6654 13.6668 10.6747 13.6668 6.9987C13.6668 3.3227 10.6762 0.332031 7.00016 0.332031ZM5.6675 9.9407L3.19216 7.4707L4.1335 6.5267L5.66616 8.0567L9.1955 4.52736L10.1382 5.47003L5.6675 9.9407Z"
                                            fill="#089A79"
                                          />
                                        </svg>
                                        You submitted this resource
                                      </Button>
                                    )}
                                    <Link
                                      to={{
                                        pathname: `/hackathonSubmissionform/${data.id}`,
                                        state: {
                                          fromEdit: true,
                                          submissionId,
                                          hackathonDataDetails:
                                            hackathonDataDetails,
                                        },
                                      }}
                                    >
                                      <TervButtonOutlined
                                        text={"View"}
                                        variant="outlined"
                                        // onClick={}
                                        style={{ width: "5rem" }}
                                      />
                                    </Link>
                                  </div>
                                </CardActions>
                              )}
                            </div>
                          </Card>
                          {deleteModal && (
                            <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="simple-modal-title"
                              aria-describedby="simple-modal-description"
                            >
                              <div style={modalStyle} className={classes.paper}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <br />
                                  <p style={paraStyle}>
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="49"
                                        height="48"
                                        viewBox="0 0 49 48"
                                        fill="none"
                                      >
                                        <path
                                          d="M8.5 8V40C8.5 41.0609 8.92143 42.0783 9.67157 42.8284C10.4217 43.5786 11.4391 44 12.5 44H36.5C37.5609 44 38.5783 43.5786 39.3284 42.8284C40.0786 42.0783 40.5 41.0609 40.5 40V16.684C40.4999 16.1511 40.3934 15.6236 40.1866 15.1325C39.9799 14.6414 39.6771 14.1965 39.296 13.824L30.416 5.14C29.6687 4.40932 28.6651 4.00013 27.62 4H12.5C11.4391 4 10.4217 4.42143 9.67157 5.17157C8.92143 5.92172 8.5 6.93913 8.5 8V8Z"
                                          stroke="#BDBBC3"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M18.5 26H30.5"
                                          stroke="#BDBBC3"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M18.5 34H24.5"
                                          stroke="#BDBBC3"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M28.5 4V12C28.5 13.0609 28.9214 14.0783 29.6716 14.8284C30.4217 15.5786 31.4391 16 32.5 16H40.5"
                                          stroke="#BDBBC3"
                                          strokeWidth="2"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>

                                    <div>
                                      {" "}
                                      Are you sure want to delete this
                                      Submission?
                                    </div>
                                    <div style={{ alignItems: "center" }}>
                                      {" "}
                                      Once deleted cannot be recovered
                                    </div>
                                  </p>
                                </div>
                                <br />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div style={{ paddingRight: "24px" }}>
                                    <button
                                      style={{
                                        background: "#F2F1F3",
                                        paddingLeft: "38px",
                                        paddingRight: "37px",
                                        paddingTop: "8px",
                                        paddingBottom: "7px",
                                        border: "none",
                                        borderRadius: "3px",
                                      }}
                                      onClick={handleClose}
                                    >
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          fontStyle: "normal",
                                          fontWeight: "normal",
                                          fontSize: "14px",
                                          lineHeight: "26px",
                                          color: "#4A4851",
                                          textAlign: "center",
                                        }}
                                      >
                                        Cancel
                                      </span>
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      style={{
                                        background: "#D42216",
                                        color: "#ffffff",
                                        paddingLeft: "38px",
                                        paddingRight: "37px",
                                        paddingTop: "8px",
                                        paddingBottom: "7px",
                                        border: "none",
                                        borderRadius: "3px",
                                      }}
                                      onClick={() => {
                                        submissionDelete(index);
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          fontStyle: "normal",
                                          fontWeight: "normal",
                                          fontSize: "14px",
                                          lineHeight: "26px",
                                          color: "#ffffff",
                                          textAlign: "center",
                                        }}
                                      >
                                        Delete
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div
                style={
                  smallScreen
                    ? { display: "flex", flexDirection: "column" }
                    : {
                        display: "flex",
                        marginTop: "32px",
                        flex: "1 1 0%",
                        alignItems: "center",
                      }
                }
              >
                {hackathonListLength !== 0 && (
                  <div className="pt-1">
                    {hackathonId == undefined ||
                    submitReports ||
                    hackathonDataDetails?.submitted ? (
                      <div>
                        {/* {!smallScreen && (
                      <TervButtonFilled
                        text={"Submit this resource"}
                        variant="outlined"
                        color="#96939F"
                        disabledSubmission
                        style={{ width: "15rem" }}
                      />)}
                       {smallScreen && (
                      <TervButtonFilled
                        text={"Submit this resource"}
                        variant="outlined"
                        color="#96939F"
                        disabledSubmission
                        style={{ width: "22.5rem" }}
                      />)} */}
                      </div>
                    ) : (
                      <div>
                        {!smallScreen && (
                          <TervButtonFilled
                            text={"Submit this resource"}
                            variant="outlined"
                            onClick={() => {
                              hackathonProjectSubmission();
                            }}
                            style={{ width: "15rem" }}
                          />
                        )}
                        {smallScreen && (
                          <TervButtonFilled
                            text={"Submit this resource"}
                            variant="outlined"
                            onClick={() => {
                              hackathonProjectSubmission();
                            }}
                            style={{ width: "22.5rem" }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="pr-3 pt-1">
                  {hackathonListLength == 0 && (
                    <div className="container">
                      <Hackathonemptystate />
                      <h4 className={styles.add}>
                        Start by adding submission draft
                      </h4>
                      <p className={styles.add2}>
                        Click button below to add submission draft
                      </p>
                    </div>
                  )}

                  {hackathonListLength >= 2 ||
                  submitReports ||
                  hackathonDataDetails?.submitted ? (
                    <div></div>
                  ) : (
                    <div className="container">
                      {!smallScreen && (
                        <Link
                          style={{ display: "inline-block" }}
                          to={{
                            pathname: `/hackathonSubmissionform`,
                            state: {
                              fromEdit: true,
                              submissionId,
                              hackathonDataDetails: hackathonDataDetails,
                            },
                          }}
                        >
                          <TervButtonOutlined
                            text={
                              hackathonListLength == 0
                                ? "Add Draft"
                                : "Add another draft"
                            }
                            variant="outlined"
                            color="#1BA94C"
                            icon={
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14 6H8V0H6V6H0V8H6V14H8V8H14V6Z"
                                  fill="#1BA94C"
                                />
                              </svg>
                            }
                            style={{
                              width: "15rem",
                              border: "1px solid #1BA94C",
                            }}
                          />
                        </Link>
                      )}
                      {smallScreen && (
                        <Link
                          style={{ display: "inline-block" }}
                          to={{
                            pathname: `/hackathonSubmissionform`,
                            state: {
                              fromEdit: true,
                              submissionId,
                              hackathonDataDetails: hackathonDataDetails,
                            },
                          }}
                        >
                          <TervButtonOutlined
                            text={
                              hackathonListLength == 0
                                ? "Add Draft"
                                : "Add another draft"
                            }
                            variant="outlined"
                            color="#1BA94C"
                            icon={
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14 6H8V0H6V6H0V8H6V14H8V8H14V6Z"
                                  fill="#1BA94C"
                                />
                              </svg>
                            }
                            style={{
                              width: "21.5rem",
                              border: "1px solid #1BA94C",
                            }}
                          />
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <br />
              {/* <div className="pt-3">
                <div style={{ display: "flex" }}>
                  <div style={{ paddingTop: "1px" }}>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM7.5 10.375C7.5 10.4438 7.44375 10.5 7.375 10.5H6.625C6.55625 10.5 6.5 10.4438 6.5 10.375V6.125C6.5 6.05625 6.55625 6 6.625 6H7.375C7.44375 6 7.5 6.05625 7.5 6.125V10.375ZM7 5C6.80374 4.99599 6.61687 4.91522 6.47948 4.775C6.3421 4.63478 6.26515 4.4463 6.26515 4.25C6.26515 4.0537 6.3421 3.86522 6.47948 3.725C6.61687 3.58478 6.80374 3.50401 7 3.5C7.19626 3.50401 7.38313 3.58478 7.52052 3.725C7.6579 3.86522 7.73485 4.0537 7.73485 4.25C7.73485 4.4463 7.6579 4.63478 7.52052 4.775C7.38313 4.91522 7.19626 4.99599 7 5Z"
                        fill="#EEB60C"
                      />
                    </svg>
                  </div>
                  <span className="pl-2">
                    You can add maximum 2 submissions
                  </span>
                </div>
              </div> */}

              {/* <div className={styles.loadDataMain} onClose={handleClose}>
        <div className={styles.loadDataText}>
        <InfoIcon style={{width:"20px" ,color:"orange",height:"20px", top:"1px", }} />
          <p>
            You can submit only one Resource from draft,
          </p>
        </div>
      </div> */}
            </main>
          )}
        </div>
      )}
    </div>
  );
}

export default ProjectSubmission;
