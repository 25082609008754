import styles from "./PracticeHeroCard.module.scss";
import stylesMobile from "./PracticeHeroCardMobile.module.scss";
import { useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const PracticeHeroCard = () => {
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const { t } = useTranslation();
  const SVGMobile = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="160"
      height="90"
      viewBox="0 0 272 150"
      fill="none"
    >
      <path
        d="M3.6525 170L158.821 169.439L200.182 169.43L213.884 169.427L255.325 169.418L444.648 169.382C444.648 169.382 487.951 130.669 441.695 93.9005C393.737 55.7783 370.417 86.8249 355.674 33.1633C318.569 -101.914 -48.3076 -23.2965 47.9203 91.6469C82.5695 133.034 -20.283 111.036 3.6525 170Z"
        fill="#F8FCFF"
      />
      <g filter="url(#filter0_dd)">
        <path
          d="M179.295 120.453H64.3206C61.4941 120.453 59.2031 118.163 59.2031 115.337V18.8783C59.2031 16.053 61.4941 13.763 64.3206 13.763H179.295C182.122 13.763 184.413 16.053 184.413 18.8783V115.337C184.414 118.163 182.122 120.453 179.295 120.453Z"
          fill="white"
        />
        <path
          d="M184.414 25.6019H59.2031V17.5821C59.2031 15.4727 60.9136 13.763 63.0239 13.763H180.593C182.703 13.763 184.414 15.4727 184.414 17.5821V25.6019Z"
          fill="#699DEE"
        />
        <path
          d="M64.2695 19.6059C64.2695 18.8237 64.9032 18.19 65.6854 18.19C66.4676 18.19 67.1013 18.8237 67.1013 19.6059C67.1013 20.3881 66.4676 21.0218 65.6854 21.0218C64.9032 21.0207 64.2695 20.387 64.2695 19.6059Z"
          fill="#DDE7FE"
        />
        <path
          d="M70.2188 19.6051C70.2188 18.8229 70.8524 18.1893 71.6346 18.1893C72.4158 18.1893 73.0505 18.8229 73.0505 19.6051C73.0505 20.3874 72.4169 21.021 71.6346 21.021C70.8524 21.02 70.2188 20.3863 70.2188 19.6051Z"
          fill="#DDE7FE"
        />
        <path
          d="M76.1582 19.6051C76.1582 18.8229 76.7919 18.1893 77.5741 18.1893C78.3563 18.1893 78.99 18.8229 78.99 19.6051C78.99 20.3874 78.3563 21.021 77.5741 21.021C76.7919 21.02 76.1582 20.3863 76.1582 19.6051Z"
          fill="#DDE7FE"
        />
        <path
          opacity="0.5"
          d="M104.63 35.001H69.1367V70.4791H104.63V35.001Z"
          fill="#CDE0FB"
        />
        <path
          opacity="0.5"
          d="M142.503 37.6493H114.271C113.53 37.6493 112.93 37.0488 112.93 36.3082C112.93 35.5677 113.53 34.9672 114.271 34.9672H142.503C143.244 34.9672 143.844 35.5677 143.844 36.3082C143.843 37.0498 143.244 37.6493 142.503 37.6493Z"
          fill="#7C9AF2"
        />
        <path
          opacity="0.5"
          d="M176.262 35.8502H148.031C147.29 35.8502 146.689 35.2496 146.689 34.5091C146.689 33.7686 147.29 33.168 148.031 33.168H176.262C177.003 33.168 177.604 33.7686 177.604 34.5091C177.604 35.2507 177.003 35.8502 176.262 35.8502Z"
          fill="#7C9AF2"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M148.092 47.9687H113.799C113.319 47.9687 112.93 47.5798 112.93 47.1C112.93 46.6202 113.319 46.2312 113.799 46.2312H148.092C148.572 46.2312 148.961 46.6202 148.961 47.1C148.961 47.5798 148.572 47.9687 148.092 47.9687Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M161.137 47.9687H152.084C151.604 47.9687 151.215 47.5798 151.215 47.1C151.215 46.6202 151.604 46.2312 152.084 46.2312H161.137C161.617 46.2312 162.006 46.6202 162.006 47.1C162.006 47.5798 161.617 47.9687 161.137 47.9687Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M126.739 53.3612H113.801C113.321 53.3612 112.932 52.9722 112.932 52.4924C112.932 52.0126 113.321 51.6237 113.801 51.6237H126.739C127.219 51.6237 127.609 52.0126 127.609 52.4924C127.609 52.9722 127.219 53.3612 126.739 53.3612Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M147.705 53.3612H129.975C129.495 53.3612 129.105 52.9722 129.105 52.4924C129.105 52.0126 129.495 51.6237 129.975 51.6237H147.705C148.185 51.6237 148.574 52.0126 148.574 52.4924C148.574 52.9722 148.185 53.3612 147.705 53.3612Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M161.137 53.3612H152.084C151.604 53.3612 151.215 52.9722 151.215 52.4924C151.215 52.0126 151.604 51.6237 152.084 51.6237H161.137C161.617 51.6237 162.006 52.0126 162.006 52.4924C162.006 52.9722 161.617 53.3612 161.137 53.3612Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M131.356 58.021H113.799C113.319 58.021 112.93 57.632 112.93 57.1522C112.93 56.6724 113.319 56.2834 113.799 56.2834H131.356C131.836 56.2834 132.225 56.6724 132.225 57.1522C132.226 57.632 131.837 58.021 131.356 58.021Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M164.888 58.021H136.174C135.694 58.021 135.305 57.632 135.305 57.1522C135.305 56.6724 135.694 56.2834 136.174 56.2834H164.888C165.368 56.2834 165.757 56.6724 165.757 57.1522C165.757 57.632 165.368 58.021 164.888 58.021Z"
            fill="#BDD1F9"
          />
        </g>
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M148.09 65.0881H113.797C113.317 65.0881 112.928 64.6991 112.928 64.2193C112.928 63.7396 113.317 63.3506 113.797 63.3506H148.09C148.57 63.3506 148.959 63.7396 148.959 64.2193C148.959 64.6981 148.57 65.0881 148.09 65.0881Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M161.137 65.0881H152.084C151.604 65.0881 151.215 64.6991 151.215 64.2193C151.215 63.7396 151.604 63.3506 152.084 63.3506H161.137C161.617 63.3506 162.006 63.7396 162.006 64.2193C162.006 64.6981 161.617 65.0881 161.137 65.0881Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M126.737 70.4806H113.799C113.319 70.4806 112.93 70.0916 112.93 69.6118C112.93 69.132 113.319 68.743 113.799 68.743H126.737C127.217 68.743 127.607 69.132 127.607 69.6118C127.607 70.0916 127.217 70.4806 126.737 70.4806Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M147.699 70.4806H129.969C129.489 70.4806 129.1 70.0916 129.1 69.6118C129.1 69.132 129.489 68.743 129.969 68.743H147.699C148.179 68.743 148.568 69.132 148.568 69.6118C148.568 70.0916 148.179 70.4806 147.699 70.4806Z"
            fill="#BDD1F9"
          />
        </g>
        <path
          d="M85.0412 40.2798H88.707L98.6106 62.9381H94.4307L92.1482 57.5065H81.5059L79.1904 62.9381H75.1387L85.0412 40.2798ZM90.6997 54.0026L86.8094 45.0041L82.9512 54.0026H90.6997Z"
          fill="#BDD1F9"
        />
        <path
          d="M88.9496 77.6258H69.123V81.4513H88.9496V77.6258Z"
          fill="#F99746"
        />
        <path
          d="M88.9496 81.4557H69.123V85.2812H88.9496V81.4557Z"
          fill="#ED7D2B"
        />
        <path
          d="M88.9496 85.2739H69.123V89.0995H88.9496V85.2739Z"
          fill="#474FEA"
        />
        <path
          d="M103.563 79.7928H92.6545C92.198 79.7928 91.8281 79.4231 91.8281 78.9668C91.8281 78.5105 92.198 78.1408 92.6545 78.1408H103.563C104.02 78.1408 104.389 78.5105 104.389 78.9668C104.389 79.4231 104.02 79.7928 103.563 79.7928Z"
          fill="#BDD1F9"
        />
        <path
          d="M99.8868 82.7043H92.6525C92.1961 82.7043 91.8262 82.3345 91.8262 81.8783C91.8262 81.422 92.1961 81.0522 92.6525 81.0522H99.8868C100.343 81.0522 100.713 81.422 100.713 81.8783C100.713 82.3345 100.343 82.7043 99.8868 82.7043Z"
          fill="#BDD1F9"
        />
        <path
          d="M88.9496 97.4063H69.123V105.224H88.9496V97.4063Z"
          fill="#F99746"
        />
        <path
          d="M88.9496 103.293H69.123V108.884H88.9496V103.293Z"
          fill="#B5453C"
        />
        <path
          d="M69.123 97.4063L76.2354 103.379L69.123 108.885V97.4063Z"
          fill="#474FEA"
        />
        <path
          d="M103.563 99.569H92.6545C92.198 99.569 91.8281 99.1993 91.8281 98.743C91.8281 98.2867 92.198 97.917 92.6545 97.917H103.563C104.02 97.917 104.389 98.2867 104.389 98.743C104.389 99.1993 104.02 99.569 103.563 99.569Z"
          fill="#BDD1F9"
        />
        <path
          d="M99.8868 102.48H92.6525C92.1961 102.48 91.8262 102.111 91.8262 101.654C91.8262 101.198 92.1961 100.828 92.6525 100.828H99.8868C100.343 100.828 100.713 101.198 100.713 101.654C100.713 102.111 100.343 102.48 99.8868 102.48Z"
          fill="#BDD1F9"
        />
      </g>
      <path
        d="M211.364 150.542H149.521V127.056H211.57C216.585 127.056 220.65 131.119 220.65 136.132V141.259C220.65 146.386 216.493 150.542 211.364 150.542Z"
        fill="#FCECDB"
      />
      <path
        d="M273.698 136.721V140.254C273.698 142.143 273.223 143.91 272.394 145.431C270.727 148.489 267.628 150.544 264.08 150.544H147.614C146.843 150.544 146.219 149.876 146.219 149.052V148.523C146.219 147.7 146.843 147.031 147.614 147.031H218.611C218.991 146.537 219.325 146 219.608 145.43C220.273 144.09 220.648 142.561 220.648 140.939V136.448C220.648 135.622 220.548 134.821 220.36 134.058C220.043 132.765 219.473 131.581 218.714 130.57H147.614C146.843 130.57 146.219 129.902 146.219 129.077V128.549C146.219 127.724 146.843 127.056 147.614 127.056H264.667C268.791 127.056 272.271 130.015 273.351 134.057C273.577 134.904 273.698 135.797 273.698 136.721Z"
        fill="#699DEE"
      />
      <path
        d="M273.35 134.058H220.36C220.043 132.765 219.473 131.581 218.714 130.57H147.614C146.843 130.57 146.219 129.902 146.219 129.077V128.549C146.219 127.724 146.843 127.056 147.614 127.056H264.667C268.791 127.057 272.27 130.015 273.35 134.058Z"
        fill="#548EE2"
      />
      <path
        d="M149.521 138.793L158.144 139.189L149.521 139.426V138.793Z"
        fill="#F4D2B8"
      />
      <path
        d="M149.521 142.491L170.038 142.885L149.521 143.123V142.491Z"
        fill="#F4D2B8"
      />
      <path
        d="M193.667 127.119H133.916V100.279H193.885C199.188 100.279 203.486 104.576 203.486 109.876V117.306C203.485 122.725 199.089 127.119 193.667 127.119Z"
        fill="#FCECDB"
      />
      <path
        d="M203.517 110.693V116.469C203.502 118.937 202.358 121.077 200.678 122.222C199.85 122.783 198.894 123.104 197.878 123.104H132.084C131.313 123.104 130.688 123.85 130.688 124.771V125.453C130.688 126.373 131.313 127.12 132.084 127.12H245.745C249.006 127.12 251.888 125.185 253.629 122.223C254.727 120.356 255.371 118.083 255.371 115.628V111.071C255.371 109.389 255.05 107.797 254.473 106.379C253.012 102.77 249.916 100.281 246.333 100.281H132.084C131.313 100.281 130.688 101.026 130.688 101.946V102.628C130.688 103.548 131.313 104.295 132.084 104.295H198.079C199.656 104.295 201.084 105.096 202.082 106.379C202.964 107.514 203.509 109.027 203.517 110.693Z"
        fill="#AFCDFB"
      />
      <path
        d="M130.686 124.765V125.447C130.686 126.367 131.311 127.114 132.082 127.114H245.743C249.004 127.114 251.886 125.179 253.627 122.217H200.677C199.85 122.778 198.893 123.099 197.877 123.099H132.083C131.311 123.098 130.686 123.844 130.686 124.765Z"
        fill="#A4C4F4"
      />
      <path
        d="M133.916 113.7L142.351 114.151L133.916 114.423V113.7Z"
        fill="#F4D2B8"
      />
      <path
        d="M133.916 107.524L153.982 107.975L133.916 108.246V107.524Z"
        fill="#F4D2B8"
      />
      <path
        d="M153.775 107.884C153.775 107.884 152.45 108.735 152.012 110.135C151.935 110.38 151.885 110.641 151.874 110.917C151.801 112.775 151.874 116.982 151.874 116.982L147.964 114.879L144.064 116.982V111.281C144.064 110.203 144.399 109.155 145.02 108.468C145.322 108.136 145.687 107.884 146.1 107.884H153.775Z"
        fill="#F99746"
      />
      <path
        d="M153.77 107.884C153.77 107.884 152.445 108.735 152.007 110.135L144.172 110.218C144.172 110.218 144.48 108.214 146.096 107.884H153.77Z"
        fill="#ED7D2B"
      />
      <path
        d="M201.374 122.402C203.337 125.872 205.827 127.225 205.827 127.225L216.224 127.299C216.225 127.298 199.877 119.754 201.374 122.402Z"
        fill="#233862"
      />
      <path
        d="M209.175 149.2C209.443 149.512 209.475 149.859 209.34 150.229C209.34 150.23 209.338 150.233 209.336 150.235C209.083 150.917 208.259 151.677 207.302 152.434C207.18 152.531 207.071 152.62 206.976 152.702C206.951 152.723 206.926 152.745 206.903 152.767C206.884 152.784 206.866 152.801 206.847 152.817C205.953 153.628 206.286 153.819 204.68 155.466C202.848 157.344 201.596 157.369 201.242 157.194C200.941 157.046 201.56 156.047 202.246 154.925C202.28 154.87 202.313 154.816 202.346 154.761C202.437 154.611 202.528 154.461 202.618 154.31C203.422 152.959 203.224 152.321 203.049 150.301C202.908 148.659 200.561 141.117 200.561 141.117L204.049 140.542C204.049 140.542 205.056 146.542 206.205 147.472C206.82 147.968 207.511 147.98 208.104 148.348C208.145 148.374 208.187 148.401 208.227 148.428C208.562 148.654 208.906 148.887 209.175 149.2Z"
        fill="#FFB27D"
      />
      <path
        d="M209.177 149.206C209.446 149.518 209.478 149.865 209.342 150.235C209.342 150.236 209.341 150.239 209.339 150.241C209.03 150.922 208.226 151.678 207.301 152.433C207.18 152.533 207.073 152.623 206.978 152.708C206.954 152.729 206.928 152.751 206.906 152.773C206.886 152.79 206.868 152.807 206.849 152.823C205.955 153.634 206.289 153.825 204.682 155.472C202.851 157.35 201.599 157.375 201.244 157.2C200.943 157.052 201.562 156.053 202.249 154.931C202.604 154.803 204.252 154.147 205.506 152.567C206.61 151.178 207.353 149.389 208.106 148.356C208.148 148.381 208.189 148.408 208.229 148.435C208.565 148.66 208.908 148.893 209.177 149.206Z"
        fill="#233862"
      />
      <path
        d="M181.101 146.83C180.879 147.904 179.347 147.714 177.638 147.283C175.928 146.853 176.331 147.353 173.896 147.066C171.461 146.78 170.728 145.866 170.657 145.503C170.593 145.181 171.797 145.077 173.091 144.937C173.253 144.92 173.417 144.9 173.581 144.882C175.041 144.709 176.115 143.776 177.445 143.013C178.706 142.29 180.599 139.321 180.599 139.321L183.94 139.61C183.94 139.61 182.167 142.261 181.48 143.414C181.076 144.093 181.091 144.993 181.136 145.689C181.159 146.066 181.18 146.452 181.101 146.83Z"
        fill="#FFB27D"
      />
      <path
        d="M177.638 147.278C175.928 146.847 176.331 147.348 173.896 147.061C171.461 146.775 170.728 145.861 170.657 145.498C170.593 145.176 171.797 145.071 173.091 144.931L173.103 144.956C173.103 144.956 174.558 146.002 176.586 146.065C178.239 146.118 179.994 145.656 181.136 145.683C181.159 146.061 181.18 146.448 181.101 146.825C180.879 147.899 179.347 147.709 177.638 147.278Z"
        fill="#233862"
      />
      <path
        d="M194.066 125.552L197.132 133.979L201.135 144.982C201.135 144.982 202.241 145.097 203.304 144.8C204.282 144.525 205.408 143.794 205.408 143.794C205.408 143.794 204.358 132.002 202.293 125.223C201.764 123.483 201.166 122.074 200.502 121.279C197.251 117.387 194.066 125.552 194.066 125.552Z"
        fill="#102048"
        fillOpacity="0.93"
      />
      <path
        d="M197.334 112.225C199.386 111.58 205.219 112.468 211.077 113.657L212.319 111.641L224.995 110.741C224.995 110.741 229.109 120.038 226.78 125.927C226.438 126.792 225.602 127.152 224.462 127.163C224.462 127.164 224.461 127.164 224.461 127.165C224.461 127.165 216.9 127.297 214.452 127.28C208.151 127.235 201.148 122.857 200.24 122.668C198.753 122.36 198.136 125.333 196.624 128.448C195.113 131.564 182.527 142.193 182.527 142.193C182.527 142.193 180.604 142.198 179.811 141.895C178.959 141.569 178.65 141.151 178.65 141.151C178.65 141.151 192.706 113.682 197.334 112.225Z"
        fill="#102048"
      />
      <path
        d="M212.509 94.2042C217.004 96.7185 226.119 101.931 226.168 101.898C226.168 101.898 229.522 103.189 231.44 101.935C234.635 99.8463 236.381 94.1753 230.599 89.2438C227.714 86.7839 225.658 86.1759 225.372 82.2798C225.217 80.1662 222.377 73.1723 217.571 73.7835C217.169 73.8348 216.765 73.87 216.361 73.854C215.801 73.8316 214.874 73.9074 213.921 74.4652C211.192 76.0628 205.753 93.1954 212.509 94.2042Z"
        fill="#233862"
      />
      <path
        d="M216.305 92.1156C216.285 92.1284 218.519 92.9085 220.021 92.2556C221.435 91.6433 222.519 89.3341 222.497 89.333C221.857 89.2988 221.157 88.7336 220.641 85.0555L220.368 85.1516L215.768 86.7695C215.768 86.7695 216.089 88.2431 216.295 89.6461C216.468 90.8226 216.558 91.9511 216.305 92.1156Z"
        fill="#FFB27D"
      />
      <path
        d="M215.771 86.7628C215.771 86.7628 215.967 87.7961 216.24 89.2718C218.878 88.8166 219.99 86.5662 220.372 85.145L215.771 86.7628Z"
        fill="#ED975D"
      />
      <path
        d="M215.087 88.2314C215.087 88.2314 221.37 87.4331 220.812 83.5296C220.255 79.626 220.691 76.8937 216.655 77.2378C212.619 77.5818 212.054 79.1826 211.923 80.5311C211.793 81.8797 213.437 88.3382 215.087 88.2314Z"
        fill="#FFB27D"
      />
      <path
        d="M212.156 78.3966C212.156 78.3966 216.15 81.8866 218.882 82.1847C221.613 82.4829 222.654 81.6131 222.654 81.6131C222.654 81.6131 221.053 80.2645 220.098 78.0995C219.817 77.4616 219.216 77.0203 218.521 76.9562C216.57 76.7745 212.871 76.6708 212.156 78.3966Z"
        fill="#233862"
      />
      <path
        d="M214.671 78.0978C214.671 78.0978 213.318 79.3876 212.887 80.3482C212.472 81.2758 212.333 83.4984 212.653 84.3843C212.653 84.3843 210.587 80.298 211.787 78.3286C213.059 76.2406 214.671 78.0978 214.671 78.0978Z"
        fill="#233862"
      />
      <path
        d="M210.961 101.33C211.062 114.034 210.942 111.378 212.324 112.594C212.657 112.887 213.598 113.067 214.837 113.157C218.731 113.444 225.586 112.85 225.851 112.132C226.767 109.647 226.139 108.869 226.915 105.062C226.996 104.657 227.095 104.219 227.213 103.741C227.856 101.126 228.471 100.24 226.283 96.0163C224.298 92.1908 222.23 89.0833 222.042 89.1934C216.713 92.3051 216.303 89.655 216.303 89.655C216.303 89.655 210.911 95.1347 210.961 101.33Z"
        fill="#FF3E29"
      />
      <path
        d="M197.129 133.979L201.131 144.983C201.131 144.983 202.238 145.097 203.3 144.8C204.279 144.525 205.404 143.794 205.404 143.794C205.404 143.794 204.355 132.003 202.289 125.223L197.129 133.979Z"
        fill="#102048"
      />
      <path
        d="M216.297 89.65C216.297 89.65 210.83 90.5369 210.171 92.8824C209.514 95.228 205.643 109.018 205.643 109.018C205.643 109.018 208.416 109.536 209.707 108.264C210.998 106.991 214.254 96.4023 214.254 96.4023L216.297 89.65Z"
        fill="#FF3E29"
      />
      <path
        d="M214.838 113.154C218.732 113.441 225.587 112.847 225.852 112.129C226.768 109.644 226.14 108.866 226.916 105.059L225.074 99.7554C225.074 99.7554 225.343 105.649 223.594 107.185C221.848 108.722 216.457 110.126 215.603 111.415C215.192 112.033 214.919 112.655 214.838 113.154Z"
        fill="#F2CCAA"
      />
      <path
        d="M219.314 110.701C219.314 110.701 215.476 109.818 214.852 110.155C214.23 110.492 212.705 111.723 212.512 112.153C212.32 112.584 213.896 111.835 214.408 111.701C214.739 111.615 215.608 111.924 216.038 112.07C217.848 112.688 218.962 112.384 218.962 112.384L219.314 110.701Z"
        fill="#FFB27D"
      />
      <path
        d="M222.038 89.1881C222.038 89.1881 227.197 89.5429 228.966 92.0466C231.457 95.5708 236.341 110.551 233.467 112.525C230.231 114.747 218.805 113.009 218.805 113.009C218.805 113.009 218.437 111.228 218.973 110.372C219.508 109.516 228.124 108.186 228.523 107.792C228.923 107.398 223.613 100.016 223.622 98.8246C223.632 97.6331 222.038 89.1881 222.038 89.1881Z"
        fill="#FF3E29"
      />
      <path
        d="M214.661 111.22C214.661 111.22 213.848 112.253 213.583 112.375C213.318 112.497 213.148 112.465 213.148 112.465L213.698 111.492L214.661 111.22Z"
        fill="#FFB27D"
      />
      <path
        d="M214.851 110.151L212.502 111.271C212.502 111.271 212.6 111.434 212.889 111.424C213.177 111.413 213.906 111.152 213.906 111.152L214.851 110.151Z"
        fill="#FFB27D"
      />
      <path
        d="M214.089 114.049L195.564 113.918L190.09 100.028L208.585 100.158L214.089 114.049Z"
        fill="#F99746"
      />
      <path
        d="M195.344 113.379L219.807 113.551L219.802 114.091L195.558 113.92L195.344 113.379Z"
        fill="#ED7D2B"
      />
      <path
        d="M200.29 107.028C200.154 106.539 200.438 106.147 200.924 106.15C201.412 106.154 201.916 106.552 202.053 107.041C202.189 107.529 201.904 107.921 201.418 107.918C200.932 107.915 200.425 107.515 200.29 107.028Z"
        fill="white"
      />
      <path
        d="M33 129L39 135L33 141L27 135L33 129Z"
        fill="#089A79"
        fillOpacity="0.2"
      />
      <path
        d="M210 8L216 14L210 20L204 14L210 8Z"
        fill="#D99229"
        fillOpacity="0.2"
      />
      <path
        d="M261 79L267 85L261 91L255 85L261 79Z"
        fill="#DE5445"
        fillOpacity="0.2"
      />
      <defs>
        <filter
          id="filter0_dd"
          x="57.2031"
          y="13.763"
          width="129.211"
          height="112.69"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0 0.294118 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0 0.294118 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
  const SVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="272"
      height="150"
      viewBox="0 0 272 150"
      fill="none"
    >
      <path
        d="M3.6525 170L158.821 169.439L200.182 169.43L213.884 169.427L255.325 169.418L444.648 169.382C444.648 169.382 487.951 130.669 441.695 93.9005C393.737 55.7783 370.417 86.8249 355.674 33.1633C318.569 -101.914 -48.3076 -23.2965 47.9203 91.6469C82.5695 133.034 -20.283 111.036 3.6525 170Z"
        fill="#F8FCFF"
      />
      <g filter="url(#filter0_dd)">
        <path
          d="M179.295 120.453H64.3206C61.4941 120.453 59.2031 118.163 59.2031 115.337V18.8783C59.2031 16.053 61.4941 13.763 64.3206 13.763H179.295C182.122 13.763 184.413 16.053 184.413 18.8783V115.337C184.414 118.163 182.122 120.453 179.295 120.453Z"
          fill="white"
        />
        <path
          d="M184.414 25.6019H59.2031V17.5821C59.2031 15.4727 60.9136 13.763 63.0239 13.763H180.593C182.703 13.763 184.414 15.4727 184.414 17.5821V25.6019Z"
          fill="#699DEE"
        />
        <path
          d="M64.2695 19.6059C64.2695 18.8237 64.9032 18.19 65.6854 18.19C66.4676 18.19 67.1013 18.8237 67.1013 19.6059C67.1013 20.3881 66.4676 21.0218 65.6854 21.0218C64.9032 21.0207 64.2695 20.387 64.2695 19.6059Z"
          fill="#DDE7FE"
        />
        <path
          d="M70.2188 19.6051C70.2188 18.8229 70.8524 18.1893 71.6346 18.1893C72.4158 18.1893 73.0505 18.8229 73.0505 19.6051C73.0505 20.3874 72.4169 21.021 71.6346 21.021C70.8524 21.02 70.2188 20.3863 70.2188 19.6051Z"
          fill="#DDE7FE"
        />
        <path
          d="M76.1582 19.6051C76.1582 18.8229 76.7919 18.1893 77.5741 18.1893C78.3563 18.1893 78.99 18.8229 78.99 19.6051C78.99 20.3874 78.3563 21.021 77.5741 21.021C76.7919 21.02 76.1582 20.3863 76.1582 19.6051Z"
          fill="#DDE7FE"
        />
        <path
          opacity="0.5"
          d="M104.63 35.001H69.1367V70.4791H104.63V35.001Z"
          fill="#CDE0FB"
        />
        <path
          opacity="0.5"
          d="M142.503 37.6493H114.271C113.53 37.6493 112.93 37.0488 112.93 36.3082C112.93 35.5677 113.53 34.9672 114.271 34.9672H142.503C143.244 34.9672 143.844 35.5677 143.844 36.3082C143.843 37.0498 143.244 37.6493 142.503 37.6493Z"
          fill="#7C9AF2"
        />
        <path
          opacity="0.5"
          d="M176.262 35.8502H148.031C147.29 35.8502 146.689 35.2496 146.689 34.5091C146.689 33.7686 147.29 33.168 148.031 33.168H176.262C177.003 33.168 177.604 33.7686 177.604 34.5091C177.604 35.2507 177.003 35.8502 176.262 35.8502Z"
          fill="#7C9AF2"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M148.092 47.9687H113.799C113.319 47.9687 112.93 47.5798 112.93 47.1C112.93 46.6202 113.319 46.2312 113.799 46.2312H148.092C148.572 46.2312 148.961 46.6202 148.961 47.1C148.961 47.5798 148.572 47.9687 148.092 47.9687Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M161.137 47.9687H152.084C151.604 47.9687 151.215 47.5798 151.215 47.1C151.215 46.6202 151.604 46.2312 152.084 46.2312H161.137C161.617 46.2312 162.006 46.6202 162.006 47.1C162.006 47.5798 161.617 47.9687 161.137 47.9687Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M126.739 53.3612H113.801C113.321 53.3612 112.932 52.9722 112.932 52.4924C112.932 52.0126 113.321 51.6237 113.801 51.6237H126.739C127.219 51.6237 127.609 52.0126 127.609 52.4924C127.609 52.9722 127.219 53.3612 126.739 53.3612Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M147.705 53.3612H129.975C129.495 53.3612 129.105 52.9722 129.105 52.4924C129.105 52.0126 129.495 51.6237 129.975 51.6237H147.705C148.185 51.6237 148.574 52.0126 148.574 52.4924C148.574 52.9722 148.185 53.3612 147.705 53.3612Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M161.137 53.3612H152.084C151.604 53.3612 151.215 52.9722 151.215 52.4924C151.215 52.0126 151.604 51.6237 152.084 51.6237H161.137C161.617 51.6237 162.006 52.0126 162.006 52.4924C162.006 52.9722 161.617 53.3612 161.137 53.3612Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M131.356 58.021H113.799C113.319 58.021 112.93 57.632 112.93 57.1522C112.93 56.6724 113.319 56.2834 113.799 56.2834H131.356C131.836 56.2834 132.225 56.6724 132.225 57.1522C132.226 57.632 131.837 58.021 131.356 58.021Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M164.888 58.021H136.174C135.694 58.021 135.305 57.632 135.305 57.1522C135.305 56.6724 135.694 56.2834 136.174 56.2834H164.888C165.368 56.2834 165.757 56.6724 165.757 57.1522C165.757 57.632 165.368 58.021 164.888 58.021Z"
            fill="#BDD1F9"
          />
        </g>
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M148.09 65.0881H113.797C113.317 65.0881 112.928 64.6991 112.928 64.2193C112.928 63.7396 113.317 63.3506 113.797 63.3506H148.09C148.57 63.3506 148.959 63.7396 148.959 64.2193C148.959 64.6981 148.57 65.0881 148.09 65.0881Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M161.137 65.0881H152.084C151.604 65.0881 151.215 64.6991 151.215 64.2193C151.215 63.7396 151.604 63.3506 152.084 63.3506H161.137C161.617 63.3506 162.006 63.7396 162.006 64.2193C162.006 64.6981 161.617 65.0881 161.137 65.0881Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M126.737 70.4806H113.799C113.319 70.4806 112.93 70.0916 112.93 69.6118C112.93 69.132 113.319 68.743 113.799 68.743H126.737C127.217 68.743 127.607 69.132 127.607 69.6118C127.607 70.0916 127.217 70.4806 126.737 70.4806Z"
            fill="#BDD1F9"
          />
          <path
            opacity="0.3"
            d="M147.699 70.4806H129.969C129.489 70.4806 129.1 70.0916 129.1 69.6118C129.1 69.132 129.489 68.743 129.969 68.743H147.699C148.179 68.743 148.568 69.132 148.568 69.6118C148.568 70.0916 148.179 70.4806 147.699 70.4806Z"
            fill="#BDD1F9"
          />
        </g>
        <path
          d="M85.0412 40.2798H88.707L98.6106 62.9381H94.4307L92.1482 57.5065H81.5059L79.1904 62.9381H75.1387L85.0412 40.2798ZM90.6997 54.0026L86.8094 45.0041L82.9512 54.0026H90.6997Z"
          fill="#BDD1F9"
        />
        <path
          d="M88.9496 77.6258H69.123V81.4513H88.9496V77.6258Z"
          fill="#F99746"
        />
        <path
          d="M88.9496 81.4557H69.123V85.2812H88.9496V81.4557Z"
          fill="#ED7D2B"
        />
        <path
          d="M88.9496 85.2739H69.123V89.0995H88.9496V85.2739Z"
          fill="#474FEA"
        />
        <path
          d="M103.563 79.7928H92.6545C92.198 79.7928 91.8281 79.4231 91.8281 78.9668C91.8281 78.5105 92.198 78.1408 92.6545 78.1408H103.563C104.02 78.1408 104.389 78.5105 104.389 78.9668C104.389 79.4231 104.02 79.7928 103.563 79.7928Z"
          fill="#BDD1F9"
        />
        <path
          d="M99.8868 82.7043H92.6525C92.1961 82.7043 91.8262 82.3345 91.8262 81.8783C91.8262 81.422 92.1961 81.0522 92.6525 81.0522H99.8868C100.343 81.0522 100.713 81.422 100.713 81.8783C100.713 82.3345 100.343 82.7043 99.8868 82.7043Z"
          fill="#BDD1F9"
        />
        <path
          d="M88.9496 97.4063H69.123V105.224H88.9496V97.4063Z"
          fill="#F99746"
        />
        <path
          d="M88.9496 103.293H69.123V108.884H88.9496V103.293Z"
          fill="#B5453C"
        />
        <path
          d="M69.123 97.4063L76.2354 103.379L69.123 108.885V97.4063Z"
          fill="#474FEA"
        />
        <path
          d="M103.563 99.569H92.6545C92.198 99.569 91.8281 99.1993 91.8281 98.743C91.8281 98.2867 92.198 97.917 92.6545 97.917H103.563C104.02 97.917 104.389 98.2867 104.389 98.743C104.389 99.1993 104.02 99.569 103.563 99.569Z"
          fill="#BDD1F9"
        />
        <path
          d="M99.8868 102.48H92.6525C92.1961 102.48 91.8262 102.111 91.8262 101.654C91.8262 101.198 92.1961 100.828 92.6525 100.828H99.8868C100.343 100.828 100.713 101.198 100.713 101.654C100.713 102.111 100.343 102.48 99.8868 102.48Z"
          fill="#BDD1F9"
        />
      </g>
      <path
        d="M211.364 150.542H149.521V127.056H211.57C216.585 127.056 220.65 131.119 220.65 136.132V141.259C220.65 146.386 216.493 150.542 211.364 150.542Z"
        fill="#FCECDB"
      />
      <path
        d="M273.698 136.721V140.254C273.698 142.143 273.223 143.91 272.394 145.431C270.727 148.489 267.628 150.544 264.08 150.544H147.614C146.843 150.544 146.219 149.876 146.219 149.052V148.523C146.219 147.7 146.843 147.031 147.614 147.031H218.611C218.991 146.537 219.325 146 219.608 145.43C220.273 144.09 220.648 142.561 220.648 140.939V136.448C220.648 135.622 220.548 134.821 220.36 134.058C220.043 132.765 219.473 131.581 218.714 130.57H147.614C146.843 130.57 146.219 129.902 146.219 129.077V128.549C146.219 127.724 146.843 127.056 147.614 127.056H264.667C268.791 127.056 272.271 130.015 273.351 134.057C273.577 134.904 273.698 135.797 273.698 136.721Z"
        fill="#699DEE"
      />
      <path
        d="M273.35 134.058H220.36C220.043 132.765 219.473 131.581 218.714 130.57H147.614C146.843 130.57 146.219 129.902 146.219 129.077V128.549C146.219 127.724 146.843 127.056 147.614 127.056H264.667C268.791 127.057 272.27 130.015 273.35 134.058Z"
        fill="#548EE2"
      />
      <path
        d="M149.521 138.793L158.144 139.189L149.521 139.426V138.793Z"
        fill="#F4D2B8"
      />
      <path
        d="M149.521 142.491L170.038 142.885L149.521 143.123V142.491Z"
        fill="#F4D2B8"
      />
      <path
        d="M193.667 127.119H133.916V100.279H193.885C199.188 100.279 203.486 104.576 203.486 109.876V117.306C203.485 122.725 199.089 127.119 193.667 127.119Z"
        fill="#FCECDB"
      />
      <path
        d="M203.517 110.693V116.469C203.502 118.937 202.358 121.077 200.678 122.222C199.85 122.783 198.894 123.104 197.878 123.104H132.084C131.313 123.104 130.688 123.85 130.688 124.771V125.453C130.688 126.373 131.313 127.12 132.084 127.12H245.745C249.006 127.12 251.888 125.185 253.629 122.223C254.727 120.356 255.371 118.083 255.371 115.628V111.071C255.371 109.389 255.05 107.797 254.473 106.379C253.012 102.77 249.916 100.281 246.333 100.281H132.084C131.313 100.281 130.688 101.026 130.688 101.946V102.628C130.688 103.548 131.313 104.295 132.084 104.295H198.079C199.656 104.295 201.084 105.096 202.082 106.379C202.964 107.514 203.509 109.027 203.517 110.693Z"
        fill="#AFCDFB"
      />
      <path
        d="M130.686 124.765V125.447C130.686 126.367 131.311 127.114 132.082 127.114H245.743C249.004 127.114 251.886 125.179 253.627 122.217H200.677C199.85 122.778 198.893 123.099 197.877 123.099H132.083C131.311 123.098 130.686 123.844 130.686 124.765Z"
        fill="#A4C4F4"
      />
      <path
        d="M133.916 113.7L142.351 114.151L133.916 114.423V113.7Z"
        fill="#F4D2B8"
      />
      <path
        d="M133.916 107.524L153.982 107.975L133.916 108.246V107.524Z"
        fill="#F4D2B8"
      />
      <path
        d="M153.775 107.884C153.775 107.884 152.45 108.735 152.012 110.135C151.935 110.38 151.885 110.641 151.874 110.917C151.801 112.775 151.874 116.982 151.874 116.982L147.964 114.879L144.064 116.982V111.281C144.064 110.203 144.399 109.155 145.02 108.468C145.322 108.136 145.687 107.884 146.1 107.884H153.775Z"
        fill="#F99746"
      />
      <path
        d="M153.77 107.884C153.77 107.884 152.445 108.735 152.007 110.135L144.172 110.218C144.172 110.218 144.48 108.214 146.096 107.884H153.77Z"
        fill="#ED7D2B"
      />
      <path
        d="M201.374 122.402C203.337 125.872 205.827 127.225 205.827 127.225L216.224 127.299C216.225 127.298 199.877 119.754 201.374 122.402Z"
        fill="#233862"
      />
      <path
        d="M209.175 149.2C209.443 149.512 209.475 149.859 209.34 150.229C209.34 150.23 209.338 150.233 209.336 150.235C209.083 150.917 208.259 151.677 207.302 152.434C207.18 152.531 207.071 152.62 206.976 152.702C206.951 152.723 206.926 152.745 206.903 152.767C206.884 152.784 206.866 152.801 206.847 152.817C205.953 153.628 206.286 153.819 204.68 155.466C202.848 157.344 201.596 157.369 201.242 157.194C200.941 157.046 201.56 156.047 202.246 154.925C202.28 154.87 202.313 154.816 202.346 154.761C202.437 154.611 202.528 154.461 202.618 154.31C203.422 152.959 203.224 152.321 203.049 150.301C202.908 148.659 200.561 141.117 200.561 141.117L204.049 140.542C204.049 140.542 205.056 146.542 206.205 147.472C206.82 147.968 207.511 147.98 208.104 148.348C208.145 148.374 208.187 148.401 208.227 148.428C208.562 148.654 208.906 148.887 209.175 149.2Z"
        fill="#FFB27D"
      />
      <path
        d="M209.177 149.206C209.446 149.518 209.478 149.865 209.342 150.235C209.342 150.236 209.341 150.239 209.339 150.241C209.03 150.922 208.226 151.678 207.301 152.433C207.18 152.533 207.073 152.623 206.978 152.708C206.954 152.729 206.928 152.751 206.906 152.773C206.886 152.79 206.868 152.807 206.849 152.823C205.955 153.634 206.289 153.825 204.682 155.472C202.851 157.35 201.599 157.375 201.244 157.2C200.943 157.052 201.562 156.053 202.249 154.931C202.604 154.803 204.252 154.147 205.506 152.567C206.61 151.178 207.353 149.389 208.106 148.356C208.148 148.381 208.189 148.408 208.229 148.435C208.565 148.66 208.908 148.893 209.177 149.206Z"
        fill="#233862"
      />
      <path
        d="M181.101 146.83C180.879 147.904 179.347 147.714 177.638 147.283C175.928 146.853 176.331 147.353 173.896 147.066C171.461 146.78 170.728 145.866 170.657 145.503C170.593 145.181 171.797 145.077 173.091 144.937C173.253 144.92 173.417 144.9 173.581 144.882C175.041 144.709 176.115 143.776 177.445 143.013C178.706 142.29 180.599 139.321 180.599 139.321L183.94 139.61C183.94 139.61 182.167 142.261 181.48 143.414C181.076 144.093 181.091 144.993 181.136 145.689C181.159 146.066 181.18 146.452 181.101 146.83Z"
        fill="#FFB27D"
      />
      <path
        d="M177.638 147.278C175.928 146.847 176.331 147.348 173.896 147.061C171.461 146.775 170.728 145.861 170.657 145.498C170.593 145.176 171.797 145.071 173.091 144.931L173.103 144.956C173.103 144.956 174.558 146.002 176.586 146.065C178.239 146.118 179.994 145.656 181.136 145.683C181.159 146.061 181.18 146.448 181.101 146.825C180.879 147.899 179.347 147.709 177.638 147.278Z"
        fill="#233862"
      />
      <path
        d="M194.066 125.552L197.132 133.979L201.135 144.982C201.135 144.982 202.241 145.097 203.304 144.8C204.282 144.525 205.408 143.794 205.408 143.794C205.408 143.794 204.358 132.002 202.293 125.223C201.764 123.483 201.166 122.074 200.502 121.279C197.251 117.387 194.066 125.552 194.066 125.552Z"
        fill="#102048"
        fillOpacity="0.93"
      />
      <path
        d="M197.334 112.225C199.386 111.58 205.219 112.468 211.077 113.657L212.319 111.641L224.995 110.741C224.995 110.741 229.109 120.038 226.78 125.927C226.438 126.792 225.602 127.152 224.462 127.163C224.462 127.164 224.461 127.164 224.461 127.165C224.461 127.165 216.9 127.297 214.452 127.28C208.151 127.235 201.148 122.857 200.24 122.668C198.753 122.36 198.136 125.333 196.624 128.448C195.113 131.564 182.527 142.193 182.527 142.193C182.527 142.193 180.604 142.198 179.811 141.895C178.959 141.569 178.65 141.151 178.65 141.151C178.65 141.151 192.706 113.682 197.334 112.225Z"
        fill="#102048"
      />
      <path
        d="M212.509 94.2042C217.004 96.7185 226.119 101.931 226.168 101.898C226.168 101.898 229.522 103.189 231.44 101.935C234.635 99.8463 236.381 94.1753 230.599 89.2438C227.714 86.7839 225.658 86.1759 225.372 82.2798C225.217 80.1662 222.377 73.1723 217.571 73.7835C217.169 73.8348 216.765 73.87 216.361 73.854C215.801 73.8316 214.874 73.9074 213.921 74.4652C211.192 76.0628 205.753 93.1954 212.509 94.2042Z"
        fill="#233862"
      />
      <path
        d="M216.305 92.1156C216.285 92.1284 218.519 92.9085 220.021 92.2556C221.435 91.6433 222.519 89.3341 222.497 89.333C221.857 89.2988 221.157 88.7336 220.641 85.0555L220.368 85.1516L215.768 86.7695C215.768 86.7695 216.089 88.2431 216.295 89.6461C216.468 90.8226 216.558 91.9511 216.305 92.1156Z"
        fill="#FFB27D"
      />
      <path
        d="M215.771 86.7628C215.771 86.7628 215.967 87.7961 216.24 89.2718C218.878 88.8166 219.99 86.5662 220.372 85.145L215.771 86.7628Z"
        fill="#ED975D"
      />
      <path
        d="M215.087 88.2314C215.087 88.2314 221.37 87.4331 220.812 83.5296C220.255 79.626 220.691 76.8937 216.655 77.2378C212.619 77.5818 212.054 79.1826 211.923 80.5311C211.793 81.8797 213.437 88.3382 215.087 88.2314Z"
        fill="#FFB27D"
      />
      <path
        d="M212.156 78.3966C212.156 78.3966 216.15 81.8866 218.882 82.1847C221.613 82.4829 222.654 81.6131 222.654 81.6131C222.654 81.6131 221.053 80.2645 220.098 78.0995C219.817 77.4616 219.216 77.0203 218.521 76.9562C216.57 76.7745 212.871 76.6708 212.156 78.3966Z"
        fill="#233862"
      />
      <path
        d="M214.671 78.0978C214.671 78.0978 213.318 79.3876 212.887 80.3482C212.472 81.2758 212.333 83.4984 212.653 84.3843C212.653 84.3843 210.587 80.298 211.787 78.3286C213.059 76.2406 214.671 78.0978 214.671 78.0978Z"
        fill="#233862"
      />
      <path
        d="M210.961 101.33C211.062 114.034 210.942 111.378 212.324 112.594C212.657 112.887 213.598 113.067 214.837 113.157C218.731 113.444 225.586 112.85 225.851 112.132C226.767 109.647 226.139 108.869 226.915 105.062C226.996 104.657 227.095 104.219 227.213 103.741C227.856 101.126 228.471 100.24 226.283 96.0163C224.298 92.1908 222.23 89.0833 222.042 89.1934C216.713 92.3051 216.303 89.655 216.303 89.655C216.303 89.655 210.911 95.1347 210.961 101.33Z"
        fill="#FF3E29"
      />
      <path
        d="M197.129 133.979L201.131 144.983C201.131 144.983 202.238 145.097 203.3 144.8C204.279 144.525 205.404 143.794 205.404 143.794C205.404 143.794 204.355 132.003 202.289 125.223L197.129 133.979Z"
        fill="#102048"
      />
      <path
        d="M216.297 89.65C216.297 89.65 210.83 90.5369 210.171 92.8824C209.514 95.228 205.643 109.018 205.643 109.018C205.643 109.018 208.416 109.536 209.707 108.264C210.998 106.991 214.254 96.4023 214.254 96.4023L216.297 89.65Z"
        fill="#FF3E29"
      />
      <path
        d="M214.838 113.154C218.732 113.441 225.587 112.847 225.852 112.129C226.768 109.644 226.14 108.866 226.916 105.059L225.074 99.7554C225.074 99.7554 225.343 105.649 223.594 107.185C221.848 108.722 216.457 110.126 215.603 111.415C215.192 112.033 214.919 112.655 214.838 113.154Z"
        fill="#F2CCAA"
      />
      <path
        d="M219.314 110.701C219.314 110.701 215.476 109.818 214.852 110.155C214.23 110.492 212.705 111.723 212.512 112.153C212.32 112.584 213.896 111.835 214.408 111.701C214.739 111.615 215.608 111.924 216.038 112.07C217.848 112.688 218.962 112.384 218.962 112.384L219.314 110.701Z"
        fill="#FFB27D"
      />
      <path
        d="M222.038 89.1881C222.038 89.1881 227.197 89.5429 228.966 92.0466C231.457 95.5708 236.341 110.551 233.467 112.525C230.231 114.747 218.805 113.009 218.805 113.009C218.805 113.009 218.437 111.228 218.973 110.372C219.508 109.516 228.124 108.186 228.523 107.792C228.923 107.398 223.613 100.016 223.622 98.8246C223.632 97.6331 222.038 89.1881 222.038 89.1881Z"
        fill="#FF3E29"
      />
      <path
        d="M214.661 111.22C214.661 111.22 213.848 112.253 213.583 112.375C213.318 112.497 213.148 112.465 213.148 112.465L213.698 111.492L214.661 111.22Z"
        fill="#FFB27D"
      />
      <path
        d="M214.851 110.151L212.502 111.271C212.502 111.271 212.6 111.434 212.889 111.424C213.177 111.413 213.906 111.152 213.906 111.152L214.851 110.151Z"
        fill="#FFB27D"
      />
      <path
        d="M214.089 114.049L195.564 113.918L190.09 100.028L208.585 100.158L214.089 114.049Z"
        fill="#F99746"
      />
      <path
        d="M195.344 113.379L219.807 113.551L219.802 114.091L195.558 113.92L195.344 113.379Z"
        fill="#ED7D2B"
      />
      <path
        d="M200.29 107.028C200.154 106.539 200.438 106.147 200.924 106.15C201.412 106.154 201.916 106.552 202.053 107.041C202.189 107.529 201.904 107.921 201.418 107.918C200.932 107.915 200.425 107.515 200.29 107.028Z"
        fill="white"
      />
      <path
        d="M33 129L39 135L33 141L27 135L33 129Z"
        fill="#089A79"
        fillOpacity="0.2"
      />
      <path
        d="M210 8L216 14L210 20L204 14L210 8Z"
        fill="#D99229"
        fillOpacity="0.2"
      />
      <path
        d="M261 79L267 85L261 91L255 85L261 79Z"
        fill="#DE5445"
        fillOpacity="0.2"
      />
      <defs>
        <filter
          id="filter0_dd"
          x="57.2031"
          y="13.763"
          width="129.211"
          height="112.69"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0 0.294118 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0 0.294118 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
  return (
    <div>
      {smallScreen ? (
        <div className={stylesMobile.main}>
          <div>
            <h4>{t("Practice")}</h4>
            <p>
              {t(
                "Curated questions at varied difficulty levels and from previous year tests, guided with hints, explanation videos, test cases and solutions in a state-of-art environment."
              )}
            </p>
          </div>
          <SVGMobile />
        </div>
      ) : (
        <div className={styles.main}>
          <div>
            <h4>{t("Practice")}</h4>
            <p>
              {t(
                "Curated questions at varied difficulty levels and from previous year tests, guided with hints, explanation videos, test cases and solutions in a state-of-art environment."
              )}
            </p>
          </div>
          <SVG />
        </div>
      )}
    </div>
  );
};

export default PracticeHeroCard;
