import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Rating from "@material-ui/lab/Rating";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { useHistory, useNavigate } from "react-router-dom";
import * as CustomIcons from "../../assets/icons/icons";
import { PriceContainer } from "pages/LoginPage/New/PriceContainer";
import styles from "./Prepare.module.scss";
import "./prepares.css";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    width: 300,
    boxShadow: "0px 0px 25px rgba(170, 170, 170, 0.25)",
    borderRadius: 5,
    position: "relative",
    top: "50px",
    height: 400,
  },
  media: {
    height: "150px",
  },
  colorPrimary: {
    backgroundColor: "#DFDFDF",
  },
  barColorPrimary: {
    backgroundColor: "#F56F18;",
  },
  buttonWidth: {
    width: "100%",
  },
  hours: {
    color: "#4A4851",
    marginBottom: "5px",
  },
  chapter: {
    marginRight: "12px",
  },
  intermediate: {
    marginRight: "10px",
  },
  completedChapter: {
    fontSize: 14,
  },
  sectionTitle: {
    fontWeight: 600,
    fontSize: 20,
    color: "#323036",
  },
  dividers: {
    margin: "10px 0",
  },
});

export default function RelatedCourses(props) {
  const id = props.categoryId;
  const classes = useStyles();
  const history = useHistory();
  const [ContinuePackages, setContinuePackages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [topPackages, setTopPackages] = useState([]);
  const { t } = useTranslation();

  const handlePageRedirect = (data) => {
    if (data.status === "Inprogress" || data.status === "Completed") {
      history.push(`/course-learning/${data.id}`);
    } else if (data.status === "NotStarted") {
      history.push(`/course-overview/${data.id}`);
    }
  };

  return (
    <>
      <div className="mx-auto pt-5">
        <h2 className={classes.sectionTitle}>Courses You May Like</h2>
        {props.relatedCourse.length == 0 ? (
          <p className={classes.relatedInfo} style={{ fontWeight: "semibold" }}>
            *No related course available*
          </p>
        ) : (
          <Grid container spacing={10}>
            {props.relatedCourse.length > 0 &&
              props.relatedCourse
                .slice(0, 4)
                .filter((e) => e.status == "NotStarted")
                .map((data, index) => {
                  return (
                    <>
                      <Grid item xs={12} md={6} lg={3} key={index}>
                        <Card className={classes.root}>
                          <CardMedia
                            className={classes.media}
                            // image={data.media}
                            image={
                              data?.media
                                ? data.media.includes("default")
                                  ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${data.media}.jpeg`
                                  : data?.media
                                : `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/default-${
                                    GetRandom(9) + 1
                                  }.jpeg`
                            }
                            title={data.name}
                          />
                          <div className={styles.courseTagName}>
                            {data.courseTags === "Premium"
                              ? CustomIcons.premiumIcon
                              : data.courseTags === "Special"
                              ? CustomIcons.specialIcon
                              : data.courseTags === "Latest"
                              ? CustomIcons.latestIcon
                              : CustomIcons.feturedIcon}
                          </div>
                          <CardContent>
                            <h2 className="course-name">{data.name}</h2>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <div className={classes.hours}>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="hours-svg"
                                  >
                                    <g clipPath="url(#clip0)">
                                      <path
                                        d="M8.00016 2.33331C4.32416 2.33331 1.3335 5.32398 1.3335 8.99998C1.3335 12.676 4.32416 15.6666 8.00016 15.6666C11.6762 15.6666 14.6668 12.676 14.6668 8.99998C14.6668 5.32398 11.6762 2.33331 8.00016 2.33331ZM8.00016 14.3333C5.0595 14.3333 2.66683 11.9406 2.66683 8.99998C2.66683 6.05931 5.0595 3.66665 8.00016 3.66665C10.9408 3.66665 13.3335 6.05931 13.3335 8.99998C13.3335 11.9406 10.9408 14.3333 8.00016 14.3333Z"
                                        fill="#7C7887"
                                      />
                                      <path
                                        d="M8.66683 5.66669H7.3335V9.66669H11.3335V8.33335H8.66683V5.66669Z"
                                        fill="#7C7887"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0">
                                        <rect
                                          width="16"
                                          height="16"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>

                                  <span className="duration">
                                    {" "}
                                    {data.duration} hours
                                  </span>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div className={classes.chapter}>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="hours-svg"
                                  >
                                    <path
                                      d="M1.3335 4.00002C1.3335 4.00002 2.3335 2.66669 4.66683 2.66669C7.00016 2.66669 8.00016 4.00002 8.00016 4.00002V13.3334C8.00016 13.3334 7.00016 12.6667 4.66683 12.6667C2.3335 12.6667 1.3335 13.3334 1.3335 13.3334V4.00002Z"
                                      stroke="#7C7887"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M8 4.00002C8 4.00002 9 2.66669 11.3333 2.66669C13.6667 2.66669 14.6667 4.00002 14.6667 4.00002V13.3334C14.6667 13.3334 13.6667 12.6667 11.3333 12.6667C9 12.6667 8 13.3334 8 13.3334V4.00002Z"
                                      stroke="#7C7887"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <span className="level">
                                    {" "}
                                    {data.numberOfModules} Chapters
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <Rating
                                  name="size-small"
                                  defaultValue={
                                    data.courseRating ? data.courseRating : 0
                                  }
                                  size="small"
                                  readOnly
                                ></Rating>
                                <span className="text-muted continue-rating">
                                  {" "}
                                  {data.courseRating ? data.courseRating : 0}
                                </span>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div className={classes.intermediate}>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.6665 4H9.99984V11.3333H8.6665V4ZM11.3332 2H12.6665V11.3333H11.3332V2ZM5.99984 6H7.33317V11.3333H5.99984V6ZM2.6665 12.6667H13.3332V14H2.6665V12.6667ZM3.33317 8H4.6665V11.3333H3.33317V8Z"
                                      fill="#7C7887"
                                    />
                                  </svg>
                                  <span className="level"> {data.level}</span>
                                </div>
                              </Grid>
                            </Grid>

                            <Divider className={classes.dividers} />
                            <>
                              {data.status === "Inprogress" ||
                              data.status === "Completed" ? (
                                <>
                                  <Box display="flex" alignItems="center">
                                    <Box width="100%" mr={1}>
                                      <LinearProgress
                                        value={data.progress}
                                        variant="determinate"
                                        classes={{
                                          colorPrimary: classes.colorPrimary,
                                          barColorPrimary:
                                            classes.barColorPrimary,
                                        }}
                                      />
                                    </Box>
                                    <Box minWidth={35}>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        {`${Math.round(data.progress)}%`}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Typography
                                    variant="p"
                                    className={classes.completedChapter}
                                  >
                                    {`${data.modulesCompleted}/${data.numberOfModules}`}{" "}
                                    chapters completed
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <PriceContainer
                                    originalPrice={data.originalPrice}
                                    strikingPrice={data.strikingPrice}
                                  />
                                </>
                              )}
                            </>
                          </CardContent>

                          <CardActions>
                            <Button
                              variant="outlined"
                              className={
                                data.status === "Inprogress"
                                  ? "continue-button"
                                  : data.status === "NotStarted"
                                  ? "preview-button"
                                  : "continue-button"
                              }
                              onClick={() => handlePageRedirect(data)}
                            >
                              {data.status === "Inprogress"
                                ? t("Continue learning")
                                : data.status === "NotStarted"
                                ? t("Preview")
                                : t("Completed")}
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    </>
                  );
                })}
          </Grid>
        )}
      </div>
    </>
  );
}
